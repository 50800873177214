import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import { CheckBoxField, TextField } from "../../../../components/common/inputs";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import { convertToBoolean } from "../../../../components/common/commonUtilities";

export const PtsManageProgramsTableRowEdit = ({
                                                  handleCancelProgramClick,
                                                  handleSaveProgramClick,
                                                  isNewProgram = false,
                                                  program
                                              }) => {
    const cancelBtnLabelDetails = isNewProgram ? `adding` : `editing`;
    return (
        <tr>
            <td>
                <TextField
                    {...program.programName}
                    label={`Program Name`}
                    name={`programName`}
                    showLabel={false}
                />
            </td>
            <td className={`text-center`}>
                <CheckBoxField
                    {...program.requiresReturnOnInvestmentReport}
                    checked={convertToBoolean(program.requiresReturnOnInvestmentReport.value)}
                    label={`ROI Report Required`}
                    showLabel={false}
                    value={(!convertToBoolean(program.requiresReturnOnInvestmentReport.value)).toString()}
                />
            </td>
            <td>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={`Save program for ${program.programName.value}`}
                    name={`btnSaveProgram_${program.programId.value}`}
                    onClick={handleSaveProgramClick}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.CANCEL}
                    label={`Cancel ${cancelBtnLabelDetails} of program for ${program.programName.value}`}
                    name={`btnCancelProgram_${program.programId.value}`}
                    onClick={handleCancelProgramClick}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

PtsManageProgramsTableRowEdit.propTypes = {
    handleCancelProgramClick: PropTypes.func.isRequired,
    handleSaveProgramClick: PropTypes.func.isRequired,
    isNewProgram: PropTypes.bool,
    program: PropTypes.object.isRequired
};