import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as strategicPlanActions from "../../actions/strategicPlanActions";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import Strategy from "../../components/strategicPlan/Strategy";
import TeamMembers from "../../components/strategicPlan/TeamMembers";
import { getParams } from "../../components/layout/getParams";
import * as strategicPlanLocations from "../../constants/strategicPlan/strategicPlanLocations";

export class StrategicPlanReportPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isTitleSet: false,
			params: {}
		};
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, strategicPlanLocations.REPORT);

        if (params !== null){
        	this.setState({params});
		}

		this.props.actions.loadFiveYearReport(params.dateWindowId);

		if (this.props.plans.length === 0) {
			this.props.actions.loadPlans();
		}

		this.props.actions.loadSppIndicators();

		this.props.actions.loadAllDateWindows();

        if (!this.state.isTitleSet) {
            const pageTitle = `BEESS Strategic Plan 2018-2023`;

            this.props.actions.updatePageTitle(pageTitle);

            this.setState({isTitleSet: true});
        }
	}

	render() {
		{
			if (this.props.sppIndicators.length === 0 ||
				this.props.plans.length === 0 ||
				this.props.fiveYearReport.length === 0)
				return null;

			const filteredPlans = this.props.plans.filter(plan => plan.dateWindowId === this.state.params.dateWindowId);
			const documentFilters = (
				{
					showIndicators: true,
					showActions: true
				}
			);

			return (
				<div>
					{
						filteredPlans.map(plan => {
							return (
								<section key={plan.planDateWindowId} className="strategyPlan">
									<header>
										<h1>{plan.name}</h1>
									</header>

									{
										plan.teamMembers.length > 0 &&
										<TeamMembers
											members={plan.teamMembers}
											filters={documentFilters}
											isEditing={false}
											isLoading={this.props.isLoading}
										/>
									}
									{

										this.props.fiveYearReport
											.filter(strategy => strategy.planDateWindowId === plan.planDateWindowId)
											.map((strategy, i) => {
												return (
													<Strategy
														key={strategy.id}
														strategyIndex={i}
														strategy={strategy}
														filters={documentFilters}
														planStartYear={plan.startYear.toString()}
														isEditing={false}
														isLoading={this.props.isLoading}
														onFileDownload={null}
														sppIndicators={this.props.sppIndicators}
													/>);
											})
									}
								</section>
							);
						})
					}
				</div>
			);
		}
	}
}

StrategicPlanReportPage.propTypes = {
	actions: PropTypes.object.isRequired,
	plans: PropTypes.array.isRequired,
	sppIndicators: PropTypes.array.isRequired,
	fiveYearReport: PropTypes.array.isRequired,
	dateWindows: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		plans: state.strategicPlan.plans,
		dateWindows: state.sharedData.dateWindows,
		sppIndicators: state.sharedData.sppIndicators,
		fiveYearReport: state.strategicPlan.fiveYearReport,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		strategicPlanActions,
		sharedDataActions,
		layoutActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StrategicPlanReportPage);