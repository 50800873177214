import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../../../components/authorization/policies/MonitoringCompliancePolicy";
import {createIndicatorTitle} from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ComplianceUtilities from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import audit_outcomes from "../../../../constants/auditOutcomes";
import {emptyGuid} from "../../../../constants/config";
import {monitoring_compliance} from "../../../../constants/policyEvents";
import CapApi from "../CapApi";
import {getRelatedActionsActivities} from "../capUtilities";
import StateActivitiesView from "./StateActivitiesView";
import StateActivityDetails from "./StateActivityDetails";

export const StateActivitiesContainer = ({
                                             actions,
                                             assessmentId,
                                             districtId,
                                             handleScroll,
                                             handleScrollToTop,
                                             institutions,
                                             isLoading,
                                             selectedAssessment
                                         }) => {
    const [titleIsSet, setTitleIsSet] = useState(false);
    const [capActivities, setCapActivities] = useState(null);
    const [protocolCorrectiveActions, setProtocolCorrectiveActions] = useState(null);
    const [currentAssessmentId, setCurrentAssessmentId] = useState("");
    const [activityId, setActivityId] = useState(null);
    const [shouldReloadCapActivity, setShouldReloadCapActivity] = useState(false);
    const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);

    const loadCapActivities = async () => {
        const capResults = await actions.executeApi(CapApi.loadCapActivities, [assessmentId]);

        const pcaResults = await actions.executeApi(CapApi.loadProtocolCorrectiveActions, [assessmentId]);

        setCapActivities(capResults);
        setProtocolCorrectiveActions(pcaResults);
        setCurrentAssessmentId(assessmentId);
    };

    const canAudit = () => {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.cap.AUDIT);
    }

    const canModifyReview = () => {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.review.MODIFY);
    }

    const reviewActivity = (activityId, indicatorId) => {
        setActivityId(activityId);
        setSelectedIndicatorId(indicatorId);
        handleScrollToTop();
    };

    const cancelReviewActivity = () => {
        setActivityId(null);
        handleScroll(selectedIndicatorId);
        setSelectedIndicatorId(null);
    };

    const handleSaveResultsReview = async (resultsReview) => {
        await actions.executeApi(CapApi.saveCapActivityResultReview, [resultsReview]);
        await loadCapActivities();
        setShouldReloadCapActivity(true);
        setActivityId(null);
        handleScroll(selectedIndicatorId);
        setSelectedIndicatorId(null);
    };

    const onActivityOutcomeChange = async (auditOutcome) => {
        let auditOutcomeApi = "";
        switch (auditOutcome) {
            case audit_outcomes.ACCEPTED.name:
                auditOutcomeApi = `approveDistrictCapActivity`;
                break;
            case audit_outcomes.NEEDS_REVISION.name:
                auditOutcomeApi = `rejectDistrictCapActivity`;
                break;
            case audit_outcomes.RESET.name:
                auditOutcomeApi = `resetDistrictCapActivity`;
                break;
        }

        if (auditOutcomeApi !== "") {
            await actions.executeApi(CapApi[auditOutcomeApi], [assessmentId, activityId]);
            await loadCapActivities();
            setShouldReloadCapActivity(true);
        }
    };

    const onActivityCommentSaved = async (auditCommentDto) => {
        const isCreate = auditCommentDto.auditCommentId === emptyGuid;
        await actions.executeApi(CapApi.CapActivityAuditComment, [auditCommentDto, isCreate, assessmentId, activityId]);
        await loadCapActivities();
        setShouldReloadCapActivity(true);
    }

    const updateTitle = () => {
        if (titleIsSet || !institutions || institutions.length === 0) return;

        const pageTitle = ComplianceUtilities.createPageTitle(institutions, districtId, "Corrective Actions");
        actions.updatePageTitle(pageTitle);

        setTitleIsSet(true);
    }

    useEffect(() => {
        if (capActivities || !assessmentId || currentAssessmentId === assessmentId) return;

        loadCapActivities();
    }, [assessmentId]);

    useEffect(() => {
        updateTitle();
    }, [institutions]);

    if (!capActivities || !protocolCorrectiveActions || !selectedAssessment) return null;

    return (
        <section className="compliance compliance__correctiveActionPlan">
            {
                !activityId &&
                selectedAssessment.indicatorRequirements.map((requirement, index) => {
                    const relatedData = getRelatedActionsActivities(requirement.indicatorId, protocolCorrectiveActions, capActivities);
                    const indicatorTitle = createIndicatorTitle(requirement.title, requirement.publicIdentifier);
                    return (
                        <div key={index} className={"section-spacing-bottom"} id={requirement.indicatorId}>
                            <h3>{indicatorTitle}</h3>
                            <StateActivitiesView
                                canEditActivityResult={canAudit() || canModifyReview()}
                                capActivities={relatedData.relatedCapActivities}
                                handleReview={reviewActivity}
                                protocolCorrectiveActions={relatedData.relatedCorrectiveActions}
                                indicatorId={requirement.indicatorId}
                            />
                        </div>
                    );
                })
            }
            {
                activityId &&
                <StateActivityDetails
                    assessmentId={assessmentId}
                    canAudit={canAudit()}
                    canModifyReview={canModifyReview()}
                    capActivity={capActivities.find(f => f.activityId === activityId)}
                    isLoading={isLoading}
                    onActivityCommentSaved={onActivityCommentSaved}
                    onActivityOutcomeChange={onActivityOutcomeChange}
                    onCancel={cancelReviewActivity}
                    onSave={handleSaveResultsReview}
                    protocolCorrectiveActions={protocolCorrectiveActions}
                    setShouldReloadCapActivity={setShouldReloadCapActivity}
                    shouldReloadCapActivity={shouldReloadCapActivity}
                />
            }
        </section>
    );
};

StateActivitiesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    districtId: PropTypes.string,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    institutions: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedAssessment: PropTypes.object.isRequired
};

export default StateActivitiesContainer;

