import PropTypes from "prop-types";
import React from "react";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as drLocations from "../drLocations";

export const DrNavigation = ({ currentLocation, history }) => {
    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (currentLocation.startsWith(location)) return;

        history.push(location);
    };

    const caseListUrl = drLocations.LANDING_CASE_LIST.getUrl();
    const districtSummaryUrl = drLocations.DR_DISTRICT_SUMMARY.getUrl();
    const drSummaryUrl = drLocations.DR_SUMMARY.getUrl();
    const drCaseLoadesUrl = drLocations.DR_CASELOAD_SUMMARY.getUrl();
    const drCaseResearchUrl = drLocations.DR_CASE_RESEARCH_SUMMARY.getUrl();

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`drNavigation`}>
            <Button
                btnClass={currentLocation.startsWith(drSummaryUrl) ? "is-active" : ""}
                label={`My Summary`}
                name={`btnDrSummary`}
                onClick={(event) => navButtonClick(event, drSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(caseListUrl) ? "is-active" : ""}
                label={`Case List`}
                name={`btnCaseList`}
                onClick={(event) => navButtonClick(event, caseListUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(drCaseLoadesUrl) ? "is-active" : ""}
                label={`Caseloads`}
                name={`btnCaseLoads`}
                onClick={(event) => navButtonClick(event, drCaseLoadesUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(districtSummaryUrl) ? "is-active" : ""}
                label={`District Summary`}
                name={`btnDistrictSummary`}
                onClick={(event) => navButtonClick(event, districtSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(drCaseResearchUrl) ? "is-active" : ""}
                label={`Case Research`}
                name={`btnCaseResearch`}
                onClick={(event) => navButtonClick(event, drCaseResearchUrl)}
            />
        </ButtonBar>
    );
};

DrNavigation.propTypes = {
    currentLocation: PropTypes.string,
    history: PropTypes.object
};
