import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import {createListItem} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import * as LayoutActions from "../../../actions/layoutActions";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { STATE_BPIE_IMPORT, STATE_BPIE_REGIONS, STATE_SBPIE_CREATE, STATE_MANAGE_FIN } from "../bpieLocations";
import { isFinFacilitator} from "../bpieUtilities";
import AccountUtility from "../../../api/AccountUtility";
import {DASHBOARD} from "../../../constants/systemLocations";
import {bpieAdminSections} from "./bpieAdminConstants";

const BpieAdminWrapper = ({
                              actions,
                              Component,
                              districts,
                              finFacilitatorDistricts,
                              currentLocation,
                              history,
                              props
                          }) => {
    const [dataChanged, setDataChanged] = useState(false);

    const bpieImportUrl = STATE_BPIE_IMPORT.getUrl();
    const createBpieUrl = STATE_SBPIE_CREATE.getUrl();
    const regionUrl = STATE_BPIE_REGIONS.getUrl();
    const manageFinUrl = STATE_MANAGE_FIN.getUrl();

    const handleSelectSection = (event, section) => {
        let url = "";
        switch (section) {
            case bpieAdminSections.Import:
                url = bpieImportUrl;
                break;
            case bpieAdminSections.Create:
                url = createBpieUrl;
                break;
            case bpieAdminSections.Region:
                url = regionUrl;
                break;
            case bpieAdminSections.ManageFin:
                url = manageFinUrl;
                break;
        }

        if (url)
            navButtonClick(event, url);
    };

    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (location === currentLocation) return;

        if (dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setDataChanged(false);

        history.push(location);
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    useEffect(() => {
        if (!AccountUtility.isBpieStateManageUser()
            || isFinFacilitator(finFacilitatorDistricts)) {
            history.push(DASHBOARD.path);
        }
    }, []);

    return (
        <GridRow isPageNav>
            <GridColumn
                isPageNavLinks
                isStickyLinks
                large="3"
            >
                <Button
                    btnClass={currentLocation === bpieImportUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, bpieImportUrl)}
                    label={bpieAdminSections.Import}
                    name={bpieImportUrl}
                />

                <Button
                    btnClass={currentLocation === createBpieUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, createBpieUrl)}
                    label={bpieAdminSections.Create}
                    name={createBpieUrl}
                />

                <Button
                    btnClass={currentLocation === regionUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, regionUrl)}
                    label={bpieAdminSections.Region}
                    name={regionUrl}
                />

                <Button
                    btnClass={currentLocation === manageFinUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, manageFinUrl)}
                    label={bpieAdminSections.ManageFin}
                    name={manageFinUrl}
                />
            </GridColumn>

            <GridColumn
                isPageNavContent
                large="9"
            >
                <Component
                    {...props}
                    actions={actions}
                    dataChanged={dataChanged}
                    districts={districts}
                    handleDataChanged={handleDataChanged}
                    handleSelectSection={handleSelectSection}
                    history={history}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;

    const institutions = [...state.sharedData.institutions];
    const allDistricts = institutions.map(ins => {
        return {
            ...createListItem(ins.id, ins.name),
            isDisabled: false
        }
    });

    return {
        Component,
        finFacilitatorDistricts: state.bpie.finFacilitatorDistricts,
        currentLocation,
        districts: allDistricts,
        props
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieAdminWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    districts: PropTypes.array,
    finFacilitatorDistricts: PropTypes.array,
    history: PropTypes.object,
    props: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieAdminWrapper);