import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import * as menuActions from '../../actions/menuActions';
import * as accountActions from '../../actions/accountActions';
import Banner from "./Banner";
import Menu from "./Menu";
import {bindActionCreators} from "redux";
import LocalStorage from "../shared/LocalStorage";

class SiteHeader extends React.PureComponent {
    constructor(props) {
        super(props);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.adminMenuChange = this.adminMenuChange.bind(this);
        this.logout = this.logout.bind(this);
        this.handleReturnAsAdmin = this.handleReturnAsAdmin.bind(this);
    }

    toggleMenu() {
        !this.props.isActive && this.props.actions.toggleMenu(true);
    }

    adminMenuChange(isOpen) {
        this.props.actions.toggleAdminMenuOpen(isOpen);
    }

    logout() {
        LocalStorage.logout();
        this.props.actions.logoutSuccess();
    }

    handleReturnAsAdmin = () => {
        const returnUrl = LocalStorage.getAdminReturnUrl();

        let refreshTokenViewModel = {
            token: LocalStorage.getAdminAccessToken(),
            refreshToken: LocalStorage.getAdminRefreshToken()
        }

        this.props.actions.getUserTokenFromRefreshToken(refreshTokenViewModel)
            .then(() => {
                this.props.history.push(returnUrl);
            });
    }

    render() {
        return (
            <div>
                <Banner
                    onClick={this.toggleMenu}
                    isActive={this.props.isActive}
                />
                <Menu
                    isActive={this.props.isActive}
                    logout={this.logout}
                    isAdminMenuOpen={this.props.isAdminMenuOpen}
                    adminMenuChange={this.adminMenuChange}
                    handleReturnAsAdmin={this.handleReturnAsAdmin}
                />
            </div>
        );
    }
}


SiteHeader.propTypes = {
    actions: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    isAdminMenuOpen: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        isActive: state.menu.isActive,
        isAdminMenuOpen: state.menu.isAdminMenuOpen
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, menuActions, accountActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeader);