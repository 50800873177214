import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import {emptyGuid} from "../../../../constants/config";

export const createDiscretionaryProjectObject = ({
                                                     discretionaryProjectFiscalAgents,
                                                     discretionaryProjectId,
                                                     projectName
                                                 } = {}) => ({
    discretionaryProjectId: discretionaryProjectId || emptyGuid,
    projectName: projectName || "",
    discretionaryProjectFiscalAgents: isArrayNullOrEmpty(discretionaryProjectFiscalAgents)
        ? []
        : discretionaryProjectFiscalAgents.map(fiscalAgent => createDiscretionaryProjectFiscalAgentObject(fiscalAgent))
});

const createDiscretionaryProjectFiscalAgentObject = ({
                                                         discretionaryProjectUsers,
                                                         fiscalAgentId,
                                                         fiscalAgentName
                                                     } = {}) => ({
    discretionaryProjectUsers: isArrayNullOrEmpty(discretionaryProjectUsers)
        ? []
        : discretionaryProjectUsers.map(projectUser => createDiscretionaryProjectUserObject(projectUser)),
    fiscalAgentId,
    fiscalAgentName
});

const createDiscretionaryProjectUserObject = ({
                                                  applicationUserId,
                                                  firstName,
                                                  lastName
                                              } = {}) => ({
    applicationUserId,
    firstName,
    lastName
});
