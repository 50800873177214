import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import AuditHistory from "../../components/audit/AuditHistory";
import {isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import IndicatorProtocol from "../../components/MonitoringCompliance/IndicatorProtocol";
import IndicatorProtocolDistrictForm from "../../components/MonitoringCompliance/IndicatorProtocolDistrictForm";
import {getProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {SMALL_STICKY_HEIGHT} from "../../constants/buttonStickyHeights";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import LocalStorage from "../../components/shared/LocalStorage";
import { getParams } from "../../components/layout/getParams";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class MonitoringComplianceEditStudentRecordPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			studentRecord: null,
			protocolResponses: [],
			indicatorName: "",
			districtName: "",
			params: {},
			showDelete: true,
			initData: false,
			protocolsLoaded: false
		};

		this.onChangeProtocolResponse = this.onChangeProtocolResponse.bind(this);
		this.onChangeSubQuestionResponse = this.onChangeSubQuestionResponse.bind(this);
		this.onClickSave = this.onClickSave.bind(this);
		this.onClickReturn = this.onClickReturn.bind(this);
		this.onClickDelete = this.onClickDelete.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, ComplianceLocations.COMPLIANCE_RECORD_DISTRICT);
        if (params !== null)
        	this.setState({params});

		this.initializeData(params.indicatorRequirementId);

		if (!this.props.institution)
			this.props.actions.loadInstitutions();
	}

	componentDidUpdate() {

		this.initializeData(this.state.params.indicatorRequirementId);

		if (!this.state.studentRecord &&
			this.props.institution.id &&
			this.props.currentIndicatorRequirement &&
			this.props.selectedAssessment.studentRecords &&
			this.props.indicatorProtocols &&
			this.props.indicatorProtocols.length > 0 &&
			this.state.protocolsLoaded) {

			let studentRecord = this.props.selectedAssessment.studentRecords.find(rec => {
				return rec.studentRecordId === this.state.params.studentRecordId;
			});

			if (studentRecord) {
				this.props.actions.setEditMode(!studentRecord.isRecordSubmitted);
				this.props.actions.updatePageTitle("Monitoring for Compliance - " + studentRecord.name);
				let protocolResponses = [...studentRecord.protocolResponses.map(rec => Object.assign({}, rec))];
				this.setState({
					studentRecord: studentRecord,
					protocolResponses: this.setDefaultResponse(protocolResponses, !studentRecord.isRecordSubmitted)
				});

				return;
			}

			//Student record was not found.  Create new student record
			this.props.actions.setEditMode(true);
			const studentRecordId = this.state.params.studentRecordId;
			const newRecord = MonitoringComplianceUtilities.newStudentRecord(studentRecordId, [this.props.currentIndicatorRequirement.indicatorId]);
			this.props.actions.updatePageTitle("Monitoring for Compliance - New Student Record");
			this.setState({
				studentRecord: newRecord,
				protocolResponses: this.setDefaultResponse([], true),
				showDelete: false
			});
		}
	}

	initializeData(indicatorRequirementId) {
		if (this.props.selectedAssessment
			&& this.props.selectedAssessment.indicatorRequirements
			&& !this.state.initData) {
			const indicatorRequirementArray = this.props.selectedAssessment.indicatorRequirements.filter(r => r.indicatorRequirementId === indicatorRequirementId);
			if(indicatorRequirementArray.length === 1) {
				this.props.actions.loadIndicatorProtocols(this.props.selectedAssessment.dateWindowId, indicatorRequirementArray[0].indicatorId)
					.then(() => {
						this.setState({protocolsLoaded: true});
					})
				this.setState({initData: true});
			}
		}
	}

	onChangeProtocolResponse(event, protocolId) {
		this.updateProtocolResponses(event.target.value, protocolId);
	}

	updateProtocolResponses(responseValue, protocolId) {
		this.setState({
			protocolResponses: this.state.protocolResponses.map(rec => {
				if (rec.protocolId === protocolId && rec.responseType === responseTypes.ASSESS)
					rec.response = responseValue;

				return rec;
			})
		});
		this.props.handleDataChanged();
	}

	onChangeSubQuestionResponse(event, protocolId, index) {
		this.setState({
			protocolResponses: this.state.protocolResponses.map(rec => {
				if (rec.protocolId === protocolId && rec.responseType === responseTypes.ASSESS) {
					let subQuestionResponses = MonitoringComplianceUtilities.updateSubQuestionArray([...rec.subQuestionResponses], index, event.target.value);
					rec.subQuestionResponses = [...subQuestionResponses];
				}

				return rec;
			})
		});
		this.props.handleDataChanged();
	}

	 createNewProtocolResponses(protocolResponses) {
		 this.props.indicatorProtocols.map(protocol => {
			 let response = protocolResponses.find(r => r.protocolId === protocol.protocolId && r.responseType === responseTypes.ASSESS);
			 if (!response) {
				 let protocolResponseId = emptyGuid;
				 let newResponse = MonitoringComplianceUtilities.newProtocolResponse(protocolResponseId, protocol.protocolId, "", responseTypes.ASSESS);
				 protocolResponses.push(newResponse);
			 }
		 });
	}

	setDefaultResponse(protocolResponses, isEditing) {
		if(protocolResponses.filter(f => f.responseType === responseTypes.ASSESS).length !== this.props.indicatorProtocols.length && isEditing && this.canUserEdit()) {
			this.createNewProtocolResponses(protocolResponses);
		}

		return protocolResponses;
	}

	onClickSave(event) {
		event.preventDefault();
		this.save(true);
	}

	save(reload) {
		if((!this.props.isEditing || !this.canUserEdit()) && !reload)
			this.returnToMainPage();

		if (this.props.isEditing && this.canUserEdit()) {
			this.props.actions.saveStudentRecord(this.props.institution.id,
				this.props.selectedAssessment.monitoringAssessmentId,
				this.state.studentRecord.studentRecordId,
				MonitoringComplianceUtilities.prepareProtocolResponsesForSave(this.state.protocolResponses, responseTypes.ASSESS),
				responseTypes.ASSESS)
				.then((newStudentRecordId) => {
					this.props.handleDataChanged(false);
					if(!newStudentRecordId) return;

					const callback = reload ? () => this.setState({studentRecord: null}) : null;
					this.props.loadComplianceAssessment(callback);

					if(reload) {
						if (this.state.params.studentRecordId === emptyGuid) {
							this.setState({
								params: {...this.state.params, studentRecordId: newStudentRecordId},
								showDelete: true
							});
							this.props.history.replace(ComplianceLocations.COMPLIANCE_RECORD_DISTRICT.path
								.replace(ComplianceLocations.ASSESSMENT_ID, this.state.params.assessmentId)
								.replace(ComplianceLocations.REQUIREMENT_ID, this.state.params.indicatorRequirementId)
								.replace(ComplianceLocations.STUDENT_RECORD_ID, newStudentRecordId));
						}
					}
					else
						this.returnToMainPage();
				});
		}
	}

	returnToMainPage() {
		if (this.props.selectedAssessment.dateWindowId)
			this.props.history.push(ComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path
				.replace(ComplianceLocations.DATE_WINDOW_ID, this.props.selectedAssessment.dateWindowId));
		else
			this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);

		this.props.handleScroll(this.state.params.indicatorRequirementId);
	}

	onClickReturn(event) {
		event.preventDefault();
		this.save(false);
	}

	onClickDelete(event) {
		event.preventDefault();

		if(confirm("Are you sure you want to delete this student record?")) {
			this.props.actions.deleteStudentRecord(this.props.institution.id,
				this.props.selectedAssessment.monitoringAssessmentId,
				this.state.studentRecord.studentRecordId)
				.then(() => {
					this.props.handleDataChanged(false);
					this.props.loadComplianceAssessment(() => {
						this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);
						this.props.handleScroll(this.state.params.indicatorRequirementId);
					});
				});
		}
	}

	canUserEdit() {
		return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
	}

	hasMissingResponses() {
		if(!this.state.studentRecord || this.state.studentRecord.studentRecordId === emptyGuid)
			return false;

		let hasMissingResponses = false;
		for(let protocol of this.props.indicatorProtocols) {
			let response = MonitoringComplianceUtilities.getProtocolResponse(
				this.state.protocolResponses,
				protocol.protocolId,
				responseTypes.ASSESS);
			if(isTrimmedStringEmpty(response))
				hasMissingResponses = true;
		}

		return hasMissingResponses;
	}

	render() {
		if (!this.props.selectedAssessment.studentRecords || !this.state.studentRecord ) return null;
		const canEdit = this.canUserEdit();
		const isEditing = this.props.isEditing && canEdit;
		const showMissingResponses = this.hasMissingResponses();
		return (
			<div>
				{
					showMissingResponses &&
					<p>Missing responses are <span className={`missing-data-highlight`}>highlighted.</span></p>
				}
				<AuditHistory audits={this.state.studentRecord.audits} />
				{
					isEditing &&
					this.props.indicatorProtocols.map(protocol => {
						let response = "";
						let responseText = "";
						let subQuestionResponses = [];
						if (this.state.studentRecord) {
							response = MonitoringComplianceUtilities.getProtocolResponse(
								this.state.protocolResponses,
								protocol.protocolId,
								responseTypes.ASSESS);

							responseText = MonitoringComplianceUtilities.getProtocolResponse(
								this.state.protocolResponses,
								protocol.protocolId,
								responseTypes.ASSESS,
								true);

							subQuestionResponses = MonitoringComplianceUtilities.getSubQuestionResponse(
								this.state.protocolResponses,
								protocol.protocolId,
								responseTypes.ASSESS);
						}

						return (
							<IndicatorProtocolDistrictForm
								key={protocol.protocolId}
								protocol={protocol}
								response={response}
								responseText={responseText}
								onResponseChange={(event) => this.onChangeProtocolResponse(event, protocol.protocolId)}
								subQuestionResponses={subQuestionResponses}
								onSubQuestionResponseChange={this.onChangeSubQuestionResponse}
								showMissingResponses={showMissingResponses}
							/>);
					})
				}
				{
					!isEditing &&
					this.props.indicatorProtocols.length > 0 &&
					<IndicatorProtocol
						indicatorProtocols={this.props.indicatorProtocols}
                        protocolResponses={this.state.protocolResponses}
						assessmentApproved={this.props.indicatorRequirementStatus.assessmentApproved}
					/>
				}
				{
					isEditing &&
					<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} stickyHeight={SMALL_STICKY_HEIGHT}>
						{
							this.state.showDelete &&
							<Button name={"delete"}
									label={"Delete"}
									onClick={this.onClickDelete}
									disabled={this.props.isLoading}
									buttonType={ButtonTypes.DELETE}/>
						}
						<Button name={"save"}
								label={"Save"}
								onClick={this.onClickSave}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.SAVE}/>
						<Button name={"cancel"}
								label={"Save and Return"}
								onClick={this.onClickReturn}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.BACK}/>
					</ButtonBar>
				}
				{
					!isEditing &&
					<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} stickyHeight={SMALL_STICKY_HEIGHT}>
						<Button name={"cancel"}
								label={"Return"}
								onClick={this.onClickReturn}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.BACK}/>
					</ButtonBar>
				}
			</div>
		);
	}


}

MonitoringComplianceEditStudentRecordPage.propTypes = {
	actions: PropTypes.object.isRequired,
	currentIndicatorRequirement: PropTypes.object,
	handleDataChanged: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	indicatorProtocols: PropTypes.arrayOf(PropTypes.object).isRequired,
	indicatorRequirementStatus: PropTypes.object,
	institution: PropTypes.object,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	loadComplianceAssessment: PropTypes.func.isRequired,
	location: PropTypes.object.isRequired,
	selectedAssessment: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
	let institution = {};
	let currentIndicatorRequirement = {};
    const params = getParams(ownProps.location.pathname, ComplianceLocations.COMPLIANCE_RECORD_DISTRICT);

	const currentUser = LocalStorage.getLoggedInUser();

	if (state.sharedData.institutions.length > 0) {
		const institutionArray = state.sharedData.institutions.filter(i => i.id === currentUser.InstitutionId);
		if(institutionArray.length === 1)
			institution = Object.assign({}, institutionArray[0]);
	}

	if (state.monitoringCompliance.selectedAssessment && state.monitoringCompliance.selectedAssessment.indicatorRequirements) {
		const currentIndicatorRequirementArray  = state.monitoringCompliance.selectedAssessment.indicatorRequirements.filter(ir => ir.indicatorRequirementId === params.indicatorRequirementId);
		if(currentIndicatorRequirementArray.length === 1)
			currentIndicatorRequirement = Object.assign({}, currentIndicatorRequirementArray[0]);
	}

	const indicatorRequirementStatus = getProtocolRequirementStatus(currentIndicatorRequirement);

	return {
		indicatorProtocols: state.monitoringCompliance.indicatorProtocols,
		currentIndicatorRequirement: currentIndicatorRequirement,
		indicatorRequirementStatus: indicatorRequirementStatus,
		institution: institution,
		isEditing: state.monitoringCompliance.isEditing,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

export default connect(mapStateToProps)(MonitoringComplianceEditStudentRecordPage);