import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import FormTable from "../../../../components/common/FormTable";
import {SelectField} from "../../../../components/common/inputs";
import {
    convertFormInputObjectToObject, reinitializeInputFormFields,
    useFormInputObject,
} from "../../../../components/common/inputs/inputUtility";
import RadioField from "../../../../components/common/inputs/RadioField";
import {gridConstants} from "../../../../constants/inputConstants";
import {APPLICATION_ACCESS_STATUS_LIST, COPY_BEHAVIOR_LIST} from "../../gmsConstants";
import {GmsAdminNavigation} from "../GmsAdminNavigation";
import {createApplicationSectionObject} from "./gmsManageApplicationsObjectFactory";

export const GmsManageApplicationsView = ({
                                              applicationSections,
                                              dataChanged,
                                              gmsSettings,
                                              handleDataChanged,
                                              handleSaveGmsSettings,
                                          }) => {
    const gmsSettingsForm = useFormInputObject({...gmsSettings}, () => handleDataChanged());
    const gmsSectionsForm = useFormInputObject(createApplicationSectionObject(applicationSections), () => handleDataChanged());

    const handleSaveGmsSettingsClick = (event) => {
        event.preventDefault();
        const updatedSettings = convertFormInputObjectToObject(gmsSettingsForm);
        const sectionsObject = convertFormInputObjectToObject(gmsSectionsForm);

        handleSaveGmsSettings(updatedSettings, sectionsObject);
    };

    useEffect(() => {
        reinitializeInputFormFields(gmsSettingsForm, gmsSettings);
        handleDataChanged(false);
    }, [gmsSettings.dateWindowId]);

    return (
        <>
            <h2>State-wide Fiscal Agent Access</h2>
            <FormTable>
                <RadioField
                    {...gmsSettingsForm.applicationAccessStatus}
                    legend={`Access to Applications`}
                    options={APPLICATION_ACCESS_STATUS_LIST}
                    showLegend
                />
            </FormTable>
            <h2>Current Year Response Behavior</h2>
            <FormTable>
                {
                    applicationSections.map((section) => {
                        const formElement = gmsSectionsForm[section.elementId];
                        return <SelectField
                            includeDefaultOption={false}
                            key={section.elementId}
                            label={section.elementText}
                            options={COPY_BEHAVIOR_LIST}
                            medium={gridConstants.column.SIX}
                            showLabel
                            {...formElement}
                        />;
                    })
                }
            </FormTable>
            <GmsAdminNavigation
                handleSave={handleSaveGmsSettingsClick}
                isDirty={dataChanged}
            />
        </>
    );
};

GmsManageApplicationsView.propTypes = {
    applicationSections: PropTypes.array,
    dataChanged: PropTypes.bool,
    gmsSettings: PropTypes.object,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveGmsSettings: PropTypes.func.isRequired,
};