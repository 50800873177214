import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import AccountUtility from "../../../api/AccountUtility";
import {UserSearchCriteria} from "../../../components/common/searchCriterias/userSearchCriteria";
import {DASHBOARD} from "../../../constants/systemLocations";
import {createTeamMemberList} from "../../drObjectFactory";
import api from "../../drApi";
import userApi from "../../../api/UserApi";
import drAdminTeamMembersApi from "./drAdminTeamMembersApi";
import DrAdminTeamMembersEdit from "./DrAdminTeamMembersEdit";
import {dynamicSort, isNullOrUndefined} from "../../../components/common/commonUtilities";
import  * as userConstants from "../../../constants/users/userConstants";
import * as behaviors from "../../../constants/behaviors";
import * as contexts from "../../../constants/contexts";

const DrAdminTeamMembersContainer = ({history}) => {
    const [fullListOfUsersSorted, setFullListOfUsersSorted] = useState(null);
    const [teamMembers, setTeamMembers] = useState(null);
    const [stateUsers, setStateUsers] = useState(null);
    const [selectedDrRole, setSelectedDrRole] = useState(1);

    const handleSaveTeamMembers = (selectedTeamMemberUserIds) => {
        drAdminTeamMembersApi.saveTeamMembers({userIds: selectedTeamMemberUserIds, role: selectedDrRole}).then(setTeamMembers);
    };

    useEffect(() => {
        if(!AccountUtility.isStateUserDrAuthorized())
            history.push(DASHBOARD.path);

        api.getTeamMembers(true).then(setTeamMembers);

        let userSearchCriteria = new UserSearchCriteria("", `${contexts.STATE_COMPLAINTS}.${behaviors.EDIT},${contexts.DUE_PROCESS}.${behaviors.EDIT},${contexts.MEDIATIONS}.${behaviors.EDIT}`, userConstants.USER_TYPES.State, userConstants.STATUS.ACTIVE);
        userSearchCriteria.RecordsPerPage = 0;
        userSearchCriteria.SortByColumn = "LastName";
        userApi.getUsers(userSearchCriteria).then((result) => setStateUsers(result.data));
    }, []);

    const buildUniqueCombinedList = () => {
        const nonActiveDoeUsers = teamMembers.filter(f => f.role === selectedDrRole).map((teamMember) => {
            const userExists = stateUsers.find(f => f.userId === teamMember.userId);
            if(userExists)
                return;

            teamMember.id = null;
            return teamMember;
        });

        const allUsers = [...nonActiveDoeUsers.filter(f => !isNullOrUndefined(f)), ...stateUsers];

        setFullListOfUsersSorted(allUsers.sort(dynamicSort("lastName", false, "firstName")));
    };

    useEffect(() => {
        if(!teamMembers || !stateUsers)
            return;

        buildUniqueCombinedList();
    }, [teamMembers, stateUsers, selectedDrRole]);

    const handleChangeDrRole = (drRole) => {
        setSelectedDrRole(parseInt(drRole));
    };

    if(!teamMembers || !stateUsers || !fullListOfUsersSorted)
        return null;

    return <DrAdminTeamMembersEdit
        fullListOfUsersSorted={createTeamMemberList(fullListOfUsersSorted)}
        handleChangeDrRole={handleChangeDrRole}
        handleSaveTeamMembers={handleSaveTeamMembers}
        selectedDrRole={selectedDrRole}
        selectedTeamMemberUserIds={teamMembers.filter(f => f.role === selectedDrRole).map(i => i.userId)}
        stateUsers={createTeamMemberList(stateUsers)}
    />;
};

DrAdminTeamMembersContainer.propTypes = {
    history: PropTypes.object,
};

export default DrAdminTeamMembersContainer;
