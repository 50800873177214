import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import {getYesNoNotApplicableOption} from "../../../../components/common/inputs/inputUtility";
import LocalStorage from "../../../../components/shared/LocalStorage";
import {bpieTeamRoles} from "../../bpieConstants";
import {createBpieTeamMember} from "../../bpieObjectFactory";
import {schoolBpieTrainingStatus} from "./bpieSchoolInfoConstants";

export const createSchoolInfoDetails = ({assessmentTeam, dateMeetingHeld, hasCompletedBpieTraining, hasMetEssaTarget, schoolBpieId, schoolBpieTeam, bpieTrainingQuestions, hasPassedBpieTraining} = {}) => {

    const teamLeader = schoolBpieTeam.find(f => f.role === bpieTeamRoles.meetingLeader);
    const finFacilitator = schoolBpieTeam.find(f => f.role === bpieTeamRoles.finFacilitator);

    const schoolInfoDetails = {
        ...createBpieTeamMember(teamLeader),
        assessmentTeam: assessmentTeam || "",
        bpieTrainingQuestions,
        dateMeetingHeld: dateMeetingHeld || "",
        districtId: LocalStorage.getLoggedInUser().InstitutionId,
        finFacilitatorId: finFacilitator?.userId || "",
        hasCompletedBpieTraining: isNullOrUndefined(hasCompletedBpieTraining) ? null : hasCompletedBpieTraining,
        hasMetEssaTarget: getYesNoNotApplicableOption(hasMetEssaTarget),
        schoolBpieId: schoolBpieId || "",
        trainingStatus: hasPassedBpieTraining
            ? schoolBpieTrainingStatus.PassedTraining
            : isNullOrUndefined(hasCompletedBpieTraining)
                ? schoolBpieTrainingStatus.NotSelected
                : hasCompletedBpieTraining ? schoolBpieTrainingStatus.TakeAssessment : schoolBpieTrainingStatus.TakeTraining,
    };

    for(let i = 0; i < bpieTrainingQuestions.length; i++)
        schoolInfoDetails[`response${i}`] = "";

    return schoolInfoDetails;
};

export const createTrainingResponse = (trainingQuestion, answer) => ({
    trainingQuestion,
    answer
});