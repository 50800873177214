import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import GridTable from "../../../../components/common/GridTable";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import Button from "../../../../components/common/buttons/Button";
import * as config from "../../../../constants/config";
import {createEditRowObject, createSchoolAdmin} from "../../School/bpieSchoolAdminFactory";
import BpieDistrictSummaryRowEdit from "./BpieDistrictSummaryRowEdit";
import {bpieTeamRoles} from "../../bpieConstants";

const BpieDistrictSbpieErrorsView = ({
                                         actions,
                                         bpie,
                                         getSbpieFromError,
                                         handleSbpieErrorFixed,
                                         sbpieErrors
                                     }) => {
    const [editRow, setEditRow] = useState(createEditRowObject);

    const handleFixError = async (event, schoolBpieErrorId) => {
        event.preventDefault();
        await getSbpieFromError(schoolBpieErrorId);
        setEditRow(createEditRowObject(schoolBpieErrorId, true));
    };

    const createSchoolAdminFromBpie = () => {
        const schoolAdmin = bpie.schoolBpieTeam?.find(admin => admin.role === bpieTeamRoles.schoolAdministrator);
        if(schoolAdmin)
            return createSchoolAdmin({
                email: schoolAdmin.email,
                firstName: schoolAdmin.firstName,
                lastName: schoolAdmin.lastName,
                isReplacement: false
            });

        return createSchoolAdmin({});
    }

    const handleCancelClick = () => {
        setEditRow(createEditRowObject);
    }
    const handleReloadSchoolAdministrator = (sBpieErrorId) => {
        setEditRow(createEditRowObject);
        handleSbpieErrorFixed(sBpieErrorId);
    }

    const notificationTrigger = <CollapsibleTrigger
        text={`Schools with errors when creating their SBPIE Assessments`}/>;

    if (sbpieErrors.length === 0)
        return null;

    return (
        <>
            <p>If needed, <a href={`mailto:` + config.supportEmail}>contact support via email for help</a></p>
            <CollapsibleContainer
                id={`sbpie-errors-container`}
                open
                trigger={notificationTrigger}
            >
                <GridTable>
                    <thead>
                    <tr>
                        <th>School Name</th>
                        <th>MSID</th>
                        <th>Principal Name</th>
                        <th>Principal Email</th>
                        <th>Error</th>
                        <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR}>
                            <th>Action</th>
                        </Allow>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        sbpieErrors.map((sbpieError, key) => {
                            const isEditSchoolBpie = editRow.id === sbpieError.schoolBpieErrorId;
                            return (
                                <tr key={key}>
                                    {!isEditSchoolBpie && <>
                                        <td>{sbpieError.schoolName}</td>
                                        <td>{sbpieError.msidNumber}</td>
                                        <td>{sbpieError.principalFirstName} {sbpieError.principalLastName}</td>
                                        <td>{sbpieError.principalEmail}</td>
                                        <td>{sbpieError.errorMessage}</td>
                                        <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR}>
                                            <td>
                                                {
                                                    <Button
                                                        buttonType={ButtonTypes.SYSTEM}
                                                        label={`Fix Error`}
                                                        name={`btnFixError`}
                                                        onClick={(event) => handleFixError(event, sbpieError.schoolBpieErrorId)}
                                                        showLabel
                                                    />
                                                }
                                            </td>
                                        </Allow>
                                    </>
                                    }
                                    {isEditSchoolBpie &&
                                        <BpieDistrictSummaryRowEdit
                                            actions={actions}
                                            bpie={bpie}
                                            createSchoolAdminFromBpie={createSchoolAdminFromBpie}
                                            handleCancelClick={handleCancelClick}
                                            handleReloadSchoolAdministrator={() => handleReloadSchoolAdministrator(sbpieError.schoolBpieErrorId)}
                                            isReplacement={false}
                                        />
                                    }
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </GridTable>
            </CollapsibleContainer>
        </>
    );
}

BpieDistrictSbpieErrorsView.propTypes = {
    actions: PropTypes.object.isRequired,
    bpie: PropTypes.object.isRequired,
    getSbpieFromError: PropTypes.func.isRequired,
    handleSbpieErrorFixed: PropTypes.func.isRequired,
    sbpieErrors: PropTypes.array.isRequired
};

export default BpieDistrictSbpieErrorsView;