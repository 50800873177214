import React from "react";
import {PTS} from "../../constants/contexts";
import {FilterView, FilterViewPropTypes} from "../../components/common/searchCriterias/FilterView";

export const PtsFilter = ({
                              dateWindows,
                              handleExport,
                              handleSearchFiltersChange,
                              numberOfFilterColumns = 0,
                              searchCriteria
                          }) => {
    return <FilterView
        context={PTS}
        dateWindows={dateWindows}
        handleExport={handleExport}
        handleSearchFiltersChange={handleSearchFiltersChange}
        numberOfFilterColumns={numberOfFilterColumns}
        searchCriteria={searchCriteria}
    />;
};

PtsFilter.propTypes = FilterViewPropTypes;