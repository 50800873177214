import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {gridConstants} from "../../../../constants/inputConstants";
import gmsApi from "../../gmsApi";
import {FORM_TYPE} from "../../gmsConstants";
import {GmsApplicationHistorySelectField} from '../GmsApplicationHistorySelectField';
import Button from '../../../../components/common/buttons/Button';
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {BUDGET_TYPES} from "./gmsApplicationBudgetConstants";

export const GmsApplicationBudgetHistory = ({
                                                actions,
                                                applicationHistories,
                                                applicationId,
                                                budgetId,
                                                isAmendmentBudget,
                                                selectedApplicationHistory,
                                                setSelectedApplicationHistory
                                            }) => {
    const handleClickExportBudgetSubmissionHistory = async (event) => {
        event.preventDefault();
        await actions.executeApi(gmsApi.exportBudget, [applicationId, budgetId, selectedApplicationHistory]);
    };

    const downloadDoeBudgetForm = (event, formType) => {
        event.preventDefault();
        const applicationHistoryId = selectedApplicationHistory || emptyGuid;
        actions.executeApi(gmsApi.downloadGeneratedForm, [applicationId, formType, applicationHistoryId]);
    }

    useEffect(() => {
        if (applicationHistories.every(a => selectedApplicationHistory !== a.id))
            setSelectedApplicationHistory("");
    }, []);

    const formType = isAmendmentBudget ? FORM_TYPE.doe151 : FORM_TYPE.doe101;
    const downloadFormLabelSuffix = isAmendmentBudget ? BUDGET_TYPES.amendmentBudget.description : BUDGET_TYPES.applicationBudget.description;

    return (
        <GridRow rowClass="applicationHistoryBar" medium={gridConstants.column.TWO}>
            <GridColumn>
                <GmsApplicationHistorySelectField
                    applicationHistories={applicationHistories}
                    label={`Budget Submission History`}
                    includeCurrent
                    includeDefaultOption={false}
                    selectedApplicationHistory={selectedApplicationHistory}
                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                />
            </GridColumn>
            <GridColumn columnClass={`applicationHistoryBar__buttonColumn`}>
                <Button
                    label={`Export Selected Budget`}
                    name={`btnExportBudgetSubmissionHistory`}
                    onClick={handleClickExportBudgetSubmissionHistory}
                />
                <Button
                    showLabel
                    buttonType={ButtonTypes.DOWNLOAD}
                    label={`Download Selected ${downloadFormLabelSuffix}`}
                    name="btnDownload"
                    onClick={(event) => downloadDoeBudgetForm(event, formType)}
                />
            </GridColumn>

        </GridRow>
    );
};


GmsApplicationBudgetHistory.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    budgetId: PropTypes.string.isRequired,
    isAmendmentBudget: PropTypes.bool.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired
};