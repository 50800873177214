import React, {useState} from "react";
import PropTypes from "prop-types";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../constants/AuditConstants";
import {inputStyles} from "../../constants/inputConstants";
import {RadioButtonYesNoList} from "../../DisputeResolution/drConstants";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../common/commonUtilities";
import RadioField from "../common/inputs/RadioField";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../common/NotifyUser";

const AuditHistoryOutcomeCommentForm = ({
                                            comment,
                                            handleCommentChange,
                                            labelForVisibility = `Visible to the district`,
                                            onCommentSave,
                                            onCommentCancel,
                                            parentId,
                                            isLoading
                                        }) => {
    const [commentError, setCommentError] = useState("");
    const [isVisibleToDistrictError, setIsVisibleToDistrictError] = useState("");

    const isFormValid = () => {
        let hasErrors = false;
        let commentErrorCheck = "";
        let isVisibleToDistrictErrorCheck = "";
        if (isTrimmedStringEmpty(comment.text)) {
            commentErrorCheck = "Required.";
            hasErrors = true;
        }

        if (isNullOrUndefined(comment.isVisibleToDistrict)) {
            isVisibleToDistrictErrorCheck = "Required.";
            hasErrors = true;
        }

        setCommentError(commentErrorCheck);
        setIsVisibleToDistrictError(isVisibleToDistrictErrorCheck);
        if (hasErrors)
            NotifyUser.Warning("Missing required items.  Please review and try submitting again.");

        return !hasErrors;
    };

    const handleSaveComment = (event, comment, parentId) => {
        event.preventDefault();

        if (isFormValid())
            onCommentSave(event, comment, parentId);
    };


    return (
        <li>
            <TextAreaEditor
                error={commentError}
                label={`Comment`}
                name={"tbEditComment_" + comment.auditCommentId}
                onChange={(event) => handleCommentChange(event, comment, parentId)}
                showLabel
                value={comment.text}
            />
            <RadioField
                direction={inputStyles.radio.direction.HORIZONTAL}
                error={isVisibleToDistrictError}
                legend={labelForVisibility}
                name={IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME}
                showLegend
                options={RadioButtonYesNoList}
                onChange={(event) => handleCommentChange(event, comment, parentId)}
                value={comment.isVisibleToDistrict}
            />
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name={"btnEditSave_" + comment.auditCommentId}
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={(event) => handleSaveComment(event, comment, parentId)}
                        btnClass="button-context"
                        disabled={isLoading}/>

                <Button name={"btnCancel_" + comment.auditCommentId}
                        label="Cancel"
                        onClick={onCommentCancel}
                        disabled={isLoading}
                        buttonType={ButtonTypes.CANCEL}/>
            </ButtonBar>
        </li>
    );
};

AuditHistoryOutcomeCommentForm.propTypes = {
    comment: PropTypes.object.isRequired,
    handleCommentChange: PropTypes.func,
    labelForVisibility: PropTypes.string,
    onCommentSave: PropTypes.func,
    onCommentCancel: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    parentId: PropTypes.string.isRequired
};

export default AuditHistoryOutcomeCommentForm;