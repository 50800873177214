import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const GmsManageDiscretionaryProjectsTableRowDisplay = ({
                                                                  discretionaryProject,
                                                                  handleDeleteProjectClick,
                                                                  handleModifyProjectClick,
                                                                  handleViewProjectClick,
                                                                  rowDisabled
                                                              }) => {
    return (
        <tr>
            <td>{discretionaryProject.projectName}</td>
            <td className={`action-no-wrap`}>
                <Button
                    buttonType={ButtonTypes.EDIT}
                    disabled={rowDisabled}
                    label={`Modify discretionary project for ${discretionaryProject.projectName}`}
                    name={`btnModifyProject_${discretionaryProject.discretionaryProjectId}`}
                    onClick={(event) => handleModifyProjectClick(event, discretionaryProject)}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.DELETE}
                    disabled={rowDisabled}
                    label={`Delete discretionary project for ${discretionaryProject.projectName}`}
                    name={`btnDeleteProject_${discretionaryProject.discretionaryProjectId}`}
                    onClick={(event) => handleDeleteProjectClick(event, discretionaryProject.discretionaryProjectId)}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.VIEW}
                    disabled={rowDisabled}
                    label={`View discretionary project details for ${discretionaryProject.projectName}`}
                    name={`btnViewProject_${discretionaryProject.discretionaryProjectId}`}
                    onClick={(event) => handleViewProjectClick(event, discretionaryProject.discretionaryProjectId)}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

GmsManageDiscretionaryProjectsTableRowDisplay.propTypes = {
    discretionaryProject: PropTypes.object,
    handleDeleteProjectClick: PropTypes.func.isRequired,
    handleModifyProjectClick: PropTypes.func.isRequired,
    handleViewProjectClick: PropTypes.func.isRequired,
    rowDisabled: PropTypes.bool.isRequired,
};