import React from "react";
import PropTypes from "prop-types";
import Allow from "../authorization/Allow";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import {ADD_GOAL} from "../../constants/policyEvents";
import ActionBar from "../common/buttons/ActionBar";

const StrategyGoal = ({
	                      goals,
	                      onEditGoals,
	                      isEditing,
	                      isLoading,
	                      filters
                      }) => {
	return (
		<section className={"strategy__goal"}>
			<header>
				<h3>FDOE Strategic Goals</h3>
			</header>
			{
				!isEditing &&
				filters.showButtons &&
				<Allow policy={StrategicPlanPolicy} policyEvent={ADD_GOAL}>
					<ActionBar
						elementId={"strategy__goal"}
						onEditClick={onEditGoals}
						disabled={isLoading}
					/>
				</Allow>
			}
			{
				goals.map(goal => {
					return (<div key={goal.text}>{goal.text}</div>);
				})
			}
		</section>
	);
};

StrategyGoal.propTypes = {
	goals: PropTypes.array.isRequired,
	onEditGoals: PropTypes.func,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	filters: PropTypes.object
};

export default StrategyGoal;