import React, {useState} from "react";
import PropTypes from "prop-types";
import {emptyGuid} from "../../../constants/config";
import api from "../drDueProcessApi";
import DrDueProcessActionView from "./DrDueProcessActionView";

const DrDueProcessActionContainer = ({
	                                     canAudit,
	                                     canEdit,
										 canReview,
										 canSubmit,
	                                     executeApi,
	                                     dueProcess,
	                                     handleDataChanged,
	                                     handleUpdateDueProcess,
	                                     isDistrictUser
                                     }) => {

	const [editActionId, setEditActionId] = useState('');
	const [editResponseId, setEditResponseId] = useState('');
	const canEditAction = canEdit && !isDistrictUser && dueProcess.isActionEditable;
	const canRespond = canEdit && isDistrictUser;
	const canStateReview = canReview && !isDistrictUser;

	const handleSaveActionAsync = async (action) => {
		action.dueProcessId = dueProcess.id;
		return await executeApi(api.saveDueProcessAction, [action])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			});
	};

	const handleSaveResponseAsync = async (response) => {
		return await executeApi(api.saveResponse, [response, dueProcess.id])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			});
	};

	const handleDeleteAction = (action) => {
		if (action && confirm("Are you sure you want to delete this action? By doing so, it will remove all responses and audits associated with this action.\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
			executeApi(api.deleteDueProcessAction, [dueProcess.id, action.id])
				.then((result) => {
					handleUpdateDueProcess(result);
				});
		}
	};

	const handleSubmitResponseAsync = async (response, auditAction) => {
		return await executeApi(api.auditResponse, [response, dueProcess.id, auditAction])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleAuditResponseAsync = async (response, auditAction) => {
		return await executeApi(api.auditResponse, [response, dueProcess.id, auditAction])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleSaveResponseReviewAsync = async (review, actionId) => {
		return await executeApi(api.saveResponseReview, [review, dueProcess.id, actionId])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleRemoveResponseReviewAsync = async (actionId, responseId) => {
		if (!confirm("Are you sure you want to remove this review? \n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
			return;

		return await executeApi(api.removeResponseReview, [dueProcess.id, actionId, responseId])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const saveAuditCommentAsync = async (responseId, actionId, comment, existing = false) => {
		return await executeApi(api.saveAuditComment, [responseId, actionId, dueProcess.id, comment, existing])
			.then((result) => {
				handleUpdateDueProcess(result);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleSaveAuditCommentAsync = async (responseId, actionId, comment) => {
		if (comment.auditCommentId === "") {
			const auditCommentId = emptyGuid;
			comment = Object.assign({}, comment, {auditCommentId});

			return await saveAuditCommentAsync(responseId, actionId, comment);
		}
		else {
			return await saveAuditCommentAsync(responseId, actionId, comment, true);
		}
	};

	if (!dueProcess) return null;

	return <DrDueProcessActionView
		actionModels={dueProcess.actions}
		canAudit={canAudit}
		canEditAction={canEditAction}
		canRespond={canRespond}
		canStateReview={canStateReview}
		canSubmit={canSubmit}
		editActionId={editActionId}
		editResponseId={editResponseId}
		handleAuditResponseAsync={handleAuditResponseAsync}
		handleDeleteAction={handleDeleteAction}
		handleRemoveResponseReviewAsync={handleRemoveResponseReviewAsync}
		handleSaveActionAsync={handleSaveActionAsync}
		handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
		handleSaveResponseAsync={handleSaveResponseAsync}
		handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
		handleSubmitResponseAsync={handleSubmitResponseAsync}
		handleDataChanged={handleDataChanged}
		isEditMode={!!editActionId}
		isDistrictUser={isDistrictUser}
		setEditActionId={setEditActionId}
		setEditResponseId={setEditResponseId}
	/>;
};

DrDueProcessActionContainer.propTypes = {
	canAudit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	canReview: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	dueProcess: PropTypes.object.isRequired,
	executeApi: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleUpdateDueProcess: PropTypes.func.isRequired,
	isDistrictUser: PropTypes.bool.isRequired
};

export default DrDueProcessActionContainer;
