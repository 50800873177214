import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as SurveyAdminActions from "../../actions/surveyAdminActions";
import * as LayoutActions from "../../actions/layoutActions";
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import {catchError} from "../../actions/actionUtility";
import { getParams } from "../../components/layout/getParams";
import * as surveyLocations from "../../constants/surveys/surveyLocations";

class ManageSurveyReportsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onResponsesReportClick = this.onResponsesReportClick.bind(this);
		this.onProgressReportClick = this.onProgressReportClick.bind(this);
		this.onDiscretionaryProjectReportClick = this.onDiscretionaryProjectReportClick.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.MANAGE_REPORTS);

        if(params !== null)
        	this.setState({params});

		this.props.actions.tryLoadSurveyDetails(params.surveyInstanceId)
			.then(() => {
				this.props.actions.updatePageTitle(this.props.surveyDetails.title + " - Reports");
			})
			.catch(error => catchError(error));
	}

	onResponsesReportClick() {
		this.props.actions.loadSurveyResponsesReport(this.state.params.surveyInstanceId);
	}

	onProgressReportClick() {
		this.props.actions.loadSurveyProgressReport(this.state.params.surveyInstanceId);
	}

	onDiscretionaryProjectReportClick() {
		this.props.actions.loadSurveyDiscretionaryProjectReport(this.state.params.surveyInstanceId);
	}

	render() {
		return (
			<TileContainer>
				<Tile
					onClick={this.onResponsesReportClick}
					body={"Response Report"}
				/>
				<Tile
					onClick={this.onProgressReportClick}
					body={"Progress Report"}
				/>
				<Tile
					onClick={this.onDiscretionaryProjectReportClick}
					body={"Discretionary Project Report"}
				/>
			</TileContainer>
		);
	}
}

ManageSurveyReportsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	surveyDetails: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		surveyDetails: state.surveyAdmin.selectedSurveyInstance,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, LayoutActions, SurveyAdminActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyReportsPage);