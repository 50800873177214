import SurveyApi from "../api/SurveyApi";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";


//SURVEY ACTION CREATORS
export function loadSurveysByRespondentIdSuccess(data) {
	return {type: actionTypes.LOAD_SURVEYS_BY_RESPONDENT_ID_AJAX_SUCCESS, data};
}

export function loadSurveysByRespondentId(respondentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyApi.getSurveysByRespondentId(respondentId)
			.then(data => {
				dispatch(loadSurveysByRespondentIdSuccess(data));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadSurveyByIdSuccess(data) {
	return {type: actionTypes.LOAD_SURVEY_BY_ID_AJAX_SUCCESS, data};
}

export function tryLoadSurveyById(surveyId, surveyRespondentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyApi.getSurveyById(surveyId, surveyRespondentId)
			.then(data => {
				dispatch(loadSurveyByIdSuccess(data));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function loadSurveyElementsForTakeSurveySuccess(data) {
	return {type: actionTypes.LOAD_SURVEY_ELEMENTS_FOR_TAKESURVEY_AJAX_SUCCESS, data};
}

export function tryLoadSurveyElementsForTakeSurvey(instanceId, surveyRespondentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyApi.getSurveyElementsForTakeSurvey(instanceId, surveyRespondentId)
			.then(data => {
				dispatch(loadSurveyElementsForTakeSurveySuccess(data));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw error;
			});
	};
}

export function saveRespondentSurveyElementsSuccess() {
	return {type: actionTypes.SAVE_RESPONDENT_SURVEY_ELEMENTS_AJAX_SUCCESS};
}

export function trySaveRespondentSurveyElements(surveyElements, componentHistory) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyApi.saveRespondentSurveyElements(surveyElements, componentHistory)
			.then(() => {
				dispatch(saveRespondentSurveyElementsSuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw error;
			});
	};
}

export function submitRespondentSurveySuccess() {
	return {type: actionTypes.SUBMIT_RESPONDENT_SURVEY_AJAX_SUCCESS};
}

export function trySubmitRespondentSurvey(surveyElements, componentHistory, surveyId, dateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyApi.submitRespondentSurvey(surveyElements, componentHistory, surveyId, dateWindowId)
			.then(() => {
				dispatch(submitRespondentSurveySuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw error;
			});
	};
}

export function changeElementResponse(element) {
	return {type: actionTypes.CHANGE_SURVEY_ELEMENT_RESPONSE, data: element};
}

export function appendViewedComponentHistory(elementId) {
	return {type: actionTypes.APPEND_VIEWED_COMPONENT_HISTORY, data: elementId};
}

export function goToStep(step) {
	return {type: actionTypes.GO_TO_STEP, data: step};
}

export function clearSurveyData() {
	return {type: actionTypes.CLEAR_SURVEY_DATA};
}