import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";

export const DrDistrictSummaryMediation = ({
									   handleClickDistrict,
	                                   handleClickOrder,
	                                   summary
                                   }) => {
	return (
		<GridTable>
			<thead>
			<tr>
				<th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
					District
				</th>
				<th onClick={handleClickOrder} data-id="mediationOpenCount" className="is-clickable center">
					Total Open
				</th>
				<th onClick={handleClickOrder} data-id="mediationClosedCount" className="is-clickable center">
					Total Closed
				</th>
				<th onClick={handleClickOrder} data-id="mediationWithdrawnCount" className="is-clickable center">
					Total Withdrawn
				</th>
				<th onClick={handleClickOrder} data-id="mediationOverallCount" className="is-clickable center">
					Overall Total
				</th>
			</tr>
			</thead>
			<tbody>
			{
				isArrayNullOrEmpty(summary) &&
				<tr>
					<td colSpan={5} className={"center"}>
						No districts match the search criteria specified.
					</td>
				</tr>
			}
			{
				summary.map((district, key) =>
					(<tr
						key={key}
						onClick={(event) => handleClickDistrict(event, district.districtName)}
						className={"is-clickable"}
					>
						<td>{district.districtName}</td>
						<td className={"center"}>{district.mediationOpenCount}</td>
						<td className={"center"}>{district.mediationClosedCount}</td>
						<td className={"center"}>{district.mediationWithdrawnCount}</td>
						<td className={"center"}>{district.mediationOverallCount}</td>
					</tr>)
				)
			}
			</tbody>
		</GridTable>
	);
};

DrDistrictSummaryMediation.propTypes = {
	handleClickDistrict: PropTypes.func.isRequired,
	handleClickOrder: PropTypes.func.isRequired,
	summary: PropTypes.array
};

export default DrDistrictSummaryMediation;

export const MemoizedDrDistrictSummaryMediation = React.memo(DrDistrictSummaryMediation);
