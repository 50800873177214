import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import GridTable from "../../../components/common/GridTable";
import {ReadMore} from "../../../components/common/ReadMore";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";

const DrAdminIntakeRequirementView = ({
                                          handleDeleteIntakeRequirement,
                                          handleOrder,
                                          hasResponses,
                                          intakeRequirements,
                                          setEditRequirementId,
                                          setViewVersionId
                                      }) => {
    const onAddEditClick = (event, requirementId) => {
        event.preventDefault();
        setEditRequirementId(requirementId);
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        setViewVersionId(null);
    };

    const onSortClick = (event) => {
        event.preventDefault();
        handleOrder();
    };

    const onDeleteClick = (event, requirementId) => {
        event.preventDefault();
        handleDeleteIntakeRequirement(requirementId);
    };

    const hasRelatedConditionalOptionalRequirements = (conditionalId) => {
        return intakeRequirements.filter(f => f.conditionalParentId === conditionalId).length > 0;
    };

    return (
        <>
            <ButtonBar position={ButtonBarPositions.STICKY}>
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={`Return to Intake Versions`}
                    name={`btnReturnIntakeVersions`}
                    onClick={onCancelClick}
                    showLabel
                />
                <Button
                    disabled={intakeRequirements.length === 0}
                    label={`Order Requirements`}
                    name={`btnOrderRequirements`}
                    onClick={onSortClick}
                    showLabel
                />
                <Button
                    buttonType={ButtonTypes.ADD}
                    label={`Add Intake Requirement`}
                    name={`btnAddIntakeRequirement`}
                    onClick={(event) => onAddEditClick(event, emptyGuid)}
                    showLabel
                />
            </ButtonBar>
            <GridTable className={`intake-requirements-table`}>
                <thead>
                <tr>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Order</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    intakeRequirements.map((requirement, key) =>
                        (<tr key={key}>
                            <td><ReadMore text={requirement.requirementDescription} /></td>
                            <td className={`typeOutput`}>{requirement.requirementTypeOutput}</td>
                            <td className={"text-center"}>{requirement.displayOrder}</td>
                            <td className={"text-center actions"}>
                                <Button
                                    buttonType={ButtonTypes.EDIT}
                                    label={`Edit Intake Requirement for ${requirement.displayOrder}`}
                                    name={`btnEdit_${requirement.requirementId}`}
                                    onClick={(event) => onAddEditClick(event, requirement.requirementId)}
                                    showLabel={false}
                                />
                                {
                                    !hasResponses &&
                                    !hasRelatedConditionalOptionalRequirements(requirement.requirementId) &&
                                    <Button
                                        buttonType={ButtonTypes.DELETE}
                                        label={`Delete Intake Requirement for ${requirement.displayOrder}`}
                                        name={`btnDelete_${requirement.requirementId}`}
                                        onClick={(event) => onDeleteClick(event, requirement.requirementId)}
                                        showLabel={false}
                                    />
                                }
                            </td>
                        </tr>)
                    )
                }
                {
                    intakeRequirements.length === 0 &&
                    <tr>
                        <td className={`text-center`} colSpan={4}><a href={`#`} onClick={(event) => onAddEditClick(event, emptyGuid)}>Add an intake requirement</a></td>
                    </tr>
                }
                </tbody>
            </GridTable>
        </>
    );
};

DrAdminIntakeRequirementView.propTypes = {
    handleDeleteIntakeRequirement: PropTypes.func.isRequired,
    handleOrder: PropTypes.func.isRequired,
    hasResponses: PropTypes.bool.isRequired,
    intakeRequirements: PropTypes.array.isRequired,
    setEditRequirementId: PropTypes.func.isRequired,
    setViewVersionId: PropTypes.func.isRequired
};

export default DrAdminIntakeRequirementView;