import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import DateTimePickerField from "../../components/common/inputs/DateTimePickerField";
import TextAreaEditor from "../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../common/NotifyUser";
import {createReminderObject} from "./notificationObjectFactory";
import FormTable from "../../components/common/FormTable";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../common/inputs/inputUtility";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const ReminderForm = ({
                          handleReturn,
                          reminder,
                          saveNotification
                      }) => {
    const reminderForm = useFormInputObject(createReminderObject(reminder));

    const isValidReminder = () => {
        clearInputFormErrorFields(reminderForm);

        if (!reminderForm.remindDate.value)
            reminderForm.remindDate.setError("Required");

        if (!reminderForm.reminderNotes.value)
            reminderForm.reminderNotes.setError("Required");

        let isValid = inputFormIsValid(reminderForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const handleClickSave = async (event) => {
        event.preventDefault();

        if (!isValidReminder())
            return;

        const form = convertFormInputObjectToObject(reminderForm);

        await saveNotification(form);

        handleReturn(true);
    };

    const handleClickCancel = (event) => {
        event.preventDefault();

        handleReturn();
    }

    return (
        <>
            <FormTable>
                <DateTimePickerField
                    label="Send yourself a reminder notification on"
                    showLabel
                    {...reminderForm.remindDate}
                />
                <TextAreaEditor
                    label={`Reminder Notes`}
                    showLabel
                    {...reminderForm.reminderNotes}
                />
            </FormTable>

            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name="btnSave"
                        label={`Save`}
                        buttonType={ButtonTypes.SAVE}
                        onClick={handleClickSave}
                />
                <Button name="btnCancel"
                        label={`Cancel`}
                        buttonType={ButtonTypes.CANCEL}
                        onClick={handleClickCancel}
                />
            </ButtonBar>
        </>
    );
};

ReminderForm.propTypes = {
    handleReturn: PropTypes.func.isRequired,
    reminder: PropTypes.object,
    saveNotification: PropTypes.func.isRequired
};

export default ReminderForm;