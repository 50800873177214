import toastr from "toastr";
import toastrOptions from "../../constants/toastrOptions";

export class NotifyUser {
	static clear() {
		toastr.clear();
	}
	static Error(message, title="", props = {}) {
		return toastr.error(message, title, {...toastrOptions.Error, ...props});
	}
	static ServerError(message, title="", props = {}) {
		return toastr.error(message, `Server Error ${title}`, {...toastrOptions.ServerError, ...props});
	}
	static Success(message, title="", props = {}) {
		return toastr.success(message, title, {...toastrOptions.Success, ...props});
	}
	static Info(message, title="", props = {}) {
		return toastr.info(message, title, {...toastrOptions.Info, ...props});
	}
	static Warning(message, title="", props = {}) {
		return toastr.warning(message, title, {...toastrOptions.Warning, ...props});
	}
}