import React from 'react';
import PropTypes from 'prop-types';
import * as textPositions from "../../constants/progressBarTextPositions";

const ProgressBar = ({
                         currentStep,
                         fullTextToDisplay,
                         progressTextPosition,
                         progressTextPrefix,
                         progressTextSuffix,
                         totalSteps,
                         wrapperClass
                     }) => {
    const widthPercentage = ((currentStep / totalSteps) * 100) + '%';

    const textToDisplay = fullTextToDisplay ? fullTextToDisplay : progressTextPrefix + " " + currentStep + " of " + totalSteps + " " + progressTextSuffix;
    const progressText = (<p className="progress-meter-text">{textToDisplay}</p>);

    return (
        <div>
            {progressTextPosition === textPositions.AboveBar && progressText}

            <div className={`progress ${wrapperClass}`}
                 role="progressbar"
                 tabIndex="0"
                 aria-valuenow={currentStep}
                 aria-valuemin={1}
                 aria-valuemax={totalSteps}
                 aria-valuetext={textToDisplay}>

                {progressTextPosition === textPositions.InsideBar && progressText}

                <div className="progress-meter"
                     style={{"width": widthPercentage}}/>
            </div>

            {progressTextPosition === textPositions.BelowBar && progressText}
        </div>
    );
};

ProgressBar.propTypes = {
    currentStep: PropTypes.number.isRequired,
    fullTextToDisplay: PropTypes.string,
    progressTextPosition: PropTypes.string.isRequired,
    progressTextPrefix: PropTypes.string,
    progressTextSuffix: PropTypes.string,
    totalSteps: PropTypes.number.isRequired,
    wrapperClass: PropTypes.string
};

ProgressBar.defaultProps = {
    fullTextToDisplay: "",
    progressTextPosition: textPositions.BelowBar,
    progressTextPrefix: "",
    progressTextSuffix: "",
    wrapperClass: ""
};

export default ProgressBar;