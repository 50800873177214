import PropTypes from "prop-types";
import React from "react";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";

export const McNavigation = ({ currentLocation, dateWindowId, history }) => {
    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (currentLocation.startsWith(location)) return;

        history.push(location);
    };

    const districtSummaryUrl = dateWindowId
        ? ComplianceLocations.LANDING_DOE_DATE_WINDOW.path.replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
        : ComplianceLocations.LANDING_DOE.path;
    const mcSummaryUrl = ComplianceLocations.MC_SUMMARY.path;
    const mcNotificationSettingsUrl = ComplianceLocations.MC_NOTIFICATION_SETTINGS.path;

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`mcNavigation`}>
            <Button
                btnClass={currentLocation.startsWith(mcSummaryUrl) ? "is-active" : ""}
                label={`My Summary`}
                name={`btnMcSummary`}
                onClick={(event) => navButtonClick(event, mcSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(districtSummaryUrl) ? "is-active" : ""}
                label={`District Summary`}
                name={`btnDistrictSummary`}
                onClick={(event) => navButtonClick(event, districtSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(mcNotificationSettingsUrl) ? "is-active" : ""}
                label={`Notification Settings`}
                name={`btnNotificationSettings`}
                onClick={(event) => navButtonClick(event, mcNotificationSettingsUrl)}
            />
        </ButtonBar>
    );
};

McNavigation.propTypes = {
    currentLocation: PropTypes.string,
    dateWindowId: PropTypes.string,
    history: PropTypes.object
};
