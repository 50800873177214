import React from "react";
import PropTypes from "prop-types";
import Button from "../buttons/Button";
import ButtonMock from "../buttons/ButtonMock";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as textPositions from "../../../constants/progressBarTextPositions";
import ProgressBar from "../ProgressBar";
import ButtonBar from "../buttons/ButtonBar";

const BackNextPager = ({
                           backText,
                           centerText,
                           nextText,
                           back,
                           center,
                           next,
                           currentPage,
                           totalPages,
                           disableBack,
                           disableCenter,
                           disableNext,
                           isBottom,
                           isProgress,
                           save,
                           saveText,
                           disableSave,
                           wrapperClass = "",
                       }) => {

    return <>
        <ButtonBar position={isBottom ? ButtonBarPositions.STICKY_BOTTOM : ButtonBarPositions.STICKY}
                   className={`buttonBar--fullWidth align-middle ${wrapperClass}`}>
            {
                back &&
                <div className="buttonBar__buttonContainer">
                    <Button onClick={back}
                            name={"btnBack"}
                            label={backText}
                            disabled={disableBack}
                            buttonType={ButtonTypes.BACK}
                    />
                </div>
            }
            {
                currentPage && totalPages && !isProgress &&
                <div className="buttonBar__buttonContainer">
                    <ButtonMock label={currentPage + " of " + totalPages}/>
                </div>
            }
            {
                currentPage && totalPages && isProgress &&
                <div className="buttonBar__buttonContainer">
                    <ProgressBar currentStep={currentPage}
                                 totalSteps={totalPages}
                                 progressTextPosition={textPositions.InsideBar}
                                 progressTextPrefix={"Page"}/>
                </div>
            }
            {
                center &&
                <div className="buttonBar__buttonContainer">
                    <Button onClick={center}
                            name={"btnCenter"}
                            label={centerText}
                            disabled={disableCenter}
                            buttonType={ButtonTypes.SAVE}
                    />
                </div>
            }
            {
                save &&
                <div className="buttonBar__buttonContainer">
                    <Button onClick={save}
                            label={saveText}
                            name={"btnSave"}
                            disabled={disableSave}
                            buttonType={ButtonTypes.SAVE}
                    />
                </div>
            }
            {
                next &&
                <div className="buttonBar__buttonContainer">
                    <Button onClick={next}
                            label={nextText}
                            name={"btnNext"}
                            disabled={disableNext}
                            buttonType={ButtonTypes.FORWARD}
                    />
                </div>
            }
        </ButtonBar>
    </>;
};

BackNextPager.propTypes = {
    backText: PropTypes.string,
    centerText: PropTypes.string,
    nextText: PropTypes.string,
    back: PropTypes.func,
    center: PropTypes.func,
    next: PropTypes.func,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    disableBack: PropTypes.bool,
    disableCenter: PropTypes.bool,
    disableNext: PropTypes.bool,
    isBottom: PropTypes.bool,
    isProgress: PropTypes.bool,
    save: PropTypes.func,
    saveText: PropTypes.string,
    disableSave: PropTypes.bool,
    wrapperClass: PropTypes.string,
};

BackNextPager.defaultProps = {
    backText: "Back",
    centerText: "Save",
    nextText: "Next",
    disableBack: false,
    disableCenter: false,
    disableNext: false,
    disableSave: false,
    isBottom: false,
    isProgress: false,
};

export default BackNextPager;