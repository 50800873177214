import React from "react";
import PropTypes from "prop-types";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

export const AuditHistoryOutcomeResponse = ({response}) => {
    if(!response) return null;

    return (
        <div className={`textReadOnly`} aria-label={"Response"}>
            <HtmlDisplayBlock html={response}/>
        </div>
    );
};

AuditHistoryOutcomeResponse.propTypes = {
    response: PropTypes.string
};