import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import DrStateComplaintsApi from "../StateComplaints/drStateComplaintsApi";
import * as drLocations from "../drLocations";

const DrDistrictStateComplaint = ({
	                                  actions,
	                                  districtId,
	                                  dateWindowId,
	                                  handleClickView
                                  }) => {

	const [complaints, setComplaints] = useState([]);

	const tileContainerTitle = (complaints.length === 0) ? "No state complaints are available at this time" : "State Complaints";

	const getComplaints = () => {
		const tileUrl = drLocations.STATE_COMPLAINT_TILE_DISTRICT;
		actions.executeApi(DrStateComplaintsApi.getDistrictComplaintsByDate, [districtId, tileUrl, dateWindowId])
			.then((result) => {
				setComplaints(result);
			});

	};

	useEffect(() => {
		if (districtId && dateWindowId)
			getComplaints();
	}, [districtId, dateWindowId]);

	return (
		<TileContainer title={tileContainerTitle}>
			{
				complaints.map((tile, i) => {
					return (<Tile key={i}
					              header={tile.header}
					              body={tile.body}
					              footer={tile.footer}
					              isNew={tile.isNew}
					              onClick={() => handleClickView(tile.url)}
					/>);
				})
			}

		</TileContainer>
	);
};

DrDistrictStateComplaint.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string,
	districtId: PropTypes.string,
	handleClickView: PropTypes.func.isRequired,
};

export default DrDistrictStateComplaint;