import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import PrintTable from "../../../components/common/PrintTable";
import api from "../drMediationsApi";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const FederalReportPrintView = ({
	                                actions,
	                                dateWindowId,
	                                dateWindows
                                }) => {
	const [federalReport, setFederalReport] = useState(null);

	const handleUpdateFederalReport = (federalReport) => {
		setFederalReport(federalReport);
	};

	const getFederalReport = async () => {
		await actions.executeApi(api.getFederalReport, [dateWindowId])
			.then((results) => handleUpdateFederalReport(results));
	};

	useEffect(() => {
		getFederalReport();
	}, []);

	if (!federalReport)
		return null;

	return (
		<>
			<div className={"report-header"}>
				<h2>{fetchDate(dateWindows, dateWindowId)} Mediations – IDEA Part B Dispute Resolution Report</h2>
				<h2>Statewide Report</h2>
			</div>
			<PrintTable className={"table-print-center add-padding"}>
				<thead>
				<tr>
					<th>District</th>
					<th>(2) Total number of mediation requests received through all dispute resolution processes</th>
					<th>(2.1) Mediations held</th>
					<th>(a) Mediations held related to due process complaints</th>
					<th>(i) Mediation agreements related to due process complaints</th>
					<th>(b) Mediations held not related to due process complaints</th>
					<th>(i) Mediation agreements not related to due process complaints</th>
					<th>(2.2) Mediations pending</th>
					<th>(2.3) Mediations withdrawn or not held</th>
				</tr>
				</thead>
				<tbody>
				{
					federalReport.map((district, index) => {
						return (
							<tr key={index}>
								<td>{district.districtName}</td>
								<td className={"text-center"}>{district.totalMediationRequests}</td>
								<td className={"text-center"}>{district.totalMediationsHeld}</td>
								<td className={"text-center"}>{district.totalHeldRelatedToDueProcessComplaints}</td>
								<td className={"text-center"}>{district.totalAgreementsRelatedToDueProcessComplaints}</td>
								<td className={"text-center"}>{district.totalHeldNotRelatedToDueProcessComplaints}</td>
								<td className={"text-center"}>{district.totalAgreementsNotRelatedToDueProcessComplaints}</td>
								<td className={"text-center"}>{district.totalPending}</td>
								<td className={"text-center"}>{district.totalWithdrawnDismissed}</td>
							</tr>
						);
					})
				}
				</tbody>
			</PrintTable>
		</>
	);
};

FederalReportPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	dateWindows: PropTypes.array.isRequired
};

export default FederalReportPrintView;