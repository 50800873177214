import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {
    createListItem,
    dynamicSort,
    isArrayNullOrEmpty,
    isNullOrUndefined
} from "../../../../components/common/commonUtilities";
import {emptyGuid} from "../../../../constants/config";
import regionAdminApi from "./regionAdminApi";
import RegionEdit from "./RegionEdit";
import {createDistrictsList, createRegionsList} from "./regionObjectFactory";
import RegionView from "./RegionView";

const RegionContainer = ({
                             actions,
                             districts,
                             handleDataChanged
                         }) => {
    const [allActiveInactiveDistricts, setAllActiveInactiveDistricts] = useState(null);
    const [regions, setRegions] = useState(null);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [regionIdToEdit, setRegionIdToEdit] = useState(null);

    const handleSaveDistrictsForRegion = async (selectedDistrictIds) => {
         await actions.executeApi(regionAdminApi.SaveDistrictsForRegion, [{districtIds: selectedDistrictIds, regionId: selectedRegion.id}])
             .then(async () => {
                 handleDataChanged(false);
                 await loadRegions();
             });
    };

    const handleSaveRegion = async (regionName, regionId) => {
         await actions.executeApi(regionAdminApi.SaveRegion, [{regionName, regionId}])
             .then((results) => {
                 buildRegionsList(results, selectedRegion, regionName);
                 handleCancelRegionEdit();
             });
    };

    const handleCancelRegionEdit = () => {
        setRegionIdToEdit(null);
        handleDataChanged(false);
    };

    const deleteRegion = async (regionId) => {
        await actions.executeApi(regionAdminApi.DeleteRegion, [regionId])
            .then((results) => {
                buildRegionsList(results);
                handleDataChanged(false);
            });
    };

    const buildRegionsList = (regionsData, currentSelectedRegion = null, regionToSet = null) => {
        const regionsList = createRegionsList(regionsData);
        setRegions(regionsList);

        if(isNullOrUndefined(currentSelectedRegion)) {
            setSelectedRegion( regionsList.length > 0 ? regionsList[0] : null);
        }
        else if(!isNullOrUndefined(regionToSet)) {
            const region = regionsList.find(f => f.text === regionToSet);
            if(!isNullOrUndefined(region))
                setSelectedRegion(region);
        }
    };

    const loadRegions = async () => {
        await actions.executeApi(regionAdminApi.getRegions, [])
            .then((results) => {
                buildRegionsList(results, selectedRegion);
            });
    }

    const createActiveAndInactiveDistrictsList = () => {
        const nonActiveDistricts = selectedRegion.districtIds.map((districtId) => {
            const districtExists = districts.some(f => f.id === districtId);
            if(districtExists)
                return;

            return {
                ...createListItem(districtId, "Missing"),
                isDisabled: true
            };
        });

        const allDistricts = [...nonActiveDistricts.filter(f => !isNullOrUndefined(f)), ...districts];

        setAllActiveInactiveDistricts(allDistricts.sort(dynamicSort("text")));
    };

    const getUnassociatedDistricts = () => {
        let allSelectedDistrictIds = [];
        for(let region of regions)
            allSelectedDistrictIds.push(...region.districtIds);

        return districts.filter(f => !allSelectedDistrictIds.includes(f.id)).sort(dynamicSort("text"));
    }

    const handleChangeRegion = (regionId) => {
        const region = regions.find(f => f.id === regionId);
        setSelectedRegion(region);
    };

    useEffect(() => {

        loadRegions();
        actions.updatePageTitle("Manage Regions");

    }, []);

    useEffect(() => {
        if(isArrayNullOrEmpty(districts) || isNullOrUndefined(selectedRegion))
            return;

        createActiveAndInactiveDistrictsList();
    }, [districts, selectedRegion]);

    if(!regions || !allActiveInactiveDistricts)
        return null;

    return (
        <>
            {
                isNullOrUndefined(regionIdToEdit) &&
                <RegionView
                    allActiveInactiveDistricts={createDistrictsList(allActiveInactiveDistricts)}
                    availableDistricts={getUnassociatedDistricts()}
                    deleteRegion={deleteRegion}
                    handleChangeRegion={handleChangeRegion}
                    handleDataChanged={handleDataChanged}
                    handleSaveDistrictsForRegion={handleSaveDistrictsForRegion}
                    selectedDistrictIds={selectedRegion?.districtIds}
                    selectedRegionId={selectedRegion?.id}
                    setRegionIdToEdit={setRegionIdToEdit}
                    regions={regions}
                />
            }
            {
                !isNullOrUndefined(regionIdToEdit) &&
                <RegionEdit
                    handleCancelRegionEdit={handleCancelRegionEdit}
                    handleDataChanged={handleDataChanged}
                    handleSaveRegion={handleSaveRegion}
                    regionId={regionIdToEdit}
                    regionName={regionIdToEdit === emptyGuid ? `` : selectedRegion.text}
                    regions={regions}
                />
            }
        </>
    );
}

RegionContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    districts: PropTypes.array,
    handleDataChanged: PropTypes.func.isRequired
};

export default RegionContainer;