import React from 'react';
import PropTypes from 'prop-types';
import RadioField from "../common/inputs/RadioField";
import {MonitoringCompliancePolicy, policyEvents} from "../authorization/policies/MonitoringCompliancePolicy";
import ActionBar from "../common/buttons/ActionBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../common/buttons/ButtonBar";
import DisplayField from "../common/inputs/DisplayField";
import * as AuthorizationUtilities from "../authorization/AuthorizationUtilities";

const Classification = ({
	                        isEditing,
	                        isNew,
	                        classification,
	                        onClassificationChange,
	                        onDeleteClick,
	                        onEditClick,
	                        isLoading,
	                        onSaveClick,
	                        onCancelClick
                        }) => {
	const options = [{text: "Tier 1", value: "Tier 1"}, {text: "Tier 2", value: "Tier 2"}, {
		text: "Tier 3",
		value: "Tier 3"
	}];
	const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, policyEvents.monitoring_compliance.assessment.MODIFY);
	isEditing = isEditing && canEdit;
	return (
		<div className={"compliance__requiredProtocol__classification"}>
			{
				!isEditing && canEdit &&
				<ActionBar
					elementId={"abClassification"}
					onDeleteClick={onDeleteClick}
					onEditClick={onEditClick}
					disabled={isLoading}
				/>
			}
			<div>
				{
					isEditing &&
					<RadioField
						legend="District Type:"
						name={"rfClassification"}
						onChange={onClassificationChange}
						options={options}
						value={classification}
					/>
				}
				{
					!isEditing &&
					<DisplayField
						name="dfClassification"
						label="District Type:"
						showLabel
					>
						{classification}
					</DisplayField>
				}
				{
					!isNew &&
					isEditing &&
					<ButtonBar position={ButtonBarPositions.BOTTOM}>
						<Button name={"saveClassification"}
						        label={"Save"}
						        onClick={onSaveClick}
						        buttonType={ButtonTypes.SAVE}
						        diabled={isLoading}/>
						<Button name={"cancelClassification"}
						        label={"Cancel"}
						        onClick={onCancelClick}
						        buttonType={ButtonTypes.CANCEL}
						        diabled={isLoading}/>
					</ButtonBar>
				}
			</div>
		</div>
	);
};

Classification.propTypes = {
	isEditing: PropTypes.bool.isRequired,
	isNew: PropTypes.bool.isRequired,
	classification: PropTypes.string.isRequired,
	onClassificationChange: PropTypes.func.isRequired,
	onSaveClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	onDeleteClick: PropTypes.func,
	onEditClick: PropTypes.func,
	isLoading: PropTypes.bool.isRequired
};

export default Classification;