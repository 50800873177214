import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {getGeneralProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import {getDistinctIds, isNumber} from "../../components/common/commonUtilities";
import * as correctionResultConstants from "../../constants/monitoringCompliance/correctionResults";
import {updateCheckboxListArray} from "../../components/common/inputs/inputUtility";
import {StudentCorrectionsView} from "./DistrictStudentCorrections/StudentCorrectionsView";
import {MatrixCorrectionsPage} from "./MatrixCorrectionsPage";

export class CorrectionsPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 1,
        };

        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.onSave = this.onSave.bind(this);
        this.mainPage = this.mainPage.bind(this);
        this.onChangeStudentCorrectionResult = this.onChangeStudentCorrectionResult.bind(this);
        this.onChangeMatrixCorrectionResult = this.onChangeMatrixCorrectionResult.bind(this);
        this.getBackButtonText = this.getBackButtonText.bind(this);
        this.getNextButtonText = this.getNextButtonText.bind(this);
        this.getRecordId = this.getRecordId.bind(this);
        this.calculateTotalPages = this.calculateTotalPages.bind(this);
    }

    componentDidMount() {
        if(isNumber(this.props.page))
            this.setState({currentStep: parseInt(this.props.page)});

        this.validateCorrectionsData();
    }

    componentDidUpdate() {
        this.validateCorrectionsData();
    }

    validateCorrectionsData() {
        const {selectedAssessment, history} = this.props;
        if (!selectedAssessment) return true;

        const generalAssessmentStatus = getGeneralProtocolRequirementStatus(selectedAssessment);

        if (!generalAssessmentStatus.assessmentApproved ||
            !selectedAssessment.anyProtocolsNotMeetingCompliance) {
            history.push(ComplianceLocations.LANDING_DISTRICT.path);
            return false;
        }

        return true;
    }

    clearOutNotCorrectedFields(protocolCorrectiveResultClone) {
        protocolCorrectiveResultClone.dateCorrected = "";
        protocolCorrectiveResultClone.corrections = [];
    }

    onChangeStudentCorrectionResult(event, studentProtocolCorrectiveResultId) {
        this.onChangeCorrectionResult(event,
            studentProtocolCorrectiveResultId,
            this.props.studentProtocolCorrectiveResults,
            "studentProtocolCorrectiveResultId",
            correctionResultConstants.studentCorrectionOptions,
            correctionResultConstants.studentCorrections,
            "changeStudentCorrectionResult");
    }

    onChangeMatrixCorrectionResult(event, matrixProtocolCorrectiveResultId) {
        this.onChangeCorrectionResult(event,
            matrixProtocolCorrectiveResultId,
            this.props.matrixProtocolCorrectiveResults,
            "matrixProtocolCorrectiveResultId",
            correctionResultConstants.matrixCorrectionOptions,
            correctionResultConstants.matrixCorrections,
            "changeMatrixCorrectionResult");
    }

    onChangeCorrectionResult(event, protocolCorrectiveResultId, protocolCorrectiveResults, id, correctionOptions, correctionsProperties, changeAction) {
        this.props.handleDataChanged();

        let protocolCorrectiveResult = protocolCorrectiveResults.find(f => f[id] === protocolCorrectiveResultId);

        if(protocolCorrectiveResult !== undefined) {
            let protocolCorrectiveResultClone = Object.assign({}, protocolCorrectiveResult);
            const nameArray = event.target.name.split("_");

            if(event.target.value === "acknowledgeNeedsRevisionComplete") {
                protocolCorrectiveResultClone.acknowledgeNeedsRevisionComplete = event.target.checked;
            }
            else if(event.target.type === "checkbox") {
                const name = nameArray[0];
                const allOptions = correctionOptions.map(option => option.value);
                protocolCorrectiveResultClone[name] = updateCheckboxListArray(event.target.value, [...protocolCorrectiveResultClone[name]], allOptions);
            }
            else if(nameArray.length === 3 ) {
                const propertyName = nameArray[0];
                const columnName = nameArray[1];
                const otherTextCheckValue = correctionsProperties[propertyName];

                let currentResults = [...protocolCorrectiveResultClone[columnName]];
                let results = currentResults.map(result => {
                    if(result.startsWith(otherTextCheckValue))
                        return `${otherTextCheckValue}${correctionResultConstants.correctionOtherTextSeparator}${event.target.value}`;
                    else
                        return result;
                });

                protocolCorrectiveResultClone[columnName] = results;
            }
            else
                protocolCorrectiveResultClone[event.target.id] = event.target.value;

            if(protocolCorrectiveResultClone.corrected === correctionResultConstants.corrected.No)
                this.clearOutNotCorrectedFields(protocolCorrectiveResultClone);


            this.props.actions[changeAction](protocolCorrectiveResultId, protocolCorrectiveResultClone);
        }
    }

    onSave(event, save) {
        event.preventDefault();

        save(this.state.currentStep, this);
    }

    mainPage(event, save) {
        event.preventDefault();
        save(0, this);
    }

    next(event, save) {
        event.preventDefault();

        const totalSteps = this.calculateTotalPages();

        if (this.state.currentStep === totalSteps)
            save(0, this);
        else
            save(this.state.currentStep + 1, this);
    }

    back(event, save) {
        event.preventDefault();

        if (this.state.currentStep === 1)
            save(0, this);
        else
            save(this.state.currentStep - 1, this);
    }

    getBackButtonText(curStep) {
        return (curStep === 1) ? "Main Page" : "Back";
    }

    getNextButtonText(curStep, totalSteps) {
        return (curStep === totalSteps) ? "Main Page" : "Next";
    }

    getDistinctRecordIds() {
        return this.props.isMatrix
            ? getDistinctIds(this.props.matrixProtocolCorrectiveResults, "studentRecordId")
            : getDistinctIds(this.props.studentProtocolCorrectiveResults, "studentRecordId");
    }

    calculateTotalPages() {
        return this.getDistinctRecordIds().length;
    }

    getRecordId() {
        let recordIds = this.getDistinctRecordIds();
        let currentRecordId = this.state.currentStep - 1;

        return recordIds[currentRecordId];
    }

    render() {
        if(!this.props.dateWindowId) return null;

        return (
            <>
                {
                    this.props.isMatrix &&
                    <MatrixCorrectionsPage
                        actions={this.props.actions}
                        matrixProtocolCorrectiveResults={this.props.matrixProtocolCorrectiveResults}
                        isLoading={this.props.isLoading}
                        location={this.props.location}
                        history={this.props.history}
                        currentStep={this.state.currentStep}
                        back={this.back}
                        calculateTotalPages={this.calculateTotalPages}
                        getBackButtonText={this.getBackButtonText}
                        getNextButtonText={this.getNextButtonText}
                        getMatrixRecordId={this.getRecordId}
                        mainPage={this.mainPage}
                        next={this.next}
                        onChangeMatrixCorrectionResult={this.onChangeMatrixCorrectionResult}
                        onSave={this.onSave}
                    />
                }
                {
                    !this.props.isMatrix &&
                    <StudentCorrectionsView
                        actions={this.props.actions}
                        assessmentId={this.props.assessmentId}
                        dateWindowId={this.props.dateWindowId}
                        handleDataChanged={this.props.handleDataChanged}
                        handleScroll={this.props.handleScroll}
                        history={this.props.history}
                        isLoading={this.props.isLoading}
                        onChangeStudentCorrectionResult={this.onChangeStudentCorrectionResult}
                        protocolId={this.props.protocolId}
                        studentProtocolCorrectiveResults={this.props.studentProtocolCorrectiveResults}
                        studentRecordId={this.props.studentRecordId}
                    />
                }
            </>
        );
    }
}

CorrectionsPage.defaultProps = {
    isMatrix: false
};

CorrectionsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    dateWindowId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMatrix: PropTypes.bool,
    location: PropTypes.object.isRequired,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    page: PropTypes.string,
    protocolId: PropTypes.string,
    selectedAssessment: PropTypes.object,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    studentRecordId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    return {
        studentProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.studentProtocolCorrectiveResults,
        matrixProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.matrixProtocolCorrectiveResults
    };
}

export default connect(mapStateToProps)(CorrectionsPage);