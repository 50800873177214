import React from "react";
import PropTypes from "prop-types";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import Allow from "../authorization/Allow";
import ActionBar from "../common/buttons/ActionBar";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";

const ProgressMonitoringActivity = ({activity, isLoading, isEditing, onEditActivity, onDeleteActivity, filters}) => {
	return (
		<section className={"strategy__progressActivity "} id={activity.id}>
			{
				!isEditing &&
                filters.showButtons &&
				<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_PROGRESS_MONITORING}>
					<ActionBar
						elementId={activity.id}
						onEditClick={onEditActivity}
						onDeleteClick={onDeleteActivity}
						disabled={isLoading}
					/>
				</Allow>

			}

			<p>
				<em>{outputStandardDateFormat(activity.dateNoteCreated, false)}</em><br />
				{activity.text}
			</p>
		</section>
	);
};

ProgressMonitoringActivity.propTypes = {
	activity: PropTypes.object.isRequired,
    filters: PropTypes.object,
	isLoading: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	onEditActivity: PropTypes.func,
	onDeleteActivity: PropTypes.func
};

export default ProgressMonitoringActivity;