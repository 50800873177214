import React from 'react';
import PropTypes from "prop-types";
import GridRow from "../../../components/common/GridRow";
import HtmlDisplayInline from "../../../components/htmlDisplay/HtmlDisplayInline";
import {gridConstants} from "../../../constants/inputConstants";
import GridColumn from "../../../components/common/GridColumn";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import TextField from "../../../components/common/inputs/TextField";
import {createSchoolAdmin} from "./bpieSchoolAdminFactory";
import {
    convertFormInputObjectToObject,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import bpieApi from "../bpieApi";

const BpieSchoolAdministratorForm = ({
                                         actions,
                                         handleCancelClick,
                                         handleReloadSchoolAdministrator,
                                         isReplacement,
                                         schoolAdmin,
                                         schoolBpieId
                                     }) => {

    const responseForm = useFormInputObject(createSchoolAdmin(schoolAdmin || {}));

    const onSaveClick = async (event) => {
        event.preventDefault();
        const hasAdministrator = !isTrimmedStringEmpty(schoolAdmin?.email);

        const replaceAdminWarning = hasAdministrator && isReplacement ? ` The current school administrator will no longer have access to the SBPIE Assessment.` : ``;
        const addUpdateMessage = hasAdministrator ? `update the current` : `add the new`;
        const save = confirm(`Are you sure you want ${addUpdateMessage} school administrator?${replaceAdminWarning}`);
        if (!save)
            return;

        const updatedAdmin = convertFormInputObjectToObject(responseForm);
        updatedAdmin.currentEmail = schoolAdmin.email;
        updatedAdmin.isReplacement = isReplacement;

        actions.executeApi(bpieApi.saveSchoolAdministrator, [schoolBpieId, updatedAdmin])
            .then(async () => {
                await handleReloadSchoolAdministrator();
            });
    }

    const onCancelClick = (event) => {
        event.preventDefault();
        const cancel = confirm('Are you sure you want to cancel?');
        if (!cancel)
            return;

        handleCancelClick();
    }

    if (!schoolAdmin)
        return null;

    const updateSchoolAdminDirections = isReplacement
        ? `DO NOT USE THIS IF YOU ARE HAVING A DIFFERENT USER INPUT THE DATA. USE EDIT TO TEMPORARILY CHANGE THE EMAIL!<br/><br/>
            Replace is for changing the current administrator to a new person. If you are only needing to modify
                    the current school administrator&apos;s information, please use the &ldquo;Edit&rdquo; feature.`
        : `If you are having a different user input the data, only change the email address and not the name. Please remember to change it back after submitting the SBPIE.<br/><br/>
            Editing is for making changes to the current administrator&apos;s account only. If this is a new
                    school administrator, use the &ldquo;Replace&rdquo; feature.`;

    return (
        <>
            <p className={`page-directions-highlight`}>
                <HtmlDisplayInline html={updateSchoolAdminDirections} />
            </p>
            <GridRow medium={gridConstants.column.FOUR} rowClass={"summary-school-admin--form"}>
                <GridColumn>
                    <TextField
                        {...responseForm.firstName}
                        label="First Name"
                        placeholder="FirstName"
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <TextField
                        {...responseForm.lastName}
                        label="Last Name"
                        placeholder="LastName"
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <TextField
                        {...responseForm.email}
                        label="Email"
                        placeholder="Email"
                        showLabel
                    />
                </GridColumn>
                <GridColumn>
                    <Button name={"save"}
                            label={"Save"}
                            showLabel={false}
                            buttonType={ButtonTypes.SAVE}
                            onClick={onSaveClick}
                    />
                    <Button name={"cancel"}
                            label={"Cancel"}
                            showLabel={false}
                            buttonType={ButtonTypes.CANCEL}
                            onClick={onCancelClick}
                    />
                </GridColumn>
            </GridRow>
        </>);
}

BpieSchoolAdministratorForm.propTypes = {
    actions: PropTypes.object.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
    handleReloadSchoolAdministrator: PropTypes.func.isRequired,
    isReplacement: PropTypes.bool.isRequired,
    schoolAdmin: PropTypes.object.isRequired,
    schoolBpieId: PropTypes.string.isRequired
};

export default BpieSchoolAdministratorForm;


