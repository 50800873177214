import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy, policyEvents} from "../../../../components/authorization/policies/GmsPolicy";
import gmsApi from "../../gmsApi";
import * as gmsLocations from "../../gmsLocations";
import {getApplicationFormFileId} from "../../gmsUtilities";
import GmsApplicationOverviewElement from "./GmsApplicationOverviewElement";
import HtmlDisplay from "../../../../components/htmlDisplay/HtmlDisplay";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import {FORM_TYPE, getResponseStatusType} from "../../gmsConstants";
import SubmissionModule from "../../../../components/common/SubmissionModule";
import FileUploadButton from '../../../../components/common/inputs/FileUploadButton';
import DownloadUtility from '../../../../api/requests/DownloadUtility';
import {isNullOrUndefined} from '../../../../components/common/commonUtilities';

const GmsApplicationOverviewContainer = ({
                                             actions,
                                             applicationId,
                                             currentUserFiscalAgentId,
                                             getApplicationHistoryData,
                                             history,
                                             isFiscalAgentUser,
                                         }) => {
    const [application, setApplication] = useState(null);

    const handlePrint = () => {
        const resolvedPath = gmsLocations.GMS_APPLICATION_PRINT_SETUP.getUrl(applicationId);
        history.push(resolvedPath);
    };
    const handleCreateAmendment = () => {
        const resolvedPath = gmsLocations.GMS_APPLICATION_AMENDMENT_SETUP.getUrl(applicationId);
        history.push(resolvedPath);
    };

    const createApplicationRow = (path, sectionTitle, status) => {
        return <tr className="is-clickable" key={sectionTitle} onClick={() => history.push(path)}>
            <td>{sectionTitle}</td>
            <td className={`action-no-wrap`}>{getResponseStatusType(status)}</td>
        </tr>;
    };

    const initializeData = async () => {
        await actions.executeApi(gmsApi.getApplication, [applicationId])
            .then((result) => {
                const isFiscalAgentUserViewingIncorrectFiscalAgent = isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId.toLowerCase();
                if (!result.canViewApplication || isFiscalAgentUserViewingIncorrectFiscalAgent) {
                    const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                    history.push(resolvedPath);
                    return;
                }
                setApplication(result);
            });
    };

    useEffect(() => {
        initializeData();
    }, []);

    const handleDownloadSignedDoe200Click = () => {
        const signedDoe200FileId = getApplicationFormFileId(application.applicationForms, FORM_TYPE.doe200);
        actions.executeApi(gmsApi.downloadSignedFormWithFileId, [signedDoe200FileId, FORM_TYPE.doe200]);
    };

    const handleUploadSignedDoe200Click = () => {
        const uploadDoe100 = document.getElementById("uploadDoe200");
        uploadDoe100.click();
    };

    const handleFileUploadSignedDoe200Change = (event) => {
        const file = [...event.target.files];
        actions.executeApi(gmsApi.uploadSignedForm, [file, applicationId, FORM_TYPE.doe200])
            .then(() => {
                initializeData();
                DownloadUtility.clearFileUploadSelection("uploadDoe200");
            });
    };

    const handleSubmit = async () => {
        if (!confirm("Are you sure you want to submit the application?"))
            return;

        return await actions.executeApi(gmsApi.submitApplication, [applicationId])
            .then(async () => {
                await getApplicationHistoryData();
                await initializeData()
            });
    };

    const createSubmissionModule = (canUserUploadSignedDoe200) => {
        const signedDoe200FileId = getApplicationFormFileId(application.applicationForms, FORM_TYPE.doe200);
        const isSignedDoe200Uploaded = !isNullOrUndefined(signedDoe200FileId);
        const downloadDoe200Label = `Download Signed DOE 200 Form`;
        const canOnlyDownloadDoe200 = isSignedDoe200Uploaded && !canUserUploadSignedDoe200;
        const allowedToCreateAmendment = allow(GmsPolicy, policyEvents.APPLICATION.createAmendment);

        if ((application.canCreateAmendment || canOnlyDownloadDoe200) && application.isAmendmentSubmitted) {
            return {
                handlePrimarySubmit: handleCreateAmendment,
                hidePrimarySubmissionButton: !application.canCreateAmendment || !allowedToCreateAmendment,
                primaryButtonLabel: `Create Amendment`,
                primaryDisabled: !application.canCreateAmendment,
                secondaryDownloadButtonLabel: downloadDoe200Label,
                hideSecondaryDownloadButton: !isSignedDoe200Uploaded,
                handleSecondaryDownload: handleDownloadSignedDoe200Click,
                submissionTitle: application.canCreateAmendment ? "Create Amendment" : "DOE 200"
            };
        }

        if (canUserUploadSignedDoe200) {
            return {
                handlePrimarySubmit: handleUploadSignedDoe200Click,
                hidePrimarySubmissionButton: false,
                primaryButtonLabel: isSignedDoe200Uploaded ? `Replace Signed DOE 200 Form` : `Upload Signed DOE 200 Form`,
                primaryDisabled: false,
                secondaryDownloadButtonLabel: downloadDoe200Label,
                hideSecondaryDownloadButton: !isSignedDoe200Uploaded,
                handleSecondaryDownload: handleDownloadSignedDoe200Click,
                submissionTitle: "DOE 200",
            };
        }

        return {
            handlePrimarySubmit: handleSubmit,
            hidePrimarySubmissionButton: application.hideAbilityToSubmit || !canSubmit,
            primaryButtonLabel: `Submit Application`,
            primaryDisabled: application.isSubmissionDisabled,
            submissionTitle: canSubmit ? "Verify and Submit Application" : ""
        };
    }


    useEffect(() => {
        if (application && application.applicationTitle)
            actions.updatePageTitle(application.applicationTitle);
    }, [application]);

    if (!application || !applicationId) return null;

    const canSubmit = allow(GmsPolicy, policyEvents.APPLICATION.submit);
    const canUserUploadSignedDoe200 = application.canUploadSignedDoe200 && allow(GmsPolicy, policyEvents.APPLICATION.uploadDoe200);
    const submissionModuleInfo = createSubmissionModule(canUserUploadSignedDoe200, handleUploadSignedDoe200Click);

    return (
        <>
            <HtmlDisplay html={application.instructions || ""}/>
            <SubmissionModule
                handlePrimarySubmit={submissionModuleInfo.handlePrimarySubmit}
                handleSecondarySubmit={handlePrint}
                handleSecondaryDownload={submissionModuleInfo.handleSecondaryDownload}
                hidePrimarySubmissionButton={submissionModuleInfo.hidePrimarySubmissionButton}
                hideSecondaryDownloadButton={submissionModuleInfo.hideSecondaryDownloadButton}
                name={"submitApplication"}
                primaryButtonLabel={submissionModuleInfo.primaryButtonLabel}
                primaryDisabled={submissionModuleInfo.primaryDisabled}
                secondaryButtonLabel={`Application Reports`}
                secondaryDownloadButtonLabel={submissionModuleInfo.secondaryDownloadButtonLabel}
                submissionMessage={canSubmit ? application.submissionMessage : ""}
                submissionTitle={submissionModuleInfo.submissionTitle}
            />
            {
                canUserUploadSignedDoe200 &&
                <FileUploadButton
                    className={`is-hidden`}
                    label={"Upload DOE 200"}
                    name={"uploadDoe200"}
                    onChange={handleFileUploadSignedDoe200Change}
                />
            }
            {
                application.elements &&
                <GmsApplicationOverviewElement applicationId={applicationId} budgets={application.budgets}
                                               createApplicationRow={createApplicationRow}
                                               elements={application.elements}/>
            }
            <GmsApplicationNavigation history={history} applicationId={applicationId}/>
        </>
    );
};


GmsApplicationOverviewContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    getApplicationHistoryData: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired
};

export default GmsApplicationOverviewContainer;