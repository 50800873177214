import React from 'react';
import PropTypes from "prop-types";
import BpiePrioritiesView from "../../Assessment/AssessmentPriorities/BpiePrioritiesView";
import bpieDistrictApi from "../bpieDistrictApi";

const DistrictAssessmentPrioritiesContainer = ({
                                                   actions,
                                                   dataChanged,
                                                   districtBpie,
                                                   handleAssessmentRedirect,
                                                   handleDataChanged,
                                                   handleSummaryRedirect,
                                                   isDistrictUser,
                                                   isEditable,
                                                   loadDistrictBpie
                                               }) => {
    const savePriorityIndicators = (priorityBpieIndicatorIds) => {
        actions.executeApi(bpieDistrictApi.saveDistrictBpieIndicatorPriorities, [districtBpie.districtId, districtBpie.districtBpieId, priorityBpieIndicatorIds])
            .then(() => {
                loadDistrictBpie();
            });
    }

    if (!districtBpie)
        return null;

    return <BpiePrioritiesView
        canEdit={isEditable && !isDistrictUser}
        dataChanged={dataChanged}
        handleDataChanged={handleDataChanged}
        indicators={districtBpie.indicators}
        initialResponses={districtBpie.responses}
        isBpieActive={isEditable}
        onEditAssessment={handleAssessmentRedirect}
        pageTitle={`District Priority Indicators`}
        returnToSummary={handleSummaryRedirect}
        savePriorityIndicators={savePriorityIndicators}
    />;
}

DistrictAssessmentPrioritiesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool,
    districtBpie: PropTypes.object.isRequired,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSummaryRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    loadDistrictBpie: PropTypes.func.isRequired
}

export default DistrictAssessmentPrioritiesContainer;
