import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../api/requests/RequestTypes";
import * as config from "../../../../constants/config";
import {API_MESSAGES} from "../../gmsConstants";
import {API_ROOT} from "../../gmsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsManageDiscretionaryProjectsApi {
    static getDiscretionaryProjects() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects`,
                RequestTypes.GET)
        );
    }

    static getDiscretionaryProject(discretionaryProjectId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects/${discretionaryProjectId}`,
                RequestTypes.GET)
        );
    }

    static deleteDiscretionaryProject(discretionaryProjectId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects/${discretionaryProjectId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.deleted)
        );
    }

    static saveDiscretionaryProject(discretionaryProjectId, project) {
        const isNewDiscretionaryProject = discretionaryProjectId === config.emptyGuid;
        const apiUrlSuffix = isNewDiscretionaryProject ? `` : `/${discretionaryProjectId}`;
        const requestType = isNewDiscretionaryProject ? RequestTypes.POST : RequestTypes.PUT;

        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects${apiUrlSuffix}`,
                requestType,
                JSON.stringify(project),
                API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.saved)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsManageDiscretionaryProjectsApi {
    static getDiscretionaryProjects() {
        return mockResolveApiCall([]);
    }

    static getDiscretionaryProject() {
        return mockResolveApiCall({});
    }

    static deleteDiscretionaryProject() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.deleted);
    }

    static saveDiscretionaryProject() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.saved);
    }
}

const GmsManageDiscretionaryProjectsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsManageDiscretionaryProjectsApi : MockGmsManageDiscretionaryProjectsApi;
export default GmsManageDiscretionaryProjectsApi;