import React from 'react';
import PropTypes from 'prop-types';
import ButtonMock from "./ButtonMock";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ActionBarPosition from "../../../constants/ActionBarPositions";

const ActionBarWrapper = ({
	                          position,
	                          isExpanded,
	                          buttonType,
	                          buttonLabel,
	                          disabled,
	                          children
                          }) => {
	let expanded = isExpanded ? " actionBar--expanded " : "";

	return (
		<span className={"actionBar " + position + expanded}>
            <div className="dropdown" tabIndex={"0"}>
	            <ButtonMock
		            buttonType={buttonType}
		            label={buttonLabel}
		            showLabel={false}
		            disabled={disabled}
	            />
	            {
		            children &&
		            <div className="dropdown__content" >
			            {children}
		            </div>
	            }
            </div>
        </span>
	);
};

ActionBarWrapper.propTypes = {
	position: PropTypes.string,
	isExpanded: PropTypes.bool,
	disabled: PropTypes.bool,
	buttonType: PropTypes.string,
	buttonLabel: PropTypes.string,
	children: PropTypes.any.isRequired //eslint-disable-line react/forbid-prop-types
};

ActionBarWrapper.defaultProps = {
	position: ActionBarPosition.RIGHT,
	buttonType: ButtonTypes.CONFIGURE,
	buttonLabel: "Configure",
	isExpanded: false,
	disabled: false
};

export default ActionBarWrapper;