import React, {useEffect} from "react";
import PropTypes from "prop-types";
import AccountUtility from "../../api/AccountUtility";
import Button from "../../components/common/buttons/Button";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import * as LayoutActions from "../../actions/layoutActions";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as drLocations from "../drLocations";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DrAdminSections} from "./drAdminConstants";

const DrAdminWrapper = ({
                            actions,
                            Component,
                            currentLocation,
                            history,
                            props
                        }) => {
    const teamMembersUrl = drLocations.DR_ADMIN_TEAM_MEMBERS.getUrl();
    const intakeUrl = drLocations.DR_ADMIN_INTAKE.getUrl();

    const handleSelectSection = (event, section) => {
        let url = "";
        switch (section) {
            case DrAdminSections.TeamMembers:
                url = teamMembersUrl;
                break;
            case DrAdminSections.Intake:
                url = intakeUrl;
                break;
        }

        if (url)
            navButtonClick(event, url);
    };

    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (location === currentLocation) return;

        history.push(location);
    };

    useEffect(() => {
        actions.updatePageTitle(`DR Admin`);
    }, []);

    if(!AccountUtility.isStateUserDrAuthorized())
        return null;

    return (
        <GridRow isPageNav>
            <GridColumn
                isPageNavLinks
                isStickyLinks
                large="3"
            >
                <Button
                    btnClass={currentLocation === teamMembersUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, teamMembersUrl)}
                    label={DrAdminSections.TeamMembers}
                    name={teamMembersUrl}
                />

                <Button
                    btnClass={currentLocation === intakeUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, intakeUrl)}
                    label={DrAdminSections.Intake}
                    name={intakeUrl}
                />
            </GridColumn>

            <GridColumn
                isPageNavContent
                large="9"
            >
                <Component
                    {...props}
                    actions={actions}
                    handleSelectSection={handleSelectSection}
                    history={history}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;

    return {
        Component,
        currentLocation,
        props
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrAdminWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    history: PropTypes.object,
    props: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrAdminWrapper);