import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {
    buildStudentRecordsNeedingCorrectionsList
} from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {StudentSummaryGrid} from "../../../components/MonitoringCompliance/StudentCorrections/StudentSummaryGrid";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";
import {StudentCorrectionsProtocolSummaryView} from "./StudentCorrectionsProtocolSummaryView";

export const StudentCorrectionsSummaryView = ({
                                                  actions,
                                                  assessmentId,
                                                  dateWindowId,
                                                  history,
                                                  isLoading,
                                                  selectedAssessment,
                                                  studentProtocolCorrectiveResults,
                                                  studentRecordId
                                              }) => {

    const [editStudentRecordId, setEditStudentRecordId] = useState(null);

    const validateCorrectionsData = () => {
        if (!selectedAssessment) return true;

        let indicatorRequirement = selectedAssessment.indicatorRequirements.find(f => f.assessmentApproved);

        if (!indicatorRequirement ||
            !selectedAssessment.anyProtocolsNotMeetingCompliance) {
            history.push(ComplianceLocations.LANDING_DISTRICT.path);
            return false;
        }

        return true;
    };

    const editStudentCorrections = (studentRecordId) => {
        setEditStudentRecordId(studentRecordId);
    };

    const clearEditStudentCorrections = () => {
        setEditStudentRecordId(null);
        if(studentRecordId)
            history.push(ComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path
                .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId));
    }

    useEffect(() => {
        const isValid = validateCorrectionsData();

        if (isValid) {
            if (MonitoringComplianceUtilities.isValidId(assessmentId)) {
                actions.loadComplianceWithStudentCorrectiveResults(assessmentId);
            }
        }
    }, [assessmentId]);

    useEffect(() => {
        if (!editStudentRecordId)
            actions.updatePageTitle("Student Protocol Corrections Summary");
    }, [editStudentRecordId])

    useEffect(() => {
        if(studentRecordId) {
            setEditStudentRecordId(studentRecordId);
        }
    }, [])

    const studentRecords = buildStudentRecordsNeedingCorrectionsList(studentProtocolCorrectiveResults, selectedAssessment.indicatorRequirements);
    const studentRecord = editStudentRecordId ? studentRecords.find(f => f.studentRecordId === editStudentRecordId) : null;

    return (
        <section className={"compliance__studentCorrectionResultsSummary"}>
            {
                !editStudentRecordId &&
                <StudentSummaryGrid
                    selectStudentCorrections={editStudentCorrections}
                    studentRecords={studentRecords}
                />
            }
            {
                editStudentRecordId &&
                studentRecord &&
                <StudentCorrectionsProtocolSummaryView
                    actions={actions}
                    assessmentId={assessmentId}
                    clearEditStudentCorrections={clearEditStudentCorrections}
                    dateWindowId={dateWindowId}
                    history={history}
                    isLoading={isLoading}
                    studentRecord={studentRecord}
                />
            }
            {
                editStudentRecordId &&
                !studentRecord &&
                <p>Student record not found.</p>
            }
        </section>
    );
};


StudentCorrectionsSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedAssessment: PropTypes.object,
    studentProtocolCorrectiveResults: PropTypes.array,
    studentRecordId: PropTypes.string
};
