import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import SppElementSet from "../../components/spp/SppElementSet";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import * as sppActions from "../../actions/sppActions";
import * as fileActions from "../../actions/fileActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import SppElement from "../../components/spp/SppElement";
import * as elementTypes from "../../constants/spp/sppElementTypes";
import * as sppUtilities from "../../components/spp/SppUtilities";
import * as publicLocations from "../../constants/publicLocations";
import {NotifyUser} from "../../components/common/NotifyUser";
import { animateScroll as scroll } from "react-scroll";
import {getParams} from "../../components/layout/getParams";

export class SppPublicSectionPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 1,
			isTitleSet: false,
			params: {}
		};

		this.back = this.back.bind(this);
		this.next = this.next.bind(this);
		this.fileOnView = this.fileOnView.bind(this);
		this.getNextButtonText = this.getNextButtonText.bind(this);
		this.getBackButtonText = this.getBackButtonText.bind(this);
		this.getPageResponses = this.getPageResponses.bind(this);
		this.documentHierarchy = this.documentHierarchy.bind(this);
		this.totalSteps = this.totalSteps.bind(this);
		this.documentPage = this.documentPage.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, publicLocations.SPP_SECTION);

        if(params !== null)
            this.setState({params});

		this.props.actions.loadPolicyCompliance(params.documentId, params.institutionId)
			.catch(error => NotifyUser.Error(error));

		this.props.actions.loadSppDocument(params.documentId)
			.catch(error => NotifyUser.Error(error));

		this.props.actions.loadSppDocumentElements(params.documentId)
			.catch(error => NotifyUser.Error(error));

		this.props.actions.loadInstitutionResponses(params.institutionId, params.documentId)
			.catch(error => NotifyUser.Error(error));
	}

	componentDidUpdate(){
        if (!this.state.isTitleSet &&
            this.props.dateWindows.length > 0 &&
            this.props.document.id !== undefined &&
	        this.props.document.componentsHierarchy !== undefined &&
	        this.props.elements.length > 0 &&
            this.props.policyCompliance.id !== undefined &&
            this.props.institutions.length > 0) {
        	const pageTitle = sppUtilities.generatePageTitle(
                this.props.dateWindows,
                null,
                this.props.document,
                this.props.institutions,
                this.state.params.institutionId
            );

            this.props.actions.updatePageTitle(pageTitle);

	        const section = this.props.elements.filter(el => el.id === this.state.params.sectionId)[0];

	        if (section && section.text)
		        this.props.actions.updatePageSubTitle(section.text);

	        this.setState({
                isTitleSet: true
            });
        }

	}

	componentWillUnmount() {
		this.props.actions.clearSubTitle();
	}

	getNextButtonText(curStep, totalSteps) {
		return (curStep === totalSteps) ? "Overview" : "Next";
	}

	getBackButtonText(curStep) {
		return (curStep === 1) ? "Overview" : "Back";
	}

	fileOnView(event, fileId) {
		event.preventDefault();
		this.props.actions.downloadFile(fileId);
	}

	back(event) {
		event.preventDefault();

		if (this.state.currentStep === 1)
			this.props.history.push(this.documentPage());
		else {
			this.setState({
				currentStep: this.state.currentStep - 1
			});
			scroll.scrollToTop();
		}
	}

	next(event) {
		event.preventDefault();

		if (this.state.currentStep === this.totalSteps())
			this.props.history.push(this.documentPage());
		else {
			this.setState({
				currentStep: this.state.currentStep + 1
			});
			scroll.scrollToTop();
		}
	}

	documentPage() {
		return this.props.history.push(publicLocations.SPP_DOCUMENT.path
			.replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
			.replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId)
		);
	}

	getPageResponses() {
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, this.documentHierarchy());

		const currentElementSetHierarchy = sectionHierarchy.childElements[this.state.currentStep - 1].childElements;

		const elements = currentElementSetHierarchy.map(
			elementHierarchy => {
				return this.props.elements.filter(el => el.id === elementHierarchy.elementId)[0];
			}).filter(el => el.layout !== elementTypes.Display);

		return elements.map(el => {
			return this.props.responses.filter(response => response.elementId === el.id)[0];
		});
	}

	documentHierarchy() {
		return JSON.parse(this.props.document.componentsHierarchy);
	}

	totalSteps() {
        const params = getParams(this.props.location.pathname, publicLocations.SPP_SECTION);

		const documentHierarchy = this.documentHierarchy();
        const sectionHierarchy = sppUtilities.getSectionHierarchy(params.sectionId, documentHierarchy);
		return sectionHierarchy.childElements.length;
	}

	render() {
		if (this.props.dateWindows.length === 0 ||
			this.props.document.componentsHierarchy === undefined ||
			this.props.elements.length === 0 ||
			this.props.responses.length === 0) return null;

		const documentHierarchy = this.documentHierarchy();
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, documentHierarchy);
		const totalSteps = this.totalSteps();

		const sectionElements = sppUtilities.getSectionElements(this.state.params.sectionId, documentHierarchy, this.props.elements, true);
		const sectionInputElements = sppUtilities.getSectionElements(this.state.params.sectionId, documentHierarchy, this.props.elements, false);

		const responses = sppUtilities.getElementResponses(sectionInputElements, this.props.responses);

		//get the current element set's hierarchy children
		const elementListForCurrentPage = sectionHierarchy ? sectionHierarchy.childElements[this.state.currentStep - 1].childElements : [];

		return (
			<div>

				<SppElementSet>
					{
						elementListForCurrentPage.map(childElement => {
							const element = sectionElements.filter(el => el.id === childElement.elementId)[0];
							const response = responses.filter(r => r.elementId === childElement.elementId)[0];
							return (<SppElement key={element.id}
							                    element={element}
							                    response={response}
							                    fileOnView={(event) => this.fileOnView(event, response.data)}
							/>);
						})
					}
				</SppElementSet>

				<BackNextPager next={this.next}
				               back={this.back}
				               backText={this.getBackButtonText(this.state.currentStep)}
				               nextText={this.getNextButtonText(this.state.currentStep, totalSteps)}
				               currentPage={this.state.currentStep}
				               totalPages={totalSteps}
				               isBottom
				               disableBack={this.props.isLoading}
				               disableNext={this.props.isLoading}
				/>
			</div>
		);
	}
}

SppPublicSectionPage.propTypes = {
	actions: PropTypes.object.isRequired,
	document: PropTypes.object.isRequired,
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	responses: PropTypes.arrayOf(PropTypes.object),
	policyCompliance: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		document: state.spp.selectedDocument,
		elements: state.spp.documentElements,
		responses: state.spp.documentResponses,
		policyCompliance: state.spp.selectedPolicyCompliance,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sppActions,
		fileActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppPublicSectionPage);