import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import AccountUtility from "../../../api/AccountUtility";
import NotificationsView from "../../../components/Notifications/NotificationsView";
import {emptyGuid} from "../../../constants/config";
import mcNotificationsApi from "./McNotificationsApi";

export const McNotificationsContainer = ({
                                             actions,
                                             assessmentId
                                         }) => {
    const [notifications, setNotifications] = useState(null);

    const getNotifications = async () => {
        await actions.executeApi(mcNotificationsApi.getMcUserNotifications, [assessmentId])
            .then((result) => setNotifications(result));
    };

    const deleteNotification = async (reminderId) => {
        await actions.executeApi(mcNotificationsApi.removeDrReminder, [assessmentId, reminderId]);

        await getNotifications();
    };

    const saveNotification = async (form) => {
        await actions.executeApi(mcNotificationsApi.saveMcReminder, [assessmentId, form.reminderId, form.remindDate, form.reminderNotes]);
    }

    useEffect(() => {
        const isDistrictUser = AccountUtility.isDistrictUser();
        if(isDistrictUser || assessmentId === emptyGuid) return;

        getNotifications();
    }, [assessmentId]);

    return <NotificationsView
        deleteNotification={deleteNotification}
        getNotifications={getNotifications}
        notifications={notifications}
        saveNotification={saveNotification}
    />;
};

McNotificationsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired
};

export default McNotificationsContainer;
