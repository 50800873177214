import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    Chart,
    ChartCategoryAxis,
    ChartCategoryAxisItem, ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem
} from "@progress/kendo-react-charts";
import 'hammerjs';

const ChartContainer = ({
                            CustomToolTip,
                            chartClass,
                            chartTitle,
                            categoryLabels,
                            data,
                            dataFormat,
                            getPrint,
                            isMultiSeries = false,
                            labelContent,
                            chartHeight = 800,
                            chartRefValue = null
                        }) => {
    const [defaultChartOptions, setDefaultChartOptions] = useState(null);
    const chartRef = useRef(chartRefValue);

    const handleChartRender = (args) => {
        const chart = args.target.chartInstance;
        if (!defaultChartOptions && chart) {
            setDefaultChartOptions(chart.options);
        }
    };

    const resizeChart = (width = "") => {
        if (chartRef.current) {
            const chartInstance = chartRef.current.chartInstance;
            if (!width) {
                chartInstance.setOptions(defaultChartOptions);
                chartInstance.element.style.width = "";
            } else
                chartInstance.options.chartArea.width = width;
            chartInstance.resize();
        }
    };

    const printChart = (width) => {
        resizeChart(width);
    };

    useEffect(() => {
        if (getPrint && chartRef)
            getPrint(printChart);
    }, [chartRef]);

    const sharedTooltipRender = (context) => <CustomToolTip {...context} />;

    return (
        <Chart ref={chartRef} className={chartClass} onRender={handleChartRender}
               style={{height:chartHeight, maxHeight: 1000}}>
            <ChartTitle text={chartTitle}/>
            {
                sharedTooltipRender && CustomToolTip &&
                <ChartTooltip render={sharedTooltipRender}/>
            }
            <ChartLegend position="bottom" orientation="horizontal"/>
            {
                !labelContent &&
                <ChartValueAxis>
                    <ChartValueAxisItem min={0} max={100} labels={{format: dataFormat, position: "start"}}/>
                    <ChartValueAxisItem min={0} max={100} labels={{format: dataFormat, position: "end"}}/>
                </ChartValueAxis>
            }
            {
                labelContent &&
                <ChartValueAxis>
                    <ChartValueAxisItem labels={{position: "start", content: labelContent}}/>
                    <ChartValueAxisItem labels={{position: "end", content: labelContent}}/>
                </ChartValueAxis>
            }
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categoryLabels}/>
            </ChartCategoryAxis>
            {
                !isMultiSeries &&
                <ChartSeries>
                    <ChartSeriesItem data={data} type="bar">
                        <ChartSeriesLabels format={dataFormat} font={"0.7rem Arial"}/>
                    </ChartSeriesItem>
                </ChartSeries>
            }
            {
                isMultiSeries &&
                <ChartSeries>
                    {
                        data.map((item, idx) => (
                            <ChartSeriesItem
                                key={idx}
                                type="bar"
                                data={item.data}
                                name={item.name}
                            />
                        ))
                    }
                </ChartSeries>
            }
        </Chart>
    );
};

ChartContainer.propTypes = {
    CustomToolTip: PropTypes.func,
    chartClass: PropTypes.string,
    chartTitle: PropTypes.string.isRequired,
    categoryLabels: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    dataFormat: PropTypes.string,
    getPrint: PropTypes.func,
    isMultiSeries: PropTypes.bool,
    labelContent: PropTypes.func,
    chartHeight: PropTypes.number,
    chartRefValue: PropTypes.number
};

export default ChartContainer;