import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as LayoutActions from "../../actions/layoutActions";
import * as SurveyAdminAction from "../../actions/surveyAdminActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import SelectField from "../../components/common/inputs/SelectField";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as config from "../../constants/config";
import {SURVEYS} from "../../constants/contexts";
import * as SurveyStatuses from "../../constants/surveys/surveyStatuses";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import GridTable from "../../components/common/GridTable";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import {gridConstants} from "../../constants/inputConstants";

export class ManageSurveyLandingPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onSurveyClick = this.onSurveyClick.bind(this);
		this.onSurveyGroupDetailsClick = this.onSurveyGroupDetailsClick.bind(this);
		this.onCloneClick = this.onCloneClick.bind(this);
		this.onDateWindowChange = this.onDateWindowChange.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Survey Admin");
		this.props.actions.loadSurveyInstances();
		this.props.actions.loadAllDateWindows();
	}

	onSurveyClick(event, surveyInstanceId) {
		event.preventDefault();

		this.props.history.push(surveyLocations.MANAGE_DETAILS.path
			.replace(surveyLocations.INSTANCE_ID, surveyInstanceId));
	}

	onSurveyGroupDetailsClick(event) {
		event.preventDefault();

		this.props.history.push(surveyLocations.MANAGE_GROUP_DETAILS.path
			.replace(surveyLocations.DATE_WINDOW_ID, this.props.selectedDateWindowId));
	}

	onCloneClick(event, surveyInstanceId) {
		event.preventDefault();

		if (confirm("Are you sure you want to clone this survey?"))
			this.props.actions.tryCopySurvey(surveyInstanceId)
				.then(() => {
					this.props.actions.loadSurveyInstances();
				})
				.catch();
	}

	onDateWindowChange(event) {
		event.preventDefault();

		this.props.actions.changeSelectedDateWindow(event.target.value);
	}

	render() {
		if (this.props.isLoading)
			return (<p>Loading...</p>);

		if ((this.props.surveys.length === 0 || this.props.dateWindows.length === 0) && !this.props.isLoading)
			return (<p>There are no surveys in the system at this time or an error occurred while attempting to load the
				page. If you believe this message to be associated with an error, {config.supportDetails(SURVEYS)}.</p>);

		const activeFilteredSurveys = this.props.surveys.filter(survey => survey.dateWindowId === this.props.selectedDateWindowId && survey.status !== SurveyStatuses.Closed);

		const closedSurveyList = this.props.surveys.filter(survey => survey.dateWindowId === this.props.selectedDateWindowId && survey.status === SurveyStatuses.Closed);

		return (
			<section>
				<GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
					<GridColumn>
						<SelectField name="dateWindow"
						             showLabel={true}
						             label="Date Window"
						             onChange={this.onDateWindowChange}
						             options={this.props.dateWindows}
						             value={this.props.selectedDateWindowId}
						             includeDefaultOption={false}/>
					</GridColumn>
				</GridRow>

				<TileContainer>
					{
						activeFilteredSurveys.map(survey => {
							const body = survey.closeDate === "" ? "TBD" : survey.closeDate;

							return (
								<Tile
									key={survey.surveyId}
									header={survey.title + " (v" + survey.version + ")"}
									body={"Close Date: " + body}
									footer={survey.status}
									id={survey.surveyId}
									onClick={(event) => this.onSurveyClick(event, survey.surveyId)}/>
							);
						})
					}
					<Tile
						isNew={true}
						body={"New Survey"}
						onClick={this.onSurveyGroupDetailsClick}/>
				</TileContainer>

				{
					closedSurveyList.length > 0 &&
					<GridTable>
						<thead>
						<tr>
							<th>Closed Surveys</th>
							<th>Close Date</th>
							<th className="text-center">Actions</th>
						</tr>
						</thead>
						<tbody>
						{
							closedSurveyList.map(survey => {
								return (
									<tr key={survey.surveyId}
									    id={survey.surveyId}
									    className="is-clickable">
										<td onClick={(event) => this.onSurveyClick(event, survey.surveyId)}>
											{survey.title + " (v" + survey.version + ")"}
										</td>
										<td onClick={(event) => this.onSurveyClick(event, survey.surveyId)}>
											{survey.closeDate}
										</td>
										<td>
											<Button
												name={"clone|" + survey.surveyId}
												onClick={(event) => this.onCloneClick(event, survey.surveyId)}
												elementId={survey.surveyId}
												disabled={this.props.isLoading}
												buttonType={ButtonTypes.CLONE}
												label={"Copy"}
											/>
										</td>
									</tr>
								);
							})
						}
						</tbody>
					</GridTable>
				}

			</section>
		);
	}
}

ManageSurveyLandingPage.propTypes = {
	actions: PropTypes.object.isRequired,
	surveys: PropTypes.arrayOf(PropTypes.object).isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedDateWindowId: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	let dateWindows = [...state.sharedData.dateWindows];

	if (dateWindows.length > 0) {
		const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
	}

	return {
		surveys: state.surveyAdmin.surveyInstances,
		dateWindows: dateWindows,
		selectedDateWindowId: state.sharedData.selectedDateWindowId,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, LayoutActions, SurveyAdminAction, sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyLandingPage);