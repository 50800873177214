import React from "react";
import PropTypes from "prop-types";
import StrategyIndicator from "./StrategyIndicator";
import StrategyAction from "./StrategyAction";
import Allow from "../authorization/Allow";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import StrategyGoal from "./StrategyGoal";
import StrategyIndicatorForm from "./StrategyIndicatorForm";
import StrategyActionForm from "./StrategyActionForm";
import StrategyGoalForm from "./StrategyGoalForm";
import IndexIdentifier from "../common/IndexIdentifier";
import * as indexIdentifierListTypes from "../../constants/indexIdentifierListTypes";
import ActionBar from "../common/buttons/ActionBar";

const Strategy = ({
					  strategy,
					  strategyIndex,
					  onDeleteStrategy,
					  filters,
					  metricYearSelectListItems,
					  planStartYear,

					  isEditing,
					  editItemId,
					  isLoading,
					  onFileDownload,
					  onFileDelete,
					  onCancel,

					  sppIndicators,
					  onAddIndicator,
					  onEditIndicator,
					  onChangeIndicator,
					  onSaveIndicator,
					  onCreateMetric,
					  onEditMetrics,
					  onDeleteIndicator,

					  resources,
					  onAddAction,
					  onEditAction,
					  onChangeAction,
					  onSaveAction,
					  onDeleteAction,

					  onEditSubAction,
					  onAddSubAction,
					  onChangeSubAction,
					  onSaveSubAction,
					  onDeleteSubAction,

					  onAddActivity,
					  onEditActivity,
					  onChangeActivity,
					  onChangeActivityDate,
					  onSaveActivity,
					  onDeleteActivity,

					  goals,
					  onEditGoals,
					  onSaveGoals,
					  onChangeGoals
				  }) => {
	return (
		<section className={"strategy "} id={strategy.id}>
			<header>
				<h2>Strategy <IndexIdentifier currentIndex={strategyIndex}
											  listType={indexIdentifierListTypes.UPPER_CASE_LETTERS}/></h2>
				{
					!isEditing &&
					filters.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_STRATEGY}>
						<ActionBar
							elementId={strategy.id}
							onDeleteClick={(event) => onDeleteStrategy(event, strategy.id)}
							disabled={isLoading}
						/>
					</Allow>
				}
			</header>

			<div id="goalsContainer" className="strategy__row">
				{
					!isEditing &&
					filters.showButtons &&
					strategy.goals.length === 0 &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_GOAL}>
						<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
							<Tile
								onClick={!isEditing ? (event) => onEditGoals(event, strategy.id) : null}
								body={"Add Goals"}
								isNew={true}
							/>
						</TileContainer>
					</Allow>
				}

				{
					strategy.goals.length > 0 &&
					editItemId !== strategy.id &&
					<StrategyGoal goals={strategy.goals}
								  isLoading={isLoading}
								  isEditing={isEditing}
								  onEditGoals={(event) => onEditGoals(event, strategy.id)}
								  filters={filters}/>
				}

				{
					isEditing &&
					editItemId === strategy.id &&
					<StrategyGoalForm isLoading={isLoading}
									  onSave={(event) => onSaveGoals(event, strategy.id)}
									  onCancel={onCancel}
									  onChange={(event) => onChangeGoals(event, strategy.id)}
									  goals={goals}
									  selectedGoals={strategy.goals}/>
				}
			</div>

			<div id="indicatorsContainer" className="strategy__row">
				{
					(filters.showButtons ||
						(strategy.strategyIndicators.length > 0)) &&
					<label htmlFor="indicatorsContainer" className="is-visuallyhidden">Indicators</label>
				}

				{
					strategy.strategyIndicators.length > 0 &&
					strategy.strategyIndicators.map((indicator) => {
						const metricId = indicator.baseline === null ? "new-metric" : indicator.baseline.id;
						if (isEditing && indicator.id === editItemId) {
							return (<StrategyIndicatorForm key={indicator.id}
														   onDownloadFile={onFileDownload}
														   sppIndicators={sppIndicators}
														   filters={filters}
														   strategyIndicator={indicator}
														   metricId={metricId}
														   onChange={(event) => onChangeIndicator(event, strategy.id, indicator.id, metricId)}
														   onSave={(event) => onSaveIndicator(event, strategy.id, indicator.id)}
														   onCancel={onCancel}
														   isLoading={isLoading}/>);
						}
						else {
							return (<StrategyIndicator key={indicator.id}
													   downloadFile={onFileDownload}
													   onDeleteFile={(event) => onFileDelete(event, strategy.id, indicator.id)}
													   strategyIndicator={indicator}
													   sppIndicators={sppIndicators}
													   filters={filters}
													   isEditing={isEditing}
													   isLoading={isLoading}
													   onDeleteIndicator={(event) => onDeleteIndicator(event, strategy.id, indicator.id)}
													   onEditIndicator={onEditIndicator}
													   onCancel={onCancel}
													   metricYearSelectListItems={metricYearSelectListItems}
													   editItemId={editItemId}
													   onCreateMetric={(event) => onCreateMetric(event, strategy.id, indicator.id)}
													   onSaveMetrics={(event) => onSaveIndicator(event, strategy.id, indicator.id)}
													   onChangeMetric={(event, strategyId, indicatorId, metricId) => onChangeIndicator(event, strategy.id, indicator.id, metricId)}
													   onEditMetrics={(event) => onEditMetrics(event, metricId)}
													   planStartYear={planStartYear}/>);
						}
					})
				}

				{
					filters.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_INDICATOR}>
						<TileContainer rowTilesLarge="1" rowTilesMedium={"1"}>
							<Tile body={"Add Indicator"}
								  onClick={!isEditing ? (event) => onAddIndicator(event, strategy.id) : null}
								  isNew={true}/>
						</TileContainer>
					</Allow>
				}
			</div>

			<div id="actionsContainer" className="strategy__row">
				{
					(filters.showButtons || (strategy.strategyActions.length > 0)) &&
					<label htmlFor="actionsContainer" className="is-visuallyhidden">Actions</label>
				}

				{
					strategy.strategyActions.length > 0 &&
					strategy.strategyActions.map((action, i) => {
						if (isEditing && action.id === editItemId) {
							return (
								<StrategyActionForm
									key={action.id}
									actionIndex={i}
									editItemId={editItemId}
									action={action}
									filters={filters}
									strategyAction={action}
									resources={resources}
									onChange={(event) => onChangeAction(event, strategy.id, action.id)}
									onCancel={onCancel}
									onSave={(event) => onSaveAction(event, strategy.id, action.id)}
									isLoading={isLoading}/>);
						}
						else return (
							<StrategyAction
								key={action.id}
								actionIndex={i}
								action={action}
								isEditing={isEditing}
								filters={filters}
								onAddSubAction={(event) => onAddSubAction(event, strategy.id, action.id)}
								onChangeSubAction={(event, strategyId, actionId, subActionId) => onChangeSubAction(event, strategy.id, action.id, subActionId)}
								onSaveSubAction={(event, strategyId, actionId, subActionId) => onSaveSubAction(event, strategy.id, action.id, subActionId)}
								onDeleteSubAction={(event, strategyId, actionId, subActionId) => onDeleteSubAction(event, strategy.id, action.id, subActionId)}
								isLoading={isLoading}
								onCancel={onCancel}
								editItemId={editItemId}
								onEditActivity={onEditActivity}
								onAddActivity={(event, strategyId, actionId, subActionId) => onAddActivity(event, strategy.id, action.id, subActionId)}
								onSaveActivity={(event, strategyId, actionId, subActionId, activityId) => onSaveActivity(event, strategy.id, action.id, subActionId, activityId)}
								onDeleteActivity={(event, strategyId, actionId, subActionId, activityId) => onDeleteActivity(event, strategy.id, action.id, subActionId, activityId)}
								onChangeActivity={(event, strategyId, actionId, subActionId, activityId) => onChangeActivity(event, strategy.id, action.id, subActionId, activityId)}
								onChangeActivityDate={(event, strategyId, actionId, subActionId, activityId) => onChangeActivityDate(event, strategy.id, action.id, subActionId, activityId)}
								onEditAction={onEditAction}
								onEditSubAction={onEditSubAction}
								onDeleteAction={(event) => onDeleteAction(event, strategy.id, action.id)}/>);
					})
				}

				{
					filters.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_ACTION}>
						<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
							<Tile body={"Add Action (5-year)"}
								  onClick={!isEditing ? (event) => onAddAction(event, strategy.id) : null}
								  isNew={true}/>
						</TileContainer>
					</Allow>
				}
			</div>
		</section>
	);
};

Strategy.propTypes = {
	editItemId: PropTypes.string,
	planStartYear: PropTypes.string,

	strategyIndex: PropTypes.number.isRequired,

	strategy: PropTypes.object.isRequired,
	filters: PropTypes.object,

	resources: PropTypes.array,
	goals: PropTypes.array,
	sppIndicators: PropTypes.array,
	metricYearSelectListItems: PropTypes.array,

	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onCancel: PropTypes.func,

	onChangeIndicator: PropTypes.func,
	onSaveIndicator: PropTypes.func,
	onAddIndicator: PropTypes.func,
	onEditIndicator: PropTypes.func,
	onDeleteIndicator: PropTypes.func,

	onEditGoals: PropTypes.func,
	onSaveGoals: PropTypes.func,
	onChangeGoals: PropTypes.func,

	onAddSubAction: PropTypes.func,
	onChangeSubAction: PropTypes.func,
	onSaveSubAction: PropTypes.func,
	onEditSubAction: PropTypes.func,
	onDeleteSubAction: PropTypes.func,

	onAddActivity: PropTypes.func,
	onChangeActivity: PropTypes.func,
	onChangeActivityDate: PropTypes.func,
	onSaveActivity: PropTypes.func,
	onEditActivity: PropTypes.func,
	onDeleteActivity: PropTypes.func,

	onAddAction: PropTypes.func,
	onEditAction: PropTypes.func,
	onChangeAction: PropTypes.func,
	onSaveAction: PropTypes.func,
	onDeleteAction: PropTypes.func,

	onDeleteStrategy: PropTypes.func,

	onFileDownload: PropTypes.func,
	onFileDelete: PropTypes.func,

	onCreateMetric: PropTypes.func,
	onEditMetrics: PropTypes.func,
};

export default Strategy;