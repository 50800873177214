import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from '../../actions/layoutActions';
import * as sharedDataActions from '../../actions/sharedDataActions';
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import * as monitoringComplianceActions from '../../actions/monitoringComplianceActions';
import {createListItem} from "../../components/common/commonUtilities";
import {fetchStartYearFromList} from "../../components/common/dateUtilities";
import {McFilter} from "../../components/MonitoringCompliance/McFilter";
import {McNavigation} from "../../components/MonitoringCompliance/McNavigation";
import {createBeessIndicatorList} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import GridTable from "../../components/common/GridTable";
import {getParams} from "../../components/layout/getParams";
import {
    McFilterVisibilityRules,
    MonitoringComplianceDistrictSearchCriteria
} from "../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import {SiteSectionsForTeamMember} from "../../constants/config";
import * as monitoringComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import {getAllDistrictTeamMembers} from "../users/userObjectFactory";

export class MonitoringComplianceDistrictPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loadData: false
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.viewDistrictComplianceDetails = this.viewDistrictComplianceDetails.bind(this);
        this.onOrderChange = this.onOrderChange.bind(this);
    }

    componentDidMount() {
        this.props.actions.updatePageTitle("Monitoring for Compliance - Districts");

        if (this.props.dateWindows.length === 0)
            this.props.actions.loadAllDateWindows();
    }

    componentDidUpdate() {
        let selectedDateWindowId = null;
        if (this.props.dateWindows.length > 0 && this.props.searchCriteria)
            selectedDateWindowId = this.props.searchCriteria.dateWindowId !== "" ? this.props.searchCriteria.dateWindowId
                : this.props.selectedDateWindowId || this.props.dateWindows[0].value;

        if (!this.state.loadData &&
            selectedDateWindowId) {
            this.loadDefaultSearchCriteria(selectedDateWindowId)
            this.setState({loadData: true});
        }
    }

    async loadDefaultSearchCriteria(dateWindowId) {
        const beessIndicators = await this.props.actions.loadBeessIndicators(fetchStartYearFromList(this.props.dateWindows, dateWindowId));
        const indicators = createBeessIndicatorList(beessIndicators);

        let criteria = {...this.props.searchCriteria};
        let selectedStatus = criteria.status !== "" ? criteria.status : this.props.selectedStatus || "";
        let selectedNonComplianceStatus = criteria.nonComplianceStatus !== "" ? criteria.nonComplianceStatus : this.props.selectedNonComplianceStatus || "";
        let selectedIndicatorType = criteria.indicatorType !== ""
            ? criteria.indicatorType
            : indicators.length > 0 ? indicators[0].value : "";

        const institutions = [...this.props.institutions];
        let allDistricts = institutions.map(ins => createListItem(ins.id, ins.name));
        criteria.setDistrictList(allDistricts);
        criteria.allIndicators = indicators;
        criteria.teamMembers = getAllDistrictTeamMembers(institutions, SiteSectionsForTeamMember.DesktopMonitoring.id);
        criteria.indicatorType = selectedIndicatorType;
        criteria.dateWindowId = dateWindowId;
        criteria.status = selectedStatus;
        criteria.nonComplianceStatus = selectedNonComplianceStatus;
        criteria.setVisibilityRules(McFilterVisibilityRules.McDistrictSummary);

        this.props.actions.saveSearchCriteria(criteria);
        this.props.actions.loadDistrictComplianceStatuses(dateWindowId, criteria.indicatorType);
    }

    async onFormChange(criteria, reloadsData) {
        let updatedCriteria = {...criteria};

        if (reloadsData) {
            if(this.props.searchCriteria.dateWindowId !== updatedCriteria.dateWindowId) {
                const beessIndicators = await this.props.actions.loadBeessIndicators(fetchStartYearFromList(this.props.dateWindows, updatedCriteria.dateWindowId))
                const indicators = createBeessIndicatorList(beessIndicators);
                updatedCriteria.allIndicators = indicators;
                updatedCriteria.indicatorType = indicators.length > 0 ? indicators[0].value : "";
            }

            this.props.actions.loadDistrictComplianceStatuses(updatedCriteria.dateWindowId, updatedCriteria.indicatorType);
        }

        this.props.actions.saveSearchCriteria(updatedCriteria);
    }

    viewDistrictComplianceDetails(districtId) {
        const dateWindowId = this.props.searchCriteria.dateWindowId;

        this.props.history.push(monitoringComplianceLocations.MANAGE_PROTOCOLS_DOE_DISTRICT.path
            .replace(monitoringComplianceLocations.DISTRICT_ID, districtId)
            .replace(monitoringComplianceLocations.DATE_WINDOW_ID, dateWindowId));
    }

    onOrderChange(event) {
        event.preventDefault();

        let criteria = {...this.props.searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");
        criteria.First();
        this.props.actions.saveSearchCriteria(criteria);
    }

    render() {
        if (this.props.dateWindows.length === 0 ||
            !this.props.searchCriteria ||
            !this.props.searchCriteria.areRulesCurrent(McFilterVisibilityRules.McDistrictSummary))
            return null;

        let criteria = this.props.searchCriteria;
        let filteredDistricts = criteria.applyFiltersAndOrder(this.props.districtStatuses);

        const indicator = this.props.indicators.find(f => f.value === this.props.searchCriteria.indicatorType);
        const indicatorTitle = !indicator ? "No indicators have been created for this year." : `Summary on ${indicator.text}`;

        return (
            <div>
                <McFilter
                    dateWindows={this.props.dateWindows}
                    handleSearchFiltersChange={this.onFormChange}
                    searchCriteria={criteria}
                />

                {
                    !this.props.isLoading &&
                    filteredDistricts.length === 0 &&
                    <p>There are no districts associated with the selected filters.</p>
                }
                {
                    !this.props.isLoading &&
                    filteredDistricts.length > 0 &&
                    <>
                        <h2>{indicatorTitle}</h2>
                        <GridTable className={"compliance__district-list"}>
                            <thead>
                            <tr>
                                <th rowSpan={3} onClick={this.onOrderChange} data-id="districtName" valign={"bottom"}
                                    className="is-clickable">District
                                </th>
                                <th rowSpan={3} onClick={this.onOrderChange} data-id="status" valign={"bottom"}
                                    className="text-center is-clickable">Status
                                </th>
                                <th colSpan={8} className={"text-center"}>Findings of Noncompliance Tracking Status</th>
                            </tr>
                            <tr>
                                <th colSpan={4} className={"text-center district-noncompliance"}>District</th>
                                <th colSpan={4} className={"text-center"}>Bureau</th>
                            </tr>
                            <tr>
                                <th onClick={this.onOrderChange} data-id={"districtStudent"}
                                    className={"text-center is-clickable district-noncompliance"}>Student-Specific
                                </th>
                                <th onClick={this.onOrderChange} data-id={"districtCapActivityStatus"}
                                    className={"text-center is-clickable district-noncompliance"}>CAP Activities
                                </th>
                                <th onClick={this.onOrderChange} data-id={"districtCapActivityResultStatus"}
                                    className={"text-center is-clickable district-noncompliance"}>CAP Activity Results
                                </th>
                                <th onClick={this.onOrderChange} data-id={"districtMatrixCorrectiveActions"}
                                    className={"text-center is-clickable district-noncompliance"}>Matrix of Services
                                </th>
                                <th onClick={this.onOrderChange} data-id={"bureauStudent"}
                                    className={"text-center is-clickable"}>Student-Specific
                                </th>
                                <th onClick={this.onOrderChange} data-id={"bureauCapActivityStatus"}
                                    className={"text-center is-clickable"}>CAP Activities
                                </th>
                                <th onClick={this.onOrderChange} data-id={"bureauCapActivityResultStatus"}
                                    className={"text-center is-clickable"}>CAP Activity Results
                                </th>
                                <th onClick={this.onOrderChange} data-id={"bureauMatrixCorrectiveActions"}
                                    className={"text-center is-clickable"}>Matrix of Services
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                filteredDistricts.map(d =>
                                    <tr key={d.districtId}
                                        onClick={() => this.viewDistrictComplianceDetails(d.districtId)}
                                        className="is-clickable">
                                        <td>{d.districtName}</td>
                                        <td className={"text-center"}>{d.status}</td>
                                        <td className={"text-center district-noncompliance"}>{d.districtStudentSpecificStatus}</td>
                                        <td className={"text-center district-noncompliance"}>{d.districtCapActivityStatus}</td>
                                        <td className={"text-center district-noncompliance"}>{d.districtCapActivityResultStatus}</td>
                                        <td className={"text-center district-noncompliance"}>{d.districtMatrixCorrectiveActionStatus}</td>
                                        <td className={"text-center"}>{d.bureauStudentSpecificStatus}</td>
                                        <td className={"text-center"}>{d.bureauCapActivityStatus}</td>
                                        <td className={"text-center"}>{d.bureauCapActivityResultStatus}</td>
                                        <td className={"text-center"}>{d.bureauMatrixCorrectiveActionStatus}</td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </GridTable>
                    </>
                }

                <McNavigation currentLocation={this.props.currentLocation} history={this.props.history}/>
            </div>
        );
    }
}

MonitoringComplianceDistrictPage.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
    indicators: PropTypes.arrayOf(PropTypes.object),
    selectedDateWindowId: PropTypes.string.isRequired,
    selectedStatus: PropTypes.string.isRequired,
    selectedNonComplianceStatus: PropTypes.string.isRequired,
    districtStatuses: PropTypes.array.isRequired,
    institutions: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const currentLocation = ownProps.location.pathname;
    const params = getParams(currentLocation, monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW_STATUS);
    const institutions = [...state.sharedData.institutions];

    let dateWindows = (this.props && this.props.dateWindows) ? this.props.dateWindows : [];
    let selectedDateWindowId = (params && params.dateWindowId) ? params.dateWindowId : state.sharedData.selectedDateWindowId;
    let selectedStatus = (params && params.status) ? params.status : "";
    let selectedNonComplianceStatus = (params && params.nonComplianceStatus) ? params.nonComplianceStatus : "";

    if (dateWindows.length === 0 && state.sharedData.dateWindows.length > 0) {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);

        if (!sharedDataUtilities.isValueInList(dateWindows, state.sharedData.selectedDateWindowId))
            selectedDateWindowId = dateWindows[0].value;
    }

    const searchCriteria = state.searchCriteria.MonitoringComplianceDistrictSearchCriteria || new MonitoringComplianceDistrictSearchCriteria(selectedDateWindowId);

    return {
        currentLocation,
        dateWindows: dateWindows,
        indicators: createBeessIndicatorList(state.sharedData.beessIndicators),
        selectedDateWindowId: selectedDateWindowId,
        selectedStatus: selectedStatus,
        selectedNonComplianceStatus: selectedNonComplianceStatus,
        institutions,
        isLoading: state.ajaxCallsInProgress > 0,
        districtStatuses: state.monitoringCompliance.districtStatuses,
        searchCriteria
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, layoutActions, sharedDataActions, monitoringComplianceActions, searchCriteriaActions, apiForLocalStateActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringComplianceDistrictPage);