import React from "react";
import PropTypes from "prop-types";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import SelectField from "../common/inputs/SelectField";
import * as MonitoringPerformanceTypes
	from "../../constants/monitoringPerformance/monitoringPerformanceAssessmentTypes";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import DateTimePickerField from "../common/inputs/DateTimePickerField";

const MonitoringPerformanceAssessmentForm = ({
	                                             assessment,
	                                             availableDistricts,
	                                             isLoading,
	                                             onChange,
	                                             onSavePerformanceAssessment,
	                                             onCancel
                                             }) => {
	const radioOptions = [
		{
			"text": MonitoringPerformanceTypes.OnSite.text,
			"value": MonitoringPerformanceTypes.OnSite.value
		},
		{
			"text": MonitoringPerformanceTypes.Desktop.text,
			"value": MonitoringPerformanceTypes.Desktop.value
		}
	];

	return (
		<section className="performance__monitoringType performance__monitoringType--editing"
		         id={"performance__monitoringType"}>
			<header>
				<h2>Assessment Details</h2>
			</header>

			<GridRow>
				<GridColumn>
					{!assessment.monitoringAssessmentId &&
					<SelectField
						label={"District"}
						showLabel
						name={"districtId"}
						defaultOption={"Select District"}
						options={availableDistricts}
						onChange={onChange}
						includeDefaultOption={true}
					/>
					}
				</GridColumn>
				<GridColumn>
					<SelectField
						label={`Type`}
						showLabel
						name={"monitoringType"}
						value={assessment.monitoringType ? assessment.monitoringType.toString() : ""}
						defaultOption={"Select Type"}
						includeDefaultOption={!assessment.monitoringAssessmentId}
						onChange={onChange}
						options={radioOptions}/>
				</GridColumn>
				<GridColumn>
					<DateTimePickerField
						name={"visitStartDate"}
						label={"Start Date"}
						onChange={onChange}
						value={assessment.visitStartDate}
						showLabel
					/>
				</GridColumn>
				<GridColumn>
					<DateTimePickerField
						name={"visitEndDate"}
						label={"End Date"}
						onChange={onChange}
						value={assessment.visitEndDate}
						showLabel
					/>
				</GridColumn>
				<GridColumn>
					<ButtonBar position={ButtonBarPositions.COLUMN}>
						<Button
							name={"save"}
							label={"Save"}
							onClick={(event) => onSavePerformanceAssessment(event, assessment)}
							buttonType={ButtonTypes.SAVE}
							disabled={isLoading}
						/>
						<Button
							name={"cancel"}
							label={"Cancel"}
							onClick={onCancel}
							buttonType={ButtonTypes.CANCEL}
							disabled={isLoading}
						/>
					</ButtonBar>
				</GridColumn>
			</GridRow>
		</section>
	);
};

MonitoringPerformanceAssessmentForm.propTypes = {
	assessment: PropTypes.object,
	availableDistricts: PropTypes.array,
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onSavePerformanceAssessment: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

export default MonitoringPerformanceAssessmentForm;