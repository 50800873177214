import {createPlanObject, createFiveYearPlanObject} from "../components/strategicPlan/strategicPlanObjectFactory";
import {generateId} from "../components/common/IdGeneratorUtility";
import * as actionTypes from "../constants/actionTypes";
import {baseLinePrefix, metricPrefix, activityPrefix} from "../constants/strategicPlan/idPrefixes";
import initialState from "./initialState";
import * as stratUtils from "../components/strategicPlan/strategicPlanUtilities";
import * as indicatorTypes from "../constants/indicatorTypes";
import {outputStandardDateFormat} from "../components/shared/sharedDataUtilities";

export default function strategicPlanReducer(state = initialState.strategicPlan, action) {
    switch(action.type){
        case actionTypes.LOAD_PLANS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    plans: [...action.data].map(plan => (createPlanObject(plan))),
                    isEditing: false,
	                editItemId: ""
                }
            );
	    case actionTypes.LOAD_FIVE_YEAR_REPORT_AJAX_SUCCESS:
		    return Object.assign(
			    {},
			    state,
			    {
				    fiveYearReport: [...action.data].map(plan => (createFiveYearPlanObject(plan))),
				    isEditing: false,
				    editItemId: ""
			    }
		    );
        case actionTypes.LOAD_ACTION_RESOURCES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    resources: [...action.data],
                    editItemId: "",
                    isEditing: false
                }
            );
        case actionTypes.LOAD_STRATEGIES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: action.data.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

	                        if(stratInd.baseline && stratInd.baseline.startYear) {
                                stratInd.baseline.startYear = stratInd.baseline.startYear.toString();
                                stratInd.baseline.id = generateId(1, baseLinePrefix, stratInd.id)
                            }

                            stratInd.metrics = stratInd.metrics.map((met, index) => {
	                            //detach level from state
	                            met = Object.assign({}, met);

	                            met.id = generateId(index + 1, metricPrefix, stratInd.id),
                                met.startYear = met.startYear.toString();
                                met.hasMetTarget = (met.hasMetTarget === null) ? "" : met.hasMetTarget.toString();

                                if(met.targetVerificationComment === null)
                                    met.targetVerificationComment = "";

                                return met;
                            });

                            return stratInd;
                        });

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                subAct.progressMonitoringNoteEntries = subAct.progressMonitoringNoteEntries.map((activity, index) => {
                                    //detach level from state
                                    activity = Object.assign({}, activity, { id: generateId(index, activityPrefix, subAct.id)});

                                    activity.dateNoteCreated = outputStandardDateFormat(activity.dateNoteCreated, false);

                                    return activity;
                                });

                                return subAct;
                            });

                            return stratAct;
                        });

                        return strat;
                    }),
                    isEditing: false,
	                editItemId: ""
                }
            );
        case actionTypes.ADD_STRATEGY_AJAX_SUCCESS:
	    return Object.assign(
		    {},
		    state,
		    {
			    strategies: [...state.strategies, stratUtils.newStrategy(action.data.strategyId, action.data.planDateWindowId)]
		    }
	    );
	    case actionTypes.DELETE_STRATEGY_AJAX_SUCCESS:
		    return Object.assign(
			    {},
			    state,
			    {
				    strategies: [...state.strategies].filter(s => s.id !== action.data)
			    }
		    );
        case actionTypes.UPDATE_PLAN_FILTERS:
            return Object.assign(
                {},
                state,
                {
                    filterSettings: Object.assign({}, action.data)
                }
            );
        case actionTypes.CREATE_STRATEGY_INDICATOR:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        strat = Object.assign({}, strat);

                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = [...strat.strategyIndicators, stratUtils.newStrategyIndicator(action.data.strategyId, action.data.strategicIndicatorId)];
                        return strat;
                    }),
                    isEditing: true,
                    editItemId: action.data.strategicIndicatorId
                }
            );
        case actionTypes.CREATE_INDICATOR:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(!strat.strategyIndicators.some(si => si.id === action.data.strategicIndicatorId)) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            if(stratInd.id !== action.data.strategicIndicatorId) return stratInd;

                            stratInd.indicators = [...stratInd.indicators, stratUtils.newIndicator(action.data.indicatorText, indicatorTypes.SPP, action.data.strategicIndicatorId)];

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.UPDATE_INDICATOR:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(!strat.strategyIndicators.some(si => si.id === action.data.strategicIndicatorId)) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            //if the current strat indicator isn't the updated one, return it
                            if(stratInd.id !== action.data.strategicIndicatorId) return stratInd;

                            stratInd.indicators = stratInd.indicators.map(ind => {
                                //detach level from state
                                ind = Object.assign({}, ind);

                                //only BEESS indicators can be updated
                                if(ind.indicatorType === indicatorTypes.BEESS)
                                    ind.text = action.data.indicatorText;

                                return ind;
                            });

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.REMOVE_INDICATOR:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(!strat.strategyIndicators.some(si => si.id === action.data.strategicIndicatorId)) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            stratInd.indicators = stratInd.indicators.filter(i =>
                                i.text !== action.data.indicatorText);

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.SAVE_INDICATORS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.ADD_ACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't contian the empty action Id return as is
                        if(!strat.strategyActions.some(sa => sa.id === "")) return strat;

                        strat.strategyActions = strat.strategyActions.map(sa => {
                            sa = Object.assign({}, sa);

                            if(sa.id !== "") return sa;

                            sa.id = action.data;

                            return sa;
                        });

                        return strat;
                    }),
	                isEditing: false,
	                editItemId: ""                }
            );
        case actionTypes.CREATE_STRATEGY_ACTION:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one we're looking for return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        //get the last action
                        let order = 1;
                        if(strat.strategyActions.length > 0)
                        {
                            const lastAction = [...strat.strategyActions].pop();
                            order = lastAction.order + 1;
                        }

                        const newAction = stratUtils.newAction(action.data.strategyId, order);

                        strat.strategyActions = [...strat.strategyActions, newAction];

                        return strat;
                    }),
                    isEditing: true
                }
            );
        case actionTypes.UPDATE_STRATEGY_ACTION:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one we're looking for return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this is not the action we're looking for return as is
                            if(stratAct.id !== action.data.id) return stratAct;

                            return action.data;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.CLEAR_PLAN_DATA:
            return Object.assign(
                {},
                initialState.strategicPlan
            );
        case actionTypes.EDIT_STRATEGY_GOALS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.UPDATE_STRATEGY_GOALS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one we're looking for return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.goals = action.data.goals;

                        return strat;
                    })
                }
            );
        case actionTypes.LOAD_STRATEGIC_GOALS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    goals: action.data,
	                isEditing: false,
	                editItemId: ""               }
            );
        case actionTypes.SAVE_STRATEGY_GOALS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.CREATE_SUBACTION:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            //get the last action
                            let order = 1;
                            if(stratAct.subActions.length > 0)
                            {
                                const lastSubAction = [...stratAct.subActions].pop();
                                order = lastSubAction.order + 1;
                            }

                            const newSubAction = stratUtils.newSubAction(action.data.strategyId, order);

                            stratAct.subActions = [...stratAct.subActions, newSubAction];

                            return stratAct;
                        });

                        return strat;
                    }),
                    isEditing: true
                }
            );
        case actionTypes.UPDATE_SUBACTION:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(!strat.strategyActions.some(sa => sa.id === action.data.actionId)) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                //if this is not the subAction updated, return as is
                                if(subAct.id !== action.data.subAction.id) return subAct;

                                return action.data.subAction;
                            });

                            return stratAct;
                        });

                        return strat;
                    }),
                    isEditing: true
                }
            );
        case actionTypes.ADD_SUBACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(!strat.strategyActions.some(sa => sa.id === action.data.actionId)) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                //if this is not the subAction updated, return as is
                                if(subAct.id !== "") return subAct;

                                subAct.id = action.data.subActionId;

                                return subAct;
                            });

                            return stratAct;
                        });

                        return strat;
                    }),
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.CREATE_PROGRESS_MONITORING_ACTIVITY:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                if(subAct.id !== action.data.subActionId) return subAct;

                                const newProgressMonitoringActivity = stratUtils.newProgressMonitoringActivity(action.data.noteId);

                                subAct.progressMonitoringNoteEntries = [...subAct.progressMonitoringNoteEntries, newProgressMonitoringActivity];

                                return subAct;
                            });

                            return stratAct;
                        });

                        return strat;
                    }),
                    isEditing: true,
                    editItemId: action.data.noteId
                }
            );
        case actionTypes.UPDATE_PROGRESS_MONITORING_ACTIVITY:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                if(subAct.id !== action.data.subActionId) return subAct;

                                subAct.progressMonitoringNoteEntries = subAct.progressMonitoringNoteEntries.map(activity => {
                                    //detach level from state
                                    activity = Object.assign({}, activity);

                                    if(activity.id !== action.data.activity.id) return activity;

                                    return action.data.activity;
                                });

                                return subAct;
                            });

                            return stratAct;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.UPDATE_SUBACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.EDIT_STRATEGY_ACTION:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.UPDATE_ACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.EDIT_SUBACTION:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.EDIT_STRATEGY_INDICATOR:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.EDIT_PROGRESS_MONITORING_ACTIVITY:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.DELETE_PROGRESS_MONITORING_ACTIVITY_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = stratAct.subActions.map(subAct => {
                                //detach level from state
                                subAct = Object.assign({}, subAct);

                                if(subAct.id !== action.data.subAction.id) return subAct;

                                return action.data.subAction;
                            });

                            return stratAct;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.DELETE_SUBACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = strat.strategyActions.map(stratAct => {
                            //detach level from state
                            stratAct = Object.assign({}, stratAct);

                            //if this action isn't the one with the added sub action, return as is
                            if(stratAct.id !== action.data.actionId) return stratAct;

                            stratAct.subActions = [...stratAct.subActions].filter(sub => sub.id !== action.data.subActionId);

                            return stratAct;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.DELETE_ACTION_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyActions = [...strat.strategyActions].filter(act => act.id !== action.data.actionId);

                        return strat;
                    })
                }
            );
        case actionTypes.LOAD_PLAN_DATE_WINDOW_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    planDateWindow: createPlanObject(Object.assign({}, action.data, { startYear: action.data.startYear.toString() }))
                }
            );
        case actionTypes.EDIT_TEAM_MEMBERS:
            return Object.assign(
                {},
                state,
                {
                    editItemId: action.data,
                    isEditing: true
                }
            );
        case actionTypes.CREATE_TEAM_MEMBER:
            return Object.assign(
                {},
                state,
                {
                    planDateWindow: Object.assign(
                        {},
                        state.planDateWindow,
                        {
                            teamMembers: [...state.planDateWindow.teamMembers, stratUtils.newTeamMember(action.data)]
                        }
                    ),
                    isEditing: true,
                    editItemId: state.planDateWindow.planDateWindowId
                }
            );
        case actionTypes.UPDATE_TEAM_MEMBER:
            return Object.assign(
                {},
                state,
                {
                    planDateWindow: Object.assign(
                        {},
                        state.planDateWindow,
                        {
                            teamMembers: state.planDateWindow.teamMembers.map(mem => {
                                //detach level from state
                                mem = Object.assign({}, mem);

                                //if the current member isn't the one we're looking for return as is
                                if(mem.id !== action.data.id) return mem;

                                return action.data;
                            })
                        }
                    )
                }
            );
        case actionTypes.REMOVE_TEAM_MEMBER:
            return Object.assign(
                {},
                state,
                {
                    planDateWindow: Object.assign(
                        {},
                        state.planDateWindow,
                        {
                            teamMembers: [...state.planDateWindow.teamMembers].filter(m => m.id !== action.data.id)
                        }
                    )
                }
            );
        case actionTypes.SAVE_TEAM_MEMBERS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: ""
                }
            );
        case actionTypes.UPDATE_BASELINE_METRIC:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            //if the current strat indicator isn't the updated one, return it
                            if(stratInd.id !== action.data.strategyIndicatorId) return stratInd;

                            stratInd.baseline = action.data.baseline;

                            if(stratInd.baseline.startYear !== "" &&
                                stratInd.metrics.length > 0) {
                                let nextStartYear = parseInt(action.data.baseline.startYear) + 1;

                                stratInd.metrics = stratInd.metrics.map(met => {
                                    met = Object.assign({}, met);
                                    met.startYear = nextStartYear;
                                    nextStartYear++;
                                    return met;
                                });
                            }

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.UPDATE_METRIC:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            //if the current strat indicator isn't the updated one, return it
                            if(stratInd.id !== action.data.strategyIndicatorId) return stratInd;

                            stratInd.metrics = stratInd.metrics.map(met => {
                                //detach from level
                                met = Object.assign({}, met);

                                //if this is not the right metric, then return as is
                                if(met.id !== action.data.metric.id) return met;

                                return action.data.metric;
                            });

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.CREATE_METRIC:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat doesn't include the updated stratindicator, return strat
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            //detach level from state
                            stratInd = Object.assign({}, stratInd);

                            //if the current strat indicator isn't the updated one, return it
                            if(stratInd.id !== action.data.strategyIndicatorId) return stratInd;

                            if(stratInd.baseline === null)
                                stratInd.baseline = stratUtils.newMetric(action.data.id);
                            else {
                                let nextStartYear;
                                if(stratInd.metrics.length === 0)
                                    nextStartYear = parseInt(stratInd.baseline.startYear) + 1;
                                else
                                    nextStartYear = parseInt(stratInd.metrics[stratInd.metrics.length - 1].startYear) + 1;

                                stratInd.metrics = [...stratInd.metrics, stratUtils.newMetric(action.data.id, nextStartYear.toString())];
                            }

                            return stratInd;
                        });

                        return strat;
                    }),
                    isEditing: true,
                    editItemId: state.strategies
                        .filter(s => s.id === action.data.strategyId)[0].strategyIndicators
                        .filter(si => si.id === action.data.strategyIndicatorId)[0].baseline === null
                        ? action.data.id :
                        state.strategies
                            .filter(s => s.id === action.data.strategyId)[0].strategyIndicators
                            .filter(si => si.id === action.data.strategyIndicatorId)[0].baseline.id
                }
            );
        case actionTypes.EDIT_METRICS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data
                }
            );
        case actionTypes.DELETE_STRATEGY_INDICATOR_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);

                        //if the current strat isn't the one with the action to which we're adding the
                        // sub action, return as is
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = [...strat.strategyIndicators].filter(stratInd => stratInd.id !== action.data.strategyIndicatorId);

                        return strat;
                    })
                }
            );
        case actionTypes.ADD_INDICATOR_FILE:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            stratInd = Object.assign({}, stratInd);
                            if(stratInd.id !== action.data.strategyIndicatorId) return stratInd;

                            stratInd.triangleFileId = action.data.fileId;

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.REMOVE_INDICATOR_FILE:
            return Object.assign(
                {},
                state,
                {
                    strategies: state.strategies.map(strat => {
                        //detach level from state
                        strat = Object.assign({}, strat);
                        if(strat.id !== action.data.strategyId) return strat;

                        strat.strategyIndicators = strat.strategyIndicators.map(stratInd => {
                            stratInd = Object.assign({}, stratInd);
                            if(stratInd.id !== action.data.strategyIndicatorId) return stratInd;

                            stratInd.triangleFileId = null;

                            return stratInd;
                        });

                        return strat;
                    })
                }
            );
        case actionTypes.COPY_PLAN_DATE_WINDOW_AJAX_SUCCESS:
        {
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans,
                        Object.assign(
                            {},
                            [...state.plans].filter(p => p.planDateWindowId === action.data.oldPlanDateWindowId)[0],
                            {
                                planDateWindowId: action.data.newPlanDateWindowId,
                                dateWindowId: action.data.newDateWindowId
                            })]
                }
            );
        }

        case actionTypes.CREATE_RESOURCE:
            return Object.assign(
                {},
                state,
                {
                    resources: [...state.resources, stratUtils.newResource()],
                    isEditing: true
                }
            );
        case actionTypes.EDIT_RESOURCE:
            return Object.assign(
                {},
                state,
                {
                    editItemId: action.data,
                    isEditing: true
                }
            );
        case actionTypes.CHANGE_RESOURCE:
            return Object.assign(
                {},
                state,
                {
                    resources: [...state.resources].map(re => {
                        re = Object.assign({}, re);
                        if(re.id !== action.data.id) return re;
                        return action.data;
                    })
                }
            );
        case actionTypes.ADD_RESOURCE_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    resources: [...state.resources].map(re => {
                        re = Object.assign({}, re);
                        if(re.id !== "") return re;
                        re.id = action.data;
                        return re;
                    }),
                    isEditing: false,
	                editItemId: ""
                }
            );
        case actionTypes.UPDATE_RESOURCE_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editItemId: "",
                    isEditing: false
                }
            );
        case actionTypes.DELETE_RESOURCE_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    resources: [...state.resources].filter(re => re.id !== action.data.id)
                }
            );

        case actionTypes.CREATE_GOAL:
            return Object.assign(
                {},
                state,
                {
                    goals: [...state.goals, stratUtils.newGoal()],
                    isEditing: true
                }
            );
        case actionTypes.EDIT_GOAL:
            return Object.assign(
                {},
                state,
                {
                    editItemId: action.data,
                    isEditing: true
                }
            );
        case actionTypes.CHANGE_GOAL:
            return Object.assign(
                {},
                state,
                {
                    goals: [...state.goals].map(g => {
                        g = Object.assign({}, g);
                        if(g.id !== action.data.id) return g;
                        return action.data;
                    })
                }
            );
        case actionTypes.ADD_GOAL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    goals: [...state.goals].map(g => {
                        g = Object.assign({}, g);
                        if(g.id !== "") return g;
                        g.id = action.data;
                        return g;
                    }),
                    isEditing: false,
	                editItemId: ""
                }
            );
        case actionTypes.UPDATE_GOAL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editItemId: "",
                    isEditing: false
                }
            );
        case actionTypes.DELETE_GOAL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    goals: [...state.goals].filter(g => g.id !== action.data.id)
                }
            );
        case actionTypes.CREATE_PLAN_DATE_WINDOW:
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans, stratUtils.newPlan(action.data)],
                    isEditing: true
                }
            );
        case actionTypes.CHANGE_PLAN_DATE_WINDOW:
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans].map(pdw => {
                        pdw = Object.assign({}, pdw);

                        if(state.editItemId !== pdw.planDateWindowId) return pdw;

                        return action.data;
                    })
                }
            );
        case actionTypes.ADD_PLAN_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans].map(pdw => {
                        pdw = Object.assign({}, pdw);

                        if(pdw.planDateWindowId !== "") return pdw;

                        pdw.planDateWindowId = action.data;

                        return pdw;
                    }),
                    isEditing: false,
	                editItemId: ""
                }
            );
        case actionTypes.DELETE_PLAN_DATE_WINDOW_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans].filter(g => g.planDateWindowId !== action.data.planDateWindowId)
                }
            );
        case actionTypes.UPDATE_PLAN_DATE_WINDOW_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    plans: [...state.plans].map(planDateWindow => {
                        planDateWindow = Object.assign({}, planDateWindow);

                        if(planDateWindow.planDateWindowId !== action.data.planDateWindowId) return planDateWindow;

                        return action.data;
                    })
                }
            );
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.strategicPlan;
        default:
            return state;
    }
}