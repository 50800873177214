import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintClosureProcessRequirement from "./DrStateComplaintClosureProcessRequirement";
import FormTable from "../../../components/common/FormTable";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {StateComplaintPolicy} from "../../../components/authorization/policies/StateComplaintPolicy";
import {dispute_resolution} from "../../../constants/policyEvents";

const DrStateComplaintClosureProcessView = ({
	                                            closureProcessRequirements,
	                                            complaint,
	                                            handleChangeCompletionDate,
												handleClickCancel,
	                                            handleCreateClosureProcess,
	                                            handleRemoveClosureProcess,
	                                            handleSaveClosureProcess
                                            }) => {
	const canEdit = AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY);

	return (
		<section className={"timeline"}>
			<FormTable>
				<DisplayField
					name={`complaintNumber`}
					label={`Case No`}
					columnsMedium={6}
					showLabel
				>
					{complaint.complaintNumber}
				</DisplayField>
				<DisplayField
					name={`lastActionDueDate`}
					label={`Last Action Due Date`}
					columnsMedium={6}
					showLabel
				>
					{outputStandardDateFormat(complaint.lastActionDueDate, false)}
				</DisplayField>
				<DisplayField
					name={`lastDocumentsReceivedDate`}
					label={`Documents Received`}
					columnsMedium={6}
					showLabel
				>
					{outputStandardDateFormat(complaint.lastDocumentsReceivedDate, false)}
				</DisplayField>
				{
					closureProcessRequirements.map((closureProcessRequirement, index) => {
						return <DrStateComplaintClosureProcessRequirement
							closureProcessRequirement={closureProcessRequirement}
							handleChangeCompletionDate={handleChangeCompletionDate}
							key={index}
						/>;
					})
				}
				{
					complaint.closureProcessIndicatorData !== null &&
					<DisplayField
						name={`closureProcessIndicatorData`}
						label={`BEESS Indicator Data`}
						columnsMedium={6}
						showLabel
					>
						{complaint.closureProcessIndicatorData.toString()}
					</DisplayField>
				}
			</FormTable>
			{
				canEdit &&
				closureProcessRequirements.length === 0 &&
				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<Button name="btnCreate"
					        label="Start Closure Letter Process"
					        buttonType={ButtonTypes.SAVE}
					        onClick={() => handleCreateClosureProcess()}/>
				</ButtonBar>
			}
			{
				canEdit &&
				closureProcessRequirements.length > 0 &&
				<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
					<Button name="btnRemove"
					        label="Remove Closure Letter Process"
					        buttonType={ButtonTypes.DELETE}
					        onClick={handleRemoveClosureProcess}/>

					<Button name="btnSave"
							label="Save"
							buttonType={ButtonTypes.SAVE}
							onClick={handleSaveClosureProcess}/>

					<Button name="btnCancel"
							label="Cancel and Reload"
							buttonType={ButtonTypes.CANCEL}
							onClick={handleClickCancel}/>
				</ButtonBar>
			}
		</section>
	);
};

DrStateComplaintClosureProcessView.propTypes = {
	closureProcessRequirements: PropTypes.array.isRequired,
	complaint: PropTypes.object.isRequired,
	handleChangeCompletionDate: PropTypes.func.isRequired,
	handleClickCancel: PropTypes.func.isRequired,
	handleCreateClosureProcess: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleRemoveClosureProcess: PropTypes.func.isRequired,
	handleSaveClosureProcess: PropTypes.func.isRequired
};

export default DrStateComplaintClosureProcessView;