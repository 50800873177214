import AccountUtility from "../../../api/AccountUtility";
import { DefaultPolicy } from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import { GMS as policyEvents } from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import { verifyAccessToContextAndClaim } from "../AuthorizationUtilities";

class GmsPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.GMS]);
        this.updatePolicy();
    }

    updatePolicy() {
        this._policy[policyEvents.APPLICATION.modify] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.APPLICATION.submit] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.APPLICATION.createAmendment] =
            function (allowedContexts, userBehaviors) {
                return AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.ADMIN.audit] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser()
                    && verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.APPLICATION.review] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.VIEW], userBehaviors);
            };

        this._policy[policyEvents.ADMIN.manage] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.APPLICATION.uploadDoe200] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.ADMIN.CASE_NOTE.modify] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE], userBehaviors);
            };
    }

}

export { GmsPolicy, policyEvents };