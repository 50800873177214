import React from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {RadioButtonYesNoObject} from "../../drConstants";
import api from "../drDueProcessApi";
import DrDueProcessFinalOrderView from "./DrDueProcessFinalOrderView";

const DrDueProcessFinalOrderContainer = ({
                                             actions,
                                             canEdit,
                                             dueProcess,
                                             executeApi,
                                             handleDataChanged,
                                             handleSelectSection,
                                             handleUpdateDueProcess,
                                             isDistrictUser,
                                             userDrTeamMemberRoles
                                         }) => {

    const readOnly = !canEdit || isDistrictUser;

    const handleSave = async (form) => {
        if (!isArrayNullOrEmpty(form.actions) && form.finalOrderOrderedActions !== RadioButtonYesNoObject.Yes && dueProcess.finalOrderOrderedActions === RadioButtonYesNoObject.Yes)
            if (!confirm("Changing the ordered actions from yes will clear out any existing actions.\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
                return;

        await executeApi(api.saveDueProcessFinalOrder, [form])
            .then((result) => {
                handleUpdateDueProcess(result);
            });
    };

    if (!dueProcess) return null;

    return (
        <DrDueProcessFinalOrderView
            deleteFile={actions.deleteFile}
            downloadFile={actions.downloadFile}
            dueProcess={dueProcess}
            handleDataChanged={handleDataChanged}
            handleSave={handleSave}
            handleSelectSection={handleSelectSection}
            isDistrictUser={isDistrictUser}
            readOnly={readOnly}
            uploadFile={actions.uploadFile}
            userDrTeamMemberRoles={userDrTeamMemberRoles}
        />
    );
};

DrDueProcessFinalOrderContainer.propTypes = {
    actions: PropTypes.object,
    canEdit: PropTypes.bool.isRequired,
    dueProcess: PropTypes.object.isRequired,
    executeApi: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateDueProcess: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrDueProcessFinalOrderContainer;
