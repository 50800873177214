import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as AuthorizationUtilities from "../authorization/AuthorizationUtilities";
import Button from "../common/buttons/Button";
import {UserPolicy} from "../authorization/policies/UserPolicy";
import * as policyEvents from "../../constants/policyEvents";
import {isNullOrUndefined} from "../common/commonUtilities";
import {STATUS} from "../../constants/users/userConstants";

const UserSearchRow = ({ user, onClick, impersonating }) => {

    const handleImpersonateClick = (event, userId) => {
        event.preventDefault();
        impersonating(userId);
    };
    const canImpersonate = AuthorizationUtilities.allow(UserPolicy, policyEvents.IMPERSONATE);
    const trRowClick = canImpersonate ? null : (event) => onClick(event, user.userId);
    const trRowClass = canImpersonate ? "" : "is-clickable";
    const isUserActive = user.status === STATUS.ACTIVE;

    return (
        <tr id={user.userId} onClick={trRowClick} className={trRowClass}>
            <td>
                {user.email}
            </td>
            <td>
                {user.username}
            </td>
            <td>
                {user.institution}
            </td>
            <td>
                {user.status}
            </td>
            {
                canImpersonate &&
                <td className={"text-center action-no-wrap"}>
                    {
                        isUserActive && !isNullOrUndefined(user.institutionId) &&
                        <Button name={`btnImpersonate_${user.userId}`}
                                label={`Impersonate for ${user.email}`}
                                buttonType={ButtonTypes.IMPERSONATE}
                                showLabel={false}
                                onClick={(event) => handleImpersonateClick(event, user.userId)}
                        />
                    }

                    <Button name={`btnProfile_${user.userId}`}
                            label={`Edit Profile for ${user.email}`}
                            buttonType={ButtonTypes.EDIT}
                            showLabel={false}
                            onClick={(event) => onClick(event, user.userId)}
                    />
                </td>
            }
        </tr>
    );
};

UserSearchRow.propTypes = {
    user: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    impersonating: PropTypes.func.isRequired
};

export default UserSearchRow;