import React from 'react';
import PropTypes from 'prop-types';
import IndicatorProtocolGrid from "./IndicatorProtocolGrid";

const IndicatorProtocol = ({
                               assessmentApproved,
                               highlightNonCompliance = false,
                               indicatorProtocols,
                               protocolResponses
                           }) => {
    return (
        <IndicatorProtocolGrid
            assessmentApproved={assessmentApproved}
            highlightNonCompliance={highlightNonCompliance}
            indicatorProtocols={indicatorProtocols}
            protocolResponses={protocolResponses}
            showState={false}
        />
    );
};

IndicatorProtocol.propTypes = {
    assessmentApproved: PropTypes.bool.isRequired,
    highlightNonCompliance: PropTypes.bool,
    indicatorProtocols: PropTypes.array.isRequired,
    protocolResponses: PropTypes.array.isRequired
};

export default IndicatorProtocol;