import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as shardDataActions from "../../actions/sharedDataActions";
import { getParamsMultiple } from "../../components/layout/getParams";
import * as sppLocations from "../../constants/spp/sppLocations";
import SppAdminDocumentResponseCopyStrategy from "../../components/sppAdmin/SppAdminDocumentResponseCopyStrategy";
import SppAdminDocumentHierarchySelector from "../../components/sppAdmin/SppAdminDocumentHierarchySelector";

class SppAdminCopyPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const params = getParamsMultiple(this.props.location.pathname,
            [
                sppLocations.ADMIN_COPY,
                sppLocations.ADMIN_COPY_DOCUMENT,
                sppLocations.ADMIN_COPY_PART
            ]);

        return (
            <div className={`sppAdminContainer`}>
                <SppAdminDocumentHierarchySelector
                    isLoading={this.props.isLoading}
                    isForCopy={true}
                    params={params}
                    warnOnNavigate={this.props.warnOnNavigate}
                />

                <SppAdminDocumentResponseCopyStrategy
                    isLoading={this.props.isLoading}
                    params={params}
                    history={this.props.history}
                    setDataChanged={this.props.setDataChanged}
                />
            </div>
        );
    }
}

SppAdminCopyPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    setDataChanged: PropTypes.func.isRequired,
    warnOnNavigate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        shardDataActions,
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SppAdminCopyPage));