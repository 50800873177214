import React from "react";
import PropTypes from "prop-types";
import {createListIfExistsInIdArray} from "../common/commonUtilities";
import ListManagerField from "../common/inputs/ListManagerField";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const StrategyGoalForm = ({
	                          goals,
	                          selectedGoals,
	                          onChange,
	                          onSave,
	                          onCancel,
	                          isLoading
                          }) => {

	if (goals.length > 0) {
		goals = goals.map(g => {
			return {
				id: g.text,
				text: g.text,
				value: g.text
			};
		});
	}

	selectedGoals = selectedGoals.map(g => { return g.text });

	return (
		<section className={"strategy__goal strategy__goal--editing"}>
			<header>
				<h3>FDOE Strategic Goals</h3>
			</header>

			<ListManagerField listItems={goals}
			                  name={"goals"}
			                  onChange={onChange}
			                  selectToAdd
							  listValues={createListIfExistsInIdArray(selectedGoals, goals)}
			                  disabled={isLoading}/>

			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name={"save"}
				        label={"Save"}
				        buttonType={ButtonTypes.SAVE}
				        onClick={onSave}
				        disabled={isLoading} />
				<Button name={"cancel"}
				        onClick={onCancel}
				        buttonType={ButtonTypes.CANCEL}
				        label={"Cancel"}
				        disabled={isLoading}/>
			</ButtonBar>
		</section>
	);
};

StrategyGoalForm.propTypes = {
	goals: PropTypes.array.isRequired,
	selectedGoals: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default StrategyGoalForm;