import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function authorizationReducer(state = initialState.currentContext, action) {
    switch(action.type){
        case actionTypes.UPDATE_CURRENT_CONTEXT:
            return [...action.currentContext];
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.currentContext;
        default:
            return state;
    }
}