import {createDescriptionObjectForList, createListFromObject} from "../../../../components/common/commonUtilities";

export const OTHER_FUNCTION_CODES = [`9999`];

export const BUDGET_COLUMNS = {
    entry: createDescriptionObjectForList(1, "Entry"),
    fundingCategory: createDescriptionObjectForList(2, "Funding Category"),
    function: createDescriptionObjectForList(3, "Function"),
    objectCodeDescription: createDescriptionObjectForList(4, "Object Code/ Description"),
    accountTitleAndNarrative: createDescriptionObjectForList(5, "Account Title and Narrative"),
    fte: createDescriptionObjectForList(6, "FTE (decimal)"),
    amount: createDescriptionObjectForList(7, "Amount (decimal)"),
    agreement: createDescriptionObjectForList(8, "Agreement Allocation (decimal)"),
    feedback: createDescriptionObjectForList(9, "Feedback"),
    amendmentComments: createDescriptionObjectForList(10, "Amendment Comments"),
};

export const BUDGET_COLUMNS_LIST = createListFromObject(BUDGET_COLUMNS);

export const BUDGET_TYPES = {
    applicationBudget: createDescriptionObjectForList(1, `DOE 101`),
    amendmentBudget: createDescriptionObjectForList(2, `DOE 151`)
}

export function isAmendmentBudgetType(budgetType) {
    return budgetType === BUDGET_TYPES.amendmentBudget.id;
}