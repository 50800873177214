import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {BPIE_LANDING_WITH_SCHOOL_BPIE} from "../../bpieLocations";
import sbpieAssessmentApi from "../sbpieAssessmentApi";
import BpieComponentView from "../../Assessment/AssessmentEdit/BpieComponentView";
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {bpie} from "../../../../constants/policyEvents";
import {BpiePolicy} from "../../../../components/authorization/policies/BpiePolicy";
import {convertToNumber, isNullOrUndefined} from "../../../../components/common/commonUtilities";
import {ResourcesLink} from "../sbpieConstants";

const SchoolAssessmentContainer = ({
                                     actions,
                                     handleAssessmentRedirect,
                                     handleDataChanged,
                                     history,
                                     indicatorNumber,
                                     loadAssessment,
                                     schoolBpie,
                                 }) => {
    const [assessment, setAssessment] = useState(null);
    const canEdit = AuthorizationUtilities.allow(BpiePolicy, bpie.schoolBpieAssessments.MODIFY);
    const convertedIndicator = convertToNumber(indicatorNumber);

    const handleSaveResponseAndRedirect = (response, bpieIndicatorId, desiredIndicator) => {
        if (schoolBpie.isActive && canEdit)
            actions.executeApi(
                sbpieAssessmentApi.saveSbpieIndicatorResponse, [schoolBpie.schoolBpieId, bpieIndicatorId, response])
                .then(() => {
                    handleDataChanged(false);
                    handleAssessmentRedirect(schoolBpie.schoolBpieId, desiredIndicator);
                });
        else
            handleAssessmentRedirect(schoolBpie.schoolBpieId, desiredIndicator);
    };

    useEffect(() => {
        if (!schoolBpie)
            return;

        loadAssessment(setAssessment);
    }, [schoolBpie]);

    useEffect(() => {
        if (!assessment)
            return;

        if (isNullOrUndefined(convertedIndicator) || assessment.indicators.length < convertedIndicator)
            handleAssessmentRedirect(schoolBpie.schoolBpieId);
    }, [assessment]);

    useEffect(() => {
        if (!schoolBpie.isActive)
            history.push(BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(schoolBpie.schoolBpieId));
    }, []);

    if (!assessment || isNullOrUndefined(convertedIndicator))
        return null;

    const ratingsDefinition = <ul>
        <li><strong>Not yet:</strong> There is <strong>no evidence</strong> that the school has put
            in place actions to address this indicator or implement the practice.
        </li>
        <li><strong>Partially–Beginning:</strong> The school is just <strong>beginning</strong> to
            implement the practice—it is not implemented consistently across the entire school,
            and <strong>considerable
                further action or improvement is needed</strong>. <em>For example, there is evidence
                that <strong>one</strong> grade-level or content-area team is effectively
                implementing the practice with some
                SWD; the practice <strong>does not</strong> include those students with <strong>low-incidence
                    disabilities</strong>.</em>
        </li>
        <li><strong>Partially–Almost:</strong> The school is <strong>almost</strong> at full
            implementation and needs only a <strong>minimal amount</strong> of progress to reach
            full implementation
            for <strong>all</strong> SWD. <em>For example, the practice is evident
                in <strong>all</strong> grade-level or content-area classes for
                students with <strong>high-incidence disabilities</strong>; however, there
                are <strong>some</strong> students with <strong>low-incidence
                    disabilities</strong> who
                are <strong>not included</strong> as part of this practice.</em>
        </li>
        <li><strong>Fully:</strong> There is clear evidence that this indicator is <strong>consistently
            practiced</strong> and in place <strong>across the entire school</strong> and <strong>for
            all SWD</strong>.
        </li>
    </ul>;

    return (
        <>
            <BpieComponentView
                canEdit={canEdit}
                indicators={assessment.indicators}
                handleDataChanged={handleDataChanged}
                handleRedirect={handleAssessmentRedirect}
                handleSaveResponseAndRedirect={handleSaveResponseAndRedirect}
                indicatorNumber={convertedIndicator}
                isBpieActive={schoolBpie.isActive}
                lastIndicatorNumber={assessment.indicators[assessment.indicators.length - 1].indicatorNumber}
                ratingsDefinition={ratingsDefinition}
                resources={ResourcesLink}
                responses={assessment.responses}
            />
        </>
    );
}

SchoolAssessmentContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object,
    indicatorNumber: PropTypes.string.isRequired,
    loadAssessment: PropTypes.func.isRequired,
    schoolBpie: PropTypes.object.isRequired,
};

export default SchoolAssessmentContainer;