import React from 'react';
import PropTypes from "prop-types";
import {ExternalLink} from "../../../components/common/ExternalLink";

export const ResourceGlossaryView = ({
                               glossary,
                               resources
                           }) => {
    const glossaryResourcesPipe = glossary && resources ? " | " : "";

    return <>
            {
                (glossary || resources) &&
                <div className={`resources-glossary-container`}>
                    {
                        glossary &&
                        <ExternalLink title={`Glossary`} label={`Glossary`} url={glossary}/>
                    }
                    {glossaryResourcesPipe}
                    {
                        resources &&
                        <ExternalLink title={`Resources`} label={`Resources`} url={resources}/>
                    }
                </div>
            }
    </>
}

ResourceGlossaryView.propTypes = {
    glossary: PropTypes.string,
    resources: PropTypes.string,
};