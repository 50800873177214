import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import * as sharedDataActions from '../../actions/sharedDataActions';
import * as sppActions from '../../actions/sppActions';
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import SelectField from "../../components/common/inputs/SelectField";
import TextField from "../../components/common/inputs/TextField";
import * as publicLocations from "../../constants/publicLocations";
import SppPublicDistrictSearchResults from '../../components/sppPublic/SppPublicDistrictSearchResults';
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {gridConstants} from "../../constants/inputConstants";

export class SppPublicDistrictPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			district: "",
			loadData: false
		};

		this.dateWindowChange = this.dateWindowChange.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.viewDistrictSppDetails = this.viewDistrictSppDetails.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Policies and Procedures - Districts");
	}

	componentDidUpdate() {
		if (!this.state.loadData &&
			!isArrayNullOrEmpty(this.props.dateWindowsAnnualList)) {
			let selectedDateWindowId = this.props.dateWindowId ? this.props.dateWindowId : this.props.dateWindowsAnnualList[0].value;

			this.dateWindowChange(selectedDateWindowId);

			this.setState({loadData: true});
		}
	}

	dateWindowChange(dateWindowId) {
		this.props.actions.changeSelectedDateWindow(dateWindowId);
		this.props.actions.loadDistrictStatuses(dateWindowId);
	}

	onFormChange(event) {
		event.preventDefault();

		this.setState({
			district: event.target.value
		});
	}

	viewDistrictSppDetails(event, districtId, documentId) {
		event.preventDefault();

		this.props.history.push(publicLocations.SPP_DOCUMENT.path
			.replace(publicLocations.SPP_INSTITUTION_ID, districtId)
			.replace(publicLocations.SPP_DOCUMENT_ID, documentId));
	}

	render() {
		if (isArrayNullOrEmpty(this.props.dateWindowsAnnualList)) return null;

		let filteredDistricts;

		if (this.state.district !== "" && this.props.districtStatuses.length > 0) {
			const institutionSearchStrings = this.state.district.split(",").map(d => d.trim());

			filteredDistricts = this.props.districtStatuses.filter(ins => institutionSearchStrings.some(function (search) {
				return (ins.districtName.toLowerCase().indexOf(search.toLowerCase()) >= 0);
			}));
		} else {
			filteredDistricts = this.props.districtStatuses;
		}

		const districtData = filteredDistricts.map((district) => {
			return {
				id: district.districtId,
				name: district.districtName,
				documentId: district.documentId,
				progress: district.progress
			};
		});

		return (

			<section>
				<GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
					<GridColumn>
						<SelectField name="dateWindow"
						             showLabel={true}
						             label="Date Window"
						             onChange={(e) => this.dateWindowChange(e.target.value)}
						             options={this.props.dateWindowsAnnualList}
						             value={this.props.dateWindowId}
						             includeDefaultOption={false}/>
					</GridColumn>
					<GridColumn>
						<TextField name="district"
						           showLabel={true}
						           label="District"
						           onChange={this.onFormChange}
						           value={this.state.district}/>
					</GridColumn>
				</GridRow>
				{
					!this.props.isLoading &&
					filteredDistricts.length === 0 &&
					<p>There is no Policies and Procedures document associated with the selected academic year. For years prior to
						2017-18, <a href="http://beess.fcim.org/sppDistrictDocSearch.aspx" target="_blank"
						            rel="noopener noreferrer">click
							here</a> to search the archives.</p>
				}
				{
					!this.props.isLoading &&
					filteredDistricts.length > 0 &&
					<SppPublicDistrictSearchResults districts={districtData} sppDetails={this.viewDistrictSppDetails}/>
				}
			</section>
		);
	}
}

SppPublicDistrictPage.propTypes = {
	actions: PropTypes.object.isRequired,
	dateWindowsAnnualList: PropTypes.array,
	dateWindowId: PropTypes.string,
	districtStatuses: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	params: PropTypes.object,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		districtStatuses: state.spp.districtStatuses
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, layoutActions, sharedDataActions, sppActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppPublicDistrictPage);