import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import {dispute_resolution as policyEvents}  from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class StateComplaintPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.STATE_COMPLAINTS]);
        this.updatePolicy();
    }

    updatePolicy(){
        //must have EDIT to add/edit/delete state complaints
        this._policy[policyEvents.stateComplaint.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        //must have EDIT to respond to state complaints
        this._policy[policyEvents.stateComplaint.response.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        //must have CERTIFY to audit state complaint responses
        this._policy[policyEvents.stateComplaint.response.AUDIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        //must have CERTIFY to review state complaint responses
        this._policy[policyEvents.stateComplaint.response.REVIEW] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        //must have CERTIFY to submit complaint responses
        this._policy[policyEvents.stateComplaint.response.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };
    }

}

export {StateComplaintPolicy, policyEvents};