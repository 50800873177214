import React from "react";
import PropTypes from "prop-types";
import * as elementTypes from "../../constants/surveys/elementTypes";
import FormTable from "../common/FormTable";
import SelectField from "../common/inputs/SelectField";
import TextField from "../common/inputs/TextField";
import CheckboxField from "../common/inputs/CheckBoxField";
import Button from "../common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as buttonBarPositions from "../../constants/ButtonBarPositions";
import * as buttonTypes from "../../constants/ButtonTypes";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import GridTable from "../common/GridTable";

const EditElementForm = ({
  element,
  isLoading,
  onElementChange,
  onSaveElement,
  onCancelChanges,
  isEditItemNew
}) => {
  const showNonTitleFields = element.elementType !== elementTypes.TITLE;
  let typeOptions;
  if (showNonTitleFields)
    typeOptions = [
      { text: elementTypes.TEXTBOX, value: elementTypes.TEXTBOX },
      { text: elementTypes.TEXTAREA, value: elementTypes.TEXTAREA },
      { text: elementTypes.SELECT, value: elementTypes.SELECT }
    ];

  return (
    <div>
      { !isEditItemNew &&
        <p>
          <strong>
            Note - editing should only be used for minor changes; otherwise, add
            it as a new element or clone the entire element set.
          </strong>
        </p>
      }
      <FormTable>
        {showNonTitleFields && (
          <SelectField
            label={"Type"}
            name={"elementType"}
            value={element.elementType}
            showLabel
            options={typeOptions}
            includeDefaultOption={false}
            onChange={e => onElementChange(e, element)}
          />
        )}

        <TextField
          name={"labelText"}
          label={"Label"}
          onChange={e => onElementChange(e, element)}
          value={element.labelText}
          placeholder={"Label Text"}
          showLabel
        />
        {!showNonTitleFields && (
          <TextField
            name={"subtext"}
            label={"Sub-Label"}
            onChange={e => onElementChange(e, element)}
            value={element.subtext}
            placeholder={"Sub-Label Text"}
            showLabel
          />
        )}

        {showNonTitleFields && (
          <CheckboxField
            label={"Is Required"}
            name={"isRequired"}
            checked={element.isRequired}
            onChange={e => onElementChange(e, element)}
            showLabel
          />
        )}

        {element.elementType === elementTypes.SELECT && (
          <GridRow>
            <GridColumn small={"12"} medium={"2"}>
              <label>Choices</label>
            </GridColumn>
            <GridColumn small={"12"} medium={"10"}>
              <GridTable>
                <thead>
                  <tr className="background-color-none">
                    <th>Report Value</th>
                    <th colSpan="2">Display Value</th>
                  </tr>
                </thead>
                <tbody>
                  {element.answerChoices.map((choice, i) => {
                    return (
                      <tr key={"answerchoice-" + i}>
                        <td>
                          <TextField
                            label={"Report Value"}
                            showLabel={false}
                            value={choice.value}
                            name={"value|" + choice.componentId}
                            onChange={e => onElementChange(e, element, choice)}
                          />
                        </td>
                        <td>
                          <TextField
                            label={"Display Value"}
                            showLabel={false}
                            value={choice.text}
                            name={"text|" + choice.componentId}
                            onChange={e => onElementChange(e, element, choice)}
                          />
                        </td>
                        <td>
                          <Button
                            onClick={e => onElementChange(e, element, choice)}
                            name={"remove|" + element.componentId}
                            buttonType={buttonTypes.DELETE}
                            label={"Remove Answer Choice"}
                            showLabel={false}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </GridTable>
              <Button
                onClick={e => onElementChange(e, element)}
                label={"Add Answer Choice"}
                buttonType={buttonTypes.ADD}
                name={"add|" + element.componentId}
              />
            </GridColumn>
          </GridRow>
        )}
      </FormTable>
      <ButtonBar position={buttonBarPositions.BOTTOM}>
        <Button
          name={"save"}
          label={"Save"}
          onClick={e => onSaveElement(e, element)}
          buttonType={buttonTypes.SAVE}
          disabled={isLoading}
        />
        <Button
          name={"cancel"}
          label={"Cancel"}
          onClick={onCancelChanges}
          buttonType={buttonTypes.CANCEL}
          disabled={isLoading}
        />
      </ButtonBar>
    </div>
  );
};

EditElementForm.propTypes = {
  element: PropTypes.object.isRequired,
  onElementChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveElement: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  isEditItemNew: PropTypes.bool.isRequired
};

EditElementForm.defaultProps = {
    isEditItemNew: false
};

export default EditElementForm;
