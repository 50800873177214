import React from "react";
import PropTypes from 'prop-types';
import {
    createListItem,
    dynamicSort, isArray,
    isNullOrUndefined,
} from "../../components/common/commonUtilities";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import LocalStorage from "../../components/shared/LocalStorage";
import SummaryView from "../../components/summary/SummaryView";
import {DISPUTE_RESOLUTION_CONTEXT} from "../../constants/contexts";
import {dateSelectOptionChoices} from "../../constants/inputConstants";
import {ConvertStateComplaintToSingular} from "../drConstants";

export const DrSummaryUpcoming = ({
                                      drSummarySearchCriteria,
                                      handleClickGeneral,
                                      setDateRangeSearchCriteria,
                                      setDrSummarySearchCriteria,
                                      summary,
                                      updatePinnedItems
                                  }) => {
    const createItemsToPinList = (summaryList) => {
        const user = LocalStorage.getLoggedInUser();
        const userId = !isNullOrUndefined(user) ? user.Id : "";

        let pinList = summaryList
            .filter(f => !f.isPinned)
            .map((district) => {
                let role = "";
                const teamMembers = district.teamMembers?.filter(f => f.userId === userId);
                if (teamMembers && teamMembers.length > 0) {
                    if (isArray(teamMembers)) {
                        role = ` (`
                        for (let teamMember of teamMembers)
                            role += `${teamMember.roleDescription}, `;
                        role = role.substring(0, role.length - 2) + `)`
                    } else
                        role = ` (${teamMembers.roleDescription})`;
                }

                return createListItem(`${district.id}|${district.type}`, `${ConvertStateComplaintToSingular(district.typeDescription)} ${district.title}${role}`);
            });

        pinList = pinList.sort(dynamicSort("text"));

        return pinList;
    }

    const buildDrSearchCriteria = (dateRangeType, startDate, endDate) => {
        return new DrSearchCriteria(drSummarySearchCriteria.dateWindowId, dateRangeType, startDate, endDate, "title", "title", drSummarySearchCriteria.disputeResolutionTypeWithDefault);
    };

    return <SummaryView
        buildSearchCriteria={buildDrSearchCriteria}
        containerId={"upcoming"}
        context={DISPUTE_RESOLUTION_CONTEXT}
        createItemsToPinList={createItemsToPinList}
        dateSelectOptionChoice={dateSelectOptionChoices.upcoming}
        handleClickGeneral={handleClickGeneral}
        setDateRangeSearchCriteria={setDateRangeSearchCriteria}
        setSummarySearchCriteria={setDrSummarySearchCriteria}
        summary={summary}
        summarySearchCriteria={drSummarySearchCriteria}
        summaryViewTitle={"Upcoming"}
        updatePinnedItems={updatePinnedItems}
        visibilityRules={DrFilterVisibilityRules.DrSummaryUpcoming}
    />;
};

DrSummaryUpcoming.propTypes = {
    drSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    setDateRangeSearchCriteria: PropTypes.func.isRequired,
    setDrSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array,
    updatePinnedItems: PropTypes.func.isRequired
};

export default DrSummaryUpcoming;