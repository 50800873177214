import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import FormTable from "../../../../components/common/FormTable";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import GridRow from "../../../../components/common/GridRow";
import GridColumn from "../../../../components/common/GridColumn";
import {createSchoolAdmin} from "../bpieSchoolAdminFactory";
import {gridConstants} from "../../../../constants/inputConstants";
import BpieSchoolAdministratorForm from "../BpieSchoolAdministratorForm";
import {emptyGuid} from "../../../../constants/config";

export const BpieSchoolSummaryCurrentSchoolDetails = ({
                                                          actions,
                                                          currentSchoolBpieId,
                                                          handleReloadSchoolAdministrator,
                                                          handleResendNotification,
                                                          hasAdministrator,
                                                          schoolDetails
                                                      }) => {

    const [isEditing, setIsEditing] = useState(false);
    const [isReplacement, setIsReplacement] = useState(false);
    const schoolAdmin = createSchoolAdmin(schoolDetails.currentSchoolAdministrator || {});

    const showAdminButtons = currentSchoolBpieId !== emptyGuid;
    const handleClickEdit = (event, isReplacement) => {
        event.preventDefault();
                setIsReplacement(isReplacement);
        setIsEditing(true);
    }

    const handleReloadSchoolAdministratorForSchoolDetails = (schoolBpieId, result) => {
        setIsEditing(false);
        handleReloadSchoolAdministrator(schoolBpieId, result);
    }

    const handleClickCancel = () => {
        setIsEditing(false);
    }

    const handleClickResendNotification = (event) => {
        event.preventDefault();
        handleResendNotification();
    }

    if (!schoolAdmin)
        return null;

    return (
        <FormTable>
            <DisplayField
                name={"schoolDistrict"}
                label={"School District:"}
                showLabel
                isInline>
                {schoolDetails.schoolDistrict}
            </DisplayField>
            <DisplayField
                name={"currentSchoolName"}
                label={"Current School Name:"}
                showLabel
                isInline>
                {schoolDetails.currentSchoolNameWithMsid}
            </DisplayField>

            <DisplayField
                name={`currentSchoolAdministrator`}
                label={`Current School Administrator:`}
                showLabel>
                {
                    !isEditing &&
                    <GridRow medium={gridConstants.column.TWO} rowClass={"summary-school-admin"}>
                        <GridColumn align={"middle"}>
                            {schoolAdmin.displayName}
                        </GridColumn>
                        <Allow policy={BpiePolicy}
                               policyEvent={policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR}>
                            <GridColumn>
                                {!hasAdministrator && showAdminButtons &&
                                    <Button name={"add"}
                                            label={"Add School Administrator"}
                                            showLabel={true}
                                            buttonType={ButtonTypes.ADD}
                                            onClick={(event) => handleClickEdit(event, false)}
                                    />
                                }
                                {hasAdministrator && showAdminButtons &&
                                    <>
                                        <Button name={"edit"}
                                                label={"Edit"}
                                                showLabel={false}
                                                buttonType={ButtonTypes.EDIT}
                                                onClick={(event) => handleClickEdit(event, false)}
                                        />
                                        <Button name={"replace"}
                                                label={"Replace"}
                                                showLabel
                                                buttonType={ButtonTypes.REPLACE}
                                                onClick={(event) => handleClickEdit(event, true)}
                                        />
                                        {
                                            schoolDetails.canResendPrincipalNotification &&
                                            <Button
                                                label={`Resend Principal Email`}
                                                name={`resendNotification`}
                                                onClick={handleClickResendNotification}
                                                showLabel
                                            />
                                        }
                                    </>
                                }
                            </GridColumn>
                        </Allow>
                    </GridRow>
                }
                {
                    isEditing &&
                    <BpieSchoolAdministratorForm
                        actions={actions}
                        handleCancelClick={handleClickCancel}
                        handleReloadSchoolAdministrator={handleReloadSchoolAdministratorForSchoolDetails}
                        isReplacement={isReplacement}
                        schoolAdmin={isReplacement ? createSchoolAdmin({}) : schoolAdmin}
                        schoolBpieId={schoolDetails.currentSchoolBpieId}
                    />
                }
            </DisplayField>
        </FormTable>
    );
};

BpieSchoolSummaryCurrentSchoolDetails.propTypes = {
    actions: PropTypes.object.isRequired,
    currentSchoolBpieId: PropTypes.string.isRequired,
    handleReloadSchoolAdministrator: PropTypes.func.isRequired,
    handleResendNotification: PropTypes.func.isRequired,
    hasAdministrator: PropTypes.bool.isRequired,
    schoolDetails: PropTypes.object.isRequired,
};