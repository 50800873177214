import SurveyAdminApi from "../api/SurveyAdminApi";
import {resetComponentIdBackToEmptyGuid} from "../components/surveyAdmin/surveyUtilities";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import * as config from "../constants/config";

export function loadSurveyInstancesSuccess(surveyInstances) {
	return {type: actionTypes.LOAD_SURVEY_INSTANCES_AJAX_SUCCESS, data: surveyInstances};
}

export function loadSurveyInstances() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getSurveyInstances()
			.then(surveys => {
				dispatch(loadSurveyInstancesSuccess(surveys));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadSurveyDateWindowsSuccess(dateWindows) {
	return {type: actionTypes.LOAD_DATE_WINDOWS_AJAX_SUCCESS, data: dateWindows};
}

export function loadSurveyDateWindows() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getSurveyDateWindows()
			.then(dateWindows => {
				dispatch(loadSurveyDateWindowsSuccess(dateWindows));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadSurveyDetailsSuccess(details) {
	return {type: actionTypes.LOAD_SURVEY_DETAILS_AJAX_SUCCESS, data: details};
}

export function tryLoadSurveyDetails(surveyInstanceId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getSurveyInstance(surveyInstanceId)
			.then(details => {
				dispatch(loadSurveyDetailsSuccess(details));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function updateOpenComponents(componentId) {
	return {type: actionTypes.UPDATE_OPEN_COMPONENTS, data: componentId};
}

export function saveSurveyDetailsSuccess(details) {
	return {type: actionTypes.SAVE_SURVEY_DETAILS_AJAX_SUCCESS, data: details};
}

export function saveSurveyDetails(details) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.saveSurveyDetails(details)
			.then(() => {
				dispatch(saveSurveyDetailsSuccess(details));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function saveElementOrderSuccess() {
	return {type: actionTypes.SAVE_ELEMENT_ORDER_AJAX_SUCCESS};
}

export function saveElementOrder(surveyId, sortedElements) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.saveElementOrder(surveyId, sortedElements)
			.then(() => {
				dispatch(saveElementOrderSuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function publishSurveySuccess() {
	return {type: actionTypes.PUBLISH_SURVEY_AJAX_SUCCESS};
}

export function tryPublishSurvey(surveyId, closeDate) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.publishSurvey(surveyId, closeDate)
			.then(() => {
				dispatch(publishSurveySuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function unpublishSurveySuccess() {
	return {type: actionTypes.UNPUBLISH_SURVEY_AJAX_SUCCESS};
}

export function tryUnpublishSurvey(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.unpublishSurvey(surveyId)
			.then(() => {
				dispatch(unpublishSurveySuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function closeSurveySuccess() {
	return {type: actionTypes.CLOSE_SURVEY_AJAX_SUCCESS};
}

export function tryCloseSurvey(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.closeSurvey(surveyId)
			.then(() => {
				dispatch(closeSurveySuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function remindSurveyRespondentsSuccess() {
	return {type: actionTypes.REMIND_SURVEY_RESPONDENTS_AJAX_SUCCESS};
}

export function remindSurveyRespondents(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.remindRespondents(surveyId)
			.then(() =>
				dispatch(remindSurveyRespondentsSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function deleteSurveyRespondentSuccess(surveyRespondentId) {
	return {type: actionTypes.DELETE_SURVEY_RESPONDENT_AJAX_SUCCESS, data: surveyRespondentId};
}

export function deleteSurveyRespondent(surveyRespondentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.deleteRespondent(surveyRespondentId)
			.then(() =>
				dispatch(deleteSurveyRespondentSuccess(surveyRespondentId))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function unsubmitRespondentSurveySuccess(surveyRespondentId) {
	return {type: actionTypes.UNSUBMIT_SURVEY_RESPONDENT_AJAX_SUCCESS, data: surveyRespondentId};
}

export function unsubmitRespondentSurvey(surveyRespondentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.unsubmitRespondentSurvey(surveyRespondentId)
			.then(() =>
				dispatch(unsubmitRespondentSurveySuccess(surveyRespondentId))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function loadSurveyRespondentsSuccess(respondents) {
	return {type: actionTypes.LOAD_SURVEY_RESPONDENTS_AJAX_SUCCESS, data: respondents};
}

export function loadSurveyRespondents(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getSurveyRespondents(surveyId)
			.then(respondents =>
				dispatch(loadSurveyRespondentsSuccess(respondents))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function importSurveyRespondentsSuccess() {
	return {type: actionTypes.IMPORT_SURVEY_RESPONDENTS_AJAX_SUCCESS};
}

export function tryImportSurveyRespondents(files, surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.importSurveyRespondents(files, surveyId)
			.then(() =>
				dispatch(importSurveyRespondentsSuccess())
			)
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}


export function downloadSampleRespondentImportFileSuccess() {
	return {type: actionTypes.DOWNLOAD_SAMPLE_RESPONDENT_IMPORT_FILE_AJAX_SUCCESS};
}

export function downloadSampleRespondentImportFile() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.downloadSampleRespondentImportFile()
			.then(() =>
				dispatch(downloadSampleRespondentImportFileSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function loadSurveyElementsSuccess(elements) {
	return {type: actionTypes.LOAD_SURVEY_ELEMENTS_AJAX_SUCCESS, data: elements};
}

export function loadSurveyElements(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getSurveyElements(surveyId)
			.then((elements) =>
				dispatch(loadSurveyElementsSuccess(elements))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function deleteSurveyElementSuccess(elementId) {
	return {type: actionTypes.DELETE_SURVEY_ELEMENT_AJAX_SUCCESS, data: elementId};
}

export function deleteSurveyElement(surveyId, elementId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.deleteSurveyElement(surveyId, elementId)
			.then(() =>
				dispatch(deleteSurveyElementSuccess(elementId))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function addSurveyElementsSuccess() {
	return {type: actionTypes.ADD_SURVEY_ELEMENTS_AJAX_SUCCESS};
}

export function addSurveyElements(surveyId, elements) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.addSurveyElements(surveyId, resetComponentIdBackToEmptyGuid(elements))
			.then(() => {
				dispatch(addSurveyElementsSuccess(elements));
				return true;
			})
			.catch((error) => {
				dispatch(ajaxCallError(error));
				return false;
			});
	};
}

export function updateSurveyElementsSuccess() {
	return {type: actionTypes.UPDATE_SURVEY_ELEMENTS_AJAX_SUCCESS};
}

export function updateSurveyElements(surveyId, elements) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.updateSurveyElements(surveyId, resetComponentIdBackToEmptyGuid(elements))
			.then(() =>
				dispatch(updateSurveyElementsSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function cloneSurveyElementSuccess() {
	return {type: actionTypes.CLONE_SURVEY_ELEMENT_AJAX_SUCCESS};
}

export function cloneSurveyElement(surveyId, componentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.cloneSurveyElement(surveyId, componentId)
			.then(() => {
				dispatch(cloneSurveyElementSuccess());
				return true;
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				return false;
			});
	};
}


export function clearSurveyInstanceData() {
	return {type: actionTypes.CLEAR_SURVEY_INSTANCE_DATA};
}

export function copySurveySuccess() {
	return {type: actionTypes.COPY_SURVEY_AJAX_SUCCESS};
}

export function tryCopySurvey(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.copySurvey(surveyId)
			.then(() =>
				dispatch(copySurveySuccess())
			)
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function changeSurveyDetails(details) {
	return {type: actionTypes.CHANGE_SURVEY_DETAILS, data: details};
}

export function createElementSet(componentId) {
	return {type: actionTypes.CREATE_ELEMENT_SET, data: componentId};
}

export function createElement(parentId, componentId) {
	return {type: actionTypes.CREATE_ELEMENT, data: {parentId, componentId}};
}

export function editElement(componentId) {
	return {type: actionTypes.EDIT_ELEMENT, data: componentId};
}

export function changeElement(element) {
	return {type: actionTypes.CHANGE_ELEMENT, data: element};
}

export function createAnswerChoice(parentId, componentId) {
	return {type: actionTypes.CREATE_ANSWER_CHOICE, data: {parentId, componentId}};
}

export function removeAnswerChoice(parentId, componentId) {
	return {type: actionTypes.REMOVE_ANSWER_CHOICE, data: {parentId, componentId}};
}

export function changeAnswerChoice(parentId, answerChoice) {
	return {type: actionTypes.CHANGE_ANSWER_CHOICE, data: {parentId, answerChoice}};
}

export function loadSurveyResponsesReportSuccess() {
	return {type: actionTypes.LOAD_SURVEY_RESPONSES_REPORT_AJAX_SUCCESS};
}

export function loadSurveyResponsesReport(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getReport(config.apiUrl + "surveys/GetResponseReport/" + surveyId)
			.then(() =>
				dispatch(loadSurveyResponsesReportSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function loadSurveyProgressReportSuccess() {
	return {type: actionTypes.LOAD_SURVEY_PROGRESS_REPORT_AJAX_SUCCESS};
}

export function loadSurveyProgressReport(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getReport(config.apiUrl + "surveys/GetProgressReport/" + surveyId)
			.then(() =>
				dispatch(loadSurveyProgressReportSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function loadSurveyDiscretionaryProjectReportSuccess() {
	return {type: actionTypes.LOAD_SURVEY_DISCRETIONARY_PROJECT_REPORT_AJAX_SUCCESS};
}

export function loadSurveyDiscretionaryProjectReport(surveyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SurveyAdminApi.getReport(config.apiUrl + "surveys/GetDiscretionaryProjectReport/" + surveyId)
			.then(() =>
				dispatch(loadSurveyDiscretionaryProjectReportSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

