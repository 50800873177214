import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../gmsConstants";
import {API_ROOT} from "../gmsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsSummaryApi {
    static exportApplications(dateWindowId) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/dates/${dateWindowId}/applications/export`,
                RequestTypes.GET)
        );
    }

    static deleteApplication(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.APPLICATION_SETTINGS.deleted)
        );
    }

    static importApplications(dateWindowId, files) {
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let data = new FormData();
        data.append('file', files[0]);

        let request = AjaxRequestOptions(
            `${config.apiUrl}${API_ROOT}/admin/dates/${dateWindowId}/applications/import`,
            RequestTypes.POST,
            data,
            API_MESSAGES.ADMIN.IMPORT.success);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsSummaryApi {
    static exportApplications(){
        return ApiUtility.mockResolveApiCall();
    }

    static importApplications(){
        return ApiUtility.mockResolveApiCall();
    }

    static deleteApplication(){
        return ApiUtility.mockResolveApiCall("", API_MESSAGES.ADMIN.APPLICATION_SETTINGS.deleted);
    }
}

const GmsSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsSummaryApi : MockGmsSummaryApi;
export default GmsSummaryApi;