import Location from "../../components/shared/Location";

export const API_ROOT = `Pts`;
export const ROOT = `/${API_ROOT}`;
export const PTS_STATE_ROOT = `/${API_ROOT}/Admin`;
export const PTS_DATE_WINDOW_ID = ":dateWindowId";
export const PROGRAM_ID = ":programId";
export const DISCRETIONARY_PROJECT_ID = ":discretionaryProjectId";
export const REPORT_TYPE = ":reportType";
export const DATE_RANGE_TYPE = ":dateRangeType";
export const START_DATE = ":startDate";
export const END_DATE = ":endDate";
export const DATE_WINDOW_DASHBOARD_PARAM = "dateWindowId";
export const PTS_STATUS_DASHBOARD_PARAM = "ptsStatus";
export const PTS_TEAM_MEMBER_DASHBOARD_PARAM = "teamMemberId";

// ************************ SHARED ROUTES ************************
export const PTS_SUMMARY = Location(() => `${ROOT}/Summary`);
export const PTS_PROJECT_INFORMATION = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Information`);
export const PTS_DELIVERABLES_SUMMARY = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Deliverables`);
export const PTS_HTML_REPORTS = Location ((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID, reportType = REPORT_TYPE, dateRangeType = DATE_RANGE_TYPE, startDate = START_DATE, endDate = END_DATE) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Reports/${reportType}/dateRangeType/${dateRangeType}/startDate/${startDate}/endDate/${endDate}`);
export const PTS_REPORTS = Location((dateWindowId = PTS_DATE_WINDOW_ID, discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${ROOT}/Dates/${dateWindowId}/Projects/${discretionaryProjectId}/Reports`);

// ************************ STATE ADMIN ROUTES ********************************

export const PTS_ADMIN_GMS_USERS = Location(() => `${PTS_STATE_ROOT}/GmsUsers/`);
export const PTS_ADMIN_MANAGE_PROGRAMS = Location(() => `${PTS_STATE_ROOT}/Programs/`);
export const PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS = Location((programid = PROGRAM_ID) => `${PTS_STATE_ROOT}/Programs/${programid}/agentsUsers`);
export const PTS_ADMIN_IMPORT_PROJECTS = Location(() => `${PTS_STATE_ROOT}/Projects/Import/`);
export const PTS_ADMIN_IMPORT_ACTIVITIES = Location(() => `${PTS_STATE_ROOT}/Activities/Import/`);

export const ALL_PTS_ROUTES = [
    PTS_ADMIN_GMS_USERS,
    PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS,
    PTS_ADMIN_MANAGE_PROGRAMS,
    PTS_ADMIN_IMPORT_PROJECTS,
    PTS_SUMMARY,
    PTS_PROJECT_INFORMATION,
    PTS_DELIVERABLES_SUMMARY,
    PTS_HTML_REPORTS,
    PTS_REPORTS,
    PTS_ADMIN_IMPORT_ACTIVITIES
];