import StrategicPlanApi from "../api/StrategicPlanApi";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function loadFiveYearReportSuccess(report) {
	return {type: actionTypes.LOAD_FIVE_YEAR_REPORT_AJAX_SUCCESS, data: report};
}

export function loadFiveYearReport(dateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getFiveYearReport(dateWindowId)
			.then((report) => {
				dispatch(loadFiveYearReportSuccess(report));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadPlansSuccess(plans) {
	return {type: actionTypes.LOAD_PLANS_AJAX_SUCCESS, data: plans};
}

export function loadPlans() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getPlans()
			.then((plans) => {
				dispatch(loadPlansSuccess(plans));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadPlanDateWindowSuccess(planDateWindow) {
	return {type: actionTypes.LOAD_PLAN_DATE_WINDOW_AJAX_SUCCESS, data: planDateWindow};
}

export function loadPlanDateWindow(planDateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getPlanDateWindow(planDateWindowId)
			.then((plans) => {
				dispatch(loadPlanDateWindowSuccess(plans));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function saveStrategicIndicatorSuccess() {
	return {type: actionTypes.SAVE_INDICATORS_AJAX_SUCCESS};
}

export function trySaveStrategicIndicator(strategyIndicator) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.saveIndicator(strategyIndicator)
			.then(() => {
				dispatch(saveStrategicIndicatorSuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function loadStrategiesSuccess(strategies) {
	return {type: actionTypes.LOAD_STRATEGIES_AJAX_SUCCESS, data: strategies};
}

export function loadStrategies(planDateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getPlanStrategies(planDateWindowId)
			.then((strats) => {
				dispatch(loadStrategiesSuccess(strats));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadActionResourcesSuccess(resources) {
	return {type: actionTypes.LOAD_ACTION_RESOURCES_AJAX_SUCCESS, data: resources};
}

export function loadActionResources() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getActionResources()
			.then((resources) => {
				dispatch(loadActionResourcesSuccess(resources));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function addPlanSuccess(planDateWindowId) {
	return {type: actionTypes.ADD_PLAN_AJAX_SUCCESS, data: planDateWindowId};
}

export function addPlan(newPlan) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addPlan(newPlan)
			.then((planDateWindowId) => {
				dispatch(addPlanSuccess(planDateWindowId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function deleteStrategySuccess(strategyId) {
	return {type: actionTypes.DELETE_STRATEGY_AJAX_SUCCESS, data: strategyId};
}

export function deleteStrategy(strategyId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteStrategy(strategyId)
			.then(() => {
				dispatch(deleteStrategySuccess(strategyId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function addStrategySuccess(strategyId, planDateWindowId) {
	return {type: actionTypes.ADD_STRATEGY_AJAX_SUCCESS, data: {strategyId, planDateWindowId}};
}

export function addStrategy(planDateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addStrategy(planDateWindowId)
			.then((strategyId) => {
				dispatch(addStrategySuccess(strategyId, planDateWindowId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function addActionSuccess(actionId) {
	return {type: actionTypes.ADD_ACTION_AJAX_SUCCESS, data: actionId};
}

export function addAction(action) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addAction(action)
			.then((actionId) => {
				dispatch(addActionSuccess(actionId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function saveActionSuccess() {
	return {type: actionTypes.UPDATE_ACTION_AJAX_SUCCESS};
}

export function saveAction(action) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.saveAction(action)
			.then(() => {
				dispatch(saveActionSuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadStrategicGoalsSuccess(goals) {
	return {type: actionTypes.LOAD_STRATEGIC_GOALS_AJAX_SUCCESS, data: goals};
}

export function loadStrategicGoals() {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.getGoals()
			.then((goals) => {
				dispatch(loadStrategicGoalsSuccess(goals));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function saveStrategyGoalsSuccess() {
	return {type: actionTypes.SAVE_STRATEGY_GOALS_AJAX_SUCCESS};
}

export function saveStrategyGoals(strategyId, goals) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.saveStrategyGoals(strategyId, goals)
			.then(() => {
				dispatch(saveStrategyGoalsSuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function addSubActionSuccess(actionId, subActionId) {
	return {type: actionTypes.ADD_SUBACTION_AJAX_SUCCESS, data: {actionId, subActionId}};
}

export function addSubAction(actionId, subAction) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addSubAction(actionId, subAction)
			.then((subActionId) => {
				dispatch(addSubActionSuccess(actionId, subActionId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function updateSubActionSuccess() {
	return {type: actionTypes.UPDATE_SUBACTION_AJAX_SUCCESS};
}

export function saveSubAction(actionId, subAction) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.updateSubAction(actionId, subAction)
			.then((subActionId) => {
				dispatch(updateSubActionSuccess(actionId, subActionId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function deleteProgressMonitoringActivitySuccess(strategyId, actionId, subAction) {
	return {
		type: actionTypes.DELETE_PROGRESS_MONITORING_ACTIVITY_AJAX_SUCCESS,
		data: {strategyId, actionId, subAction}
	};
}

export function deleteProgressMonitoringActivity(strategyId, actionId, subAction) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.updateSubAction(actionId, subAction)
			.then(() => {
				dispatch(deleteProgressMonitoringActivitySuccess(strategyId, actionId, subAction));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function deleteSubActionSuccess(strategyId, actionId, subActionId) {
	return {type: actionTypes.DELETE_SUBACTION_AJAX_SUCCESS, data: {strategyId, actionId, subActionId}};
}

export function deleteSubAction(strategyId, actionId, subActionId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteSubAction(strategyId, actionId, subActionId)
			.then(() => {
				dispatch(deleteSubActionSuccess(strategyId, actionId, subActionId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function deleteActionSuccess(strategyId, actionId) {
	return {type: actionTypes.DELETE_ACTION_AJAX_SUCCESS, data: {strategyId, actionId}};
}

export function deleteAction(strategyId, actionId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteAction(strategyId, actionId)
			.then(() => {
				dispatch(deleteActionSuccess(strategyId, actionId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function deleteIndicatorSuccess(strategyId, strategyIndicatorId) {
	return {type: actionTypes.DELETE_STRATEGY_INDICATOR_AJAX_SUCCESS, data: {strategyId, strategyIndicatorId}};
}

export function tryDeleteIndicator(strategyId, strategyIndicatorId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteIndicator(strategyId, strategyIndicatorId)
			.then(() =>
				dispatch(deleteIndicatorSuccess(strategyId, strategyIndicatorId))
			)
			.catch(error => {
					dispatch(ajaxCallError(error));
					throw error;
				}
			);
	};
}

export function saveTeamMembersSuccess() {
	return {type: actionTypes.SAVE_TEAM_MEMBERS_AJAX_SUCCESS};
}

export function saveTeamMembers(planDateWindowId, teamMembers) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.saveTeamMembers(planDateWindowId, teamMembers)
			.then(() =>
				dispatch(saveTeamMembersSuccess())
			)
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function copyPlanDateWindowSuccess(oldPlanDateWindowId, newDateWindowId, newPlanDateWindowId) {
	return {
		type: actionTypes.COPY_PLAN_DATE_WINDOW_AJAX_SUCCESS,
		data: {oldPlanDateWindowId, newDateWindowId, newPlanDateWindowId}
	};
}

export function copyPlanDateWindow(oldPlanDateWindowId, newDateWindowId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.copyPlanDateWindow(oldPlanDateWindowId, newDateWindowId)
			.then((newPlanDateWindowId) => {
				dispatch(copyPlanDateWindowSuccess(oldPlanDateWindowId, newDateWindowId, newPlanDateWindowId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function addResourceSuccess(resourceId) {
	return {type: actionTypes.ADD_RESOURCE_AJAX_SUCCESS, data: resourceId};
}

export function addResource(resource) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addResource(resource)
			.then((resourceId) => {
				dispatch(addResourceSuccess(resourceId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function updateResourceSuccess() {
	return {type: actionTypes.UPDATE_RESOURCE_AJAX_SUCCESS};
}

export function updateResource(resource) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.updateResource(resource)
			.then(() =>
				dispatch(updateResourceSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function deleteResourceSuccess(resource) {
	return {type: actionTypes.DELETE_RESOURCE_AJAX_SUCCESS, data: resource};
}

export function deleteResource(resource) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteResource(resource)
			.then(() =>
				dispatch(deleteResourceSuccess(resource))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function addGoalSuccess(goalId) {
	return {type: actionTypes.ADD_GOAL_AJAX_SUCCESS, data: goalId};
}

export function addGoal(goal) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.addGoal(goal)
			.then((goalId) => {
				dispatch(addGoalSuccess(goalId));
			})
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function updateGoalSuccess() {
	return {type: actionTypes.UPDATE_GOAL_AJAX_SUCCESS};
}

export function updateGoal(goal) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.updateGoal(goal)
			.then(() =>
				dispatch(updateGoalSuccess())
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function deleteGoalSuccess(goal) {
	return {type: actionTypes.DELETE_GOAL_AJAX_SUCCESS, data: goal};
}

export function deleteGoal(goal) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deleteGoal(goal)
			.then(() =>
				dispatch(deleteGoalSuccess(goal))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function deletePlanDateWindowSuccess(planDateWindow) {
	return {type: actionTypes.DELETE_PLAN_DATE_WINDOW_AJAX_SUCCESS, data: planDateWindow};
}

export function deletePlanDateWindow(planDateWindow) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.deletePlanDateWindow(planDateWindow.planDateWindowId)
			.then(() =>
				dispatch(deletePlanDateWindowSuccess(planDateWindow))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function updatePlanDateWindowSuccess(planDateWindow) {
	return {type: actionTypes.UPDATE_PLAN_DATE_WINDOW_AJAX_SUCCESS, data: planDateWindow};
}

export function updatePlanDateWindow(planDateWindow) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return StrategicPlanApi.updatePlanDateWindow(planDateWindow)
			.then(() =>
				dispatch(updatePlanDateWindowSuccess(planDateWindow))
			)
			.catch(error =>
				dispatch(ajaxCallError(error))
			);
	};
}

export function clearPlanData() {
	return {type: actionTypes.CLEAR_PLAN_DATA};
}

export function updatePlanFilters(filters) {
	return {type: actionTypes.UPDATE_PLAN_FILTERS, data: filters};
}

export function createStrategyIndicator(strategyId, strategicIndicatorId) {
	return {type: actionTypes.CREATE_STRATEGY_INDICATOR, data: {strategyId, strategicIndicatorId}};
}

export function updateIndicator(strategicIndicatorId, indicatorText) {
	return {type: actionTypes.UPDATE_INDICATOR, data: {strategicIndicatorId, indicatorText}};
}

export function addIndicator(strategicIndicatorId, indicatorText) {
	return {type: actionTypes.CREATE_INDICATOR, data: {strategicIndicatorId, indicatorText}};
}

export function removeIndicator(strategicIndicatorId, indicatorText) {
	return {type: actionTypes.REMOVE_INDICATOR, data: {strategicIndicatorId, indicatorText}};
}

export function addStrategyAction(strategyId) {
	return {type: actionTypes.CREATE_STRATEGY_ACTION, data: {strategyId}};
}

export function updateStrategyAction(action) {
	return {type: actionTypes.UPDATE_STRATEGY_ACTION, data: action};
}

export function editStrategyAction(actionId) {
	return {type: actionTypes.EDIT_STRATEGY_ACTION, data: actionId};
}

export function editStrategyGoals(strategyId) {
	return {type: actionTypes.EDIT_STRATEGY_GOALS, data: strategyId};
}

export function updateStrategyGoals(strategyId, goals) {
	return {type: actionTypes.UPDATE_STRATEGY_GOALS, data: {strategyId, goals}};
}

export function createSubAction(strategyId, actionId) {
	return {type: actionTypes.CREATE_SUBACTION, data: {strategyId, actionId}};
}

export function updateSubAction(actionId, subAction) {
	return {type: actionTypes.UPDATE_SUBACTION, data: {actionId, subAction}};
}

export function editSubAction(subActionId) {
	return {type: actionTypes.EDIT_SUBACTION, data: subActionId};
}

export function createProgressMonitoringActivity(strategyId, actionId, subActionId, noteId) {
	return {type: actionTypes.CREATE_PROGRESS_MONITORING_ACTIVITY, data: {strategyId, actionId, subActionId, noteId}};
}

export function updateProgressMonitoringActivity(strategyId, actionId, subActionId, activity) {
	return {type: actionTypes.UPDATE_PROGRESS_MONITORING_ACTIVITY, data: {strategyId, actionId, subActionId, activity}};
}

export function editProgressMonitoringActivity(id) {
	return {type: actionTypes.EDIT_PROGRESS_MONITORING_ACTIVITY, data: id};
}

export function editStrategyIndicator(strategyIndicatorId) {
	return {type: actionTypes.EDIT_STRATEGY_INDICATOR, data: strategyIndicatorId};
}

export function createTeamMember(id) {
	return {type: actionTypes.CREATE_TEAM_MEMBER, data: id};
}

export function updateTeamMember(teamMember) {
	return {type: actionTypes.UPDATE_TEAM_MEMBER, data: teamMember};
}

export function removeTeamMember(teamMember) {
	return {type: actionTypes.REMOVE_TEAM_MEMBER, data: teamMember};
}

export function editTeamMembers(planDateWindowId) {
	return {type: actionTypes.EDIT_TEAM_MEMBERS, data: planDateWindowId};
}

export function updateBaselineMetric(strategyId, strategyIndicatorId, baseline) {
	return {type: actionTypes.UPDATE_BASELINE_METRIC, data: {strategyId, strategyIndicatorId, baseline}};
}

export function updateMetric(strategyId, strategyIndicatorId, metric) {
	return {type: actionTypes.UPDATE_METRIC, data: {strategyId, strategyIndicatorId, metric}};
}

export function createMetric(strategyId, strategyIndicatorId, id) {
	return {type: actionTypes.CREATE_METRIC, data: {strategyId, strategyIndicatorId, id}};
}

export function editMetrics(baselineId) {
	return {type: actionTypes.EDIT_METRICS, data: baselineId};
}

export function addIndicatorTriangleFile(strategyId, strategyIndicatorId, fileId) {
	return {type: actionTypes.ADD_INDICATOR_FILE, data: {strategyId, strategyIndicatorId, fileId}};
}

export function removeIndicatorTriangleFile(strategyId, strategyIndicatorId) {
	return {type: actionTypes.REMOVE_INDICATOR_FILE, data: {strategyId, strategyIndicatorId}};
}

export function createResource() {
	return {type: actionTypes.CREATE_RESOURCE};
}

export function editResource(resourceId) {
	return {type: actionTypes.EDIT_RESOURCE, data: resourceId};
}

export function changeResource(resource) {
	return {type: actionTypes.CHANGE_RESOURCE, data: resource};
}

export function createGoal() {
	return {type: actionTypes.CREATE_GOAL};
}

export function editGoal(goalId) {
	return {type: actionTypes.EDIT_GOAL, data: goalId};
}

export function changeGoal(goal) {
	return {type: actionTypes.CHANGE_GOAL, data: goal};
}

export function createPlanDateWindow(dateWindowId) {
	return {type: actionTypes.CREATE_PLAN_DATE_WINDOW, data: dateWindowId};
}

export function changePlanDateWindow(planDateWindow) {
	return {type: actionTypes.CHANGE_PLAN_DATE_WINDOW, data: planDateWindow};
}