import React from 'react';
import PropTypes from 'prop-types';
import SelectFieldAdapter, {searchableSelectFieldProps} from "./SelectFieldAdapter";

const SearchableSingleSelectField = (props) => {
    return <SelectFieldAdapter
        {...props}
        isMulti={false}
    />;
};

SearchableSingleSelectField.propTypes = {
    ...searchableSelectFieldProps,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired
};

export default SearchableSingleSelectField;