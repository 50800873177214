import {getPropName} from "../components/common/commonUtilities";
import * as contexts from "./contexts";

export const USERS = "Users";
export const SURVEYS = "Surveys";
export const SPP = "Policies and Procedures";
export const STRATEGIC_PLAN = "Strategic Plan";
export const MONITORING_PERFORMANCE = "Monitoring for Performance";
export const MONITORING_COMPLIANCE = "Monitoring for Compliance";
export const STATE_COMPLAINTS = "DR State Complaint";
export const DUE_PROCESS = "DR Due Process Hearing Requests";
export const MEDIATIONS = "DR Mediation";
export const BPIE = "BPIE";
export const GMS = "GMS";
export const PTS = "PTS";
export const ADMIN = "System Admin";

export const NOT_INCLUDED_IN_USER_BEHAVIORS = [
    getPropName(contexts, contexts.DISPUTE_RESOLUTION_CONTEXT),
    getPropName(contexts, contexts.DISPUTE_RESOLUTION_CONTEXTS),
    getPropName(contexts, contexts.NO_DISTRICTS),
    getPropName(contexts, contexts.GMS)
];