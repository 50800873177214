import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {DpieGoalDetailsInfo} from "../../DpieGoalDetailsInfo";

export const BpieDistrictPieGoalDetailsInfo = ({
                                                   dpieGoal,
                                                   isEditable,
                                                   isEditingActionStep,
                                                   redirectToGoalSummary,
                                                   sbpiePriorityYearRange,
                                                   setEditingDpieGoalId
                                               }) => {
    const handleEditDpieGoalDetailsClick = (event) => {
        event.preventDefault();
        setEditingDpieGoalId(dpieGoal.dpieGoalId);
    }

    const handleReturnDpieGoalSummaryClick = (event) => {
        event.preventDefault();
        redirectToGoalSummary();
    }

    return <>
        <ButtonBar position={ButtonBarPositions.STICKY}>
            {
                !isEditingActionStep && isEditable &&
                <Button
                    buttonType={ButtonTypes.EDIT}
                    label={`Edit Goal ${dpieGoal.goalNumber}`}
                    name={`btnEditDpieGoalDetails`}
                    onClick={handleEditDpieGoalDetailsClick}
                />
            }

            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to DPIE Goal Summary`}
                name={`btnReturnDpieGoalSummary`}
                onClick={handleReturnDpieGoalSummaryClick}
            />
        </ButtonBar>

        <DpieGoalDetailsInfo dpieGoal={dpieGoal} sbpiePriorityYearRange={sbpiePriorityYearRange} />
    </>;
};

BpieDistrictPieGoalDetailsInfo.propTypes = {
    dpieGoal: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditingActionStep: PropTypes.bool.isRequired,
    redirectToGoalSummary: PropTypes.func.isRequired,
    sbpiePriorityYearRange: PropTypes.string.isRequired,
    setEditingDpieGoalId: PropTypes.func.isRequired
};