import React from "react";
import PropTypes from 'prop-types';
import {EmailLink} from "../../../../components/common/ExternalLink";
import * as config from "../../../../constants/config";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {BpieSchoolSummaryCurrentSchoolDetails} from "./BpieSchoolSummaryCurrentSchoolDetails";
import {BpieSchoolSummarySbpies} from "./BpieSchoolSummarySbpies";
import {BpieSchoolSummaryStatus} from "./BpieSchoolSummaryStatus";

export const BpieSchoolSummaryView = ({
                                          actions,
                                          currentSchoolBpieId,
                                          handleActivateSbpie,
                                          handleCreateSbpie,
                                          handleDeleteSbpie,
                                          handleReloadSchoolAdministrator,
                                          handleResendNotification,
                                          handleUpdateStatus,
                                          isDistrictUser,
                                          schoolBpieId,
                                          schoolDetails
                                      }) => {

    const hasAdministrator = !isTrimmedStringEmpty(schoolDetails.currentSchoolAdministrator?.email);

    return (
        <>
            <p>If any school information is incorrect or you need assistance accessing your account,  <EmailLink address={config.bpieSupportEmail} label={`contact FIN`}/>.
                If you have tech support issues, please <EmailLink address={config.supportEmail} label={`contact support via
                email`}/>.</p>

            {currentSchoolBpieId !== config.emptyGuid &&
                <BpieSchoolSummaryStatus
                    handleActivateSbpie={handleActivateSbpie}
                    handleCreateSbpie={handleCreateSbpie}
                    handleUpdateStatus={handleUpdateStatus}
                    hasAdministrator={hasAdministrator}
                    isDistrictUser={isDistrictUser}
                    schoolDetails={schoolDetails}
                />
            }
            <BpieSchoolSummaryCurrentSchoolDetails
                actions={actions}
                currentSchoolBpieId={currentSchoolBpieId}
                handleReloadSchoolAdministrator={handleReloadSchoolAdministrator}
                handleResendNotification={handleResendNotification}
                hasAdministrator={hasAdministrator}
                schoolDetails={schoolDetails}
            />

            <BpieSchoolSummarySbpies
                actions={actions}
                handleDeleteSbpie={handleDeleteSbpie}
                schoolBpieId={schoolBpieId}
                schoolDetails={schoolDetails}
            />
        </>
    );
};

BpieSchoolSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    currentSchoolBpieId: PropTypes.string.isRequired,
    handleActivateSbpie: PropTypes.func.isRequired,
    handleCreateSbpie: PropTypes.func.isRequired,
    handleDeleteSbpie: PropTypes.func.isRequired,
    handleResendNotification: PropTypes.func.isRequired,
    handleUpdateStatus: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    handleReloadSchoolAdministrator: PropTypes.func.isRequired,
    schoolBpieId: PropTypes.string.isRequired,
    schoolDetails: PropTypes.object.isRequired,
};