import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {BpieApiMessages} from "../../bpieConstants";
import {API_ROOT} from "../../bpieLocations"

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictPieSummaryApi {
    static addDpieYear(dateWindowId, districtId, districtPieId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}`,
                RequestTypes.POST,
                null,
                BpieApiMessages.DPIE.DPIE_YEAR_ADDED
            )
        );
    }

    static downloadDistrictPie(dateWindowId, districtId, districtBpieId, districtPieId, includeStatusUpdates) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}/dpies/${districtPieId}/download/${includeStatusUpdates}`,
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictPieSummaryApi {
    static addDpieYear(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.DPIE_YEAR_ADDED);
    }

    static downloadDistrictPie(){
        return mockResolveApiCall({});
    }
}

const bpieDistrictPieSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictPieSummaryApi : MockBpieDistrictPieSummaryApi;
export default bpieDistrictPieSummaryApi;