import React from 'react';
import PropTypes from 'prop-types';
import {gridConstants} from "../../constants/inputConstants";

const GridRow = ({
                     align = "",
                     children,
                     rowClass = "",
                     id,
                     isPageNav,
                     large,
                     medium,
                     small = gridConstants.column.TWELVE,
                     xlarge,
                     xxlarge,
                 }) => {

    const className = `row` +
        (rowClass ? ` ${rowClass}` : "") +
        (isPageNav ? " pageNav" : "") +
        (xxlarge ? ` xxlarge-up-${xxlarge}` : "") +
        (xlarge ? ` xlarge-up-${xlarge}` : "") +
        (large ? ` large-up-${large}` : "") +
        (medium ? ` medium-up-${medium}` : "") +
        (small ? ` small-up-${small}` : "") +
        (align ? ` align-${align}` : "");

    return (
        <div id={id} className={className}>
            {children}
        </div>
    );
};

const columnPropType = gridConstants.column.PROP_TYPE;

GridRow.propTypes = {
    align: PropTypes.string,
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    id: PropTypes.string,
    isPageNav: PropTypes.bool,
    large: columnPropType,
    medium: columnPropType,
    rowClass: PropTypes.string,
    small: columnPropType,
    xlarge: columnPropType,
    xxlarge: columnPropType,
};

export default GridRow;