import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import {monitoring_compliance_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceCaseNotesApi {

    static saveComplianceCaseNote(monitoringAssessmentId, caseNote, isNew){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/assessments/' + monitoringAssessmentId + "/case-notes",
            isNew ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(caseNote),
            isNew ? ApiSuccessMessages.caseNote.CREATE : ApiSuccessMessages.caseNote.UPDATE,
            'application/json'));
    }

    static deleteComplianceCaseNote(monitoringAssessmentId, caseNoteId){
        const dto = {monitoringAssessmentId, caseNoteId};

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/assessments/' + monitoringAssessmentId + '/case-notes/' + caseNoteId,
            RequestTypes.DELETE,
            null,
            ApiSuccessMessages.caseNote.DELETE,
            'application/json'));
    }

    static exportComplianceCaseNotes(monitoringAssessmentId, caseNoteFilter) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}monitoring/compliances/case-notes/export/assessments/${monitoringAssessmentId}?startDate=${caseNoteFilter.startDate}&endDate=${caseNoteFilter.endDate}&noteType=${caseNoteFilter.noteType}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.caseNote.DOWNLOAD
            ));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceCaseNotesApi {
    static saveComplianceCaseNot(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteComplianceCaseNot(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static exportComplianceCaseNotes() {
        return new Promise( resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceCaseNotesApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceCaseNotesApi : MockMonitoringComplianceCaseNotesApi;
export default MonitoringComplianceCaseNotesApi;