import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as MonitoringComplianceActions from "../../actions/monitoringComplianceActions";
import {getProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import { getParams } from "../../components/layout/getParams";
import {NotifyUser} from "../../components/common/NotifyUser";
import MatrixOfServiceValidation from "../../components/MonitoringCompliance/MatrixOfServiceValidation";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class MatrixOfServiceValidatePage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            studentRecord: null,
            protocolResponses: [],
            indicatorName: "",
            districtName: "",
            params: {},
            showDelete: true,
            editSubmittedValidation: false
        };

        this.onChangeProtocolResponse = this.onChangeProtocolResponse.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickReturn = this.onClickReturn.bind(this);
        this.onClickRemove = this.onClickRemove.bind(this);
        this.editSubmittedValidation = this.editSubmittedValidation.bind(this);
    }

    componentDidMount() {
        const params = getParams(this.props.location.pathname, ComplianceLocations.COMPLIANCE_MATRIX_RECORD_VALIDATE);
        if (params !== null)
            this.setState({params});

        if (!this.props.selectedAssessment.dateWindowId)
            this.props.actions.loadComplianceAssessment(params.assessmentId)
                .then(() => {
                        this.props.actions.setEditMode(this.props.indicatorRequirementStatus.allStudentRecordsSubmitted && !this.props.indicatorRequirementStatus.validationSubmitted || false);
                        this.loadIndicatorProtocols(params.indicatorRequirementId);
                    }
                );
        else {
            this.props.actions.setEditMode(this.props.indicatorRequirementStatus.allStudentRecordsSubmitted && !this.props.indicatorRequirementStatus.validationSubmitted || false);
            this.loadIndicatorProtocols(params.indicatorRequirementId);
        }
    }

    componentDidUpdate() {
        if (!this.state.studentRecord &&
            this.props.currentIndicatorRequirement &&
            this.props.selectedAssessment.studentRecords &&
            this.props.indicatorProtocols &&
            this.props.indicatorProtocols.length > 0) {

            let studentRecord = this.props.selectedAssessment.studentRecords.find(rec => {
                return rec.studentRecordId === this.state.params.studentRecordId;
            });

            if (studentRecord) {
                this.props.actions.updatePageTitle("Matrix of Service Validation: " + studentRecord.name);
                let protocolResponses = [...studentRecord.protocolResponses.map(rec => Object.assign({}, rec))];
                const showDelete = protocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length > 0;
                this.setState({
                    studentRecord: studentRecord,
                    protocolResponses: this.setDefaultResponse(protocolResponses),
                    showDelete: showDelete
                });

            }
        }
    }

    loadIndicatorProtocols(indicatorRequirementId) {
        if (this.props.selectedAssessment.indicatorRequirements) {
            const indicatorRequirement = this.props.selectedAssessment.indicatorRequirements.find(r => r.indicatorRequirementId === indicatorRequirementId);
            this.props.actions.loadIndicatorProtocols(this.props.selectedAssessment.dateWindowId, indicatorRequirement.indicatorId);
        }
    }

    onChangeProtocolResponse(event, protocolId) {
        const responseValue = event.target.name.startsWith("cb") && !event.target.checked ? "" : event.target.value;

        this.setState({
            protocolResponses: this.state.protocolResponses.map(rec => {
                if (rec.protocolId === protocolId && rec.responseType === responseTypes.VALIDATION)
                    rec.response = responseValue;

                return rec;
            })
        });
    }

    createNewProtocolResponses(protocolResponses) {
        this.props.indicatorProtocols.map(protocol => {
            let response = protocolResponses.find(r => r.protocolId === protocol.protocolId && r.responseType === responseTypes.VALIDATION);
            if (!response) {
                let protocolResponseId = emptyGuid;
                let newResponse = MonitoringComplianceUtilities.newProtocolResponse(protocolResponseId, protocol.protocolId, "0", responseTypes.VALIDATION);
                protocolResponses.push(newResponse);
            }
        });
    }

    setDefaultResponse(protocolResponses) {
        if(protocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length !== this.props.indicatorProtocols.length && this.props.isEditing && this.canUserEdit()) {
            this.createNewProtocolResponses(protocolResponses);
        }

        return protocolResponses;
    }

    validate() {
        let isValid = true;

        let responsesWithErrors = this.props.indicatorProtocols.reduce((errors, protocol) => {

            let stateResponse = MonitoringComplianceUtilities.getProtocolResponse(
                this.state.protocolResponses,
                protocol.protocolId,
                responseTypes.VALIDATION);

            if (stateResponse === "0" && protocol.stateResponseOptions.length > 1)
                errors.push(" " + protocol.standardDescription);

            return errors;
        }, []);

        if(responsesWithErrors.length > 0) {
            NotifyUser.Error("Missing responses to the following protocols:" + responsesWithErrors);
            isValid = false;
        }

        return isValid;
    }

    onClickSave(event) {
        event.preventDefault();
        this.save(true);
    }

    save(reload) {
        if((!this.props.isEditing || !this.canUserEdit()) && !reload)
            this.returnToMainPage();

        if (this.props.isEditing && this.canUserEdit() && this.validate()) {
            this.props.actions.saveStudentRecord(this.props.selectedAssessment.districtId,
                this.props.selectedAssessment.monitoringAssessmentId,
                this.state.studentRecord.studentRecordId,
                MonitoringComplianceUtilities.prepareProtocolResponsesForSave(this.state.protocolResponses, responseTypes.VALIDATION),
                responseTypes.VALIDATION)
                .then(() => {
                    if (reload) {
                        this.props.actions.loadComplianceAssessment(this.state.params.assessmentId)
                            .then(() => {
                                    this.setState({studentRecord: null});
                                    this.props.actions.setEditMode(this.props.indicatorRequirementStatus.allStudentRecordsSubmitted && (!this.props.indicatorRequirementStatus.validationSubmitted || this.state.editSubmittedValidation) || false);
                                }
                            );
                    }
                    else
                        this.returnToMainPage();
                });
        }
    }

    returnToMainPage() {
        this.props.history.push(ComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path
            .replace(ComplianceLocations.DATE_WINDOW_ID, this.props.selectedAssessment.dateWindowId)
            .replace(ComplianceLocations.DISTRICT_ID, this.props.selectedAssessment.districtId));
    }

    onClickReturn(event) {
        event.preventDefault();
        this.save(false);
    }

    onClickRemove(event) {
        event.preventDefault();

        if(confirm("Are you sure you want to remove the validation for this student record?")) {
            const dateWindowId = this.props.selectedAssessment.dateWindowId;
            const districtId = this.props.selectedAssessment.districtId;
            this.props.actions.removeStudentRecordValidation(this.props.selectedAssessment.districtId,
                this.props.selectedAssessment.monitoringAssessmentId,
                this.state.studentRecord.studentRecordId)
                .then(() => {
                    this.props.history.push(ComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path
                        .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
                        .replace(ComplianceLocations.DISTRICT_ID, districtId));
                });
        }
    }

    editSubmittedValidation(event) {
        event.preventDefault();
        if(confirm("Are you sure you want to edit this already submitted validation?  By doing so, it will automatically remove reassessed responses if they are no longer needed.")) {
            this.props.actions.setEditMode(true);
            this.setState({editSubmittedValidation: true});
        }
    }

    canUserEdit() {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    }

    render() {
        if (!this.props.selectedAssessment.studentRecords || !this.state.studentRecord ) return null;
        const canEdit = this.canUserEdit();
        const isEditing = this.props.isEditing && canEdit;

        return (
            <div>
                {
                    isEditing &&
                    <ButtonBar position={ButtonBarPositions.STICKY}>
                        {
                            this.state.showDelete &&
                            !this.state.editSubmittedValidation &&
                            <Button name={"remove"}
                                    label={"Remove Validation"}
                                    onClick={this.onClickRemove}
                                    disabled={this.props.isLoading}
                                    buttonType={ButtonTypes.DELETE}/>
                        }
                        <Button name={"save"}
                                label={"Save"}
                                onClick={this.onClickSave}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.SAVE}/>
                        <Button name={"cancel"}
                                label={"Save and Return to Validate Records"}
                                onClick={this.onClickReturn}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.BACK}/>
                    </ButtonBar>
                }
                {
                    !isEditing &&
                    <ButtonBar position={ButtonBarPositions.STICKY}>
                        <Button name={"cancel"}
                                label={"Return to Validate Records"}
                                onClick={this.onClickReturn}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.BACK}/>
                        {
                            this.props.indicatorRequirementStatus.validationSubmitted &&
                            this.state.studentRecord.isRecordValidated &&
                            canEdit &&
                            !this.props.indicatorRequirementStatus.assessmentApproved &&
                            <Button label={"Edit Submitted Validation"} name={"btnEditSubmittedValidation"} onClick={this.editSubmittedValidation} />
                        }
                    </ButtonBar>
                }
                {
                    this.props.indicatorProtocols.length > 0 &&
                    <MatrixOfServiceValidation
                        indicatorProtocols={this.props.indicatorProtocols}
                        protocolResponses={this.state.protocolResponses}
                        onResponseChange={this.onChangeProtocolResponse}
                        isEditing={isEditing}
                        assessmentApproved={this.props.indicatorRequirementStatus.assessmentApproved}
                    />
                }
            </div>
        );
    }
}

MatrixOfServiceValidatePage.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object.isRequired,
    indicatorProtocols: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentIndicatorRequirement: PropTypes.object,
    indicatorRequirementStatus: PropTypes.object,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    let currentIndicatorRequirement = {};
    const params = getParams(ownProps.location.pathname, ComplianceLocations.COMPLIANCE_MATRIX_RECORD_VALIDATE);

    if (state.monitoringCompliance.selectedAssessment && state.monitoringCompliance.selectedAssessment.indicatorRequirements) {
        const currentIndicatorRequirementArray  = state.monitoringCompliance.selectedAssessment.indicatorRequirements.filter(ir => ir.indicatorRequirementId === params.indicatorRequirementId);
        if(currentIndicatorRequirementArray.length === 1)
            currentIndicatorRequirement = Object.assign({}, currentIndicatorRequirementArray[0]);
    }

    const indicatorRequirementStatus = getProtocolRequirementStatus(currentIndicatorRequirement);

    return {
        selectedAssessment: state.monitoringCompliance.selectedAssessment,
        indicatorProtocols: state.monitoringCompliance.indicatorProtocols,
        currentIndicatorRequirement: currentIndicatorRequirement,
        indicatorRequirementStatus: indicatorRequirementStatus,
        isEditing: state.monitoringCompliance.isEditing,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        sharedDataActions,
        MonitoringComplianceActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatrixOfServiceValidatePage);