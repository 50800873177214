import React from "react";
import PropTypes from 'prop-types';
import McSummaryDueNow from "./McSummaryDueNow";
import McSummaryUpcoming from "./McSummaryUpcoming";

export const McSummaryView = ({
                                  mcSummarySearchCriteria,
                                  handleClickGeneral,
                                  setDateRangeSearchCriteria,
                                  setMcSummarySearchCriteria,
                                  summary,
                              }) => {
    return (
        <>
            <McSummaryDueNow
                mcSummarySearchCriteria={mcSummarySearchCriteria}
                handleClickGeneral={handleClickGeneral}
                setMcSummarySearchCriteria={setMcSummarySearchCriteria}
                summary={summary}
            />

            <McSummaryUpcoming
                mcSummarySearchCriteria={mcSummarySearchCriteria}
                handleClickGeneral={handleClickGeneral}
                setDateRangeSearchCriteria={setDateRangeSearchCriteria}
                setMcSummarySearchCriteria={setMcSummarySearchCriteria}
                summary={summary}
            />
        </>
    );
};

McSummaryView.propTypes = {
    mcSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    setDateRangeSearchCriteria: PropTypes.func.isRequired,
    setMcSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array
};

export default McSummaryView;