import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../api/requests/RequestTypes";
import * as config from "../../../../constants/config";
import {API_MESSAGES} from "../../ptsConstants";
import {API_ROOT} from "../../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsManageProgramsApi {
    static getPrograms() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/programs`,
                RequestTypes.GET)
        );
    }

    static getProgram(programId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/programs/${programId}`,
                RequestTypes.GET)
        );
    }

    static deleteProgram(programId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/programs/${programId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.PROGRAMS.deleted)
        );
    }

    static saveProgram(programId, program) {
        const isNewProgram = programId === config.emptyGuid;
        const apiUrlSuffix = isNewProgram ? `` : `/${programId}`;
        const requestType = isNewProgram ? RequestTypes.POST : RequestTypes.PUT;

        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/programs${apiUrlSuffix}`,
                requestType,
                JSON.stringify(program),
                API_MESSAGES.ADMIN.PROGRAMS.saved)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsManageProgramsApi {
    static getPrograms() {
        return mockResolveApiCall([]);
    }

    static getProgram() {
        return mockResolveApiCall({});
    }

    static deleteProgram() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.PROGRAMS.deleted);
    }

    static saveProgram() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.PROGRAMS.saved);
    }
}

const PtsManageProgramsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsManageProgramsApi : MockPtsManageProgramsApi;
export default PtsManageProgramsApi;