export default {
    ajaxCallsInProgress: 0,
    dashboardAjaxCallsInProgress: 0,
    currentContext: [],
    layout: {
        pageTitle: {
            headerLeft: "",
            headerCenter: "",
            headerRight: ""
        },
        subTitle: ""
    },
    account: {
        isLoggedIn: false,
        redirectUrl: ""
    },
	menu: {
		isActive: false,
        isAdminMenuOpen: false
	},
    searchCriteria: {},
    sharedData: {
        isEditing: false,
        editItemId: "",
        systemMessage: {},
        institutions: [],
        institutionsWithStateAgencies: [],
        institutionsWithDiscretionaryAgencies: [],
        allInstitutions: [],
        districtsWithSchools: [],
        dateWindows: [],
        selectedDateWindowId: "",
	    selectedMultiYearDateWindowId: "",
        sppIndicators: [],
        beessIndicators: [],
        ptsIndicators: [],
        allIndicators: [],
        appSettings: {},
        systemIsLocked: false
    },
    dashboard: {
        bpieTiles: [],
        ptsTiles: [],
        gmsTiles: [],
        sppTiles: [],
        monitoringPerformanceTiles: [],
        monitoringComplianceTiles: [],
        editableSurveys: [],
        loggedInRespondent: {},
        mediationTiles: [],
        dueProcessTiles: [],
        complaintTiles: []
    },
    user: {
        searchResults: [],
        totalRecords: 0,
        behaviors: [],
        selectedUser: {},
        programsForFiscalAgent: [],
    },
    survey: {
        currentStep: 1,
        surveys: [],
        selectedSurvey: {},
        selectedSurveyElements: [],
        viewedComponentHistory: []
    },
    surveyAdmin: {
        surveyInstances: [],
        selectedSurveyInstance: {},
        instanceElements: [],
        instanceRespondents: [],
        isEditing: false,
        editItemId: "",
        isEditItemNew: false,
        openComponents: []
    },
    spp: {
	    sppTiles: [],
	    sppInstances:[],
        selectedPolicyCompliance: {},
        selectedDocument: {},
        documentElements: [],
        sectionHierarchy: {},
        documentResponses: [],
        policyCompliances: [],
        isInEditMode: false,
        commentId: "",
        districtStatuses: [],
        editItemId: ""
    },
    strategicPlan: {
        plans: [],
	    fiveYearReport: [],
        resources: [],
        goals: [],
        planDateWindow: {},
        strategies: [],
        filterSettings: {
            showButtons: false,
            showIndicators: true,
            showActions: true,
            showSubActions: true,
            showProgress: true,
            showTeam: true
        },
        isEditing: false,
        editItemId: ""
    },
    monitoringPerformance: {
        districtStatuses: [],
        selectedDateWindow: "",
        selectedAssessmentId: "",
        selectedAssessment: {},
        isEditing: false,
        isAddingIndicator: false,
        isAddingAction: false,
        isNew: false,
        editItemId: "",
        commentId: "",
        isCommentInEditMode: false
    },
    monitoringCompliance: {
        districtStatuses: [],
        indicators: [],
        indicatorProtocols: [],
        selectedAssessment: {},
        isEditing: false,
        isNew: false,
        editItemOriginalCopy: {},
        complianceCaseNotes: []
    },
    monitoringComplianceStudentCorrection: {
        studentProtocolCorrectiveResults: [],
        matrixProtocolCorrectiveResults: [],
        commentId: "",
        isCommentInEditMode: false
    },
    bpie: {
        finFacilitatorDistricts: [],
        finFacilitatorDistrictsLoaded: false
    }
};