import React from "react";
import PropTypes from "prop-types";
import {createListIfExistsInIdArray} from "../common/commonUtilities";
import TextArea from "../common/inputs/TextArea";
import TextField from "../common/inputs/TextField";
import ListManagerField from "../common/inputs/ListManagerField";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import SubAction from "./SubAction";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const StrategyActionForm = ({
	                            action,
								actionIndex,
	                            filters,
	                            editItemId,
	                            resources,
	                            strategyAction,
	                            onChange,
	                            onSave,
	                            onCancel,
	                            isLoading
                            }) => {

	if (resources.length > 0) {
		resources = resources.map(r => {
			return {
				id: r.text,
				text: r.text,
				value: r.text
			};
		});
	}

	return (
		<section className={"strategy__action strategy__action--editing "} id={strategyAction.id}>
			<header>
                <TextArea label={"Action " + (actionIndex + 1)}
                          name={"text"}
                          onChange={onChange}
                          value={strategyAction.text}
                          showLabel/>

				<TextField label={"Responsible Entity"}
				           name={"responsibleParty"}
				           onChange={onChange}
				           value={strategyAction.responsibleParty}
				           showLabel placeholder={"Organization, Position, etc"}/>

				<TextArea label={"Progress Monitoring Process"}
				          name={"progressMonitoringNotes"}
				          onChange={onChange}
				          value={strategyAction.progressMonitoringNotes}
				          showLabel
				          placeholder={"Describe the team's process through which they will monitor their actions"}/>

				<ListManagerField managerLabel={"Resources"}
				                  name={"resources"}
				                  listItems={resources}
				                  onChange={onChange}
								  listValues={createListIfExistsInIdArray(strategyAction.resources, resources)}
				                  disabled={isLoading}/>

				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<Button name={"save"}
					        label={"Save"}
					        buttonType={ButtonTypes.SAVE}
					        onClick={onSave}
					        disabled={isLoading}
					/>
					<Button name={"cancel"}
					        onClick={onCancel}
					        buttonType={ButtonTypes.CANCEL}
					        label={"Cancel"}
					        disabled={isLoading}/>
				</ButtonBar>
			</header>

			{
				(filters.showSubActions ||
				filters.showProgress) &&
				action.subActions.length > 0 &&
				action.subActions.map((sub, i) => {
					return (<SubAction key={sub.id}
					                   filters={filters}
					                   actionOrder={action.order}
					                   subAction={sub}
					                   editItemId={editItemId}
					                   onCancel={onCancel}
					                   isLoading={isLoading}
					                   isEditing={true}
					                   actionIndex={actionIndex}
					                   subActionIndex={i}
					/>);
				})
			}
		</section>
	);
};

StrategyActionForm.propTypes = {
	action: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	editItemId: PropTypes.string.isRequired,
	resources: PropTypes.array.isRequired,
	strategyAction: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	actionIndex: PropTypes.number.isRequired

};

export default StrategyActionForm;