import React, {useState} from 'react';
import InputAdapter, {sharedProps, numberProps} from "./InputAdapter";
import {numberOfDecimalPlaces, validateDecimal} from "../commonUtilities";

const AbstractNumberField = (props) => {

    const {decimalPlaces = 2, isInline= true, isPositiveNumberOnly, isWholeNumberOnly, maxValue, minValue, onChange, value} = props;
    const [error, setError] = useState("");

    const minNumber = isPositiveNumberOnly ? 0 : minValue;

    const step = isWholeNumberOnly ? "1" : ".01";

    const onChangeValidation = (e) => {
        const event = {...e};
        const newValue = event.target.value;
        const numberOfDigits = isWholeNumberOnly ? 0 : decimalPlaces;
        const foundDigits = numberOfDecimalPlaces(newValue);
        const shouldValidate = foundDigits > numberOfDigits;

        setError(event.target.validationMessage);

        if (shouldValidate)
            event.target.value = validateDecimal(Number(newValue), numberOfDigits);

        onChange(event);
    };

    return <InputAdapter
        {...props}
        error={error || props.error}
        inputType={`number`}
        isInline={isInline}
        maxNumber={maxValue}
        minNumber={minNumber}
        onChange={onChangeValidation}
        step={step}
        value={value}
    />;
};

export const AbstractNumberFieldProps = {
    ...numberProps,
    ...sharedProps,
};

AbstractNumberField.propTypes = AbstractNumberFieldProps;

export default AbstractNumberField;