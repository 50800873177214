import Location from "../components/shared/Location";
import {emptyGuid} from "../constants/config";

export const ROOT = `/DisputeResolution`;
export const DATE_WINDOW_ID_ROOT = "dateWindowId";
const DATE_WINDOW_ID = `:${DATE_WINDOW_ID_ROOT}`;
const INSTITUTION_ID = ":institutionId";
export const DISPUTE_RESOLUTION_TYPE_ROOT  = "disputeResolutionType";
const DISPUTE_RESOLUTION_TYPE  = `:${DISPUTE_RESOLUTION_TYPE_ROOT}`;
export const STATUS_ROOT = "status";
const STATUS = `:${STATUS_ROOT}`;
export const DATE_RANGE_TYPE_ROOT = "dateRangeType";
const DATE_RANGE_TYPE = `:${DATE_RANGE_TYPE_ROOT}`;
export const TEAM_MEMBER_ROLE_ROOT = "teamMemberRole";
const TEAM_MEMBER_ROLE = `:${TEAM_MEMBER_ROLE_ROOT}`;
export const TEAM_MEMBER_ROOT = "teamMember";
const TEAM_MEMBER = `:${TEAM_MEMBER_ROOT}`;
export const OVERDUE_ROOT = "includeTimelineRequirementOverdue";
const OVERDUE = `:${OVERDUE_ROOT}`;

export const DR_DISTRICT_SUMMARY = Location(() => `${ROOT}/DistrictSummary`);

export const DR_SUMMARY = Location(() => `${ROOT}/DrSummary`);

export const LANDING_DOE_DATE_WINDOW_TYPE = Location((dateWindowId = DATE_WINDOW_ID, disputeResolutionType = DISPUTE_RESOLUTION_TYPE, status = STATUS, dateRangeType = DATE_RANGE_TYPE, teamMemberRole = TEAM_MEMBER_ROLE) =>
	`${ROOT}/DistrictSummary/Dates/${dateWindowId}/Types/${disputeResolutionType}/Status/${status}/DateRangeType/${dateRangeType}?/TeamMemberRole/${teamMemberRole}?`);

export const LANDING_DISTRICT = Location((institutionId=INSTITUTION_ID) =>
	`${ROOT}/Institution/${institutionId}/`);

export const LANDING_DISTRICT_DATE_WINDOW_TYPE = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, disputeResolutionType=DISPUTE_RESOLUTION_TYPE, status=STATUS, teamMember=TEAM_MEMBER, includeTimelineRequirementOverdue = OVERDUE) =>
	`${ROOT}/Institution/${institutionId}/Dates/${dateWindowId}/Types/${disputeResolutionType}/Status/${status}`);

export const LANDING_CASE_LIST = Location(() => `${ROOT}/CaseList`);

export const DR_CASELOAD_SUMMARY = Location(() => `${ROOT}/Caseload`);

export const DR_CASE_RESEARCH_SUMMARY = Location(() => `${ROOT}/CaseResearch`);

/////////////////////////////// State Complaint ////////////////////////////////////////
const STATE_COMPLAINTS_ROOT = `${ROOT}/StateComplaints`;
const COMPLAINT_ID = ":complaintId";
const DATE_RANGE = ":dateRange";
const START_DATE = ":startDate";
const END_DATE = ":endDate";
export const COMPLAINT_ID_NEW = emptyGuid;

export const STATE_COMPLAINT = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/`);

export const STATE_COMPLAINT_DISTRICT = STATE_COMPLAINT;

export const STATE_COMPLAINT_OVERVIEW_DOE = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Overview`);

export const STATE_COMPLAINT_TILE_DISTRICT = STATE_COMPLAINT_DISTRICT.getUrl("{dateWindowId}", "{institutionId}", "{complaintId}");

export const STATE_COMPLAINT_INTAKE = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Intake`);

export const STATE_COMPLAINT_TIMELINE = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Timeline`);

export const STATE_COMPLAINT_ROUTING = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Routing`);

export const STATE_COMPLAINT_OUTCOME = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Outcome`);

export const STATE_COMPLAINT_ISSUES = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Issues`);

export const STATE_COMPLAINT_ACTIONS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Actions`);

export const STATE_COMPLAINT_RESPONSES = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Responses`);

export const STATE_COMPLAINT_CLOSURE_PROCESS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/Closure`);

export const STATE_COMPLAINT_CLOSURE_WITH_NO_ACTIONS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/ClosureNoActions`);

export const STATE_COMPLAINT_CASE_NOTES = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, complaintId=COMPLAINT_ID) =>
	`${STATE_COMPLAINTS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Complaint/${complaintId}/CaseNotes`);

export const STATE_COMPLAINT_TASK_PRINT = Location((complaintId= COMPLAINT_ID, dateRange= DATE_RANGE, startDate= START_DATE, endDate = END_DATE) =>
	`${STATE_COMPLAINTS_ROOT}/Complaint/${complaintId}/Tasks/DateRanges/${dateRange}/${startDate}/${endDate}/Print`);

/////////////////////////////// Mediation ////////////////////////////////////////
const MEDIATIONS_ROOT = `${ROOT}/Mediations`;
const MEDIATION_ID = ":mediationId";
export const MEDIATION_ID_NEW = emptyGuid;

export const MEDIATION_TILE_OVERVIEW = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, mediationId=MEDIATION_ID) =>
	`${MEDIATIONS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Mediation/${mediationId}/Overview`);

export const MEDIATION_TILE = MEDIATION_TILE_OVERVIEW.getUrl("{dateWindowId}", "{institutionId}", "{mediationId}");

export const MEDIATION_OVERVIEW = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, mediationId=MEDIATION_ID) =>
	`${MEDIATIONS_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/Mediation/${mediationId}/Overview`);

/////////////////////////////// Due Process ////////////////////////////////////////
const DUE_PROCESSES_ROOT = `${ROOT}/DueProcesses`;
const DUE_PROCESS_ID = ":dueProcessId";
export const DUE_PROCESS_ID_NEW = emptyGuid;

export const DUE_PROCESS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}`);

export const DUE_PROCESS_TILE_OVERVIEW = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/Overview`);

export const DUE_PROCESS_TILE = DUE_PROCESS_TILE_OVERVIEW.getUrl("{dateWindowId}", "{institutionId}", "{dueProcessId}");

export const DUE_PROCESS_OVERVIEW = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/Overview`);

export const DUE_PROCESS_RESOLUTION_PROCESS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/ResolutionProcess`);

export const DUE_PROCESS_FINAL_ORDER = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/FinalOrder`);

export const DUE_PROCESS_ACTIONS = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/Actions`);

export const DUE_PROCESS_CASE_NOTES = Location((dateWindowId=DATE_WINDOW_ID, institutionId=INSTITUTION_ID, dueProcessId=DUE_PROCESS_ID) =>
	`${DUE_PROCESSES_ROOT}/Institution/${institutionId}/Date/${dateWindowId}/DueProcess/${dueProcessId}/CaseNotes`);
////////////////////////////// DR Admin ///////////////////////////////////////////////
const DR_ADMIN_ROOT = `${ROOT}/Admin`;

export const DR_ADMIN_TEAM_MEMBERS = Location(() => `${DR_ADMIN_ROOT}/TeamMembers/`);
export const DR_ADMIN_INTAKE = Location(() => `${DR_ADMIN_ROOT}/IntakeRequirements/`);