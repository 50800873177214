import React from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import GridTable from "../../../../components/common/GridTable";
import SchoolBpieCreateList from "./SchoolBpieCreateList";

const SchoolBpieCreateView = ({bpie}) => {
    const hasCreateErrors = bpie.numberOfSchoolBpieCreateErrors > 0;
    const createContainerTitle = hasCreateErrors ? `SBPIEs with errors` : `SBPIEs creation information`;

    const updateNotificationTrigger = <CollapsibleTrigger
        text={`School information updates`}/>;

    return (
        <>
            <div>
                <p>Number of SBPIES to create: {bpie.numberOfSchoolsBpiesToCreate}</p>
                <p>Number of SBPIES to be updated: {bpie.numberOfBpieSchoolsToUpdate}</p>
                {hasCreateErrors && <p>Number of errors generated when creating SBPIEs: {bpie.numberOfSchoolBpieCreateErrors}</p>}
            </div>
            <SchoolBpieCreateList
                containerId={`school-create-container`}
                createContainerTitle={createContainerTitle}
                createSchoolsList={bpie.sbpiesToBeCreated}
                hasCreateErrors={hasCreateErrors}
            />
            <SchoolBpieCreateList
                containerId={`school-created-container`}
                createContainerTitle={`SBPIEs Created`}
                createSchoolsList={bpie.sbpiesActuallyCreated}
            />
            {
                bpie.hasSchoolSchoolBpieUpdates &&
                <CollapsibleContainer
                    id={`school-update-container`}
                    open={bpie.hasSchoolSchoolBpieUpdates}
                    trigger={updateNotificationTrigger}
                >
                    <GridTable>
                        <thead>
                        <tr>
                            <th>District Number</th>
                            <th>School Number</th>
                            <th>Updates</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            bpie.schoolBpieUpdates.map((schoolUpdate, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{schoolUpdate.districtNumber}</td>
                                        <td>{schoolUpdate.schoolNumber}</td>
                                        <td>{schoolUpdate.formattedUpdates}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                </CollapsibleContainer>
            }
        </>
    );
}

SchoolBpieCreateView.propTypes = {
    bpie: PropTypes.object.isRequired
};

export default SchoolBpieCreateView;