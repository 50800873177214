import React from 'react';
import PropTypes from 'prop-types';

const ToolTip = ({ children, showIcon, tip }) => {
    if (!children && !showIcon) return null;
    if (!tip) return children;

    let className = "tool-tip";
    if (showIcon)
        className += ` tool-tip--icon`;

    ///TODO: update with better a11y option
    return (
        <span className={className} aria-label={tip} title={tip}>
            {children}
        </span>
    );
};

ToolTip.propTypes = {
    children: PropTypes.any.isRequired,
    showIcon: PropTypes.bool,
    tip: PropTypes.string.isRequired,
}

export default ToolTip;