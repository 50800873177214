import PropTypes from "prop-types";
import React from "react";
import {
	DueProcessPolicy,
	policyEvents
} from "../../../components/authorization/policies/DueProcessPolicy";
import ActionBar from "../../../components/common/buttons/ActionBar";
import AuditBar from "../../../components/common/buttons/AuditBar";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import DisplayField from "../../../components/common/inputs/DisplayField";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import * as ActionBarPositions from "../../../constants/ActionBarPositions";
import * as apiParameters from "../../../constants/api/apiParameters";
import audit_outcomes from "../../../constants/auditOutcomes";
import DrDueProcessActionResponse from "./DrDueProcessActionResponse";
import DrDueProcessActionResponseEdit from "./DrDueProcessActionResponseEdit";

const DrDueProcessActionComponent = ({
	                                     action,
										 canAudit,
	                                     canEditAction,
	                                     canRespond,
										 canSubmit,
										 handleAuditResponseAsync,
	                                     handleCancel,
										 handleDataChanged,
	                                     handleDeleteAction,
	                                     handleEdit,
	                                     handleSaveResponseAsync,
										 handleSubmitResponseAsync,
										 isDistrictUser,
										 isEditing,
										 isEditMode
                                     }) => {
	const handleClickEdit = () => handleEdit(action.id);

	const returnAuditClickHandler = (handler, districtResponse, action) => {
		return handler
			? (event, auditAction = action) => {
				handler(districtResponse, auditAction);
			}
			: undefined;
	};

	const handleClickSubmit = isDistrictUser && canSubmit && !!action.hasResponse
	&& !action.isSubmittedToState
		? returnAuditClickHandler(handleSubmitResponseAsync, action.districtResponse, apiParameters.audit.SUBMIT)
		: undefined;

	const handleClickAudit = returnAuditClickHandler(handleAuditResponseAsync, action.districtResponse);

	const handleChangeOutcome = canAudit && !isDistrictUser && handleClickAudit
		? (event) => {
			event.preventDefault();
			const auditOutcome = event.target.id.split("_")[0];

			switch (auditOutcome) {
				case audit_outcomes.ACCEPTED.name:
					handleClickAudit(event, apiParameters.audit.APPROVE);
					break;
				case audit_outcomes.NEEDS_REVISION.name:
					handleClickAudit(event, apiParameters.audit.REVISE);
					break;
				case audit_outcomes.RESET.name:
					handleClickAudit(event, apiParameters.audit.RESET);
					break;
			}
		}
		: undefined;

	return (
		<div>
			{
				!isArrayNullOrEmpty(action.districtResponse.responseAudits) &&
				<AuditBar
					disabled={isEditMode}
					elementId={`action-audit-${action.id}`}
					onOutcomeChange={handleChangeOutcome}
					outcome={action.districtResponse.currentStatus}
					showAccepted={action.isReadyToApprove}
					policy={DueProcessPolicy}
					policyEvent={policyEvents.dueProcess.audits.AUDIT}
					position={ActionBarPositions.RIGHT_TOP}
				/>
			}
			{
				!isEditMode &&
				canEditAction &&
				<ActionBar
					elementId={`action-${action.id}`}
					onEditClick={handleClickEdit}
					onDeleteClick={() => handleDeleteAction(action)}
					disabled={isEditMode}
					position={ActionBarPositions.RIGHT_BOTTOM}
				/>
			}
			{
				!isEditMode &&
				canRespond &&
				<ActionBar
					elementId={`response-${action.id}`}
					onEditClick={handleClickEdit}
					onSubmitClick={handleClickSubmit}
					disabled={isEditMode}
					position={ActionBarPositions.RIGHT_BOTTOM}
				/>
			}
			<FormTable className={`dueProcessAction__response`}>
				{
					action.description &&
					<DisplayField
						name="description"
						label="Description"
						showLabel
					>
						{action.description}
					</DisplayField>
				}
				{
					action.dateDue &&
					<DisplayField
						name="dateDue"
						label="Deadline"
						showLabel
					>
						{outputStandardDateFormat(action.dateDue, false)}
					</DisplayField>
				}
				{
					isEditing &&
					canRespond &&
					<DrDueProcessActionResponseEdit
						handleClickCancel={handleCancel}
						handleDataChanged={handleDataChanged}
						handleSaveResponseAsync={handleSaveResponseAsync}
						response={action.districtResponse}
					/>
				}
				{
					(!isEditing ||
						!canRespond) &&
					<DrDueProcessActionResponse
						response={action.districtResponse}
					/>
				}
			</FormTable>
		</div>
	);
};

DrDueProcessActionComponent.propTypes = {
	action: PropTypes.object.isRequired,
	canAudit: PropTypes.bool.isRequired,
	canEditAction: PropTypes.bool.isRequired,
	canRespond: PropTypes.bool.isRequired,
	canSubmit: PropTypes.bool.isRequired,
	handleAuditResponseAsync: PropTypes.func.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleDeleteAction: PropTypes.func.isRequired,
	handleEdit: PropTypes.func.isRequired,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	handleSubmitResponseAsync: PropTypes.func.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isEditMode: PropTypes.bool.isRequired
};

export default DrDueProcessActionComponent;