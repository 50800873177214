import React from "react";
import PropTypes from 'prop-types';
import CollapsibleContainer from "../../../components/common/CollapsibleContainer";
import DrStateComplaintActionComponent from "./DrStateComplaintActionComponent";
import DrStateComplaintActionComponentEdit from "./DrStateComplaintActionComponentEdit";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import Tile from "../../../components/common/Tiles/Tile";
import * as drObjectFactory from '../drStateComplaintObjectFactory';
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {newActionId} from "../drStateComplaintConstants";

const DrStateComplaintActionView = ({
	                                    actionModels,
										dateOrdered,
	                                    editActionId,
										editActionIssueId,
	                                    handleDeleteAction,
	                                    handleSaveActionAsync,
										handleScroll,
	                                    handleDataChanged,
	                                    isEditMode,
	                                    issueId,
	                                    setEditActionId,
	                                    studentExceptionalities,
	                                    readOnly
                                    }) => {

	const noActions = isArrayNullOrEmpty(actionModels);
	const showCancel = !noActions || !!issueId;

	return (
		<>
			{
				!noActions &&
				actionModels.map((act, key) => {
					const action = drObjectFactory.createActionObject(act);
					const isEditing = editActionId === action.id;
					const containerId = `container-${action.id}`;
					return <CollapsibleContainer
						id={containerId}
						key={key}
						trigger={`Action ${action.idDisplay}`}
						triggerDisabled={isEditing}>
						{
							isEditing ?
								<DrStateComplaintActionComponentEdit
									action={action}
									dateOrdered={dateOrdered}
									handleSaveActionAsync={handleSaveActionAsync}
									handleDataChanged={handleDataChanged}
									setEditActionId={setEditActionId}
									studentExceptionalities={studentExceptionalities}
									onScroll={() => handleScroll(containerId)}
								/>
								:
								<DrStateComplaintActionComponent
									action={action}
									isEditMode={isEditMode}
									onClickDelete={() => handleDeleteAction(action)}
									onClickEdit={() => setEditActionId(action.id)}
									readOnly={readOnly}
								/>
						}
					</CollapsibleContainer>;
				})
			}
			{
				!noActions &&
				!isEditMode &&
				!readOnly &&
				editActionId !== newActionId &&
				<TileContainer rowTilesLarge="1" rowTilesMedium="1">
					<Tile body={"Add Action"}
					      onClick={() => setEditActionId(newActionId)}
					      isNew={true}/>
				</TileContainer>
			}
			{
				(
					noActions ||
					(editActionId === newActionId
					&& (!issueId || issueId === editActionIssueId))
				) &&
				!readOnly &&
				<div id={newActionId}>
					<DrStateComplaintActionComponentEdit
						action={drObjectFactory.createActionObject({issueId})}
						dateOrdered={dateOrdered}
						handleSaveActionAsync={handleSaveActionAsync}
						handleDataChanged={handleDataChanged}
						setEditActionId={setEditActionId}
						showCancel={showCancel}
						studentExceptionalities={studentExceptionalities}
						onScroll={() => handleScroll(newActionId)}
					/>
				</div>
			}
			{
				noActions && readOnly &&
				<p><strong>No actions have been created yet.</strong></p>
			}
		</>
	);
};

DrStateComplaintActionView.propTypes = {
	actionModels: PropTypes.array.isRequired,
	dateOrdered: PropTypes.string.isRequired,
	editActionId: PropTypes.string.isRequired,
	editActionIssueId: PropTypes.string,
	handleSaveActionAsync: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleDeleteAction: PropTypes.func.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	issueId: PropTypes.string,
	readOnly: PropTypes.bool.isRequired,
	setEditActionId: PropTypes.func.isRequired,
	studentExceptionalities: PropTypes.array
};

export default DrStateComplaintActionView;