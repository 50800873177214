import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getDistinctIds, isNumber} from "../../../../components/common/commonUtilities";
import * as MonitoringComplianceUtilities
    from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import BackNextPager from "../../../../components/common/pagers/BackNextPager";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import MatrixResultsGrid from "../../../../components/MonitoringCompliance/StudentCorrections/MatrixResultsGrid";
import * as ComplianceLocations from "../../../../constants/monitoringCompliance/monitoringComplianceLocations";

export const MatrixCorrectionsStateView = ({
                                               actions,
                                               dateWindowId,
                                               districtId,
                                               editCommentItemId,
                                               handleCommentChange,
                                               handleScrollToTop,
                                               history,
                                               institutions,
                                               isCommentInEditMode,
                                               isLoading,
                                               matrixProtocolCorrectiveResults,
                                               onCommentCancel,
                                               onCommentCreate,
                                               onCommentEdit,
                                               onCommentSave,
                                               onMatrixProtocolCorrectiveResultOutcomeChange,
                                               page,
                                               returnToMainPage
                                           }) => {
    const [titleIsSet, setTitleIsSet] = useState(false);
    const [loadResults, setLoadResults] = useState(false);
    const [currentStep, setCurrentStep] = useState(isNumber(page) ? parseInt(page) : 1);

    const move = (newStep) => {
        setCurrentStep(newStep);
        handleScrollToTop();

        history.push(ComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS.path
            .replace(ComplianceLocations.DISTRICT_ID, districtId)
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.CURRENT_PAGE, newStep)
            .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX));
    };

    const next = (event) => {
        event.preventDefault();

        const totalSteps = calculateTotalPages();

        if (currentStep === totalSteps)
            returnToMainPage();
        else
            move(currentStep + 1);
    };

    const back = (event) => {
        event.preventDefault();

        if (currentStep === 1)
            returnToMainPage();
        else
            move(currentStep - 1);
    };

    const getBackButtonText = (curStep) => {
        return (curStep === 1) ? "Main Page" : "Back";
    };

    const getNextButtonText = (curStep, totalSteps) => {
        return (curStep === totalSteps) ? "Main Page" : "Next";
    };

    const getDistinctRecordIds = () => {
        return getDistinctIds(matrixProtocolCorrectiveResults, "studentRecordId");
    };

    const calculateTotalPages = () => {
        return getDistinctRecordIds().length;
    }

    const getMatrixRecordId = () => {
        let recordIds = getDistinctRecordIds();
        let currentStudentRecordId = currentStep - 1;

        return recordIds[currentStudentRecordId];
    };

    useEffect(() => {
        if (MonitoringComplianceUtilities.isValidId(dateWindowId) && MonitoringComplianceUtilities.isValidId(districtId) && !loadResults) {
            actions.loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId);
            setLoadResults(true);
        }
    }, [districtId, dateWindowId]);

    useEffect(() => {
        if (titleIsSet || institutions.length === 0) return;

        const pageTitle = MonitoringComplianceUtilities.createPageTitle(institutions, districtId, "Matrix Specific Corrections");
        actions.updatePageTitle(pageTitle);

        setTitleIsSet(true);
    }, [institutions])

    if (!matrixProtocolCorrectiveResults) return null;

    const matrixRecordId = getMatrixRecordId();
    const matrixCorrectionResults = matrixProtocolCorrectiveResults.filter(f => f.studentRecordId === matrixRecordId);
    const totalSteps = calculateTotalPages();

    return (
        <section className={"compliance__studentCorrectionResults"}>
            {
                matrixProtocolCorrectiveResults.length === 0 &&
                <p>No matrix specific corrections are needed at this time.</p>
            }
            {
                matrixProtocolCorrectiveResults.length > 0 &&
                <div>
                    {
                        currentStep > 1 &&
                        currentStep < totalSteps &&
                        <ButtonBar position={ButtonBarPositions.STICKY}>
                            <Button
                                buttonType={ButtonTypes.BACK}
                                label={"Main Page"}
                                name={"btnBackTop"}
                                onClick={returnToMainPage}
                            />
                        </ButtonBar>
                    }
                    <MatrixResultsGrid
                        editCommentItemId={editCommentItemId}
                        handleCommentChange={handleCommentChange}
                        isCommentInEditMode={isCommentInEditMode}
                        isLoading={isLoading}
                        matrixCorrectionResults={matrixCorrectionResults}
                        onCommentCancel={(event) => onCommentCancel(event, "loadDistrictComplianceWithMatrixCorrectiveResults")}
                        onCommentCreate={onCommentCreate}
                        onCommentEdit={onCommentEdit}
                        onCommentSave={onCommentSave}
                        onMatrixProtocolCorrectiveResultOutcomeChange={(event, outcomeDto) => onMatrixProtocolCorrectiveResultOutcomeChange(event, outcomeDto, "Matrix", "loadDistrictComplianceWithMatrixCorrectiveResults")}
                    />
                    <BackNextPager
                        back={back}
                        backText={getBackButtonText(currentStep)}
                        currentPage={currentStep}
                        isBottom
                        next={next}
                        nextText={getNextButtonText(currentStep, totalSteps)}
                        totalPages={totalSteps}
                    />
                </div>
            }
        </section>
    );
}

MatrixCorrectionsStateView.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    editCommentItemId: PropTypes.string.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.func.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCommentCancel: PropTypes.func.isRequired,
    onCommentCreate: PropTypes.func.isRequired,
    onCommentEdit: PropTypes.func.isRequired,
    onCommentSave: PropTypes.func.isRequired,
    onMatrixProtocolCorrectiveResultOutcomeChange: PropTypes.func.isRequired,
    page: PropTypes.string,
    returnToMainPage: PropTypes.func.isRequired
};
