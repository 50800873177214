import PropTypes from "prop-types";
import React from "react";
import DisplayField from "../../../components/common/inputs/DisplayField";

const DrDueProcessActionResponse = ({
	                                    response
                                    }) => {

	return (
		<>
			{
				!response.dateCompleted &&
				<DisplayField
					name="notCompleted"
					label="Completed"
					showLabel
				>
					No
				</DisplayField>
			}
			{
				response.dateCompleted &&
				<DisplayField
					name="dateCompleted"
					label="Date Completed"
					showLabel
				>
					{response.dateCompleted}
				</DisplayField>
			}
			{
				response.results &&
				<DisplayField
					name="results"
					label="Results"
					showLabel
				>
					{response.results}
				</DisplayField>
			}
		</>
	);
};

DrDueProcessActionResponse.propTypes = {
	response: PropTypes.object
};

DrDueProcessActionResponse.defaultProps = {
	type: React.Fragment.toString()
};

export default DrDueProcessActionResponse;