import PropTypes from 'prop-types';
import React from 'react';
import Header from "./Header";
import Loader from "../common/Loader";

class Main extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const {component: Component, isDashboardLoading = false, isLoading, wrapperPage: WrapperPage} = this.props;
		return (
			<main className="l-page-container" id="main">
				<Loader isLoading={isLoading || isDashboardLoading} />
				<section aria-busy={isLoading} aria-live="polite" className={"content"} id="content">
					<Header
						pageTitle={this.props.layout.pageTitle}
						subTitle={this.props.layout.subTitle}
					/>
					{
						!WrapperPage &&
						<Component {...this.props} />
					}
					{
						WrapperPage &&
						<WrapperPage {...this.props} />
					}
				</section>
			</main>
		);
	}
}

Main.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	isDashboardLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	layout: PropTypes.object.isRequired,
	wrapperPage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	path: PropTypes.string
};

export default Main;
