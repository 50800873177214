import React from 'react';
import PropTypes from 'prop-types';
import {createListItem, deepCopyArray} from "../../../components/common/commonUtilities";
import SelectField from "../../../components/common/inputs/SelectField";
import {emptyGuid} from "../../../constants/config";

export const GmsApplicationHistorySelectField = ({
                                                     applicationHistories = [],
                                                     disabled = false,
                                                     includeCurrent = false,
                                                     includeDefaultOption = true,
                                                     label = "Submission History",
                                                     name = `lblApplicationHistory`,
                                                     selectedApplicationHistory,
                                                     setSelectedApplicationHistory,
                                                     showLabel = true
                                                 }) => {
    const onChangeApplicationHistory = (event) => {
        setSelectedApplicationHistory(event.target.value);
    };

    const options = deepCopyArray(applicationHistories);
    if (includeCurrent)
        options.unshift(createListItem(emptyGuid, "Current Application"));

    if (options.length === 0)
        return null;

    return (
        <SelectField
            defaultOption={"Select an Option"}
            disabled={disabled}
            label={label}
            includeDefaultOption={includeDefaultOption}
            isInline
            name={name}
            onChange={onChangeApplicationHistory}
            options={options}
            showLabel={showLabel}
            value={selectedApplicationHistory}
        />
    );
};

GmsApplicationHistorySelectField.propTypes = {
    applicationHistories: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
    includeCurrent: PropTypes.bool,
    includeDefaultOption: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
    showLabel: PropTypes.bool
};