import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import userApi from "../../../../../api/UserApi";
import {isArrayNullOrEmpty, isNullOrUndefined, setPageTitle} from "../../../../../components/common/commonUtilities";
import {UserSearchCriteria} from "../../../../../components/common/searchCriterias/userSearchCriteria";
import * as behaviors from "../../../../../constants/behaviors";
import * as contexts from "../../../../../constants/contexts";
import {USER_TYPES} from "../../../../../constants/users/userConstants";
import * as userConstants from "../../../../../constants/users/userConstants";
import ptsApi from "../../../ptsApi";
import * as ptsLocations from "../../../ptsLocations";
import ptsManageProgramsApi from "../ptsManageProgramsApi";
import {createProgramObject} from "../ptsManageProgramsObjectFactory";
import ptsManageFiscalAgentsApi from "./ptsManageFiscalAgentsUsersApi";
import {PtsManageFiscalAgentsUsersView} from "./PtsManageFiscalAgentsUsersView";
import {createSaveFiscalAgentsUsersArray} from "./ptsManageFiscalAgentUsersUtilities";

const PtsManageFiscalAgentsUsersContainer = ({
                                                 allFiscalAgents,
                                                 actions,
                                                 programId,
                                                 gotoLocation,
                                                 handleDataChanged,
                                                 isLoading
                                             }) => {
    const [program, setProgram] = useState(null);
    const [users, setUsers] = useState(null);
    const [gmsUsers, setGmsUsers] = useState([]);

    const handleSavePtsProgramFiscalAgentsUsers = async (fiscalAgentIds, programUserIds, liaisonUserIds, grantManagementUserIds) => {
        const fiscalAgentUsers = createSaveFiscalAgentsUsersArray(fiscalAgentIds, programUserIds, liaisonUserIds, grantManagementUserIds);

        await actions.executeApi(ptsManageFiscalAgentsApi.saveProgramFiscalAgents, [programId, fiscalAgentUsers]);
        gotoLocation(ptsLocations.PTS_ADMIN_MANAGE_PROGRAMS.path, true);
    };

    const initializeData = async () => {
        await actions.executeApi(ptsApi.getGmsUsers)
            .then(setGmsUsers);

        const result = await actions.executeApi(ptsManageProgramsApi.getProgram, [programId]);
        const updatedProgram = createProgramObject(result);
        setProgram(updatedProgram);

        let userSearchCriteria = new UserSearchCriteria("", `${contexts.PTS}.${behaviors.VIEW}`, USER_TYPES.All, userConstants.STATUS.ACTIVE);
        userSearchCriteria.RecordsPerPage = 0;
        userSearchCriteria.SortByColumn = "LastName";
        userApi.getUsers(userSearchCriteria).then((result) => setUsers(result.data));
    };

    useEffect(() => {
        initializeData();
    }, []);

    useEffect(() => {
        if(isNullOrUndefined(program)) return;

        actions.updatePageTitle(`PTS Admin - ${program.programName}`);
        setPageTitle(`PTS Admin - ${program.programName} - Associate Fiscal Agents and Users`);
    }, [program]);

    if (isNullOrUndefined(program) || isArrayNullOrEmpty(allFiscalAgents) || isNullOrUndefined(users))
        return null;

    return (
        <PtsManageFiscalAgentsUsersView
            program={program}
            fiscalAgents={allFiscalAgents}
            gmsUsers={gmsUsers}
            gotoLocation={gotoLocation}
            handleDataChanged={handleDataChanged}
            handleSavePtsProgramFiscalAgentsUsers={handleSavePtsProgramFiscalAgentsUsers}
            isLoading={isLoading}
            users={users}
        />
    );
};

PtsManageFiscalAgentsUsersContainer.propTypes = {
    allFiscalAgents: PropTypes.array,
    actions: PropTypes.object,
    programId: PropTypes.string.isRequired,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default PtsManageFiscalAgentsUsersContainer;
