import React from "react";
import PropTypes from "prop-types";
import TextField from "../common/inputs/TextField";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const GoalForm = ({goal, isLoading, onSave, onCancel, onChange}) => {
	return (
		<tr id={goal.id}>
			<td>
				<TextField name={"text"}
				           label={"Goal Text"}
				           showLabel={false}
				           value={goal.text}
				           placeholder={"Goal Text"}
				           onChange={onChange}/>
			</td>
			<td className="button-cell">
				<ButtonBar position={ButtonBarPositions.TABLE}>
					<Button name={"save"}
					        label={"save"}
					        showLabel={false}
					        onClick={onSave}
					        disabled={isLoading}
					        buttonType={ButtonTypes.SAVE}/>
					<Button name={"cancel"}
					        label={"cancel"}
					        showLabel={false}
					        onClick={onCancel}
					        disabled={isLoading}
					        buttonType={ButtonTypes.CANCEL}/>
				</ButtonBar>
			</td>
		</tr>
	);
};

GoalForm.propTypes = {
	goal: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};

export default GoalForm;