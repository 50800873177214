import React, {useEffect, useState} from 'react';
import {PtsPolicy, policyEvents} from "../../../components/authorization/policies/PtsPolicy";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {createSaveImplementationObject, createSaveSourceDocumentationObject} from "../ptsObjectFactory";
import ptsDeliverableSummaryApi from "./ptsDeliverableSummaryApi";
import {PtsDeliverableSummaryView} from "./PtsDeliverableSummaryView";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";

export const PtsDeliverableSummaryContainer = ({
                                                   actions,
                                                   dataChanged,
                                                   dateWindowId,
                                                   discretionaryProjectId,
                                                   districtsList,
                                                   handleDataChanged,
                                                   pageSubTitle,
                                                   project,
                                                   updateDiscretionaryProject
                                               }) => {
    const [editImplementationId, setEditImplementationId] = useState(null);
    const [activityId, setActivityId] = useState(null);
    const [selectedQuarter, setSelectedQuarter] = useState(null);
    const canEdit = AuthorizationUtilities.allow(PtsPolicy, policyEvents.IMPLEMENTATIONS.edit)
        && !project.isCompleted;
    const canModifyDocumentation = AuthorizationUtilities.allow(PtsPolicy, policyEvents.SOURCE_DOCUMENTATION.modify) && !project.isCompleted;

    const setActivityAndImplementation = (activityId, implementationId, quarter) => {
        setActivityId(activityId);
        setEditImplementationId(implementationId);
        setSelectedQuarter(quarter);
    };

    const handleSaveImplementation = async (activityId, quarter, implementation, activityCategory) => {
        const saveImplementation = createSaveImplementationObject(implementation);

        NotifyUser.clear(true);

        const updatedProject = await actions.executeApi(ptsDeliverableSummaryApi.saveImplementation,
            [
                dateWindowId,
                discretionaryProjectId,
                activityId,
                quarter,
                saveImplementation,
                activityCategory
            ]
        );

        updateDiscretionaryProject(updatedProject);
        setActivityAndImplementation(null, null, null);
    };

    const handleDeleteImplementation = async (activityId, quarter, implementationId) => {
        NotifyUser.clear(true);

        const updatedProject = await actions.executeApi(ptsDeliverableSummaryApi.deleteImplementation,
            [
                dateWindowId,
                discretionaryProjectId,
                activityId,
                quarter,
                implementationId
            ]
        );

        updateDiscretionaryProject(updatedProject);
        setActivityAndImplementation(null, null, null);
    };

    const handleFileDownload = (fileId) => {
        actions.downloadFile(fileId);
    };

    const handleFileDelete = async (activityId, fileId, updatedFileIds) => {
        NotifyUser.clear(true);
        return await actions.deleteFile(fileId)
            .then(async (isFileDeleted) => {
                if (isFileDeleted) {
                    const updatedProject = await actions.executeApi(ptsDeliverableSummaryApi.updateImplementationSourceDocumentation,
                        [
                            dateWindowId,
                            discretionaryProjectId,
                            activityId,
                            fileId,
                            updatedFileIds
                        ]
                    );
                    updateDiscretionaryProject(updatedProject);
                    return true;
                }
                return false;
            });
    };

    const handleFileUpload = async (activityId, files, currentFileDetails, selectedQuarter) => {
        NotifyUser.clear(true);
        return await actions.uploadFile(files)
            .then(async (fileId) => {
                if (!fileId) return null;

                const newFileDetail = createSaveSourceDocumentationObject(fileId, selectedQuarter);

                const updatedCurrentFileDetails = [...currentFileDetails, newFileDetail];

                const updatedProject = await actions.executeApi(ptsDeliverableSummaryApi.updateImplementationSourceDocumentation,
                    [
                        dateWindowId,
                        discretionaryProjectId,
                        activityId,
                        fileId,
                        updatedCurrentFileDetails
                    ]);
                updateDiscretionaryProject(updatedProject);
                return newFileDetail;
            });
    };

    useEffect(() => {
        actions.updatePageTitle(`Deliverables Summary ${pageSubTitle}`);
    }, []);

    if (isNullOrUndefined(project))
        return null;

    return <PtsDeliverableSummaryView
            activityId={activityId}
            canEdit={canEdit}
            canModifyDocumentation={canModifyDocumentation}
            dataChanged={dataChanged}
            districtsList={districtsList}
            editImplementationId={editImplementationId}
            handleDataChanged={handleDataChanged}
            handleDeleteImplementation={handleDeleteImplementation}
            handleFileDelete={handleFileDelete}
            handleFileDownload={handleFileDownload}
            handleFileUpload={handleFileUpload}
            handleSaveImplementation={handleSaveImplementation}
            project={project}
            selectedQuarter={selectedQuarter}
            setActivityAndImplementation={setActivityAndImplementation}
        />;
};

PtsDeliverableSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    dateWindowId: PropTypes.string,
    discretionaryProjectId: PropTypes.string.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    pageSubTitle: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
    updateDiscretionaryProject: PropTypes.func.isRequired
};