import React from "react";
import PropTypes from 'prop-types';
import {Allow} from "../../../components/authorization/Allow";
import {PtsPolicy, policyEvents} from "../../../components/authorization/policies/PtsPolicy";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";

export const PtsDeliverableSummaryButtons = ({
                                                 disableButtons,
                                                 disableFields,
                                                 handleClickCancelEdit,
                                                 handleClickDelete,
                                                 handleClickSave,
                                                 isAdding,
                                                 validateForm
                                             }) => {
    const cancelLabel = disableFields ? `Close` : `Cancel`;
    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`implementationEditButtonContainer`}>
            {
                !disableFields &&
                <Button buttonType={ButtonTypes.SAVE} label={`Save`} name={`btnSave`} disabled={disableButtons}
                        onClick={(event) => handleClickSave(event, validateForm)} showLabel/>
            }

            <Button buttonType={ButtonTypes.CANCEL} label={cancelLabel} name={`btnCancel`} disabled={disableButtons}
                    onClick={handleClickCancelEdit} showLabel/>

            {
                !disableFields && !isAdding &&
                <Allow policy={PtsPolicy} policyEvent={policyEvents.IMPLEMENTATIONS.delete}>
                    <Button buttonType={ButtonTypes.DELETE} label={`Delete`} name={`btnDelete`} disabled={disableButtons}
                            onClick={handleClickDelete} showLabel/>
                </Allow>
            }
        </ButtonBar>
    );
};

PtsDeliverableSummaryButtons.propTypes = {
    disableButtons: PropTypes.bool.isRequired,
    disableFields: PropTypes.bool.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    isAdding: PropTypes.bool.isRequired,
    validateForm: PropTypes.func.isRequired
};