import React from "react";
import PropTypes from "prop-types";
import * as indicatorTypes from "../../constants/indicatorTypes";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import Allow from "../authorization/Allow";
import ActionBar from "../common/buttons/ActionBar";
import IndicatorMetrics from "./IndicatorMetrics";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import IndicatorMetricsForm from "./IndicatorMetricsForm";
import FileViewDelete from "../common/inputs/FileViewDelete";
import {allow} from "../authorization/AuthorizationUtilities";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

//eslint-disable

const StrategyIndicator = ({
                               strategyIndicator,
                               sppIndicators,
                               filters,
	                           isEditing,
                               isLoading,
                               onEditIndicator,
                               onEditMetrics,
                               onCreateMetric,
                               onChangeMetric,
                               onSaveMetrics,
                               onDeleteFile,
                               onCancel,
                               editItemId,
	                           metricYearSelectListItems,
                               onDeleteIndicator,
                               downloadFile,
                               planStartYear
                           }) => {
    return (
        <section className={"strategy__indicator "} id={strategyIndicator.id} name={strategyIndicator.id}>
            <header>
                {
                    !isEditing &&
                    filters.showButtons &&
                    <Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_INDICATOR}>
                        <ActionBar
                            elementId={strategyIndicator.id}
                            onDeleteClick={(event) => onDeleteIndicator(event, strategyIndicator.id)}
                            onEditClick={(event) => onEditIndicator(event, strategyIndicator.id)}
                            disabled={isLoading}/>
                    </Allow>
                }

                {
                    strategyIndicator.indicators.map((ind, i) => {
                        const sppIndicator = sppIndicators.filter(i => i.publicIdentifier === ind.text)[0];

                        if (ind.indicatorType === indicatorTypes.BEESS) {
                            if (ind.text === "" || ind.text === null) return null;

                            return (
                                <div key={i}>
                                    <h3>BEESS Indicator</h3>
                                    <div>{ind.text}</div>
                                </div>
                            );
                        }
                        else {
                            return (
                                <div key={i}>
                                    <h3>SPP Indicator {sppIndicator.publicIdentifier}</h3>
                                    <HtmlDisplayBlock html={sppIndicator.text} />
                                </div>
                            );
                        }
                    })
                }

                {
                    filters.showIndicators &&
                    strategyIndicator.triangleFileId &&
                    <FileViewDelete label={"Tiered Districts Document"}
                                    name={strategyIndicator.triangleFileId}
                                    onDelete={allow(StrategicPlanPolicy, policyEvents.ADD_INDICATOR)
                                        ? onDeleteFile
                                        : null}
                                    onView={downloadFile}/>
                }
            </header>

            {
                filters.showIndicators &&
                (strategyIndicator.baseline || strategyIndicator.metrics.length > 0) &&
                (!isEditing || (isEditing && editItemId !== strategyIndicator.baseline.id)) &&
                <IndicatorMetrics strategyIndicator={strategyIndicator}
                                  metricId={strategyIndicator.baseline.id}
                                  isEditing={isEditing}
                                  onEditMetrics={onEditMetrics}
                                  filters={filters}
                                  planStartYear={planStartYear}/>
            }

            {
                isEditing &&
                (strategyIndicator.baseline && editItemId === strategyIndicator.baseline.id) &&
                <IndicatorMetricsForm isLoading={isLoading}
                                      planStartYear={planStartYear}
                                      strategyIndicator={strategyIndicator}
                                      metricYearSelectListItems={metricYearSelectListItems}
                                      onChange={onChangeMetric}
                                      onCancel={onCancel}
                                      onSave={onSaveMetrics}
                                      onCreateMetric={onCreateMetric}/>
            }

            {
                filters.showIndicators &&
                filters.showButtons &&
                !isEditing &&
                !strategyIndicator.baseline &&
                <Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_METRICS}>
                    <TileContainer rowTilesLarge={"1"}>
                        <Tile isNew={true} body={"Add Data"} onClick={onCreateMetric}/>
                    </TileContainer>
                </Allow>
            }
        </section>
    );
};

StrategyIndicator.propTypes = {
    strategyIndicator: PropTypes.object.isRequired,
    filters: PropTypes.object,
    sppIndicators: PropTypes.array,
	metricYearSelectListItems: PropTypes.array,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onEditIndicator: PropTypes.func,
    onCreateMetric: PropTypes.func,
    editItemId: PropTypes.string,
    onChangeMetric: PropTypes.func,
    onSaveMetrics: PropTypes.func,
    onCancel: PropTypes.func,
    onEditMetrics: PropTypes.func,
    onDeleteIndicator: PropTypes.func,
    downloadFile: PropTypes.func,
    onDeleteFile: PropTypes.func,
    planStartYear: PropTypes.string
};

export default StrategyIndicator;