import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import FormTable from "../../components/common/FormTable";
import PasswordField from "../../components/common/inputs/PasswordField";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as buttonTypes from "../../constants/ButtonTypes";
import * as accountActions from "../../actions/accountActions";
import * as layoutActions from "../../actions/layoutActions";
import * as userActions from "../../actions/userActions";
import * as publicLocations from "../../constants/publicLocations";
import {catchError} from "../../actions/actionUtility";
import {NotifyUser} from "../../components/common/NotifyUser";
import {getParams} from "../../components/layout/getParams";
import DisplayField from "../../components/common/inputs/DisplayField";

export class ResetPasswordPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			resetPasswordViewModel: {
				email: "",
				password: "",
				confirmPassword: "",
				code: ""
			}
		};

		this.handleFormChange = this.handleFormChange.bind(this);
		this.onClick = this.onClick.bind(this);
	}

	componentDidMount() {
		const params = getParams(this.props.location.pathname, publicLocations.USER_RESET_PASSWORD);

		this.props.actions.updatePageTitle("Reset Password");

		this.props.actions.loadUserById(params.userId);
	}

	componentDidUpdate() {
		if (this.state.resetPasswordViewModel.email === "" &&
			this.props.user.email !== undefined) {
			const viewModel = this.state.resetPasswordViewModel;

			viewModel.email = this.props.user.email;

			this.setState({
				resetPasswordViewModel: viewModel
			});
		}
	}

	handleFormChange(event) {
		event.preventDefault();

		let viewModel = this.state.resetPasswordViewModel;
		viewModel[event.target.id] = event.target.value;

		this.setState({
			resetPasswordViewModel: viewModel
		});
	}

	onClick(event) {
		event.preventDefault();

		if (this.state.resetPasswordViewModel.password !== this.state.resetPasswordViewModel.confirmPassword)
			NotifyUser.Warning("The passwords do not match.");
		else if (this.state.resetPasswordViewModel.password.length < 6)
			NotifyUser.Warning("The password be 6-100 characters in length.");
		else {
			this.props.actions.resetPassword(this.state.resetPasswordViewModel)
				.then(() => {
					this.props.history.push(publicLocations.USER_SIGN_IN.path);
				})
				.catch(error => catchError(error));
		}
	}

	render() {
		if (this.props.isLoading)
			return null;
		if (!this.state.resetPasswordViewModel.email)
			return <p>User account not found.</p>;

		return (
			<div>
				<FormTable>
					<DisplayField label={`Password requirements:`} name={`requirements`} showLabel>
						<ul>
							<li>have at least six characters</li>
							<li>have at least one lower case letter</li>
							<li>have at least one upper case letter</li>
							<li>have at least one number</li>
							<li>have at least one non-alphanumeric character</li>
						</ul>
					</DisplayField>
					<DisplayField name={`email`} label={`Email`} showLabel>
						{this.state.resetPasswordViewModel.email}
					</DisplayField>
					<PasswordField
						name="password"
						inputType={"password"}
						showLabel
						label="New Password"
						onChange={this.handleFormChange}
						value={this.state.resetPasswordViewModel.password}
					/>
					<PasswordField
						name="confirmPassword"
						inputType={"password"}
						showLabel
						label="Confirm Password"
						onChange={this.handleFormChange}
						value={this.state.resetPasswordViewModel.confirmPassword}
					/>
					<ButtonBar position={ButtonBarPositions.TABLE}>
						<Button name={"btnResetPassword"}
						        buttonType={buttonTypes.PRIMARY}
						        label={"Submit"}
						        disabled={this.props.isLoading}
						        onClick={this.onClick}/>
					</ButtonBar>
				</FormTable>
			</div>
		);
	}
}

ResetPasswordPage.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	user: PropTypes.object
};

function mapStateToProps(state) {
	return {
		isLoading: state.ajaxCallsInProgress > 0,
		user: state.user.selectedUser
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, accountActions, layoutActions, userActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);