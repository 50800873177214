import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import * as searchCriteriaActions from "../../../actions/searchCriteriaActions";
import AccountUtility from "../../../api/AccountUtility";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy} from "../../../components/authorization/policies/GmsPolicy";
import Button from "../../../components/common/buttons/Button";
import {createListItem, isArrayNullOrEmpty, setPageTitle} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import * as LayoutActions from "../../../actions/layoutActions";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    GmsFilterVisibilityRules,
    GmsSearchCriteria,
} from "../../../components/common/searchCriterias/GmsSearchCriteria";
import {getParamsMultiple} from "../../../components/layout/getParams";
import {generateDateWindowSelectListItemsAfterYear} from "../../../components/shared/sharedDataUtilities";
import * as policyEvents from "../../../constants/policyEvents";
import {DASHBOARD} from "../../../constants/systemLocations";
import {GmsFilter} from "../GmsFilter";
import * as gmsLocations from "../gmsLocations";
import {GMS_ADMIN_SECTIONS} from "./gmsAdminConstants";

const GmsAdminWrapper = ({
                             actions,
                             allFiscalAgents,
                             Component,
                             currentLocation,
                             dateWindows,
                             history,
                             isLoading,
                             params,
                             props,
                             searchCriteria,
                         }) => {
    const [dataChanged, setDataChanged] = useState(false);

    const teamMembersUrl = gmsLocations.GMS_ADMIN_TEAM_MEMBERS.getUrl();
    const manageApplicationsUrl = gmsLocations.GMS_ADMIN_MANAGE_APPLICATIONS.getUrl();
    const manageDiscretionaryProjectsUrl = gmsLocations.GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS.getUrl();

    const pageShowsGmsFilter = [manageApplicationsUrl, manageDiscretionaryProjectsUrl];

    const handleSelectSection = (event, section) => {
        let url = "";
        switch (section) {
            case GMS_ADMIN_SECTIONS.teamMembers:
                url = teamMembersUrl;
                break;
            case GMS_ADMIN_SECTIONS.manageApplications:
                url = manageApplicationsUrl;
                break;
            case GMS_ADMIN_SECTIONS.manageDiscretionaryProjects:
                url = manageDiscretionaryProjectsUrl;
                break;
        }

        if (url)
            navButtonClick(event, url);
    };

    const navButtonClick = (event, location) => {
        event.preventDefault();

        gotoLocation(location);
    };

    const gotoLocation = (location, ignoreDataChanged = false) => {
        if (location === currentLocation) return;

        if (!ignoreDataChanged && dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setDataChanged(false);

        history.push(location);
    };

    const getSection = (location) => {
        switch (location) {
            case teamMembersUrl:
                return GMS_ADMIN_SECTIONS.teamMembers;
            case manageApplicationsUrl:
                return GMS_ADMIN_SECTIONS.manageApplications;
            case manageDiscretionaryProjectsUrl:
                return GMS_ADMIN_SECTIONS.manageDiscretionaryProjects;
        }
    };

    const handleSearchFiltersChange = (criteria) => {
        if (dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setDataChanged(false);
        actions.saveSearchCriteria(criteria);
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const getCurrentLocationVisibilityRules = () => {
        let visibilityRules = null;
        switch (currentLocation) {
            case manageApplicationsUrl:
            case teamMembersUrl:
                visibilityRules = GmsFilterVisibilityRules.AdminWrapper;
                break;
            case manageDiscretionaryProjectsUrl:
                visibilityRules = GmsFilterVisibilityRules.DiscretionaryProject;
                break;
            default:
                visibilityRules = GmsFilterVisibilityRules.AdminWrapper;
                break;
        }

        return visibilityRules;
    }

    useEffect(() => {
        if (!currentLocation) return;

        if(!currentLocation) {
            actions.updatePageTitle(`GMS Admin`);
            setPageTitle(`GMS Admin - ${getSection(currentLocation)}`);
        }
    }, [currentLocation]);

    useEffect(() => {
        if (AccountUtility.isFiscalAgentUser()
            || !AuthorizationUtilities.allow(GmsPolicy, policyEvents.MANAGE)) {
            history.push(DASHBOARD.path);
        }
    }, []);

    useEffect(() => {
        if (isArrayNullOrEmpty(dateWindows) || isArrayNullOrEmpty(allFiscalAgents)) return;

        const visibilityRules = getCurrentLocationVisibilityRules();

        if (!visibilityRules || searchCriteria.areRulesCurrent(visibilityRules)) return;

        let criteria = {...searchCriteria};

        if (!criteria.dateWindowId)
            criteria.dateWindowId = dateWindows[0].value;

        criteria.allowAllDateWindows = false;
        criteria.setVisibilityRules(visibilityRules);
        criteria.setFiscalAgentList(allFiscalAgents);
        actions.saveSearchCriteria(criteria);

    }, [dateWindows, allFiscalAgents, currentLocation]);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(getCurrentLocationVisibilityRules()))
        return null;

    return (
        <GridRow isPageNav>
            <GridColumn
                isPageNavLinks
                isStickyLinks
                large="3"
            >
                <Button
                    btnClass={currentLocation === teamMembersUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, teamMembersUrl)}
                    label={GMS_ADMIN_SECTIONS.teamMembers}
                    name={teamMembersUrl}
                />
                <Button
                    btnClass={currentLocation === manageApplicationsUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, manageApplicationsUrl)}
                    label={GMS_ADMIN_SECTIONS.manageApplications}
                    name={manageApplicationsUrl}
                />
                <Button
                    btnClass={currentLocation === manageDiscretionaryProjectsUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, manageDiscretionaryProjectsUrl)}
                    label={GMS_ADMIN_SECTIONS.manageDiscretionaryProjects}
                    name={manageDiscretionaryProjectsUrl}
                />
            </GridColumn>

            <GridColumn
                isPageNavContent
                large="9"
            >
                {
                    pageShowsGmsFilter.find(f => f === currentLocation) &&
                    <GmsFilter
                        dateWindows={dateWindows}
                        handleSearchFiltersChange={handleSearchFiltersChange}
                        searchCriteria={searchCriteria}
                    />
                }
                <Component
                    {...props}
                    {...params}
                    actions={actions}
                    allFiscalAgents={allFiscalAgents}
                    dataChanged={dataChanged}
                    dateWindowId={searchCriteria.dateWindowId}
                    gotoLocation={gotoLocation}
                    handleDataChanged={handleDataChanged}
                    handleSelectSection={handleSelectSection}
                    isLoading={isLoading}
                    searchCriteria={searchCriteria}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const searchCriteria = state.searchCriteria.GmsSearchCriteria || new GmsSearchCriteria();
    const dateWindows = generateDateWindowSelectListItemsAfterYear([...state.sharedData.dateWindows], "2022");
    const currentLocation = props.location.pathname;

    const institutions = [...state.sharedData.institutionsWithDiscretionaryAgencies];
    const allFiscalAgents = institutions.map(ins => createListItem(ins.id, ins.name));

    const multipleParams = getParamsMultiple(currentLocation, gmsLocations.ALL_GMS_ROUTES);
    const params = !multipleParams ? {} : multipleParams;

    return {
        allFiscalAgents,
        Component,
        currentLocation,
        dateWindows,
        params,
        props,
        isLoading: state.ajaxCallsInProgress > 0,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, searchCriteriaActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch),
    };
};

GmsAdminWrapper.propTypes = {
    actions: PropTypes.object,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.array.isRequired,
    history: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    params: PropTypes.object,
    props: PropTypes.object,
    searchCriteria: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GmsAdminWrapper);