import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import {emptyGuid} from "../../../../constants/config";

export const createProgramObject = ({
                                        programFiscalAgents,
                                        programId = emptyGuid,
                                        programName = "",
                                        requiresReturnOnInvestmentReport = false
                                    } = {}) => ({
    programId,
    programName,
    requiresReturnOnInvestmentReport,
    programFiscalAgents: isArrayNullOrEmpty(programFiscalAgents)
        ? []
        : programFiscalAgents.map(fiscalAgent => createProgramFiscalAgentObject(fiscalAgent))
});

const createProgramFiscalAgentObject = ({
                                            programUsers,
                                            fiscalAgentId,
                                            fiscalAgentName
                                        } = {}) => ({
    programUsers: isArrayNullOrEmpty(programUsers)
        ? []
        : programUsers.map(programUser => createProgramUserObject(programUser)),
    fiscalAgentId,
    fiscalAgentName
});

const createProgramUserObject = ({
                                     applicationUserId,
                                     firstName,
                                     gmsUserId,
                                     lastName,
                                     ptsRole
                                 } = {}) => ({
    applicationUserId,
    firstName,
    gmsUserId,
    lastName,
    ptsRole
});
