import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as LayoutActions from "../../actions/layoutActions";
import AccountUtility from "../../api/AccountUtility";
import {isArrayNullOrEmpty, setPageTitle} from "../../components/common/commonUtilities";
import {fetchDate, fetchDistrict,} from "../../components/shared/sharedDataUtilities";
import {CaseNumberPostFix, DisputeResolutionType} from "../drConstants";
import * as drLocations from "../drLocations";
import DrMediationOverviewView from "../Mediations/DrMediationOverviewView";
import DrNotificationsContainer from "../Notifications/DrNotificationsContainer";
import {createMediationObject} from "./drMediationObjectFactory";
import drMediationsApi from "./drMediationsApi";
import {createCaseNumberObject} from "../drUtilities";

const DrMediationContainer = ({
                                  actions,
                                  api,
                                  dateWindowId,
                                  dateWindows,
                                  history,
                                  institutionId,
                                  institutions,
                                  mediationId,
                              }) => {

    const [district, setDistrict] = useState("");
    const [date, setDate] = useState("");
    const [returnUrl, setReturnUrl] = useState("/");
    const [mediation, setMediation] = useState(null);

    const GetDefaultCaseNumber = async () => {
        await actions.executeApi(api.GetDefaultCaseNumber, [dateWindowId])
            .then((result) => {
                const newMediation = createMediationObject();
                newMediation.caseNumberCount = result;

                const caseNumberObject = createCaseNumberObject(result, CaseNumberPostFix.Mediation, dateWindows, dateWindowId);

                newMediation.caseNumberYear = caseNumberObject.caseNumberYear;
                newMediation.caseNumberType = caseNumberObject.caseNumberType;
                newMediation.caseNumber = caseNumberObject.caseNumber;

                setMediation(newMediation);
            });
    };

    const getMediation = async () => {
        await actions.executeApi(api.getMediation, [mediationId])
            .then((result) => {
                setMediation(result);
            });
    };

    const handleReturn = () => {
        const isDistrictUser = AccountUtility.isDistrictUser();
        const url = isDistrictUser ? returnUrl : drLocations.LANDING_CASE_LIST.getUrl();
        history.push(url);
    };

    const handleClickSave = async (event, form) => {
        event.preventDefault();
        form.dateWindowId = dateWindowId;
        form.districtId = institutionId;

        if (mediationId === drLocations.MEDIATION_ID_NEW) {
            await actions.executeApi(api.addMediation, [form])
                .then(handleReturn);
        }
        else {
            form.id = mediation.id;
            await actions.executeApi(api.saveMediation, [form])
                .then(handleReturn);
        }
    };

    const handleClickDelete = async (event) => {
        event.preventDefault();

        if (!confirm("Are you sure you want to delete this mediation?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
            return;

        await actions.executeApi(api.deleteMediation, [mediationId])
            .then(handleReturn);
    };

    useEffect(() => {
        if (date || isArrayNullOrEmpty(dateWindows)) return;

        if (mediationId && mediationId !== drLocations.MEDIATION_ID_NEW)
            getMediation();
        else {
            GetDefaultCaseNumber();
        }
    }, [mediationId, dateWindowId, date, dateWindows]);

    useEffect(() => {
        setDistrict(fetchDistrict(institutions, institutionId));
    }, [institutions, institutionId]);

    useEffect(() => {
        if (date || isArrayNullOrEmpty(dateWindows)) return;

        setDate(fetchDate(dateWindows, dateWindowId));
    }, [dateWindows, dateWindowId]);

    useEffect(() => {
        actions.updatePageTitle(`State Mediation`, ``, `${district} (${date})`);
        setPageTitle(`State Mediation: ${district} (${date})`);
    }, [district, date]);

    useEffect(() => {
        setReturnUrl(drLocations.LANDING_DISTRICT.getUrl(institutionId));
    }, [institutionId]);

    if (!mediation) return null;

    return (
        <>
            <DrNotificationsContainer
                actions={actions}
                id={mediationId}
                drType={DisputeResolutionType.Mediation}
            />

            <DrMediationOverviewView
                handleClickDelete={mediationId !== drLocations.MEDIATION_ID_NEW ? handleClickDelete : null}
                handleClickSave={handleClickSave}
                handleReturn={handleReturn}
                mediation={mediation}
                dateWindowId={dateWindowId}
                institutionId={institutionId}
            />
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const {DrMediationOverviewView: DrMediationOverviewView} = ownProps;
    const params = drLocations.MEDIATION_OVERVIEW.getParams(ownProps.location.pathname);
    const api = drMediationsApi;

    return {
        api,
        DrMediationOverviewView,
        currentLocation: ownProps.location.pathname,
        ...params,
        props: ownProps
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrMediationContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    api: PropTypes.func.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    institutionId: PropTypes.string.isRequired,
    institutions: PropTypes.array.isRequired,
    mediationId: PropTypes.string.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrMediationContainer);
