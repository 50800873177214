import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonTypes from "../../../constants/ButtonTypes";
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";

const Button = ({
                    btnClass = "",
                    buttonType = ButtonTypes.SYSTEM,
                    disabled = false,
                    inputDescription = "",
                    isSubmitButton = false,
                    label,
                    name,
                    onClick,
                    showLabel = true,
                }) => {

    const handleOnClick = (event) => {
        if(!onClick) return;

        while(event.target.tagName.toLowerCase() !== "button")
            event.target = event.target.parentElement;

        onClick(event);
    }

    return (
        <button
            className={`button ${buttonType} ${btnClass}`}
            disabled={disabled || !onClick}
            id={name}
            onClick={handleOnClick}
            title={!showLabel ? label : ""}
            type={isSubmitButton ? `submit` : `button`}
        >
            <HtmlDisplayInline html={label} name={`${name}_label`} className={!showLabel ? " is-visuallyhidden " : ""}/>
            <HtmlDisplayInline html={inputDescription} className={`show-for-sr`} />
        </button>
    );
};

Button.propTypes = {
    btnClass: PropTypes.string,
    buttonType: PropTypes.string,
    disabled: PropTypes.bool,
    inputDescription: PropTypes.string,
    isSubmitButton: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    showLabel: PropTypes.bool
};

export default Button;