import React from "react";
import PropTypes from "prop-types";
import Allow from "../authorization/Allow";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import SubAction from "./SubAction";
import SubActionForm from "./SubActionForm";
import ActionBar from "../common/buttons/ActionBar";

const StrategyAction = ({
	                        action,
							actionIndex,
	                        filters,
	                        isEditing,
	                        editItemId,
	                        onEditAction,
	                        onDeleteAction,
	                        onEditSubAction,
	                        onEditActivity,
	                        onAddSubAction,
	                        onChangeSubAction,
	                        onSaveSubAction,
	                        onDeleteSubAction,
	                        onAddActivity,
	                        onChangeActivity,
                            onChangeActivityDate,
	                        onSaveActivity,
	                        onDeleteActivity,
	                        onCancel,
	                        isLoading
                        }) => {
	return (
		<section className={"strategy__action "} id={action.id} name={action.id}>
			<header>
				{
					!isEditing &&
                    filters.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_ACTION}>
						<ActionBar
							elementId={action.id}
							onEditClick={(event) => onEditAction(event, action.id)}
							onDeleteClick={action.subActions.length === 0 ? onDeleteAction : null}
							disabled={isLoading} />
					</Allow>
				}
				<h3>Action {actionIndex + 1}</h3>
				<div>{action.text}</div>
				{
					filters.showActions &&
					<p><strong>Responsible Entity:</strong> {action.responsibleParty}</p>
				}

				{
					filters.showActions &&
					<p><strong>Progress Monitoring Process:</strong> {action.progressMonitoringNotes}</p>
				}

				{
					filters.showActions &&
					<div><strong>Resources</strong>
						{
							action.resources.length === 0 &&
							<div>None</div>
						}
						{
							action.resources.length > 0 &&
							<ul>
								{
									action.resources.map((re) =>
										<li key={re}>{re}</li>)
								}
							</ul>

						}

					</div>
				}
			</header>

			{
				(filters.showSubActions ||
				filters.showProgress) &&
				action.subActions.length > 0 &&
				action.subActions.map((sub, i) => {
					if (isEditing && editItemId === sub.id) {
						return (<SubActionForm
							key={sub.id}
							filters={filters}
							onChange={(event, strategyId, actionId) => onChangeSubAction(event, strategyId, actionId, sub.id)}
							onCancel={onCancel}
							onSave={(event, strategyId, actionId) => onSaveSubAction(event, strategyId, actionId, sub.id)}
							isLoading={isLoading}
							subAction={sub}
                            subActionIndex={i}
                            actionIndex={actionIndex}
							actionOrder={action.order}/>);
					}
					else
                        return (<SubAction key={sub.id}
                                           filters={filters}
                                           actionOrder={action.order}
                                           subAction={sub}
                                           subActionIndex={i}
                                           actionIndex={actionIndex}
                                           editItemId={editItemId}
                                           onCancel={onCancel}
                                           isLoading={isLoading}
                                           isEditing={isEditing}
                                           onEditActivity={onEditActivity}
                                           onChangeActivity={(event, strategyId, actionId, subActionId, activityId) => onChangeActivity(event, strategyId, actionId, sub.id, activityId)}
                                           onChangeActivityDate={(event, strategyId, actionId, subActionId, activityId) => onChangeActivityDate(event, strategyId, actionId, sub.id, activityId)}
                                           onSaveActivity={(event, strategyId, actionId, subActionId, activityId) => onSaveActivity(event, strategyId, actionId, sub.id, activityId)}
                                           onDeleteActivity={(event, strategyId, actionId, subActionId, activityId) => onDeleteActivity(event, strategyId, actionId, sub.id, activityId)}
                                           onAddActivity={(event, strategyId, actionId) => onAddActivity(event, strategyId, actionId, sub.id)}
                                           onEditSubAction={onEditSubAction}
                                           onDeleteSubAction={(event, strategyId, actionId) => onDeleteSubAction(event, strategyId, actionId, sub.id)}/>);
				})
			}

			{
				filters.showButtons &&
				filters.showSubActions &&
				<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_SUB_ACTION}>
					<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
						<Tile body={"Add Sub-action"} isNew={true} onClick={!isEditing ? onAddSubAction : null}/>
					</TileContainer>
				</Allow>
			}
		</section>
	);
};

StrategyAction.propTypes = {
	action: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
    actionIndex: PropTypes.number.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	editItemId: PropTypes.string,
	onAddSubAction: PropTypes.func,
	onChangeSubAction: PropTypes.func,
	onSaveSubAction: PropTypes.func,
	onCancel: PropTypes.func,
	onChangeActivity: PropTypes.func,
    onChangeActivityDate: PropTypes.func,
	onSaveActivity: PropTypes.func,
	onAddActivity: PropTypes.func,
	onEditAction: PropTypes.func,
	onEditSubAction: PropTypes.func,
	onEditActivity: PropTypes.func,
	onDeleteActivity: PropTypes.func,
	onDeleteSubAction: PropTypes.func,
	onDeleteAction: PropTypes.func
};

export default StrategyAction;