import React, {useEffect, useState} from 'react';
import {
    GmsFilterVisibilityRules,
} from "../../../components/common/searchCriterias/GmsSearchCriteria";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {getAllDistrictTeamMembers} from "../../users/userObjectFactory";
import {GmsFilter} from "../GmsFilter";
import {GmsSummarySearchResults} from "./GmsSummarySearchResults";
import gmsApi from "../gmsApi";
import {emptyGuid, SiteSectionsForTeamMember} from "../../../constants/config";

const GmsSummaryContainer = ({
                                 actions,
                                 allFiscalAgents,
                                 currentUserFiscalAgent,
                                 dateWindows,
                                 history,
                                 isFiscalAgentUser,
                                 searchCriteria,
                             }) => {
    const [summary, setSummary] = useState(null);

    const handleSearch = async (criteria, gotoFirstPage = true) => {
        let updatedCriteria = {...criteria};
        if(currentUserFiscalAgent)
            updatedCriteria.fiscalAgent = currentUserFiscalAgent;

        if (!isValidSearch(updatedCriteria)) {
            NotifyUser.Warning("Please select either a date window or a fiscal agent.");
            return;
        }

        const dateWindowId = updatedCriteria.dateWindowId || emptyGuid;
        const fiscalAgentId = !updatedCriteria.fiscalAgent ? emptyGuid : allFiscalAgents.find(f => f.text === updatedCriteria.fiscalAgent).value;

        const result = await actions.executeApi(gmsApi.getSummary, [dateWindowId, fiscalAgentId]);

        await updateSearchResults(updatedCriteria, result, gotoFirstPage);
    };


    const updateSearchResults = async (criteria, currentSummary, gotoFirstPage = true) => {
        if (gotoFirstPage)
            criteria.First();

        if(currentUserFiscalAgent)
            criteria.fiscalAgent = currentUserFiscalAgent;

        actions.saveSearchCriteria(criteria);
        setSummary(currentSummary);
    };

    const isValidSearch = (criteria) => {
        const isValid = !!criteria.dateWindowId || !!criteria.fiscalAgent;
        return isValid;
    };

    const autoSearch = async (criteria, gotoFirstPage = true) => {
        if (isFiscalAgentUser || isValidSearch(criteria))
            await handleSearch(criteria, gotoFirstPage);
        else
            await updateSearchResults(criteria, null);
    };

    const handleSearchFiltersChange = async (criteria, reloadPage) => {
        if (reloadPage) {
            await autoSearch(criteria);
        } else
            await updateSearchResults(criteria, summary);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const initializeData = async (criteria) => {
        actions.saveSearchCriteria(criteria);

        await autoSearch(criteria, false);
    };

    useEffect(() => {
        let criteria = {...searchCriteria};
        if (!isFiscalAgentUser)
            criteria.setFiscalAgentList(allFiscalAgents);
        else
            criteria.fiscalAgent = currentUserFiscalAgent;

        criteria.teamMembers = getAllDistrictTeamMembers(allFiscalAgents, SiteSectionsForTeamMember.GMS.id);

        criteria.allowAllDateWindows = true;
        criteria.setVisibilityRules(isFiscalAgentUser ? GmsFilterVisibilityRules.FiscalAgentSummaryWrapper
            : GmsFilterVisibilityRules.AdminSummaryWrapper);
        initializeData(criteria);

    }, []);

    useEffect(() => {
        actions.updatePageTitle(`GMS Summary`);
    }, []);

    if (isNullOrUndefined(searchCriteria))
        return null;

    return (
        <>
            <GmsFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            {
                !isNullOrUndefined(summary) &&
                <GmsSummarySearchResults
                    actions={actions}
                    handleClickOrder={handleClickOrder}
                    handleSearch={handleSearch}
                    history={history}
                    isFiscalAgentUser={isFiscalAgentUser}
                    searchCriteria={searchCriteria}
                    summary={searchCriteria.applyFiltersAndOrder(summary)}
                />
            }
            {
                isNullOrUndefined(summary) &&
                <div className={`search-results-warning-container`}>
                    <p className={`search-results-warning`}>Please choose a date window or fiscal agent.</p>
                </div>
            }
        </>
    );
};

GmsSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentUserFiscalAgent: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default GmsSummaryContainer;