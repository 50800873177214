import {createListFromObject} from "../../../../components/common/commonUtilities";

export const TIPS = {
    deliverableNumber: `Deliverable Identifier`,
    sourceDoc: `Documentation Maintained by the Project to Support the Deliverable`,
    fundingSourceType: `Federal or State General Revenue`,
    deliverableBudget: `Total Budget for Deliverable`,
    totalUnits: `Performance Requirements: Grant Year Total Deliverable Units`,
    quarterUnits: `Performance Targets/Deliverable Units to be Completed per Quarter`,
};

export const FUNDING_SOURCE = {
    state: 1,
    federal: 2,
}

export const FUNDING_SOURCE_LIST = createListFromObject(FUNDING_SOURCE);

export const CATEGORIES = {
    products: 1,
    serviceDelivery: 2,
    training: 3,
};

export const CATEGORIES_LIST = createListFromObject(CATEGORIES);

export const PRODUCT_TYPES = {
    analytical: "Analytical",
    informational: "Informational",
    instructional: "Instructional",
};

export const PRODUCT_TYPES_LIST = createListFromObject(PRODUCT_TYPES);

export const TRAINING_TYPES = {
    facilitation: "Facilitation of a Training Event",
    presentation: "Presentation of a Training Event",
    provision: "Provision of Training",
};

export const TRAINING_TYPES_LIST = createListFromObject(TRAINING_TYPES);

export const SERVICE_TYPES = {
    direct: "Direct",
    indirect: "Indirect",
    leadership: "Leadership Activities",
};

export const SERVICE_TYPES_LIST = createListFromObject(SERVICE_TYPES);
