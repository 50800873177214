import React from "react";
import {convertToArray, isNullOrUndefined} from "./commonUtilities";
import PropTypes from "prop-types";

const MapAdInfinitum = ({
						   component,
						   yutes
					   }) => {
	yutes = convertToArray(yutes);

	return (
		yutes.map((child, index) => {
			if (!child) return null;

			if (child.type && child.type.toString() === React.Fragment.toString()) {
				return child.props.children.map((grandchild, grandIndex) => {
					if(isNullOrUndefined(grandchild))
						return null;

					return <MapAdInfinitum
						key={grandIndex}
						component={component}
						yutes={grandchild}
					/>;
				});
			}
			else if (Array.isArray(child)) {
				return child.map((grandchild, grandIndex) => {
					if(isNullOrUndefined(grandchild))
						return null;

					return <MapAdInfinitum
						key={grandIndex}
						component={component}
						yutes={grandchild}
					/>;
				});
			}
			else if (child && child.type && !Array.isArray(child)) {
				return component(child, index);
			}
		})
	);
};

MapAdInfinitum.propTypes = {
	component: PropTypes.func.isRequired,
	yutes: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.bool,
		PropTypes.element,
		PropTypes.object,
		PropTypes.string,
	]).isRequired
};

export default MapAdInfinitum;