import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {
    CollapsibleContainerCollection, createAllContainerDetailsObject
} from "../../../../../components/common/CollapsibleContainerCollection";
import {
    isNullOrUndefined,
    isNumber,
    isTrimmedStringEmpty
} from "../../../../../components/common/commonUtilities";
import {
    clearInputFormErrorFields,
    inputFormIsValid,
    useFormInputObject
} from "../../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {createActionStepUpdateFormProperties, createSaveActionStepUpdatesArray} from "../../bpieDistrictObjectFactory";
import {DpieGoalDetailsInfo} from "../../DpieGoalDetailsInfo";
import {BpieDistrictPieActionStepUpdateDetailsForm} from "./BpieDistrictPieActionStepUpdateDetailsForm";

export const BpieDistrictPieActionStepUpdateDetailsView = ({
                                                               actionStepUpdates,
                                                               dpieGoal,
                                                               handleDataChanged,
                                                               handleSaveActionStepUpdates,
                                                               redirectToActionStepUpdateSummary
                                                           }) => {
    const actionStepForm = useFormInputObject(createActionStepUpdateFormProperties(actionStepUpdates, dpieGoal.actionSteps), () => handleDataChanged());

    const handleDeleteActionStepUpdate = (actionStepId) => {
        if(!isActionStepUpdatesValid("Before deleting a status update, please fix the required missing items."))
            return;

        if(!confirm("Are you want to delete this status update?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            return;

        actionStepForm[`dpieActionStepStatus_${actionStepId}`].setValue(null);
        actionStepForm[`actionStepUpdateNotes_${actionStepId}`].setValue("");

        saveActionStepUpdates();
    };

    const isActionStepUpdatesValid = (notifyMessage = "") => {
        clearInputFormErrorFields(actionStepForm);

        for(let actionStep of dpieGoal.actionSteps) {
            const actionStepUpdateStatus = actionStepForm[`dpieActionStepStatus_${actionStep.actionStepId}`].value ?? "";
            const actionStepUpdateNotes = actionStepForm[`actionStepUpdateNotes_${actionStep.actionStepId}`].value ?? "";
            const status = !isTrimmedStringEmpty(actionStepUpdateStatus.toString()) && isNumber(actionStepUpdateStatus)
                ? parseInt(actionStepUpdateStatus) : null;
            if (!isTrimmedStringEmpty(actionStepUpdateNotes) && isNullOrUndefined(status))
                actionStepForm[`dpieActionStepStatus_${actionStep.actionStepId}`].setError("Required");
        }

        let isValid = inputFormIsValid(actionStepForm);
        if (!isValid)
            NotifyUser.Warning(!isTrimmedStringEmpty(notifyMessage) ? notifyMessage : "Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const handleSaveActionStepUpdatesClick = (event) => {
        event.preventDefault();
        saveActionStepUpdates();
    };

    const saveActionStepUpdates = () => {
        if (!isActionStepUpdatesValid())
            return;

        const result = createSaveActionStepUpdatesArray(actionStepForm, actionStepUpdates, dpieGoal.actionSteps);
        handleSaveActionStepUpdates(result);
    };

    const handleReturnToActionUpdateSummaryClick = (event) => {
        event.preventDefault();
        redirectToActionStepUpdateSummary();
    };

    const allContainerDetails = dpieGoal.actionSteps.map(actionStep =>
        createAllContainerDetailsObject(actionStep.actionStepId,
            `Action Step ${actionStep.actionStepNumber}`,
            actionStep)
    );

    const canEditNotes = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY_NOTES);

    return <>
        <DpieGoalDetailsInfo dpieGoal={dpieGoal} />

        <CollapsibleContainerCollection
            allContainerDetails={allContainerDetails}
            containerClassName={`actionStep--info`}
            expandCollapseAllLinkName={`btnExpandCollapseAll`}
            expandCollapseAllLinkSuffix={`Action Steps`}
            expandCollapseAllLinkWrapperClass={`actionStep--expandCollapse`}
        >
            <BpieDistrictPieActionStepUpdateDetailsForm
                actionStepForm={actionStepForm}
                canEditNotes={canEditNotes}
                handleDeleteActionStepUpdate={handleDeleteActionStepUpdate}
            />
        </CollapsibleContainerCollection>

        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
            {
                canEditNotes &&
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Save Action Step Status Updates"}
                    name="btnSave"
                    onClick={handleSaveActionStepUpdatesClick}
                />
            }
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to Goal Summary`}
                name={`btnReturnToActionUpdateSummary`}
                onClick={handleReturnToActionUpdateSummaryClick}
            />
        </ButtonBar>
    </>;
};

BpieDistrictPieActionStepUpdateDetailsView.propTypes = {
    actionStepUpdates: PropTypes.array,
    dpieGoal: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveActionStepUpdates: PropTypes.func.isRequired,
    redirectToActionStepUpdateSummary: PropTypes.func.isRequired
};