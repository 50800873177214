import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AuditHistory from "../../../../components/audit/AuditHistory";
import {MonitoringCompliancePolicy} from "../../../../components/authorization/policies/MonitoringCompliancePolicy";
import AuditBar from "../../../../components/common/buttons/AuditBar";
import {
    convertToBoolean,
    createListIfExistsInIdArray, createListItem, getNeedsRevisionAuditComment, isNullOrUndefined, isTrimmedStringEmpty,
} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import ListManagerField from "../../../../components/common/inputs/ListManagerField";
import TextAreaEditor from "../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import SectionHeader from "../../../../components/common/SectionHeader";
import HtmlDisplayBlock from "../../../../components/htmlDisplay/HtmlDisplayBlock";
import * as ActionBarPositions from "../../../../constants/ActionBarPositions";
import audit_outcomes from "../../../../constants/auditOutcomes";
import * as capUtilities from "../capUtilities";

const ActivityForm = ({
                          activityForm,
                          disabledForm,
                          handleActivityOutcomeChange,
                          isLoading,
                          onActivityCommentSaved,
                          protocolCorrectiveActions
                      }) => {
    const [editCommentId, setEditCommentId] = useState(null);
    const autoAssociatesAllProtocols = protocolCorrectiveActions.every(i => i.capCorrectionAutoAssociatesAllProtocols);
    const isResourcesRequired = protocolCorrectiveActions.every(i => i.capCorrectionRequiresResources);

    const getAssociatedProtocolsList = () => {
        return protocolCorrectiveActions.map(protocol => createListItem(protocol.correctiveActionPlanId, `${protocol.publicIdentifier}-${protocol.standardDisplayNumber}`));
    };

    const setAllProtocolsIfAutoAssociated = () => {
        if(!autoAssociatesAllProtocols) return;

        const allActionPlanIds = protocolCorrectiveActions.map(protocol => protocol.correctiveActionPlanId);
        activityForm.associatedProtocolPlanIds.setValue(allActionPlanIds);
    };

    const fullProtocolsList = getAssociatedProtocolsList();
    const isSamplingActivity = activityForm.isSamplingActivity.value;

    useEffect(() => {
        setAllProtocolsIfAutoAssociated();
    }, []);

    return (
        <>
            <div>
                {
                    handleActivityOutcomeChange &&
                    <AuditBar
                        elementId={"capActivityStatus"}
                        isExpanded={true}
                        outcome={activityForm.capActivityStatus.value}
                        onOutcomeChange={handleActivityOutcomeChange}
                        position={ActionBarPositions.RIGHT}
                        policy={MonitoringCompliancePolicy}
                        disabled={isLoading}
                    />
                }
                <SectionHeader>
                    <h3>Activity Details</h3>
                </SectionHeader>
            </div>

            {
                onActivityCommentSaved &&
                <AuditHistory
                    audits={activityForm.audits.value}
                    onCommentCancel={(e) => capUtilities.onCommentCancel(e, activityForm.audits.value, activityForm, editCommentId, setEditCommentId)}
                    onCommentSave={(e, comment) => capUtilities.onCommentSave(e, comment, onActivityCommentSaved, setEditCommentId)}
                    onCommentEdit={(commentId) => capUtilities.onCommentEdit(commentId, setEditCommentId)}
                    handleCommentChange={(e) => capUtilities.handleCommentChange(e, activityForm, editCommentId)}
                    editItemId={editCommentId}
                    isInEditMode={!isNullOrUndefined(editCommentId)}
                    isLoading={isLoading}
                    onCommentCreate={(auditId) => capUtilities.onCommentCreate(auditId, activityForm, setEditCommentId)}
                    parentIdPropName={"activityId"}
                />
            }
            {
                !onActivityCommentSaved &&
                <AuditHistory
                    audits={activityForm.audits.value}
                    isLoading={isLoading}
                />
            }

            <FormTable>
                {
                    activityForm.capActivityStatus.value === audit_outcomes.NEEDS_REVISION.value &&
                    <div className={`highlight-needsRevision`}>
                        <p><strong>Feedback:</strong></p>
                        <HtmlDisplayBlock html={getNeedsRevisionAuditComment(activityForm.audits.value)}/>
                        {
                            !disabledForm &&
                            <CheckBoxField
                                {...activityForm.acknowledgeNeedsRevisionComplete}
                                checked={convertToBoolean(activityForm.acknowledgeNeedsRevisionComplete.value)}
                                label={`I acknowledge that the revisions are complete and ready to be submitted.`}
                                showLabel
                                value={(!convertToBoolean(activityForm.acknowledgeNeedsRevisionComplete.value)).toString()}
                                wrapperClass={`acknowledge-container`}
                            />
                        }
                    </div>
                }
                {
                    isSamplingActivity &&
                    <TextAreaEditor
                        {...activityForm.defaultActivitySummary}
                        disabled
                        label={`Activity ${activityForm.activityNumber.value}`}
                        showLabel
                    />
                }
                <TextAreaEditor
                    {...activityForm.activitySummary}
                    disabled={disabledForm}
                    height={400}
                    label={isSamplingActivity ? `Additional Details` : `Activity ${activityForm.activityNumber.value}`}
                    showLabel
                    value={isTrimmedStringEmpty(activityForm.activitySummary.value) && disabledForm ? "<p>None</p>" : activityForm.activitySummary.value}
                />
                {
                    isResourcesRequired &&
                    <TextAreaEditor
                        {...activityForm.resources}
                        disabled={disabledForm}
                        height={400}
                        label={"Resources"}
                        showLabel
                    />
                }
                <DateTimePickerField
                    {...activityForm.timelineStartDate}
                    medium={6}
                    disabled={disabledForm}
                    label={"Timeline Start Date"}
                    showLabel
                />
                <DateTimePickerField
                    {...activityForm.timelineEndDate}
                    medium={6}
                    disabled={disabledForm}
                    label={"Timeline End Date"}
                    showLabel
                />
                {
                    !disabledForm &&
                    !isSamplingActivity &&
                    !autoAssociatesAllProtocols &&
                    <ListManagerField
                        {...activityForm.associatedProtocolPlanIds}
                        archivedLabel={"Deleted"}
                        listItems={fullProtocolsList}
                        listValues={createListIfExistsInIdArray(activityForm.associatedProtocolPlanIds.value, fullProtocolsList)}
                        managerLabel={"Associated Protocols"}
                    />
                }
                {
                    (disabledForm || isSamplingActivity) &&
                    !autoAssociatesAllProtocols &&
                    <div className={`associated-protocols`}>
                        <p><strong>Associated Protocols</strong></p>
                        <ul>
                            {
                                createListIfExistsInIdArray(activityForm.associatedProtocolPlanIds.value, fullProtocolsList).map(protocol => {
                                    return <li key={protocol.value}>{protocol.text}</li>;
                                })
                            }
                        </ul>
                    </div>
                }
            </FormTable>
        </>
    );
};

ActivityForm.propTypes = {
    activityForm: PropTypes.object.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    handleActivityOutcomeChange: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    onActivityCommentSaved: PropTypes.func,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object)
};

export default ActivityForm;