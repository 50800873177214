import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import UserSearchForm from "../../components/users/UserSearchForm";
import UserSearchResults from "../../components/users/UserSearchResults";
import * as layoutActions from "../../actions/layoutActions";
import * as accountActions from "../../actions/accountActions";
import * as userActions from "../../actions/userActions";
import * as behaviorActions from "../../actions/behaviorActions";
import * as institutionActions from "../../actions/sharedDataActions";
import * as usersLocations from "../../constants/users/usersLocations";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {UserSearchCriteria} from "../../components/common/searchCriterias/userSearchCriteria";
import LocalStorage from "../../components/shared/LocalStorage";
import * as systemLocations from "../../constants/systemLocations";

export class UserSearchPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadData: false
        };

        this.searchUsers = this.searchUsers.bind(this);
        this.clearCriteria = this.clearCriteria.bind(this);
        this.order = this.order.bind(this);
        this.first = this.first.bind(this);
        this.last = this.last.bind(this);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.details = this.details.bind(this);
        this.onCriteriaChange = this.onCriteriaChange.bind(this);
        this.newUser = this.newUser.bind(this);

        this.handleImpersonateUser = this.handleImpersonateUser.bind(this);
    }

    componentDidMount() {
        if (this.props.behaviors.length === 0)
            this.props.actions.loadBehaviors();

        if (this.props.institutions.length === 0)
            this.props.actions.loadInstitutions();

        this.props.actions.updatePageTitle("User Search");

        this.props.actions.createSearchCriteria(this.createUserSearchCriteria());
    }

    componentDidUpdate() {
        if (!this.state.loadData &&
            this.props.searchCriteria !== undefined) {
            this.loadUsers();

            this.setState({loadData: true});
        }
    }

    createUserSearchCriteria() {
        let currentUser = LocalStorage.getLoggedInUser();
        return new UserSearchCriteria(currentUser.InstitutionId === null ? "" : currentUser.InstitutionId);
    }

    loadUsers(searchCriteria = this.props.searchCriteria) {
        let criteria = Object.assign({}, searchCriteria);

        this.props.actions.loadUsers(criteria);
    }

    onCriteriaChange(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria[event.target.name] = event.target.value;

        this.props.actions.saveSearchCriteria(criteria);
    }

    searchUsers(event) {
        event.preventDefault();

        this.loadUsers();
    }

    clearCriteria(event) {
        event.preventDefault();

        let criteria = {...this.props.searchCriteria};
        criteria.clearOptionalSearchItems(false);

        this.props.actions.saveSearchCriteria(criteria);
        this.loadUsers(criteria);
    }

    order(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.UpdateSorting(event.target.innerHTML);

        this.props.actions.saveSearchCriteria(criteria);

        this.loadUsers(criteria);
    }

    handleImpersonateUser = (userId) => {
        this.props.actions.impersonating(userId, this.props.currentLocation)
            .then(() => {
                this.props.history.push(systemLocations.DASHBOARD.path);

            });
    };

    first(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.First();

        this.props.actions.saveSearchCriteria(criteria);

        this.loadUsers(criteria);
    }

    last(event) {
        event.preventDefault();

        const totalRecords = this.props.totalRecords;

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Last(totalRecords);

        this.props.actions.saveSearchCriteria(criteria);
        this.loadUsers(criteria);
    }

    next(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Next();
        this.props.actions.saveSearchCriteria(criteria);
        this.loadUsers(criteria);
    }

    previous(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.Previous();
        this.props.actions.saveSearchCriteria(criteria);
        this.loadUsers(criteria);
    }

    details(event, userId) {
        event.preventDefault();
        this.props.history.push(usersLocations.PROFILE.path
            .replace(usersLocations.USER_ID, userId));
    }

    newUser(event) {
        event.preventDefault();
        this.props.history.push(usersLocations.NEW_USER.path);
    }

    render() {
        if (this.props.institutions.length === 0 ||
            this.props.behaviors.length === 0 ||
            this.props.searchCriteria === undefined)
            return null;

        return (
            <div>
                <UserSearchForm criteria={this.props.searchCriteria}
                                institutions={this.props.institutions}
                                behaviors={this.props.behaviors}
                                search={this.searchUsers}
                                clearCriteria={this.clearCriteria}
                                addUser={this.newUser}
                                onFormChange={this.onCriteriaChange}
                                isLoading={this.props.isLoading}/>

                <UserSearchResults users={this.props.users}
                                   first={this.first}
                                   last={this.last}
                                   next={this.next}
                                   previous={this.previous}
                                   details={this.details}
                                   order={this.order}
                                   totalRecords={this.props.totalRecords}
                                   currentPage={this.props.searchCriteria.Page}
                                   recordsPerPage={this.props.searchCriteria.RecordsPerPage}
                                   isLoading={this.props.isLoading}
                                   impersonating={this.handleImpersonateUser}
                />
            </div>
        );
    }
}

UserSearchPage.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    behaviors: PropTypes.arrayOf(PropTypes.object).isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.object.isRequired,
    totalRecords: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object,
    currentLocation: PropTypes.string
};

function mapStateToProps(state, props) {
    const behaviorSelectListItems = state.user.behaviors.map(
        behavior => {
            return {
                value: behavior.value,
                text: behavior.text
            };
        }
    );

    const institutionSelectListItems = state.sharedData.allInstitutions.map(
        institution => {
            return {
                value: institution.id,
                text: institution.name
            };
        }
    );

    const currentLocation = props.location.pathname;

    return {
        users: state.user.searchResults,
        totalRecords: state.user.totalRecords,
        behaviors: behaviorSelectListItems,
        institutions: institutionSelectListItems,
        isLoading: state.ajaxCallsInProgress > 0,
        searchCriteria: state.searchCriteria.UserSearchCriteria,
        currentLocation
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, userActions, layoutActions, behaviorActions, institutionActions, searchCriteriaActions, accountActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchPage);