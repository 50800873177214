import React from "react";
import PropTypes from 'prop-types';
import FormTable from "../../../../components/common/FormTable";
import {convertLineBreaksToBreakTags} from "../../../../components/common/HtmlUtilities";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import HtmlDisplayInline from "../../../../components/htmlDisplay/HtmlDisplayInline";

export const BpieDistrictInfoView = ({
                                         districtBpie
                                     }) => {
    return (
        <>
            <FormTable>
                <DisplayField
                    name={"datesCovered"}
                    label={"Date Window:"}
                    showLabel
                    isInline>
                    {districtBpie.datesCovered}
                </DisplayField>
                <DisplayField
                    name={"bpieStatus"}
                    label={"Current Status:"}
                    showLabel
                    isInline>
                    {districtBpie.districtBpieStatusDescription}
                </DisplayField>
                <DisplayField
                    name={"districtContact"}
                    label={"District Contact:"}
                    showLabel
                    isInline>
                    {`${districtBpie.districtContactFirstName} ${districtBpie.districtContactLastName}, ${districtBpie.districtContactEmail}`}
                </DisplayField>
                <DisplayField
                    name={"assessmentTeam"}
                    label={"All team member names and titles/positions:"}
                    showLabel>
                    <HtmlDisplayInline html={convertLineBreaksToBreakTags(districtBpie.assessmentTeam)} />
                </DisplayField>
                <DisplayField
                    label={`Date Meeting Held`}
                    name={`dateMeetingHeld`}
                    showLabel
                >
                    {districtBpie.dateMeetingHeldOutput}
                </DisplayField>
            </FormTable>
        </>
    );
};

BpieDistrictInfoView.propTypes = {
    districtBpie: PropTypes.object
};