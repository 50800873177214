import React from "react";
import PropTypes from 'prop-types';
import {verifyAccessToContext} from "../../components/authorization/AuthorizationUtilities";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import LocalStorage from "../../components/shared/LocalStorage";
import {createSummarySearchObject} from "../../components/summary/summaryObjectFactory";
import * as contexts from "../../constants/contexts";
import DrDueProcessCaseload from "../DrComponents/DrDueProcessCaseload";
import DrMediationCaseload from "../DrComponents/DrMediationCaseload";
import DrStateComplaintCaseload from "../DrComponents/DrStateComplaintCaseload";
import {DisputeResolutionType} from "../drConstants";
import * as drLocations from "../drLocations";

export const DrSummaryCaseload = ({
                                      caseload,
                                      history,
                                      selectedDisputeResolutionType,
                                      setDrSummarySearchCriteria
                                  }) => {

    const handleClickCaseload = async (event, type, userId = "", status = "", role = "") => {
        event.preventDefault();

        const upcomingSearchCriteria = new DrSearchCriteria();
        await setDrSummarySearchCriteria(upcomingSearchCriteria, createSummarySearchObject({
            type,
            role,
            status
        }));

        history.push(drLocations.LANDING_CASE_LIST.getUrl());
    };

    const validateSelectedDisputeResolutionType = (type) => {
        return selectedDisputeResolutionType === "" || selectedDisputeResolutionType === type;
    }

    const {complaintCaseloads, mediationCaseload, dueProcessCaseloads} = caseload;
    const showDrStateComplaints = verifyAccessToContext(contexts.STATE_COMPLAINTS, LocalStorage.getBehaviorClaims()) && validateSelectedDisputeResolutionType(DisputeResolutionType.StateComplaints);
    const showDrMediation = verifyAccessToContext(contexts.MEDIATIONS, LocalStorage.getBehaviorClaims()) && validateSelectedDisputeResolutionType(DisputeResolutionType.Mediation);
    const showDrDueProcess = verifyAccessToContext(contexts.DUE_PROCESS, LocalStorage.getBehaviorClaims()) && validateSelectedDisputeResolutionType(DisputeResolutionType.DueProcess);

    return (
        <>
            {
                showDrStateComplaints &&
                !isArrayNullOrEmpty(complaintCaseloads) &&
                <DrStateComplaintCaseload
                    complaintCaseloads={complaintCaseloads}
                    handleClickCaseload={handleClickCaseload}
                />
            }
            {
                showDrDueProcess &&
                !isArrayNullOrEmpty(dueProcessCaseloads) &&
                <DrDueProcessCaseload
                    dueProcessCaseloads={dueProcessCaseloads}
                    handleClickCaseload={handleClickCaseload}
                />
            }
            {
                showDrMediation &&
                !isArrayNullOrEmpty(mediationCaseload) &&
                <DrMediationCaseload
                    mediationCaseload={mediationCaseload}
                    handleClickCaseload={handleClickCaseload}
                />
            }
        </>
    );
};

DrSummaryCaseload.propTypes = {
    caseload: PropTypes.object.isRequired,
    history: PropTypes.object,
    selectedDisputeResolutionType: PropTypes.string,
    setDrSummarySearchCriteria: PropTypes.func.isRequired
};

export default DrSummaryCaseload;