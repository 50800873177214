import React from 'react';
import PropTypes from "prop-types";
import {GmsApplicationEditingDisabledView} from "../GmsApplicationEditingDisabledView";
import {createBudgetSummary} from "../../gmsObjectFactory";
import {ExternalLink} from "../../../../components/common/ExternalLink";
import {GmsApplicationBudgetTable} from "./GmsApplicationBudgetTable";

export const GmsApplicationBudgetView = ({
                                             actions,
                                             applicationId,
                                             budget,
                                             budgetId,
                                             canUserEditBudget,
                                             codes,
                                             editId,
                                             isAmendmentBudget,
                                             isEditing,
                                             loadApplicationBudget,
                                             searchCriteria,
                                             setEditId
                                         }) => {
    const budgetSummary = createBudgetSummary(budget);
    const newLineItemLabel = "Add Budget Line Item";

    return (
        <>
            <GmsApplicationEditingDisabledView isEditingDisabledMessageVisible={budget.isEditingDisabledMessageVisible}/>

            <p><b>Directions:</b> You can add entries to your budget using the <i>{newLineItemLabel}</i> button. Or if
                you prefer to edit your budget in Excel, <i>Export Budget</i> to an Excel file, then <i>Import Budget
                </i> with your updated Excel file.&nbsp;
                <ExternalLink label={`Green Book`} url={`https://www.fldoe.org/comptroller/gbook.asp`}/> |&nbsp;
                <ExternalLink label={`Red Book`} url={`https://www.fldoe.org/fefp/redtoc.asp`}/>
            </p>

            {
                !budget.canImportBudget && canUserEditBudget && !isAmendmentBudget &&
                <p className={`page-directions-highlight`}>Only line item editing is allowed when revising the budget.</p>
            }
            {
                !budget.canImportBudget && canUserEditBudget && isAmendmentBudget &&
                <div className={`page-directions-highlight`}>
                    <p>Only line item editing is allowed when amending the budget.</p>
                    <p>Also, only certain fields are editable for currently approved line items and deleting currently approved line items only changes the amount to zero.
                        But if you add a new budget line item, all fields are editable and it will be deletable.</p>
                </div>
            }

            <p>Requested: {budgetSummary.requested} |
                Total: {budgetSummary.total} |
                Remaining: <span className={budgetSummary.isOverBudget ? `color--alert` : ""}>
                    {budgetSummary.remaining}</span>
            </p>

            {
                budgetSummary.isOverBudget &&
                <p className={`color--alert`}>WARNING: Your budget exceeds the amount of funds requested
                    by: {budgetSummary.overBudget}</p>
            }

            <GmsApplicationBudgetTable
                actions={actions}
                applicationId={applicationId}
                budget={budget}
                budgetId={budgetId}
                canUserEditBudget={canUserEditBudget}
                codes={codes}
                editId={editId}
                isAmendmentBudget={isAmendmentBudget}
                isEditing={isEditing}
                loadApplicationBudget={loadApplicationBudget}
                newLineItemLabel={newLineItemLabel}
                searchCriteria={searchCriteria}
                setEditId={setEditId}
            />
        </>
    );
};


GmsApplicationBudgetView.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    budget: PropTypes.object.isRequired,
    budgetId: PropTypes.string.isRequired,
    canUserEditBudget: PropTypes.bool.isRequired,
    codes: PropTypes.object.isRequired,
    editId: PropTypes.string,
    isAmendmentBudget: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    loadApplicationBudget: PropTypes.func.isRequired,
    searchCriteria: PropTypes.object,
    setEditId: PropTypes.func.isRequired
};