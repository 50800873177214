import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import {
    clearInputFormErrorFields,
    inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import TextField from "../../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {createRegionDetailsObject} from "./regionObjectFactory";

const RegionEdit = ({
                        handleCancelRegionEdit,
                        handleDataChanged,
                        handleSaveRegion,
                        regionId,
                        regionName,
                        regions
                    }) => {

    const regionDetailsForm = useFormInputObject(createRegionDetailsObject(regionName), () => handleDataChanged(true));

    const validateForm = () => {
        clearInputFormErrorFields(regionDetailsForm);

        if (isTrimmedStringEmpty(regionDetailsForm.regionName.value))
            regionDetailsForm.regionName.setError("Required.")

        const newRegionName = regionDetailsForm.regionName.value.toLowerCase().trim();
        const duplicateExists = regions.some(s => s.text.toLowerCase() === newRegionName && s.id !== regionId);
        if (duplicateExists)
            regionDetailsForm.regionName.setError("Region name must be unique.")

        let isValid = inputFormIsValid(regionDetailsForm);
        if (!isValid)
            NotifyUser.Warning("Please correct the following issues and try submitting again.");

        return isValid;
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            handleSaveRegion(regionDetailsForm.regionName.value.trim(), regionId);
        }
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        handleCancelRegionEdit();
    };

    return (
        <>
            <FormTable>
                <TextField
                    label={`Region Name`}
                    showLabel
                    {...regionDetailsForm.regionName}
                />
            </FormTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>

                <Button name="btnCancel"
                        label="Cancel"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={onCancelClick}/>
            </ButtonBar>
        </>
    );
};

RegionEdit.propTypes = {
    handleCancelRegionEdit: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveRegion: PropTypes.func.isRequired,
    regionId: PropTypes.string.isRequired,
    regionName: PropTypes.string.isRequired,
    regions: PropTypes.array
};

export default RegionEdit;