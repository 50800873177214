export const ASSESSMENT_ID = ":assessmentId";
export const DATE_WINDOW_ID = ":dateWindowId";
export const ROOT = `/MonitoringPerformance`;

//DISTRICT
export const LANDING_DISTRICT = {
    path: `${ROOT}/Assessments`,
    params: {}
};

export const ASSESSMENT_DISTRICT = {
    path: `${ROOT}/Assessments/${ASSESSMENT_ID}`,
    params: {
        assessmentId: ASSESSMENT_ID
    }
};

//DOE
export const LANDING_DOE = {
    path: `${ROOT}/Institutions/`,
    params: {}
};

export const LANDING_DOE_WITH_DATEWINDOW = {
    path: `${ROOT}/Institutions/${DATE_WINDOW_ID}`,
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

export const ASSESSMENT_DOE =  {
    path: `${ROOT}/Assessments/${ASSESSMENT_ID}`,
    params: {
        assessmentId: ASSESSMENT_ID
    }
};

export const ASSESSMENT_NEW_DOE = {
    path: `${ROOT}/Assessments/Date/${DATE_WINDOW_ID}`,
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

// TILES
export const DASHBOARD_TILE_DISTRICT = {
    path: ASSESSMENT_DISTRICT.path.replace( ASSESSMENT_ID , `{assessmentId}`),
    params: {
        assessmentId: ASSESSMENT_ID
    }
};

export const DASHBOARD_TILE_DOE = {
    path: LANDING_DOE_WITH_DATEWINDOW.path.replace( DATE_WINDOW_ID, `{dateWindowId}`),
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};