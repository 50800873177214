import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import SectionHeader from "../../../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../../../bpieConstants";
import * as bpieLocations from "../../../bpieLocations";
import {getDistrictPiePageSubTitle, isPageDependenciesLoaded} from "../../bpieDistrictPieUtilities";
import {BpieDistrictPieActionStepUpdatesSummaryView} from "./BpieDistrictPieActionStepUpdatesSummaryView";

export const BpieDistrictPieActionStepUpdatesSummaryContainer = ({
                                                                     dateWindowId,
                                                                     districtBpie,
                                                                     districtBpieId,
                                                                     districtId,
                                                                     districtPie,
                                                                     districtPieId,
                                                                     dpieProgressMonitoringId,
                                                                     handleDistrictBpieSearchRedirect,
                                                                     handleDistrictPieSectionsRedirect,
                                                                     isDistrictUser,
                                                                     loadDistrictPie,
                                                                     normalizeUrlAndRedirect,
                                                                     updatePageTitleDetails
                                                                 }) => {
    const progressMonitoring = districtPie?.dpieProgressMonitorings.find(pm => pm.dpieProgressMonitoringId === dpieProgressMonitoringId);
    const pageSubTitle = `Select a Goal for Status Updates for Progress Monitoring ${progressMonitoring?.dateMeetingHeldOutput}`;

    const handleEditActionStepUpdates = (goalId) => {
        const addEditActionStepUpdatesUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_ACTION_STEP_UPDATE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, goalId, dpieProgressMonitoringId)
            : bpieLocations.STATE_DPIE_ACTION_STEP_UPDATE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, goalId, dpieProgressMonitoringId);

        normalizeUrlAndRedirect(addEditActionStepUpdatesUrl);
    };

    const handleDpieProgressMonitoringSummaryRedirectClick = (event) => {
        event.preventDefault();
        handleDistrictPieSectionsRedirect(districtPieId, DistrictPieTask.ProgressMonitoring.id);
    };

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
        }

        loadDistrictPie();
    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtPie)) return;

        updatePageTitleDetails(getDistrictPiePageSubTitle(pageSubTitle, districtPie));

        const progressMonitoringStatus = districtPie.districtPieTaskStatuses.find(taskStatus => taskStatus.dpieTask === DistrictPieTask.ProgressMonitoring.id);
        if (progressMonitoringStatus?.isDisabled ?? true) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtPie]);

    if(!isPageDependenciesLoaded([districtBpie, progressMonitoring], districtPie, districtPieId)) return null;

    const fullPageSubTitle = getDistrictPiePageSubTitle(pageSubTitle, districtPie);

    return <>
        <SectionHeader>
            <h2>{fullPageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieActionStepUpdatesSummaryView
            actionStepUpdates={progressMonitoring.actionStepUpdates}
            goals={districtPie.dpieGoals}
            handleEditActionStepUpdates={handleEditActionStepUpdates}
        />

        <ButtonBar>
            <Button
                buttonType={ButtonTypes.BACK}
                label={`Return to Progress Monitoring Summary`}
                name={`btnReturnDpieProgressMonitoringSummary`}
                onClick={handleDpieProgressMonitoringSummaryRedirectClick}
            />
        </ButtonBar>
    </>;
}

BpieDistrictPieActionStepUpdatesSummaryContainer.propTypes = {
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object,
    districtBpieId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    dpieProgressMonitoringId: PropTypes.string.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    normalizeUrlAndRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};