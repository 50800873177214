import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Allow} from "../../../../components/authorization/Allow";
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../../../components/authorization/policies/MonitoringCompliancePolicy";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import SubmissionModule from "../../../../components/common/SubmissionModule";
import {isNew, createIndicatorTitle} from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import audit_outcomes from "../../../../constants/auditOutcomes";
import {emptyGuid} from "../../../../constants/config";
import {readyToSubmit} from "../../../../constants/monitoringCompliance/correctionResults";
import * as ComplianceLocations from "../../../../constants/monitoringCompliance/monitoringComplianceLocations";
import {monitoring_compliance} from "../../../../constants/policyEvents";
import CapApi from "../CapApi";
import {getRelatedActionsActivities} from "../capUtilities";
import DistrictActivitiesView from "./DistrictActivitiesView";
import DistrictActivityForm from "./DistrictActivityForm";

export const DistrictActivitiesContainer = ({
                                                actions,
                                                assessmentId,
                                                handleScroll,
                                                handleScrollToTop,
                                                history,
                                                isLoading,
                                                selectedAssessment
                                            }) => {
    const [capActivities, setCapActivities] = useState(null);
    const [protocolCorrectiveActions, setProtocolCorrectiveActions] = useState(null);
    const [currentAssessmentId, setCurrentAssessmentId] = useState("");
    const [activityId, setActivityId] = useState(null);
    const [selectedIndicatorId, setSelectedIndicatorId] = useState(null);

    const validateCorrectionsData = () => {
        if (!selectedAssessment) return true;

        let indicatorRequirement = selectedAssessment.indicatorRequirements.find(f => f.assessmentApproved);

        if (!indicatorRequirement ||
            !selectedAssessment.anyProtocolsNotMeetingCompliance) {
            history.push(ComplianceLocations.LANDING_DISTRICT.path);
            return false;
        }

        return true;
    };

    const loadCapActivities = async () => {
        const capResults = await actions.executeApi(CapApi.loadCapActivities, [assessmentId]);

        const pcaResults = await actions.executeApi(CapApi.loadProtocolCorrectiveActions, [assessmentId]);

        setCapActivities(capResults);
        setProtocolCorrectiveActions(pcaResults);
        setCurrentAssessmentId(assessmentId);
    };

    const isReadOnly = () => {
        return !AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.activity.MODIFY);
    }

    const addActivity = (indicatorId) => {
        setActivityId(emptyGuid);
        setSelectedIndicatorId(indicatorId);
        handleScrollToTop();
    };

    const editActivity = (activityId, indicatorId) => {
        setActivityId(activityId);
        setSelectedIndicatorId(indicatorId);
        handleScrollToTop();
    };

    const deleteActivity = async (activityId, indicatorId) => {
        await actions.executeApi(CapApi.deleteCapActivity, [assessmentId, activityId]);
        await loadCapActivities();
        handleScroll(indicatorId);
    };

    const submitActivityResults = async (activityId, activityResultId, indicatorId) => {
        await actions.executeApi(CapApi.submitDistrictCapActivityResult, [assessmentId, activityId, activityResultId]);
        await loadCapActivities();
        handleScroll(indicatorId);
    };

    const cancelAddEditActivity = () => {
        setActivityId(null);
        handleScroll(selectedIndicatorId);
        setSelectedIndicatorId(null);
    };

    const saveActivity = async (activity, isCapActivityResult, shouldSubmitActivityResults) => {
        activity.indicatorId = selectedIndicatorId;

        if(isCapActivityResult)
            await actions.executeApi(CapApi.saveCapActivityResult, [assessmentId, activityId, activity, isNew(activity.activityResultId)]);
        else
            await actions.executeApi(CapApi.saveCapActivity, [assessmentId, activity, isNew(activity.activityId)]);

        if(shouldSubmitActivityResults)
            await submitActivityResults(activityId, activity.activityResultId, selectedIndicatorId);
        else {
            await loadCapActivities();
            handleScroll(selectedIndicatorId);
        }
        setActivityId(null);
        setSelectedIndicatorId(null);
    };

    const handleSubmitActivities = async (indicatorTitle, indicatorId) => {
        if (!confirm(`Are you sure you want to submit your CAP activities for ${indicatorTitle}?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            return;

        await actions.executeApi(CapApi.submitDistrictCapActivities, [assessmentId, indicatorId]);
        await loadCapActivities();
        handleScroll(indicatorId);
    }

    const getSubmissionMessage = (relatedData, assessmentApproved) => {
        if(!assessmentApproved)
            return `CAP activities cannot be added until the assessment is approved.`;

        let message = "";
        for (let action of relatedData.relatedCorrectiveActions) {
            if (!action.hasRequiredActivities)
                message += `<li>${action.publicIdentifier}-${action.standardDisplayNumber}</li>`
        }

        const totalActivitiesRequired = Math.max(...relatedData.relatedCorrectiveActions.map(s => s.totalActivitiesRequired));
        const totalActivityPlural = totalActivitiesRequired > 1 ? "activities" : "activity";

        message = isTrimmedStringEmpty(message) ? ""
            : `<p>The following protocols still need to be associated with at least ${totalActivitiesRequired} ${totalActivityPlural}.</p><ul>${message}</ul>`;

        const revisedActivitiesMessage = relatedData.relatedCapActivities.some(f => f.capActivityStatus === audit_outcomes.NEEDS_REVISION.value && !f.acknowledgeNeedsRevisionComplete)
            ? `<p>All revised activities need to be <em>acknowledged that they are completed</em> before being allowed to be submitted.</p>${message}`
            : relatedData.relatedCapActivities.some(f => !f.capActivityStatus.startsWith(readyToSubmit)
                && f.capActivityStatus !== audit_outcomes.SUBMITTED.value
                && f.capActivityStatus !== audit_outcomes.ACCEPTED.value) ? "Not all CAP activities are ready to submit." : message;

        return isTrimmedStringEmpty(revisedActivitiesMessage) ? `<p>Activities are ready to be submitted!</p>` : revisedActivitiesMessage;
    };

    const isSubmissionDisabled = (relatedData) => {
        return relatedData.relatedCorrectiveActions.some(f => !f.hasRequiredActivities)
            || relatedData.relatedCapActivities.some(f => f.capActivityStatus === audit_outcomes.NEEDS_REVISION.value && !f.acknowledgeNeedsRevisionComplete)
            || (relatedData.relatedCapActivities.some(f => !f.capActivityStatus.startsWith(readyToSubmit)
                && f.capActivityStatus !== audit_outcomes.SUBMITTED.value
                && f.capActivityStatus !== audit_outcomes.ACCEPTED.value));
    };

    const areCapActivitiesEditable = (indicatorId) => {
        const relatedData = getRelatedActionsActivities(indicatorId, protocolCorrectiveActions, capActivities);

        if (relatedData.relatedCapActivities.length === 0)
            return true;

        return relatedData.relatedCapActivities.some(s => s.capActivityStatus === audit_outcomes.IN_PROGRESS.value || s.capActivityStatus === audit_outcomes.NEEDS_REVISION.value || s.capActivityStatus.startsWith(readyToSubmit));
    };

    useEffect(() => {
        if (capActivities && currentAssessmentId === assessmentId) return;

        const isValid = validateCorrectionsData();
        if(!isValid) return;

        loadCapActivities();
    }, [assessmentId]);

    useEffect(() => {
        actions.updatePageTitle("CAP Activities");
    }, []);

    if (!capActivities || !protocolCorrectiveActions || !selectedAssessment) return null;

    return (
        <section className="compliance compliance__correctiveActionPlan">
            {
                !activityId &&
                selectedAssessment.indicatorRequirements.map((requirement, index) => {
                    const relatedData = getRelatedActionsActivities(requirement.indicatorId, protocolCorrectiveActions, capActivities);
                    const indicatorTitle = createIndicatorTitle(requirement.title, requirement.publicIdentifier);
                    const isEditable = areCapActivitiesEditable(requirement.indicatorId);

                    return (
                        <div key={index} className={"section-spacing-bottom"} id={requirement.indicatorId}>
                            <h3>{indicatorTitle}</h3>
                            <DistrictActivitiesView
                                capActivities={relatedData.relatedCapActivities}
                                handleAdd={isEditable && requirement.assessmentApproved ? addActivity : null}
                                handleDelete={deleteActivity}
                                handleEdit={editActivity}
                                handleSubmitResults={submitActivityResults}
                                indicatorId={requirement.indicatorId}
                                isLoading={isLoading}
                                protocolCorrectiveActions={relatedData.relatedCorrectiveActions}
                                readOnly={isReadOnly()}
                            />
                            {
                                isEditable &&
                                <Allow policy={MonitoringCompliancePolicy}
                                       policyEvent={monitoring_compliance.activity.SUBMIT}>
                                    <SubmissionModule
                                        handlePrimarySubmit={() => handleSubmitActivities(indicatorTitle, requirement.indicatorId)}
                                        hidePrimarySubmissionButton={!requirement.assessmentApproved}
                                        name={`btnSubmit`}
                                        primaryButtonLabel={`Submit Activities`}
                                        primaryDisabled={isLoading || isSubmissionDisabled(relatedData)}
                                        submissionMessage={getSubmissionMessage(relatedData, requirement.assessmentApproved)}
                                        submissionTitle={`CAP Activity Submission`}
                                    />
                                </Allow>
                            }
                        </div>
                    );
                })
            }
            {
                activityId &&
                <DistrictActivityForm
                    assessmentId={assessmentId}
                    capActivity={capActivities.find(f => f.activityId === activityId)}
                    isLoading={isLoading}
                    onCancel={cancelAddEditActivity}
                    onSave={saveActivity}
                    protocolCorrectiveActions={getRelatedActionsActivities(selectedIndicatorId, protocolCorrectiveActions, capActivities).relatedCorrectiveActions}
                    readOnly={isReadOnly()}
                />
            }
        </section>
    );
};

DistrictActivitiesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    selectedAssessment: PropTypes.object.isRequired
};

export default DistrictActivitiesContainer;

