import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Scroll from "react-scroll";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as LayoutActions from "../../actions/layoutActions";
import * as FileActions from "../../actions/fileActions";
import AccountUtility from "../../api/AccountUtility";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {DueProcessPolicy} from "../../components/authorization/policies/DueProcessPolicy";
import Button from "../../components/common/buttons/Button";
import {
    createListFromObject,
    isNullOrUndefined,
    isTrimmedStringEmpty,
    setPageTitle
} from "../../components/common/commonUtilities";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import LocalStorage from "../../components/shared/LocalStorage";
import {fetchDate, fetchDistrict,} from "../../components/shared/sharedDataUtilities";
import {dispute_resolution} from "../../constants/policyEvents";
import drApi from "../drApi";
import {DrRolesAssociatedToDueProcess, SingleSelectionDrRolesAssociatedToDueProcess} from "../drConstants";
import * as drLocations from "../drLocations";
import {createTeamMemberList, createUserRoleObject} from "../drObjectFactory";
import api from "./drDueProcessApi";
import {createDueProcessObject} from "./drDueProcessObjectFactory";
import {DueProcessSections} from "./drDueProcessConstants";

const DrDueProcessWrapper = ({
                                 actions,
                                 dueProcessId,
                                 Component,
                                 currentLocation,
                                 dateWindowId,
                                 dateWindows,
                                 history,
                                 institutionId,
                                 institutions,
                                 props
                             }) => {

    const [dataChanged, setDataChanged] = useState(false);
    const [district, setDistrict] = useState("");
    const [date, setDate] = useState("");
    const [returnUrl, setReturnUrl] = useState("/");
    const [dueProcess, setDueProcess] = useState(null);
    const [teamMembers, setTeamMembers] = useState(createListFromObject([]));
    const [userDrTeamMemberRoles, setUserDrTeamMemberRoles] = useState([]);

    const [canEdit] = useState(allow(DueProcessPolicy, dispute_resolution.dueProcess.MODIFY));
    const [canSubmit] = useState(allow(DueProcessPolicy, dispute_resolution.dueProcess.audits.SUBMIT));
    const [canAudit] = useState(allow(DueProcessPolicy, dispute_resolution.dueProcess.audits.AUDIT));
    const [canReview] = useState(allow(DueProcessPolicy, dispute_resolution.dueProcess.audits.REVIEW));
    const [isDistrictUser] = useState(AccountUtility.isDistrictUser());

    const returnCaseListUrl = drLocations.LANDING_CASE_LIST.getUrl();
    const returnDrSummaryUrl = drLocations.DR_SUMMARY.getUrl();
    const overviewUrl = drLocations.DUE_PROCESS_OVERVIEW.getUrl(dateWindowId, institutionId, dueProcessId);
    const resolutionProcessUrl = drLocations.DUE_PROCESS_RESOLUTION_PROCESS.getUrl(dateWindowId, institutionId, dueProcessId);
    const finalOrderUrl = drLocations.DUE_PROCESS_FINAL_ORDER.getUrl(dateWindowId, institutionId, dueProcessId);
    const actionsUrl = drLocations.DUE_PROCESS_ACTIONS.getUrl(dateWindowId, institutionId, dueProcessId);
    const caseNotesUrl = drLocations.DUE_PROCESS_CASE_NOTES.getUrl(dateWindowId, institutionId, dueProcessId);

    const scroller = Scroll.scroller;
    const animateScroll = Scroll.animateScroll;
    const scrollConfig = {
        duration: 500,
        delay: 50,
        smooth: true
    };
    const handleScroll = (containerId) => {
        window.setTimeout(() => {
            scroller.scrollTo(containerId, scrollConfig);
        }, 150);
    };

    const handleScrollToTop = () => {
        window.setTimeout(() => {
            animateScroll.scrollToTop(scrollConfig);
        }, 150);
    }

    const handleReturn = () => history.push(isDistrictUser ? returnUrl : returnCaseListUrl);

    const handleSelectSection = (event, section, forceReplace = false) => {
        let url = "";
        switch (section) {
            case DueProcessSections.Overview:
                url = overviewUrl;
                break;
            case DueProcessSections.ResolutionProcess:
                url = resolutionProcessUrl;
                break;
            case DueProcessSections.FinalOrder:
                url = finalOrderUrl;
                break;
            case DueProcessSections.CorrectiveActions:
                url = actionsUrl;
                break;
            case DueProcessSections.CaseNotes:
                url = caseNotesUrl;
                break;
        }

        if (url)
            navButtonClick(event, url, forceReplace);

        if (forceReplace)
            loadDueProcess();
    };

    const getSectionFromCurrentLocation = () => {
        switch (currentLocation) {
            case overviewUrl:
                return DueProcessSections.Overview
            case resolutionProcessUrl:
                return DueProcessSections.ResolutionProcess;
            case finalOrderUrl:
                return DueProcessSections.FinalOrder;
            case actionsUrl:
                return DueProcessSections.CorrectiveActions;
            case caseNotesUrl:
                return DueProcessSections.CaseNotes;
        }

        return "";
    }

    const navButtonClick = (event, location, forceReplace = false) => {
        event.preventDefault();

        if (forceReplace) {
            history.replace(location);
            setDataChanged(false);
            return;
        }

        if (location === currentLocation) return;

        if (dataChanged) {
            if (confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page.")) {
                if (location !== returnCaseListUrl && location !== returnUrl && location !== returnDrSummaryUrl)
                    loadDueProcess();
            } else
                return;
        }

        setDataChanged(false);

        history.push(location);
        handleScrollToTop();
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const handleUpdateDueProcess = (updatedDueProcess) => {
        setDueProcess(createDueProcessObject(updatedDueProcess));

        setDataChanged(false);
    };

    const getDueProcess = () => {
        actions.executeApi(api.getDueProcess, [dueProcessId])
            .then((result) => handleUpdateDueProcess(result));
    };

    const loadDueProcess = () => {
        if (dueProcessId && dueProcessId !== drLocations.DUE_PROCESS_ID_NEW)
            getDueProcess();
        else
            handleUpdateDueProcess();
    };

    const getAssociatedDueProcessRoles = (dueProcess, teamMembers) => {
        const user = LocalStorage.getLoggedInUser();
        const userId = !isNullOrUndefined(user) ? user.Id : "";
        let userRoles = [];
        for (let role of DrRolesAssociatedToDueProcess) {
            const dueProcessTeamMember = dueProcess.teamMembers.find(f => f.role === role);
            const dueProcessTeamMemberId = !dueProcessTeamMember ? "" : dueProcessTeamMember.id;
            if (teamMembers.find(f => f.id === dueProcessTeamMemberId && f.userId === userId && !f.isDisabled))
                userRoles.push(createUserRoleObject(role, dueProcessTeamMemberId));
        }

        for (let role of SingleSelectionDrRolesAssociatedToDueProcess) {
            const teamMember = teamMembers.find(f => f.userId === userId && f.role === role && !f.isDisabled);
            if (teamMember)
                userRoles.push(createUserRoleObject(role, teamMember.id));
        }

        return userRoles;
    };


    useEffect(() => {
        loadDueProcess();
    }, [dueProcessId]);

    useEffect(() => {
        if (institutions && institutionId)
            setDistrict(fetchDistrict(institutions, institutionId));
    }, [institutions, institutionId]);

    useEffect(() => {
        if (dateWindows && dateWindowId)
            setDate(fetchDate(dateWindows, dateWindowId));
    }, [dateWindows, dateWindowId]);

    useEffect(() => {
        if (district && date && dueProcess) {
            let dueProcessDetails = dueProcess.caseNumber ? `Case No ${dueProcess.caseNumber}` : "";

            const currentSection = getSectionFromCurrentLocation();
            const headerLeft = 'Due Process Hearing Request';
            const headerCenter = `${district} (${date})`;
            actions.updatePageTitle(headerLeft, headerCenter, dueProcessDetails);
            setPageTitle(`${headerLeft}: ${headerCenter}${dueProcess.caseNumber ? `, ${dueProcessDetails}` : ""}${isTrimmedStringEmpty(currentSection) ? "" : ` - ${currentSection}`}`);
        }
    }, [district, date, dueProcess, currentLocation]);

    useEffect(() => {
        setReturnUrl(drLocations.LANDING_DISTRICT.getUrl(institutionId));
    }, [institutionId]);

    useEffect(() => {
        drApi.getTeamMembers()
            .then((result) => setTeamMembers(createTeamMemberList(result, true)));
    }, []);

    useEffect(() => {
        if (isNullOrUndefined(dueProcess) || teamMembers.length === 0)
            return;

        setUserDrTeamMemberRoles(getAssociatedDueProcessRoles(dueProcess, teamMembers));

    }, [teamMembers, dueProcess]);

    if (!dueProcess) return null;

    return (
        <GridRow
            isPageNav
            rowClass={`dueProcessContainer`}
        >
            <GridColumn
                isPageNavLinks
                isStickyLinks
                large="3"
            >
                <Button
                    btnClass={currentLocation === overviewUrl ? "is-active" : ""}
                    onClick={(event) => navButtonClick(event, overviewUrl)}
                    label={DueProcessSections.Overview}
                    name={overviewUrl}
                />
                {
                    dueProcessId !== drLocations.DUE_PROCESS_ID_NEW &&
                    <Button
                        btnClass={currentLocation === resolutionProcessUrl ? "is-active" : ""}
                        onClick={(event) => navButtonClick(event, resolutionProcessUrl)}
                        label={DueProcessSections.ResolutionProcess}
                        name={resolutionProcessUrl}
                    />
                }
                {
                    dueProcess.isFinalOrderEnabled &&
                    <Button
                        btnClass={currentLocation === finalOrderUrl ? "is-active" : ""}
                        onClick={(event) => navButtonClick(event, finalOrderUrl)}
                        label={DueProcessSections.FinalOrder}
                        name={finalOrderUrl}
                    />
                }
                {
                    dueProcess.isActionEnabled &&
                    <Button
                        btnClass={currentLocation === actionsUrl ? "is-active" : ""}
                        onClick={(event) => navButtonClick(event, actionsUrl)}
                        label={DueProcessSections.CorrectiveActions}
                        name={actionsUrl}
                    />
                }
                {
                    dueProcessId !== drLocations.DUE_PROCESS_ID_NEW &&
                    !isDistrictUser &&
                    <Button
                        btnClass={currentLocation === caseNotesUrl ? "is-active" : ""}
                        onClick={(event) => navButtonClick(event, caseNotesUrl)}
                        label={DueProcessSections.CaseNotes}
                        name={caseNotesUrl}
                    />
                }
                {
                    !isDistrictUser &&
                    <>
                        <Button
                            onClick={(event) => navButtonClick(event, returnCaseListUrl)}
                            label={"Return to Case List"}
                            name={`btnBackCaseList`}
                        />
                        <Button
                            onClick={(event) => navButtonClick(event, returnDrSummaryUrl)}
                            label={"Return to My Summary"}
                            name={`btnBackDrSummary`}
                        />
                    </>
                }
                {
                    isDistrictUser &&
                    <Button
                        onClick={(event) => navButtonClick(event, returnUrl)}
                        label={"Back to District Landing"}
                        name={`btnBack`}
                    />
                }
            </GridColumn>

            <GridColumn
                isPageNavContent
                large="9"
            >
                <Component
                    {...props}
                    actions={actions}
                    canEdit={canEdit}
                    canSubmit={canSubmit}
                    canAudit={canAudit}
                    canReview={canReview}
                    dateWindowId={dateWindowId}
                    dueProcess={dueProcess}
                    executeApi={actions.executeApi}
                    handleDataChanged={handleDataChanged}
                    handleReturn={handleReturn}
                    handleSelectSection={handleSelectSection}
                    handleUpdateDueProcess={handleUpdateDueProcess}
                    handleScroll={handleScroll}
                    handleScrollToTop={handleScrollToTop}
                    history={history}
                    institutionId={institutionId}
                    isDistrictUser={isDistrictUser}
                    teamMembers={teamMembers}
                    userDrTeamMemberRoles={userDrTeamMemberRoles}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;
    const params = drLocations.DUE_PROCESS.getParams(currentLocation);

    return {
        Component,
        currentLocation,
        ...params,
        props
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, FileActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrDueProcessWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindowId: PropTypes.string,
    dateWindows: PropTypes.array,
    dueProcessId: PropTypes.string,
    history: PropTypes.object,
    institutionId: PropTypes.string,
    institutions: PropTypes.array,
    props: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrDueProcessWrapper);