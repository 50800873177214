import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {DisputeResolutionType, DrRoles} from "../drConstants";
import {DrFilterDueProcessStatus} from "../DueProcesses/drDueProcessConstants";
import SectionHeader from "../../components/common/SectionHeader";

export const DrDueProcessCaseload = ({
                                             dueProcessCaseloads,
                                             handleClickCaseload,
                                             handleClickOrder = null,
                                             includeUserStatus = false,
                                             showName = false
                                         }) => {
    const sortClass = handleClickOrder ? "is-clickable" : "";

    return (
        <>
            {
                dueProcessCaseloads &&
                dueProcessCaseloads.length > 0 &&
                <>
                    <SectionHeader>
                        <h2>Due Process Caseload</h2>
                    </SectionHeader>

                    <GridTable className={`caseload-table`}>
                        <thead>
                        <tr>
                            {
                                showName &&
                                <th rowSpan={2} onClick={handleClickOrder} data-id="fullName" className={sortClass}>Name</th>
                            }
                            {
                                includeUserStatus &&
                                <th rowSpan={2} onClick={handleClickOrder} data-id="userStatus" className={sortClass}>Status</th>
                            }
                            <th colSpan={4} className={`text-center`}>Due Process Hearing Requests</th>
                            <th colSpan={4} className={`text-center`}>Resolution Process Status Reports</th>
                        </tr>
                        <tr>
                            <th onClick={handleClickOrder} data-id="open" className={sortClass}>Open</th>
                            <th onClick={handleClickOrder} data-id="WithdrawnOrDismissedOrResolved" className={sortClass}>Withdrawn/ Dismissed</th>
                            <th onClick={handleClickOrder} data-id="HearingFullyAdjudicated" className={sortClass}>Hearing Fully Adjudicated</th>
                            <th onClick={handleClickOrder} data-id="resolved" className={sortClass}>Resolved with Pending Ordered Actions</th>
                            <th onClick={handleClickOrder} data-id="resolutionProcessWithUpcomingDeadlines" className={sortClass}>Upcoming</th>
                            <th onClick={handleClickOrder} data-id="resolutionProcessNeedingReview" className={sortClass}>Ready for Review</th>
                            <th onClick={handleClickOrder} data-id="resolutionProcessOverdue" className={sortClass}>Overdue</th>
                            <th onClick={handleClickOrder} data-id="resolutionProcessBeingRevised" className={sortClass}>Currently Being Revised</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            dueProcessCaseloads.map((dueProcess, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            showName &&
                                            <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, "", DrRoles.DueProcessLiaison.id)}
                                                className={"is-clickable"}>{dueProcess.fullName}</td>
                                        }
                                        {
                                            includeUserStatus &&
                                            <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, "", DrRoles.DueProcessLiaison.id)}
                                                className={"is-clickable"}>{dueProcess.userStatus}</td>
                                        }
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.Pending, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.open}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.WithdrawnOrDismissedOrResolvedWithoutHearing, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.withdrawnOrDismissedOrResolved}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.HearingFullyAdjudicated, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.hearingFullyAdjudicated}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.HearingFullyAdjudicatedWithOrderedActions, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.resolved}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.StatusReportUpcoming, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.resolutionProcessWithUpcomingDeadlines}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.StatusReportNeedingReview, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.resolutionProcessNeedingReview}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.StatusReportOverdue, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.resolutionProcessOverdue}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.DueProcess, dueProcess.userId, DrFilterDueProcessStatus.StatusReportNeedingRevisions, DrRoles.DueProcessLiaison.id)}
                                            className={"is-clickable"}>{dueProcess.resolutionProcessBeingRevised}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                </>
            }
        </>
    );
};

DrDueProcessCaseload.propTypes = {
    dueProcessCaseloads: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClickCaseload: PropTypes.func.isRequired,
    handleClickOrder: PropTypes.func,
    includeUserStatus: PropTypes.bool,
    showName: PropTypes.bool
};

export default DrDueProcessCaseload;