import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Scroll from "react-scroll";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as monitoringComplianceActions from "../../actions/monitoringComplianceActions";
import * as monitoringComplianceStudentCorrectionActions from "../../actions/monitoringComplianceStudentCorrectionActions";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import * as SharedDataActions from "../../actions/sharedDataActions";
import Button from "../../components/common/buttons/Button";
import {isNullOrUndefined} from "../../components/common/commonUtilities";
import {
    McFilterVisibilityRules,
    MonitoringComplianceDistrictSearchCriteria
} from "../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import {McFilter} from "../../components/MonitoringCompliance/McFilter";
import {getGeneralProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import LocalStorage from "../../components/shared/LocalStorage";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as monitoringComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import {getParamsMultiple} from "../../components/layout/getParams";
import {emptyGuid} from "../../constants/config";

const genericStudentRecordsPath = monitoringComplianceLocations.LANDING_DISTRICT.path;
const studentRecordsPath = monitoringComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path;
const reassessmentPath = monitoringComplianceLocations.COMPLIANCE_REASSESS_DISTRICT_REVIEW.path;
const studentCorrectionsPath = monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path;
const studentCorrectionsWithProtocolsPath = monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY_FOR_STUDENT.path;
const matrixCorrectionsPath = monitoringComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path;
const correctivePath = monitoringComplianceLocations.DISTRICT_CORRECTIVE_ACTIONS.path;
const notificationSettingsPath = monitoringComplianceLocations.DISTRICT_NOTIFICATION_SETTINGS.path;

export class MonitoringComplianceDistrictWrapperPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentPath: null,
            dataChanged: false,
            currentStep: 1
        };

        this.navButtonClick = this.navButtonClick.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.comparePaths = this.comparePaths.bind(this);
        this.onChangeDateWindow = this.onChangeDateWindow.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollToTop = this.handleScrollToTop.bind(this);
        this.loadComplianceAssessment = this.loadComplianceAssessment.bind(this);
        this.updateCurrentStep = this.updateCurrentStep.bind(this);
        this.handleDataChanged = this.handleDataChanged.bind(this);
    }

    componentDidMount() {
        if (this.props.dateWindows.length === 0)
            this.props.actions.loadAllDateWindows()
                .then(() => {
                    this.loadComplianceAssessment();
                });
        else
            this.loadComplianceAssessment();

        this.setState({currentPath: this.getCurrentPath()});
    }

    componentDidUpdate(prevProps) {
        if (this.getCurrentLocation() === genericStudentRecordsPath) {
            this.setState({currentPath: genericStudentRecordsPath});
            this.redirectToNextTask();
        }
        else if(prevProps.location.pathname !== this.props.location.pathname
            && prevProps.location.pathname !== genericStudentRecordsPath)
            this.setState({currentPath: this.getCurrentPath()});
    }

    getScrollConfig() {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    }

    handleScroll(containerId) {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, this.getScrollConfig());
        }, 300);
    }

    handleScrollToTop() {
        const animateScroll = Scroll.animateScroll;
        window.setTimeout(() => {
            animateScroll.scrollToTop(this.getScrollConfig());
        }, 300);
    }

    redirectToNextTask() {
        let path = studentRecordsPath;

        const selectedAssessment = this.validateAssessment();
        if(!selectedAssessment)
            return;

        const generalAssessmentStatus = getGeneralProtocolRequirementStatus(selectedAssessment);

        if(generalAssessmentStatus.validationSubmitted &&
            generalAssessmentStatus.studentRecordsWithDiscrepanciesRequiringReassessment &&
             !generalAssessmentStatus.allAssessmentsApproved)
            path = reassessmentPath;
        else if(generalAssessmentStatus.assessmentApproved &&
                selectedAssessment.anyProtocolsNotMeetingCompliance)
           path = studentCorrectionsPath;
        else if(generalAssessmentStatus.assessmentApproved &&
                selectedAssessment.anyMatrixProtocolsNotMeetingCompliance)
            path = matrixCorrectionsPath;
        else if(generalAssessmentStatus.assessmentApproved &&
                selectedAssessment.anyProtocolsNotMeetingCompliance)
            path = correctivePath;

        this.props.history.replace(this.buildPath(path));
    }

    validateAssessment() {
        const selectedAssessment = this.props.selectedAssessment;
        if(!selectedAssessment || !selectedAssessment.monitoringAssessmentId || selectedAssessment.monitoringAssessmentId === emptyGuid) {
            this.props.history.replace(this.buildPath(studentRecordsPath));
            return null;
        }
        return selectedAssessment;
    }

    updateCurrentStep(currentStep) {
        this.setState({currentStep: currentStep});
    }

    loadComplianceAssessment(callback = null) {
        const {assessmentId} = this.props;
        this.props.actions.clearSelectedComplianceAssessment();
        if(assessmentId) {
            this.props.actions.loadComplianceAssessment(assessmentId)
                .then((assessment) => {
                    if(!assessment) return;
                    this.initializeSearchCriteria(assessment.dateWindowId);
                    this.validateAssessment();
                    if(callback) callback();
                })
        }
        else {
            const selectedDateWindowId = this.getDefaultWindowId();

            if(selectedDateWindowId !== "") {
                this.props.actions.loadDistrictComplianceAssessments(LocalStorage.getLoggedInUser().InstitutionId, selectedDateWindowId)
                    .then(() => {
                        if(callback) callback();
                        this.validateAssessment();
                    });
                this.initializeSearchCriteria(selectedDateWindowId);
            }
        }
    }

    getDefaultWindowId() {
        const {dateWindowId} = this.props;
        return dateWindowId ? dateWindowId
                : this.props.searchCriteria && this.props.searchCriteria.dateWindowId !== ""
                    ? this.props.searchCriteria.dateWindowId
                    : this.props.dateWindows.length > 0 ? this.props.dateWindows[0].value : "";
    }

    initializeSearchCriteria(dateWindowId) {
        let criteria = {...this.props.searchCriteria};

        if(criteria.dateWindowId === dateWindowId  && criteria.areRulesCurrent(McFilterVisibilityRules.DistrictWrapper))
            return;

        criteria.dateWindowId = dateWindowId;
        criteria.setVisibilityRules(McFilterVisibilityRules.DistrictWrapper);
        this.props.actions.saveSearchCriteria(criteria);
    }

    handleDataChanged(dataUpdated = true) {
        this.setState({dataChanged: dataUpdated});
    }

    navButtonClick(event, location) {
        event.preventDefault();

        if (this.comparePaths(location)) return;

        if(this.state.dataChanged) {
            if (confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page.")) {
                this.loadComplianceAssessment();
            }
            else
                return;
        }

        this.redirectNav(location);
    }

    redirectNav(location) {
        this.setState({dataChanged: false});
        this.props.history.push(this.buildPath(location));
        this.handleScrollToTop();
    }

    comparePaths(path) {
        path = this.buildPath(path);

        return (path === this.getCurrentLocation());
    }

    getCurrentLocation() {
        return this.props.location.pathname;
    }

    getCurrentPath() {
        if(this.comparePaths(studentRecordsPath))
            return studentRecordsPath;

        if(this.comparePaths(reassessmentPath))
            return reassessmentPath;

        if(this.comparePaths(studentCorrectionsPath))
            return studentCorrectionsPath;

        if(this.comparePaths(studentCorrectionsWithProtocolsPath))
            return studentCorrectionsWithProtocolsPath;

        if(this.comparePaths(matrixCorrectionsPath))
            return matrixCorrectionsPath;

        if(this.comparePaths(correctivePath))
            return correctivePath;

        if(this.comparePaths(notificationSettingsPath))
            return notificationSettingsPath;

        return null;
    }

    buildPath(location, dateWindowIdOverride = null) {
        let {assessmentId, dateWindowId, studentRecordId} = this.props;
        if ((dateWindowId === null || assessmentId == null) &&
            this.props.selectedAssessment &&
            this.props.selectedAssessment.monitoringAssessmentId &&
            this.props.selectedAssessment.monitoringAssessmentId !== emptyGuid) {
            dateWindowId = dateWindowId || this.props.selectedAssessment.dateWindowId;
            assessmentId = assessmentId || this.props.selectedAssessment.monitoringAssessmentId;
        }
        else if(dateWindowId === null)
            dateWindowId = this.getDefaultWindowId();

        dateWindowId = isNullOrUndefined(dateWindowIdOverride) ? dateWindowId : dateWindowIdOverride;

        return location.replace(monitoringComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(monitoringComplianceLocations.ASSESSMENT_ID, assessmentId)
            .replace(monitoringComplianceLocations.STUDENT_RECORD_ID, studentRecordId)
            .replace(monitoringComplianceLocations.IS_MATRIX, monitoringComplianceLocations.MATRIX);
    }

    onChangeDateWindow(criteria) {
        this.props.actions.loadDistrictComplianceAssessments(LocalStorage.getLoggedInUser().InstitutionId, criteria.dateWindowId)
            .then(() => {
                if(this.validateAssessment())
                    this.redirectNav(this.state.currentPath.replace(monitoringComplianceLocations.DATE_WINDOW_ID, criteria.dateWindowId));

                this.handleScrollToTop();
            });

        this.props.actions.saveSearchCriteria(criteria);
    }

    shouldShowMcFilter() {
        return this.state.currentPath &&
            this.props.searchCriteria &&
            this.props.searchCriteria.areRulesCurrent(McFilterVisibilityRules.DistrictWrapper) &&
            !this.comparePaths(notificationSettingsPath);
    }

    render() {
        const {component: Component} = this.props;

        const generalAssessmentStatus = getGeneralProtocolRequirementStatus(this.props.selectedAssessment);

        return (
            <GridRow isPageNav>
                <GridColumn isPageNavLinks
                            isStickyLinks
                            large="3">
                    <Button btnClass={this.comparePaths(studentRecordsPath) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, studentRecordsPath)}
                            label={"Student Records"}
                            name={monitoringComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path}/>
                    {
                        this.props.selectedAssessment &&
                        <>
                            {
                                generalAssessmentStatus.validationSubmitted &&
                                generalAssessmentStatus.studentRecordsWithDiscrepanciesRequiringReassessment &&
                                !generalAssessmentStatus.allAssessmentsApproved &&
                                <Button btnClass={this.comparePaths(reassessmentPath) ? "is-active" : ""}
                                        onClick={(event) => this.navButtonClick(event, reassessmentPath)}
                                        label={"Reassessment"}
                                        name={monitoringComplianceLocations.COMPLIANCE_REASSESS_DISTRICT_REVIEW.path}/>
                            }
                            {
                                generalAssessmentStatus.assessmentApproved &&
                                this.props.selectedAssessment.anyProtocolsNotMeetingCompliance &&
                                <Button btnClass={this.comparePaths(studentCorrectionsPath) ? "is-active" : ""}
                                        onClick={(event) => this.navButtonClick(event, studentCorrectionsPath)}
                                        label={"Student Specific Corrections"}
                                        name={monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path}/>
                            }
                            {
                                generalAssessmentStatus.assessmentApproved &&
                                this.props.selectedAssessment.anyMatrixProtocolsNotMeetingCompliance &&
                                <Button btnClass={this.comparePaths(matrixCorrectionsPath) ? "is-active" : ""}
                                        onClick={(event) => this.navButtonClick(event, matrixCorrectionsPath)}
                                        label={"Matrix Specific Corrections"}
                                        name={monitoringComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path}/>
                            }
                            {
                                generalAssessmentStatus.assessmentApproved &&
                                this.props.selectedAssessment.anyProtocolsNotMeetingCompliance &&
                                <Button btnClass={this.comparePaths(correctivePath) ? "is-active" : ""}
                                        onClick={(event) => this.navButtonClick(event, correctivePath)}
                                        label={"Corrective Actions"}
                                        name={monitoringComplianceLocations.DISTRICT_CORRECTIVE_ACTIONS.path}/>
                            }
                        </>
                    }
                    <Button btnClass={this.comparePaths(notificationSettingsPath) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, notificationSettingsPath)}
                            label={"Notification Settings"}
                            name={monitoringComplianceLocations.DISTRICT_NOTIFICATION_SETTINGS.path}/>
                </GridColumn>

                <GridColumn isPageNavContent large="9">
                    {
                        this.shouldShowMcFilter() &&
                        <McFilter
                            dateWindows={this.props.dateWindows}
                            handleSearchFiltersChange={this.onChangeDateWindow}
                            searchCriteria={this.props.searchCriteria}
                        />
                    }
                    {
                        (!this.props.selectedAssessment || !this.props.selectedAssessment.indicatorRequirements) &&
                        <div>An assessment has not been created for the selected date window.</div>
                    }
                    {
                        this.props.selectedAssessment &&
                        this.props.selectedAssessment.indicatorRequirements &&
                        <Component
                            {...this.props}
                            currentStep={this.state.currentStep}
                            handleDataChanged={this.handleDataChanged}
                            handleScroll={this.handleScroll}
                            handleScrollToTop={this.handleScrollToTop}
                            loadComplianceAssessment={this.loadComplianceAssessment}
                            updateCurrentStep={this.updateCurrentStep}
                        />
                    }
                </GridColumn>
            </GridRow>
        );
    }
}

MonitoringComplianceDistrictWrapperPage.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    component: PropTypes.func.isRequired,
    dateWindowId: PropTypes.string,
    dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMatrix: PropTypes.bool,
    location: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    page: PropTypes.string,
    path: PropTypes.string.isRequired,
    protocolId: PropTypes.string,
    searchCriteria: PropTypes.object,
    selectedAssessment: PropTypes.object,
    studentRecordId: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
    let dateWindows = [...state.sharedData.dateWindows];
    if (dateWindows.length > 0) {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
    }

    const params = getParamsMultiple(ownProps.location.pathname, monitoringComplianceLocations.AllDistrictRoutes);
    const selectedDateWindowId = (params && params.dateWindowId) ? params.dateWindowId : state.monitoringCompliance.selectedDateWindow;
    const searchCriteria = state.searchCriteria.MonitoringComplianceDistrictSearchCriteria || new MonitoringComplianceDistrictSearchCriteria(selectedDateWindowId);

    const selectedAssessment = state.monitoringCompliance.selectedAssessment;
    const monitoringAssessmentId = selectedAssessment && selectedAssessment.monitoringAssessmentId ? selectedAssessment.monitoringAssessmentId : null;

    return {
        assessmentId: params && params.assessmentId ? params.assessmentId : monitoringAssessmentId,
        dateWindowId: params && params.dateWindowId ? params.dateWindowId : null,
        studentRecordId: params && params.studentRecordId ? params.studentRecordId : null,
        protocolId: params && params.protocolId ? params.protocolId : null,
        page: params && params.page ? params.page : null,
        isMatrix: params && params.isMatrix ? params.isMatrix : null,
        dateWindows,
        isLoading: state.ajaxCallsInProgress > 0,
        searchCriteria,
        selectedAssessment: selectedAssessment
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({},
        layoutActions,
        monitoringComplianceActions,
        monitoringComplianceStudentCorrectionActions,
        apiForLocalStateActions,
        SharedDataActions,
        searchCriteriaActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MonitoringComplianceDistrictWrapperPage));