import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { createHistoryListItem } from "./gmsUtilities";
import {emptyGuid} from "../../constants/config";
import gmsApi from "./gmsApi";

export const GmsWrapperApplicationHistory = (props) => {
    const {
        actions,
        applicationId = "",
        Component
    } = props;

    const [applicationHistories, setApplicationHistories] = useState([]);
    const [selectedApplicationHistory, setSelectedApplicationHistory] = useState("");

    const getApplicationHistoryData = async () => {
        if(!applicationId || applicationId === emptyGuid) {
            setApplicationHistories([]);
            setSelectedApplicationHistory("");
            return;
        }

        await actions.executeApi(gmsApi.getApplicationHistories, [applicationId])
            .then((applicationHistory) => {
                let applicationHistoryOptions = [];
                if (applicationHistory && applicationHistory.length > 0)
                    applicationHistoryOptions = applicationHistory.map(history => createHistoryListItem(history));

                setApplicationHistories(applicationHistoryOptions);
                setSelectedApplicationHistory("");
            });
    };

    useEffect(() => {
        getApplicationHistoryData();
    }, [applicationId]);

    return (
        <Component
            {...props}
            applicationHistories={applicationHistories}
            getApplicationHistoryData={getApplicationHistoryData}
            selectedApplicationHistory={selectedApplicationHistory}
            setSelectedApplicationHistory={setSelectedApplicationHistory}
        />
    );
};

GmsWrapperApplicationHistory.propTypes = {
    actions: PropTypes.object,
    applicationId: PropTypes.string,
    Component: PropTypes.func
};