import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import drDueProcessesApi from "../DueProcesses/drDueProcessApi";
import * as drLocations from "../drLocations";

const DrDistrictDueProcess = ({
	                              actions,
	                              dateWindowId,
	                              districtId,
	                              handleClickView
                              }) => {

	const [dueProcesses, setDueProcesses] = useState([]);

	const getDueProcesses = () => {
		actions.executeApi(drDueProcessesApi.getDistrictDueProcessesByDate, [districtId, drLocations.DUE_PROCESS_TILE, dateWindowId])
			.then((result) => {
				setDueProcesses(result);
			});
	};

	const tileContainerTitle = (dueProcesses.length === 0) ? "No due process hearing requests are available at this time" : "Due Process Hearing Requests";

	useEffect(() => {
		if (districtId && dateWindowId)
			getDueProcesses();
	}, [districtId, dateWindowId]);

	return (
		<TileContainer title={tileContainerTitle}>
			{
				dueProcesses.map((tile, i) => {
					return (<Tile key={i}
					              header={tile.header}
					              body={tile.body}
					              footer={tile.footer}
					              isNew={tile.isNew}
					              onClick={() => handleClickView(tile.url)}
					/>);
				})
			}

		</TileContainer>
	);
};

DrDistrictDueProcess.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string,
	districtId: PropTypes.string,
	handleClickView: PropTypes.func.isRequired
};

export default DrDistrictDueProcess;