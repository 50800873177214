import React from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty, isNullOrUndefined} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import {reinitializeInputFormFields, useFormInputObject} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import {emptyGuid} from "../../../../constants/config";
import {createGmsUserObject} from "./ptsAdminGmsUserFactory";
import {PtsManageGmsUserTableRowEdit} from "./PtsAdminGmsUserTableRowEdit";
import {PtsManageGmsUserTableRowDisplay} from "./PtsManageGmsUserTableRowDisplay";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const PtsAdminGmsUsersView = ({
                                         editGmsUserId,
                                         gmsUsers,
                                         handleDataChanged,
                                         handleDeleteGmsUser,
                                         handleSaveGmsUser,
                                         setEditGmsUserId
                                     }) => {
    const gmsUserForm = useFormInputObject(createGmsUserObject(), () => handleDataChanged());

    const handleCancelGmsUserClick = (event) => {
        event.preventDefault();
        handleDataChanged(false);
        setEditGmsUserId(null);
        NotifyUser.clear(true);
    };

    const handleAddGmsUserClick = (event) => {
        event.preventDefault();
        setEditGmsUserId(emptyGuid);
        reinitializeInputFormFields(gmsUserForm, createGmsUserObject());
    };

    const handleModifyGmsUserClick = (event, gmsUser) => {
        event.preventDefault();
        setEditGmsUserId(gmsUser.gmsUserId);
        reinitializeInputFormFields(gmsUserForm, gmsUser);
    };

    const handleDeleteGmsUserClick = (event, gmsUserId) => {
        event.preventDefault();
        if(confirm("Are you sure you want to delete this GMS user?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            handleDeleteGmsUser(gmsUserId);
    };

    const rowsDisabled = !isNullOrUndefined(editGmsUserId);
    const isNewGmsUser = editGmsUserId === emptyGuid;

    return (
        <GridTable>
            <thead>
            <tr>
                <th>
                    First Name
                </th>
                <th>
                    Last Name
                </th>
                <th>
                    Email
                </th>
                <th>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            {
                isArrayNullOrEmpty(gmsUsers) && !isNewGmsUser &&
                <tr>
                    <td colSpan={4} className={"center"}>
                        No GMS users found.
                    </td>
                </tr>
            }
            {
                gmsUsers.map((gmsUser, key) => {
                    if (gmsUser.gmsUserId === editGmsUserId)
                        return <PtsManageGmsUserTableRowEdit
                            gmsUserForm={gmsUserForm}
                            handleCancelGmsUserClick={handleCancelGmsUserClick}
                            handleSaveGmsUser={handleSaveGmsUser}
                            key={key}
                        />;

                    return <PtsManageGmsUserTableRowDisplay
                        handleDeleteGmsUserClick={handleDeleteGmsUserClick}
                        handleModifyGmsUserClick={handleModifyGmsUserClick}
                        gmsUser={gmsUser}
                        key={key}
                        rowDisabled={rowsDisabled}
                    />;
                })
            }
            {
                isNewGmsUser &&
                <PtsManageGmsUserTableRowEdit
                    gmsUserForm={gmsUserForm}
                    handleCancelGmsUserClick={handleCancelGmsUserClick}
                    handleSaveGmsUser={handleSaveGmsUser}
                    isNewGmsUser={isNewGmsUser}
                />
            }
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={12}>
                    <Button
                        buttonType={ButtonTypes.ADD}
                        btnClass={`button--large`}
                        label={`Add GMS User`}
                        name={`btnAddGmsUser`}
                        onClick={handleAddGmsUserClick}
                        showLabel
                    />
                </td>
            </tr>
            </tfoot>
        </GridTable>
    );
};

PtsAdminGmsUsersView.propTypes = {
    editGmsUserId: PropTypes.string,
    gmsUsers: PropTypes.array,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeleteGmsUser: PropTypes.func.isRequired,
    handleSaveGmsUser: PropTypes.func.isRequired,
    setEditGmsUserId: PropTypes.func.isRequired
};