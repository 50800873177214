import * as config from "../constants/config";
import * as ApiUtility from "../api/ApiUtility";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../api/requests/RequestTypes';
import {reports as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerReportsApi {
    static getReport(reportUrl){
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}${reportUrl}`,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockReportsApi {
    static getReport() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}


const ReportApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerReportsApi : MockReportsApi;
export default ReportApi;