import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import ActivitySummaryGrid from "../ActivitiesComponents/ActivitySummaryGrid";
import {enableCapActivityResults, getCapResultsStatusWhenActivityAccepted} from "../capUtilities";

const StateActivitiesView = ({
                                 capActivities = [],
                                 canEditActivityResult,
                                 handleReview,
                                 protocolCorrectiveActions,
                                 indicatorId
                             }) => {

    const getCapActivityStatus = (capActivityStatus, capActivityResultsStatus) => {
        const resultsStatus = getCapResultsStatusWhenActivityAccepted(capActivityStatus, capActivityResultsStatus);

        return isTrimmedStringEmpty(resultsStatus) ? capActivityStatus : resultsStatus;
    };

    const getEditButtonDetails = (activityNumber, capActivityStatus, capActivityResultsStatus, isReviewEnabled) => {
        const canEditActivityResultReview = canEditActivityResult && isReviewEnabled;

        const editButtonPostfix = isReviewEnabled
            ? " Review"
            : enableCapActivityResults(capActivityStatus) ? " Results" : "";

        return {
            isEditable: canEditActivityResultReview,
            label:  `${canEditActivityResultReview ? "Edit" : "Review"} CAP Activity ${activityNumber}${editButtonPostfix}`
        };
    };

    return (
        <ActivitySummaryGrid
            capActivities={capActivities}
            getCapActivityStatus={getCapActivityStatus}
            getEditButtonDetails={getEditButtonDetails}
            handleEdit={handleReview}
            indicatorId={indicatorId}
            protocolCorrectiveActions={protocolCorrectiveActions}
            readOnly={true}
        />
    );
};

StateActivitiesView.propTypes = {
    capActivities: PropTypes.arrayOf(PropTypes.object),
    canEditActivityResult: PropTypes.bool.isRequired,
    handleReview: PropTypes.func.isRequired,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object),
    indicatorId: PropTypes.string.isRequired
};

export default StateActivitiesView;