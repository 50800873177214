import React from "react";
import PropTypes from 'prop-types';
import FederalReportView from "./FederalReportView";

const FederalWithGiftedReportPrintView = ({
                                    actions,
                                    dateWindowId,
                                    dateWindows
                                }) => {
    return <FederalReportView
        actions={actions}
        dateWindowId={dateWindowId}
        dateWindows={dateWindows}
        includeGifted
    />;
};

FederalWithGiftedReportPrintView.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired
};

export default FederalWithGiftedReportPrintView;