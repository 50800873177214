import React from "react";
import PropTypes from 'prop-types';
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {
    isSubmissionStudentCorrectionsEnabledDueToStatus,
    isViewOnlyDueToStatus
} from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import audit_outcomes from "../../../constants/auditOutcomes";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {MonitoringCompliancePolicy} from "../../../components/authorization/policies/MonitoringCompliancePolicy";
import Button from "../../../components/common/buttons/Button";
import GridTable from "../../../components/common/GridTable";
import SubmissionModule from "../../../components/common/SubmissionModule";
import {approvalNeeded, approvalNeededMessage} from "../../../constants/monitoringCompliance/correctionResults";
import {monitoring_compliance} from "../../../constants/policyEvents";

export const StudentCorrectionsSummaryGrid = ({
                                                  clearEditStudentCorrections,
                                                  editProtocolCorrection,
                                                  isLoading,
                                                  studentRecord,
                                                  submitStudentCorrections
                                              }) => {
    const canSubmit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.SUBMIT);
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);
    let isSubmissionModuleEnabled = isSubmissionStudentCorrectionsEnabledDueToStatus(studentRecord.corrections);
    let areCorrectionsSubmitted = true;

    const onEditClick = (event, protocolId) => {
        event.preventDefault();
        editProtocolCorrection(studentRecord.studentRecordId, protocolId);
    };

    const onSubmitClick = () => {
        submitStudentCorrections(studentRecord.studentRecordId);
    };

    const onReturnClick = () => {
        clearEditStudentCorrections();
    };

    const getSubmissionMessage = () => {
        if(!canSubmit)
            return "You do not have access to submit corrections.  If this is an issue, please contact FDOE.";

        if(areCorrectionsSubmitted)
            return `All corrections have been submitted.`;

        if(!isSubmissionModuleEnabled && studentRecord.corrections.find(f => f.status === audit_outcomes.NEEDS_REVISION.value))
            return `All revised corrections for ${studentRecord.name} need to be completed and <em>acknowledged that they are completed</em> before being allowed to be submitted.`;

        if(!isSubmissionModuleEnabled && studentRecord.status === approvalNeeded)
            return approvalNeededMessage;

        if(!isSubmissionModuleEnabled)
            return `All corrections for ${studentRecord.name} need to be completed before being allowed to be submitted.`;

        return `${studentRecord.name} is ready to be submitted.`
    };

    if (!studentRecord || studentRecord.corrections.length === 0)
        return <p>No student specific corrections are needed at this time.</p>;

    return (
        <div>
            <h2>{studentRecord.name}</h2>
            <GridTable>
                <thead>
                <tr>
                    <th className={"text-center"}>Protocol</th>
                    <th className={"text-center"}>Status</th>
                    <th className={"text-center"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {
                    studentRecord.corrections.map((result, index) => {
                        const viewOnlyDueToStatus = isViewOnlyDueToStatus(result.status);
                        const buttonType = viewOnlyDueToStatus || !canEdit ? ButtonTypes.VIEW : ButtonTypes.EDIT;
                        const label = viewOnlyDueToStatus || !canEdit ? "View" : "Edit";
                        if(!viewOnlyDueToStatus)
                            areCorrectionsSubmitted = viewOnlyDueToStatus;

                        return (
                            <tr key={`p_${index}`}>
                                <td className={"text-center"}>{result.protocol}</td>
                                <td className={"text-center"}>{result.status}</td>
                                <td className={"text-center"}>
                                    <Button
                                        buttonType={buttonType}
                                        label={`${label} Student Protocol Correction`}
                                        name={`editButton_${index}`}
                                        onClick={(event) => onEditClick(event, result.protocolId)}
                                        showLabel={false}
                                    />
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </GridTable>
            <SubmissionModule
                buttonBarPosition={ButtonBarPositions.STICKY_BOTTOM}
                handlePrimarySubmit={onSubmitClick}
                hidePrimarySubmissionButton={areCorrectionsSubmitted || !canSubmit}
                name={`btnSubmitCorrectiveReviews`}
                primaryButtonLabel={`Submit Student Corrections`}
                primaryDisabled={!isSubmissionModuleEnabled || isLoading}
                secondaryButtonLabel={`Return to Student List`}
                handleSecondarySubmit={onReturnClick}
                submissionMessage={getSubmissionMessage()}
                submissionTitle={`Submit Completed Corrections for ${studentRecord.name}`}
            />
        </div>
    );
};

StudentCorrectionsSummaryGrid.propTypes = {
    clearEditStudentCorrections: PropTypes.func.isRequired,
    editProtocolCorrection: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    submitStudentCorrections: PropTypes.func.isRequired,
    studentRecord: PropTypes.object
};