import React from 'react';
import PropTypes from 'prop-types';
import GridTable from "../../../../components/common/GridTable";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import Tile from "../../../../components/common/Tiles/Tile";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import ActivitySummaryGridRows from "./ActivitySummaryGridRows";

const ActivitySummaryGrid = ({
                                 capActivities = [],
                                 getCapActivityStatus,
                                 getEditButtonDetails,
                                 handleAdd,
                                 handleDelete,
                                 handleEdit,
                                 handleSubmitResults,
                                 isLoading = false,
                                 protocolCorrectiveActions,
                                 indicatorId,
                                 readOnly = true,
                             }) => {

    const onClickEditActivity = (event, activityId) => {
        event.preventDefault();
        handleEdit(activityId, indicatorId);
    };

    const onClickAddActivity = (event) => {
        event.preventDefault();
        handleAdd(indicatorId);
    };

    const onClickDeleteActivity = (event, activityId) => {
        event.preventDefault();
        if (confirm("Are you sure you want to delete this activity?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            handleDelete(activityId, indicatorId);
    };

    const onClickSubmitActivityResults = (event, activityId, activityResultId) => {
        event.preventDefault();
        if (confirm("Are you sure you want to submit the activity results?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            handleSubmitResults(activityId, activityResultId, indicatorId);
    };

    const getAssociatedProtocols = (activityId) => {
        const protocols = protocolCorrectiveActions.filter(f => f.associatedCapActivityIds.indexOf(activityId) >= 0);
        if (!protocols || protocols.length === 0) return "None";

        const protocolInfo = protocols.reduce(
            (previous, current) => `${previous}<li>${current.publicIdentifier}-${current.standardDisplayNumber}</li>`,
            "",
        );

        return `<ul>${protocolInfo}</ul>`;
    };

    const getActivitySummary = (defaultActivitySummary, activitySummary) => {
        if (isTrimmedStringEmpty(defaultActivitySummary))
            return activitySummary;

        return `${defaultActivitySummary}<p><strong>Additional Details</strong></p>${isTrimmedStringEmpty(activitySummary) ? "<p>None</p>" : activitySummary}`;
    };

    return (
        <GridTable className={`activities-table`}>
            <thead>
            <tr>
                <th>
                    Number
                </th>
                <th className={"text-center"}>
                    Summary
                </th>
                <th>
                    Associated Protocols
                </th>
                <th className={"text-center"}>
                    Status
                </th>
                <th className={"text-center"}>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            <ActivitySummaryGridRows
                capActivities={capActivities}
                getActivitySummary={getActivitySummary}
                getAssociatedProtocols={getAssociatedProtocols}
                getCapActivityStatus={getCapActivityStatus}
                getEditButtonDetails={getEditButtonDetails}
                onClickDeleteActivity={onClickDeleteActivity}
                onClickEditActivity={onClickEditActivity}
                onClickSubmitActivityResults={handleSubmitResults ? onClickSubmitActivityResults : null}
                readOnly={readOnly}
            />
            </tbody>
            {
                handleAdd &&
                <tfoot>
                {
                    !readOnly &&
                    <tr>
                        <td colSpan={5}>
                            <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                                <Tile
                                    body={"Add CAP Activity"}
                                    onClick={!isLoading ? onClickAddActivity : null}
                                    isNew={true}
                                    isMedium={true}
                                />
                            </TileContainer>
                        </td>
                    </tr>
                }
                </tfoot>
            }
        </GridTable>
    );
};

ActivitySummaryGrid.propTypes = {
    capActivities: PropTypes.arrayOf(PropTypes.object),
    getCapActivityStatus: PropTypes.func.isRequired,
    getEditButtonDetails: PropTypes.func.isRequired,
    handleAdd: PropTypes.func,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleSubmitResults: PropTypes.func,
    isLoading: PropTypes.bool,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object),
    indicatorId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
};

export default ActivitySummaryGrid;