import {ADMIN} from "./contexts";

export const VIEW = "view";
export const EDIT = "edit";
export const CERTIFY = "certify";
export const MANAGE = "manage";
export const BPIE_SCHOOL_ADMIN = "bpieSchoolAdmin";

export const UserAssignableBehaviors = [VIEW, EDIT, CERTIFY, MANAGE];

export const UserAssignableBehaviorsToSkip = [`${ADMIN}.${MANAGE}`];