import React from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ActionBarPosition from "../../../constants/ActionBarPositions";
import ActionBarWrapper from "./ActionBarWrapper";
import Allow from "../../authorization/Allow";
import {policyEvents} from "../../authorization/policies/DefaultPolicy";

const ActionBar = ({
	                   onEditClick,
					   onOrderClick,
	                   onInsertClick,
	                   onInsertClick2,
	                   onCloneClick,
	                   onDeleteClick,
	                   onSubmitClick,
	                   position,
	                   isExpanded,
	                   elementId,
	                   buttonType,
	                   buttonLabel,
	                   insertLabel,
	                   insertLabel2,
	                   policy,
	                   editPolicyEvent,
	                   clonePolicyEvent,
	                   deletePolicyEvent,
	                   submitPolicyEvent,
	                   disabled
                   }) => {

	if (!onEditClick &&
		!onOrderClick &&
		!onCloneClick &&
		!onDeleteClick &&
		!onInsertClick &&
		!onInsertClick2 &&
		!onSubmitClick)
		return null;

	return (
		<ActionBarWrapper
			position={position}
			isExpanded={isExpanded}
			buttonType={buttonType}
			buttonLabel={buttonLabel}
			disabled={disabled}
		>
			{
				onInsertClick2 &&
				<Allow policy={policy} policyEvent={editPolicyEvent}>
					<Button
						buttonType={ButtonTypes.INSERT_BEFORE}
						label={insertLabel2}
						showLabel={false}
						name={"btnInsert_" + elementId}
						onClick={onInsertClick2}
						disabled={disabled}/>
				</Allow>
			}
			{
				onInsertClick &&
				<Allow policy={policy} policyEvent={editPolicyEvent}>
					<Button
						buttonType={ButtonTypes.INSERT_AFTER}
						label={insertLabel}
						showLabel={false}
						name={"btnInsert_" + elementId}
						onClick={onInsertClick}
						disabled={disabled}/>
				</Allow>
			}
			{
				onDeleteClick &&
				<Allow policy={policy} policyEvent={deletePolicyEvent}>
					<Button
						buttonType={ButtonTypes.DELETE}
						label="Delete"
						showLabel={false}
						name={"btnDelete_" + elementId}
						onClick={onDeleteClick}
						disabled={disabled}/>
				</Allow>
			}
			{
				onCloneClick &&
				<Allow policy={policy} policyEvent={clonePolicyEvent}>
					<Button
						buttonType={ButtonTypes.CLONE}
						label={"Clone"}
						showLabel={false}
						name={"btnClone_" + elementId}
						onClick={onCloneClick}
						disabled={disabled}/>
				</Allow>
			}
			{
				onEditClick &&
				<Allow policy={policy} policyEvent={editPolicyEvent}>
					<Button
						buttonType={ButtonTypes.EDIT}
						label={"Edit"}
						showLabel={false}
						name={"btnEdit_" + elementId}
						onClick={onEditClick}
						disabled={disabled}/>
				</Allow>
			}
			{
				onOrderClick &&
				<Allow policy={policy} policyEvent={editPolicyEvent}>
					<Button
						buttonType={ButtonTypes.ORDER}
						label={"Order"}
						showLabel={false}
						name={"btnOrder_" + elementId}
						onClick={onOrderClick}
						disabled={disabled}/>
				</Allow>
			}
			{
				onSubmitClick &&
				<Allow policy={policy} policyEvent={submitPolicyEvent}>
					<Button
						buttonType={ButtonTypes.SAVE}
						name={"btnSubmit_" + elementId}
						label={"Submit"}
						showLabel={false}
						onClick={onSubmitClick}
						disabled={disabled}/>
				</Allow>
			}
		</ActionBarWrapper>
	);
};

ActionBar.propTypes = {
	onEditClick: PropTypes.func,
	onOrderClick: PropTypes.func,
	onCloneClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	onInsertClick: PropTypes.func,
	onInsertClick2: PropTypes.func,
	onSubmitClick: PropTypes.func,
	position: PropTypes.string,
	insertLabel: PropTypes.string,
	insertLabel2: PropTypes.string,
	elementId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	buttonType: PropTypes.string,
	buttonLabel: PropTypes.string,
	policy: PropTypes.func,
	editPolicyEvent: PropTypes.string,
	clonePolicyEvent: PropTypes.string,
	deletePolicyEvent: PropTypes.string,
	submitPolicyEvent: PropTypes.string,
	isExpanded: PropTypes.bool
};

ActionBar.defaultProps = {
	position: ActionBarPosition.RIGHT,
	buttonType: ButtonTypes.CONFIGURE,
	editPolicyEvent: policyEvents.VIEW,
	clonePolicyEvent: policyEvents.VIEW,
	deletePolicyEvent: policyEvents.VIEW,
	submitPolicyEvent: policyEvents.VIEW,
	buttonLabel: "Configure",
	insertLabel: "Insert",
	insertLabel2: "Insert",
	isExpanded: false,
	disabled: false
};

export default ActionBar;