import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {isArray, isString, isObject, convertToNumber, isTrimmedStringEmpty, validateDecimal, isArrayNullOrEmpty}
    from "../../../components/common/commonUtilities";
import {ELEMENT_TYPE} from "../gmsConstants";
import {getResponseObject, isCalculatedElementType, getCalculatedElementValue, allowsResponse} from "../gmsUtilities";
import {GmsApplicationFormElement} from "./ApplicationForm/GmsApplicationFormElement";
import {GmsApplicationPrintElement} from "./ApplicationPrint/GmsApplicationPrintElement";

export const GmsApplicationElementWrapper = (props) => {
    const {
        element,
        isDirty,
        isPrint = false,
        responses = {},
    } = props;

    const buildElementDetails = () => {
        if (!element) return null;

        const elementValue = element.elementValue && isString(element.elementValue) ? JSON.parse(element.elementValue) : "";

        const options = isArray(elementValue) || isObject(elementValue) ? elementValue : undefined;

        const isCalculated = isCalculatedElementType(element.elementType) && !!options;

        const response = getResponseObject(responses[element.elementId]);

        const isChecked = !!response.value;

        return {
            isCalculated,
            isChecked,
            options,
            response,
        };
    };

    const shouldAllowResponse = allowsResponse(element, responses);

    useEffect(() => {
        if (isPrint) return;

        const elementDetails = buildElementDetails();
        const {isCalculated, options, response} = elementDetails;

        const isDollarElementType = element.elementType === ELEMENT_TYPE.dollarTextbox;
        const dollarDefaultValue = validateDecimal(0);
        const hasValue = isArray(response.value) ? !isArrayNullOrEmpty(response.value) : !isTrimmedStringEmpty(response.value);
        const needsToBeCleared = isDollarElementType ? response.value !== dollarDefaultValue : hasValue;
        if (!shouldAllowResponse && needsToBeCleared) {
            const clearedValue = isDollarElementType ? dollarDefaultValue : "";
            response.setValue(clearedValue);
            return;
        }

        if (!elementDetails || !isCalculated) return;

        let calculatedValue = getCalculatedElementValue(options, responses);

        const isEmptyCalculatedValue = response.value === "" && isDirty;

        const doesCalculatedValueDifferFromCurrentValue = convertToNumber(response.value)
            !== convertToNumber(calculatedValue);

        if (doesCalculatedValueDifferFromCurrentValue || isEmptyCalculatedValue)
            response.setValue(calculatedValue);

    }, [responses, element]);

    if (!element) return null;

    const elementDetails = buildElementDetails();

    const {isCalculated, isChecked, options, response} = elementDetails;

    return isPrint
        ? <GmsApplicationPrintElement
            {...props}
            isChecked={isChecked}
            response={response.value}
        />
        : <GmsApplicationFormElement
            {...props}
            isCalculated={isCalculated}
            isChecked={isChecked}
            isDisabled={!shouldAllowResponse}
            options={options}
            response={response}
        />;
};

GmsApplicationElementWrapper.propTypes = {
    actions: PropTypes.object,
    applicationHistories: PropTypes.array,
    applicationId: PropTypes.string,
    canUserEditApplication: PropTypes.bool,
    element: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    isDirty: PropTypes.bool,
    isFiscalAgentUser: PropTypes.bool,
    isPrint: PropTypes.bool.isRequired,
    nextAmendmentNumber: PropTypes.number,
    parentId: PropTypes.string,
    programName: PropTypes.string,
    responses: PropTypes.object.isRequired,
    sectionId: PropTypes.string,
    selectedApplicationHistory: PropTypes.string,
    setSelectedApplicationHistory: PropTypes.func,
    targetedAllocation: PropTypes.number,
};