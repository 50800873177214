import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {INSTITUTION_TYPES} from "../../../constants/sharedData/institutionTypes";
import {APPLICATION_STATUS_LIST, APPLICATION_TYPE_LIST} from "../../../containers/gms/gmsConstants";
import {DISCRETIONARY_PROJECT_STATUS_LIST} from "../../../containers/pts/ptsConstants";
import Button from "../buttons/Button";
import ButtonBar from "../buttons/ButtonBar";
import {
    dynamicSort,
    isArray,
    isArrayNullOrEmpty,
    generateSelectListFromArray, isTrimmedStringEmpty, isNullOrUndefined
} from "../commonUtilities";
import GridRow from "../GridRow";
import GridColumn from "../GridColumn";
import DateSelectField from "../inputs/DateSelectField";
import SelectField from "../inputs/SelectField";
import SearchableMultiSelectField from "../inputs/SearchableMultiSelectField";
import SearchableSingleSelectField from "../inputs/SearchableSingleSelectField";
import TextField from "../inputs/TextField";
import {SearchFilterElements} from "./searchCriteria";
import LocalStorage from "../../shared/LocalStorage";
import {createDistinctTeamMemberNameList} from "../../users/teamMemberObjectFactory";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {RecordsPerPageList} from "../../../constants/searchOptions";
import SectionHeader from "../SectionHeader";
import {MONITORING_COMPLIANCE} from "../../../constants/contexts";

// DR Imports
import {DrFilterElements} from "./DrSearchCriteria";
import {
    BuildDisputeResolutionTypeList,
    DisputeResolutionType,
    DrFiltersComplaintStatusList,
    DrRolesList, RadioButtonYesNoList, UserStatusList,
    DueProcessCaseResearchStatusList, MediationCaseResearchStatusList, StateComplaintCaseResearchStatusList,
    EnrollmentLocationList
} from "../../../DisputeResolution/drConstants";
import {
    DrFilterDueProcessStatusList,
    DueProcessComplaintTypesList,
    ResolutionSessionOutcomeList,
    PrevailingPartyList,
    ParentRepresentationStatusList,
    FinalOrderIssuesList
} from "../../../DisputeResolution/DueProcesses/drDueProcessConstants";
import {DrFilterMediationStatusList} from "../../../DisputeResolution/Mediations/drMediationConstants";
import {OutcomeFindings} from "../../../DisputeResolution/StateComplaints/drStateComplaintConstants";
import api from "../../../DisputeResolution/DueProcesses/drDueProcessApi";

// MC Imports
import {
    ComplianceMonitoringNonComplianceStatusList,
    ComplianceMonitoringOverallStatusList
} from "../../../constants/monitoringCompliance/statusValues";
import {McFilterElements} from "./MonitoringComplianceDistrictSearchCriteria";

// BPIE Imports
import {BpieFilterElements} from "./BpieSearchCriteria";

// GMS Imports
import {GmsFilterElements} from "./GmsSearchCriteria";

// PTS Imports
import {PtsFilterElements} from "./PtsSearchCriteria";

export const FilterView = ({
                               context,
                               dateWindows,
                               handleExport,
                               handleSearchFiltersChange,
                               numberOfFilterColumns = 0,
                               searchCriteria
                           }) => {
    const [teamMemberRoles, setTeamMemberRoles] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [judges, setJudges] = useState([]);

    const handleExportClick = (event) => {
        event.preventDefault();
        handleExport(searchCriteria);
    };

    const onSelectDateChange = (dateRangeType, startDate, endDate) => {
        let criteria = {...searchCriteria};
        criteria[SearchFilterElements.dateRange] = dateRangeType;
        criteria[SearchFilterElements.startDate] = startDate;
        criteria[SearchFilterElements.endDate] = endDate;
        handleSearchFiltersChange(criteria, false);
    };

    const handleChangeForm = (event) => {
        const {id, value} = event.target;
        let criteria = {...searchCriteria};
        criteria[id] = value;

        if (id === DrFilterElements.teamMember && searchCriteria.isVisible(DrFilterElements.teamMemberRole)) {
            criteria[DrFilterElements.teamMemberRole] = "";
            updateTeamMemberRoles(value);
        }

        if (id === DrFilterElements.teamMemberMulti && searchCriteria.isVisible(DrFilterElements.teamMemberRoleMulti)) {
            criteria[DrFilterElements.teamMemberRoleMulti] = [];
            updateTeamMemberRoles(value);
        }

        if (id === DrFilterElements.disputeResolutionTypeWithDefault) {
            criteria[DrFilterElements.disputeResolutionType] = isTrimmedStringEmpty(value)
                ? criteria[DrFilterElements.disputeResolutionType]
                : value;
        }

        if (id === DrFilterElements.disputeResolutionType) {
            criteria[DrFilterElements.disputeResolutionTypeWithDefault] = value;
        }

        const reload = criteria.ShouldReloadSearch(id);

        if (reload) {
            criteria.sourceParams = {
                ...criteria.UpdateSourceParams(),
            };
        }

        handleSearchFiltersChange(criteria, reload);
    };

    const updateTeamMemberRoles = (selectedTeamMember) => {
        let list = [];
        if (!isArray(selectedTeamMember))
            selectedTeamMember = [selectedTeamMember];
        searchCriteria[DrFilterElements.teamMembers].filter(f => selectedTeamMember.indexOf(f.userId) > -1).map((user) => {
            const roleItem = DrRolesList.find(r => r.value === user.role);
            if (roleItem && !list.find(f => f.value === roleItem.value))
                list.push(roleItem);
        });

        list = list.sort(dynamicSort("text"));

        setTeamMemberRoles(list);
    };

    const getStatusOptions = () => {
        if (context === MONITORING_COMPLIANCE)
            setStatusOptions(ComplianceMonitoringOverallStatusList);

        if (!searchCriteria.isVisible(DrFilterElements.disputeResolutionType))
            return;

        switch (searchCriteria[DrFilterElements.disputeResolutionType]) {
            case DisputeResolutionType.StateComplaints:
                setStatusOptions(DrFiltersComplaintStatusList);
                break;
            case DisputeResolutionType.DueProcess:
                setStatusOptions(DrFilterDueProcessStatusList);
                break;
            case DisputeResolutionType.Mediation:
                setStatusOptions(DrFilterMediationStatusList);
                break;
        }
    };

    const handleClearFilterClick = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.clearOptionalSearchItems(false);
        criteria.sourceParams = {
            ...criteria.UpdateSourceParams(),
        };
        handleSearchFiltersChange(criteria, criteria.allowAllDateWindows);
    };

    useEffect(() => {
        if(!searchCriteria.statusSingle || !searchCriteria.statusSingleOptions || searchCriteria.statusSingleOptions.length === 0)
            return;

        if(!searchCriteria.statusSingleOptions.find(f => f.value.toString() === searchCriteria.statusSingle.toString())) {
            let criteria = {...searchCriteria};
            criteria.statusSingle = "";
            handleSearchFiltersChange(criteria, criteria.allowAllDateWindows);
        }
    }, [searchCriteria.statusSingle, searchCriteria.statusSingleOptions]);

    useEffect(() => {
        getStatusOptions();
    }, [searchCriteria[DrFilterElements.disputeResolutionType]]);

    useEffect(() => {
        if (searchCriteria[DrFilterElements.teamMember] && searchCriteria.isVisible(DrFilterElements.teamMemberRole))
            updateTeamMemberRoles(searchCriteria[DrFilterElements.teamMember]);
    }, [searchCriteria.currentVisibilityRulesName, searchCriteria[DrFilterElements.teamMember], searchCriteria.teamMembers]);

    useEffect(() => {
        if (searchCriteria[DrFilterElements.disputeResolutionType] === DisputeResolutionType.DueProcess
            && searchCriteria.isVisible(DrFilterElements.assignedALJ)
            && isArrayNullOrEmpty(judges))
            api.getJudges()
                .then((result) => setJudges(generateSelectListFromArray(result, judges)));
    }, [searchCriteria[DrFilterElements.disputeResolutionType]]);

    const numberOfColumns = numberOfFilterColumns > 0 ? numberOfFilterColumns
        : searchCriteria.visibilityRules?.length <= 4
            ? searchCriteria.visibilityRules?.length > 1 ? searchCriteria.visibilityRules.length : 2
            : 4;

    const setVariableStatus = () => {
        if (context === MONITORING_COMPLIANCE)
            return generateSelectListFromArray(ComplianceMonitoringOverallStatusList);

        switch (searchCriteria[DrFilterElements.disputeResolutionType]) {
            case DisputeResolutionType.StateComplaints:
                return StateComplaintCaseResearchStatusList;

            case DisputeResolutionType.DueProcess:
                return DueProcessCaseResearchStatusList;

            case DisputeResolutionType.Mediation:
                return MediationCaseResearchStatusList;
        }
    };

    const containsDiscretionaryAgents = searchCriteria.allDistricts.some(f => f.institutionType === INSTITUTION_TYPES.discretionaryAgency.id);
    return (
        <>
            <SectionHeader>
                <p>Filter</p>
            </SectionHeader>

            <GridRow rowClass="filterBar" medium={numberOfColumns}>
                {
                    searchCriteria.isVisible(SearchFilterElements.dateWindowId) &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={searchCriteria.allowAllDateWindows}
                            defaultOption={"All Date Windows"}
                            label="Date Window"
                            name={SearchFilterElements.dateWindowId}
                            onChange={handleChangeForm}
                            options={dateWindows}
                            showLabel
                            value={searchCriteria.dateWindowId}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.disputeResolutionType) &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={false}
                            label="Type"
                            name={DrFilterElements.disputeResolutionType}
                            onChange={handleChangeForm}
                            options={BuildDisputeResolutionTypeList(LocalStorage.getBehaviorClaims())}
                            showLabel
                            value={searchCriteria.disputeResolutionType}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(GmsFilterElements.applicationTypeDescription) &&
                    <GridColumn>
                        <SelectField
                            label="Type"
                            defaultOption={"All Types"}
                            name={GmsFilterElements.applicationTypeDescription}
                            onChange={handleChangeForm}
                            options={APPLICATION_TYPE_LIST}
                            showLabel
                            value={searchCriteria.applicationTypeDescription}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(GmsFilterElements.applicationStatusDescription) &&
                    <GridColumn>
                        <SelectField
                            label="Status"
                            defaultOption={"All Statuses"}
                            name={GmsFilterElements.applicationStatusDescription}
                            onChange={handleChangeForm}
                            options={APPLICATION_STATUS_LIST}
                            showLabel
                            value={searchCriteria.applicationStatusDescription}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.disputeResolutionTypeWithDefault) &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={true}
                            defaultOption={"All"}
                            label="Type"
                            name={DrFilterElements.disputeResolutionTypeWithDefault}
                            onChange={handleChangeForm}
                            options={BuildDisputeResolutionTypeList(LocalStorage.getBehaviorClaims())}
                            showLabel
                            value={searchCriteria.disputeResolutionTypeWithDefault}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(McFilterElements.indicatorType) &&
                    searchCriteria.allIndicators.length > 0 &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={false}
                            label="Type"
                            name={McFilterElements.indicatorType}
                            onChange={handleChangeForm}
                            options={searchCriteria.allIndicators}
                            showLabel
                            value={searchCriteria.indicatorType}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.schoolBpieId) &&
                    searchCriteria.allSchoolBpies.length > 0 &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={false}
                            label="SBPIE Assessment"
                            name={BpieFilterElements.schoolBpieId}
                            onChange={handleChangeForm}
                            options={searchCriteria.allSchoolBpies}
                            showLabel
                            value={searchCriteria.schoolBpieId}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.districtBpieId) &&
                    searchCriteria.allDistrictBpies.length > 0 &&
                    <GridColumn>
                        <SelectField
                            includeDefaultOption={false}
                            label="DBPIE Assessment"
                            name={BpieFilterElements.districtBpieId}
                            onChange={handleChangeForm}
                            options={searchCriteria.allDistrictBpies}
                            showLabel
                            value={searchCriteria.districtBpieId}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.caseNumber) &&
                    <GridColumn>
                        <TextField
                            label={"Case Number"}
                            name={DrFilterElements.caseNumber}
                            onChange={handleChangeForm}
                            showLabel
                            value={searchCriteria.caseNumber}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.textSearch) &&
                    searchCriteria.textSearchLabel &&
                    <GridColumn>
                        <TextField
                            label={searchCriteria.textSearchLabel}
                            name={SearchFilterElements.textSearch}
                            onChange={handleChangeForm}
                            showLabel
                            value={searchCriteria.textSearch}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.principalInfo) &&
                    <GridColumn>
                        <TextField
                            label={`Principal Name or Email`}
                            name={BpieFilterElements.principalInfo}
                            onChange={handleChangeForm}
                            showLabel
                            value={searchCriteria.principalInfo}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.dateRange) &&
                    <GridColumn>
                        <DateSelectField
                            dateRangeType={searchCriteria.dateRange}
                            defaultOption={`Include All Tasks`}
                            endDate={searchCriteria.endDate}
                            includeDefaultOption={searchCriteria.includeDefaultOptionDateRange}
                            label={`Date Range`}
                            name={SearchFilterElements.dateRange}
                            onSelectDate={onSelectDateChange}
                            optionsToUse={searchCriteria.dateRangeOptionsToUse}
                            showLabel
                            startDate={searchCriteria.startDate}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.dateRangeCustomOnly) &&
                    <DateSelectField
                        customOnly
                        dateRangeType={searchCriteria.dateRange}
                        endDate={searchCriteria.endDate}
                        includeDefaultOption={false}
                        label={`Date Range`}
                        name={SearchFilterElements.dateRangeCustomOnly}
                        onSelectDate={onSelectDateChange}
                        showLabel
                        startDate={searchCriteria.startDate}
                    />
                }
                {
                    searchCriteria.isVisible(PtsFilterElements.programName) &&
                    searchCriteria.allPrograms.length > 0 &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All Programs`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`Programs`}
                            name={PtsFilterElements.programName}
                            onChange={handleChangeForm}
                            options={searchCriteria.allPrograms}
                            value={searchCriteria.programName}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.district) &&
                    searchCriteria.allDistricts.length > 0 &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={containsDiscretionaryAgents ? `All Districts or Institutions`: `All Districts`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`District`}
                            name={SearchFilterElements.district}
                            onChange={handleChangeForm}
                            options={searchCriteria.allDistricts}
                            value={searchCriteria.district}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.fiscalAgent) &&
                    searchCriteria.allFiscalAgents.length > 0 &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All Fiscal Agents`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`Fiscal Agent`}
                            name={SearchFilterElements.fiscalAgent}
                            onChange={handleChangeForm}
                            options={searchCriteria.allFiscalAgents}
                            value={searchCriteria.fiscalAgent}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(GmsFilterElements.projectName) &&
                    searchCriteria.allProjects.length > 0 &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All Projects`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`Projects`}
                            name={GmsFilterElements.projectName}
                            onChange={handleChangeForm}
                            options={searchCriteria.allProjects}
                            value={searchCriteria.projectName}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(PtsFilterElements.discretionaryProjectStatusDescription) &&
                    <GridColumn>
                        <SelectField
                            label="Status"
                            defaultOption={"All Statuses"}
                            name={PtsFilterElements.discretionaryProjectStatusDescription}
                            onChange={handleChangeForm}
                            options={DISCRETIONARY_PROJECT_STATUS_LIST}
                            showLabel
                            value={searchCriteria.discretionaryProjectStatusDescription}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.schoolNumber) &&
                    !isNullOrUndefined(searchCriteria.allSchools) &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All Schools`}
                            disabled={!searchCriteria.district}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`Schools (MSID)`}
                            name={BpieFilterElements.schoolNumber}
                            onChange={handleChangeForm}
                            options={searchCriteria.allSchools}
                            value={searchCriteria.schoolNumber}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.dbpieFinUser) &&
                    !isNullOrUndefined(searchCriteria.finUsers) &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`FIN Facilitator/Admin`}
                            name={BpieFilterElements.dbpieFinUser}
                            onChange={handleChangeForm}
                            options={searchCriteria.finUsers}
                            value={searchCriteria.dbpieFinUser}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(BpieFilterElements.sbpieFinFacilitator) &&
                    !isNullOrUndefined(searchCriteria.finUsers) &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All`}
                            includeDefaultOption={true}
                            includePlaceHolder={false}
                            label={`FIN Facilitator`}
                            name={BpieFilterElements.sbpieFinFacilitator}
                            onChange={handleChangeForm}
                            options={searchCriteria.finUsers}
                            value={searchCriteria.sbpieFinFacilitator}
                        />
                    </GridColumn>
                }

                {
                    searchCriteria.isVisible(SearchFilterElements.status) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Status Options`}
                            label="Status"
                            name={SearchFilterElements.status}
                            onChange={handleChangeForm}
                            options={statusOptions}
                            showLabel
                            value={searchCriteria.status}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.statusSingle) &&
                    searchCriteria.statusSingleOptions.length > 0 &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Status Options`}
                            label="Status"
                            name={SearchFilterElements.statusSingle}
                            onChange={handleChangeForm}
                            options={searchCriteria.statusSingleOptions}
                            showLabel
                            value={searchCriteria.statusSingle}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.statusMulti) &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            label={`Status`}
                            name={DrFilterElements.statusMulti}
                            onChange={handleChangeForm}
                            options={setVariableStatus()}
                            placeHolder={`All Status Options`}
                            showLabel
                            value={searchCriteria.statusMulti}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(McFilterElements.nonComplianceStatus) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Status Options`}
                            label="Non-Compliance Status"
                            name={McFilterElements.nonComplianceStatus}
                            onChange={handleChangeForm}
                            options={ComplianceMonitoringNonComplianceStatusList}
                            showLabel
                            value={searchCriteria.nonComplianceStatus}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.userStatus) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Status Options`}
                            label="Status"
                            name={DrFilterElements.userStatus}
                            onChange={handleChangeForm}
                            options={UserStatusList}
                            showLabel
                            value={searchCriteria.userStatus}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.outcomeFindings) &&
                    searchCriteria.disputeResolutionType === DisputeResolutionType.StateComplaints &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            defaultOption={`All Outcomes`}
                            includeDefaultOption
                            includePlaceHolder={false}
                            label="Outcome"
                            name={DrFilterElements.outcomeFindings}
                            onChange={handleChangeForm}
                            options={OutcomeFindings}
                            showLabel
                            value={searchCriteria.outcomeFindings}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.outcomeFindingsMulti) &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            label="Outcome"
                            name={DrFilterElements.outcomeFindingsMulti}
                            onChange={handleChangeForm}
                            options={OutcomeFindings}
                            placeHolder={`All Outcomes`}
                            showLabel
                            value={searchCriteria.outcomeFindingsMulti}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.teamMember) &&
                    searchCriteria.teamMembers &&
                    searchCriteria.teamMembers.length > 0 &&
                    <>
                        <GridColumn>
                            <SearchableSingleSelectField
                                defaultOption={`All Team Members`}
                                includeDefaultOption
                                includePlaceHolder={false}
                                label="Team Member"
                                name={SearchFilterElements.teamMember}
                                onChange={handleChangeForm}
                                options={createDistinctTeamMemberNameList(searchCriteria.teamMembers)}
                                showLabel
                                value={searchCriteria.teamMember}
                            />
                        </GridColumn>
                        {
                            searchCriteria.isVisible(DrFilterElements.teamMemberRole) &&
                            <GridColumn>
                                <SearchableSingleSelectField
                                    defaultOption={`All Roles`}
                                    disabled={!searchCriteria.teamMember}
                                    includeDefaultOption
                                    includePlaceHolder={false}
                                    label="Role"
                                    name={DrFilterElements.teamMemberRole}
                                    onChange={handleChangeForm}
                                    options={teamMemberRoles}
                                    showLabel
                                    value={searchCriteria.teamMemberRole}
                                />
                            </GridColumn>
                        }
                    </>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.teamMemberMulti) &&
                    searchCriteria.teamMembers &&
                    searchCriteria.teamMembers.length > 0 &&
                    <>
                        <GridColumn>
                            <SearchableMultiSelectField
                                includePlaceHolder
                                label="Team Member"
                                name={DrFilterElements.teamMemberMulti}
                                onChange={handleChangeForm}
                                options={createDistinctTeamMemberNameList(searchCriteria.teamMembers)}
                                placeHolder={`All Team Members`}
                                showLabel
                                value={searchCriteria.teamMemberMulti}
                            />
                        </GridColumn>
                        {
                            searchCriteria.isVisible(DrFilterElements.teamMemberRoleMulti) &&
                            <GridColumn>
                                <SearchableMultiSelectField
                                    disabled={isArrayNullOrEmpty(searchCriteria.teamMemberMulti)}
                                    includePlaceHolder
                                    label="Role"
                                    name={DrFilterElements.teamMemberRoleMulti}
                                    onChange={handleChangeForm}
                                    options={teamMemberRoles}
                                    placeHolder={`All Roles`}
                                    showLabel
                                    value={searchCriteria.teamMemberRoleMulti}
                                />
                            </GridColumn>
                        }
                    </>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.districtMulti) &&
                    searchCriteria.allDistricts.length > 0 &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            label={containsDiscretionaryAgents ? `District or Institution` : `District`}
                            name={DrFilterElements.districtMulti}
                            onChange={handleChangeForm}
                            options={searchCriteria.allDistricts}
                            placeHolder={`All Districts`}
                            value={searchCriteria.districtMulti}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.itemsToPin &&
                    searchCriteria.itemsToPin.length > 0 &&
                    searchCriteria.isVisible(DrFilterElements.pinItem) &&
                    <GridColumn>
                        <SearchableSingleSelectField
                            includePlaceHolder
                            label="Pin"
                            name={DrFilterElements.pinItem}
                            onChange={handleChangeForm}
                            options={searchCriteria.itemsToPin}
                            showLabel
                            value={searchCriteria.pinItem}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.issueCodes &&
                    searchCriteria.issueCodes.length > 0 &&
                    searchCriteria.isVisible(DrFilterElements.issuesCodeMulti) &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            label="Issue Code"
                            name={DrFilterElements.issuesCodeMulti}
                            onChange={handleChangeForm}
                            options={searchCriteria.issueCodes}
                            placeHolder={`All Issue Codes`}
                            showLabel
                            value={searchCriteria.issuesCodeMulti}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.citations &&
                    searchCriteria.citations.length > 0 &&
                    searchCriteria.isVisible(DrFilterElements.citationMulti) &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            label="Citation"
                            name={DrFilterElements.citationMulti}
                            onChange={handleChangeForm}
                            options={searchCriteria.citations}
                            placeHolder={`All Citations`}
                            showLabel
                            value={searchCriteria.citationMulti}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.advocateAttorney) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Advocates/Attorneys`}
                            label="Advocate/Attorney"
                            name={DrFilterElements.advocateAttorney}
                            onChange={handleChangeForm}
                            options={RadioButtonYesNoList}
                            showLabel
                            value={searchCriteria.advocateAttorney}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.enrollmentLocation) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Enrollment Locations`}
                            label="Enrollment Location"
                            name={DrFilterElements.enrollmentLocation}
                            onChange={handleChangeForm}
                            options={EnrollmentLocationList}
                            showLabel
                            value={searchCriteria.enrollmentLocation}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.systemic) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Issue Types`}
                            label="Systemic"
                            name={DrFilterElements.systemic}
                            onChange={handleChangeForm}
                            options={RadioButtonYesNoList}
                            showLabel
                            value={searchCriteria.systemic}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.tiedToDueProcessMediation) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Complaints`}
                            label="Tied To Due Process Hearing Requests/Mediation"
                            name={DrFilterElements.tiedToDueProcessMediation}
                            onChange={handleChangeForm}
                            options={RadioButtonYesNoList}
                            showLabel
                            value={searchCriteria.tiedToDueProcessMediation}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.complaintType) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Complaint Types`}
                            label="Complaint Type"
                            name={DrFilterElements.complaintType}
                            onChange={handleChangeForm}
                            options={DueProcessComplaintTypesList}
                            showLabel
                            value={searchCriteria.complaintType}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.assignedALJ) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Judges`}
                            label="Assigned Administrative Law Judge"
                            name={DrFilterElements.assignedALJ}
                            onChange={handleChangeForm}
                            options={judges}
                            showLabel
                            value={searchCriteria.assignedALJ}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.resolutionSessionOutcome) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Resolution Session Outcomes`}
                            label="Resolution Session Outcome"
                            name={DrFilterElements.resolutionSessionOutcome}
                            onChange={handleChangeForm}
                            options={ResolutionSessionOutcomeList}
                            showLabel
                            value={searchCriteria.resolutionSessionOutcome}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.changeOfPlacement) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Ordered Actions`}
                            label="Change of Placement Ordered"
                            name={DrFilterElements.changeOfPlacement}
                            onChange={handleChangeForm}
                            options={RadioButtonYesNoList}
                            showLabel
                            value={searchCriteria.changeOfPlacement}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.prevailingParty) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Prevailing Parties`}
                            label="Prevailing Party"
                            name={DrFilterElements.prevailingParty}
                            onChange={handleChangeForm}
                            options={PrevailingPartyList}
                            showLabel
                            value={searchCriteria.prevailingParty}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.parentRepresentationStatus) &&
                    <GridColumn>
                        <SelectField
                            defaultOption={`All Parent Representation Statuses`}
                            label="Parent Representation Status"
                            name={DrFilterElements.parentRepresentationStatus}
                            onChange={handleChangeForm}
                            options={ParentRepresentationStatusList}
                            showLabel
                            value={searchCriteria.parentRepresentationStatus}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(DrFilterElements.finalOrderIssue) &&
                    <GridColumn>
                        <SearchableMultiSelectField
                            includePlaceHolder
                            placeHolder={`All Issues`}
                            label="Issues"
                            name={DrFilterElements.finalOrderIssue}
                            onChange={handleChangeForm}
                            options={FinalOrderIssuesList}
                            showLabel
                            value={searchCriteria.finalOrderIssue}
                        />
                    </GridColumn>
                }
                {
                    searchCriteria.isVisible(SearchFilterElements.RecordsPerPage) &&
                    <GridColumn>
                        <SelectField
                            label="Records Per Page"
                            name={SearchFilterElements.RecordsPerPage}
                            onChange={handleChangeForm}
                            options={RecordsPerPageList}
                            showLabel
                            value={searchCriteria.RecordsPerPage}
                            includeDefaultOption={false}
                        />
                    </GridColumn>
                }
                {
                    (searchCriteria.isVisible(SearchFilterElements.clearButton) || handleExport) &&
                    <ButtonBar position={""}>
                        {
                            handleExport &&
                            <Button name={`btnExport`}
                                    label={`Export`}
                                    buttonType={ButtonTypes.DOWNLOAD}
                                    onClick={handleExportClick}
                                    btnClass={`l-float-right`}
                            />
                        }
                        {
                            searchCriteria.isVisible(SearchFilterElements.clearButton) &&
                            <Button name={`btnClearFilter`}
                                    label={`Clear Filters`}
                                    buttonType={ButtonTypes.CANCEL}
                                    onClick={handleClearFilterClick}
                                    btnClass={`l-float-right`}
                            />
                        }
                    </ButtonBar>
                }
            </GridRow>
        </>
    );
};

export const FilterViewPropTypes = {
    dateWindows: PropTypes.array,
    handleExport: PropTypes.func,
    handleSearchFiltersChange: PropTypes.func.isRequired,
    numberOfFilterColumns: PropTypes.number,
    searchCriteria: PropTypes.object.isRequired
};

FilterView.propTypes = {
    ...FilterViewPropTypes,
    context: PropTypes.string.isRequired
};