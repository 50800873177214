import React from "react";
import PropTypes from 'prop-types';
import DisplayField from "../../../components/common/inputs/DisplayField";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";

const DrStateComplaintResponseComponentDisplay = ({
	                                                  response
                                                  }) => {

	return (
		<>
			{
				<DisplayField
					label={`Corrected`}
					name={`corrected`}
					showLabel
				>
					{response.dateCorrected ? "Yes" : "No"}
				</DisplayField>
			}
			{
				!!response.dateCorrected &&
				<DisplayField
					label="Date Corrected"
					name={`dateCorrected${response.id}`}
					showLabel
				>
					{outputStandardDateFormat(response.dateCorrected, false)}
				</DisplayField>
			}
			{
				!isArrayNullOrEmpty(response.corrections) &&
				<DisplayField
					name="corrections"
					label="Corrections"
					showLabel
				>
					<ul>
						{
							response.corrections.map((correction, key) =>
								<li key={key}><HtmlDisplayBlock html={correction}/></li>)
						}
					</ul>
				</DisplayField>
			}
		</>
	);
};

DrStateComplaintResponseComponentDisplay.propTypes = {
	response: PropTypes.object.isRequired
};

export default DrStateComplaintResponseComponentDisplay;