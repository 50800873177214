import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {catchError} from "../../actions/actionUtility";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {NotifyUser} from "../../components/common/NotifyUser";
import * as classHooks from "../../constants/sharedData/classHooks";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonIcon from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import InstitutionForm from "../../components/admin/InstitutionForm";
import GridTable from "../../components/common/GridTable";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import {
	INSTITUTION_TYPE_DESCRIPTION,
	INSTITUTION_TYPE_FORM_NAME,
	INSTITUTION_TYPES_LIST
} from "../../constants/sharedData/institutionTypes";

class InstitutionPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onCreateInstitution = this.onCreateInstitution.bind(this);
		this.onEditInstitution = this.onEditInstitution.bind(this);
		this.onChangeInstitution = this.onChangeInstitution.bind(this);
		this.onSaveInstitution = this.onSaveInstitution.bind(this);
		this.onDeleteInstitution = this.onDeleteInstitution.bind(this);
		this.onCancelInstitution = this.onCancelInstitution.bind(this);
		this.isValidInstitution = this.isValidInstitution.bind(this);
	}

	componentDidMount() {
	    this.props.actions.loadInstitutions();
	}

	onCreateInstitution(event) {
		event.preventDefault();

		this.props.actions.createInstitution();
		this.props.actions.enterEditMode();
	}

	onEditInstitution(event, institutionId) {
		event.preventDefault();

		this.props.actions.enterEditMode(institutionId);
	}

	onChangeInstitution(event, institutionId) {
		event.preventDefault();

		const changedInstitution = Object.assign(
			{},
			this.props.institutions.find(i => i.id === institutionId));

		if(event.target.name === INSTITUTION_TYPE_FORM_NAME) {
			const institutionType = parseInt(event.target.value);
			const newInstitutionType = INSTITUTION_TYPES_LIST.find(f => f.id === institutionType);
			changedInstitution[INSTITUTION_TYPE_FORM_NAME] = institutionType;
			changedInstitution[INSTITUTION_TYPE_DESCRIPTION] = newInstitutionType.text;
		}
		else
			changedInstitution[event.target.name] = event.target.value;

		this.props.actions.changeInstitution(changedInstitution);
	}

	onSaveInstitution(event, institutionId) {
		event.preventDefault();

		const savedInstitution = this.props.institutions.find(i => i.id === institutionId);

		if (this.isValidInstitution(savedInstitution)) {
			if (this.props.editItemId === "")
				this.props.actions.addInstitution(savedInstitution)
					.then(() => {
						this.props.actions.leaveEditMode();
					})
					.catch(errors => catchError(errors));
			else
				this.props.actions.updateInstitution(savedInstitution)
					.then(() => {
						this.props.actions.leaveEditMode();
					})
					.catch(errors => catchError(errors));
		}
	}

	isValidInstitution(institution) {
		let isValid = true;

		if (institution.enrollmentSize === "") {
			isValid = false;
			NotifyUser.Warning("You need an enrollment size.");
		}

		if (institution.type === "") {
			isValid = false;
			NotifyUser.Warning("You need a type.");
		}

		if (institution.name === "") {
			isValid = false;
			NotifyUser.Warning("You need a name.");
		}

		if (institution.edwId === "") {
			isValid = false;
			NotifyUser.Warning("You need a number.");
		}

		return isValid;
	}

	onDeleteInstitution(event, institutionId) {
		event.preventDefault();

		if (confirm("You better be suuuuper sure you want to do this, friend. Cause if you delete this institution and you don't mean to it's going to get real awkward real fast. Just saying. You sure about this?")) {
			const deletedInstitution = this.props.institutions.filter(i => i.id === institutionId)[0];
			this.props.actions.deleteInstitution(deletedInstitution);
		}
	}

	onCancelInstitution(event) {
		event.preventDefault();

		this.props.actions.loadInstitutions();
		this.props.actions.leaveEditMode();
	}

	render() {
		if (this.props.institutions.length === 0)
			return null;

		return (
			<GridTable>
			<>
				<thead>
				<tr>
					<th className="text-center">Number</th>
					<th>Name</th>
					<th>Type</th>
					{
						this.props.canEdit &&
						<th className="text-center">Actions</th>
					}
				</tr>
				</thead>
				<tbody>
				{
					this.props.institutions.map(institution => {
						if (institution.id === this.props.editItemId)
							return (<InstitutionForm key={institution.id}
							                         institution={institution}
							                         isLoading={this.props.isLoading}
							                         onCancelInstitution={this.onCancelInstitution}
							                         onChangeInstitution={(event) => this.onChangeInstitution(event, institution.id)}
							                         onSaveInstitution={(event) => this.onSaveInstitution(event, institution.id)}/>);

						return (
							<tr key={institution.id} id={institution.id} className={classHooks.INSTITUTION}>
								<td className="text-center">{institution.edwId}</td>
								<td>{institution.name}</td>
								<td>{institution[INSTITUTION_TYPE_DESCRIPTION]}</td>
								{
									this.props.canEdit &&
									<td className="button-cell">
										{
											<ButtonBar position={ButtonBarPositions.TABLE}>
												<ButtonIcon name={"edit|" + institution.id}
															label={"edit"}
															onClick={(event) => this.onEditInstitution(event, institution.id)}
															buttonType={ButtonTypes.EDIT}
															showLabel={false}
															disabled={this.props.isLoading || this.props.isEditing}/>
												<ButtonIcon name={"delete|" + institution.id}
															label={"delete"}
															onClick={(event) => this.onDeleteInstitution(event, institution.id)}
															buttonType={ButtonTypes.DELETE}
															showLabel={false}
															disabled={this.props.isLoading || this.props.isEditing}/>
											</ButtonBar>
										}
									</td>
								}
							</tr>
						);
					})
				}
				</tbody>
				{
					this.props.canEdit &&
					<tfoot>
						<tr key={"new"} id={"NEW_INSTITUTION"}>
							<td colSpan={5}>
								<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
									<Tile body={"Add Institution"}
										  onClick={!this.props.isEditing && !this.props.isLoading ? this.onCreateInstitution : null}
										  isNew={true}
										  isMedium={true}
									/>
								</TileContainer>
							</td>
						</tr>
					</tfoot>
				}
			</>
			</GridTable>
		);
	}
}

InstitutionPage.propTypes = {
	actions: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	editItemId: PropTypes.string.isRequired,
	institutions: PropTypes.array.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		dateWindows: state.sharedData.dateWindows,
		institutions: state.sharedData.institutionsWithDiscretionaryAgencies,
		sppIndicators: state.sharedData.sppIndicators,
		isEditing: state.sharedData.isEditing,
		editItemId: state.sharedData.editItemId,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sharedDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(InstitutionPage);