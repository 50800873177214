import React from "react";
import {Redirect, Switch} from "react-router-dom";
import "../sass/main.scss";
import withTracker from "../withTracker";

import * as publicLocations from "../constants/publicLocations";
import * as systemLocations from '../constants/systemLocations';
import * as reportLocations from '../Reports/reportLocations';
import * as usersLocations from '../constants/users/usersLocations';
import * as surveyLocations from "../constants/surveys/surveyLocations";
import * as strategicPlanLocations from "../constants/strategicPlan/strategicPlanLocations";
import * as sppLocations from "../constants/spp/sppLocations";
import * as monitoringPerformanceLocations from "../constants/monitoringPerformance/monitoringPerformanceLocations";
import * as monitoringComplianceLocations from "../constants/monitoringCompliance/monitoringComplianceLocations";
import * as drLocations from "../DisputeResolution/drLocations";

import * as customContexts from '../constants/customContexts';
import * as contexts from "../constants/contexts";
import {allReportContexts} from "../Reports/reportConfiguration";

import LoginLayout from "./layout/LoginLayout";
import PrintLayout from "./layout/PrintLayout";
import PublicLayout from "./layout/PublicLayout";
import EnsureLoggedIn from "./account/EnsureLoggedIn";

import ManageSurveyWrapperPage from "./surveyAdmin/ManageSurveyWrapperPage";
import SppAdminWrapperPage from "./SppAdmin/SppAdminWrapperPage";
import StrategicPlanAdminWrapper from "./strategicPlanAdmin/StrategicPlanAdminWrapper";
import MonitoringComplianceWrapperPage from "./monitoringCompliance/MonitoringComplianceWrapperPage";
import SystemSettingsWrapper from "./admin/SystemAdminWrapper";

import LoginPage from "./account/LoginPage";
import ForgotPasswordPage from "./account/ForgotPasswordPage";
import SppDocumentPrintPage from "./spp/SppDocumentPrintPage";
import SurveyLandingPage from "./surveys/SurveyLandingPage";
import TakeSurveyPage from "./surveys/TakeSurveyPage";
import SurveyCongratulationsPage from "../containers/surveys/CongratulationsPage";
import SppPublicDistrictPage from "./sppPublic/SppPublicDistrictPage";
import SppPublicDocumentPage from "./sppPublic/SppPublicDocumentPage";
import SppPublicSectionPage from "./sppPublic/SppPublicSectionPage";
import ResetPasswordPage from "./account/ResetPasswordPage";
import NotFoundPage from "./NotFoundPage";
import DashboardPage from "./DashboardPage";
import StateUserDistrictAssignmentContainer from "./users/StateUserDistrictAssignmentContainer";
import UserSearchPage from "./users/UserSearchPage";
import UserProfilePage from "./users/UserProfilePage";
import SppLandingPage from "./spp/SppLandingPage";
import SppReviewDistrictPage from "./sppReview/SppReviewDistrictPage";
import SppDocumentPage from "./spp/SppDocumentPage";
import SppSectionPage from "./spp/SppSectionPage";
import SppReviewDocumentPage from "./sppReview/SppReviewDocumentPage";
import SppReviewSectionPage from "./sppReview/SppReviewSectionPage";
import SppAdminDocumentsPage from "./SppAdmin/SppAdminDocumentsPage";
import SppAdminEditorPage from "./SppAdmin/SppAdminEditorPage";
import SppAdminCopyPage from "./SppAdmin/SppAdminCopyPage";
import OrderSppElementsPage from "./SppAdmin/OrderSppElementsPage";
import ManageSurveyLandingPage from "./surveyAdmin/ManageSurveyLandingPage";
import ManageSurveyDetailsPage from "./surveyAdmin/ManageSurveyDetailsPage";
import ManageSurveyGroupDetailsPage from "./surveyAdmin/ManageSurveyGroupDetailsPage";
import ManageSurveyElementsPage from "./surveyAdmin/ManageSurveyElementsPage";
import OrderSurveyElementsPage from "./surveyAdmin/OrderSurveyElementsPage";
import ManageSurveyRespondentsPage from "./surveyAdmin/ManageSurveyRespondentsPage";
import ManageSurveyReportsPage from "./surveyAdmin/ManageSurveyReportsPage";
import StrategicPlanLandingPage from "./strategicPlan/StrategicPlanLandingPage";
import StrategicPlanPage from "./strategicPlan/StrategicPlanPage";
import StrategicPlanReportPage from "./strategicPlan/StrategicPlanReportPage";
import ManagePlansPage from "./strategicPlanAdmin/ManagePlansPage";
import ManageResourcesPage from "./strategicPlanAdmin/ManageResourcesPage";
import ManageStrategicGoalsPage from "./strategicPlanAdmin/ManageStrategicGoalsPage";
import MonitoringPerformanceAdminLandingPage from "./monitoringPerformance/MonitoringPerformanceLandingPage";
import MonitoringPerformanceAssessmentPage from "./monitoringPerformance/MonitoringPerformanceAssessmentPage";
import McSummaryContainer from "./monitoringCompliance/McSummary/McSummaryContainer";
import McNotificationSettingsContainer from "./monitoringCompliance/NotificationSettings/McNotificationSettingsContainer";
import MonitoringComplianceDistrictPage from "./monitoringCompliance/MonitoringComplianceDistrictPage";
import McReviewStudentRecordsContainer 	from "./monitoringCompliance/ReviewStudentRecords/McReviewStudentRecordsContainer";
import ManageDistrictProtocolsPage from "./monitoringCompliance/ManageDistrictProtocolsPage";
import {ValidateStudentRecordsContainer} from "./monitoringCompliance/ValidateStudentRecords/ValidateStudentRecordsContainer";
import MatrixOfServiceValidatePage from "./monitoringCompliance/MatrixOfServiceValidatePage";
import MonitoringComplianceValidateStudentRecordPage from "./monitoringCompliance/MonitoringComplianceValidateStudentRecordPage";
import DistrictActivitiesContainer from "./monitoringCompliance/Cap/DistrictActivities/DistrictActivitiesContainer";
import StateActivitiesReviewContainer from "./monitoringCompliance/Cap/StateActivitiesReview/StateActivitiesReviewContainer";
import CorrectionsSummaryContainer from "./monitoringCompliance/DistrictStudentCorrections/CorrectionsSummaryContainer";
import CorrectionsStateSummaryContainer from "./monitoringCompliance/StateStudentCorrections/Summary/CorrectionsStateSummaryContainer";
import ComplianceCaseNotePage from "./monitoringCompliance/ComplianceCaseNotePage";
import CorrectionsPage from "./monitoringCompliance/CorrectionsPage";
import CorrectionsStateDetailsContainer from "./monitoringCompliance/StateStudentCorrections/Details/CorrectionsStateDetailsContainer";
import MonitoringComplianceDistrictWrapperPage from "./monitoringCompliance/MonitoringComplianceDistrictWrapperPage";
import StudentRecordsDistrictSummaryPage from "./monitoringCompliance/StudentRecordsDistrictSummaryPage";
import ReassessSubmitDistrictPage from "./monitoringCompliance/ReassessSubmitDistrictPage";
import ReassessDistrictPage from "./monitoringCompliance/ReassessDistrictPage";
import MonitoringComplianceEditStudentRecordPage from "./monitoringCompliance/MonitoringComplianceEditStudentRecordPage";
import MatrixOfServiceDistrictPage from "./monitoringCompliance/MatrixOfServiceDistrictPage";
import AppSettingsPage from "./admin/AppSettingsPage";
import SystemMessagePage from "./admin/SystemMessagePage";
import InstitutionPage from "./admin/InstitutionPage";
import SppIndicatorsPage from "./admin/SppIndicatorsPage";
import BeessIndicatorsPage from "./admin/BeessIndicatorsPage";
import PtsIndicatorsPage from "./admin/PtsIndicatorsPage";
import FileUploadPage from "./admin/FileUploadPage";
import ReportsContainer from "../Reports/ReportContainer";
import PrintContainer from "../Reports/Print/PrintContainer";
import DrRoutes from "../DisputeResolution/DrRoutes";
import BpieRoutes from "./bpie/bpieRoutes";
import GmsRoutes from "./gms/gmsRoutes";
import PtsRoutes from "./pts/ptsRoutes";
import * as bpieLocations from "./bpie/bpieLocations";
import * as gmsLocations from "./gms/gmsLocations";
import * as ptsLocations from "./pts/ptsLocations";

class App extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<LoginLayout
					exact
					path={publicLocations.USER_SIGN_IN.path}
					component={withTracker(LoginPage)}
				/>
				<LoginLayout
					exact
					path={publicLocations.USER_FORGOT_PASSWORD.path}
					component={withTracker(ForgotPasswordPage)}
				/>
				<PublicLayout
					exact
					path={publicLocations.USER_RESET_PASSWORD.path}
					component={withTracker(ResetPasswordPage)}
				/>

				<PrintLayout
					exact
					path={publicLocations.SPP_DOCUMENT_PRINT.path}
					component={withTracker(SppDocumentPrintPage)}
					context={customContexts.SPP}
				/>

				<PrintLayout
					exact
					path={publicLocations.SPP_DOCUMENT_SECTION_PRINT.path}
					component={withTracker(SppDocumentPrintPage)}
					context={customContexts.SPP}
				/>

				<PublicLayout
					exact
					path={publicLocations.SURVEYS_LANDING.path}
					component={withTracker(SurveyLandingPage)}
					context={customContexts.SURVEYS}
				/>
				<PublicLayout
					exact
					path={publicLocations.SURVEYS_TAKE.path}
					component={withTracker(TakeSurveyPage)}
					context={customContexts.SURVEYS}
				/>
				<PublicLayout
					exact
					path={publicLocations.SURVEYS_CONGRATULATIONS.path}
					component={withTracker(SurveyCongratulationsPage)}
					context={customContexts.SURVEYS}
				/>

				<PublicLayout
					exact
					path={publicLocations.SPP_LANDING.path}
					component={withTracker(SppPublicDistrictPage)}
					context={customContexts.SPP}
				/>
				<PublicLayout
					exact
					path={publicLocations.SPP_DOCUMENT.path}
					component={withTracker(SppPublicDocumentPage)}
					context={customContexts.SPP}
				/>
				<PublicLayout
					exact
					path={publicLocations.SPP_SECTION.path}
					component={withTracker(SppPublicSectionPage)}
					context={customContexts.SPP}
				/>

				<EnsureLoggedIn
					exact
					path={systemLocations.DASHBOARD.path}
					component={withTracker(DashboardPage)}
					context={customContexts.SYSTEM}
				/>

				<EnsureLoggedIn
					exact
					path={usersLocations.SEARCH.path}
					component={withTracker(UserSearchPage)}
					context={[contexts.USERS]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={usersLocations.PROFILE.path}
					component={withTracker(UserProfilePage)}
					context={[contexts.USERS]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={usersLocations.NEW_USER.path}
					component={withTracker(UserProfilePage)}
					context={[contexts.USERS]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={usersLocations.STATE_USER_DISTRICT_ASSIGNMENTS.path}
					component={withTracker(StateUserDistrictAssignmentContainer)}
					context={[contexts.USERS]}
					authorizationRequired
				/>

				<EnsureLoggedIn
					exact
					path={sppLocations.LANDING_DISTRICT.path}
					component={withTracker(SppLandingPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.LANDING_DOE.path}
					component={withTracker(SppReviewDistrictPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.LANDING_DOE_DATE_WINDOW.path}
					component={withTracker(SppReviewDistrictPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.DOCUMENT_DISTRICT.path}
					component={withTracker(SppDocumentPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.SECTION_DISTRICT.path}
					component={withTracker(SppSectionPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.DOCUMENT_DOE.path}
					component={withTracker(SppReviewDocumentPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.SECTION_DOE.path}
					component={withTracker(SppReviewSectionPage)}
					context={[contexts.SPP]}
					authorizationRequired
				/>

				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_DOCUMENTS.path}
					component={withTracker(SppAdminDocumentsPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_EDITOR.path}
					component={withTracker(SppAdminEditorPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_EDITOR_DOCUMENT.path}
					component={withTracker(SppAdminEditorPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_EDITOR_PART.path}
					component={withTracker(SppAdminEditorPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_EDITOR_SECTION.path}
					component={withTracker(SppAdminEditorPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_COPY.path}
					component={withTracker(SppAdminCopyPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_COPY_DOCUMENT.path}
					component={withTracker(SppAdminCopyPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_COPY_PART.path}
					component={withTracker(SppAdminCopyPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={sppLocations.ADMIN_EDITOR_SECTION_ORDER.path}
					component={withTracker(OrderSppElementsPage)}
					context={[contexts.SPP]}
					authorizationRequired
					wrapperPage={SppAdminWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.TAKE.path}
					component={withTracker(TakeSurveyPage)}
					context={[contexts.SURVEYS]}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.CONGRATULATIONS.path}
					component={withTracker(SurveyCongratulationsPage)}
					context={[contexts.SURVEYS]}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.LANDING.path}
					component={withTracker(ManageSurveyLandingPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.MANAGE_DETAILS.path}
					component={withTracker(ManageSurveyDetailsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
					wrapperPage={ManageSurveyWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.MANAGE_GROUP_DETAILS.path}
					component={withTracker(ManageSurveyGroupDetailsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.MANAGE_ELEMENTS.path}
					component={withTracker(ManageSurveyElementsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
					wrapperPage={ManageSurveyWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.ORDER_ELEMENTS.path}
					component={withTracker(OrderSurveyElementsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
					wrapperPage={ManageSurveyWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.MANAGE_RESPONDENTS.path}
					component={withTracker(ManageSurveyRespondentsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
					wrapperPage={ManageSurveyWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={surveyLocations.MANAGE_REPORTS.path}
					component={withTracker(ManageSurveyReportsPage)}
					context={[contexts.SURVEYS]}
					authorizationRequired
					wrapperPage={ManageSurveyWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.LANDING.path}
					component={withTracker(StrategicPlanLandingPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.PLAN.path}
					component={withTracker(StrategicPlanPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.REPORT.path}
					component={withTracker(StrategicPlanReportPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
				/>

				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.MANAGE_PLANS.path}
					component={withTracker(ManagePlansPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
					wrapperPage={StrategicPlanAdminWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.MANAGE_RESOURCES.path}
					component={withTracker(ManageResourcesPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
					wrapperPage={StrategicPlanAdminWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={strategicPlanLocations.MANAGE_GOALS.path}
					component={withTracker(ManageStrategicGoalsPage)}
					context={[contexts.STRATEGIC_PLAN]}
					authorizationRequired
					wrapperPage={StrategicPlanAdminWrapper}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringPerformanceLocations.LANDING_DOE.path}
					component={withTracker(MonitoringPerformanceAdminLandingPage)}
					context={[contexts.MONITORING_PERFORMANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringPerformanceLocations.LANDING_DOE_WITH_DATEWINDOW.path}
					component={withTracker(MonitoringPerformanceAdminLandingPage)}
					context={[contexts.MONITORING_PERFORMANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringPerformanceLocations.ASSESSMENT_DOE.path}
					component={withTracker(MonitoringPerformanceAssessmentPage)}
					context={[contexts.MONITORING_PERFORMANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringPerformanceLocations.ASSESSMENT_NEW_DOE.path}
					component={withTracker(MonitoringPerformanceAssessmentPage)}
					context={[contexts.MONITORING_PERFORMANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringPerformanceLocations.LANDING_DISTRICT.path}
					component={withTracker(MonitoringPerformanceAssessmentPage)}
					context={[contexts.MONITORING_PERFORMANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.MC_SUMMARY.path}
					component={withTracker(McSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.MC_NOTIFICATION_SETTINGS.path}
					component={withTracker(McNotificationSettingsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.LANDING_DOE.path}
					component={withTracker(MonitoringComplianceDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW.path}
					component={withTracker(MonitoringComplianceDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW_STATUS.path}
					component={withTracker(MonitoringComplianceDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.MANAGE_PROTOCOLS_DOE_DISTRICT.path}
					component={withTracker(ManageDistrictProtocolsPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path}
					component={withTracker(ValidateStudentRecordsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_MATRIX_RECORD_VALIDATE.path}
					component={withTracker(MatrixOfServiceValidatePage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_RECORD_VALIDATE.path}
					component={withTracker(MonitoringComplianceValidateStudentRecordPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.MANAGE_CORRECTIVE_ACTIONS_DOE_DISTRICT.path}
					component={withTracker(StateActivitiesReviewContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.LANDING_DISTRICT.path}
					component={withTracker(StudentRecordsDistrictSummaryPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path}
					component={withTracker(StudentRecordsDistrictSummaryPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_NOTIFICATION_SETTINGS.path}
					component={withTracker(McNotificationSettingsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_REASSESS_DISTRICT_REVIEW.path}
					component={withTracker(ReassessSubmitDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_REASSESS_DISTRICT.path}
					component={withTracker(ReassessDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_RECORD_DISTRICT.path}
					component={withTracker(MonitoringComplianceEditStudentRecordPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_REVIEW_SUBMISSION.path}
					component={withTracker(McReviewStudentRecordsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.COMPLIANCE_MATRIX_RECORD_DISTRICT.path}
					component={withTracker(MatrixOfServiceDistrictPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_CORRECTIVE_ACTIONS.path}
					component={withTracker(DistrictActivitiesContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path}
					component={withTracker(CorrectionsSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY_FOR_STUDENT.path}
					component={withTracker(CorrectionsSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path}
					component={withTracker(CorrectionsSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path}
					component={withTracker(CorrectionsStateSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path}
					component={withTracker(CorrectionsStateSummaryContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DOE_CASE_NOTES.path}
					component={withTracker(ComplianceCaseNotePage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS.path}
					component={withTracker(CorrectionsPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>
				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS.path}
					component={withTracker(CorrectionsPage)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceDistrictWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS.path}
					component={withTracker(CorrectionsStateDetailsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={monitoringComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS.path}
					component={withTracker(CorrectionsStateDetailsContainer)}
					context={[contexts.MONITORING_COMPLIANCE]}
					authorizationRequired
					wrapperPage={MonitoringComplianceWrapperPage}
				/>

				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_APP_SETTINGS.path}
					component={withTracker(AppSettingsPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_SYSTEM_MESSAGE.path}
					component={withTracker(SystemMessagePage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_INSTITUTIONS.path}
					component={withTracker(InstitutionPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_SPP_INDICATORS.path}
					component={withTracker(SppIndicatorsPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_BEESS_INDICATORS.path}
					component={withTracker(BeessIndicatorsPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_PTS_INDICATORS.path}
					component={withTracker(PtsIndicatorsPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={systemLocations.ADMIN_FILE_UPLOAD.path}
					component={withTracker(FileUploadPage)}
					context={[contexts.ADMIN]}
					authorizationRequired
					wrapperPage={SystemSettingsWrapper}
				/>
				<EnsureLoggedIn
					exact
					path={reportLocations.REPORTS.path}
					component={withTracker(ReportsContainer)}
					context={allReportContexts}
					authorizationRequired
				/>

				<PrintLayout
					path={reportLocations.REPORTS_PRINT.path}
					component={withTracker(PrintContainer)}
					context={allReportContexts}
					authorizationRequired
				/>

				<DrRoutes path={drLocations.ROOT} />
				<BpieRoutes path={bpieLocations.ROOT} />
				<GmsRoutes path={gmsLocations.ROOT} />
				<PtsRoutes path={ptsLocations.ROOT} />
				<PublicLayout component={withTracker(NotFoundPage)}/>
				<Redirect to={publicLocations.PAGE_NOT_FOUND.path} />
			</Switch>
		);
	}
}
export default App;