import {DefaultPolicy} from "./DefaultPolicy";
import {allReportContexts} from "../../../Reports/reportConfiguration";

class ReportsPolicy extends DefaultPolicy {
    constructor() {
        super(allReportContexts);
    }
}

export {ReportsPolicy};
