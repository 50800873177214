import {searchCriteria} from "./searchCriteria";

export function SppReviewDistrictSearchCriteria() {
    return Object.assign({}, searchCriteria("SppReviewDistrictSearchCriteria", "districtName", "districtName"), {
        dateWindowId: "",
        district: "",
        progress: "",
        partId: "",
        selectPartItems: [],
        sectionId: "",
        selectSectionItems: []
    });
}