import PropTypes from "prop-types";
import React from "react";
import * as buttonTypes from "../../constants/ButtonTypes";

export const EmailLink = (props) => {
    const {className, label, address} = props;
    const classes = className ? className : undefined;
    const newLabel = label ? label : address;

    return <ExternalLinkAdapter url={`mailto:${address}`} label={newLabel} opensInNewTab={false} className={classes} />;
};

export const ExternalLink = ({className, label, url, title}) => {
    if(!className) className = "";
    return <ExternalLinkAdapter label={label} url={url} className={className} title={title}/>;
};

export const ExternalLinkButton = ({className, label, url, title}) => {
    if(!className) className = "";
    let classes = `${className} button button--external ${buttonTypes.SYSTEM}`;
    return <ExternalLinkAdapter className={classes} label={label} url={url} title={title} opensInNewTab />;
};

const ExternalLinkAdapter = ({className, label, url, opensInNewTab = true, title}) => {
    if (!label && !title) return <> </>;

    const newTabTarget = opensInNewTab ? "_blank" : null;
    const newTabRel = opensInNewTab ? "noopener noreferrer" : null;

    return (
        <a href={url} className={className} target={newTabTarget} rel={newTabRel} title={title}>
            {label}
        </a>
    );
};

const sharedProps = {
    className: PropTypes.string,
    label: PropTypes.any,
    title: PropTypes.string,
    url: PropTypes.string,
};

EmailLink.propTypes = {
    address: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.any,
};

ExternalLink.propTypes = {
    ...sharedProps,
};
ExternalLinkButton.propTypes = {
    ...sharedProps,
};

ExternalLinkAdapter.propTypes = {
    opensInNewTab: PropTypes.bool,
    ...sharedProps,
};