import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import GridTable from "../../../../components/common/GridTable";
import SectionHeader from "../../../../components/common/SectionHeader";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {BpieDistrictPieSummaryTasks} from "./BpieDistrictPieSummaryTasks";

export const BpieDistrictPieSummaryView = ({
                                               districtPies = [],
                                               handleDistrictPieSectionsRedirect,
                                               handleAddDpieYear,
                                               handleDownloadDistrictPieReport,
                                           }) => {
    const currentDistrictPie = districtPies[districtPies.length - 1];
    const nextDistrictPieYear = currentDistrictPie.dpieYear + 1;
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    const handleAddDpieYearClick = (event) => {
        event.preventDefault();
        if (confirm(`Are you sure you want to add year ${nextDistrictPieYear} and copy previous DPIE Goals/Actions?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            handleAddDpieYear(currentDistrictPie.districtPieId);
    };

    return <>
        {
            districtPies.map((districtPie, index) => {
                return (
                    <div key={index} className={`districtPie-summary`}>
                        <SectionHeader>
                            <h2>{districtPie.dateCovered}</h2>
                        </SectionHeader>
                        <GridTable>
                            <thead>
                            <tr>
                                <th>Task</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <BpieDistrictPieSummaryTasks
                                districtPie={districtPie}
                                handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
                                handleDownloadDistrictPieReport={handleDownloadDistrictPieReport}
                            />
                            </tbody>
                        </GridTable>
                    </div>
                );
            })
        }
        {
            isEditable &&
            <>
                <p className={`highlight-example`}>When adding year {nextDistrictPieYear}, all goals and actions from the previous year will be included. Any SBPIE priority indicators added since last year&apos;s PIE will be available as well.</p>

                <ButtonBar>
                    {

                        <Button
                            buttonType={ButtonTypes.SAVE}
                            label={`Add Year ${nextDistrictPieYear}`}
                            name="btnAddDpieYear"
                            onClick={handleAddDpieYearClick}
                        />
                    }
                </ButtonBar>
            </>
        }
    </>;
};

BpieDistrictPieSummaryView.propTypes = {
    districtPies: PropTypes.array.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    handleAddDpieYear: PropTypes.func.isRequired,
    handleDownloadDistrictPieReport: PropTypes.func.isRequired
};