export const ADMIN = "admin";
export const BPIE = "bpie";
export const DISPUTE_RESOLUTION_CONTEXT = "dr";
export const DUE_PROCESS = "dueprocess";
export const MEDIATIONS = "mediations";
export const STATE_COMPLAINTS = "statecomplaints";
export const GMS = "gms";
export const MONITORING_COMPLIANCE = "compliance";
export const MONITORING_PERFORMANCE = "performance";
export const SPP = "sp&p";
export const PTS = "pts";
export const STRATEGIC_PLAN = "plan";
export const SURVEYS = "surveys";
export const USERS = "users";

export const NO_DISTRICTS = [STRATEGIC_PLAN, ADMIN, SURVEYS];
export const DISPUTE_RESOLUTION_CONTEXTS = [STATE_COMPLAINTS, DUE_PROCESS, MEDIATIONS];
