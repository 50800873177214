import PropTypes from "prop-types";
import React, {useState} from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import {SelectField} from "../../../../components/common/inputs";
import HtmlDisplayInline from "../../../../components/htmlDisplay/HtmlDisplayInline";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {
    DistrictPieReportType,
    DistrictPieReportTypeList,
    DistrictPieTask,
    DistrictPieTaskList
} from "../../bpieConstants";

export const BpieDistrictPieSummaryTasks = ({
                                                districtPie,
                                                handleDistrictPieSectionsRedirect,
                                                handleDownloadDistrictPieReport,
                                            }) => {
    const [reportType, setReportType] = useState(DistrictPieReportType.WithProgressMonitoringData);

    const handleClickDistrictPieAction = (event, districtPieId, sectionId) => {
        event.preventDefault();
        if (sectionId === DistrictPieTask.DownloadDistrictPie.id)
            handleDownloadDistrictPieReport(districtPieId, reportType);
        else
            handleDistrictPieSectionsRedirect(districtPieId, sectionId);
    };

    const handleReportTypeChange = (event) => {
        event.preventDefault();
        setReportType(event.target.value);
    }

    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    return <>
        {
            DistrictPieTaskList.map((task, taskIndex) => {
                const labelType = task.id === DistrictPieTask.DownloadDistrictPie.id ? "" : isEditable ? "Edit" : "View";
                const buttonType = task.id === DistrictPieTask.DownloadDistrictPie.id
                    ? ButtonTypes.DOWNLOAD
                    : isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
                const taskStatus = districtPie.districtPieTaskStatuses.find(t => t.dpieTask === task.id);
                return (
                    <tr key={`${districtPie.districtPieId}_${taskIndex}`}>
                        <td>
                            <HtmlDisplayInline html={task.text}/>
                            {
                                task.id === DistrictPieTask.DownloadDistrictPie.id &&
                                <SelectField
                                    includeDefaultOption={false}
                                    label={`Report Type for District PIE for ${districtPie.dateCovered}`}
                                    name={`reportType-${districtPie.districtPieId}`}
                                    onChange={handleReportTypeChange}
                                    options={DistrictPieReportTypeList}
                                    showLabel={false}
                                    value={reportType}
                                />
                            }
                        </td>
                        <td>{taskStatus.dpieTaskStatusDescription}</td>
                        <td className={`actions text-center`}>
                            <Button
                                buttonType={buttonType}
                                disabled={taskStatus.isDisabled}
                                label={`${labelType} ${task.text.replace(/&amp;/g, "&")} for District PIE for ${districtPie.dateCovered}`}
                                name={`btnDistrictPie-${districtPie.districtPieId}-${taskIndex}`}
                                onClick={(event) => handleClickDistrictPieAction(event, districtPie.districtPieId, task.id)}
                                showLabel={false}
                            />
                        </td>
                    </tr>
                );
            })
        }
    </>;
};

BpieDistrictPieSummaryTasks.propTypes = {
    districtPie: PropTypes.object.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    handleDownloadDistrictPieReport: PropTypes.func.isRequired
};