import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import Button from "../../components/common/buttons/Button";
import {objectHasProperty} from "../../components/common/commonUtilities";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import TextField from "../../components/common/inputs/TextField";
import CheckboxField from "../../components/common/inputs/CheckBoxField";
import FormTable from "../../components/common/FormTable";

export class SystemMessagePage extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			header: "",
			text: "",
			displayMessage: false,
			isLoaded: false
		};

		this.onSystemMessageChange = this.onSystemMessageChange.bind(this);
		this.onSystemMessageSave = this.onSystemMessageSave.bind(this);
		this.onSystemMessageCancel = this.onSystemMessageCancel.bind(this);
		this.onRestrictSystemAccess = this.onRestrictSystemAccess.bind(this);
	}

	componentDidMount() {
		this.setSystemMessage();
	}

	componentDidUpdate() {
		this.setSystemMessage();
	}

	setSystemMessage() {
		if(objectHasProperty(this.props.systemMessage, "text") && !this.state.isLoaded)
			this.setState({...this.props.systemMessage, ...{isLoaded: true}});
	}

	onSystemMessageChange(event) {
		const systemMessage = Object.assign({}, this.state);

		if (event.target.name === "displayMessage")
			systemMessage[event.target.name] = event.target.checked;
		else
			systemMessage[event.target.name] = event.target.value;

		this.setState({...systemMessage});
	}

	onSystemMessageSave(event) {
		event.preventDefault();

		this.props.actions.saveSystemMessage(this.state);
	}

	onRestrictSystemAccess() {
		this.props.actions.restrictSystemAccess(true);
	}

	onSystemMessageCancel(event) {
		event.preventDefault();

		this.setSystemMessage();
	}

	render() {
		if(!this.props.systemMessage) return null;

		return (
			<div>
				{
					this.props.canEdit &&
					<ButtonBar position={ButtonBarPositions.TOP}>
						<Button name={"btnSaveMessage"}
								label={"Save"}
								onClick={this.onSystemMessageSave}
								buttonType={ButtonTypes.SAVE}
								disabled={this.props.isLoading}/>

						<Button name={"btnCancelMessageChanges"}
								label={"Cancel"}
								onClick={this.onSystemMessageCancel}
								buttonType={ButtonTypes.CANCEL}
								disabled={this.props.isLoading}/>

						<Button name={"btnRestrictAccess"}
								label={"Restrict System Access"}
								onClick={this.onRestrictSystemAccess}
								buttonType={ButtonTypes.RESTRICT}
								disabled={this.props.isLoading}/>
					</ButtonBar>
				}
				<FormTable>
					<TextField showLabel={true}
					           name={"header"}
					           label={"Message Header"}
					           onChange={this.onSystemMessageChange}
							   disabled={this.props.isLoading || !this.props.canEdit}
					           value={this.state.header}/>
					<TextField showLabel={true}
					           name={"text"}
					           label={"Message Body"}
					           onChange={this.onSystemMessageChange}
							   disabled={this.props.isLoading || !this.props.canEdit}
					           value={this.state.text}/>
					<CheckboxField showLabel
								   disabled={this.props.isLoading || !this.props.canEdit}
					               name={"displayMessage"}
					               label={"Display Message"}
					               checked={this.state.displayMessage}
					               onChange={this.onSystemMessageChange}/>
				</FormTable>
			</div>
		);
	}
}

SystemMessagePage.propTypes = {
	actions: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	systemIsLocked: PropTypes.bool.isRequired,
	systemMessage: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		isLoading: state.ajaxCallsInProgress > 0,
		systemMessage: state.sharedData.systemMessage,
		systemIsLocked: state.sharedData.systemIsLocked
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sharedDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemMessagePage);