import {SBPIE_ASSESSMENT_STATUS, bpieTeamRoles, DBPIE_ASSESSMENT_STATUS} from "./bpieConstants";
import { createBpieTeamMember, createFinFacilitator } from "./bpieObjectFactory";
import { meetingLeaderTitles } from "./School/SchoolInformation/bpieSchoolInfoConstants";
import { createBpieIndicator, createBpieResponse } from "./Assessment/bpieAssessmentFactory";

const defaultSchoolBpieId = "00000000-0000-0000-0000-000000000001";
const defaultDistrictBpieId = "00000000-0000-0000-0000-000000000002";

const defaultBpieResponse = {
    implementationStatus: "Not Yet",
    bpieIndicatorId: "00000000-0000-0000-0000-000000000001",
    isPriority: true,
    supportingEvidence: null
}

const defaultBpieIndicator = {
    description: "The school team analyzes data to identify barriers and initiate improvement steps that increase the number of students with low and high incidence disabilities, across all grades, in general education and natural contexts.",
    evidence: "<ul><li>School analyzes data of all SWDs to identify current barriers and practices for the provision of educational services in general education classes and natural contexts.</li><li>School increases the number of SWDs who receive educational services in inclusive classrooms and natural contexts.</li><li>Data are collected, analyzed and shared with all teachers regarding student achievement of SWDs in general education and natural contexts.</li><li>Data chats are conducted with students to create strong accountability and high expectations.</li></ul>",
    indicatorDomainType: "Leadership and Decision Making",
    bpieIndicatorId: "00000000-0000-0000-0000-000000000001",
    indicatorNumber: 1,
    suggestedMeasures: "Data from state, district and school assessments and alternate assessments, other achievement measures, behavior data, educational environment (percentage of time in general education), graduation rate, postschool outcomes, observations from classroom walk-throughs and progress toward individual educational plan (IEP) goals.",
    summary: "School analyzes data to identify barriers and initiate improvement steps that increase the number of students in gen. ed."
};

const schoolBpieTeam = [
    {
        schoolBpieId: defaultSchoolBpieId,
        userId: "7eef380a-959e-49e5-bc8c-30679a44129b",
        firstName: "Jane",
        lastName: "Doe",
        email: "jDoe@school.gov",
        title: "",
        titleOther: "",
        role: bpieTeamRoles.finFacilitator
    },
    {
        schoolBpieId: defaultSchoolBpieId,
        userId: "7eef380a-959e-49e5-bc8c-30679a44129b",
        firstName: "Erin",
        lastName: "Sampson",
        email: "eSampson@school.gov",
        title: meetingLeaderTitles.SchoolPrincipal,
        titleOther: "",
        role: bpieTeamRoles.schoolAdministrator
    },
    {
        schoolBpieId: defaultSchoolBpieId,
        userId: "7eef380a-959e-49e5-bc8c-30679a44129b",
        firstName: "Christi",
        lastName: "Yu",
        email: "cYu@school.gov",
        title: meetingLeaderTitles.TeacherLeader,
        titleOther: "",
        role: bpieTeamRoles.meetingLeader
    }
];

let schoolBpie = null;

const archivedSchoolBpies = [
    {
        districtId: "00000000-0000-0000-0000-000000000001",
        msidNumber: "1",
        schoolBpieId: "00000000-0000-0000-0000-000000000002",
        datesCovered: "2017-2020",
        status: SBPIE_ASSESSMENT_STATUS.Archived.id,
    },
    {
        districtId: "00000000-0000-0000-0000-000000000001",
        msidNumber: "1",
        schoolBpieId: "00000000-0000-0000-0000-000000000003",
        datesCovered: "2014-2017",
        status: SBPIE_ASSESSMENT_STATUS.Archived.id,
    }
];

export function getFinFacilitators() {
    return [
        createFinFacilitator("Philicia.Rich@fin.org", "Philicia", "Rich", "00000000-0000-0000-0000-000000000001"),
        createFinFacilitator("Caren.Prichard@fin.org", "Caren", "Prichard", "00000000-0000-0000-0000-000000000002"),
        createFinFacilitator("Lynne.Smith@fin.org", "Lynne", "Smith", "00000000-0000-0000-0000-000000000003"),
        createFinFacilitator("Kristin.Radloff@fin.org", "Kristin", "Radloff", "00000000-0000-0000-0000-000000000004"),
        createFinFacilitator("Rebekah.Wallis@fin.org", "Rebekah", "Wallis", "00000000-0000-0000-0000-000000000005"),
        createFinFacilitator("Erika.Boyd@fin.org", "Erika", "Boyd", "00000000-0000-0000-0000-000000000006"),
        createFinFacilitator("Allison.Kanji@fin.org", "Allison", "Kanji", "00000000-0000-0000-0000-000000000007"),
        createFinFacilitator("Bill.Pearlman@fin.org", "Bill", "Pearlman", "00000000-0000-0000-0000-000000000008"),
        createFinFacilitator("Erin.Perman@fin.org", "Erin", "Perman", "00000000-0000-0000-0000-000000000009"),
    ];
}

function buildIndicator(indicatorNumber) {
    const defaultId = "00000000-0000-0000-0000-00000000000" + indicatorNumber;
    return createBpieIndicator({
        bpieIndicatorId: defaultId,
        indicatorNumber: indicatorNumber,
    });
}

export function getSchoolBpie() {
    if (!schoolBpie) {
        schoolBpie = {
            indicators: [
                defaultBpieIndicator,
                createBpieIndicator({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000002",
                    indicatorNumber: 2,
                    indicatorDomainType: `Type Domain 2`,
                    description: `describe 2`,
                    evidence: `this is evidence for  2`,
                    suggestedMeasures: `measuring 2 stuff`,
                    summary: `summed up 2 beautifully`

                }),
                buildIndicator(3),
                buildIndicator(4),
                buildIndicator(5),
                buildIndicator(6),
                buildIndicator(7),
                buildIndicator(8),
                buildIndicator(9),
                buildIndicator(10),
                buildIndicator(11),
                buildIndicator(12),
                buildIndicator(13),
                buildIndicator(14),
                buildIndicator(15),
                buildIndicator(16),
                buildIndicator(17),
                buildIndicator(18),
                buildIndicator(19),
                buildIndicator(20),
                buildIndicator(21),
                buildIndicator(22),
                buildIndicator(23),
                buildIndicator(24),
                buildIndicator(25),
                buildIndicator(26),
                buildIndicator(27),
                buildIndicator(28),
                buildIndicator(29),
                buildIndicator(30),
                buildIndicator(31),
                buildIndicator(32),
                buildIndicator(33),
                buildIndicator(34),
            ],
            responses: [
                defaultBpieResponse,
                createBpieResponse({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000003",
                    isPriority: true,
                }),
                createBpieResponse({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000005",
                    isPriority: true,
                }),
            ],
            assessmentTeam: "",
            charterSchoolStatus: "No",
            datesCovered: "2022-2025",
            hasCompletedBpieTraining: false,
            hasMetEssaTarget: 'Yes',
            isActive: false,
            isAdministratorMeetingLeader: false,
            msidNumber: "1",
            primaryServiceType: "Education",
            schoolAdminEmail: "joe@admin.com",
            schoolAdminFirstName: "Joe",
            schoolAdminLastName: "Admin",
            schoolBpieId: defaultSchoolBpieId,
            schoolBpieTeam: schoolBpieTeam,
            schoolDistrict: "Collier",
            schoolFunction: "Education",
            districtId: "00000000-0000-0000-0000-000000000001",
            schoolName: "Cypress Palm Middle School",
            schoolType: "Public",
            status: SBPIE_ASSESSMENT_STATUS.Started.id,
        };
    }

    return schoolBpie;
}

export function getTrainingResponse() {
    return {
        schoolBpieId: defaultSchoolBpieId,
        responses: [
             {
                questionType: 1,
                answer: "Once every 5 years"
            },
            {
                questionType: 2,
                answer: "Supporting evidence and data"
            },
            {
                questionType: 3,
                answer: "Develop short- and long-term improvement"
            },
        ]
    }
}

export function getSchoolBpies() {
    return {
        current: getSchoolBpie(),
        archived: archivedSchoolBpies
    };
}

export function startSchoolBpie(schoolInfoDetails) {
    let schoolBpie = getSchoolBpie();

    schoolBpie.hasMetEssaTarget = schoolInfoDetails.hasMetEssaTarget;
    schoolBpie.hasCompletedBpieTraining = schoolInfoDetails.hasCompletedBpieTraining;
    schoolBpie.assessmentTeam = schoolInfoDetails.assessmentTeam;
    schoolBpie.status = SBPIE_ASSESSMENT_STATUS.Started.id;

    const finFacilitators = getFinFacilitators();
    let selectedFinFacilitator = finFacilitators.find(f => f.userId === schoolInfoDetails.finFacilitatorId);
    if (selectedFinFacilitator)
        updateTeamMember(schoolBpie, schoolInfoDetails, bpieTeamRoles.finFacilitator);

    updateTeamMember(schoolBpie, schoolInfoDetails, bpieTeamRoles.meetingLeader);
}

function updateTeamMember(bpie, schoolInfoDetails, role) {
    let schoolBpieTeam = bpie.schoolBpieTeam;
    let index = schoolBpieTeam.findIndex(f => f.role === role);
    if (index === -1) {
        const newMember = createBpieTeamMember({
            role: role,
            schoolBpieId: bpie.schoolBpieId
        });
        schoolBpieTeam.push(newMember);
        index = schoolBpieTeam.findIndex(f => f.role === role);
    }

    schoolBpieTeam[index].email = schoolInfoDetails.email;
    schoolBpieTeam[index].firstName = schoolInfoDetails.firstName;
    schoolBpieTeam[index].lastName = schoolInfoDetails.lastName;
    schoolBpieTeam[index].title = schoolInfoDetails.title;
    schoolBpieTeam[index].titleOther = schoolInfoDetails.titleOther;
}

export function saveTeamMemberDetails(teamMemberDetails) {
    let schoolBpie = getSchoolBpie();

    schoolBpie.hasCompletedBpieTraining = teamMemberDetails.hasCompletedBpieTraining;
    schoolBpie.assessmentTeam = teamMemberDetails.assessmentTeam;
    schoolBpie.status = SBPIE_ASSESSMENT_STATUS.Started.id;
}

export function saveIndicatorResponse(bpieIndicatorId, indicatorResponse) {
    let schoolBpie = getSchoolBpie();
    let responses = schoolBpie.responses;
    let index = responses.findIndex(f => f.bpieIndicatorId === bpieIndicatorId);

    if (index < 0) {
        responses.push(createBpieResponse({
            bpieIndicatorId: bpieIndicatorId,
        }));
        index = responses.findIndex(f => f.bpieIndicatorId === bpieIndicatorId);
    }

    responses[index].supportingEvidence = indicatorResponse.supportingEvidence;
    responses[index].implementationStatus = indicatorResponse.implementationStatus;
    responses[index].isPriority = indicatorResponse.isPriority;

    return schoolBpie;
}

export function startSchoolBpieAssessment() {
    let schoolBpie = getSchoolBpie();
    schoolBpie.status = SBPIE_ASSESSMENT_STATUS.InProgress.id;
    schoolBpie.isActive = true;
}

export function submitSchoolBpieAssessment() {
    let schoolBpie = getSchoolBpie();
    schoolBpie.status = SBPIE_ASSESSMENT_STATUS.Archived.id;
    schoolBpie.isActive = true;
}

export function saveIndicatorPriorities(priorityBpieIndicatorIds) {
    let schoolBpie = getSchoolBpie();
    schoolBpie.responses.forEach(c => {
        c.isPriority = priorityBpieIndicatorIds.includes(c.bpieIndicatorId)
    });
}

export function getReportData() {
    return {
        districtName: "Marion",
        reportPeriod: "July 1, 2021 - June 30, 2022",
        indicators: [
            {
                bpieIndicatorId: 1,
                indicatorNumber: 1,
                summary: 'The school leadership team analyzes data to identify barriers and initiate improvement steps that increase the number of students with low- and high-incidence disabilities, across all grades, in general education and natural contexts.'
            },
            {
                bpieIndicatorId: 2,
                indicatorNumber: 2,
                summary: 'The school has developed, and regularly monitors progress for, goals related to short- and long-term improvement efforts to implement and improve inclusive educational practices, as measured by the School BPIE Assessment.'
            },
            {
                bpieIndicatorId: 3,
                indicatorNumber: 3,
                summary: 'The school has a key person who oversees, coordinates and monitors the implementation of best practices for inclusive education for all SWD.'
            },
            {
                bpieIndicatorId: 4,
                indicatorNumber: 4,
                summary: 'School administrators advocate for all SWD to have the same school choice options as students without disabilities to ensure all SWD receive educational services in their neighborhood school or school of choice.'
            },
            {
                bpieIndicatorId: 5,
                indicatorNumber: 5,
                summary: 'School data reflect that all SWD, regardless of the type or severity of disability, receive their education and related services in age- and grade-appropriate, heterogeneous, general education contexts 80% or more of the day.'
            },
            {
                bpieIndicatorId: 6,
                indicatorNumber: 6,
                summary: 'School data reflect that all SWD, ages 3–5, receive special education and related services in the regular early childhood (Pre-K) classes with peers without disabilities.'
            },
            {
                bpieIndicatorId: 7,
                indicatorNumber: 7,
                summary: 'School administrators communicate expectations for all school personnel to share responsibility for all of the students in their building and consider all SWD as general education students first.'
            },
            {
                bpieIndicatorId: 8,
                indicatorNumber: 8,
                summary: 'School administrators facilitate the use of resources, by school personnel, to implement best practices for inclusive education for all SWD.'
            },
            {
                bpieIndicatorId: 9,
                indicatorNumber: 9,
                summary: 'School administrators communicate expectations for all school personnel to use person first language in all written and verbal communications.'
            },
            {
                bpieIndicatorId: 10,
                indicatorNumber: 10,
                summary: 'School administrators use job interview questions to appraise an applicant’s knowledge and beliefs pertaining to diversity and inclusive practices, as applicable to the position.'
            },
            {
                bpieIndicatorId: 11,
                indicatorNumber: 11,
                summary: 'School administrators advocate for all SWD to be transported to and from school and community-based activities with students without disabilities attending the same school, except for those who have an IEP indicating a shortened school day.'
            },
            {
                bpieIndicatorId: 12,
                indicatorNumber: 12,
                summary: 'All SWD have the same opportunities as students without disabilities to participate in all school-sponsored, non-academic, age-appropriate activities, including electives, sports, dances, clubs, field trips, school plays, community service activities and graduation activities.'
            },
            {
                bpieIndicatorId: 13,
                indicatorNumber: 13,
                summary: 'All students, including SWD, are given equal consideration for recognition through honors, awards and other designations offered by the school.'
            },
            {
                bpieIndicatorId: 14,
                indicatorNumber: 14,
                summary: 'School administrators analyze data to identify professional development (PD) and technical assistance (TA) needed for school personnel to implement effective inclusive practices.'
            },
            {
                bpieIndicatorId: 15,
                indicatorNumber: 15,
                summary: 'School leaders provide job- embedded professional development for all school-based personnel, as appropriate for their job role, on best practices for inclusive education for all SWD.'
            },
            {
                bpieIndicatorId: 16,
                indicatorNumber: 16,
                summary: 'School leaders facilitate job- embedded, technical assistance for all school-based personnel, as appropriate for their job role, on best practices for inclusive education for all SWD.'
            },
            {
                bpieIndicatorId: 17,
                indicatorNumber: 17,
                summary: 'School administrators ensure that collaborative planning time is used productively and reflected in general and special education staff schedules and instructional plans.'
            },
            {
                bpieIndicatorId: 18,
                indicatorNumber: 18,
                summary: 'Special, electives and career technical education (CTE) teachers have regularly scheduled opportunities to consult with special education teachers and related service providers to implement strategies that support the learning of all SWD in their classes.'
            },
            {
                bpieIndicatorId: 19,
                indicatorNumber: 19,
                summary: 'General and special education teachers use state standards as the foundation for instruction of all SWD, including those with the most significant cognitive disabilities.'
            },
            {
                bpieIndicatorId: 20,
                indicatorNumber: 20,
                summary: 'An MTSS and problem-solving process is consistently used by school personnel to ensure progress in the general education curriculum, across all grades and settings, for all students with and without disabilities.'
            },
            {
                bpieIndicatorId: 21,
                indicatorNumber: 21,
                summary: 'All instructional and related services personnel use formative assessment processes and tools to gather, analyze and evaluate data about effective instruction and behavior interventions for all students with and without disabilities in general education and natural contexts.'
            },
            {
                bpieIndicatorId: 22,
                indicatorNumber: 22,
                summary: 'Teachers of SWD who spend less than 80% of their day in general education classes use formative assessment data to identify effective instructional and behavioral interventions that, when implemented in general education and natural contexts, allow SWD to make progress toward meeting IEP and learning goals.'
            },
            {
                bpieIndicatorId: 23,
                indicatorNumber: 23,
                summary: 'There is a school-wide approach to facilitate positive, interdependent relationships and social responsibility among all students with and without disabilities across all general education and natural contexts.'
            },
            {
                bpieIndicatorId: 24,
                indicatorNumber: 24,
                summary: 'There is a school-wide approach for planning and implementing Universal Design for Learning (UDL) across all instructional and non- instructional school contexts.'
            },
            {
                bpieIndicatorId: 25,
                indicatorNumber: 25,
                summary: 'There are a variety of service delivery models in place, across all grade levels, to provide instruction and related services to SWD in general education classes and natural contexts.'
            },
            {
                bpieIndicatorId: 26,
                indicatorNumber: 26,
                summary: 'All paraprofessionals have received PD that includes clear descriptions of their work responsibilities and strategies for providing support to SWD in general education classrooms and natural contexts. '
            },
            {
                bpieIndicatorId: 27,
                indicatorNumber: 27,
                summary: 'All special education teachers are full, collaborative members of a general education curriculum team.'
            },
            {
                bpieIndicatorId: 28,
                indicatorNumber: 28,
                summary: 'General and special education teachers use regularly scheduled collaborative planning time to clarify their roles and responsibilities while planning effective instruction and assessment for all students.'
            },
            {
                bpieIndicatorId: 29,
                indicatorNumber: 29,
                summary: 'Family members of SWD are contributing members of school decision-making groups.'
            },
            {
                bpieIndicatorId: 30,
                indicatorNumber: 30,
                summary: 'Learning opportunities and resources are provided to families of SWD as a result of needs assessments and student data.'
            },
            {
                bpieIndicatorId: 31,
                indicatorNumber: 31,
                summary: 'When communicating with families of SWD, all personnel consider family members as a resource and obtain their input in planning and problem solving.'
            },
            {
                bpieIndicatorId: 32,
                indicatorNumber: 32,
                summary: 'Reports of progress toward implementing inclusive practices are disseminated to families, school district personnel and community members annually.'
            },
            {
                bpieIndicatorId: 33,
                indicatorNumber: 33,
                summary: 'The school uses a person-centered planning process for SWD.'
            },
            {
                bpieIndicatorId: 34,
                indicatorNumber: 34,
                summary: 'School uses a team decision-making process to ensure SWD transition from grade to grade, school to school and district to district to ensure placement in the Least Restrictive Environment (LRE).'
            },
        ],
        sbpies: [
            {
                schoolDistrict: "Orange",
                assessment: {
                    responses: [
                        {
                            bpieIndicatorId: 6,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 26,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 14,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 34,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 18,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 10,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 19,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 20,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 29,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 13,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 9,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 8,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 31,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 30,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 15,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 2,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 28,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 21,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 25,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 4,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 5,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 16,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 32,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 24,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 7,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 11,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 27,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 33,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 3,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 23,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 22,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 17,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 1,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 12,
                            isPriority: false
                        }
                    ]
                }
            },
            {
                schoolDistrict: "Leon",
                assessment: {
                    responses: [
                        {
                            bpieIndicatorId: 6,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 26,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 14,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 34,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 18,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 10,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 19,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 20,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 29,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 13,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 9,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 8,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 31,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 30,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 15,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 2,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 28,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 21,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 25,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 4,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 5,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 16,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 32,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 24,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 7,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 11,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 27,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 33,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 3,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 23,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 22,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 17,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 1,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 12,
                            isPriority: false
                        }
                    ]
                }
            },
            {
                schoolDistrict: "Marion",
                assessment: {
                    responses: [
                        {
                            bpieIndicatorId: 6,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 26,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 14,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 34,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 18,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 10,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 19,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 20,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 29,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 13,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 9,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 8,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 31,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 30,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 15,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 2,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 28,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 21,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 25,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 4,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 5,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 16,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 32,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 24,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 7,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 11,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 27,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 33,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 3,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 23,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 22,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 17,
                            isPriority: false
                        },
                        {
                            bpieIndicatorId: 1,
                            isPriority: true
                        },
                        {
                            bpieIndicatorId: 12,
                            isPriority: false
                        }
                    ]
                }
            }
        ]
    };
}

export function getDistrictBpie() {
        return {
            indicators: [
                defaultBpieIndicator,
                createBpieIndicator({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000002",
                    indicatorNumber: 2,
                    indicatorDomainType: `Type Domain 2`,
                    description: `describe 2`,
                    evidence: `this is evidence for  2`,
                    suggestedMeasures: `measuring 2 stuff`,
                    summary: `summed up 2 beautifully`

                }),
                buildIndicator(3),
                buildIndicator(4),
                buildIndicator(5),
                buildIndicator(6),
                buildIndicator(7),
                buildIndicator(8),
                buildIndicator(9),
                buildIndicator(10),
                buildIndicator(11),
                buildIndicator(12),
                buildIndicator(13),
                buildIndicator(14),
                buildIndicator(15),
                buildIndicator(16),
                buildIndicator(17),
                buildIndicator(18),
                buildIndicator(19),
                buildIndicator(20),
                buildIndicator(21),
                buildIndicator(22),
                buildIndicator(23),
                buildIndicator(24),
                buildIndicator(25),
                buildIndicator(26),
                buildIndicator(27),
                buildIndicator(28),
                buildIndicator(29),
                buildIndicator(30),
            ],
            responses: [
                defaultBpieResponse,
                createBpieResponse({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000003",
                }),
                createBpieResponse({
                    bpieIndicatorId: "00000000-0000-0000-0000-000000000005",
                }),
            ],
            assessmentTeam: "",
            datesCovered: "2022-2025",
            isActive: false,
            districtBpieId: defaultDistrictBpieId,
            district: "Collier",
            districtId: "00000000-0000-0000-0000-000000000001",
            status: DBPIE_ASSESSMENT_STATUS.InProgress.id,
        };
}

export function getDistricts() {
    return [
        {
            districtNumber: "01",
            name: "Alachua",
            districtId : "00000000-0000-0000-0000-000000000001"
        }
    ]
}

export function getDistrictBpiesSummary() {
    return getDistrictBpie();
}
