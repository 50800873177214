import {mockResolveApiCall} from "../../../api/ApiUtility";
import {emptyGuid} from "../../../constants/config";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_ROOT} from "../gmsLocations";
import {API_MESSAGES} from "../gmsConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsCaseNotesApi {
    static getApplicationWithCaseNotes(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}/case-notes`,
                RequestTypes.GET)
        );
    }

    static saveCaseNote(applicationId, caseNoteModel) {
        const isNew = caseNoteModel.caseNoteId === emptyGuid;
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}/case-notes`,
                isNew ? RequestTypes.POST : RequestTypes.PUT,
                JSON.stringify(caseNoteModel),
                isNew ? API_MESSAGES.ADMIN.CASE_NOTE.created : API_MESSAGES.ADMIN.CASE_NOTE.updated)
        );
    }

    static deleteCaseNote(applicationId, caseNoteId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}/case-notes/${caseNoteId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.CASE_NOTE.deleted)
        );
    }

    static exportCaseNotes(applicationId, caseNoteFilter) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}/case-notes/export?startDate=${caseNoteFilter.startDate}&endDate=${caseNoteFilter.endDate}`,
                RequestTypes.GET,
                null,
                API_MESSAGES.ADMIN.CASE_NOTE.downloaded)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsCaseNotesApi {
    static getApplicationWithCaseNotes() {
        return mockResolveApiCall({});
    }

    static saveCaseNote(applicationId, caseNoteModel) {
        const isNew = caseNoteModel.caseNoteId === emptyGuid;
        return mockResolveApiCall({}, isNew ? API_MESSAGES.ADMIN.CASE_NOTE.created : API_MESSAGES.ADMIN.CASE_NOTE.updated);
    }

    static deleteCaseNote() {
        return mockResolveApiCall({}, API_MESSAGES.ADMIN.CASE_NOTE.deleted);
    }

    static exportCaseNotes() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.CASE_NOTE.downloaded);
    }
}

const gmsCaseNotesApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsCaseNotesApi : MockGmsCaseNotesApi;
export default gmsCaseNotesApi;