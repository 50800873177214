/* eslint-disable react/prop-types */
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {EditorUtils} from "@progress/kendo-react-editor";
import React from "react";

export const validateTelerikHtmlConversion = (value, includeAdvancedTools) => {
	let result = value.toString();
	if(!includeAdvancedTools) return result.replace(/<p><\/p>/gi, "");

	result = result.replace(/<p>/gi, "");
	result = result.replace(/<\/p>/gi, "");
	result = result.replace(/<h6>/gi, "<p>");
	result = result.replace(/<\/h6>/gi, "</p>");

	return result;
};

export const convertToTelerikHtml = (value, includeAdvancedTools) => {
	let result = value.toString();
	if(!includeAdvancedTools) return result;
	result = result.replace(/<p>/gi, "<h6>");
	result = result.replace(/<\/p>/gi, "</h6>");

	return result;
};

// eslint-disable-next-line react/display-name
export const createContentWrap = (settings) => (props) => {
	const { view } = props;
	const nodeType = view && view.state.schema.nodes[settings.nodeType];
	const canInsert = view && EditorUtils.canInsert(view.state, nodeType);

	const handleChange = (event) => {
		const classValue = event.target.value.class;
		EditorUtils.insertNode(view, nodeType.create({class: classValue}, view.state.selection.content().content))
	};

	return (
		<DropDownList
			onChange={handleChange}
			disabled={!canInsert}
			{...settings.props}
		/>
	);
};
