import {DueProcessPolicy} from "../components/authorization/policies/DueProcessPolicy";
import {MediationPolicy} from "../components/authorization/policies/MediationPolicy";
import {StateComplaintPolicy} from "../components/authorization/policies/StateComplaintPolicy";
import LocalStorage from "../components/shared/LocalStorage";
import {
    allow,
    convertBehaviorsApiToJsObject
} from "../components/authorization/AuthorizationUtilities";
import {BPIE_SCHOOL_ADMIN, MANAGE} from "../constants/behaviors";
import * as policyEvents from "../constants/policyEvents";
import * as config from "../constants/config";
import {getMockAccounts} from "./AccountMockData";
import {isNullOrUndefined} from "../components/common/commonUtilities";
import {ADMIN, BPIE} from "../constants/contexts";

const tokenUrl = "http://beessgsw.org"

function isBpieSchoolAdminUtility(decodeJwtTokenFunction) {
    const accessToken = LocalStorage.getJwtToken();
    if(!accessToken)
        return false;

    let decodedToken = decodeJwtTokenFunction(accessToken);
    if(!decodedToken)
        return false;

    return decodedToken[`${tokenUrl}/behavior`].includes(`${BPIE}.${BPIE_SCHOOL_ADMIN}`);
}

function isBpieStateManageUserUtility(decodeJwtTokenFunction) {
    const accessToken = LocalStorage.getJwtToken();
    if(!accessToken)
        return false;

    let decodedToken = decodeJwtTokenFunction(accessToken);
    if(!decodedToken)
        return false;

    return decodedToken[`${tokenUrl}/behavior`].includes(`${BPIE}.${MANAGE}`) &&
        isBpieStateUserUtility(decodeJwtTokenFunction);
}

function isBpieStateUserUtility(decodeJwtTokenFunction) {
    const accessToken = LocalStorage.getJwtToken();
    if(!accessToken)
        return false;

    let decodedToken = decodeJwtTokenFunction(accessToken);
    if(!decodedToken)
        return false;

    return decodedToken[`${tokenUrl}/stateuser`] === "true"
        || decodedToken[`${tokenUrl}/isstatebpieuser`] === "true";
}

function isDistrictUserUtility(decodeJwtTokenFunction) {
    const accessToken = LocalStorage.getJwtToken();
    if (!accessToken)
        return true;

    let decodedToken = decodeJwtTokenFunction(accessToken);
    if(!decodedToken)
        return true;

    return decodedToken[`${tokenUrl}/institution_id`] !== undefined;
}

function getUserDetailsUtility(decodeJwtTokenFunction) {
    const accessToken = LocalStorage.getJwtToken();
    if(!accessToken)
        return null;

    return getUserIdentityFromTokenUtility(decodeJwtTokenFunction, accessToken);
}

function getUserIdentityFromTokenUtility(decodeJwtTokenFunction, access_token) {
    const decodedToken = decodeJwtTokenFunction(access_token);

    if(!decodedToken)
        return {};

    const behaviors = convertBehaviorsApiToJsObject(decodedToken[`${tokenUrl}/behavior`]);
    const institution = decodedToken[`${tokenUrl}/institution`];
    const institutionId = decodedToken[`${tokenUrl}/institution_id`];

    return {
        Email: decodedToken["email"],
        Id: decodedToken["sid"],
        Institution: institution === undefined ? null : institution,
        InstitutionId: institutionId === undefined ? null : institutionId,
        Username: decodedToken["sub"],
        IsStateUser: decodedToken[`${tokenUrl}/stateuser`] === "true",
        Behaviors: behaviors,
        FirstName: decodedToken[`${tokenUrl}/firstname`],
        LastName: decodedToken[`${tokenUrl}/lastname`],
    };
}

function isStateUserDrAuthorizedUtility(decodeJwtTokenFunction) {
    return (allow(StateComplaintPolicy, policyEvents.MANAGE)
            || allow(MediationPolicy, policyEvents.MANAGE)
            || allow(DueProcessPolicy, policyEvents.MANAGE))
        && !isDistrictUserUtility(decodeJwtTokenFunction);
}

class ServerAccountUtility {
    static decodeJwtToken(token) {
        if (!token)
            return null

        let base64Url = token.split('.')[1];
        if (!base64Url)
            return null;

        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));
    }

    static isBpieSchoolAdmin() {
        return isBpieSchoolAdminUtility(this.decodeJwtToken);
    }

    static isOnlyBpieSchoolAdmin() {
        const behaviorClaims = LocalStorage.getBehaviorClaims();
        return this.isBpieSchoolAdmin()
            && behaviorClaims.length === 1;
    }

    static isBpieStateManageUser() {
        return isBpieStateManageUserUtility(this.decodeJwtToken);
    }

    static isBpieStateUser() {
        return isBpieStateUserUtility(this.decodeJwtToken);
    }

    static isDistrictUser() {
        return isDistrictUserUtility(this.decodeJwtToken);
    }

    static isFiscalAgentUser() {
        return isDistrictUserUtility(this.decodeJwtToken);
    }

    static getUserDetails() {
        return getUserDetailsUtility(this.decodeJwtToken);
    }

    static getValidatedBpieDistrictId(districtId){
        const isDistrictUser = !this.isBpieStateUser();
        const userDetails = this.getUserDetails();
        return isDistrictUser ? userDetails.InstitutionId : districtId;
    }

    static getUserIdentityFromToken(access_token) {
        return getUserIdentityFromTokenUtility(this.decodeJwtToken, access_token);
    }

    static isStateUserDrAuthorized() {
        return isStateUserDrAuthorizedUtility(this.decodeJwtToken);
    }
}

class MockAccountUtility {
    static decodeJwtToken(token) {
        if (!token)
            return null;

        const userAcct = getMockAccounts().find(a => a.access_token === token);

        if(isNullOrUndefined(userAcct))
            return null;

        const isAdmin = userAcct.behaviors.some(a => a.startsWith(ADMIN));

        return {
            "sub": userAcct.id,
            "jti": "91a5cd9a-fb28-4795-968d-e1eab0805f50",
            "sid": "7eef380a-959e-49e5-bc8c-30679a44129b",
            "email": userAcct.email,
            "http://beessgsw.org/admin": isAdmin !== undefined ? isAdmin : false,
            "http://beessgsw.org/behavior": userAcct.behaviors,
            "http://beessgsw.org/institution": userAcct.institution || undefined,
            "http://beessgsw.org/institution_id": userAcct.institutionId || undefined,
            "nbf": 1549488904,
            "exp": 1549494304,
            "iss": "GswApi",
            "aud": "GswReact"
        }
    }

    static isBpieSchoolAdmin() {
        return isBpieSchoolAdminUtility(this.decodeJwtToken);
    }

    static isOnlyBpieSchoolAdmin() {
        const behaviorClaims = LocalStorage.getBehaviorClaims();
        return this.isBpieSchoolAdmin()
            && behaviorClaims.length === 1;
    }

    static isBpieStateManageUser() {
        return isBpieStateManageUserUtility(this.decodeJwtToken)
    }

    static isBpieStateUser() {
        return isBpieStateUserUtility(this.decodeJwtToken)
    }

    static isDistrictUser() {
        return isDistrictUserUtility(this.decodeJwtToken);
    }

    static isFiscalAgentUser() {
        return isDistrictUserUtility(this.decodeJwtToken);
    }

    static getUserDetails() {
        return getUserDetailsUtility(this.decodeJwtToken);
    }

    static getUserIdentityFromToken(access_token) {
        return getUserIdentityFromTokenUtility(this.decodeJwtToken, access_token);
    }

    static isStateUserDrAuthorized() {
        return isStateUserDrAuthorizedUtility(this.decodeJwtToken);
    }
}

const AccountUtility = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerAccountUtility : MockAccountUtility;
export default AccountUtility;