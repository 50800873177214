import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {
    getListOfPropNames,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import {convertToDate} from "../../../components/common/dateUtilities";
import FormTable from "../../../components/common/FormTable";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import RadioField from "../../../components/common/inputs/RadioField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {
    outputLongDateFormat,
} from "../../../components/shared/sharedDataUtilities";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";
import {inputStyles} from "../../../constants/inputConstants";
import {
    ResolutionProcessOption1Outcomes,
    ResolutionProcessOption3Outcomes,
    ResolutionProcessOption3OutcomesList,
    ResolutionProcessOption3AgreementList,
    ResolutionProcessOptions,
    ResolutionProcessOptionsList,
    DueProcessSections, DueProcessComplaintTypes
} from "../drDueProcessConstants";
import {RadioButtonYesNoList, RadioButtonYesNoObject} from "../../drConstants";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject,
    inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import CollapsibleContainer from "../../../components/common/CollapsibleContainer";
import * as drLocations from "../../drLocations";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import {styles as ActionButton} from "../../../constants/ActionButton";
import {createExtensionDateObject} from "../drDueProcessObjectFactory";

export const DrDueProcessResolutionProcessView = ({
                                                      dueProcess,
                                                      handleDataChanged,
                                                      handleSaveResolutionProcess,
                                                      handleSelectSection,
                                                      history,
                                                      isDistrictUser,
                                                      readOnly,
                                                      resolutionProcess
                                                  }) => {

    const [isAdminSaveButtonVisible, setIsAdminSaveButtonVisible] = useState(false);
    const [isExtensionsDefaultOpen, setIsExtensionsDefaultOpen] = useState(null);
    const [isDirtyExtension, setIsDirtyExtension] = useState([]);

    const resolutionProcessForm = useFormInputObject(resolutionProcess, handleOptionChange);

    function handleOptionChange(event) {
        if (event.target.id === "resolutionProcessOption") {
            for (let option of getListOfPropNames(ResolutionProcessOptions)) {
                if (resolutionProcessForm.resolutionProcessOption.value !== ResolutionProcessOptions[option]) {
                    for (let optionProperty of getListOfPropNames(resolutionProcessForm, option)) {
                        resolutionProcessForm[optionProperty].setValue("");
                    }
                }
            }
        }

        if (event.target.id === "noticeInWriting") {
            resolutionProcessForm.dateOfNoticeInWriting.setValue("");
            resolutionProcessForm.explanationNoticeInWriting.setValue("");
        }

        if ((event.target.id === "reasonForExtension" || event.target.id === "extensionDate") && !isDistrictUser)
        {
            setIsAdminSaveButtonVisible(true);
        }

        handleDataChanged();
    }

    const isDisabled = () => {
        const hasNewExtensionRequest = !isTrimmedStringEmpty(resolutionProcessForm.reasonForExtension.value) && isNullOrUndefined(resolutionProcessForm.extensionDate.value);
        return (hasExtensionDateRequestNotGranted())
            || hasNewExtensionRequest
            || readOnly
            || !isNullOrUndefined(dueProcess.dateSubmitted);
    }

    const isExtensionDisabled = () => {
        return (!isDistrictUser && !readOnly)
            || !isNullOrUndefined(dueProcess.dateSubmitted);
    }

    const hasExtension = () => {
        return resolutionProcessForm.resolutionProcessExtensions && resolutionProcessForm.resolutionProcessExtensions.value?.length > 0;
    };

    const validateForm = (shouldSubmitReport) => {
        clearInputFormErrorFields(resolutionProcessForm);

        if (isTrimmedStringEmpty(resolutionProcessForm.reasonForExtension.value) && !isTrimmedStringEmpty(resolutionProcessForm.extensionDate.value))
            resolutionProcessForm.reasonForExtension.setError("Reason for extension is required <br/> an extension date has been selected.");

        if (resolutionProcessForm.caseNumber.value !== dueProcess.caseNumber)
            resolutionProcessForm.caseNumber.setError("The DOAH Case No. does not match our file. Please verify<br/>the number or contact the bureau’s due process hearing request liaison.");

        if (isNullOrUndefined(resolutionProcessForm.dateReceived.value) || !dueProcess.dateReceived.toString().startsWith(resolutionProcessForm.dateReceived.value.toString()))
            resolutionProcessForm.dateReceived.setError("The date respondent received notice does not match our file. Please<br/>verify the date or contact the bureau’s due process liaison.");

        if (shouldSubmitReport) {
            if (isTrimmedStringEmpty(resolutionProcessForm.noticeInWriting.value))
                resolutionProcessForm.noticeInWriting.setError("Required");
            else if (resolutionProcessForm.noticeInWriting.value.toLowerCase() === RadioButtonYesNoObject.Yes.toLowerCase() && isTrimmedStringEmpty(resolutionProcessForm.dateOfNoticeInWriting.value))
                resolutionProcessForm.dateOfNoticeInWriting.setError("Required");
            else if (resolutionProcessForm.noticeInWriting.value.toLowerCase() === RadioButtonYesNoObject.No.toLowerCase() && isTrimmedStringEmpty(resolutionProcessForm.explanationNoticeInWriting.value))
                resolutionProcessForm.explanationNoticeInWriting.setError("Required");

            if (isTrimmedStringEmpty(resolutionProcessForm.resolutionProcessOption.value))
                resolutionProcessForm.resolutionProcessOption.setError("Required");

            for (let option of getListOfPropNames(ResolutionProcessOptions)) {
                if (resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions[option]) {
                    for (let optionProperty of getListOfPropNames(resolutionProcessForm, option)) {
                        if (isNullOrUndefined(resolutionProcessForm[optionProperty].value) || isTrimmedStringEmpty(resolutionProcessForm[optionProperty].value)) {
                            if (resolutionProcessForm.option3Outcome.value === ResolutionProcessOption3OutcomesList.Outcome2 && optionProperty === resolutionProcessForm.option3AgreementType.name) continue;
                            if (optionProperty === resolutionProcessForm.option1DateHeldBeyondDeadlineExplanation.name
                                || optionProperty === resolutionProcessForm.option1UseOfAttorneyDifferencesExplanation.name) continue;
                            resolutionProcessForm[optionProperty].setError("Required");
                        }
                    }
                }
            }

            if (resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option1
                && isTrimmedStringEmpty(resolutionProcessForm.option1DateHeldBeyondDeadlineExplanation.value)
                && isDateHeldBeyondDeadlineExplanationNeeded())
                resolutionProcessForm.option1DateHeldBeyondDeadlineExplanation.setError("Required");

            if (resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option1
                && isTrimmedStringEmpty(resolutionProcessForm.option1UseOfAttorneyDifferencesExplanation.value)
                && isUseOfAttorneyDifferenceNeeded())
                resolutionProcessForm.option1UseOfAttorneyDifferencesExplanation.setError("Required");
        }

        let isValid = inputFormIsValid(resolutionProcessForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleClickCancel = (event) => {
        handleSelectSection(event, DueProcessSections.ResolutionProcess, true);
    };

    const saveStatusReport = async (event, shouldSubmitReport) => {
        event.preventDefault();

        if (validateForm(shouldSubmitReport)) {
            const resolutionProcess = convertFormInputObjectToObject(resolutionProcessForm);
            if (!isTrimmedStringEmpty(resolutionProcess.reasonForExtension))
                resolutionProcess.resolutionProcessExtensions = [
                    ...(resolutionProcess.resolutionProcessExtensions || []),
                    createExtensionDateObject({
                        resolutionProcessId: resolutionProcess.resolutionProcessId,
                        reasonForExtension: resolutionProcess.reasonForExtension,
                        extensionDate: resolutionProcess.extensionDate
                    })
                ];


            resolutionProcess.resolutionProcessId = dueProcess.resolutionProcess?.resolutionProcessId ?? emptyGuid;

            if(await handleSaveResolutionProcess(resolutionProcess, shouldSubmitReport)) {
                setIsAdminSaveButtonVisible(false);
                setIsDirtyExtension([]);
                handleDataChanged(false);
            }
        }
    };

    const handleClickReviewPreviousNextStatusReport = (event, previousNextDueProcessId, previousNextDueDistrictId) => {
        event.preventDefault();
        history.push(drLocations.DUE_PROCESS_RESOLUTION_PROCESS.getUrl(dueProcess.dateWindowId, previousNextDueDistrictId, previousNextDueProcessId));
    };

    const isDateHeldBeyondDeadlineExplanationNeeded = () => {
        const dateHeld = convertToDate(resolutionProcessForm.option1DateHeld.value);
        const resolutionMeetingDeadline = convertToDate(dueProcess.resolutionMeetingDeadline);
        return dateHeld > resolutionMeetingDeadline;
    };

    const getDateHeldBeyondDeadlineExplanationLabel = () => {
        const daysPastResolutionRequirement = dueProcess.complaintType === DueProcessComplaintTypes.Expedited ? "7" : "15";
        const formattedDateHeld = outputLongDateFormat(resolutionProcessForm.option1DateHeld.value);

        return `4a-1. According to the DOAH Case Management Order dated ${dueProcess.caseManagementOrderDateOutput}, the resolution meeting deadline was ${dueProcess.resolutionMeetingDeadlineOutput}. You entered a Date Held of ${formattedDateHeld}, which is beyond the ${daysPastResolutionRequirement}-calendar-day resolution meeting requirement. Please provide a brief explanation.`;
    }

    const isUseOfAttorneyDifferenceNeeded = () => {
        if (isNullOrUndefined(resolutionProcessForm.option1ParentAttorney.value) || isNullOrUndefined(resolutionProcessForm.option1DistrictAttorney.value) ||
            isTrimmedStringEmpty(resolutionProcessForm.option1ParentAttorney.value) || isTrimmedStringEmpty(resolutionProcessForm.option1DistrictAttorney.value))
            return false;

        return resolutionProcessForm.option1ParentAttorney.value.toString() === RadioButtonYesNoObject.No
            && resolutionProcessForm.option1DistrictAttorney.value.toString() === RadioButtonYesNoObject.Yes;
    };

    const findExtension = (extensionId) => {
        return resolutionProcessForm.resolutionProcessExtensions.value.find((extension) => extension.resolutionProcessExtensionId === extensionId);
    }

    const handleDateChanged = (event, extensionId) => {
        const extension = findExtension(extensionId);
        extension.extensionDate = event.target.value;
        extension.isChanged = true;
        setIsAdminSaveButtonVisible(true);
        resolutionProcessForm.resolutionProcessExtensions.setValue([...resolutionProcessForm.resolutionProcessExtensions.value]);

        if(!isDirtyExtension.includes(extensionId)) {
            isDirtyExtension.push(extensionId);
            setIsDirtyExtension([...isDirtyExtension]);
        }
    }

    const handleRemoveExtensionClick = (extensionId) => {
        if (!confirm("Are you sure you want to remove this extension?"))
            return;

        const remainingExtensions = resolutionProcessForm.resolutionProcessExtensions.value.filter((extension) => extension.resolutionProcessExtensionId !== extensionId);
        resolutionProcessForm.resolutionProcessExtensions.setValue(remainingExtensions)

        if(!isDistrictUser)
            setIsAdminSaveButtonVisible(true);
    }

    const isDatePickerDisabled = (extensionId) => {
        const extension = findExtension(extensionId);
        if (isDirtyExtension.includes(extensionId))
            return false;

        const isDatePickerDisabledForAdmin = !isDistrictUser && !isNullOrUndefined(extension.extensionDate);
        return isDatePickerDisabledForAdmin || isDistrictUser;
    }

    const shouldDisplayRemoveExtensionButton = (extensionId) => {
        const extension = findExtension(extensionId);
        const adminDisplay = !isNullOrUndefined(extension) && !isDistrictUser && !isNullOrUndefined(extension.extensionDate);
        const districtDisplay = !isNullOrUndefined(extension) && !readOnly || isDistrictUser && isNullOrUndefined(extension.extensionDate);
        return adminDisplay || districtDisplay;
    }

    const hasExtensionDateRequestNotGranted = () => {
        return resolutionProcessForm.resolutionProcessExtensions.value?.some((extension) => {
            return isNullOrUndefined(extension.extensionDate) && !isTrimmedStringEmpty(extension.reasonForExtension);
        });
    };

    const shouldOpenExtensionRequest = () => {
        return !isDistrictUser && hasExtensionDateRequestNotGranted();
    }

    useEffect(() => {
        if(isExtensionsDefaultOpen) return;

        setIsExtensionsDefaultOpen(shouldOpenExtensionRequest());
    }, []);

    useEffect(() => {
        resolutionProcessForm.dueDate.setValue(dueProcess.resolutionProcessDueDate);

        if (!resolutionProcessForm.resolutionProcessExtensions?.value || resolutionProcessForm.resolutionProcessExtensions?.value.length === dueProcess.resolutionProcess?.resolutionProcessExtensions?.length)
            return;

        resolutionProcessForm.resolutionProcessExtensions.setValue(dueProcess.resolutionProcess?.resolutionProcessExtensions);
        resolutionProcessForm.extensionDate.setValue("");
        resolutionProcessForm.reasonForExtension.setValue("");

    }, [dueProcess.resolutionProcess?.resolutionProcessExtensions]);

    return (
        <>
            {
                <>
                    <h3>General Information</h3>

                    <p>The Bureau of Exceptional Education and Student Services (bureau) is required to report
                        compliance with the associated due process complaint timelines
                        described in sections 300.508, 300.510, and 300.532, Title 34, of the Code of Federal
                        Regulations (C.F.R.). The information you provide below will assist
                        the bureau in meeting its reporting requirements and improving system efficiency.</p>

                    <p>Please submit your responses within 30 calendar days of receipt of the due process complaint, or
                        no later than <strong>{resolutionProcessForm.dueDate.value}</strong>. </p>

                    <p>If you have any questions or require assistance with completing this form, please contact a due
                        process hearing request
                        liaison at <a href={`mailto:DueProcess@fldoe.org`}>DueProcess@fldoe.org</a> or 850-245-0475.</p>

                    <CollapsibleContainer trigger="Helpful Information">
                        <h3>Response to a Due Process Hearing Request</h3>
                        <p>The requirements of 34 C.F.R. § 300.508(e), indicate that, if the school district has not
                            sent a
                            prior written notice under 34 C.F.R. § 300.503 to the
                            parent regarding the subject matter contained in the parent’s due process complaint, the
                            school
                            district must, within 10 calendar days of receiving the due process complaint, send to the
                            parent a response that includes:</p>

                        <ul>
                            <li>An explanation of why the agency proposed or refused to take the action raised in the
                                due
                                process complaint;
                            </li>
                            <li>A description of other options that the IEP team considered and the reasons why those
                                options
                                were rejected;
                            </li>
                            <li>A description of each evaluation procedure, assessment, record, or report the agency
                                used as
                                the basis for the proposed or refused action; and
                            </li>
                            <li>A description of the other factors that are relevant to the agency’s proposed or refused
                                action.
                            </li>
                        </ul>

                        <p>A response by a school district shall not be construed to preclude the school district from
                            asserting that the parent’s due process complaint was insufficient, where appropriate.</p>

                        <h3>Other Party Response to a Due Process Hearing Request</h3>

                        <p>The requirements of 34 C.F.R. § 300.508(f), indicate that, except as provided in the
                            preceding
                            section, the party receiving a due process complaint must,
                            within 10 calendar days of receiving the due process complaint, send to the other party a
                            response that specifically addresses the issues raised in the due process complaint.</p>

                        <h3>Resolution Process</h3>
                        <p>The requirements of 34 C.F.R. § 300.510(a), indicate that, within 15 calendar days of
                            receiving
                            notice of the parent’s due process complaint, and prior to
                            the initiation of a due process hearing request, the school district must convene a
                            resolution
                            meeting
                            with the parent to discuss the basis of the complaint and
                            to provide the district with the opportunity to resolve the dispute.</p>

                        <p>The meeting must include the relevant member or members of the IEP team who have specific
                            knowledge of the facts identified in the due process complaint and
                            a representative of the district who has decision-making authority on behalf of that agency.
                            It
                            may not include an attorney of the district unless the parent
                            is accompanied by an attorney.</p>

                        <p>The requirements of 34 C.F.R. § 300.510(b), indicate that, if the district has not resolved
                            the
                            due process complaint to the satisfaction of the parent within
                            30 calendar days of the receipt of the due process complaint, the due process hearing
                            request
                            may
                            occur.</p>

                        <p>The requirements of 34 C.F.R. § 300.510(c), indicate that the 45-day timeline for issuing a
                            final
                            decision begins at the expiration of this 30-day period, unless
                            adjusted for one of the following exceptions: The 45-day timeline begins the day after one
                            of
                            the following events:</p>
                        <ul>
                            <li>Both parties agree in writing to waive the resolution meeting;</li>
                            <li>After either the mediation or resolution meeting starts but before the end of the 30-day
                                period, the parties agree in writing that no agreement is possible;
                            </li>
                            <li>If both parties agree in writing to continue the mediation at the end of the 30-day
                                resolution period, but later, the parent or district withdraws from the mediation
                                process.
                            </li>
                        </ul>

                        <h3>Expedited Due Process Hearing Requests</h3>
                        <p>Under 34 C.F.R. § 300.532, the parent of a student with a disability who disagrees with any
                            decision regarding placement under §§ 300.530 and 300.531, or the
                            manifestation determination under § 300.530(e), or a district that believes that maintaining
                            the
                            current placement of the student is substantially likely to
                            result in injury to the student or others, may appeal the decision by filing an expedited
                            due
                            process complaint requesting a hearing. In these cases, the
                            resolution meeting must be held within seven (7) calendar days, with resolution required
                            within
                            15 calendar days.</p>
                    </CollapsibleContainer>

                    <CollapsibleContainer trigger="Extension Request" open={isExtensionsDefaultOpen}>
                        <h3>Extension Information</h3>
                        <FormTable>
                            {
                                hasExtension() &&
                                resolutionProcessForm.resolutionProcessExtensions.value.map((extension, index) => {
                                    return (
                                        <FieldsetLayout legend={`Timeline Extended`}
                                                        key={extension.resolutionProcessExtensionId}>
                                            <div className={"removeExtensionContainer"}>
                                                {
                                                    shouldDisplayRemoveExtensionButton(extension.resolutionProcessExtensionId) &&
                                                    <span className={ActionButton.position.RIGHT}>
                                                        <Button
                                                            label={"Remove Extension"}
                                                            name={"btnRemoveExtension"}
                                                            buttonType={ButtonTypes.REMOVE}
                                                            showLabel={false}
                                                            onClick={() => handleRemoveExtensionClick(extension.resolutionProcessExtensionId)}
                                                        />
                                                    </span>
                                                }
                                                <DateTimePickerField
                                                    name={`extensionDate`}
                                                    label={`Extension Date #${index + 1}`}
                                                    showLabel
                                                    value={extension.extensionDate}
                                                    disabled={isDatePickerDisabled(extension.resolutionProcessExtensionId)}
                                                    onChange={(event) => handleDateChanged(event, extension.resolutionProcessExtensionId)}
                                                    medium={6}
                                                />
                                            </div>
                                            <DisplayField
                                                name={`reasonForExtension`}
                                                label={`Reason`}
                                                columnsMedium={6}
                                                showLabel
                                            >
                                                <p>{extension.reasonForExtension}</p>
                                            </DisplayField>
                                        </FieldsetLayout>);
                                })
                            }
                            {
                                <>
                                    {
                                        !isDistrictUser &&
                                        <DateTimePickerField
                                            name="extensionDate"
                                            label="If extending the timeline, provide the new Date"
                                            showLabel
                                            {...resolutionProcessForm.extensionDate}
                                            medium={6}
                                            disabled={isExtensionDisabled()}
                                        />
                                    }
                                    {
                                        <TextField
                                            name="reasonForExtension"
                                            label="Reason for Extension"
                                            showLabel
                                            {...resolutionProcessForm.reasonForExtension}
                                            columnsMedium={6}
                                            disabled={isExtensionDisabled()}
                                        />
                                    }
                                </>
                            }
                        </FormTable>
                    </CollapsibleContainer>

                    <FormTable>
                        <TextField
                            label={"1. DOAH Case No. (e.g., <span class='no-break'>00-000000E</span> or <span class='no-break'>00-000000EDM</span>):"}
                            labelClass={`form-list-indent-1`}
                            showLabel
                            {...resolutionProcessForm.caseNumber}
                            columnsMedium={6}
                            disabled={readOnly}
                        />

                        <DateTimePickerField
                            label={"2. Date respondent received notice of due process complaint:"}
                            labelClass={"form-list-indent-1"}
                            helpText={"The receipt date indicated in the most recent<br/>Case Management Order issued by DOAH."}
                            showLabel
                            {...resolutionProcessForm.dateReceived}
                            medium={6}
                            disabled={readOnly}
                        />

                        <RadioField
                            legend={`3. The district has sent a prior written notice, or has responded in writing, to the parent regarding the subject matter in the due process hearing request.`}
                            legendClass={'form-list-indent-1'}
                            showLegend
                            options={RadioButtonYesNoList}
                            disabled={isDisabled()}
                            {...resolutionProcessForm.noticeInWriting}
                        />

                        {
                            resolutionProcessForm.noticeInWriting.value.toLowerCase() === RadioButtonYesNoObject.Yes.toLowerCase() &&
                            <DateTimePickerField
                                label={`3a. Date of response:`}
                                labelClass={`form-list-indent-2`}
                                showLabel
                                {...resolutionProcessForm.dateOfNoticeInWriting}
                                medium={6}
                                disabled={isDisabled()}
                            />
                        }

                        {
                            resolutionProcessForm.noticeInWriting.value.toLowerCase() === RadioButtonYesNoObject.No.toLowerCase() &&
                            <TextAreaEditor
                                label={"3a. Please provide an explanation:"}
                                labelClass={`form-list-indent-2`}
                                showLabel
                                {...resolutionProcessForm.explanationNoticeInWriting}
                                disabled={isDisabled()}
                            />
                        }

                        <RadioField
                            legend={`4. Resolution Process Outcome. Please select one option:`}
                            legendClass={`form-list-indent-1`}
                            options={ResolutionProcessOptionsList}
                            {...resolutionProcessForm.resolutionProcessOption}
                            disabled={isDisabled()}
                            showLegend
                        />

                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option1 &&
                            <>
                                <DateTimePickerField
                                    label={"4a. Date held:"}
                                    labelClass={`form-list-indent-2`}
                                    showLabel
                                    {...resolutionProcessForm.option1DateHeld}
                                    medium={6}
                                    disabled={isDisabled()}
                                />

                                {
                                    isDateHeldBeyondDeadlineExplanationNeeded() &&
                                    <TextAreaEditor
                                        label={getDateHeldBeyondDeadlineExplanationLabel()}
                                        labelClass={`form-list-indent-4`}
                                        showLabel
                                        {...resolutionProcessForm.option1DateHeldBeyondDeadlineExplanation}
                                        disabled={isDisabled()}
                                    />
                                }

                                <RadioField
                                    legend={`4b. Outcome`}
                                    legendClass={`form-list-indent-2`}
                                    options={ResolutionProcessOption1Outcomes}
                                    {...resolutionProcessForm.option1Outcome}
                                    disabled={isDisabled()}
                                    showLegend
                                />

                                <RadioField
                                    legend={`4c. The parent was represented by an attorney.`}
                                    legendClass={`form-list-indent-2`}
                                    options={RadioButtonYesNoList}
                                    {...resolutionProcessForm.option1ParentAttorney}
                                    disabled={isDisabled()}
                                    showLegend
                                    direction={inputStyles.radio.direction.HORIZONTAL}
                                />

                                <RadioField
                                    legend={`4d. The district was represented by an attorney.`}
                                    legendClass={`form-list-indent-2`}
                                    options={RadioButtonYesNoList}
                                    {...resolutionProcessForm.option1DistrictAttorney}
                                    disabled={isDisabled()}
                                    showLegend
                                    direction={inputStyles.radio.direction.HORIZONTAL}
                                />

                                {
                                    isUseOfAttorneyDifferenceNeeded() &&
                                    <TextAreaEditor
                                        label={`4d-1. According to 34 C.F.R. § 300.510(a)(1)(ii), a resolution meeting may not include an attorney of the LEA unless the parent is accompanied by an attorney. Please provide a brief explanation.`}
                                        labelClass={`form-list-indent-4`}
                                        showLabel
                                        {...resolutionProcessForm.option1UseOfAttorneyDifferencesExplanation}
                                        disabled={isDisabled()}
                                    />
                                }

                                <DisplayField label={`Option 1 Hint`} name={`option1Hint`} showLabel={false}>
                                    <p><em>Hint:<br/>
                                        A <strong>written settlement agreement</strong> is a legally binding written
                                        document,
                                        signed by the parent and a representative of the public agency, specifying the
                                        resolution of the dispute that formed the basis for a due process complaint
                                        arrived
                                        at
                                        in a resolution meeting. A written settlement agreement is one that fully
                                        resolves
                                        all
                                        issues of the due process complaint and negates the need for a due process
                                        hearing
                                        request.</em>
                                    </p>

                                    <p><em>If a written settlement agreement is finalized after a resolution meeting but
                                        during the 30-day resolution period that fully resolves all issues of the
                                        due process complaint and negates the need for a due process hearing request,
                                        the
                                        district should select &quot;a written settlement
                                        agreement <strong>was</strong> reached&quot;.</em>
                                    </p>
                                </DisplayField>
                            </>
                        }

                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option2 &&
                            <DateTimePickerField
                                label={"4a. Date of waiver:"}
                                labelClass={`form-list-indent-2`}
                                showLabel
                                {...resolutionProcessForm.option2DateOfWaiver}
                                medium={6}
                                disabled={isDisabled()}
                            />
                        }

                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option3 &&
                            <>
                                <DateTimePickerField
                                    label={"4a. Date held:"}
                                    labelClass={`form-list-indent-2`}
                                    showLabel
                                    {...resolutionProcessForm.option3DateHeld}
                                    medium={6}
                                    disabled={isDisabled()}
                                />

                                <RadioField
                                    legend={`4b. Outcome`}
                                    legendClass={`form-list-indent-2`}
                                    options={ResolutionProcessOption3Outcomes}
                                    {...resolutionProcessForm.option3Outcome}
                                    disabled={isDisabled()}
                                    showLegend
                                />

                                {
                                    resolutionProcessForm.option3Outcome.value === ResolutionProcessOption3OutcomesList.Outcome1 &&
                                    <RadioField
                                        legend={`4b-1. Type of agreement reached`}
                                        legendClass={`form-list-indent-4`}
                                        options={ResolutionProcessOption3AgreementList}
                                        {...resolutionProcessForm.option3AgreementType}
                                        disabled={isDisabled()}
                                        showLegend
                                    />
                                }

                                <RadioField
                                    legend={`4c. The parent was represented by an attorney.`}
                                    legendClass={`form-list-indent-2`}
                                    options={RadioButtonYesNoList}
                                    {...resolutionProcessForm.option3ParentAttorney}
                                    disabled={isDisabled()}
                                    showLegend
                                    direction={inputStyles.radio.direction.HORIZONTAL}
                                />

                                <RadioField
                                    legend={`4d. The district was represented by an attorney.`}
                                    legendClass={`form-list-indent-2`}
                                    options={RadioButtonYesNoList}
                                    {...resolutionProcessForm.option3DistrictAttorney}
                                    disabled={isDisabled()}
                                    showLegend
                                    direction={inputStyles.radio.direction.HORIZONTAL}
                                />
                            </>
                        }

                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option5 &&
                            <DateTimePickerField
                                label={"4a. Date withdrawn or dismissed:"}
                                labelClass={`form-list-indent-2`}
                                showLabel
                                {...resolutionProcessForm.option5DateWithdrawnOrDismissed}
                                medium={6}
                                disabled={isDisabled()}
                            />
                        }

                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option6 &&
                            <>
                                <DateTimePickerField
                                    label={"4a. Date dismissed:"}
                                    labelClass={`form-list-indent-2`}
                                    showLabel
                                    {...resolutionProcessForm.option6DateWithdrawnOrDismissed}
                                    medium={6}
                                    disabled={isDisabled()}
                                />

                                <TextAreaEditor
                                    label={"4b. Please describe the extent of reasonable attempts the school district made to hold the resolution meeting and the outcome:"}
                                    labelClass={`form-list-indent-2`}
                                    showLabel
                                    {...resolutionProcessForm.option6DescriptionOfAttemptsMadeByDistrict}
                                    disabled={isDisabled()}
                                />
                            </>
                        }
                        {
                            resolutionProcessForm.resolutionProcessOption.value === ResolutionProcessOptions.Option7 &&
                            <TextAreaEditor
                                label={"4a. Please specify:"}
                                labelClass={`form-list-indent-2`}
                                showLabel
                                {...resolutionProcessForm.option7OtherValue}
                                disabled={isDisabled()}
                            />
                        }
                    </FormTable>
                </>
            }
            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button
                        name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SUBMIT}
                        onClick={(event) => saveStatusReport(event, false)}
                    />
                    <Button
                        name="btnSubmit"
                        label="Save and Submit Report"
                        buttonType={ButtonTypes.SUBMIT}
                        onClick={(event) => saveStatusReport(event, true)}
                        disabled={isDisabled()}
                    />
                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                </ButtonBar>
            }
            {
                !isDistrictUser &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button
                        name="btnPreviousNeedingReview"
                        label="Previous Needing Review"
                        buttonType={ButtonTypes.PREVIOUS}
                        onClick={(event) => handleClickReviewPreviousNextStatusReport(event, dueProcess.previousResolutionProcessNeedingReviewId, dueProcess.previousResolutionProcessNeedingReviewDistrictId)}
                        disabled={isNullOrUndefined(dueProcess.previousResolutionProcessNeedingReviewId)}
                    />
                    {
                        isAdminSaveButtonVisible &&
                        <Button
                            name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SUBMIT}
                            onClick={(event) => saveStatusReport(event, false)}
                        />
                    }
                    <Button
                        name="btnNextNeedingReview"
                        label="Next Needing Review"
                        buttonType={ButtonTypes.NEXT}
                        onClick={(event) => handleClickReviewPreviousNextStatusReport(event, dueProcess.nextResolutionProcessNeedingReviewId, dueProcess.nextResolutionProcessNeedingReviewDistrictId)}
                        disabled={isNullOrUndefined(dueProcess.nextResolutionProcessNeedingReviewId)}
                    />
                </ButtonBar>
            }
        </>
    );
};

DrDueProcessResolutionProcessView.propTypes =
    {
        dueProcess: PropTypes.object.isRequired,
        handleDataChanged: PropTypes.func.isRequired,
        handleSelectSection: PropTypes.func.isRequired,
        handleSaveResolutionProcess: PropTypes.func.isRequired,
        history: PropTypes.object.isRequired,
        isDistrictUser: PropTypes.bool.isRequired,
        readOnly: PropTypes.bool.isRequired,
        resolutionProcess: PropTypes.object.isRequired
    };

export default DrDueProcessResolutionProcessView;