import * as apiParameters from "./apiParameters";

//ACCOUNT
export const ACCOUNT_REQUEST_PASSWORD_RESET = "An email has been sent to the specified address.";
export const ACCOUNT_RESET_PASSWORD = "Password successfully changed.";

//SHARED DATA
export const SHARED_DATA_ADD_INSTITUTION = "The institution was added successfully.";
export const SHARED_DATA_UPDATE_INSTITUTION = "The institution was updated successfully.";
export const SHARED_DATA_DELETE_INSTITUTION = "The institution was deleted successfully.";

export const SHARED_DATA_ADD_INDICATOR = "The indicator was added successfully.";
export const SHARED_DATA_UPDATE_INDICATOR = "The indicator was updated successfully.";
export const SHARED_DATA_DELETE_INDICATOR = "The indicator was deleted successfully.";

export const SHARED_DATA_SAVE_SYSTEM_MESSAGE = "The system message was saved successfully.";
export const SHARED_DATA_SAVE_SYSTEM_ACCESS_RESTRICTION = "The system access was saved successfully.";

//STRATEGIC PLAN ADMIN
export const STRATEGIC_PLAN_ADD_GOAL = "The goal were successfully added.";
export const STRATEGIC_PLAN_DELETE_GOAL = "The goal were successfully deleted.";
export const STRATEGIC_PLAN_UPDATE_GOAL = "The goal were successfully updated.";

export const STRATEGIC_PLAN_ADD_PLAN = "The plan was added successfully.";
export const STRATEGIC_PLAN_COPY_PLAN = "The plan was copied successfully.";
export const STRATEGIC_PLAN_DELETE_PLAN = "The plan was deleted successfully.";
export const STRATEGIC_PLAN_UPDATE_PLAN = "The plan was updated successfully.";

export const STRATEGIC_PLAN_ADD_RESOURCE = "The resource was added successfully.";
export const STRATEGIC_PLAN_DELETE_RESOURCE = "The resource was deleted successfully.";
export const STRATEGIC_PLAN_UPDATE_RESOURCE = "The resource was updated successfully.";

//STRATEGIC PLAN
export const STRATEGIC_PLAN_ADD_STRATEGY = "The strategy was added successfully.";
export const STRATEGIC_PLAN_DELETE_STRATEGY = "The strategy was deleted successfully.";

export const STRATEGIC_PLAN_SAVE_INDICATOR = "The indicator was successfully saved.";
export const STRATEGIC_PLAN_DELETE_INDICATOR = "The indicator and its data were successfully deleted.";

export const STRATEGIC_PLAN_ADD_ACTION = "The action was successfully added.";
export const STRATEGIC_PLAN_DELETE_ACTION = "The action was successfully deleted.";
export const STRATEGIC_PLAN_SAVE_ACTION = "The action was successfully saved.";

export const STRATEGIC_PLAN_ADD_SUBACTION = "The sub-action was successfully added.";
export const STRATEGIC_PLAN_DELETE_SUBACTION = "The sub-action was successfully deleted.";
export const STRATEGIC_PLAN_UPDATE_SUBACTION = "The sub-action was successfully saved.";

export const STRATEGIC_PLAN_SAVE_GOALS = "The goals were successfully saved.";

export const STRATEGIC_PLAN_SAVE_TEAM_MEMBERS = "The team members were successfully saved.";

//USER
export const USER_PASSWORD_RESET_EMAIL = "The password reset email was successfully sent.";

export const USER_DELETED = "The user was successfully soft deleted. This means the user is still in the database.";
export const USER_REACTIVATED = "The user was successfully reactivated and can now log back in.";
export const USER_SAVED = "The user was successfully saved.";

export const INSTITUTIONTEAMMEMBER_SAVED = "The team member district associations were successfully saved.";

//SURVEY ADMIN
export const SURVEY_SAVE_DETAILS = "The details were successfully saved.";
export const SURVEY_SAVE_ORDER = "The order was successfully saved.";
export const SURVEY_PUBLISHED = "The survey was successfully published.";
export const SURVEY_UNPUBLISHED = "The survey was successfully unpublished.";
export const SURVEY_CLOSED = "The survey was successfully closed.";
export const SURVEY_COPIED = "The survey was successfully copied.";

export const SURVEY_ADD_ELEMENTS = "The survey elements were added successfully.";
export const SURVEY_SAVE_ELEMENTS = "The survey elements were saved successfully.";
export const SURVEY_CLONE_ELEMENT = "The survey element was cloned successfully.";
export const SURVEY_DELETE_ELEMENTS = "The survey elements were deleted successfully.";

export const SURVEY_REPORT_DOWNLOADED = "Report successfully downloaded.";
export const SAMPLE_RESPONDENT_IMPORT_FILE_DOWNLOADED = "Sample respondent import file successfully downloaded.";

export const SURVEY_IMPORT_RESPONDENTS = "The respondents were successfully imported.";
export const SURVEY_REMIND_RESPONDENTS = "Reminder emails were successfully sent.";
export const SURVEY_UNSUBMIT_RESPONDENT = "The respondent's survey was unsubmitted successfully.";
export const SURVEY_DELETE_RESPONDENT = "The survey respondent was deleted successfully.";

//SPP REVIEW
export const SPP_REVIEW_SAVE_COMMENT = "The comment was successfully saved.";
export const SPP_REVIEW_DELETE_APPROVAL_DOCUMENT = "The district's approval document was successfully deleted.";
export const SPP_REVIEW_DELETE_PREAPPROVAL_DOCUMENT = "The district's pre-approval document was successfully deleted.";
export const SPP_REVIEW_DELETE_CERTIFICATION_DOCUMENT = "The distric's approval letter was successfully deleted.";

//SPP ADMIN
export const SPP_ADMIN_REPORT_DOWNLOADED = "Report successfully downloaded.";
export const SPP_ADMIN_DOCUMENT_COPIED = "Document successfully cloned for the new year.";
export const SPP_ADMIN_RESPONSES_COPIED = "Responses successfully copied from the previous year.";
export const SPP_ADMIN_TOGGLE_STATUS = "Document status successfully updated.";

//SPP
export const spp_api = {
	element: {
		ADDED: `Element successfully added.`,
		DELETED: `Element successfully deleted.`,
		SAVED: `Element successfully saved.`,
		ORDERED: `Element successfully ordered.`
	},
	response: {
		SUBMIT: `Your responses were successfully submitted.`,
		READY: `Your responses were marked as Ready to Submit.`
	},
	copyStrategy: {
		SAVE: `Your copy strategies were successfully saved.`
	}
};

//FILES
export const files_api = {
	upload: {
		SUCCESS: 'File uploaded successfully.'
	},
	download: {
		SUCCESS: 'File downloaded successfully.'
	},
	delete: {
		SUCCESS: 'File deleted.'
	}
};

//MONITORING FOR PERFORMANCE
export const monitoring_performance_api = {
	response: {
		APPROVE: `The action response was marked reviewed.`,
		REJECT:  `The action response was marked needs revisions.`,
		RESET:   `The action response was marked submitted.`,
		CREATE: `Your response was successfully saved.`,
		UPDATE: `Your response was successfully updated.`,
		SUBMIT: `Your response was successfully submitted.`
	},
	action: {
		CREATE: `The action was successfully added.`,
		DELETE: `The action was successfully removed.`,
		SUBMIT: `The action was successfully submitted.`,
		UPDATE: `The action was successfully updated.`,
		COMMENT: "The comment was successfully saved."
	},
	indicator: {
		CREATE: `The indicator was successfully added.`,
		UPDATE: `The indicator was successfully updated.`,
		DELETE: `The indicator was successfully removed.`,
	},
	assessment: {
		DISTRICT_ACCESS: `District access was successfully updated.`,
		DETAILS_UPDATED: `Assessment details were successfully updated.`,
	}
};

//MONITORING FOR COMPLIANCE
export const monitoring_compliance_api = {
	protocol: {
		CREATE: 'The protocol requirement was successfully added.',
		SAVE: 'The protocol requirements were successfully updated.'
	},
	assessment: {
		CREATE: 'The compliance assessment was successfully created.',
		UPDATE: 'The compliance assessment was successfully updated.',
		DELETE: 'The compliance assessment was successfully removed.',
		VALIDATED: 'The validation has been submitted.',
		NOTES_UPDATED: 'The validation notes have been updated.',
		UNSUBMIT: 'The assessment has been unsubmitted.',
		VALIDATION_UNSUBMIT: 'The validation has been unsubmitted.',
		REASSESSMENT_UNSUBMIT: 'The reassessment has been unsubmitted.',
		REASSESSMENT_SAVE: 'The reassessment was successfully saved.',
	},
	studentRecord: {
		SAVE: 'The student record was successfully saved.',
		DELETE: 'The student record was successfully removed.',
		REMOVEVALIDATION: 'The validation for the student record was successfully removed.',
		SUBMIT: 'Student records have been submitted.',
		UNSUBMIT: 'The student record has been unsubmitted.',
	},
	activity: {
        CREATE: `The activity was successfully added.`,
        DELETE: `The activity was successfully removed.`,
        SUBMIT: `The activities were successfully submitted.`,
        UPDATE: `The activity was successfully updated.`,
		APPROVE: `The activity was marked approved.`,
		REJECT:  `The activity was marked needs revisions.`,
		RESET:   `The activity was marked submitted.`,
	},
	activityResult: {
		SAVED: `The activity result was successfully saved.`,
		SUBMIT: `The activity result was successfully submitted.`
	},
	review: {
        SAVED: `Your activity review was successfully saved.`
	},
	studentCorrectionResults: {
		SAVE: 'The student corrections were successfully saved.',
		SUBMIT: `Your completed student specific corrections were successfully submitted.`,
		REJECT:  `The student specific correction was marked needs revisions.`,
		APPROVE: `The student specific correction was marked reviewed.`,
		RESET:   `The student specific correction was marked submitted.`
	},
	matrixCorrectionResults: {
		SAVE: 'The matrix corrections were successfully saved.',
		SUBMIT: `Your completed matrix specific corrections were successfully submitted.`,
		REJECT:  `The matrix specific correction was marked needs revisions.`,
		APPROVE: `The matrix specific correction was marked reviewed.`,
		RESET:   `The matrix specific correction was marked submitted.`
	},
	auditHistoryComments: {
		SAVE: "The comment was successfully saved."
	},
	caseNote: {
		CREATE: `The case note was successfully added.`,
		DELETE: `The case note was successfully removed.`,
		UPDATE: `The case note was successfully updated.`,
		DOWNLOAD: `The case notes were successfully downloaded.`
	},

};

//Dispute Resolution
export const dr_api = {
	state_complaints: {
		ADDED: `State Complaint successfully added.`,
		DELETED: `State Complaint successfully deleted.`,
		SAVED: `State Complaint successfully saved.`,
		intake: {
			SAVED: `State Complaint Intake response form successfully saved.`,
			RESPONSE_UPDATED: `State Complaint Intake response successfully updated.`,
			RESPONSE_DELETED: `State Complaint Intake response successfully deleted.`
		},
		timeline: {
			SAVED: `State Complaint Timeline successfully saved.`
		},
		action: {
			DELETED: `State Complaint District Action successfully deleted.`,
			SAVED: `State Complaint District Action successfully saved.`
		},
		issue: {
			DELETED: `State Complaint District Issue successfully deleted.`,
			SAVED: `State Complaint District Issue successfully saved.`
		},
		response: {
			audit: {
				getMessage: (auditAction) => {
					switch (auditAction) {
						case apiParameters.audit.APPROVE:
							return dr_api.state_complaints.response.APPROVED;
						case apiParameters.audit.COMMENTS:
							return dr_api.state_complaints.response.AUDIT_COMMENTS;
						case apiParameters.audit.RESET:
							return dr_api.state_complaints.response.AUDIT_RESET;
						case apiParameters.audit.REVISE:
							return dr_api.state_complaints.response.NEEDS_REVISION;
						case apiParameters.audit.SUBMIT:
							return dr_api.state_complaints.response.SUBMITTED;
						default:
							return "Success. No message included.";
					}
				},
			},
			AUDIT_RESET: `State Complaint audit successfully reset.`,
			AUDIT_COMMENTS: `State Complaint audit comment successfully saved.`,
			APPROVED: `State Complaint response successfully approved.`,
			NEEDS_REVISION: `State Complaint response successfully marked needs revision.`,
			SAVED: `State Complaint response successfully saved.`,
			SUBMITTED: `State Complaint response successfully submitted.`,
			review: {
				SAVED: `State Complaint response review successfully saved.`
			}
		},
		closure: {
			ADDED: `Closure letter process successfully created.`,
			DELETED: `Closure letter process successfully deleted.`,
			SAVED: `Closure letter process successfully saved.`
		},
		caseNote: {
			ADDED: `Case note successfully created.`,
			DELETED: `Case note successfully deleted.`,
			SAVED: `Case note successfully saved.`,
			DOWNLOAD: `Case investigation log report successfully downloaded.`
		},
		CASE_RESEARCH: {
			DOWNLOAD: `Case research on State Complaint successfully downloaded.`
		}
	},

	mediations: {
		ADDED: `Mediation successfully added.`,
		DELETED: `Mediation successfully deleted.`,
		SAVED: `Mediation successfully saved.`,
		CASE_RESEARCH: {
			DOWNLOAD: `Case research on Mediation successfully downloaded.`
		}
	},

	dueProcesses: {
		ADDED: `Due Process Hearing Requests successfully added.`,
		DELETED: `Due Process Hearing Requests successfully deleted.`,
		SAVED: `Due Process Hearing Requests successfully saved.`,
		action: {
			DELETED: `Due Process Hearing Requests District Corrective Action successfully deleted.`,
			SAVED: `Due Process Hearing Requests District Corrective Action successfully saved.`
		},
		response: {
			audit: {
				getMessage: (auditAction) => {
					switch (auditAction) {
						case apiParameters.audit.APPROVE:
							return dr_api.dueProcesses.response.APPROVED;
						case apiParameters.audit.COMMENTS:
							return dr_api.dueProcesses.response.AUDIT_COMMENTS;
						case apiParameters.audit.RESET:
							return dr_api.dueProcesses.response.AUDIT_RESET;
						case apiParameters.audit.REVISE:
							return dr_api.dueProcesses.response.NEEDS_REVISION;
						case apiParameters.audit.SUBMIT:
							return dr_api.dueProcesses.response.SUBMITTED;
						default:
							return "Success. No message included.";
					}
				},
			},
			AUDIT_RESET: `Due Process Hearing Requests audit successfully reset.`,
			AUDIT_COMMENTS: `Due Process Hearing Requests audit comment successfully saved.`,
			APPROVED: `Due Process Hearing Requests response successfully approved.`,
			NEEDS_REVISION: `Due Process Hearing Requests response successfully marked needs revision.`,
			SAVED: `Due Process Hearing Requests response successfully saved.`,
			SUBMITTED: `Due Process Hearing Requests response successfully submitted.`,
			review: {
				SAVED: `Due Process Hearing Requests response review successfully saved.`,
				DELETED: `Due Process Hearing Requests response review successfully deleted.`
			}
		},
		RESOLUTION_PROCESS: {
			SAVED: `Resolution Process Status Report successfully saved.`,
			SUBMITTED: `Resolution Process Status Report successfully submitted.`,
			audit: {
				getMessage: (auditAction) => {
					switch (auditAction) {
						case apiParameters.audit.APPROVE:
							return dr_api.dueProcesses.RESOLUTION_PROCESS.APPROVED;
						case apiParameters.audit.COMMENTS:
							return dr_api.dueProcesses.RESOLUTION_PROCESS.AUDIT_COMMENTS;
						case apiParameters.audit.RESET:
							return dr_api.dueProcesses.RESOLUTION_PROCESS.AUDIT_RESET;
						case apiParameters.audit.REVISE:
							return dr_api.dueProcesses.RESOLUTION_PROCESS.NEEDS_REVISION;
						default:
							return "Success. No message included.";
					}
				},
			},
			AUDIT_RESET: `Resolution Process Status Report successfully reset.`,
			AUDIT_COMMENTS: `Resolution Process Status Report comment successfully saved.`,
			APPROVED: `Resolution Process Status Report successfully approved.`,
			NEEDS_REVISION: `Resolution Process Status Report successfully marked needs revision.`,
		},
		caseNote: {
			ADDED: `Case note successfully created.`,
			DELETED: `Case note successfully deleted.`,
			SAVED: `Case note successfully saved.`,
			DOWNLOAD: `Case investigation log report successfully downloaded.`
		},
		CASE_RESEARCH: {
			DOWNLOAD: `Case research on Due Process Hearing Request successfully downloaded.`
		}
	},
	admin: {
		teamMembers: {
			SAVED: `The team members list was successfully saved.`,
		},
		intakeVersions: {
			SAVED: `The intake version was successfully saved.`,
			DELETED: `The intake version was successfully deleted.`,
			SAVE_ORDER: "The order was successfully saved.",
		},
		intakeRequirements: {
			SAVED: `The intake requirement was successfully saved.`,
			DELETED: `The intake requirement was successfully deleted.`,
		}
	},

	reminders: {
		SAVED: `The reminder was successfully saved.`,
		REMOVED: `The reminder was successfully removed.`,
		SETTINGS_SAVED: `The settings were successfully saved.`
	}
};

export const reports = {
	REPORT_DOWNLOADED: "Report successfully downloaded."
};