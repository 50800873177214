import {cloneDeep} from "lodash/lang";
import {getPropName, isArrayNullOrEmpty, isNullOrUndefined} from "../commonUtilities";
import {
    searchCriteria,
    SearchFilterElements
} from "./searchCriteria";

export function BpieSearchCriteria(schoolBpieId = "", dateWindowId = "", sortByColumn = "districtName", baseSortByColumn = "schoolName") {
    return {
        ...searchCriteria("BpieSearchCriteria", sortByColumn, baseSortByColumn, 20, "", dateWindowId),
        allSchoolBpies: [],
        schoolBpieId: schoolBpieId,
        allSchools: [],
        schoolNumber: "",
        allDistrictBpies: [],
        districtBpieId: "",
        finUsers: [],
        sbpieFinFacilitator: "",
        dbpieFinUser: "",
        applyFiltersAndOrder: ApplyFiltersAndOrder,
        areRulesCurrent: AreRulesCurrent,
        clearOptionalSearchItems: ClearOptionalSearchItems,
        setVisibilityRules: SetVisibilityRules,
    };
}

export const BpieFilterElements = {
    ...SearchFilterElements,
    ...{
        schoolBpieId: "schoolBpieId",
        schoolNumber: "schoolNumber",
        districtBpieId: "districtBpieId",
        principalInfo: "principalInfo",
        sbpieFinFacilitator: "sbpieFinFacilitator",
        dbpieFinUser: "dbpieFinUser"
    },
};

export const BpieFilterVisibilityRules = {
    SchoolBpieWrapper: [BpieFilterElements.schoolBpieId],
    SbpieSearch: [BpieFilterElements.dateWindowId, BpieFilterElements.district, BpieFilterElements.schoolNumber, BpieFilterElements.RecordsPerPage, BpieFilterElements.sbpieFinFacilitator, BpieFilterElements.statusSingle, BpieFilterElements.clearButton],
    DbpieSearch: [BpieFilterElements.dateWindowId, BpieFilterElements.district, BpieFilterElements.RecordsPerPage, BpieFilterElements.dbpieFinUser, BpieFilterElements.statusSingle, BpieFilterElements.clearButton],
    PendingSbpies: [BpieFilterElements.textSearch, BpieFilterElements.principalInfo, BpieFilterElements.clearButton],
}

function SetVisibilityRules(whichRules) {
    const rulesName = getPropName(BpieFilterVisibilityRules, whichRules);
    this.baseSetVisibilityRules(rulesName, whichRules, this.BaseSortByColumn);
}

function AreRulesCurrent(whichRules) {
    return this.baseAreRulesCurrent(whichRules, BpieFilterVisibilityRules)
}

function ClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    this.baseClearOptionalSearchItems(clearTeamMembersAsWell, clearOutAll);

    if(this.isVisible(BpieFilterElements.schoolBpieId) || clearOutAll)
        this.schoolBpieId = "";

    if(this.isVisible(BpieFilterElements.schoolNumber) || clearOutAll)
        this.schoolNumber = "";

    if(this.isVisible(BpieFilterElements.principalInfo) || clearOutAll)
        this.principalInfo = "";

    if(this.isVisible(BpieFilterElements.sbpieFinFacilitator) || clearOutAll)
        this.sbpieFinFacilitator = "";

    if(this.isVisible(BpieFilterElements.dbpieFinUser) || clearOutAll)
        this.dbpieFinUser = "";
}

function ApplyFiltersAndOrder(summaryData, finUsers = null) {
    if(isArrayNullOrEmpty(summaryData))
        return [];

    let summary = cloneDeep(summaryData);

    summary = this.baseApplyFilters(summary);

    if(!isArrayNullOrEmpty(summary) && this.schoolNumber && this.isVisible(BpieFilterElements.schoolNumber))
        summary = summary.filter(f => f.msidNumber.toLowerCase().indexOf(this.schoolNumber.toLowerCase()) > -1);

    if(!isArrayNullOrEmpty(summary) && this.textSearch
        && this.isVisible(BpieFilterElements.textSearch)
        && this.areRulesCurrent(BpieFilterVisibilityRules.PendingSbpies))
        summary = summary.filter(f => f.schoolName.toLowerCase().indexOf(this.textSearch.toLowerCase()) > -1
        || f.msidNumber.indexOf(this.textSearch) > -1);

    if(!isArrayNullOrEmpty(summary) && this.principalInfo && this.isVisible(BpieFilterElements.principalInfo))
        summary = summary.filter(f => `${f.schoolAdminFirstName.toLowerCase()} ${f.schoolAdminLastName.toLowerCase()}`.indexOf(this.principalInfo.toLowerCase()) > -1
        || f.schoolAdminEmail.toLowerCase().indexOf(this.principalInfo.toLowerCase()) > -1);

    if(!isArrayNullOrEmpty(summary) && this.sbpieFinFacilitator && this.isVisible(BpieFilterElements.sbpieFinFacilitator))
        summary = summary.filter(f => !isNullOrUndefined(f.finFacilitator) && f.finFacilitator.userId === this.sbpieFinFacilitator);

    if(!isArrayNullOrEmpty(summary) && this.dbpieFinUser && this.isVisible(BpieFilterElements.dbpieFinUser) && !isNullOrUndefined(finUsers)) {
        const finUser = finUsers.find(f => f.userId === this.dbpieFinUser);
        if(finUser.isFinAdmin) {
            summary = summary.filter(f => f.finUserId === this.dbpieFinUser);
        }
        else {
            const finFacilitatorDistrictIds = finUser.districts.map(district => district.districtId);
            summary = summary.filter(f => finFacilitatorDistrictIds.includes(f.districtId));
        }
    }

    return this.baseSort(summary);
}