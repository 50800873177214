import React from "react";
import PropTypes from 'prop-types';
import FormTable from "../../../components/common/FormTable";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import RadioField from "../../../components/common/inputs/RadioField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import SectionHeader from "../../../components/common/SectionHeader";
import {DateTimePickerType, inputStyles} from "../../../constants/inputConstants";
import {
    DrComplaintTimelineRequirementResponseOptionsEnum,
    DrComplaintTimelineRequirementResponseOptionsList
} from "../../drConstants";

const DrStateComplaintRoutingRequirements = ({
                                                 getRoutingInformationFeedback,
                                                 handleChangeCompletionDate,
                                                 handleChangeRoutingFeedback,
                                                 handleChangeRoutingRequirementResponse,
                                                 routingRequirementResponse,
                                                 timelineResponse
                                             }) => {
    const handleRoutingRequirementChange = (event, timelineRoutingRequirementResponseId, timelineResponseId) => {
        event.preventDefault();
        let response = event.target.value;
        handleChangeRoutingRequirementResponse(timelineResponseId, timelineRoutingRequirementResponseId, response);
    };

    const isCompletionDisabled = () => {
        const responseAllApproved = routingRequirementResponse.response !== DrComplaintTimelineRequirementResponseOptionsEnum.Approved;
        return !!responseAllApproved;
    };

    const isRoutingFeedbackEnabled = () => {
        const responseAnyNotApproved = routingRequirementResponse.response === DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved;
        return !!responseAnyNotApproved || !isCompletionDisabled();
    };

    const handleChangeRoutingFeedbackClick = (event, timelineResponseId, isFeedback = false) => {
        handleChangeRoutingFeedback(timelineResponseId, event.target.name, event.target.value, isFeedback);
    }

    return (
        <>
            <SectionHeader>
                <h2>{timelineResponse.activity}</h2>
            </SectionHeader>
            <FormTable>
                <RadioField
                    inputClass={`medium-3`}
                    labelClass={`medium-9`}
                    legend={'The report meets the minimum Legal Analysis and Writing Skills requirements.'}
                    name={`routingRequirementResponse`}
                    onChange={(event) => handleRoutingRequirementChange(event, routingRequirementResponse.timelineRoutingRequirementResponseId, routingRequirementResponse.timelineResponseId)}
                    options={DrComplaintTimelineRequirementResponseOptionsList}
                    showLegend
                    value={routingRequirementResponse.response}
                    wrapperClass={inputStyles.radioButtons.group.APPROVALS}
                />
                {
                    timelineResponse.dueDateWarning &&
                    <p><strong className={`color--alert`}>{timelineResponse.dueDateWarning}</strong></p>
                }
                <DateTimePickerField
                    name={`routingTimelineDueDate`}
                    label="Due"
                    showLabel
                    value={timelineResponse.dueDate}
                    disabled
                    medium={6}
                />
                <DateTimePickerField
                    name={`routingTimelineCompleted`}
                    label="Completed"
                    showLabel
                    value={timelineResponse.dateCompleted}
                    onChange={(event) => handleChangeCompletionDate(timelineResponse.timelineRequirementId, event.target.value)}
                    medium={6}
                    disabled={isCompletionDisabled()}
                />
                {
                    isRoutingFeedbackEnabled() &&
                    <>
                        <DateTimePickerField
                            dateTimePickerType={DateTimePickerType.TimeOnly}
                            label="Review Start Time (Optional)"
                            medium={6}
                            name={`startTime`}
                            noCalendar
                            onChange={(event) => handleChangeRoutingFeedbackClick(event, timelineResponse.timelineResponseId)}
                            showLabel
                            value={timelineResponse.startTime}
                        />
                        <DateTimePickerField
                            dateTimePickerType={DateTimePickerType.TimeOnly}
                            label="Review End Time (Optional)"
                            medium={6}
                            name={`endTime`}
                            noCalendar
                            onChange={(event) => handleChangeRoutingFeedbackClick(event, timelineResponse.timelineResponseId)}
                            showLabel
                            value={timelineResponse.endTime}
                        />
                        <TextAreaEditor
                            getValue={getRoutingInformationFeedback}
                            label={`Feedback (Optional)`}
                            name={`routingInformationFeedback`}
                            onChange={(event) => handleChangeRoutingFeedbackClick(event, timelineResponse.timelineResponseId, true)}
                            showLabel
                        />
                    </>
                }
            </FormTable>
        </>
    );
};

DrStateComplaintRoutingRequirements.propTypes = {
    getRoutingInformationFeedback: PropTypes.func.isRequired,
    handleChangeCompletionDate: PropTypes.func.isRequired,
    handleChangeRoutingFeedback: PropTypes.func.isRequired,
    handleChangeRoutingRequirementResponse: PropTypes.func.isRequired,
    routingRequirementResponse: PropTypes.object.isRequired,
    timelineResponse: PropTypes.object.isRequired
};

export default DrStateComplaintRoutingRequirements;