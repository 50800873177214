import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../common/GridTable";
import ResultsGrid from "./ResultsGrid";

const MatrixResultsGrid = (
    {
        matrixCorrectionResults,
        onMatrixProtocolCorrectiveResultOutcomeChange,
        isLoading,
        onCommentCreate,
        isCommentInEditMode,
        handleCommentChange,
        onCommentEdit,
        onCommentSave,
        onCommentCancel,
        editCommentItemId
    }) =>
{
    const mainAreaWidthProps = {
        medium: "12"
    };
    return (
        <div>
            <p><strong>For matrix record: {matrixCorrectionResults[0].name}</strong></p>
            <GridTable>
                <thead>
                <tr>
                    <th>Finding(s) of Noncompliance</th>
                </tr>
                </thead>
                <ResultsGrid
                    correctionResults={matrixCorrectionResults}
                    isLoading={isLoading}
                    onProtocolCorrectiveResultOutcomeChange={onMatrixProtocolCorrectiveResultOutcomeChange}
                    idPropName={"matrixProtocolCorrectiveResultId"}
                    mainAreaWidthProps={mainAreaWidthProps}
                    onCommentCreate={onCommentCreate}
                    isCommentInEditMode={isCommentInEditMode}
                    handleCommentChange={handleCommentChange}
                    onCommentEdit={onCommentEdit}
                    onCommentSave={onCommentSave}
                    onCommentCancel={onCommentCancel}
                    editCommentItemId={editCommentItemId}
                />
            </GridTable>
        </div>
    );
};

MatrixResultsGrid.propTypes = {
    matrixCorrectionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onMatrixProtocolCorrectiveResultOutcomeChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCommentCreate: PropTypes.func.isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    onCommentEdit: PropTypes.func.isRequired,
    onCommentSave: PropTypes.func.isRequired,
    onCommentCancel: PropTypes.func.isRequired,
    editCommentItemId: PropTypes.string.isRequired
};

export default MatrixResultsGrid;