import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {catchError} from "../../actions/actionUtility";
import * as fileActions from "../../actions/fileActions";
import * as LayoutActions from "../../actions/layoutActions";
import * as MonitoringPerformanceActions from "../../actions/monitoringPerformanceActions";
import * as SharedDataActions from "../../actions/sharedDataActions";
import AccountUtility from "../../api/AccountUtility";
import Allow from "../../components/authorization/Allow";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {
	MonitoringPerformancePolicy,
	policyEvents
} from "../../components/authorization/policies/MonitoringPerformancePolicy";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as CommonUtilities from "../../components/common/commonUtilities";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import SelectField from "../../components/common/inputs/SelectField";
import Switch from "../../components/common/inputs/Switch";
import {NotifyUser} from "../../components/common/NotifyUser";
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import {getParamsMultiple} from "../../components/layout/getParams";
import AssessmentIndicator from "../../components/MonitoringPerformance/AssessmentIndicator";
import AssessmentIndicatorForm from "../../components/MonitoringPerformance/AssessmentIndicatorForm";
import MonitoringPerformanceAssessment from "../../components/MonitoringPerformance/MonitoringPerformanceAssessment";
import MonitoringPerformanceAssessmentForm
	from "../../components/MonitoringPerformance/MonitoringPerformanceAssessmentForm";
import * as PerformanceUtilities from "../../components/MonitoringPerformance/MonitoringPerformanceUtilities";
import LocalStorage from "../../components/shared/LocalStorage";
import {
	filterCurrentAnnualDateWindows,
	generateDateWindowSelectListItems,
	generateIndicatorSelectListItems,
	outputStandardDateFormat
} from "../../components/shared/sharedDataUtilities";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../constants/AuditConstants";
import audit_outcomes from "../../constants/auditOutcomes";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as PerformanceTypes from "../../constants/monitoringPerformance/monitoringPerformanceAssessmentTypes";
import * as monitoringPerformanceLocations from "../../constants/monitoringPerformance/monitoringPerformanceLocations";
import {gridConstants} from "../../constants/inputConstants";

export class MonitoringPerformanceAssessmentPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			district: "",
			isAssessmentLoaded: false,
			newIndicator: {
				indicatorId: "",
				isRequired: false
			},
			params: {}
		};

		this.onSubmitCreateAssessment = this.onSubmitCreateAssessment.bind(this);
		this.onCancelCreateAssessment = this.onCancelCreateAssessment.bind(this);

		this.onChangeAssessment = this.onChangeAssessment.bind(this);
		this.onCancelAssessment = this.onCancelAssessment.bind(this);

		this.onEditAssessment = this.onEditAssessment.bind(this);
		this.onSaveAssessment = this.onSaveAssessment.bind(this);

		this.onChangeDistrictAccess = this.onChangeDistrictAccess.bind(this);

		this.onEditIndicator = this.onEditIndicator.bind(this);
		this.onAddIndicator = this.onAddIndicator.bind(this);
		this.onCancelAddIndicator = this.onCancelAddIndicator.bind(this);
		this.onDeleteIndicator = this.onDeleteIndicator.bind(this);
		this.onSaveIndicator = this.onSaveIndicator.bind(this);
		this.onOpenIndicator = this.onOpenIndicator.bind(this);
		this.onChangeIndicator = this.onChangeIndicator.bind(this);

		this.onAddAction = this.onAddAction.bind(this);
		this.onChangeAction = this.onChangeAction.bind(this);
		this.onSaveAction = this.onSaveAction.bind(this);
		this.onEditAction = this.onEditAction.bind(this);
		this.onCancelAddAction = this.onCancelAddAction.bind(this);
		this.onDeleteIndicatorAction = this.onDeleteIndicatorAction.bind(this);

		this.onEditActionResponse = this.onEditActionResponse.bind(this);
		this.onChangeActionResponse = this.onChangeActionResponse.bind(this);
		this.onSaveActionResponse = this.onSaveActionResponse.bind(this);
		this.onCancelActionResponse = this.onCancelActionResponse.bind(this);
		this.onResponseDocumentUpload = this.onResponseDocumentUpload.bind(this);
		this.onAddActionResponse = this.onAddActionResponse.bind(this);
		this.onFileView = this.onFileView.bind(this);
		this.onFileDelete = this.onFileDelete.bind(this);
		this.onSubmitActionResponse = this.onSubmitActionResponse.bind(this);
		this.onResponseOutcomeChange = this.onResponseOutcomeChange.bind(this);

		this.onBackClick = this.onBackClick.bind(this);
		this.clearNewIndicatorState = this.clearNewIndicatorState.bind(this);
		this.onChangeDateWindow = this.onChangeDateWindow.bind(this);

		this.onCommentCancel = this.onCommentCancel.bind(this);
		this.onCommentSave = this.onCommentSave.bind(this);
		this.onCommentEdit = this.onCommentEdit.bind(this);
		this.onCommentCreate = this.onCommentCreate.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this);
	}

	componentDidMount() {
		const params = getParamsMultiple(this.props.location.pathname,
			[
				monitoringPerformanceLocations.ASSESSMENT_DOE,
				monitoringPerformanceLocations.ASSESSMENT_NEW_DOE,
				monitoringPerformanceLocations.LANDING_DOE,
				monitoringPerformanceLocations.ASSESSMENT_DISTRICT
			]);

		if (params !== null)
			this.setState({params});

		this.props.actions.clearSelectedAssessment();

		if (CommonUtilities.isArrayNullOrEmpty(this.props.dateWindows))
			this.props.actions.loadAllDateWindows();

		if (CommonUtilities.isArrayNullOrEmpty(this.props.sppIndicators))
			this.props.actions.loadSppIndicators();

		if (CommonUtilities.isArrayNullOrEmpty(this.props.allDistricts))
			this.props.actions.loadInstitutions();

		if (this.props.districtView)
			this.props.actions.loadDistrictStatuses();
	}

	componentDidUpdate(nextProps) {
		if (!this.state.isAssessmentLoaded &&
			!CommonUtilities.isArrayNullOrEmpty(this.props.dateWindows) &&
			!CommonUtilities.isArrayNullOrEmpty(this.props.allDistricts) &&
			(!this.props.districtView ||
				(this.props.districtView && this.props.districtAssessments && this.props.districtAssessments.length > 0)
			)) {
			let selectedAssessmentId = "";
			if (this.props.districtView)
				selectedAssessmentId = this.props.selectedAssessmentId;
			else {
				selectedAssessmentId = this.state.params.assessmentId;
				this.props.actions.setSelectedAssessmentId(selectedAssessmentId);
			}
			this.loadAssessment(selectedAssessmentId);
		}
	}

	onChangeDateWindow(event) {
		event.preventDefault();

		const selectedAssessmentId = event.target.value;

		this.props.actions.setSelectedAssessmentId(selectedAssessmentId);
		this.loadAssessment(selectedAssessmentId);

		this.props.history.push(monitoringPerformanceLocations.ASSESSMENT_DISTRICT.path
			.replace(monitoringPerformanceLocations.ASSESSMENT_ID, selectedAssessmentId));
	}

	loadAssessment(selectedAssessmentId) {
		this.setState({isAssessmentLoaded: true});

		if (selectedAssessmentId === "" && this.props.districtAssessments && this.props.districtAssessments.length > 0) {
			const districtId = LocalStorage.getLoggedInUser().InstitutionId;
			const currentDistrictAssessments = this.props.districtAssessments.filter(f => f.districtId === districtId);

			if (currentDistrictAssessments.length > 0)
				selectedAssessmentId = currentDistrictAssessments[0].monitoringAssessmentId;

			this.props.actions.setSelectedAssessmentId(selectedAssessmentId);
		}

		if (this.state.params.dateWindowId || selectedAssessmentId === "") {
			this.props.actions.updatePageTitle("Monitoring for Performance");
			this.loadBeessIndicatorsForYear(this.state.params.dateWindowId);

		}
		else {
			this.props.actions.loadPerformanceMonitoringAssessment(selectedAssessmentId, this.props.allDistricts)
				.then(() => {
					const pageTitlePostfix = (!this.props.districtView) ? " - " + this.props.selectedAssessment.districtName : "";
					this.props.actions.updatePageTitle(`Monitoring for Performance${pageTitlePostfix}`);
					this.loadBeessIndicatorsForYear(this.props.selectedAssessment.dateWindowId);
				});
		}
	}

	loadBeessIndicatorsForYear(selectedDateWindowId) {
		if (CommonUtilities.isArrayNullOrEmpty(this.props.beessIndicators) && !!selectedDateWindowId) {
			const dateWindowStartYear = this.props.dateWindows.find(f => f.value === selectedDateWindowId).text.substring(0, 4);
			this.props.actions.loadBeessIndicators(dateWindowStartYear);
		}
	}

	onChangeAssessment(event) {
		const propName = event.target.id;
		const propValue = event.target.value;

		let updatedAssessment = Object.assign({}, this.props.selectedAssessment, {[propName]: propValue});

		if (propName === "districtId")
			updatedAssessment.districtName = this.props.allDistricts.filter(d => d.value === propValue)[0].text;

		this.props.actions.changeMonitoringPerformanceAssessmentDetails(updatedAssessment);
	}

	onSubmitCreateAssessment(event, assessment) {
		const assessmentId = emptyGuid;
		let updatedAssessment = Object.assign({}, assessment, {monitoringAssessmentId: assessmentId}, {dateWindowId: this.state.params.dateWindowId});

		this.props.actions.createPerformanceMonitoringAssessment(updatedAssessment)
			.then((newId) => {
				if(!newId)
					return;

				this.props.actions.clearSelectedAssessment();
				this.loadAssessment(newId);
				this.props.history.push(monitoringPerformanceLocations.ASSESSMENT_DOE.path
					.replace(monitoringPerformanceLocations.ASSESSMENT_ID, newId));
			});
	}

	onCancelCreateAssessment() {
		this.props.history.push(monitoringPerformanceLocations.LANDING_DOE.path);
	}

	onEditAssessment(event, assessmentId) {
		event.preventDefault();

		this.props.actions.editMonitoringPerformanceAssessmentDetails(assessmentId);
	}

	onCancelAssessment(event) {
		event.preventDefault();

		this.props.actions.cancelMonitoringPerformanceAssessmentDetails();
	}

	onSaveAssessment(event) {
		event.preventDefault();

		this.props.actions.updatePerformanceMonitoringAssessment(this.props.selectedAssessment);
	}

	onChangeDistrictAccess(event) {
		event.preventDefault();
		const allowAccess = event.target.checked;

		this.props.actions.changeDistrictAccess(allowAccess);
		this.props.actions.updateDistrictAccess(this.props.selectedAssessment.monitoringAssessmentId, allowAccess);
	}

	onEditIndicator(event, indicatorAssessmentId) {
		event.preventDefault();

		const indicator = this.props.selectedAssessment.indicatorAssessments.find(f => f.indicatorAssessmentId === indicatorAssessmentId);
		this.setState({newIndicator: indicator});

		this.props.actions.editAssessmentIndicator(indicatorAssessmentId);
	}

	onAddIndicator(event) {
		event.preventDefault();

		this.props.actions.addAssessmentIndicator();
	}

	onCancelAddIndicator(event) {
		event.preventDefault();

		this.clearNewIndicatorState();
		this.props.actions.cancelAddAssessmentIndicator();
	}

	onSaveIndicator(event) {
		event.preventDefault();
		const indicator = this.state.newIndicator;
		const indicatorAssessmentId = !indicator.indicatorAssessmentId ? emptyGuid : indicator.indicatorAssessmentId;
		const assessmentIndicator = PerformanceUtilities.newAssessmentIndicator(
			this.props.selectedAssessmentId,
			indicatorAssessmentId,
			indicator.indicatorId,
			indicator.isRequired,
			indicator.explanationForAssessment,
		);

		this.props.actions.createAssessmentIndicator(assessmentIndicator)
			.then((success) => {
				if (!success) return;

				this.clearNewIndicatorState();
				this.props.actions.loadPerformanceMonitoringAssessment(this.props.selectedAssessmentId);
			});
	}

	clearNewIndicatorState() {
		this.setState({newIndicator: Object.assign({}, {indicatorId: "", isRequired: false})});
	}

	onDeleteIndicator(event, indicatorAssessmentId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this indicator along with all its data?")) {
			this.props.actions.deleteAssessmentIndicator(this.props.selectedAssessmentId, indicatorAssessmentId);
		}
	}

	onChangeIndicator(event) {
		const newIndicator = Object.assign({}, this.state.newIndicator);
		const name = event.target.id;

		if (event.target.type === 'checkbox')
			newIndicator[name] = event.target.checked;
		else
			newIndicator[name] = event.target.value;

		this.setState({newIndicator: newIndicator});
	}

	onOpenIndicator(assessmentId, assessmentIndicatorId) {
		this.props.actions.loadAssessmentIndicatorActions(assessmentId, assessmentIndicatorId);
	}

	onDeleteIndicatorAction(event, indicatorAssessmentId, actionId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this action?")) {
			this.props.actions.deleteAssessmentIndicatorAction(this.props.selectedAssessmentId, indicatorAssessmentId, actionId);
		}
	}

	onAddAction(event, indicatorAssessmentId) {
		event.preventDefault();

		const newActionId = emptyGuid;
		const newAction = PerformanceUtilities.newIndicatorAction(this.props.selectedAssessmentId, indicatorAssessmentId, newActionId);

		this.props.actions.addIndicatorAction(indicatorAssessmentId, newAction);
	}

	onEditAction(indicatorAssessmentId, actionId) {
		const changedIndicator = this.props.selectedAssessment.indicatorAssessments.filter(ia => ia.indicatorAssessmentId === indicatorAssessmentId)[0];
		const action = Object.assign({}, changedIndicator.indicatorActions.filter(ia => ia.actionId === actionId)[0]);

		this.props.actions.editIndicatorAction(actionId);
		this.setState({
			editingAction: action
		});
	}

	onCancelAddAction(event, assessmentId, indicatorAssessmentId) {
		event.preventDefault();
		this.props.actions.loadAssessmentIndicatorActions(assessmentId, indicatorAssessmentId);
		this.props.actions.cancelAddAction();
	}

	onChangeAction(event, indicatorAssessmentId, actionId) {
		let action = PerformanceUtilities.getAction(this.props.selectedAssessment, actionId);

		const name = event.target.id;
		action[name] = event.target.value;

		this.props.actions.changeIndicatorAction(action);
	}

	onSaveAction(event, indicatorAssessmentId) {
		const action = Object.assign({}, PerformanceUtilities.getAction(this.props.selectedAssessment, this.props.editItemId));
		const indicatorActionDto = PerformanceUtilities.newIndicatorAction(this.props.selectedAssessmentId, indicatorAssessmentId, action.actionId,
			action.actionSummary, action.districtActionType, action.dueDate);

		if (this.props.isAddingAction) {
			this.props.actions.createIndicatorAction(indicatorActionDto)
				.then((success) => {
					if(success)
						this.props.actions.loadAssessmentIndicatorActions(this.props.selectedAssessmentId, indicatorAssessmentId);
				});
		}
		else {
			this.props.actions.updateIndicatorAction(indicatorActionDto);
		}
	}

	onAddActionResponse(event, monitoringAssessmentId, indicatorAssessmentId, actionId) {
		const newResponse = PerformanceUtilities.newIndicatorActionResponse(monitoringAssessmentId, indicatorAssessmentId, actionId, {responseId: emptyGuid});

		this.props.actions.addIndicatorActionResponse(newResponse);
	}

	onEditActionResponse(responseId) {
		this.props.actions.editIndicatorActionResponse(responseId);
	}

	onChangeActionResponse(event) {
		let actionResponse = PerformanceUtilities.getActionResponse(this.props.selectedAssessment, this.props.editItemId);

		actionResponse[event.target.id] = event.target.value;

		this.props.actions.changeActionResponse(actionResponse);
	}

	isActionResponseValid(response) {
		let isValid = true;
		let errors = "";
		if (!response.resolutionDescription || response.resolutionDescription.length < 1) {
			errors += "<li>Action Taken Description is required.</li>";
			isValid = false;
		}

		if (!response.dateResolutionCompleted || response.dateResolutionCompleted.length < 1) {
			errors += "<li>Date Completed is required.</li>";
			isValid = false;
		}

		if (!isValid)
			NotifyUser.Error(`Please fix the following items:<ul>${errors}</ul>`);

		return isValid;
	}

	onSaveActionResponse(event) {
		event.preventDefault();

		const response = PerformanceUtilities.getActionResponse(this.props.selectedAssessment, this.props.editItemId);
		let isValid = this.isActionResponseValid(response);

		if (!isValid)
			return null;

		if (this.props.isNew) {
			this.props.actions.createIndicatorActionResponse(response)
				.then((success) => {
					if(success)
						this.props.actions.loadAssessmentIndicatorActions(this.props.selectedAssessmentId, response.indicatorAssessmentId);
				});
		}
		else {
			this.props.actions.updateIndicatorActionResponse(response);
		}
	}

	onCancelActionResponse(event, assessmentId, assessmentIndicatorId) {
		event.preventDefault();

		this.props.actions.cancelEditIndicatorActionResponse();
		this.props.actions.loadAssessmentIndicatorActions(assessmentId, assessmentIndicatorId).then();
	}

	onResponseDocumentUpload(event) {
		event.preventDefault();

		const fileName = event.target.value.substring(event.target.value.lastIndexOf("\\") + 1);

		this.props.actions.uploadFile(event.target.files, emptyGuid)
			.then((newFileId) => {
				if(!newFileId) return;

				const actionResponse = PerformanceUtilities.getActionResponse(this.props.selectedAssessment, this.props.editItemId);
				let responseFiles = actionResponse.fileItems ? [...actionResponse.fileItems] : [];
				responseFiles.push({fileItemId: newFileId, name: fileName});

				actionResponse["fileItems"] = responseFiles;

				this.props.actions.changeActionResponse(actionResponse);
			});
	}

	onSubmitActionResponse(indicatorAssessmentId, actionId) {
		this.props.actions.submitActionResponse(this.props.selectedAssessment.monitoringAssessmentId, indicatorAssessmentId, actionId);
	}

	onResponseOutcomeChange(event, outcomeDto) {
		event.preventDefault();

		const auditOutcome = event.target.id.split("_")[0];

		let auditOutcomeAction = "";
		switch (auditOutcome) {
			case audit_outcomes.ACCEPTED.name:
				auditOutcomeAction = "approveActionResponse";
				break;
			case audit_outcomes.NEEDS_REVISION.name:
				auditOutcomeAction = "rejectActionResponse";
				break;
			case audit_outcomes.RESET.name:
				auditOutcomeAction = "resetActionResponse";
				break;
		}

		if (auditOutcomeAction !== "") {
			this.props.actions[auditOutcomeAction](outcomeDto)
				.then(() => {
					this.props.actions.loadAssessmentIndicatorActions(outcomeDto.assessmentId, outcomeDto.indicatorAssessmentId);
				});
		}
	}

	onFileView(event) {
		event.preventDefault();

		const fileId = event.target.id;
		this.props.actions.downloadFile(fileId);
	}

	onFileDelete(event) {
		event.preventDefault();

		if (!confirm("Are you sure you want to delete this file?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
			return;

		const fileId = event.target.id;

		let actionResponse = PerformanceUtilities.getActionResponse(this.props.selectedAssessment, this.props.editItemId);
		actionResponse["fileItems"] = actionResponse["fileItems"].filter(f => f.fileItemId !== fileId);

		this.props.actions.changeActionResponse(actionResponse);
		this.props.actions.deleteFile(fileId);
	}

	onBackClick(event) {
		event.preventDefault();
		const location = AccountUtility.isDistrictUser() ?
			monitoringPerformanceLocations.LANDING_DISTRICT.path :
			monitoringPerformanceLocations.LANDING_DOE.path;
		this.props.history.push(location);
	}

	onCommentCancel(event, assessmentId, assessmentIndicatorId) {
		event.preventDefault();
		this.props.actions.cancelComment();
		this.props.actions.loadAssessmentIndicatorActions(assessmentId, assessmentIndicatorId);
	}

	onCommentSave(event, comment, actionId, assessmentId, indicatorAssessmentId) {
		event.preventDefault();

		if (comment.auditCommentId === "") {
			const newCommentId = emptyGuid;
			comment = Object.assign({}, comment, {auditCommentId: newCommentId});

			this.props.actions.addAuditComment(comment, actionId, assessmentId, indicatorAssessmentId)
				.catch(error => catchError(error));
		}
		else {
			this.props.actions.updateAuditComment(comment, assessmentId, indicatorAssessmentId, actionId)
				.catch(error => catchError(error));
		}
	}

	onCommentEdit(commentId) {
		this.props.actions.editComment(commentId);
	}

	onCommentCreate(auditId, actionId) {
		this.props.actions.createComment(auditId, actionId);
	}

	handleCommentChange(event, comment, actionId) {
		event.preventDefault();

		comment = Object.assign({}, comment);

		const fieldUpdated = event.target.name;
		const updatedValue = event.target.value;

		if(fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
			comment.isVisibleToDistrict = updatedValue;
		else
			comment.text = updatedValue;

		this.props.actions.changeComment(comment, actionId);
	}

	render() {
		if (!this.state.isAssessmentLoaded || !this.props.selectedAssessment)
			return null;

		const isCertify = AuthorizationUtilities.allow(MonitoringPerformancePolicy, policyEvents.monitoring_performance.assessment.SUBMIT);

		const isCreateAssessment = !this.props.districtView && !this.props.selectedAssessmentId;
		const isEditAssessment =
			!this.props.districtView &&
			this.props.selectedAssessmentId &&
			this.props.isEditing &&
			this.props.editItemId === this.props.selectedAssessmentId;

		const showAssessmentForm = isCreateAssessment || isEditAssessment;

		let availableAssessments = [];
		if (this.props.districtAssessments)
			availableAssessments = this.props.districtAssessments
				.filter(a => a.isVisibleToDistrict && a.districtId === this.props.selectedAssessment.districtId)
				.map(assessment => {
					const dateRange = outputStandardDateFormat(assessment.visitStartDate, false) + " - " + outputStandardDateFormat(assessment.visitEndDate, false);
					return {
						text: `${dateRange}: ${PerformanceTypes.Text(assessment.monitoringType)}`,
						value: assessment.monitoringAssessmentId
					};
				});

		return (
			<div>
				{
					this.props.selectedAssessmentId &&
					!this.props.districtView &&
					<ButtonBar className={"buttonBar--fullWidth align-middle"}>
						<div className="buttonBar__buttonContainer">
							<Button
								onClick={this.onBackClick}
								name={"btnBack"}
								label={"Back"}
								buttonType={ButtonTypes.BACK}
							/>
						</div>
						<div className="buttonBar__buttonContainer">
							<Switch
								label={"Toggle District Access"}
								name={"districtViewToggle"}
								checked={this.props.selectedAssessment.isVisibleToDistrict}
								onChange={this.onChangeDistrictAccess}
								legend={"Allow District Access"}
								showLegend
								showLabel={false}
								disabled={!isCertify}
							/>
						</div>
					</ButtonBar>
				}
				{
					this.props.districtView &&
					availableAssessments.length > 0 &&
					<GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
						<GridColumn>
							<SelectField
								name={"dateWindow"}
								showLabel={true}
								label={"Date Window"}
								options={availableAssessments}
								onChange={this.onChangeDateWindow}
								disabled={this.props.isLoading}
								value={this.props.selectedAssessmentId}
								includeDefaultOption={false}
							/>
						</GridColumn>
					</GridRow>
				}
				{
					this.props.districtView &&
					availableAssessments.length === 0 &&
					<p>You do not have any monitoring performance assessments available.</p>
				}
				{
					this.props.selectedAssessmentId &&
					!this.props.districtView &&
					this.props.editItemId !== this.props.selectedAssessmentId &&
					<MonitoringPerformanceAssessment
						assessment={this.props.selectedAssessment}
						isEditing={this.props.isEditing}
						isLoading={this.props.isLoading}
						onEditAssessment={(event) => this.onEditAssessment(event, this.props.selectedAssessmentId)}
					/>
				}
				{
					showAssessmentForm &&
					<MonitoringPerformanceAssessmentForm
						assessment={this.props.selectedAssessment}
						availableDistricts={this.props.availableDistricts}
						isLoading={this.props.isLoading}
						onChange={this.onChangeAssessment}
						onCancel={!this.props.selectedAssessmentId ? this.onCancelCreateAssessment : this.onCancelAssessment}
						onSavePerformanceAssessment={!this.props.selectedAssessmentId ? this.onSubmitCreateAssessment : this.onSaveAssessment}
					/>
				}
				{
					this.props.selectedAssessmentId &&
					((this.props.districtView && this.props.selectedAssessment.isVisibleToDistrict) || !this.props.districtView) &&
					<section className={"performance__indicators"} id={"performance__indicators"}>
						<header>
							<h2>Target Areas</h2>
						</header>
						{
							this.props.selectedAssessment.indicatorAssessments &&
							this.props.selectedAssessment.indicatorAssessments.map((indicatorAssessment) => {
								if (!indicatorAssessment || indicatorAssessment.indicatorAssessmentId === this.props.editItemId) return null;

								return (
									<AssessmentIndicator
										key={indicatorAssessment.indicatorAssessmentId}
										indicatorAssessment={indicatorAssessment}
										indicators={this.props.allIndicators}
										isEditing={this.props.isEditing || this.props.isAddingAction}
										editItemId={this.props.editItemId}
										isLoading={this.props.isLoading}
										onEditIndicator={this.onEditIndicator}
										onDeleteIndicator={this.onDeleteIndicator}
										onSaveIndicator={this.onSaveIndicator}
										onOpenIndicator={this.onOpenIndicator}
										onAddAction={this.onAddAction}
										onDeleteAction={this.onDeleteIndicatorAction}
										onCancel={this.onCancelAddAction}
										onSaveAction={this.onSaveAction}
										onEditAction={this.onEditAction}
										onChangeAction={this.onChangeAction}
										isNewAction={this.props.isAddingAction}
										districtView={this.props.districtView}
										onAddActionResponse={this.onAddActionResponse}
										onCancelActionResponse={this.onCancelActionResponse}
										onEditActionResponse={this.onEditActionResponse}
										onChangeActionResponse={this.onChangeActionResponse}
										onSaveActionResponse={this.onSaveActionResponse}
										onResponseDocumentUpload={this.onResponseDocumentUpload}
										onSubmitActionResponse={this.onSubmitActionResponse}
										onResponseOutcomeChange={this.onResponseOutcomeChange}
										assessmentId={this.props.selectedAssessmentId}
										onFileView={this.onFileView}
										onFileDelete={this.onFileDelete}

										editCommentItemId={this.props.commentId}
										handleCommentChange={this.handleCommentChange}
										isCommentInEditMode={this.props.isCommentInEditMode}
										onCommentCancel={this.onCommentCancel}
										onCommentCreate={this.onCommentCreate}
										onCommentEdit={this.onCommentEdit}
										onCommentSave={this.onCommentSave}
									/>);
							})
						}
						{
							this.props.isAddingIndicator &&
							!this.props.districtView &&
							<AssessmentIndicatorForm
								indicatorList={this.props.allIndicators}
								value={this.state.newIndicator}
								isLoading={this.props.isLoading}
								onSaveIndicator={this.onSaveIndicator}
								onChangeIndicator={this.onChangeIndicator}
								onCancel={this.onCancelAddIndicator}
							/>
						}
						{
							!this.props.isEditing &&
							!this.props.isLoading &&
							!this.props.districtView &&
							<Allow policy={MonitoringPerformancePolicy}
							       policyEvent={policyEvents.monitoring_performance.indicator.MODIFY}>
								<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
									<Tile body={"Add Target Area"} onClick={this.onAddIndicator} isNew={true}/>
								</TileContainer>
							</Allow>
						}
					</section>
				}
			</div>
		);
	}
}

MonitoringPerformanceAssessmentPage.propTypes = {
	actions: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	isEditing: PropTypes.bool,
	isAddingIndicator: PropTypes.bool,
	isAddingAction: PropTypes.bool,
	editItemId: PropTypes.string,
	selectedAssessmentId: PropTypes.string,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	sppIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	beessIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	allIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
	availableDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
	districtAssessments: PropTypes.arrayOf(PropTypes.object),
	selectedAssessment: PropTypes.object,
	districtView: PropTypes.bool.isRequired,
	isNew: PropTypes.bool,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	isCommentInEditMode: PropTypes.bool.isRequired,
	commentId: PropTypes.string.isRequired
};

function mapStateToProps(state, ownProps) {
	const params = getParamsMultiple(ownProps.location.pathname,
		[
			monitoringPerformanceLocations.ASSESSMENT_DOE,
			monitoringPerformanceLocations.ASSESSMENT_NEW_DOE,
			monitoringPerformanceLocations.LANDING_DOE,
			monitoringPerformanceLocations.ASSESSMENT_DISTRICT
		]);

	const dateWindowId = params && params.dateWindowId ? params.dateWindowId : "";
	const selectedAssessmentId = params && params.assessmentId ? params.assessmentId : state.monitoringPerformance.selectedAssessmentId;

	let districtsWithAssessment = [...state.monitoringPerformance.districtStatuses]
		.filter(s => s.dateWindowId === dateWindowId)
		.map(ins => {
			return ins.districtId;
		});

	let allDistricts = [...state.sharedData.institutions].map(ins => {
		return {value: ins.id, text: ins.name};
	});

	let districtsWithoutAssessment = allDistricts.filter(function (a) {
		return !districtsWithAssessment.includes(a.value);
	});

	let sppIndicators = generateIndicatorSelectListItems([...state.sharedData.sppIndicators], true);

	const filteredBeessIndicators = state.sharedData.beessIndicators.filter(f => !f.hasProtocols);
	let beessIndicators = generateIndicatorSelectListItems(filteredBeessIndicators, false, true);

	const allIndicators = sppIndicators.concat(beessIndicators);

	let dateWindows = [...state.sharedData.dateWindows];
	if (dateWindows.length > 0) {
		const annualWindows = filterCurrentAnnualDateWindows(dateWindows, true);
		dateWindows = generateDateWindowSelectListItems(annualWindows);
	}

	return {
		isLoading: state.ajaxCallsInProgress > 0,
		availableDistricts: districtsWithoutAssessment,
		dateWindows: dateWindows,
		districtsWithAssessment,
		allDistricts,
		selectedAssessment: state.monitoringPerformance.selectedAssessment,
		isEditing: state.monitoringPerformance.isEditing || state.monitoringPerformance.isAddingIndicator,
		isAddingIndicator: state.monitoringPerformance.isAddingIndicator,
		isAddingAction: state.monitoringPerformance.isAddingAction,
		isEditingIndicator: state.monitoringPerformance.isEditingIndicator,
		editItemId: state.monitoringPerformance.editItemId,
		beessIndicators,
		sppIndicators,
		allIndicators,
		districtView: AccountUtility.isDistrictUser(),
		isNew: state.monitoringPerformance.isNew,
		districtAssessments: state.monitoringPerformance.districtStatuses,
		selectedAssessmentId: selectedAssessmentId,
		isCommentInEditMode: state.monitoringPerformance.isCommentInEditMode,
		commentId: state.monitoringPerformance.commentId
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		LayoutActions,
		SharedDataActions,
		MonitoringPerformanceActions,
		fileActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringPerformanceAssessmentPage);