import React from "react";
import PropTypes from 'prop-types';
import { limitTextLength } from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import FileUploadButton from "../../../components/common/inputs/FileUploadButton";
import FileViewDelete from "../../../components/common/inputs/FileViewDelete";
import {clearUpload} from "../../../components/common/inputs/inputUtility";
import {gridConstants} from "../../../constants/inputConstants";
import {createSaveSourceDocumentationObject} from "../ptsObjectFactory";

export const PtsDeliverableSummarySourceDocumentationEdit = ({
                                                                 activityId,
                                                                 canDeleteDocumentation,
                                                                 handleFileDelete,
                                                                 handleFileDownload,
                                                                 handleFileUpload,
                                                                 isDisabled,
                                                                 quarterSelected,
                                                                 sourceDocumentationFiles
                                                             }) => {
    const handleClickUploadSourceDocument = async (event) => {
        event.preventDefault();
        const files = event.target.files;
        const currentFileDetails = sourceDocumentationFiles.map((file) => createSaveSourceDocumentationObject(file.sourceDocumentationFileId, file.discretionaryProjectQuarter));
        await handleFileUpload(activityId, files, currentFileDetails, quarterSelected);

        clearUpload(`btnUploadSourceDocumentation_${activityId}_${quarterSelected}`);
    };

    const handleClickDownloadSourceDocument = (event) => {
        event.preventDefault();
        const fileId = event.target.id;
        handleFileDownload(fileId);
    };

    const handleClickDeleteSourceDocument = async (event) => {
        event.preventDefault();
        if (!confirm("Are you sure you want to delete this file?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
            return;

        const fileId = event.target.id;
        const updatedFiles = sourceDocumentationFiles.filter((file) => file.sourceDocumentationFileId !== fileId);
        const updatedFileDetails = updatedFiles.map((file) => createSaveSourceDocumentationObject(file.sourceDocumentationFileId, file.discretionaryProjectQuarter));
        await handleFileDelete(activityId, fileId, updatedFileDetails);
    };

    const quarterFiles = sourceDocumentationFiles.filter(f => f.discretionaryProjectQuarter === quarterSelected)

    if(isDisabled && quarterFiles.length === 0)
        return null;

    return <>
        <p><strong>Source Documentation</strong></p>
        {
            !isDisabled &&
            <FileUploadButton
                className={`sourceDocumentation__upload`}
                name={`btnUploadSourceDocumentation_${activityId}_${quarterSelected}`}
                label={"Upload Document"}
                onChange={handleClickUploadSourceDocument}
            />
        }

        <GridRow rowClass={`sourceDocumentationFiles`}>
            {
                quarterFiles.map((file) => {
                    const fileViewDeleteWrapperClass = canDeleteDocumentation
                        ? `sourceDocumentationFiles__fileDownload`
                        : `sourceDocumentationFiles__fileDownload--disabled`;

                    return (
                        <GridColumn key={file.sourceDocumentationFileId} medium={gridConstants.column.THREE} large={gridConstants.column.THREE}>
                            <FileViewDelete
                                name={file.sourceDocumentationFileId}
                                label={limitTextLength(file.displayName, 15)}
                                wrapperClass={fileViewDeleteWrapperClass}
                                onView={handleClickDownloadSourceDocument}
                                onDelete={canDeleteDocumentation ? handleClickDeleteSourceDocument : null}
                            />
                        </GridColumn>
                    );
                })
            }
        </GridRow>
    </>;
};

PtsDeliverableSummarySourceDocumentationEdit.propTypes = {
    activityId: PropTypes.string.isRequired,
    canDeleteDocumentation: PropTypes.bool.isRequired,
    handleFileDelete: PropTypes.func.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    quarterSelected: PropTypes.number.isRequired,
    sourceDocumentationFiles: PropTypes.array
};