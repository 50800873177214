import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    buildStudentRecordsNeedingCorrectionsList
} from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities
    from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {StudentSummaryGrid} from "../../../../components/MonitoringCompliance/StudentCorrections/StudentSummaryGrid";
import * as ComplianceLocations from "../../../../constants/monitoringCompliance/monitoringComplianceLocations";

export const StudentCorrectionsStateSummaryView = ({
                                                       actions,
                                                       dateWindowId,
                                                       districtId,
                                                       history,
                                                       institutions,
                                                       selectedAssessment,
                                                       studentProtocolCorrectiveResults
                                                   }) => {

    const [titleIsSet, setTitleIsSet] = useState(false);
    const [currentDateWindowId, setCurrentDateWindowId] = useState("");

    const onSummaryClick = (studentRecordId) => {
        history.push(ComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS.path
            .replace(ComplianceLocations.DISTRICT_ID, districtId)
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId));
    };

    const updateTitle = () => {
        if (titleIsSet || !institutions || institutions.length === 0) return;

        const pageTitle = MonitoringComplianceUtilities.createPageTitle(institutions, districtId, "Student Specific Corrections Summary");
        actions.updatePageTitle(pageTitle);

        setTitleIsSet(true);
    }

    useEffect(() => {
        if (!MonitoringComplianceUtilities.isValidId(dateWindowId)
            || !MonitoringComplianceUtilities.isValidId(districtId)
            || currentDateWindowId === dateWindowId) return;

        actions.loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId);
        setCurrentDateWindowId(dateWindowId);

    }, [dateWindowId, districtId]);

    useEffect(() => {
        updateTitle();
    }, [institutions]);

    if (!studentProtocolCorrectiveResults) return null;

    return (
        <section className={"compliance__studentCorrectionResultsSummary"}>
            {
                studentProtocolCorrectiveResults.length === 0 &&
                <p>No student specific corrections are needed at this time.</p>
            }
            {
                studentProtocolCorrectiveResults.length > 0 &&
                <div>
                    <StudentSummaryGrid
                        selectStudentCorrections={onSummaryClick}
                        studentRecords={buildStudentRecordsNeedingCorrectionsList(studentProtocolCorrectiveResults, selectedAssessment.indicatorRequirements)}
                    />
                </div>
            }
        </section>
    );
}

StudentCorrectionsStateSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAssessment: PropTypes.object.isRequired,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
};
