import React from 'react';
import PropTypes from 'prop-types';
import ActionBarWrapper from "./ActionBarWrapper";
import Allow from "../../authorization/Allow";
import audit_outcomes from "../../../constants/auditOutcomes";
import Button from "./Button";
import {getOutcomeButtonStyleFromOutcome, getOutcomeButtonTitleFromOutcome} from "./AuditBarUtilities";
import {DefaultPolicy, policyEvents} from "../../authorization/policies/DefaultPolicy";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ActionBarPosition from "../../../constants/ActionBarPositions";

const AuditBar = ({
	                  onOutcomeChange,
	                  elementId,
	                  disabled,
	                  position,
	                  isExpanded,
	                  outcome,
	                  policyEvent,
	                  policy,
	                  showAccepted
                  }) => {

	const buttonType = outcome ? getOutcomeButtonStyleFromOutcome(outcome) : ButtonTypes.NEEDS_REVIEW;
	const buttonLabel = outcome ? getOutcomeButtonTitleFromOutcome(outcome) : audit_outcomes.NOT_SUBMITTED.value;
	const showLabel = isExpanded && position !== ActionBarPosition.RIGHT_TOP;
	const shouldExpand = showLabel;

	return (
		<ActionBarWrapper
			buttonType={buttonType}
			buttonLabel={buttonLabel}
			isExpanded={shouldExpand}
			position={position}
		>
			{
				onOutcomeChange &&
				(outcome === audit_outcomes.SUBMITTED.value) &&
				showAccepted &&
				<Allow policy={policy} policyEvent={policyEvent}>
					<Button
						buttonType={ButtonTypes.REVIEWED}
						disabled={disabled}
						label={audit_outcomes.ACCEPTED.value}
						name={`${audit_outcomes.ACCEPTED.name}_${elementId}`}
						onClick={onOutcomeChange}
						showLabel={showLabel}
					/>
				</Allow>
			}
			{
				onOutcomeChange &&
				(outcome === audit_outcomes.SUBMITTED.value) &&
				<Allow policy={policy} policyEvent={policyEvent}>
					<Button
						buttonType={ButtonTypes.NEEDS_REVISIONS}
						disabled={disabled}
						label={audit_outcomes.NEEDS_REVISION.value}
						name={`${audit_outcomes.NEEDS_REVISION.name}_${elementId}`}
						onClick={onOutcomeChange}
						showLabel={showLabel}
					/>
				</Allow>
			}
			{
				onOutcomeChange &&
				(outcome === audit_outcomes.ACCEPTED.value || outcome === audit_outcomes.NEEDS_REVISION.value) &&
				<Allow policy={policy} policyEvent={policyEvent}>
					<Button
						buttonType={ButtonTypes.CANCEL}
						disabled={disabled}
						label={audit_outcomes.RESET.value}
						name={`${audit_outcomes.RESET.name}_${elementId}`}
						onClick={onOutcomeChange}
						showLabel={showLabel}
					/>
				</Allow>
			}
		</ActionBarWrapper>
	);
};

AuditBar.propTypes = {
	onOutcomeChange: PropTypes.func,
	outcome: PropTypes.string,
	elementId: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
	position: PropTypes.string,
	policyEvent: PropTypes.string,
	policy: PropTypes.func,
	isExpanded: PropTypes.bool,
	showAccepted: PropTypes.bool
};

AuditBar.defaultProps = {
	position: ActionBarPosition.RIGHT,
	isExpanded: true,
	disabled: false,
	showAccepted: true,
	policyEvent: policyEvents.CERTIFY,
	policy: DefaultPolicy
};

export default AuditBar;