import {mockResolveApiCall} from "../../../api/ApiUtility";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_ROOT} from "../bpieLocations"
import {BpieApiMessages} from "../bpieConstants";
import LocalStorage from "../../../components/shared/LocalStorage";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictApi {
    static getSchoolsWithPendingSbpies(){
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/pendingSbpies`,
                RequestTypes.GET));
    }

    static activateSelectedSbpies(selectedPendingSbpies){
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/activateSbpies`,
                RequestTypes.PUT,
                JSON.stringify(selectedPendingSbpies),
                BpieApiMessages.DISTRICT.NOTIFY_PRINCIPAL_START.SUCCESS
            ));
    }

    static getSchoolsWithSbpieErrors(){
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/SbpieErrors`,
                RequestTypes.GET));
    }

    static getNotApplicableSbpies(){
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/NotApplicable`,
                RequestTypes.GET));
    }

    static getSchoolBpieByErrorId(schoolBpieErrorId) {
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/Sbpies/${schoolBpieErrorId}`,
                RequestTypes.GET));
    }

    static removeSchoolBpieError(schoolBpieErrorId){
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}/SbpieErrors/${schoolBpieErrorId}/fixed`,
                RequestTypes.DELETE,
                null,
                BpieApiMessages.DISTRICT.SBPIE_ERRORS.FIXED
            ));
    }

    static getSchoolSummary(districtId, schoolNumber, schoolBpieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/schools/${schoolNumber}/sbpies/${schoolBpieId}`,
                RequestTypes.GET));
    }

    static createSbpie(districtId, schoolNumber){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/schools/${schoolNumber}`,
                RequestTypes.PUT,
                null,
                BpieApiMessages.DISTRICT.CREATE_SBPIE.SUCCESS
            ));
    }

    static activateSbpie(districtId, schoolNumber){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/schools/${schoolNumber}/activate`,
                RequestTypes.PUT,
                null,
                BpieApiMessages.DISTRICT.ACTIVATE_SBPIE.SUCCESS
            ));
    }

    static updateSbpieStatus(districtId, schoolNumber, bpieStatus){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/schools/${schoolNumber}/status/${bpieStatus}`,
                RequestTypes.PUT,
                null,
                BpieApiMessages.DISTRICT.STATUS_UPDATED.SUCCESS
            ));
    }

    static resendPrincipalNotification(districtId, schoolBpieId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/sbpies/${schoolBpieId}/resendNotification`,
                RequestTypes.POST,
                null,
                BpieApiMessages.DISTRICT.NOTIFY_PRINCIPAL_START.RESEND_SUCCESS
            ));
    }

    static getDistrictContacts(districtId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/contacts`,
                RequestTypes.GET));
    }

    static getTopSbpieIndicators(dateWindowId, districtId, districtPieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/topSbpieIndicators`,
                RequestTypes.GET));
    }

    static getDistrictBpie(dateWindowId, districtId, districtBpieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}`,
                RequestTypes.GET));
    }

    static isDistrictBpieStatusDistrictReviewing() {
        const user = LocalStorage.getLoggedInUser();
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${user.InstitutionId}`,
                RequestTypes.GET));
    }

    static saveDistrictBpie(dateWindowId, districtId, districtBpieId, districtInfoModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dbpies/${districtBpieId}`,
                RequestTypes.PUT,
                JSON.stringify(districtInfoModel),
                BpieApiMessages.DBPIE.UPDATED
            ));
    }

    static saveDistrictResponseBpie(districtId, districtBpieId, indicatorId, response){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/dbpies/${districtBpieId}/indicators/${indicatorId}/response`,
                RequestTypes.PUT,
                JSON.stringify(response),
                BpieApiMessages.ASSESSMENT.UPDATE_RESPONSE.SUCCESS
            ));
    }

    static saveDistrictFeedback(districtId, districtBpieId, districtFeedback) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/dbpies/${districtBpieId}/feedback`,
                RequestTypes.PUT,
                JSON.stringify({districtFeedback}),
                BpieApiMessages.DBPIE.FEEDBACK_SAVED
            ));
    }

    static updateDistrictBpieStatus(districtId, districtBpieId, bpieStatus, districtFeedback) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/dbpies/${districtBpieId}/status/${bpieStatus}`,
                RequestTypes.PUT,
                JSON.stringify({districtFeedback}),
                BpieApiMessages.DBPIE.STATUS_UPDATED
            ));
    }

    static saveDistrictBpieIndicatorPriorities(districtId, districtBpieId, priorityBpieIndicatorIds){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/districts/${districtId}/dbpies/${districtBpieId}/priorities`,
                RequestTypes.PUT,
                JSON.stringify(priorityBpieIndicatorIds),
                BpieApiMessages.ASSESSMENT.SAVE_PRIORITIES.SUCCESS
            )
        );
    }

    static getDistrictPie(dateWindowId, districtId, districtPieId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}`,
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictApi {
    static getSchoolsWithPendingSbpies(){
        return mockResolveApiCall({});
    }

    static activateSelectedSbpies(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.NOTIFY_PRINCIPAL_START.SUCCESS);
    }

    static getSchoolsWithSbpieErrors() {
        return mockResolveApiCall({});
    }

    static getNotApplicableSbpies() {
        return mockResolveApiCall({});
    }

    static removeSchoolBpieError(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.SBPIE_ERRORS.FIXED);
    }

    static getSchoolSummary() {
        return mockResolveApiCall({});
    }

    static createSbpie(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.CREATE_SBPIE.SUCCESS);
    }

    static activateSbpie(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.ACTIVATE_SBPIE.SUCCESS);
    }

    static updateSbpieStatus(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.STATUS_UPDATED.SUCCESS);
    }

    static resendPrincipalNotification(){
        return mockResolveApiCall({}, BpieApiMessages.DISTRICT.NOTIFY_PRINCIPAL_START.RESEND_SUCCESS);
    }
    static getDistrictContacts() {
        return mockResolveApiCall([]);
    }
    static getTopSbpieIndicators() {
        return mockResolveApiCall([]);
    }

    static getDistrictBpie() {
        return mockResolveApiCall({});
    }

    static getSchoolBpieByErrorId() {
        return mockResolveApiCall({});
    }

    static saveDistrictBpie(){
        return mockResolveApiCall({}, BpieApiMessages.DBPIE.UPDATED);
    }

    static saveDistrictResponseBpie(){
        return mockResolveApiCall({}, BpieApiMessages.ASSESSMENT.UPDATE_RESPONSE.SUCCESS);
    }

    static saveDistrictFeedback() {
        return mockResolveApiCall({}, BpieApiMessages.DBPIE.FEEDBACK_SAVED);
    }

    static updateDistrictBpieStatus() {
        return mockResolveApiCall({}, BpieApiMessages.DBPIE.STATUS_UPDATED);
    }

    static saveDistrictBpieIndicatorPriorities(){
        return mockResolveApiCall({}, BpieApiMessages.ASSESSMENT.SAVE_PRIORITIES.SUCCESS);
    }
    static getDistrictPie() {
        return mockResolveApiCall({});
    }
}

const bpieDistrictApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictApi : MockBpieDistrictApi;
export default bpieDistrictApi;