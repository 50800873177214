export const VIEW = "VIEW_CONTEXT";
export const CERTIFY = "CERTIFY_AUDIT";
export const MANAGE = "MANAGE_CONTEXT";
export const ADMIN_MENU = "ADMIN_MENU";

export const IMPERSONATE = "IMPERSONATE";
export const DELETE = "DELETE";
export const MODIFY_BEHAVIORS = "MODIFY_BEHAVIORS";
export const ASSIGN_INSTITUTION = "ASSIGN_INSTITUTION";

export const MARK_READY_TO_SUBMIT = "MARK_READY_TO_SUBMIT";
export const SUBMIT_SPP_SECTION = "SUBMIT_SPP_SECTION";
export const AUDIT_SPP_RESPONSES = "AUDIT_SPP_RESPONSES";
export const PRE_APPROVE_SPP_DOCUMENT = "PRE_APPROVE_SPP_DOCUMENT";
export const APPROVE_SPP_DOCUMENT = "APPROVE_SPP_DOCUMENT";

export const EDIT_PLAN = "EDIT_PLAN";
export const ADD_STRATEGIC_PLAN = "ADD_STRATEGIC_PLAN";
export const ADD_STRATEGY = "ADD_STRATEGY";
export const ADD_INDICATOR = "ADD_INDICATOR";
export const ADD_ACTION = "ADD_ACTION";
export const ADD_SUB_ACTION = "ADD_SUB_ACTION";
export const ADD_GOAL = "ADD_GOAL";
export const ADD_PROGRESS_MONITORING = "ADD_PROGRESS_MONITORING";
export const ADD_TEAM_MEMBER = "MANAGE_TEAM_MEMBER";
export const MANAGE_TEAM_MEMBER_DISTRICT_ASSIGNMENT = "MANAGE_TEAM_MEMBER_DISTRICT_ASSIGNMENT";
export const ADD_METRICS = "ADD_METRICS";

const monitoring_performance_type = "GSW/MONITORING_PERFORMANCE";
export const monitoring_performance = {
	response: {
		ACTION_BAR: `${monitoring_performance_type}/response/ACTION_BAR`,
		AUDIT:  `${monitoring_performance_type}/response/AUDIT`,
		MODIFY: `${monitoring_performance_type}/response/MODIFY`,
		SUBMIT: `${monitoring_performance_type}/response/SUBMIT`,
	},
	action: {
		MODIFY: `${monitoring_performance_type}/action/MODIFY`,
	},
	indicator: {
		MODIFY: `${monitoring_performance_type}/indicator/MODIFY`,
	},
	assessment: {
		MODIFY: `${monitoring_performance_type}/assessment/MODIFY`,
		SUBMIT: `${monitoring_performance_type}/assessment/SUBMIT`,
	}
};

const monitoring_compliance_type = "GSW/MONITORING_COMPLIANCE";
export const monitoring_compliance = {
	protocolRequirement:{
		ACTION_BAR: `${monitoring_compliance_type}/requirement/ACTION_BAR`,
		MODIFY: `${monitoring_compliance_type}/requirement/MODIFY`
	},
	assessment: {
		MODIFY: `${monitoring_compliance_type}/assessment/MODIFY`,
		SUBMIT: `${monitoring_compliance_type}/assessment/SUBMIT`
	},
	studentRecord: {
		MODIFY: `${monitoring_compliance_type}/studentRecord/MODIFY`,
		SUBMIT: `${monitoring_compliance_type}/studentRecord/SUBMIT`
	},
	activity: {
		MODIFY: `${monitoring_compliance_type}/activity/MODIFY`,
		SUBMIT: `${monitoring_compliance_type}/activity/SUBMIT`
	},
	caseNote: {
		MODIFY: `${monitoring_compliance_type}/caseNote/MODIFY`
	},
	review: {
		MODIFY: `${monitoring_compliance_type}/review/MODIFY`,
		ACTION_BAR: `${monitoring_compliance_type}/cap/ACTION_BAR`
	},
	cap: {
        AUDIT:  `${monitoring_compliance_type}/cap/AUDIT`,
		SUBMIT: `${monitoring_compliance_type}/cap/SUBMIT`
	},
	studentCorrections: {
		MODIFY: `${monitoring_compliance_type}/studentCorrections/MODIFY`,
		SUBMIT: `${monitoring_compliance_type}/studentCorrections/SUBMIT`,
		AUDIT:  `${monitoring_compliance_type}/studentCorrections/AUDIT`,
	}
};

const dispute_resolution_type = "gsw/DISPUTE_RESOLUTION";
export const dispute_resolution = {
	stateComplaint: {
		MODIFY: `${dispute_resolution_type}/complaint/MODIFY`,
		SUBMIT: `${dispute_resolution_type}/complaint/SUBMIT`,
		response: {
			AUDIT:  `${dispute_resolution_type}/complaint/response/AUDIT`,
			MODIFY: `${dispute_resolution_type}/complaint/response/MODIFY`,
			SUBMIT: `${dispute_resolution_type}/complaint/response/SUBMIT`,
			REVIEW: `${dispute_resolution_type}/complaint/response/REVIEW`,
		},
	},

	dueProcess: {
		MODIFY: `${dispute_resolution_type}/dueProcess/MODIFY`,
		audits: {
			AUDIT:  `${dispute_resolution_type}/dueProcess/response/AUDIT`,
			SUBMIT: `${dispute_resolution_type}/dueProcess/response/SUBMIT`,
			REVIEW: `${dispute_resolution_type}/dueProcess/response/REVIEW`,
		}
	},

	mediation: {
		MODIFY: `${dispute_resolution_type}/mediation/MODIFY`
	},
};

const bpie_type = "GSW/BPIE";
export const bpie = {
	admin: {
		MANAGE: `${bpie_type}/admin/MANAGE`
	},
	districtBpieAssessments: {
		MODIFY: `${bpie_type}/districtBpieAssessments/MODIFY`,
		SUBMIT: `${bpie_type}/districtBpieAssessments/SUBMIT`,
		IMPERSONATE: `${bpie_type}/districtBpieAssessments/IMPERSONATE`,
	},
	districtPieAssessments: {
		MODIFY: `${bpie_type}/districtPieAssessments/MODIFY`,
		MODIFY_NOTES: `${bpie_type}/districtPieAssessments/MODIFY_NOTES`,
	},
	schoolBpieAssessments: {
		REVIEW: `${bpie_type}/schoolBpieAssessments/REVIEW`,
		MODIFY: `${bpie_type}/schoolBpieAssessments/MODIFY`,
		IMPERSONATE: `${bpie_type}/schoolBpieAssessments/IMPERSONATE`,
		SUBMIT: `${bpie_type}/schoolBpieAssessments/SUBMIT`,
		MANAGE_ADMINISTRATOR: `${bpie_type}/schoolBpieAssessments/MANAGE_ADMINISTRATOR`,
		UPDATE_STATUS: `${bpie_type}/schoolBpieAssessments/UPDATE_STATUS`,
		CREATE_ACTIVATE: `${bpie_type}/schoolBpieAssessments/ACTIVATE`,
	},
	teamMember: {
		REVIEW: `${bpie_type}/teamMember/REVIEW`,
		MODIFY: `${bpie_type}/teamMember/MODIFY`
	}
}

const gms_type = "GSW/GMS";
export const GMS = {
	APPLICATION: {
		review: `${gms_type}/application/REVIEW`,
		modify: `${gms_type}/application/MODIFY`,
		createAmendment: `${gms_type}/application/CREATE_AMENDMENT`,
		submit: `${gms_type}/application/SUBMIT`,
		uploadDoe200: `${gms_type}/application/UPLOAD_DOE_200`,

	},
	ADMIN: {
		manage: `${gms_type}/admin/MANAGE`,
		audit: `${gms_type}/admin/AUDIT`,
		CASE_NOTE: {
			modify: `${gms_type}/admin/caseNote/MODIFY`
		}
	}
}

const pts_type = "GSW/PTS";
export const PTS = {
	ADMIN: {
		manage: `${pts_type}/admin/MANAGE`,
	},
	IMPLEMENTATIONS: {
		edit: `${pts_type}/implementations/EDIT`,
		delete: `${pts_type}/implementations/DELETE`
	},
	QUARTERLY_REPORTS: {
		submit: `${pts_type}/quarterlyReports/SUBMIT`
	},
	SOURCE_DOCUMENTATION: {
		modify: `${pts_type}/returnOnInvestment/MODIFY`
	},
	RETURN_ON_INVESTMENT: {
		modify: `${pts_type}/returnOnInvestment/MODIFY`
	}
}