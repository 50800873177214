export const ASSESSMENT_ID = ":assessmentId";
export const DATE_ROUTE = "/Date/";
export const DATE_WINDOW_ID = ":dateWindowId";
export const ROOT = `/MonitoringCompliance`;
export const REQUIREMENT_ID = ":indicatorRequirementId";
export const STUDENT_RECORD_ID = ":studentRecordId";
export const PROTOCOL_ID = ":protocolId";
export const DISTRICT_ROUTE = "/District/";
export const DISTRICT_ID = ":districtId";
export const CURRENT_PAGE = ":page";
export const IS_MATRIX = ":isMatrix";
export const MATRIX = "Matrix";
export const STATUS = ":status";
export const NON_COMPLIANCE_STATUS = ":nonComplianceStatus";
export const INDICATOR_TYPE = ":indicatorType";

//DISTRICT
export const LANDING_DISTRICT = {
	path: `${ROOT}/Assessment`,
	params: {}
};

export const DISTRICT_NOTIFICATION_SETTINGS = {
	path: `${ROOT}/Notifications/Settings`,
	params: {}
};

export const LANDING_DISTRICT_DATE_WINDOW = {
	path: `${LANDING_DISTRICT.path}${DATE_ROUTE}${DATE_WINDOW_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID
	}
};

export const COMPLIANCE_RECORD_DISTRICT = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/IndicatorRequirement/${REQUIREMENT_ID}/Record/${STUDENT_RECORD_ID}`,
	params: {
		assessmentId: ASSESSMENT_ID,
		indicatorRequirementId: REQUIREMENT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};

export const COMPLIANCE_MATRIX_RECORD_DISTRICT = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/IndicatorRequirement/${REQUIREMENT_ID}/Record/${STUDENT_RECORD_ID}/Matrix`,
	params: {
		assessmentId: ASSESSMENT_ID,
		indicatorRequirementId: REQUIREMENT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};

export const COMPLIANCE_REVIEW_SUBMISSION = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/IndicatorRequirement/${REQUIREMENT_ID}/Review`,
	params: {
		assessmentId: ASSESSMENT_ID,
		indicatorRequirementId: REQUIREMENT_ID
	}
};

export const COMPLIANCE_REASSESS_DISTRICT_REVIEW = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/ReassessReview`,
	params: {
		dateWindowId: DATE_WINDOW_ID
	}
};

export const COMPLIANCE_REASSESS_DISTRICT = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/Reassess`,
	params: {
		assessmentId: ASSESSMENT_ID
	}
};

export const DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/StudentCorrections`,
	params: {
		dateWindowId: DATE_WINDOW_ID
	}
};

export const DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY_FOR_STUDENT = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/Assessment/${ASSESSMENT_ID}/StudentCorrections/${STUDENT_RECORD_ID}/Summary`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		assessmentId: ASSESSMENT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};


export const DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/StudentCorrections/${IS_MATRIX}/Summary`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		isMatrix: IS_MATRIX
	}
};

export const DISTRICT_CORRECTIVE_ACTIONS = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/Caps`,
	params: {
		dateWindowId: DATE_WINDOW_ID
	}
};

export const DISTRICT_STUDENT_CORRECTIVE_ACTIONS = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/Assessment/${ASSESSMENT_ID}/StudentCorrections/${STUDENT_RECORD_ID}/Protocol/${PROTOCOL_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		assessmentId: ASSESSMENT_ID,
		studentRecordId: STUDENT_RECORD_ID,
		protocolId: PROTOCOL_ID
	}
};

export const DISTRICT_MATRIX_CORRECTIVE_ACTIONS = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}/Assessment/${ASSESSMENT_ID}/StudentCorrections/${IS_MATRIX}/Page/${CURRENT_PAGE}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		assessmentId: ASSESSMENT_ID,
		page: CURRENT_PAGE,
		isMatrix: IS_MATRIX
	}
};

export const AllDistrictRoutes = [
	LANDING_DISTRICT,
	LANDING_DISTRICT_DATE_WINDOW,
	DISTRICT_NOTIFICATION_SETTINGS,
	COMPLIANCE_RECORD_DISTRICT,
	COMPLIANCE_MATRIX_RECORD_DISTRICT,
	COMPLIANCE_REVIEW_SUBMISSION,
	COMPLIANCE_REASSESS_DISTRICT_REVIEW,
	COMPLIANCE_REASSESS_DISTRICT,
	DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY,
	DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY_FOR_STUDENT,
	DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY,
	DISTRICT_CORRECTIVE_ACTIONS,
	DISTRICT_STUDENT_CORRECTIVE_ACTIONS,
	DISTRICT_MATRIX_CORRECTIVE_ACTIONS];

//DOE

export const MC_SUMMARY = {
	path: `${ROOT}/Assessment/Summary`,
	params: {}
};

export const MC_NOTIFICATION_SETTINGS = {
	path: `${ROOT}/Admin/Notifications/Settings`,
	params: {}
};

export const LANDING_DOE = {
	path: `${ROOT}/Institution`,
	params: {}
};

export const LANDING_DOE_DATE_WINDOW = {
	path: `${LANDING_DOE.path}${DATE_ROUTE}${DATE_WINDOW_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID
	}
};

export const LANDING_DOE_DATE_WINDOW_STATUS = {
	path: `${LANDING_DOE.path}${DATE_ROUTE}${DATE_WINDOW_ID}/Overall/${STATUS}/NonCompliance/${NON_COMPLIANCE_STATUS}/Type/${INDICATOR_TYPE}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		status: STATUS,
		nonComplianceStatus: NON_COMPLIANCE_STATUS,
		indicatorType: INDICATOR_TYPE
	}
};

export const MANAGE_PROTOCOLS_DOE_DISTRICT = {
	path: `${LANDING_DOE.path}/Protocols${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID
	}
};

export const VALIDATE_STUDENT_RECORDS_DOE_DISTRICT = {
	path: `${LANDING_DOE.path}/ValidateRecords${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID
	}
};

export const COMPLIANCE_MATRIX_RECORD_VALIDATE = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/IndicatorRequirement/${REQUIREMENT_ID}/Record/${STUDENT_RECORD_ID}/Matrix/Assess`,
	params: {
		assessmentId: ASSESSMENT_ID,
		indicatorRequirementId: REQUIREMENT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};


export const COMPLIANCE_RECORD_VALIDATE = {
	path: `${ROOT}/Assessment/${ASSESSMENT_ID}/IndicatorRequirement/${REQUIREMENT_ID}/Record/${STUDENT_RECORD_ID}/Assess`,
	params: {
		assessmentId: ASSESSMENT_ID,
		indicatorRequirementId: REQUIREMENT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};

export const MANAGE_CORRECTIVE_ACTIONS_DOE_DISTRICT = {
	path: `${LANDING_DOE.path}/Caps${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID
	}
};

export const DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY = {
	path: `${LANDING_DOE.path}/Student${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}/Summary`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID
	}
};

export const DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY = {
	path: `${LANDING_DOE.path}/Student${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}/${IS_MATRIX}/Summary`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID,
		isMatrix: IS_MATRIX
	}
};

export const DOE_STUDENT_CORRECTIVE_ACTIONS = {
	path: `${LANDING_DOE.path}${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}/Students/${STUDENT_RECORD_ID}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID,
		studentRecordId: STUDENT_RECORD_ID
	}
};

export const DOE_MATRIX_CORRECTIVE_ACTIONS = {
	path: `${LANDING_DOE.path}/Student${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}/${IS_MATRIX}/Page/${CURRENT_PAGE}`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID,
		page: CURRENT_PAGE,
		isMatrix: IS_MATRIX
	}
};

export const DOE_CASE_NOTES = {
	path: `${ROOT}${DATE_ROUTE}${DATE_WINDOW_ID}${DISTRICT_ROUTE}${DISTRICT_ID}/CaseNotes`,
	params: {
		dateWindowId: DATE_WINDOW_ID,
		districtId: DISTRICT_ID
	}
};

export const AllDoeRoutes = [
	MC_SUMMARY,
	MC_NOTIFICATION_SETTINGS,
	LANDING_DOE,
	LANDING_DOE_DATE_WINDOW,
	LANDING_DOE_DATE_WINDOW_STATUS,
	MANAGE_PROTOCOLS_DOE_DISTRICT,
	VALIDATE_STUDENT_RECORDS_DOE_DISTRICT,
	COMPLIANCE_MATRIX_RECORD_VALIDATE,
	COMPLIANCE_RECORD_VALIDATE,
	MANAGE_CORRECTIVE_ACTIONS_DOE_DISTRICT,
	DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY,
	DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY,
	DOE_STUDENT_CORRECTIVE_ACTIONS,
	DOE_MATRIX_CORRECTIVE_ACTIONS,
	DOE_CASE_NOTES
];