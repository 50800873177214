import {mockResolveApiCall} from "../../api/ApiUtility";
import * as config from "../../constants/config";
import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../api/requests/RequestTypes';
import * as mockData from "./gmsMockData";
import {API_ROOT} from "./gmsLocations";
import {emptyGuid} from "../../constants/config";
import {API_MESSAGES} from "./gmsConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsApi {

    static deleteBudgetLineItem(applicationId, budgetId, budgetLineItemId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/budgets/${budgetId}/lineItems/${budgetLineItemId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.BUDGET.DELETE_LINE_ITEM.success,
                'application/json')
        );
    }

    static exportBudget(applicationId, budgetId, applicationHistoryId = null) {
        const exportBudgetHistoryUrl = !applicationHistoryId || applicationHistoryId === emptyGuid
            ? ``
            : `histories/${applicationHistoryId}/`;

        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/budgets/${budgetId}/${exportBudgetHistoryUrl}lineItems/export`,
                RequestTypes.GET,
                null,
                API_MESSAGES.BUDGET.EXPORT.success)
        );
    }

    static getSummary(dateWindowId, fiscalAgentId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/fiscalAgents/${fiscalAgentId}/applications`,
                RequestTypes.GET)
        );
    }

    static getApplication(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/sections/${emptyGuid}`,
                RequestTypes.GET)
        );
    }

    static uploadSignedForm(files, applicationId, formType) {
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded.');

        let data = new FormData();
        data.append('file', files[0]);

        let request = AjaxRequestOptions(
            `${config.apiUrl}${API_ROOT}/applications/${applicationId}/forms/${formType.id}/upload`,
            RequestTypes.POST,
            data,
            API_MESSAGES.DOE_FORMS.UPLOAD.getMessage(formType, true));

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static downloadSignedFormWithFileId(fileId, formType) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}files/${fileId}`,
                RequestTypes.GET,
                null,
                API_MESSAGES.DOE_FORMS.DOWNLOAD.getMessage(formType, true)
            )
        );
    }

    static downloadSignedForm(applicationId, formType, applicationHistoryId = emptyGuid) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/history/${applicationHistoryId}/forms/${formType.id}/signed`,
                RequestTypes.GET,
                null,
                API_MESSAGES.DOE_FORMS.DOWNLOAD.getMessage(formType, true)
            )
        );
    }

    static downloadGeneratedForm(applicationId, formType, applicationHistoryId = emptyGuid) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/history/${applicationHistoryId}/forms/${formType.id}`,
                RequestTypes.GET,
                null,
                API_MESSAGES.DOE_FORMS.DOWNLOAD.getMessage(formType, false)
            )
        );
    }

    static getCompleteApplication(applicationId, applicationHistoryId = null) {

        const printApplicationHistoryUrl = !applicationHistoryId
            ? ``
            : `histories/${applicationHistoryId}/`;

        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/${printApplicationHistoryUrl}`,
                RequestTypes.GET)
        );
    }

    static getApplicationBudget(applicationId, budgetId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/budgets/${budgetId}`,
                RequestTypes.GET)
        );
    }

    static getApplicationSection(applicationId, sectionId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/sections/${sectionId}`,
                RequestTypes.GET)
        );
    }

    static getBudgetCodes() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/budgetCodes`,
                RequestTypes.GET)
        );
    }

    static importBudget(files, applicationId, budgetId) {
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let data = new FormData();
        data.append('file', files[0]);

        let request = AjaxRequestOptions(
            `${config.apiUrl}${API_ROOT}/applications/${applicationId}/budgets/${budgetId}/lineItems/import`,
            RequestTypes.POST,
            data,
            API_MESSAGES.BUDGET.IMPORT.success);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static saveApplicationResponse(applicationId, sectionId, saveApplicationResponses) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/responses/${sectionId}`,
                RequestTypes.PUT,
                JSON.stringify(saveApplicationResponses),
                API_MESSAGES.ASSESSMENT.UPDATE_RESPONSE.success,
                'application/json')
        );
    }

    static saveBudgetLineItem(applicationId, budgetId, budgetLineItem) {
        const {budgetLineItemId} = budgetLineItem;
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/budgets/${budgetId}/lineItems/${budgetLineItemId}`,
                RequestTypes.POST,
                JSON.stringify(budgetLineItem),
                API_MESSAGES.BUDGET.SAVE_LINE_ITEM.success,
                'application/json')
        );
    }

    static getTeamMembers(activeOnly = false) {
        const activeOnlyRoute = activeOnly ? "/active" : "/all";
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/teamMembers${activeOnlyRoute}`,
                RequestTypes.GET));
    }

    static submitApplication(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${applicationId}/submitted`,
                RequestTypes.PUT,
                null,
                API_MESSAGES.ASSESSMENT.SUBMIT_APPLICATION.success)
        );
    }

    static auditResponse(applicationId, sectionId, auditAction) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/sections/${sectionId}/${auditAction}`,
                RequestTypes.POST,
                null,
                API_MESSAGES.ADMIN.AUDIT.getMessage(auditAction)
            )
        );
    }

    static saveAuditComment(applicationId, elementId, sectionId, comment, existing, auditAction="comments") {
        const elementUrl = !elementId || existing ? `` : `/elements/${elementId}`;

        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/sections/${sectionId}${elementUrl}/audits/${comment.auditId}/${auditAction}`,
                existing ? RequestTypes.PUT : RequestTypes.POST,
                JSON.stringify(comment),
                API_MESSAGES.ADMIN.AUDIT.getMessage(auditAction)
            )
        );
    }

    static getApplicationHistories(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/histories`,
                RequestTypes.GET));
    }

    static getApplicationDeliverables(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/deliverables`,
                RequestTypes.GET));
    }
    static saveApplicationDeliverable(applicationId, deliverable) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/deliverables`,
                RequestTypes.PUT,
                JSON.stringify(deliverable),
                API_MESSAGES.DELIVERABLE.SAVE_LINE_ITEM.success
            )
        );
    }
    static deleteApplicationDeliverable(applicationId, deliverableId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/deliverables/${deliverableId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.DELIVERABLE.DELETE_LINE_ITEM.success,
                'application/json')
        );
    }

    static getApplicationSalaries() {
        return mockResolveApiCall(mockData.getApplicationSalaries());
    }
    static saveApplicationSalary() {
        return mockResolveApiCall(mockData.getApplicationSalaries(), API_MESSAGES.SALARY.SAVE_LINE_ITEM.success);
    }
    static deleteApplicationSalary() {
        return mockResolveApiCall(mockData.getApplicationSalaries(), API_MESSAGES.SALARY.DELETE_LINE_ITEM.success);
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsApi {
    static deleteBudgetLineItem(applicationId, budgetId, budgetLineItemId) {
        return mockResolveApiCall(mockData.deleteBudgetItem(budgetLineItemId), API_MESSAGES.BUDGET.DELETE_LINE_ITEM.success);
    }

    static uploadSignedForm(files, applicationId, formType) {
        return mockResolveApiCall("", API_MESSAGES[formType].SIGNED.uploadSuccess);
    }

    static downloadSignedForm(applicationId, formType) {
        return mockResolveApiCall("", API_MESSAGES[formType].DOWNLOAD.success);
    }

    static downloadGeneratedForm(applicationId, formType) {
        return mockResolveApiCall("", API_MESSAGES[formType].DOWNLOAD.success);
    }

    static exportBudget() {
        return mockResolveApiCall("", API_MESSAGES.BUDGET.EXPORT.success);
    }

    static getSummary(dateWindowId) {
        return mockResolveApiCall(mockData.getSummary(dateWindowId));
    }

    static getApplication() {
        return mockResolveApiCall(mockData.getApplication());
    }

    static getCompleteApplication() {
        return mockResolveApiCall(mockData.getApplication());
    }

    static getApplicationBudget() {
        return mockResolveApiCall(mockData.getApplicationBudget());
    }

    static getApplicationSection() {
        return mockResolveApiCall(mockData.getApplicationSection());
    }

    static getBudgetCodes() {
        return mockResolveApiCall(mockData.getBudgetCodes());
    }

    static importBudget() {
        return mockResolveApiCall("", API_MESSAGES.BUDGET.IMPORT.success);
    }

    static saveApplicationResponse() {
        return mockResolveApiCall("", API_MESSAGES.ASSESSMENT.UPDATE_RESPONSE.success);
    }

    static saveBudgetLineItem(applicationId, budgetId, budgetLineItem) {
        return mockResolveApiCall(mockData.saveBudgetItem(budgetLineItem), API_MESSAGES.BUDGET.SAVE_LINE_ITEM.success);
    }
    static getTeamMembers() {
        return mockResolveApiCall([]);
    }

    static submitApplication() {
        return mockResolveApiCall("", API_MESSAGES.ASSESSMENT.SUBMIT_APPLICATION.success);
    }

    static auditResponse(applicationId, sectionId, auditAction) {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.AUDIT.getMessage(auditAction));
    }

    static saveAuditComment(applicationId, elementId, sectionId, comment, existing, auditAction="comments") {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.AUDIT.getMessage(auditAction));
    }

    static getApplicationHistories() {
        return mockResolveApiCall([]);
    }

    static getApplicationDeliverables() {
        return mockResolveApiCall(mockData.getApplicationDeliverables());
    }
    static saveApplicationDeliverable() {
        return mockResolveApiCall(mockData.getApplicationDeliverables(), API_MESSAGES.DELIVERABLE.SAVE_LINE_ITEM.success);
    }
    static deleteApplicationDeliverable() {
        return mockResolveApiCall(mockData.getApplicationDeliverables(), API_MESSAGES.DELIVERABLE.DELETE_LINE_ITEM.success);
    }

    static getApplicationSalaries() {
        return mockResolveApiCall(mockData.getApplicationSalaries());
    }
    static saveApplicationSalary() {
        return mockResolveApiCall(mockData.getApplicationSalaries(), API_MESSAGES.SALARY.SAVE_LINE_ITEM.success);
    }
    static deleteApplicationSalary() {
        return mockResolveApiCall(mockData.getApplicationSalaries(), API_MESSAGES.SALARY.DELETE_LINE_ITEM.success);
    }
}

const gmsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsApi : MockGmsApi;
export default gmsApi;