import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {SppPolicy} from "../../components/authorization/policies/SppPolicy";
import * as sppActions from "../../actions/sppActions";
import * as sppReviewActions from "../../actions/sppReviewActions";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as fileActions from "../../actions/fileActions";
import {openInNewTab} from "../../components/common/HtmlUtilities";
import Switch from "../../components/common/inputs/Switch";
import {emptyGuid} from "../../constants/config";
import * as progressTextPositions from "../../constants/progressBarTextPositions";
import * as sppUtilities from "../../components/spp/SppUtilities";
import * as policyEvents from "../../constants/policyEvents";
import LocalStorage from "../../components/shared/LocalStorage";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import DocumentPart from "../../components/spp/DocumentPart";
import ProgressBar from "../../components/common/ProgressBar";
import FileUploadButton from "../../components/common/inputs/FileUploadButton";
import Allow from "../../components/authorization/Allow";
import * as sppLocations from "../../constants/spp/sppLocations";
import * as publicLocations from "../../constants/publicLocations";
import {getParams} from "../../components/layout/getParams";

export class SppDocumentPage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isTitleSet: false,
            params: {},
            showNeeds: false
        };

        this.selectSection = this.selectSection.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
        this.clearFileSelection = this.clearFileSelection.bind(this);
        this.onApprovalUpload = this.onApprovalUpload.bind(this);
        this.showApprovalUpload = this.showApprovalUpload.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.onChangeShowNeeds = this.onChangeShowNeeds.bind(this);
        this.onPrintPartClick = this.onPrintPartClick.bind(this);
    }

    componentDidMount() {
        const currentUser = LocalStorage.getLoggedInUser();
        const params = getParams(this.props.location.pathname, sppLocations.DOCUMENT_DISTRICT);

        if (params !== null)
            this.setState({params});

        this.props.actions.loadInstitutionResponses(currentUser.InstitutionId, params.documentId);

        if (this.props.allDateWindows.length === 0)
            this.props.actions.loadAllDateWindows();

        if (this.props.institutions.length === 0)
            this.props.actions.loadInstitutions();

        if (!this.props.selectedDocument || this.props.selectedDocument.id !== params.documentId)
            this.props.actions.loadSppDocument(params.documentId);

        if (!this.props.elements[0] || this.props.elements[0].documentId !== params.documentId)
            this.props.actions.loadSppDocumentElements(params.documentId);

        if (!this.props.policyCompliance || this.props.policyCompliance.documentId !== params.documentId)
            this.props.actions.loadPolicyCompliance(params.documentId, currentUser.InstitutionId);
    }

    componentDidUpdate() {
        if (!this.state.isTitleSet &&
            this.props.allDateWindows.length > 0 &&
            this.props.selectedDocument.id === this.state.params.documentId &&
            this.props.policyCompliance.documentId === this.state.params.documentId &&
            this.props.institutions.length > 0) {

            const pageTitle = sppUtilities.generatePageTitle(
                this.props.allDateWindows,
                this.props.policyCompliance,
                this.props.selectedDocument,
                this.props.institutions,
                LocalStorage.getLoggedInUser().InstitutionId);

            this.props.actions.updatePageTitle(pageTitle);

            this.setState({
                isTitleSet: true
            });
        }
    }

    onPrintClick(event) {
        event.preventDefault();
        const currentUser = LocalStorage.getLoggedInUser();
        openInNewTab(publicLocations.SPP_DOCUMENT_PRINT.path
            .replace(publicLocations.SPP_INSTITUTION_ID, currentUser.InstitutionId)
            .replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId));

    }

    onPrintPartClick(event, sectionId) {
        event.preventDefault();
        const currentUser = LocalStorage.getLoggedInUser();
        openInNewTab(publicLocations.SPP_DOCUMENT_SECTION_PRINT.path
            .replace(publicLocations.SPP_INSTITUTION_ID, currentUser.InstitutionId)
            .replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId)
            .replace(publicLocations.SPP_SECTION_ID, sectionId));
    }

    onApprovalUpload(event) {
        event.preventDefault();

        const currentUser = LocalStorage.getLoggedInUser();
        const currentInstitution = this.props.institutions.filter(ins => ins.id === currentUser.InstitutionId)[0];

        const selectedDocumentDateWindow = this.props.allDateWindows.filter(dw => dw.id === this.props.selectedDocument.dateWindowId)[0];
        const dateWindow = new Date(selectedDocumentDateWindow.openDate).getFullYear() + "-" + new Date(selectedDocumentDateWindow.closeDate).getFullYear();

        if (confirm("Are you sure you want to complete " + currentInstitution.name + "'s Policies and Procedures document approval for " + dateWindow + "?")) {
            this.props.actions.uploadFile(event.target.files, emptyGuid)
                .then((fileId) => {
                    if (!fileId) return;
                    this.props.actions.approveInstitutionDocument(this.props.policyCompliance.id, fileId);
                });
        }

        this.clearFileSelection();
    }

    clearFileSelection() {
        const preApprovalUpload = document.getElementById("fuApproval");
        preApprovalUpload.value = "";
    }

    selectSection(event, sectionId) {
        event.preventDefault();

        this.props.history.push(sppLocations.SECTION_DISTRICT.path
            .replace(sppLocations.DOCUMENT_ID, this.state.params.documentId)
            .replace(sppLocations.SECTION_ID, sectionId));
    }

    showApprovalUpload() {
        return this.props.policyCompliance.approvalFileId === null && this.props.policyCompliance.preApprovalFileId !== null;
    }

    downloadFile(event, fileId) {
        event.preventDefault();

        this.props.actions.downloadFile(fileId);
    }

    onChangeShowNeeds(event) {
        const showNeeds = event.target.checked;
        this.setState({showNeeds: showNeeds});
    }

    render() {
        if (this.props.allDateWindows.length === 0 ||
            this.props.elements.length === 0 ||
            this.props.responses.length === 0 ||
            this.props.elements[0].documentId !== this.state.params.documentId ||
            this.props.selectedDocument.id === undefined ||
            this.props.selectedDocument.id !== this.state.params.documentId ||
            this.props.policyCompliance.id === undefined ||
            this.props.policyCompliance.documentId !== this.state.params.documentId)
            return null;

        const inputElements = sppUtilities.getInputElements(this.props.elements);

        const totalQuestions = inputElements.length;

        const hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);

        const documentResponses =
            sppUtilities.getElementResponses(inputElements, this.props.responses);

        const lockedResponses = documentResponses.filter(response => {
            const mostRecentAudit = response.audits[0];
            return mostRecentAudit !== undefined && mostRecentAudit.isLocked;
        });

        return (
            <section>
                <ButtonBar>
                    <Button name="btnPrint"
                            label="Print All Parts"
                            buttonType={ButtonTypes.PRINT}
                            onClick={this.onPrintClick}/>

                    {//Download Signature Page
                        this.props.policyCompliance.preApprovalFileId !== null &&
                        <Button name="btnDownloadPreApproval"
                                label="Download Signature Page"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.preApprovalFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }
                    {//Upload Signed Page
                        this.showApprovalUpload() &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.APPROVE_SPP_DOCUMENT}>
                            <FileUploadButton name={"fuApproval"}
                                              label={"Upload Signed Page"}
                                              onChange={this.onApprovalUpload}
                                              disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Download Signed Page
                        this.props.policyCompliance.approvalFileId !== null &&
                        <Button name="btnDownloadApproval"
                                label="Download Signed Page"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.approvalFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }

                    {//Download State Approval Letter
                        this.props.policyCompliance.stateCertificationFileId !== null &&
                        <Button name="btnDownloadStateCertification"
                                label="Download State Approval"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.stateCertificationFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }
                    <Switch
                        label={"Toggle Needs Revision"}
                        name={"needsRevisionViewToggle"}
                        checked={this.state.showNeeds}
                        onChange={this.onChangeShowNeeds}
                        legend={"Show Needs Revision Only"}
                        showLegend
                        showLabel={false}
                        align={"center"}
                        small={"4"}
                    />
                </ButtonBar>

                <ProgressBar currentStep={lockedResponses.length} totalSteps={totalQuestions}
                             progressTextPosition={progressTextPositions.InsideBar}/>
                {
                    hierarchyJson.map((part, index) => {
                        return (<DocumentPart
                                key={"part" + index}
                                partId={part.elementId}
                                documentHierarchy={hierarchyJson}
                                elements={this.props.elements}
                                selectSection={this.selectSection}
                                responses={documentResponses}
                                showOnlyNeeds={this.state.showNeeds}
                                onPrintPartClick={this.onPrintPartClick}
                            />
                        );
                    })
                }
            </section>
        );
    }
}

SppDocumentPage.propTypes = {
    actions: PropTypes.object.isRequired,
    allDateWindows: PropTypes.array.isRequired,
    selectedDocument: PropTypes.object.isRequired,
    responses: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
    policyCompliance: PropTypes.object.isRequired,
    institutions: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        allDateWindows: state.sharedData.dateWindows,
        selectedDocument: state.spp.selectedDocument,
        elements: state.spp.documentElements,
        responses: state.spp.documentResponses,
        policyCompliance: state.spp.selectedPolicyCompliance,
        institutions: state.sharedData.institutions,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        sppActions,
        sppReviewActions,
        fileActions,
        layoutActions,
        sharedDataActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SppDocumentPage);