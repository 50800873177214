import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as surveyAdminActions from "../../actions/surveyAdminActions";
import Button from "../../components/common/buttons/Button";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import { getParamsMultiple } from "../../components/layout/getParams";

export class ManageSurveyWrapperPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.navButtonClick = this.navButtonClick.bind(this);
	    this.getCurrentLocation = this.getCurrentLocation.bind(this);
	    this.comparePaths = this.comparePaths.bind(this);
    }

    componentWillUnmount(){
        this.props.actions.clearSurveyInstanceData();
    }

    navButtonClick(event, location){
        event.preventDefault();

        if (location === this.getCurrentLocation()) return;

        if (location.includes(surveyLocations.INSTANCE_ID)) {
            const params = getParamsMultiple(this.props.location.pathname,
                 [
                     surveyLocations.MANAGE_DETAILS,
                     surveyLocations.MANAGE_ELEMENTS,
                     surveyLocations.MANAGE_RESPONDENTS,
                     surveyLocations.MANAGE_REPORTS,
                     surveyLocations.ORDER_ELEMENTS
                 ]);

            location = location.replace(surveyLocations.INSTANCE_ID, params.surveyInstanceId);
        }

        this.props.history.push(location);
    }

    comparePaths(path){
        if (path.includes(surveyLocations.INSTANCE_ID))
        {
            const params = getParamsMultiple(this.props.location.pathname,
                [
                    surveyLocations.MANAGE_DETAILS,
                    surveyLocations.MANAGE_ELEMENTS,
                    surveyLocations.MANAGE_RESPONDENTS,
                    surveyLocations.MANAGE_REPORTS,
                    surveyLocations.ORDER_ELEMENTS
                ]);

            path = path.replace(surveyLocations.INSTANCE_ID, params.surveyInstanceId);
        }

	    return (path === this.getCurrentLocation());
    }

    getCurrentLocation(){
        return this.props.location.pathname;
    }

    render() {
        const {component: Component} = this.props;

        return (
            <GridRow isPageNav>
                <GridColumn isPageNavLinks
                            isStickyLinks
                            large="3">
                    <Button btnClass={this.comparePaths(surveyLocations.MANAGE_DETAILS.path) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, surveyLocations.MANAGE_DETAILS.path)}
                            label={"Details"}
                            name={surveyLocations.MANAGE_DETAILS.path} />

                    <Button btnClass={this.comparePaths(surveyLocations.MANAGE_ELEMENTS.path) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, surveyLocations.MANAGE_ELEMENTS.path)}
                            label={"Elements"}
                            name={surveyLocations.MANAGE_ELEMENTS.path} />

	                <Button btnClass={this.comparePaths(surveyLocations.MANAGE_REPORTS.path) ? "is-active" : ""}
	                        onClick={(event) => this.navButtonClick(event, surveyLocations.MANAGE_REPORTS.path)}
	                        label={"Reports"}
	                        disabled={this.props.isLoading}
	                        name={surveyLocations.MANAGE_REPORTS.path} />

                    <Button btnClass={this.comparePaths(surveyLocations.MANAGE_RESPONDENTS.path) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, surveyLocations.MANAGE_RESPONDENTS.path)}
                            label={"Respondents"}
                            name={surveyLocations.MANAGE_RESPONDENTS.path} />

                    <Button label={"Back to Surveys"}
                            onClick={(event) => this.navButtonClick(event, surveyLocations.LANDING.path)}
                            name={surveyLocations.LANDING.path} />
                </GridColumn>

                <GridColumn isPageNavContent
                            large="9">
                    <Component {...this.props} />
                </GridColumn>
            </GridRow>
        );
    }
}

ManageSurveyWrapperPage.propTypes = {
    actions: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    layout: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, layoutActions, surveyAdminActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ManageSurveyWrapperPage));