import React from 'react';
import PropTypes from 'prop-types';
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

const BpieDistrictSummaryRowView = ({
                                        bpie,
                                        handleToggleActivate,
                                        disabled,
                                        isSelected,
                                        onEditClick
                                    }) => {
    return (
        <>
            <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                <td className={`text-center`}>
                    <CheckBoxField
                        checked={isSelected}
                        onChange={handleToggleActivate}
                        label={`Activate ${bpie.schoolName}'s SBPIE Assessment`}
                        name={`cbActivate_${bpie.msidNumber}`}
                        showLabel={false}
                        value={bpie.msidNumber}
                        disabled={disabled}
                    />
                </td>
            </Allow>
            <td onClick={(event) => handleToggleActivate(event, bpie.msidNumber, !isSelected)}
                className={`is-clickable`}>
                {bpie.schoolName} ({bpie.msidNumber})
            </td>
            <td>{bpie.previousBpieDatesCovered}</td>
            <td>{bpie.schoolAdminFirstName} {bpie.schoolAdminLastName}</td>
            <td>{bpie.schoolAdminEmail}</td>
            <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR}>
                <td className={"actions"}>
                    <Button name={"edit"}
                            label={"Edit"}
                            showLabel={false}
                            buttonType={ButtonTypes.EDIT}
                            onClick={(event) => onEditClick(event, bpie.schoolBpieId, false)}
                            disabled={disabled}
                    />
                    <Button name={"replace"}
                            label={"Replace"}
                            showLabel={false}
                            buttonType={ButtonTypes.REPLACE}
                            onClick={(event) => onEditClick(event, bpie.schoolBpieId, true)}
                            disabled={disabled}
                    />
                </td>
            </Allow>
        </>
    );
}

BpieDistrictSummaryRowView.propTypes = {
    bpie: PropTypes.object.isRequired,
    handleToggleActivate: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired
};

export default BpieDistrictSummaryRowView;