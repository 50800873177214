import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import * as ApiUtility from "./ApiUtility";
import * as config from "../constants/config";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

class ServerSppAdminApi{
	static CopySppDocument(sourceDocumentId){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}Spp/documents/${sourceDocumentId}/copy`,
			RequestTypes.POST,
			JSON.stringify({ sourceDocumentId }),
			ApiSuccessMessages.SPP_ADMIN_DOCUMENT_COPIED));
	}

	static CopySppResponses(destinationDocumentId){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}Spp/documents/${destinationDocumentId}/copy-approved-policy-compliances`,
			RequestTypes.POST,
			JSON.stringify({ destinationDocumentId }),
			ApiSuccessMessages.SPP_ADMIN_RESPONSES_COPIED));
	}

	static ToggleSppDocumentStatus(documentId){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}Spp/documents/${documentId}/toggle-status`,
			RequestTypes.POST,
			JSON.stringify({ documentId }),
			ApiSuccessMessages.SPP_ADMIN_TOGGLE_STATUS));
	}

    static saveSppDocumentElement(element){
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'Spp/documents/' + element.documentId + "/elements/" + element.id,
			RequestTypes.PUT,
			JSON.stringify(element),
			ApiSuccessMessages.spp_api.element.SAVED,
			'application/json'));
    }

	static deleteSppDocumentElements(documentId, deletedElementIds){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'Spp/documents/' + documentId + "/elements/",
			RequestTypes.DELETE,
			JSON.stringify(deletedElementIds),
			ApiSuccessMessages.spp_api.element.DELETED,
			'application/json'));
	}

	static addSppDocumentElement(createElementViewModel){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'Spp/documents/' + createElementViewModel.elementViewModel.documentId + "/elements/",
			RequestTypes.POST,
			JSON.stringify(createElementViewModel),
			ApiSuccessMessages.spp_api.element.ADDED,
			'application/json'));
	}

	static saveElementOrder(documentId, movedElementViewModel){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'Spp/documents/' + documentId + "/elements/move",
			RequestTypes.POST,
			JSON.stringify(movedElementViewModel),
			ApiSuccessMessages.spp_api.element.ORDERED,
			'application/json'));
	}
}

class MockSppAdminApi{
    static saveSppDocumentElement(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const SppAdminApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSppAdminApi : MockSppAdminApi;
export default SppAdminApi;