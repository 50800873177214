import React from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";

const DrStateComplaintActionDeadlineExtensions = ({
                                                      deadlineExtensions
                                         }) => {

    return (
            <>
            {
                !isArrayNullOrEmpty(deadlineExtensions) &&
                <ul>
                {
                    deadlineExtensions.map((extension, key) =>
                        <li key={key}>
                            {outputStandardDateFormat(extension.extendedDateDue, false)}
                            <br/>
                            <strong>Reason: </strong> {extension.reasonForExtension}
                        </li>
                    )
                }
                </ul>
            }
            {
                isArrayNullOrEmpty(deadlineExtensions) &&
                <>None</>
            }
        </>
    );
};

DrStateComplaintActionDeadlineExtensions.propTypes = {
    deadlineExtensions: PropTypes.array.isRequired
};

export default DrStateComplaintActionDeadlineExtensions;