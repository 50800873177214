import * as config from "../constants/config";
import * as indicatorType from "../constants/indicatorTypes";
import * as teamMemberRoles from "../constants/strategicPlan/teamMemberRoles";
import * as policyEvents from "../constants/policyEvents";
import {allow} from "../components/authorization/AuthorizationUtilities";
import {StrategicPlanPolicy} from "../components/authorization/policies/StrategicPlanPolicy";
import * as ApiUtility from "./ApiUtility";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

const stratPlanBaseUrl = 'strategic-plans/';
const adminBaseUrl = 'manage/' + stratPlanBaseUrl;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerStrategicPlanApi {
	static getPlans() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl,
			RequestTypes.GET));
	}

	static getFiveYearReport(dateWindowId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl + "reports/5-year-report/" + dateWindowId,
			RequestTypes.GET));
	}

	static getPlanDateWindow(planDateWindowId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl + planDateWindowId,
			RequestTypes.GET));
	}

	static getPlanStrategies(planDateWindowId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl + planDateWindowId + "/strategies",
			RequestTypes.GET,
			planDateWindowId));
	}

    static addPlan(newPlan){
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl,
			RequestTypes.POST,
			JSON.stringify(newPlan),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_PLAN));
    }

    static updatePlanDateWindow(planDateWindow){
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl,
			RequestTypes.PUT,
			JSON.stringify(planDateWindow),
			ApiSuccessMessages.STRATEGIC_PLAN_UPDATE_PLAN));
    }

	static addStrategy(planDateWindowId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl + "strategies",
			RequestTypes.POST,
			JSON.stringify({planDateWindowId}),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_STRATEGY));
	}

	static deleteStrategy(strategyId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}strategies/${strategyId}`,
			RequestTypes.DELETE,
			{ strategyId },
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_STRATEGY,
			null,
			`application/x-www-form-urlencoded`));
	}

	static saveIndicator(strategyIndicator) {
		const url = allow(StrategicPlanPolicy, policyEvents.ADD_INDICATOR) ?
                `${config.apiUrl}${stratPlanBaseUrl}indicators` :
				`${config.apiUrl}${stratPlanBaseUrl}indicators/metrics`;

		return ApiUtility.sendRequest(AjaxRequestOptions(
			url,
			RequestTypes.PUT,
			JSON.stringify(strategyIndicator),
			ApiSuccessMessages.STRATEGIC_PLAN_SAVE_INDICATOR));
	}

	static addAction(action) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}actions`,
			RequestTypes.POST,
			JSON.stringify(action),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_ACTION));
	}

	static saveAction(action) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}actions`,
			RequestTypes.PUT,
			JSON.stringify(action),
			ApiSuccessMessages.STRATEGIC_PLAN_SAVE_ACTION));
	}

	static getActionResources() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}actions/resources`,
			RequestTypes.GET));
	}

	static getGoals() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}goals`,
			RequestTypes.GET));
	}

	static saveStrategyGoals(strategyId, goals) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}strategy/${strategyId}/goals`,
			RequestTypes.PUT,
			JSON.stringify(goals),
			ApiSuccessMessages.STRATEGIC_PLAN_SAVE_GOALS));
	}

	static addSubAction(actionId, subAction) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}actions/${actionId}/subactions`,
			RequestTypes.POST,
			JSON.stringify(subAction),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_SUBACTION));
	}

	static saveTeamMembers(planDateWindowId, teamMembers) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + stratPlanBaseUrl + planDateWindowId + "/team-members",
			RequestTypes.PUT,
			JSON.stringify(teamMembers),
			ApiSuccessMessages.STRATEGIC_PLAN_SAVE_TEAM_MEMBERS));
	}

	static updateSubAction(actionId, subAction) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}actions/${actionId}/subactions`,
			RequestTypes.PUT,
			JSON.stringify(subAction),
			ApiSuccessMessages.STRATEGIC_PLAN_UPDATE_SUBACTION));
	}

	static deleteSubAction(strategyId, actionId, subActionId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}strategies/${strategyId}/actions/${actionId}/subactions/${subActionId}`,
			RequestTypes.DELETE,
			null,
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_SUBACTION));
	}

	static deleteAction(strategyId, actionId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}strategies/${strategyId}/actions/${actionId}`,
			RequestTypes.DELETE,
			null,
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_ACTION));
	}

	static deleteIndicator(strategyId, strategyIndicatorId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}${stratPlanBaseUrl}strategies/${strategyId}/indicators/${strategyIndicatorId}`,
			RequestTypes.DELETE,
			null,
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_INDICATOR));
	}

    static copyPlanDateWindow(planDateWindowId, newDateWindowId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + planDateWindowId + "/strategies/" + newDateWindowId,
			RequestTypes.POST,
			JSON.stringify({
				planDateWindowId,
				newDateWindowId
			}),
			ApiSuccessMessages.STRATEGIC_PLAN_COPY_PLAN));
	}

    static addResource(resource) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "actions/resources",
			RequestTypes.POST,
			JSON.stringify(resource),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_RESOURCE));
    }

    static updateResource(resource) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "actions/resources",
			RequestTypes.PUT,
			JSON.stringify(resource),
			ApiSuccessMessages.STRATEGIC_PLAN_UPDATE_RESOURCE));
    }

    static deleteResource(resource) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "actions/resources",
			RequestTypes.DELETE,
			JSON.stringify(resource),
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_RESOURCE));
    }

    static addGoal(goal) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "goals",
			RequestTypes.POST,
			JSON.stringify(goal),
			ApiSuccessMessages.STRATEGIC_PLAN_ADD_GOAL));
    }

    static updateGoal(goal) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "goals",
			RequestTypes.PUT,
			JSON.stringify(goal),
			ApiSuccessMessages.STRATEGIC_PLAN_UPDATE_GOAL));
    }

    static deleteGoal(goal) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + "goals",
			RequestTypes.DELETE,
			JSON.stringify(goal),
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_GOAL));
    }

    static deletePlanDateWindow(planDateWindowId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + adminBaseUrl + planDateWindowId,
			RequestTypes.DELETE,
			JSON.stringify(planDateWindowId),
			ApiSuccessMessages.STRATEGIC_PLAN_DELETE_PLAN));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

const plans = [
	{
		planDateWindowId: "1",
		planId: "p1",
		dateWindowId: "dffa3ab7-7f95-40f4-89a2-3a2167ad41d9",
		name: "Plan to Train Your Cat",
		status: "Open",
		teamMembers: [
			{
				key: "11",
				text: "Garfield",
				role: teamMemberRoles.BEESS_POINT.value
			},
			{
				key: "12",
				text: "Nala",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "13",
				text: "Sylvester",
				role: teamMemberRoles.TEAM_LEAD.value
			},
			{
				key: "14",
				text: "Simba",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "15",
				text: "Trumpy Cat",
				role: teamMemberRoles.CONSULTANT.value
			},
			{
				key: "16",
				text: "Tigger",
				role: teamMemberRoles.CONSULTANT.value
			},
			{
				key: "17",
				text: "Puss in Boots",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "18",
				text: "Cheshire",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
		]
	},
	{
		planDateWindowId: "2",
		planId: "p2",
		dateWindowId: "9c644736-eea5-43ca-1088-08d466512670",
		name: "The Fresh Plan of Belair",
		status: "Open",
		teamMembers: []
	},
	{
		planDateWindowId: "3",
		planId: "p3",
		dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
		name: "Plan to Prove There's a Such Thing as a 5-Year Plan",
		status: "Open",
		teamMembers: [
			{
				key: "11",
				text: "Clezlie Line",
				role: teamMemberRoles.BEESS_POINT.value
			},
			{
				key: "12",
				text: "Dandrew Enard",
				role: teamMemberRoles.TEAM_LEAD.value
			},
			{
				key: "13",
				text: "Wudy Jhite",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "14",
				text: "Beff Judnick",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "15",
				text: "Devin Keak",
				role: teamMemberRoles.TEAM_MEMBER.value
			},
			{
				key: "16",
				text: "Wichael Mong",
				role: teamMemberRoles.TEAM_MEMBER.value
			}
		]
	}
];

const strategies = [
	{
		id: "strat1",
		planDateWindowId: "1",
		goals: [
			{
				text: "Highest Kitty Achievement"
			},
			{
				text: "Efficient Kitty Outcomes"
			}
		],
		strategyIndicators: [
			{
				id: "ind1",
				triangleFileId: "",
				baseline: {
					key: "bl1",
					baseline: "50%",
					target: "",
					actual: "",
					startYear: "2013"
				},
				indicators: [
					{
						strategyIndicatorId: "ind1",
						indicatorType: indicatorType.BEESS,
						text: "The cats will respond to their name, sit, lay down, and shake commands. This indicates the highest level of kitty achievement as as highlighted by the Good Kitty Authority (GKA)"
					}
				],
				metrics: [
					{
						key: "bl2",
						baseline: "",
						target: "55%",
						actual: "57%",
						startYear: "2014"
					},
					{
						key: "bl3",
						baseline: "",
						target: "60%",
						actual: "61%",
						startYear: "2015"
					},
					{
						key: "bl4",
						baseline: "",
						target: "65%",
						actual: "64%",
						startYear: "2016"
					},
					{
						key: "bl5",
						baseline: "",
						target: "65%",
						actual: "",
						startYear: "2017"
					},
					{
						key: "bl6",
						baseline: "",
						target: "70%",
						actual: "",
						startYear: "2018"
					}
				]
			}
		],
		strategyActions: [
			{
				id: "act1",
				order: 1,
				progressMonitoringNotes: "Every week, we will meet to discuss kitty progress",
				responsibleParty: "Kitty Owner",
				text: "Once per day, kitty owners will call their cats and perform a set of pavlovian style training tasks to encourage the kitty to follow the sit command.",
				strategyId: "strat1",
				resources: [],
				subActions: [
					{
						id: "sub1",
						order: 1,
						responsibleParty: "Devin Keak",
						text: "Once per day, call kitty's name. If she comes, give her 4 kitty treats",
						progressMonitoringNoteEntries: [
							{
								key: "111",
								dateNoteCreated: "07/01/2017",
								text: "Called kitty. She didn't come. Called kitty over and over until she came for a reason unbeknown to me, and I gave her a treat."
							},
							{
								key: "112",
								dateNoteCreated: "07/05/2017",
								text: "Called kitty. She didn't come. Called kitty again and she came. Gave her a treat."
							},
							{
								key: "113",
								dateNoteCreated: "07/10/2017",
								text: "Called kitty. She came, but was hesitant to approach until she saw the treat. Gave her the treat."
							},
							{
								key: "114",
								dateNoteCreated: "07/15/2017",
								text: "Called kitty. She came immediately. I am successfully training my cat. What am I doing with my life?"
							}
						],
					},
					{
						id: "sub2",
						order: 2,
						responsibleParty: "Devin Keak",
						text: "Once per day, look at kitty and say 'sit'. If she sits, give her 4 kitty treats",
						progressMonitoringNoteEntries: [
							{
								key: "115",
								dateNoteCreated: "08/01/2017",
								text: "Told kitty to sit. She didn't sit. I pushed her butt down, said sit, and gave her a treat."
							},
							{
								key: "116",
								dateNoteCreated: "08/05/2017",
								text: "Told kitty to sit. She hesitated and then didn't sit. I pushed her butt down, said sit, and gave her a treat."
							},
							{
								key: "117",
								dateNoteCreated: "08/10/2017",
								text: "Told kitty to sit. She looked like she was really contemplating sitting, but then didn't sit. I pushed her butt down, said sit, and gave her a treat."
							},
							{
								key: "118",
								dateNoteCreated: "08/15/2017",
								text: "Told kitty to sit. She lowered her butt a little, but didn't totally sit. I pushed her butt the rest of the way down, said sit, and gave her a treat."
							},
							{
								key: "119",
								dateNoteCreated: "08/20/2017",
								text: "Told kitty to sit. She sat! I couldn't believe my eyes. I told my cat to sit and she sat! Gave her a treat."
							}

						],
					},
					{
						id: "sub3",
						order: 3,
						responsibleParty: "Devin Keak",
						text: "Once kitty has mastered sit, look at kitty and say 'lay down'. If she lays, give her 4 kitty treats",
						progressMonitoringNoteEntries: [
							{
								key: "120",
								dateNoteCreated: "09/01/2017",
								text: "Told kitty to lay down. She just looked at me. I gently laid her down while saying lay down. While she was laying, I gave her treats."
							},
							{
								key: "121",
								dateNoteCreated: "09/05/2017",
								text: "Told kitty to lay down. She looked at me with slightly less uncertainty than last time, but still didn't lay down. I gently laid her down while saying lay down. While she was laying, I gave her treats"
							},
							{
								key: "122",
								dateNoteCreated: "09/10/2017",
								text: "Told kitty to lay down. In a fit of miracle genius, she laid down and asked, 'Am I doing this right?' I gave her 4 treats and a bachelor's of art in english"
							}
						],
					}
				]
			},
			{
				id: "act2",
				order: 2,
				progressMonitoringNotes: "Discourage Bad Kitty Behavior",
				responsibleParty: "Kitty Owner",
				text: "Provide disincentives for bad behavior with sharp noises and intense eye contact when the kitty is misbehaving",
				strategyId: "1",
				resources: [],
				subActions: [
					{
						id: "su",
						order: 1,
						responsibleParty: "Wichael Mong",
						text: "When you see kitty on the counters, yell 'HEY' to startle kitty and get her to jump off.",
						progressMonitoringNoteEntries: [
							{
								key: "123",
								dateNoteCreated: "10/01/2018",
								text: "Saw kitty jump on the counter. I yelled, 'HEY', made eye contact, and she jumped down"
							},
							{
								key: "124",
								dateNoteCreated: "10/10/2018",
								text: "Saw kitty jump on the counter. I yelled, 'HEY', made eye contact, and she jumped down"
							}
						],
					},
					{
						id: "sub5",
						order: 2,
						responsibleParty: "Wichael Mong",
						text: "When you see kitty scratching on furniture, loudly stomp towards her to scare her away from the furniture",
						progressMonitoringNoteEntries: [
							{
								key: "125",
								dateNoteCreated: "10/31/2018",
								text: "Heard kitty scratching furniture while I was getting ready for my yearly Spooky Scary Halloween Party. While wearing my werewolf costume, I rapidly approached kitty and she stopped scratching and ran away immediately. She never scratched again after that for some reason.."
							}
						],
					}
				]
			},
			{
				id: "act3",
				order: 3,
				progressMonitoringNotes: "Once per month we will Skype to observe kitty features",
				responsibleParty: "Kitty Owner",
				text: "Increase kitty coat health, kitty disposition, and quality of life in general through various activities",
				strategyId: "strat1",
				resources: [],
				subActions: [
					{
						id: "sub6",
						order: 1,
						responsibleParty: "Beff Judnick",
						text: "Frequently brush kitty with a slicker brush",
						progressMonitoringNoteEntries: [
							{
								key: "126",
								dateNoteCreated: "12/01/2018",
								text: "Brushed kitty for 20 minutes"
							},
							{
								key: "127",
								dateNoteCreated: "12/10/2018",
								text: "Brushed kitty until she purred herself to sleep"
							}
						],
					},
					{
						id: "sub7",
						order: 2,
						responsibleParty: "Beff Judnick",
						text: "Occasionally give kitty eggs to improve coat health",
						progressMonitoringNoteEntries: [
							{
								key: "128",
								dateNoteCreated: "01/10/2019",
								text: "Made kitty's favorite scrambled eggs with cat nip garnish. She ate it all and then rolled in the empty bowl."
							}
						],
					}
				]
			}
		]
	},
	{
		id: "strat2",
		planDateWindowId: "3",
		goals: [
			{
				text: "Prove the Impossible"
			},
		],
		strategyIndicators: [
			{
				id: "inc2",
				triangleFileId: "",
				baseline: {
					key: "bl7",
					baseline: "0/10 Professionals Agree",
					target: "",
					actual: "",
					startYear: "2013"
				},
				indicators: [
					{
						strategyIndicatorId: "inc2",
						indicatorType: indicatorType.SPP,
						text: "1337"
					}
				],
				metrics: [
					{
						key: "bl8",
						baseline: "",
						target: "10/10 Professional Agree",
						actual: "0/10 Professionals Agree",
						startYear: "2014"
					},
					{
						key: "bl9",
						baseline: "",
						target: "10/10 Professionals Agree",
						actual: "0/10 Professionals Agree",
						startYear: "2015"
					},
					{
						key: "bl10",
						baseline: "",
						target: "10/10 Professionals Agree",
						actual: "0/10 Professionals Agree",
						startYear: "2016"
					},
					{
						key: "bl11",
						baseline: "",
						target: "10/10 Professionals Agree",
						actual: "0/10 Professionals Agree",
						startYear: "2017"
					},
					{
						key: "bl12",
						baseline: "",
						target: "10/10 Professionals Agree",
						actual: "0/10 Professionals Agree",
						startYear: "2018"
					}
				]
			}
		],
		strategyActions: [
			{
				id: "act4",
				strategyId: "strat2",
				order: 1,
				progressMonitoringNotes: "Every two weeks, we will meet to discuss progress",
				responsibleParty: "DOE Product Owner",
				resources: [],
				text: "During every meeting, or whenever possible, continue to refer to the 5 year plan as if it is not a myth, and it is essential to the operation of this part of this system.",
				subActions: [
					{
						id: "sub8",
						order: 1,
						responsibleParty: "Dandrew Aenard",
						text: "Schedule meetings in which you will have the opportunity to use the term '5-year plan'",
						progressMonitoringNoteEntries: [
							{
								key: "129",
								dateNoteCreated: "07/01/2017",
								text: "Convened a meeting to discuss the progress of the Strategic Plan component thus far, and used the term '5-Year-Plan' several times as if it was a thing that existed"
							},
							{
								key: "130",
								dateNoteCreated: "08/01/2017",
								text: "Sat in on a meeting with the partner and the developers wherein they discussed a totally unrelated component of the system. Managed to sneak in a question about whether they have 'any kind of 5-year-plan in this component like in the strategy plan component.' Haha. Got 'em good. Suckers."
							}
						],
					},
					{
						id: "sub9",
						order: 2,
						responsibleParty: "Dandrew Aenard",
						text: "Frequently call up Vicki to see how she's doing and find a way to casually drop in the term 5-year plan into the conversation",
						progressMonitoringNoteEntries: [
							{
								key: "131",
								dateNoteCreated: "09/01/2017",
								text: "Called Vicki to see if she had any availability to meet next week with the GSW team. Asked how this year's 5-year-plan is going. Honestly, at the rate at which I'm using this term, I don't think there's a way people can believe there's no such thing as a 5-year-plan at this point."
							},
							{
								key: "132",
								dateNoteCreated: "08/01/2017",
								text: "Randomly ran into Vicki at Publix. Mentioned that I have a 5-year-plan, not unlike the ones GSW has in its strategic plan component, to change my diet to contain only foods which people always thought didn't exist, but actually do exist."
							}
						],
					}
				]
			},
			{
				id: "act5",
				strategyId: "strat2",
				order: 2,
				resources: [],
				progressMonitoringNotes: "Every week we will review progress via email thread",
				responsibleParty: "FCIM Project Manager",
				text: "During every meeting, or whenever possible, continue to insist that we should not talk about the non-existence of the 5-year plan with the partner.",
				subActions: [
					{
						id: "sub10",
						order: 1,
						responsibleParty: "Cezlie Lline",
						text: "Attend all stand up meetings and continually insist that the 5-year plan is not only real, but that it is the most important thing in the GSW, period.",
						progressMonitoringNoteEntries: [
							{
								key: "133",
								dateNoteCreated: "11/01/2017",
								text: "Attended every stand up meeting last month and, even though the length and types of plans was barely ever mentioned, I made sure to mention the 5-year-plan and its importance every. single. day."
							},
							{
								key: "134",
								dateNoteCreated: "12/01/2017",
								text: "Saw a particularly juicy opportunity to interrupt one of the develops today as they were talking about the plan. I cut him off and pointedly asked, 'Which type of plan do you mean; 5-year or 1-year?', therefore clearly implying that there is such a difference. Nailed it!"
							}
						],
					},
					{
						id: "sub11",
						order: 2,
						responsibleParty: "Cezlie Lline",
						text: "Frequently schedule internal meetings with developers to instruct them about how to speak and what to speak when in meetings with the partner",
						progressMonitoringNoteEntries: [
							{
								key: "135",
								dateNoteCreated: "01/01/2018",
								text: "Held a meeting during which I instructed the developers to not use certain words while in communication with the partner. The words include: vulnerable, entitlement, diversity, evidence-based, science-based, and the phrase 'there's no such thing as a 5-year-plan'. I'm hoping that without those words and phrases the partner won't realize that there's not such... I mean... Their minds won't be poisoned by any crazy ideas about the non-existence of the 5-year-plan"
							}
						]
					}
				]
			},
			{
				id: "act6",
				strategyId: "strat2",
				order: 3,
				progressMonitoringNotes: "We will have a stand up meeting every day",
				responsibleParty: "Development Team Member",
				resources: [],
				text: "Sigh a lot and try your hardest not to remind everyone with whom you communicate that there is, in fact, no such thing as a 5 year plan. Consider communicating it in other, subtler ways.",
				subActions: [
					{
						id: "sub12",
						order: 1,
						responsibleParty: "Beff Judnick",
						text: "Periodically and spontaneously stand up during meetings and loudly proclaim 'DOWN WITH THE 5-YEAR PLAN!'",
						progressMonitoringNoteEntries: [
							{
								key: "136",
								dateNoteCreated: "02/01/2017",
								text: "I was at a meeting with my realtor discussing the best area in which to look for houses which would be good for 203k loans. To help answer that question, he asked what I thought I wanted to be doing with the property in 5 years. I told him rather loudly that 'THERE'S NO SUCH THING AS A 5-YEAR PLAN'"
							},
							{
								key: "137",
								dateNoteCreated: "03/01/2017",
								text: "During a recent job interview, I was asked 'Where do you see yourself in 5 years?' I responded by passionately pointing out that 'THE 5-YEAR PLAN IS A LIE.'"
							}
						]
					},
					{
						id: "sub13",
						order: 2,
						text: "Write passive, yet poignant messages on your white board - the only space over which you have any control - letting any visitors and passersby know about the truth behind the 5 year plan",
						responsibleParty: "Beff Judnick",
						progressMonitoringNoteEntries: [
							{
								key: "138",
								dateNoteCreated: "04/1/2017",
								text: "I have finally conceded. I wrote on my white board that I remembered that there was a such thing as a 5-year plan. APRIL FOOLS! HA! Got ya!"
							},
							{
								key: "139",
								dateNoteCreated: "04/15/2017",
								text: "Wrote and stylized the text 'THE 5-YEAR PLAN IS A MYTH' across the top of the white board"
							},
							{
								key: "140",
								dateNoteCreated: "05/01/2017",
								text: "Wrote and stylized the text 'THE 5-YEAR PLAN IS A MYTH' down the right side of the white board"
							},
							{
								key: "141",
								dateNoteCreated: "05/01/2017",
								text: "Illustrated a short comic strip across the bottom of the white board. It tells the story of a man who approaches a house and knocks on its door. When the inhabitant answers the door the man who knocked asks, 'Do you you have a minute to hear about the myth of the 5-year plan?' In appropriate fashion, the inhabitant slams the door in the crazy, unreasonable trespasser's face, at which point the the crazy, unreasonable trespasser breaks the 4th wall of the comic by looking directly at the reader and frowning. Check it our in this week's Sunday paper."
							}
						],
					},
					{
						id: "sub14",
						order: 3,
						responsibleParty: "Beff Judnick",
						text: "Write hilarious mock data that is topical to the underlying subtext of this sub-action's parent.",
						progressMonitoringNoteEntries: [
							{
								key: "142",
								dateNoteCreated: "Today",
								text: "This mock data Is So Meta. Even This Acronym"
							}
						],
					}
				]
			}
		]
	},
	{
		id: "strat3",
		planDateWindowId: "2",
		goals: [],
		strategyIndicators: [],
		strategyActions: []
	}
];

const resources = [
	{text: "Kitty Rearing 101: The Book"},
	{text: "Kitty Rearing 101: The Movie"},
	{text: "Kitty Whisperer"},
	{text: "Kitty Whisperer 2: Careless Whispers"},
	{text: "Kitty Whisperer 3: Kitty's Mummy Returns"},
	{text: "Ignoring Reality Handbook"},
	{text: "Information Hoarders in The Workplace: A Comprehensive Guide"},
	{text: "Course XYZ: Effective Personnel Management"},
	{text: "Course ABC: Team Building"},
	{text: "Positive Effects of Transparency Within Team Projects"}
];

const goals = [
	{text: "Highest Kitty Achievement"},
	{text: "Make 'Em Believe"},
	{text: "Especially Good Outcomes"},
	{text: "Boldly Go Where No Man Has Gone Before"}
];

class MockStrategicPlanApi {
	static getPlans() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve([...plans]);
			}, config.environment.delay);
		});
	}

	static saveIndicator() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

    static getPlanStrategies(planDateWindowId){
        return new Promise(resolve => {
            const data = strategies.filter(strat => strat.planDateWindowId === planDateWindowId);

			setTimeout(() => {
				resolve([...data]);
			}, config.environment.delay);
		});
	}

	static addPlan() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static addStrategy() {
		const newId = Math.random().toString();

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static deleteStrategy() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static addAction() {
		const newId = Math.random().toString();

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static getActionResources() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(resources);
			}, config.environment.delay);
		});
	}

	static getGoals() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(goals);
			}, config.environment.delay);
		});
	}

	static saveStrategyGoals() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static addSubAction() {
		const newId = Math.random().toString();

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static updateSubAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteSubAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getPlanDateWindow(planDateWindowId) {
		const selectedPlan = plans.filter(p => p.planDateWindowId === planDateWindowId)[0];

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(selectedPlan);
			}, config.environment.delay);
		});
	}

	static saveTeamMembers() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteIndicator() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

    static copyPlanDateWindow() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static addResource() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static updateResource() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteResource() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static addGoal() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static updateGoal() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteGoal() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deletePlanDateWindow() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static updatePlanDateWindow() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const StrategicPlanApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerStrategicPlanApi : MockStrategicPlanApi;
export default StrategicPlanApi;