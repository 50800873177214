import {isTrimmedStringEmpty} from "../components/common/commonUtilities";
import {dr_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import * as config from "../constants/config";
import * as ApiUtility from "../api/ApiUtility";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../api/requests/RequestTypes';

const disputeApi = 'dispute-resolution';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrApi {
    static getTeamMembers(activeOnly = false) {
        const activeOnlyRoute = activeOnly ? "/active" : "/all";
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/teamMembers${activeOnlyRoute}`,
                RequestTypes.GET));
    }

    static getSummaryTeamMembers(dateWindowId, type) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/admin/teamMembers/Dates/${dateWindowId}/Type/${type}`,
                RequestTypes.GET));
    }

    static GetComplaintNumbers(dateWindowId, districtId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/complaintNumbers/date/${dateWindowId}/institutions/${districtId}`,
                RequestTypes.GET));
    }

    static GetDueProcessCaseNumbers(dateWindowId, districtId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/dueProcessCaseNumbers/date/${dateWindowId}/institutions/${districtId}`,
                RequestTypes.GET));
    }
    static getCaseListData(dateWindowId, type) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/admin/Dates/${dateWindowId}/Type/${type}`,
                RequestTypes.GET));
    }

    static getDrSummaryData(dateWindowId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/admin/Dates/${dateWindowId}/Summary`,
                RequestTypes.GET));
    }

    static saveDrSummaryPins(id, type) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/admin/Records/${id}/Types/${type}`,
                RequestTypes.PUT));
    }

    static getDrUserNotifications(id, type) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/records/${id}/types/${type}/reminders`,
                RequestTypes.GET));
    }

    static saveDrReminder(id, type, reminderId, remindDate, reminderNotes) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/records/${id}/types/${type}/reminders/${reminderId}`,
                RequestTypes.PUT,
                JSON.stringify({remindDate: remindDate, reminderNotes: reminderNotes}),
                ApiSuccessMessages.reminders.SAVED
            )
        );
    }

    static removeDrReminder(id, type, reminderId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/records/${id}/types/${type}/reminders/${reminderId}`,
                RequestTypes.DELETE,
                null,
                ApiSuccessMessages.reminders.REMOVED
            )
        );
    }

    static getCaseloadData(dateWindowId, userId = "") {
        const userUrl = isTrimmedStringEmpty(userId) ? "" : `Users/${userId}/`;
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/admin/Dates/${dateWindowId}/${userUrl}Caseload`,
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrApi {
    static getTeamMembers() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getSummaryTeamMembers() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetComplaintNumbers() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetDueProcessCaseNumbers() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getCaseListData() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getDrSummaryData() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveDrSummaryPins() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getDrUserNotifications(id, type) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveDrReminder() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static removeDrReminder() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getCaseloadData(dateWindowId) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const DrApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrApi : MockDrApi;
export default DrApi;