import PropTypes from "prop-types";
import React from "react";
import VideoAdapter from "./VideoAdapter";

const VimeoDisplay = ({
    videoId,
}) => {

    return <VideoAdapter
        name={videoId}
        url={`https://player.vimeo.com/video/${videoId}`}
    />;
};

VimeoDisplay.propTypes = {
    videoId: PropTypes.string.isRequired,
};

export default React.memo(VimeoDisplay);