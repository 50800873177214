import {convertToNumber} from "../../../../components/common/commonUtilities";
import {APPLICATION_ACCESS_STATUS} from "../../gmsConstants";

export const createGmsSettingsObject = ({
                                            applicationAccessStatus = APPLICATION_ACCESS_STATUS.notVisible.id,
                                            dateWindowId,
                                        } = {}) => ({
    applicationAccessStatus,
    dateWindowId,
});

export const updateMainSectionsObject = (sections, form) => {
    let result = [];

    sections.forEach((section) => {
        const elementId = section.elementId;
        const elementCopyBehavior = convertToNumber(form[elementId]);
        if (section.elementCopyBehavior !== elementCopyBehavior) {
            const updatedSection = {
                elementId,
                elementCopyBehavior,
            }
            result.push(updatedSection);
        }
    });

    return result;
};

export const createApplicationSectionObject = (sections) => {
    let result = {};
    sections.forEach((section) => result[section.elementId] = section.elementCopyBehavior);
    return result;
};