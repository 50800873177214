import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import bpieAdminApi from "../bpieAdminApi";
import SchoolBpieCreateView from "./SchoolBpieCreateView";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {createBpie} from "../../sbpieCreateFactory";

const SBpieCreateContainer = ({
                                  actions
                              }) => {

    const [bpie, setBpie] = useState(null);
    const [showButtonBar, setShowButtonBar] = useState(false);

    useEffect(() => {
        loadNewBpieInformation();
        actions.updatePageTitle("Create School BPIEs");
    }, []);

    const loadNewBpieInformation = async () => {
        await actions.executeApi(bpieAdminApi.getNewBpieInformation, [])
            .then(setBpieUpdateValues);
    }

    const setBpieUpdateValues = (schoolBpieUpdateResult) => {
        setBpie(createBpie(schoolBpieUpdateResult));
        setShowButtonBar(schoolBpieUpdateResult.numberOfBpiesToUpdate > 0 || schoolBpieUpdateResult.numberOfBpies > 0);
    }

    const onSaveClick = async (event) => {
        setShowButtonBar(false);
        event.preventDefault();
        await actions.executeApi(bpieAdminApi.createSchoolBpies, [bpie.schoolBpieUpdates])
            .then(setBpieUpdateValues);
    }

    if (bpie === null)
        return null;

    let saveBtnLabel = "Create SBPIES";
    if (bpie.numberOfBpieSchoolsToUpdate > 0)
        saveBtnLabel = "Update School SBPIE School Information";
    if (bpie.numberOfBpieSchoolsToUpdate > 0  && bpie.numberOfSchoolsBpiesToCreate > 0)
        saveBtnLabel = "Create SBPIES and Update School SBPIE School Information";

    return (
        <>
            <div>
                <SchoolBpieCreateView bpie={bpie}/>

                <FlexSpacer/>
                {showButtonBar &&
                    <ButtonBar>
                        <Button
                            buttonType={ButtonTypes.SAVE}
                            label={saveBtnLabel}
                            name="btnSave"
                            onClick={onSaveClick}
                        />
                    </ButtonBar>
                }
            </div>
        </>
    );
}

SBpieCreateContainer.propTypes = {
    actions: PropTypes.object.isRequired,
};

export default SBpieCreateContainer;