import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {DrComplaintTimelineRequirementResponseOptionsEnum} from "../../drConstants";
import {getTeamMemberId} from "../../drUtilities";
import {
    StateComplaintSections,
} from "../drStateComplaintConstants";
import DrStateComplaintNextSteps from "../DrStateComplaintNextSteps";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";
import {isAbleToEditTimelineCompletionDate} from "../StateComplaintsUtilities";
import DrStateComplaintRoutingRequirements from "./DrStateComplaintRoutingRequirements";
import DrStateComplaintRoutingSummary from "./DrStateComplaintRoutingSummary";
import SectionHeader from "../../../components/common/SectionHeader";

let getRoutingInformationFeedbackValue = null;

const DrStateComplaintRoutingView = ({
                                         complaint,
                                         handleChangeCompletionDate,
                                         handleChangeRoutingFeedback,
                                         handleChangeRoutingRequirementResponse,
                                         handleDataChanged,
                                         handleSaveRouting,
                                         handleSelectSection,
                                         readOnly,
                                         timelineModel,
                                         timelineResponses,
                                         userDrTeamMemberRoles
                                     }) => {
    const routingForm = useFormInputObject(drObjectFactory.createTimelineObject(timelineModel || {}), () => handleDataChanged());

    const fetchFormData = () => {
        const result = convertFormInputObjectToObject(routingForm);
        result.timelineResponseModels = timelineResponses;
        return result;
    };

    const handleClickSave = (event, isApproved) => {
        event.preventDefault();
        const form = fetchFormData();
        form.isApproved = isApproved;

        let updatedResponse = form.timelineResponseModels.find(d => d.timelineResponseId === timelineModel.currentResponseIdForRouting);
        if (updatedResponse && getRoutingInformationFeedbackValue) {
            updatedResponse.routingInformationFeedback = getRoutingInformationFeedbackValue();
            updatedResponse.responderId = getTeamMemberId(userDrTeamMemberRoles, updatedResponse.roleResponsibleForCompletion, true, true);
        }

        handleSaveRouting(form);
    };

    const getRoutingInformationFeedback = (getFunc) => {
        getRoutingInformationFeedbackValue = getFunc;
    };

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.Routing, true);
    };

    const isSaveBtnDisabled = () => {
        const currentTimelineResponse = getCurrentTimelineResponse();
        if (!currentTimelineResponse) return false;

        const allApproved = !(currentTimelineResponse.routingResponseModels.find(f => f.response !== DrComplaintTimelineRequirementResponseOptionsEnum.Approved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber));
        const isEnabled = !allApproved || !!currentTimelineResponse.dateCompleted;
        return !isEnabled;
    }

    const saveBtnLabel = () => {
        const currentTimelineResponse = getCurrentTimelineResponse();
        if (!currentTimelineResponse) return "Save";

        const atLeastOneResponseNotApproved = currentTimelineResponse.routingResponseModels.find(f => f.response === DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber);
        const allApproved = !(currentTimelineResponse.routingResponseModels.find(f => f.response !== DrComplaintTimelineRequirementResponseOptionsEnum.Approved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber));
        const caseNoteHasData = caseNoteDataExists(currentTimelineResponse);
        const caseNoteLabel = caseNoteHasData ? " and Add Case Note" : "";
        return !atLeastOneResponseNotApproved
            ? allApproved ? `Approve${caseNoteLabel}` : "Save"
            : `Not Approved${caseNoteLabel}`;
    };

    const caseNoteDataExists = (currentTimelineResponse) => {
        let routingFeedback = "";
        if(getRoutingInformationFeedbackValue)
            routingFeedback = getRoutingInformationFeedbackValue();

        return currentTimelineResponse.endTime || currentTimelineResponse.startTime || routingFeedback;
    };

    const saveBtnType = () => {
        const currentTimelineResponse = getCurrentTimelineResponse();
        if (!currentTimelineResponse) return ButtonTypes.SAVE;

        const atLeastOneResponseNotApproved = currentTimelineResponse.routingResponseModels.find(f => f.response === DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber);
        return !atLeastOneResponseNotApproved ? ButtonTypes.SAVE : ButtonTypes.NEEDS_REVISIONS;
    }

    const saveButtonStatus = () => {
        const currentTimelineResponse = getCurrentTimelineResponse();
        if (!currentTimelineResponse) return null;

        const atLeastOneResponseNotApproved = currentTimelineResponse.routingResponseModels.find(f => f.response === DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber);
        const allApproved = !(currentTimelineResponse.routingResponseModels.find(f => f.response !== DrComplaintTimelineRequirementResponseOptionsEnum.Approved
            && f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber));
        return !atLeastOneResponseNotApproved
            ? allApproved ? true : null
            : false;
    }

    const getCurrentTimelineResponse = () => {
        return timelineModel.timelineResponseModels.find(f => f.timelineResponseId === timelineModel.currentResponseIdForRouting);
    }

    if (!timelineModel || !timelineModel.displayRoutingSection)
        return null;

    const currentTimelineResponse = getCurrentTimelineResponse();

    return (
        <section className={"timeline-routing"} id={`routing-view`}>
            {
                currentTimelineResponse &&
                !readOnly &&
                isAbleToEditTimelineCompletionDate(userDrTeamMemberRoles, currentTimelineResponse.roleResponsibleForCompletion) &&
                <DrStateComplaintRoutingRequirements
                    getRoutingInformationFeedback={getRoutingInformationFeedback}
                    handleChangeCompletionDate={handleChangeCompletionDate}
                    handleChangeRoutingFeedback={handleChangeRoutingFeedback}
                    handleChangeRoutingRequirementResponse={handleChangeRoutingRequirementResponse}
                    routingRequirementResponse={currentTimelineResponse.routingResponseModels.find(f => f.reviewNumber === currentTimelineResponse.currentRoutingRequirementReviewNumber)}
                    timelineResponse={currentTimelineResponse}
                />
            }

            <SectionHeader>
                <h2>Routing Process History</h2>
            </SectionHeader>

            <DrStateComplaintRoutingSummary routingSummaryDetails={complaint.routingSummaryDetails}
                                            timelineResponses={timelineModel.timelineResponseModels}/>

            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button name="btnSave"
                            label={saveBtnLabel()}
                            buttonType={saveBtnType()}
                            onClick={(event) => handleClickSave(event, saveButtonStatus())}
                            disabled={isSaveBtnDisabled()}
                    />
                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                    <DrStateComplaintNextSteps handleSelectSection={handleSelectSection} complaint={complaint}/>
                </ButtonBar>
            }
        </section>
    );
};

DrStateComplaintRoutingView.propTypes = {
    complaint: PropTypes.object,
    handleChangeCompletionDate: PropTypes.func.isRequired,
    handleChangeRoutingFeedback: PropTypes.func.isRequired,
    handleChangeRoutingRequirementResponse: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveRouting: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    timelineModel: PropTypes.object,
    timelineResponses: PropTypes.array.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintRoutingView;