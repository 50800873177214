import React from 'react';
import AbstractNumberField, {AbstractNumberFieldProps} from "./AbstractNumberField";

const NumberField = (props) => {

	return <AbstractNumberField
		{...props}
	/>;
};

NumberField.propTypes = AbstractNumberFieldProps;

export default NumberField;