import React from "react";
import PropTypes from 'prop-types';
import RadioField from "../../common/inputs/RadioField";
import {inputStyles} from '../../../constants/inputConstants';
import * as responseTypes from "../../../constants/monitoringCompliance/responseTypes";
import * as responseOptions from "../../../constants/monitoringCompliance/responseOptions";
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import * as AuthorizationUtilities from "../../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../../constants/policyEvents";

const ReassessSubQuestion = (
    {
        protocolToBeReassessed,
        protocolResponseIndex,
        comparedResponse,
        originalResponse,
        onSubQuestionResponseChange,
        responseTypeDisplayed
    }
    ) =>
{
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    return (
        <>
            <tr valign={"top"}>
                <td className={"response-no-wrap"}>{originalResponse.studentRecordName}</td>
                <td className={"response-no-wrap"}>{protocolToBeReassessed.publicIdentifier}</td>
                <td><HtmlDisplayBlock html={protocolToBeReassessed.standardDescription} /></td>
                <td className={"text-center"}><HtmlDisplayBlock html={originalResponse.responseText || originalResponse.response} /></td>
                <td className={`text-center ${responseTypeDisplayed === responseTypes.VALIDATION ? "highlight-discrepancy" : ""}`}><HtmlDisplayBlock html={(comparedResponse.response === responseOptions.NV) ? originalResponse.responseText || originalResponse.response : comparedResponse.responseText || comparedResponse.response } /></td>
            </tr>
            {
                protocolToBeReassessed.subQuestions.map((subQuestion, index) => {
                    let response = "";
                    let originalSubQuestionResponse = "";

                    if (comparedResponse.subQuestionResponses.length >= index + 1) {
                        response = comparedResponse.subQuestionResponses[index];
                    }
                    if (originalResponse.subQuestionResponses.length >= index + 1) {
                        originalSubQuestionResponse = originalResponse.subQuestionResponses[index];
                    }

                    return (
                        <tr key={`${comparedResponse.protocolResponseId}_${protocolResponseIndex}_${index}`}>
                            <td></td>
                            <td></td>
                            <td>
                                <ol type={"a"} start={index + 1}>
                                    <li><HtmlDisplayBlock html={subQuestion.text} /></li>
                                </ol>
                            </td>
                            <td className={"text-center"}>{originalSubQuestionResponse}</td>
                            {
                                responseTypeDisplayed === responseTypes.REASSESS &&
                                <td>
                                    <RadioField
                                        name={`${comparedResponse.protocolResponseId}_${protocolResponseIndex}_${index}`}
                                        options={subQuestion.districtResponseOptions}
                                        value={response}
                                        onChange={(event) => onSubQuestionResponseChange(event, comparedResponse.protocolResponseId, comparedResponse.studentRecordId, comparedResponse.protocolId, index)}
                                        direction={inputStyles.radio.direction.HORIZONTAL}
                                        disabled={!canEdit}
                                        legend={`Protocol Responses for &quot;${subQuestion.text}&quot; for student record ${originalResponse.studentRecordName}`}
                                        showLegend={false}
                                    />
                                </td>
                            }
                            {
                                responseTypeDisplayed === responseTypes.VALIDATION &&
                                <td className={"text-center highlight-discrepancy"}>{(response === responseOptions.NV) ? originalSubQuestionResponse : response}</td>
                            }
                        </tr>
                    );
                })
            }
        </>
    );
};

ReassessSubQuestion.propTypes = {
    protocolToBeReassessed: PropTypes.object.isRequired,
    protocolResponseIndex: PropTypes.number.isRequired,
    comparedResponse: PropTypes.object.isRequired,
    originalResponse: PropTypes.object.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    responseTypeDisplayed: PropTypes.number.isRequired
};

export default ReassessSubQuestion;