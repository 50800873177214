import GridColumn from "../../../components/common/GridColumn";
import SearchableSingleSelectField from "../../../components/common/inputs/SearchableSingleSelectField";
import GridRow from "../../../components/common/GridRow";
import React, {useEffect} from "react";
import {emptyGuid} from "../../../constants/config";
import BpieApi from "../bpieApi";
import PropTypes from "prop-types";
import {gridConstants} from "../../../constants/inputConstants";

const BpieSchoolFilterContainer = ({
                                       actions,
                                       districtId,
                                       school,
                                       schools,
                                       setSchools,
                                       loadReportData
                                   }) => {
    const handleUpdateSchools = (result) => {
        const filteredSchools = result.filter(f => districtId === emptyGuid || f.districtId === districtId);
        if (!filteredSchools)
            return;

        const schoolOptions = filteredSchools.map(r => {
            return {
                text: r.schoolNameWithMsid,
                value: `${r.districtId};${r.msidNumber}`
            };
        });
        setSchools(schoolOptions);
    };

    const loadSchools = () => {
        actions.executeApi(BpieApi.getAllDistinctBasicSchoolDetails, [])
            .then((result) => {
                handleUpdateSchools(result);
            });
    };

    useEffect(() => {
        loadSchools();
    }, []);

    return <GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
        {
            schools &&
            0 < schools.length &&
            <GridColumn>
                <SearchableSingleSelectField
                    label={`School`}
                    name={`schoolNumber`}
                    options={schools}
                    onChange={loadReportData}
                    value={school}
                />
            </GridColumn>
        }
    </GridRow>;
};

BpieSchoolFilterContainer.propTypes = {
    actions: PropTypes.object,
    districtId: PropTypes.string,
    school: PropTypes.string,
    schools: PropTypes.array,
    setSchools: PropTypes.func,
    loadReportData: PropTypes.func,
};

export default BpieSchoolFilterContainer;