import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import GridTable from "../../../../../components/common/GridTable";
import {ReadMore} from "../../../../../components/common/ReadMore";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";

export const BpieDistrictPieActionStepUpdatesSummaryView = ({
                                                                actionStepUpdates = [],
                                                                goals = [],
                                                                handleEditActionStepUpdates
                                                            }) => {
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
    const buttonType = isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
    const labelType = isEditable ? "Edit" : "View";

    const handleEditActionStepUpdatesClick = (event, goalId) => {
        event.preventDefault();
        handleEditActionStepUpdates(goalId);
    };

    return <>
        <GridTable className={`dpieGoalsSummary`}>
            <thead>
            <tr>
                <th>Goal</th>
                <th className={`text-center`}>Total Actions</th>
                <th className={`text-center`}>Total with Status Updated</th>
                <th className={`text-center`}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
                goals.map((goal, index) => {
                    const actionStepUpdatesForGoal = actionStepUpdates.filter(actionStep => goal.actionSteps.some(s => s.actionStepId === actionStep.dpieActionStepId));
                    return (
                        <tr key={index}>
                            <td>
                                <ReadMore text={`<strong>Goal ${goal.goalNumber}: </strong> ${goal.goalDescription}`}/>
                            </td>
                            <td className={`text-center`}>{goal.actionSteps.length}</td>
                            <td className={`text-center`}>{actionStepUpdatesForGoal.length}</td>
                            <td className={`actions text-center`}>
                                <Button
                                    buttonType={buttonType}
                                    label={`${labelType} Action Step Status Updates for Goal ${goal.goalNumber}`}
                                    name={`btnEditActionStatusUpdates-${index}`}
                                    onClick={(event) => handleEditActionStepUpdatesClick(event, goal.dpieGoalId)}
                                    showLabel={false}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
        </GridTable>
    </>;
};

BpieDistrictPieActionStepUpdatesSummaryView.propTypes = {
    actionStepUpdates: PropTypes.array,
    goals: PropTypes.array.isRequired,
    handleEditActionStepUpdates: PropTypes.func.isRequired
};