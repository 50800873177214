import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";

class Banner extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<header className="banner" role="banner">
				<div className="banner__gradient">
					<div className="l-flex-container">
						{
							this.props.onClick &&
							<Button
								btnClass={this.props.isActive ? " is-active " : ""}
								buttonType={ButtonTypes.MENU}
								name="btnMainMenu"
								onClick={this.props.onClick}
								label="menu"
							/>
						}
						{
							!this.props.onClick &&
							<div className="menu-counterweight"/>
						}
						<div>
							<table>
								<thead>
								<tr>
									<td className="banner__logo"/>
									<td className="l-table-spacer"/>
									<td className="banner__title">
										<div className={`h1`}>General Supervision</div>
										<div className={`h2`}>Bureau of Exceptional Education and Student Services</div>
									</td>
								</tr>
								</thead>
							</table>
						</div>
						<div className="menu-counterweight"/>
					</div>
				</div>
			</header>
		);
	}
}

Banner.propTypes = {
	isActive: PropTypes.bool.isRequired,
	onClick: PropTypes.func
};

Banner.defaultProps = {
	isActive: false
};

export default Banner;