import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {
    arraysOfObjectsEqual,
    createListIfExistsInIdArray,
    dynamicSort,
    isNullOrUndefined
} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import ListManagerField from "../../../../components/common/inputs/ListManagerField";
import {
    convertFormInputObjectToObject,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../components/common/inputs/SelectField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {actionTypes, gridConstants} from "../../../../constants/inputConstants";
import {createRegionUpdateObject} from "./regionObjectFactory";

const RegionView = ({
                        allActiveInactiveDistricts,
                        availableDistricts,
                        deleteRegion,
                        handleChangeRegion,
                        handleDataChanged,
                        handleSaveDistrictsForRegion,
                        regions,
                        selectedDistrictIds,
                        selectedRegionId,
                        setRegionIdToEdit
                    }) => {

    const regionForm = useFormInputObject(createRegionUpdateObject(selectedDistrictIds, availableDistricts), handleUpdatingAvailableDistrics);

    function handleUpdatingAvailableDistrics(event) {
        if (isNullOrUndefined(event.source))
            return;

        handleDataChanged(true);

        const action = event.source.action;
        const districtId = event.source.value;
        let districts = [...regionForm.currentAvailableDistricts.value]
        if (action === actionTypes.REMOVE && districts.every(f => f.id !== districtId)) {
            const districtToAddToList = allActiveInactiveDistricts.find(f => f.id === districtId);
            if (!isNullOrUndefined(districtToAddToList))
                districts.push(districtToAddToList);
        } else if (action === actionTypes.ADD)
            districts = districts.filter(f => f.id !== districtId);

        regionForm.currentAvailableDistricts.setValue(districts.sort(dynamicSort("text")));
    }

    const validateForm = (form) => {
        let errors = "";
        if (form.selectedDistrictIds.length === 0)
            errors += "At least one district needs to be selected.";

        if (errors) {
            NotifyUser.Warning(errors);
            return false;
        }

        return true;
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        saveDistrictsForRegion();
    };

    const onEditRegionClick = (event) => {
        event.preventDefault();
        setRegionIdToEdit(selectedRegionId);
    };

    const onAddRegionClick = (event) => {
        event.preventDefault();
        setRegionIdToEdit(emptyGuid);
    };

    const onDeleteRegionClick = (event) => {
        event.preventDefault();
        if(confirm("Are you sure you want to delete this region?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            deleteRegion(selectedRegionId);
    };

    const saveDistrictsForRegion = () => {
        let result = convertFormInputObjectToObject(regionForm);

        const isValid = validateForm(result);
        if (isValid) {
            handleSaveDistrictsForRegion(result.selectedDistrictIds);
        }

        return isValid;
    };

    const handleOnChangeRegion = (event) => {
        if (saveDistrictsForRegion())
            handleChangeRegion(event.target.value);
    };

    useEffect(() => {
        const newForm = createRegionUpdateObject(selectedDistrictIds, availableDistricts);
        if(!arraysOfObjectsEqual(regionForm.selectedDistrictIds.value, newForm.selectedDistrictIds))
            regionForm.selectedDistrictIds.setValue(newForm.selectedDistrictIds);
        if(!arraysOfObjectsEqual(regionForm.selectedDistrictId.value, newForm.selectedDistrictId))
            regionForm.selectedDistrictId.setValue(newForm.selectedDistrictId);
    }, [selectedDistrictIds]);

    useEffect(() => {
        if( !arraysOfObjectsEqual(regionForm.currentAvailableDistricts.value,availableDistricts))
            regionForm.currentAvailableDistricts.setValue(availableDistricts);
    }, [availableDistricts]);

    return (
        <>
            <GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
                <GridColumn>
                    <SelectField
                        name={"region"}
                        helpText={`Changing the region automatically saves the district list.`}
                        label={"Current Region"}
                        showLabel
                        options={regions}
                        includeDefaultOption={false}
                        value={selectedRegionId}
                        onChange={handleOnChangeRegion}
                    />
                </GridColumn>
            </GridRow>
            <ButtonBar position={ButtonBarPositions.MODULE}>
                <Button name="btnAdd"
                        label="Add Region"
                        buttonType={ButtonTypes.ADD}
                        onClick={onAddRegionClick}/>

                <Button name="btnEdit"
                        label="Edit Current Region"
                        buttonType={ButtonTypes.EDIT}
                        onClick={onEditRegionClick}/>

                <Button name="btnDelete"
                        label="Delete Current Region"
                        buttonType={ButtonTypes.DELETE}
                        onClick={onDeleteRegionClick}/>
            </ButtonBar>
            <FormTable>
                <ListManagerField
                    {...regionForm.selectedDistrictIds}
                    archivedLabel={""}
                    listItems={regionForm.currentAvailableDistricts.value}
                    managerLabel={`Districts`}
                    name={`selectedDistrictIds`}
                    listValues={createListIfExistsInIdArray(regionForm.selectedDistrictIds.value, allActiveInactiveDistricts)}
                />
            </FormTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>
            </ButtonBar>
        </>
    );
};

RegionView.propTypes = {
    allActiveInactiveDistricts: PropTypes.array.isRequired,
    availableDistricts: PropTypes.array.isRequired,
    deleteRegion: PropTypes.func.isRequired,
    handleChangeRegion: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveDistrictsForRegion: PropTypes.func.isRequired,
    regions: PropTypes.array.isRequired,
    selectedDistrictIds: PropTypes.array,
    selectedRegionId: PropTypes.string,
    setRegionIdToEdit: PropTypes.func.isRequired
};

export default RegionView;