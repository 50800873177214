import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import {BpieDistrictPieDetailsForm} from "./BpieDistrictPieDetailsForm";

export const BpieDistrictPieDetailsView = ({
                                               districtContacts,
                                               districtPie,
                                               dbpiePriorityIndicators,
                                               handleDataChanged,
                                               handleDistrictPieSummaryRedirect,
                                               handleSaveDistrictPieDetails,
                                               sbpieIndicators
                                           }) => {

    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    return <>
        <BpieDistrictPieDetailsForm
            dbpiePriorityIndicators={dbpiePriorityIndicators}
            districtContacts={districtContacts}
            districtPie={districtPie}
            handleDataChanged={handleDataChanged}
            handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
            handleSaveDistrictPieDetails={handleSaveDistrictPieDetails}
            isEditable={isEditable}
            sbpieIndicators={sbpieIndicators}
        />
    </>;
};

BpieDistrictPieDetailsView.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtContacts: PropTypes.array,
    districtPie: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictPieSummaryRedirect: PropTypes.func.isRequired,
    handleSaveDistrictPieDetails: PropTypes.func.isRequired,
    sbpieIndicators: PropTypes.array.isRequired
};