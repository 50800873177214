import React from 'react';
import PropTypes from 'prop-types';
export const loadingClass = "loader--isLoading";

const Loader = ({isLoading}) => {
        const message = isLoading ? "Loading in progress." : "Loading complete.";
        const className = `loader ${isLoading ? loadingClass : ""}`;

        return (
            <div className={className} role={`status`} aria-live={`assertive`}>
                <span>{message}</span>
            </div>
        );
}

Loader.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};

export default Loader;