import React, {cloneElement} from "react";

export function refocusOnPageChange() {
    const resetFocusItem = document.getElementsByTagName("h1")[0];
    if(!resetFocusItem) return;

    resetFocusItem.style.outline = "none";
    resetFocusItem.focus();
}

export function renderChildrenWithNewProps(children, props) {
    return React.Children.map(children, child => {
        return cloneElement(child, props);
    });
}