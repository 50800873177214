import React from "react";
import PropTypes from 'prop-types';
import * as responseTypes from "../../../constants/monitoringCompliance/responseTypes";
import ReassessSubQuestion from "./ReassessSubQuestion";

const ReassessSubQuestionList = (
    {
        protocolToBeReassessed,
        protocolResponses,
        onSubQuestionResponseChange,
        responseTypeDisplayed
    }) =>
{
    return (
        <>
            {
                protocolResponses.filter(f => f.responseType === responseTypeDisplayed).map((comparedResponse, index) => {
                    const originalResponse = protocolResponses.find(f => f.responseType === responseTypes.ASSESS
                        && f.studentRecordId === comparedResponse.studentRecordId);

                    return (
                        <ReassessSubQuestion
                            key={`${comparedResponse.protocolResponseId}_${index}`}
                            protocolResponseIndex={index}
                            protocolToBeReassessed={protocolToBeReassessed}
                            originalResponse={originalResponse}
                            comparedResponse={comparedResponse}
                            onSubQuestionResponseChange={onSubQuestionResponseChange}
                            responseTypeDisplayed={responseTypeDisplayed}
                        />
                    );
                })
            }
        </>
    );
};

ReassessSubQuestionList.propTypes = {
    protocolToBeReassessed: PropTypes.object.isRequired,
    protocolResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    responseTypeDisplayed: PropTypes.number.isRequired
};

export default ReassessSubQuestionList;