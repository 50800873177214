import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Banner from "../../components/layout/Banner";
import Footer from "../../components/layout/Footer";
import FlexSpacer from "../../components/common/FlexSpacer";
import Main from "../../components/layout/Main";
import {Route} from "react-router-dom";
import * as AuthorizationActions from "../../actions/authorizationActions";
import * as SharedDataActions from "../../actions/sharedDataActions";
import {arraysEqual, convertContextForThemeStyling} from "../../components/common/commonUtilities";
import {bindActionCreators} from "redux";
import {
	filterCurrentAnnualDateWindows,
	generateDateWindowSelectListItems
} from "../../components/shared/sharedDataUtilities";

export class PublicLayout extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.actions.loadAllDateWindows();
		this.props.actions.loadInstitutions();
	}

	componentDidUpdate() {
		const context = this.props.context ? this.props.context : ["public"];
		if (!arraysEqual(this.props.currentContext, context)) {
			this.props.actions.updateCurrentContext(context);
		}
	}

	render() {
		const {exact, path} = this.props;
		return (
			<Route {...exact} {...path} render={componentProps => (
				<div
					className={`l-flex-fullHeight theme-${convertContextForThemeStyling(this.props.currentContext)}`}>
					<Banner/>

					<Main
						{...this.props}
						{...componentProps}
					/>

					<FlexSpacer/>

					<Footer/>
				</div>
			)}/>
		);
	}
}

PublicLayout.propTypes = {
	actions: PropTypes.object.isRequired,
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
	context: PropTypes.array,
	currentContext: PropTypes.array,
	exact: PropTypes.bool,
	layout: PropTypes.object.isRequired,
	path: PropTypes.string,
};

function mapStateToProps(state) {
	const dateWindows = [...state.sharedData.dateWindows];
	let dateWindowsAnnualList = [];

	if (dateWindows.length > 0) {
		const annualWindows = filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindowsAnnualList = generateDateWindowSelectListItems(annualWindows);
	}

	return {
		currentContext: state.currentContext,
		dateWindowId: state.sharedData.selectedDateWindowId,
		dateWindows,
		dateWindowsAnnualList,
		institutions: state.sharedData.institutions,
		isLoading: state.ajaxCallsInProgress > 0,
		layout: state.layout
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, AuthorizationActions, SharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicLayout);
