import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import DrSchoolInformationEdit from "../../DrComponents/DrSchoolInformationEdit";
import * as drLocations from "../../drLocations";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {
    createListFromObject, getListOfPropNames,
    isNullOrUndefined, isNumber,
    isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FormTable from "../../../components/common/FormTable";
import SelectField from "../../../components/common/inputs/SelectField";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {DrRoles, DrRolesAssociatedToComplaint, StateComplaintTypeList} from "../../drConstants";
import {
    getFullCaseNumber,
    getTeamMemberId,
    updateTeamMembers,
    validateSchoolInformationRecords
} from "../../drUtilities";
import {StateComplaintSections} from "../drStateComplaintConstants";
import DrStateComplaintNextSteps from "../DrStateComplaintNextSteps";
import * as drObjectFactory from '../drStateComplaintObjectFactory';
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import SectionHeader from "../../../components/common/SectionHeader";

export const DrStateComplaintOverviewView = ({
                                                complaint,
                                                handleDeleteComplaint,
                                                handleSaveComplaint,
                                                handleDataChanged,
                                                handleSelectSection,
                                                readOnly,
                                                teamMembers
                                            }) => {
    const complaintForm = useFormInputObject(drObjectFactory.createComplaintObject(complaint), () => handleDataChanged());
    const [leadInvestigators, setLeadInvestigators] = useState(createListFromObject([]));
    const [backupInvestigators, setBackupInvestigators] = useState(createListFromObject([]));
    const [directors, setDirectors] = useState(createListFromObject([]));
    const [adminSecretaries, setAdminSecretaries] = useState(createListFromObject([]));
    const [contentSpecialists, setContentSpecialists] = useState(createListFromObject([]));
    const relatedMediationCaseNumbers = isNullOrUndefined(complaint.relatedMediationCaseNumbers) ? [] : getListOfPropNames(complaint.relatedMediationCaseNumbers);

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.Overview, true);
    };

    const onCaseNumberChange = (event) => {
                                                        const caseNumberCount = event.target.value;

                                                        if(caseNumberCount.length > 4 || !isNumber(caseNumberCount)) return;

                                                        complaintForm.complaintNumber.setValue(getFullCaseNumber(complaintForm.caseNumberYear.value, caseNumberCount, complaintForm.caseNumberType.value));
        complaintForm.caseNumberCount.setValue(caseNumberCount);
    }

    const validateForm = () => {
        clearInputFormErrorFields(complaintForm);

        const allSchoolInformationValid = validateSchoolInformationRecords(complaintForm);
        if(!allSchoolInformationValid)
            complaintForm.schoolInformationRecords.setError("All associated school data is required.");

        if(isTrimmedStringEmpty(complaintForm.caseNumberCount.value))
            complaintForm.caseNumberCount.setError("Missing case number.");

        if (isNullOrUndefined(complaintForm.dateComplaintReceived.value))
            complaintForm.dateComplaintReceived.setError("The date received must be selected.");

        if (isNullOrUndefined(complaintForm.dateComplaintFiled.value))
            complaintForm.dateComplaintFiled.setError("The date filed must be selected.");

        if(isTrimmedStringEmpty(complaintForm.ProgramDirector.value))
            complaintForm.ProgramDirector.setError("Director must be selected.");

        if(isTrimmedStringEmpty(complaintForm.ComplaintLeadInvestigator.value))
            complaintForm.ComplaintLeadInvestigator.setError("Lead investigator must be selected.");

        if(isTrimmedStringEmpty(complaintForm.ComplaintBackupInvestigator.value))
            complaintForm.ComplaintBackupInvestigator.setError("Backup investigator must be selected.");

        if(isTrimmedStringEmpty(complaintForm.AdminSecretary.value))
            complaintForm.AdminSecretary.setError("Administrative secretary must be selected.");

        let isValid = inputFormIsValid(complaintForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleClickSave = (event) => {
        event.preventDefault();
        if (validateForm()) {
            const form = convertFormInputObjectToObject(complaintForm);

            let currentTeamMembers = [...complaint.teamMembers];
            form.teamMembers = updateTeamMembers(currentTeamMembers, DrRolesAssociatedToComplaint, complaintForm);
            handleSaveComplaint(form);
        }
    };

    const handleClickDelete = handleDeleteComplaint
        ? (event) => {
            event.preventDefault();
            if (!confirm("Are you sure you want to delete this State Complaint?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
                return;

            handleDeleteComplaint();
        }
        : null;


    useEffect(() => {
        setLeadInvestigators(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.ComplaintLeadInvestigator.id) || a.id === getTeamMemberId(complaint.teamMembers, DrRoles.ComplaintLeadInvestigator.id)));
        setBackupInvestigators(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.ComplaintBackupInvestigator.id) || a.id === getTeamMemberId(complaint.teamMembers, DrRoles.ComplaintBackupInvestigator.id)));
        setDirectors(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.ProgramDirector.id) || a.id === getTeamMemberId(complaint.teamMembers, DrRoles.ProgramDirector.id)));
        setAdminSecretaries(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.AdminSecretary.id) || a.id === getTeamMemberId(complaint.teamMembers, DrRoles.AdminSecretary.id)));
        setContentSpecialists(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.ContentSpecialist.id) || a.id === getTeamMemberId(complaint.teamMembers, DrRoles.ContentSpecialist.id)));
    }, [teamMembers, complaint]);

    return (
        <>
            <SectionHeader>
                <h2>Section I. General Information</h2>
            </SectionHeader>
            <FormTable>
                <DisplayField name={`status`} label={`Status`} columnsMedium={6} showLabel>
                    {complaint.complaintStatus || "New"}
                </DisplayField>
                <DisplayField label={`Case Number (Fiscal Year-&lt;Number&gt;C)`} name={`caseNumber`} showLabel columnsMedium={6}>
                    {complaintForm.complaintNumber.value}
                </DisplayField>
                <TextField
                    columnsMedium={6}
                    disabled={readOnly}
                    label="Update Number in the Case Number"
                    name="caseNumberCount"
                    showLabel
                    {...complaintForm.caseNumberCount}
                    onChange={onCaseNumberChange}
                />
                <DateTimePickerField
                    name="dateComplaintReceived"
                    label="Date Received"
                    showLabel
                    {...complaintForm.dateComplaintReceived}
                    medium={6}
                    disabled={readOnly}
                />
                <DateTimePickerField
                    name="dateComplaintFiled"
                    label="Date Filed"
                    showLabel
                    {...complaintForm.dateComplaintFiled}
                    medium={6}
                    disabled={readOnly}
                />
                <SelectField
                    {...complaintForm.stateComplaintType}
                    disabled={!!complaint.id || readOnly}
                    includeDefaultOption={false}
                    label="State Complaint Type"
                    medium={6}
                    name="stateComplaintType"
                    options={StateComplaintTypeList}
                    showLabel
                />
                <SelectField
                    name="ProgramDirector"
                    label="Director"
                    showLabel
                    options={directors}
                    {...complaintForm.ProgramDirector}
                    medium={6}
                    disabled={readOnly}
                />
                <SelectField
                    name="ComplaintLeadInvestigator"
                    label="Lead Investigator"
                    showLabel
                    options={leadInvestigators}
                    {...complaintForm.ComplaintLeadInvestigator}
                    medium={6}
                    disabled={readOnly}
                />
                <SelectField
                    name="ComplaintBackupInvestigator"
                    label="Backup Investigator"
                    showLabel
                    options={backupInvestigators}
                    {...complaintForm.ComplaintBackupInvestigator}
                    medium={6}
                    disabled={readOnly}
                />
                <SelectField
                    name="AdminSecretary"
                    label="Administrative Secretary"
                    showLabel
                    options={adminSecretaries}
                    {...complaintForm.AdminSecretary}
                    medium={6}
                    disabled={readOnly}
                />
                <SelectField
                    name="ContentSpecialist"
                    label="Content Specialist"
                    showLabel
                    options={contentSpecialists}
                    {...complaintForm.ContentSpecialist}
                    medium={6}
                    disabled={readOnly}
                />
            </FormTable>

            <SectionHeader>
                <h2>Section II. Demographics</h2>
            </SectionHeader>
            <FormTable>
                <DrSchoolInformationEdit
                    {...complaintForm.schoolInformationRecords}
                    readOnly={readOnly}
                />
            </FormTable>

            <SectionHeader>
                <h2>Section III. Affiliated Cases</h2>
            </SectionHeader>
            <FormTable>
               <DisplayField
                    label="Affiliated Due Process Hearing Request"
                    showLabel
                    columnsMedium={6}
                    name="relatedDueProcessCaseNo"
               >
                    {complaint.additionalResponsesFromIntake && complaint.additionalResponsesFromIntake.relatedDueProcessCaseNumber
                        ? <Link
                            target={"_blank"}
                            to={drLocations.DUE_PROCESS_OVERVIEW.getUrl(
                                                                    complaint.dateWindowId,
                                                                    complaint.districtId,
                                                                    complaint.additionalResponsesFromIntake.relatedDueProcessId
                                                                )}
                        >
                            {complaint.additionalResponsesFromIntake.relatedDueProcessCaseNumber}
                        </Link>
                        : "None Associated"
                    }
               </DisplayField>
               <DisplayField
                    label="Affiliated Mediation Requests"
                    showLabel
                    columnsMedium={6}
                    name="relatedMediationCaseNo"
               >
                   {relatedMediationCaseNumbers.length > 0
                        ?  <ul className="single-space related-mediation-case-numbers">
                            {relatedMediationCaseNumbers.map((mediationId, index) => {
                            return <li key={index}>
                                        <Link
                                        target={"_blank"}
                                        to={drLocations.MEDIATION_OVERVIEW.getUrl(
                                                                                complaint.dateWindowId,
                                                                                complaint.districtId,
                                                                                mediationId
                                                                            )}
                                        >
                                            {complaint.relatedMediationCaseNumbers[mediationId]}
                                        </Link>
                                    </li>
                            })}
                       </ul>
                        : "None Associated"
                   }
               </DisplayField>
            </FormTable>
            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    {
                        !readOnly &&
                        handleClickDelete &&
                        <Button name="btnDelete"
                                label="Delete"
                                buttonType={ButtonTypes.DELETE}
                                onClick={handleClickDelete}/>
                    }
                    <Button name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SAVE}
                            onClick={handleClickSave}/>

                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                    <DrStateComplaintNextSteps handleSelectSection={handleSelectSection} complaint={complaint}/>

                </ButtonBar>
            }
        </>
    );
};

DrStateComplaintOverviewView.propTypes = {
    complaint: PropTypes.object,
    handleDeleteComplaint: PropTypes.func,
    handleSaveComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    teamMembers: PropTypes.array.isRequired,
};

export default DrStateComplaintOverviewView;