import {cloneDeep} from "lodash/lang";
import {BUDGET_COLUMNS_LIST} from "../../../containers/gms/Application/ApplicationBudget/gmsApplicationBudgetConstants";
import {
    getPropName,
    isArrayNullOrEmpty,
    filterObjectArrayByField,
    getDistinctIds,
    createListItem,
    removeDuplicatesFromListArray,
} from "../commonUtilities";
import {searchCriteria, SearchFilterElements} from "./searchCriteria";

export function GmsSearchCriteria(sortByColumn = "fiscalAgentName", baseSortByColumn = "fiscalAgentName") {
    return {
        ...searchCriteria("GmsSearchCriteria", sortByColumn, baseSortByColumn, 20),
        applicationTypeDescription: "",
        applicationStatusDescription: "",
        budgetColumnsDisplayed: BUDGET_COLUMNS_LIST.map(column => column.value),
        projectName: "",
        allProjects: [],
        applyFiltersAndOrder: ApplyFiltersAndOrder,
        areRulesCurrent: AreRulesCurrent,
        clearOptionalSearchItems: ClearOptionalSearchItems,
        setProjectList: SetProjectList,
        setVisibilityRules: SetVisibilityRules,
    };
}

export const GmsFilterElements = {
    ...SearchFilterElements,
    ...{
        applicationTypeDescription: "applicationTypeDescription",
        applicationStatusDescription: "applicationStatusDescription",
        projectName: "projectName"
    },
};

export const GmsFilterVisibilityRules =
    new (function () {
        this.shared = [
            GmsFilterElements.dateWindowId,
            GmsFilterElements.RecordsPerPage,
            GmsFilterElements.clearButton,
            GmsFilterElements.applicationTypeDescription,
            GmsFilterElements.applicationStatusDescription,
        ];
        this.AdminSummaryWrapper = [
            ...this.shared,
            GmsFilterElements.fiscalAgent,
            GmsFilterElements.teamMember,
        ];
        this.FiscalAgentSummaryWrapper = [
            ...this.shared,
            GmsFilterElements.fiscalAgentHidden,
        ];
        this.AdminWrapper = [
            GmsFilterElements.dateWindowId,
        ];
        this.DiscretionaryProject = [
            GmsFilterElements.projectName,
            GmsFilterElements.clearButton
        ];
    })();

function SetProjectList(allProjects) {
    const projectNames = allProjects.map(({projectName}) => createListItem(projectName, projectName));
    this.allProjects = removeDuplicatesFromListArray(projectNames);
}

function SetVisibilityRules(whichRules) {
    const rulesName = getPropName(GmsFilterVisibilityRules, whichRules);
    this.baseSetVisibilityRules(rulesName, whichRules, this.BaseSortByColumn);
}

function AreRulesCurrent(whichRules) {
    return this.baseAreRulesCurrent(whichRules, GmsFilterVisibilityRules);
}

function ClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    this.baseClearOptionalSearchItems(clearTeamMembersAsWell, clearOutAll);

    if (this.isVisible(GmsFilterElements.applicationTypeDescription) || clearOutAll)
        this.applicationTypeDescription = "";

    if (this.isVisible(GmsFilterElements.applicationStatusDescription) || clearOutAll)
        this.applicationStatusDescription = "";

    if (this.isVisible(GmsFilterElements.projectName) || clearOutAll)
        this.projectName = "";
}

function ApplyFiltersAndOrder(summaryData) {
    if (isArrayNullOrEmpty(summaryData))
        return [];

    let summary = cloneDeep(summaryData);
    summary = this.baseApplyFilters(summary);

    if(isArrayNullOrEmpty(summary))
        return [];

    if (this.applicationTypeDescription && this.isVisible(GmsFilterElements.applicationTypeDescription))
        summary = summary.filter(f => f.applicationTypeDescription.toLowerCase() === this.applicationTypeDescription.toLowerCase());

    if (this.applicationStatusDescription && this.isVisible(GmsFilterElements.applicationStatusDescription))
        summary = summary.filter(f => f.applicationStatusDescription.toLowerCase() === this.applicationStatusDescription.toLowerCase());

    if (this.projectName && this.isVisible(GmsFilterElements.projectName))
        summary = summary.filter(f => f.projectName.toLowerCase() === this.projectName.toLowerCase());

    if (this.teamMember && this.isVisible(GmsFilterElements.teamMember)) {
        const associatedMember = this.teamMembers.filter(m => m.userId === this.teamMember);
        const associatedFiscalAgents = getDistinctIds(associatedMember, "fiscalAgentId");
        summary = filterObjectArrayByField(summary, associatedFiscalAgents.toString(), "fiscalAgentId");
    }

    return this.baseSort(summary);
}