import React from 'react';
import PropTypes from 'prop-types';
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import SubmissionModule from "../../components/common/SubmissionModule";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import RecordRequirementsTable from "../../components/MonitoringCompliance/RecordRequirementsTable";
import {Allow} from "../../components/authorization/Allow";
import {monitoring_compliance} from "../../constants/policyEvents";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";

export class StudentRecordsDistrictSummaryPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onRequirementEditClick = this.onRequirementEditClick.bind(this);
        this.onAddStudentRecordClick = this.onAddStudentRecordClick.bind(this);
        this.onDeleteStudentRecordClick = this.onDeleteStudentRecordClick.bind(this);
        this.onClickReviewStudentRecords = this.onClickReviewStudentRecords.bind(this);
    }

    componentDidMount() {
        this.props.actions.updatePageTitle("Monitoring for Compliance - Student Records");
    }

    onRequirementEditClick(event, indicatorRequirementId, studentRecordId) {
        event.preventDefault();

        this.linkStudentRecords(indicatorRequirementId, studentRecordId);
    }

    onAddStudentRecordClick(event, indicatorRequirementId) {
        event.preventDefault();

        const newStudentRecordId = emptyGuid;

        this.linkStudentRecords(indicatorRequirementId, newStudentRecordId);
    }

    onDeleteStudentRecordClick(event, studentRecordId, indicatorRequirementId) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this student record?")) {
            this.props.actions.deleteStudentRecord(this.props.selectedAssessment.districtId,
                this.props.selectedAssessment.monitoringAssessmentId,
                studentRecordId)
                .then(() => {
                    this.props.loadComplianceAssessment(() => {
                        this.props.handleScroll(indicatorRequirementId);
                    });
                });
        }
    }

    linkStudentRecords(indicatorRequirementId, studentRecordId) {
        const indicator = this.props.selectedAssessment.indicatorRequirements.find(f => f.indicatorRequirementId === indicatorRequirementId);
        const path = (indicator && indicator.isMatrix)
            ? ComplianceLocations.COMPLIANCE_MATRIX_RECORD_DISTRICT.path
            : ComplianceLocations.COMPLIANCE_RECORD_DISTRICT.path;

        this.props.history.push(path
            .replace(ComplianceLocations.ASSESSMENT_ID, this.props.selectedAssessment.monitoringAssessmentId)
            .replace(ComplianceLocations.REQUIREMENT_ID, indicatorRequirementId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId));
        this.props.handleScrollToTop();
    }

    onClickReviewStudentRecords(indicatorRequirementId) {
        this.props.history.push(ComplianceLocations.COMPLIANCE_REVIEW_SUBMISSION.path
            .replace(ComplianceLocations.ASSESSMENT_ID, this.props.selectedAssessment.monitoringAssessmentId)
            .replace(ComplianceLocations.REQUIREMENT_ID, indicatorRequirementId));
        this.props.handleScrollToTop();
    }

    canUserEdit() {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    }

    render() {
        return (
            <section>
                <div className={`page-directions`}>
                    <p>All monitoring protocol documentation can be found on <a href={"" +
                        "http://fldoe.org/academics/exceptional-student-edu/monitoring/"} target={"_blank"} rel={"noopener noreferrer"}>FDOE&lsquo;s site</a>.</p>
                    <p><strong>GSW Student Number</strong> - upon beginning a review for a given protocol in the GSW, a unique student identifier will be assigned by the web-based application. The user must record the student identifier for each student in the Student Selection Form (column F), as maintaining this information is critical for the validation and verification processes.</p>
                </div>
                {
                    this.props.selectedAssessment.indicatorRequirements.map((requirement, index) => {
                        const studentRecords = MonitoringComplianceUtilities.getStudentRecordsForIndicatorRequirement(
                            requirement.indicatorId,
                            this.props.selectedAssessment.studentRecords);

                        const submissionInformation = MonitoringComplianceUtilities.validateIsReadyToSubmit(requirement, studentRecords);

                        return (
                            <section className="compliance__requiredRecords section-spacing-bottom" id={requirement.indicatorRequirementId} key={requirement.indicatorRequirementId}>
                                <RecordRequirementsTable
                                    indicatorRequirement={requirement}
                                    onEditClick={this.onRequirementEditClick}
                                    isLoading={this.props.isLoading}
                                    onClickAddStudentRecord={this.onAddStudentRecordClick}
                                    onClickDeleteStudentRecord={(event, studentRecordId) => this.onDeleteStudentRecordClick(event, studentRecordId, requirement.indicatorRequirementId)}
                                    requirementRecords={studentRecords}
                                    canEdit={this.canUserEdit()}
                                />
                                <Allow policy={MonitoringCompliancePolicy} policyEvent={monitoring_compliance.studentRecord.SUBMIT}>
                                    <SubmissionModule
                                        handlePrimarySubmit={() => this.onClickReviewStudentRecords(requirement.indicatorRequirementId)}
                                        hidePrimarySubmissionButton={submissionInformation.isSubmitted}
                                        name={`btnSubmit_${index}`}
                                        primaryButtonLabel={`Review Submission Data`}
                                        primaryDisabled={this.props.isLoading || submissionInformation.isSubmissionModuleDisabled}
                                        submissionMessage={submissionInformation.submissionMessage}
                                        submissionTitle={`Student Record Submission`}
                                    />
                                </Allow>
                            </section>
                        );
                    })
                }
            </section>
        );
    }
}

StudentRecordsDistrictSummaryPage.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object
};

export default StudentRecordsDistrictSummaryPage;