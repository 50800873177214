import React from "react";
import PropTypes from "prop-types";
import SurveyRespondentRow from "../surveyAdmin/SurveyRespondentRow";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";
import GridTable from "../common/GridTable";

const SurveyRespondentList = ({ respondents, surveyStatus, onRespondentDelete, onRespondentUnsubmit }) => {
    return (
        <GridTable>
            <thead>
                <tr>
                    <th>Email</th>
                    <th>District</th>
                    <th>Progress</th>
                    {
                        surveyStatus !== surveyStatuses.Closed &&
                        <th className="text-center">Actions</th>
                    }
                </tr>
            </thead>
            <tbody>
            {
                respondents.map(respondent =>
                    <SurveyRespondentRow
                        key={respondent.surveyRespondentId}
                        surveyRespondentId={respondent.surveyRespondentId}
                        email={respondent.email}
                        progress={respondent.progress}
                        institution={respondent.institution}
                        surveyStatus={surveyStatus}
                        onDelete={onRespondentDelete}
                        onUnsubmit={onRespondentUnsubmit} /> )
            }
            </tbody>
        </GridTable>
    );
};

SurveyRespondentList.propTypes = {
    respondents: PropTypes.array.isRequired,
    onRespondentDelete: PropTypes.func.isRequired,
    onRespondentUnsubmit: PropTypes.func.isRequired,
    surveyStatus: PropTypes.string.isRequired
};

export default SurveyRespondentList;