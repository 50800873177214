import * as actionTypes from "../constants/actionTypes";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";

export function apiSuccess() {
    return { type: actionTypes.GENERIC_LOCAL_STATE_AJAX_SUCCESS };
}

export function executeApi(apiFunc, apiParams = []) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return apiFunc(...apiParams)
            .then((results) => {
                dispatch(apiSuccess());
                return results;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}