import userApi from "../api/UserApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

//PROFILE ACTION CREATORS
export function loadUserByIdSuccess(user){
    return { type: actionTypes.LOAD_USER_BY_ID_AJAX_SUCCESS, user };
}

export function loadUserById(userId){
    return dispatch => {

        dispatch(beginAjaxCall());

        return userApi.getUserById(userId)
            .then(user =>
            {
                dispatch(loadUserByIdSuccess(user));
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
            }
        );
    };
}

export function resetUserPasswordSuccess(){
    return {type: actionTypes.RESET_USER_PASSWORD_AJAX_SUCCESS };
}

export function resetUserPassword(userId){
    return dispatch => {

        dispatch(beginAjaxCall());

        return userApi.resetUserPassword(userId)
            .then(() =>
            {
                dispatch(resetUserPasswordSuccess());
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
                throw (error);
            }
        );
    };
}

export function deleteUserSuccess(){
    return {type: actionTypes.DELETE_USER_AJAX_SUCCESS };
}

export function deleteUser(userId){
    return dispatch => {

        dispatch(beginAjaxCall());

        return userApi.deleteUser(userId)
            .then(() =>
            {
                dispatch(deleteUserSuccess());
                dispatch(clearLoadedUser());
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
                throw (error);
            }
        );
    };
}

export function reactivateUserSuccess(){
    return {type: actionTypes.REACTIVATE_USER_AJAX_SUCCESS };
}

export function reactivateUser(userId){
    return dispatch => {

        dispatch(beginAjaxCall());

        return userApi.reactivateUser(userId)
            .then(() =>
            {
                dispatch(reactivateUserSuccess());
                dispatch(clearLoadedUser());
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
                throw (error);
            }
        );
    };
}

export function clearLoadedUser(){
    return { type: actionTypes.CLEAR_LOADED_USER };
}

export function saveUserSuccess(user){
    return { type: actionTypes.SAVE_USER_AJAX_SUCCESS, user};
}

export function saveUser(user){
    return dispatch => {
        dispatch(beginAjaxCall());

        return userApi.saveUser(user)
            .then((data) =>
            {
                dispatch(saveUserSuccess(user));
                dispatch(clearLoadedUser());
                user.id = data.id;
                return user;
            })
            .catch(error =>
                {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

export function saveUserProgramsSuccess(){
    return { type: actionTypes.SAVE_USER_PROGRAMS_AJAX_SUCCESS };
}

export function saveUserPrograms(user, selectedPrograms){
    return dispatch => {
        dispatch(beginAjaxCall());

        return userApi.saveUserPrograms(user, selectedPrograms)
            .then(() =>
            {
                dispatch(saveUserProgramsSuccess());
            })
            .catch(error =>
                {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            );
    };
}

//USER SEARCH ACTION CREATORS
export function loadUsersSuccess(data){
    return { type: actionTypes.LOAD_USERS_AJAX_SUCCESS, data };
}

export function loadUsers(filterCriteria){
    return dispatch => {
        dispatch(beginAjaxCall());

        return userApi.getUsers(filterCriteria)
            .then(data =>
            {
                dispatch(loadUsersSuccess(data));
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
                throw (error);
            }
        );
    };
}

export function loadProgramForFiscalAgentSuccess(programsForFiscalAgent) {
    return { type: actionTypes.LOAD_PROGRAMS_FOR_FISCAL_AGENT_AJAX_SUCCESS, programsForFiscalAgent: programsForFiscalAgent };
}

export function loadProgramForFiscalAgent(fiscalAgentId, userId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return userApi.getProgramForFiscalAgent(fiscalAgentId, userId)
            .then(programsForFiscalAgent => {
                dispatch(loadProgramForFiscalAgentSuccess(programsForFiscalAgent));
                return programsForFiscalAgent;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            }
        );
    };
}
