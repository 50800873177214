import React from "react";
import PropTypes from 'prop-types';
import HtmlDisplayInline from "../../../components/htmlDisplay/HtmlDisplayInline";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {DrRoles} from "../../drConstants";
import {TimelineRequirements} from "../drStateComplaintConstants";

const TrackingSheetRow = ({
	                          complaint,
	                          districtName,
	                          rowIndex,
							  timelineColumns
                          }) => {

	const getTimelineRequirementByType = (timeline, timelineRequirementType, whichDate) => {
		if (!timeline)
			return "&nbsp;";

		const timelineItem = timeline.timelineResponseModels.find(f => f.timelineRequirementType === timelineRequirementType);

		if (!timelineItem)
			return "&nbsp;";

		return getTimelineDate(timelineItem, whichDate);
	};

	const getTimelineRequirement = (timeline, timelineRequirementId, whichDate) => {
		if (!timeline)
			return "&nbsp;";

		const timelineItem = timeline.timelineResponseModels.find(f => f.timelineRequirementId === timelineRequirementId);

		if (!timelineItem)
			return "&nbsp;";

		return getTimelineDate(timelineItem, whichDate);
	};

	const getTimelineDate = (timelineItem, whichDate) => {
		const rawDate = whichDate === "due"
			? timelineItem.dueDate
			: timelineItem.dateCompleted;

		if(!rawDate)
			return "&nbsp;";

		return outputStandardDateFormat(rawDate, false, true);
	};

	const getTeamMemberName = (role) => {
		const teamMember = complaint.teamMembers.find(f => f.role === role);

		return teamMember ? teamMember.fullName : "";
	};

	const timeline = complaint.timelineModel;
	const rowCss = rowIndex % 2 ? "" : "odd";
	const completedRowCss = !timeline ? "" : "completed-row";

	return (
		<>
			<tr className={rowCss}>
				<td rowSpan={"2"}>{complaint.complaintNumber}</td>
				<td rowSpan={"2"}>{districtName}</td>
				<td rowSpan={"2"}>{getTeamMemberName(DrRoles.ComplaintLeadInvestigator.id)}</td>
				<td rowSpan={"2"}>{getTeamMemberName(DrRoles.ComplaintBackupInvestigator.id)}</td>
				<td rowSpan={"2"}>{getTeamMemberName(DrRoles.ProgramDirector.id)}</td>
				<td className={"text-center"}
				    rowSpan={"2"}>{outputStandardDateFormat(complaint.dateComplaintReceived, false, true)}</td>
				<td className={"text-center"}
				    rowSpan={"2"}>{outputStandardDateFormat(complaint.dateComplaintFiled, false, true)}</td>
				<td className={"text-center"}
				    rowSpan={"2"}>{outputStandardDateFormat(complaint.extensionDate, false, true)}</td>
				{
					timelineColumns.map((column, index) => {
						return (
							<td key={index} className={"text-center"}><HtmlDisplayInline html={getTimelineRequirement(timeline, column.timelineRequirementId, "due")} /></td>
						);
					})
				}
				<td className={"text-center"} rowSpan={"2"}><HtmlDisplayInline html={getTimelineRequirementByType(timeline, TimelineRequirements.OrderSigned, "due")} /></td>
			</tr>
			<tr className={`${rowCss} ${completedRowCss}`}>
				{
					timelineColumns.map((column, index) => {
						return (
							<td key={index} className={"text-center"}><HtmlDisplayInline html={getTimelineRequirement(timeline, column.timelineRequirementId, "completed")} /></td>
						);
					})
				}
			</tr>
		</>
	);
};

TrackingSheetRow.propTypes = {
	complaint: PropTypes.object.isRequired,
	districtName: PropTypes.string.isRequired,
	rowIndex: PropTypes.number.isRequired,
	timelineColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TrackingSheetRow;