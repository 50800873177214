import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import gmsApi from "../../gmsApi";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import * as gmsLocations from "../../gmsLocations";
import {GmsApplicationBudgetAudit} from "./GmsApplicationBudgetAudit";
import {GmsApplicationBudgetHistory} from "./GmsApplicationBudgetHistory";
import {allow} from '../../../../components/authorization/AuthorizationUtilities';
import {GmsPolicy, policyEvents} from '../../../../components/authorization/policies/GmsPolicy';
import {GmsApplicationBudgetView} from "./GmsApplicationBudgetView";
import {isAmendmentBudgetType} from "./gmsApplicationBudgetConstants";

const GmsApplicationBudgetContainer = ({
                                           actions,
                                           applicationHistories,
                                           applicationId,
                                           budgetId,
                                           currentUserFiscalAgentId,
                                           history,
                                           isFiscalAgentUser,
                                           searchCriteria,
                                           selectedApplicationHistory,
                                           setSelectedApplicationHistory,
                                       }) => {
    const [budget, setBudget] = useState({});
    const [codes, setCodes] = useState(null);
    const [editId, setEditId] = useState(null);
    const isEditing = !!editId;
    const canUserEditBudget = budget.canEditBudget && allow(GmsPolicy, policyEvents.APPLICATION.modify);

    const loadApplicationBudget = () => {
        actions.executeApi(gmsApi.getApplicationBudget, [applicationId, budgetId])
            .then((result) => {
                if (!result.canViewApplication || (isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId)) {
                    const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                    history.push(resolvedPath);
                    return;
                }

                setBudget(result);
            });
    };

    const initializeCodes = () => {
        actions.executeApi(gmsApi.getBudgetCodes)
            .then((result) => {
                setCodes(result);
            });
    };

    useEffect(() => {
        loadApplicationBudget();
        initializeCodes();
    }, []);

    useEffect(() => {
        if (budget?.applicationTitle)
            actions.updatePageTitle(budget.applicationTitle);

        actions.updatePageSubTitle(budget.budgetTitle);

        return actions.clearSubTitle;
    }, [budget]);

    if (!budget || !codes || !budget.budgetElementId) return null;

    const isAmendmentBudget = isAmendmentBudgetType(budget.budgetType);
    const filteredApplicationHistories= applicationHistories.filter(x => x.hasAmendedData === isAmendmentBudget);

    return (
        <>
            <GmsApplicationBudgetAudit
                actions={actions}
                applicationId={applicationId}
                budget={budget}
                budgetId={budgetId}
                canUserEditBudget={canUserEditBudget}
                isFiscalAgentUser={isFiscalAgentUser}
                setBudget={setBudget}
            />

            <GmsApplicationBudgetHistory
                actions={actions}
                applicationHistories={filteredApplicationHistories}
                applicationId={applicationId}
                budgetId={budgetId}
                isAmendmentBudget={isAmendmentBudget}
                selectedApplicationHistory={selectedApplicationHistory}
                setSelectedApplicationHistory={setSelectedApplicationHistory}
            />

            <GmsApplicationBudgetView
                actions={actions}
                applicationId={applicationId}
                budget={budget}
                budgetId={budgetId}
                canUserEditBudget={canUserEditBudget}
                codes={codes}
                editId={editId}
                isAmendmentBudget={isAmendmentBudget}
                isEditing={isEditing}
                loadApplicationBudget={loadApplicationBudget}
                searchCriteria={searchCriteria}
                setEditId={setEditId}
            />

            {
                !isEditing &&
                <GmsApplicationNavigation
                    applicationId={applicationId}
                    budgetId={budgetId}
                    canEditApplication={canUserEditBudget}
                    history={history}
                />
            }
        </>
    );
};


GmsApplicationBudgetContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    budgetId: PropTypes.string.isRequired,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.object,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
};

export default GmsApplicationBudgetContainer;