import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import { RequestTypes } from '../../../api/requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceSummaryApi {

    static getMcSummaryData(dateWindowId, indicatorId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/Dates/${dateWindowId}/Indicators/${indicatorId}/Summary`,
            RequestTypes.GET));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceSummaryApi {
    static getMcSummaryData(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceSummaryApi : MockMonitoringComplianceSummaryApi;
export default MonitoringComplianceSummaryApi;