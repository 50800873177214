import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {createListIfExistsInIdArray} from "../../../components/common/commonUtilities";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintCitationEdit from "../Citations/DrStateComplaintCitationEdit";
import DrStateComplaintStudentEdit from "../Students/DrStateComplaintStudentEdit";
import FormTable from "../../../components/common/FormTable";
import ListManagerField from "../../../components/common/inputs/ListManagerField";
import SelectField from "../../../components/common/inputs/SelectField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {
	convertFormInputObjectToObject,
	useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as drStateComplaintConstants from "../drStateComplaintConstants";
import * as drObjectFactory from "../drStateComplaintObjectFactory";

const DrStateComplaintIssueComponentEdit = ({
												isLoading,
												issue,
	                                            issueCodes,
	                                            noIssues,
	                                            onClickCancel: handleClickCancel,
	                                            onClickSave: handleClickSave,
	                                            onScroll: handleScroll,
	                                            handleDataChanged,
	                                            studentExceptionalities
                                            }) => {

	const issueForm = useFormInputObject(drObjectFactory.createIssueObject(issue), () => handleDataChanged());

	const validateForm = (form) => {
		let errors = "";
		const students = form.students;
		if (students) {
			for (let index in students) {
				const student = students[index];
				if (!student.primaryExceptionalityType || !student.isPreK)
					errors += "<li>All student data is required.</li>";
			}
		}

		const citations = form.citations;
		if (citations) {
			for (let index in citations) {
				const citation = citations[index];
				citation.citationText = citation.citationText.trim();
				if (!citation.citationType || !citation.isViolation || !citation.citationText)
					errors += "<li>All citation data is required.</li>";

				if(citation.citationType === drStateComplaintConstants.CitationTypes.Federal && citation.citationText.indexOf(".") === -1)
					errors += `<li>${drStateComplaintConstants.CitationTypes.Federal} citations must contain a period - ${citation.citationText}`;

				if(citation.citationType === drStateComplaintConstants.CitationTypes.State) {
					const stateMaxLength = 20;
					if(citation.citationText.length > stateMaxLength)
						errors += `<li>${drStateComplaintConstants.CitationTypes.State} citations must not be greater than ${stateMaxLength} characters - ${citation.citationText}`;

					if(citation.citationText.indexOf(".") === -1 || citation.citationText.indexOf("-") === -1)
						errors += `<li>${drStateComplaintConstants.CitationTypes.State} citations must contain a dash and a period - ${citation.citationText}`;
				}

				if(citations.filter(f => f.citationType === citation.citationType && f.citationText.toLowerCase() === citation.citationText.toLowerCase()).length > 1) {
					const duplicateErrorMsg = `<li>Duplicate citations are not allowed - ${citation.citationText}</li>`;
					if(errors.indexOf(duplicateErrorMsg) === -1)
						errors += duplicateErrorMsg;
				}
			}
		}

		if(errors) {
			NotifyUser.Warning(errors);
			return false;
		}

		return true;
	};

	const fetchFormData = () => {
		const result = convertFormInputObjectToObject(issueForm);
		if (validateForm(result)) {
			handleClickSave(result);
		}
	};

	useEffect(() => {
		handleScroll();
	}, []);

	return (
		<>
			<FormTable>
				{
					!issue.issueId &&
					<DisplayField label={`New Issue`} name={`newIssue`} showLabel={false}>
						<h2>New Issue</h2>
					</DisplayField>
				}
				<SelectField
					medium={6}
					label="Type"
					name="issueScope"
					showLabel
					options={drStateComplaintConstants.IssueTypeList}
					includeDefaultOption={false}
					{...issueForm.issueScope}
				/>
				<TextAreaEditor
					label="Description"
					name="issueDescription"
					showLabel
					{...issueForm.issueDescription}
				/>
				<TextAreaEditor
					label="General Citation Details"
					name="generalCitationDetails"
					showLabel
					{...issueForm.generalCitationDetails}
				/>
				<DrStateComplaintCitationEdit
					{...issueForm.citations}
				/>
				<ListManagerField
					{...issueForm.issueCodeDescriptions}
					listItems={issueCodes}
					managerLabel={`Codes`}
					name={`issueCodeDescriptions`}
					listValues={createListIfExistsInIdArray(issueForm.issueCodeDescriptions.value, issueCodes)}
				/>
				<DrStateComplaintStudentEdit
					{...issueForm.students}
					studentExceptionalities={studentExceptionalities}
				/>
			</FormTable>
			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name="btnSave"
						disabled={isLoading}
				        label="Save"
				        buttonType={ButtonTypes.SAVE}
				        onClick={() => fetchFormData()}/>
				{
					!noIssues &&
					<Button name="btnCancel"
					        label="Cancel"
					        buttonType={ButtonTypes.CANCEL}
					        onClick={() => handleClickCancel()}/>
				}
			</ButtonBar>
		</>
	);
};

DrStateComplaintIssueComponentEdit.propTypes = {
	isLoading: PropTypes.bool,
	issue: PropTypes.object.isRequired,
	issueCodes: PropTypes.array.isRequired,
	noIssues: PropTypes.bool,
	onClickCancel: PropTypes.func.isRequired,
	onClickSave: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	onScroll: PropTypes.func.isRequired,
	studentExceptionalities: PropTypes.array.isRequired
};

DrStateComplaintIssueComponentEdit.defaultProps = {
	noIssues: false
};

export default DrStateComplaintIssueComponentEdit;