import React from 'react';
import PropTypes from 'prop-types';
import AuditHistoryOutcomeCommentDisplay from "./AuditHistoryOutcomeCommentDisplay";
import AuditHistoryOutcomeCommentForm from "./AuditHistoryOutcomeCommentForm";
import AccountUtility from "../../api/AccountUtility";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";

const AuditHistoryOutcomeComment = ({
                                        audit,
                                        editItemId,
                                        handleCommentChange,
                                        isFirstAudit,
                                        isInEditMode,
                                        isLoading,
                                        labelForVisibility,
                                        onCommentEdit,
                                        onCommentSave,
                                        onCommentCancel,
                                        onCommentCreate,
                                        parentIdPropName
                                    }) => {
    if (audit === undefined || !audit.comments)
        return null;

    return (
        <>
            {
                isFirstAudit &&
                !isInEditMode &&
                !AccountUtility.isDistrictUser() &&
                onCommentCreate &&
                <TileContainer rowTilesLarge={"1"}>
                    <Tile
                        isNew={true}
                        body={"Add Comment"}
                        isMedium
                        onClick={
                            (event) => {
                                event.preventDefault();
                                onCommentCreate(audit.auditId, audit[parentIdPropName]);
                            }
                        }
                    />
                </TileContainer>
            }
            {
                audit.comments.map((comment) => {
                    if (isInEditMode && comment.auditCommentId === editItemId) {
                        return (<AuditHistoryOutcomeCommentForm
                            comment={comment}
                            key={comment.auditCommentId}
                            handleCommentChange={handleCommentChange}
                            isLoading={isLoading}
                            labelForVisibility={labelForVisibility}
                            onCommentCancel={onCommentCancel}
                            onCommentSave={onCommentSave}
                            parentId={audit[parentIdPropName]}
                        />);
                    }

                    return <AuditHistoryOutcomeCommentDisplay
                        comment={comment}
                        key={comment.auditCommentId}
                        isInEditMode={isInEditMode}
                        onCommentEdit={onCommentEdit}
                    />;
                })
            }
        </>
    );
};

AuditHistoryOutcomeComment.propTypes = {
    audit: PropTypes.object.isRequired,
    editItemId: PropTypes.string,
    handleCommentChange: PropTypes.func,
    isFirstAudit: PropTypes.bool,
    isInEditMode: PropTypes.bool,
    isLoading: PropTypes.bool,
    labelForVisibility: PropTypes.string,
    onCommentEdit: PropTypes.func,
    onCommentSave: PropTypes.func,
    onCommentCancel: PropTypes.func,
    onCommentCreate: PropTypes.func,
    parentIdPropName: PropTypes.string
};

export default AuditHistoryOutcomeComment;