export const createReportStatusesObject = ({
                                               hasUnsignedDoe100Form = false,
                                               hasSignedDoe100Form = false,
                                               hasUnsignedDoe150Form = false,
                                               hasSignedDoe150Form = false,
                                               hasSignedDoe200Form = false,
                                               hasDoe101Form = false,
                                               hasDoe151Form = false
                                                } = {}) => ({
    hasUnsignedDoe100Form,
    hasSignedDoe100Form,
    hasUnsignedDoe150Form,
    hasSignedDoe150Form,
    hasSignedDoe200Form,
    hasDoe101Form,
    hasDoe151Form,
    anyFormsAvailable: hasUnsignedDoe100Form || hasSignedDoe100Form || hasUnsignedDoe150Form
        || hasSignedDoe150Form || hasSignedDoe200Form || hasDoe101Form || hasDoe151Form
});
