import * as indicatorTypes from "../../constants/indicatorTypes";
import * as teamMemberRoles from "../../constants/strategicPlan/teamMemberRoles";
import {generateDateWindowFormat} from "../common/commonUtilities";

function newPlan(dateWindowId) {
	return {
		planDateWindowId: "",
		planId: "",
		dateWindowId: dateWindowId,
		name: "",
		status: "Open",
		teamMembers: []
	};
}

function newStrategy(strategyId, planDateWindowId) {
	return {
		id: strategyId,
		planDateWindowId: planDateWindowId,
		goals: [],
		strategyIndicators: [],
		strategyActions: []
	};
}

function newStrategyIndicator(strategyId, id) {
	return {
		id: id,
		strategyId: strategyId,
		triangleFileId: null,
		indicators: [
			{
				indicatorType: indicatorTypes.BEESS,
				text: "",
				strategyIndicatorId: id
			}
		],
		baseline: null,
		metrics: []
	};
}

function newMetric(id, startYear = "") {
	return {
		id: id,
		baseline: "",
		actual: "",
		target: "",
		startYear: startYear,
		hasMetTarget: false,
		targetVerificationComment: ""
	};
}

function newIndicator(text, type, strategyIndicatorId) {
	return {
		text: text,
		indicatorType: type,
		strategyIndicatorId: strategyIndicatorId
	};
}

function newAction(strategyId, order) {
	return {
		id: "",
		strategyId: strategyId,
		order: order,
		responsibleParty: "",
		text: "",
		progressMonitoringNotes: "",
		resources: [],
		subActions: []
	};
}

function newTeamMember(id) {
	return {
		id: id,
		role: teamMemberRoles.TEAM_MEMBER.value,
		text: ""
	};
}

function newSubAction(strategyId, order) {
	return {
		id: "",
		order: order,
		text: "",
		responsibleParty: "",
		strategyId: strategyId,
		progressMonitoringNoteEntries: []
	};
}

function newProgressMonitoringActivity(id) {
	return {
		id: id,
		dateNoteCreated: "",
		text: ""
	};
}

function newResource() {
	return {
		id: "",
		text: ""
	};
}

function newGoal() {
	return {
		id: "",
		text: ""
	};
}

function formatMetricDateWindow(startYear) {
	if (!startYear) return "";
	return startYear + "-" + (parseInt(startYear) + 1).toString().substring(2, 4);
}

function generatePageTitle(dateWindows, dateWindowId, pageTitle) {
	const dateWindow = generateDateWindowFormat(dateWindows, dateWindowId);

	return  dateWindow ? pageTitle + " (" + dateWindow + ")" : pageTitle;
}

export {
	newPlan,
	newStrategy,
	newStrategyIndicator,
	newIndicator,
	newAction,
	newSubAction,
	newProgressMonitoringActivity,
	formatMetricDateWindow,
	newTeamMember,
	newMetric,
	newResource,
	newGoal,
	generatePageTitle
};