import React from 'react';
import PropTypes from 'prop-types';
import AuditBar from "../common/buttons/AuditBar";
import LocalStorage from "../shared/LocalStorage";

const SppElementContainer = ({children, response}) => {
	const isLoggedIn = LocalStorage.isLoggedIn();
	return (
		<>
			{
				response.audits.length > 0 &&
				isLoggedIn &&
				<AuditBar outcome={response.audits[0].outcomeString} elementId={response.elementId}/>
			}
			{children}
		</>
	);
};

SppElementContainer.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
	]).isRequired,
	response: PropTypes.object
};
SppElementContainer.defaultProps = {
	outcome: ""
};

export default SppElementContainer;