import React from 'react';
import PropTypes from 'prop-types';
import AuditBar from "../common/buttons/AuditBar";

const SppElementReviewOutcome = ({
                                     onOutcomeChange,
                                     policyResponseId,
                                     audits,
                                     children
                                 }) => {

    return (
        <>
            {audits.length > 0 &&
            <AuditBar
                outcome={audits[0].outcomeString}
                onOutcomeChange={onOutcomeChange}
                elementId={policyResponseId}
                isExpanded={true}
            />
            }
            {children}
        </>
    );
};

SppElementReviewOutcome.propTypes = {
    onOutcomeChange: PropTypes.func.isRequired,
    policyResponseId: PropTypes.string.isRequired,
    audits: PropTypes.array.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
    ]).isRequired
};

export default SppElementReviewOutcome;