import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";
import ClickableDiv from "../buttons/ClickableDiv";

const Tile = ({
                  header,
                  headerInputDescription,
                  body,
                  bodyInputDescription,
                  footer,
                  footerInputDescription,
                  onClick,
                  id,
                  isDisabled,
                  isLarge = true,
                  isMedium,
                  isNew,
                  children = null,
              }) => {

    const clickClass = onClick && !isDisabled ? " is-clickable" : "";
    let sizeClass = isMedium ? "medium " :
        isLarge ? "large " : "";
    const newClass = isNew ? " tileContainer__tile--new" : "";
    const handleClick = isDisabled ? null : onClick;

    return <>
        <ClickableDiv disabled={isDisabled} onClick={handleClick} id={id}>
            <div className={"tileContainer__tile tileContainer__tile--" + sizeClass + clickClass + newClass}>

                {children}

                {
                    (header && header !== "") &&
                    <>
                        <HtmlDisplayBlock className={"tileContainer__tile__header"} html={header}/>
                        {
                            headerInputDescription &&
                            <HtmlDisplayInline html={headerInputDescription} className={`show-for-sr`} />
                        }
                    </>
                }

                <HtmlDisplayBlock
                    className={"tileContainer__tile__content"}
                    html={body}
                />
                {
                    bodyInputDescription &&
                    <HtmlDisplayInline html={bodyInputDescription} className={`show-for-sr`} />
                }

                {
                    (footer && footer !== "") &&
                    <>
                        <HtmlDisplayBlock
                            className={"tileContainer__tile__footer"}
                            html={footer}
                        />
                        {
                            footerInputDescription &&
                            <HtmlDisplayInline html={footerInputDescription} className={`show-for-sr`} />
                        }
                    </>
                }
            </div>
        </ClickableDiv>
    </>;
};

Tile.propTypes = {
    header: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    headerInputDescription: PropTypes.string,
    body: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.element,
    ]),
    bodyInputDescription: PropTypes.string,
    footer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    footerInputDescription: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    isDisabled: PropTypes.bool,
    isLarge: PropTypes.bool,
    isMedium: PropTypes.bool,
    isNew: PropTypes.bool,
    children: PropTypes.element,
};

export default Tile;