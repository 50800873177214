import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import AccountUtility from "../../api/AccountUtility";
import Button from "../../components/common/buttons/Button";
import GridTable from "../../components/common/GridTable";
import {isArrayNullOrEmpty, isNullOrUndefined, isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import SelectField from "../../components/common/inputs/SelectField";
import {removeDomainForInternalRoute} from "../../components/common/routingUtilities";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import GridRow from "../../components/common/GridRow";
import {SortType} from "../../constants/sharedData/dynamicSorting";
import {TaskItemForListItem} from "../../components/tasks/TaskItem";
import {
    DisputeResolutionType,
    GetNewDisputeResolutionTypeDetailsIfHaveAccess,
    HasTeamMembers
} from "../drConstants";
import * as drLocations from "../drLocations";

export const DrCaseListSearchResults = ({
                                            allDistricts,
                                            disputeResolutionType,
                                            handleClickOrder,
                                            history,
                                            selectedDateWindowId,
                                            selectedInstitution,
                                            summary
                                        }) => {
    const [newInstitutionId, setNewInstitutionId] = useState("");
    const isDistrictUser = AccountUtility.isDistrictUser();
    const selectedDrType = GetNewDisputeResolutionTypeDetailsIfHaveAccess(disputeResolutionType);
    const readOnly = isDistrictUser || isTrimmedStringEmpty(selectedDrType);

    const handleClickDistrict = (event, dateWindowId, institutionId, id, url = "") => {
        event.preventDefault();
        event.stopPropagation();

        if (!url) {
            switch (disputeResolutionType) {
                case DisputeResolutionType.StateComplaints:
                    url = drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, institutionId, id);
                    break;
                case DisputeResolutionType.DueProcess:
                    url = drLocations.DUE_PROCESS_OVERVIEW.getUrl(dateWindowId, institutionId, id);
                    break;
                case DisputeResolutionType.Mediation:
                    url = drLocations.MEDIATION_OVERVIEW.getUrl(dateWindowId, institutionId, id);
                    break;
            }
        }

        if (url)
            history.push(removeDomainForInternalRoute(url));
    };

    useEffect(() => {
        if(!isNullOrUndefined(selectedInstitution) && !isArrayNullOrEmpty(allDistricts)) {
            const district = allDistricts.find(f => f.text === selectedInstitution);
            if(district)
                setNewInstitutionId(district.value);
        }
    }, [selectedInstitution]);

    const totalColumns = HasTeamMembers(disputeResolutionType) ? 6 : 5;
    const displayDateFiledOrDateReceived = disputeResolutionType === DisputeResolutionType.DueProcess ? "Date Received" : "Date Filed";

    return (
        <>
            {
                !readOnly &&
                <GridRow align={`right`}>
                    <SelectField
                        defaultOption={`Select a district`}
                        includeDefaultOption={true}
                        label={`New ${selectedDrType} for:`}
                        medium={5}
                        name="newInstitutionId"
                        onChange={(event) => setNewInstitutionId(event.target.value)}
                        options={allDistricts}
                        showLabel
                        value={newInstitutionId}
                        wrapperClass={`align-elements-right`}
                    />
                    <Button
                        btnClass={`align-elements-right`}
                        buttonType={ButtonTypes.ADD}
                        disabled={!newInstitutionId}
                        label={`Add ${selectedDrType}`}
                        name={`btnAddDrType`}
                        onClick={(event) => handleClickDistrict(event, selectedDateWindowId, newInstitutionId, emptyGuid)}
                        showLabel
                    />
                </GridRow>

            }
            <GridTable className={`case-list-table`}>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
                        District
                    </th>
                    <th onClick={handleClickOrder} data-id="title" className="is-clickable">
                        Case Number
                    </th>
                    <th onClick={handleClickOrder} data-id={`dateFiled`} data-sorttype={SortType.Date} className={`is-clickable`}>
                        {displayDateFiledOrDateReceived}
                    </th>
                    <th onClick={handleClickOrder} data-id="status" className="is-clickable">
                        Status
                    </th>
                    <th>
                        Tasks
                    </th>
                    {
                        HasTeamMembers(disputeResolutionType) &&
                        <th>
                            Team Members
                        </th>
                    }
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(summary) &&
                    <tr>
                        <td colSpan={totalColumns} className={"center"}>
                            No results match the search criteria specified.
                        </td>
                    </tr>
                }
                {
                    summary.map((district, key) =>
                        (<tr
                            key={key}
                            onClick={(event) => handleClickDistrict(event, selectedDateWindowId, district.districtId, district.id)}
                            className={"is-clickable"}
                        >
                            <td>{district.districtName}</td>
                            <td>{district.title}</td>
                            <td>{district.dateFiled}</td>
                            <td>{district.status}</td>
                            <td>
                                <ul>
                                    {
                                        district.tasks &&
                                        district.tasks.map((task, index) => {
                                            return <TaskItemForListItem
                                                key={index}
                                                task={task}
                                                viewRecord={(event) => handleClickDistrict(event, selectedDateWindowId, district.districtId, district.id, task.url)}
                                            />;
                                        })
                                    }
                                    {
                                        !district.tasks || district.tasks.length === 0 &&
                                        <li>None</li>
                                    }
                                </ul>
                            </td>
                            {
                                HasTeamMembers(disputeResolutionType) &&
                                <td>
                                    <ul>
                                        {
                                            district.teamMembers &&
                                            district.teamMembers.map((teamMember, index) => {
                                                return <li
                                                    key={index}>{teamMember.fullName} - {teamMember.roleDescription}</li>;
                                            })
                                        }
                                        {
                                            !district.teamMembers || district.teamMembers.length === 0 &&
                                            <li>None</li>
                                        }
                                    </ul>
                                </td>
                            }
                        </tr>)
                    )
                }
                </tbody>
            </GridTable>
        </>
    );
};

DrCaseListSearchResults.propTypes = {
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    disputeResolutionType: PropTypes.string.isRequired,
    handleClickOrder: PropTypes.func,
    history: PropTypes.object.isRequired,
    selectedDateWindowId: PropTypes.string.isRequired,
    selectedInstitution: PropTypes.string,
    summary: PropTypes.array
};