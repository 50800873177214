import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import PrintTable from "../../../components/common/PrintTable";
import api from "../drStateComplaintsApi";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const FederalReportPrintView = ({
                                    actions,
                                    dateWindowId,
                                    dateWindows
                                }) => {
    const [federalReport, setFederalReport] = useState(null);

    const handleUpdateFederalReport = (federalReport) => {
        setFederalReport(federalReport);
    };

    const getFederalReport = async () => {
        await actions.executeApi(api.getFederalReport, [dateWindowId])
            .then((results) => handleUpdateFederalReport(results));
    };

    useEffect(() => {
        getFederalReport();
    }, []);

    if (!federalReport)
        return null;

    return (
        <>
            <div className={"report-header"}>
                <h2>{fetchDate(dateWindows, dateWindowId)} State Complaints – IDEA Part B Dispute Resolution Report</h2>
                <h2>Statewide Report</h2>
            </div>
            <PrintTable className={"table-print-center add-padding"}>
                <thead>
                <tr>
                    <th>District</th>
                    <th>(1) Total number of written signed complaints filed</th>
                    <th>(1.1) Complaints with reports issued</th>
                    <th>(a) Reports with findings of noncompliance</th>
                    <th>(b) Reports within timeline</th>
                    <th>(c) Reports within extended timelines</th>
                    <th>(1.2) Complaints pending</th>
                    <th>(a) Complaints pending a due process hearing</th>
                    <th>(1.3) Complaints withdrawn or dismissed</th>
                </tr>
                </thead>
                <tbody>
                {
                    federalReport.map((district, index) => {
                        return (<tr key={index}>
                            <td>{district.districtName}</td>
                            <td className={"text-center"}>{district.totalComplaints}</td>
                            <td className={"text-center"}>{district.totalComplaintsWithReports}</td>
                            <td className={"text-center"}>{district.totalComplaintsWithNonCompliance}</td>
                            <td className={"text-center"}>{district.totalComplaintsWithinTimeline}</td>
                            <td className={"text-center"}>{district.totalComplaintsWithinExtendedTimeline}</td>
                            <td className={"text-center"}>{district.totalPendingComplaints}</td>
                            <td className={"text-center"}>{district.totalComplaintsInAbeyance}</td>
                            <td className={"text-center"}>{district.totalComplaintsWithdrawnDismissed}</td>
                        </tr>);
                    })
                }
                </tbody>
            </PrintTable>
        </>
    );
};

FederalReportPrintView.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired
};

export default FederalReportPrintView;