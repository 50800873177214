import * as ApiUtility from "./ApiUtility";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import * as config from "../constants/config";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerSppReviewApi {
    static AcceptElementResponse(policyResponseId, auditId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/audits/' + policyResponseId + '/accepted/' + auditId,
            RequestTypes.POST,
            JSON.stringify({
                policyResponseId: policyResponseId,
                auditId: auditId
            })));
    }

    static ElementResponseRevisionRequired(policyResponseId, auditId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/audits/' + policyResponseId + '/needsRevision/' + auditId,
            RequestTypes.POST,
            JSON.stringify({
                policyResponseId: policyResponseId,
                auditId: auditId
            })));
    }

    static ResetAudit(policyResponseId, auditId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/audits/' + policyResponseId + '/reset/' + auditId,
            RequestTypes.POST,
            JSON.stringify({
                policyResponseId: policyResponseId,
                auditId: auditId
            })));
    }

    static ResponseAuditComment(auditCommentViewModel, isCreate) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/audits/' + auditCommentViewModel.auditId + '/comments',
            isCreate ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(auditCommentViewModel ),
            ApiSuccessMessages.SPP_REVIEW_SAVE_COMMENT));
    }

    static GetPolicyCompliances(documentId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'Spp/PolicyCompliances/Document/' + documentId,
            RequestTypes.GET,
            { documentId: documentId }));
    }

    static PreApproveInstitutionDocument(policyComplianceId, fileId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "sppReview/policy-compliances/" + policyComplianceId + "/pre-approve",
            RequestTypes.POST,
            JSON.stringify({
                policyComplianceId: policyComplianceId,
                preApprovalFileId: fileId
            }),
            null,
            'application/json'));
    }

    static RemovePreApproval(policyComplianceId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/policy-compliances/' + policyComplianceId + '/pre-approve',
            RequestTypes.DELETE,
            JSON.stringify({ policyComplianceId: policyComplianceId }),
            ApiSuccessMessages.SPP_REVIEW_DELETE_PREAPPROVAL_DOCUMENT,
            'application/json'));
    }

	static ApproveInstitutionDocument(policyComplianceId, fileId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}sppReview/policy-compliances/${policyComplianceId}/approve`,
            RequestTypes.POST,
            JSON.stringify({
                policyComplianceId,
                approvalFileId: fileId
            }),
            null,
            'application/json'));
	}

    static RemoveApproval(policyComplianceId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/policy-compliances/' + policyComplianceId + '/approve',
            RequestTypes.DELETE,
            JSON.stringify({ policyComplianceId: policyComplianceId }),
            ApiSuccessMessages.SPP_REVIEW_DELETE_APPROVAL_DOCUMENT,
            'application/json'));
    }

    static CertifyInstitutionDocument(policyComplianceId, fileId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "sppReview/policy-compliances/" + policyComplianceId + "/certify",
            RequestTypes.POST,
            JSON.stringify({
                policyComplianceId: policyComplianceId,
                stateCertificationFileId: fileId
            }),
            null,
            'application/json'));
    }

    static RemoveInstitutionCertificationDocument(policyComplianceId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'sppReview/policy-compliances/' + policyComplianceId + '/certify',
            RequestTypes.DELETE,
            JSON.stringify({ policyComplianceId: policyComplianceId }),
            ApiSuccessMessages.SPP_REVIEW_DELETE_CERTIFICATION_DOCUMENT,
            'application/json'));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockSppReviewApi {
    static AcceptElementResponse() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static ElementResponseRevisionRequired() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();

            }, config.environment.delay);
        });
    }

    static ResetAudit() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static ResponseAuditComment() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static PreApproveInstitutionDocument(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetPolicyCompliances() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static RemovePreApproval() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

	static ApproveInstitutionDocument() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

    static RemoveApproval() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static CertifyInstitutionDocument() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static RemoveInstitutionCertificationDocument(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const SppReviewApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSppReviewApi : MockSppReviewApi;
export default SppReviewApi;
