import React from 'react';
import PropTypes from 'prop-types';

const ClickableDiv = ({
                          children,
                          className = "",
                          disabled = false,
                          id,
                          onClick,
                          title = "",
                      }) => {

    const handleOnClick = (event) => {
        if (!onClick) return;

        while (event.target.tagName.toLowerCase() !== "button")
            event.target = event.target.parentElement;

        onClick(event);
    };

    return (
        <button
            className={`button button--clickableDiv ${className}`}
            disabled={disabled || !onClick}
            id={id}
            name={id}
            onClick={handleOnClick}
            title={title}
            type={`button`}
        >
            {children}
        </button>
    );
};

ClickableDiv.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
};

export default ClickableDiv;