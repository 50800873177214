import React from 'react';
import PropTypes from 'prop-types';
import AbstractTextAreaEditor from './AbstractTextAreaEditor';

const TextAreaEditor = (props) => {
	return (
		<AbstractTextAreaEditor
			{...props}
		/>
	);
};

TextAreaEditor.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	getValue: PropTypes.func,
	height: PropTypes.number,
	includeAdvancedTools: PropTypes.bool,
	value: PropTypes.string,
	showLabel: PropTypes.bool,
	error: PropTypes.string,
	disabled: PropTypes.bool,
	elementEditBar: PropTypes.object,
	align: PropTypes.string,
	wrapperClass: PropTypes.string,
	labelClass: PropTypes.string,
	inputClass: PropTypes.string,
	helpText: PropTypes.string
};

export default TextAreaEditor;