import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isObjectNullOrEmpty} from "../../../../components/common/commonUtilities";
import {convertFormInputObjectToObject} from "../../../../components/common/inputs/inputUtility";
import gmsApi from "../../gmsApi";
import {createGmsSalary} from "../../gmsObjectFactory";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import GmsApplicationSalaryEditForm from "./GmsApplicationSalaryEditForm";

const GmsApplicationSalaryEditContainer = ({
                                               actions,
                                               applicationId,
                                               history,
                                               salaryId,
                                           }) => {
    const [summary, setSummary] = useState(null);
    const [salary, setSalary] = useState(createGmsSalary({}));
    const [updatedSalary, setUpdatedSalary] = useState(null);

    const loadApplicationSalaries = () => {
        actions.executeApi(gmsApi.getApplicationSalaries, [applicationId])
            .then((result) => {
                setSummary(result);
            });
    };

    const formCallback = (event, data) => {
        setUpdatedSalary(data);
    };

    const handleSave = async () => {
        const validatedForm = convertFormInputObjectToObject(updatedSalary);

        return await actions.executeApi(gmsApi.saveApplicationSalary, [applicationId, validatedForm]);
    };

    useEffect(() => {
        loadApplicationSalaries();
    }, []);

    useEffect(() => {

        if (isObjectNullOrEmpty(summary)) return;

        if (summary?.applicationTitle)
            actions.updatePageTitle(summary.applicationTitle);

        const verb = salaryId ? "Edit" : "Add";

        actions.updatePageSubTitle(`${verb} Salary Increase`);

        const editSalary = summary?.salaries.find(s => s.id === salaryId);

        if (editSalary)
            setSalary(editSalary);

        return actions.clearSubTitle;
    }, [summary]);

    return <>
        <GmsApplicationSalaryEditForm
            formCallback={formCallback}
            salary={salary}
        />

        <GmsApplicationNavigation
            applicationId={applicationId}
            canEditApplication={true}
            handleSave={handleSave}
            history={history}
            isDirty={true}
            salaryId={salaryId}
            sectionId={"Salaries"}
        />
    </>;
};


GmsApplicationSalaryEditContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    salaryId: PropTypes.string.isRequired,
};

export default GmsApplicationSalaryEditContainer;