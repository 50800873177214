import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

import TextField from '../common/inputs/TextField';
import Button from '../common/buttons/Button';
import * as publicLocations from "../../constants/publicLocations";

const ForgotPasswordForm = ({forgotPasswordViewModel, onClick, onChange, isLoading, errors}) => {
	return (
		<main id="main" role="main">
			<p>Submit the email address associated with your account below.<br/>
				You will receive an email with a link to update your password.</p>

			<form>
				<TextField
					name="email"
					label="Email Address"
					placeholder="Email Address..."
					value={forgotPasswordViewModel.email}
					onChange={onChange}
					inputClass="input-login"
					showLabel={false}
					error={(errors) ? errors.email : ""}
				/>

				<Button
					name="btnSubmit"
					label="Submit"
					onClick={onClick}
					btnClass="button--submit"
					disabled={isLoading}
				/>
				<div>
					<Link to={publicLocations.USER_SIGN_IN.path}>Return to login</Link>
				</div>
			</form>
		</main>
	);
};

ForgotPasswordForm.propTypes = {
	forgotPasswordViewModel: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	errors: PropTypes.object
};

export default ForgotPasswordForm;
