import React from 'react';
import PropTypes from 'prop-types';
import {ReadMore} from "../ReadMore";

const TableRowReadOnly = ({
                              label,
                              name,
                              value,
                          }) => {

    return <tr className={`align-top`}>
        <td className={`large-column`}>
            <label id={name}>{label}</label>
        </td>
        <td className={`large-column`}>
            <ReadMore ariaLabelledBy={name} text={value} hideBorderWhenNotUseTruncate />
        </td>
    </tr>;
};

TableRowReadOnly.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
};

export default TableRowReadOnly;