import * as ApiUtility from "./ApiUtility";
import * as config from "../constants/config";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

class ServerSurveyAdminApi {
    static getSurveyDateWindows() {
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "surveys/dateWindows",
                RequestTypes.GET);

            request.setCallbacks(function(data) {
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static getSurveyInstances(){
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "surveys/instances",
                RequestTypes.GET);

            request.setCallbacks(function(data) {
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static getSurveyInstance(surveyInstanceId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyInstanceId,
            RequestTypes.GET,
            { surveyId: surveyInstanceId }));
    }

    static saveSurveyDetails(details) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/",
            RequestTypes.PUT,
            JSON.stringify(details),
            ApiSuccessMessages.SURVEY_SAVE_DETAILS));
    }

    static saveElementOrder(surveyId, sortedElements) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/sort",
            RequestTypes.POST,
            JSON.stringify(sortedElements),
            ApiSuccessMessages.SURVEY_SAVE_ORDER,
            'application/json'));
    }

    static publishSurvey(surveyId, closeDate) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/publish",
            RequestTypes.PUT,
            { advertisedCloseDate: closeDate },
            ApiSuccessMessages.SURVEY_PUBLISHED,
            `application/x-www-form-urlencoded`));
    }

    static unpublishSurvey(surveyId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/unpublish",
            RequestTypes.POST,
            null,
            ApiSuccessMessages.SURVEY_UNPUBLISHED));
    }

    static remindRespondents(surveyId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/remind",
            RequestTypes.POST,
            null,
            ApiSuccessMessages.SURVEY_REMIND_RESPONDENTS));
    }

    static deleteRespondent(surveyRespondentId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/respondents/" + surveyRespondentId,
            RequestTypes.DELETE,
            null,
            ApiSuccessMessages.SURVEY_DELETE_RESPONDENT));
    }

    static unsubmitRespondentSurvey(surveyRespondentId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
                config.apiUrl + "surveys/respondents/" + surveyRespondentId + "/unsubmit",
                RequestTypes.PUT,
                null,
                ApiSuccessMessages.SURVEY_UNSUBMIT_RESPONDENT));
    }

    static getSurveyRespondents(surveyId) {
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "surveys/" + surveyId + "/respondents",
                RequestTypes.GET);

            request.setCallbacks(function(data) {
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static importSurveyRespondents(files, surveyId) {
        let formData = new FormData();
        formData.append('uploadedFile', files[0]);

        let request = AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/import",
            RequestTypes.POST,
            formData,
            ApiSuccessMessages.SURVEY_IMPORT_RESPONDENTS);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static downloadSampleRespondentImportFile() {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(config.apiUrl + "surveys/respondents/sample",
                RequestTypes.GET,
                null,
                ApiSuccessMessages.SAMPLE_RESPONDENT_IMPORT_FILE_DOWNLOADED));
    }

    static getSurveyElements(surveyId){
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "surveys/" + surveyId + "/elements",
                RequestTypes.GET);

            request.setCallbacks(function(data){
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static copySurvey(surveyId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/copy",
            RequestTypes.POST,
            ApiSuccessMessages.SURVEY_COPIED));
    }

    static deleteSurveyElement(surveyId, elementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/elements/" + elementId,
            RequestTypes.DELETE,
            null,
            ApiSuccessMessages.SURVEY_DELETE_ELEMENTS));
    }

	static addSurveyElements(surveyId, elementViewModels){
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/elements",
            RequestTypes.POST,
            JSON.stringify(elementViewModels),
            ApiSuccessMessages.SURVEY_ADD_ELEMENTS));
	}

    static updateSurveyElements(surveyId, elementViewModels){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/elements",
            RequestTypes.PUT,
            JSON.stringify(elementViewModels),
            ApiSuccessMessages.SURVEY_SAVE_ELEMENTS));
    }

    static cloneSurveyElement(surveyId, componentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/clone/" + componentId,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.SURVEY_CLONE_ELEMENT));
    }

    static closeSurvey(surveyId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "surveys/" + surveyId + "/close",
            RequestTypes.POST,
            null,
            ApiSuccessMessages.SURVEY_CLOSED));
    }

    static getReport(reportUrl){
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(reportUrl,
                RequestTypes.GET,
                null,
                ApiSuccessMessages.SURVEY_REPORT_DOWNLOADED));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
const surveys = [
    {
        surveyGroupId: 1,
        dateWindowId: 1,
        surveyId: 1,
        surveyTitle: "Cat Trainer's Survey"
    },
    {
        surveyGroupId: 2,
        surveyId: 2,
        dateWindowId: 2,
        surveyTitle: "Hurricane Survival Checklist"
    },
    {
        surveyGroupId: 3,
        surveyId: 3,
        dateWindowId: 3,
        surveyTitle: "The 'How's your day going?' Survey"
    },
];

const surveyDateWindows = [
    {
        id: 1,
        openDate: new Date(2016, 0, 1),
        closeDate: new Date(2016, 11, 31)
    },
    {
        id: 2,
        openDate: new Date(2017, 0, 1),
        closeDate: new Date(2017, 11, 31)
    },
    {
        id: 3,
        openDate: new Date(2018, 0, 1),
        closeDate: new Date(2018, 11, 31)
    },
];

const surveyRespondents = [
    {
        id: 1,
        email: "email@email.com",
        institution: "Alachua"
    },
    {
        id: 2,
        email: "email1@email.com",
        institution: "Brevard"
    },
    {
        id: 3,
        email: "email2@email.com",
        institution: "Broward"
    },
    {
        id: 4,
        email: "email3@email.com",
        institution: "Calhoun"
    },
    {
        id: 5,
        email: "email4@email.com",
        institution: "Zenyatta"
    }
];

class MockSurveyAdminApi {
    static getSurveys() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...surveys]);
            }, config.environment.delay);
        });
    }

    static getSurveyDateWindows() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...surveyDateWindows]);
            }, config.environment.delay);
        });
    }

    static getSurveyInstance(surveyId) {
        return new Promise(resolve => {
            const instance = surveys.filter(s => s.surveyId === surveyId);

            setTimeout(() => {
                resolve(instance);
            }, config.environment.delay);
        });
    }

    static saveSurveyDetails() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveElementOrder() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static publishSurvey() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static unpublishSurvey() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static closeSurvey() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static cloneSurveyElement() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static remindRespondents() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteRespondent() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static unsubmitRespondentSurvey() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getSurveyRespondents() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(surveyRespondents);
            }, config.environment.delay);
        });
    }

    static importSurveyRespondents() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static downloadSampleRespondentImportFile() {
        return new Promise( resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static copySurvey() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getSurveyElements(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteSurveyElement(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static updateSurveyElements() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getReport(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const SurveyAdminApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSurveyAdminApi : MockSurveyAdminApi;
export default SurveyAdminApi;