import React from "react";
import PropTypes from "prop-types";
import {DueProcessPolicy, policyEvents} from "../../../components/authorization/policies/DueProcessPolicy";
import AuditBar from "../../../components/common/buttons/AuditBar";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import * as ActionBarPositions from "../../../constants/ActionBarPositions";
import * as apiParameters from "../../../constants/api/apiParameters";
import audit_outcomes from "../../../constants/auditOutcomes";
import {emptyGuid} from "../../../constants/config";
import api from "../drDueProcessApi";
import * as DrDueProcessObjectFactory from "../drDueProcessObjectFactory";
import {DrDueProcessResolutionProcessAuditView} from "./DrDueProcessResolutionProcessAuditView";
import DrDueProcessResolutionProcessView from "./DrDueProcessResolutionProcessView";
import SectionHeader from "../../../components/common/SectionHeader";

const DrDueProcessResolutionProcessContainer = ({
                                                    canAudit,
                                                    canSubmit,
                                                    dueProcess,
                                                    executeApi,
                                                    handleDataChanged,
                                                    handleSelectSection,
                                                    handleUpdateDueProcess,
                                                    history,
                                                    isDistrictUser
                                                }) => {

    const handleSaveResolutionProcess = async (form, shouldSubmitReport) => {
        if (!shouldSubmitReport || confirm("By submitting the report, you will no longer be able to make any changes.\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
            form.shouldSubmitReport = shouldSubmitReport;
            return await executeApi(api.saveResolutionProcess, [dueProcess.id, form])
                .then((result) => {
                    handleUpdateDueProcess(result);
                    return true;
                })
                .catch (() => {
                    return false;
                });
        }
    };

    const saveAuditCommentAsync = async (comment, existing = false) => {
        return await executeApi(api.saveResolutionProcessAuditComment, [dueProcess.id, comment, existing])
            .then((result) => {
                handleUpdateDueProcess(result);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleSaveAuditCommentAsync = async (comment) => {
        if (comment.auditCommentId === "") {
            const auditCommentId = emptyGuid;
            comment = Object.assign({}, comment, {auditCommentId});

            return await saveAuditCommentAsync(comment);
        } else {
            return await saveAuditCommentAsync(comment, true);
        }
    };

    const handleAuditResolutionProcessAsync = async (auditAction) => {
        return await executeApi(api.auditResolutionProcess, [dueProcess.id, auditAction])
            .then((result) => {
                handleUpdateDueProcess(result);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleChangeOutcome = canAudit && !isDistrictUser
        ? async (event) => {
            event.preventDefault();
            const auditOutcome = event.target.id.split("_")[0];

            switch (auditOutcome) {
                case audit_outcomes.ACCEPTED.name:
                    await handleAuditResolutionProcessAsync(apiParameters.audit.APPROVE);
                    break;
                case audit_outcomes.NEEDS_REVISION.name:
                    await handleAuditResolutionProcessAsync(apiParameters.audit.REVISE);
                    break;
                case audit_outcomes.RESET.name:
                    await handleAuditResolutionProcessAsync(apiParameters.audit.RESET);
                    break;
            }
        }
        : undefined;

    if (!dueProcess) return null;

    const resolutionProcess = DrDueProcessObjectFactory.createResolutionProcessObject(dueProcess);
    const readOnly = !canSubmit || !isDistrictUser || !resolutionProcess.isReadyToSubmit;

    return (
        <>
            {
                !isArrayNullOrEmpty(resolutionProcess.resolutionProcessAudits) &&
                <AuditBar
                    elementId={`resolutionProcessAudit`}
                    onOutcomeChange={handleChangeOutcome}
                    outcome={resolutionProcess.currentStatus}
                    policy={DueProcessPolicy}
                    policyEvent={policyEvents.dueProcess.audits.AUDIT}
                    position={ActionBarPositions.TOP_RIGHT}
                />
            }
            <SectionHeader>
                <h2>Resolution Process Status Report</h2>
            </SectionHeader>

            <DrDueProcessResolutionProcessAuditView
                audits={resolutionProcess.resolutionProcessAudits}
                canAudit={canAudit}
                handleDataChanged={handleDataChanged}
                handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
                isDistrictUser={isDistrictUser}
            />

            <DrDueProcessResolutionProcessView
                dueProcess={dueProcess}
                handleDataChanged={handleDataChanged}
                handleSaveResolutionProcess={handleSaveResolutionProcess}
                handleSelectSection={handleSelectSection}
                history={history}
                isDistrictUser={isDistrictUser}
                readOnly={readOnly}
                resolutionProcess={resolutionProcess}
            />
        </>
    );
};

DrDueProcessResolutionProcessContainer.propTypes = {
    canAudit: PropTypes.bool.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    dueProcess: PropTypes.object.isRequired,
    executeApi: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateDueProcess: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isDistrictUser: PropTypes.bool.isRequired
};

export default DrDueProcessResolutionProcessContainer;
