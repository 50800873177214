import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as LayoutActions from "../../actions/layoutActions";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import {ExternalLinkButton} from "../../components/common/ExternalLink";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as drLocations from "../drLocations";
import {createComplaintObject} from "./drStateComplaintObjectFactory";
import api from "./drStateComplaintsApi";
import DrStateComplaintResponseContainer from "./Responses/DrStateComplaintResponseContainer";
import districtGuide from "../../documents/Entering Corrective Action Data into the GSW - District Guide.pdf";

const DrStateComplaintDistrictWrapper = ({
	                                         actions,
	                                         complaintId,
	                                         history,
	                                         institutionId,
                                         }) => {
	const [complaint, setComplaint] = useState(null);
	const [returnUrl, setReturnUrl] = useState("/");

	const getAndSaveComplaint = () => {
		api.getComplaint(complaintId)
			.then((result) => handleUpdateComplaint(result));
	};

	const handleUpdateComplaint = (complaint) => setComplaint(createComplaintObject(complaint));

	const updateComplaint = () => {
		if (complaintId && complaintId !== drLocations.COMPLAINT_ID_NEW)
			getAndSaveComplaint();
		else
			handleUpdateComplaint();
	};
	useEffect(updateComplaint, [complaintId]);

	const updateReturnUrl = () => {
		if (institutionId)
			setReturnUrl(drLocations.LANDING_DISTRICT.getUrl(institutionId));
	};
	useEffect(updateReturnUrl, [institutionId]);

	const updatePageTitle = () => {
		if (complaint)
			actions.updatePageTitle(`Complaint Case No ${complaint.complaintNumber}`);
	};
	useEffect(() => updatePageTitle(), [complaint]);

	const handleReturn = () => history.push(returnUrl);

	if (!complaint) return null;

	return (
		<>
			<ButtonBar>
				<Button name={"btnBack"}
				        label={"District Landing"}
				        onClick={handleReturn}
				        buttonType={ButtonTypes.BACK}
				/>

				<ExternalLinkButton url={districtGuide} label={`District Guide to Entering Corrective Actions`} />

			</ButtonBar>
			<DrStateComplaintResponseContainer
				actions={actions}
				complaint={complaint}
				handleUpdateComplaint={handleUpdateComplaint}
			/>
		</>
	);
};

const mapStateToProps = (state, props) => {
	const currentLocation = props.location.pathname;
	const params = drLocations.STATE_COMPLAINT_DISTRICT.getParams(currentLocation);

	return {
		...params,
	};
};

const mapDispatchToProps = dispatch => {
	const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
};

DrStateComplaintDistrictWrapper.propTypes = {
	actions: PropTypes.object,
	complaintId: PropTypes.string,
	history: PropTypes.object,
	institutionId: PropTypes.string
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DrStateComplaintDistrictWrapper);