import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {fileUploadTypes} from "../../../constants/inputConstants";

const FileUploadButton = ({
                              className = `input-theme`,
                              disabled,
                              fileUploadType,
                              label = `File Upload`,
                              name,
                              onChange,
                          }) => {

    let accept = "";
    switch (fileUploadType) {
        case fileUploadTypes.EXCEL:
            accept = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel`;
            break;
    }

    return (
        <span className={`buttonWrapper ${className}`}>
            <input
                accept={accept}
                disabled={disabled}
                id={name}
                name={name}
                onChange={onChange}
                type="file"
            />
            <label htmlFor={name} className={"button " + ButtonTypes.UPLOAD + (disabled ? " is-disabled" : "")}>
	            <span>{label}</span>
            </label>
        </span>
    );
};

FileUploadButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fileUploadType: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default FileUploadButton;