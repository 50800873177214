import React from "react";
import PropTypes from "prop-types";
import TextArea from "../common/inputs/TextArea";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import DateTimePickerField from "../common/inputs/DateTimePickerField";

const ProgressMonitoringActivityForm = ({
	                                        activity,
	                                        onChangeActivity,
											onDateChange,
	                                        onSaveActivity,
	                                        onCancel,
	                                        isLoading
                                        }) => {
	return (
		<div
			className={"strategy__progressActivity strategy__progressActivity--editing "}
			id={activity.id}>
			<DateTimePickerField name={"dateNoteCreated"}
								 label={"Date"}
								 onChange={(event, strategyId, actionId, subActionId, activityId) => onDateChange(event, strategyId, actionId, subActionId, activityId)}
								 value={activity.dateNoteCreated}
								 showLabel />

			<TextArea name={"text"}
			          label={"Activity"}
			          value={activity.text}
			          onChange={(event, strategyId, actionId, subActionId, activityId) => onChangeActivity(event, strategyId, actionId, subActionId, activityId)}
			          showLabel/>

			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name={"save"}
				        label={"Save"}
				        buttonType={ButtonTypes.SAVE}
				        onClick={onSaveActivity}
				        disabled={isLoading}
				/>
				<Button name={"cancel"}
				        onClick={onCancel}
				        buttonType={ButtonTypes.CANCEL}
				        label={"Cancel"}
				        disabled={isLoading}/>
			</ButtonBar>
		</div>
	);
};

ProgressMonitoringActivityForm.propTypes = {
	activity: PropTypes.object.isRequired,
	onChangeActivity: PropTypes.func.isRequired,
	onDateChange: PropTypes.func,
	onSaveActivity: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default ProgressMonitoringActivityForm;