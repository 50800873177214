import PropTypes from "prop-types";
import React from 'react';
import {isNullOrUndefined, limitTextLength} from "../../../../components/common/commonUtilities";
import GridColumn from "../../../../components/common/GridColumn";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import FileViewDelete from "../../../../components/common/inputs/FileViewDelete";
import {clearUpload} from "../../../../components/common/inputs/inputUtility";
import {gridConstants} from "../../../../constants/inputConstants";
import {createSaveRoiObject} from "../../ptsObjectFactory";

export const PtsRoiQuarter = ({
                                  handleFileDelete,
                                  handleFileDownload,
                                  handleFileUpload,
                                  isModifyDocumentationDisabled,
                                  quarter,
                                  requiresReturnOnInvestmentReport = false,
                                  returnOnInvestmentFiles,
                              }) => {
    const handleClickUploadRoi = async (event) => {
        event.preventDefault();
        const files = event.target.files;

        const currentFileDetails = returnOnInvestmentFiles.map((file) => createSaveRoiObject(file.roiDocumentationFileId, file.discretionaryProjectQuarter));
        await handleFileUpload(files, currentFileDetails, quarter.value);
    };

    const handleClickDownloadRoi = (event) => {
        event.preventDefault();
        const fileId = event.target.id;
        handleFileDownload(fileId);
    };

    const handleClickDeleteRoi = async (event) => {
        event.preventDefault();
        if (!confirm("Are you sure you want to delete this file?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
            return;

        const fileId = event.target.id;
        const updatedFiles = returnOnInvestmentFiles.filter((file) => file.roiDocumentationFileId !== fileId);
        const updatedFileDetails = updatedFiles.map((file) => createSaveRoiObject(file.roiDocumentationFileId, file.discretionaryProjectQuarter));
        await handleFileDelete(fileId, updatedFileDetails);
    };

    const roiFile = returnOnInvestmentFiles.find(f => f.discretionaryProjectQuarter === quarter.value);
    const fileViewDeleteWrapperClass = isModifyDocumentationDisabled
        ? `roiDocumentationFiles__fileDownload--disabled`
        : `roiDocumentationFiles__fileDownload`;

    if(!requiresReturnOnInvestmentReport)
        return null;

    return <>
        <p><strong>ROI Documentation</strong></p>
        {
            isNullOrUndefined(roiFile) &&
            <>
                {
                    !isModifyDocumentationDisabled &&
                    <FileUploadButton
                        name={`btnUploadRoi_${quarter.value}`}
                        label={"Upload Document"}
                        onChange={handleClickUploadRoi}
                    />
                }
                <p>No ROI documentation has been uploaded for this quarter.</p>
            </>
        }
        {
            !isNullOrUndefined(roiFile) &&
            <GridColumn medium={gridConstants.column.THREE} large={gridConstants.column.THREE}>
                <FileViewDelete
                    name={roiFile.roiDocumentationFileId}
                    label={limitTextLength(roiFile.displayName, 15)}
                    wrapperClass={fileViewDeleteWrapperClass}
                    onView={handleClickDownloadRoi}
                    onDelete={isModifyDocumentationDisabled ? null : handleClickDeleteRoi}
                />
            </GridColumn>
        }
    </>;
};


PtsRoiQuarter.propTypes = {
    handleFileDelete: PropTypes.func.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    handleFileUpload: PropTypes.func.isRequired,
    isModifyDocumentationDisabled: PropTypes.bool,
    quarter: PropTypes.object,
    requiresReturnOnInvestmentReport: PropTypes.bool,
    returnOnInvestmentFiles: PropTypes.array.isRequired,
};