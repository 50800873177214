import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import GridTable from "../../../../components/common/GridTable";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {createEditRowObject, createSchoolAdmin} from "../../School/bpieSchoolAdminFactory";
import {bpieTeamRoles} from "../../bpieConstants";
import BpieDistrictSummaryRowView from "./BpieDistrictSummaryRowView";
import BpieDistrictSummaryRowEdit from "./BpieDistrictSummaryRowEdit";

const BpieDistrictSummaryView = ({
                                     actions,
                                     handleClickOrder,
                                     handleReloadSchoolAdministrator,
                                     handleToggleActivate,
                                     handleToggleSelectAll,
                                     isDisabled,
                                     onActivateSelectedSbpies,
                                     pendingSbpies,
                                     selectedPendingSbpies,
                                     setIsDisabled
                                 }) => {

    const [editRow, setEditRow] = useState(createEditRowObject);
    const [isReplacement, setIsReplacement] = useState(false);

    if (!pendingSbpies)
        return null;

    const toggleSelectAllLabel = pendingSbpies.length === selectedPendingSbpies.length
        ? "Deselect All"
        : "Select All";

    const toggleButtonType = pendingSbpies.length === selectedPendingSbpies.length
        ? ButtonTypes.CHECKED
        : ButtonTypes.UNCHECKED;

    const onEditClick = (event, schoolBpieId, isReplacement) => {
        event.preventDefault();
        setIsReplacement(isReplacement);
        setEditRow(createEditRowObject(schoolBpieId, true));
        setIsDisabled(true);
    }

    const createSchoolAdminFromBpie = (schoolBpieId) => {
        const schoolAdmin = findSchoolAdmin(schoolBpieId);
        return createSchoolAdmin({
            email: schoolAdmin.email,
            firstName: schoolAdmin.firstName,
            lastName: schoolAdmin.lastName,
            isReplacement: false
        });
    }

    const findSchoolAdmin = (schoolBpieId) => {
        const bpie = pendingSbpies.find(bpie => bpie.schoolBpieId === schoolBpieId);
        return bpie.schoolBpieTeam.find(admin => admin.role === bpieTeamRoles.schoolAdministrator);
    }

    const handleCancelClick = () => {
        setEditRow(createEditRowObject);
        setIsDisabled(false);
    }

    const handleReloadSchoolAdministratorInSummaryView = () => {
        setEditRow(createEditRowObject);
        setIsDisabled(false);
        handleReloadSchoolAdministrator();
    }

    return (
        <>
            <h2>Schools with Pending SBPIE Assessments</h2>
            {
                pendingSbpies.length === 0 &&
                <p>None currently</p>
            }
            {
                pendingSbpies.length > 0 &&
                <>
                    <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                        <p>Only rows selected will be activated. Select or unselect all using the checkbox in the header row
                        of the table.</p>
                    </Allow>
                    <GridTable className={`districtSummary`}>
                        <thead>
                        <tr>
                            <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                                <th className={`text-center`}>
                                    <Button
                                        btnClass={`districtSummary__toggleButton`}
                                        buttonType={toggleButtonType}
                                        label={toggleSelectAllLabel}
                                        name={`btnToggleSelectAll`}
                                        onClick={handleToggleSelectAll}
                                        showLabel={false}
                                        disabled={isDisabled}
                                    />
                                </th>
                            </Allow>
                            <th onClick={handleClickOrder} data-id="schoolName" className="is-clickable">School Name
                            </th>
                            <th onClick={handleClickOrder} data-id="previousBpieDatesCovered"
                                className="is-clickable">Last SBPIE
                            </th>
                            <th onClick={handleClickOrder} data-id="schoolAdminFirstName"
                                className="is-clickable">Principal Name
                            </th>
                            <th onClick={handleClickOrder} data-id="schoolAdminEmail" className="is-clickable">Principal
                                Email
                            </th>
                            <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.MANAGE_ADMINISTRATOR}>
                                <th>Actions</th>
                            </Allow>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            pendingSbpies.map((bpie) => {
                                const isSelected = selectedPendingSbpies.some(f => f === bpie.msidNumber);
                                const isEditSchoolBpie = editRow.id === bpie.schoolBpieId;
                                return (
                                    <tr key={bpie.schoolBpieId}>
                                        {isEditSchoolBpie ?
                                            <BpieDistrictSummaryRowEdit
                                                actions={actions}
                                                bpie={bpie}
                                                createSchoolAdminFromBpie={createSchoolAdminFromBpie}
                                                handleCancelClick={handleCancelClick}
                                                isReplacement={isReplacement}
                                                handleReloadSchoolAdministrator={handleReloadSchoolAdministratorInSummaryView}
                                            />
                                            :
                                            <BpieDistrictSummaryRowView
                                                bpie={bpie}
                                                createSchoolAdminFromBpie={createSchoolAdminFromBpie}
                                                disabled={isDisabled}
                                                handleToggleActivate={handleToggleActivate}
                                                isSelected={isSelected}
                                                onEditClick={onEditClick}
                                            />
                                        }
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                    <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                            <Button
                                disabled={selectedPendingSbpies.length === 0 || isDisabled}
                                name={`btnActivateSbpies`}
                                onClick={onActivateSelectedSbpies}
                                label={`Activate ${selectedPendingSbpies.length} Selected SBPIE Assessments`}
                                buttonType={ButtonTypes.EMAIL}
                                showLabel
                            />
                        </ButtonBar>
                    </Allow>
                </>
            }
        </>
    );
}

BpieDistrictSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    handleClickOrder: PropTypes.func.isRequired,
    handleReloadSchoolAdministrator: PropTypes.func.isRequired,
    handleToggleActivate: PropTypes.func.isRequired,
    handleToggleSelectAll: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onActivateSelectedSbpies: PropTypes.func.isRequired,
    pendingSbpies: PropTypes.array,
    selectedPendingSbpies: PropTypes.array.isRequired,
    setIsDisabled: PropTypes.func.isRequired
};

export default BpieDistrictSummaryView;