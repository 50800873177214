import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import GridTable from "../../../../components/common/GridTable";
import bpieDistrictApi from "../bpieDistrictApi";

export const BpieDistrictNotApplicableView = ({
                                                  actions,
                                              }) => {

    const [notApplicableSbpies, setNotApplicableSbpies] = useState(null);

    const loadNotApplicableSbpies = async () => {
        await actions.executeApi(bpieDistrictApi.getNotApplicableSbpies, [])
            .then(setNotApplicableSbpies);
    }

    const notificationTrigger = <CollapsibleTrigger
        text={`School SBPIEs Not Applicable`}/>;

    useEffect(() => {
        loadNotApplicableSbpies();
    }, []);

    if (!notApplicableSbpies || notApplicableSbpies.length === 0)
        return null;

    return (
        <>
            <FlexSpacer/>

            <CollapsibleContainer
                id={`sbpie-notApplicable-container`}
                trigger={notificationTrigger}
            >
                <p>This applies to any school that does not meet the criterion of completing the school BPIE assessments.
                    For any questions, please contact your FIN Facilitator.</p>
                <GridTable>
                    <thead>
                    <tr>
                        <th>School Name</th>
                        <th>Last SBPIE</th>
                        <th>Principal Name</th>
                        <th>Principal Email</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        notApplicableSbpies.map((notApplicableSbpie, key) => {
                            return (
                                <tr key={key}>
                                    <td>{notApplicableSbpie.schoolName} ({notApplicableSbpie.msidNumber})</td>
                                    <td>{notApplicableSbpie.previousBpieDatesCovered}</td>
                                    <td>{notApplicableSbpie.schoolAdminFirstName} {notApplicableSbpie.schoolAdminLastName}</td>
                                    <td>{notApplicableSbpie.schoolAdminEmail}</td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </GridTable>
            </CollapsibleContainer>
        </>
    );
}

BpieDistrictNotApplicableView.propTypes = {
    actions: PropTypes.object.isRequired
};