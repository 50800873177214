import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import {openInNewTab} from "../../../../components/common/HtmlUtilities";
import gmsApi from "../../gmsApi";
import * as gmsLocations from "../../gmsLocations";
import {createReportStatusesObject} from "./gmsApplicationPrintSetupObjectFactory";
import {GmsApplicationPrintSetupView} from "./GmsApplicationPrintSetupView";
import {gridConstants} from "../../../../constants/inputConstants";
import {emptyGuid} from "../../../../constants/config";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {GmsApplicationHistorySelectField} from '../GmsApplicationHistorySelectField';

const GmsApplicationPrintSetupContainer = ({
                                               actions,
                                               applicationHistories,
                                               applicationId,
                                               currentUserFiscalAgentId,
                                               history,
                                               isFiscalAgentUser,
                                               selectedApplicationHistory,
                                               setSelectedApplicationHistory
                                           }) => {
    const [application, setApplication] = useState(null);
    const [reportStatuses, setReportStatuses] = useState(createReportStatusesObject());

    const handlePrintApplication = (selectedSections) => {
        var resolvedPath;
        if (selectedApplicationHistory && selectedApplicationHistory !== emptyGuid) {
            resolvedPath = gmsLocations.GMS_APPLICATION_HISTORY_PRINT.getUrl(applicationId, selectedSections.join(`|`), selectedApplicationHistory);
        } else {
            resolvedPath = gmsLocations.GMS_APPLICATION_PRINT.getUrl(applicationId, selectedSections.join(`|`));
        }
        openInNewTab(resolvedPath);
    };

    const redirectToSummary = () => {
        const resolvedPath = gmsLocations.GMS_APPLICATION.getUrl(applicationId);
        history.push(resolvedPath);
    }

    useEffect(() => {
        const initializeData = async () => {
            await actions.executeApi(gmsApi.getApplication, [applicationId])
                .then((result) => {
                    const isFiscalAgentUserViewingIncorrectFiscalAgent = isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId.toLowerCase();
                    if (!result.canViewApplication || isFiscalAgentUserViewingIncorrectFiscalAgent) {
                        const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                        history.push(resolvedPath);
                        return;
                    }

                    setApplication(result);
                });
        };

        initializeData();

    }, []);

    useEffect(() => {
        if (application && application.applicationTitle)
            actions.updatePageTitle(application.applicationTitle);
    }, [application]);

    useEffect(() => {
        if (isNullOrUndefined(application)) return;

        const history = applicationHistories?.find(x => x.id === selectedApplicationHistory);

        if (!isNullOrUndefined(history)) {
            setReportStatuses(createReportStatusesObject(history.reportStatuses))
        }
        else {
            setReportStatuses(createReportStatusesObject(application.reportStatuses));
        }
    }, [selectedApplicationHistory, applicationHistories, application])

    if (!application)
        return null;

    const submissionHistoryDirections = applicationHistories.length > 0 ? ` the submission history and ` : ``;

    return (
        <>
            <p>Select {submissionHistoryDirections} which sections you would like to print and then click &quot;Print
                Selected Sections&quot;.
                Close the window afterwards to return to this page.</p>
            {
                applicationHistories.length > 0 &&
                <GridRow rowClass="applicationHistoryBar" medium={gridConstants.column.TWO}>
                    <GridColumn>
                        <GmsApplicationHistorySelectField
                            applicationHistories={applicationHistories}
                            includeCurrent
                            includeDefaultOption={false}
                            selectedApplicationHistory={selectedApplicationHistory}
                            setSelectedApplicationHistory={setSelectedApplicationHistory}
                        />
                    </GridColumn>
                </GridRow>
            }

            <GmsApplicationPrintSetupView
                actions={actions}
                application={application}
                handleReturnToSummary={redirectToSummary}
                handlePrintApplication={handlePrintApplication}
                reportStatuses={reportStatuses}
                selectedApplicationHistory={selectedApplicationHistory}
            />
        </>
    );
};


GmsApplicationPrintSetupContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired
};

export default GmsApplicationPrintSetupContainer;