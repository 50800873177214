import AjaxRequest from './requests/AjaxRequestWithRetry';
import { ToastrNotificationFactory } from './notifications/ToastrNotificationFactory';
import LocalStorage from '../components/shared/LocalStorage';
import * as config from '../constants/config';
import AjaxBlobRequest from './requests/AjaxBlobRequest';
import {serialize} from "object-to-formdata";

const notificationFactory = new ToastrNotificationFactory();
const tokenUrl = config.apiUrl + "accounts/tokens/refresh";

function validateOptions(ajaxRequestOptions) {
    if (!ajaxRequestOptions)
        throw new Error('Request options are required to configure the http request.');
}
// API bridge
export function sendRequest(ajaxRequestOptions) {
    validateOptions(ajaxRequestOptions);

    return AjaxRequest(LocalStorage, notificationFactory, config.apiMaxRetryCount, tokenUrl)
    .send(ajaxRequestOptions);
}

export function downloadBlob(ajaxRequestOptions) {
    validateOptions(ajaxRequestOptions);

    return AjaxBlobRequest(LocalStorage, notificationFactory, config.apiMaxRetryCount, tokenUrl).send(ajaxRequestOptions);
}

export function convertObjectToFormData(obj) {
    return serialize(obj, {indices: true});
}

function mockApiCall(message, resolveResult = undefined) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (resolveResult) {
                if (message) {
                    notificationFactory.createSuccess(message).notify();
                }
                resolve(resolveResult);
            }
            else {
                const errorString = message ? message : "No result given to test.";
                notificationFactory.createErrorsFromXhrObject(errorString).notify();
                reject(errorString);
            }

        }, config.environment.delay);
    });
}

export function mockResolveApiCall(result, successMessage) {
    return mockApiCall(successMessage, result);
}

export function mockRejectApiCall(errorMessage) {
    return mockApiCall(errorMessage);
}
