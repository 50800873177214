import React from "react";
import PropTypes from "prop-types";
import RadioField from "../../../components/common/inputs/RadioField";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import {inputStyles} from "../../../constants/inputConstants";
import {
    IntakeRequirementType,
    IsIntakeRequirementMetList,
    IsIntakeRequirementMetWithNotApplicableList
} from "../drStateComplaintConstants";

const DrStateComplaintIntakeResponseView = ({
                                                handleIntakeChange,
                                                intakeResponse,
                                                parentIsYes,
                                                readOnly,
                                                requirementDescription,
                                                requirementType
                                            }) => {

    const options = !parentIsYes && requirementType === IntakeRequirementType.ConditionalOptional
        || requirementType === IntakeRequirementType.Optional
        ? IsIntakeRequirementMetWithNotApplicableList
        : IsIntakeRequirementMetList;

    return (
        <>
            <div>
                <HtmlDisplayBlock html={requirementDescription} />
            </div>
            {
                requirementType !== IntakeRequirementType.Informational &&
                intakeResponse &&
                <RadioField
                    direction={inputStyles.radio.direction.HORIZONTAL}
                    disabled={readOnly}
                    legend={`Met Requirements on requirement ${intakeResponse.requirementNumber}`}
                    name={`isRequirementMet_${intakeResponse.requirementNumber}`}
                    onChange={(event) => handleIntakeChange(intakeResponse.requirementId, intakeResponse.responseId, "isRequirementMet", event)}
                    options={options}
                    showLegend={false}
                    value={intakeResponse.isRequirementMet}
                    wrapperClass={`met-requirements`}
                />
            }
        </>
    );
};

DrStateComplaintIntakeResponseView.propTypes = {
    handleIntakeChange: PropTypes.func.isRequired,
    intakeResponse: PropTypes.object,
    parentIsYes: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    requirementDescription: PropTypes.string.isRequired,
    requirementType: PropTypes.number.isRequired,
};

export default DrStateComplaintIntakeResponseView;