import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonMock from "../../../components/common/buttons/ButtonMock";
import DisplayField from "../../../components/common/inputs/DisplayField";
import GridTable from "../../../components/common/GridTable";
import RadioField from "../../../components/common/inputs/RadioField";
import SelectField from "../../../components/common/inputs/SelectField";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import Tile from "../../../components/common/Tiles/Tile";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {createFakeEvent, createYesNoList} from "../../../components/common/inputs/inputUtility";
import {createStudentObject} from "../drStateComplaintObjectFactory";
import {getRandomInt} from "../../../components/common/HtmlUtilities";
import {isArrayNullOrEmpty, isInSelectList} from "../../../components/common/commonUtilities";
import {inputStyles} from '../../../constants/inputConstants';
import {styles as ActionButton} from "../../../constants/ActionButton";

const DrStateComplaintStudentEdit = ({
	                                     onChange: setStudents,
	                                     value: students,
	                                     studentExceptionalities
                                     }) => {
	const handleClickAddStudent = () => {
		let updatedStudents = [...students];
		const id = `${getRandomInt()}`;
		updatedStudents.push(createStudentObject({id}));
		handleSetStudents(updatedStudents);
	};

	const handleClickRemoveStudent = (event) => {
		let updatedStudents = [...students];
		const id = event.target.id;
		updatedStudents = updatedStudents.filter(s => s.id !== id);
		handleSetStudents(updatedStudents);
	};

	const handleChangeStudent = (event, id) => {
		let updatedStudents = [...students];
		let student = updatedStudents.filter(s => s.id === id)[0];
		const field = event.target.name;
		student[field] = event.target.value;
		handleSetStudents(updatedStudents);
	};

	const handleSetStudents = (updatedStudents) => {
		setStudents(createFakeEvent(updatedStudents, "students"));
	};

	return (
		<DisplayField label={"Students"} name="students" showLabel>
			{
				!isArrayNullOrEmpty(students) &&
				<GridTable>
					<thead>
					<tr className="text-center">
						<th>Prekindergarten</th>
						<th>Primary Exceptionality</th>
					</tr>
					</thead>
					<tbody>
					{
						students.map((student, key) => {
							const isNew = !student.primaryExceptionalityType;
							const isInList = isInSelectList(studentExceptionalities, student.primaryExceptionalityType);
							const canEdit = isNew || isInList;

							return <tr className="text-center" key={key}>
								<td>
									<RadioField
										direction={inputStyles.radio.direction.HORIZONTAL}
										index={key}
										legend={"Prekindergarten"}
										showLegend={false}
										name={`isPreK`}
										onChange={(event) => handleChangeStudent(event, student.id)}
										options={createYesNoList()}
										value={student.isPreK}
									/>
								</td>
								<td>
									{
										canEdit &&
										<SelectField
											includeDefaultOption
											label="Primary Exceptionality"
											name="primaryExceptionalityType"
											onChange={(event) => handleChangeStudent(event, student.id)}
											options={studentExceptionalities}
											value={student.primaryExceptionalityType}
										/>
									}
									{
										!canEdit &&
										<ButtonMock label={`${student.primaryExceptionalityType} (archived)`}/>
									}
									<span className={ActionButton.position.RIGHT}>
										<Button name={`${student.id}`}
										        label={"Remove"}
										        showLabel={false}
										        onClick={(event) => handleClickRemoveStudent(event)}
										        buttonType={ButtonTypes.REMOVE}/>
									</span>
								</td>
							</tr>;
						})
					}
					</tbody>
				</GridTable>
			}

			<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
				<Tile body={"Add Student"}
				      onClick={handleClickAddStudent}
				      isNew={true}
				      isMedium={true}/>
			</TileContainer>
		</DisplayField>
	);
};

DrStateComplaintStudentEdit.propTypes = {
	onChange: PropTypes.func.isRequired,
	studentExceptionalities: PropTypes.array.isRequired,
	value: PropTypes.array.isRequired
};

export default DrStateComplaintStudentEdit;