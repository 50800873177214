import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Allow} from "../../../components/authorization/Allow";
import {MonitoringCompliancePolicy} from "../../../components/authorization/policies/MonitoringCompliancePolicy";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import * as MonitoringComplianceUtilities from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import RecordRequirementsTable from "../../../components/MonitoringCompliance/RecordRequirementsTable";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";
import {monitoring_compliance} from "../../../constants/policyEvents";
import {ValidationNotesView} from "./ValidationNotesView";

export const ValidateStudentRecordsContainer = ({
                                                    actions,
                                                    dateWindowId,
                                                    districtId,
                                                    handleDataChanged,
                                                    history,
                                                    institutions,
                                                    isLoading,
                                                    loadComplianceAssessment,
                                                    selectedAssessment
                                                }) => {
    const [isTitleSet, setIsTitleSet] = useState(false);
    const [editingValidationNoteId, setEditingValidationNoteId] = useState("");

    const onRequirementEditClick = (event, indicatorRequirementId, studentRecordId) => {
        event.preventDefault();

        const indicator = selectedAssessment.indicatorRequirements.find(f => f.indicatorRequirementId === indicatorRequirementId);
        const path = (indicator && indicator.isMatrix)
            ? ComplianceLocations.COMPLIANCE_MATRIX_RECORD_VALIDATE.path
            : ComplianceLocations.COMPLIANCE_RECORD_VALIDATE.path;

        history.push(path
            .replace(ComplianceLocations.ASSESSMENT_ID, selectedAssessment.monitoringAssessmentId)
            .replace(ComplianceLocations.REQUIREMENT_ID, indicatorRequirementId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId));
    };

    const onUnsubmitStudentRecordClick = (event, studentRecordId) => {
        event.preventDefault();

        if (confirm(`Are you sure you want to unsubmit this student record?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`)) {
            actions.unsubmitComplianceAssessmentStudentRecord(selectedAssessment.monitoringAssessmentId, studentRecordId)
                .then(() => {
                    loadComplianceAssessment();
                });
        }
    };

    const onClickCompleteValidation = (event, indicatorRequirementId, requiringReassessment) => {
        event.preventDefault();

        let confirmMsg = (requiringReassessment)
            ? "Are you sure you want to send this compliance back to the district for reassessment?"
            : "Are you sure you want to approve this compliance?";

        if (confirm(confirmMsg)) {
            actions.submitComplianceAssessmentValidation(selectedAssessment.monitoringAssessmentId, indicatorRequirementId)
                .then(() => {
                    loadComplianceAssessment();
                });
        }
    };

    const onClickUnsubmitCompliance = (event, indicatorRequirementId) => {
        event.preventDefault();

        if (confirm("Are you sure you want to unsubmit the compliance?  This will remove all validation and reassessment data if it exists."))
            actions.unsubmitCompliance(selectedAssessment.monitoringAssessmentId, indicatorRequirementId)
                .then(() => {
                    loadComplianceAssessment();
                });
    };

    const onClickUnsubmitValidation = (event, indicatorRequirementId) => {
        event.preventDefault();
        if (confirm("Are you sure you want to unsubmit the validation? This will remove all reassessment data if it exists.")) {
            actions.unsubmitValidation(selectedAssessment.monitoringAssessmentId, indicatorRequirementId)
                .then(() => {
                    loadComplianceAssessment();
                });
        }
    };

    const onClickUnsubmitReassessment = (event, indicatorRequirementId) => {
        event.preventDefault();
        if (confirm("Are you sure you want to unsubmit the reassessment?")) {
            actions.unsubmitReassessment(selectedAssessment.monitoringAssessmentId, indicatorRequirementId)
                .then(() => {
                    loadComplianceAssessment();
                });
        }
    };


    useEffect(() => {
        if (!institutions || institutions.length === 0) return;

        const pageTitle = MonitoringComplianceUtilities.createPageTitle(institutions, districtId, "Validate Student Records");
        actions.updatePageTitle(pageTitle);
        setIsTitleSet(true);

    }, [institutions])

    useEffect(() => {
        if (!isTrimmedStringEmpty(editingValidationNoteId))
            setEditingValidationNoteId("");
    }, [dateWindowId])

    if (!isTitleSet || !selectedAssessment.indicatorRequirements) return null;

    return (
        <>
            {
                selectedAssessment.indicatorRequirements.map((requirement, index) => {
                    if(requirement.indicatorRequirementId === emptyGuid) return null;

                    const studentRecords = MonitoringComplianceUtilities.getStudentRecordsForIndicatorRequirement(
                        requirement.indicatorId,
                        selectedAssessment.studentRecords);
                    const indicatorTitle = requirement.publicIdentifier;

                    const validationSubmitted = requirement.validationSubmitted;
                    const assessmentApproved = requirement.assessmentApproved;
                    let readyToApprove = requirement.allStudentRecordsSubmitted;
                    let requiringReassessment = !isNullOrUndefined(requirement.studentRecordsWithDiscrepanciesRequiringReassessment) && requirement.studentRecordsWithDiscrepanciesRequiringReassessment;
                    const isEditingValidationNote = !isTrimmedStringEmpty(editingValidationNoteId);

                    return (
                        <section className="compliance__requiredRecords section-spacing-bottom"
                                 id={requirement.indicatorRequirementId}
                                 key={index}>
                            <RecordRequirementsTable
                                canUnsubmit
                                indicatorRequirement={requirement}
                                isLoading={isLoading}
                                onEditClick={onRequirementEditClick}
                                onClickUnsubmitStudentRecord={onUnsubmitStudentRecordClick}
                                requirementRecords={studentRecords}
                            />
                            <ValidationNotesView
                                actions={actions}
                                assessmentId={selectedAssessment.monitoringAssessmentId}
                                currentValidationNote={requirement.validationNotes}
                                editMode={editingValidationNoteId === requirement.indicatorRequirementId}
                                handleDataChanged={handleDataChanged}
                                indicatorRequirementId={requirement.indicatorRequirementId}
                                indicatorTitle={indicatorTitle}
                                isLoading={isLoading}
                                loadComplianceAssessment={loadComplianceAssessment}
                                setEditModId={setEditingValidationNoteId}
                            />
                            {
                                readyToApprove &&
                                <Allow policy={MonitoringCompliancePolicy}
                                       policyEvent={monitoring_compliance.assessment.SUBMIT}>
                                    <ButtonBar position={ButtonBarPositions.MODULE}
                                               className={`buttonBar--submission-module`}>
                                        <div className={`button--container`}>
                                            <Button
                                                buttonType={ButtonTypes.SUBMIT}
                                                disabled={isLoading || isEditingValidationNote}
                                                label={`Unsubmit Compliance for ${indicatorTitle}`}
                                                name={`btnUnsubmitCompliance_${index}`}
                                                onClick={(event) => onClickUnsubmitCompliance(event, requirement.indicatorRequirementId)}
                                                showLabel
                                            />
                                            {
                                                readyToApprove &&
                                                !validationSubmitted &&
                                                <Button
                                                    buttonType={ButtonTypes.SAVE}
                                                    disabled={isLoading || isEditingValidationNote}
                                                    label={`Complete Validation for ${indicatorTitle}`}
                                                    name={`btnValidationComplete_${index}`}
                                                    onClick={(event) => onClickCompleteValidation(event, requirement.indicatorRequirementId, requiringReassessment)}
                                                    showLabel
                                                />
                                            }
                                            {
                                                validationSubmitted &&
                                                ((requiringReassessment && !assessmentApproved) || !requiringReassessment) &&
                                                <Button
                                                    buttonType={ButtonTypes.HISTORY}
                                                    disabled={isLoading || isEditingValidationNote}
                                                    label={`Unsubmit Validation for ${indicatorTitle}`}
                                                    name={`btnUnsubmitValidation_${index}`}
                                                    onClick={(event) => onClickUnsubmitValidation(event, requirement.indicatorRequirementId)}
                                                    showLabel
                                                />
                                            }
                                            {
                                                validationSubmitted &&
                                                requiringReassessment &&
                                                assessmentApproved &&
                                                <Button
                                                    buttonType={ButtonTypes.HISTORY}
                                                    disabled={isLoading || isEditingValidationNote}
                                                    label={`Unsubmit Reassessment for ${indicatorTitle}`}
                                                    name={`btnUnsubmitReassessment_${index}`}
                                                    onClick={(event) => onClickUnsubmitReassessment(event, requirement.indicatorRequirementId)}
                                                    showLabel
                                                />
                                            }
                                        </div>
                                    </ButtonBar>
                                </Allow>
                            }
                        </section>);
                })
            }
        </>
    );
};

ValidateStudentRecordsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object.isRequired
};

