import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import FormTable from "../../../components/common/FormTable";
import GridRow from "../../../components/common/GridRow";
import RadioField from "../../../components/common/inputs/RadioField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import {inputStyles} from "../../../constants/inputConstants";
import {
    ComplainantRelationshipToStudent, IsIntakeRequirementMet, IsIntakeRequirementMetList,
    StateComplaintSections,
} from "../drStateComplaintConstants";
import {RadioButtonYesNoObject} from "../../drConstants";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {isIntakeConditionalParentYesSelected} from "../StateComplaintsUtilities";
import {DrStateComplaintIntakeOtherInfoView} from "./DrStateComplaintIntakeOtherInfoView";
import DrStateComplaintIntakeResponse from "./DrStateComplaintIntakeResponseView";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import SectionHeader from "../../../components/common/SectionHeader";

const DrStateComplaintIntakeView = ({
                                        complaint,
                                        handleIntakeChange,
                                        handleSaveIntakeResponseForm,
                                        handleSelectSection,
                                        handleDataChanged,
                                        intakeRequirements,
                                        intakeResponses,
                                        readOnly,
                                        updateAllResponses
                                    }) => {
    const [updateAll, setUpdateAll] = useState(IsIntakeRequirementMet.Yes);
    const intakeForm = useFormInputObject(drObjectFactory.createIntakeObject(complaint), () => handleDataChanged());

    const fetchFormData = () => {
        const result = convertFormInputObjectToObject(intakeForm);
        result.intakeRequirementResponseModels = intakeResponses;
        return result;
    };

    const isMissingResponseToAdditionalIntakeQuestions = () => {
        const isMissingAdditionalIntakeResponse =  
            !intakeForm.complainantRelationshipToStudent.value 
            || intakeForm.complainantRelationshipToStudent.value.length === 0
            || !intakeForm.reachedAgeOfMajority.value
            || !intakeForm.isComplaintSubjectOfDueProcess.value
            || !intakeForm.issuesInvolvedTheSamePartiesAndDecided.value
            || !intakeForm.complainantRequireAssistance.value
            || !intakeForm.receiveCorrespondenceElectronically.value
            || !intakeForm.resolveComplaintThroughMediation.value
            || !intakeForm.resolveComplaintThroughFacilitation.value;

        if(isMissingAdditionalIntakeResponse)
            NotifyUser.Warning("Missing responses in the Other Additional Information section.");
    };

    const validateResponsesToAdditionalIntakeQuestions = () => {
        clearInputFormErrorFields(intakeForm);

        if (intakeForm.complainantRelationshipToStudent.value.includes(ComplainantRelationshipToStudent.Other) && !intakeForm.otherRelationshipToStudent.value)
            intakeForm.otherRelationshipToStudent.setError("Required");

        if (intakeForm.isComplaintSubjectOfDueProcess.value === RadioButtonYesNoObject.Yes && intakeForm.relatedDueProcessId.value && !intakeForm.extendRelatedToDueProcess.value)
            intakeForm.extendRelatedToDueProcess.setError("Required");

        if (intakeForm.issuesInvolvedTheSamePartiesAndDecided.value === RadioButtonYesNoObject.Yes && !intakeForm.extendIssuesInvolved.value)
            intakeForm.extendIssuesInvolved.setError("Required");

        if (intakeForm.issuesInvolvedTheSamePartiesAndDecided.value === RadioButtonYesNoObject.Yes && !intakeForm.involvedDueProcessId.value)
            intakeForm.involvedDueProcessId.setError("Required");

        if (intakeForm.complainantRequireAssistance.value === RadioButtonYesNoObject.Yes && intakeForm.reasonForAssistance.value.length === 0)
            intakeForm.reasonForAssistance.setError("Required");

        let isValid = inputFormIsValid(intakeForm);
        if (!isValid)
            NotifyUser.Error("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const handleClickSave = (event) => {
        event.preventDefault();

        if(validateResponsesToAdditionalIntakeQuestions()) {
            const form = fetchFormData();
            handleSaveIntakeResponseForm(form);
        }
    };

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.Intake, true);
    };

    const handleUpdateAllResponses = (event) => {
        event.preventDefault();

        updateAllResponses(updateAll);
    }

    useEffect(() => {
        if(complaint.isIntakeRequirementCompleted)
            isMissingResponseToAdditionalIntakeQuestions();
    }, [complaint.isIntakeRequirementCompleted]);

    return (
        <div className={"intake"}>
            <SectionHeader>
                <h2>Section I. Minimum State Complaint Filing Requirements</h2>
            </SectionHeader>

            <p>Directions - Review the state complaint to determine whether the complaint meets the minimum state complaint filing requirements.</p>

            {
                !readOnly &&
                <GridRow align={`middle`}>
                    <div>Update all responses below to: </div>
                    <RadioField
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        legend={`Update all responses`}
                        name={`updateAll`}
                        onChange={(event) => setUpdateAll(event.target.value)}
                        options={IsIntakeRequirementMetList}
                        showLegend={false}
                        value={updateAll}
                    />
                    <Button
                        btnClass={`update-all`}
                        label={`Update`}
                        name={`btnUpdateAllResponses`}
                        onClick={handleUpdateAllResponses}
                        showLabel
                    />
                </GridRow>
            }

            <FormTable>
            {
                intakeRequirements.map((requirement, key) => {
                    let response = intakeResponses.find(f => f.requirementId === requirement.id);

                    let parentIsYes = isIntakeConditionalParentYesSelected(intakeResponses, requirement.conditionalParentId);

                    return (
                        <DrStateComplaintIntakeResponse
                            key={key}
                            handleIntakeChange={handleIntakeChange}
                            intakeResponse={response}
                            parentIsYes={parentIsYes}
                            readOnly={readOnly}
                            requirementDescription={requirement.description}
                            requirementType={requirement.requirementType}
                        />
                    );
                })
            }
            </FormTable>

            <TextAreaEditor
                disabled={readOnly}
                label={`Comments`}
                name={`intakeComments`}
                showLabel
                {...intakeForm.intakeComments}
            />

            <DrStateComplaintIntakeOtherInfoView
                complaint={complaint}
                intakeForm={intakeForm}
                readOnly={readOnly}
            />

            {
                !readOnly &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SAVE}
                            onClick={handleClickSave}/>
                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                </ButtonBar>
            }
        </div>
    );

};

DrStateComplaintIntakeView.propTypes = {
    complaint: PropTypes.object.isRequired,
    handleIntakeChange: PropTypes.func.isRequired,
    handleSaveIntakeResponseForm: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    intakeRequirements: PropTypes.array.isRequired,
    intakeResponses: PropTypes.array,
    readOnly: PropTypes.bool.isRequired,
    updateAllResponses: PropTypes.func.isRequired
};

export default DrStateComplaintIntakeView;