import React from "react";
import PropTypes from "prop-types";
import DrStateComplaintClosureNoActionsView from "./DrStateComplaintClosureNoActionsView";

export const DrStateComplaintClosureNoActionsContainer = ({
                                                              actions,
                                                              api,
                                                              complaint,
                                                              handleDataChanged,
                                                              handleSelectSection,
                                                              handleUpdateComplaint
                                                          }) => {

    const handleSaveComplaint = (form) => {
        actions.executeApi(api.saveComplaint, [form])
            .then((result) => {
                handleUpdateComplaint(result);
            });
    };

    if (!complaint) return;

    return <DrStateComplaintClosureNoActionsView
        complaint={complaint}
        handleSaveComplaint={handleSaveComplaint}
        handleSelectSection={handleSelectSection}
        handleDataChanged={handleDataChanged}
    />;
};

DrStateComplaintClosureNoActionsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    api: PropTypes.func.isRequired,
    complaint: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired
};

export default DrStateComplaintClosureNoActionsContainer;
