import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";
import GridColumn from "../GridColumn";
import GridRow from "../GridRow";
import FieldsetLayout from "./FieldsetLayout";
import Button from "../buttons/Button";
import {inputStyles} from '../../../constants/inputConstants';
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {createFakeEvent} from "./inputUtility";
import {getRandomInt} from "../HtmlUtilities";
import {getIndexOfMaxValueInArray, isNullOrUndefined} from "../commonUtilities";

const AbstractRadioField = ({
                                direction,
                                disabled,
                                error,
                                helpText,
                                inputClass,
                                legend,
                                legendClass,
                                name,
                                onChange,
                                options=[],
                                showClearResponseButton,
                                showLegend,
                                usesColumns = false,
                                wrapperClass,
                                value
                            }) => {

    const uniqueId = getRandomInt();
    const directionClass = usesColumns ? "" : direction;
    let radioButtonWrapperClass = `${inputStyles.radio.THEME} ${directionClass}`;

    if (showClearResponseButton) {
        legendClass = `${legendClass} ${inputStyles.radio.withClear.LEGEND}`;
        radioButtonWrapperClass = `${radioButtonWrapperClass} ${inputStyles.radio.withClear.WRAPPER} `;
    }

    const props = {
        inputClass,
        name,
        onChange,
        options,
        uniqueId,
        value,
    }

    const layout = usesColumns
        ? <ColumnsRadioFieldLayout
            {...props}
        />
        : <BlockRadioFieldLayout
            {...props}
        />;


    return (
        <FieldsetLayout
            legend={legend}
            showLegend={showLegend}
            legendClass={legendClass}
            helpText={helpText}
            error={error}
            wrapperClass={wrapperClass}
            disabled={disabled}>
            {
                showClearResponseButton &&
                <Button
                    buttonType={ButtonTypes.CLEAR}
                    label={`Clear Response`}
                    showLabel={false}
                    name={`btnClearResponse`}
                    onClick={() => onChange(createFakeEvent("", name))}
                    btnClass={inputStyles.radio.withClear.BUTTON}
                />
            }
            <div className={radioButtonWrapperClass}>
                {layout}
            </div>
        </FieldsetLayout>
    );
};

const BlockRadioFieldLayout = ({
                                   inputClass,
                                   name,
                                   onChange,
                                   options,
                                   uniqueId,
                                   value
                               }) => {
    return (
        <>
            {
                options.map((option, key) => {
                    return (
                        <div key={key} className={`${inputStyles.radio.THEME}__option`}>
                            <RadioFieldComponent
                                index={key}
                                inputClass={inputClass}
                                name={name}
                                onChange={onChange}
                                option={option}
                                uniqueId={uniqueId}
                                value={value}
                            />
                        </div>
                    );
                })
            }
        </>
    );
};

const ColumnsRadioFieldLayout = ({
                                     inputClass,
                                     name,
                                     onChange,
                                     options,
                                     uniqueId,
                                     value
                                 }) => {
    const standardColumnWidth = "3";

    const getUnevenWidthSizes = (totalUnevenColumns) => {
        const optionSizes = options.map(option => option.text.length);
        for(let i = 0; i < totalUnevenColumns; i++) {
            let indexOfMax = getIndexOfMaxValueInArray(optionSizes);
            small[indexOfMax] = standardColumnWidth;
            optionSizes[indexOfMax] = 0;
        }
    }
    const fillValue = options.length === 5 ? "2" : standardColumnWidth;
    let small = Array(options.length).fill(fillValue);

    if(options.length === 5)
        getUnevenWidthSizes(2);

    return (
        <GridRow>
            {
                options.map((option, key) => {
                    return (
                        <GridColumn small={small[key]} key={key}>
                            <RadioFieldComponent
                                index={key}
                                inputClass={inputClass}
                                name={name}
                                onChange={onChange}
                                option={option}
                                uniqueId={uniqueId}
                                value={value}
                            />
                        </GridColumn>
                    );
                })
            }
        </GridRow>
    );
}

const RadioFieldComponent = ({
                                 index,
                                 inputClass,
                                 name,
                                 onChange,
                                 option,
                                 uniqueId,
                                 value
                             }) => {
    const checked = isNullOrUndefined(value) ? false : option.value.toString().toLowerCase() === value.toString().toLowerCase();
    const id = `${name}_${uniqueId}_${index}`;

    return (
        <>
            <input checked={checked}
                   className={inputClass}
                   type="radio"
                   id={id}
                   name={id}
                   value={option.value}
                   onChange={(e) => onChange(createFakeEvent(e.target.value, name))}
            />
            <label htmlFor={id} className={option.id}>
                <HtmlDisplayInline html={option.text}/>
            </label>
        </>
    );
}

const RadioFieldLayoutPropTypes = {
    inputClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

export const BaseAbstractRadioFieldPropTypes = {
    ...RadioFieldLayoutPropTypes,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    legend: PropTypes.string.isRequired,
    legendClass: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    showClearResponseButton: PropTypes.bool,
    showLegend: PropTypes.bool,
    wrapperClass: PropTypes.string,
};

AbstractRadioField.propTypes = {
    ...BaseAbstractRadioFieldPropTypes,
    direction: PropTypes.string,
    usesColumns: PropTypes.bool
}

BlockRadioFieldLayout.propTypes = {
    ...RadioFieldLayoutPropTypes,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    uniqueId: PropTypes.number.isRequired
}

ColumnsRadioFieldLayout.propTypes = {
    ...RadioFieldLayoutPropTypes,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    uniqueId: PropTypes.number.isRequired
}

RadioFieldComponent.propTypes = {
    ...RadioFieldLayoutPropTypes,
    index: PropTypes.number.isRequired,
    option: PropTypes.object.isRequired,
    uniqueId: PropTypes.number.isRequired
};

export default AbstractRadioField;