import SppReviewApi from "../api/SppReviewApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function acceptElementResponseSuccess(policyResponseId, auditId){
    return { type: actionTypes.SUBMIT_SPP_ACCEPT_ELEMENT_RESPONSE_AJAX_SUCCESS, data: {policyResponseId, auditId} };
}

export function acceptElementResponse(policyResponseId, auditId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.AcceptElementResponse(policyResponseId, auditId)
            .then((newAuditId) => {
                dispatch(acceptElementResponseSuccess(policyResponseId, newAuditId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function rejectElementResponseSuccess(policyResponseId, auditId){
    return { type: actionTypes.SUBMIT_SPP_ELEMENT_RESPONSE_REVISION_REQUIRED_AJAX_SUCCESS, data: {policyResponseId, auditId}  };
}

export function rejectElementResponse(policyResponseId, auditId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.ElementResponseRevisionRequired(policyResponseId, auditId)
            .then((newAuditId) => {
                dispatch(rejectElementResponseSuccess(policyResponseId, newAuditId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function resetAuditSuccess(policyResponseId, auditId){
    return { type: actionTypes.SUBMIT_SPP_REQUEST_AUDIT_AJAX_SUCCESS, data: {policyResponseId, auditId}};
}

export function resetAudit(policyResponseId, auditId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.ResetAudit(policyResponseId, auditId)
            .then((newAuditId) => {
                dispatch(resetAuditSuccess(policyResponseId, newAuditId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function addAuditCommentSuccess(auditCommentViewModel, policyResponseId, auditCommentId){
    return { type: actionTypes.ADD_AUDIT_COMMENT_AJAX_SUCCESS, data: { comment: auditCommentViewModel, policyResponseId, auditCommentId } };
}

export function addAuditComment(auditCommentViewModel, policyResponseId){
    return dispatch => {
        dispatch(beginAjaxCall());
        return SppReviewApi.ResponseAuditComment(auditCommentViewModel, true)
            .then((auditCommentId) => {
                dispatch(addAuditCommentSuccess(auditCommentViewModel, policyResponseId, auditCommentId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function updateAuditCommentSuccess(){
    return { type: actionTypes.UPDATE_AUDIT_COMMENT_AJAX_SUCCESS };
}

export function updateAuditComment(auditCommentViewModel){
    return dispatch => {
        dispatch(beginAjaxCall());
        return SppReviewApi.ResponseAuditComment(auditCommentViewModel, false)
            .then(() => {
                dispatch(updateAuditCommentSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}


export function preApproveDocumentSuccess(fileId){
    return { type: actionTypes.PRE_APPROVE_SPP_INSTITUTION_DOCUMENT_AJAX_SUCCESS, data: fileId };
}

export function preApproveDocument(policyComplianceId, fileId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.PreApproveInstitutionDocument(policyComplianceId, fileId)
            .then(() => {
                dispatch(preApproveDocumentSuccess(fileId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function approveInstitutionDocumentSuccess(fileId){
	return { type: actionTypes.APPROVE_INSTITUTION_DOCUMENT_AJAX_SUCCESS, data: fileId};
}

export function approveInstitutionDocument(policyComplianceId, fileId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppReviewApi.ApproveInstitutionDocument(policyComplianceId, fileId)
			.then(() => {
				dispatch(approveInstitutionDocumentSuccess(fileId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadPolicyCompliancesSuccess(policyCompliances){
    return { type: actionTypes.LOAD_POLICY_COMPLIANCES_AJAX_SUCCESS, data: policyCompliances };
}

export function loadPolicyCompliances(documentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.GetPolicyCompliances(documentId)
            .then((policyCompliances) => {
                dispatch(loadPolicyCompliancesSuccess(policyCompliances));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function undoPreApproveSuccess(){
    return { type: actionTypes.UNDO_PREAPPROVE_AJAX_SUCCESS };
}

export function undoPreApprove(policyComplianceId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.RemovePreApproval(policyComplianceId)
            .then(() => {
                dispatch(undoPreApproveSuccess(policyComplianceId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function undoApprovalSuccess(){
    return { type: actionTypes.UNDO_APPROVE_AJAX_SUCCESS };
}

export function undoApproval(policyComplianceId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.RemoveApproval(policyComplianceId)
            .then(() => {
                dispatch(undoApprovalSuccess(policyComplianceId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function createComment(auditId, policyResponseId){
    return { type: actionTypes.CREATE_AUDIT_COMMENT, data: { auditId, policyResponseId}};
}

export function editComment(auditCommentId){
    return { type: actionTypes.EDIT_AUDIT_COMMENT, data: auditCommentId };
}

export function changeComment(comment, policyResponseId){
    return { type: actionTypes.CHANGE_AUDIT_COMMENT, data: { comment, policyResponseId } };
}

export function certifyInstitutionDocumentSuccess(fileId){
    return { type: actionTypes.CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS, data: fileId };
}

export function certifyInstitutionDocument(policyComplianceId, fileId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.CertifyInstitutionDocument(policyComplianceId, fileId)
            .then(() => {
                dispatch(certifyInstitutionDocumentSuccess(fileId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function undoInstitutionCertificationSuccess(){
    return { type: actionTypes.UNDO_CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS };
}

export function undoInstitutionCertification(policyComplianceId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return SppReviewApi.RemoveInstitutionCertificationDocument(policyComplianceId)
            .then(() => {
                dispatch(undoInstitutionCertificationSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}