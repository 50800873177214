import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {
    isTrimmedStringEmpty,
    objectHasProperty
} from "../../../../components/common/commonUtilities";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import FieldsetLayout from "../../../../components/common/inputs/FieldsetLayout";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import {gridConstants} from "../../../../constants/inputConstants";
import {FORM_TYPE} from "../../gmsConstants";
import {createDoe100Data, createDoe100Labels} from "../../gmsObjectFactory";
import TextField from "../../../../components/common/inputs/TextField";
import _ from "lodash";
import FormTable from "../../../../components/common/FormTable";
import DollarField from "../../../../components/common/inputs/DollarField";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import gmsApi from '../../gmsApi';
import {GmsApplicationHistorySelectField} from "../GmsApplicationHistorySelectField";
import DownloadUtility from '../../../../api/requests/DownloadUtility';

export const GmsApplicationFormElementDoe100 = ({
                                                    actions,
                                                    applicationHistories,
                                                    applicationId,
                                                    canEditApplication,
                                                    initialSignedDoe100FileId,
                                                    isDirty,
                                                    isFiscalAgentUser,
                                                    programName = "",
                                                    response = {value: {}},
                                                    selectedApplicationHistory,
                                                    setSelectedApplicationHistory,
                                                    targetedAllocation = 0
                                                }) => {
    const [signedDoe100FileId, setSignedDoe100FileId] = useState(initialSignedDoe100FileId);
    const [isReadyToPrint, setIsReadyToPrint] = useState(false);
    const responseValue = _.isString(response.value) && !isTrimmedStringEmpty(response.value) ? JSON.parse(response.value) : response.value;
    const responses = useFormInputObject(createDoe100Data(responseValue));
    const labels = createDoe100Labels();
    const filteredApplicationHistories= applicationHistories.filter(x => !x.hasAmendedData);

    const handlePrintClick = (event) => {
        event.preventDefault();
        actions.executeApi(gmsApi.downloadGeneratedForm, [applicationId, FORM_TYPE.doe100]);
    };

    const handleUploadSignedDoe100Click = () => {
        const uploadDoe100 = document.getElementById("uploadDoe100");
        uploadDoe100.click();
    };

    const handleFileUploadSignedDoe100Change = (event) => {
        const file = [...event.target.files];
        actions.executeApi(gmsApi.uploadSignedForm, [file, applicationId, FORM_TYPE.doe100])
            .then((newFileId) => {
                setSignedDoe100FileId(newFileId);
                DownloadUtility.clearFileUploadSelection("uploadDoe100");
            });
    };

    const handleDownloadSignedDoe100Click = (event) => {
        event.preventDefault();
        actions.executeApi(gmsApi.downloadSignedFormWithFileId, [signedDoe100FileId, FORM_TYPE.doe100]);
    };

    const handleClickDownloadDoe100History = async (event) => {
        event.preventDefault();
        if (!selectedApplicationHistory) {
            NotifyUser.Warning("Please select a DOE 100 History option before downloading.");
            return;
        }
        await actions.executeApi(gmsApi.downloadGeneratedForm, [applicationId, FORM_TYPE.doe100, selectedApplicationHistory]);
    };

    useEffect(() => {
        if(filteredApplicationHistories.every(a => selectedApplicationHistory !== a.id))
            setSelectedApplicationHistory("");
    }, []);

    useEffect(() => {
        let hasError = false;

        const updatedResponse = convertFormInputObjectToObject(responses);
        if (!_.isEqual(updatedResponse, response.value)) {
            response.setValue(JSON.stringify(updatedResponse));
        }

        const validateProperty = (property) => {
            const hasErrorNotYetMarked = labels[property].required && !responses[property].value
                && !responses[property].error;
            if (hasErrorNotYetMarked) {
                responses[property].setError(`${labels[property].label} is required.`);
            }

            const hasErrorMarkedCorrectly = labels[property].required && !responses[property].value
                && responses[property].error;
            if (hasErrorNotYetMarked || hasErrorMarkedCorrectly) {
                hasError = true;
            }

            const hasErrorMarkedShouldNot = labels[property].required && responses[property].value
                && responses[property].error;
            if (hasErrorMarkedShouldNot) {
                responses[property].setError("");
            }
        };

        for (let property in responses) {
            const isPropertyInResponsesObject = objectHasProperty(responses, property);
            const isPropertyInLabelsObject = objectHasProperty(labels, property);

            if (isPropertyInResponsesObject && isPropertyInLabelsObject)
                validateProperty(property);
        }

        setIsReadyToPrint(!hasError && !isDirty)

        if (isFiscalAgentUser) return;

        if (hasError)
            response.setError("Complete required fields.");
        else
            response.setError("");

    }, [responses, response]);

    return (
        <>
            <div className={`doe100DownloadContainer`}>
                <h3>Download and Upload Forms</h3>

                {
                    isReadyToPrint &&
                    <>
                        <p>All required form fields have been completed, you can now
                            <Button
                                buttonType={ButtonTypes.LINK_INLINE}
                                disabled={!isReadyToPrint}
                                label={`download an unsigned PDF`}
                                name={`btnPrintDoe100`}
                                onClick={handlePrintClick}
                            />
                            copy of this form.</p>
                        <ButtonBar position={ButtonBarPositions.MODULE}>
                            <Button
                                disabled={!canEditApplication}
                                label={!signedDoe100FileId ? `Upload Signed DOE 100 Form` : `Replace Signed DOE 100 Form`}
                                name={`btnUploadSignedDoe100`}
                                onClick={handleUploadSignedDoe100Click}
                                showLabel
                            />
                            <Button
                                disabled={!signedDoe100FileId}
                                label={`Download Signed DOE 100 Form`}
                                name={`btnDownloadSignedDoe100`}
                                onClick={handleDownloadSignedDoe100Click}
                                showLabel
                            />
                        </ButtonBar>
                    </>
                }
                {
                    !isReadyToPrint &&
                    <p className={`color--alert`}>When all required form fields have been completed and saved then you
                        will
                        be able to
                        &quot;Download a PDF&quot; copy of this form.</p>
                }

                {
                    filteredApplicationHistories.length > 0 &&
                    <>
                        <p>Download unsigned DOE 100 Historical PDF Files.</p>
                        <GridRow rowClass={`applicationHistoryBar applicationHistoryBar--noBorder`} medium={gridConstants.column.TWO}>
                            <GridColumn columnClass={`text-right`}>
                                <GmsApplicationHistorySelectField
                                    applicationHistories={filteredApplicationHistories}
                                    label={`DOE 100 History`}
                                    selectedApplicationHistory={selectedApplicationHistory}
                                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                                    showLabel={false}
                                />
                            </GridColumn>
                            <GridColumn columnClass={`applicationHistoryBar__buttonColumn`}>
                                <Button
                                    label={`Download DOE 100 History`}
                                    name={`btnDownloadDoe100History`}
                                    onClick={handleClickDownloadDoe100History}
                                />
                            </GridColumn>
                        </GridRow>
                    </>
                }
            </div>

            <h3>Funds Requested</h3>
            <p>Below is the amount allocated for this grant. Indicate the roll forward for this grant, leave as 0.00 if
                there is no roll forward.</p>
            <FieldsetLayout disabled={!canEditApplication}>
                <FormTable>
                    <TextField columnsMedium={6} columnsLarge={8} showLabel name={`programName`} value={programName}
                               {...labels.fundsProgramName}
                    />

                    <DollarField columnsMedium={6} columnsLarge={8} showLabel name={`targetedAllocation`}
                                 value={targetedAllocation}
                                 {...labels.fundsAllocated}
                    />

                    <DollarField columnsMedium={6} columnsLarge={8} showLabel
                                 {...responses.fundsRollForward}
                                 {...labels.fundsRollForward}
                    />
                </FormTable>

                <h3>Applicant Information</h3>
                <p>Enter the address of the agency receiving the award.</p>

                <FormTable>
                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantAddress}
                               {...labels.applicantAddress}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantCity}
                               {...labels.applicantCity}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantZip}
                               {...labels.applicantZip}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantPhysicalAddress}
                               {...labels.applicantPhysicalAddress}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantUeiNumber}
                               {...labels.applicantUeiNumber}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.applicantFeinNumber}
                               {...labels.applicantFeinNumber}
                    />
                </FormTable>

                <h3>Contact Information</h3>
                <p>Enter the name and address of the contact person. In the &quot;Agency Head&quot; text box enter the
                    name
                    of the
                    district superintendent or agency head whose signature will be certifying the DOE 100.</p>

                <FormTable>
                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactNameAgency}
                               {...labels.contactNameAgency}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactNameFiscal}
                               {...labels.contactNameFiscal}
                    />


                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactName}
                               {...labels.contactName}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactAddress}
                               {...labels.contactAddress}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactCity}
                               {...labels.contactCity}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactZip}
                               {...labels.contactZip}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactEmail}
                               {...labels.contactEmail}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactPhone}
                               {...labels.contactPhone}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactPhoneExt}
                               {...labels.contactPhoneExt}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactFax}
                               {...labels.contactFax}
                    />
                </FormTable>
                <FileUploadButton
                    className={`is-hidden`}
                    label={"Upload DOE 100"}
                    name={"uploadDoe100"}
                    onChange={handleFileUploadSignedDoe100Change}
                />
            </FieldsetLayout>
        </>
    );
};

GmsApplicationFormElementDoe100.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    canEditApplication: PropTypes.bool.isRequired,
    initialSignedDoe100FileId: PropTypes.string,
    isDirty: PropTypes.bool.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    programName: PropTypes.string,
    response: PropTypes.object.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
    targetedAllocation: PropTypes.number
};