import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as SurveyAdminActions from "../../actions/surveyAdminActions";
import * as LayoutActions from "../../actions/layoutActions";
import {generateNewId} from "../../components/common/IdGeneratorUtility";
import * as config from "../../constants/config";
import CollapsibleContainer from "../../components/common/CollapsibleContainer";
import SurveyElementSetEditable from "../../components/surveyAdmin/SurveyElementSetEditable";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import {SURVEYS} from "../../constants/contexts";
import {answerChoicePrefix} from "../../constants/surveys/idPrefixes";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";
import EditElementForm from "../../components/surveyAdmin/EditElementForm";
import * as elementTypes from "../../constants/surveys/elementTypes";
import {catchError} from "../../actions/actionUtility";
import { getParams } from "../../components/layout/getParams";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import Button from "../../components/common/buttons/Button";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import ButtonBar from "../../components/common/buttons/ButtonBar";

export class ManageSurveyElementsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			params: {}
		};

		this.changeElement = this.changeElement.bind(this);
		this.deleteElement = this.deleteElement.bind(this);
		this.createElementSet = this.createElementSet.bind(this);
		this.cancelChanges = this.cancelChanges.bind(this);
		this.saveElement = this.saveElement.bind(this);
		this.cloneElement = this.cloneElement.bind(this);
		this.editElement = this.editElement.bind(this);
		this.orderArea = this.orderArea.bind(this);
		this.onContainersUnmounted = this.onContainersUnmounted.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.MANAGE_ELEMENTS);

        if (params !== null)
        	this.setState({params});

		this.props.actions.tryLoadSurveyDetails(params.surveyInstanceId)
			.then(() => {

				this.props.actions.loadSurveyElements(params.surveyInstanceId);

				this.props.actions.updatePageTitle(this.props.surveyDetails.title + " - Elements");
			})
			.catch(error => catchError(error));
	}

	createElementSet() {
		const elementSetId = config.emptyGuid;
		this.props.actions.createElementSet(elementSetId);
	}

	createElement(parentId) {
		const elementId = config.emptyGuid;
		this.props.actions.createElement(parentId, elementId);
	}

	editElement(event, componentId) {
		event.preventDefault();
		this.props.actions.editElement(componentId);
	}

	changeElement(event, element, choice = null) {
		if (choice === null) {
			element = Object.assign({}, element);

			if (event.target.id.startsWith("add")) {
				event.preventDefault();

				const currentList = element.answerChoices.filter(f => f.componentId.startsWith(answerChoicePrefix));
				const newElementId = generateNewId(currentList, answerChoicePrefix, "", "componentId");
				this.props.actions.createAnswerChoice(element.componentId, newElementId);
			}
			else {
				if (event.target.type === 'checkbox')
					element[event.target.name] = event.target.checked;
				else
					element[event.target.name] = event.target.value;

				this.props.actions.changeElement(element);
			}
		}
		else if (event.target.id.startsWith("remove")) {
			event.preventDefault();
			this.props.actions.removeAnswerChoice(element.componentId, choice.componentId);
		}
		else {
			event.preventDefault();
			choice = Object.assign({}, choice);
			choice[event.target.id.split('|')[0]] = event.target.value;
			this.props.actions.changeAnswerChoice(element.componentId, choice);
		}
	}

	deleteElement(event, element) {
		event.preventDefault();

		const confirmationMessage = element.elementType === elementTypes.TITLE ?
			"Deleting an element set will also delete all elements within the set. Are you sure you want to delete this element set?" :
			"Are you sure you want to delete this element?";

		if (confirm(confirmationMessage)) {
			this.props.actions.deleteSurveyElement(this.state.params.surveyInstanceId, element.componentId);
		}
	}

	saveElement(event, element) {
		event.preventDefault();

		let elementsToSave;
		if (element.elementType !== elementTypes.SELECT)
			elementsToSave = [element];
		else {
			const optionElements = this.props.elements.filter(e => e.parentId === element.componentId && element.componentId !== config.emptyGuid);
			elementsToSave = [...optionElements, element];
		}

		if (this.props.isEditItemNew) {
			this.props.actions.addSurveyElements(this.state.params.surveyInstanceId, elementsToSave)
				.then((isSuccess) => {
					if(!isSuccess) return;

					this.props.actions.loadSurveyElements(this.state.params.surveyInstanceId)
				});
		}
		else {
			this.props.actions.updateSurveyElements(this.state.params.surveyInstanceId, elementsToSave);
		}
	}

	cloneElement(event, componentId) {
		event.preventDefault();

		this.props.actions.cloneSurveyElement(this.state.params.surveyInstanceId, componentId)
			.then((isSuccess) => {
				if(!isSuccess) return;
				this.props.actions.loadSurveyElements(this.state.params.surveyInstanceId);
			})
	}

	cancelChanges(event) {
		event.preventDefault();
		this.props.actions.loadSurveyElements(this.state.params.surveyInstanceId);
	}

	orderArea(event, parentElementId) {
		event.preventDefault();

		this.props.history.push(surveyLocations.ORDER_ELEMENTS.path
			.replace(surveyLocations.INSTANCE_ID, this.state.params.surveyInstanceId)
			.replace(surveyLocations.PARENT_ELEMENT_ID, parentElementId));
	}

	isComponentInContainerEditable(elements) {
		const editItemId = this.props.editItemId;
		const elementSetOrQuestion = elements.filter(f => f.componentId === editItemId
			|| f.parentId === editItemId);

		return elementSetOrQuestion.length > 0 && this.props.isEditing;
	}

	isComponentOpen(componentId) {
		return this.props.openComponents.filter(f => f === componentId).length > 0;
	}

	onContainersUnmounted(collapsibleContainer) {
		const currentIsClosed = this.props.openComponents.filter(f => f === collapsibleContainer.props.id).length === 0;

		if(currentIsClosed !== collapsibleContainer.state.isClosed)
			this.props.actions.updateOpenComponents(collapsibleContainer.props.id);
	}

	render() {
		const isSurveyStatusEdit = this.props.surveyDetails.status === surveyStatuses.Editing;

		if (this.props.elements.length === 0 && !this.props.isLoading && !isSurveyStatusEdit)
			return (<p>This survey has no elements at this time.
				If you believe you are seeing this message in error, try reloading the page or
				{config.supportDetails(SURVEYS)}.
			</p>);

		const elementSets = this.props.elements.filter(element =>
			element.parentId === config.emptyGuid);

		return (
			<div>
				{
					isSurveyStatusEdit && this.props.elements.length > 0 &&
					<ButtonBar position={ButtonBarPositions.TOP}>
						<Button label={"Order Element Sets"} name={"btnOrderAreas"} onClick={(event) => this.orderArea(event, config.emptyGuid)} />
					</ButtonBar>
				}
				{
					elementSets.map(elementSet => {
						if (this.props.isEditing &&
							this.props.editItemId === elementSet.componentId &&
							this.props.isEditItemNew)
							return (<EditElementForm key={elementSet.componentId}
													 element={elementSet}
													 onElementChange={this.changeElement}
													 isLoading={this.props.isLoading}
													 onCancelChanges={this.cancelChanges}
													 onSaveElement={this.saveElement}
													 isEditItemNew={this.props.isEditItemNew}
							/>);

						const elements = this.props.elements.filter(element =>
							element.componentId === elementSet.componentId ||
							element.parentId === elementSet.componentId);

						return (
							<CollapsibleContainer key={elementSet.componentId}
												  id={elementSet.componentId}
							                      trigger={elementSet.labelText}
												  triggerDisabled={this.isComponentInContainerEditable(elements)}
												  open={this.isComponentOpen(elementSet.componentId)}
												  onUnmount={this.onContainersUnmounted}

							>
								<SurveyElementSetEditable
									elements={elements}
									isLoading={this.props.isLoading}
									editItemId={this.props.editItemId}
									isEditing={this.props.isEditing}
									cancelChanges={this.cancelChanges}
									changeElement={this.changeElement}
									saveElement={this.saveElement}
									cloneElementClick={isSurveyStatusEdit ? this.cloneElement : null}
									onEditClick={isSurveyStatusEdit ? this.editElement : null}
									onDeleteClick={isSurveyStatusEdit ? this.deleteElement : null}
									orderArea={isSurveyStatusEdit ? this.orderArea : null}
									isEditItemNew={this.props.isEditItemNew}
								/>

								{
									isSurveyStatusEdit &&
									!this.props.isEditing &&
									<TileContainer rowTilesLarge={"1"}>
										<Tile body={"Add Element"}
										      onClick={() => this.createElement(elementSet.componentId)}
										      isNew
										      isMedium
										/>
									</TileContainer>
								}
							</CollapsibleContainer>
						);
					})
				}

				{
					isSurveyStatusEdit &&
					!this.props.isEditing &&
					<TileContainer rowTilesLarge={"1"}>
						<Tile body={"Add Element Set"}
						      onClick={() => this.createElementSet()}
						      isNew
						      isMedium
						/>
					</TileContainer>
				}
			</div>
		);
	}
}

ManageSurveyElementsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	surveyDetails: PropTypes.object.isRequired,
	elements: PropTypes.array.isRequired,
	isEditing: PropTypes.bool.isRequired,
	editItemId: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isEditItemNew: PropTypes.bool.isRequired,
	openComponents: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		surveyDetails: state.surveyAdmin.selectedSurveyInstance,
		elements: state.surveyAdmin.instanceElements,
		isEditing: state.surveyAdmin.isEditing,
		editItemId: state.surveyAdmin.editItemId,
		isLoading: state.ajaxCallsInProgress > 0,
		isEditItemNew: state.surveyAdmin.isEditItemNew,
		openComponents: state.surveyAdmin.openComponents
	};
}

function mapDispatchToProps(dispatch) {
	const actions = Object.assign({}, SurveyAdminActions, LayoutActions);

	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyElementsPage);