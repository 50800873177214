import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../gmsLocations";
import {API_MESSAGES} from "../../gmsConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsApplicationAmendmentApi {
    static CreateAmendmentForApplication(applicationId, sectionIds, isBudgetAmended) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/applications/${applicationId}/amendment/${isBudgetAmended}`,
                RequestTypes.POST,
                JSON.stringify(sectionIds),
                API_MESSAGES.AMENDMENT.createSuccess,
                'application/json')
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsApplicationAmendmentApi {
    static CreateAmendmentForApplication() {
        return mockResolveApiCall("", API_MESSAGES.AMENDMENT.createSuccess);
    }
}

const gmsApplicationAmendmentApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsApplicationAmendmentApi : MockGmsApplicationAmendmentApi;
export default gmsApplicationAmendmentApi;