import {
    createListItem,
    isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../../../components/common/commonUtilities";
import {PTS_ROLES} from "../../../ptsConstants";

const fiscalAgentUserDelimiter = "|";

export function createProgramUserIdsArray(fiscalAgents, ptsUserTypeId) {
    if(isArrayNullOrEmpty(fiscalAgents)) return [];

    let list = [];
    for(let fiscalAgent of fiscalAgents) {
        for(let programUser of fiscalAgent.programUsers.filter(f => f.ptsRole === ptsUserTypeId)) {
            const userId = ptsUserTypeId === PTS_ROLES.grantsManagementUser.id ? programUser.gmsUserId : programUser.applicationUserId;
            list.push(`${fiscalAgent.fiscalAgentId}${fiscalAgentUserDelimiter}${userId}`);
        }
    }

    return list;
}

export function createProjectManagersSelectList(users) {
    if(isArrayNullOrEmpty(users)) return [];

    return users.map(user => {
        return {
            ...createListItem(`${user.institutionId}${fiscalAgentUserDelimiter}${user.userId}`, `${user.fullName} (${user.institution})`),
            fiscalAgentId: user.institutionId
        };
    });
}

export function createStateUsersSelectList(users) {
    if(isArrayNullOrEmpty(users)) return [];

    return users.map(user => {
        const isDisabled = user.isDisabled ?? false;
        const isDisabledMessage = isDisabled ? ' (Disabled)' : '';
        const userId = user.userId || user.gmsUserId;
        return {
            ...createListItem(userId, `${user.fullName}${isDisabledMessage}`),
            isDisabled
        };
    });
}

export function updateStateUsersSelectedArray(fiscalAgentId, userId, currentUsers) {
    const newUser = `${fiscalAgentId}${fiscalAgentUserDelimiter}${userId}`;
    let selectedUsers = currentUsers.filter(f => !f.startsWith(fiscalAgentId));
    if(!isTrimmedStringEmpty(userId))
        selectedUsers.push(newUser);
    return selectedUsers;
}

export function findSelectedStateUser(fiscalAgentId, currentUsers) {
    return currentUsers.find(f => f.startsWith(fiscalAgentId))?.split(fiscalAgentUserDelimiter)[1] ?? "";
}

export function createSaveFiscalAgentsUsersArray(fiscalAgentIds, programUserIds, liaisonUserIds, grantManagementUserIds) {
    if(isArrayNullOrEmpty(fiscalAgentIds)) return [];

    let saveList = [];
    for(let fiscalAgentId of fiscalAgentIds) {
        const projectManagerUsers = programUserIds.filter(f => f.startsWith(fiscalAgentId));
        let fiscalAgentUsers = [...createSaveFiscalAgentUsersArray(fiscalAgentId, projectManagerUsers, PTS_ROLES.projectManager.id)];

        const liaisonUsers = liaisonUserIds.filter(f => f.startsWith(fiscalAgentId));
        fiscalAgentUsers = [...fiscalAgentUsers, ...createSaveFiscalAgentUsersArray(fiscalAgentId, liaisonUsers, PTS_ROLES.liaisonUser.id)];

        const grantManagementUsers = grantManagementUserIds.filter(f => f.startsWith(fiscalAgentId));
        fiscalAgentUsers = [...fiscalAgentUsers, ...createSaveFiscalAgentUsersArray(fiscalAgentId, grantManagementUsers, PTS_ROLES.grantsManagementUser.id)];

        saveList.push(createSaveFiscalAgentUsersObject(fiscalAgentId, fiscalAgentUsers));
    }

    return saveList;
}

function createSaveFiscalAgentUsersObject(fiscalAgentId, fiscalAgentUsers) {
    return {
        fiscalAgentId,
        fiscalAgentUsers: fiscalAgentUsers.filter(f => !isNullOrUndefined(f))
    };
}

function createSaveFiscalAgentUsersArray(fiscalAgentId, fiscalAgentUsers, ptsRole) {
    if(isArrayNullOrEmpty(fiscalAgentUsers)) return [];

    const fiscalAgentUserDetails = fiscalAgentUsers.map(user => {
        const userDetails = user.split(fiscalAgentUserDelimiter);
        return userDetails.length === 2 ? createSaveFiscalAgentUser(userDetails[1], ptsRole) : null;
    });

    return fiscalAgentUserDetails.filter(f => !isNullOrUndefined(f));
}

function createSaveFiscalAgentUser(userId, role) {
    return {
        userId,
        role
    };
}

export function removeProgramUsersAfterFiscalAgentsUpdated(fiscalAgentIds, programUserIds) {
    if(isArrayNullOrEmpty(fiscalAgentIds)) return [];

    return programUserIds.filter(user => {
        const userDetails = user.split(fiscalAgentUserDelimiter);
        const fiscalAgentId = userDetails.length === 2 ? userDetails[0] : "";
        return fiscalAgentIds.includes(fiscalAgentId);
    });
}