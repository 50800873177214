import React from 'react';
import {inputStyles} from "../../../constants/inputConstants";
import AbstractRadioField, {BaseAbstractRadioFieldPropTypes} from "./AbstractRadioField";

const ColumnRadioField = ({
                        disabled,
                        error,
                        helpText,
                        inputClass,
                        legend,
                        legendClass = "",
                        name,
                        onChange,
                        options,
                        showClearResponseButton,
                        showLegend = true,
                        value,
                        wrapperClass = ""
                    }) => {

    wrapperClass += ` ${inputStyles.columnRadio.THEME}`;

    return (
        <AbstractRadioField
            disabled={disabled}
            error={error}
            helpText={helpText}
            inputClass={inputClass}
            legend={legend}
            legendClass={legendClass}
            name={name}
            onChange={onChange}
            options={options}
            showClearResponseButton={showClearResponseButton}
            showLegend={showLegend}
            usesColumns
            wrapperClass={wrapperClass}
            value={value}
        />
    );
};

ColumnRadioField.propTypes = {
    ...BaseAbstractRadioFieldPropTypes
};


export default ColumnRadioField;