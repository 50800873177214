import React from 'react';
import PropTypes from 'prop-types';
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../../../components/authorization/policies/MonitoringCompliancePolicy";
import {ReadMore} from "../../../../components/common/ReadMore";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import Button from "../../../../components/common/buttons/Button";
import HtmlDisplayBlock from "../../../../components/htmlDisplay/HtmlDisplayBlock";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {monitoring_compliance} from "../../../../constants/policyEvents";
import {createActivityResultObject} from "../capObjectFactory";
import {enableCapActivityResults, isStatusEditable} from "../capUtilities";

const ActivitySummaryGridRows = ({
                                 capActivities = [],
                                 getActivitySummary,
                                 getAssociatedProtocols,
                                 getCapActivityStatus,
                                 getEditButtonDetails,
                                 onClickDeleteActivity,
                                 onClickEditActivity,
                                 onClickSubmitActivityResults,
                                 readOnly = true,
                             }) => {

    const canSubmit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.SUBMIT);

    return isArrayNullOrEmpty(capActivities) ?
        <tr>
            <td colSpan={5} className={`center`}> No CAP activities.</td>
        </tr>
        : <>
        {
            capActivities.map(({
                                   acknowledgeNeedsRevisionComplete,
                                   activityId,
                                   activityNumber,
                                   activitySummary,
                                   capActivityStatus,
                                   capActivityResultDto = {},
                                   defaultActivitySummary,
                                   isDeletable,
                                   isResultsReadyToSubmit,
                                   isReviewEnabled,
                                   monitoringAssessmentId,
                               }) => {
                const canDeleteActivity = !readOnly && isStatusEditable(capActivityStatus);
                const activityResult = createActivityResultObject(monitoringAssessmentId, activityId, capActivityResultDto ?? {});
                const {
                    activityResultId,
                    acknowledgeResultsNeedsRevisionComplete,
                    capActivityResultsStatus,
                } = activityResult;
                const canSubmitActivityResults = canSubmit && enableCapActivityResults(capActivityStatus) && isStatusEditable(capActivityResultsStatus);
                const associatedProtocols = getAssociatedProtocols(activityId);

                const editableContent = getEditButtonDetails(activityNumber, capActivityStatus, capActivityResultsStatus, isReviewEnabled);
                const isEditable = editableContent.isEditable;
                const activitySummaryFormatted = getActivitySummary(defaultActivitySummary, activitySummary);
                const capActivityStatusFormatted = getCapActivityStatus(capActivityStatus, capActivityResultsStatus, isResultsReadyToSubmit, acknowledgeNeedsRevisionComplete, acknowledgeResultsNeedsRevisionComplete);

                return (
                    <tr key={activityId} valign={'top'}>
                        <td className={"text-center"}>{activityNumber}</td>
                        <td><ReadMore text={activitySummaryFormatted}/></td>
                        <td><HtmlDisplayBlock html={associatedProtocols}/></td>
                        <td className={"text-center"}>{capActivityStatusFormatted}</td>
                        <td className={`action-no-wrap text-center`}>
                            <Button
                                name={`btnEdit_${activityId}`}
                                onClick={(e) => onClickEditActivity(e, activityId)}
                                label={editableContent.label}
                                buttonType={isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW}
                                showLabel={false}
                            />
                            {
                                canDeleteActivity &&
                                isDeletable &&
                                <Button
                                    name={`btnDelete_${activityId}`}
                                    onClick={(e) => onClickDeleteActivity(e, activityId)}
                                    label={`Delete CAP Activity ${activityNumber}`}
                                    buttonType={ButtonTypes.DELETE}
                                    showLabel={false}
                                />
                            }
                            {
                                isResultsReadyToSubmit &&
                                canSubmitActivityResults &&
                                onClickSubmitActivityResults &&
                                <Button
                                    name={`btnSubmitResults_${activityId}`}
                                    onClick={(e) => onClickSubmitActivityResults(e, activityId, activityResultId)}
                                    label={`Submit CAP Activity ${activityNumber} Results`}
                                    buttonType={ButtonTypes.SUBMIT_ICON}
                                    showLabel={false}
                                />
                            }
                        </td>
                    </tr>
                );
            })
        }
    </>;
};

ActivitySummaryGridRows.propTypes = {
    capActivities: PropTypes.arrayOf(PropTypes.object),
    getActivitySummary: PropTypes.func.isRequired,
    getAssociatedProtocols: PropTypes.func.isRequired,
    getCapActivityStatus: PropTypes.func.isRequired,
    getEditButtonDetails: PropTypes.func.isRequired,
    onClickDeleteActivity: PropTypes.func.isRequired,
    onClickEditActivity: PropTypes.func.isRequired,
    onClickSubmitActivityResults: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default ActivitySummaryGridRows;