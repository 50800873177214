import React from 'react';
import PropTypes from 'prop-types';
import {gridConstants} from "../../constants/inputConstants";

const GridColumn = ({
                        align = "",
                        children,
                        columnClass = "",
                        id,
                        isPageNavLinks,
                        isStickyLinks,
                        isPageNavContent,
                        large,
                        medium,
                        small,
                        xlarge,
                        xxlarge,
                    }) => {

    const className = "columns" +
        (columnClass ? ` ${columnClass}` : "") +
        (isPageNavLinks ? " pageNav__links " : "") +
        (isPageNavContent ? " pageNav__content " : "") +
        (xxlarge ? ` xxlarge-${xxlarge}` : "") +
        (xlarge ? ` xlarge-${xlarge}` : "") +
        (large ? ` large-${large}` : "") +
        (medium ? ` medium-${medium}` : "") +
        (small ? ` small-${small}` : ` small-${gridConstants.column.TWELVE}`) +
        (align ? ` align-self-${align}` : "");

    const childElements = isStickyLinks
        ? <div className="pageNav__links__sticky">{children}</div>
        : children;

    return (
        <div id={id} className={className}>
            {childElements}
        </div>
    );
};

const columnPropType = gridConstants.column.PROP_TYPE;

GridColumn.propTypes = {
    align: PropTypes.string,
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    columnClass: PropTypes.string,
    id: PropTypes.string,
    isStickyLinks: PropTypes.bool,
    isPageNavLinks: PropTypes.bool,
    isPageNavContent: PropTypes.bool,
    large: columnPropType,
    medium: columnPropType,
    small: columnPropType,
    xlarge: columnPropType,
    xxlarge: columnPropType,
};

export default GridColumn;