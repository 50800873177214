import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../common/inputs/SelectField';
import TextField from '../common/inputs/TextField';
import TextArea from '../common/inputs/TextArea';
import GridColumn from "../common/GridColumn";
import GridRow from "../common/GridRow";
import * as elementTypes from '../../constants/surveys/elementTypes';
import ActionBar from "../common/buttons/ActionBar";
import EditElementForm from "./EditElementForm";
import FormTable from "../common/FormTable";
import * as actionBarPositions from "../../constants/ActionBarPositions";

const SurveyElementSet = ({
							  isEditing,
							  editItemId,
							  changeElement,
							  isLoading,
							  onEditClick,
							  onDeleteClick,
							  cancelChanges,
							  saveElement,
							  cloneElementClick,
	                          elements,
							  orderArea,
							  isEditItemNew
						  }) => {

	const isAreaIncludeOrder = (elementType) => {
		return orderArea &&
			(elementType === elementTypes.TITLE
				|| elementType === elementTypes.SELECT);
	};

	const isAreaIncludeClone = (elementType) => {
		return cloneElementClick &&
			(elementType === elementTypes.TITLE);
	};

	return (
		<FormTable>
			{
				elements.map(function (element) {
					if (isEditing &&
						element.componentId === editItemId)
						return (
							<EditElementForm
								key={element.componentId}
								element={element}
								onElementChange={changeElement}
								isLoading={isLoading}
								onCancelChanges={cancelChanges}
								onSaveElement={saveElement}
								isEditItemNew={isEditItemNew}
							/>);

					let actionBar = (
						<ActionBar
							elementId={element.componentId}
							position={element.elementType === elementTypes.TITLE ? actionBarPositions.TOP : actionBarPositions.RIGHT}
							onEditClick={onEditClick ? (e) => onEditClick(e, element.componentId) : null}
							onCloneClick={isAreaIncludeClone(element.elementType) ? (e) => cloneElementClick(e, element.componentId) : null}
							onDeleteClick={onEditClick ? (e) => onDeleteClick(e, element) : null}
							onOrderClick={isAreaIncludeOrder(element.elementType) ? (e) => orderArea(e, element.componentId) : null}
							disabled={isEditing}
						/>);

					if (element.elementType === elementTypes.TITLE) {
						return (
							<GridRow key={element.componentId}>
								{actionBar}
								<GridColumn>
									<h1>{element.labelText}</h1>
									<p>{element.subtext}</p>
								</GridColumn>
							</GridRow>
						);
					}
					else if (element.elementType === elementTypes.SELECT) {
						return (
							<SelectField
								key={element.componentId}
								name={element.componentId}
								label={element.labelText}
								value={element.answer}
								showLabel={true}
								options={element.answerChoices}
								defaultOption={!element.isRequired ? "Not Applicable" : null}
								labelClass="medium-8"
								inputClass="medium-4"
								elementEditBar={actionBar}
							/>
						);
					}
					else if (element.elementType === elementTypes.TEXTBOX) {
						return (
							<TextField
								key={element.componentId}
								name={element.componentId}
								label={element.labelText}
								value={element.answer}
								showLabel={true}
								labelClass="medium-8"
								inputClass="medium-4"
								elementEditBar={actionBar}
							/>
						);
					}
					else if (element.elementType === elementTypes.TEXTAREA) {
						return (
							<TextArea
								key={element.componentId}
								name={element.componentId}
								label={element.labelText}
								value={element.answer}
								showLabel={true}
								labelClass="small-12"
								inputClass="small-12"
								elementEditBar={actionBar}
							/>
						);
					}
				})
			}
		</FormTable>
	);
};

SurveyElementSet.propTypes = {
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	onEditClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	isEditing: PropTypes.bool.isRequired,
	editItemId: PropTypes.string.isRequired,
	changeElement: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	cancelChanges: PropTypes.func.isRequired,
	saveElement: PropTypes.func.isRequired,
	cloneElementClick: PropTypes.func,
	orderArea: PropTypes.func,
	isEditItemNew: PropTypes.bool
};

export default SurveyElementSet;