import React from "react";
import PropTypes from "prop-types";
import 'flatpickr/dist/themes/light.css';
import DateTimePicker from 'react-flatpickr';
import {DateTimePickerType} from "../../../constants/inputConstants";
import InputLayout from "./InputLayout";
import moment from "moment";
import Button from "../buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {createFakeEvent} from "./inputUtility";
import {outputStandardDateFormat, outputStandardTimeOnlyFormat} from "../../shared/sharedDataUtilities";

const DateTimePickerField = ({
                                 align = "middle",
                                 disabled = false,
                                 dateTimePickerType = DateTimePickerType.Calendar,
                                 error,
                                 helpText,
                                 inputClass = "",
                                 label,
                                 labelClass = "",
                                 large,
                                 medium,
                                 name,
                                 onChange,
                                 showLabel,
                                 small,
                                 value = '',
                                 wrapperClass = ""
                             }) => {

    const flatPickrDisplayTimeFormat = "h:i K";
    const flatPickrDisplayDateFormat = "m/d/Y";
    const flatPickrInternalTimeFormat = "HH:mm";
    const flatPickrInternalDateFormat = "YYYY-MM-DD";
    const momentInputDateFormat = "h:mm A";
    const momentPickrDateFormat = "M/D/YYYY";
    const invalidDate = "Invalid date";
    const isValidDate = !!value && value !== invalidDate;
    const enableTime = dateTimePickerType !== DateTimePickerType.Calendar;
    const noCalendar = dateTimePickerType === DateTimePickerType.TimeOnly;
    const altFormat = noCalendar ? flatPickrDisplayTimeFormat : enableTime ? `${flatPickrDisplayDateFormat} ${flatPickrDisplayTimeFormat}` : flatPickrDisplayDateFormat;

    const handleChange = (dateObject, dateString) => {
        dateString = !value && noCalendar
            ? getInitialTimeOnlyValueHack()
            : dateString;

        onChange(createFakeEvent(dateString, name));
    };

    const getInitialTimeOnlyValueHack = () => {
        return "12:00";
    };

    const clearDate = () => {
        onChange(createFakeEvent("", name));
    };

    const options = {
        altFormat:  altFormat,
        allowInput: true,
        altInput: true,
        noCalendar: noCalendar,
        enableTime: enableTime,
        onChange: handleChange,
        class: name
    };
    let formatDateTime = null;

    if (!showLabel) labelClass += " is-visuallyhidden";

    wrapperClass += disabled ? " is-disabled" : "";

    if (error && error.length > 0) {
        wrapperClass += "has-error";
    }

    if (isValidDate) {
        const flatPickrInternalDateTimeFormat = noCalendar ? flatPickrInternalTimeFormat : enableTime ? `${flatPickrInternalDateFormat} ${flatPickrInternalTimeFormat}` : flatPickrInternalDateFormat;
        const momentInputDateTimeFormat = noCalendar ? momentInputDateFormat : enableTime ? `${momentPickrDateFormat} ${momentInputDateFormat}` : momentPickrDateFormat;

        formatDateTime = noCalendar ? outputStandardTimeOnlyFormat(value) : outputStandardDateFormat(value, enableTime);
        formatDateTime = !disabled
            ? moment(formatDateTime, momentInputDateTimeFormat, false).format(flatPickrInternalDateTimeFormat)
            : formatDateTime;
    }

    const editBar = (!disabled && isValidDate) ? <Button
        buttonType={ButtonTypes.CLEAR}
        label={`Clear`}
        showLabel={false}
        name={`btnClear`}
        onClick={clearDate}
    /> : <></>;

    const disabledOutput =
        <div className={`input--small__container`}>
            {
                disabled
                    ? <div className={`input input--mock`}>{formatDateTime}</div> :
                    <>
                        <DateTimePicker value={formatDateTime} options={options}/>
                        {editBar}
                    </>
            }
        </div>;


    return (
        <InputLayout
            align={align}
            disabled={disabled}
            error={error}
            helpText={helpText}
            inputClass={inputClass}
            label={label}
            labelClass={labelClass}
            large={large}
            medium={medium}
            name={name}
            showLabel={showLabel}
            small={small}
            wrapperClass={wrapperClass}
        >
            {disabledOutput}
        </InputLayout>
    );
};

DateTimePickerField.propTypes = {
    align: PropTypes.string,
    dateTimePickerType: PropTypes.number,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    inputClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    large: PropTypes.number,
    medium: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool,
    small: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    wrapperClass: PropTypes.string
};

export default DateTimePickerField;