const idDelimiter = "-";

function generateNewId(currentList, prefix, postFix = "", idPropName = "id") {
    let maxId = 0;
    for(let i = 0; i < currentList.length; i++) {
        let idParts = currentList[i][idPropName].split(idDelimiter);
        if(parseInt(idParts[1]) > maxId)
            maxId = parseInt(idParts[1]);
    }
    const newId = maxId + 1;
    return generateId(newId, prefix, postFix)
}

function generateId(index, prefix, postFix = "") {
    return `${prefix}${idDelimiter}${index}-${postFix}`;
}

export {
    generateNewId,
    generateId
};