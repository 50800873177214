import * as drStateComplaintObjectFactory from './StateComplaints/drStateComplaintObjectFactory';
import * as drConstants from "./drConstants";
import * as drStateComplaintConstants from "./StateComplaints/drStateComplaintConstants";
import * as drLocations from "./drLocations";
import * as drMediationObjectFactory from './Mediations/drMediationObjectFactory';

import * as drDueProcessObjectFactory from './DueProcesses/drDueProcessObjectFactory';

let summaryItems = null;
let complaints = getComplaints();
let resolutionTypes = null;
let districtComplaintTiles = null;

let mediation = null;
let mediationList = null;

let dueProcessList =null;
let districtDueProcessesByDate =  null;

export function getStateComplaintsSummary(dateWindowId="8") {
	if (summaryItems === null) {
		summaryItems = [
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("1", "Alachua", 2, 1, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("2", "Baker", 1, 0, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("3", "Bay", 1, 0, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("4", "Bradford", 1, 0, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("5", "Brevard", 1, 0, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("6", "Broward", 1, 0, 0, dateWindowId),
			drStateComplaintObjectFactory.createStateComplaintSummaryObject("7", "Calhoun", 1, 0, 0, dateWindowId)
		];
	}

	return summaryItems;
}

export function getComplaints(districtId = "district1", dateWindowId = "date1") {
	if (complaints === null) {
		complaints = [
			drStateComplaintObjectFactory.createComplaintObject({
				complaintNumber: "complaint1",
				complaintStatus: drStateComplaintConstants.ComplaintStatus.Open,
				dateComplaintReceived: "1/1/2019",
				dateComplaintFiled: "1/2/2019",
				dateWindowId,
				districtId,
				id: "id1",
				resolutionType: drStateComplaintConstants.ResolutionType.AlternativeResolution,
				alternativeResolutionType: "BRD",
				withinTimelineOverride: false
			}),
			drStateComplaintObjectFactory.createComplaintObject({
				complaintNumber: "complaint2",
				complaintStatus: drStateComplaintConstants.ComplaintStatus.Open,
				dateComplaintReceived: "1/1/2019",
				dateComplaintFiled: "1/2/2019",
				dateWindowId,
				districtId,
				id: "id2",
				resolutionType: drStateComplaintConstants.ResolutionType.ReportOfInquiry,
				withinTimelineOverride: false
			}),
		];
	}

	return complaints;
}

export function getComplaint(){
	return complaints[0];
}

export function getDisputeResolutionTypes() {
	if (resolutionTypes === null) {
		resolutionTypes = drConstants.DisputeResolutionType;
	}

	return resolutionTypes;
}

export function getDistrictComplaintTilesByDate(districtId, routeTemplate, dateWindowId) {
	if (!districtComplaintTiles) {
		districtComplaintTiles = [
			drStateComplaintObjectFactory.createComplaintTile({
				body: "body1",
				footer: "footer1",
				header: "header1",
				isNew: false,
				url: drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, districtId, routeTemplate)
			}),
			drStateComplaintObjectFactory.createComplaintTile({
				isNew: true,
				url: drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, districtId, drLocations.COMPLAINT_ID_NEW)
			})
		];
	}

	return districtComplaintTiles;
}

//************************************************** MOCK Data for mediation ****************************************
export function getMediationSummary() {
	if (summaryItems === null) {
		summaryItems = [
			drMediationObjectFactory.createMediationSummaryObject("1", "Alachua", 2, 1, 0, "3"),
			drMediationObjectFactory.createMediationSummaryObject("6", "Broward", 1, 0, 0, "1")
		];
	}

	return summaryItems;
}

export function getMediationById(mediationId) {
	if (mediation === null) {
		mediation =
			drMediationObjectFactory.createMediationObject(
				mediationId, //id,
				"dffa3ab7-7f95-40f4-89a2-3a2167ad41d9",
				"7b3d5c9b-0961-496e-be6b-01016d0ef917",
				"Withdrawn",
				"789",
				"2019-05-29T09:55:27.43-04:00",
				"Jane,Parent's Advocate,Other Parent Representative",
				"Other",
				null,

				"2019-06-10T09:55:27.43-04:00",
				"True",
				"True",

				"True",
				"xyz",
				"False",

				"2019-06-29T09:55:27.43-04:00",
				"FSU",
				"2019-06-05T09:55:27.43-04:00",
				"Mediator2",

				"True",
				"False",
				"True",
				"False",
				"True",

				"Mediation withdrawn or not held",
				"False",
				"True",

				"testing@201906121135"
			);
	}

	return mediation;
}

export function getMediations() {
    if(mediationList === null) {
        mediationList = [
            {
                id:"1",
                dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
                districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",
                mediationStatus:"Open",
                caseNumber:"2019-0001",
                dateRequested: Date.parse("2019-01-01"),
                requestedBy: "District,Parent",

                dateMeetingHeld: null,
                isAgreementActive: null,
                agreement: null,

                interpreterNeeded: false,
                typeOfInterpreter: null,

                dateMeetingScheduled: null,
                placeMeetingScheduled: null,
                dateMediatorAssigned: null,
                mediatorName: null,

                toldMediationNotRequired: undefined,
                toldRightsNotWaved: undefined,
                filedStateComplaint: undefined,
                filedDueProcess: undefined,
                mediationRelatedToDueProcess: false,
                agreementRelatedToDueProcess: false,

                comment: "1st medition of the year"
            },
            {
                id:"2",
                dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
                districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",
                mediationStatus:"Open",
                caseNumber:"2019-0002",
                dateRequested: Date.parse("2019-01-01"),
                requestedBy: "District,Parent",

                dateMeetingHeld: null,
                isAgreementActive: null,
                agreement: null,

                interpreterNeeded: false,
                typeOfInterpreter: null,

                dateMeetingScheduled: null,
                placeMeetingScheduled: null,
                dateMediatorAssigned: null,
                mediatorName: null,

                toldMediationNotRequired: undefined,
                toldRightsNotWaved: undefined,
                filedStateComplaint: undefined,
                filedDueProcess: undefined,

                mediationRelatedToDueProcess: false,
                agreementRelatedToDueProcess: false,

                comment: "2nd medition of the year"
            },
            {
                id:"3",
                dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
                districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",
                mediationStatus:"Open",
                caseNumber:"2019-0001",
                dateRequested: Date.parse("2019-01-01"),
                requestedBy: "District,Parent",

                dateMeetingHeld: null,
                isAgreementActive: null,
                agreement: null,

                interpreterNeeded: false,
                typeOfInterpreter: null,

                dateMeetingScheduled: null,
                placeMeetingScheduled: null,
                dateMediatorAssigned: null,
                mediatorName: null,

                toldMediationNotRequired: undefined,
                toldRightsNotWaved: undefined,
                filedStateComplaint: undefined,
                filedDueProcess: undefined,

                mediationRelatedToDueProcess: false,
                agreementRelatedToDueProcess: false,

                comment: "3rd medition of the year"
            },
        ];
    }

    return mediationList;
}

function saveMediations(newMediationList) {
    mediationList = newMediationList;
}

export {saveMediations};

//************************************************** MOCK Data for Due process ****************************************
export function getDueProcessSummary() {
	if (summaryItems === null) {
		summaryItems = [
			drDueProcessObjectFactory.createDueProcessSummaryObject("1", "Alachua", 2, 2, 1, 0, 1, 1, 0, 1,"3"),
			drDueProcessObjectFactory.createDueProcessSummaryObject("6", "Broward", 1, 2, 0, 1, 0,0, 1,0,"1")
		];
	}

	return summaryItems;
}

export function getDistrictDueProcessesByDate(dateWindowId, districtId) {
	if (districtDueProcessesByDate === null) {
		districtDueProcessesByDate = getDueProcesses().filter(a => a.dateWindowId == dateWindowId && a.districtId == districtId);
	}

	return districtDueProcessesByDate;
}

export function getDueProcesses() {
	if(dueProcessList === null) {
		dueProcessList = [
			{
				id:"1",
				dateWindowId:"9C644736-EEA5-43CA-1088-08D466512670",
				districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",

				caseNumber:"19-0001",
				dateReceived: Date.parse("2019-01-01"),
				dateFiled: Date.parse("2019-01-01"),

				alj: "adjudge1",
				extension: "No",
				expedited: "No",
				changeOfPlacement: undefined,

				dueProcessStatus:"Open",
				dateClosed: undefined,
				resolutionType: undefined,
				writtenSettlementAgreement: undefined,

				comment: "1st due process hearing request of the year"
			},
			{
				id:"2",
				dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
				districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",

				caseNumber:"19-0002",
				dateReceived: Date.parse("2019-01-01"),
				dateFiled: Date.parse("2019-01-01"),

				alj: "adjudge2",
				extension: "Yes",
				expedited: "No",
				changeOfPlacement: undefined,

				dueProcessStatus:"Open",
				dateClosed: undefined,
				resolutionType: undefined,
				writtenSettlementAgreement: undefined,

				comment: "2nd due process hearing request of the year"
			},
			{
				id:"3",
				dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
				districtId:"7B3D5C9B-0961-496E-BE6B-01016D0EF917",

				caseNumber:"19-0003",
				dateReceived: Date.parse("2019-01-01"),
				dateFiled: Date.parse("2019-01-01"),

				alj: "adjudge3",
				extension: "Yes",
				expedited: "No",
				changeOfPlacement: undefined,

				dueProcessStatus:"Closed",
				dateClosed: Date.parse("2019-02-01"),
				resolutionType: "mediation",
				writtenSettlementAgreement: undefined,

				comment: "3rd due process hearing request of the year"
			},
			{
				id:"4",
				dateWindowId:"DFFA3AB7-7F95-40F4-89A2-3A2167AD41D9",
				districtId:"33DC3A4B-1270-4838-864C-0189002DDB1E",

				caseNumber:"19-0003",
				dateReceived: Date.parse("2019-01-01"),
				dateFiled: Date.parse("2019-01-01"),

				alj: "adjudge3",
				extension: "Yes",
				expedited: "No",
				changeOfPlacement: undefined,

				dueProcessStatus:"Closed",
				dateClosed: Date.parse("2019-02-01"),
				resolutionType: "mediation",
				writtenSettlementAgreement: undefined,
				comment: "4th due process hearing request of the year"
			},
		];
	}

	return dueProcessList;
}

function saveDueProcesses(newDueProcessList) {
	dueProcessList = newDueProcessList;
}

export {saveDueProcesses};