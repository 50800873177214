import React from "react";
import PropTypes from "prop-types";
import * as config from "../../../../constants/config";
import FormTable from "../../../../components/common/FormTable";
import DisplayField from "../../../../components/common/inputs/DisplayField";

export const BpieSchoolInfoView = ({
                                   schoolBpie
                               }) => {
    if(schoolBpie === null)
        return null;

    return (
        <>
            <FormTable>
                <DisplayField
                    name={"msidNumber"}
                    label={"MSID Number:"}
                    showLabel
                    isInline>
                    {schoolBpie.msidNumber}
                </DisplayField>
                <DisplayField
                    name={"schoolDistrict"}
                    label={"School District:"}
                    showLabel
                    isInline>
                    {schoolBpie.schoolDistrict}
                </DisplayField>
                <DisplayField
                    name={"schoolName"}
                    label={"School Name:"}
                    showLabel
                    isInline>
                    {schoolBpie.schoolName}
                </DisplayField>
                <DisplayField
                    name={"schoolAdminName"}
                    label={"School Administrator Name:"}
                    showLabel
                    isInline>
                    {`${schoolBpie.schoolAdminFirstName} ${schoolBpie.schoolAdminLastName}`}
                </DisplayField>
                <DisplayField
                    name={"schoolAdminEmail"}
                    label={"School Administrator Email:"}
                    showLabel
                    isInline>
                    {schoolBpie.schoolAdminEmail}
                </DisplayField>
                <DisplayField
                    name={"schoolType"}
                    label={"School Type:"}
                    showLabel
                    isInline>
                    {schoolBpie.schoolType}
                </DisplayField>
                <DisplayField
                    name={"schoolFunction"}
                    label={"School Function / Setting:"}
                    showLabel
                    isInline>
                    {schoolBpie.schoolFunction}
                </DisplayField>
                <DisplayField
                    name={"charterSchoolStatus"}
                    label={"Is a Charter School:"}
                    showLabel
                    isInline>
                    {schoolBpie.charterSchoolStatus}
                </DisplayField>
                <DisplayField
                    name={"primaryServiceType"}
                    label={"Primary Service Type:"}
                    showLabel
                    isInline>
                    {schoolBpie.primaryServiceType}
                </DisplayField>
                <p>If any information is incorrect, <a href={`mailto:` + config.supportEmail}>contact us via email</a></p>
            </FormTable>
        </>
    );
};

BpieSchoolInfoView.propTypes = {
    schoolBpie: PropTypes.object.isRequired
};

export default BpieSchoolInfoView;
