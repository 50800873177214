import React, {useState} from 'react';
import PropTypes from "prop-types";
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy} from "../../../../components/authorization/policies/BpiePolicy";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {ReadMore} from "../../../../components/common/ReadMore";
import SubmissionModule from "../../../../components/common/SubmissionModule";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import {isSupportingEvidenceRequired} from "../../bpieUtilities";
import {getResponse} from "../bpieAssessmentFactory";

const BpieAssessmentSummaryView = ({
                                       bpieLabel,
                                       buildSubmissionModuleOptions,
                                       disableFeedback = false,
                                       displayFeedback = false,
                                       feedbackLabel = "",
                                       feedbackValue = "",
                                       indicators,
                                       isIncomplete,
                                       isSubmitted,
                                       onEditIndicator,
                                       priorityButtonClick,
                                       responses,
                                       saveFeedback,
                                       submitBpie,
                                       submitPolicyEvent,
                                       useIsModuleReview = true
                                   }) => {
    const [isModuleReview, setIsModuleReview] = useState(useIsModuleReview);

    const onSubmitClick = (feedback) => {
        if (isModuleReview)
            setIsModuleReview(false);
        else
            submitBpie(feedback);
    };

    const onSecondarySubmitClick = (feedback) => {
        saveFeedback(feedback);
    };

    const isMissingData = (indicator, implementationStatus, supportingEvidence) => {
        return isTrimmedStringEmpty(implementationStatus) || (isTrimmedStringEmpty(supportingEvidence) && isSupportingEvidenceRequired(indicator, implementationStatus));
    };

    const checkForMissingData = () => {
        let overallIsMissingData = false;
        for (let indicator of indicators) {
            const response = getResponse(responses, indicator.bpieIndicatorId);
            if (isMissingData(indicator, response.implementationStatus, response.supportingEvidence)) {
                overallIsMissingData = true;
                break;
            }
        }

        return overallIsMissingData;
    };

    const anyMissingData = checkForMissingData();
    const displayPriorityIndicatorMessage = !responses.some(r => r.isPriority);
    const submissionModuleOptions = buildSubmissionModuleOptions(anyMissingData, displayPriorityIndicatorMessage, isModuleReview);

    return (
        <>
            {
                !anyMissingData && displayPriorityIndicatorMessage && !isSubmitted &&
                <p className={`missing-data-highlight`}><strong>Next task - </strong> <Button
                    name={`bpiePriorityListLink`} label={`select your priority indicators`}
                    onClick={priorityButtonClick}
                    buttonType={ButtonTypes.LINK}/></p>
            }
            {
                !anyMissingData && !displayPriorityIndicatorMessage && !isSubmitted &&
                <ButtonBar position={displayFeedback ? ButtonBarPositions.TOP : ButtonBarPositions.STICKY} className={`bpiePriorityList`}>
                    <Button
                        label={`${bpieLabel} Assessment Priority List`}
                        name={`btnPriorityList`}
                        onClick={priorityButtonClick}
                    />
                </ButtonBar>
            }
            {
                anyMissingData && !isSubmitted &&
                <p className={`missing-data-highlight`}><strong>NOTE: all indicators highlighted below still need
                    responses.</strong></p>
            }

            <GridTable className={'bpie-summary-table'}>
                <thead>
                <tr>
                    <th className={`text-center`}>Indicator</th>
                    <th>Implementation Status</th>
                    <th className={`text-center`}>Indicator is Priority</th>
                    <th>Supporting Evidence</th>
                    {!isSubmitted && <th className={`text-center`}>Edit</th>}
                </tr>
                </thead>
                <tbody>
                {
                    indicators.map((indicator, key) => {
                        const response = getResponse(responses, indicator.bpieIndicatorId);
                        const missingDataClass = isMissingData(indicator, response.implementationStatus, response.supportingEvidence) && !isSubmitted ? "missing-data-highlight" : "";
                        const supportingEvidence = <ReadMore text={response.supportingEvidence} />;
                        return (
                            <tr key={key} valign={`top`} className={missingDataClass}>
                                <td className={`text-center`}>{indicator.indicatorNumber}</td>
                                <td>{response.implementationStatus}</td>
                                <td className={`text-center`}>{response.isPriority ? 'Yes' : 'No'}</td>
                                <td>{supportingEvidence}</td>
                                {!isSubmitted && <td className={'text-center'}>
                                    <Button
                                        name={`btnEdit_${indicator.indicatorNumber}`}
                                        onClick={(event) => onEditIndicator(event, indicator.indicatorNumber)}
                                        label={`Indicator Response ${indicator.indicatorNumber}`}
                                        buttonType={ButtonTypes.EDIT}
                                        showLabel={false}
                                    />
                                </td>}
                            </tr>);
                    })
                }
                </tbody>
            </GridTable>
            {
                !isIncomplete &&
                <Allow policy={BpiePolicy} policyEvent={submitPolicyEvent}>
                    <SubmissionModule
                        buttonBarPosition={displayFeedback ? ButtonBarPositions.STICKY_BOTTOM : ButtonBarPositions.MODULE}
                        disableSubmissionFeedback={disableFeedback}
                        displaySubmissionFeedback={displayFeedback}
                        handlePrimarySubmit={onSubmitClick}
                        hidePrimarySubmissionButton={submissionModuleOptions.hidePrimarySubmissionButton}
                        name={`BpieSubmission`}
                        primaryButtonLabel={submissionModuleOptions.primaryButtonLabel}
                        primaryDisabled={submissionModuleOptions.isSubmissionModuleDisabled}
                        secondaryButtonLabel={submissionModuleOptions.secondaryButtonLabel}
                        secondaryDisabled={submissionModuleOptions.secondaryDisabled}
                        handleSecondarySubmit={onSecondarySubmitClick}
                        hideSecondarySubmissionButton={submissionModuleOptions.hideSecondarySubmissionButton}
                        submissionFeedbackLabel={feedbackLabel}
                        submissionFeedbackValue={feedbackValue}
                        submissionMessage={submissionModuleOptions.submissionMessage}
                        submissionTitle={submissionModuleOptions.submissionTitle}
                    />
                </Allow>
            }
        </>
    );
}

BpieAssessmentSummaryView.propTypes = {
    bpieLabel: PropTypes.string.isRequired,
    buildSubmissionModuleOptions: PropTypes.func.isRequired,
    disableFeedback: PropTypes.bool,
    displayFeedback: PropTypes.bool,
    feedbackLabel: PropTypes.string,
    feedbackValue: PropTypes.string,
    indicators: PropTypes.array.isRequired,
    isIncomplete: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired,
    onEditIndicator: PropTypes.func.isRequired,
    priorityButtonClick: PropTypes.func.isRequired,
    responses: PropTypes.array.isRequired,
    saveFeedback: PropTypes.func,
    submitBpie: PropTypes.func.isRequired,
    submitPolicyEvent: PropTypes.string.isRequired,
    useIsModuleReview: PropTypes.bool
}

export default BpieAssessmentSummaryView;
