import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import ptsApi from "../../ptsApi";
import ptsAdminGmsUsersApi from "./ptsAdminGmsUsersApi";
import { isNullOrUndefined } from "../../../../components/common/commonUtilities";
import {PtsAdminGmsUsersView} from "./PtsAdminGmsUsersView";

export const PtsAdminGmsUsersContainer = ({
                                          actions,
                                          handleDataChanged,
                                      }) => {
    const [gmsUsers, setGmsUsers] = useState(null);
    const [editGmsUserId, setEditGmsUserId] = useState(null);

    const handleSaveGmsUser = (gmsUser) => {
        NotifyUser.clear(true);
        actions.executeApi(ptsAdminGmsUsersApi.saveGmsUser, [editGmsUserId, gmsUser])
            .then((result) => {
                handleDataChanged(false);
                setGmsUsers(result);
                setEditGmsUserId(null);
            });
    };

    const handleDeleteGmsUser = (gmsUserId) => {
        actions.executeApi(ptsAdminGmsUsersApi.deleteGmsUser, [gmsUserId])
            .then((result) => {
                setGmsUsers(result);
            });
    };

    useEffect(() => {
        ptsApi.getGmsUsers().then(setGmsUsers);
    }, []);

    if(isNullOrUndefined(gmsUsers))
        return null;

    return <PtsAdminGmsUsersView
        editGmsUserId={editGmsUserId}
        gmsUsers={gmsUsers}
        handleDataChanged={handleDataChanged}
        handleDeleteGmsUser={handleDeleteGmsUser}
        handleSaveGmsUser={handleSaveGmsUser}
        setEditGmsUserId={setEditGmsUserId}
    />;
};

PtsAdminGmsUsersContainer.propTypes = {
    actions: PropTypes.object,
    dataChanged: PropTypes.bool,
    handleDataChanged: PropTypes.func.isRequired,
};