import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../actions/layoutActions';
import {Link} from "react-router-dom";
import localStorage from "../components/shared/LocalStorage";
import * as systemLocations from "../constants/systemLocations";
import * as publicLocations from "../constants/publicLocations";

export class NotFoundPage extends React.PureComponent {
    componentDidMount() {
        this.props.actions.updatePageTitle("Page Not Found");
    }

    render() {
        return (
            <>
                <p>There was an error loading the page you requested.</p>
                {
                    localStorage.isLoggedIn() &&
                    <p>Please <Link to={systemLocations.DASHBOARD.path}>return to the dashboard</Link> to continue.</p>
                }
                {
                    !localStorage.isLoggedIn() &&
                    <p>Please <Link to={publicLocations.USER_SIGN_IN.path}>return to the login page</Link> to continue.</p>
                }
            </>
        );
    }
}

NotFoundPage.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(layoutActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (NotFoundPage);