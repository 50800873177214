import React from "react";
import PropTypes from "prop-types";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import Allow from "../authorization/Allow";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import * as policyEvents from "../../constants/policyEvents";
import * as teamMemberRoles from "../../constants/strategicPlan/teamMemberRoles";
import ActionBar from "../common/buttons/ActionBar";

const TeamMembers = ({
                         members,
                         isEditing,
                         isLoading,
                         onEditTeam,
                         filters
}) => {
    const points = members.filter(mem => mem.role === teamMemberRoles.BEESS_POINT.value);
    const leads = members.filter(mem => mem.role === teamMemberRoles.TEAM_LEAD.value);
    const consultants = members.filter(mem => mem.role === teamMemberRoles.CONSULTANT.value);
    const teammembers = members.filter(mem => mem.role === teamMemberRoles.TEAM_MEMBER.value);

    return (
        <section className="strategyPlanTeam" id={"team__members"}>
            <header>
                <h2>Team Members</h2>
            </header>

            {
                !isEditing &&
                filters.showButtons &&
                <Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_TEAM_MEMBER}>
	                <ActionBar
                        elementId={"team"}
		                onEditClick={onEditTeam}
	                    disabled={isLoading}
		                />
                </Allow>
            }

            <GridRow>
                <GridColumn small={"12"} large={"6"}>
	                {
		                points.length > 0 &&
		                <div>
			                <label htmlFor="plan__points">BEESS Point Person(s)</label>
			                <ul id="plan__points">
				                {
					                points.map(mem => <li key={mem.id}>{mem.text}</li>)
				                }
			                </ul>
		                </div>
	                }
	                {
		                leads.length > 0 &&
		                <div>
			                <label htmlFor="plan__leads">Team Lead(s)</label>
			                <ul id="plan__leads">
				                {
					                leads.map(mem => <li key={mem.id}>{mem.text}</li>)
				                }
			                </ul>
		                </div>
	                }

                    {
                        consultants.length > 0 &&
                        <div>
                            <label htmlFor="plan__consultants">Consultants</label>
                            <ul id="plan__consultants">
                                {
	                                consultants.map(mem => <li key={mem.id}>{mem.text}</li>)
                                }
                            </ul>
                        </div>
                    }
                </GridColumn>
	            {
		            teammembers.length > 0 &&
		            <GridColumn small={"12"} large={"6"}>
			            <div>
				            <label htmlFor="plan__members">Members</label>
				            <ul id="plan__members">
					            {
						            teammembers.map(mem => <li key={mem.id}>{mem.text}</li>)
					            }
				            </ul>
			            </div>
		            </GridColumn>
	            }

            </GridRow>
        </section>
    );
};

TeamMembers.propTypes = {
    members: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onEditTeam: PropTypes.func,
    filters: PropTypes.object.isRequired
};

export default TeamMembers;