import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import api from "../../../api/MonitoringComplianceReportsApi";
import PrintTable from "../../../components/common/PrintTable";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const CapPrintView = ({
	                      actions,
	                      dateWindowId,
	                      districtId,
	                      dateWindows
                      }) => {
	const [capInfo, setCapInfo] = useState(null);

	const handleUpdateCapInfo = (capInfo) => {
		setCapInfo(capInfo);
	};

	const getCapInfo = async () => {
		await actions.executeApi(api.GetCapReport, [districtId, dateWindowId])
			.then((results) => handleUpdateCapInfo(results));
	};

	useEffect(() => {
		getCapInfo();
	}, []);

	if (!capInfo)
		return null;

	return (
		<div className={"compliance-print-report"}>
			{
				capInfo.districtName === "" &&
				<p className={"no-report"}>No report available for this district.</p>
			}
			{
				capInfo.districtName !== "" &&
				<>
					<div className={"report-header"}>
						<h2>{fetchDate(dateWindows, dateWindowId)} Self-Assessment</h2>
						<h2>Correction of Noncompliance — {capInfo.districtName} District Report</h2>
					</div>
					<PrintTable>
						<thead>
						<tr>
							<th colSpan={"2"}>Noncompliance (NC)</th>
							<th className={"response-no-wrap"}># NC</th>
							<th># Total Corrected</th>
							<th>% Total Corrected</th>
							<th>CAP Corrected</th>
							<th>CAP Corrected Timely</th>
						</tr>
						</thead>
						<tbody>
						{
							capInfo.nonComplianceCorrections.map((record, index) => {
								const rowClass = index % 2 === 1 ? "odd" : "";
								return (
									<tr key={`${index}`} className={rowClass}>
										<td valign={"top"} className={"response-no-wrap"}>{record.protocol}</td>
										<td><HtmlDisplayBlock html={`${record.standardDescription}<br/>${record.citation}`}/>
										</td>
										<td className={"text-center"} valign={"top"}>{record.numberNonCompliance}</td>
										<td className={"text-center"} valign={"top"}>{record.totalCorrected}</td>
										<td className={"text-center"} valign={"top"}>{record.percentTotalCorrected}</td>
										<td className={"text-center"} valign={"top"}>{record.capCorrected}</td>
										<td className={"text-center"} valign={"top"}>{record.capCorrectedTimely}</td>
									</tr>
								);
							})
						}
						</tbody>
						<tfoot>
						<tr>
							<td className={"text-right"}>Total:</td>
							<td className={"text-center"}>{capInfo.totalCorrections}</td>
							<td className={"text-center"}>{capInfo.totalNonCompliance}</td>
							<td className={"text-center"}>{capInfo.totalCorrected}</td>
							<td className={"text-center"}>{capInfo.percentTotalCorrected}</td>
							<td className={"text-center"}>{capInfo.totalCapCorrected}</td>
							<td className={"response-no-wrap text-right"}><HtmlDisplayBlock
								html={capInfo.totalsForCapCorrectedTimely}/></td>
						</tr>
						</tfoot>
					</PrintTable>
				</>
			}
		</div>
	);
};

CapPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string.isRequired,
	dateWindows: PropTypes.array.isRequired
};

export default CapPrintView;