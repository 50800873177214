import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {convertToBoolean, convertToString, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {FieldsetLayout} from "../../../../components/common/inputs";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import DollarField from "../../../../components/common/inputs/DollarField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import TextArea from "../../../../components/common/inputs/TextArea";
import TextField from "../../../../components/common/inputs/TextField";
import NumberField from "../../../../components/common/inputs/NumberField";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import SelectField from "../../../../components/common/inputs/SelectField";
import PercentField from "../../../../components/common/inputs/PercentField";
import SearchableSingleSelectField from "../../../../components/common/inputs/SearchableSingleSelectField";
import {emptyGuid} from "../../../../constants/config";
import {gridConstants} from "../../../../constants/inputConstants";
import {isBudgetFteDisabled, isFteExceptionDisabled} from "../../gmsUtilities";
import {
    createBudgetElementConfigObject,
    createBudgetElementsConfigObject,
    createCodeList
} from "../../gmsObjectFactory";
import {OTHER_FUNCTION_CODES} from "./gmsApplicationBudgetConstants";
import {isBudgetElementDisabled} from "./gmsApplicationBudgetUtilities";

export const GmsApplicationBudgetTableLineItemEdit = ({
                                                          budgetLineItem,
                                                          canEditFeedback,
                                                          canViewFeedback,
                                                          codes,
                                                          editOnlyFeedback,
                                                          handleCancelEdit,
                                                          handleSave,
                                                          hasAgreementAllocation,
                                                          isAmendmentBudget,
                                                          isAmendmentDeleted,
                                                          totalColumns
                                                      }) => {
    const bliForm = useFormInputObject({...budgetLineItem});

    const {functionCodes, objectCodes, fundingCategoryCodes} = codes;
    const functionCodeList = createCodeList(functionCodes);
    const objectCodeList = createCodeList(objectCodes);
    const fundingCategoryCodeList = createCodeList(fundingCategoryCodes);

    const isExceptionDisabled = isFteExceptionDisabled(objectCodes, bliForm.objectCode.value);

    const isChecked = !!bliForm.exceptionToFteRule.value && !isExceptionDisabled;

    const fteExceptionValue = isChecked ? "" : "true";
    const includeCommentsInNarrative = convertToBoolean(bliForm.includeCommentsInNarrative.value);
    const isDeletedForAmendment = bliForm.isDeletedForAmendment.value || isAmendmentDeleted;
    const isEditable = (bliForm.isAmendmentLineItem.value && isAmendmentBudget) || !isAmendmentBudget;

    if (isExceptionDisabled && bliForm.exceptionToFteRule.value !== "") {
        bliForm.exceptionToFteRule.setValue("");
    }

    const isFteDisabled = isBudgetFteDisabled(objectCodes, bliForm.objectCode.value, bliForm.exceptionToFteRule.value);

    if (isFteDisabled && bliForm.fullTimeEquivalent.value !== 0) {
        bliForm.fullTimeEquivalent.setValue(0);
    }

    const handleClickCancelEdit = (event) => {
        event.preventDefault();
        handleCancelEdit();
    };

    const handleClickSave = () => {
        const isValid = validateForm();
        if (isValid) {
            const bli = convertFormInputObjectToObject(bliForm);

            bli.exceptionToFteRule = bli.exceptionToFteRule || "false";
            if (!hasAgreementAllocation)
                bli.percentage = 0;

            bli.includeCommentsInNarrative = bli.includeCommentsInNarrative || "false";
            if (isDeletedForAmendment) {
                bli.isDeletedForAmendment = true;
                bli.amount = 0;
            }

            handleSave(bli);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(bliForm);

        if (isTrimmedStringEmpty(bliForm.fundingCategoryCode.value))
            bliForm.fundingCategoryCode.setError("Required");

        if (isTrimmedStringEmpty(bliForm.functionCode.value))
            bliForm.functionCode.setError("Required");

        if (isTrimmedStringEmpty(bliForm.objectCode.value))
            bliForm.objectCode.setError("Required");

        if (isTrimmedStringEmpty(bliForm.accountDescription.value))
            bliForm.accountDescription.setError("Required");

        const fullTimeEquivalent = convertToString(bliForm.fullTimeEquivalent.value)
        if (isTrimmedStringEmpty(fullTimeEquivalent))
            bliForm.fullTimeEquivalent.setError("Required");

        const amount = convertToString(bliForm.amount.value)
        if (isTrimmedStringEmpty(amount))
            bliForm.amount.setError("Required");

        const percentage = convertToString(bliForm.percentage.value)
        if (hasAgreementAllocation && isTrimmedStringEmpty(percentage))
            bliForm.percentage.setError("Required");

        let isValid = inputFormIsValid(bliForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const createBudgetLineItemTitleDetailsObject = (title, titleClass = "") => ({
        budgetLineItemTitle: title,
        budgetLineItemTitleClass: `budgetEditRow__budgetLineItemTitle ${titleClass}`
    });

    const getBudgetLineItemDetails = () => {
        if(!isAmendmentBudget) {
            const isNewBudgetLineItem = budgetLineItem.budgetLineItemId === emptyGuid;
            return isNewBudgetLineItem
                ? createBudgetLineItemTitleDetailsObject(`New budget line item`, `budgetEditRow__budgetLineItemTitle--addedNew`)
                : createBudgetLineItemTitleDetailsObject(`Edit budget line item`, `budgetEditRow__budgetLineItemTitle--editCurrent`);
        }

        if(budgetLineItem.isDeletedForAmendment)
            return createBudgetLineItemTitleDetailsObject(`Current budget line item deleted - only amendment comments are editable`,
                `budgetEditRow__budgetLineItemTitle--deleted`)


        if(budgetLineItem.isAmendmentLineItem)
            return createBudgetLineItemTitleDetailsObject(`New budget line item - all fields are editable`,
                `budgetEditRow__budgetLineItemTitle--addedNew`);

        return createBudgetLineItemTitleDetailsObject(`Current budget line item - only certain fields are editable`,
            `budgetEditRow__budgetLineItemTitle--editCurrent`);
    };

    const {budgetLineItemTitle, budgetLineItemTitleClass} = getBudgetLineItemDetails();
    const budgetElementConfig = createBudgetElementsConfigObject();
    const disabledStatuses = createBudgetElementConfigObject(isDeletedForAmendment, editOnlyFeedback, !isEditable, isExceptionDisabled, isFteDisabled, !canEditFeedback);
    return (
        <tr className={`align-top`}>
            <td colSpan={totalColumns}>
                <FieldsetLayout fieldsetClass={`budgetLineItemTitle`} legend={budgetLineItemTitle} legendClass={budgetLineItemTitleClass} showLegend>
                    <DisplayField name={`entryNumber`} label={`Entry Number: `} showLabel isInline
                                  columnsMedium={gridConstants.column.SIX}>
                        {bliForm.entryNumber.value}
                    </DisplayField>

                    <GridRow medium={gridConstants.column.TWO} rowClass={`budgetEditRow`}>
                        <GridColumn>
                            <SelectField label={`Funding Category`} showLabel
                                         disabled={isBudgetElementDisabled(budgetElementConfig.fundingCategoryCode, disabledStatuses)}
                                         options={fundingCategoryCodeList} {...bliForm.fundingCategoryCode}/>

                            <SearchableSingleSelectField
                                {...bliForm.functionCode}
                                disabled={isBudgetElementDisabled(budgetElementConfig.functionCode, disabledStatuses)}
                                includeDefaultOption={false}
                                label={`Function`}
                                listHasTextField={OTHER_FUNCTION_CODES}
                                options={functionCodeList}
                                otherTextClass={`budgetEditRow__otherText`}
                                otherTextLabel={`Specify other function value (number)`}
                                isOtherTextNumberOnly
                            />

                            <SearchableSingleSelectField label={`Object Code`} options={objectCodeList}
                                                         disabled={isBudgetElementDisabled(budgetElementConfig.objectCode, disabledStatuses)}
                                                         includeDefaultOption={false} {...bliForm.objectCode} />

                            <CheckBoxField {...bliForm.exceptionToFteRule} checked={isChecked}
                                           disabled={isBudgetElementDisabled(budgetElementConfig.exceptionToFteRule, disabledStatuses)}
                                           label={`FTE Rule Exception`} labelClass={`--flush`}
                                           value={fteExceptionValue}/>

                            <TextField label={`Alt. Description`} showLabel
                                       disabled={isBudgetElementDisabled(budgetElementConfig.alternateDescription, disabledStatuses)}
                                       {...bliForm.alternateDescription} />

                        </GridColumn>
                        <GridColumn>
                            <TextArea label={`Account Description`} showLabel
                                      disabled={isBudgetElementDisabled(budgetElementConfig.accountDescription, disabledStatuses)}
                                      {...bliForm.accountDescription} />

                            <NumberField label={`FTE (decimal)`} {...bliForm.fullTimeEquivalent} decimalPlaces={3}
                                         showLabel
                                         disabled={isBudgetElementDisabled(budgetElementConfig.fullTimeEquivalent, disabledStatuses)}
                                         isPositiveNumberOnly/>

                            <DollarField
                                {...bliForm.amount}
                                disabled={isBudgetElementDisabled(budgetElementConfig.amount, disabledStatuses)}
                                isPositiveNumberOnly
                                label={`Amount (decimal)`}
                                showLabel
                                value={isAmendmentDeleted ? 0 : bliForm.amount.value}

                            />

                            {
                                hasAgreementAllocation &&
                                <PercentField label={`Agreement Allocation (decimal)`} {...bliForm.percentage}
                                              minValue={.01}
                                              showLabel
                                              disabled={isBudgetElementDisabled(budgetElementConfig.percentage, disabledStatuses)}
                                />
                            }
                        </GridColumn>
                    </GridRow>

                    {
                        isAmendmentBudget &&
                        <>
                            <TextArea
                                {...bliForm.amendmentComment}
                                disabled={isBudgetElementDisabled(budgetElementConfig.amendmentComment, disabledStatuses)}
                                label={`Amendment Comment${isDeletedForAmendment ? ` (Line item removed - amount automatically changed to zero)` : ``}`}
                                showLabel
                            />
                            <CheckBoxField
                                {...bliForm.includeCommentsInNarrative}
                                disabled={isBudgetElementDisabled(budgetElementConfig.includeCommentsInNarrative, disabledStatuses)}
                                label={`Include comments in narrative`}
                                checked={includeCommentsInNarrative}
                                value={includeCommentsInNarrative ? `` : `true`}
                            />
                        </>
                    }

                    {
                        canViewFeedback &&
                        <TextArea label={`Line Item Feedback`} showLabel {...bliForm.feedback}
                                  disabled={isBudgetElementDisabled(budgetElementConfig.feedback, disabledStatuses)}
                        />
                    }

                    <ButtonBar position={ButtonBarPositions.COLUMN} className={`budgetButtonContainer`}>
                        <Button buttonType={ButtonTypes.SAVE} label={`Save`} name={`btnSave`}
                                onClick={handleClickSave} showLabel/>

                        <Button buttonType={ButtonTypes.CANCEL} label={`Cancel`} name={`btnEdit`}
                                onClick={handleClickCancelEdit} showLabel/>
                    </ButtonBar>
                </FieldsetLayout>
            </td>
        </tr>
    );
};


GmsApplicationBudgetTableLineItemEdit.propTypes = {
    budgetLineItem: PropTypes.object,
    canEditFeedback: PropTypes.bool.isRequired,
    canViewFeedback: PropTypes.bool.isRequired,
    codes: PropTypes.object.isRequired,
    editOnlyFeedback: PropTypes.bool.isRequired,
    handleCancelEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    hasAgreementAllocation: PropTypes.bool.isRequired,
    isAmendmentBudget: PropTypes.bool.isRequired,
    isAmendmentDeleted: PropTypes.bool.isRequired,
    totalColumns: PropTypes.number.isRequired
};