import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as surveyActions from '../../actions/surveyActions';
import * as layoutActions from '../../actions/layoutActions';
import {catchError} from "../../actions/actionUtility";
import {NotifyUser} from "../../components/common/NotifyUser";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import SurveyElementSet from "../../components/survey/SurveyElementSet";
import * as elementTypes from '../../constants/surveys/elementTypes';
import AutoSave from '../../components/common/AutoSave';
import {emptyGuid} from "../../constants/config";
import LocalStorage from "../../components/shared/LocalStorage";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import * as publicLocations from "../../constants/publicLocations";
import {getParamsMultiple} from "../../components/layout/getParams";


export class TakeSurvey extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			disableAutoSave: false
		};

		this.back = this.back.bind(this);
		this.next = this.next.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.saveResponses = this.saveResponses.bind(this);
		this.getNextButtonText = this.getNextButtonText.bind(this);
		this.validate = this.validate.bind(this);
		this.titleElements = this.titleElements.bind(this);
		this.pageElements = this.pageElements.bind(this);
	}

	componentDidMount() {
        const params = getParamsMultiple(this.props.location.pathname, [surveyLocations.TAKE,publicLocations.SURVEYS_TAKE]);

		this.props.actions.tryLoadSurveyById(params.surveyInstanceId, params.respondentId)
			.then(() => {
				if(this.props.survey && this.props.survey.surveyRespondentId !== undefined) {
					this.props.actions.updatePageTitle(this.props.survey.title);

					this.props.actions.tryLoadSurveyElementsForTakeSurvey(params.surveyInstanceId, this.props.survey.surveyRespondentId)
						.then(() => {
							let startPage = 1;
							const titleElements = this.titleElements();
							let startElementSet;
							if (this.props.survey.lastViewedComponentId !== emptyGuid) {
								startElementSet = titleElements.filter(te => te.componentId === this.props.survey.lastViewedComponentId)[0];
								startPage = titleElements.indexOf(startElementSet) + 1;
							} else startElementSet = titleElements[0];

							this.props.actions.goToStep(startPage);

							if(startElementSet)
								this.props.actions.appendViewedComponentHistory(startElementSet.componentId);
						})
						.catch(error => {
							catchError(error)
							this.props.history.push(publicLocations.SURVEYS_LANDING.path.replace(publicLocations.SURVEY_RESPONDENT_ID, params.respondentId));
						});
				}
			})
			.catch(error => {
				catchError(error)
				this.props.history.push(publicLocations.SURVEYS_LANDING.path.replace(publicLocations.SURVEY_RESPONDENT_ID, params.respondentId));
			});
	}

	getNextButtonText(curStep, totalSteps) {
		return (curStep === totalSteps) ? "Finish" : "Next";
	}

	titleElements() {
		return this.props.elements.filter(e => e.elementType === elementTypes.TITLE);
	}

	pageElements(includeTitle = true) {
		const titleElements = this.titleElements()[this.props.currentStep - 1];
		const curElementSetId = !titleElements ? emptyGuid : titleElements.componentId;

		return this.props.elements.filter(e =>
			e.parentId === curElementSetId ||
			(includeTitle && e.componentId === curElementSetId));
	}

	handleFormChange(event) {
		const changedElement = Object.assign({}, this.props.elements.filter(e => e.componentId === event.target.id)[0]);
		changedElement.answer = event.target.value;
		this.props.actions.changeElementResponse(changedElement);
	}

	back(event) {
		event.preventDefault();

		const nextComponentId = this.titleElements()[this.props.currentStep - 1].componentId;
		this.props.actions.appendViewedComponentHistory(nextComponentId);
		this.props.actions.goToStep(this.props.currentStep - 1);
	}

	next(event) {
		if (this.validate()) {
			this.saveResponses(event)
				.then(() => {
					const nextComponent = this.titleElements()[this.props.currentStep];

					if (nextComponent !== undefined) {
						this.props.actions.appendViewedComponentHistory(nextComponent.componentId);
						this.props.actions.goToStep(this.props.currentStep + 1);
					}
					else {
						this.props.history.push(LocalStorage.isLoggedIn() ?
							surveyLocations.CONGRATULATIONS.path :
							publicLocations.SURVEYS_CONGRATULATIONS.path);
					}
				});
		}
	}

	validate() {
		const curChildren = this.pageElements(false);

		let isValid = true;
		for (let i = 0; i < curChildren.length; i++) {
			const currentChild = curChildren[i];

			if (currentChild.isRequired && currentChild.answer === "") {
				isValid = false;
				NotifyUser.Warning("The " + currentChild.labelText + " field is required.");
			}
		}

		return isValid;
	}

	saveResponses(event) {
		event.preventDefault();
		return new Promise((resolve) => {
			let curChildren = this.pageElements(false);
			if(curChildren.length === 0)
				curChildren = this.titleElements();
			const totalPages = this.titleElements().length;

			if (totalPages > this.props.currentStep) {
				this.props.actions.trySaveRespondentSurveyElements(curChildren, this.props.viewedComponentHistory)
					.then(() => resolve())
					.catch(error => catchError(error));
			}
			else {
				if (!this.state.disableAutoSave) {
					this.props.actions.trySubmitRespondentSurvey(
						curChildren,
						this.props.viewedComponentHistory,
						this.props.survey.surveyId,
						this.props.survey.dateWindowId)
						.then(() => resolve())
						.catch(error => catchError(error));

					this.setState({
						disableAutoSave: true
					});
				}
			}
		});
	}

	render() {
		if (this.props.survey.surveyId === undefined ||
			this.props.elements.length === 0) return null;

		const elementSets = this.titleElements();
		const totalElementSets = elementSets.length;

		const curSetElements = this.pageElements();

		return (
			<div>
				<BackNextPager backText="Back"
				               nextText={this.getNextButtonText(this.props.currentStep, totalElementSets)}
				               back={this.back}
				               next={this.next}
				               currentPage={this.props.currentStep}
				               totalPages={totalElementSets}
				               disableBack={this.props.currentStep === 1 || this.props.isLoading}
				               disableNext={this.props.isLoading}
				               isProgress
				/>

				<AutoSave save={this.saveResponses}>
					<SurveyElementSet elements={curSetElements}
					                  onFormChangeHandler={this.handleFormChange}/>
				</AutoSave>
			</div>
		);
	}
}

TakeSurvey.propTypes = {
	actions: PropTypes.object.isRequired,
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	survey: PropTypes.object,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	currentStep: PropTypes.number.isRequired,
	viewedComponentHistory: PropTypes.array.isRequired,
	disableAutoSave: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		elements: state.survey.selectedSurveyElements,
		survey: state.survey.selectedSurvey,
		currentStep: state.survey.currentStep,
		viewedComponentHistory: state.survey.viewedComponentHistory,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, surveyActions, layoutActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(TakeSurvey);