import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {isGuid} from "../commonUtilities";
import {extractFilename} from "./inputUtility";
import Button from "../buttons/Button";
import ButtonMock from "../buttons/ButtonMock";
import FieldsetLayout from "./FieldsetLayout";
import FileUploadButton from "./FileUploadButton";

const FileManagementField = ({
                                 canUpload,
                                 error,
                                 handleClickDownload,
                                 label="Upload File",
                                 legend,
                                 legendClass = `margin-bottom`,
                                 name,
                                 onChange,
                                 showLegend = true,
                                 value,
                                 wrapperClass,
                             }) => {
    const createValueObject = ({fileId = "", fileName = ""}) => ({fileId, fileName});
    const parseValueString = (string) => {
        try {
            return createValueObject(JSON.parse(string));
        } catch {
            return createValueObject({fileName: string});
        }
    };

    const {fileId, fileName} = parseValueString(value);

    const isValueGuid = isGuid(fileId);
    const onClick = isValueGuid ? () => handleClickDownload(fileId) : undefined;
    const fileUploadInput = document.getElementById(name);

    if (isValueGuid && fileUploadInput?.value)
        fileUploadInput.value = "";

    const disableUpload = !canUpload;
    const fileNameLabel = extractFilename(fileName);

    return (
        <FieldsetLayout
            error={error}
            legend={legend}
            legendClass={legendClass}
            showLegend={showLegend}
            wrapperClass={wrapperClass + " input-button"}
        >
            <FileUploadButton
                disabled={disableUpload}
                label={label}
                name={name}
                onChange={onChange}
            />
            <Button
                buttonType={ButtonTypes.DOWNLOAD}
                label={"Download File"}
                name={`${name}_download`}
                onClick={onClick}
            />
            <ButtonMock label={fileNameLabel}/>
        </FieldsetLayout>
    );
};

FileManagementField.propTypes = {
    canUpload: PropTypes.bool,
    error: PropTypes.string,
    handleClickDownload: PropTypes.func,
    label: PropTypes.string,
    legend: PropTypes.string,
    legendClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    showLegend: PropTypes.bool,
    value: PropTypes.string,
    wrapperClass: PropTypes.string,
};

export default FileManagementField;