import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {GmsFilterVisibilityRules} from "../../../../components/common/searchCriterias/GmsSearchCriteria";
import gmsManageDiscretionaryProjectsApi from "./gmsManageDiscretionaryProjectsApi";
import {createDiscretionaryProjectObject} from "./gmsManageDiscretionaryProjectsObjectFactory";
import {GmsManageDiscretionaryProjectsView} from "./GmsManageDiscretionaryProjectsView";

const GmsManageDiscretionaryProjectsContainer = ({
                                                     actions,
                                                     dataChanged,
                                                     gotoLocation,
                                                     handleDataChanged,
                                                     searchCriteria
                                                 }) => {
    const [discretionaryProjects, setDiscretionaryProjects] = useState(null);

    const handleSaveGmsDiscretionaryProject = async (discretionaryProjectId, project) => {
        await actions.executeApi(gmsManageDiscretionaryProjectsApi.saveDiscretionaryProject, [discretionaryProjectId, project])
        await initializeData(project.projectName);
    };

    const handleDeleteGmsDiscretionaryProject = async (discretionaryProjectId) => {
        if (!confirm(`Are you sure you want to delete this discretionary project?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            return;

        await actions.executeApi(gmsManageDiscretionaryProjectsApi.deleteDiscretionaryProject, [discretionaryProjectId]);
        await initializeData("");
    };

    const initializeData = async (updatedProjectName = null) => {
        handleDataChanged(false);
        const result = await actions.executeApi(gmsManageDiscretionaryProjectsApi.getDiscretionaryProjects)
        const projects = result.map(project => createDiscretionaryProjectObject(project));
        setDiscretionaryProjects(projects);
        updateProjectListFilter(projects, updatedProjectName);
    };

    const updateProjectListFilter = (projects, updatedProjectName) => {
        let criteria = {...searchCriteria};
        criteria.setProjectList(projects);

        if(!isNullOrUndefined(updatedProjectName)) {
            if (isTrimmedStringEmpty(updatedProjectName))
                criteria.projectName = "";
            else if (!isTrimmedStringEmpty(searchCriteria.projectName))
                criteria.projectName = updatedProjectName;
        }

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        initializeData();
    }, []);

    if (isNullOrUndefined(discretionaryProjects) || isNullOrUndefined(searchCriteria) || !searchCriteria.areRulesCurrent(GmsFilterVisibilityRules.DiscretionaryProject))
        return null;

    return (
        <GmsManageDiscretionaryProjectsView
            actions={actions}
            dataChanged={dataChanged}
            discretionaryProjects={discretionaryProjects}
            gotoLocation={gotoLocation}
            handleDataChanged={handleDataChanged}
            handleDeleteGmsDiscretionaryProject={handleDeleteGmsDiscretionaryProject}
            handleSaveGmsDiscretionaryProject={handleSaveGmsDiscretionaryProject}
            searchCriteria={searchCriteria}
        />
    );
};

GmsManageDiscretionaryProjectsContainer.propTypes = {
    actions: PropTypes.object,
    dataChanged: PropTypes.bool,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default GmsManageDiscretionaryProjectsContainer;
