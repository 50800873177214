import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {dr_api as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";

const disputeAdminApi = 'dispute-resolution/admin';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrAdminTeamMembersApi {
    static saveTeamMembers(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/teamMembers/`,
                RequestTypes.PUT,
                JSON.stringify(model),
                ApiSuccessMessages.admin.teamMembers.SAVED));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrAdminTeamMembersApi {
    static saveTeamMembers(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const DrAdminTeamMembersApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrAdminTeamMembersApi : MockDrAdminTeamMembersApi;
export default DrAdminTeamMembersApi;