import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";
import audit_outcomes from "../constants/auditOutcomes";
import * as sharedDataUtilities from "../components/shared/sharedDataUtilities";

export default function monitoringComplianceStudentCorrectionReducer(state = initialState.monitoringComplianceStudentCorrection, action) {
    switch (action.type) {
        case actionTypes.LOAD_COMPLIANCE_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    studentProtocolCorrectiveResults: action.data
                });
        case actionTypes.LOAD_COMPLIANCE_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: action.data
                });
        case actionTypes.CHANGE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT:
            return Object.assign(
                {},
                state,
                {
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId === action.data.studentProtocolCorrectiveResultId)
                            return Object.assign({}, action.data.studentProtocolCorrectiveResult);
                        else
                            return Object.assign({}, result);
                    })
                });
        case actionTypes.CHANGE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId === action.data.matrixProtocolCorrectiveResultId)
                            return Object.assign({}, action.data.matrixProtocolCorrectiveResult);
                        else
                            return Object.assign({}, result);
                    })
                });
        case actionTypes.REJECT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.studentProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.NEEDS_REVISION.value
                                });
                        }
                    })
                });
        case actionTypes.APPROVE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.studentProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.ACCEPTED.value
                                });
                        }
                    })
                });
        case actionTypes.RESET_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.studentProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.SUBMITTED.value
                                });
                        }
                    })
                });
        case actionTypes.REJECT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.matrixProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.NEEDS_REVISION.value
                                });
                        }
                    })
                });
        case actionTypes.APPROVE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.matrixProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.ACCEPTED.value
                                });
                        }
                    })
                });
        case actionTypes.RESET_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.matrixProtocolCorrectiveResultId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    status: audit_outcomes.SUBMITTED.value
                                });
                        }
                    })
                });
        case actionTypes.CREATE_COMPLIANCE_AUDIT_COMMENT:
            return Object.assign(
                {},
                state,
                {
                    isCommentInEditMode: true,
                    commentId: "",
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.auditId) return a;
                                        const comments = [...a.comments];
                                        comments.unshift(sharedDataUtilities.newComment(action.data.auditId));
                                        a.comments = comments;

                                        return a;
                                    })
                                });
                        }
                    }),
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.auditId) return a;
                                        const comments = [...a.comments];
                                        comments.unshift(sharedDataUtilities.newComment(action.data.auditId));
                                        a.comments = comments;

                                        return a;
                                    })
                                });
                        }
                    })
                }
            );
        case actionTypes.CANCEL_COMPLIANCE_AUDIT_COMMENT:
        case actionTypes.UPDATE_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isCommentInEditMode: false,
                    commentId: "",
                }
            );
        case actionTypes.EDIT_COMPLIANCE_AUDIT_COMMENT:
            return Object.assign(
                {},
                state,
                {
                    isCommentInEditMode: true,
                    commentId: action.data,
                }
            );
        case actionTypes.CHANGE_COMPLIANCE_AUDIT_COMMENT:
            return Object.assign(
                {},
                state,
                {
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.comment.auditId) return a;
                                        a.comments = [...a.comments].map(c => {
                                            c = Object.assign({}, c);

                                            if(c.auditCommentId !== action.data.comment.auditCommentId) return c;

                                            return action.data.comment;
                                        });

                                        return a;
                                    })
                                });
                        }
                    }),
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.comment.auditId) return a;
                                        a.comments = [...a.comments].map(c => {
                                            c = Object.assign({}, c);

                                            if(c.auditCommentId !== action.data.comment.auditCommentId) return c;

                                            return action.data.comment;
                                        });

                                        return a;
                                    })
                                });
                        }
                    })

                }
            );
        case actionTypes.ADD_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isCommentInEditMode: false,
                    commentId: "",
                    matrixProtocolCorrectiveResults: state.matrixProtocolCorrectiveResults.map(result => {
                        if (result.matrixProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.comment.auditId) return a;
                                        a.comments = [...a.comments].map(c => {
                                            c = Object.assign({}, c);

                                            if(c.auditCommentId !== "") return c;

                                            return {...action.data.comment, auditCommentId: action.data.auditCommentId};
                                        });

                                        return a;
                                    })
                                });
                        }
                    }),
                    studentProtocolCorrectiveResults: state.studentProtocolCorrectiveResults.map(result => {
                        if (result.studentProtocolCorrectiveResultId !== action.data.correctionId) {
                            return Object.assign({}, result);
                        } else {
                            return Object.assign(
                                {},
                                result,
                                {
                                    audits: [...result.audits].map(a => {
                                        a = Object.assign({}, a);
                                        if (a.auditId !== action.data.comment.auditId) return a;
                                        a.comments = [...a.comments].map(c => {
                                            c = Object.assign({}, c);

                                            if(c.auditCommentId !== "") return c;

                                            return {...action.data.comment, auditCommentId: action.data.auditCommentId};
                                        });

                                        return a;
                                    })
                                });
                        }
                    })
                }
            );
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
        case actionTypes.LOGOUT_AJAX_SUCCESS:
            return initialState.monitoringComplianceStudentCorrection;
        default:
            return state;
    }
}