import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../api/requests/RequestTypes";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import {mockResolveApiCall} from "../../../api/ApiUtility";
import {API_ROOT} from "../bpieLocations";
import {BpieApiMessages} from "../bpieConstants";

class ServerBpieAdminApi {
    static importMsidFile(files) {

        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let formData = new FormData();
        formData.append('file', files[0]);
        let request = AjaxRequestOptions(
            `${config.apiUrl}${API_ROOT}/schools/import`,
            RequestTypes.POST,
            formData,
            BpieApiMessages.SBPIE.MSID_IMPORT_SUCCESS);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static getSchoolImportErrors(){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/schools/importErrors`,
                RequestTypes.GET));
    }

    static getNewBpieInformation(){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/schools/bpies`,
                RequestTypes.GET));
    }

    static createSchoolBpies(){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/schools/bpies`,
                RequestTypes.POST));
    }

}


class MockBpieAdminApi {
    static importMsidFile() {
        return mockResolveApiCall({});
    }

    static getSchoolImportErrors() {
        return mockResolveApiCall({});
    }

    static getNewBpieInformation(){
        return mockResolveApiCall({});
    }
    static createSchoolBpies(){
        return mockResolveApiCall({});
    }
}

const BpieAdminApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieAdminApi: MockBpieAdminApi;
export default BpieAdminApi;