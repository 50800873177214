import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {setPageTitle} from "../../../../components/common/commonUtilities";
import PrintTable from "../../../../components/common/PrintTable";
import BpieReportApi from "../bpieReportApi";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import BpieSchoolFilterContainer from "../BpieSchoolFilterContainer";
import AccountUtility from "../../../../api/AccountUtility";

const SchoolSummaryReportContainer = ({
                                          actions,
                                          districtId,
                                          reportTitle,
                                          isStatusReport = true
                                      }) => {
    const [reportData, setReportData] = useState(null);
    const [school, setSchool] = useState("");
    const [schools, setSchools] = useState(null);
    const [schoolName, setSchoolName] = useState("");

    const validDistrictId = AccountUtility.getValidatedBpieDistrictId(districtId);

    const handleUpdateReportData = (result) => {
        setReportData(result);
    };

    const loadReportData = (event) => {
        event.preventDefault();
        setSchool(event.target.value);
    };

    const handleExcelExport = (event) => {
        event.preventDefault();
        const values = school.split(';');
        actions.executeApi(isStatusReport ? BpieReportApi.getSchoolStatusTrendReport : BpieReportApi.getSchoolStatusPriorityReport, [values[0], values[1]]);
    };

    useEffect(() => {
        setPageTitle(reportTitle);
    }, []);

    useEffect(() => {
        if (!school)
            return;
        const label = schools.find(s => s.value === school).text;
        const values = school.split(';');
        actions.executeApi(BpieReportApi.getSchoolSummaryData, [values[0], values[1]])
            .then((result) => {
                setSchoolName(label);
                handleUpdateReportData(result);
            });
    }, [school]);

    return <div className={"theme-bpie"}>
        <BpieSchoolFilterContainer
            actions={actions}
            districtId={validDistrictId}
            school={school}
            schools={schools}
            setSchools={setSchools}
            loadReportData={loadReportData}
        />
        {
            reportData &&
            <>
                <div className="report-header">
                    <h1>{reportTitle}</h1>
                    <h2>District: {reportData.districtName}</h2>
                    <h2>School: {schoolName}</h2>
                </div>
                <PrintTable>
                    <thead>
                    <tr>
                        <th>MSID Number</th>
                        <th>Completion Date</th>
                        <th>Date Meeting Held</th>
                        <th>Name of School</th>
                        {
                            reportData.indicators.map((indicator, key) => {
                                return (<th key={`indicatorTitle-${key}`}>{indicator.indicatorNumber}</th>);
                            })
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reportData.schoolBpies.map((bpie, key) => {
                            return (<tr key={`bpieRow-${key}`}>
                                <td>{bpie.msidNumber}</td>
                                <td>{bpie.dateCompletedOutput}</td>
                                <td>{bpie.dateMeetingHeldOutput}</td>
                                <td>{bpie.schoolName}</td>
                                {
                                    reportData.indicators.map((indicator, key) => {
                                        const response = bpie.assessment.responses.find(f => f.indicatorNumber === indicator.indicatorNumber);
                                        if(!response)
                                            return <td key={`indicatorValue-${key}`}>&nbsp;</td>;

                                        return (
                                            isStatusReport
                                                ?
                                                <td key={`indicatorValue-${key}`}>{`${response.statusAbbreviation}${response.isPriority ? "*" : ""}`}</td>
                                                :
                                                <td key={`indicatorValue-${key}`}>{`${response.isPriority ? "S" : ""}`}</td>
                                        )
                                    })
                                }
                            </tr>);
                        })
                    }
                    </tbody>
                </PrintTable>
                <br/>
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`pdfReportNavigation`}>
                    <Button buttonType={ButtonTypes.PRINT}
                            label={`Export to Excel`}
                            name={`btnExportToExcel`}
                            onClick={handleExcelExport}
                    />
                </ButtonBar>
            </>
        }
    </div>;
};


SchoolSummaryReportContainer.propTypes = {
    actions: PropTypes.object,
    districtId: PropTypes.string,
    reportTitle: PropTypes.string,
    isStatusReport: PropTypes.bool
};

export default SchoolSummaryReportContainer;