import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import {dispute_resolution as policyEvents}  from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class DueProcessPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.DUE_PROCESS]);
        this.updatePolicy();
    }

    updatePolicy(){
        //must have Edit to add/edit/delete due processes
        this._policy[policyEvents.dueProcess.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        //must have CERTIFY to audit due process responses
        this._policy[policyEvents.dueProcess.audits.AUDIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        //must have CERTIFY to review due process responses
        this._policy[policyEvents.dueProcess.audits.REVIEW] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        //must have CERTIFY to submit due process responses
        this._policy[policyEvents.dueProcess.audits.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };
    }

}

export {DueProcessPolicy, policyEvents};