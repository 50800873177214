
export const INSTITUTION_ID = ":institutionId";
export const DOCUMENT_ID = ":documentId";
export const PART_ID = ":partId";
export const SECTION_ID = ":sectionId";
export const DATE_WINDOW_ID = ":dateWindowId";
export const ROOT = `/Spp`;

//DISTRICT
export const LANDING_DISTRICT = {
    path: `${ROOT}`
};

export const DOCUMENT_DISTRICT = {
    path: `${LANDING_DISTRICT.path}/Document/${DOCUMENT_ID}`,
    params: {
        documentId: DOCUMENT_ID
    }
};

export const SECTION_DISTRICT = {
    path: `${DOCUMENT_DISTRICT.path}/Section/${SECTION_ID}`,
    params: {
        documentId: DOCUMENT_ID,
        sectionId: SECTION_ID
    }
};

export const DASHBOARD_TILE_DISTRICT = {
    path: DOCUMENT_DISTRICT.path.replace( DOCUMENT_ID , `{documentId}`),
    params: {
        documentId: DOCUMENT_ID
    }
};

//DOE
export const LANDING_DOE = {
    path: `${ROOT}/Institution`
};

export const LANDING_DOE_DATE_WINDOW = {
    path: `${LANDING_DOE.path}/Date/${DATE_WINDOW_ID}`,
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

export const DOCUMENT_DOE = {
    path: `${LANDING_DOE.path}/${INSTITUTION_ID}/Document/${DOCUMENT_ID}`,
    params: {
        institutionId: INSTITUTION_ID,
        documentId: DOCUMENT_ID
    }
};

export const SECTION_DOE = {
    path: `${DOCUMENT_DOE.path}/Section/${SECTION_ID}`,
    params: {
        institutionId: INSTITUTION_ID,
        documentId: DOCUMENT_ID,
        sectionId: SECTION_ID
    }
};

export const DASHBOARD_TILE_DOE = {
    path: LANDING_DOE_DATE_WINDOW.path.replace( DATE_WINDOW_ID , `{dateWindowId}`),
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

//ADMIN
export const ADMIN_DOCUMENTS = {
    path: `${ROOT}/Admin/Documents`
};

export const ADMIN_EDITOR = {
    path: `${ROOT}/Admin/Editor`,
    params: {}
};

export const ADMIN_EDITOR_DOCUMENT = {
    path: `${ADMIN_EDITOR.path}/Document/${DOCUMENT_ID}`,
    params: {
        documentId: DOCUMENT_ID
    }
};

export const ADMIN_EDITOR_PART = {
    path: `${ADMIN_EDITOR_DOCUMENT.path}/Part/${PART_ID}`,
    params: {
        documentId: DOCUMENT_ID,
        partId: PART_ID
    }
};

export const ADMIN_EDITOR_SECTION = {
    path: `${ADMIN_EDITOR_PART.path}/Section/${SECTION_ID}`,
    params: {
        documentId: DOCUMENT_ID,
        partId: PART_ID,
        sectionId: SECTION_ID
    }
}

export const ADMIN_EDITOR_SECTION_ORDER = {
    path: `${ADMIN_EDITOR_SECTION.path}/order`,
    params: {
        documentId: DOCUMENT_ID,
        partId: PART_ID,
        sectionId: SECTION_ID
    }
};

export const ADMIN_COPY = {
    path: `${ROOT}/Admin/Copy`,
    params: {}
};

export const ADMIN_COPY_DOCUMENT = {
  path: `${ADMIN_COPY.path}/Document/${DOCUMENT_ID}`,
  params: {
      documentId: DOCUMENT_ID
  }
};

export const ADMIN_COPY_PART = {
    path: `${ADMIN_COPY_DOCUMENT.path}/Part/${PART_ID}`,
    params: {
        documentId: DOCUMENT_ID,
        partId: PART_ID
    }
};

