import React from "react";
import {FilterView, FilterViewPropTypes} from "../../components/common/searchCriterias/FilterView";
import {DISPUTE_RESOLUTION_CONTEXT} from "../../constants/contexts";

export const DrFilter = ({
                             dateWindows,
                             handleExport,
                             handleSearchFiltersChange,
                             numberOfFilterColumns = 0,
                             searchCriteria
                         }) => {
    return <FilterView
        context={DISPUTE_RESOLUTION_CONTEXT}
        dateWindows={dateWindows}
        handleExport={handleExport}
        handleSearchFiltersChange={handleSearchFiltersChange}
        numberOfFilterColumns={numberOfFilterColumns}
        searchCriteria={searchCriteria}
    />;
};

DrFilter.propTypes = FilterViewPropTypes;