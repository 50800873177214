import PropTypes from "prop-types";
import React, {Fragment} from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import GridTable from "../../../../../components/common/GridTable";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../../constants/config";

export const BpieDistrictPieProgressMonitoringsSummaryView = ({
                                                                  progressMonitorings = [],
                                                                  handleAddEditProgressMonitoring,
                                                                  handleRemoveProgressMonitoring,
                                                                  handleViewActionStepUpdates
                                                              }) => {
    const handleClickEditProgressMonitoring = (event, progressMonitoringId) => {
        event.preventDefault();
        handleAddEditProgressMonitoring(progressMonitoringId);
    };

    const handleClickAddProgressMonitoring = (event) => {
        event.preventDefault();
        handleAddEditProgressMonitoring(emptyGuid);
    };

    const handleClickRemoveProgressMonitoring = (event, progressMonitoringId) => {
        event.preventDefault();
        handleRemoveProgressMonitoring(progressMonitoringId);
    };

    const handleClickViewActionStepUpdates = (event, progressMonitoringId) => {
        event.preventDefault();
        handleViewActionStepUpdates(progressMonitoringId);
    };

    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
    const buttonType = isEditable ? ButtonTypes.EDIT : ButtonTypes.VIEW;
    const labelType = isEditable ? "Edit" : "View";

    return <>
        <GridTable className={`dpieProgressMonitoringsSummary`}>
            <thead>
            <tr>
                <th>Progress Monitoring</th>
                <th className={`text-center`}>Total Action Updates</th>
                <th className={`text-center`}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
                progressMonitorings.map((progressMonitoring, index) => {
                    return (
                        <tr key={index}>
                            <td className={`text-center`}>{progressMonitoring.dateMeetingHeldOutput}</td>
                            <td className={`text-center`}>{progressMonitoring.actionStepUpdates.length}</td>
                            <td className={`actions text-center`}>
                                <Button
                                    buttonType={buttonType}
                                    label={`${labelType} Progress Monitoring held on ${progressMonitoring.dateMeetingHeldOutput}`}
                                    name={`btnEditProgressMonitoring-${index}`}
                                    onClick={(event) => handleClickEditProgressMonitoring(event, progressMonitoring.dpieProgressMonitoringId)}
                                    showLabel={false}
                                />
                                {
                                    isEditable &&
                                    <Button
                                        buttonType={ButtonTypes.DELETE}
                                        label={`Remove Progress Monitoring held on ${progressMonitoring.dateMeetingHeldOutput}`}
                                        name={`btnRemoveProgressMonitoring-${index}`}
                                        onClick={(event) => handleClickRemoveProgressMonitoring(event, progressMonitoring.dpieProgressMonitoringId)}
                                        showLabel={false}
                                    />
                                }
                                <Button
                                    buttonType={ButtonTypes.CONTENT}
                                    label={`Action Step Updates for Progress Monitoring held on ${progressMonitoring.dateMeetingHeldOutput}`}
                                    name={`btnViewActionStepUpdates-${index}`}
                                    onClick={(event) => handleClickViewActionStepUpdates(event, progressMonitoring.dpieProgressMonitoringId)}
                                    showLabel={false}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            {
                isEditable &&
                <tr>
                    <td colSpan={3} className={`text-center`}>
                        <Button
                            buttonType={ButtonTypes.ADD}
                            btnClass={`button--large`}
                            label={`Add Progress Monitoring`}
                            name={`btnAddProgressMonitoring`}
                            onClick={handleClickAddProgressMonitoring}
                            showLabel
                        />
                    </td>
                </tr>
            }
            {
                !isEditable && progressMonitorings.length === 0 &&
                <tr>
                    <td colSpan={3} className={`text-center`}>
                        No progress monitoring details available.
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>;
};

BpieDistrictPieProgressMonitoringsSummaryView.propTypes = {
    progressMonitorings: PropTypes.array.isRequired,
    handleAddEditProgressMonitoring: PropTypes.func.isRequired,
    handleRemoveProgressMonitoring: PropTypes.func.isRequired,
    handleViewActionStepUpdates: PropTypes.func.isRequired
};