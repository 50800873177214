import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintActionDeadlinesEdit from "./DrStateComplaintActionDeadlinesEdit";
import DrStateComplaintStudentEdit from "../Students/DrStateComplaintStudentEdit";
import FormTable from "../../../components/common/FormTable";
import SelectField from "../../../components/common/inputs/SelectField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import * as drStateComplaintConstants from "../drStateComplaintConstants";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";

const DrStateComplaintActionComponentEdit = ({
	                                             action,
												 dateOrdered,
	                                             handleSaveActionAsync,
	                                             handleDataChanged,
												 onScroll: handleScroll,
	                                             setEditActionId,
	                                             showCancel,
	                                             studentExceptionalities
                                             }) => {

	const actionForm = useFormInputObject(drObjectFactory.createActionObject(action), () => handleDataChanged());

	const validateForm = (form) => {
		const students = form.students;
		if (students) {
			for (let index in students) {
				const student = students[index];
				if (!student.primaryExceptionalityType || !student.isPreK) {
					NotifyUser.Warning("All student data is required.");
					return false;
				}
			}
		}
		return true;
	};

	const handleClickSave = async () => {
		const form = convertFormInputObjectToObject(actionForm);
		if (validateForm(form)) {
			const result = await handleSaveActionAsync(form);
			if (result) {
				handleClickCancel();
			}
		}
	};

	const handleClickCancel = () => {
		handleDataChanged(false);
		setEditActionId('');
	};

	useEffect(() => {
		handleScroll();
	}, []);

	return (
		<>
			<FormTable>
				{
					!action.id &&
					<DisplayField label={`New Action`} name={`newAction`} showLabel={false}>
						<h2>New Action</h2>
					</DisplayField>
				}
				{
					!actionForm.actionType.value &&
					<DisplayField
						columnsMedium={6}
						label={"Type"}
						name={"actionType"}
						showLabel
					>
						{drStateComplaintConstants.ComplaintActionType.ToBeCompleted}
					</DisplayField>
				}
				{
					actionForm.actionType.value &&
					<SelectField
						medium={6}
						label="Type"
						name="actionType"
						showLabel
						options={drStateComplaintConstants.IssueActionTypeList}
						includeDefaultOption={false}
						{...actionForm.actionType}
					/>
				}
				<TextAreaEditor
					label="Description"
					name="description"
					showLabel
					{...actionForm.description}
				/>
				<DrStateComplaintActionDeadlinesEdit
					dateOrdered={dateOrdered}
					{...actionForm.deadlines}
				/>

				{
					studentExceptionalities &&
					studentExceptionalities.length > 0 &&
					<DrStateComplaintStudentEdit
						{...actionForm.students}
						studentExceptionalities={studentExceptionalities}
					/>
				}
			</FormTable>
			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name="btnSave"
				        label="Save"
				        buttonType={ButtonTypes.SAVE}
				        onClick={() => handleClickSave()}/>
				{
					showCancel &&
					<Button name="btnCancel"
					        label="Cancel"
					        buttonType={ButtonTypes.CANCEL}
					        onClick={() => handleClickCancel()}/>
				}
			</ButtonBar>
		</>
	);
};

DrStateComplaintActionComponentEdit.defaultProps = {
	showCancel: true
};

DrStateComplaintActionComponentEdit.propTypes = {
	action: PropTypes.object.isRequired,
	dateOrdered: PropTypes.string.isRequired,
	handleSaveActionAsync: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	onScroll: PropTypes.func.isRequired,
	setEditActionId: PropTypes.func.isRequired,
	showCancel: PropTypes.bool,
	studentExceptionalities: PropTypes.array
};

export default DrStateComplaintActionComponentEdit;