import React from 'react';
import PropTypes from 'prop-types';
import {toDangerouslySetHtml} from "../common/HtmlUtilities";

const HtmlDisplay = ({
                         ariaLabelledBy,
                         className = "",
                         name,
                         html = "",
                         onClick,
                         isInline
                     }) => {
    if (!html) return <></>;

    let classes = `htmlDisplay is-dangerouslySetHtml ${className}`;
    classes += onClick ? "htmlDisplay--editable is-clickable" : "";

    const props = {
        'aria-labelledby': ariaLabelledBy,
        className: classes,
        id: name,
        onClick,
        dangerouslySetInnerHTML: toDangerouslySetHtml(html) //eslint-disable-line react/no-danger
    };

    return isInline ? <span {...props} /> : <div {...props} />;
};

HtmlDisplay.propTypes = {
    ariaLabelledBy: PropTypes.string,
    className: PropTypes.string,
    html: PropTypes.string,
    isInline: PropTypes.bool,
    name: PropTypes.string,
    onClick: PropTypes.func,
};

export default HtmlDisplay;