import React from "react";
import PropTypes from "prop-types";
import DisplayField from "../../../components/common/inputs/DisplayField";
import GridTable from "../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {convertBooleanToYesNoText} from "../../../components/shared/sharedDataUtilities";

const DrStateComplaintStudent = ({
	                                 students
                                 }) => {

	return (
		<DisplayField label={"Students"} name={"students"} showLabel>
			{
				!isArrayNullOrEmpty(students) &&
				<GridTable>
					<thead>
					<tr className="text-center">
						<th>Prekindergarten</th>
						<th>Primary Exceptionality</th>
					</tr>
					</thead>
					<tbody>
					{
						students.map((student, key) =>
							<tr className="text-center" key={key}>
								<td>{convertBooleanToYesNoText(student.isPreK)}</td>
								<td>{student.primaryExceptionalityType}</td>
							</tr>
						)
					}
					</tbody>
				</GridTable>
			}
			{
				isArrayNullOrEmpty(students) &&
				<div>None</div>
			}
		</DisplayField>
	);
};

DrStateComplaintStudent.propTypes = {
	students: PropTypes.array.isRequired
};

export default DrStateComplaintStudent;