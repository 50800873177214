import {getYesNoNotApplicableOption, getYesNoOption} from "../../components/common/inputs/inputUtility";
import LocalStorage from "../../components/shared/LocalStorage";
import {
    outputCurrentDate,
    outputStandardDateFormat,
    outputStandardTimeOnlyFormat
} from "../../components/shared/sharedDataUtilities";
import {createTeamMemberObject} from "../../components/users/teamMemberObjectFactory";
import {emptyGuid} from "../../constants/config";
import {dispute_resolution} from "../../constants/sharedData/dynamicSorting";
import {createSchoolInformationObject} from "../drObjectFactory";
import * as drStateComplaintConstants from "./drStateComplaintConstants";
import {
    convertToArray,
    dynamicSort,
    isNullOrUndefined,
    isObjectNullOrEmpty
} from "../../components/common/commonUtilities";
import {DrRoles, StateComplaintType} from "../drConstants";
import {getTeamMemberId} from "../drUtilities";

export const createStateComplaintSummaryObject = (
    districtId,
    districtName,
    totalOpenCases,
    totalFiled,
    activeComplaintsWithNonCompliance,
    responsesNeedingRevisionCount,
    closedComplaintsWithNonCompliance,
    responsesSubmittedCount,
    dateWindowId) =>
    ({
        districtId,
        districtName,
        totalOpenCases,
        totalFiled,
        activeComplaintsWithNonCompliance,
        responsesNeedingRevisionCount,
        closedComplaintsWithNonCompliance,
        responsesSubmittedCount,
        dateWindowId
    });

export const createComplaintTile = ({
                                        body = "New Complaint",
                                        footer,
                                        header,
                                        isNew = false,
                                        url
                                    } = {}) => ({
    body,
    footer,
    header,
    isNew,
    url
});

export const createReviewObject = ({
                                       dateDocumentationReceived,
                                       dateDocumentationReviewed,
                                       responseId,
                                       id
                                   } = {}) => ({
    dateDocumentationReceived: dateDocumentationReceived ? dateDocumentationReceived : "",
    dateDocumentationReviewed: dateDocumentationReviewed ? dateDocumentationReviewed : "",
    responseId,
    id
});

export const createResponseAuditsObject = ({
                                               auditId,
                                               comments,
                                               dateCreated,
                                               isDistrictUser,
                                               isLocked,
                                               outcomeString,
                                               responseId,
                                               auditCreatedBy
                                           } = {}) => ({
    auditId,
    comments,
    dateCreated,
    isDistrictUser,
    isLocked,
    outcomeString,
    responseId,
    auditCreatedBy
});

export const createResponseObject = ({
                                         corrections,
                                         dateCorrected,
                                         deadlineId,
                                         currentStatus,
                                         id,
                                         isReadyToSubmit,
                                         responseAudits,
                                         review
                                     } = {}) => ({
    corrected: !!dateCorrected,
    corrections: corrections ? convertToArray(corrections) : [],
    dateCorrected: dateCorrected ? dateCorrected : "",
    deadlineId,
    currentStatus,
    hasReview: !isObjectNullOrEmpty(review) && !!review.dateDocumentationReviewed,
    id,
    isReadyToSubmit,
    responseAudits: responseAudits ? responseAudits.map(audit => {
        return createResponseAuditsObject(audit);
    }) : [],
    review: !isObjectNullOrEmpty(review) ? createReviewObject(review) : {}
});

export const createDeadlineObject = ({
                                         actionId,
                                         isTimely,
                                         dateDue,
                                         overallDateDue,
                                         deadlineExtensions,
                                         id,
                                         isSubmittedToState,
                                         response
                                     } = {}) => ({
    actionId,
    isTimely,
    dateDue,
    overallDateDue,
    hasResponse: !isObjectNullOrEmpty(response) && !!response.id,
    id,
    isSubmittedToState: !!isSubmittedToState,
    response: !isObjectNullOrEmpty(response) ? createResponseObject(response) : {},
    deadlineExtensions: deadlineExtensions ? deadlineExtensions.map(extension => {
        return createDeadlineExtensionObject(extension);
    }) : [],
});

export const createDeadlineExtensionObject = ({
                                                  deadlineId,
                                                  extendedDateDue,
                                                  id,
                                                  reasonForExtension
                                              } = {}) => ({
    deadlineId,
    extendedDateDue,
    id,
    reasonForExtension: reasonForExtension || ""
});

export const createActionObject = ({
                                       id,
                                       actionType,
                                       complaintId,
                                       deadlines,
                                       description,
                                       idDisplay,
                                       issueId,
                                       students
                                   } = {}) => (
    {
        id,
        actionType: actionType ? actionType : issueId ? drStateComplaintConstants.IssueActionType.Corrective : undefined,
        complaintId,
        deadlines: deadlines ? deadlines.map(deadline => {
            return createDeadlineObject(deadline);
        }) : [],
        description,
        idDisplay,
        issueId,
        students: students ? students.map(student => {
            return createStudentObject(student);
        }) : []
    });

export const createCitationObject = ({
                                         id,
                                         isViolation,
                                         citationType,
                                         citationText
                                     } = {}) => (
    {
        id,
        isViolation: !isNullOrUndefined(isViolation) ? isViolation.toString() : "",
        citationType,
        citationText: citationText ? citationText : ""
    });

export const createStudentObject = ({
                                        id,
                                        isPreK,
                                        primaryExceptionalityType,
                                        secondaryExceptionalities
                                    } = {}) => (
    {
        id,
        isPreK: !isNullOrUndefined(isPreK) ? isPreK.toString() : "",
        primaryExceptionalityType,
        secondaryExceptionalities: secondaryExceptionalities ? secondaryExceptionalities : []
    });

export const createIssueObject = ({
                                      actionModels,
                                      generalCitationDetails,
                                      complaintId,
                                      issueCodeDescriptions,
                                      issueDescription,
                                      issueId,
                                      issueNumber,
                                      issueScope,
                                      students,
                                      citations
                                  } = {}) => ({
    actionModels: actionModels ? actionModels.map(action => {
        return createActionObject(action);
    }) : [],
    generalCitationDetails: generalCitationDetails ? generalCitationDetails : "",
    complaintId: complaintId ? complaintId : undefined,
    issueCodeDescriptions: issueCodeDescriptions ? issueCodeDescriptions : [],
    issueDescription: issueDescription ? issueDescription : "",
    issueId: issueId ? issueId : undefined,
    issueNumber,
    issueScope: issueScope ? issueScope : drStateComplaintConstants.IssueTypeList[0].value,
    students: students ? students.map(student => {
        return createStudentObject(student);
    }) : [],
    citations: citations ? citations.map(citation => {
        return createCitationObject(citation);
    }) : []
});

export const createIntakeObject = ({
                                       id,
                                       intakeComments,
                                       intakeRequirementResponseModels,
                                       additionalResponsesFromIntake
                                   } = {}) => ({
    ...{
        complaintId: id,
        intakeComments,
        intakeRequirementResponseModels: intakeRequirementResponseModels,
    },
    ...createAdditionalResponsesFromIntake(additionalResponsesFromIntake)
});

export const createAdditionalResponsesFromIntake = ({
                                                        complainantRelationshipToStudent,
                                                        otherRelationshipToStudent,
                                                        reachedAgeOfMajority,
                                                        isComplaintSubjectOfDueProcess,
                                                        extendRelatedToDueProcess,
                                                        relatedDueProcessId,
                                                        issuesInvolvedTheSamePartiesAndDecided,
                                                        extendIssuesInvolved,
                                                        involvedDueProcessId,
                                                        complainantRequireAssistance,
                                                        reasonForAssistance,
                                                        receiveCorrespondenceElectronically,
                                                        resolveComplaintThroughFacilitation,
                                                        resolveComplaintThroughMediation
                                                    } = {}) => ({
    complainantRelationshipToStudent: complainantRelationshipToStudent ? complainantRelationshipToStudent : [],
    otherRelationshipToStudent: otherRelationshipToStudent,
    reachedAgeOfMajority: getYesNoOption(reachedAgeOfMajority),
    isComplaintSubjectOfDueProcess: getYesNoOption(isComplaintSubjectOfDueProcess),
    extendRelatedToDueProcess: extendRelatedToDueProcess,
    relatedDueProcessId: relatedDueProcessId,
    issuesInvolvedTheSamePartiesAndDecided: getYesNoOption(issuesInvolvedTheSamePartiesAndDecided),
    extendIssuesInvolved: extendIssuesInvolved,
    involvedDueProcessId: involvedDueProcessId,
    complainantRequireAssistance: getYesNoOption(complainantRequireAssistance),
    reasonForAssistance: reasonForAssistance ? reasonForAssistance : [],
    receiveCorrespondenceElectronically: getYesNoOption(receiveCorrespondenceElectronically),
    resolveComplaintThroughFacilitation,
    resolveComplaintThroughMediation
});

export const createIntakeResponse = ({
                                         requirementId,
                                         responseId,
                                         dateResponded,
                                         isRequirementMet = "",
                                     }, requirementNumber) => ({
    responseId: !responseId ? emptyGuid : responseId,
    requirementId,
    dateResponded: !dateResponded ? outputCurrentDate() : dateResponded,
    isRequirementMet: getYesNoNotApplicableOption(isRequirementMet, false),
    requirementNumber
});

export const createTimelineObject = ({
                                         complaintId,
                                         extendedTimelineDate,
                                         timelineId,
                                         isRedacted,
                                         timelineResponseModels,
                                         complaintExtensions,
                                         complaintNonAbeyanceExtensions,
                                         currentResponseIdForRouting,
                                         displayRoutingSection,
                                         isRoutingStarted,
                                         routingChecklistEnabled
                                     } = {}) => ({
    complaintId,
    extendedTimelineDate: extendedTimelineDate ? extendedTimelineDate : undefined,
    timelineId,
    removeExtension: false,
    isRedacted: isRedacted ? isRedacted : false,
    reasonForExtension: '',
    timelineResponseModels,
    currentResponseIdForRouting,
    displayRoutingSection,
    isRoutingStarted,
    routingChecklistEnabled,
    complaintExtensions: complaintExtensions ? complaintExtensions.sort(dynamicSort(dispute_resolution.COMPLAINT_EXTENSIONS)) : [],
    complaintNonAbeyanceExtensions: complaintNonAbeyanceExtensions ? complaintNonAbeyanceExtensions.sort(dynamicSort(dispute_resolution.COMPLAINT_EXTENSIONS)) : []
});

export const createComplaintObject = ({
                                          abeyanceExitDate,
                                          abeyanceStartedDate,
                                          actionModels,
                                          additionalResponsesFromIntake,
                                          allActionsAccepted,
                                          alternativeResolutionType,
                                          closureProcessIndicatorData,
                                          closureProcessRequirementModels,
                                          complaintNumber,
                                          caseNumberCount,
                                          caseNumberYear,
                                          caseNumberType,
                                          complaintStatus,
                                          dateWithdrawnOrDismissedOrIssuedWithNoActions,
                                          dateComplaintFiled,
                                          dateComplaintReceived,
                                          dateOrdered,
                                          dateWindowId,
                                          disposition,
                                          dispositionOther,
                                          districtId,
                                          hasActions,
                                          hasCorrectiveActions,
                                          hasExtension,
                                          hasNonAbeyanceExtension,
                                          id,
                                          intakeRequirementVersionId,
                                          isIntakeRequirementEnabled,
                                          isIntakeRequirementCompleted,
                                          intakeComments,
                                          intakeRequirementResponseModels,
                                          timelineModel,
                                          isEditable,
                                          isOrdered,
                                          isSignedCompletionBeforeDueDate,
                                          issueModels,
                                          isActionEnabled,
                                          isActionStarted,
                                          isIssueEnabled,
                                          isIssueStarted,
                                          isResponsesEnabled,
                                          isTimelineEnabled,
                                          isTimelineStarted,
                                          lastActionDueDate,
                                          lastDocumentsReceivedDate,
                                          orderedDueDate,
                                          relatedMediationCaseNumbers,
                                          resolutionType,
                                          schoolInformationRecords,
                                          stateComplaintType,
                                          withinTimelineOverride,
                                          caseNoteModels,
                                          routingSummaryDetails,
                                          teamMembers,
                                          ProgramDirector,
                                          ComplaintLeadInvestigator,
                                          ComplaintBackupInvestigator,
                                          AdminSecretary,
                                          ContentSpecialist
                                      } = {}) => ({
    abeyanceExitDate: abeyanceExitDate ? abeyanceExitDate : undefined,
    abeyanceStartedDate: abeyanceStartedDate ? abeyanceStartedDate : undefined,
    additionalResponsesFromIntake,
    actionModels: actionModels ? actionModels.map(action => {
        return createActionObject(action);
    }) : [],
    allActionsAccepted,
    alternativeResolutionType: alternativeResolutionType ? alternativeResolutionType : "",
    closureProcessIndicatorData,
    closureProcessRequirementModels,
    complaintNumber: complaintNumber ? complaintNumber : "",
    caseNumberCount,
    caseNumberYear,
    caseNumberType,
    complaintStatus,
    dateComplaintFiled: dateComplaintFiled ? dateComplaintFiled : undefined,
    dateComplaintReceived: dateComplaintReceived ? dateComplaintReceived : undefined,
    dateOrdered: dateOrdered || "",
    dateWindowId,
    dateWithdrawnOrDismissedOrIssuedWithNoActions: dateWithdrawnOrDismissedOrIssuedWithNoActions ? dateWithdrawnOrDismissedOrIssuedWithNoActions : undefined,
    disposition: disposition ? disposition : "",
    dispositionOther: dispositionOther ? dispositionOther : "",
    districtId,
    hasActions,
    hasCorrectiveActions: getYesNoOption(hasCorrectiveActions),
    hasExtension,
    hasNonAbeyanceExtension,
    id,
    intakeRequirementVersionId,
    isIntakeRequirementEnabled,
    isIntakeRequirementCompleted,
    intakeComments: intakeComments || "",
    intakeRequirementResponseModels,
    timelineModel: timelineModel ? createTimelineObject(timelineModel) : undefined,
    isActionEnabled,
    isActionStarted,
    isEditable: !isNullOrUndefined(isEditable) ? isEditable : true,
    isIssuedWithNoCorrectiveActions: getYesNoOption(resolutionType === drStateComplaintConstants.ResolutionType.ReportOfInquiry ? !!dateWithdrawnOrDismissedOrIssuedWithNoActions : ""),
    isIssueEnabled,
    isIssueStarted,
    isOrdered,
    isSignedCompletionBeforeDueDate,
    isResponsesEnabled,
    isTimelineEnabled,
    isTimelineStarted,
    issueModels: issueModels ? issueModels.map(issue => {
        return createIssueObject(issue);
    }) : [],
    lastActionDueDate,
    lastDocumentsReceivedDate,
    orderedDueDate: orderedDueDate || "",
    relatedMediationCaseNumbers,
    resolutionType: resolutionType && resolutionType != "0" ? resolutionType : undefined,
    schoolInformationRecords: schoolInformationRecords ? schoolInformationRecords.map(schoolInfo => createSchoolInformationObject(schoolInfo)) : [],
    stateComplaintType: stateComplaintType ? stateComplaintType : StateComplaintType.Disability,
    withinTimelineOverride: !isNullOrUndefined(withinTimelineOverride) ? withinTimelineOverride.toString() : "",
    caseNoteModels: caseNoteModels || [],
    routingSummaryDetails,
    teamMembers: teamMembers ? teamMembers.map(teamMember => createTeamMemberObject(teamMember)) : [],
    ProgramDirector: ProgramDirector || getTeamMemberId(teamMembers, DrRoles.ProgramDirector.id),
    ComplaintLeadInvestigator: ComplaintLeadInvestigator || getTeamMemberId(teamMembers, DrRoles.ComplaintLeadInvestigator.id),
    ComplaintBackupInvestigator: ComplaintBackupInvestigator || getTeamMemberId(teamMembers, DrRoles.ComplaintBackupInvestigator.id),
    AdminSecretary: AdminSecretary || getTeamMemberId(teamMembers, DrRoles.AdminSecretary.id),
    ContentSpecialist: ContentSpecialist || getTeamMemberId(teamMembers, DrRoles.ContentSpecialist.id),
});

export const createRoutingCaseNoteObject = (responseFeedback, complaintId, authorId, associatedAreaId, startTime, endTime) => {
    return createCaseNoteObject({
        authorId,
        complaintId,
        content: responseFeedback,
        noteType: drStateComplaintConstants.CaseNoteType.RoutingInformation.id,
        dateAdded: outputCurrentDate(),
        startTime,
        endTime,
        associatedAreaId
    })
};

export const createCaseNoteObject = ({
                                         activityDuration,
                                         associatedAreaId,
                                         author,
                                         authorId,
                                         caseNoteId,
                                         complaintId,
                                         content,
                                         dateAdded,
                                         endTime,
                                         noteType,
                                         otherNoteType,
                                         startTime,
                                     } = {}) => ({
    activityDuration,
    associatedAreaId,
    author: author || `${LocalStorage.getLoggedInUser().FirstName} ${LocalStorage.getLoggedInUser().LastName}`,
    authorId,
    caseNoteId,
    complaintId,
    content,
    dateAdded: dateAdded ? outputStandardDateFormat(dateAdded, false) : "",
    endTime: endTime ? outputStandardTimeOnlyFormat(endTime) : "",
    noteType,
    otherNoteType: otherNoteType || "",
    startTime: startTime ? outputStandardTimeOnlyFormat(startTime) : "",
    updatedStartTime: outputStandardTimeOnlyFormat(startTime, true),
    updatedEndTime: outputStandardTimeOnlyFormat(endTime, true)
});

export const createAbeyanceObject = ({
                                         abeyanceExitDate,
                                         abeyanceStatus,
                                         abeyanceStartedDate,
                                         extendRelatedToDueProcess,
                                         relatedDueProcessId
                                     } = {}) => ({
    abeyanceExitDate,
    abeyanceStatus: abeyanceStatus || drStateComplaintConstants.AbeyanceStatus.NotRequired.id,
    abeyanceStartedDate,
    extendRelatedToDueProcess,
    relatedDueProcessId
});