import PropTypes from "prop-types";
import PrintTable from "../../../../components/common/PrintTable";
import React, {Fragment} from "react";
import {quartersDateSelectFieldOptions} from "../../../../constants/inputConstants";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {SourceDocumentationPrintView} from "./SourceDocumentationPrintView";

export const ImplementationsPrintView = ({
                                             activityNumberOutput,
                                             dateRangeType,
                                             handleFileDownload,
                                             implementationsByQuarter,
                                             quarter,
                                             sourceDocumentationFiles
                                         }) => {

    const isYearToDate = dateRangeType === quartersDateSelectFieldOptions.yearToDate.description;
    if (dateRangeType !== quarter.text && !isYearToDate)
        return null;

    const hasImplementations = implementationsByQuarter[quarter.id].length > 0;
    const implementationTitle = isYearToDate ? <p><strong>{quarter.text}</strong></p> : null;
    const implementationClass = isYearToDate ? `` : `implementations-container--flush-left`;
    if (!hasImplementations)
        return (<>
            {implementationTitle}
            <p className={`implementations-container ${implementationClass}`}><em>No implementations.</em></p>
        </>);

    return (
        <>
            {implementationTitle}
            <div className={`implementations-container ${implementationClass}`}>
                {
                    isYearToDate &&
                    <SourceDocumentationPrintView
                        activityNumberOutput={activityNumberOutput}
                        handleFileDownload={handleFileDownload}
                        isYearToDate
                        quarter={quarter}
                        sourceDocumentationFiles={sourceDocumentationFiles}
                    />
                }
                {
                    !hasImplementations &&
                    <p><strong>Implementations:</strong> <em>None</em></p>
                }
                {
                    hasImplementations &&
                    <>
                        <PrintTable>
                            <thead className={`no-head-group`}>
                            <tr className="text-center">
                                <th>
                                    Implementation
                                    Date
                                </th>
                                <th>
                                    Status Update
                                </th>
                                <th>
                                    # of Units
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                implementationsByQuarter[quarter.id].map((implementation, key) => {
                                    return <tr key={`${activityNumberOutput}_${quarter.id}_implementation-${key}`}
                                               valign={`top`}>
                                        <td className={`text-center`}>
                                            {implementation.implementationDateOutput}
                                        </td>
                                        <td>
                                            <em>{implementation.implementationName}</em>
                                            {
                                                !isTrimmedStringEmpty(implementation.implementationDetails) &&
                                                <>
                                                    {` - ${implementation.implementationDetails}`}
                                                </>

                                            }
                                        </td>
                                        <td className={`text-center`}>
                                            {implementation.implementedUnits}
                                        </td>
                                    </tr>;
                                })
                            }
                            <tr>
                                <td colSpan={2} className={`text-right`}>
                                    <strong>Total Units:</strong>
                                </td>
                                <td className={`text-center`}>{implementationsByQuarter[quarter.id].reduce((a, b) => a + b.implementedUnits, 0)}</td>
                            </tr>
                            </tbody>
                        </PrintTable>
                    </>
                }
            </div>
        </>
    );
};

ImplementationsPrintView.propTypes = {
    activityNumberOutput: PropTypes.string,
    dateRangeType: PropTypes.string,
    handleFileDownload: PropTypes.func.isRequired,
    implementationsByQuarter: PropTypes.array.isRequired,
    quarter: PropTypes.object.isRequired,
    sourceDocumentationFiles: PropTypes.array,
};
