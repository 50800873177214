import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import CollapsibleContainer from "../../../components/common/CollapsibleContainer";
import * as drStateComplaintConstants from "../drStateComplaintConstants";
import DrStateComplaintIssueComponentEdit from "./DrStateComplaintIssueComponentEdit";
import DrStateComplaintIssueComponent from "./DrStateComplaintIssueComponent";
import DrStateComplaintActionView from "../Actions/DrStateComplaintActionView";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import Tile from "../../../components/common/Tiles/Tile";
import * as drObjectFactory from '../drStateComplaintObjectFactory';
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";

const DrStateComplaintIssueView = ({
									   dateOrdered,
	                                   handleDeleteIssue,
                                       handleDeleteAction,
                                       handleSaveActionAsync,
                                       handleSaveIssue,
                                       handleScroll,
                                       handleDataChanged,
									   hasCorrectiveActions,
									   isLoading,
                                       issueCodes,
                                       issueModels,
                                       newIssueId,
                                       readOnly,
                                       studentExceptionalities
                                   }) => {

	const [editIssueId, setEditIssueId] = useState('');
	const [editActionId, setEditActionId] = useState('');
	const [editActionIssueId, setEditActionIssueId] = useState('');
	const [isEditMode, setIsEditMode] = useState(false);

	const noIssues = isArrayNullOrEmpty(issueModels);

	const handleClickSave = async (form) => {
		const issue = drObjectFactory.createIssueObject(form);
		const isSuccess = await handleSaveIssue(issue);
		if (isSuccess) {
			if(editIssueId !== drStateComplaintConstants.newIssueId)
				handleScroll(getIssueContainerId(editIssueId));

			setEditIssueId("");
		}
	};

	const handleClickCancel = () => {
		setEditIssueId("");
		handleDataChanged(false);
	};

	const handleClickDelete = (event, issueId) => {
		if (issueId && confirm("Are you sure you want to delete this issue?")) {
			handleDeleteIssue(issueId);
		}
	};

	const isNewIssue = (issueId) => {
	    const isNewIssueStatus = newIssueId === issueId;
	    if(isNewIssueStatus)
			handleScroll(getIssueContainerId(newIssueId));

	    return isNewIssueStatus;
    };

	const getIssueContainerId = (issueId) => {
		return `container-${issueId}`
	};

	const setActionEdit = (actionId, issueId) => {
		setEditActionId(actionId);
		setEditActionIssueId(issueId);
	};

	useEffect(() => {
		setIsEditMode(!!editIssueId || !!editActionId);
	}, [editActionId, editIssueId]);

	return (
		<>
			{
				!noIssues &&
				issueModels.map((i, key) => {
					const issue = drObjectFactory.createIssueObject(i);
					const actions = issue.actionModels;
					const isEditing = editIssueId === issue.issueId;
					const containerId = getIssueContainerId(issue.issueId);

					return <CollapsibleContainer
						id={containerId}
						key={key}
                        open={isNewIssue(issue.issueId)}
						trigger={`Issue ${issue.issueNumber}`}
						triggerDisabled={isEditing}>
						{
							isEditing ?
								<DrStateComplaintIssueComponentEdit
									isLoading={isLoading}
									issue={issue}
									issueCodes={issueCodes}
									onClickCancel={handleClickCancel}
									onClickSave={handleClickSave}
									handleDataChanged={handleDataChanged}
									onScroll={() => handleScroll(containerId)}
									studentExceptionalities={studentExceptionalities}
								/>
								:
								<DrStateComplaintIssueComponent
									handleClickEdit={setEditIssueId}
									handleClickDelete={handleClickDelete}
									isEditMode={isEditMode}
									issue={issue}
									readOnly={readOnly}
								/>
						}
						{
							!isEditMode && !readOnly && hasCorrectiveActions &&
							isArrayNullOrEmpty(actions) &&
							<TileContainer rowTilesLarge="1" rowTilesMedium="1">
								<Tile body={"Add Action"}
								      onClick={() => setActionEdit(drStateComplaintConstants.newActionId, issue.issueId)}
								      isNew={true}/>
							</TileContainer>
						}
						{
							(!isArrayNullOrEmpty(actions) || !!editActionId) &&
							<DrStateComplaintActionView
								actionModels={actions}
								dateOrdered={dateOrdered}
								editActionId={editActionId}
								editActionIssueId={editActionIssueId}
								isEditMode={isEditMode}
								issueId={issue.issueId}
								handleSaveActionAsync={handleSaveActionAsync}
								handleScroll={handleScroll}
								handleDataChanged={handleDataChanged}
								handleDeleteAction={handleDeleteAction}
								setEditActionId={(actionId) => setActionEdit(actionId, issue.issueId)}
								readOnly={readOnly}
							/>

						}
					</CollapsibleContainer>;
				})
			}
			{
				!isEditMode &&
				!readOnly &&
				!noIssues &&
				editIssueId !== drStateComplaintConstants.newIssueId &&
				<TileContainer rowTilesLarge="1" rowTilesMedium="1">
					<Tile body={"Add Issue"}
					      onClick={() => setEditIssueId(drStateComplaintConstants.newIssueId)}
					      isNew={true}/>
				</TileContainer>
			}
			{
				(
					noIssues ||
					editIssueId === drStateComplaintConstants.newIssueId
				) &&
				!readOnly &&
				<div id={drStateComplaintConstants.newIssueId}>
                    <DrStateComplaintIssueComponentEdit
						isLoading={isLoading}
                        issue={drObjectFactory.createIssueObject()}
                        issueCodes={issueCodes}
                        noIssues={noIssues}
                        onClickCancel={handleClickCancel}
                        onClickSave={handleClickSave}
                        handleDataChanged={handleDataChanged}
                        onScroll={() => handleScroll(drStateComplaintConstants.newIssueId)}
                        studentExceptionalities={studentExceptionalities}
                    />
                </div>
			}
			{
				noIssues && readOnly &&
				<p><strong>No issues have been created yet.</strong></p>
			}
		</>
	);
};

DrStateComplaintIssueView.propTypes = {
	dateOrdered: PropTypes.string.isRequired,
	handleDeleteIssue: PropTypes.func.isRequired,
    handleDeleteAction: PropTypes.func.isRequired,
    handleSaveActionAsync: PropTypes.func.isRequired,
    handleSaveIssue: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
	hasCorrectiveActions: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool,
    issueCodes: PropTypes.array.isRequired,
    issueModels: PropTypes.array.isRequired,
    newIssueId: PropTypes.string,
	readOnly: PropTypes.bool.isRequired,
    studentExceptionalities: PropTypes.array.isRequired
};

export default DrStateComplaintIssueView;