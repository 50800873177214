import React from 'react';
import PropTypes from "prop-types";
import {emptyGuid} from "../../../../constants/config";
import bpieDistrictApi from "../bpieDistrictApi";
import {BpieDistrictInfoForm} from "./BpieDistrictInfoForm";
import {BpieDistrictInfoView} from "./BpieDistrictInfoView";

const BpieDistrictInfoContainer = ({
                                       actions,
                                       dateWindowId,
                                       districtBpie,
                                       districtBpieId,
                                       districtId,
                                       handleDataChanged,
                                       isDistrictUser,
                                       isEditable,
                                       loadDistrictBpie
                                   }) => {
    const handleSaveDbpie = async (districtInfo) => {
        await actions.executeApi(bpieDistrictApi.saveDistrictBpie, [dateWindowId, districtId, districtBpieId, districtInfo])
            .then(() => {
                handleDataChanged(false);
                if(districtBpieId === emptyGuid)
                    loadDistrictBpie();
            });
    };

    return (
        <>
            {
                (!isEditable || isDistrictUser) &&
                <BpieDistrictInfoView
                    districtBpie={districtBpie}
                />
            }
            {
                isEditable && !isDistrictUser &&
                <BpieDistrictInfoForm
                    actions={actions}
                    districtBpie={districtBpie}
                    districtId={districtId}
                    handleDataChanged={handleDataChanged}
                    handleSaveDbpie={handleSaveDbpie}
                />
            }
        </>
    );
}

BpieDistrictInfoContainer.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtBpieId: PropTypes.string,
    districtId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    loadDistrictBpie: PropTypes.func.isRequired
};

export default BpieDistrictInfoContainer;