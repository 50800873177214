import React from 'react';
import PropTypes from "prop-types";
import {GMS_APPLICATION_SALARIES} from "../../gmsLocations";
import * as gmsLocations from "../../gmsLocations";
import {buildSuffixForSectionsInOverview} from "../../gmsUtilities";
import {convertElementTypeToBudgetType} from "../ApplicationBudget/gmsApplicationBudgetUtilities";
import {
    isElementTypeForDeliverables,
    isElementTypeForBudgets,
    isElementTypeForSalaries,
} from "./gmsApplicationOverviewUtilities";
import GmsApplicationOverviewSection from "./GmsApplicationOverviewSection";
import {emptyFunction} from "../../../../components/common/commonUtilities";

const GmsApplicationOverviewElement = ({
                                           applicationId,
                                           budgets,
                                           createApplicationRow = emptyFunction,
                                           elements,
                                       }) => {

    const getBudgetRow = (elementText, status, budgetType) => {
        const budget = budgets.find(f => f.budgetType === budgetType);
        const budgetId = budget?.budgetId || "";
        const applicationMissingBudget = !budgetId;
        if (applicationMissingBudget) return;

        const resolvedPath = gmsLocations.GMS_APPLICATION_BUDGET.path
            .replace(gmsLocations.APPLICATION_ID, applicationId)
            .replace(gmsLocations.BUDGET_ID, budgetId);

        return createApplicationRow(resolvedPath, elementText, status);
    };

    const getDeliverablesRow = (elementText, status) => {
        const resolvedPath = gmsLocations.GMS_APPLICATION_DELIVERABLES.path
            .replace(gmsLocations.APPLICATION_ID, applicationId)

        return createApplicationRow(resolvedPath, elementText, status);
    };

    const getSalariesRow = (elementText, status) => {
        const resolvedPath = gmsLocations.GMS_APPLICATION_SALARIES.path
            .replace(gmsLocations.APPLICATION_ID, applicationId)

        return createApplicationRow(resolvedPath, elementText, status);
    };

    return (
        <>
            {
                elements &&
                elements.map((element) => {
                    const {elementId, elements: subElements, elementText, status, elementType} = element;

                    if (isElementTypeForBudgets(elementType)) {
                        const budgetType = convertElementTypeToBudgetType(elementType);
                        return getBudgetRow(elementText, status, budgetType);
                    }

                    if (isElementTypeForDeliverables(elementType)) {
                        return getDeliverablesRow(elementText, status);
                    }

                    if (isElementTypeForSalaries(elementType)) {
                        return getSalariesRow(elementText, status);
                    }


                    const hasChildren = !!subElements;
                    if (hasChildren) {
                        let openSection = true;
                        let sectionTitle = elementText;
                        const suffix = buildSuffixForSectionsInOverview(subElements);
                        if (suffix) {
                            openSection = false;
                            sectionTitle += ` - All Sections ${suffix}`;
                        }

                        return <GmsApplicationOverviewSection sectionTitle={sectionTitle} key={elementText}
                                                              openSection={openSection}>
                            <GmsApplicationOverviewElement applicationId={applicationId} budgets={budgets}
                                                           elements={subElements}
                                                           createApplicationRow={createApplicationRow}/>
                        </GmsApplicationOverviewSection>;
                    }

                    const resolvedPath = gmsLocations.GMS_APPLICATION_SECTION.path
                        .replace(gmsLocations.APPLICATION_ID, applicationId)
                        .replace(gmsLocations.SECTION_ID, elementId);

                    return createApplicationRow(resolvedPath, elementText, status);
                })
            }
        </>
    );
};


GmsApplicationOverviewElement.propTypes = {
    applicationId: PropTypes.string.isRequired,
    budgets: PropTypes.array,
    createApplicationRow: PropTypes.func,
    elements: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
};
export default GmsApplicationOverviewElement;