import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import GridTable from "../../components/common/GridTable";
import ButtonIcon from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as strategicPlanActions from "../../actions/strategicPlanActions";

import GoalForm from "../../components/strategicPlanAdmin/GoalForm";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {NotifyUser} from "../../components/common/NotifyUser";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";

class ManageStrategicGoalsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onCreate = this.onCreate.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onSave = this.onSave.bind(this);
		this.isGoalValid = this.isGoalValid.bind(this);
		this.onDelete = this.onDelete.bind(this);

	}

	componentDidMount() {
	    this.props.actions.loadStrategicGoals();
	}

	componentWillUnmount() {
		this.props.actions.clearPlanData();
	}

	onCreate(event) {
		event.preventDefault();
		this.props.actions.createGoal();
	}

	onEdit(event, goalId) {
		event.preventDefault();
		this.props.actions.editGoal(goalId);
	}

	onChange(event, goalId) {
		event.preventDefault();
		const changedGoal = Object.assign({}, this.props.goals.filter(re => re.id === goalId)[0]);
		changedGoal[event.target.name] = event.target.value;
		this.props.actions.changeGoal(changedGoal);
	}

	onCancel(event) {
		event.preventDefault();
		this.props.actions.loadStrategicGoals();
	}

	onSave(event, goalId) {
		event.preventDefault();
		const savedGoal = Object.assign({}, this.props.goals.filter(re => re.id === goalId)[0]);

		if (this.isGoalValid(savedGoal)) {
			if (goalId === "")
				this.props.actions.addGoal(savedGoal);
			else
				this.props.actions.updateGoal(savedGoal);
		}
	}

	onDelete(event, goalId) {
		event.preventDefault();
		const deletedGoal = Object.assign({}, this.props.goals.filter(re => re.id === goalId)[0]);

		if (confirm("Are you sure you want to delete the FLDOE Strategic Goal?")) {
			this.props.actions.deleteGoal(deletedGoal);
		}
	}

	isGoalValid(goal) {
		let isValid = true;

		if (goal.text === "") {
			isValid = false;
			NotifyUser.Warning("Goal text is required.");
		}

		return isValid;
	}

	render() {
		if (isArrayNullOrEmpty(this.props.goals))
			return null;

		return (
			<section>
				<GridTable>
					<thead>
					<tr>
						<th>Goal</th>
						<th className="text-center">Actions</th>
					</tr>
					</thead>
					<tbody>
					{
						this.props.goals.map(g => {
							if (this.props.isEditing && this.props.editItemId === g.id)
								return (<GoalForm key={g.id}
								                  goal={g}
								                  isLoading={this.props.isLoading}
								                  onSave={(event) => this.onSave(event, g.id)}
								                  onCancel={this.onCancel}
								                  onChange={(event) => this.onChange(event, g.id)}/>);

							return (
								<tr key={g.id} id={g.id}>
									<td>{g.text}</td>
									<td className="button-cell">
										{
											<ButtonBar position={ButtonBarPositions.TABLE}>
												<ButtonIcon name={"edit|" + g.id}
												            label={"edit"}
												            onClick={(event) => this.onEdit(event, g.id)}
												            buttonType={ButtonTypes.EDIT}
												            showLabel={false}
												            disabled={this.props.isLoading || this.props.isEditing}
												/>
												<ButtonIcon name={"delete|" + g.id}
												            label={"delete"}
												            onClick={(event) => this.onDelete(event, g.id)}
												            buttonType={ButtonTypes.DELETE}
												            showLabel={false}
												            disabled={this.props.isLoading || this.props.isEditing}
												/>
											</ButtonBar>
										}
									</td>
								</tr>);
						})
					}
					</tbody>
					<tfoot>
					<tr key={"new"}>
						<td colSpan={2}>
							<TileContainer rowTilesMedium={"1"}
							               rowTilesLarge={"1"}>
								<Tile onClick={!this.props.isEditing ? this.onCreate : null}
								      body={"Add Goal"}
								      isMedium
								      isNew/>
							</TileContainer>
						</td>
					</tr>
					</tfoot>
				</GridTable>
			</section>
		);
	}
}

ManageStrategicGoalsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	goals: PropTypes.array.isRequired,
	editItemId: PropTypes.string.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		goals: state.strategicPlan.goals,
		editItemId: state.strategicPlan.editItemId,
		isEditing: state.strategicPlan.isEditing,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		strategicPlanActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageStrategicGoalsPage);