import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import AuditHistory from "../../../../components/audit/AuditHistory";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy, policyEvents} from "../../../../components/authorization/policies/GmsPolicy";
import {deepCopyArray, isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import {newComment} from "../../../../components/shared/sharedDataUtilities";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../../constants/AuditConstants";
import {emptyGuid} from "../../../../constants/config";
import gmsApi from "../../gmsApi";
import {handleScroll} from "../../../../components/common/scrollUtilities";

const GmsApplicationFormAuditHistorySection = ({
                                                   actions,
                                                   applicationId,
                                                   audits,
                                                   elementId,
                                                   isFiscalAgentUser,
                                                   sectionId,
                                                   setSection
                                               }) => {

    const [updatedAudits, setUpdatedAudits] = useState([]);
    const [auditCommentIdToEdit, setAuditCommentIdToEdit] = useState('');
    const [isInEditFeedbackMode, setIsInEditFeedbackMode] = useState(false);

    const canAudit = allow(GmsPolicy, policyEvents.ADMIN.audit);
    const scrollToId = `auditContainer__${elementId || sectionId}`;

    const clearEditMode = () => {
        setAuditCommentIdToEdit('');
        setIsInEditFeedbackMode(false);
    };

    const handleCreateComment = (auditId) => {
        let copiedAudits = deepCopyArray(updatedAudits);
        let audit = copiedAudits.find(a => a.auditId === auditId);
        audit.comments.unshift(newComment(auditId));
        setUpdatedAudits(copiedAudits);
        setIsInEditFeedbackMode(true);
    };

    const handleEditComment = (auditCommentId) => {
        setAuditCommentIdToEdit(auditCommentId);
        setIsInEditFeedbackMode(true);
    };

    const handleCancelComment = () => {
        setUpdatedAudits(deepCopyArray(audits));
        clearEditMode();
    };

    const handleChangeComment = (event, updateComment) => {
        event.preventDefault();
        let copiedAudits = deepCopyArray(updatedAudits);

        const fieldUpdated = event.target.name;
        const updatedValue = event.target.value;

        let updatedComment = copiedAudits.find(a => a.auditId === updateComment.auditId)
            .comments
            .find(c => c.auditCommentId === updateComment.auditCommentId);

        if (fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
            updatedComment.isVisibleToDistrict = updatedValue;
        else
            updatedComment.text = updatedValue;

        setUpdatedAudits(copiedAudits);
    };

    const handleSaveComment = async (event, comment) => {
        event.preventDefault();

        const isNewComment = comment.auditCommentId === "";
        if (isNewComment) {
            const auditCommentId = emptyGuid;
            comment = {...comment, ...{auditCommentId}};
        }

        actions.executeApi(gmsApi.saveAuditComment, [applicationId, elementId, sectionId, comment, !isNewComment])
            .then((result) => {
                clearEditMode();
                setSection(result);

                if(scrollToId)
                    handleScroll(scrollToId)
            });
    };

    useEffect(() => {
        setUpdatedAudits(deepCopyArray(audits));
    }, [audits]);

    if (isArrayNullOrEmpty(audits))
        return null;

    return (
        <>
            {
                !isFiscalAgentUser &&
                canAudit &&
                <AuditHistory
                    ariaLabelledById={elementId || sectionId}
                    audits={updatedAudits}
                    editItemId={auditCommentIdToEdit}
                    id={scrollToId}
                    isInEditMode={isInEditFeedbackMode}
                    isLoading={false}
                    onCommentCreate={handleCreateComment}
                    onCommentCancel={handleCancelComment}
                    onCommentEdit={handleEditComment}
                    onCommentSave={handleSaveComment}
                    handleCommentChange={handleChangeComment}
                    parentIdPropName={`responseId`}
                />
            }
            {
                (isFiscalAgentUser || !canAudit) &&
                <AuditHistory
                    ariaLabelledById={elementId || sectionId}
                    audits={updatedAudits}
                    isHistoryOpen={isFiscalAgentUser}
                />
            }
        </>
    );
};

GmsApplicationFormAuditHistorySection.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    audits: PropTypes.array,
    elementId: PropTypes.string,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    sectionId: PropTypes.string.isRequired,
    setSection: PropTypes.func.isRequired
};

export default GmsApplicationFormAuditHistorySection;