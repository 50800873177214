import React from "react";
import PropTypes from 'prop-types';
import DrSummaryCaseload from "./DrSummaryCaseload";
import DrSummaryDueNow from "./DrSummaryDueNow";
import DrSummaryUpcoming from "./DrSummaryUpcoming";

export const DrSummaryView = ({
                                  caseload,
                                  drSummarySearchCriteria,
                                  handleClickGeneral,
                                  history,
                                  setDateRangeSearchCriteria,
                                  setDrSummarySearchCriteria,
                                  summary,
                                  updatePinnedItems
                              }) => {
    return (
        <>
            <DrSummaryDueNow
                drSummarySearchCriteria={drSummarySearchCriteria}
                handleClickGeneral={handleClickGeneral}
                setDrSummarySearchCriteria={setDrSummarySearchCriteria}
                summary={summary}
            />

            <DrSummaryUpcoming
                drSummarySearchCriteria={drSummarySearchCriteria}
                handleClickGeneral={handleClickGeneral}
                setDateRangeSearchCriteria={setDateRangeSearchCriteria}
                setDrSummarySearchCriteria={setDrSummarySearchCriteria}
                summary={summary}
                updatePinnedItems={updatePinnedItems}
            />

            <DrSummaryCaseload
                caseload={caseload}
                history={history}
                selectedDisputeResolutionType={drSummarySearchCriteria.disputeResolutionTypeWithDefault}
                setDrSummarySearchCriteria={setDrSummarySearchCriteria}
            />
        </>
    );
};

DrSummaryView.propTypes = {
    caseload: PropTypes.object.isRequired,
    drSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    history: PropTypes.object,
    setDateRangeSearchCriteria: PropTypes.func.isRequired,
    setDrSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array,
    updatePinnedItems: PropTypes.func.isRequired
};

export default DrSummaryView;