import React, {cloneElement, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {renderChildrenWithNewProps} from "../layout/layoutUtility";
import {isNullOrUndefined} from "./commonUtilities";

export const Tabs = ({
                         children,
                         className = "",
                         defaultSelectedTab = null,
                         tabTitles = [],
                         wrapperClass = ""
                     }) => {
    const [selectedTab, setSelectedTab] = useState(null);

    const getTablePanelId = (title) => {
        return title.replace(/\s/g, '');
    }

    const handleTabClick = (event, updateSelectedTab) => {
        event.preventDefault();
        setSelectedTab(updateSelectedTab);
        return false;
    };

    useEffect(() => {
        if (tabTitles.length > 0) {
            const defaultTabTitle = isNullOrUndefined(defaultSelectedTab) ? tabTitles[0] : defaultSelectedTab;
            setSelectedTab(getTablePanelId(defaultTabTitle));
        }
    }, []);

    return <>
        <ul className={`tabs ${wrapperClass}`}>
            {
                tabTitles.map((title, index) => {
                    const tabPanelId = getTablePanelId(title);
                    const firstTabClassName = tabPanelId === selectedTab ? "is-active" : ""
                    return (
                        <li key={index} className={`tabs-title ${firstTabClassName}`}>
                            <a onClick={(event) => handleTabClick(event, tabPanelId)} href={"#"}>{title}</a>
                        </li>
                    );
                })
            }
        </ul>
        <div className={`tabs-content ${className}`}>
            {renderChildrenWithNewProps(children,{selectedTab})}
        </div>
    </>
};

Tabs.propTypes = {
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    defaultSelectedTab: PropTypes.string,
    tabTitles: PropTypes.array.isRequired,
    wrapperClass: PropTypes.string
};
