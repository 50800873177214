import * as elementTypes from "../../constants/spp/sppElementTypes";
import LocalStorage from "../shared/LocalStorage";
import * as progressValues from "../../constants/spp/progressValues";
import audit_outcomes from "../../constants/auditOutcomes";
import {formatDateWindow, generateDateWindowFormat, limitTextLength} from "../common/commonUtilities";
import {
	generateDateWindowSelectListItems,
	isAnnualDateWindow,
	outputStandardDateFormat
} from "../shared/sharedDataUtilities";

export function getSectionHierarchy(sectionId, documentHierarchy) {
	for (let partIndex = 0; partIndex < documentHierarchy.length; partIndex++) {
		const partChildren = documentHierarchy[partIndex].childElements;

		for (let sectionIndex = 0; sectionIndex < partChildren.length; sectionIndex++)
			if (partChildren[sectionIndex].elementId === sectionId)
				return partChildren[sectionIndex];
	}
}

export function getSectionElements(sectionId, documentHierarchy, elementList, includeDisplayElements) {
	let sectionElements = [];

	const sectionHierarchy = getSectionHierarchy(sectionId, documentHierarchy);

	if (!sectionHierarchy || !sectionHierarchy.childElements)
		return;

	for (let elementSetIndex = 0; elementSetIndex < sectionHierarchy.childElements.length; elementSetIndex++) {
		const elementSetHierarchy = sectionHierarchy.childElements[elementSetIndex];

		for (let elementIndex = 0; elementIndex < elementSetHierarchy.childElements.length; elementIndex++) {
			const elementHierarchy = elementSetHierarchy.childElements[elementIndex];

			const element = {...elementList.filter(el => el.id === elementHierarchy.elementId)[0]};

			if (!includeDisplayElements && element.layout === elementTypes.Display) continue;

			element.abbreviatedText = limitTextLength(element.text);

			sectionElements.push(element);
		}
	}

	return sectionElements;
}

export function getPartElements(partId, documentHierarchy, elementList, includeDisplayElements = false) {
	let partElements = [];

	const partHierarchy = documentHierarchy.filter(hier => hier.elementId === partId)[0];

	for (let i = 0; i < partHierarchy.childElements.length; i++) {
		const sectionId = partHierarchy.childElements[i].elementId;
		const sectionElements = getSectionElements(sectionId, documentHierarchy, elementList, includeDisplayElements);

		sectionElements.map(element => partElements.push(element));
	}

	return partElements;
}

export function getPartResponses(partId, documentHierarchy, elements, responses) {
	const partElements = getPartElements(partId, documentHierarchy, elements);

	let filteredResponses = [];

	for (let elementIndex = 0; elementIndex < partElements.length; elementIndex++) {
		const element = partElements[elementIndex];
		const elementResponse = responses.filter(response => response.elementId === element.id)[0];

		filteredResponses.push(elementResponse);
	}

	return filteredResponses;
}

export function getElementResponses(elements, existingResponses) {
	const currentUser = LocalStorage.getLoggedInUser();

	if (!elements)
		return;

	return elements.map(element => {
		const elementResponse = existingResponses.filter(response => response.elementId === element.id)[0];

		if (elementResponse !== undefined) {
			return Object.assign({}, elementResponse);
		}

		return getNewResponse(currentUser, element);
	});
}

export function getSectionResponses(sectionId, documentHierarchy, elements, responses) {
	const sectionElements = getSectionElements(sectionId, documentHierarchy, elements, false);

	responses = getElementResponses(sectionElements, responses);

	let filteredResponses = [];

	if (sectionElements) {
        for (let elementIndex = 0; elementIndex < sectionElements.length; elementIndex++) {
            const element = sectionElements[elementIndex];
            const elementResponse = responses.filter(response => response.elementId === element.id)[0];

            filteredResponses.push(elementResponse);
        }
    }

	return filteredResponses;
}

export function getInputElements(elements) {
	return elements.filter(el => el.layout !== elementTypes.Progress && el.layout !== elementTypes.Display);
}

export function calculateProgress(elements, responses, policyCompliance) {
	let returnProgress = progressValues.NOT_STARTED;

	if (elements !== undefined && responses !== undefined) {
		elements = getInputElements(elements);

		//if more than 1 response has a data value it's at least started
		const checkboxElements = elements.filter(el => el.layout === elementTypes.Checkbox);
		const nonCheckboxElements = elements.filter(el => el.layout !== elementTypes.Checkbox);
		if (responses.some(response => {
			const checkElement = checkboxElements.filter(el => el.id === response.elementId)[0];
			if (checkElement !== undefined && response.data === "true") return true;

			const nonCheckElement = nonCheckboxElements.filter(el => el.id === response.elementId)[0];
			if (nonCheckElement !== undefined && response.data !== "") return true;

			return false;
		})) returnProgress = progressValues.STARTED;

		//if all the required elements' responses are ready to submit, it's ready to submit
		const submitReadyResponses = responses.filter(response => {
			return  response.isReadyToSubmit ||
			(response.audits.length > 0 &&
				response.audits[0].outcome === audit_outcomes.ACCEPTED.enum);
		});
		if (submitReadyResponses.length >= elements.length) returnProgress = progressValues.READY_TO_SUBMIT;

		//if all the required elements' responses' first audit is not null and has an outcome of inProgress, it's submited
		const submittedResponses = responses.filter(response => {
			return response.audits.length > 0 &&
				(response.audits[0].outcome === audit_outcomes.SUBMITTED.enum ||
					response.audits[0].outcome === audit_outcomes.ACCEPTED.enum);
		});
		if (submittedResponses.length >= elements.length) returnProgress = progressValues.SUBMITTED;

		//if all the element responses' first audit record is not null and has an outcome of Accepted, it's accepted
		const acceptedResponses = responses.filter(response => {
			return response.audits.length > 0 &&
				response.audits[0].outcome === audit_outcomes.ACCEPTED.enum;
		});
		if (acceptedResponses.length >= elements.length) returnProgress = progressValues.ACCEPTED;

		//if one or more responses has a most recent audit whose outcome is needs revision, it's needs revision
		const needsRevisionResponses = responses.filter(response => {
			return response.audits.length > 0 &&
				response.audits[0].outcome === audit_outcomes.NEEDS_REVISION.enum;
		});
		if (needsRevisionResponses.length > 0) returnProgress = progressValues.NEEDS_REVISION;
	}

	if (policyCompliance !== undefined) {
		if (policyCompliance.preApprovalFileId !== null)
			returnProgress = progressValues.PRE_APPROVED;

		if (policyCompliance.approvalFileId !== null)
			returnProgress = progressValues.APPROVED;
	}

	return returnProgress;
}

export function getNewResponse(currentUser, element) {
	return {
		elementId: element.id,
		institutionId: currentUser === undefined ? null : currentUser.InstitutionId,
		applicationUserId: currentUser === undefined ? null : currentUser.Id,
		data: element.layout === elementTypes.Checkbox ? "false" : "",
		isReadyToSubmit: false,
		audits: []
	};
}

export function generatePageTitle(dateWindows, policyCompliance, document, institutions, currentInstitutionId) {
	const dateWindow = (dateWindows && document) ? generateDateWindowFormat(dateWindows, document.dateWindowId) : "";
	const currentInstitutionName = (institutions && currentInstitutionId) ? institutions.filter(ins => ins.id === currentInstitutionId)[0].name : "";
	const titleSuffix = policyCompliance ? generatePageTitleSuffix(policyCompliance) : "";
	return `${dateWindow} Policies and Procedures: ${currentInstitutionName} ${titleSuffix}`;
}

export function generatePageTitleSuffix(policyCompliance) {
	let titleSuffix = "";
	if (policyCompliance !== undefined) {
		if (policyCompliance.preApprovalFileId !== null)
			titleSuffix = " (" + progressValues.PRE_APPROVED + ")";

		if (policyCompliance.approvalFileId !== null)
			titleSuffix = " (" + progressValues.APPROVED + ")";
	}
	return titleSuffix;
}

export function newElement(
	id = ``,
	documentId = ``,
	layout = 6,
	isRequired = false,
	text = `New Element`,
	value = ``) {
	return {
		id,
		documentId,
		layout,
		isRequired,
		text,
		value
	};
}

export function newAudit(policyResponseId, outcome, outcomeString, newAuditId) {
	return {
		comments: [],
		dateCreated: new Date(),
		policyResponseId: policyResponseId,
		isLocked: false,
		outcome: outcome,
		outcomeString: outcomeString,
		auditId: newAuditId,
		auditCreatedBy: LocalStorage.getLoggedInUser().Email,
	};
}

export function newComment(auditId) {
	return {
		auditCommentId: "",
		auditId: auditId,
		commentOwnerEmail: LocalStorage.getLoggedInUser().Email,
		isCommentOwner: true,
		text: "",
		dateCreated: new Date(),
		dateModified: null,
		displayDate: outputStandardDateFormat(new Date())
	};
}

export function checkboxShouldPrint(element, response) {
	if (element.text !== "I have read and understand the above information." &&
		!(element.text === "This section is not applicable for the district." && response.data !== "true"))
		return true;
	return false;
}

export function generateSppThreeYearDateWindowFormatPrint(dateWindows, dateWindowId) {
    const selectedDateWindow = dateWindows.filter(dw => dw.id === dateWindowId)[0];
    if (!selectedDateWindow)
        return "";

    const yearOneFormatted = formatDateWindow(selectedDateWindow);
    const yearThreeOpen = new Date(selectedDateWindow.openDate).getFullYear() + 2;
    const yearThreeClose = new Date(selectedDateWindow.closeDate).getFullYear() + 2;

    let yearThreeDateWindow = {
        openDate: "07/01/" + yearThreeOpen,
        closeDate: "06/30/" + yearThreeClose
    };

    const yearThreeFormatted = formatDateWindow(yearThreeDateWindow);

    return (yearOneFormatted !== "" && yearThreeFormatted !== "") ? yearOneFormatted + " through " + yearThreeFormatted : "";
}

export function generateSppThreeYearDateWindowFormat(dateWindows, dateWindowId) {
	const selectedDateWindow = dateWindows.filter(dw => dw.id === dateWindowId)[0];

	const annualDateWindows = dateWindows
		.filter(dw => isAnnualDateWindow(dw.openDate, dw.closeDate));

	const threeYearDateWindows = dateWindows
		.filter(dw => isSppThreeYearDateWindow(dw.openDate, dw.closeDate));

	const selectedThreeYearDateWindow = threeYearDateWindows.filter(threeYear =>
		threeYear.openDate <= selectedDateWindow.openDate &&
		threeYear.closeDate >= selectedDateWindow.closeDate)[0];

	if (!selectedThreeYearDateWindow)
		return "";

	const yearOne = annualDateWindows.filter(y1 => y1.openDate === selectedThreeYearDateWindow.openDate)[0];

	const yearThree = annualDateWindows.filter(y3 => y3.closeDate === selectedThreeYearDateWindow.closeDate)[0];

	if (!yearOne || !yearThree)
		return "";

	const yearOneFormatted = formatDateWindow(yearOne);

	const yearThreeFormatted = formatDateWindow(yearThree);

	return (yearOneFormatted !== "" && yearThreeFormatted !== "") ? yearOneFormatted + " through " + yearThreeFormatted : "";
}

export function isSppThreeYearDateWindow(startDateString, endDateString) {
	const startUTC = new Date(startDateString);
	const endUTC = new Date(endDateString);

	return ((endUTC.getFullYear() - startUTC.getFullYear()) === 3);
}

export function generateInstanceSelectList(instances, dateWindows) {
	let selectItems = [];
	let dateWindowSelectItems = generateDateWindowSelectListItems(dateWindows);

	instances.map((instance) => {
		const year = dateWindowSelectItems.filter(dw => dw.value === instance.dateWindowId)[0];

		selectItems.push(
			{
				text: year.text,
				value: instance.id
			}
		);
	});

	return selectItems;
}

export function getSectionMenuOptions(selectedDocument, documentElements, searchCriteria, partId, sectionId = "", useDefaultPartId = true) {
	const defaultCriteria = {
		selectPartItems: [],
		selectSectionItems: [],
		partId: "",
		sectionId: ""
	};
	let criteria = Object.assign(defaultCriteria, searchCriteria);
	if(!selectedDocument || !documentElements)
		return criteria;

	let selectPartItems = [];
	let selectSectionItems = [];

	if(!selectedDocument.componentsHierarchy)
		return criteria;

	const hierarchyJson = JSON.parse(selectedDocument.componentsHierarchy);

	if(!partId && sectionId) {
		hierarchyJson.map((part) => {
			if(part.childElements.find(f => f.elementId === sectionId))
				partId = part.elementId;
		});
	}

	hierarchyJson.map((part) => {
		const partElement = documentElements.find(element => element.id === part.elementId);

		if (partElement) {
			selectPartItems.push(
				{
					text: partElement.text,
					value: part.elementId
				}
			);

			if(!partId && !sectionId && useDefaultPartId) {
				partId = part.elementId;
			}
		}

		if (part.elementId === partId) {
			part.childElements.map((section) => {
				const element = documentElements.find(element => element.id === section.elementId);
				if(!element)
					return;

				const sectionText = element.text;
				selectSectionItems.push(
					{
						text: sectionText,
						value: section.elementId
					}
				);
			});
		}
	});

	criteria.selectPartItems = selectPartItems;
	criteria.selectSectionItems = selectSectionItems;
	criteria.partId = partId;
	criteria.sectionId = sectionId;

	return criteria;
}

export function getSectionTitles(selectedDocument, documentElements, partId) {

	const selectSectionItems =  [];

	if(!selectedDocument || !documentElements)
		return selectSectionItems;

	if(!selectedDocument.componentsHierarchy)
		return selectSectionItems;

	const hierarchyJson = JSON.parse(selectedDocument.componentsHierarchy);

	hierarchyJson.map((part) => {
		if (part.elementId === partId) {
			part.childElements.map((section) => {
				const element = documentElements.find(element => element.id === section.elementId);
				if(!element)
					return;

				const sectionText = element.text;
				selectSectionItems.push(
					{
						text: sectionText,
						value: section.elementId
					}
				);
			});
		}
	});

	return selectSectionItems;
}