import React from "react";
import PropTypes from "prop-types";

const IndexIdentifier = ({ currentIndex, listType }) => {
    return (<ol className={"indexIdentifier indexIdentifier--" + listType} start={currentIndex + 1}><li /></ol>);
};

IndexIdentifier.propTypes = {
    currentIndex: PropTypes.number.isRequired,
    listType: PropTypes.string
};

export default IndexIdentifier;