import React from "react";
import PropTypes from 'prop-types';
import CheckBoxField from "./CheckBoxField";
import TextAreaEditor from "./textAreaEditor/TextAreaEditor";
import {inputStyles} from '../../../constants/inputConstants';

const CheckBoxFieldOtherText = (
	{
		index,
		isChecked,
		isLoading,
		label,
		name,
		otherText,
		otherTextId,
		otherTextSeparator,
		onResponseChange,
		propID,
		viewOnly,
		wrapperClass
	}) => {
	let otherTextValue = "";
	if (otherTextId !== "" && isChecked)
		otherTextValue = otherText.substring(label.length + otherTextSeparator.length);
	return (
		<>
			<CheckBoxField
				checked={isChecked}
				label={label}
				name={`${name}_${index}_${propID}`}
				value={label}
				showLabel={true}
				disabled={viewOnly || isLoading}
				onChange={(event) => onResponseChange(event, propID)}
				wrapperClass={wrapperClass}
			/>
			{
				otherTextId !== "" &&
				isChecked &&
				<TextAreaEditor
					disabled={viewOnly || isLoading}
					error={(otherTextValue === "" && !viewOnly) ? "More details required." : ""}
					label={"More details"}
					name={`${otherTextId}_${name}_${propID}`}
					onChange={(event) => onResponseChange(event, propID)}
					showLabel={false}
					value={otherTextValue}
					wrapperClass={`${inputStyles.textbox.OTHER}`}
				/>
			}
		</>
	);
};

CheckBoxFieldOtherText.propTypes = {
	propID: PropTypes.string.isRequired,
	index: PropTypes.number.isRequired,
	isChecked: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	name: PropTypes.string,
	otherText: PropTypes.string.isRequired,
	otherTextId: PropTypes.string.isRequired,
	otherTextSeparator: PropTypes.string.isRequired,
	onResponseChange: PropTypes.func.isRequired,
	viewOnly: PropTypes.bool.isRequired,
	wrapperClass: PropTypes.string
};

CheckBoxFieldOtherText.defaultProps = {
	name: "cblOther"
};

export default CheckBoxFieldOtherText;