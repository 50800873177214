import React from "react";
import PropTypes from 'prop-types';
import RadioField from "../../common/inputs/RadioField";
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import * as responseTypes from "../../../constants/monitoringCompliance/responseTypes";
import * as responseOptions from "../../../constants/monitoringCompliance/responseOptions";
import * as AuthorizationUtilities from "../../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../../constants/policyEvents";
import {inputStyles} from '../../../constants/inputConstants';

const ReassessNoSubQuestions = (
    {
        protocolToBeReassessed,
        protocolResponses,
        onResponseChange,
        responseTypeDisplayed
    }) =>
{
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    return (
        <>
            {
                protocolResponses.filter(f => f.responseType === responseTypeDisplayed).map((comparedResponse, index) => {
                    const originalResponse = protocolResponses.find(f => f.responseType === responseTypes.ASSESS
                        && f.studentRecordId === comparedResponse.studentRecordId);

                    const options = protocolToBeReassessed.districtResponseOptions;
                    return (
                        <tr key={`${comparedResponse.protocolResponseId}_${index}`} valign={"top"}>
                            <td className={"response-no-wrap"}>{originalResponse.studentRecordName}</td>
                            <td className={"response-no-wrap"}>{protocolToBeReassessed.publicIdentifier}</td>
                            <td><HtmlDisplayBlock html={protocolToBeReassessed.standardDescription} /></td>
                            <td className={"text-center"}><HtmlDisplayBlock html={originalResponse.responseText || originalResponse.response} /></td>
                            {
                                responseTypeDisplayed === responseTypes.REASSESS &&
                                options.length > 0 &&
                                <td>
                                    <RadioField
                                        name={`${comparedResponse.protocolResponseId}_${index}`}
                                        options={options}
                                        value={comparedResponse.response}
                                        onChange={(event) => onResponseChange(event, comparedResponse.protocolResponseId, comparedResponse.studentRecordId, comparedResponse.protocolId)}
                                        direction={inputStyles.radio.direction.HORIZONTAL}
                                        disabled={!canEdit}
                                        legend={`Responses for &quot;${protocolToBeReassessed.standardDescription}&quot; for student record ${originalResponse.studentRecordName}`}
                                        showLegend={false}
                                    />
                                </td>
                            }
                            {
                                responseTypeDisplayed === responseTypes.REASSESS &&
                                options.length === 0 &&
                                <td className={"text-center"}><HtmlDisplayBlock html={comparedResponse.responseText || comparedResponse.response} /></td>
                            }
                            {
                                responseTypeDisplayed === responseTypes.VALIDATION &&
                                <td className={"text-center highlight-discrepancy"}><HtmlDisplayBlock html={(comparedResponse.response === responseOptions.NV) ? originalResponse.responseText || originalResponse.response : comparedResponse.responseText || comparedResponse.response } /></td>
                            }
                        </tr>
                    );
                })
            }
        </>
    );
};

ReassessNoSubQuestions.propTypes = {
    protocolToBeReassessed: PropTypes.object.isRequired,
    protocolResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onResponseChange: PropTypes.func.isRequired,
    responseTypeDisplayed: PropTypes.number.isRequired
};

export default ReassessNoSubQuestions;