import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {PtsFilterVisibilityRules} from "../../../../components/common/searchCriterias/PtsSearchCriteria";
import ptsManageProgramsApi from "./ptsManageProgramsApi";
import {createProgramObject} from "./ptsManageProgramsObjectFactory";
import {PtsManageProgramsView} from "./PtsManageProgramsView";

const PtsManageProgramsContainer = ({
                                        actions,
                                        dataChanged,
                                        gotoLocation,
                                        handleDataChanged,
                                        searchCriteria
                                    }) => {
    const [programs, setPrograms] = useState(null);

    const handleSavePtsProgram = async (programId, program) => {
        await actions.executeApi(ptsManageProgramsApi.saveProgram, [programId, program])
        await initializeData(program.programName);
    };

    const handleDeletePtsProgram = async (programId) => {
        if (!confirm(`Are you sure you want to delete this program?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            return;

        await actions.executeApi(ptsManageProgramsApi.deleteProgram, [programId]);
        await initializeData("");
    };

    const initializeData = async (updatedProgramName = null) => {
        handleDataChanged(false);
        const result = await actions.executeApi(ptsManageProgramsApi.getPrograms)
        const updatedPrograms = result.map(program => createProgramObject(program));
        setPrograms(updatedPrograms);
        updateProgramListFilter(updatedPrograms, updatedProgramName);
    };

    const updateProgramListFilter = (updatedPrograms, updatedProgramName) => {
        let criteria = {...searchCriteria};
        criteria.setProgramList(updatedPrograms);

        if (!isNullOrUndefined(updatedProgramName)) {
            if (isTrimmedStringEmpty(updatedProgramName))
                criteria.programName = "";
            else if (!isTrimmedStringEmpty(searchCriteria.programName))
                criteria.programName = updatedProgramName;
        }

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        initializeData();
    }, []);

    if (isNullOrUndefined(programs) || isNullOrUndefined(searchCriteria) || !searchCriteria.areRulesCurrent(PtsFilterVisibilityRules.Program))
        return null;

    return (
        <PtsManageProgramsView
            actions={actions}
            dataChanged={dataChanged}
            programs={programs}
            gotoLocation={gotoLocation}
            handleDataChanged={handleDataChanged}
            handleDeletePtsProgram={handleDeletePtsProgram}
            handleSavePtsProgram={handleSavePtsProgram}
            searchCriteria={searchCriteria}
        />
    );
};

PtsManageProgramsContainer.propTypes = {
    actions: PropTypes.object,
    dataChanged: PropTypes.bool,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default PtsManageProgramsContainer;
