import {dr_api as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import { RequestTypes } from '../../../api/requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceNotificationsApi {

    static getMcUserNotifications(assessmentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/${assessmentId}/reminders`,
            RequestTypes.GET));
    }

    static saveMcReminder(assessmentId, reminderId, remindDate, reminderNotes) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}monitoring/compliances/${assessmentId}/reminders/${reminderId}`,
                RequestTypes.PUT,
                JSON.stringify({remindDate: remindDate, reminderNotes: reminderNotes}),
                ApiSuccessMessages.reminders.SAVED
            )
        );
    }

    static removeDrReminder(assessmentId, reminderId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}monitoring/compliances/${assessmentId}/reminders/${reminderId}`,
                RequestTypes.DELETE,
                null,
                ApiSuccessMessages.reminders.REMOVED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceNotificationsApi {
    static getMcUserNotifications(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveDrReminder() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static removeDrReminder() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceNotificationsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceNotificationsApi : MockMonitoringComplianceNotificationsApi;
export default MonitoringComplianceNotificationsApi;