import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {isTrimmedStringEmpty} from "./commonUtilities";

export const Modal = ({
                          children,
                          modalContentClass = "",
                          modalId = ""
                      }) => {
    const className = `modal-content ${modalContentClass}`;
    const modalIdAttributes = isTrimmedStringEmpty(modalId) ? {} : {id: modalId};

    useEffect(() => {
        $('html').addClass('modal-open');
        return () => {
            $('html').removeClass('modal-open');
        };
    }, []);

    return <>
        <div className="modal"></div>
        <div className={`modal-content-container`} {...modalIdAttributes}>
            <div className={className}>
                {children}
            </div>
        </div>
    </>;
};

Modal.propTypes = {
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    modalContentClass: PropTypes.string,
    modalId: PropTypes.string
};