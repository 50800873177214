import {emptyGuid} from "../../../../constants/config";

export const createGmsUserObject = ({
                                        email = "",
                                        firstName = "",
                                        fullName = "",
                                        gmsUserId = emptyGuid,
                                        lastName = ""
                                    } = {}) => ({
    email,
    firstName,
    fullName,
    gmsUserId,
    lastName
});