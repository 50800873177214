import React from "react";
import PropTypes from 'prop-types';
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {convertToDate, getEndOfCurrentDay} from "../../../components/common/dateUtilities";
import GridTable from "../../../components/common/GridTable";
import PrintTable from "../../../components/common/PrintTable";
import {TaskItemForTableRow} from "../../../components/tasks/TaskItem";

export const DrStateComplaintDueNowTasks = ({
                                                complaintTasks,
                                                handleClickRecord,
                                                isPrint = false
                                            }) => {
    const dueNowTasks = complaintTasks.filter(t => convertToDate(t.dueDate) <= convertToDate(getEndOfCurrentDay()));

    if (isArrayNullOrEmpty(dueNowTasks))
        return null;

    const tableDetails = (
        <>
            <thead>
            <tr>
                <th>
                    Tasks
                </th>
            </tr>
            </thead>
            <tbody>
            {
                dueNowTasks.map((task, key) => {
                    return <TaskItemForTableRow
                        key={key}
                        isPrint={isPrint}
                        task={task}
                        viewRecord={(event) => handleClickRecord(event, task.url)}
                    />;
                })
            }
            </tbody>
        </>
    );

    return (
        <>
            {
                !isPrint &&
                <>
                    <h2>Due Now</h2>
                    <GridTable className={`dr-summary-due-now-table`}>
                        {tableDetails}
                    </GridTable>
                </>
            }
            {
                isPrint &&
                <PrintTable caption={`Due Now`} className={"add-padding dr-summary-due-now-table"}>
                    {tableDetails}
                </PrintTable>
            }
        </>
    );
};

DrStateComplaintDueNowTasks.propTypes =
{
    complaintTasks: PropTypes.array.isRequired,
    handleClickRecord: PropTypes.func,
    isPrint: PropTypes.bool
};

export default DrStateComplaintDueNowTasks;