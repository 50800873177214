import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FormTable from "../../../components/common/FormTable";
import RadioField from "../../../components/common/inputs/RadioField";
import SelectField from "../../../components/common/inputs/SelectField";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {inputStyles} from "../../../constants/inputConstants";
import {RadioButtonYesNoList, RadioButtonYesNoObject} from "../../drConstants";
import {ResolutionTypeAbeyance, StateComplaintSections} from "../drStateComplaintConstants";
import * as drStateComplaintConstants from '../drStateComplaintConstants';
import DrStateComplaintNextSteps from "../DrStateComplaintNextSteps";
import * as drObjectFactory from '../drStateComplaintObjectFactory';
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {StateComplaintPolicy} from "../../../components/authorization/policies/StateComplaintPolicy";
import {dispute_resolution} from "../../../constants/policyEvents";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject,
    createYesNoList, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";

export const DrStateComplaintOutcomeView = ({
                                                complaint,
                                                handleSaveComplaint,
                                                handleDataChanged,
                                                handleSelectSection,
                                                readOnly
                                            }) => {
    const {Withdrawn, Dismissed} = drStateComplaintConstants.ResolutionNotOrderedTypes;
    const {AlternativeResolution, ReportOfInquiry} = drStateComplaintConstants.ResolutionType;
    const [isDismissedWithdrawnType, setIsDismissedWithdrawnType] = useState(false);
    const [isVisibleDateClosed, setIsVisibleDateClosed] = useState(false);
    const [isRoiType, setIsRoiType] = useState(false);
    const [isVisibleAlternativeResolutionType, setIsVisibleAlternativeResolutionType] = useState(false);
    const [isVisibleDispositionOther, setIsVisibleDispositionOther] = useState(false);
    const [outcomeFindings, setOutcomeFindings] = useState([]);

    const complaintForm = useFormInputObject(drObjectFactory.createComplaintObject(complaint), () => handleDataChanged());
    const canEdit = AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY);

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.Outcome, true);
    };

    const validateOutcomes = () => {
        clearInputFormErrorFields(complaintForm);

        if(!complaint.isOrdered && complaintForm.resolutionType.value === ReportOfInquiry)
            complaintForm.resolutionType.setError("Report of Inquiry cannot be selected until the complaint has been ordered.");
        else if(complaintForm.resolutionType.value === ReportOfInquiry && isTrimmedStringEmpty(complaintForm.hasCorrectiveActions.value))
            complaintForm.hasCorrectiveActions.setError("Required");

        let isValid = inputFormIsValid(complaintForm);
        if (!isValid)
            NotifyUser.Warning("Please correct the following issues and try submitting again.");

        return isValid;
    };

    const handleClickSave = (event) => {
        event.preventDefault();
        if(!validateOutcomes())
            return;

        const form = convertFormInputObjectToObject(complaintForm);

        form.resolutionType = complaint.resolutionType === ResolutionTypeAbeyance && !form.resolutionType ? complaint.resolutionType : form.resolutionType;

        if (!!complaint.resolutionType && form.resolutionType !== complaint.resolutionType)
            if (!confirm("Changing the Resolution Type will clear out any existing issues and actions.\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
                return;

        if(form.resolutionType === ReportOfInquiry && form.hasCorrectiveActions === RadioButtonYesNoObject.No && complaint.hasActions)
            if (!confirm("Changing the response to no for corrective actions will clear out all existing actions.\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
                return;

        handleSaveComplaint(form);
    };

    const getDispositionList = () => {
        let dispositionList = [];
        if (complaintForm.resolutionType.value === Withdrawn)
            dispositionList = drStateComplaintConstants.DispositionWithdrawnList;
        else if (complaintForm.resolutionType.value === Dismissed)
            dispositionList = drStateComplaintConstants.DispositionDismissedList;

        return dispositionList;
    };

    const getClosureDateLabel = () => {
        if(complaintForm.resolutionType.value === Withdrawn)
            return "Withdrawal Closure Date";

        if(complaintForm.resolutionType.value === Dismissed)
            return "Dismissal Closure Date";

        return "Closure Date";
    }

    useEffect(() => {
        const isDismissedWithdrawn = complaintForm.resolutionType.value === Withdrawn || complaintForm.resolutionType.value === Dismissed;
        setIsDismissedWithdrawnType(isDismissedWithdrawn);

        setIsVisibleDateClosed(isDismissedWithdrawn);

        const isAlternative = complaintForm.resolutionType.value === AlternativeResolution;
        setIsVisibleAlternativeResolutionType(isAlternative);

        const isRoi = complaintForm.resolutionType.value === ReportOfInquiry;
        setIsRoiType(isRoi);

        const findings = isVisibleAlternativeResolutionType ? drStateComplaintConstants.OutcomeFindings : drStateComplaintConstants.OutcomeFindings.filter(f => f.value !== AlternativeResolution);
        setOutcomeFindings(findings);

        if(!isDismissedWithdrawn)
            complaintForm.disposition.setValue("");

        if(!isRoi)
            complaintForm.hasCorrectiveActions.setValue("");

        if(!isDismissedWithdrawn)
            complaintForm.dateWithdrawnOrDismissedOrIssuedWithNoActions.setValue("");

        if(!isAlternative) {
            complaintForm.alternativeResolutionType.setValue("");
        }
    }, [complaintForm.resolutionType.value]);

    useEffect(() => {
        const dispositionList = getDispositionList();
        const disposition = dispositionList.find(f => f.value === complaintForm.disposition.value);

        const showDispositionOther = disposition ? drStateComplaintConstants.DispositionOtherRequirement[disposition.id] : false;
        if (!showDispositionOther)
            complaintForm.dispositionOther.setValue("");
        setIsVisibleDispositionOther(showDispositionOther);

    }, [complaintForm.disposition.value]);

    if (isNullOrUndefined(complaint)) return null;

    return (
        <>
            <FormTable>
                <SelectField
                    label={`What was the outcome?`}
                    medium={6}
                    name={`resolutionType`}
                    options={outcomeFindings}
                    {...complaintForm.resolutionType}
                    showLabel
                    disabled={readOnly}
                />
                {
                    isVisibleAlternativeResolutionType &&
                    <TextField
                        name="alternativeResolutionType"
                        label="Alternative Resolution Type"
                        showLabel
                        {...complaintForm.alternativeResolutionType}
                        columnsMedium={6}
                        disabled={readOnly}
                    />
                }
                <SelectField
                    name="disposition"
                    label="Disposition"
                    showLabel
                    options={getDispositionList()}
                    {...complaintForm.disposition}
                    medium={6}
                    disabled={readOnly || !isDismissedWithdrawnType}
                />
                {
                    isVisibleDispositionOther &&
                    <TextField
                        name="dispositionOther"
                        label="Other Disposition"
                        showLabel
                        {...complaintForm.dispositionOther}
                        columnsMedium={6}
                        disabled={readOnly}
                    />
                }
                <DateTimePickerField
                    name="dateWithdrawnOrDismissedOrIssuedWithNoActions"
                    label={getClosureDateLabel()}
                    showLabel
                    {...complaintForm.dateWithdrawnOrDismissedOrIssuedWithNoActions}
                    medium={6}
                    disabled={!canEdit || !isVisibleDateClosed}
                />
                <SelectField
                    disabled={readOnly || !isRoiType}
                    label={`Are there actions?`}
                    medium={6}
                    name={`hasCorrectiveActions`}
                    options={RadioButtonYesNoList}
                    showLabel
                    {...complaintForm.hasCorrectiveActions}
                />
                <DisplayField label={`Within Timeline Override<br/><span class="help-text">(Enabled when date completed is after due date)</span>`} name={`lblWithinTimeline`} columnsMedium={6} showLabel>
                    <RadioField
                        legend={"Within Timeline"}
                        showLegend={false}
                        name={"withinTimelineOverride"}
                        options={createYesNoList()}
                        {...complaintForm.withinTimelineOverride}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        disabled={readOnly || !complaint.isOrdered || complaint.isSignedCompletionBeforeDueDate}
                    />
                    <p>Ordered Due Date: {complaint.orderedDueDate}</p>
                    <p>Ordered Completion Date: {complaint.dateOrdered || "None"}</p>
                </DisplayField>
            </FormTable>
            {
                (!readOnly || (isVisibleDateClosed && canEdit)) &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SAVE}
                            onClick={handleClickSave}/>

                    <Button name="btnCancel"
                            label="Cancel and Reload"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleClickCancel}/>

                    <DrStateComplaintNextSteps handleSelectSection={handleSelectSection} complaint={complaint}/>

                </ButtonBar>
            }
        </>
    );
};

DrStateComplaintOutcomeView.propTypes = {
    complaint: PropTypes.object,
    handleSaveComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default DrStateComplaintOutcomeView;