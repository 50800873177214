import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {DefaultPolicy} from "./policies/DefaultPolicy";
import * as PolicyEvents from "../../constants/policyEvents";

export class Allow extends React.PureComponent {
    render() {
        const {context, currentContext, children, isFinFacilitator}  = this.props;

        let {policy, policyEvent} = this.props;

        if (policy && context) return null;

        const contextUsed = (context) ? [context] : currentContext;

        policy = policy ? new policy : new DefaultPolicy(contextUsed);

        if (policy.permit(policyEvent, isFinFacilitator))
            return children;
        else
            return null;
    }
}

Allow.propTypes = {
    context: PropTypes.string,
    currentContext: PropTypes.array,
    children: PropTypes.any.isRequired, //eslint-disable-line react/forbid-prop-types
    isFinFacilitator: PropTypes.bool,
    policy: PropTypes.func,
    policyEvent: PropTypes.string
};

Allow.defaultProps = {
    isFinFacilitator: false,
    policyEvent: PolicyEvents.VIEW
};

function mapStateToProps(state) {
    return {
        currentContext: state.currentContext
    };
}


export default connect(mapStateToProps)(Allow);