export const createBpie = ({
                               numberOfBpiesToUpdate,
                               numberOfBpies,
                               numberOfBpieCreateErrors,
                               sbpiesActuallyCreated,
                               sbpiesToBeCreated,
                               updates,
                           } = {}) => {
    return {
        hasSchoolSchoolBpieUpdates: numberOfBpiesToUpdate > 0,
        numberOfBpieSchoolsToUpdate: numberOfBpiesToUpdate || 0,
        numberOfSchoolBpieCreateErrors: numberOfBpieCreateErrors || 0,
        numberOfSchoolsBpiesToCreate: numberOfBpies || 0,
        sbpiesActuallyCreated: sbpiesActuallyCreated || [],
        sbpiesToBeCreated: sbpiesToBeCreated || [],
        schoolBpieUpdates: updates || []
    }
};