import React from "react";
import PropTypes from 'prop-types';
import FederalReportView from "./FederalReportView";

const FederalReportPrintView = ({
	                                actions,
	                                dateWindowId,
	                                dateWindows
                                }) => {
	return <FederalReportView
		actions={actions}
		dateWindowId={dateWindowId}
		dateWindows={dateWindows}
		includeGifted={false}
	/>;
};

FederalReportPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	dateWindows: PropTypes.array.isRequired
};

export default FederalReportPrintView;