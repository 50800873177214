import MonitoringComplianceApi from "../api/MonitoringComplianceApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function createDistrictComplianceAssessmentSuccess(complianceAssessment, newId){
    return { type: actionTypes.CREATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS, data: { complianceAssessment, newId }};
}

export function createDistrictComplianceAssessment(newAssessment){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.createDistrictComplianceAssessment(newAssessment)
            .then((newId) => {
                dispatch(createDistrictComplianceAssessmentSuccess(newAssessment, newId));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function updateDistrictComplianceAssessmentSuccess(complianceAssessment){
    return { type: actionTypes.UPDATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS, data: complianceAssessment };
}

export function updateDistrictComplianceAssessment(assessment){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.updateDistrictComplianceAssessment(assessment)
            .then(() => {
                dispatch(updateDistrictComplianceAssessmentSuccess(assessment));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function deleteDistrictComplianceAssessmentSuccess(){
    return { type: actionTypes.DELETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function deleteDistrictComplianceAssessment(districtId, assessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.deleteDistrictComplianceAssessment(districtId, assessmentId)
            .then(() => {
                dispatch(deleteDistrictComplianceAssessmentSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadDistrictComplianceAssessmentSuccess(assessment){
    return { type: actionTypes.LOAD_DISTRICT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS, data: assessment};
}

export function loadDistrictComplianceAssessments(districtId, dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.loadDistrictComplianceAssessments(districtId, dateWindowId)
            .then((data) => {
                dispatch(loadDistrictComplianceAssessmentSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadComplianceAssessmentSuccess(assessment){
	return { type: actionTypes.LOAD_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS, data: assessment};
}

export function loadComplianceAssessment(monitoringAssessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.loadComplianceAssessment(monitoringAssessmentId)
            .then((data) => {
                dispatch(loadComplianceAssessmentSuccess(data));
                return data;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function saveDistrictProtocolRequirementsSuccess(indicatorRequirements){
    return { type: actionTypes.SAVE_DISTRICT_ASSIGNED_PROTOCOL_AJAX_SUCCESS, data: indicatorRequirements };
}

export function saveDistrictRequiredProtocols(monitoringAssessmentId, dateWindowId, indicatorRequirements){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.saveDistrictRequiredProtocols(monitoringAssessmentId, dateWindowId, indicatorRequirements)
            .then(() => {
                dispatch(saveDistrictProtocolRequirementsSuccess(indicatorRequirements));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function editClassification(){
    return { type: actionTypes.EDIT_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION };
}

export function cancelClassificationEdit(){
    return { type: actionTypes.CANCEL_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION };
}

export function clearSelectedComplianceAssessment(){
    return { type: actionTypes.CLEAR_COMPLIANCE_MONITORING_SELECTED_ASSESSMENT };
}

export function addProtocolRequirement(monitoringAssessmentId, requirement){
    return { type: actionTypes.ADD_COMPLIANCE_PROTOCOL_REQUIREMENT, data: {monitoringAssessmentId, requirement} };
}

export function editProtocolRequirement(requirement){
    return { type: actionTypes.EDIT_COMPLIANCE_PROTOCOL_REQUIREMENT, data: {requirement} };
}

export function changeProtocolRequirement(requirement){
    return { type: actionTypes.CHANGE_COMPLIANCE_PROTOCOL_REQUIREMENT, data: requirement };
}

export function cancelProtocolRequirementEdit(){
    return { type: actionTypes.CANCEL_COMPLIANCE_PROTOCOL_REQUIREMENT_EDIT };
}

export function clearComplianceAssessmentData(){
    return { type: actionTypes.CLEAR_MONITORING_COMPLIANCE_DATA };
}

export function loadIndicatorProtocolsSuccess(protocols){
    return { type: actionTypes.LOAD_INDICATOR_PROTOCOLS_AJAX_SUCCESS, data: protocols};
}

export function loadIndicatorProtocols(dateWindowId, indicatorId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.loadIndicatorProtocols(dateWindowId, indicatorId)
            .then((data) => {
                dispatch(loadIndicatorProtocolsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function setEditMode(isEditing){
    return { type: actionTypes.SET_COMPLIANCE_EDIT_MODE , data: isEditing };
}

export function saveStudentRecordSuccess(){
    return { type: actionTypes.SAVE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS };
}

export function saveStudentRecord(districtId, assessmentId, studentRecordId, protocolResponses, responseType){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.saveStudentRecord(districtId, assessmentId, studentRecordId, protocolResponses, responseType)
            .then(((studentRercordId) => {
                dispatch(saveStudentRecordSuccess());
                return studentRercordId;
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
                return undefined;
            });
    };
}

export function saveReassessmentSuccess(){
    return { type: actionTypes.SAVE_DISTRICT_REASSESSMENT_AJAX_SUCCESS };
}

export function saveReassessment(assessmentId, protocolResponses){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.saveReassessment(assessmentId, protocolResponses)
            .then((() => {
                dispatch(saveReassessmentSuccess());
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function deleteStudentRecordSuccess(){
    return { type: actionTypes.DELETE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS };
}

export function deleteStudentRecord(districtId, assessmentId, studentRecordId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.deleteStudentRecord(districtId, assessmentId, studentRecordId)
            .then((() => {
                dispatch(deleteStudentRecordSuccess());
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function removeStudentRecordValidationSuccess(){
    return { type: actionTypes.REMOVE_DISTRICT_STUDENT_RECORD_VALIDATION_AJAX_SUCCESS };
}

export function removeStudentRecordValidation(districtId, assessmentId, studentRecordId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.removeStudentRecordValidation(districtId, assessmentId, studentRecordId)
            .then((() => {
                dispatch(removeStudentRecordValidationSuccess());
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function submitComplianceAssessmentValidationSuccess(){
    return { type: actionTypes.VALIDATION_COMPLETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function submitComplianceAssessmentValidation(assessmentId, indicatorRequirementId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.submitValidation(assessmentId, indicatorRequirementId)
            .then(() => {
                dispatch(submitComplianceAssessmentValidationSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function updateValidationNotesSuccess(){
    return { type: actionTypes.UPDATE_VALIDATION_NOTES_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function updateValidationNotes(assessmentId, indicatorRequirementId, validationNotes){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.updateValidationNotes(assessmentId, indicatorRequirementId, validationNotes)
            .then(() => {
                dispatch(updateValidationNotesSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function unsubmitComplianceSuccess(){
    return { type: actionTypes.UNSUBMIT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function unsubmitCompliance(assessmentId, indicatorRequirementId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.unsubmitAssessment(assessmentId, indicatorRequirementId)
            .then(() => {
                dispatch(unsubmitComplianceSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function unsubmitComplianceAssessmentStudentRecordSuccess(){
    return { type: actionTypes.UNSUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORD_AJAX_SUCCESS };
}

export function unsubmitComplianceAssessmentStudentRecord(assessmentId, studentRecordId) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.unsubmitStudentRecord(assessmentId, studentRecordId)
            .then(() => {
                dispatch(unsubmitComplianceAssessmentStudentRecordSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}


export function unsubmitValidationSuccess(){
    return { type: actionTypes.UNSUBMIT_VALIDATION_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function unsubmitValidation(assessmentId, indicatorRequirementId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.unsubmitValidation(assessmentId, indicatorRequirementId)
            .then(() => {
                dispatch(unsubmitValidationSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function unsubmitReassessmentSuccess(){
    return { type: actionTypes.UNSUBMIT_REASSESSMENT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS };
}

export function unsubmitReassessment(assessmentId, indicatorRequirementId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.unsubmitReassessment(assessmentId, indicatorRequirementId)
            .then(() => {
                dispatch(unsubmitReassessmentSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function submitStudentRecordsSuccess(){
    return { type: actionTypes.SUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORDS_AJAX_SUCCESS };
}

export function submitStudentRecords(districtId, assessmentId, indicatorRequirementId = null){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.submitStudentRecords(districtId, assessmentId, indicatorRequirementId)
            .then(() => {
                dispatch(submitStudentRecordsSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function resubmitStudentRecords(districtId, assessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.resubmitStudentRecords(districtId, assessmentId)
            .then(() => {
                dispatch(submitStudentRecordsSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}


export function loadDistrictComplianceStatusesSuccess(districtStatuses){
    return {type: actionTypes.LOAD_DISTRICT_COMPLIANCE_STATUSES_AJAX_SUCCESS, data: districtStatuses };
}

export function loadDistrictComplianceStatuses(dateWindowId, indicatorId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceApi.LoadDistrictComplianceStatuses(dateWindowId, indicatorId)
            .then((data) => {
                dispatch(loadDistrictComplianceStatusesSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}