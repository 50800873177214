import React from 'react';
import PropTypes from "prop-types";

export const GmsApplicationEditingDisabledView = ({
                                           isEditingDisabledMessageVisible,
                                       }) => {
    return isEditingDisabledMessageVisible
        ? <p className={`page-directions-highlight`}>
            <em>Editing applications is currently disabled. Please contact FDOE for further details.</em>
        </p>
        : null;
};


GmsApplicationEditingDisabledView.propTypes = {
    isEditingDisabledMessageVisible: PropTypes.bool.isRequired,
};