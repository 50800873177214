import {createDescriptionObjectForList, createListFromObject} from "../../components/common/commonUtilities";
import {Other} from "../caseNotes/genericCaseNoteConstants";

let ComplianceGeneralCaseNoteType = {
    TechnicalAssistance:  createDescriptionObjectForList(1, "Technical assistance"),
    Redaction: createDescriptionObjectForList(2, "Redaction")
};
ComplianceGeneralCaseNoteType[Other] = createDescriptionObjectForList(3, "Other");

export { ComplianceGeneralCaseNoteType };

export const ComplianceCaseNoteType = {...ComplianceGeneralCaseNoteType };

export const ComplianceCaseNoteTypeList = createListFromObject(ComplianceCaseNoteType);