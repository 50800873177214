import React from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";

const SchoolBpieCreateList = ({
                                  containerId,
                                  createContainerTitle,
                                  createSchoolsList,
                                  hasCreateErrors = false
                              }) => {
    if (createSchoolsList.length === 0)
        return null;

    const showErrorsColumn = hasCreateErrors && createSchoolsList.some((createSchool) => !isTrimmedStringEmpty(createSchool.errorMessage));

    const createNotificationTrigger = <CollapsibleTrigger
        text={createContainerTitle}/>;

    return (
        <CollapsibleContainer
            id={containerId}
            trigger={createNotificationTrigger}
        >
            <GridTable>
                <thead>
                <tr>
                    <th>District</th>
                    <th>School</th>
                    <th>Has a previous SBPIE</th>
                    {
                        showErrorsColumn && <th>Errors</th>
                    }
                </tr>
                </thead>
                <tbody>
                {
                    createSchoolsList.map((createSchool) => {
                        return (
                            <tr key={`${createSchool.districtNumber}_${createSchool.schoolNumber}`}>
                                <td>{`${createSchool.districtName} (${createSchool.districtNumber})`}</td>
                                <td>{`${createSchool.schoolName} (${createSchool.schoolNumber})`}</td>
                                <td>{createSchool.hasPreviousSbpie}</td>
                                {
                                    showErrorsColumn && <td>{createSchool.errorMessage}</td>
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </GridTable>
        </CollapsibleContainer>
    );
}

SchoolBpieCreateList.propTypes = {
    containerId: PropTypes.string.isRequired,
    createContainerTitle: PropTypes.string.isRequired,
    createSchoolsList: PropTypes.array.isRequired,
    hasCreateErrors: PropTypes.bool
};

export default SchoolBpieCreateList;