import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import SubmissionModule from "../../components/common/SubmissionModule";
import {getGeneralProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import LocalStorage from "../../components/shared/LocalStorage";
import {Link} from "react-router-dom";
import HtmlDisplayBlock from "../../components/htmlDisplay/HtmlDisplayBlock";
import {Allow} from "../../components/authorization/Allow";
import {monitoring_compliance} from "../../constants/policyEvents";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";

export class ReassessSubmitDistrictPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onClickResubmitRecords = this.onClickResubmitRecords.bind(this);
        this.onClickReassess = this.onClickReassess.bind(this);
    }

    componentDidMount() {
        this.props.actions.updatePageTitle("Monitoring for Compliance - Reassessment");
        this.validateReassessmentData();
    }

    componentDidUpdate() {
        this.validateReassessmentData();
    }

    validateReassessmentData() {
        if(!this.props.selectedAssessment) return;

        const generalAssessmentStatus = getGeneralProtocolRequirementStatus(this.props.selectedAssessment);

        if (!generalAssessmentStatus.validationSubmitted ||
            !generalAssessmentStatus.studentRecordsWithDiscrepanciesRequiringReassessment ||
            generalAssessmentStatus.allAssessmentsApproved)
            this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);
    }

    onClickResubmitRecords(){
        if(confirm("Are you sure you want to submit your reassessment?")) {
            this.props.actions.resubmitStudentRecords(LocalStorage.getLoggedInUser().InstitutionId, this.props.selectedAssessment.monitoringAssessmentId)
                .then(() => {
                    this.props.loadComplianceAssessment(() => {
                        this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);
                    });
                });
        }
    }

    onClickReassess() {
        this.props.history.push(ComplianceLocations.COMPLIANCE_REASSESS_DISTRICT.path
            .replace(ComplianceLocations.ASSESSMENT_ID, this.props.selectedAssessment.monitoringAssessmentId));
    }

    render() {
        const {isReadyForResubmission} = this.props.selectedAssessment;
        return (
            <section className="compliance__reassess">
                <div className={`page-directions`}>
                    <p>The validation of your district&apos;s self-assessment has been completed. You are required to review the discrepancies found during the
                        Bureau&apos;s validation. <Link to={ComplianceLocations.COMPLIANCE_REASSESS_DISTRICT.path.replace(ComplianceLocations.ASSESSMENT_ID, this.props.selectedAssessment.monitoringAssessmentId)}>Click here</Link> to
                        begin the review process. Contact your Bureau monitoring liaison if additional assistance is needed.</p>
                    {
                        this.props.selectedAssessment.indicatorRequirements
                            .filter(f => f.validationNotes && !isTrimmedStringEmpty(f.validationNotes)
                                && f.validationSubmitted
                                && f.studentRecordsWithDiscrepanciesRequiringReassessment
                                && !f.assessmentApproved)
                            .map((requirement, index) => {
                            return (
                                <div className={`page-directions`} key={index}>
                                    <p><strong>Additional comments regarding specific standards for {requirement.publicIdentifier}:</strong></p>
                                    <HtmlDisplayBlock html={requirement.validationNotes} />
                                </div>
                            );
                        })
                    }
                </div>

                <Allow policy={MonitoringCompliancePolicy} policyEvent={monitoring_compliance.studentRecord.SUBMIT}>
                    <SubmissionModule
                        handlePrimarySubmit={this.onClickResubmitRecords}
                        handleSecondarySubmit={this.onClickReassess}
                        makeMainButtonSecondary={!isReadyForResubmission}
                        name={"resubmitRecords"}
                        primaryButtonLabel={`Resubmit Student Records`}
                        primaryDisabled={this.props.isLoading || !isReadyForResubmission}
                        secondaryButtonLabel={`Review Discrepancies`}
                        secondaryDisabled={this.props.isLoading}
                        submissionMessage={isReadyForResubmission
                            ? "The reassessment is ready for submission."
                            : "The reassessment requires each discrepancy to be reviewed before being able to resubmit."}
                        submissionTitle={`Student Record Reassessment Submission`}
                    />
                </Allow>
            </section>
        );
    }
}

ReassessSubmitDistrictPage.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object
};

export default ReassessSubmitDistrictPage;