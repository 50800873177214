import {getPropName, isArrayNullOrEmpty, isNullOrUndefined, isNumber} from "../components/common/commonUtilities";
import {fetchEndYear} from "../components/common/dateUtilities";
import {createTeamMemberObject} from "../components/users/teamMemberObjectFactory";
import {DrRoles} from "./drConstants";

export function createCaseNumberObject(caseNumberCount, caseNumberType, dateWindows, dateWindowId) {
    let mediation = {};

    const endYear = fetchEndYear(dateWindows, dateWindowId);
    mediation.caseNumberYear = endYear;
    mediation.caseNumberType = caseNumberType;

    mediation.caseNumber = getFullCaseNumber(endYear, caseNumberCount, caseNumberType);

    return mediation;
}

export function getFullCaseNumber(endYear, caseNumberCount, type) {
    const paddedCaseNumberCount = caseNumberCount.padStart(4, '0');
    return `${endYear}-${paddedCaseNumberCount}${type}`;
}

export function getTeamMemberId(usersDrTeamMemberRoles, currentRoutingProcessRole, useBackupRole = false, allowForAnyTeamMember = false) {

    const teamMember = getTeamMember(usersDrTeamMemberRoles, currentRoutingProcessRole, useBackupRole)

    if(teamMember.role !== currentRoutingProcessRole && !allowForAnyTeamMember) return "";

    if(teamMember.teamMemberId) return teamMember.teamMemberId;

    return teamMember.id ? teamMember.id : "";
}

export function getTeamMember(usersDrTeamMemberRoles, currentRoutingProcessRole, useBackupRole = false) {
    if(isNullOrUndefined(usersDrTeamMemberRoles) || !isNumber(currentRoutingProcessRole) ||  isArrayNullOrEmpty(usersDrTeamMemberRoles))
        return {};

    let teamMembers = usersDrTeamMemberRoles.find(f => f.role === currentRoutingProcessRole);
    if(!teamMembers && useBackupRole)
        teamMembers = usersDrTeamMemberRoles.find(f => f.role === DrRoles.AdminSecretary.id);

    // adjust to allow for any team member to edit completion dates
    return teamMembers ? teamMembers : usersDrTeamMemberRoles[0];
}

export function getRole(currentRoleId) {
    const userRole = getPropName(DrRoles, currentRoleId);
    return userRole ? DrRoles[userRole] : null;
}

export function updateTeamMembers(currentTeamMembers, roles, form) {
    for(let role of roles) {
        let currentTeamMember = currentTeamMembers.find(f => f.role === role);
        const roleEnum = getPropName(DrRoles, role);
        const updatedTeamMemberId = form[roleEnum].value;

        if(!currentTeamMember && updatedTeamMemberId)
            currentTeamMembers.push(createTeamMemberObject({ id: updatedTeamMemberId, role: role}));
        else if(updatedTeamMemberId)
            currentTeamMember.id = updatedTeamMemberId;
        else
            currentTeamMembers = currentTeamMembers.filter(f => f.role !== role);
    }

    return currentTeamMembers;
}

export const validateSchoolInformationRecords = (form) => {
    let allSchoolInformationValid = true;
    let schools = [...form.schoolInformationRecords.value];
    if (schools) {
        for (let index in schools) {
            let school = {...schools[index]};
            school.schoolCodeError = "";
            if (!school.schoolCode) {
                allSchoolInformationValid = false;
                school.schoolCodeError = "Missing school code.";
            }
            school.enrollmentLocationError = "";
            if (!school.enrollmentLocation) {
                allSchoolInformationValid = false;
                school.enrollmentLocationError = "Missing enrollment location."
            }
            schools[index] = school;
        }
        form.schoolInformationRecords.setValue(schools);
    }

    return allSchoolInformationValid;
}