import React, {useEffect, useState} from 'react';
import {
    PtsFilterVisibilityRules,
} from "../../../components/common/searchCriterias/PtsSearchCriteria";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {PTS_ROLES} from "../ptsConstants";
import {PtsFilter} from "../PtsFilter";
import {createTeamMemberListItem, createTeamMembersList} from "./ptsSummaryObjectFactory";
import {PtsSummarySearchResults} from "./PtsSummarySearchResults";
import ptsSummaryApi from "./ptsSummaryApi";
import {emptyGuid} from "../../../constants/config";
import ptsAdminDiscretionaryProjectsApi from "../Admin/ptsAdminDiscretionaryProjectsApi";

const PtsSummaryContainer = ({
                                 actions,
                                 allFiscalAgents,
                                 currentUserFiscalAgent,
                                 dateWindows,
                                 history,
                                 isFiscalAgentUser,
                                 isStateUser = false,
                                 programs,
                                 searchCriteria,
                                 userId
                             }) => {
    const [summary, setSummary] = useState(null);

    const handleSearch = async (criteria, gotoFirstPage = true) => {
        let updatedCriteria = {...criteria};

        if(currentUserFiscalAgent)
            updatedCriteria.fiscalAgent = currentUserFiscalAgent;

        if (!isValidSearch(updatedCriteria)) {
            NotifyUser.Warning("Please select either a date window or a program.");
            return;
        }

        const dateWindowId = updatedCriteria.dateWindowId || emptyGuid;
        const programId = !updatedCriteria.programName ? emptyGuid : programs.find(f => f.programName === updatedCriteria.programName)?.programId ?? emptyGuid;
        const fiscalAgentId = !updatedCriteria.fiscalAgent ? emptyGuid : allFiscalAgents.find(f => f.text === updatedCriteria.fiscalAgent).value;

        const result = await actions.executeApi(ptsSummaryApi.getSummary, [dateWindowId, programId, fiscalAgentId]);

        await updateSearchResults(updatedCriteria, result, gotoFirstPage);
    };


    const updateSearchResults = async (criteria, currentSummary, gotoFirstPage = true) => {
        if (gotoFirstPage)
            criteria.First();

        if(currentUserFiscalAgent) {
            criteria.fiscalAgent = currentUserFiscalAgent;
            criteria.teamMember = userId;
            criteria.teamMembers = [createTeamMemberListItem({
                applicationUserId: userId,
                firstName: "",
                lastName: "",
                ptsRole: PTS_ROLES.projectManager.id
            })];
        }
        else {
            criteria.teamMembers = createTeamMembersList(currentSummary);
            if(!criteria.teamMembers.some(s => s.userId === criteria.teamMember))
                criteria.teamMember = "";
        }

        actions.saveSearchCriteria(criteria);
        setSummary(currentSummary);
    };

    const isValidSearch = (criteria) => {
        return !!criteria.dateWindowId || !!criteria.programName || !!criteria.fiscalAgent;
    };

    const autoSearch = async (criteria, gotoFirstPage = true) => {
        if (isFiscalAgentUser || isValidSearch(criteria))
            await handleSearch(criteria, gotoFirstPage);
        else
            await updateSearchResults(criteria, null);
    };

    const handleSearchFiltersChange = async (criteria, reloadPage) => {
        if (reloadPage) {
            await autoSearch(criteria);
        } else
            await updateSearchResults(criteria, summary);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const initializeData = async (criteria) => {
        actions.saveSearchCriteria(criteria);

        await autoSearch(criteria, false);
    };

    const handleDeletePtsProject = async (discretionaryProjectId) => {
        if (!confirm(`Are you sure you want to delete this project?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`))
            return;
        await actions.executeApi(ptsAdminDiscretionaryProjectsApi.DeleteProject, [discretionaryProjectId]);

        let criteria = {...searchCriteria};
        await initializeData(criteria)
    }

    useEffect(() => {
        let criteria = {...searchCriteria};
        if (!isFiscalAgentUser)
            criteria.setFiscalAgentList(allFiscalAgents);
        else
            criteria.fiscalAgent = currentUserFiscalAgent;

        criteria.setProgramList(programs);
        criteria.allowAllDateWindows = true;
        criteria.setVisibilityRules(isFiscalAgentUser ? PtsFilterVisibilityRules.FiscalAgentSummaryWrapper
            : PtsFilterVisibilityRules.AdminSummaryWrapper);
        initializeData(criteria);

    }, []);

    useEffect(() => {
        actions.updatePageTitle(`PTS Summary`);
    }, []);

    if (isNullOrUndefined(searchCriteria))
        return null;

    return (
        <>
            <PtsFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            {
                !isNullOrUndefined(summary) &&
                <PtsSummarySearchResults
                    actions={actions}
                    handleClickOrder={handleClickOrder}
                    handleDeletePtsProject={handleDeletePtsProject}
                    history={history}
                    isStateUser={isStateUser}
                    searchCriteria={searchCriteria}
                    summary={searchCriteria.applyFiltersAndOrder(summary)}
                />
            }
            {
                isNullOrUndefined(summary) &&
                <div className={`search-results-warning-container`}>
                    <p className={`search-results-warning`}>Please choose a date window, program, or fiscal agent.</p>
                </div>
            }
        </>
    );
};

PtsSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentUserFiscalAgent: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool,
    programs: PropTypes.array.isRequired,
    searchCriteria: PropTypes.object.isRequired,
    userId: PropTypes.string
};

export default PtsSummaryContainer;