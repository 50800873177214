import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import {DrFilter} from "../DrComponents/DrFilter";
import {DrNavigation} from "../DrComponents/DrNavigation";
import {DisputeResolutionType} from "../drConstants";
import * as drLocations from "../drLocations";
import drDueProcessesApi from "../DueProcesses/drDueProcessApi";
import DrMediationsApi from "../Mediations/drMediationsApi";
import DrStateComplaintsApi from "../StateComplaints/drStateComplaintsApi";
import {MemoizedDrDistrictSummaryDueProcess} from "./DrDistrictSummaryDueProcess";
import {MemoizedDrDistrictSummaryMediation} from "./DrDistrictSummaryMediation";
import {MemoizedDrDistrictSummaryStateComplaint} from "./DrDistrictSummaryStateComplaint";
import DrDistrictSummaryView from "./DrDistrictSummaryView";

const DrDistrictSummaryContainer = ({
                                actions,
                                allDistricts,
                                allDistrictsLoaded,
                                currentLocation,
                                dateWindowsAnnualList: dateWindows,
                                defaultDateWindowId,
                                history,
                                searchCriteria
                            }) => {

    const [summary, setSummary] = useState(null);
    const [componentResults, setComponentResults] = useState(null);

    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if (reloadsData) {
            criteria.clearOptionalSearchItems();
            await initializeData(criteria);
        } else
            actions.saveSearchCriteria(criteria);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);

        actions.saveSearchCriteria(criteria);
    };

    const initializeData = async (criteria) => {
        const {dateWindowId, disputeResolutionType} = criteria;
        if (!dateWindowId || !disputeResolutionType)
            return;

        let apiCall = null;

        switch (disputeResolutionType) {
            case DisputeResolutionType.StateComplaints:
                setComponentResults(MemoizedDrDistrictSummaryStateComplaint);
                apiCall = DrStateComplaintsApi.getSummary;
                break;
            case DisputeResolutionType.DueProcess:
                setComponentResults(MemoizedDrDistrictSummaryDueProcess);
                apiCall = drDueProcessesApi.getSummary;
                break;
            case DisputeResolutionType.Mediation:
                setComponentResults(MemoizedDrDistrictSummaryMediation);
                apiCall = DrMediationsApi.getSummary;
                break;
        }

        if (!apiCall)
            return;

        const result = await actions.executeApi(apiCall, [dateWindowId]);
        setSummary(result);

        actions.saveSearchCriteria(criteria);
    };

    const handleClickDistrict = (event, districtName) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
         const currentStatus = criteria.status;
         criteria.clearOptionalSearchItems(true, true);

        criteria.district = districtName;
        criteria.status = currentStatus;
        actions.saveSearchCriteria(criteria);

        const url = drLocations.LANDING_CASE_LIST.getUrl();
        history.push(url);
    };

    useEffect(() => {
        if (!defaultDateWindowId || !allDistrictsLoaded) return;

        let criteria = {...searchCriteria};

        criteria.setDistrictList(allDistricts);
        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrDistrictSummary);

        initializeData(criteria);

    }, [defaultDateWindowId, allDistrictsLoaded]);

    useEffect(() => {
        actions.updatePageTitle(`District Summary`);
    }, []);

    if (!searchCriteria || !componentResults || !summary || !searchCriteria.areRulesCurrent(DrFilterVisibilityRules.DrDistrictSummary))
        return null;

    return (
        <>
            <DrFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <DrDistrictSummaryView
                ComponentResults={componentResults}
                handleClickDistrict={handleClickDistrict}
                handleClickOrder={handleClickOrder}
                summary={searchCriteria.applyFiltersAndOrder(summary)}
            />

            <DrNavigation currentLocation={currentLocation} history={history}/>
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
        ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    const allDistricts = [...state.sharedData.institutionsWithStateAgencies].map(ins => {
        return {value: ins.id, text: ins.name};
    });

    const currentLocation = props.location.pathname;
    const allDistrictsLoaded = !isArrayNullOrEmpty(allDistricts);

    return {
        allDistricts,
        allDistrictsLoaded,
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrDistrictSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDistrictsLoaded: PropTypes.bool.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrDistrictSummaryContainer);

