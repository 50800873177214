import React from 'react';
import PropTypes from 'prop-types';
import * as responseOptions from "../../constants/monitoringCompliance/responseOptions";
import * as MonitoringComplianceUtilities from "./MonitoringComplianceUtilities";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

const IndicatorProtocolGridRow = ({
                                      assessmentApproved,
                                      highlightNonCompliance = false,
                                      protocol,
                                      protocolResponses,
                                      showState
                                  }) => {
    let stateRecordResponse = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.VALIDATION);
    let stateRecordResponseText = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.VALIDATION, true);
    let studentRecordReassessResponse = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.REASSESS);
    let studentRecordReassessResponseText = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.REASSESS, true);
    let studentRecordResponse = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.ASSESS);
    let studentRecordResponseText = MonitoringComplianceUtilities.getProtocolResponse(protocolResponses, protocol.protocolId, responseTypes.ASSESS, true);
    if (assessmentApproved) {
        studentRecordResponse = MonitoringComplianceUtilities.getProtocolApprovedResponse(stateRecordResponse, studentRecordReassessResponse, studentRecordResponse);
        studentRecordResponseText = MonitoringComplianceUtilities.getProtocolApprovedResponse(stateRecordResponseText, studentRecordReassessResponseText, studentRecordResponseText);
    }

    const highlightClass = highlightNonCompliance && studentRecordResponse === responseOptions.NO ? "highlight-row" : "";

    return (
        <>
            <tr className={`align-top ${highlightClass}`}>
                <td className={"text-center"}>{protocol.publicIdentifier}</td>
                <td><HtmlDisplayBlock html={protocol.standardDescription + " " + protocol.citation}/></td>
                <td className={"text-center"}>
                    <HtmlDisplayBlock html={studentRecordResponseText} />
                </td>
                {
                    showState &&
                    !assessmentApproved &&
                    <td className={"text-center"}>
                        <HtmlDisplayBlock html={stateRecordResponseText} />
                    </td>
                }
            </tr>
            {
                protocol.subQuestions.map((subQuestion, index) => {
                    let districtSubQuestionReassessResponse = "";
                    let districtSubQuestionResponse = "";
                    let stateSubQuestionResponse = "";

                    let districtSubQuestionReassessResponses = MonitoringComplianceUtilities.getSubQuestionResponse(protocolResponses, protocol.protocolId, responseTypes.REASSESS);
                    let districtSubQuestionResponses = MonitoringComplianceUtilities.getSubQuestionResponse(protocolResponses, protocol.protocolId, responseTypes.ASSESS);
                    let stateSubQuestionResponses = MonitoringComplianceUtilities.getSubQuestionResponse(protocolResponses, protocol.protocolId, responseTypes.VALIDATION);
                    if (districtSubQuestionReassessResponses.length >= index + 1) {
                        districtSubQuestionReassessResponse = districtSubQuestionReassessResponses[index];
                    }
                    if (districtSubQuestionResponses.length >= index + 1) {
                        districtSubQuestionResponse = districtSubQuestionResponses[index];
                    }
                    if (stateSubQuestionResponses.length >= index + 1) {
                        stateSubQuestionResponse = stateSubQuestionResponses[index];
                    }

                    if (assessmentApproved)
                        districtSubQuestionResponse = MonitoringComplianceUtilities.getProtocolApprovedResponse(stateSubQuestionResponse, districtSubQuestionReassessResponse, districtSubQuestionResponse);

                    return (
                        <tr key={protocol.protocolId + "_" + index} className={`align-top ${highlightClass}`}>
                            <td>&nbsp;</td>
                            <td className={"sub-question"}>
                                <ol type={"a"} start={index + 1}>
                                    <li><HtmlDisplayBlock html={subQuestion.text}/></li>
                                </ol>
                            </td>
                            <td className={"text-center"}>
                                {districtSubQuestionResponse}
                            </td>
                            {
                                showState &&
                                !assessmentApproved &&
                                <td className={"text-center"}>
                                    {stateSubQuestionResponse}
                                </td>
                            }
                        </tr>
                    );
                })
            }
        </>
    );
};

IndicatorProtocolGridRow.propTypes = {
    assessmentApproved: PropTypes.bool.isRequired,
    highlightNonCompliance: PropTypes.bool,
    protocol: PropTypes.object.isRequired,
    protocolResponses: PropTypes.array.isRequired,
    showState: PropTypes.bool.isRequired
};

export default IndicatorProtocolGridRow;