import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import AccountUtility from "../../api/AccountUtility";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {getReportComponent} from "../reportConfiguration";
import * as reportLocations from "../reportLocations";

const PrintContainer = ({
	                        actions,
	                        reportId,
	                        context,
	                        reportArea,
	                        dateWindowId,
	                        districtId,
	                        institutions,
	                        dateWindows,
							reportTitle,
                        }) => {
	const isDistrictUser = !AccountUtility.isBpieStateUser();

	const Component = getReportComponent(reportId, context, isDistrictUser ? "district" : reportArea);
	if (isArrayNullOrEmpty(institutions)) return null;

	return (
		<section>
			{
				!reportTitle &&
				<header className={`report-header`}>
					<h1>Florida Department of Education
						<br/>Bureau of Exceptional Education and Student Services</h1>
				</header>
			}
			{
				!Component &&
				<p className={"report-not-found"}>
					The selected report no longer exists. Please try again or contact support for help.
				</p>
			}
			{
				Component &&
				<Component
					actions={actions}
					dateWindowId={dateWindowId}
					districtId={districtId}
					districts={institutions}
					dateWindows={dateWindows}
					reportTitle={reportTitle}
				/>
			}
		</section>
	);
};

PrintContainer.propTypes = {
	actions: PropTypes.object,
	reportId: PropTypes.string.isRequired,
	context: PropTypes.string.isRequired,
	reportArea: PropTypes.string.isRequired,
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string.isRequired,
	institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
	dateWindows: PropTypes.array,
	reportTitle: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
	const params = reportLocations.REPORTS_PRINT.getParams(ownProps.location.pathname);
	return {
		...params
	};
};

const mapDispatchToProps = dispatch => {
	const combinedActions = Object.assign({}, apiForLocalStateActions);
	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PrintContainer);
