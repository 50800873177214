import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as surveyActions from '../../actions/surveyActions';
import * as layoutActions from '../../actions/layoutActions';
import SurveyListEditable from '../../components/survey/SurveyListEditable';
import SurveyListNonEditable from '../../components/survey/SurveyListNonEditable';
import LocalStorage from "../../components/shared/LocalStorage";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import * as publicLocations from "../../constants/publicLocations";
import { getParams } from "../../components/layout/getParams";

export class SurveyLandingPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onSurveyClick = this.onSurveyClick.bind(this);
	}

	componentDidMount() {
		const params = getParams(this.props.location.pathname, publicLocations.SURVEYS_LANDING);

		if(params !== null)
			this.setState({params});

		if(params.respondentId)
			this.props.actions.loadSurveysByRespondentId(params.respondentId);

		this.props.actions.updatePageTitle("Surveys");
	}

	componentWillUnmount() {
		this.props.actions.clearSurveyData();
	}

	onSurveyClick(instanceId) {
		const path = LocalStorage.isLoggedIn() ?
			surveyLocations.TAKE.path :
			publicLocations.SURVEYS_TAKE.path;

		this.props.history.push(path
			.replace(surveyLocations.INSTANCE_ID, instanceId)
			.replace(surveyLocations.RESPONDENT_ID, this.state.params.respondentId));
	}

	render() {
		return (
			<div>
				{this.props.surveyCount === 0 &&
				!this.props.isLoading &&
				<p>There are no surveys at this time, but thanks for checking in!</p>
				}
				{this.props.isLoading &&
				<p>Loading surveys...</p>
				}

				<SurveyListEditable surveys={this.props.editableSurveys} onClick={this.onSurveyClick}/>

				<SurveyListNonEditable surveys={this.props.nonEditableSurveys}/>
			</div>
		);
	}
}

SurveyLandingPage.propTypes = {
	actions: PropTypes.object.isRequired,
	surveyCount: PropTypes.number.isRequired,
	editableSurveys: PropTypes.arrayOf(PropTypes.object).isRequired,
	nonEditableSurveys: PropTypes.arrayOf(PropTypes.object).isRequired,
	location: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		surveyCount: state.survey.surveys.length,
		editableSurveys: state.survey.surveys.filter(s => s.canEditResponse),
		nonEditableSurveys: state.survey.surveys.filter(s => s.canEditResponse === false),
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, surveyActions, layoutActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyLandingPage);