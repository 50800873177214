import React from 'react';
import PropTypes from 'prop-types';
import SppElementPrint from "./SppElementPrint";

const SectionPrint = ({sectionId, sectionTitle, elements, responses, fileOnView}) => {
    return (
            <section id={sectionId} className={`pageBreak--avoid`}>
                <h2>{sectionTitle}</h2>
                {

                    elements.map(childElement => {
                        const response = responses.filter(r => r.elementId === childElement.id)[0];

                        return (<SppElementPrint key={childElement.id}
                                                 element={childElement}
                                                 response={response}
                                                 fileOnView={fileOnView}
                        />);
                    })
                }
            </section>
    );
};


SectionPrint.propTypes = {
    sectionId: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,
    fileOnView: PropTypes.func
};

export default SectionPrint;