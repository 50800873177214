import PropTypes from "prop-types";
import React from "react";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../../components/common/commonUtilities";
import GridColumn from "../../../../../components/common/GridColumn";
import GridRow from "../../../../../components/common/GridRow";
import {SelectField} from "../../../../../components/common/inputs";
import TextAreaEditor from "../../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import HtmlDisplayBlock from "../../../../../components/htmlDisplay/HtmlDisplayBlock";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {gridConstants} from "../../../../../constants/inputConstants";
import {DistrictPieActionStepStatusList} from "../../../bpieConstants";

export const BpieDistrictPieActionStepUpdateDetailsForm = ({
                                                               parentObject: actionStep,
                                                               actionStepForm,
                                                               canEditNotes,
                                                               handleDeleteActionStepUpdate
                                                           }) => {
    const handleDeleteActionStepUpdateClick = (event) => {
        event.preventDefault();
        handleDeleteActionStepUpdate(actionStep.actionStepId);
    };

    const actionStepUpdateHasData = !isTrimmedStringEmpty(actionStepForm[`dpieActionStepStatus_${actionStep.actionStepId}`].value)
        || !isTrimmedStringEmpty(actionStepForm[`actionStepUpdateNotes_${actionStep.actionStepId}`].value);

    const canEditAll = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);

    return <div className={`viewInfo`}>
        <GridRow>
            <GridColumn>
                <HtmlDisplayBlock html={actionStep.actionStepDescription}/>
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={gridConstants.column.SIX}>
                <strong>By Whom:</strong><br/>
                <span className={`help-text`}>(FIN, District, Other Collaborative Partner)</span>
            </GridColumn>
            <GridColumn medium={gridConstants.column.SIX}>
                {actionStep.responsibleForCompletion}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={gridConstants.column.SIX}>
                <strong>By When:</strong><br/>
                <span className={`help-text`}>(Deadline for Completion)</span>
            </GridColumn>
            <GridColumn medium={gridConstants.column.SIX}>
                {actionStep.completeByDate}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={gridConstants.column.SIX}>
                <strong>Follow-Up:</strong><br/>
                <span className={`help-text`}>(Measurable and aligned with each action step)</span>
            </GridColumn>
            <GridColumn medium={gridConstants.column.SIX}>
                {actionStep.followUp}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={gridConstants.column.SIX}>
                <strong>By What:</strong><br/>
                <span
                    className={`help-text`}>(Provide evidence that will demonstrate completion of Action Step)</span>
            </GridColumn>
            <GridColumn medium={gridConstants.column.SIX}>
                {actionStep.evidence}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={gridConstants.column.SIX}>
                <strong>Current Status:</strong>
            </GridColumn>
            <GridColumn medium={gridConstants.column.SIX}>
                {actionStep.currentActionStepStatus}
            </GridColumn>
        </GridRow>
        {
            canEditAll &&
            <ButtonBar position={ButtonBarPositions.TOP}>
                <Button
                    buttonType={ButtonTypes.DELETE}
                    disabled={!actionStepUpdateHasData}
                    label={"Delete"}
                    name={`btnDelete_${actionStep.actionStepId}`}
                    onClick={handleDeleteActionStepUpdateClick}
                />
            </ButtonBar>
        }

        <SelectField
            {...actionStepForm[`dpieActionStepStatus_${actionStep.actionStepId}`]}
            defaultOption={`Select a status option`}
            disabled={!canEditAll}
            includeDefaultOption
            label={`Action Step Update for Action Step ${actionStep.actionStepNumber}: `}
            medium={gridConstants.column.SIX}
            options={DistrictPieActionStepStatusList}
            showLabel
        />

        <TextAreaEditor
            {...actionStepForm[`actionStepUpdateNotes_${actionStep.actionStepId}`]}
            disabled={!canEditNotes}
            label={`Notes for Action Step ${actionStep.actionStepNumber}`}
            showLabel
        />
    </div>;
};

BpieDistrictPieActionStepUpdateDetailsForm.propTypes = {
    actionStepForm: PropTypes.object,
    canEditNotes: PropTypes.bool.isRequired,
    handleDeleteActionStepUpdate: PropTypes.func.isRequired,
    parentObject: PropTypes.object
};