import {
    createDescriptionObjectForList, createListFromObject
} from "../../components/common/commonUtilities";

export const INSTITUTION_TYPE_FORM_NAME = "institutionType";
export const INSTITUTION_TYPE_DESCRIPTION = "institutionTypeDescription";

export const INSTITUTION_TYPES = {
    county: createDescriptionObjectForList(1, "County"),
    charter: createDescriptionObjectForList(2, "Charter"),
    labSchool: createDescriptionObjectForList(3, "Lab School"),
    virtual: createDescriptionObjectForList(4, "Virtual"),
    stateAgency: createDescriptionObjectForList(5, "State Agency"),
    discretionaryAgency: createDescriptionObjectForList(6, "Discretionary Agency")
};

export const INSTITUTION_TYPES_LIST = createListFromObject(INSTITUTION_TYPES);
export const INSTITUTION_FORM_TYPES_LIST = INSTITUTION_TYPES_LIST.filter(f => f.id !== INSTITUTION_TYPES.stateAgency.id);