import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import {DBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";
import BpieDownloadButtonsView from "../../BpieDownloadButtonsView";
import {getResponse} from "../../Assessment/bpieAssessmentFactory";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import BpieAssessmentSummaryView from "../../Assessment/AssessmentSummary/BpieAssessmentSummaryView";
import {createSubmissionModuleOptions} from "../../bpieObjectFactory";
import bpieDistrictApi from "../bpieDistrictApi";

const DistrictAssessmentSummaryContainer = ({
                                                actions,
                                                districtBpie,
                                                handleAssessmentRedirect,
                                                handlePrioritiesRedirect,
                                                isDistrictUser,
                                                isEditable,
                                                loadDistrictBpie
                                            }) => {
    const [responses, setResponses] = useState(null);

    const onEditIndicator = (event, desiredIndicator) => {
        event.preventDefault();
        handleAssessmentRedirect(desiredIndicator);
    }

    const submitDistrictBpie = (feedback) => {
        if (confirm("Are you sure you want to submit the DBPIE Assessment?")) {
            actions.executeApi(bpieDistrictApi.updateDistrictBpieStatus, [districtBpie.districtId, districtBpie.districtBpieId, districtBpie.nextDistrictBpieStatus, feedback])
                .then(() => {
                    loadDistrictBpie();
                });
        }
    }

    const saveFeedback = (feedback) => {
        actions.executeApi(bpieDistrictApi.saveDistrictFeedback, [districtBpie.districtId, districtBpie.districtBpieId, feedback])
            .then(() => {
                loadDistrictBpie();
            });
    };

    const priorityButtonClick = () => {
        handlePrioritiesRedirect();
    }

    const buildSubmissionModuleOptions = (dataMissing, priorityIndicatorDataMissing) => {
        if (districtBpie.isFinalized)
            return createSubmissionModuleOptions(true,
                true,
                `Submit DBPIE Assessment`,
                `The DBPIE Assessment has been finalized.`,
                `DBPIE Assessment`);

        const autoApproveMessage = districtBpie.autoApproveDate ? `<br/><br/>NOTE: This DBPIE will be automatically approved on ${districtBpie.autoApproveDate}` : '';

        if (!districtBpie.canSubmit)
            return createSubmissionModuleOptions(true,
                true,
                `Submit DBPIE Assessment`,
                `Current status - ${districtBpie.districtBpieStatusDescription} ${autoApproveMessage}`,
                `DBPIE Assessment`);

        if (dataMissing || priorityIndicatorDataMissing)
            return createSubmissionModuleOptions(false,
                true,
                `Submit DBPIE Assessment`,
                dataMissing ? `Highlighted responses above need to be completed.` : `Priority indicators need to be selected.`,
                `DBPIE Assessment Submission`);

        let emailStep = "";

        switch (districtBpie.nextDistrictBpieStatus) {
            case DBPIE_ASSESSMENT_STATUS.AdminReviewing.id:
                emailStep = " and notify the FIN Admin that it is available for review";
                break;
            case DBPIE_ASSESSMENT_STATUS.AdminReviewed.id:
                emailStep = " and notify the FIN Facilitator that it is available to be submitted to the district for their review";
                break;
            case DBPIE_ASSESSMENT_STATUS.Draft.id:
                emailStep = " and <strong><em>NOT</em></strong> notify the district, as this will be handled directly by the FIN Facilitator or FIN Administrator";
                break;
            case DBPIE_ASSESSMENT_STATUS.DistrictReviewed.id:
                emailStep = " and notify the FIN Facilitator or FIN Administrators that feedback has been provided";
                break;
            case DBPIE_ASSESSMENT_STATUS.Finalized.id:
                emailStep = " and notify the district that it is available for download";
                break;
        }

        const districtBpieDistrictFeedback = districtBpie.districtBpieStatus === DBPIE_ASSESSMENT_STATUS.Draft.id
            ? "Feedback for "
            : "";

        return createSubmissionModuleOptions(false,
            false,
            `Submit ${districtBpieDistrictFeedback}DBPIE Assessment`,
            `Submitting the DBPIE Assessment will change its status to &quot;${districtBpie.nextDistrictBpieStatusDescription}&quot;${emailStep}. ${autoApproveMessage}`,
            `DBPIE Assessment Submission`,
            `Save Feedback Only`,
            false,
            !isDistrictUser);
    };

    useEffect(() => {
        if (!districtBpie)
            return;

        const builtResponses = districtBpie.indicators.map((i) => {
            return getResponse(districtBpie.responses, i.bpieIndicatorId);
        });

        if (isEditable && builtResponses.every(f => isTrimmedStringEmpty(f.implementationStatus))) {
            handleAssessmentRedirect(1);
            return;
        }

        setResponses(builtResponses);
    }, []);

    if (!districtBpie || !responses)
        return null;

    return (
        <>
            <h2>DBPIE Assessment Summary</h2>

            <BpieAssessmentSummaryView
                bpieLabel={`DBPIE`}
                buildSubmissionModuleOptions={buildSubmissionModuleOptions}
                disableFeedback={districtBpie.disableDistrictFeedback}
                displayFeedback={districtBpie.displayDistrictFeedback}
                feedbackValue={districtBpie.districtFeedback}
                feedbackLabel={`Overall District DBPIE Assessment Feedback<br/>(Also include indicator number for feedback specific to indicators.)`}
                indicators={districtBpie.indicators}
                isIncomplete={false}
                isSubmitted={!isEditable || isDistrictUser}
                onEditIndicator={onEditIndicator}
                priorityButtonClick={priorityButtonClick}
                responses={responses}
                saveFeedback={saveFeedback}
                submitBpie={submitDistrictBpie}
                submitPolicyEvent={policyEvents.districtBpieAssessments.SUBMIT}
                useIsModuleReview={false}
            />

            {
                districtBpie.isFinalized &&
                <BpieDownloadButtonsView
                    actions={actions}
                    bpieId={districtBpie.districtBpieId}
                    isDistrictBpie
                />
            }
        </>
    );
}

DistrictAssessmentSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    districtBpie: PropTypes.object.isRequired,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handlePrioritiesRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    loadDistrictBpie: PropTypes.func.isRequired
}

export default DistrictAssessmentSummaryContainer;