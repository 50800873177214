import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {convertBooleanToYesNoText} from "../../../../components/shared/sharedDataUtilities";

export const PtsManageProgramsTableRowDisplay = ({
                                                     handleDeleteProgramClick,
                                                     handleModifyProgramClick,
                                                     handleViewProgramClick,
                                                     program,
                                                     rowDisabled
                                                 }) => {
    return (
        <tr>
            <td>{program.programName}</td>
            <td>{convertBooleanToYesNoText(program.requiresReturnOnInvestmentReport)}</td>
            <td className={`action-no-wrap`}>
                <Button
                    buttonType={ButtonTypes.EDIT}
                    disabled={rowDisabled}
                    label={`Modify program for ${program.programName}`}
                    name={`btnModifyProgram_${program.programId}`}
                    onClick={(event) => handleModifyProgramClick(event, program)}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.DELETE}
                    disabled={rowDisabled}
                    label={`Delete program for ${program.programName}`}
                    name={`btnDeleteProgram_${program.programId}`}
                    onClick={(event) => handleDeleteProgramClick(event, program.programId)}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.VIEW}
                    disabled={rowDisabled}
                    label={`View program details for ${program.programName}`}
                    name={`btnViewProgram_${program.programId}`}
                    onClick={(event) => handleViewProgramClick(event, program.programId)}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

PtsManageProgramsTableRowDisplay.propTypes = {
    handleDeleteProgramClick: PropTypes.func.isRequired,
    handleModifyProgramClick: PropTypes.func.isRequired,
    handleViewProgramClick: PropTypes.func.isRequired,
    program: PropTypes.object,
    rowDisabled: PropTypes.bool.isRequired,
};