import {
    createDescriptionObjectForList,
    createListFromObject,
    createListItem, generateArrayFromObjectValues
} from "../../components/common/commonUtilities";
import * as apiParameters from "../../constants/api/apiParameters";

export const API_MESSAGES = {
    ADMIN: {
        TEAM_MEMBERS: {
            saved: `The team members list was successfully saved.`,
        },
        APPLICATION_SETTINGS: {
            saved: `The application settings were successfully saved.`,
            deleted: `The application was successfully deleted.`
        },
        GMS_APPLICATION_SECTIONS: {
            saved: `The Response Behavior settings were successfully saved.`
        },
        GMS_SETTINGS: {
            saved: `The Fiscal Agent Access settings were successfully saved.`
        },
        IMPORT: {
            success: "Application successfully imported."
        },
        AUDIT: {
            getMessage: (auditAction) => {
                switch (auditAction) {
                    case apiParameters.audit.APPROVE:
                        return API_MESSAGES.ADMIN.AUDIT.approved;
                    case apiParameters.audit.COMMENTS:
                        return API_MESSAGES.ADMIN.AUDIT.auditComments;
                    case apiParameters.audit.RESET:
                        return API_MESSAGES.ADMIN.AUDIT.auditReset;
                    case apiParameters.audit.REVISE:
                        return API_MESSAGES.ADMIN.AUDIT.needsRevision;
                    default:
                        return "Success. No message included.";
                }
            },
            auditReset: `Application audit for this section successfully reset.`,
            auditComments: `Application audit comment successfully saved.`,
            approved: `Application successfully approved for this section.`,
            needsRevision: `Application successfully marked as needs revision for this section.`,
        },
        CASE_NOTE: {
            created: `The case note was successfully added.`,
            deleted: `The case note was successfully removed.`,
            updated: `The case note was successfully updated.`,
            downloaded: `The case notes were successfully downloaded.`
        },
        DISCRETIONARY_PROJECTS: {
            saved: `The discretionary project was successfully saved.`,
            deleted: `The discretionary project was successfully deleted.`,
            FISCAL_AGENTS_USERS: {
                saved: `The associated fiscal agents and users were successfully saved.`,
            }
        }
    },
    ASSESSMENT: {
        UPDATE_RESPONSE: {
            success: "Responses successfully saved."
        },
        SUBMIT_APPLICATION: {
            success: "Application successfully submitted."
        }
    },
    BUDGET: {
        DELETE_LINE_ITEM: {
            success: "Budget line item successfully deleted."
        },
        EXPORT: {
            success: "Budget successfully exported."
        },
        IMPORT: {
            success: "Budget successfully imported."
        },
        SAVE_LINE_ITEM: {
            success: "Budget line item successfully saved."
        }
    },
    DELIVERABLE: {
        DELETE_LINE_ITEM: {
            success: "Deliverable successfully deleted."
        },
        SAVE_LINE_ITEM: {
            success: "Deliverable successfully saved."
        }
    },
    SALARY: {
        DELETE_LINE_ITEM: {
            success: "Salary Increase successfully deleted."
        },
        SAVE_LINE_ITEM: {
            success: "Salary Increase successfully saved."
        }
    },
    DOE_FORMS: {
        DOWNLOAD: {
            getMessage: (formType, isSigned) => {
                const signed = isSigned ? "Signed " : "";
                return `${signed}${formType.description} successfully downloaded.`;
            }
        },
        UPLOAD: {
            getMessage: (formType, isSigned) => {
                const signed = isSigned ? "Signed " : "";
                return `${signed}${formType.description} successfully uploaded.`;
            }
        }
    },
    AMENDMENT: {
        createSuccess: "Amendment successfully created."
    }

};

export const APPLICATION_STATUS = {
    notStarted: createDescriptionObjectForList(1, "Created"),
    inProgress: createDescriptionObjectForList(2, "In Progress"),
    readyToSubmit: createDescriptionObjectForList(3, "Fiscal Agent Submitted"),
    approved: createDescriptionObjectForList(4, "Approved"),
    needsRevision: createDescriptionObjectForList(5, "Needs Revision"),
    completed: createDescriptionObjectForList(6, "Completed"),
    needsAmendment: createDescriptionObjectForList(7, "Needs Amendment")
};

export const APPLICATION_STATUS_LIST = generateArrayFromObjectValues(APPLICATION_STATUS).map((type) => createListItem(type.description));

export const COPY_BEHAVIOR = {
    doNotCopyFromPreviousYear: createDescriptionObjectForList(1, "Do Not Copy From Previous Year"),
    copyFromPreviousYear: createDescriptionObjectForList(2, "Copy From Previous Year"),
}
export const COPY_BEHAVIOR_LIST = createListFromObject(COPY_BEHAVIOR);

export const ELEMENT_TYPE = {
    textbox: 1,
    textarea: 2,
    dropdown: 3,
    radio: 4,
    file: 5,
    display: 6,
    checkbox: 7,
    section: 8,
    checkboxList: 9,
    wholeNumberTextbox: 10,
    dollarTextbox: 11,
    doe101Section: 13,
    doe100: 14,
    wholeDollarTextbox: 15,
    percentTextbox: 16,
    doe150: 17,
    doe151Section: 18,
    deliverableSection: 19,
    salarySection: 20,
};

export const FORM_TYPE = {
    doe101: createDescriptionObjectForList(1, "DOE 101"),
    doe100: createDescriptionObjectForList(2, "DOE 100"),
    doe150: createDescriptionObjectForList(3, "DOE 150"),
    doe151: createDescriptionObjectForList(4, "DOE 151"),
    doe200: createDescriptionObjectForList(5, "DOE 200"),
}

export const RESPONSE_STATUS_TYPE = {
    notStarted: createDescriptionObjectForList(1, "Not Started"),
    inProgress: createDescriptionObjectForList(2, "In Progress"),
    readyToSubmit: createDescriptionObjectForList(3, "Ready to Submit"),
    viewOnly: createDescriptionObjectForList(4, "View-Only"),
    fdoeReviewing: createDescriptionObjectForList(5, "FDOE Reviewing"),
    approved: createDescriptionObjectForList(6, "Approved"),
    needsRevision: createDescriptionObjectForList(7, "Needs Revision"),
    needsAmendment: createDescriptionObjectForList(8, "Needs Amendment")
};

export const RESPONSE_STATUS_TYPE_LIST = createListFromObject(RESPONSE_STATUS_TYPE);

export const getResponseStatusType = (type = 1) => {
    const currentStatus = RESPONSE_STATUS_TYPE_LIST.find(f => f.id === type);
    return !currentStatus ? RESPONSE_STATUS_TYPE.notStarted.description : currentStatus.text;
};

export function allSameStatusTypeGroupsCollapseSection(isFiscalAgentUser) {
    return isFiscalAgentUser ?
        [
            [RESPONSE_STATUS_TYPE.readyToSubmit, RESPONSE_STATUS_TYPE.approved],
        ]
        :
        [
            [RESPONSE_STATUS_TYPE.approved, RESPONSE_STATUS_TYPE.needsRevision]
        ];
}

export const BUDGET_CODE_TYPE = {
    fundingCategory: 1,
    function: 2,
    object: 3,
};

export const BUDGET_FTE_VALUE = {
    permissible: 1,
    notPermissible: 2,
    required: 3,
}

export const APPLICATION_TYPE = {
    k12: createDescriptionObjectForList(1, "IDEA, Part B, K-12"),
    preK: createDescriptionObjectForList(2, "IDEA, Part B, Preschool")
}

export const APPLICATION_TYPE_LIST = generateArrayFromObjectValues(APPLICATION_TYPE).map((type) => createListItem(type.description));
export const APPLICATION_TYPE_FORM_LIST = createListFromObject(APPLICATION_TYPE);

export const GMS_ROLES = {
    grantsManagementUser: createDescriptionObjectForList(1, "Grants Management User")
};

export const GMS_ROLES_LIST = createListFromObject(GMS_ROLES);

export const APPLICATION_ACCESS_STATUS = {
    notVisible: createDescriptionObjectForList(1, "No access"),
    visible: createDescriptionObjectForList(2, "Viewable only"),
    editableButNotSubmittable: createDescriptionObjectForList(3, "Open for data collection only"),
    editableAndSubmittable: createDescriptionObjectForList(4, "Open for both data collection and submission")
};

export const APPLICATION_ACCESS_STATUS_LIST = createListFromObject(APPLICATION_ACCESS_STATUS);