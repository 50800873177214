import React from "react";
import PropTypes from 'prop-types';
import audit_outcomes from "../../../constants/auditOutcomes";
import {monitoring_compliance} from "../../../constants/policyEvents";
import * as AuthorizationUtilities from "../../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../authorization/policies/MonitoringCompliancePolicy";
import CheckBoxField from "../../common/inputs/CheckBoxField";
import RadioField from "../../common/inputs/RadioField";
import DateTimePickerField from "../../common/inputs/DateTimePickerField";
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import GridRow from "../../common/GridRow";
import GridColumn from "../../common/GridColumn";
import CheckBoxFieldOtherText from "../../common/inputs/CheckBoxFieldOtherText";
import AuditBar from "../../common/buttons/AuditBar";
import AuditHistory from "../../audit/AuditHistory";
import * as correctionResultConstants from "../../../constants/monitoringCompliance/correctionResults";
import * as ActionBarPositions from "../../../constants/ActionBarPositions";
import {getNeedsRevisionAuditComment, optionIndexInArray} from "../../common/commonUtilities";
import {inputStyles} from '../../../constants/inputConstants';
import FieldsetLayout from "../../common/inputs/FieldsetLayout";
import {isViewOnlyDueToStatus} from "../MonitoringComplianceUtilities";

const ResultsGridForm = ({
	                         correctionResults,
	                         onResponseChange,
	                         isLoading,
	                         idPropName,
	                         correctionOptions,
	                         correctionsWithAdditionalInfoRequirement,
	                         mainAreaWidthProps
                         }) => {
	const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);
	return (
		<tbody>
		{
			correctionResults.map((result, index) => {
				const corrected = !result.corrected ? "" : result.corrected.toString();
				const viewOnlyDueToStatus = isViewOnlyDueToStatus(result.status) || !canEdit;
				const notCorrected = corrected === correctionResultConstants.corrected.No;
				const viewOnly = notCorrected || viewOnlyDueToStatus;
				const isMissingCheckBox = result.corrections.length === 0;
				const missingCheckboxDataMessage = isMissingCheckBox && !viewOnly ? "Corrections are required." : "";
				return (
					<tr key={`sr_${index}`} id={result.protocolId || `sr_${index}`}>
						<td>
							{
								result.status !== audit_outcomes.IN_PROGRESS.value &&
								<AuditBar
									elementId={result[idPropName]}
									isExpanded={false}
									outcome={result.status}
									position={ActionBarPositions.RIGHT}
								/>
							}
							<GridRow>
								<GridColumn columnClass={"correction-info"}>
									{
										result.publicIdentifier &&
										<GridRow>
											<GridColumn medium={"1"}>
												{result.publicIdentifier}
											</GridColumn>
											<GridColumn medium={"11"}>
												<HtmlDisplayBlock html={result.standardDescription + " " + result.citation}/>
											</GridColumn>
										</GridRow>
									}
									{
										result.reportedCostFactor &&
										<GridRow>
											<GridColumn medium={"4"}>
												Reported Cost Factor: {result.reportedCostFactor}
											</GridColumn>
											<GridColumn medium={"4"}>
												Reviewed Cost Factor: {result.reviewedCostFactor}
											</GridColumn>
										</GridRow>
									}
								</GridColumn>
								<GridColumn {...mainAreaWidthProps}>
									{
										result.status === audit_outcomes.NEEDS_REVISION.value &&
										<div className={`highlight-needsRevision`}>
											<p><strong>Feedback:</strong></p>
											<HtmlDisplayBlock html={getNeedsRevisionAuditComment(result.audits)} />
											<CheckBoxField
												checked={result.acknowledgeNeedsRevisionComplete || false}
												label={`I acknowledge that the revisions are complete and ready to be submitted.`}
												name={`cbAcknowledgeNeedsRevisionComplete_${index}`}
												onChange={(event) => onResponseChange(event, result[idPropName])}
												showLabel
												value={`acknowledgeNeedsRevisionComplete`}
												wrapperClass={`acknowledge-container`}
											/>
										</div>
									}
									<RadioField
										name={"corrected"}
										options={correctionResultConstants.correctedOptions}
										disabled={viewOnlyDueToStatus || isLoading}
										value={corrected}
										legend={"Corrected"}
										showLegend={true}
										onChange={(event) => onResponseChange(event, result[idPropName])}
										direction={inputStyles.radio.direction.HORIZONTAL}
										error={(!corrected || notCorrected) && !viewOnlyDueToStatus ? "Corrected needs updating." : ""}
									/>
									<DateTimePickerField
										disabled={viewOnly || isLoading}
										error={!result.dateCorrected && !viewOnly ? "Date corrected is required." : ""}
										inputClass={`input--small`}
										name={"dateCorrected"}
										label={"Date Corrected"}
										onChange={(event) => onResponseChange(event, result[idPropName])}
										showLabel={true}
										value={result.dateCorrected}
									/>
									<GridRow>
										<GridColumn>
											<FieldsetLayout
												legend={"Corrections"}
												showLegend
												error={missingCheckboxDataMessage}
											>
												{
													correctionOptions.map((option, index) => {
														const correctionsIndex = optionIndexInArray(result.corrections, option.value, false);
														const isChecked = correctionsIndex > -1;
														const showOtherText = correctionsWithAdditionalInfoRequirement[option.id];
														const otherText = isChecked && showOtherText ? result.corrections[correctionsIndex] : "";
														const propID = result[idPropName];
														return (
															<CheckBoxFieldOtherText
																key={`scl_${index}_${propID}`}
																index={index}
																isChecked={isChecked}
																label={option.value}
																name={"corrections"}
																otherText={otherText}
																otherTextId={showOtherText ? option.id : ""}
																otherTextSeparator={correctionResultConstants.correctionOtherTextSeparator}
																onResponseChange={onResponseChange}
																viewOnly={viewOnly}
																isLoading={isLoading}
																propID={propID}
															/>
														);
													})
												}
											</FieldsetLayout>
										</GridColumn>
									</GridRow>
								</GridColumn>
							</GridRow>

							<AuditHistory
								audits={result.audits}
								isLoading={isLoading}
							/>
						</td>
					</tr>
				);
			})
		}
		</tbody>
	);
};

ResultsGridForm.propTypes = {
	correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
	correctionOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
	correctionsWithAdditionalInfoRequirement: PropTypes.object.isRequired,
	onResponseChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	idPropName: PropTypes.string.isRequired,
	mainAreaWidthProps: PropTypes.object.isRequired
};

export default ResultsGridForm;