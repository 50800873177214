import PropTypes from "prop-types";
import React from "react";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";

export const GmsAdminNavigation = ({
                                       handleCancel,
                                       handleSave,
                                       isDirty,
                                   }) => {
    
    if (!handleSave && !handleCancel) return null;

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
            {
                handleCancel &&
                <Button
                    buttonType={ButtonTypes.CANCEL}
                    disabled={!isDirty}
                    label={`Cancel`}
                    name={`btnCancel`}
                    onClick={handleCancel}
                />
            }
            {
                handleSave &&
                <Button
                    buttonType={ButtonTypes.SAVE}
                    disabled={!isDirty}
                    label={isDirty ? `Save Settings` : `Settings Saved`}
                    name={`btnSave`}
                    onClick={handleSave}
                />
            }
        </ButtonBar>
    );
};

GmsAdminNavigation.propTypes = {
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func,
    isDirty: PropTypes.bool,
};
