import React from 'react';
import PropTypes from 'prop-types';
import IndicatorProtocolForm from "./IndicatorProtocolForm";

const IndicatorProtocolStateForm = ({
                                        protocol,
                                        response,
                                        responseText,
                                        subQuestionResponses,
                                        onResponseChange,
                                        onSubQuestionResponseChange,
                                        districtResponse,
                                        districtSubQuestionResponses
                                       }) => {
    return (
        <IndicatorProtocolForm
            onResponseChange={onResponseChange}
            onSubQuestionResponseChange={onSubQuestionResponseChange}
            protocol={protocol}
            response={response}
            responseText={responseText}
            subQuestionResponses={subQuestionResponses}
            showState={true}
            districtResponse={districtResponse}
            districtSubQuestionResponses={districtSubQuestionResponses}
            options={protocol.stateResponseOptions}
        />

    );
};

IndicatorProtocolStateForm.propTypes = {
    protocol: PropTypes.object.isRequired,
    response: PropTypes.string.isRequired,
    responseText: PropTypes.string.isRequired,
    subQuestionResponses: PropTypes.array.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    districtResponse: PropTypes.string.isRequired,
    districtSubQuestionResponses: PropTypes.array.isRequired,
};

export default IndicatorProtocolStateForm;