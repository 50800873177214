import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Allow} from "../../../components/authorization/Allow";
import {
    MonitoringCompliancePolicy,
    policyEvents
} from "../../../components/authorization/policies/MonitoringCompliancePolicy";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {getDistinctIds} from "../../../components/common/commonUtilities";
import {isValid} from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {MatrixSummaryGrid} from "./MatrixSummaryGrid";
import audit_outcomes from "../../../constants/auditOutcomes";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {
    matrixCorrections,
    matrixCorrectionsWithAdditionalInfoRequirement
} from "../../../constants/monitoringCompliance/correctionResults";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";

const MatrixCorrectionsSummaryView = ({
                                          actions,
                                          assessmentId,
                                          dateWindowId,
                                          history,
                                          isLoading,
                                          matrixProtocolCorrectiveResults
                                     }) => {
    const buildMatrixList = () => {
        const distinctRecordIds = getDistinctIds(matrixProtocolCorrectiveResults, "studentRecordId");

        let list = [];
        distinctRecordIds.map((recordId) => {
            const corrections = matrixProtocolCorrectiveResults.filter(f => f.studentRecordId === recordId);

            const recordsWithMissingData = corrections.reduce((agg, record) => {
                let output = record.matrix;
                if(!output)
                    output = "Missing data";

                if(!isValid(record, matrixCorrectionsWithAdditionalInfoRequirement, matrixCorrections))
                    agg.push(<li key={output}>{output}</li>);

                return agg;
            }, []);

            const recordsToBeSubmitted = corrections.reduce((agg, record) => {
                let output = record.matrix;
                if(!output)
                    output = "Missing data";

                if(isValid(record, matrixCorrectionsWithAdditionalInfoRequirement, matrixCorrections) && record.status !== audit_outcomes.ACCEPTED.value && record.status !== audit_outcomes.SUBMITTED.value)
                    agg.push(<li key={output}>{output}</li>);

                return agg;
            }, []);

            const allRecordsToBeSubmitted = corrections.reduce((agg, record) => {
                let output = record.matrix;
                if(!output)
                    output = "Missing data";

                if(isValid(record, matrixCorrectionsWithAdditionalInfoRequirement, matrixCorrections) && record.status !== audit_outcomes.ACCEPTED.value && record.status !== audit_outcomes.SUBMITTED.value)
                    agg += `<li key=${output}>${output}</li>`;

                return agg;
            }, "");

            const totalRecords = corrections.length;
            const totalSubmitted = corrections.filter(f => f.status === audit_outcomes.ACCEPTED.value || f.status === audit_outcomes.SUBMITTED.value).length;
            const totalToBeSubmitted = totalRecords - recordsWithMissingData.length - totalSubmitted;
            const totalAvailableToBeSubmitted = totalRecords - totalSubmitted;

            list.push({
                name: corrections[0].name,
                recordsWithMissingData: recordsWithMissingData.length === 0 ? <em>None</em> : <ul>{recordsWithMissingData}</ul>,
                totalToBeSubmitted,
                totalAvailableToBeSubmitted,
                totalSubmitted,
                recordsToBeSubmitted: recordsToBeSubmitted.length === 0 ? null : <ul>{recordsToBeSubmitted}</ul>,
                allRecordsToBeSubmitted,
                totalRecords
            })
        });

        return list;
    };

    const onSubmitMatrixCorrectiveResults = (event) => {
        event.preventDefault();

        if(confirm("Are you sure you want to submit matrix corrections that are completed and have no missing data?  All others will still be editable and can be submitted at a later date.")) {
            actions.submitMatrixCorrectiveResults(assessmentId)
                .then(() => {
                    actions.loadComplianceWithMatrixCorrectiveResults(assessmentId);
                });
        }
    };

    const onSummaryClick = (event, index) => {
        event.preventDefault();

        history.push(ComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS.path
            .replace(ComplianceLocations.ASSESSMENT_ID, assessmentId)
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX)
            .replace(ComplianceLocations.CURRENT_PAGE, index + 1));
    };

    useEffect(() => {
        actions.updatePageTitle("Matrix Corrections Summary");

        if (MonitoringComplianceUtilities.isValidId(assessmentId)) {
            actions.loadComplianceWithMatrixCorrectiveResults(assessmentId);
        }
    }, []);

    if(!matrixProtocolCorrectiveResults) return null;

    const summaryGridList = buildMatrixList();

    return (
        <section className={"compliance__studentCorrectionResultsSummary"}>
            {
                matrixProtocolCorrectiveResults.length === 0 &&
                <p>No matrix specific corrections are needed at this time.</p>
            }
            {
                matrixProtocolCorrectiveResults.length > 0 &&
                <>
                    <MatrixSummaryGrid
                        correctionResults={summaryGridList}
                        onClick={onSummaryClick}
                    />

                    {
                        summaryGridList.filter(f => f.totalToBeSubmitted > 0).length > 0 &&
                        <Allow policy={MonitoringCompliancePolicy}
                               policyEvent={policyEvents.monitoring_compliance.studentCorrections.SUBMIT}>
                            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                                <Button
                                    name={`btnSubmitCorrectiveReviews`}
                                    label={"Submit Completed Matrix Specific Corrections"}
                                    buttonType={ButtonTypes.SUBMIT}
                                    onClick={onSubmitMatrixCorrectiveResults}
                                    showLabel={true}
                                    disabled={isLoading}
                                />
                            </ButtonBar>
                        </Allow>
                    }
                </>
            }
        </section>
    );
};

MatrixCorrectionsSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    matrixProtocolCorrectiveResults: PropTypes.array
};

export default MatrixCorrectionsSummaryView;

