import React from "react";
import PropTypes from "prop-types";
import {INDICATOR} from "../../constants/sharedData/classHooks";
import TextField from "../common/inputs/TextField";
import Textarea from "../common/inputs/TextArea";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";

const IndicatorForm = ({
	                          indicator,
	                          isLoading,
	                          onChangeIndicator,
	                          onSaveIndicator,
	                          onCancelIndicator,
							  showTitleField
                          }) => {
	const totalColumns = showTitleField ? 3 : 2;
	return (
		<tr id={indicator.id} className={INDICATOR}>
			<td colSpan={totalColumns}>
				<GridRow>
					<GridColumn medium={"3"} columnClass={""}>
						<TextField name={"publicIdentifier"}
				           label={"Public Identifier"}
						   showLabel={true}
				           disabled={isLoading}
				           value={indicator.publicIdentifier ? indicator.publicIdentifier : ""}
				           onChange={onChangeIndicator}
						/>
					</GridColumn>
					{
						showTitleField &&
						<GridColumn medium={"8"} columnClass={"medium-offset-1"}>
							<TextField name={"title"}
									   label={"Title"}
									   showLabel={true}
									   disabled={isLoading}
									   value={indicator.title ? indicator.title : ""}
									   onChange={onChangeIndicator}
							/>
						</GridColumn>
					}
				</GridRow>
				<GridRow rowClass={"row__space"}>
					<GridColumn>
						<Textarea name={"text"}
								  label={"Indicator HTML"}
								  showLabel={true}
								  disabled={isLoading}
								  value={indicator.text ? indicator.text : ""}
								  onChange={onChangeIndicator}
						/>
					</GridColumn>
				</GridRow>
			</td>
			<td className="button-cell">
				<ButtonBar position={ButtonBarPositions.TABLE}>
					<Button name={"save|" + indicator.id}
					        label={"save"}
					        showLabel={false}
					        buttonType={ButtonTypes.SAVE}
					        onClick={onSaveIndicator}
					        disabled={isLoading}/>
					<Button name={"cancel|" + indicator.id}
					        label={"cancel"}
					        showLabel={false}
					        buttonType={ButtonTypes.CANCEL}
					        onClick={onCancelIndicator}
					        disabled={isLoading}/>
				</ButtonBar>
			</td>
		</tr>
	);
};

IndicatorForm.propTypes = {
	indicator: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onChangeIndicator: PropTypes.func.isRequired,
	onSaveIndicator: PropTypes.func.isRequired,
	onCancelIndicator: PropTypes.func.isRequired,
	showTitleField: PropTypes.bool.isRequired
};

export default IndicatorForm;