import {isNullOrUndefined} from "../../components/common/commonUtilities";
import {SBPIE_ASSESSMENT_STATUS} from "./bpieConstants";
import {createBpieIndicator, createBpieResponse} from "./Assessment/bpieAssessmentFactory";

export const createSchoolBpie = ({
                                     assessmentTeam,
                                     bpieTrainingQuestions,
                                     charterSchoolStatus,
                                     datesCovered,
                                     dateMeetingHeld,
                                     dateMeetingHeldOutput,
                                     districtId,
                                     hasCompletedBpieTraining,
                                     hasMetEssaTarget,
                                     hasPassedBpieTraining,
                                     indicators,
                                     msidNumber,
                                     previousBpieDatesCovered,
                                     primaryServiceType,
                                     responses,
                                     schoolAdminEmail,
                                     schoolAdminFirstName,
                                     schoolAdminLastName,
                                     schoolBpieId,
                                     schoolBpieTeam,
                                     schoolDistrict,
                                     schoolFunction,
                                     schoolName,
                                     schoolType,
                                     status
                                 } = {}) => {
    const bpieStatus = status || SBPIE_ASSESSMENT_STATUS.Pending.id;
    return {
        assessmentTeam: assessmentTeam || "",
        bpieTrainingQuestions,
        charterSchoolStatus: charterSchoolStatus || "",
        dateMeetingHeld: dateMeetingHeld || "",
        dateMeetingHeldOutput: dateMeetingHeldOutput || "",
        datesCovered: datesCovered || "",
        districtId: districtId || "",
        hasCompletedBpieTraining: isNullOrUndefined(hasCompletedBpieTraining) ? null : hasCompletedBpieTraining,
        hasMetEssaTarget: isNullOrUndefined(hasMetEssaTarget) ? "" : hasMetEssaTarget,
        hasPassedBpieTraining: isNullOrUndefined(hasPassedBpieTraining) ? null : hasPassedBpieTraining,
        hasStarted: bpieStatus >= SBPIE_ASSESSMENT_STATUS.Started.id,
        indicators: isNullOrUndefined(indicators) ? [] : indicators.map(i => createBpieIndicator(i)),
        isActive: bpieStatus === SBPIE_ASSESSMENT_STATUS.Started.id || bpieStatus === SBPIE_ASSESSMENT_STATUS.InProgress.id,
        isArchived: bpieStatus === SBPIE_ASSESSMENT_STATUS.Archived.id,
        isConcluded: bpieStatus === SBPIE_ASSESSMENT_STATUS.Archived.id || bpieStatus === SBPIE_ASSESSMENT_STATUS.Incomplete.id,
        isIncomplete: bpieStatus === SBPIE_ASSESSMENT_STATUS.Incomplete.id,
        msidNumber: msidNumber || "0",
        previousBpieDatesCovered: previousBpieDatesCovered || "",
        primaryServiceType: primaryServiceType || "",
        responses: isNullOrUndefined(responses) ? [] : responses.map(r => createBpieResponse(r)),
        schoolAdminEmail: schoolAdminEmail || "",
        schoolAdminFirstName: schoolAdminFirstName || "",
        schoolAdminLastName: schoolAdminLastName || "",
        schoolBpieId: schoolBpieId || "",
        schoolBpieTeam: isNullOrUndefined(schoolBpieTeam) ? [] : schoolBpieTeam.map(team => createBpieTeamMember(team)),
        schoolDistrict: schoolDistrict || "0",
        schoolFunction: schoolFunction || "",
        schoolName: schoolName || "",
        schoolType: schoolType || "",
        status: bpieStatus
    }
};

export const createBpieTeamMember = ({
                                         email,
                                         firstName,
                                         lastName,
                                         role,
                                         schoolBpieId,
                                         teamMemberId,
                                         title,
                                         titleOther,
                                         userId
                                     } = {}) => (
    {
        email: email || "",
        firstName: firstName || "",
        lastName: lastName || "",
        role: role || "",
        schoolBpieId: schoolBpieId || "",
        teamMemberId: teamMemberId || "",
        title: title || "",
        titleOther: titleOther || "",
        userId: userId || ""
    }
);

export const createFinFacilitator = (
    email,
    firstName,
    lastName,
    userId
) => (
    {
        email: email || "",
        firstName: firstName || "",
        lastName: lastName || "",
        userId: userId || ""
    }
);

export const createSubmissionModuleOptions = (
    hidePrimarySubmissionButton,
    isSubmissionModuleDisabled,
    primaryButtonLabel,
    submissionMessage,
    submissionTitle,
    secondaryButtonLabel = "",
    secondaryDisabled = false,
    hideSecondarySubmissionButton = true
) => (
    {
        hidePrimarySubmissionButton,
        hideSecondarySubmissionButton,
        isSubmissionModuleDisabled,
        primaryButtonLabel,
        secondaryButtonLabel,
        secondaryDisabled,
        submissionMessage,
        submissionTitle
    }
);

export const createExportSummaryFilters = ({schoolNumber, statusSingle}) => ({
    schoolNumber,
    status: statusSingle
});