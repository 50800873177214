import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as fileActions from "../../actions/fileActions";
import * as sppActions from "../../actions/sppActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import DocumentPrint from "../../components/spp/DocumentPrint";
import * as sppUtilities from "../../components/spp/SppUtilities";
import {setPageTitle} from "../../components/common/commonUtilities";
import {getParams} from "../../components/layout/getParams";
import * as publicLocations from "../../constants/publicLocations";

export class SppDocumentPrint extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isTitleSet: false,
            params: {}
		};

		this.fileOnView = this.fileOnView.bind(this);
	}

	componentDidMount() {
		const currentLocation = this.props.location.pathname;
        const params = getParams(currentLocation, publicLocations.SPP_DOCUMENT_PRINT)
			|| getParams(currentLocation, publicLocations.SPP_DOCUMENT_SECTION_PRINT);

        if(params !== null)
            this.setState({params});

		if (this.props.allDateWindows.length === 0)
			this.props.actions.loadAllDateWindows();

		if (this.props.institutions.length === 0)
			this.props.actions.loadInstitutions();

		this.props.actions.loadSppDocument(params.documentId);

		this.props.actions.loadSppDocumentElements(params.documentId);

		this.props.actions.loadInstitutionResponses(params.institutionId, params.documentId);
	}

	 componentDidUpdate(){
         if (!this.state.isTitleSet &&
			 this.props.allDateWindows.length > 0 &&
             this.props.selectedDocument.id !== undefined &&
             this.props.institutions.length > 0) {
             const title = sppUtilities.generatePageTitle(this.props.allDateWindows, undefined, this.props.selectedDocument, this.props.institutions, this.state.params.institutionId);

             setPageTitle(title);

             this.setState({isTitleSet: true});
         }
	 }

	fileOnView(event) {
		event.preventDefault();
		const responses = [...this.props.responses];
		const response = responses.filter(re => re.elementId === event.target.id)[0];

		const indexOfResponse = responses.indexOf(response);
		const fileId = responses[indexOfResponse].data;
		this.props.actions.downloadFile(fileId);
	}

	render() {
		if (this.props.elements.length === 0 ||
			this.props.selectedDocument.id === undefined ||
			this.props.responses.length === 0 ||
			this.props.institutions.length === 0)
			return null;

		let hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);

		if(this.state.params.sectionId)
			hierarchyJson = hierarchyJson.filter(h => h.elementId === this.state.params.sectionId);

		const inputElements = sppUtilities.getInputElements(this.props.elements);

		const responses = sppUtilities.getElementResponses(inputElements, this.props.responses);

		const currentInstitution = this.props.institutions.filter(ins => ins.id === this.state.params.institutionId)[0];

		const dateWindow = sppUtilities.generateSppThreeYearDateWindowFormatPrint(this.props.allDateWindows, this.props.selectedDocument.dateWindowId);

		return (
			<section>
				<header className={`spp-cover-page pageBreak--after`}>
					<div className="l-flex-spacer" />

					<h1>FLORIDA DEPARTMENT OF EDUCATION</h1>
					<h1>DIVISION OF K-12 PUBLIC SCHOOLS</h1>
					<h1>BUREAU OF EXCEPTIONAL EDUCATION AND STUDENT SERVICES</h1>
					<h1>School District</h1>
					<h1>{currentInstitution.name}</h1>

					<div className="l-flex-spacer" />

					<h2>EXCEPTIONAL STUDENT EDUCATION<br/>
						POLICIES AND PROCEDURES (P&amp;P)</h2>
					<h2>EFFECTIVE DATE:</h2>
					<h2>{dateWindow}</h2>

					<div className="l-flex-spacer" />
				</header>

				{
					hierarchyJson.map((part, index) => {
						return (<DocumentPrint
							key={"part" + index}
							partId={part.elementId}
							documentHierarchy={hierarchyJson}
							elements={this.props.elements}
							responses={responses}
							fileOnView={this.fileOnView}
						/>);
					})
				}
			</section>
		);
	}
}

SppDocumentPrint.propTypes = {
	actions: PropTypes.object.isRequired,
	allDateWindows: PropTypes.array.isRequired,
	responses: PropTypes.array.isRequired,
	institutions: PropTypes.array.isRequired,
	elements: PropTypes.array.isRequired,
	selectedDocument: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		institutions: state.sharedData.institutions,
		allDateWindows: state.sharedData.dateWindows,
		selectedDocument: state.spp.selectedDocument,
		elements: state.spp.documentElements,
		responses: state.spp.documentResponses
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		sppActions,
		fileActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppDocumentPrint);