import PropTypes from 'prop-types';
import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as AuthorizationActions from "../../actions/authorizationActions";
import * as menuActions from "../../actions/menuActions";
import * as SharedDataActions from "../../actions/sharedDataActions";
import * as BpieActions from "../../actions/bpieActions";
import {verifyAccessToContext} from "../../components/authorization/AuthorizationUtilities";
import {arraysEqual, convertContextForThemeStyling} from "../../components/common/commonUtilities";
import FlexSpacer from "../../components/common/FlexSpacer";
import Footer from "../../components/layout/Footer";
import Main from "../../components/layout/Main";
import SiteHeader from "../../components/layout/SiteHeader";
import Storage from "../../components/shared/LocalStorage";
import {
	filterCurrentAnnualDateWindows,
	generateDateWindowSelectListItems,
	generateInstitutionsSelectListItems
} from "../../components/shared/sharedDataUtilities";
import {BPIE} from "../../constants/contexts";
import NotAuthorized from '../account/NotAuthorized';
import SystemMessage from "./SystemMessage";

export class AuthenticatedLayout extends React.PureComponent {
	constructor(props) {
		super(props);

		this.closeMainMenu = this.closeMainMenu.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadAllDateWindows();
		this.props.actions.loadInstitutions();
		if(verifyAccessToContext([BPIE], Storage.getBehaviorClaims()))
			this.props.actions.loadFinFacilitatorDistricts();
	}

	componentDidUpdate() {
		if (!arraysEqual(this.props.currentContext, this.props.context))
			this.props.actions.updateCurrentContext(this.props.context);
	}

	closeMainMenu(event) {
		const whichArea = (event) ? event.target : null;
		const ifMenuCollapsible = whichArea && whichArea.className && whichArea.className.indexOf && whichArea.className.indexOf("Menu_Collapsible__trigger") >= 0;

		if (this.props.isMenuActive && !ifMenuCollapsible) {
			this.props.actions.toggleMenu(false);
			this.props.actions.toggleAdminMenuOpen(false);
		}
	}

	render() {
		const userIsAuthorized = !this.props.authorizationRequired || verifyAccessToContext(this.props.context, Storage.getBehaviorClaims());

		const {currentContext, exact, history, layout, path} = this.props;

		return (
			<>
				<SystemMessage/>
				<Route {...exact} {...path} render={componentProps => (
					<div
						className={`l-flex-fullHeight theme-${convertContextForThemeStyling(currentContext)}`}
						onClick={this.closeMainMenu}>
						<SiteHeader history={history}/>

						{
							userIsAuthorized &&
							<Main
								{...componentProps}
								{...this.props}
							/>
						}

						{
							!userIsAuthorized &&
							<Main component={NotAuthorized} layout={layout}/>
						}

						<FlexSpacer/>

						<Footer currentContext={currentContext}/>
					</div>
				)}/>
			</>
		);
	}
}

AuthenticatedLayout.propTypes = {
	actions: PropTypes.object.isRequired,
	currentContext: PropTypes.array,
	dateWindows: PropTypes.array,
	dateWindowsAnnualList: PropTypes.array,
	history: PropTypes.object.isRequired,
	institutions: PropTypes.array,
	isLoading: PropTypes.bool,
	isMenuActive: PropTypes.bool.isRequired,
	layout: PropTypes.object.isRequired,
	component: PropTypes.func.isRequired,
	exact: PropTypes.bool,
	path: PropTypes.string.isRequired,
	context: PropTypes.array,
	authorizationRequired: PropTypes.bool,
	wrapperPage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function mapStateToProps(state) {
	const dateWindows = [...state.sharedData.dateWindows];
	let dateWindowsAnnualList = [];

	if (dateWindows.length > 0) {
		const annualWindows = filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindowsAnnualList = generateDateWindowSelectListItems(annualWindows);
	}

	const institutions = [...state.sharedData.institutions];
	let institutionsList = [];
	if (institutions.length > 0) {
		institutionsList = generateInstitutionsSelectListItems(institutions);
	}

	const allInstitutions = [...state.sharedData.allInstitutions];
	let allInstitutionsList = [];
	if (allInstitutions.length > 0) {
		allInstitutionsList = generateInstitutionsSelectListItems(allInstitutions);
	}

	return {
		currentContext: state.currentContext,
		dateWindowId: state.sharedData.selectedDateWindowId,
		dateWindows,
		dateWindowsAnnualList,
		institutions,
		institutionsList,
		allInstitutions: allInstitutions,
		allInstitutionsList: allInstitutionsList,
		isDashboardLoading: state.dashboardAjaxCallsInProgress > 0,
		isLoading: state.ajaxCallsInProgress > 0,
		isMenuActive: state.menu.isActive,
		layout: state.layout
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, menuActions, AuthorizationActions, SharedDataActions, BpieActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedLayout);
