import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import SectionHeader from "../../../../../components/common/SectionHeader";
import {emptyGuid} from "../../../../../constants/config";
import * as bpieLocations from "../../../bpieLocations";
import {createDbpiePriorityIndicatorsArray, createDpieGoal} from "../../bpieDistrictObjectFactory";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../../../bpieConstants";
import {getDistrictPiePageSubTitle, isPageDependenciesLoaded} from "../../bpieDistrictPieUtilities";
import bpieDistrictPieGoalsApi from "../bpieDistrictPieGoalsApi";
import {BpieDistrictPieGoalDetailsView} from "./BpieDistrictPieGoalDetailsView";

export const BpieDistrictPieGoalDetailsContainer = ({
                                                        actions,
                                                        dataChanged,
                                                        dateWindowId,
                                                        districtBpie,
                                                        districtId,
                                                        districtPie,
                                                        districtPieId,
                                                        dpieGoalId,
                                                        handleDataChanged,
                                                        handleDistrictBpieSearchRedirect,
                                                        handleDistrictPieSectionsRedirect,
                                                        isDistrictUser,
                                                        loadDistrictPie,
                                                        normalizeUrlAndRedirect,
                                                        updatePageTitleDetails
                                                    }) => {
    const isAdd = dpieGoalId === emptyGuid;
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
    const [editingDpieGoalId, setEditingDpieGoalId] = useState(isAdd && isEditable ? emptyGuid : null);
    const [editingActionStepId, setEditingActionStepId] = useState(null);
    const isEditingGoal = !isNullOrUndefined(editingDpieGoalId);
    const isEditingActionStep = !isNullOrUndefined(editingActionStepId);
    const addEditPrefixForSubTitle = isEditingGoal ? isAdd ? "Add " : "Edit " : "";
    const pageSubTitle = `${addEditPrefixForSubTitle}District Goal Details`;

    const handleSaveDistrictPieGoalDetails = (dpieGoalDetails) => {
        actions.executeApi(bpieDistrictPieGoalsApi.saveDistrictPieGoal, [dateWindowId, districtId, districtPieId, dpieGoalId, dpieGoalDetails])
            .then((goalId) => {
                loadDistrictPie();
                handleDataChanged(false);

                if (!isAdd) {
                    setEditingDpieGoalId(null);
                    return;
                }

                const addEditGoalUrl = isDistrictUser
                    ? bpieLocations.DISTRICT_DPIE_GOAL_DETAILS.getUrl(dateWindowId, districtId, districtBpie.districtBpieId, districtPieId, goalId)
                    : bpieLocations.STATE_DPIE_GOAL_DETAILS.getUrl(dateWindowId, districtId, districtBpie.districtBpieId, districtPieId, goalId);

                normalizeUrlAndRedirect(addEditGoalUrl);
            });
    };

    const handleSaveActionStep = (actionStepId, actionStepDetails) => {
        actions.executeApi(bpieDistrictPieGoalsApi.saveDistrictPieActionStep, [dateWindowId, districtId, districtPieId, dpieGoalId, actionStepId, actionStepDetails])
            .then(() => {
                loadDistrictPie();
                handleDataChanged(false);
                setEditingActionStepId(null);
            });
    };

    const handleRemoveActionStep = (actionStepId) => {
        if (!confirm("Are you sure you want to delete this DPIE action step?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            return;

        actions.executeApi(bpieDistrictPieGoalsApi.removeActionStep, [dateWindowId, districtId, districtPieId, dpieGoalId, actionStepId])
            .then(() => {
                loadDistrictPie();
            });
    };

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
            return;
        }

        loadDistrictPie();

    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtPie)) return;

        updatePageTitleDetails(getDistrictPiePageSubTitle(pageSubTitle, districtPie));

        const goalStatus = districtPie.districtPieTaskStatuses.find(taskStatus => taskStatus.dpieTask === DistrictPieTask.Goals.id);
        if (goalStatus?.isDisabled ?? true) {
            handleDistrictBpieSearchRedirect();
        }

        const dpieGoal = districtPie.dpieGoals.find(goal => goal.dpieGoalId === dpieGoalId);
        if (!isNullOrUndefined(dpieGoal) && dpieGoal.actionSteps.length === 0 && isEditable)
            setEditingActionStepId(emptyGuid);
    }, [districtPie]);

    if(!isPageDependenciesLoaded([districtBpie], districtPie, districtPieId)) return null;

    const dbpiePriorityIndicators = createDbpiePriorityIndicatorsArray(districtBpie);
    const dpieGoal = districtPie.dpieGoals.find(goal => goal.dpieGoalId === dpieGoalId) ?? createDpieGoal();
    const fullPageSubTitle = getDistrictPiePageSubTitle(pageSubTitle, districtPie);
    return <>
        <SectionHeader>
            <h2>{fullPageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieGoalDetailsView
            dataChanged={dataChanged}
            dbpiePriorityIndicators={dbpiePriorityIndicators}
            districtPieId={districtPieId}
            districtPieStartYear={districtPie.districtPieStartYear}
            dpieGoal={dpieGoal}
            editingActionStepId={editingActionStepId}
            editingDpieGoalId={editingDpieGoalId}
            handleDataChanged={handleDataChanged}
            handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
            handleRemoveActionStep={handleRemoveActionStep}
            handleSaveActionStep={handleSaveActionStep}
            handleSaveDistrictPieGoalDetails={handleSaveDistrictPieGoalDetails}
            isAdd={isAdd}
            isEditable={isEditable}
            isEditingActionStep={isEditingActionStep}
            isEditingGoal={isEditingGoal}
            sbpiePriorityIndicators={districtPie.topSbpieIndicators}
            sbpiePriorityYearRange={`${districtPie.sbpieTopIndicatorsStartYear} - ${districtPie.sbpieTopIndicatorsEndYear}`}
            setEditingDpieGoalId={setEditingDpieGoalId}
            setEditingActionStepId={setEditingActionStepId}
        />
    </>;
}

BpieDistrictPieGoalDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    dpieGoalId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    normalizeUrlAndRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};