import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../constants/AuditConstants";
import audit_outcomes from "../../../constants/auditOutcomes";
import {readyToSubmit} from "../../../constants/monitoringCompliance/correctionResults";
import {createNewCapAuditComment} from "./capObjectFactory";

export function getAssociatedProtocolPlanIds(activityId, protocolCorrectiveActions) {
    const protocols = protocolCorrectiveActions.filter(f => f.associatedCapActivityIds.indexOf(activityId) >= 0);
    if(!protocols || protocols.length === 0) return [];

    return protocols.map(protocol => protocol.correctiveActionPlanId);
}

export function getRelatedActionsActivities(indicatorId, protocolCorrectiveActions, capActivities) {
    const relatedCorrectiveActions = protocolCorrectiveActions.filter(f => f.indicatorId === indicatorId);
    const relatedCapActivities = capActivities.filter(activity => relatedCorrectiveActions.some(f => f.associatedCapActivityIds.indexOf(activity.activityId) >= 0));

    return {
        relatedCorrectiveActions,
        relatedCapActivities
    };
}

export function isStatusEditable(status) {
    return status === audit_outcomes.IN_PROGRESS.value || status === audit_outcomes.NEEDS_REVISION.value || status === readyToSubmit;
}

export function enableCapActivityResults(capActivityStatus) {
    return capActivityStatus === audit_outcomes.ACCEPTED.value;
}

export function getCapResultsStatusWhenActivityAccepted(capActivityStatus, capActivityResultsStatus, capActivityResultIsReadyToSubmit = false, acknowledgeResultsNeedsRevisionComplete = false) {
    if(!enableCapActivityResults(capActivityStatus))
        return "";

    const status = "Activity Results - ";

    if(capActivityResultsStatus === audit_outcomes.NEEDS_REVISION.value && acknowledgeResultsNeedsRevisionComplete)
        return `${status} Ready to Submit Revisions`;

    return capActivityResultIsReadyToSubmit && isStatusEditable(capActivityResultsStatus)
        ? `${status} Ready to Submit`
        : `${status}${capActivityResultsStatus}`;
}

function findComment(commentId, audits) {
    let updatedComment = null;
    for (let audit of audits) {
        updatedComment = audit.comments.find(f => f.auditCommentId === commentId);
        if (updatedComment)
            break;
    }

    return updatedComment;
}

function findAudit(audits, auditId) {
    return audits.find(f => f.auditId === auditId);
}

export function onCommentCancel(event, originalAudits, formInput, editCommentId, setEditCommentId) {
    event.preventDefault();
    let audits = [...formInput.audits.value];
    let editableComment = findComment(editCommentId, audits);
    let originalComment = findComment(editCommentId, originalAudits);

    if(!editableComment) {
        setEditCommentId(null);
        return;
    }

    if(!originalComment) {
        let audit = findAudit(audits, editableComment.auditId);
        audit.comments = audit.comments.filter(f => f.auditCommentId !== editableComment.auditCommentId);
    }
    else {
        editableComment.text = originalComment.text;
        editableComment.isVisibleToDistrict = originalComment.isVisibleToDistrict;
    }

    formInput.audits.setValue(audits);

    setEditCommentId(null);
}

export function onCommentSave(event, comment, onActivityCommentSaved, setEditCommentId) {
    event.preventDefault();

    onActivityCommentSaved(comment);
    setEditCommentId(null);
}

export function onCommentEdit(commentId, setEditCommentId) {
    setEditCommentId(commentId);
}

export function onCommentCreate(auditId, formInput, setEditCommentId) {
    const newAuditComment = createNewCapAuditComment(auditId);

    let audits = [...formInput.audits.value];
    let audit = findAudit(audits, auditId);
    if(!audit) return;

    audit.comments.unshift(newAuditComment);

    formInput.audits.setValue(audits);

    setEditCommentId(newAuditComment.auditCommentId);
}

export function handleCommentChange(event, formInput, commentId) {
    event.preventDefault();

    let audits = [...formInput.audits.value];
    let commentUpdated = findComment(commentId, audits);

    if(!commentUpdated) return;

    const fieldUpdated = event.target.name;
    const updatedValue = event.target.value;

    if (fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
        commentUpdated.isVisibleToDistrict = updatedValue;
    else
        commentUpdated.text = updatedValue;

    formInput.audits.setValue(audits);
}