import PropTypes from "prop-types";
import React, {useState} from "react";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import {
    createListItem,
    dynamicSort,
    isNullOrUndefined,
    isTrimmedStringEmpty,
} from "../../components/common/commonUtilities";
import GridTable from "../../components/common/GridTable";
import {NotifyUser} from "../../components/common/NotifyUser";
import {EDIT} from "../../constants/behaviors";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {SiteSectionsForTeamMemberList} from "../../constants/config";
import StateUserDistrictAssignmentDistrict from "./StateUserDistrictAssignmentDistrict";
import * as ApiSuccessMessages from "../../constants/api/ApiSuccessMessages";

const StateUserDistrictAssignmentView = ({
                                             cancelAndReload,
                                             districts,
                                             saveTeamMembers,
                                             stateUsers,
                                         }) => {
    const [getTeamMembers, setGetTeamMembers] = useState([]);
    let initialGetDistrictsFunctionsBuild = [];

    const buildUniqueCombinedList = (assignedUserUserId, assignedUserFirstName, assignedUserLastName, sectionContext) => {

        const users = stateUsers.filter(user => user.claimValues.some(o => o === `${sectionContext}.${EDIT}`));

        if (isTrimmedStringEmpty(assignedUserUserId) || isNullOrUndefined(assignedUserFirstName) || isNullOrUndefined(assignedUserLastName))
            return users;

        const userExists = users.find(f => f.value === assignedUserUserId);
        if (userExists)
            return users;

        let fullName = `${assignedUserFirstName} ${assignedUserLastName} (Inactive)`;
        const inactiveUser = [{
            ...createListItem(assignedUserUserId, fullName),
            lastName: assignedUserLastName,
            firstName: assignedUserFirstName,
        }];
        const allUsers = [...inactiveUser, ...users];

        return allUsers.sort(dynamicSort("lastName", false, "firstName"));
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        let updatedTeamMembers = [];

        getTeamMembers.map((getDistrictTeamMember) => {
            const districtTeamMembers = getDistrictTeamMember();
            if (districtTeamMembers.length > 0)
                updatedTeamMembers = [...updatedTeamMembers, ...districtTeamMembers];
        });

        if (updatedTeamMembers.length > 0)
            saveTeamMembers(updatedTeamMembers);
        else
            NotifyUser.Success(ApiSuccessMessages.INSTITUTIONTEAMMEMBER_SAVED);
    };

    const getUpdatedTeamMembers = (getDistrictTeamMembersFunc) => {
        initialGetDistrictsFunctionsBuild.push(getDistrictTeamMembersFunc);

        if (initialGetDistrictsFunctionsBuild.length === districts.length)
            setGetTeamMembers(initialGetDistrictsFunctionsBuild);
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        cancelAndReload();
    };

    return (
        <>
            <p>State users must have at least Edit access in any section to show up in the select list. <span
                className={`color--alert`}>Note: when selecting a team member for a district, the system also
                automatically selects that user for the other areas of the system if that team member has access and
                someone else is not already selected.</span>
            </p>
            <GridTable>
                <thead>
                <tr>
                    <th>District / Institution</th>
                    {
                        SiteSectionsForTeamMemberList.map((section, index) => (
                            <th key={index}>
                                {section.text}
                            </th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    districts.map((district, index) => (
                        <StateUserDistrictAssignmentDistrict
                            buildUniqueCombinedList={buildUniqueCombinedList}
                            district={district}
                            getUpdateTeamMembers={getUpdatedTeamMembers}
                            key={index}
                            stateUsers={stateUsers}
                        />
                    ))
                }
                </tbody>
            </GridTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>

                <Button name="btnCancel"
                        label="Cancel and Reload"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={onCancelClick}/>
            </ButtonBar>
        </>
    );
};

StateUserDistrictAssignmentView.propTypes = {
    cancelAndReload: PropTypes.func.isRequired,
    districts: PropTypes.array.isRequired,
    saveTeamMembers: PropTypes.func.isRequired,
    stateUsers: PropTypes.array.isRequired,
};

export default StateUserDistrictAssignmentView;