import PropTypes from "prop-types";
import React, {useState} from "react";
import SelectField from "../../../../components/common/inputs/SelectField";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import GridRow from "../../../../components/common/GridRow";
import GridColumn from "../../../../components/common/GridColumn";
import {gridConstants} from "../../../../constants/inputConstants";
import ptsAdminDiscretionaryProjectsApi from "../ptsAdminDiscretionaryProjectsApi";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";

const PtsImportProjectsContainer = ({
                                        actions,
                                        dateWindows,
                                    }) => {
    const [dateWindowId, setDateWindowId] = useState("");

    const setErrorValues = (importResult) => {
        console.log(importResult);
    };

    const handleFileUpload = async (event) => {
        event.preventDefault();

        await actions.executeApi(ptsAdminDiscretionaryProjectsApi.ImportProjects, [dateWindowId, event.target.files])
            .then((importResult) => {
                setErrorValues(importResult);
            });

        const ptsProjectsImport = document.getElementById("ptsProjectsImport");
        ptsProjectsImport.value = "";
    };

    const handleChangeForm = (event) => {
        event.preventDefault();
        setDateWindowId(event.target.value);
    };

    const handleDownloadSampleFile = async (event) => {
        event.preventDefault();
        await actions.executeApi(ptsAdminDiscretionaryProjectsApi.DownloadSampleProjectImportFile);
    };

    return <div className={`project-information`}>
        <GridRow>
            <GridColumn medium={gridConstants.column.FOUR}>
                <SelectField
                    includeDefaultOption={true}
                    defaultOption={"Select Date Window"}
                    label={`Date Window`}
                    name={`dateWindowId`}
                    onChange={handleChangeForm}
                    options={dateWindows}
                    showLabel
                    value={dateWindowId}
                />
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn>
                <ButtonBar position={ButtonBarPositions.TABLE}>
                    <FileUploadButton name={"ptsProjectsImport"} label={'Import Projects file'}
                                      onChange={handleFileUpload} disabled={dateWindowId === ""}/>

                    <Button name={"btnSampleFile"}
                            label={"Sample File"}
                            buttonType={ButtonTypes.DOWNLOAD}
                            onClick={handleDownloadSampleFile}/>
                </ButtonBar>
            </GridColumn>
        </GridRow>
    </div>;
};

PtsImportProjectsContainer.propTypes = {
    actions: PropTypes.object,
    dateWindows: PropTypes.array.isRequired,
};

export default PtsImportProjectsContainer;