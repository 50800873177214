export const USER_ID = ":userId";
export const ROOT = "/Users";

//MANAGE
export const SEARCH = {
    path: `${ROOT}/Search`
};

export const PROFILE = {
    path: `${ROOT}/Profile/${USER_ID}`,
    params: {
        userId: USER_ID
    }
};

export const NEW_USER = {
    path: `${ROOT}/New`,
    params: {}
};

export const STATE_USER_DISTRICT_ASSIGNMENTS = {
    path: `${ROOT}/Admin/TeamDistrictAssignments`,
    params: {}
};
