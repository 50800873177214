import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import {validateDecimal} from "../../../../components/common/commonUtilities";
import {ReadMore} from "../../../../components/common/ReadMore";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {displayFundingSource} from "./gmsApplicationDeliverablesUtilities";

const GmsApplicationDeliverablesTableRow = ({
                                                canUserEditDeliverables,
                                                deliverableItem,
                                                handleDeleteClick,
                                                handleEditClick,
                                                isEditing,
                                            }) => {
    if (!deliverableItem) return null;

    const deliverableBudget = validateDecimal(deliverableItem.deliverableBudget);
    const fundingSourceType = displayFundingSource(deliverableItem.fundingSourceType);

    return (
        <tr>
            <td className={`text-center`}>{deliverableItem.deliverableIdentifier}</td>
            <td className={`text-center`}>{deliverableItem.deliverableType}</td>
            <td>
                <div className={`bold`}>{deliverableItem.deliverableTitle}</div>
                <ReadMore hideBorderWhenNotUseTruncate text={deliverableItem.deliverableDescription} />
            </td>
            <td>
                {
                    deliverableItem.gmsIndicators.map((indicator, key) => {
                        return <div key={`${deliverableItem.deliverableIdentifier}${key}`}>{indicator}</div>;
                    })
                }
            </td>
            <td>{deliverableItem.sourceDocumentation}</td>
            <td className={`text-center`}>{fundingSourceType}</td>
            <td className={`text-center`}>${deliverableBudget}</td>
            <td className={`text-center`}>{deliverableItem.totalUnits}</td>
            <td className={`text-center`}>${deliverableItem.unitCost}</td>
            <td>
                <div>Q1: {deliverableItem.q1Units}</div>
                <div>Q2: {deliverableItem.q2Units}</div>
                <div>Q3: {deliverableItem.q3Units}</div>
                <div>Q4: {deliverableItem.q4Units}</div>
            </td>
            {
                canUserEditDeliverables &&
                <td className={`text-center`}>
                    <Button buttonType={ButtonTypes.EDIT}
                            btnClass={`button--vertical-margin`}
                            disabled={isEditing}
                            label={`Edit ${deliverableItem.deliverableIdentifier}`}
                            name={`btnEdit${deliverableItem.id}`}
                            onClick={handleEditClick}
                            showLabel={false}
                    />
                    <>
                        <br/>
                        <Button buttonType={ButtonTypes.DELETE}
                                disabled={isEditing}
                                label={`Delete ${deliverableItem.deliverableIdentifier}`}
                                name={`btnDelete${deliverableItem.id}`}
                                onClick={handleDeleteClick}
                                showLabel={false}
                        />
                    </>
                </td>
            }
        </tr>
    );
};


GmsApplicationDeliverablesTableRow.propTypes = {
    canUserEditDeliverables: PropTypes.bool,
    deliverableItem: PropTypes.object,
    handleDeleteClick: PropTypes.func,
    handleEditClick: PropTypes.func,
    isEditing: PropTypes.bool,
};

export default GmsApplicationDeliverablesTableRow;