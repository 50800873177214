import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function monitoringComplianceReducer(state = initialState.monitoringCompliance, action) {
    switch (action.type) {
        case actionTypes.CREATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedAssessment: {...action.data.complianceAssessment, monitoringAssessmentId: action.data.newId},
                    isEditing: false,
                    isNew: false,
                    editItemOriginalCopy: {}
                });
        case actionTypes.LOAD_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS:
        case actionTypes.LOAD_DISTRICT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS:
        case actionTypes.UPDATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedAssessment: action.data,
                    isEditing: false,
                    isNew: false,
                    editItemOriginalCopy: {}
                });
        case actionTypes.CLEAR_COMPLIANCE_MONITORING_SELECTED_ASSESSMENT:
            return Object.assign(
                {},
                state,
                {
                    selectedAssessment: null
                });
        case actionTypes.EDIT_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION:
            return Object.assign(
                {},
                state,
                {
                   isEditing: true,
                    isNew: false,
                    editItemOriginalCopy: {}
                });
        case actionTypes.ADD_COMPLIANCE_PROTOCOL_REQUIREMENT:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    isNew: true,
                    editItemOriginalCopy: action.data.requirement,
                    selectedAssessment: Object.assign(
                        {},
                        state.selectedAssessment,
                        {
                            indicatorRequirements: state.selectedAssessment.indicatorRequirements ?
                                        [...state.selectedAssessment.indicatorRequirements, action.data.requirement] :
                                        [action.data.requirement]
                        })
                });
        case actionTypes.EDIT_COMPLIANCE_PROTOCOL_REQUIREMENT:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    isNew: false,
                    editItemOriginalCopy: action.data.requirement
                });
        case actionTypes.CHANGE_COMPLIANCE_PROTOCOL_REQUIREMENT:
            return Object.assign(
                {},
                state,
                {
                    selectedAssessment: Object.assign(
                        {},
                        state.selectedAssessment,
                        {
                            indicatorRequirements: state.selectedAssessment.indicatorRequirements.map(req =>{
                                if (req.indicatorRequirementId === action.data.indicatorRequirementId)
                                    return Object.assign(
                                        {},
                                        action.data
                                    );

                                return req;
                            })
                        })
                });
        case actionTypes.CANCEL_COMPLIANCE_PROTOCOL_REQUIREMENT_EDIT:
        case actionTypes.CANCEL_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION:
        {
            let removeNewFromIndicatorRequirements = state.isNew ? state.selectedAssessment.indicatorRequirements.reduce((agg, req) => {
                if (req.indicatorRequirementId !== state.editItemOriginalCopy.indicatorRequirementId)
                    agg.push(req);

                return agg;
            }, []) :  state.selectedAssessment.indicatorRequirements;

            return Object.assign(
                {},
                state,
                {
                    selectedAssessment: Object.assign(
                        {},
                        state.selectedAssessment,
                        {
                            indicatorRequirements: removeNewFromIndicatorRequirements
                        }
                    ),
                    editItemOriginalCopy: {},
                    isEditing: false,
                    isNew: false
                });
        }
        case actionTypes.SAVE_DISTRICT_ASSIGNED_PROTOCOL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editItemOriginalCopy: {},
                    isEditing: false,
                    isNew: false,
                    selectedAssessment: Object.assign(
                        {},
                        state.selectedAssessment,
                        {
                            indicatorRequirements: action.data
                        }
                    )
                });
        case actionTypes.CLEAR_MONITORING_COMPLIANCE_DATA:
        case actionTypes.REMOVE_DISTRICT_STUDENT_RECORD_VALIDATION_AJAX_SUCCESS:
        case actionTypes.DELETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editItemOriginalCopy: {},
                    isEditing: false,
                    isNew: false,
                    selectedAssessment: {},
                    indicators: [],
                    indicatorProtocols: []
                });
        case actionTypes.DELETE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editItemOriginalCopy: {},
                    isEditing: false,
                    isNew: false,
                    indicators: [],
                    indicatorProtocols: []
                });
        case actionTypes.LOAD_INDICATOR_PROTOCOLS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    indicatorProtocols: action.data
                });
        case actionTypes.SET_COMPLIANCE_EDIT_MODE:
            return Object.assign(
                {},
                state,
                {
                    isEditing: action.data
                });
        case actionTypes.LOAD_DISTRICT_COMPLIANCE_STATUSES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    districtStatuses: action.data
                }
            );
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
        case actionTypes.LOGOUT_AJAX_SUCCESS:
            return initialState.monitoringCompliance;
        default:
            return state;
    }
}