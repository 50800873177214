import {DATE_WINDOW_ID, DISTRICT_ID} from "./reportLocations";

export const createReportInfoObject = (
    themeCss,
    districtTileContainerTitle,
    stateTileContainerTitle,
    context) =>
    ({
        themeCss,
        districtTileContainerTitle,
        stateTileContainerTitle,
        context
    });


export const createReportTileObject = (name, path, allowBpieStateUser = false) => ({
    allowBpieStateUser,
    api: { path },
    name
});

export const createDistrictReportApiEndPoint = (root, report) => {
    return `${createStateReportApiEndPoint(root, report)}/districts/${DISTRICT_ID}`;
};

export const createStateReportApiEndPoint = (root, report) => {
    return `${root}/${report}/dates/${DATE_WINDOW_ID}`;
};

export const createReportPrintTileObject = (name, component, title = null, allowBpieStateUser = false) => ({
    allowBpieStateUser,
    component,
    name,
    title
});
