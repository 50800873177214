import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as layoutActions from "../../actions/layoutActions";
import * as surveyAdminActions from "../../actions/surveyAdminActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {getParams} from "../../components/layout/getParams";
import {emptyGuid} from "../../constants/config";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import {NotifyUser} from "../../components/common/NotifyUser";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import TextField from "../../components/common/inputs/TextField";
import FormTable from "../../components/common/FormTable";
import DisplayField from "../../components/common/inputs/DisplayField";
import {newSurveyDetails} from "../../components/surveyAdmin/surveyUtilities";
import DateTimePickerField from "../../components/common/inputs/DateTimePickerField";

export class ManageSurveyGroupDetailsPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            params: {},
            details: {}
        };

        this.saveDetails = this.saveDetails.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
    }

    componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.MANAGE_GROUP_DETAILS);

        if(params !== null)
            this.setState({params, details: newSurveyDetails(params.dateWindowId)});

        this.props.actions.updatePageTitle("Survey Group Details");

        this.props.actions.loadAllDateWindows();
    }

    onFormChange(event) {
        event.preventDefault();

        let details = Object.assign({}, this.state.details);
        details[event.target.name] = event.target.value;

        this.setState({details});
    }

    isValid() {
        let isValid = true;

        if (this.state.details.title === "") {
            NotifyUser.Warning("Survey title is required");
            isValid = false;
        }

        if (this.state.details.closeDate === "") {
            NotifyUser.Warning("Close Date is required");
            isValid = false;
        }
        else if (!sharedDataUtilities.isDateValid(this.state.details.closeDate)) {
            NotifyUser.Warning("Close Date must be a date");
            isValid = false;
        }

        return isValid;
    }

    saveDetails(event) {
        event.preventDefault();
        if (this.isValid()) {
            let details = Object.assign({}, this.state.details);
            details.surveyId = emptyGuid;
            this.props.actions.saveSurveyDetails(details)
                .then(() => {
                    this.props.history.push(surveyLocations.LANDING.path);
                });
        }
    }

    cancelClick(event) {
        event.preventDefault();

        this.props.history.push(surveyLocations.LANDING.path);
    }

    outputDateWindow() {
        if(this.props.dateWindows.length === 0 || !this.state.params.dateWindowId)
            return "";

        const selectedDateWindow = this.props.dateWindows.filter(dw => dw.value === this.state.params.dateWindowId)[0];
        return selectedDateWindow.text;
    }

    render() {
        return (
            <section>
                <ButtonBar>
                    <Button name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SAVE}
                            onClick={this.saveDetails}/>
                    <Button name="btnCancel"
                            label="Cancel"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={this.cancelClick}/>
                </ButtonBar>
                <FormTable>
                    <DisplayField name={"DateWindow"} label={"Date Window"} columnsMedium={6} showLabel>
                        {this.outputDateWindow()}
                    </DisplayField>
                    <TextField
                        name="title"
                        label="Survey Title"
                        showLabel
                        onChange={this.onFormChange}
                        value={this.state.details.title}
                        columnsMedium={6}
                    />
                    <DateTimePickerField name={"closeDate"}
                                         label={"Close Date"}
                                         onChange={this.onFormChange}
                                         value={this.state.details.closeDate}
                                         showLabel={true}
                                         medium={6} />
                </FormTable>
            </section>
        );
    }
}

ManageSurveyGroupDetailsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    details: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    dateWindows: PropTypes.arrayOf(PropTypes.object),
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    let dateWindows = [...state.sharedData.dateWindows];

    if (dateWindows.length > 0) {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
    }

    return {
        details: state.surveyAdmin.selectedSurveyInstance,
        dateWindows: dateWindows,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, layoutActions, surveyAdminActions, sharedDataActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyGroupDetailsPage);