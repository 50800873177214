import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";

class AdminPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.ADMIN]);
    }
}

export {AdminPolicy};

