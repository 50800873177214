import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import audit_outcomes from "../../../../constants/auditOutcomes";
import ActivitySummaryGrid from "../ActivitiesComponents/ActivitySummaryGrid";
import {enableCapActivityResults, getCapResultsStatusWhenActivityAccepted, isStatusEditable} from "../capUtilities";

const DistrictActivitiesView = ({
                                    capActivities = [],
                                    handleAdd,
                                    handleDelete,
                                    handleEdit,
                                    handleSubmitResults,
                                    indicatorId,
                                    isLoading,
                                    protocolCorrectiveActions,
                                    readOnly = true
                                }) => {

    const getCapActivityStatus = (capActivityStatus, capActivityResultsStatus, capActivityResultIsReadyToSubmit, acknowledgeNeedsRevisionComplete, acknowledgeResultsNeedsRevisionComplete) => {
        const resultsStatus = getCapResultsStatusWhenActivityAccepted(capActivityStatus, capActivityResultsStatus, capActivityResultIsReadyToSubmit, acknowledgeResultsNeedsRevisionComplete);

        return isTrimmedStringEmpty(resultsStatus) ? capActivityStatus : resultsStatus;
    };

    const getEditButtonDetails = (activityNumber, capActivityStatus, capActivityResultsStatus) => {
        const canEditActivity = !readOnly && isStatusEditable(capActivityStatus);
        const canEditActivityResults = !readOnly && enableCapActivityResults(capActivityStatus) && isStatusEditable(capActivityResultsStatus);

        const editResultsButtonLabel = canEditActivityResults ? " Results" : "";

        return {
            isEditable: canEditActivity || canEditActivityResults,
            label:  `${canEditActivity || canEditActivityResults ? "Edit" : "View"} CAP Activity ${activityNumber}${editResultsButtonLabel}`
        };
    };

    return (
        <ActivitySummaryGrid
            capActivities={capActivities}
            getCapActivityStatus={getCapActivityStatus}
            getEditButtonDetails={getEditButtonDetails}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleSubmitResults={handleSubmitResults}
            indicatorId={indicatorId}
            isLoading={isLoading}
            protocolCorrectiveActions={protocolCorrectiveActions}
            readOnly={readOnly}
        />
    );
};

DistrictActivitiesView.propTypes = {
    capActivities: PropTypes.arrayOf(PropTypes.object),
    handleAdd: PropTypes.func,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSubmitResults: PropTypes.func.isRequired,
    indicatorId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool
};

export default DistrictActivitiesView;