import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import FormTable from "../../../../components/common/FormTable";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import * as config from "../../../../constants/config";
import bpieApi from "../../bpieApi";
import {SBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";
import * as policyEvents from "../../../../constants/policyEvents";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {BpiePolicy} from "../../../../components/authorization/policies/BpiePolicy";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";

export const BpieSchoolSummarySbpies = ({
                                            actions,
                                            handleDeleteSbpie,
                                            schoolBpieId,
                                            schoolDetails
                                        }) => {

    const handleClickSchool = (event, schoolBpieId) => {
        event.preventDefault();
        actions.executeApi(bpieApi.downloadBpieSchoolAssessment, [schoolBpieId]);
    };

    const canBeDeleted = (bpieStatus) => {
        const updateStatuses = [
            SBPIE_ASSESSMENT_STATUS.Pending.description,
            SBPIE_ASSESSMENT_STATUS.Activated.description,
            SBPIE_ASSESSMENT_STATUS.Started.description,
            SBPIE_ASSESSMENT_STATUS.NotApplicable.description
        ];

        return updateStatuses.some(s => s === bpieStatus);
    }

    const canDelete = allow(BpiePolicy, policyEvents.bpie.admin.MANAGE);

    if (!schoolDetails.schools || schoolDetails.schools.length === 0)
        return null;

    return (
        <>
            {
                schoolDetails.schools.map((school, index) => {
                    const notificationTrigger = <CollapsibleTrigger
                        text={`SBPIE Assessment for ${school.datesCovered}`}/>;
                    const isContainerOpen = schoolBpieId === school.schoolBpieId || (schoolBpieId === config.emptyGuid && index === 0);
                    return (
                        <CollapsibleContainer
                            id={`notification-container`}
                            trigger={notificationTrigger}
                            open={isContainerOpen}
                            key={index}
                        >
                            <FormTable>
                                {
                                    school.currentStatusOutput === SBPIE_ASSESSMENT_STATUS.Archived.description &&
                                    <DisplayField
                                        displayClass={`text-center`}
                                        label={`Download SBPIE Assessment`}
                                        name={"reportView"}
                                        showLabel={false}>
                                        <Button
                                            label={'Download SBPIE Assessment'}
                                            name={`btnViewReport`}
                                            onClick={(event) => handleClickSchool(event, school.schoolBpieId)}
                                            showLabel
                                        />
                                    </DisplayField>
                                }
                                {
                                    school.currentStatusOutput !== SBPIE_ASSESSMENT_STATUS.Archived.description &&
                                    <DisplayField
                                        label={`Download SBPIE Assessment`}
                                        name={"reportView"}
                                        showLabel={false}>
                                        <GridRow rowClass={"bpie-assessment-summary"}>
                                            <GridColumn>
                                                <em>SBPIE Assessment is not available for download as it has not
                                                    been submitted.</em>
                                                {
                                                    canDelete &&
                                                    canBeDeleted(school.currentStatusOutput) &&
                                                    <Button
                                                        buttonType={ButtonTypes.DELETE}
                                                        label="Delete"
                                                        name="btnDelete"
                                                        onClick={handleDeleteSbpie}
                                                    />
                                                }
                                            </GridColumn>
                                        </GridRow>
                                    </DisplayField>
                                }
                                <DisplayField
                                    name={"datesCovered"}
                                    label={"Date Window:"}
                                    showLabel
                                    isInline>
                                    {school.datesCovered}
                                </DisplayField>
                                <DisplayField
                                    name={"schoolName"}
                                    label={"School Name:"}
                                    showLabel
                                    isInline>
                                    {school.schoolName}
                                </DisplayField>
                                {
                                    school.finFacilitatorFirstName &&
                                    <DisplayField
                                        name={"finFacilitator"}
                                        label={"FIN Facilitator:"}
                                        showLabel
                                        isInline>
                                        {`${school.finFacilitatorFirstName} ${school.finFacilitatorLastName}`}
                                    </DisplayField>
                                }
                                {
                                    school.schoolAdminFirstName &&
                                    <DisplayField
                                        name={"schoolAdmin"}
                                        label={"School Administrator:"}
                                        showLabel
                                        isInline>
                                        {`${school.schoolAdminFirstName} ${school.schoolAdminLastName}`}
                                    </DisplayField>
                                }
                                {
                                    school.isMeetingLeaderFinFacilitatorOrSchoolAdmin &&
                                    school.meetingLeaderTitle &&
                                    <DisplayField
                                        name={"meetingLeaderInfo"}
                                        label={"Meeting Leader:"}
                                        showLabel
                                        isInline>
                                        {school.meetingLeaderTitle}
                                    </DisplayField>
                                }
                                {
                                    !school.isMeetingLeaderFinFacilitatorOrSchoolAdmin &&
                                    school.meetingLeaderFirstName &&
                                    <DisplayField
                                        name={"meetingLeaderInfo"}
                                        label={"Meeting Leader:"}
                                        showLabel
                                        isInline>
                                        {`${school.meetingLeaderFirstName} ${school.meetingLeaderLastName} - ${school.meetingLeaderTitle}`}
                                    </DisplayField>
                                }
                                <DisplayField
                                    name={"currentStatus"}
                                    label={"Current Status:"}
                                    showLabel
                                    isInline>
                                    {school.currentStatusOutput}, Updated on {school.currentDateStatusUpdated}
                                </DisplayField>
                                {
                                    school.statusDetails.length > 0 &&
                                    <DisplayField
                                        name={"statusHistory"}
                                        label={"Previous Status History:"}
                                        showLabel>
                                        <ul>
                                            {
                                                school.statusDetails.map((statusInfo, index) => {
                                                    return (
                                                        <li key={index}>{statusInfo.statusOutput}, Updated
                                                            on {statusInfo.dateStatusUpdated}</li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </DisplayField>
                                }
                            </FormTable>
                        </CollapsibleContainer>
                    );
                })
            }
        </>
    );
};

BpieSchoolSummarySbpies.propTypes = {
    actions: PropTypes.object.isRequired,
    handleDeleteSbpie: PropTypes.func.isRequired,
    schoolBpieId: PropTypes.string.isRequired,
    schoolDetails: PropTypes.object.isRequired
};