import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sppReviewActions from "../../actions/sppReviewActions";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import SppElementSet from "../../components/spp/SppElementSet";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import * as sppActions from "../../actions/sppActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as fileActions from "../../actions/fileActions";
import SppElement from "../../components/spp/SppElement";
import * as sppUtilities from "../../components/spp/SppUtilities";
import SppReviewDocumentHierarchySelector from "../../components/sppReview/SppReviewDocumentHierarchySelector";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../constants/AuditConstants";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as sppLocations from "../../constants/spp/sppLocations";
import {catchError} from "../../actions/actionUtility";
import audit_outcomes from "../../constants/auditOutcomes";
import { animateScroll as scroll } from "react-scroll";
import { getParams } from "../../components/layout/getParams";

export class SppReviewSectionPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 1,
			commentEditingId: "",
			isTitleSet: false,
			isSubtitleSet: false,
			params: {},
			selectPartItems: [],
			selectSectionItems: [],
			partId: "",
			sectionId: ""
		};

		this.back = this.back.bind(this);
		this.next = this.next.bind(this);
		this.redirectDistrictSearch = this.redirectDistrictSearch.bind(this);
		this.redirectNextPreviousSection = this.redirectNextPreviousSection.bind(this);
		this.redirectToSection = this.redirectToSection.bind(this);
		this.buildSectionMenu = this.buildSectionMenu.bind(this);
		this.getNextButtonText = this.getNextButtonText.bind(this);
		this.getOverviewUrl = this.getOverviewUrl.bind(this);
		this.fileOnView = this.fileOnView.bind(this);
		this.outcomeChange = this.outcomeChange.bind(this);
		this.onCommentCancel = this.onCommentCancel.bind(this);
		this.onCommentSave = this.onCommentSave.bind(this);
		this.onCommentEdit = this.onCommentEdit.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.calculateTotalPages = this.calculateTotalPages.bind(this);
		this.documentHierarchy = this.documentHierarchy.bind(this);
		this.onCommentCreate = this.onCommentCreate.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadAllDateWindows();

		if (this.props.institutions.length === 0) {
			this.props.actions.loadInstitutions()
				.catch(error => catchError(error));
		}
	}

    componentDidUpdate(nextProps) {
        const params = getParams(this.props.location.pathname, sppLocations.SECTION_DOE);

        if (params !== null && (params.sectionId !== undefined && params.sectionId !== this.state.params.sectionId)) {
            this.setState({params});

            this.props.actions.loadSppDocument(params.documentId)
                .catch(error => catchError(error));

            this.props.actions.loadSppDocumentElements(params.documentId)
                .catch(error => catchError(error));

            this.props.actions.loadInstitutionResponses(params.institutionId, params.documentId)
                .catch(error => catchError(error));

            this.props.actions.loadPolicyCompliance(params.documentId, params.institutionId)
                .catch(error => catchError(error));
        }

		if (!this.state.isTitleSet &&
			nextProps.allDateWindows.length > 0 &&
			nextProps.document.dateWindowId !== undefined &&
			nextProps.institutions.length > 0 &&
			nextProps.policyCompliance.id !== undefined) {
			const pageTitle = sppUtilities.generatePageTitle(nextProps.allDateWindows, nextProps.policyCompliance, nextProps.document, nextProps.institutions, params.institutionId);

			this.props.actions.updatePageTitle(pageTitle);

			this.setState({
				isTitleSet: true
			});
		}

		if (nextProps.elements.length > 0 &&
			!this.state.isSubtitleSet) {
			const section = nextProps.elements.filter(el => el.id === this.state.params.sectionId)[0];
            if(section) {
                this.props.actions.updatePageSubTitle(section.text);

                this.setState({isSubtitleSet: true});

				this.buildSectionMenu("", this.state.params.sectionId);
            }
		}
	}

	componentWillUnmount() {
		this.props.actions.clearSubTitle();
		this.props.actions.clearSppData();
	}

	documentHierarchy() {
		return JSON.parse(this.props.document.componentsHierarchy);
	}

	getOverviewUrl() {
		return sppLocations.DOCUMENT_DOE.path
			.replace(sppLocations.INSTITUTION_ID, this.state.params.institutionId)
			.replace(sppLocations.DOCUMENT_ID, this.state.params.documentId);
	}

	getBackButtonText(curStep) {
		return (curStep === 1) ? "Overview" : "Back";
	}

	getNextButtonText(curStep, totalSteps) {
		return (curStep === totalSteps) ? "Overview" : "Next";
	}

	fileOnView(event) {
		event.preventDefault();
		const response = this.props.responses.filter(re => re.elementId === event.target.id)[0];
		const fileId = response.data;

		this.props.actions.downloadFile(fileId);
	}

	outcomeChange(event) {
		event.preventDefault();

		const elementParts = event.target.id.split("_");

		let actionMethod = "";
		switch (elementParts[0]) {
			case audit_outcomes.ACCEPTED.name:
				actionMethod = "acceptElementResponse";
				break;
			case audit_outcomes.NEEDS_REVISION.name:
				actionMethod = "rejectElementResponse";
				break;
			case audit_outcomes.RESET.name:
				actionMethod = "resetAudit";
				break;
		}

		if (actionMethod !== "") {
			this.props.actions[actionMethod](elementParts[1], emptyGuid)
				.catch(error => catchError(error));
		}
	}

	onCommentCancel(event) {
		event.preventDefault();

		this.props.actions.loadInstitutionResponses(this.state.params.institutionId, this.state.params.documentId)
			.catch(error => catchError(error));
	}

	onCommentSave(event, comment, elementResponseId) {
		event.preventDefault();

		if (comment.auditCommentId === "") {
			const newCommentId = emptyGuid;

			comment = Object.assign({}, comment, {auditCommentId: newCommentId});

			this.props.actions.addAuditComment(comment, elementResponseId)
				.catch(error => catchError(error));
		}
		else {
			this.props.actions.updateAuditComment(comment)
				.catch(error => catchError(error));
		}
	}

	onCommentEdit(commentId) {
		this.props.actions.editComment(commentId);
	}

	onCommentCreate(auditId, auditResponseId) {
		this.props.actions.createComment(auditId, auditResponseId);
	}

	handleCommentChange(event, comment, policyResponseId) {
		event.preventDefault();

		comment = Object.assign({}, comment);

		const fieldUpdated = event.target.name;
		const updatedValue = event.target.value;

		if(fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
			comment.isVisibleToDistrict = updatedValue;
		else
			comment.text = updatedValue;

		this.props.actions.changeComment(comment, policyResponseId);
	}

	back(event) {
		event.preventDefault();

		if (this.state.currentStep === 1)
			this.props.history.push(this.getOverviewUrl());
		else {
			this.setState({
				currentStep: this.state.currentStep - 1
			});
			scroll.scrollToTop();
		}
	}

	next(event) {
		event.preventDefault();
		const totalSteps = this.calculateTotalPages();

		if (this.state.currentStep === totalSteps)
			this.props.history.push(this.getOverviewUrl());
		else {
			this.setState({
				currentStep: this.state.currentStep + 1
			});
			scroll.scrollToTop();
		}
	}

	redirectDistrictSearch(event) {
		event.preventDefault();
		this.props.history.push(sppLocations.LANDING_DOE.path);
	}

	getSectionId(pageDiff) {
		const hierarchyJson = this.documentHierarchy();
		let sectionId = "";

		for(let i = 0; i < hierarchyJson.length; i++) {
			let part = hierarchyJson[i];
			let position = part.childElements.map((e) => e.elementId).indexOf(this.state.params.sectionId);

			if(position === -1)
				continue;

			const hasReachedNextLimitChildElements = position + pageDiff === part.childElements.length;
			const hasReachedPreviousLimitChildElements = position + pageDiff === -1;
			if(hasReachedNextLimitChildElements || hasReachedPreviousLimitChildElements) {
				const hasReachedNextLimit = i + pageDiff >= hierarchyJson.length;
				const hasReachedPreviousLimit = i + pageDiff === -1;
				if(hasReachedNextLimit || hasReachedPreviousLimit)
					break;

				const whichChild = pageDiff === 1 ? 0 : hierarchyJson[i + pageDiff].childElements.length - 1;
				sectionId = hierarchyJson[i + pageDiff].childElements[whichChild].elementId;
			}
			else
				sectionId = part.childElements[position + pageDiff].elementId;

			break;
		}

		return sectionId;
	}

	redirectNextPreviousSection(event, pageDiff) {
		event.preventDefault();
		const sectionId = this.getSectionId(pageDiff);
		this.redirectToSection(sectionId);

		this.buildSectionMenu("", sectionId);

		const label = this.props.elements.find(f => f.id === sectionId).text;
		this.props.actions.updatePageSubTitle(label);
	}

	redirectToSection(sectionId) {
		this.setState({currentStep: 1});
		this.props.history.push(sppLocations.SECTION_DOE.path
			.replace(sppLocations.INSTITUTION_ID, this.state.params.institutionId)
			.replace(sppLocations.DOCUMENT_ID, this.state.params.documentId)
			.replace(sppLocations.SECTION_ID, sectionId));
	}

	buildSectionMenu(partId, sectionId) {
		const selectedDocument = this.props.document;
		const documentElements = this.props.elements;

		const updatedSearchCriteria = sppUtilities.getSectionMenuOptions(selectedDocument, documentElements, {}, partId, sectionId);

		this.setState(updatedSearchCriteria);
	}

	calculateTotalPages(documentHierarchy = this.documentHierarchy()) {
		return sppUtilities.getSectionHierarchy(this.state.params.sectionId, documentHierarchy).childElements.length;
	}

	render() {
		if (this.props.document.id === undefined ||
			this.props.allDateWindows.length === 0 ||
			this.props.elements.length === 0 ||
			this.props.responses.length === 0 ||
			this.state.params.sectionId === undefined) return null;

		const documentHierarchy = this.documentHierarchy();
		const totalSteps = this.calculateTotalPages(documentHierarchy);

		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, documentHierarchy);

		//get the current element set's hierarchy children
		const elementListForCurrentPage = sectionHierarchy.childElements[this.state.currentStep - 1].childElements;

		const inputElements = sppUtilities.getInputElements(this.props.elements);
		const responses = sppUtilities.getElementResponses(inputElements, this.props.responses);

		return (
			<section>
				<ButtonBar position={ButtonBarPositions.STICKY}>
					<Button name={"btnDistrictSearch"}
							label={"District Search"}
							onClick={this.redirectDistrictSearch}
							buttonType={ButtonTypes.BACK}
					/>

					<Button name={"btnPreviousSection"}
							label={"Previous Section"}
							onClick={(event) => this.redirectNextPreviousSection(event, -1)}
							buttonType={ButtonTypes.PREVIOUS}
							disabled={ this.getSectionId(-1) === ""}
					/>

					<Button name={"btnNextSection"}
							label={"Next Section"}
							onClick={(event) => this.redirectNextPreviousSection(event, 1)}
							buttonType={ButtonTypes.NEXT}
							disabled={ this.getSectionId(1) === ""}
					/>

					<SppReviewDocumentHierarchySelector
						selectPartItems={this.state.selectPartItems}
						selectSectionItems={this.state.selectSectionItems}
						partId={this.state.partId}
						sectionId={this.state.sectionId}
						redirectToSection={this.redirectToSection}
						buildSectionMenu={this.buildSectionMenu}
						updatePageSubTitle={this.props.actions.updatePageSubTitle}
					/>
				</ButtonBar>
				<SppElementSet>
					{
						elementListForCurrentPage.map(childElement => {
							const element = this.props.elements.filter(el => el.id === childElement.elementId)[0];

							const response = responses.filter(r => r.elementId === childElement.elementId)[0];

							return (
								<SppElement
									key={element.id}
									element={element}
									response={response}
									fileOnView={this.fileOnView}
									isReviewer={true}
									outcomeChange={this.outcomeChange}
									onCommentCancel={this.onCommentCancel}
									onCommentSave={this.onCommentSave}
									onCommentEdit={this.onCommentEdit}
									handleCommentChange={this.handleCommentChange}
									editItemId={this.props.commentId}
									isLoading={this.props.isLoading}
									isInEditMode={this.props.isInEditMode}
									onCommentCreate={this.onCommentCreate}/>
							);
						})
					}
				</SppElementSet>
				<BackNextPager
					next={this.next}
					back={this.back}
					backText={this.getBackButtonText(this.state.currentStep)}
					nextText={this.getNextButtonText(this.state.currentStep, totalSteps)}
					currentPage={this.state.currentStep}
					totalPages={sectionHierarchy.childElements.length}
					isBottom
				/>
			</section>
		);
	}
}

SppReviewSectionPage.propTypes = {
	actions: PropTypes.object.isRequired,
	document: PropTypes.object.isRequired,
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	allDateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	responses: PropTypes.arrayOf(PropTypes.object),
	institutions: PropTypes.array.isRequired,
	policyCompliance: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isInEditMode: PropTypes.bool.isRequired,
	commentId: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		institutions: state.sharedData.institutions,
		allDateWindows: state.sharedData.dateWindows,
		document: state.spp.selectedDocument,
		elements: state.spp.documentElements,
		responses: state.spp.documentResponses,
		policyCompliance: state.spp.selectedPolicyCompliance,
		isLoading: state.ajaxCallsInProgress > 0,
		isInEditMode: state.spp.isInEditMode,
		commentId: state.spp.commentId
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sppActions,
		sppReviewActions,
		sharedDataActions,
		fileActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppReviewSectionPage);