import React from 'react';
import PropTypes from 'prop-types';
import {isArrayNullOrEmpty} from "../common/commonUtilities";
import AuditHistoryOutcome from "./AuditHistoryOutcome";
import CollapsibleContainer from "../common/CollapsibleContainer";

const AuditHistory = ({
                          ariaLabelledById,
                          audits,
                          editItemId,
                          handleCommentChange,
                          id = "",
                          isHistoryOpen = false,
                          isInEditMode = false,
                          isLoading = false,
                          labelForVisibility,
                          onCommentCancel,
                          onCommentCreate,
                          onCommentEdit,
                          onCommentSave,
                          parentIdPropName,
                          title = "History"
                      }) => {

    if (isArrayNullOrEmpty(audits))
        return null;

    return (
        <div className="row">
            <CollapsibleContainer ariaLabelledById={ariaLabelledById} open={isHistoryOpen} redrawOnComponentDidUpdate
                                  trigger={title} id={id}>
                <ul>
                    {audits.map((audit, index) => {
                        const isFirstAudit = (index === 0);
                        return (
                            <AuditHistoryOutcome
                                key={index}
                                audit={audit}
                                editItemId={editItemId}
                                handleCommentChange={handleCommentChange}
                                isFirstAudit={isFirstAudit}
                                isInEditMode={isInEditMode}
                                isLoading={isLoading}
                                labelForVisibility={labelForVisibility}
                                onCommentCreate={onCommentCreate}
                                onCommentCancel={onCommentCancel}
                                onCommentEdit={onCommentEdit}
                                onCommentSave={onCommentSave}
                                parentIdPropName={parentIdPropName}
                            />
                        );
                    })}
                </ul>
            </CollapsibleContainer>
        </div>
    );
};

AuditHistory.propTypes = {
    ariaLabelledById: PropTypes.string,
    audits: PropTypes.array,
    handleCommentChange: PropTypes.func,
    id: PropTypes.string,
    isHistoryOpen: PropTypes.bool,
    isInEditMode: PropTypes.bool,
    isLoading: PropTypes.bool,
    editItemId: PropTypes.string,
    labelForVisibility: PropTypes.string,
    onCommentCancel: PropTypes.func,
    onCommentCreate: PropTypes.func,
    onCommentEdit: PropTypes.func,
    onCommentSave: PropTypes.func,
    parentIdPropName: PropTypes.string,
    title: PropTypes.string
};

export default AuditHistory;