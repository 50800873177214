import fileApi from "../api/FileApi";
import {emptyGuid} from "../constants/config";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function fileUploadSuccess() {
    return {type: actionTypes.FILE_UPLOAD_AJAX_SUCCESS};
}

export function uploadFile(files, fileId = emptyGuid) {
    return dispatch => {

        dispatch(beginAjaxCall());

        return fileApi.uploadFile(files, fileId)
            .then((fileId) => {
                dispatch(fileUploadSuccess());
                return fileId;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                return undefined;
            });
    };
}

export function downloadFileSuccess() {
    return {type: actionTypes.FILE_DOWNLOAD_AJAX_SUCCESS};
}

export function downloadFile(fileId) {
    return dispatch => {

        dispatch(beginAjaxCall());

        return fileApi.downloadFile(fileId)
            .then(() => {
                dispatch(downloadFileSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function deleteFileSuccess() {
    return {type: actionTypes.FILE_DELETE_AJAX_SUCCESS};
}

export function deleteFile(fileId) {
    return dispatch => {

        dispatch(beginAjaxCall());

        return fileApi.deleteFile(fileId)
            .then(() => {
                dispatch(deleteFileSuccess());
                return true;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                return false;
            });
    };
}