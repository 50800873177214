import React from 'react';
import PropTypes from "prop-types";
import GridRow from "./GridRow";
import GridColumn from "./GridColumn";


class CollapsibleTrigger extends React.PureComponent {
	render() {
		return (
			<GridRow>
				<GridColumn large="9" xlarge="10">
					{this.props.text}
				</GridColumn>
				<GridColumn large="3" xlarge="2" columnClass={`Collapsible__trigger--notice`}>
					{this.props.status}
				</GridColumn>
			</GridRow>
		);
	}
}

CollapsibleTrigger.propTypes = {
	text: PropTypes.string.isRequired,
	status: PropTypes.string
};

export default CollapsibleTrigger;