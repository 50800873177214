import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import {catchError} from "../../actions/actionUtility";
import { bindActionCreators } from 'redux';
import * as accountActions from "../../actions/accountActions";
import ForgotPasswordForm from '../../components/account/ForgotPasswordForm';
import {setPageTitle} from "../../components/common/commonUtilities";
import {NotifyUser} from "../../components/common/NotifyUser";

export class ForgotPasswordPage extends React.Component{
  constructor(props) {
      super(props);

      this.state = {
          forgotPasswordViewModel: {
            email: ""
          },
          isLoading: false,
          errors: {}
      };

      setPageTitle("Forgot Password");

      this.onFormChange = this.onFormChange.bind(this);
      this.onClick = this.onClick.bind(this);
  }

  onFormChange(e) {
    const forgotPasswordViewModel = this.state.forgotPasswordViewModel;

    const name = e.target.name;
    forgotPasswordViewModel[name] = e.target.value;

    this.setState({ forgotPasswordViewModel: forgotPasswordViewModel });
  }

  forgotPasswordFormIsValid(){
        let formIsValid = true;
        let errors = {};
        let emailPattern = /(.+)@(.+){2,}\.(.+){2,}/;

        const {email} = this.state.forgotPasswordViewModel;

        if (email.trim().length === 0){
            errors.email = 'Email is required';
            formIsValid = false;
        }
        else{
            if (!emailPattern.test(email)){
                errors.email = 'Email is invalid';
                formIsValid = false;
            }
        }

        this.setState({errors: errors});
        return formIsValid;
  }

  onClick(e) {
    e.preventDefault();

    if (!this.forgotPasswordFormIsValid()){
        NotifyUser.Warning("Please fix the form errors and try again.");
        return;
    }

    this.props.actions.requestPasswordReset(this.state.forgotPasswordViewModel)
	    .catch(errors => catchError(errors));

  }

  render(){
    return (
        <ForgotPasswordForm
            forgotPasswordViewModel={this.state.forgotPasswordViewModel}
            onClick={this.onClick}
            onChange={this.onFormChange}
            isLoading={this.props.isLoading}
            errors={this.state.errors}
        />
    );
  }
}

ForgotPasswordPage.propTypes = {
    actions: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state){
    return {
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(accountActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (ForgotPasswordPage);
