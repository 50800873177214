import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.user, action) {
    switch(action.type){
        case actionTypes.LOAD_USERS_AJAX_SUCCESS :
            return Object.assign(
                {},
                state,
                {
                    searchResults: [...action.data.data],
                    totalRecords: action.data.totalRecords
                });
        case actionTypes.LOAD_BEHAVIORS_AJAX_SUCCESS :
            return Object.assign(
                {},
                state,
                {
                    behaviors: [...action.behaviors]
                });
        case actionTypes.LOAD_USER_BY_ID_AJAX_SUCCESS :
            return Object.assign(
                {},
                state,
                {
                    selectedUser: Object.assign({}, action.user)
                });
        case actionTypes.CLEAR_LOADED_USER :
            return Object.assign(
                {},
                state,
                {
                    selectedUser: {
                        hasActiveBpie: false
                    }
                });
        case actionTypes.LOAD_PROGRAMS_FOR_FISCAL_AGENT_AJAX_SUCCESS :
            return Object.assign(
                {},
                state,
                {
                    programsForFiscalAgent: [...action.programsForFiscalAgent]
                });
        case actionTypes.SAVE_USER_AJAX_SUCCESS :
            return Object.assign(
                {},
                state,
                {
                    selectedUser: Object.assign({}, action.user)
                });
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.user;
        default:
            return state;
    }
}