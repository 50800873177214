import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {DisputeResolutionType} from "../drConstants";
import SectionHeader from "../../components/common/SectionHeader";

export const DrMediationCaseload = ({
                                        handleClickCaseload,
                                        mediationCaseload,
                                        mediationTeamMembers = []
                                    }) => {
    return (
        <>
            {
                mediationCaseload &&
                <>
                    <SectionHeader>
                        <h2>Mediation Caseload</h2>
                    </SectionHeader>
                    
                    {
                        mediationTeamMembers &&
                        mediationTeamMembers.length > 0 &&
                        <>
                            <p>Mediation specialists include:</p>
                            <ul className={`single-space`}>
                                {
                                    mediationTeamMembers.map((member, index) => {
                                        return (
                                            <li key={index}>{member.fullName}</li>
                                        );
                                    })
                                }
                            </ul>
                        </>
                    }
                    <GridTable className={`caseload-table`}>
                        <thead>
                        <tr>
                            <th>Pending</th>
                            <th>Held</th>
                            <th>Closed</th>
                            <th>Withdrawn</th>
                            <th>Agreement Reached - Yes</th>
                            <th>Agreement Reached - No</th>
                            <th>Agreement Reached - Partial</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr onClick={(event) => handleClickCaseload(event, DisputeResolutionType.Mediation)}
                            className={"is-clickable"}>
                            <td>{mediationCaseload.pending}</td>
                            <td>{mediationCaseload.held}</td>
                            <td>{mediationCaseload.closed}</td>
                            <td>{mediationCaseload.withdrawn}</td>
                            <td>{mediationCaseload.agreementReachedYes}</td>
                            <td>{mediationCaseload.agreementReachedNo}</td>
                            <td>{mediationCaseload.agreementReachedPartial}</td>
                        </tr>
                        </tbody>
                    </GridTable>
                </>
            }
        </>
    );
};

DrMediationCaseload.propTypes = {
    handleClickCaseload: PropTypes.func.isRequired,
    mediationCaseload: PropTypes.object.isRequired,
    mediationTeamMembers: PropTypes.array
};

export default DrMediationCaseload;