import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {objectHasProperty} from "../../components/common/commonUtilities";
import {NotifyUser} from "../../components/common/NotifyUser";

const SystemMessage = ({
                           actions,
                           message
                       }) => {

    const displaySystemMessage = () => {
        if (message.displayMessage && !message.messageDisplayed) {
            NotifyUser.Info(message.text, message.header, {"timeOut": 15000});
            actions.saveMessageDisplayed();
        }
    };

    useEffect(() => {
        if (!objectHasProperty(message, "text"))
            actions.loadSystemMessage();

        displaySystemMessage();
    }, [message]);

    return null;
};

const mapStateToProps = (state) => {
    return {
        message: state.sharedData.systemMessage
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, sharedDataActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

SystemMessage.propTypes = {
    actions: PropTypes.object,
    message: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemMessage);