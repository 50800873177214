import {
    convertToArray,
    isNullOrUndefined,
    isObjectNullOrEmpty
} from "../../components/common/commonUtilities";
import {getYesNoOption} from "../../components/common/inputs/inputUtility";
import {outputStandardDateFormat, outputStandardTimeOnlyFormat} from "../../components/shared/sharedDataUtilities";
import {createTeamMemberObject} from "../../components/users/teamMemberObjectFactory";
import {emptyGuid} from "../../constants/config";
import {DrRoles} from "../drConstants";
import {createSchoolInformationObject} from "../drObjectFactory";
import {getTeamMemberId, getTeamMember} from "../drUtilities";
import * as drDueProcessConstants from "./drDueProcessConstants";
import LocalStorage from "../../components/shared/LocalStorage";

export const createDueProcessSummaryObject = (
    districtId,
    districtName,
    dueProcessOpenCount,
    dueProcessTotalCount,
    dueProcessTotalCountAdjudicated,
    statusReportsInProgress,
    statusReportsNeedsRevisionCount,
    statusReportsNeedingReviewCount,
    responsesDistrictInProgress,
    responsesDistrictNeedsRevisionCount,
    responsesNeedingReviewCount,
    dateWindowId) =>
    ({
        districtId,
        districtName,
        dueProcessOpenCount,
        dueProcessTotalCount,
        dueProcessTotalCountAdjudicated,
        statusReportsInProgress,
        statusReportsNeedsRevisionCount,
        statusReportsNeedingReviewCount,
        responsesDistrictInProgress,
        responsesDistrictNeedsRevisionCount,
        responsesNeedingReviewCount,
        dateWindowId
    });

export const createDueProcessTile = ({
                                         body = "New Request",
                                         footer,
                                         header,
                                         isNew = false,
                                         key,
                                         url
                                     } = {}) => ({
    body,
    footer,
    header,
    isNew,
    key,
    url
});

export const createDueProcessObject = ({
                                           actions,
                                           alj,
                                           caseManagementOrderDate,
                                           caseManagementOrderDateOutput,
                                           caseNumber,
                                           changeOfPlacement,
                                           complaintType,
                                           dateReceived,
                                           dateClosed,
                                           dateFiled,
                                           dateSubmitted,
                                           dateWindowId,
                                           districtId,
                                           dueProcessActivityTimelineModel,
                                           DueProcessLiaison,
                                           dueProcessStatus,
                                           dueProcessTimeline,
                                           finalOrderFiles,
                                           finalOrderIssues,
                                           finalOrderIssuesAtFiling,
                                           finalOrderOrderedActions,
                                           hearingDecisionDeadline,
                                           id,
                                           inappropriateAljExtendTimeline,
                                           inappropriateAljExtendTimelineExplanation,
                                           isActionEditable,
                                           isActionEnabled,
                                           isFinalOrderEnabled,
                                           nextResolutionProcessNeedingReviewDistrictId,
                                           nextResolutionProcessNeedingReviewId,
                                           parentRepresentationStatus,
                                           parentRepresentationStatusOther,
                                           parentRepresentationStatusAtFiling,
                                           parentRepresentationStatusOtherAtFiling,
                                           partyResponseDate,
                                           prevailingParty,
                                           previousResolutionProcessNeedingReviewDistrictId,
                                           previousResolutionProcessNeedingReviewId,
                                           recommendedOversightLessons,
                                           resolvedSimilarToPastDecisions,
                                           resolvedSimilarToPastDecisionsExplanation,
                                           resolutionMeetingDeadline,
                                           resolutionMeetingDeadlineOutput,
                                           resolutionProcess,
                                           resolutionProcessDeadline,
                                           resolutionProcessDueDate,
                                           resolutionSessionOutcome,
                                           resolutionSessionOutcomeOther,
                                           schoolInformationRecords,
                                           teamMembers,
                                           timeline,
                                           writtenSettlementAgreement,
                                           caseNoteModels
                                       } = {}) => ({
    actions: actions ? actions.map(action => {
        return createActionObject(action);
    }) : [],
    alj: alj ? alj : "",
    caseManagementOrderDate,
    caseManagementOrderDateOutput,
    caseNumber: caseNumber || "",
    changeOfPlacement: changeOfPlacement ? changeOfPlacement : undefined,
    complaintType: complaintType,
    dateReceived: dateReceived ? dateReceived : undefined,
    dateClosed: dateClosed ? dateClosed : undefined,
    dateFiled: dateFiled ? dateFiled : undefined,
    dateSubmitted: dateSubmitted ? dateSubmitted : undefined,
    dateWindowId,
    districtId,
    dueProcessActivityTimelineModel: dueProcessActivityTimelineModel ? createActivityTimelineObject(dueProcessActivityTimelineModel) : undefined,
    DueProcessLiaison: DueProcessLiaison || getTeamMemberId(teamMembers, DrRoles.DueProcessLiaison.id),
    dueProcessLiaisonInfo: getTeamMember(teamMembers, DrRoles.DueProcessLiaison.id),
    dueProcessStatus: !isNullOrUndefined(dueProcessStatus) ? dueProcessStatus : drDueProcessConstants.DueProcessStatus.Pending.id,
    dueProcessTimeline: !isNullOrUndefined(dueProcessTimeline) ? dueProcessTimeline : "",
    finalOrderFiles: finalOrderFiles ? finalOrderFiles.map(finalOrderFile => createFinalOrderFileObject(finalOrderFile)) : [],
    finalOrderIssues: finalOrderIssues ? convertToArray(finalOrderIssues) : [],
    finalOrderIssuesAtFiling: finalOrderIssuesAtFiling ? convertToArray(finalOrderIssuesAtFiling) : [],
    finalOrderOrderedActions: finalOrderOrderedActions || "",
    hearingDecisionDeadline,
    id: id ? id : emptyGuid,
    inappropriateAljExtendTimeline: inappropriateAljExtendTimeline || "",
    inappropriateAljExtendTimelineExplanation: inappropriateAljExtendTimelineExplanation || "",
    isActionEditable,
    isActionEnabled,
    isFinalOrderEnabled,
    nextResolutionProcessNeedingReviewDistrictId,
    nextResolutionProcessNeedingReviewId,
    parentRepresentationStatus: !isNullOrUndefined(parentRepresentationStatus) ? parentRepresentationStatus : "",
    parentRepresentationStatusOther: parentRepresentationStatusOther ? parentRepresentationStatusOther : "",
    parentRepresentationStatusAtFiling: !isNullOrUndefined(parentRepresentationStatusAtFiling) ? parentRepresentationStatusAtFiling : "",
    parentRepresentationStatusOtherAtFiling: parentRepresentationStatusOtherAtFiling ? parentRepresentationStatusOtherAtFiling : "",
    partyResponseDate,
    prevailingParty: !isNullOrUndefined(prevailingParty) ? prevailingParty : "",
    previousResolutionProcessNeedingReviewDistrictId,
    previousResolutionProcessNeedingReviewId,
    recommendedOversightLessons: recommendedOversightLessons || "",
    resolvedSimilarToPastDecisions: resolvedSimilarToPastDecisions || "",
    resolvedSimilarToPastDecisionsExplanation: resolvedSimilarToPastDecisionsExplanation || "",
    resolutionMeetingDeadline,
    resolutionMeetingDeadlineOutput,
    resolutionProcess,
    resolutionProcessDeadline,
    resolutionProcessDueDate,
    resolutionSessionOutcome: !isNullOrUndefined(resolutionSessionOutcome) ? resolutionSessionOutcome : "",
    resolutionSessionOutcomeOther: resolutionSessionOutcomeOther ? resolutionSessionOutcomeOther : "",
    schoolInformationRecords: schoolInformationRecords ? schoolInformationRecords.map(schoolInfo => createSchoolInformationObject(schoolInfo)) : [],
    teamMembers: teamMembers ? teamMembers.map(teamMember => createTeamMemberObject(teamMember)) : [],
    timeline,
    writtenSettlementAgreement: !isNullOrUndefined(writtenSettlementAgreement) ? writtenSettlementAgreement : undefined,
    caseNoteModels: caseNoteModels || [],
});

export function createFinalOrderFileObject({
                                               dateCreated,
                                               displayName,
                                               finalOrderFileId,
                                               length,
                                               name,

                                           } = {}) {
    return {
        dateCreated,
        displayName,
        finalOrderFileId,
        length,
        name
    };
}

export const createActivityTimelineObject = ({
                                                 dueProcessActivityTimelineId,
                                                 dueProcessActivityTimelineResponseModels
                                             } = {}) => ({
    dueProcessActivityTimelineId,
    dueProcessActivityTimelineResponseModels: dueProcessActivityTimelineResponseModels ? dueProcessActivityTimelineResponseModels.map(activityTimeline => {
        return createActivityTimelineResponseObject(activityTimeline);
    }) : [],
});

export const createActivityTimelineResponseObject = ({
                                                         activity,
                                                         dateCompleted,
                                                         dateModified,
                                                         displayOrder,
                                                         dueDate,
                                                         dueDateWarning,
                                                         dueProcessActivityTimelineRequirementId,
                                                         dueProcessActivityTimelineResponseId,
                                                         responder,
                                                         responderId,
                                                         roleResponsibleForCompletion
                                                     } = {}) => ({
    activity,
    dateCompleted,
    dateModified,
    displayOrder,
    dueDate,
    dueDateWarning,
    dueProcessActivityTimelineRequirementId,
    dueProcessActivityTimelineResponseId,
    responder,
    responderId,
    roleResponsibleForCompletion
});

export const createActionObject = ({
                                       dueProcessId,
                                       description,
                                       dateDue,
                                       id = emptyGuid,
                                       districtResponse,
                                       isSubmittedToState,
                                       isReadyToApprove
                                   } = {}) => ({
    dueProcessId,
    description,
    dateDue,
    id,
    hasResponse: !isObjectNullOrEmpty(districtResponse) && !!districtResponse.dateCompleted,
    districtResponse: createResponseObject(districtResponse ? districtResponse : {actionId: id, dueProcessId}),
    isSubmittedToState: !!isSubmittedToState,
    isReadyToApprove: !!isReadyToApprove,
});

export const createResponseObject = ({
                                         actionId,
                                         currentStatus,
                                         dateCompleted,
                                         id = emptyGuid,
                                         isReadyToSubmit,
                                         responseAudits = [],
                                         results,
                                         review = {},
                                     } = {}) => ({
    actionId,
    currentStatus,
    dateCompleted: dateCompleted ? outputStandardDateFormat(dateCompleted, false) : undefined,
    id,
    isReadyToSubmit,
    responseAudits: responseAudits ? responseAudits.map(audit => {
        return createResponseAuditsObject(audit);
    }) : [],
    results: results ? results : "",
    hasReview: !isObjectNullOrEmpty(review) && !!review.dateDocumentationReviewed,
    review: !isObjectNullOrEmpty(review) ? createReviewObject(review) : {}
});

export const createReviewObject = ({
                                       dateDocumentationReceived,
                                       dateDocumentationReviewed,
                                       responseId,
                                       id
                                   } = {}) => ({
    dateDocumentationReceived: dateDocumentationReceived ? dateDocumentationReceived : "",
    dateDocumentationReviewed: dateDocumentationReviewed ? dateDocumentationReviewed : "",
    responseId,
    id
});

export const createResponseAuditsObject = ({
                                               auditId,
                                               comments,
                                               dateCreated,
                                               isDistrictUser,
                                               isLocked,
                                               outcomeString,
                                               responseId,
                                               auditCreatedBy
                                           } = {}) => ({
    auditId,
    comments,
    dateCreated,
    isDistrictUser,
    isLocked,
    outcomeString,
    responseId,
    auditCreatedBy
});

export const createResolutionProcessObject = ({
                                                  caseNumber,
                                                  dateReceived,
                                                  id,
                                                  resolutionProcessDueDate,
                                                  resolutionProcess,
                                                  reasonForExtension,
                                                  extensionDate
                                              } = {}) => {

    const resolutionProcessId = !resolutionProcess ? emptyGuid : resolutionProcess.resolutionProcessId;

    const resolutionProcessUpdate = !resolutionProcess ? {} : resolutionProcess;

    const resolutionProcessOption = !resolutionProcessUpdate.resolutionProcessOption || resolutionProcessUpdate.resolutionProcessOption === drDueProcessConstants.ResolutionProcessOptions.Option8
        ? ""
        : resolutionProcessUpdate.resolutionProcessOption;

    return {
        caseNumber: resolutionProcessId !== emptyGuid ? caseNumber : "",
        dateReceived: resolutionProcessId !== emptyGuid ? dateReceived : undefined,
        dueDate: resolutionProcessDueDate ? resolutionProcessDueDate : undefined,
        dueProcessId: id,
        resolutionProcessId,
        noticeInWriting: getYesNoOption(resolutionProcessUpdate.noticeInWriting),
        dateOfNoticeInWriting: resolutionProcessUpdate.dateOfNoticeInWriting ? resolutionProcessUpdate.dateOfNoticeInWriting : undefined,
        explanationNoticeInWriting: resolutionProcessUpdate.explanationNoticeInWriting ? resolutionProcessUpdate.explanationNoticeInWriting : "",
        option1DateHeld: resolutionProcessUpdate.option1DateHeld ? resolutionProcessUpdate.option1DateHeld : undefined,
        option1DateHeldBeyondDeadlineExplanation: resolutionProcessUpdate.option1DateHeldBeyondDeadlineExplanation || "",
        option1DistrictAttorney: getYesNoOption(resolutionProcessUpdate.option1DistrictAttorney),
        option1Outcome: resolutionProcessUpdate.option1Outcome || "",
        option1ParentAttorney: getYesNoOption(resolutionProcessUpdate.option1ParentAttorney),
        option1UseOfAttorneyDifferencesExplanation: resolutionProcessUpdate.option1UseOfAttorneyDifferencesExplanation || "",
        option2DateOfWaiver: resolutionProcessUpdate.option2DateOfWaiver ? resolutionProcessUpdate.option2DateOfWaiver : undefined,
        option3DateHeld: resolutionProcessUpdate.option3DateHeld ? resolutionProcessUpdate.option3DateHeld : undefined,
        option3DistrictAttorney: getYesNoOption(resolutionProcessUpdate.option3DistrictAttorney),
        option3Outcome: resolutionProcessUpdate.option3Outcome || "",
        option3AgreementType: resolutionProcessUpdate.option3AgreementType || "",
        option3ParentAttorney: getYesNoOption(resolutionProcessUpdate.option3ParentAttorney),
        option5DateWithdrawnOrDismissed: resolutionProcessUpdate.option5DateWithdrawnOrDismissed ? resolutionProcessUpdate.option5DateWithdrawnOrDismissed : undefined,
        option6DateWithdrawnOrDismissed: resolutionProcessUpdate.option6DateWithdrawnOrDismissed ? resolutionProcessUpdate.option6DateWithdrawnOrDismissed : undefined,
        option6DescriptionOfAttemptsMadeByDistrict: resolutionProcessUpdate.option6DescriptionOfAttemptsMadeByDistrict ? resolutionProcessUpdate.option6DescriptionOfAttemptsMadeByDistrict : "",
        option7OtherValue: resolutionProcessUpdate.option7OtherValue ? resolutionProcessUpdate.option7OtherValue : "",
        resolutionProcessOption,
        resolutionProcessAudits: resolutionProcessUpdate.resolutionProcessAudits
            ? resolutionProcessUpdate.resolutionProcessAudits.map(audit => {
                return createResolutionProcessAuditsObject(audit);
            }) : [],
        currentStatus: resolutionProcessUpdate.currentStatus,
        isReadyToSubmit: isNullOrUndefined(resolutionProcessUpdate.isReadyToSubmit) ? true : resolutionProcessUpdate.isReadyToSubmit,

        resolutionProcessExtensions: resolutionProcess?.resolutionProcessExtensions ? resolutionProcess.resolutionProcessExtensions : [],
        reasonForExtension: reasonForExtension || "",
        extensionDate
    };
};

export const createExtensionDateObject = ({
                                              resolutionProcessExtensionId,
                                              extensionDate,
                                              resolutionProcessId,
                                              reasonForExtension
                                          } = {}) => {
    return {
        resolutionProcessId: resolutionProcessId ? resolutionProcessId : "",
        extensionDate,
        resolutionProcessExtensionId: resolutionProcessExtensionId ? resolutionProcessExtensionId : "",
        reasonForExtension: reasonForExtension || "",
        removeExtension: false,
    };
};

export const createResolutionProcessAuditsObject = ({
                                                        auditId,
                                                        comments,
                                                        dateCreated,
                                                        isDistrictUser,
                                                        isLocked,
                                                        outcomeString,
                                                        resolutionProcessId,
                                                        auditCreatedBy
                                                    } = {}) => ({
    auditId,
    comments,
    dateCreated,
    isDistrictUser,
    isLocked,
    outcomeString,
    resolutionProcessId,
    auditCreatedBy
});

export const createCaseNoteObject = ({
                                         activityDuration,
                                         author,
                                         authorId,
                                         caseNoteId,
                                         dueProcessId,
                                         content,
                                         dateAdded,
                                         endTime,
                                         noteType,
                                         otherNoteType,
                                         startTime,
                                     } = {}) => ({
    activityDuration,
    author: author || `${LocalStorage.getLoggedInUser().FirstName} ${LocalStorage.getLoggedInUser().LastName}`,
    authorId,
    caseNoteId,
    dueProcessId,
    content,
    dateAdded: dateAdded ? outputStandardDateFormat(dateAdded, false) : "",
    endTime: endTime ? outputStandardTimeOnlyFormat(endTime) : "",
    noteType,
    otherNoteType: otherNoteType || "",
    startTime: startTime ? outputStandardTimeOnlyFormat(startTime) : "",
    updatedStartTime: outputStandardTimeOnlyFormat(startTime, true),
    updatedEndTime: outputStandardTimeOnlyFormat(endTime, true)
});