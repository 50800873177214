import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import GridColumn from "../../../components/common/GridColumn";
import {openInNewTab} from "../../../components/common/HtmlUtilities";
import {removeDomainForInternalRoute} from "../../../components/common/routingUtilities";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as drLocations from "../../drLocations";
import DrStateComplaintDueNowTasks from "./DrStateComplaintDueNowTasks";
import DrStateComplaintUpcomingTasks from "./DrStateComplaintUpcomingTasks";

export const DrStateComplaintTasksContainer = ({
                                                   actions,
                                                   api,
                                                   complaint,
                                                   history
                                               }) => {
    const [complaintTasks, setComplaintTasks] = useState(null);
    const [printSearchCriteria, setPrintSearchCriteria] = useState(null);

    const handlePrintClick = (event) => {
        event.preventDefault();
        const startDate = printSearchCriteria.startDate ? encodeURIComponent(printSearchCriteria.startDate) : "-1";
        const endDate = printSearchCriteria.endDate ? encodeURIComponent(printSearchCriteria.endDate) : "-1";
        const printUrl = drLocations.STATE_COMPLAINT_TASK_PRINT.getUrl(complaint.id, printSearchCriteria.dateRange, startDate, endDate);
        openInNewTab(printUrl);
    };

    const updatePrintSearchCriteria = (criteria) => {
        setPrintSearchCriteria(criteria);
    };

    const handleClickRecord = async (event, url) => {
        event.preventDefault();

        history.push(removeDomainForInternalRoute(url));
    };

    const getComplaintTasks = () => {
        if(!complaint || !complaint.id)
            return;

        actions.executeApi(api.getComplaintTasks, [complaint.id])
            .then((result) => setComplaintTasks(result));
    };

    useEffect(() => {
        getComplaintTasks();
    }, [complaint]);

    if (!complaintTasks ) return null;

    return (
        <GridColumn columnClass={`state-complaint-tasks`}>
            <ButtonBar position={""}>
                <Button name={`btnPrint`}
                        label={`Print`}
                        buttonType={ButtonTypes.PRINT}
                        onClick={handlePrintClick}
                        btnClass={`l-float-right`}
                />
            </ButtonBar>

            <DrStateComplaintDueNowTasks
                complaintTasks={complaintTasks}
                handleClickRecord={handleClickRecord}
            />

            <DrStateComplaintUpcomingTasks
                complaintTasks={complaintTasks}
                handleClickRecord={handleClickRecord}
                updatePrintSearchCriteria={updatePrintSearchCriteria}
            />
        </GridColumn>
    );
};

DrStateComplaintTasksContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    api: PropTypes.func.isRequired,
    complaint: PropTypes.object,
    history: PropTypes.object
};

export default DrStateComplaintTasksContainer;
