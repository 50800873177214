import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import * as policyEvents from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class MonitoringCompliancePolicy extends DefaultPolicy {
    constructor() {
        super([contexts.MONITORING_COMPLIANCE]);
        this.updatePolicy();
    }

    updatePolicy() {

        this._policy[policyEvents.monitoring_compliance.protocolRequirement.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.protocolRequirement.ACTION_BAR] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };


        this._policy[policyEvents.monitoring_compliance.assessment.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.assessment.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.studentRecord.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.studentRecord.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.activity.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.activity.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.review.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.review.ACTION_BAR] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.cap.AUDIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.cap.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.studentCorrections.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.studentCorrections.SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.studentCorrections.AUDIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
            };

        this._policy[policyEvents.monitoring_compliance.caseNote.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE], userBehaviors);
            };
    }
}

export {MonitoringCompliancePolicy, policyEvents};

