import * as behaviors from "../../../constants/behaviors";
import * as PolicyEvents from "../../../constants/policyEvents";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";
import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";

class AdminMenuPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.SPP, contexts.STRATEGIC_PLAN, contexts.SURVEYS, contexts.ADMIN, contexts.USERS, contexts.STATE_COMPLAINTS, contexts.BPIE, contexts.PTS]);
        this.updatePolicy();
    }

    updatePolicy() {
        this._policy[PolicyEvents.ADMIN_MENU] =
            function (allowedContexts, userBehaviors, isFinFacilitator) {
                return isFinFacilitator
                    ? verifyAccessToContextAndClaim(allowedContexts.filter(f => f !== contexts.BPIE), [ behaviors.MANAGE ], userBehaviors)
                    : verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors)
                        || verifyAccessToContextAndClaim([contexts.ADMIN], [ behaviors.MANAGE, behaviors.VIEW ], userBehaviors);
            };
    }
}

export {AdminMenuPolicy};