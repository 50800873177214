import React from "react";
import PropTypes from 'prop-types';
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {StateComplaintPolicy} from "../../../components/authorization/policies/StateComplaintPolicy";
import {dispute_resolution} from "../../../constants/policyEvents";

const DrStateComplaintClosureProcessRequirement = ({
	                                                   closureProcessRequirement,
	                                                   handleChangeCompletionDate
                                                   }) => {
	const canEdit = AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY);
	return (
		<FieldsetLayout
			legend={closureProcessRequirement.requirement}
			showLegend
		>
			<DateTimePickerField
				name={`closureProcessDue${closureProcessRequirement.closureProcessRequirementType}`}
				label="Due"
				showLabel
				value={closureProcessRequirement.dueDate}
				disabled
				medium={6}
			/>
			<DateTimePickerField
				name={`closureProcessCompleted${closureProcessRequirement.closureProcessRequirementType}`}
				label="Completed"
				showLabel
				value={closureProcessRequirement.dateCompleted}
				onChange={(event) => handleChangeCompletionDate(closureProcessRequirement.closureProcessRequirementType, event.target.value)}
				medium={6}
				disabled={!canEdit}
			/>
		</FieldsetLayout>
	);
};

DrStateComplaintClosureProcessRequirement.propTypes = {
	closureProcessRequirement: PropTypes.object.isRequired,
	handleChangeCompletionDate: PropTypes.func.isRequired
};

export default DrStateComplaintClosureProcessRequirement;