import React from "react";
import PropTypes from 'prop-types';
import ActionBar from "../../../components/common/buttons/ActionBar";
import FormTable from "../../../components/common/FormTable";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import DrDueProcessActionResponseReviewDisplay from "./DrDueProcessActionResponseReviewDisplay";
import DrDueProcessActionResponseReviewEdit from "./DrDueProcessActionResponseReviewEdit";
import {isObjectNullOrEmpty} from "../../../components/common/commonUtilities";

const DrDueProcessActionResponseReview = ({
                                            response,
                                            canStateReview,
                                            editResponseId,
                                            handleClickCancel,
                                            handleEdit,
                                            handleRemoveResponseReviewAsync,
                                            handleSaveResponseReviewAsync,
                                            isDistrictUser,
                                            isSubmitted
                                        }) => {

    const isStarted = !isObjectNullOrEmpty(response.review);
    const isEditView = !isDistrictUser && editResponseId === response.id;

    const handleClickEdit = () => handleEdit(response.id);
    const handleClickDelete = isStarted ? () => handleRemoveResponseReviewAsync(response.actionId, response.id) : undefined;

    if (!response.dateCompleted || !isSubmitted) return null;

    return (
        <div>
            {
                !isEditView &&
                canStateReview &&
                <ActionBar
                    elementId={`response-review-${response.id}`}
                    onEditClick={handleClickEdit}
                    onDeleteClick={handleClickDelete}
                    disabled={isEditView}
                />
            }

            <FormTable className={`dueProcessAction__response`}>
                <FieldsetLayout
                    legend={`Results Review`}
                    showLegend
                    fieldsetClass={`dueProcessAction__review`}
                >
                    {
                        !isEditView &&
                        !isStarted &&
                        <p>Not Started</p>
                    }
                    {
                        isEditView &&
                        <DrDueProcessActionResponseReviewEdit
                            response={response}
                            handleClickCancel={handleClickCancel}
                            handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
                            review={response.review}
                        />
                    }
                    {
                        isStarted &&
                        !isEditView &&
                        <DrDueProcessActionResponseReviewDisplay
                            review={response.review}
                        />
                    }
                </FieldsetLayout>
            </FormTable>
        </div>
    );
};

DrDueProcessActionResponseReview.propTypes = {
    response: PropTypes.object.isRequired,
    canStateReview: PropTypes.bool.isRequired,
    editResponseId: PropTypes.string.isRequired,
    handleClickCancel: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleRemoveResponseReviewAsync: PropTypes.func.isRequired,
    handleSaveResponseReviewAsync: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isSubmitted: PropTypes.bool.isRequired
};

export default DrDueProcessActionResponseReview;