import React from 'react';
import PropTypes from 'prop-types';
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import {getParams} from "../../components/layout/getParams";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import {animateScroll as scroll} from "react-scroll/modules";
import MatrixResultsGridForm from "../../components/MonitoringCompliance/StudentCorrections/MatrixResultsGridForm";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class MatrixCorrectionsPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            params: {}
        };
    }

    componentDidMount() {
        this.props.actions.updatePageTitle("Matrix Protocol Corrections");

        const params = getParams(this.props.location.pathname, ComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS);
        if (params !== null) {
            this.setState({params});
        }

        if (MonitoringComplianceUtilities.isValidId(params.assessmentId)) {
            this.props.actions.loadComplianceWithMatrixCorrectiveResults(params.assessmentId);
        }
    }

    save(newStep, that) {
        const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);

        const redirect = (newStep, that) => {
            if (newStep > 0) {
                that.setState({currentStep: newStep});
                scroll.scrollToTop();

                that.props.history.push(ComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS.path
                    .replace(ComplianceLocations.ASSESSMENT_ID, that.props.assessmentId)
                    .replace(ComplianceLocations.DATE_WINDOW_ID, that.props.dateWindowId)
                    .replace(ComplianceLocations.CURRENT_PAGE, newStep)
                    .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX));
            }
            else {
                that.props.history.push(ComplianceLocations.DISTRICT_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path
                    .replace(ComplianceLocations.DATE_WINDOW_ID, that.props.dateWindowId)
                    .replace(ComplianceLocations.ASSESSMENT_ID, that.props.assessmentId)
                    .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX));
            }
        };

        if(canEdit) {
            const currentStudentRecordId = that.getRecordId();
            that.props.actions.saveMatrixCorrectiveResult(that.props.assessmentId,
                currentStudentRecordId,
                that.props.matrixProtocolCorrectiveResults.find(f => f.studentRecordId === currentStudentRecordId))
                .then(() => {
                    if (newStep > 0) {
                        that.props.actions.loadComplianceWithMatrixCorrectiveResults(that.props.assessmentId)
                            .then(() => {
                                redirect(newStep, that);
                            })
                    }
                    else
                        redirect(newStep, that);
                });
        }
        else
            redirect(newStep, that);
    }

    render() {
        if(!this.props.matrixProtocolCorrectiveResults || this.props.matrixProtocolCorrectiveResults.length === 0) return null;

        const studentRecordId = this.props.getMatrixRecordId();
        const matrixCorrectionResults = this.props.matrixProtocolCorrectiveResults.filter(f => f.studentRecordId === studentRecordId);
        const totalSteps = this.props.calculateTotalPages();
        const currentStep = this.props.currentStep;
        const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);

        return (
            <section className={"compliance__studentCorrectionResults"}>
                {
                    this.props.matrixProtocolCorrectiveResults.length === 0 &&
                    <p>No matrix specific corrections are needed at this time.</p>
                }
                {
                    this.props.matrixProtocolCorrectiveResults.length > 0 &&
                    <div>
                        {
                            currentStep > 1 &&
                            currentStep < totalSteps &&
                            <ButtonBar position={ButtonBarPositions.STICKY}>
                                <Button name={"btnBackTop"}
                                        label={"Main Page"}
                                        onClick={(event) => this.props.mainPage(event, this.save)}
                                        buttonType={ButtonTypes.BACK}
                                />
                            </ButtonBar>
                        }
                        <MatrixResultsGridForm
                            correctionResults={matrixCorrectionResults}
                            onResponseChange={this.props.onChangeMatrixCorrectionResult}
                            isLoading={this.props.isLoading}
                        />
                        <BackNextPager
                            next={(event) => this.props.next(event, this.save)}
                            back={(event) => this.props.back(event, this.save)}
                            backText={this.props.getBackButtonText(currentStep)}
                            nextText={this.props.getNextButtonText(currentStep, totalSteps)}
                            currentPage={currentStep}
                            totalPages={totalSteps}
                            isBottom
                            save={canEdit ? (event) => this.props.onSave(event, this.save) : null}
                            saveText={"Save Results"}
                        />
                    </div>
                }
            </section>
        );
    }
}

MatrixCorrectionsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getBackButtonText: PropTypes.func.isRequired,
    getNextButtonText: PropTypes.func.isRequired,
    onChangeMatrixCorrectionResult: PropTypes.func.isRequired,
    getMatrixRecordId: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    back: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    mainPage: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    calculateTotalPages: PropTypes.func.isRequired
};
