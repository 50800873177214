import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import bpieAdminApi from "../bpieAdminApi";
import SchoolImportView from "./SchoolImportView";

const SchoolImportContainer = ({
                                   actions
                               }) => {
    const [schoolImportErrors, setSchoolImportErrors] = useState([]);
    const [hasSchoolImportErrors, setHasSchoolImportErrors] = useState(false);
    const [numberOfSchoolsImported, setNumberOfSchoolsImported] = useState(0);
    const [displayImportResults, setDisplayImportResults] = useState(false);

    const createSchoolImportError = ({
                                         errorMessage,
                                         districtName,
                                         schoolNameShort,
                                         schoolImportErrorId
                                     } = {}) => (
        {
            errorMessage: errorMessage || "",
            districtName: districtName || "",
            schoolName: schoolNameShort || "",
            schoolImportErrorId,
        }
    );

    const loadSchoolImportErrors = async () => {
        await actions.executeApi(bpieAdminApi.getSchoolImportErrors, [])
            .then((incomingSchoolImportResult) => {
                setErrorValues(incomingSchoolImportResult);
            });
    }

    const setErrorValues = (schoolImportResult) => {
        const sanitizedSchoolImportErrors = schoolImportResult.errors.map(schoolImportError => createSchoolImportError(schoolImportError));
        setNumberOfSchoolsImported(schoolImportResult.schoolsImported);
        setSchoolImportErrors(sanitizedSchoolImportErrors);
        setHasSchoolImportErrors(sanitizedSchoolImportErrors.length > 0);
    }

    const handleFileUpload = async (event) => {
        event.preventDefault();

        await actions.executeApi(bpieAdminApi.importMsidFile, [event.target.files])
            .then((schoolImportResult) => {
                setErrorValues(schoolImportResult);
            });

        const fuMsId = document.getElementById("fuMsId");
        fuMsId.value = "";

        setDisplayImportResults(true);
    }

    useEffect(() => {

        loadSchoolImportErrors();
        actions.updatePageTitle("School Import");

    }, []);

    return (
        <>
            <SchoolImportView
                schoolImportErrors={schoolImportErrors}
                numberOfSchoolsImported={numberOfSchoolsImported}
                hasSchoolImportErrors={hasSchoolImportErrors}
                displayImportResults = {displayImportResults}
                handleFileUpload={handleFileUpload}
            />

            <FlexSpacer/>

        </>
    );
}

SchoolImportContainer.propTypes = {
    actions: PropTypes.object.isRequired,
};

export default SchoolImportContainer;