import React from 'react';
import PropTypes from 'prop-types';
import AbstractRadioField, {BaseAbstractRadioFieldPropTypes} from "./AbstractRadioField";
import {inputStyles} from '../../../constants/inputConstants';

const RadioField = ({
	                    direction = inputStyles.radio.direction.VERTICAL,
	                    disabled,
	                    error,
						helpText,
	                    inputClass,
	                    legend,
	                    legendClass = "",
	                    name,
	                    onChange = () => {},
	                    options,
	                    showClearResponseButton,
	                    showLegend = true,
						wrapperClass = "",
						value
                    }) => {

	return (
		<AbstractRadioField
			direction={direction}
			disabled={disabled}
			error={error}
			helpText={helpText}
			inputClass={inputClass}
			legend={legend}
			legendClass={legendClass}
			name={name}
			onChange={onChange}
			options={options}
			showClearResponseButton={showClearResponseButton}
			showLegend={showLegend}
			wrapperClass={wrapperClass}
			value={value}
		/>
	);
};

RadioField.propTypes = {
	...BaseAbstractRadioFieldPropTypes,
	direction: PropTypes.string
};

export default RadioField;