import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sppLocations from "../../constants/spp/sppLocations";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import Button from "../../components/common/buttons/Button";

export class SppAdminWrapper extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dataChanged: false
        };

        this.getCurrentLocation = this.getCurrentLocation.bind(this);
	    this.navButtonClick = this.navButtonClick.bind(this);
	    this.compareEditPaths = this.compareEditPaths.bind(this);
        this.compareCopyPaths = this.compareCopyPaths.bind(this);
        this.setDataChanged = this.setDataChanged.bind(this);
        this.warnOnNavigate = this.warnOnNavigate.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle("Policies and Procedures Admin");
    }

    navButtonClick(event, location){
        event.preventDefault();

        if(location === this.getCurrentLocation()) return;

        if(this.warnOnNavigate())
            this.props.history.push(location);
    }

	compareEditPaths(){
		return (this.getCurrentLocation().includes(sppLocations.ADMIN_EDITOR.path));
	}

    compareCopyPaths(){
        return (this.getCurrentLocation().includes(sppLocations.ADMIN_COPY.path));
    }

    getCurrentLocation(){
        return this.props.location.pathname;
    }

    setDataChanged(dataChanged) {
        this.setState({dataChanged: dataChanged});
    }

    warnOnNavigate() {
        if(this.state.dataChanged) {
           const continueNavigation = (confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."));

           if(continueNavigation)
               this.setDataChanged(false);

           return continueNavigation;
        }

        return true;
    }

    render() {
        const {component: Component} = this.props;

        return (
            <GridRow isPageNav>
                <GridColumn isPageNavLinks
                            isStickyLinks
                            large="3">
                    <Button btnClass={this.getCurrentLocation() === sppLocations.ADMIN_DOCUMENTS.path ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, sppLocations.ADMIN_DOCUMENTS.path)}
                            label={"Documents"}
                            name={sppLocations.ADMIN_DOCUMENTS.path} />
	                <Button btnClass={this.compareEditPaths() ? "is-active" : ""}
	                        onClick={(event) => this.navButtonClick(event, sppLocations.ADMIN_EDITOR.path)}
	                        label={"Editor"}
	                        name={sppLocations.ADMIN_EDITOR.path} />
                    <Button btnClass={this.compareCopyPaths() ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, sppLocations.ADMIN_COPY.path)}
                            label={"Copy Strategy"}
                            name={sppLocations.ADMIN_COPY.path} />
                </GridColumn>

                <GridColumn isPageNavContent
                            large="9">
                    <Component {...this.props} setDataChanged={this.setDataChanged} warnOnNavigate={this.warnOnNavigate} />
                </GridColumn>
            </GridRow>
        );
    }
}

SppAdminWrapper.propTypes = {
    actions: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    layout: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        layoutActions
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(null, mapDispatchToProps)(SppAdminWrapper));