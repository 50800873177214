import {verifyAccessToContext, allow} from "../components/authorization/AuthorizationUtilities";
import {DueProcessPolicy} from "../components/authorization/policies/DueProcessPolicy";
import {MediationPolicy} from "../components/authorization/policies/MediationPolicy";
import {StateComplaintPolicy} from "../components/authorization/policies/StateComplaintPolicy";
import {createDescriptionObjectForList, createListFromObject} from "../components/common/commonUtilities";
import {createYesNoList, createYesNoObject} from "../components/common/inputs/inputUtility";
import * as contexts from "../constants/contexts";
import {dispute_resolution} from "../constants/policyEvents";
import { DueProcessStatus } from "./DueProcesses/drDueProcessConstants";
import { DrFilterMediationStatus } from "./Mediations/drMediationConstants";

export const CaseResearchVariableStatus = {
	StateComplaint: {
		Open: "Open",
		Closed: "Closed"
	},
	Mediation: DrFilterMediationStatus,
	DueProcess: DueProcessStatus
};
export const StateComplaintCaseResearchStatusList = createListFromObject(CaseResearchVariableStatus.StateComplaint);
export const MediationCaseResearchStatusList = createListFromObject(CaseResearchVariableStatus.Mediation);
export const DueProcessCaseResearchStatusList = createListFromObject(CaseResearchVariableStatus.DueProcess);

export const UserStatus =
{
	Active: "Active",
	Inactive: "Inactive",
};
export const UserStatusList = createListFromObject(UserStatus);

export const DrFiltersComplaintStatus = {
	Open: "open",
	"Open (In Abeyance)": "openInAbeyance",
	Closed: "closed",
	TimelineItemsDue: "timelineItemsDue",
	CorrectiveActionsInProgress: "correctiveActionsInProgress",
	CorrectiveActionResponsesNeedingReview: "correctiveActionResponsesNeedingReview",
	ClosureItemsDue: "closureItemsDue"
};

export const DrFiltersComplaintStatusList = createListFromObject(DrFiltersComplaintStatus);

export const DisputeResolutionType = {
	StateComplaints: "StateComplaints",
	Mediation: "Mediation",
	DueProcess: "DueProcess"
};

export const CaseNumberPostFix = {
	Mediation: "M",
	Complaint: "C",
	DueProcess: "D"
}

export const HasTeamMembers = (type) => {
	return type === DisputeResolutionType.StateComplaints || type === DisputeResolutionType.DueProcess;
}

export const ConvertStateComplaintToSingular = (typeDescription) => {
	return typeDescription === "State Complaints" ? "State Complaint" : typeDescription;
}

export function BuildDisputeResolutionTypeList(behaviorClaims) {
	const showDrStateComplaint = verifyAccessToContext(contexts.STATE_COMPLAINTS, behaviorClaims);
	const showDrMediation = verifyAccessToContext(contexts.MEDIATIONS, behaviorClaims);
	const showDrDueProcess = verifyAccessToContext(contexts.DUE_PROCESS, behaviorClaims);
	let obj = {};
	if(showDrStateComplaint)
		obj[DisputeResolutionType.StateComplaints] = DisputeResolutionType.StateComplaints;

	if(showDrMediation)
		obj[DisputeResolutionType.Mediation] = DisputeResolutionType.Mediation;

	if(showDrDueProcess)
		obj[DisputeResolutionType.DueProcess] = DisputeResolutionType.DueProcess;

	return createListFromObject(obj);
}

export function GetNewDisputeResolutionTypeDetailsIfHaveAccess(disputeResolutionType) {
	if(disputeResolutionType === DisputeResolutionType.StateComplaints)
		return allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY) ? "State Complaint" : "";

	if(disputeResolutionType === DisputeResolutionType.DueProcess)
		return allow(DueProcessPolicy, dispute_resolution.dueProcess.MODIFY) ? "Due Process Hearing Request" : "";

	if(disputeResolutionType === DisputeResolutionType.Mediation)
		return allow(MediationPolicy, dispute_resolution.mediation.MODIFY) ? "Mediation" : "";

	return "";
}

export const StateComplaintType =
	{
		Disability: "Disability",
		Gifted: "Gifted",
		Other: "Other"
	};
export const StateComplaintTypeList = createListFromObject(StateComplaintType);

export const DrRoles = {
	ComplaintLeadInvestigator: createDescriptionObjectForList(1, "Lead Investigator"),
	ContentSpecialist: createDescriptionObjectForList(10, "Content Specialist"),
	ProgramDirector: createDescriptionObjectForList(2, "Program Director"),
	AdminSecretary: createDescriptionObjectForList(3, "Administrative Secretary"),
	SectionAdministrator: createDescriptionObjectForList(4, "Section Administrator"),
	Editor: createDescriptionObjectForList(5, "Editor"),
	GeneralCounsel: createDescriptionObjectForList(6, "General Counsel"),
	BureauChief: createDescriptionObjectForList(7, "Bureau Chief"),
	MediationLiaison: createDescriptionObjectForList(8, "Mediation Liaison"),
	ComplaintBackupInvestigator: createDescriptionObjectForList(9, "Backup Investigator"),
    DueProcessLiaison: createDescriptionObjectForList(11, "Due Process Liaison"),
	DueProcessCoordinator: createDescriptionObjectForList(12, "Due Process Coordinator"),
	DueProcessAdmin: createDescriptionObjectForList(13, "Due Process Admin")
};

export const DrRolesList = createListFromObject(DrRoles);

export const CreateDrRolesListForDrAdmin = () => {
	let roles = DrRolesList.filter(f => f.value !== DrRoles.ComplaintBackupInvestigator.id);

	let leadInvestigator = roles.find(f => f.value === DrRoles.ComplaintLeadInvestigator.id);
	if(leadInvestigator)
		leadInvestigator.text = "Complaint Investigator";

	return roles;
}

export const SingleSelectionDrRoles = [
	DrRoles.SectionAdministrator.id,
	DrRoles.Editor.id,
	DrRoles.GeneralCounsel.id,
	DrRoles.BureauChief.id
];

export const SingleSelectionDrRolesAssociatedToComplaint = [
	DrRoles.SectionAdministrator.id,
	DrRoles.Editor.id,
	DrRoles.GeneralCounsel.id,
	DrRoles.BureauChief.id
];

export const DrRolesAssociatedToComplaint = [
	DrRoles.ComplaintLeadInvestigator.id,
	DrRoles.ProgramDirector.id,
	DrRoles.AdminSecretary.id,
	DrRoles.ComplaintBackupInvestigator.id,
	DrRoles.ContentSpecialist.id,
];

export const DrRolesAssociatedToDueProcess = [
	DrRoles.DueProcessLiaison.id
];

export const SingleSelectionDrRolesAssociatedToDueProcess = [
	DrRoles.DueProcessCoordinator.id,
	DrRoles.DueProcessAdmin.id,
	DrRoles.AdminSecretary.id
];

export const DrComplaintTimelineRequirementResponseNotApproved = 0;

export const DrComplaintTimelineRequirementResponseOptionsEnum = {
	Approved: 1,
	NotApproved: 2
};

export const DrComplaintTimelineRequirementResponseOptionsList = createListFromObject(DrComplaintTimelineRequirementResponseOptionsEnum);

export const RadioButtonYesNoList = createYesNoList();
export const RadioButtonYesNoObject = createYesNoObject();

export const RadioButtonYesNoUnknown = {
	Yes: "Yes",
	No: "No",
	Unknown: "Unknown"
};
export const RadioButtonYesNoUnknownList = createListFromObject(RadioButtonYesNoUnknown);

export const IntakeStatusEnum = {
	Editable: createDescriptionObjectForList(1, "Editable"),
	Active: createDescriptionObjectForList(2, "Active"),
	Inactive: createDescriptionObjectForList(3, "Inactive")
};

export const IntakeStatusList = createListFromObject(IntakeStatusEnum);

export const EnrollmentLocation = {
	PublicSchool:  createDescriptionObjectForList(1, "Public School"),
	PrivateSchool: createDescriptionObjectForList(2, "Private School"),
	PublicCharterSchool: createDescriptionObjectForList(3, "Public Charter School"),
	DjjProgram: createDescriptionObjectForList(4, "DJJ Program")
};

export const EnrollmentLocationList = createListFromObject(EnrollmentLocation);