import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../common/GridTable";
import ResultsGrid from "./ResultsGrid";

const StudentResultsGrid = (
    {
        studentCorrectionResults,
        onStudentProtocolCorrectiveResultOutcomeChange,
        isLoading,
        onCommentCreate,
        isCommentInEditMode,
        handleCommentChange,
        onCommentEdit,
        onCommentSave,
        onCommentCancel,
        editCommentItemId
    }) =>
{
    const mainAreaWidthProps = {
        columnClass: "medium-offset-1",
        medium: "11"
    };
    return (
        <div>
            <p><strong>For student record: {studentCorrectionResults[0].name}</strong></p>
            <GridTable disableHorizontalScroll>
                <thead>
                <tr>
                    <th>Finding(s) of Noncompliance</th>
                </tr>
                </thead>
                <ResultsGrid
                    correctionResults={studentCorrectionResults}
                    isLoading={isLoading}
                    onProtocolCorrectiveResultOutcomeChange={onStudentProtocolCorrectiveResultOutcomeChange}
                    idPropName={"studentProtocolCorrectiveResultId"}
                    mainAreaWidthProps={mainAreaWidthProps}
                    onCommentCreate={onCommentCreate}
                    isCommentInEditMode={isCommentInEditMode}
                    handleCommentChange={handleCommentChange}
                    onCommentEdit={onCommentEdit}
                    onCommentSave={onCommentSave}
                    onCommentCancel={onCommentCancel}
                    editCommentItemId={editCommentItemId}
                />
            </GridTable>
        </div>
    );
};

StudentResultsGrid.propTypes = {
    studentCorrectionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onStudentProtocolCorrectiveResultOutcomeChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCommentCreate: PropTypes.func.isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    onCommentEdit: PropTypes.func.isRequired,
    onCommentSave: PropTypes.func.isRequired,
    onCommentCancel: PropTypes.func.isRequired,
    editCommentItemId: PropTypes.string.isRequired
};

export default StudentResultsGrid;