import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as surveyAdminActions from "../../actions/surveyAdminActions";
import * as fileActions from "../../actions/fileActions";
import * as layoutActions from "../../actions/layoutActions";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {catchError} from "../../actions/actionUtility";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";
import SurveyRespondentList from "../../components/surveyAdmin/SurveyRespondentList";
import FileUploadButton from "../../components/common/inputs/FileUploadButton";
import { getParams } from "../../components/layout/getParams";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import $ from 'jquery';

export class ManageSurveyRespondentsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			fileUploaded: false,
			fileId: "",
		};

		this.remind = this.remind.bind(this);
		this.unsubmitRespondent = this.unsubmitRespondent.bind(this);
		this.deleteRespondent = this.deleteRespondent.bind(this);
		this.uploadFile = this.uploadFile.bind(this);
		this.downloadSampleFile = this.downloadSampleFile.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.MANAGE_RESPONDENTS);

        if (params !== null)
        	this.setState({params});

		this.props.actions.tryLoadSurveyDetails(params.surveyInstanceId)
			.then(() => {
				this.props.actions.loadSurveyRespondents(params.surveyInstanceId);

				this.props.actions.updatePageTitle(this.props.details.title + " - Respondents");
			})
			.catch(error => catchError(error));
	}

	uploadFile(event) {
		event.preventDefault();
		const files = event.target.files;
		this.props.actions.tryImportSurveyRespondents(files, this.state.params.surveyInstanceId)
			.then(() => {
				this.reloadAfterImport();
			})
			.catch(() => {
				this.reloadAfterImport();
			});
	}

	reloadAfterImport() {
		let control = $("#fuRespondents");
		control.wrap('<form>').closest('form').get(0).reset();
		control.unwrap();

		this.props.actions.loadSurveyRespondents(this.state.params.surveyInstanceId);
	}

	downloadSampleFile(event){
		event.preventDefault();

		this.props.actions.downloadSampleRespondentImportFile();
	}

	remind(event) {
		event.preventDefault();

		if (confirm("Emails will be sent to all survey respondents who have not yet submitted their survey. Are you sure you want to remind the respondents?")) {
			this.props.actions.remindSurveyRespondents(this.state.params.surveyInstanceId);
		}
	}

	unsubmitRespondent(event) {
		event.preventDefault();

		if (confirm("Are you sure you want to unsubmit this respondent's survey?")) {
			const surveyRespondentId = event.target.id.split('|')[1];

			this.props.actions.unsubmitRespondentSurvey(surveyRespondentId);
		}
	}

	deleteRespondent(event) {
		event.preventDefault();

		if (confirm("Deleting this respondent will disallow them to access this survey and erase all of their responses. Are you sure you want to delete the respondent?")) {
			const surveyRespondentId = event.target.id.split('|')[1];

			this.props.actions.deleteSurveyRespondent(surveyRespondentId);
		}
	}

	render() {
		if (this.props.details.surveyId === undefined) return null;

		return (
			<div>
				{
					this.props.details.status !== surveyStatuses.Closed &&
					<ButtonBar position={ButtonBarPositions.TOP}>
						<Button name={"btnRemind"}
						        label={"Remind Respondents"}
						        onClick={this.remind}
						        buttonType={ButtonTypes.EMAIL}
						        disabled={this.props.details.status !== surveyStatuses.Published}
						/>

						<FileUploadButton
							label={"Upload Respondents"}
							name={"fuRespondents"}
							onChange={this.uploadFile}
						/>

						<Button name={"btnSampleFile"}
								label={"Sample File"}
								buttonType={ButtonTypes.DOWNLOAD}
								onClick={this.downloadSampleFile}/>
					</ButtonBar>
				}

				{
					this.props.details.status &&
					<SurveyRespondentList
						onRespondentUnsubmit={this.unsubmitRespondent}
						onRespondentDelete={this.deleteRespondent}
						respondents={this.props.respondents}
						surveyStatus={this.props.details.status}/>
				}
			</div>
		);
	}
}

ManageSurveyRespondentsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	details: PropTypes.object.isRequired,
	respondents: PropTypes.array,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		details: state.surveyAdmin.selectedSurveyInstance,
		respondents: state.surveyAdmin.instanceRespondents,
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, surveyAdminActions, fileActions, layoutActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyRespondentsPage);