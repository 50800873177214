import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as userActions from "../../actions/userActions";
import * as layoutActions from "../../actions/layoutActions";
import {catchError} from "../../actions/actionUtility";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {BpiePolicy} from "../../components/authorization/policies/BpiePolicy";
import {deepCopyArray, deepCopyObject, isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import {createYesNoObject} from "../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../components/common/NotifyUser";
import UserProfileAccountForm from "../../components/users/UserProfileAccountForm";
import UserProfileBehaviorsForm from "../../components/users/UserProfileBehaviorsForm";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Allow from "../../components/authorization/Allow";
import * as policyEvents from "../../constants/policyEvents";
import {UserPolicy} from "../../components/authorization/policies/UserPolicy";
import * as institutionActions from "../../actions/sharedDataActions";
import LocalStorage from "../../components/shared/LocalStorage";
import {getParamsMultiple} from "../../components/layout/getParams";
import * as usersLocations from "../../constants/users/usersLocations";
import {STATUS} from "../../constants/users/userConstants";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import * as systemLocations from "../../constants/systemLocations";
import AccountUtility from "../../api/AccountUtility";
import { isAssignedToProgram } from './userObjectFactory';

export class UserProfilePage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: {},
			errors: {},
			isStateUser: !AccountUtility.isDistrictUser(),
			programsForFiscalAgent: [],
			selectedPrograms: []
		};

		this.onFormChange = this.onFormChange.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.reactivateUser = this.reactivateUser.bind(this);
		this.resetPassword = this.resetPassword.bind(this);
		this.saveUser = this.saveUser.bind(this);
		this.validate = this.validate.bind(this);
		this.cancel = this.cancel.bind(this);
		this.onBehaviorChange = this.onBehaviorChange.bind(this);
		this.redirectUser = this.redirectUser.bind(this);
		this.loadProgramForFiscalAgent = this.loadProgramForFiscalAgent.bind(this);
	}

	componentDidMount() {
		const params = getParamsMultiple(this.props.location.pathname, [
			usersLocations.PROFILE,
			usersLocations.NEW_USER
		]);

		this.props.actions.updatePageTitle("User Profile");

		if (this.props.institutions.length === 0)
			this.props.actions.loadInstitutions();

		if (params.userId)
			this.props.actions.loadUserById(params.userId);
		else
			this.props.actions.loadUserById("");

	}

    componentDidUpdate() {
		if (this.state.user.id === undefined &&
			this.props.user.id !== undefined) {

            const params = getParamsMultiple(this.props.location.pathname, [
                usersLocations.PROFILE,
                usersLocations.NEW_USER
            ]);

			let newBehaviors = [];
			for (let i = 0; i < this.props.user.behaviors.length; i++) {
				newBehaviors[i] = Object.assign({}, this.props.user.behaviors[i]);
			}

			const currentUser = LocalStorage.getLoggedInUser();

			if (currentUser.Id === params.userId)
				this.props.actions.updatePageTitle("My Account");

			let institutionId = "";
			if (this.props.user.institutionId !== null)
				institutionId = this.props.user.institutionId;
			else if (currentUser.InstitutionId !== null)
				institutionId = currentUser.InstitutionId;

			this.loadProgramForFiscalAgent(institutionId, this.props.user.id);

			const newUser = Object.assign({},
                this.props.user,
				{
					institutionId: institutionId,
					behaviors: [...newBehaviors],
				}
			);

			this.setState({
				user: newUser,
				isStateUser: !newUser.institutionId,
				selectedPrograms: []
			});
		}
	}

	componentWillUnmount() {
		this.props.actions.clearLoadedUser();
	}

	onFormChange(event) {
		event.preventDefault();

		const user = this.state.user;
		let isStateUser = this.state.isStateUser;
		let selectedPrograms = this.state.selectedPrograms;
		let programsForFiscalAgent = this.state.programsForFiscalAgent;
		const name = event.target.name;

		if ((name === "institutionId" || name === "isStateUser")) {
			if(selectedPrograms.length > 0 && !confirm("This will potentially cause the associated programs to be removed.  Are you sure you want to continue?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
				return;

			if(name === "institutionId")
				this.loadProgramForFiscalAgent(event.target.value, user.id);
			else {
				const yesNoObject = createYesNoObject(false);
				isStateUser = event.target.value === yesNoObject.Yes;
				if(isStateUser) {
					user.institutionId = "";
					this.loadProgramForFiscalAgent(user.institutionId, user.id);
				}
				else
					programsForFiscalAgent = [];

				selectedPrograms = [];
			}
		}

		if(name === 'programsForFiscalAgents'){
			selectedPrograms = event.target.value;
		}
		else if(name !== "isStateUser")
			user[name] = event.target.value;

		this.setState(
		{
				user: Object.assign({}, user),
				isStateUser: isStateUser,
				selectedPrograms: selectedPrograms,
				programsForFiscalAgent: programsForFiscalAgent
			}
		);
	}

	loadProgramForFiscalAgent(institutionId, userId){
		this.props.actions.loadProgramForFiscalAgent(institutionId, userId)
		.then(programs => {
			let previouslySelectedPrograms = programs.filter(program => program.isUserAssociated).map(m => {
				const fiscalAgentId = !m.fiscalAgentId ? `` : `|${m.fiscalAgentId}`;
				return `${m.programId}${fiscalAgentId}`;
			});

			const isFiscalAgentUpdated = this.state.selectedPrograms.length > 0;
			if(isFiscalAgentUpdated)
				previouslySelectedPrograms = this.state.selectedPrograms
					.filter(selectedProgram => programs.some(program => selectedProgram === program.programId
						|| selectedProgram === `${program.programId}|${program.fiscalAgentId}`));

			this.setState({programsForFiscalAgent: programs, selectedPrograms: previouslySelectedPrograms});
		})
		.catch(error => {
			catchError(error);
		});
	}

	onBehaviorChange(event) {
		const target = event.target;
		const splitTargetId = target.id.split("-");

		const context = splitTargetId[0];
		const behavior = splitTargetId[1];

		let userBehaviors = deepCopyArray(this.state.user.behaviors);

		const changedBehavior = userBehaviors.filter(b => {
			return b.securityArea.toLowerCase() === context && b.claimValue.split('.')[1] === behavior;
		})[0];

		const indexOfChangedBehavior = userBehaviors.indexOf(changedBehavior);

		userBehaviors[indexOfChangedBehavior].selected = target.checked;

		let user = deepCopyObject(this.state.user);
		user.behaviors = userBehaviors;

		if(this.state.selectedPrograms.length > 0 && !isAssignedToProgram(user)){
			if(!confirm("On removing all access to PTS, the user will no longer have access to the programs. Are you sure you want to continue?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
				return;
			}

			this.setState({
				user: Object.assign({}, user),
				selectedPrograms : []
			});
		}
		else {
			this.setState({
				user: Object.assign({}, user)
			});
		}
	}

	resetPassword(event) {
		event.preventDefault();

		if (confirm("Are you sure you want to prompt a password change?"))
			this.props.actions.resetUserPassword(this.state.user.id)
				.catch(error => {
					catchError(error);
				});
	}

	deleteUser(event) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this user?"))
			this.props.actions.deleteUser(this.state.user.id)
				.then(() => {
					this.redirectUser();
				})
				.catch(error => {
					catchError(error);
				});
	}

	reactivateUser(event) {
		event.preventDefault();

		if (confirm("Are you sure you want to reactivate this user?"))
			this.props.actions.reactivateUser(this.state.user.id)
				.then(() => {
					let user = this.state.user;
					user.status = STATUS.ACTIVE;
					this.setState({user: Object.assign({}, user)});
				})
				.catch(error => {
					catchError(error);
				});
	}

	validate() {
		let isValid = true;
		let errors = {};

		if (this.state.user.username === "") {
			errors.username = "Username is required";
			isValid = false;
		}

		if (this.state.user.firstName === "") {
			errors.firstName = "First Name is required";
			isValid = false;
		}

		if (this.state.user.lastName === "") {
			errors.lastName = "Last Name is required";
			isValid = false;
		}

		if (this.state.user.email === "") {
			errors.email = "Email is required";
			isValid = false;
		}

		if(!this.state.isStateUser && isTrimmedStringEmpty(this.state.user.institutionId)){
			errors.institutionId = "District/Institution is required";
			isValid = false;
		}

		this.setState({errors: Object.assign({}, errors)});

		if (!isValid)
			NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

		return isValid;
	}

	saveUser(event) {
		event.preventDefault();

		if (this.validate()) {
			this.props.actions.saveUser(this.state.user)
			.then((user) => {
				const selectedPrograms = this.state.selectedPrograms.map(program => {
					const splitProgramId = program.split("|");
					if(splitProgramId.length === 1) return splitProgramId[0];

					return {
						programId: splitProgramId[0],
						fiscalAgentId: splitProgramId[1]
					};
				});

				this.props.actions.saveUserPrograms(user, selectedPrograms)
					.then(() => {
						this.redirectUser();
					})
					.catch(error => {
						catchError(error);
					});
			})
		}
	}

	cancel(event) {
		event.preventDefault();
		this.redirectUser();
	}

	redirectUser() {
		const isManage = AuthorizationUtilities.allow(UserPolicy, policyEvents.MANAGE);

		if(isManage)
			this.props.history.push(usersLocations.SEARCH.path);
		else
			this.props.history.push(systemLocations.DASHBOARD.path);
	}

	render() {
		let buttonBar =
			(
				<ButtonBar position={ButtonBarPositions.STICKY}>
					{
						!this.props.location.pathname.endsWith("New") &&
						<Button onClick={this.resetPassword}
						        label="Reset Password"
						        name="btnResetPassword"
						        buttonType={ButtonTypes.RESET}
						        disabled={this.props.isLoading}/>
					}
					{
						!this.props.location.pathname.endsWith("New") && this.state.user.status === STATUS.ACTIVE &&
						!this.props.user.hasActiveBpie &&
						<Allow policy={UserPolicy} policyEvent={policyEvents.DELETE}>
							<Button onClick={this.deleteUser}
							        label="Delete"
							        name="btnDelete"
							        buttonType={ButtonTypes.DELETE}
							        disabled={this.props.isLoading}/>
						</Allow>
					}
					{
						!this.props.location.pathname.endsWith("New") && this.state.user.status === STATUS.DELETED &&
						<Allow policy={UserPolicy} policyEvent={policyEvents.DELETE}>
							<Button onClick={this.reactivateUser}
							        label="Reactivate"
							        name="btnReactivate"
							        buttonType={ButtonTypes.REACTIVATE}
							        disabled={this.props.isLoading}/>
						</Allow>
					}
					<Button onClick={this.saveUser}
					        label={"Save"}
					        name="btnSaveUser"
					        buttonType={ButtonTypes.SUBMIT}
					        disabled={this.props.isLoading}/>

					<Button onClick={this.cancel}
					        label="Cancel"
					        buttonType={ButtonTypes.SYSTEM}
					        name="btnCancel"/>
				</ButtonBar>
			);

		const bpieUserWarningMessage = allow(UserPolicy, policyEvents.MODIFY_BEHAVIORS) && allow(BpiePolicy, policyEvents.bpie.schoolBpieAssessments.MODIFY)
			? " The information can be updated in the SBPIE Assessments summary."
			: " Please contact the district or tech support for more information.";

		return (
			<div>
				{buttonBar}
				{
					this.props.user.hasActiveBpie &&
					<p className={`page-directions-highlight`}>Basic information for this account is disabled since this is a School Administrator with an active School BPIE.
						{bpieUserWarningMessage}</p>
				}
				<UserProfileAccountForm
					errors={this.state.errors}
					institutions={this.props.institutions}
					isStateUser={this.state.isStateUser}
					onFormChange={this.onFormChange}
					user={this.state.user}
				/>
				{
					this.state.user.behaviors &&
					this.state.user.behaviors.length > 0 &&
					<Allow policy={UserPolicy} policyEvent={policyEvents.MODIFY_BEHAVIORS}>
						<UserProfileBehaviorsForm
							canModifyPrograms={allow(UserPolicy, policyEvents.MANAGE)}
							programsForFiscalAgent={this.state.programsForFiscalAgent}
							handleBehaviorUpdate={this.onBehaviorChange}
							hasActiveBpie={this.props.user.hasActiveBpie}
							isStateUser={this.state.isStateUser}
							onFormChange={this.onFormChange}
							selectedPrograms={this.state.selectedPrograms}
							user={this.state.user}
							userBehaviors={this.state.user.behaviors}
						/>
					</Allow>
				}
			</div>
		);
	}
}

UserProfilePage.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const institutionSelectListItems = state.sharedData.allInstitutions.map(
		institution => {
			return {
				value: institution.id,
				text: institution.name
			};
		}
	);
	return {
		user: Object.assign({}, state.user.selectedUser),
		isLoading: state.ajaxCallsInProgress > 0,
		institutions: institutionSelectListItems
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		userActions,
		institutionActions,
		layoutActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage);