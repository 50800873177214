import React from 'react';
import PropTypes from "prop-types";
import PrintSectionHeader from "../../../../components/common/PrintSectionHeader";
import {createResponseFormObject} from "../../gmsObjectFactory";
import {GmsApplicationElementWrapper} from "../GmsApplicationElementWrapper";

export const GmsApplicationPrintView = ({
                                               fiscalAgentName,
                                               element,
                                               reportTitle,
                                               selectedElements
                                           }) => {
    const selectedSections = element.elements?.filter(f => selectedElements.some(s => s === f.elementId));
    return (
        <div key={element.elementId} className={`theme-gms`}>
            <div className={`idea`}>
                {
                    selectedSections.map((el) => {
                        const responses = createResponseFormObject(el.elements);
                        return (
                            <div className={`pageBreak--after`} key={el.elementId}>
                                <PrintSectionHeader>
                                    <h1>{reportTitle}</h1>
                                    <h1>{fiscalAgentName}</h1>
                                </PrintSectionHeader>
                                <GmsApplicationElementWrapper
                                    element={el}
                                    isPrint
                                    parentId={element.elementId}
                                    responses={responses}
                                />
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};


GmsApplicationPrintView.propTypes = {
    fiscalAgentName: PropTypes.string.isRequired,
    element: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array
    ]).isRequired,
    reportTitle: PropTypes.string.isRequired,
    selectedElements: PropTypes.arrayOf(PropTypes.string).isRequired
};