import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import api from "../drDueProcessApi";
import DrDueProcessOverviewView from "./DrDueProcessOverviewView";
import * as drLocations from "../../drLocations";
import {
	generateArchivableSelectListFromArray,
	isArrayNullOrEmpty,
	isGuidEmpty,
	isObjectNullOrEmpty
} from "../../../components/common/commonUtilities";
import * as drDueProcessConstants from '../drDueProcessConstants';

const DrDueProcessOverviewContainer = ({
	                                       canEdit,
	                                       dateWindowId,
	                                       dueProcess,
	                                       executeApi,
	                                       handleDataChanged,
	                                       handleReturn,
										   handleSelectSection,
	                                       handleUpdateDueProcess,
	                                       history,
	                                       institutionId,
	                                       isDistrictUser,
										   teamMembers
                                       }) => {

	const [judges, setJudges] = useState([]);
	const readOnly = !canEdit || isDistrictUser;

	const handleSave = async (form) => {
		 if (!isArrayNullOrEmpty(form.actions) && form.dueProcessStatus !== drDueProcessConstants.DueProcessStatus.HearingFullyAdjudicated.id
			 	&& dueProcess.dueProcessStatus === drDueProcessConstants.DueProcessStatus.HearingFullyAdjudicated.id) {
			 if (!confirm("Changing the status will clear out any status specific data, including all final order data, timeline completion dates, and existing actions.\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
				 return;

			 if (!confirm("Final confirmation!  Are you sure you want to change the status and remove all of the status specific data?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
				 return;
		 }

		form.dateWindowId = dateWindowId;
		form.districtId = institutionId;

		await executeApi(api.saveDueProcess, [form])
			.then((result) => {
				handleUpdateDueProcess(result);
				if (form.id === drLocations.DUE_PROCESS_ID_NEW)
					history.push(drLocations.DUE_PROCESS_OVERVIEW.getUrl(dateWindowId, institutionId, result.id));
			});
	};

	const handleClickDelete = (event) => {
		event.preventDefault();

		if (!confirm("Are you sure you want to delete this due process hearing request?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
			return;

		executeApi(api.deleteDueProcess, [dueProcess.id])
			.then(handleReturn);
	};

	useEffect(() => {
		let isMounted = true;
		if (!isObjectNullOrEmpty(dueProcess))
			api.getJudges()
				.then((result) => {
						if (isMounted)
							setJudges(generateArchivableSelectListFromArray(result, dueProcess.alj))
					}
				);

		return () => {
			isMounted = false;
		};
	}, [dueProcess]);

	if (!dueProcess || teamMembers.length === 0) return null;

	return (
		<DrDueProcessOverviewView
			dueProcess={dueProcess}
			handleClickDelete={!isGuidEmpty(dueProcess.id) ? handleClickDelete : null}
			handleDataChanged={handleDataChanged}
			handleSave={handleSave}
			handleSelectSection={handleSelectSection}
			judges={judges}
			readOnly={readOnly}
			teamMembers={teamMembers}
		/>
	);
};

DrDueProcessOverviewContainer.propTypes = {
	canEdit: PropTypes.bool.isRequired,
	dateWindowId: PropTypes.string.isRequired,
	dueProcess: PropTypes.object.isRequired,
	executeApi: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleReturn: PropTypes.func.isRequired,
	handleSelectSection: PropTypes.func.isRequired,
	handleUpdateDueProcess: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	institutionId: PropTypes.string.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	teamMembers: PropTypes.arrayOf(PropTypes.object)
};

export default DrDueProcessOverviewContainer;
