import Scroll from "react-scroll";

const defaultScrollConfig = {
    duration: 500,
    delay: 50,
    smooth: true
};

export function handleScroll(containerId, scrollConfig) {
    window.setTimeout(() => {
        let element = document.getElementById(containerId);

        if(!element) {
            element = document.getElementById(`${containerId}_0`);
        }

        if(!element) {
            element = document.querySelector(`[id^="${containerId}"]`);
        }

        if(!element)
            return;

        Scroll.scroller.scrollTo(element.id, scrollConfig || defaultScrollConfig);
    }, 150);
}

export function handleScrollToTop(scrollConfig)  {
    window.setTimeout(() => {
        Scroll.animateScroll.scrollToTop(scrollConfig || defaultScrollConfig);
    }, 150);
}