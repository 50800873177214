import PropTypes from 'prop-types';
import React from "react";
import EditComponentButtons from "../../../components/common/EditComponentButtons";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import * as drObjectFactory from "../drDueProcessObjectFactory";

const DrDueProcessActionResponseEdit = ({
	                                        handleClickCancel,
	                                        handleDataChanged,
	                                        handleSaveResponseAsync,
	                                        response
                                        }) => {

	const responseForm = useFormInputObject(drObjectFactory.createResponseObject(response), () => handleDataChanged());

	const handleClickSave = async () => {
		const form = convertFormInputObjectToObject(responseForm);
		const result = await handleSaveResponseAsync(form);
		if (result) {
			handleClickCancel();
		}
	};

	return (
		<>
			<DateTimePickerField
				inputClass={`input--small`}
				label="Date Completed"
				name="dateCompleted"
				showLabel
				{...responseForm.dateCompleted}
			/>
			<TextAreaEditor
				label="Results"
				name="results"
				showLabel
				{...responseForm.results}
			/>
			<EditComponentButtons
				handleClickCancel={handleClickCancel}
				handleClickSave={handleClickSave}
			/>
		</>
	);
};

DrDueProcessActionResponseEdit.propTypes = {
	response: PropTypes.object,
	handleClickCancel: PropTypes.func,
	handleDataChanged: PropTypes.func,
	handleSaveResponseAsync: PropTypes.func
};

DrDueProcessActionResponseEdit.defaultProps = {
	type: React.Fragment.toString()
};

export default DrDueProcessActionResponseEdit;