import PropTypes from "prop-types";
import React from "react";
import AccountUtility from "../../../api/AccountUtility";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as gmsLocations from "../gmsLocations";
import * as ButtonTypes from "../../../constants/ButtonTypes";

export const GmsApplicationNavigation = ({
                                             applicationId,
                                             budgetId,
                                             canEditApplication = false,
                                             currentLocationForFastCancel: currentLocation,
                                             handleSave,
                                             history,
                                             isDirty,
                                             salaryId,
                                             sectionId,
                                         }) => {

    const isFiscalAgentUser = AccountUtility.isFiscalAgentUser();
    const isEditingApplication = sectionId && canEditApplication;
    const isViewingApplication = sectionId || budgetId || salaryId;
    const isViewingSalary = !!salaryId;

    const applicationUrl = gmsLocations.GMS_APPLICATION.getUrl(applicationId);
    const salariesUrl = gmsLocations.GMS_APPLICATION_SALARIES.getUrl(applicationId);
    const summaryUrl = gmsLocations.GMS_SUMMARY.path;
    const returnLocation = isViewingSalary ?  salariesUrl :
        isViewingApplication ? applicationUrl : summaryUrl;

    const handleNavigation = (location) => {
        history.push(location);
    };

    const handleClearChanges = () => currentLocation
        ? handleNavigation(currentLocation)
        : window.location.reload();

    const handleReturn = (event) => {
        event && event.preventDefault();
        handleNavigation(returnLocation);
    };

    const handleCancel = (event, isReturning = false) => {
        event && event.preventDefault();
        if (confirm("You have unsaved changes. Continue?")) {
            if (isReturning)
                handleReturn();
            else
                handleClearChanges();
        }
    };

    const handleSaveReturn = async (event) => {
        event && event.preventDefault();
        await handleSave()
            .then(result => result && handleReturn());
    };

    const handleCaseNotes = (event) => {
        event && event.preventDefault();
        if (!applicationId) return;

        handleNavigation(gmsLocations.GMS_ADMIN_CASE_NOTES.getUrl(applicationId));
    };

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={``}>
            {
                !isFiscalAgentUser && !isViewingApplication &&
                <Button
                    buttonType={ButtonTypes.SYSTEM}
                    label={`Case Notes`}
                    name={`btnCaseNotes`}
                    onClick={handleCaseNotes}
                />
            }
            {
                isEditingApplication &&
                <>
                    <Button
                        buttonType={ButtonTypes.SAVE}
                        disabled={!isDirty}
                        label={isDirty ? `Save` : `Saved`}
                        name={`btnSave`}
                        onClick={handleSave}
                    />
                    <Button
                        buttonType={ButtonTypes.BACK}
                        disabled={!isDirty || !handleSave}
                        label={`Save and Return`}
                        name={`btnSaveReturn`}
                        onClick={handleSaveReturn}
                    />
                    <Button
                        buttonType={ButtonTypes.CANCEL}
                        disabled={!isDirty}
                        label={`Cancel`}
                        name={`btnCancel`}
                        onClick={handleCancel}
                    />
                    <Button
                        buttonType={isDirty ? ButtonTypes.CANCEL : ButtonTypes.BACK}
                        label={isDirty ? `Cancel and Return` : `Return`}
                        name={`btnApplicationSummary`}
                        onClick={isDirty ? (e) => handleCancel(e,true) : handleReturn}
                    />
                </>
            }
            {
                !isEditingApplication &&
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={`Return`}
                    name={`btnApplicationSummary`}
                    onClick={handleReturn}
                />
            }
        </ButtonBar>
    );
};

GmsApplicationNavigation.propTypes = {
    applicationId: PropTypes.string.isRequired,
    budgetId: PropTypes.string,
    canEditApplication: PropTypes.bool,
    currentLocationForFastCancel: PropTypes.string,
    handleSave: PropTypes.func,
    history: PropTypes.object,
    isDirty: PropTypes.bool,
    salaryId: PropTypes.string,
    sectionId: PropTypes.string,
};
