import React, {useState} from 'react';
import PropTypes from "prop-types";
import FlexSpacer from "../../../../components/common/FlexSpacer";
import SearchableMultiSelectField from "../../../../components/common/inputs/SearchableMultiSelectField";
import {handleScroll} from "../../../../components/common/scrollUtilities";
import gmsApi from "../../gmsApi";
import GridTable from "../../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import {GmsApplicationBudgetTableLineItemEdit} from "./GmsApplicationBudgetTableLineItemEdit";
import {GmsApplicationBudgetTableLineItemDisplay} from "./GmsApplicationBudgetTableLineItemDisplay";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import Tile from "../../../../components/common/Tiles/Tile";
import {emptyGuid} from "../../../../constants/config";
import {createBudgetLineItem} from "../../gmsObjectFactory";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import {allow} from '../../../../components/authorization/AuthorizationUtilities';
import {GmsPolicy, policyEvents} from '../../../../components/authorization/policies/GmsPolicy';
import {getBudgetColumnsList, getBudgetColumnsSelected} from "./gmsApplicationBudgetUtilities";

export const GmsApplicationBudgetTable = ({
                                              actions,
                                              applicationId,
                                              budget,
                                              budgetId,
                                              canUserEditBudget,
                                              codes,
                                              editId,
                                              isAmendmentBudget,
                                              isEditing,
                                              loadApplicationBudget,
                                              newLineItemLabel,
                                              searchCriteria,
                                              setEditId
                                          }) => {
    const [editOnlyFeedback, setEditOnlyFeedback] = useState(false);
    const [isAmendmentDeleted, setIsAmendmentDeleted] = useState(false);

    const canViewFeedback = budget.canViewBudgetLineItemFeedback;
    const canEditFeedback = budget.canEditBudgetLineItemFeedback && allow(GmsPolicy, policyEvents.APPLICATION.modify);

    const handleDelete = (budgetLineItemId) => {
        const deleteItem = async () => {
            await actions.executeApi(gmsApi.deleteBudgetLineItem, [applicationId, budgetId, budgetLineItemId])
                .then(loadApplicationBudget);
        };

        const currentBudgetLineItemIndex = budget.budgetLineItems.findIndex(f => f.budgetLineItemId === budgetLineItemId);

        const isNotTheLastBudgetLineItem = currentBudgetLineItemIndex !== budget.budgetLineItems.length - 1;
        const previousBudgetLineItemId = isNotTheLastBudgetLineItem
            ? budget.budgetLineItems[currentBudgetLineItemIndex + 1].budgetLineItemId
            : emptyGuid;

        if (confirm("Deleting this budget item is permanent. Continue?"))
            deleteItem()
                .then(() => handleCancelEdit(getScrollId(previousBudgetLineItemId)));

    };

    const handleSave = (budgetLineItem) => {
        const saveItem = async () => {
            await actions.executeApi(gmsApi.saveBudgetLineItem, [applicationId, budgetId, budgetLineItem])
                .then(loadApplicationBudget);
        };

        saveItem()
            .then(() => handleCancelEdit(getScrollId(editId)));
    };

    const handleAdd = () => {
        setEditId(emptyGuid);
        setEditOnlyFeedback(false);
    };
    const handleCancelEdit = (scrollId) => {
        if (scrollId)
            handleScroll(scrollId);

        setEditId(null)
        setEditOnlyFeedback(false);
    };
    const handleEdit = (budgetLineItemId, isDeletedForAmendment = false) => {
        setEditId(budgetLineItemId)
        setEditOnlyFeedback(false);
        setIsAmendmentDeleted(isDeletedForAmendment);
    };
    const handleEditOnlyFeedback = (budgetLineItemId) => {
        setEditId(budgetLineItemId);
        setEditOnlyFeedback(true);
    };

    const handleExport = async () => {
        await actions.executeApi(gmsApi.exportBudget, [applicationId, budgetId]);
    };

    const handleImport = () => {
        const fuBudget = document.getElementById("fuBudget");
        fuBudget.click();
    };

    const clearFileSelection = () => {
        const fuBudget = document.getElementById("fuBudget");
        fuBudget.value = "";
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        const file = [...event.target.files];

        if (confirm("Are you sure you want to upload a budget? All current budget items will be deleted and replaced with the imported budget line items.")) {
            await actions.executeApi(gmsApi.importBudget, [file, applicationId, budgetId])
                .then(() => {
                    loadApplicationBudget();
                    clearFileSelection();
                });
        }
    };

    const handleChangeColumnSelect = (event) => {
        let criteria = {...searchCriteria};
        criteria.budgetColumnsDisplayed = event.target.value;
        actions.saveSearchCriteria(criteria);
    };

    const getScrollId = (id) => `bli-${id}`;

    const budgetColumns = getBudgetColumnsList(canViewFeedback, budget.hasAgreementAllocation, isAmendmentBudget);
    const budgetColumnsSelected = getBudgetColumnsSelected(searchCriteria.budgetColumnsDisplayed, canViewFeedback, budget.hasAgreementAllocation);
    let totalColumns = budgetColumnsSelected.length;
    if (canUserEditBudget) totalColumns++;

    return (
        <>
            <SearchableMultiSelectField
                label={`Select which columns to display`}
                name={`msfColumnSelect`}
                onChange={handleChangeColumnSelect}
                options={budgetColumns}
                showSelectAll
                value={budgetColumnsSelected}
            />

            <GridTable>
                <thead>
                <tr className={`small-column text-center`}>
                    {
                        budgetColumns.map(column => {
                            const isColumnIncluded = budgetColumnsSelected.some(f => f === column.id);
                            if (!isColumnIncluded)
                                return null;

                            return (
                                <th key={column.id}>{column.text}</th>
                            );
                        })
                    }
                    {
                        canUserEditBudget &&
                        <th>Actions</th>
                    }
                </tr>
                </thead>
                <tbody>
                {
                    budget?.budgetLineItems?.map((bli) => {
                        const {budgetLineItemId} = bli;
                        const budgetLineItem = createBudgetLineItem({...bli});
                        return editId === budgetLineItemId
                            ? <GmsApplicationBudgetTableLineItemEdit
                                budgetLineItem={budgetLineItem}
                                canEditFeedback={canEditFeedback}
                                canViewFeedback={canViewFeedback}
                                codes={codes}
                                editOnlyFeedback={editOnlyFeedback}
                                handleCancelEdit={handleCancelEdit}
                                handleSave={handleSave}
                                hasAgreementAllocation={budget.hasAgreementAllocation}
                                isAmendmentBudget={isAmendmentBudget}
                                isAmendmentDeleted={isAmendmentDeleted}
                                totalColumns={totalColumns}
                                key={budgetLineItemId}
                            />
                            : <GmsApplicationBudgetTableLineItemDisplay
                                budgetColumnsSelected={budgetColumnsSelected}
                                budgetLineItem={budgetLineItem}
                                canEditFeedback={canEditFeedback}
                                canUserEditBudget={canUserEditBudget}
                                canViewFeedback={canViewFeedback}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleEditOnlyFeedback={handleEditOnlyFeedback}
                                isAmendmentBudget={isAmendmentBudget}
                                isEditing={isEditing}
                                scrollId={getScrollId(budgetLineItemId)}
                                key={budgetLineItemId}
                            />;

                    })
                }
                {
                    editId === emptyGuid && canUserEditBudget &&
                    <GmsApplicationBudgetTableLineItemEdit
                        budgetLineItem={createBudgetLineItem({
                            budgetId,
                            isAmendmentLineItem: isAmendmentBudget
                        })}
                        canEditFeedback={canEditFeedback}
                        canViewFeedback={canViewFeedback}
                        codes={codes}
                        editOnlyFeedback={editOnlyFeedback}
                        handleCancelEdit={handleCancelEdit}
                        handleSave={handleSave}
                        hasAgreementAllocation={budget.hasAgreementAllocation}
                        isAmendmentBudget={isAmendmentBudget}
                        isAmendmentDeleted={false}
                        totalColumns={totalColumns}
                    />
                }
                {
                    isArrayNullOrEmpty(budget.budgetLineItems) && !editId &&
                    <tr>
                        <td colSpan={totalColumns} className={`text-center`}>
                            No Data
                        </td>
                    </tr>
                }
                </tbody>
                <tfoot>
                <tr id={getScrollId(emptyGuid)}>
                    <td colSpan={totalColumns}>
                        <TileContainer rowTilesMedium={"3"} rowTilesLarge={"3"}>
                            {
                                canUserEditBudget &&
                                <Tile onClick={handleImport}
                                      body={"Import Budget"}
                                      isDisabled={!!editId || !budget.canImportBudget}
                                      isMedium
                                      footer={!budget.canImportBudget ? "Not available." : ""}
                                />
                            }

                            {
                                canUserEditBudget &&
                                <Tile onClick={handleAdd}
                                      body={newLineItemLabel}
                                      isDisabled={!!editId}
                                      isMedium
                                      isNew
                                />
                            }
                            {
                                !canUserEditBudget &&
                                <FlexSpacer/>
                            }
                            <Tile onClick={handleExport}
                                  body={"Export Budget"}
                                  isDisabled={!!editId}
                                  isMedium
                            />
                        </TileContainer>
                    </td>
                </tr>
                </tfoot>
            </GridTable>
            <FileUploadButton
                className={`is-hidden`}
                disabled={!!editId || !canUserEditBudget}
                label={"File Upload"}
                name={"fuBudget"}
                onChange={handleUpload}
            />
        </>
    );
};


GmsApplicationBudgetTable.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    budget: PropTypes.object.isRequired,
    budgetId: PropTypes.string.isRequired,
    canUserEditBudget: PropTypes.bool.isRequired,
    codes: PropTypes.object.isRequired,
    editId: PropTypes.string,
    isAmendmentBudget: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    loadApplicationBudget: PropTypes.func.isRequired,
    newLineItemLabel: PropTypes.string.isRequired,
    searchCriteria: PropTypes.object,
    setEditId: PropTypes.func.isRequired
};