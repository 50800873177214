import React from "react";
import PropTypes from 'prop-types';
import CheckBoxField from "../common/inputs/CheckBoxField";

const MatrixOfServiceCheckBoxAdapter = ({name, index, value, label, onResponseChange, isChecked, protocolId, isEditing, studentRecordId, overridenStateResponse}) => {
    const outputStatus = (isChecked) ? "Yes" : "No";
    return (
        <>
            {
                isEditing &&
                <CheckBoxField
                    name={`cb_${name}_${index}_${studentRecordId}`}
                    label={label}
                    showLabel={false}
                    checked={isChecked}
                    onChange={(event) => onResponseChange(event, protocolId, name, studentRecordId)}
                    value={value}
                />
            }
            {
                !isEditing &&
                <div>
                    {outputStatus}{overridenStateResponse}
                </div>
            }
        </>
    );
};

MatrixOfServiceCheckBoxAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool.isRequired,
    protocolId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    studentRecordId: PropTypes.string.isRequired,
    overridenStateResponse: PropTypes.string.isRequired
};

export default MatrixOfServiceCheckBoxAdapter;