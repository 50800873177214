import * as actionTypes from "../constants/actionTypes";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import MonitoringComplianceCaseNoteApi from "../api/MonitoringComplianceCaseNoteApi";

export function saveComplianceCaseNoteSuccess(){
    return { type: actionTypes.SAVE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS };
}

export function saveComplianceCaseNote(monitoringAssessmentId, complianceCaseNote, isNew){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceCaseNoteApi.saveComplianceCaseNote(monitoringAssessmentId, complianceCaseNote, isNew)
            .then(() => {
                dispatch(saveComplianceCaseNoteSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function deleteComplianceCaseNoteSuccess(caseNoteId){
    return { type: actionTypes.DELETE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS, data: {caseNoteId} };
}

export function deleteComplianceCaseNote(monitoringAssessmentId, caseNoteId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceCaseNoteApi.deleteComplianceCaseNote(monitoringAssessmentId, caseNoteId)
            .then(() => {
                dispatch(deleteComplianceCaseNoteSuccess(caseNoteId));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function exportComplianceCaseNotesSuccess(){
    return { type: actionTypes.EXPORT_COMPLIANCE_CASE_NOTES_AJAX_SUCCESS };
}

export function exportComplianceCaseNotes(monitoringAssessmentId, caseNoteFilter){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceCaseNoteApi.exportComplianceCaseNotes(monitoringAssessmentId, caseNoteFilter)
            .then(() => {
                dispatch(exportComplianceCaseNotesSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}
