import MonitoringPerformanceApi from "../api/MonitoringPerformanceApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function loadDistrictStatusesSuccess(districtStatuses){
	return { type: actionTypes.LOAD_MONITORING_PERFORMANCE_DISTRICT_STATUSES_AJAX_SUCCESS, data: districtStatuses };
}

export function loadDistrictStatuses(){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.loadMonitoringPerformanceStatuses()
			.then((data) => {
				dispatch(loadDistrictStatusesSuccess(data));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function loadPerformanceMonitoringAssessmentSuccess(assessment, allDistricts){
	if (allDistricts)
		assessment.districtName = allDistricts.filter(d => d.value === assessment.districtId)[0].text;
	return { type: actionTypes.LOAD_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS, data: assessment };
}

export function loadPerformanceMonitoringAssessment(assessmentId, allDistricts){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.loadMonitoringPerformanceAssessment(assessmentId)
			.then((data) => {
				dispatch(loadPerformanceMonitoringAssessmentSuccess(data, allDistricts));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function createPerformanceMonitoringAssessmentSuccess(assessment) {
	return { type: actionTypes.CREATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS, data: assessment };
}

export function createPerformanceMonitoringAssessment(assessment){
	return dispatch => {
		dispatch(beginAjaxCall());
		return MonitoringPerformanceApi.createMonitoringPerformanceAssessment(assessment)
			.then((newId) => {
				dispatch(createPerformanceMonitoringAssessmentSuccess(assessment));
				return newId;
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				return undefined;
			});
	};
}

export function updatePerformanceMonitoringAssessmentSuccess(assessment) {
	return { type: actionTypes.UPDATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS, data: assessment };
}

export function updatePerformanceMonitoringAssessment(assessment){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.updateMonitoringPerformanceAssessment(assessment)
			.then((data) => {
				dispatch(updatePerformanceMonitoringAssessmentSuccess(data));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function cancelMonitoringPerformanceAssessmentDetails(){
	return { type: actionTypes.CANCEL_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS };
}

export function editMonitoringPerformanceAssessmentDetails(assessmentId){
	return { type: actionTypes.EDIT_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS, data: assessmentId };
}

export function changeMonitoringPerformanceAssessmentDetails(assessment){
	return { type: actionTypes.CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS, data: assessment };
}

export function setSelectedAssessmentId(assessmentId){
	return { type: actionTypes.SET_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT_ID, data: assessmentId };
}

export function clearSelectedAssessment(){
	return { type: actionTypes.CLEAR_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT };
}

export function editAssessmentIndicator(indicatorAssessmentId){
	return { type: actionTypes.EDIT_MONITORING_ASSESSMENT_INDICATOR, data: indicatorAssessmentId };
}

export function addAssessmentIndicator(){
	return { type: actionTypes.ADD_MONITORING_ASSESSMENT_INDICATOR };
}

export function cancelAddAssessmentIndicator(){
	return { type: actionTypes.CANCEL_ADD_MONITORING_ASSESSMENT_INDICATOR };
}

export function createAssessmentIndicatorSuccess(indicatorAssessment, newId){
	return { type: actionTypes.CREATE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS, data: {indicatorAssessment, newId} };
}

export function createAssessmentIndicator(assessmentIndicator){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.createAssessmentIndicator(assessmentIndicator)
			.then((newId) => {
				dispatch(createAssessmentIndicatorSuccess(assessmentIndicator, newId));
				return true;
			})
			.catch(() => {
				dispatch(ajaxCallError());
				return false;
			});
	};
}

export function deleteAssessmentIndicatorSuccess(assessmentId, indicatorAssessmentId, indicatorActions){
	return { type: actionTypes.DELETE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS, data: {assessmentId, indicatorAssessmentId, indicatorActions} };
}

export function deleteAssessmentIndicator(assessmentId, indicatorAssessmentId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.deleteAssessmentIndicator(assessmentId, indicatorAssessmentId)
			.then(indicatorActions => {
				dispatch(deleteAssessmentIndicatorSuccess(assessmentId, indicatorAssessmentId, indicatorActions));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function loadAssessmentIndicatorActionsSuccess(indicatorAssessmentId, indicatorActions){
	return { type: actionTypes.LOAD_MONITORING_ASSESSMENT_INDICATOR_ACTIONS_AJAX_SUCCESS, data: {indicatorAssessmentId, indicatorActions}};
}

export function loadAssessmentIndicatorActions(assessmentId, indicatorAssessmentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.loadAssessmentIndicatorActions(assessmentId, indicatorAssessmentId)
			.then((actions) => {
				dispatch(loadAssessmentIndicatorActionsSuccess(indicatorAssessmentId, actions));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function deleteAssessmentIndicatorActionSuccess(assessmentId, indicatorAssessmentId, indicatorAssessmentActionId){
	return { type: actionTypes.DELETE_MONITORING_ASSESSMENT_INDICATOR_ACTION_AJAX_SUCCESS, data: {assessmentId, indicatorAssessmentId, indicatorAssessmentActionId}};
}

export function deleteAssessmentIndicatorAction(assessmentId, indicatorAssessmentId, indicatorAssessmentActionId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.deleteAssessmentIndicatorAction(assessmentId, indicatorAssessmentId, indicatorAssessmentActionId)
			.then(() => {
				dispatch(deleteAssessmentIndicatorActionSuccess(assessmentId, indicatorAssessmentId, indicatorAssessmentActionId));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function addIndicatorAction(indicatorAssessmentId, newAction){
	return { type: actionTypes.ADD_INDICATOR_ACTION, data: { indicatorAssessmentId, newAction }};
}

export function changeIndicatorAction(changedAction){
	return { type: actionTypes.CHANGE_INDICATOR_ACTION, data: changedAction };
}

export function cancelAddAction(){
	return { type: actionTypes.CANCEL_ADD_INDICATOR_ACTION };
}

export function createIndicatorActionSuccess(){
	return { type: actionTypes.SAVE_INDICATOR_ACTION_AJAX_SUCCESS };
}

export function createIndicatorAction(indicatorActionDto){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.createIndicatorAction(indicatorActionDto)
			.then(() => {
				dispatch(createIndicatorActionSuccess());
				return true;
			})
			.catch(() => {
				dispatch(ajaxCallError());
				return false;
			});
	};
}

export function updateIndicatorActionSuccess(){
	return { type: actionTypes.UPDATE_INDICATOR_ACTION_AJAX_SUCCESS };
}

export function updateIndicatorAction(indicatorActionDto){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.updateIndicatorAction(indicatorActionDto)
			.then(() => {
				dispatch(updateIndicatorActionSuccess());
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function editIndicatorAction(actionId){
	return { type: actionTypes.EDIT_INDICATOR_ACTION, data: actionId };
}

export function createIndicatorActionResponseSuccess(){
	return { type: actionTypes.CREATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS };
}

export function createIndicatorActionResponse(response){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.createIndicatorActionResponse(response)
			.then(() => {
				dispatch(createIndicatorActionResponseSuccess());
				return true;
			})
			.catch(() => {
				dispatch(ajaxCallError());
				return false;
			});
	};
}

export function updateIndicatorActionResponseSuccess(){
	return { type: actionTypes.UPDATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS };
}

export function updateIndicatorActionResponse(response){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.updateIndicatorActionResponse(response)
			.then(() => {
				dispatch(updateIndicatorActionResponseSuccess());
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}


export function addIndicatorActionResponse(actionResponse){
	return { type: actionTypes.ADD_INDICATOR_ACTION_RESPONSE, data: actionResponse };
}

export function loadIndicatorActionResponseSuccess(response){
	return { type: actionTypes.LOAD_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS, data: response };
}

export function loadIndicatorActionResponse(assessmentId, indicatorId, actionId, responseId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.loadIndicatorActionResponse(assessmentId, indicatorId, actionId, responseId)
			.then((data) => {
				dispatch(loadIndicatorActionResponseSuccess(data));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function editIndicatorActionResponse(responseId){
	return { type: actionTypes.EDIT_INDICATOR_ACTION_RESPONSE, data: responseId };
}

export function changeActionResponse(actionResponse){
	return { type: actionTypes.CHANGE_INDICATOR_ACTION_RESPONSE, data: actionResponse };
}

export function cancelEditIndicatorActionResponse(){
	return { type: actionTypes.CANCEL_ADD_INDICATOR_ACTION_RESPONSE };
}

export function changeDistrictAccess(allowAccess){
	return { type: actionTypes.CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DISTRICT_ACCESS, data: allowAccess };
}

export function updateDistrictAccessSuccess(assessmentId, allowAccess){
	return { type: actionTypes.UPDATE_MONITORING_PERFORMANCE_DISTRICT_ACCESS_AJAX_SUCCESS, data: { assessmentId, allowAccess }};
}

export function updateDistrictAccess(assessmentId, allowAccess){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.updateDistrictAccess(assessmentId, allowAccess)
			.then(() => {
				dispatch(updateDistrictAccessSuccess(assessmentId, allowAccess));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function submitActionResponseSuccess(assessmentId, indicatorId, actionId){
	return { type: actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_SUBMIT_AJAX_SUCCESS, data: {assessmentId, indicatorId, actionId} };
}

export function submitActionResponse(assessmentId, indicatorId, actionId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.submitDistrictActionResponse(assessmentId, indicatorId, actionId)
			.then(() => {
				dispatch(submitActionResponseSuccess(assessmentId, indicatorId, actionId));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function rejectActionResponseSuccess(outcomeDto){
	return { type: actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_REJECT_AJAX_SUCCESS, data: outcomeDto };
}

export function rejectActionResponse(outcomeDto){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.rejectDistrictActionResponse(outcomeDto)
			.then(() => {
				dispatch(rejectActionResponseSuccess(outcomeDto));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function approveActionResponseSuccess(outcomeDto){
	return { type: actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_APPROVE_AJAX_SUCCESS, data: outcomeDto };
}

export function approveActionResponse(outcomeDto){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.approveDistrictActionResponse(outcomeDto)
			.then(() => {
				dispatch(approveActionResponseSuccess(outcomeDto));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function resetActionResponseSuccess(outcomeDto){
	return { type: actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_RESET_AJAX_SUCCESS, data: outcomeDto };
}

export function resetActionResponse(outcomeDto){
	return dispatch => {
		dispatch(beginAjaxCall());

		return MonitoringPerformanceApi.resetDistrictActionResponse(outcomeDto)
			.then(() => {
				dispatch(resetActionResponseSuccess(outcomeDto));
			})
			.catch(() => {
				dispatch(ajaxCallError());
			});
	};
}

export function createComment(auditId, actionId){
	return { type: actionTypes.CREATE_PERFORMANCE_AUDIT_COMMENT, data: { auditId, actionId}};
}

export function editComment(auditCommentId){
	return { type: actionTypes.EDIT_PERFORMANCE_AUDIT_COMMENT, data: auditCommentId };
}

export function cancelComment(){
	return { type: actionTypes.CANCEL_PERFORMANCE_AUDIT_COMMENT };
}

export function changeComment(comment, actionId){
	return { type: actionTypes.CHANGE_PERFORMANCE_AUDIT_COMMENT, data: { comment, actionId } };
}

export function addAuditCommentSuccess(auditCommentViewModel, actionId, auditCommentId){
	return { type: actionTypes.ADD_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS, data: { comment: auditCommentViewModel, actionId, auditCommentId } };
}

export function addAuditComment(auditCommentViewModel, actionId, assessmentId, indicatorId){
	return dispatch => {
		dispatch(beginAjaxCall());
		return MonitoringPerformanceApi.ResponseAuditComment(auditCommentViewModel, true, assessmentId, indicatorId, actionId)
			.then((auditCommentId) => {
				dispatch(addAuditCommentSuccess(auditCommentViewModel, actionId, auditCommentId));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function updateAuditCommentSuccess(){
	return { type: actionTypes.UPDATE_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS };
}

export function updateAuditComment(auditCommentViewModel, assessmentId, indicatorId, actionId){
	return dispatch => {
		dispatch(beginAjaxCall());
		return MonitoringPerformanceApi.ResponseAuditComment(auditCommentViewModel, false, assessmentId, indicatorId, actionId)
			.then(() => {
				dispatch(updateAuditCommentSuccess());
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}