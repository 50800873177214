import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import FlexSpacer from "../../components/common/FlexSpacer";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/common/Loader";
import Header from "../../components/layout/Header";
import {themeConstants} from "../../constants/commonStyleConstants";
import SystemMessage from "./SystemMessage";

class LoginLayout extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {component: Component, exact, path, isLoading} = this.props;

        const pageTitle = {
            headerLeft: "General Supervision"
        };

        return (
            <>
                <SystemMessage/>
                <Route {...exact} {...path} render={componentProps => (
                    <div className={`theme-${themeConstants.LOGIN}`}>
                        <div className="l-page-container">

                            <FlexSpacer/>

                            <div role="banner">
                                <Loader isLoading={isLoading} />
                                <div className="logo"/>
                                <Header pageTitle={pageTitle}/>
                            </div>

                            <Component
                                {...componentProps}
                                {...this.props}
                            />

                            <FlexSpacer/>

                            <Footer/>
                        </div>
                    </div>
                )}/>
            </>
        );
    }
}

LoginLayout.propTypes = {
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    isLoading: PropTypes.bool,
    path: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        isLoading: state.ajaxCallsInProgress > 0
    };
}

export default connect(mapStateToProps)(LoginLayout);