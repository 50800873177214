import React from "react";
import PropTypes from 'prop-types';
import audit_outcomes from "../../../constants/auditOutcomes";
import {getNeedsRevisionAuditComment} from "../../common/commonUtilities";
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import GridRow from "../../common/GridRow";
import GridColumn from "../../common/GridColumn";
import {convertBooleanToYesNoText, outputStandardDateFormat} from "../../shared/sharedDataUtilities";
import Allow from "../../authorization/Allow";
import {MonitoringCompliancePolicy, policyEvents} from "../../authorization/policies/MonitoringCompliancePolicy";
import AuditBar from "../../common/buttons/AuditBar";
import * as ActionBarPositions from "../../../constants/ActionBarPositions";
import {corrected} from "../../../constants/monitoringCompliance/correctionResults";
import AuditHistory from "../../audit/AuditHistory";
import * as AuthorizationUtilities from "../../authorization/AuthorizationUtilities";
import {monitoring_compliance} from "../../../constants/policyEvents";

const ResultsGrid = (
    {
        correctionResults,
        onProtocolCorrectiveResultOutcomeChange,
        isLoading,
        idPropName,
        mainAreaWidthProps,
        onCommentCreate,
        isCommentInEditMode,
        handleCommentChange,
        onCommentEdit,
        onCommentSave,
        onCommentCancel,
        editCommentItemId
    }) =>
{
    const canAudit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.AUDIT);
    return (
        <tbody>
        {
            correctionResults.map((result, index) => {
                const isCorrected = result.corrected && result.corrected.toString() === corrected.Yes;
                let outcomeDto = {
                    monitoringAssessmentId: result.monitoringAssessmentId
                };
                outcomeDto[idPropName] = result[idPropName];
                return (
                    <tr key={`sr_${index}`}>
                        <td>
                            {
                                isCorrected &&
                                result.status !== audit_outcomes.IN_PROGRESS.value &&
                                <Allow policy={MonitoringCompliancePolicy}
                                       policyEvent={policyEvents.monitoring_compliance.studentCorrections.AUDIT}>
                                    <AuditBar
                                        elementId={result[idPropName]}
                                        isExpanded={true}
                                        outcome={result.status}
                                        onOutcomeChange={(event) => onProtocolCorrectiveResultOutcomeChange(event, outcomeDto)}
                                        position={ActionBarPositions.RIGHT}
                                        policy={MonitoringCompliancePolicy}
                                        disabled={isLoading}
                                    />
                                </Allow>
                            }
                            <GridRow>
                                <GridColumn columnClass={"correction-info"}>
                                {
                                    result.publicIdentifier &&
                                    <GridRow>
                                        <GridColumn medium={"1"}>
                                            {result.publicIdentifier}
                                        </GridColumn>
                                        <GridColumn medium={"11"}>
                                            <HtmlDisplayBlock html={result.standardDescription + " " + result.citation} />
                                        </GridColumn>
                                    </GridRow>
                                }
                                {
                                    result.reportedCostFactor &&
                                    <GridRow>
                                        <GridColumn medium={"4"}>
                                            Reported Cost Factor: {result.reportedCostFactor}
                                        </GridColumn>
                                        <GridColumn medium={"4"}>
                                            Reviewed Cost Factor: {result.reviewedCostFactor}
                                        </GridColumn>
                                    </GridRow>
                                }
                                </GridColumn>

                                <GridColumn {...mainAreaWidthProps}>
                                    {
                                        result.status === audit_outcomes.NEEDS_REVISION.value &&
                                        <div className={`highlight-needsRevision`}>
                                            <p><strong>Feedback:</strong></p>
                                            <HtmlDisplayBlock html={getNeedsRevisionAuditComment(result.audits)} />
                                        </div>
                                    }
                                    <p><strong>Corrected:</strong> {convertBooleanToYesNoText(isCorrected)}</p>
                                    {
                                        isCorrected &&
                                        <div>
                                            <p>
                                                <strong>Date Corrected:</strong> {outputStandardDateFormat(result.dateCorrected, false)}
                                            </p>

                                            <p><strong>Corrections</strong></p>
                                            {
                                                result.corrections.length > 0 &&
                                                <ul>
                                                    {
                                                        result.corrections.map((correction, index) => {
                                                            return (
                                                                <li key={`result_corrections_${result[idPropName]}_${index}`}>
                                                                    <HtmlDisplayBlock html={correction}/></li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            }
                                        </div>
                                    }
                                </GridColumn>
                            </GridRow>

                            {
                                !canAudit &&
                                <AuditHistory
                                    audits={result.audits}
                                    isLoading={isLoading}
                                />
                            }
                            {
                                canAudit &&
                                <AuditHistory
                                    audits={result.audits}
                                    onCommentCancel={onCommentCancel}
                                    onCommentSave={(event, comment) => onCommentSave(event, comment, result[idPropName], result.monitoringAssessmentId)}
                                    onCommentEdit={onCommentEdit}
                                    handleCommentChange={handleCommentChange}
                                    editItemId={editCommentItemId}
                                    isInEditMode={isCommentInEditMode}
                                    isLoading={isLoading}
                                    onCommentCreate={onCommentCreate}
                                    parentIdPropName={idPropName}
                                />
                            }
                        </td>
                    </tr>
                );
            })
        }
        </tbody>
    );
};

ResultsGrid.propTypes = {
    correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onProtocolCorrectiveResultOutcomeChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    idPropName: PropTypes.string.isRequired,
    mainAreaWidthProps: PropTypes.object.isRequired,
    onCommentCreate: PropTypes.func.isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    onCommentEdit: PropTypes.func.isRequired,
    onCommentSave: PropTypes.func.isRequired,
    onCommentCancel: PropTypes.func.isRequired,
    editCommentItemId: PropTypes.string.isRequired
};

export default ResultsGrid;