import React from 'react';
import PropTypes from "prop-types";
import HtmlDisplay from "../../../../components/htmlDisplay/HtmlDisplay";
import {
    CheckBoxField,
    CheckBoxList,
    DollarField,
    FileManagementField,
    NumberField,
    PercentField,
    RadioField,
    SelectField,
    TextArea,
    TextField,
} from "../../../../components/common/inputs";
import {ELEMENT_TYPE, FORM_TYPE} from "../../gmsConstants";
import {getApplicationFormFileId} from "../../gmsUtilities";
import GmsApplicationFormAuditHistorySection from "./GmsApplicationFormAuditHistorySection";
import {GmsApplicationFormElementDoe100} from "./GmsApplicationFormElementDoe100";
import {GmsApplicationFormElementDoe150} from "./GmsApplicationFormElementDoe150";
import {GmsApplicationElementWrapper} from "../GmsApplicationElementWrapper";

export const GmsApplicationFormElement = (props) => {
    const {
        actions,
        applicationForms,
        applicationHistories,
        applicationId,
        canUserEditApplication,
        element,
        isCalculated,
        isChecked,
        isDisabled,
        isDirty,
        isFiscalAgentUser,
        nextAmendmentNumber,
        options,
        parentId = "",
        programName,
        response,
        sectionId,
        selectedApplicationHistory,
        setSection,
        setSelectedApplicationHistory,
        targetedAllocation,
    } = props;

    const handleClickDownload = (fileId) => actions.downloadFile(fileId);

    return (
        <>
            {
                element.elementType === ELEMENT_TYPE.display &&
                <HtmlDisplay html={element.elementText} name={element.elementId}/>
            }
            {
                element.elementType === ELEMENT_TYPE.textbox &&
                <TextField disabled={!canUserEditApplication || isDisabled} showLabel label={element.elementText} {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.textarea &&
                <TextArea disabled={!canUserEditApplication || isDisabled} showLabel label={element.elementText} {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.dropdown &&
                <SelectField disabled={!canUserEditApplication || isDisabled} showLabel label={element.elementText}
                             options={element.options}  {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.radio &&
                <RadioField disabled={!canUserEditApplication || isDisabled} showLegend legend={element.elementText}
                            options={options} {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.checkbox &&
                <CheckBoxField
                    {...response}
                    checked={isChecked}
                    describedBy={parentId}
                    disabled={!canUserEditApplication || isDisabled}
                    label={element.elementText}
                    name={element.elementId}
                    showLabel
                    value={isChecked ? `` : `true`}
                />
            }
            {
                element.elementType === ELEMENT_TYPE.section &&
                <>
                    <HtmlDisplay html={`<h2>${element.elementText}</h2>`} name={element.elementId}/>
                    {
                        element.elements?.map((el) => {
                            return <GmsApplicationElementWrapper {...props} element={el} key={el.elementId}
                                                                 isPrint={false} parentId={element.elementId}/>;
                        })
                    }
                </>
            }
            {
                element.elementType === ELEMENT_TYPE.checkboxList &&
                <CheckBoxList showLegend legend={element.elementText} listHasTextField={[`Other`]} options={options}
                              {...response} disabled={!canUserEditApplication || isDisabled}/>
            }
            {
                element.elementType === ELEMENT_TYPE.wholeNumberTextbox &&
                <NumberField disabled={!canUserEditApplication || isDisabled} isPositiveNumberOnly isWholeNumberOnly
                             label={element.elementText} readonly={isCalculated} showLabel {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.wholeDollarTextbox &&
                <DollarField disabled={!canUserEditApplication || isDisabled} isPositiveNumberOnly isWholeNumberOnly
                             label={element.elementText} readonly={isCalculated} showLabel {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.dollarTextbox &&
                <DollarField disabled={!canUserEditApplication || isDisabled} isPositiveNumberOnly label={element.elementText}
                             readonly={isCalculated} showLabel {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.percentTextbox &&
                <PercentField disabled={!canUserEditApplication || isDisabled} isPositiveNumberOnly label={element.elementText}
                              readonly={isCalculated} showLabel {...response} />
            }
            {
                element.elementType === ELEMENT_TYPE.file &&
                <FileManagementField canUpload={canUserEditApplication && !isDisabled} handleClickDownload={handleClickDownload}
                                     legend={element.elementText} name={element.elementId} showLegend {...response}/>
            }
            {
                element.elementType === ELEMENT_TYPE.doe100 &&
                <GmsApplicationFormElementDoe100
                    actions={actions}
                    applicationHistories={applicationHistories}
                    applicationId={applicationId}
                    canEditApplication={canUserEditApplication}
                    initialSignedDoe100FileId={getApplicationFormFileId(applicationForms, FORM_TYPE.doe100)}
                    isDirty={isDirty}
                    isFiscalAgentUser={isFiscalAgentUser}
                    programName={programName}
                    response={response}
                    selectedApplicationHistory={selectedApplicationHistory}
                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                    targetedAllocation={targetedAllocation}
                />
            }
            {
                element.elementType === ELEMENT_TYPE.doe150 &&
                <GmsApplicationFormElementDoe150
                    actions={actions}
                    applicationHistories={applicationHistories}
                    applicationId={applicationId}
                    canEditApplication={canUserEditApplication}
                    initialSignedDoe150FileId={getApplicationFormFileId(applicationForms, FORM_TYPE.doe150)}
                    isDirty={isDirty}
                    isFiscalAgentUser={isFiscalAgentUser}
                    nextAmendmentNumber={nextAmendmentNumber}
                    response={response}
                    selectedApplicationHistory={selectedApplicationHistory}
                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                />
            }
            {
                element.elementType !== ELEMENT_TYPE.doe100 && element.elementType !== ELEMENT_TYPE.doe150 &&
                <GmsApplicationFormAuditHistorySection
                    applicationId={applicationId}
                    actions={actions}
                    audits={element.applicationSectionAudits}
                    elementId={element.elementId}
                    isFiscalAgentUser={isFiscalAgentUser}
                    sectionId={sectionId}
                    setSection={setSection}
                />
            }
        </>
    );
};


GmsApplicationFormElement.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationForms: PropTypes.array.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    canUserEditApplication: PropTypes.bool,
    element: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    isCalculated: PropTypes.bool,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool.isRequired,
    isDirty: PropTypes.bool.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    nextAmendmentNumber: PropTypes.number,
    options: PropTypes.array,
    parentId: PropTypes.string,
    programName: PropTypes.string,
    response: PropTypes.object.isRequired,
    responses: PropTypes.object.isRequired,
    sectionId: PropTypes.string.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSection: PropTypes.func.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
    targetedAllocation: PropTypes.number,
};