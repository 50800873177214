import {objectHasProperty} from "../../../../components/common/commonUtilities";
import {ELEMENT_TYPE} from "../../gmsConstants";
import {BUDGET_COLUMNS, BUDGET_COLUMNS_LIST, BUDGET_TYPES} from "./gmsApplicationBudgetConstants";

export function getBudgetColumnsList(canViewFeedback, hasAgreementAllocation, isAmendmentBudget) {
    return BUDGET_COLUMNS_LIST.filter(f => (canViewFeedback || f.id !== BUDGET_COLUMNS.feedback.id)
    && (hasAgreementAllocation || f.id !== BUDGET_COLUMNS.agreement.id)
    && (isAmendmentBudget || f.id !== BUDGET_COLUMNS.amendmentComments.id));
}

export function getBudgetColumnsSelected(budgetColumnsSelected, canViewFeedback, hasAgreementAllocation) {
    const filteredSelection = budgetColumnsSelected.filter(id => canViewFeedback || id !== BUDGET_COLUMNS.feedback.id);
    return filteredSelection.filter(id => hasAgreementAllocation || id !== BUDGET_COLUMNS.agreement.id);
}

export function containsBudgetColumn(budgetColumnsSelected, column) {
    return budgetColumnsSelected.some(s => s === column.id);
}

export function getTotalCharactersForReadMore(budgetColumnsSelected) { //convert using 60 characters/line
    const minReadMoreCharacters = 60;
    const maxReadMoreCharacters = 240;
    const minColumnsNeeded = 5;
    return budgetColumnsSelected.length <= minColumnsNeeded ? maxReadMoreCharacters : minReadMoreCharacters;
}

export function convertElementTypeToBudgetType(type) {
    return type === ELEMENT_TYPE.doe101Section
        ? BUDGET_TYPES.applicationBudget.id
        : BUDGET_TYPES.amendmentBudget.id;
}

export function isBudgetElementDisabled(element, currentStatuses) {
    for (let property in element) {
        if (objectHasProperty(element, property) && objectHasProperty(currentStatuses, property)) {
            if(element[property] && currentStatuses[property])
                return true;
        }
    }

    return false;
}