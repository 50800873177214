import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import BpieComponentView from "../../Assessment/AssessmentEdit/BpieComponentView";
import {convertToNumber, isNullOrUndefined} from "../../../../components/common/commonUtilities";
import bpieDistrictApi from "../bpieDistrictApi";

const DistrictAssessmentContainer = ({
                                         actions,
                                         districtBpie,
                                         handleAssessmentRedirect,
                                         handleDataChanged,
                                         indicatorNumber,
                                         isDistrictUser,
                                         isEditable
                                     }) => {
    const convertedIndicator = convertToNumber(indicatorNumber);

    const handleSaveResponseAndRedirect = (response, bpieIndicatorId, desiredIndicator) => {
        if (isEditable)
            actions.executeApi(
                bpieDistrictApi.saveDistrictResponseBpie, [districtBpie.districtId, districtBpie.districtBpieId, bpieIndicatorId, response])
                .then(() => {
                    handleDataChanged(false);
                    handleAssessmentRedirect(desiredIndicator);
                });
        else
            handleAssessmentRedirect(desiredIndicator);
    };

    useEffect(() => {
        if (isNullOrUndefined(convertedIndicator) || districtBpie.indicators.length < convertedIndicator)
            handleAssessmentRedirect(1);
    }, []);

    if (!districtBpie || isNullOrUndefined(convertedIndicator))
        return null;

    const ratingsDefinition = <ul>
        <li><strong>Not yet:</strong> There is <strong>no evidence</strong> that the district has put in place actions to address this indicator.</li>
        <li><strong>Partially–Beginning:</strong> There is <strong>some evidence</strong> that the indicator is in place or practiced in <strong>some</strong> instances
            or schools. The practice is not implemented consistently across the entire district and further action or improvement is needed.
        </li>
        <li><strong>Partially–Almost:</strong> There is <strong>evidence</strong> that the indicator is <strong>almost fully</strong> in place or practiced in <strong>most</strong> instances or schools.
            The practice is implemented consistently across <strong>most</strong> schools in the district and minimal action or improvement is needed to reach full implementation.
        </li>
        <li><strong>Fully:</strong> There is clear evidence that this indicator is <strong>consistently
            practiced</strong> and in place <strong>across the entire district</strong> and <strong>for
            all SWDs</strong>.
        </li>
    </ul>;

    return (
        <>
            <BpieComponentView
                canEdit={isEditable && !isDistrictUser}
                glossary={`https://www.floridainclusionnetwork.com/wp-content/uploads/2022/09/Glossary-of-Terms-08.2022.pdf`}
                indicators={districtBpie.indicators}
                handleDataChanged={handleDataChanged}
                handleRedirect={handleAssessmentRedirect}
                handleSaveResponseAndRedirect={handleSaveResponseAndRedirect}
                indicatorNumber={convertedIndicator}
                isBpieActive={isEditable}
                lastIndicatorNumber={districtBpie.indicators[districtBpie.indicators.length - 1].indicatorNumber}
                ratingsDefinition={ratingsDefinition}
                responses={districtBpie.responses}
            />
        </>
    );
}

DistrictAssessmentContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    districtBpie: PropTypes.object.isRequired,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    indicatorNumber: PropTypes.string.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired
};

export default DistrictAssessmentContainer;