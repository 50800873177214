import React from "react";
import PropTypes from 'prop-types';
import EditComponentButtons from "../../../components/common/EditComponentButtons";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as drObjectFactory from "../drDueProcessObjectFactory";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";

const DrDueProcessActionResponseReviewEdit = ({
                                                  response,
                                                  handleClickCancel,
                                                  handleSaveResponseReviewAsync,
                                                  review
                                              }) => {

    const reviewForm = useFormInputObject(drObjectFactory.createReviewObject(review));

    const handleClickSave = async () => {
        let updatedReview = convertFormInputObjectToObject(reviewForm);
        updatedReview.responseId = response.id;
        const isSuccess = await handleSaveResponseReviewAsync(updatedReview, response.actionId);

        if (isSuccess)
            handleClickCancel();
    };

    return (
        <>
            <DateTimePickerField
                inputClass={`input--small`}
                label="Date Documentation Received"
                name="dateDocumentationReceived"
                showLabel
                {...reviewForm.dateDocumentationReceived}
            />
            <DateTimePickerField
                inputClass={`input--small`}
                label="Date Documentation Reviewed"
                name="dateDocumentationReviewed"
                showLabel
                {...reviewForm.dateDocumentationReviewed}
            />
            <EditComponentButtons
                handleClickCancel={handleClickCancel}
                handleClickSave={handleClickSave}
                position={ButtonBarPositions.STICKY_BOTTOM}
            />
        </>
    );
};

DrDueProcessActionResponseReviewEdit.propTypes = {
    response: PropTypes.object.isRequired,
    handleClickCancel: PropTypes.func.isRequired,
    handleSaveResponseReviewAsync: PropTypes.func.isRequired,
    review: PropTypes.object.isRequired
};

export default DrDueProcessActionResponseReviewEdit;