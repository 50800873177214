import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {DISPUTE_RESOLUTION_CONTEXT, MONITORING_COMPLIANCE} from "../../constants/contexts";
import {
    isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../common/commonUtilities";
import {getEndOfCurrentDay} from "../common/dateUtilities";
import {getDateRangeFromDescription} from "../common/inputs/inputUtility";
import {futureDateSelectFieldOptions} from "../../constants/inputConstants";
import {RecordsPerPage} from "../../constants/searchOptions";
import {handleScroll} from "../common/scrollUtilities";
import {FilterView} from "../common/searchCriterias/FilterView";
import SectionHeader from "../common/SectionHeader";
import DrSummaryView from "./DrSummaryView";
import McSummaryView from "./McSummaryView";

export const SummaryView = ({
                                buildSearchCriteria,
                                containerId,
                                context,
                                createItemsToPinList,
                                dateSelectOptionChoice,
                                handleClickGeneral,
                                isDueNow = false,
                                setDateRangeSearchCriteria,
                                setSummarySearchCriteria,
                                summary,
                                summarySearchCriteria,
                                summaryViewTitle,
                                updatePinnedItems,
                                visibilityRules
                            }) => {

    const [pageSearchCriteria, setPageSearchCriteria] = useState({});
    const [filteredSummary, setFilteredSummary] = useState([]);
    const usesPinnedItems = !isNullOrUndefined(updatePinnedItems);

    const handleUpcomingSearchFiltersChange = async (criteria) => {
        criteria.RecordsPerPage = getRecordsPerPage(filteredSummary.length);
        setPageSearchCriteria(criteria);
        await setDateRangeSearchCriteria(criteria);
    };

    const handleClickOrder = (event) => {
        let criteria = {...pageSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");
        criteria.First();
        setPageSearchCriteria(criteria);
    };

    const handleClickRecord = async (summarySearch) => {
        let criteria = {...pageSearchCriteria};
        if (summarySearch.isPinned) {
            criteria.dateRange = "";
            criteria.endDate = "";
            criteria.startDate = "";
        }

        await setSummarySearchCriteria(criteria, summarySearch);

        handleClickGeneral(summarySearchCriteria.dateWindowId, summarySearch);
    };

    const handlePinItemClick = (id, type) => {
        updatePinnedItems(id, type);
    };

    const first = (event) => {
        event.preventDefault();
        let criteria = {...pageSearchCriteria};
        criteria.First();
        setPageSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const last = (event) => {
        event.preventDefault();
        let criteria = {...pageSearchCriteria};
        criteria.Last(filteredSummary.length);
        setPageSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const next = (event) => {
        event.preventDefault();
        let criteria = {...pageSearchCriteria};
        criteria.Next();
        setPageSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const previous = (event) => {
        event.preventDefault();
        let criteria = {...pageSearchCriteria};
        criteria.Previous();
        setPageSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const getRecordsPerPage = (allRecordsPerPageLength) => {
        if (summarySearchCriteria.RecordsPerPage !== RecordsPerPage.All) {
            return parseInt(summarySearchCriteria.RecordsPerPage, 10);
        } else {
            return allRecordsPerPageLength;
        }
    };

    useEffect(() => {
        if (!isNullOrUndefined(pageSearchCriteria.dateWindowId) || isArrayNullOrEmpty(summary)) return;

        const incompleteCustomDateRange = summarySearchCriteria.dateRange === futureDateSelectFieldOptions.Custom
            && isTrimmedStringEmpty(summarySearchCriteria.startDate)
            && isTrimmedStringEmpty(summarySearchCriteria.endDate);

        const isDueNowDateRange = summarySearchCriteria.dateRange === futureDateSelectFieldOptions.Custom
            && isTrimmedStringEmpty(summarySearchCriteria.startDate)
            && summarySearchCriteria.endDate === getEndOfCurrentDay();

        const dateRangeType = isTrimmedStringEmpty(summarySearchCriteria.dateRange) || incompleteCustomDateRange || isDueNowDateRange
            ? futureDateSelectFieldOptions.ThisWeek
            : summarySearchCriteria.dateRange;

        const newDateRanges = getDateRangeFromDescription(dateRangeType);
        if (dateRangeType === futureDateSelectFieldOptions.Custom) {
            newDateRanges.startDate = summarySearchCriteria.startDate;
            newDateRanges.endDate = summarySearchCriteria.endDate;
        }

        let criteria = buildSearchCriteria(dateRangeType, newDateRanges.startDate, newDateRanges.endDate);
        criteria.setVisibilityRules(visibilityRules);
        if (!isTrimmedStringEmpty(dateSelectOptionChoice))
            criteria.setDateRangeOptionsToUse(false, dateSelectOptionChoice);
        if (usesPinnedItems)
            criteria.itemsToPin = createItemsToPinList(summary);

        const updatedSummary = criteria.applyFiltersAndOrder(summary, !isDueNow, true);
        setFilteredSummary(updatedSummary);

        criteria.RecordsPerPage = getRecordsPerPage(updatedSummary.length);
        setPageSearchCriteria(criteria);
    }, [summary]);

    useEffect(() => {
        if (isNullOrUndefined(pageSearchCriteria.dateWindowId) || isArrayNullOrEmpty(summary)) return;

        setFilteredSummary(pageSearchCriteria.applyFiltersAndOrder(summary, !isDueNow, true));

    }, [pageSearchCriteria, summary]);

    useEffect(() => {
        if (isNullOrUndefined(pageSearchCriteria.dateWindowId) || !pageSearchCriteria.pinItem || !usesPinnedItems) return;

        const parts = pageSearchCriteria.pinItem.split("|");
        if (parts.length !== 2) return;
        updatePinnedItems(parts[0], parts[1]);
    }, [pageSearchCriteria.pinItem]);

    useEffect(() => {
        if (isNullOrUndefined(pageSearchCriteria.dateWindowId) || isArrayNullOrEmpty(summary) || !usesPinnedItems) return;

        let criteria = {...pageSearchCriteria};
        criteria.itemsToPin = createItemsToPinList(summary);
        criteria.pinItem = "";
        setPageSearchCriteria(criteria);
    }, [summary]);

    useEffect(() => {
        if (isNullOrUndefined(pageSearchCriteria) || isNullOrUndefined(pageSearchCriteria.dateWindowId)) return;

        let criteria = {...pageSearchCriteria};
        criteria.disputeResolutionTypeWithDefault = summarySearchCriteria.disputeResolutionTypeWithDefault;
        criteria.First();
        setPageSearchCriteria(criteria);
    }, [summarySearchCriteria.disputeResolutionTypeWithDefault, summarySearchCriteria.dateWindowId]);

    useEffect(() => {
        if (isNullOrUndefined(pageSearchCriteria) || isNullOrUndefined(pageSearchCriteria.RecordsPerPage)) return;

        let criteria = {...pageSearchCriteria};
        criteria.RecordsPerPage = getRecordsPerPage(filteredSummary.length);
        criteria.First();
        setPageSearchCriteria(criteria);

    }, [summarySearchCriteria.RecordsPerPage]);

    if (!pageSearchCriteria.dateWindowId || !pageSearchCriteria.areRulesCurrent(visibilityRules))
        return null;

    if(isArrayNullOrEmpty(filteredSummary) && isDueNow)
      return null;

    const pagerMethods = {first, last, next, previous};

    return (
        <>
            <SectionHeader>
                <h2 id={containerId}>{summaryViewTitle}</h2>
            </SectionHeader>
            {
                !isDueNow &&
                <FilterView
                    context={context}
                    handleSearchFiltersChange={handleUpcomingSearchFiltersChange}
                    searchCriteria={pageSearchCriteria}
                />
            }

            {
                context === DISPUTE_RESOLUTION_CONTEXT &&
                <DrSummaryView
                    filteredSummary={filteredSummary}
                    handleClickRecord={handleClickRecord}
                    handlePinItemClick={handlePinItemClick}
                    handleClickOrder={handleClickOrder}
                    isDueNow={isDueNow}
                    pagerMethods={pagerMethods}
                    pageSearchCriteria={pageSearchCriteria}
                    usePinItems={!isDueNow}
                />
            }

            {
                context === MONITORING_COMPLIANCE &&
                <McSummaryView
                    filteredSummary={filteredSummary}
                    handleClickRecord={handleClickRecord}
                    handleClickOrder={handleClickOrder}
                    isDueNow={isDueNow}
                    pagerMethods={pagerMethods}
                    pageSearchCriteria={pageSearchCriteria}
                />
            }
        </>
    );
};

SummaryView.propTypes = {
    buildSearchCriteria: PropTypes.func.isRequired,
    containerId: PropTypes.string.isRequired,
    context: PropTypes.string.isRequired,
    createItemsToPinList: PropTypes.func,
    dateSelectOptionChoice: PropTypes.string,
    handleClickGeneral: PropTypes.func.isRequired,
    isDueNow: PropTypes.bool,
    setDateRangeSearchCriteria: PropTypes.func,
    setSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array,
    summarySearchCriteria: PropTypes.object.isRequired,
    summaryViewTitle: PropTypes.string.isRequired,
    updatePinnedItems: PropTypes.func,
    visibilityRules: PropTypes.array.isRequired
};

export default SummaryView;