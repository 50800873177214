import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function commonReducer(state = initialState.searchCriteria, action) {
    switch(action.type){
        case actionTypes.SAVE_SEARCH_CRITERIA:
        {
            return Object.assign({}, state, { [action.pageSearchCriteria.PageId]: Object.assign({}, action.pageSearchCriteria) });
        }
        case actionTypes.CREATE_SEARCH_CRITERIA:
        {
            if(state[action.pageSearchCriteria.PageId] !== undefined)
                return state;

            return Object.assign({}, state, { [action.pageSearchCriteria.PageId]: action.pageSearchCriteria });
        }
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
        case actionTypes.LOGOUT_AJAX_SUCCESS:
            return initialState.searchCriteria;
        default:
            return state;
    }
}