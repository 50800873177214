import PropTypes from "prop-types";
import React from "react";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as bpieLocations from "./bpieLocations";

export const BpieDistrictNavigation = ({ currentLocation, history }) => {
    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (currentLocation.startsWith(location)) return;

        history.push(location);
    };

    const districtSummaryUrl = bpieLocations.DISTRICT_BPIE_SUMMARY.getUrl();
    const sbpieSummaryUrl = bpieLocations.DISTRICT_SBPIE_SEARCH.getUrl();
    const dbpieSummaryUrl = bpieLocations.DISTRICT_DBPIE_SEARCH.getUrl();

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`bpieAdminNavigation`}>
            <Button
                btnClass={currentLocation.startsWith(districtSummaryUrl) ? "is-active" : ""}
                label={`District Summary`}
                name={`btnAdminSummary`}
                onClick={(event) => navButtonClick(event, districtSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(sbpieSummaryUrl) ? "is-active" : ""}
                label={`SBPIE Assessments`}
                name={`btnSbpieList`}
                onClick={(event) => navButtonClick(event, sbpieSummaryUrl)}
            />
            <Button
                btnClass={currentLocation.startsWith(dbpieSummaryUrl) ? "is-active" : ""}
                label={`DBPIE Assessments`}
                name={`btnDbpieList`}
                onClick={(event) => navButtonClick(event, dbpieSummaryUrl)}
            />
        </ButtonBar>
    );
};

BpieDistrictNavigation.propTypes = {
    currentLocation: PropTypes.string,
    history: PropTypes.object
};
