import React from 'react';
import PropTypes from 'prop-types';
import GridTable from "../common/GridTable";

const SurveyListNonEditable = ({surveys}) => {
	if (surveys.length > 0)
		return (
			<GridTable id="surveys-not-editable">
				<thead>
				<tr>
					<th>Name</th>
					<th>Details</th>
					<th>Status</th>
				</tr>
				</thead>

				<tbody>
				{surveys.map(survey =>
					<tr key={survey.instanceId}>
						<td>{survey.title}</td>
						<td>{survey.endDate}</td>
						<td>{survey.progress}</td>
					</tr>
				)}
				</tbody>
			</GridTable>
		);
	else return <div />;

};

SurveyListNonEditable.propTypes = {
	surveys: PropTypes.array.isRequired
};

export default SurveyListNonEditable;