import store from "store";
import * as storageTypes from "./../../constants/storageTypes";

export default class LocalStorage {
    static isLoggedIn() {
        const accessToken = store.get(storageTypes.ACCESS_TOKEN);
        return accessToken && accessToken.length > 0;
    }

    static persistLogin(access_token, refreshToken, userIdentity, behaviors) {
        store.set(storageTypes.CURRENT_USER, userIdentity);
        store.set(storageTypes.REFRESH_TOKEN, refreshToken);
        store.set(storageTypes.ACCESS_TOKEN, access_token);
        store.set(storageTypes.BEHAVIOR_CLAIMS, behaviors);
    }

    static logout() {
        store.remove(storageTypes.CURRENT_USER);
        store.remove(storageTypes.ACCESS_TOKEN);
        store.remove(storageTypes.REFRESH_TOKEN);
        store.remove(storageTypes.BEHAVIOR_CLAIMS);
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN);
        store.remove(storageTypes.ADMIN_ACCESS_TOKEN);
        store.remove(storageTypes.ADMIN_RETURN_URL);
        store.remove(storageTypes.ADMIN_OVERRIDE_DASHBOARD_REDIRECT);
    }

    static getLoggedInUser() {
        return store.get(storageTypes.CURRENT_USER);
    }

    static getAccessToken() {
        return "Bearer " + store.get(storageTypes.ACCESS_TOKEN);
    }

    static saveJwtToken(token) {
        store.set(storageTypes.ACCESS_TOKEN, token);
    }

    static saveRefreshToken(refreshToken) {
        store.set(storageTypes.REFRESH_TOKEN, refreshToken);
    }

    static getJwtToken() {
        return store.get(storageTypes.ACCESS_TOKEN);
    }

    static getRefreshToken() {
        return store.get(storageTypes.REFRESH_TOKEN);
    }

    static getBehaviorClaims() {
        return store.get(storageTypes.BEHAVIOR_CLAIMS);
    }

    static persistAdminRefreshToken() {
        store.set(storageTypes.ADMIN_REFRESH_TOKEN, store.get(storageTypes.REFRESH_TOKEN));
    }

    static getAdminRefreshToken() {
        return store.get(storageTypes.ADMIN_REFRESH_TOKEN);
    }

    static removeAdminRefreshToken() {
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN);
    }

    static persistAdminAccessToken() {
        store.set(storageTypes.ADMIN_ACCESS_TOKEN, store.get(storageTypes.ACCESS_TOKEN));
    }

    static getAdminAccessToken() {
        return store.get(storageTypes.ADMIN_ACCESS_TOKEN);
    }

    static removeAdminAccessToken() {
        return store.remove(storageTypes.ADMIN_ACCESS_TOKEN);
    }

    static persistAdminReturnUrl(url, shouldOverrideDashboardRedirectRule) {
        store.set(storageTypes.ADMIN_RETURN_URL, url);
        store.set(storageTypes.ADMIN_OVERRIDE_DASHBOARD_REDIRECT, shouldOverrideDashboardRedirectRule);
    }

    static getAdminReturnUrl() {
        return store.get(storageTypes.ADMIN_RETURN_URL);
    }

    static removeAdminReturnUrl() {
        store.remove(storageTypes.ADMIN_OVERRIDE_DASHBOARD_REDIRECT);
        return store.remove(storageTypes.ADMIN_RETURN_URL);
    }

    static shouldRedirectFromDashboard() {
        const shouldOverrideDashboardRedirectRule = store.get(storageTypes.ADMIN_OVERRIDE_DASHBOARD_REDIRECT);
        if(!shouldOverrideDashboardRedirectRule)
            return false;

        this.persistAdminReturnUrl(this.getAdminReturnUrl());
        return true;
    }
}