import React from 'react';
import PropTypes from 'prop-types';
import CaseNotesView from "../../components/CaseNotes/CaseNotesView";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as ComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {emptyGuid} from "../../constants/config";
import LocalStorage from "../../components/shared/LocalStorage";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {monitoring_compliance} from "../../constants/policyEvents";
import * as CaseNoteConstants from "../../constants/monitoringCompliance/caseNoteConstants";

export class ComplianceCaseNotePage extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            titleIsSet: false
        };

        this.canUserEdit = this.canUserEdit.bind(this);
        this.handleSaveCaseNote = this.handleSaveCaseNote.bind(this);
        this.handleDownloadCaseInvestigationReport = this.handleDownloadCaseInvestigationReport.bind(this);
        this.handleDeleteCaseNote = this.handleDeleteCaseNote.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
    }

    componentDidUpdate() {
        if (!this.state.titleIsSet &&
            this.props.institutions.length > 0) {

            const pageTitle = ComplianceUtilities.createPageTitle(this.props.institutions, this.props.districtId, "Case Notes");
            this.props.actions.updatePageTitle(pageTitle);

            this.setState({
                titleIsSet: true
            });
        }
    }

    canUserEdit() {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.caseNote.MODIFY);
    }

    handleSaveCaseNote(caseNote) {
        caseNote.monitoringAssessmentId = this.props.selectedAssessment.monitoringAssessmentId;
        caseNote.authorId = LocalStorage.getLoggedInUser().Id;

        let isNew = caseNote.caseNoteId === emptyGuid;
        return this.props.actions.saveComplianceCaseNote(this.props.selectedAssessment.monitoringAssessmentId, caseNote, isNew)
            .then(() => {
                this.props.loadComplianceAssessment();
                return true;
            });
    }

    handleDeleteCaseNote(caseNoteId) {
        return this.props.actions.deleteComplianceCaseNote(this.props.selectedAssessment.monitoringAssessmentId, caseNoteId)
            .then(() => {
                this.props.loadComplianceAssessment();
                return true;
            });
    }

    handleDownloadCaseInvestigationReport(caseNoteFilter) {
        this.props.actions.exportComplianceCaseNotes(this.props.selectedAssessment.monitoringAssessmentId, caseNoteFilter)
    }

    onBackClick(event) {
        event.preventDefault();

        this.props.history.push(ComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path
            .replace(ComplianceLocations.DATE_WINDOW_ID, this.props.dateWindowId));
    }

    render() {
        if (!this.props.selectedAssessment) return null;

        let complianceCaseNotes = this.props.selectedAssessment.complianceCaseNotes;

        return (
            <section className="compliance compliance__caseNote">
                <CaseNotesView
                    caseNotes={complianceCaseNotes}
                    caseNoteType={CaseNoteConstants.ComplianceCaseNoteType}
                    caseNoteTypeList={CaseNoteConstants.ComplianceCaseNoteTypeList}
                    handleDataChanged={this.props.handleDataChanged}
                    handleDeleteCaseNote={this.handleDeleteCaseNote}
                    handleDownloadCaseInvestigationReport={this.handleDownloadCaseInvestigationReport}
                    handleSaveCaseNote={this.handleSaveCaseNote}
                    handleScroll={this.props.handleScroll}
                    handleScrollToTop={this.props.handleScrollToTop}
                    readOnly={!this.canUserEdit()}
                />
            </section>
        );
    }
}

ComplianceCaseNotePage.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object.isRequired
};

export default ComplianceCaseNotePage;
