import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {generateDateWindowFormat, makeWordPlural} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import CheckBoxField from "../../../components/common/inputs/CheckBoxField";
import {getParams} from "../../../components/layout/getParams";
import * as MonitoringComplianceUtilities from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import LocalStorage from "../../../components/shared/LocalStorage";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import {SMALL_STICKY_HEIGHT} from "../../../constants/buttonStickyHeights";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as responseOptions from "../../../constants/monitoringCompliance/responseOptions";
import McReviewStudentRecordProtocolsView from "./McReviewStudentRecordProtocolsView";
import McReviewSummaryView from "./McReviewSummaryView";

const McReviewStudentRecordsContainer = ({
                                             actions,
                                             assessmentId,
                                             dateWindows,
                                             handleScroll,
                                             handleScrollToTop,
                                             history,
                                             indicatorProtocols,
                                             indicatorRequirementId,
                                             isLoading,
                                             loadComplianceAssessment,
                                             selectedAssessment
                                         }) => {
    const [requirement, setRequirement] = useState(null);
    const [studentRecords, setStudentRecords] = useState([]);
    const [acknowledgeNonCompliance, setAcknowledgeNonCompliance] = useState(false);

    const onClickSubmitRecords = (event) => {
        event.preventDefault();

        actions.submitStudentRecords(LocalStorage.getLoggedInUser().InstitutionId, assessmentId, indicatorRequirementId)
            .then(() => {
                loadComplianceAssessment(() => {
                    history.push(ComplianceLocations.LANDING_DISTRICT.path);
                    handleScroll(indicatorRequirementId);
                });
            });
    };

    const validateReadyToSubmit = (selectedRequirement, selectedStudentRecords) => {
        const submissionInformation = MonitoringComplianceUtilities.validateIsReadyToSubmit(selectedRequirement, selectedStudentRecords);
        return !submissionInformation.isSubmissionModuleDisabled;
    };

    const returnToLandingPage = () => {
        if (selectedAssessment.dateWindowId)
            history.push(ComplianceLocations.LANDING_DISTRICT_DATE_WINDOW.path
                .replace(ComplianceLocations.DATE_WINDOW_ID, selectedAssessment.dateWindowId));
        else
            history.push(ComplianceLocations.LANDING_DISTRICT.path);
        handleScroll(indicatorRequirementId);
    };

    const handleEditClick = (event, studentRecordId) => {
        event.preventDefault();
        const editStudentRecordPath = ComplianceLocations.COMPLIANCE_RECORD_DISTRICT.path
            .replace(ComplianceLocations.ASSESSMENT_ID, selectedAssessment.monitoringAssessmentId)
            .replace(ComplianceLocations.REQUIREMENT_ID, indicatorRequirementId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId);

        history.push(editStudentRecordPath);
        handleScrollToTop();
    }

    const setPageTitle = (selectedRequirement, dateWindowId, siteDateWindows) => {
        const indicatorTitle = MonitoringComplianceUtilities.createIndicatorTitle(selectedRequirement.title, selectedRequirement.publicIdentifier);
        const reportWindowOutput = generateDateWindowFormat(siteDateWindows, dateWindowId);
        actions.updatePageTitle(`${reportWindowOutput} ${indicatorTitle} Submission Review`);
    }

    useEffect(() => {
        if (!selectedAssessment || !selectedAssessment.indicatorRequirements || dateWindows.length === 0) return;

        const selectedRequirement = selectedAssessment.indicatorRequirements.find(f => f.indicatorRequirementId === indicatorRequirementId);

        if (!selectedRequirement)
            returnToLandingPage();

        if (indicatorRequirementId === requirement?.indicatorRequirementId) return;

        actions.loadIndicatorProtocols(selectedAssessment.dateWindowId, selectedRequirement.indicatorId);

        const selectedStudentRecords = MonitoringComplianceUtilities.getStudentRecordsForIndicatorRequirement(
            selectedRequirement.indicatorId,
            selectedAssessment.studentRecords);

        if (validateReadyToSubmit(selectedRequirement, selectedStudentRecords)) {
            setPageTitle(selectedRequirement, selectedAssessment.dateWindowId, dateWindows);
            setStudentRecords(selectedStudentRecords);
            setRequirement(selectedRequirement);
        } else
            returnToLandingPage();

    }, [selectedAssessment, dateWindows]);

    const getProtocolsInNonCompliance = () => {
        let protocols = [];
        for(let studentRecord of studentRecords) {
            for(let protocolResponse of studentRecord.protocolResponses) {
                if(protocolResponse.response !== responseOptions.NO)
                    continue;

                const protocolInfo = indicatorProtocols.find(f => f.protocolId === protocolResponse.protocolId);
                if(protocolInfo && !protocols.find(f => f === protocolInfo.publicIdentifier))
                    protocols.push(protocolInfo.publicIdentifier)
            }
        }

        protocols = MonitoringComplianceUtilities.sortProtocolPublicIdentifiers(protocols);

        return protocols;
    }

    if (!selectedAssessment || !requirement || indicatorProtocols.length === 0) return null;

    const protocolsInNonCompliance = getProtocolsInNonCompliance();
    const studentRecordsInNonCompliance = studentRecords.filter(f => f.totalNonCompliance > 0);
    const acknowledgementLabel = `I acknowledge that there ${studentRecordsInNonCompliance.length === 1 ? "is" : "are"} <strong>${studentRecordsInNonCompliance.length} student ${makeWordPlural("record", studentRecordsInNonCompliance.length)} and ${protocolsInNonCompliance.length} ${makeWordPlural("protocol", protocolsInNonCompliance.length)}</strong> found non-compliant.`;

    return (
        <>
            <p>Review all of the student records below for accuracy, noting all <span className={`highlight-example`}>highlighted rows</span> represent noncompliance.  Then check the box at the bottom of the page and
                click &quot;Submit Student Records&quot;.</p>

            <McReviewSummaryView
                protocolsInNonCompliance={protocolsInNonCompliance}
                studentRecordsInNonCompliance={studentRecordsInNonCompliance}
                totalStudents={studentRecords.length}
            />

            {
                studentRecords.map((studentRecord, index) => {
                    return (
                        <McReviewStudentRecordProtocolsView
                            handleEditClick={handleEditClick}
                            indicatorProtocols={indicatorProtocols}
                            key={index}
                            studentRecord={studentRecord}
                        />
                    );
                })
            }
            <FormTable>
                <CheckBoxField
                    checked={acknowledgeNonCompliance}
                    label={acknowledgementLabel}
                    name={`cbAcknowledge`}
                    onChange={(event) => setAcknowledgeNonCompliance(event.target.checked)}
                    showLabel
                />
            </FormTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} stickyHeight={SMALL_STICKY_HEIGHT}>
                <Button
                    buttonType={ButtonTypes.BACK}
                    disabled={isLoading}
                    label={"Student Records"}
                    name={"btnReturn"}
                    onClick={returnToLandingPage}
                />
                <Button
                    buttonType={ButtonTypes.SUBMIT}
                    disabled={isLoading || !acknowledgeNonCompliance}
                    label={`Submit Student Records`}
                    name={`btnSubmitStudents`}
                    onClick={onClickSubmitRecords}
                />
            </ButtonBar>
        </>
    );
};

const mapStateToProps = (state, props) => {
    const currentLocation = props.location.pathname;
    const params = getParams(currentLocation, ComplianceLocations.COMPLIANCE_REVIEW_SUBMISSION);
    return {
        indicatorProtocols: state.monitoringCompliance.indicatorProtocols,
        isLoading: state.ajaxCallsInProgress > 0,
        dateWindows: [...state.sharedData.dateWindows],
        ...params
    };
};

McReviewStudentRecordsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    indicatorProtocols: PropTypes.array,
    indicatorRequirementId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    selectedAssessment: PropTypes.object
};

export default connect(
    mapStateToProps
)(McReviewStudentRecordsContainer);

