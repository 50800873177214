import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../components/common/inputs/SelectField";
import TextArea from "../../../../components/common/inputs/TextArea";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {createDistrictContactsList} from "../../bpieUtilities";
import bpieDistrictApi from "../bpieDistrictApi";
import {createDistrictInfoDetails} from "../bpieDistrictObjectFactory";

export const BpieDistrictInfoForm = ({
                                         actions,
                                         districtBpie,
                                         districtId,
                                         handleDataChanged,
                                         handleSaveDbpie
                                     }) => {
    const [districtContacts, setDistrictContacts] = useState(null);
    const dbpieInfoForm = useFormInputObject(createDistrictInfoDetails(districtBpie), () => handleDataChanged());

    const handleSaveDbpieClick = (event) => {
        event.preventDefault();
        saveDistrictInfo();
    };

    const saveDistrictInfo = () => {
        const isValid = validateForm();
        if (isValid) {
            const result = convertFormInputObjectToObject(dbpieInfoForm);
            handleSaveDbpie(result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(dbpieInfoForm);

        if (isTrimmedStringEmpty(dbpieInfoForm.userId.value))
            dbpieInfoForm.userId.setError("Required");

        if (isTrimmedStringEmpty(dbpieInfoForm.assessmentTeam.value))
            dbpieInfoForm.assessmentTeam.setError("Required");

        if (isTrimmedStringEmpty(dbpieInfoForm.dateMeetingHeld.value))
            dbpieInfoForm.dateMeetingHeld.setError("Required");

        let isValid = inputFormIsValid(dbpieInfoForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    useEffect(() => {
        if(districtContacts)
            return;

        actions.executeApi(bpieDistrictApi.getDistrictContacts, [districtId])
            .then(contacts => setDistrictContacts(createDistrictContactsList(contacts)));
    }, []);

    return (
        <>
            <FormTable>
                <DisplayField
                    name={"datesCovered"}
                    label={"Date Window:"}
                    showLabel
                    isInline>
                    {districtBpie.datesCovered}
                </DisplayField>
                <DisplayField
                    name={"bpieStatus"}
                    label={"Current Status:"}
                    showLabel
                    isInline>
                    {districtBpie.districtBpieStatusDescription}
                </DisplayField>
                <SelectField
                    {...dbpieInfoForm.userId}
                    defaultOption={`Select District Contact`}
                    includeDefaultOption
                    label={`District Contact`}
                    medium={6}
                    options={districtContacts}
                    showLabel
                />
                <TextArea
                    {...dbpieInfoForm.assessmentTeam}
                    label={'Add all team member names and titles/positions.'}
                    showLabel
                />
                <DateTimePickerField
                    {...dbpieInfoForm.dateMeetingHeld}
                    inputClass={`input--small`}
                    label="Date Meeting Held"
                    showLabel
                />
            </FormTable>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Save DBPIE Assessment Information"}
                    name="btnSave"
                    onClick={handleSaveDbpieClick}
                />
            </ButtonBar>
        </>
    );
};

BpieDistrictInfoForm.propTypes = {
    actions: PropTypes.object,
    districtBpie: PropTypes.object,
    districtId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveDbpie: PropTypes.func.isRequired
};