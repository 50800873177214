import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import AccountUtility from "../../api/AccountUtility";
import NotificationsView from "../../components/Notifications/NotificationsView";
import drApi from "../drApi";

export const DrNotificationsContainer = ({
                                             actions,
                                             id,
                                             drType
                                         }) => {
    const [notifications, setNotifications] = useState(null);

    const getNotifications = async () => {
        await actions.executeApi(drApi.getDrUserNotifications, [id, drType])
            .then((result) => setNotifications(result));
    };

    const deleteNotification = async (reminderId) => {
        await actions.executeApi(drApi.removeDrReminder, [id, drType, reminderId]);

        await getNotifications();
    };

    const saveNotification = async (form) => {
        await actions.executeApi(drApi.saveDrReminder, [id, drType, form.reminderId, form.remindDate, form.reminderNotes]);
    }

    useEffect(() => {
        const isDistrictUser = AccountUtility.isDistrictUser();
        if(isDistrictUser) return;

        getNotifications();
    }, [id]);

    return <NotificationsView
        deleteNotification={deleteNotification}
        getNotifications={getNotifications}
        notifications={notifications}
        saveNotification={saveNotification}
    />;
};

DrNotificationsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    drType: PropTypes.string.isRequired
};

export default DrNotificationsContainer;
