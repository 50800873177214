import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const PtsManageGmsUserTableRowDisplay = ({
                                                    handleDeleteGmsUserClick,
                                                    handleModifyGmsUserClick,
                                                    gmsUser,
                                                    rowDisabled
                                                }) => {
    return (
        <tr>
            <td>{gmsUser.firstName}</td>
            <td>{gmsUser.lastName}</td>
            <td>{gmsUser.email}</td>
            <td className={`action-no-wrap`}>
                <Button
                    buttonType={ButtonTypes.EDIT}
                    disabled={rowDisabled}
                    label={`Modify GMS user for ${gmsUser.fullName}`}
                    name={`btnModifyGmsUser_${gmsUser.gmsUserId}`}
                    onClick={(event) => handleModifyGmsUserClick(event, gmsUser)}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.DELETE}
                    disabled={rowDisabled}
                    label={`Delete GMS user for ${gmsUser.fullName}`}
                    name={`btnDeleteProgram_${gmsUser.gmsUserId}`}
                    onClick={(event) => handleDeleteGmsUserClick(event, gmsUser.gmsUserId)}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

PtsManageGmsUserTableRowDisplay.propTypes = {
    handleDeleteGmsUserClick: PropTypes.func.isRequired,
    handleModifyGmsUserClick: PropTypes.func.isRequired,
    gmsUser: PropTypes.object,
    rowDisabled: PropTypes.bool.isRequired
};