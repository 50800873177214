import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {dateCompare} from "../../../../components/common/dateUtilities";
import {
    appendedOtherValueExists,
    clearInputFormErrorFields, convertFormInputObjectToObject, inputFormIsValid,
    reinitializeInputFormFields,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import audit_outcomes from "../../../../constants/auditOutcomes";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {correctionOtherText} from "../../../../constants/monitoringCompliance/correctionResults";
import ActivityForm from "../ActivitiesComponents/ActivityForm";
import ActivityResultsForm from "../ActivitiesComponents/ActivityResultsForm";
import ActivityResultsReviewForm from "../ActivitiesComponents/ActivityResultsReviewForm";
import {createActivityObject} from "../capObjectFactory";
import {enableCapActivityResults} from "../capUtilities";
import * as capUtilities from "../capUtilities";

const StateActivityDetails = ({
                                  assessmentId,
                                  canAudit,
                                  canModifyReview,
                                  capActivity,
                                  isLoading,
                                  onActivityCommentSaved,
                                  onActivityOutcomeChange,
                                  onCancel,
                                  onSave,
                                  protocolCorrectiveActions,
                                  setShouldReloadCapActivity,
                                  shouldReloadCapActivity
                              }) => {
    const getActivityForm = () => {
        const formattedCapActivity = createActivityObject(assessmentId, capActivity);
        formattedCapActivity.associatedProtocolPlanIds = capUtilities.getAssociatedProtocolPlanIds(formattedCapActivity.activityId, protocolCorrectiveActions);

        return formattedCapActivity;
    }

    const activityForm = useFormInputObject(getActivityForm());

    const validateActivityResultsReviewForm = () => {
        clearInputFormErrorFields(activityForm);

        if (activityForm.capActivityResultsStatus.value === audit_outcomes.SUBMITTED.value)
            return true;

        if (isTrimmedStringEmpty(activityForm.comments.value))
            activityForm.comments.setError("Missing comments.");

        if (activityForm.capActivityResultsStatus.value === audit_outcomes.ACCEPTED.value) {
            if (activityForm.resultsFromReview.value.length === 0)
                activityForm.resultsFromReview.setError("Results not selected.");
            else if (!appendedOtherValueExists(correctionOtherText, activityForm.resultsFromReview.value))
                activityForm.resultsFromReview.setError(`More details are required when ${correctionOtherText} is selected for Results.`)

            if (isTrimmedStringEmpty(activityForm.dateDocumentationReceived.value))
                activityForm.dateDocumentationReceived.setError("Missing date documentation received.");
            else if (!isTrimmedStringEmpty(activityForm.timelineEndDate.value) && dateCompare(activityForm.timelineStartDate.value, activityForm.timelineEndDate.value) > 0)
                activityForm.timelineStartDate.setError("Start date needs to come before end date.")

            if (isTrimmedStringEmpty(activityForm.dateDocumentationReviewed.value))
                activityForm.dateDocumentationReviewed.setError("Missing date documentation reviewed.");

            if (isTrimmedStringEmpty(activityForm.dateCorrectionDemonstrated.value))
                activityForm.dateCorrectionDemonstrated.setError("Missing date correction demonstrated.");

            if (isTrimmedStringEmpty(activityForm.timelineStatus.value))
                activityForm.timelineStatus.setError("Status not selected.");
        }

        let isValid = inputFormIsValid(activityForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleClickSave = (event) => {
        event.preventDefault();
        if (validateActivityResultsReviewForm()) {
            const form = convertFormInputObjectToObject(activityForm);
            onSave(form);
        }
    };

    const handleClickCancel = (event) => {
        event.preventDefault();
        onCancel();
    }

    const handleActivityOutcomeChange = (event) => {
        event.preventDefault();
        const auditOutcome = event.target.id.split("_")[0];
        onActivityOutcomeChange(auditOutcome);
    };

    const canAuditActivity = () => {
        return canAudit && activityForm.capActivityStatus.value !== audit_outcomes.IN_PROGRESS.value;
    }

    useEffect(() => {
        if (!shouldReloadCapActivity) return;

        const updatedCapActivity = getActivityForm();
        reinitializeInputFormFields(activityForm, updatedCapActivity);
        setShouldReloadCapActivity(false);
    }, [shouldReloadCapActivity]);

    return (
        <>
            {
                enableCapActivityResults(activityForm.capActivityStatus.value) &&
                <>
                    {
                        activityForm.isReviewEnabled.value &&
                        <ActivityResultsReviewForm
                            activityResultsReviewForm={activityForm}
                            canAudit={canAudit && !isLoading}
                            canEdit={canModifyReview}
                            disabledForm={!canModifyReview || isLoading}
                            isLoading={isLoading}
                        />
                    }
                    <ActivityResultsForm
                        activityResultsForm={activityForm}
                        disabledForm
                    />
                </>
            }

            <ActivityForm
                activityForm={activityForm}
                disabledForm
                handleActivityOutcomeChange={canAuditActivity() ? handleActivityOutcomeChange : null}
                isLoading={isLoading}
                onActivityCommentSaved={canAuditActivity() ? onActivityCommentSaved : null}
                protocolCorrectiveActions={protocolCorrectiveActions}
            />

            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                {
                    activityForm.isReviewEnabled.value &&
                    (canModifyReview || canAudit) &&
                    <>
                        <Button
                            buttonType={ButtonTypes.SAVE}
                            disabled={isLoading}
                            label={"Save"}
                            name={"btnSave"}
                            onClick={handleClickSave}
                        />
                    </>
                }
                <Button
                    buttonType={activityForm.isReviewEnabled.value ? ButtonTypes.CANCEL : ButtonTypes.BACK}
                    disabled={isLoading}
                    label={activityForm.isReviewEnabled.value ? "Cancel" : "Return to Activities"}
                    name={"btnCancel"}
                    onClick={handleClickCancel}
                />
            </ButtonBar>
        </>
    );
};

StateActivityDetails.propTypes = {
    assessmentId: PropTypes.string.isRequired,
    canAudit: PropTypes.bool.isRequired,
    canModifyReview: PropTypes.bool,
    capActivity: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    onActivityCommentSaved: PropTypes.func.isRequired,
    onActivityOutcomeChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object),
    setShouldReloadCapActivity: PropTypes.func.isRequired,
    shouldReloadCapActivity: PropTypes.bool.isRequired
};

export default StateActivityDetails;