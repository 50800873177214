import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../../gmsConstants";
import {API_ROOT} from "../../gmsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsAdminTeamMembersApi {
    static saveTeamMembers(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/teamMembers/`,
                RequestTypes.PUT,
                JSON.stringify(model),
                API_MESSAGES.ADMIN.TEAM_MEMBERS.saved));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsAdminTeamMembersApi {
    static saveTeamMembers(){
        return ApiUtility.mockResolveApiCall({}, API_MESSAGES.ADMIN.TEAM_MEMBERS.saved);
    }
}

const GmsAdminTeamMembersApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsAdminTeamMembersApi : MockGmsAdminTeamMembersApi;
export default GmsAdminTeamMembersApi;