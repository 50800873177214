import * as contexts from "../constants/contexts";
import * as behaviors from "../constants/behaviors";
import {isNullOrUndefined} from "../components/common/commonUtilities";

const getUserBehaviors = (context, behaviorsArray, enabled = true, selected = true) => {
    return behaviorsArray.map((behavior) => {
        return {
            securityArea: context,
            claimValue: behavior,
            enabled: enabled,
            selected: selected
        };
    });
};

const defaultUserBehaviors = [
    ...getUserBehaviors(contexts.USERS, [behaviors.VIEW, behaviors.EDIT]),
    ...getUserBehaviors(contexts.USERS, [behaviors.CERTIFY, behaviors.MANAGE], true, false),
    ...getUserBehaviors(contexts.SURVEYS, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY], false, false),
    ...getUserBehaviors(contexts.SURVEYS, [behaviors.MANAGE], true, false),
    ...getUserBehaviors(contexts.SPP, [behaviors.VIEW, behaviors.EDIT]),
    ...getUserBehaviors(contexts.SPP, [behaviors.CERTIFY, behaviors.MANAGE], true, false),
    ...getUserBehaviors(contexts.BPIE, [behaviors.VIEW, behaviors.EDIT]),
    ...getUserBehaviors(contexts.GMS, [behaviors.VIEW, behaviors.EDIT]),
    ...getUserBehaviors(contexts.PTS, [behaviors.VIEW, behaviors.EDIT]),
];

const users = [
    {
        userId: "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        username: "testAccount",
        email: "testAccount@fcim.org",
        firstName: "Test",
        lastName: "Account",
        stateUser: "false",
        institution: null,
        institutionId: null,
        password: "testAccount123!",
        behaviors: [
            ...getUserBehaviors(contexts.USERS, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.SURVEYS, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.SPP, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.STRATEGIC_PLAN, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.ADMIN, [behaviors.VIEW]),
            ...getUserBehaviors(contexts.MONITORING_PERFORMANCE, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.MONITORING_COMPLIANCE, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.BPIE, [behaviors.VIEW, behaviors.EDIT]),
            ...getUserBehaviors(contexts.GMS, [behaviors.VIEW, behaviors.EDIT]),
            ...getUserBehaviors(contexts.DISPUTE_RESOLUTION_CONTEXT, [behaviors.VIEW, behaviors.EDIT]),
        ],
        refresh_token: "7n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "baaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        username: "editSppAccount",
        email: "districtAccount@fcim.org",
        firstName: "SppEdit",
        lastName: "Account",
        stateUser: "false",
        institution: "Alachua",
        institutionId: "1",
        password: "testAccount123!",
        behaviors: [
            ...getUserBehaviors(contexts.USERS, [behaviors.VIEW, behaviors.EDIT]),
            ...getUserBehaviors(contexts.SPP, [behaviors.VIEW, behaviors.EDIT]),
        ],
        refresh_token: "6n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "fyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "2345678901",
        username: "thirdTest",
        email: "thirdTest@fcim.org",
        firstName: "",
        lastName: "",
        stateUser: "",
        institution: "Brevard",
        institutionId: "2",
        password: "testAccount123!",
        behaviors: defaultUserBehaviors,
        refresh_token: "10n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "iyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "asff678901",
        username: "fwgwgTest",
        email: "wgwfwTest@fcim.org",
        firstName: "",
        lastName: "",
        stateUser: "",
        institution: "Broward",
        institutionId: "3",
        password: "testAccount123!",
        behaviors: defaultUserBehaviors,
        refresh_token: "11n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "jyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "11111111",
        username: "1111111-IIIII",
        email: "IIIIII-1111111@fcim.org",
        firstName: "",
        lastName: "",
        stateUser: "",
        institution: "Hernando",
        institutionId: "12",
        password: "testAccount123!",
        behaviors: defaultUserBehaviors,
        refresh_token: "12n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "kyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "222222-PPPP",
        username: "222222-PPPP121212",
        email: "222222-PPPP@fcim.org",
        firstName: "",
        lastName: "",
        stateUser: "",
        institution: "Leon",
        institutionId: "21",
        password: "testAccount123!",
        behaviors: defaultUserBehaviors,
        refresh_token: "13n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "myJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "caaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        username: "certifySppAccount",
        email: "certifySppAccount@fcim.org",
        firstName: "SppCertify",
        lastName: "Account",
        stateUser: "false",
        institution: "Alachua",
        institutionId: "1",
        password: "testAccount123!",
        behaviors: [
            ...getUserBehaviors(contexts.USERS, [behaviors.VIEW, behaviors.EDIT]),
            ...getUserBehaviors(contexts.SPP, [behaviors.VIEW, behaviors.CERTIFY]),
        ],
        refresh_token: "8n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "gyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    },
    {
        userId: "daaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        username: "monitoringAccount",
        email: "monitoringAccount@fcim.org",
        firstName: "Monitoring",
        lastName: "Account",
        stateUser: "false",
        institution: "Alachua",
        institutionId: "1",
        password: "testAccount123!",
        behaviors: [
            ...getUserBehaviors(contexts.USERS, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE]),
            ...getUserBehaviors(contexts.MONITORING_PERFORMANCE, [behaviors.VIEW, behaviors.EDIT]),
            ...getUserBehaviors(contexts.MONITORING_COMPLIANCE, [behaviors.VIEW, behaviors.EDIT]),
        ],
        refresh_token: "9n3x8IchS/b1r5TDWTECZ3UsKHtkjI9fflCoBhsRMjE=",
        access_token: "hyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0QWNjb3VudCIsImp0aSI6IjkxYTVjZDlhLWZiMjgtNDc5NS05NjhkLWUxZWFiMDgwNWY1MCIsInNpZCI6IjdlZWYzODBhLTk1OWUtNDllNS1iYzhjLTMwNjc5YTQ0MTI5YiIsImVtYWlsIjoidGVzdEFjY291bnRAZmNpbS5vcmciLCJodHRwOi8vYmVlc3Nnc3cub3JnL2FkbWluIjoidHJ1ZSIsImh0dHA6Ly9iZWVzc2dzdy5vcmcvYmVoYXZpb3IiOlsidXNlcnMuZWRpdCIsInNwJnAudmlldyIsInNwJnAuZWRpdCIsInNwJnAuY2VydGlmeSIsInNwJnAubWFuYWdlIiwicGxhbi52aWV3IiwicGxhbi5lZGl0IiwicGxhbi5jZXJ0aWZ5IiwicGxhbi5tYW5hZ2UiLCJ1c2Vycy52aWV3IiwiYWRtaW4udmlldyIsInVzZXJzLm1hbmFnZSIsInBlcmZvcm1hbmNlLnZpZXciLCJwZXJmb3JtYW5jZS5lZGl0IiwicGVyZm9ybWFuY2UuY2VydGlmeSIsInBlcmZvcm1hbmNlLm1hbmFnZSIsImNvbXBsaWFuY2UudmlldyIsImNvbXBsaWFuY2UuZWRpdCIsImNvbXBsaWFuY2UuY2VydGlmeSIsImNvbXBsaWFuY2UubWFuYWdlIiwic3VydmV5cy5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi5tYW5hZ2UiLCJkaXNwdXRlUmVzb2x1dGlvbi52aWV3IiwiZGlzcHV0ZVJlc29sdXRpb24uZWRpdCJdLCJuYmYiOjE1NDk0ODg5MDQsImV4cCI6MTU0OTQ5NDMwNCwiaXNzIjoiR3N3QXBpIiwiYXVkIjoiR3N3UmVhY3QifQ.NNNBw3xextOO8QhwrK40zVoc5mP2WnQ88HY2fMpACsA",
        createdBy: "Lester Tester",
        dateCreatedUtc: "6/30/2017",
        modifiedBy: "Lester Tester",
        dateModifiedUtc: "6/30/2017",
        status: "Active",
        isDeleted: false,
    }
];

const convertToAccountBehaviors = (userBehaviors) => {
    if (isNullOrUndefined(userBehaviors))
        return [];

    return userBehaviors.map((b) => getAccountBehavior(b));
};

const getAccountBehavior = (userBehavior) => {
    if (isNullOrUndefined(userBehavior))
        return "";
    if (userBehavior.selected === false)
        return "";

    const context = userBehavior.securityArea;

    return context + "." + userBehavior.claimValue;
};

const getAccountView = (user) => {
    return {
        ...user,
        id: user.userId,
        behaviors: convertToAccountBehaviors(user.behaviors)
    };
};

export const getMockAccounts = () => {
    return users.map((user) => getAccountView(user));
};

export const getMockUsers = () => {
    return users;
};
