import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as surveyAdminActions from "../../actions/surveyAdminActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {catchError} from "../../actions/actionUtility";
import FormTable from "../../components/common/FormTable";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonMock from "../../components/common/buttons/ButtonMock";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import SelectField from "../../components/common/inputs/SelectField";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";
import {NotifyUser} from "../../components/common/NotifyUser";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import { getParams } from "../../components/layout/getParams";
import DateTimePickerField from "../../components/common/inputs/DateTimePickerField";

export class ManageSurveyDetailsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.saveDetails = this.saveDetails.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.isValid = this.isValid.bind(this);
		this.onStatusChange = this.onStatusChange.bind(this);
		this.copySurvey = this.copySurvey.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, surveyLocations.MANAGE_DETAILS);

		this.props.actions.loadAllDateWindows();
		this.props.actions.tryLoadSurveyDetails(params.surveyInstanceId)
			.then(() => {
				this.props.actions.updatePageTitle(this.props.details.title + " - Details");
			})
			.catch();
	}

	onFormChange(event) {
		event.preventDefault();

		let details = Object.assign({}, this.props.details);
		details[event.target.name] = event.target.value;

		this.props.actions.changeSurveyDetails(details);
	}

	isValid() {
		let isValid = true;

		if (this.props.details.closeDate === "") {
			NotifyUser.Warning("Close Date is required");
			isValid = false;
		}
		else if (!sharedDataUtilities.isDateValid(this.props.details.closeDate)) {
			NotifyUser.Warning("Close Date must be a date");
			isValid = false;
		}

		return isValid;
	}

	saveDetails(event) {
		event.preventDefault();
		if (this.isValid()) {
			this.props.actions.saveSurveyDetails(this.props.details);
		}
	}

	onStatusChange(event) {
		event.preventDefault();

		if(event.currentTarget.id === "btnUnpublish") {
			if(confirm("Are you sure you want to unpublish the survey?")) {
				this.props.actions.tryUnpublishSurvey(this.props.details.surveyId)
					.then(() => {
						const details = Object.assign({}, this.props.details);
						details.status = surveyStatuses.Editing;
						this.props.actions.changeSurveyDetails(details);
					})
					.catch(error => catchError(error));
			}

			return;
		}

		if (this.props.details.status === surveyStatuses.Editing && this.isValid()) {
			if (confirm("Publishing this survey will email all respondents notifying them that the survey is now available. Are you sure you want to publish the survey?")) {
				this.props.actions.tryPublishSurvey(this.props.details.surveyId, this.props.details.closeDate)
					.then(() => {
						const details = Object.assign({}, this.props.details);
						details.status = surveyStatuses.Published;
						this.props.actions.changeSurveyDetails(details);
					})
					.catch(error => catchError(error));
			}
		}

		if (this.props.details.status === surveyStatuses.Published) {
			if (confirm("Closing this survey will disallow respondents from submitting responses. Once closed, the survey can not be open again. Are you survey you want to close the survey?")) {
				this.props.actions.tryCloseSurvey(this.props.details.surveyId)
					.then(() => {
						const details = Object.assign({}, this.props.details);
						details.status = surveyStatuses.Closed;
						this.props.actions.changeSurveyDetails(details);
					})
					.catch(error => catchError(error));
			}
		}
	}

	copySurvey(event) {
		event.preventDefault();

		this.props.actions.tryCopySurvey(this.props.details.surveyId)
			.then(() => {
				this.props.history.push(surveyLocations.LANDING);
			})
			.catch(error => catchError(error));
	}

	render() {
		if (this.props.details.surveyId === undefined) return null;

		const statusButtonText = this.props.details.status === surveyStatuses.Editing ?
			"Publish" : this.props.details.status === surveyStatuses.Published ?
				"Close" : "";

		const isSurveyClosed = this.props.details.status === surveyStatuses.Closed;

		return (
			<div>
				{
					statusButtonText !== "" &&
					<ButtonBar position={ButtonBarPositions.TOP}>
						{
							this.props.details.status === surveyStatuses.Published &&
							<Button label={"Unpublish"}
									name={"btnUnpublish"}
									onClick={this.onStatusChange}
									buttonType={ButtonTypes.PRIMARY}
									disabled={this.props.isLoading}/>
						}
						<Button label={statusButtonText}
						        name={"btnStatusChange"}
						        onClick={this.onStatusChange}
						        buttonType={ButtonTypes.PRIMARY}
						        disabled={this.props.isLoading}/>
						<Button label={"Clone"}
						        name={"btnCopySurvey"}
						        onClick={this.copySurvey}
						        buttonType={ButtonTypes.CLONE}
						        disabled={this.props.isLoading}/>
						{
							this.props.details.status !== surveyStatuses.Closed &&
							<Button onClick={this.saveDetails}
							        label="Save"
							        name="btnSave"
							        buttonType={ButtonTypes.SAVE}
							        disabled={this.props.isLoading}/>
						}
					</ButtonBar>
				}

				<FormTable>
					<GridRow align={"middle"}>
						<GridColumn medium="6">
							<label htmlFor={"lblStatus"}>Publication Status</label>
						</GridColumn>
						<GridColumn large="6">
							<ButtonMock id="lblStatus" label={this.props.details.status}/>
						</GridColumn>
					</GridRow>
					<SelectField
						name="dateWindowId"
						showLabel={true}
						label="Date Window"
						onChange={this.onFormChange}
						options={this.props.dateWindows}
						value={this.props.details.dateWindowId}
						medium={6}
						includeDefaultOption={false}
						disabled={isSurveyClosed}
					/>
					<DateTimePickerField name={"closeDate"}
										 label={"Close Date"}
										 onChange={this.onFormChange}
										 value={this.props.details.closeDate}
										 showLabel={true}
										 medium={6}
										 disabled={isSurveyClosed} />
				</FormTable>
			</div>
		);
	}
}

ManageSurveyDetailsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	details: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object),
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	let dateWindows = [...state.sharedData.dateWindows];

	if (dateWindows.length > 0) {
		const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
	}

	return {
		details: state.surveyAdmin.selectedSurveyInstance,
		dateWindows: dateWindows,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, layoutActions, surveyAdminActions, sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageSurveyDetailsPage);