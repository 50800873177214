import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {catchError} from "../../actions/actionUtility";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as strategicPlanActions from "../../actions/strategicPlanActions";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import SelectField from "../../components/common/inputs/SelectField";
import * as ButtonTypes from "../../constants/ButtonTypes";
import GridTable from "../../components/common/GridTable";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import PlanDateWindowForm from "../../components/strategicPlanAdmin/PlanDateWindowForm";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import {NotifyUser} from "../../components/common/NotifyUser";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";

class ManagePlansPage extends React.PureComponent {
	constructor(props) {
		super(props);

        this.onDateWindowChange = this.onDateWindowChange.bind(this);
        this.onCloneClick = this.onCloneClick.bind(this);
        this.newPlan = this.newPlan.bind(this);
        this.onSavePlanDateWindow = this.onSavePlanDateWindow.bind(this);
        this.onChangePlanDateWindow = this.onChangePlanDateWindow.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.deletePlan = this.deletePlan.bind(this);
        this.toggleState = this.toggleState.bind(this);
    }

    componentDidMount() {
        this.props.actions.loadAllDateWindows()
            .then(() => {
                if (this.props.selectedDateWindowId === "")
                    this.props.actions.changeSelectedDateWindow(this.props.dateWindows[1].value);
            })
            .catch(error => catchError(error));

	    if (isArrayNullOrEmpty(this.props.plans)) {
		    this.props.actions.loadPlans();
	    }
	}

    onDateWindowChange(event) {
        event.preventDefault();

        this.props.actions.changeSelectedDateWindow(event.target.value);
    }

	onCloneClick(event, planDateWindowId) {
		event.preventDefault();

        const selectedDateWindow = this.props.dateWindows.filter(dw => dw.value === this.props.selectedDateWindowId)[0];

		let nextDateWindow = this.props.dateWindows.filter(dw =>
		parseInt(dw.text.substring(0, 4)) ===
		parseInt(selectedDateWindow.text.substring(0, 4)) + 1);

		if (nextDateWindow.length === 0) {
			NotifyUser.Warning("The next year's date window does not exist. Please contact FCIM for assistance.");
			return;
		}

		nextDateWindow = nextDateWindow[0];

		const copiedPlan = this.props.plans.filter(pdw => pdw.planDateWindowId === planDateWindowId)[0];

		if (confirm(copiedPlan.name + " will be copied to " + nextDateWindow.text + ". Are you sure you want to copy the plan?")) {
			this.props.actions.copyPlanDateWindow(planDateWindowId, nextDateWindow.value);
		}
	}

	onSavePlanDateWindow(planDateWindow) {
		if (planDateWindow.name !== "") {
			this.props.actions.addPlan(planDateWindow);
		}
		else NotifyUser.Warning("Plan name is required to create a new plan.");
	}

	onChangePlanDateWindow(event, planDateWindowId) {
		event.preventDefault();

		const changedPlanDateWindow = Object.assign({}, [...this.props.plans].filter(pdw => pdw.planDateWindowId === planDateWindowId)[0]);

		changedPlanDateWindow[event.target.name] = event.target.value;

		this.props.actions.changePlanDateWindow(changedPlanDateWindow);
	}

	onCancel() {
		this.props.actions.loadPlans();
	}

	newPlan(event) {
		event.preventDefault();

        this.props.actions.createPlanDateWindow(this.props.selectedDateWindowId);
    }

	deletePlan(plan) {
		if (confirm("Are you sure you want to delete this plan?")) {
			this.props.actions.deletePlanDateWindow(plan);
		}
	}

    toggleState(plan) {
        const newState = plan.status === "Open" ? "Closed" : "Open";
        if (confirm("Are you sure you want to change the state to " + newState + "?")) {
            plan = Object.assign({}, plan);
            plan.status = newState;

			this.props.actions.updatePlanDateWindow(plan);
		}
	}

	render() {
		if (this.props.dateWindows.length === 0)
			return null;

        const filteredPlans = this.props.plans.filter(plan => plan.dateWindowId === this.props.selectedDateWindowId);

        return (
            <section>
                <GridRow rowClass="filterBar">
                    <GridColumn>
                        <SelectField name="dateWindow"
                                     showLabel={true}
                                     label="Date Window"
                                     onChange={this.onDateWindowChange}
                                     options={this.props.dateWindows}
                                     value={this.props.selectedDateWindowId}
                                     includeDefaultOption={false}/>
                    </GridColumn>
                </GridRow>
                <GridTable>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th className="text-center">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        filteredPlans.map(pdw => {
                            if (this.props.isEditing &&
                                pdw.planDateWindowId === this.props.editItemId)
                                return (<PlanDateWindowForm planDateWindow={pdw}
                                                            onChange={(event) => this.onChangePlanDateWindow(event, pdw.planDateWindowId)}
                                                            onSave={this.onSavePlanDateWindow}
                                                            onCancel={this.onCancel}
                                                            key={pdw.planDateWindowId}
                                                            isLoading={this.props.isLoading}/>);

							return (
								<tr key={pdw.planDateWindowId} id={pdw.planDateWindowId}>
									<td>{pdw.name}</td>
									<td>{pdw.status}</td>
									<td className="button-cell">
										<ButtonBar position={ButtonBarPositions.TABLE}>
											<Button name={"clone|" + pdw.planDateWindowId}
											        label={"clone"}
											        showLabel={false}
											        onClick={(event) => this.onCloneClick(event, pdw.planDateWindowId)}
											        disabled={this.props.isLoading || this.props.isEditing}
											        buttonType={ButtonTypes.CLONE}
											/>
											<Button name={"delete|" + pdw.planDateWindowId}
											        label={"delete"}
											        showLabel={false}
											        onClick={() => this.deletePlan(pdw)}
											        disabled={this.props.isLoading || this.props.isEditing}
											        buttonType={ButtonTypes.DELETE}
											/>

											<Button name={"toggle|" + pdw.planDateWindowId}
											        label={"toggle status"}
											        showLabel={false}
											        onClick={() => this.toggleState(pdw)}
											        disabled={this.props.isLoading || this.props.isEditing}
											        buttonType={ButtonTypes.HISTORY}
											/>
											{/*change button type based on status*/}
										</ButtonBar>
									</td>
								</tr>
							);
						})
					}
					</tbody>
					<tfoot>
					{
						!this.props.isEditing &&
						<tr>
							<td colSpan={3}>
								<TileContainer
									rowTilesMedium={"1"}
									rowTilesLarge={"1"}
								>
									<Tile onClick={this.newPlan}
									      body={"New Plan"}
									      isMedium
									      isNew/>
								</TileContainer>
							</td>
						</tr>
					}
					</tfoot>
				</GridTable>
			</section>
		);
	}
}

ManagePlansPage.propTypes = {
    actions: PropTypes.object.isRequired,
    plans: PropTypes.array.isRequired,
    dateWindows: PropTypes.array.isRequired,
    selectedDateWindowId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    editItemId: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    let dateWindows = [...state.sharedData.dateWindows];
    if(dateWindows.length > 0)
    {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
    }

    return {
        plans: state.strategicPlan.plans,
        dateWindows: dateWindows,
        selectedDateWindowId: state.sharedData.selectedDateWindowId,
        isLoading: state.ajaxCallsInProgress > 0,
        isEditing: state.strategicPlan.isEditing,
        editItemId: state.strategicPlan.editItemId
    };
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sharedDataActions,
		strategicPlanActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlansPage);