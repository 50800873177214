import React from "react";
import PropTypes from "prop-types";
import GridTable from "../../../components/common/GridTable";
import DisplayField from "../../../components/common/inputs/DisplayField";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import DrStateComplaintActionDeadlineExtensions from "./DrStateComplaintActionDeadlineExtensions";

const DrStateComplaintActionDeadlines = ({
	                                         deadlines
                                         }) => {

	return (
		<DisplayField label={"Deadlines"} name={"deadlines"} showLabel>
			{
				!isArrayNullOrEmpty(deadlines) &&
				<GridTable>
					<thead>
					<tr>
						<th>Due Date</th>
						<th>Extensions</th>
					</tr>
					</thead>
					<tbody>
					{
						deadlines.map((deadline, key) =>
							<tr key={key} className={`extension-info`}>
								<td>{outputStandardDateFormat(deadline.dateDue, false)}</td>
								<td><DrStateComplaintActionDeadlineExtensions deadlineExtensions={deadline.deadlineExtensions} /></td>
							</tr>
						)
					}
					</tbody>
				</GridTable>
			}
			{
				isArrayNullOrEmpty(deadlines) &&
				<div>None</div>
			}
		</DisplayField>
	);
};

DrStateComplaintActionDeadlines.propTypes = {
	deadlines: PropTypes.array.isRequired
};

export default DrStateComplaintActionDeadlines;