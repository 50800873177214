import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import AuditHistory from "../../../components/audit/AuditHistory";
import {deepCopyArray, isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {newComment} from "../../../components/shared/sharedDataUtilities";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../constants/AuditConstants";

export const DrDueProcessResolutionProcessAuditView = ({
                                                           audits,
                                                           canAudit,
                                                           handleDataChanged,
                                                           handleSaveAuditCommentAsync,
                                                           isDistrictUser
                                                       }) => {

    const [updatedAudits, setUpdatedAudits] = useState([]);
    const [editAuditId, setEditAuditId] = useState('');
    const [isInEditFeedbackMode, setIsInEditFeedbackMode] = useState(false);

    useEffect(() => {
        setUpdatedAudits(deepCopyArray(audits));
    }, [audits]);

    if (isArrayNullOrEmpty(audits))
        return null;

    const clearEditMode = () => {
        handleDataChanged(false);
        setEditAuditId('');
        setIsInEditFeedbackMode(false);
    };

    const handleCreateComment = (auditId) => {
        let tempAudits = deepCopyArray(updatedAudits);
        let audit = tempAudits.filter(a => a.auditId === auditId)[0];
        audit.comments.unshift(newComment(auditId));
        setUpdatedAudits(tempAudits);
        setIsInEditFeedbackMode(true);
    };

    const handleEditComment = (commentId) => {
        setEditAuditId(commentId);
        setIsInEditFeedbackMode(true);
    };

    const handleCancelComment = () => {
        setUpdatedAudits(deepCopyArray(audits));
        clearEditMode();
    };

    const handleChangeComment = (event, updateComment) => {
        event.preventDefault();
        let tempAudits = deepCopyArray(updatedAudits);

        const fieldUpdated = event.target.name;
        const updatedValue = event.target.value;

        let updatedComment = tempAudits.find(a => a.auditId === updateComment.auditId)
            .comments
            .find(c => c.auditCommentId === updateComment.auditCommentId);

        if(fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
            updatedComment.isVisibleToDistrict = updatedValue;
        else
            updatedComment.text = updatedValue;

        setUpdatedAudits(tempAudits);
    };

    const handleSaveComment = async (event, comment) => {
        event.preventDefault();
        const isSuccess = await handleSaveAuditCommentAsync(comment);

        if (isSuccess) {
            clearEditMode();
        }
    };

    return (
        <>
            {
                !isDistrictUser &&
                canAudit &&
                <AuditHistory
                    audits={updatedAudits}
                    editItemId={editAuditId}
                    isInEditMode={isInEditFeedbackMode}
                    isLoading={false}
                    onCommentCreate={handleCreateComment}
                    onCommentCancel={handleCancelComment}
                    onCommentEdit={handleEditComment}
                    onCommentSave={handleSaveComment}
                    handleCommentChange={handleChangeComment}
                    parentIdPropName={`resolutionProcessId`}
                />
            }
            {
                (isDistrictUser || !canAudit) &&
                <AuditHistory
                    audits={updatedAudits}
                />
            }
        </>
    );
};

DrDueProcessResolutionProcessAuditView.propTypes = {
    audits: PropTypes.array.isRequired,
    canAudit: PropTypes.bool.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveAuditCommentAsync: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired
};