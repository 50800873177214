import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import gmsApi from "../../gmsApi";
import gmsApplicationAmendemntApi from "../ApplicationAmendmentSetup/GmsApplicationAmendmentApi";
import * as gmsLocations from "../../gmsLocations";
import { GmsApplicationAmendmentSetupView } from "./GmsApplicationAmendmentSetupView";

const GmsApplicationAmendmentSetupContainer = ({
    actions,
    applicationId,
    currentUserFiscalAgentId,
    history,
    isFiscalAgentUser,
}) => {
    const [application, setApplication] = useState(null);

    const handleAmendmentApplication = async (selectedSections, isBudgetAmended) => {
        await actions.executeApi(gmsApplicationAmendemntApi.CreateAmendmentForApplication, [applicationId, selectedSections, isBudgetAmended])
            .then(redirectToSummary);
    }


    const redirectToSummary = () => {
        const resolvedPath = gmsLocations.GMS_APPLICATION.getUrl(applicationId);
        history.push(resolvedPath);
    }

    useEffect(() => {
        const initializeData = async () => {
            await actions.executeApi(gmsApi.getApplication, [applicationId])
                .then((result) => {
                    const isFiscalAgentUserViewingIncorrectFiscalAgent = isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId.toLowerCase();
                    if (!result.canCreateAmendment || isFiscalAgentUserViewingIncorrectFiscalAgent) {
                        const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                        history.push(resolvedPath);
                        return;
                    }

                    setApplication(result);
                });
        };

        initializeData();

    }, []);

    useEffect(() => {
            actions.updatePageSubTitle("Create Amendment");
    }, [application]);

    if (!application)
        return null;


    return (
        <>
            <GmsApplicationAmendmentSetupView
                application={application}
                handleReturnToSummary={redirectToSummary}
                handleAmendmentApplication={handleAmendmentApplication}
            />
        </>
    );
};


GmsApplicationAmendmentSetupContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
};

export default GmsApplicationAmendmentSetupContainer;