import {createListItem, isArrayNullOrEmpty, isNullOrUndefined} from "../../../../../components/common/commonUtilities";

const fiscalAgentUserDelimiter = "|";

export function createProjectUserIdsArray(fiscalAgents) {
    if(isArrayNullOrEmpty(fiscalAgents)) return [];

    let list = [];
    for(let fiscalAgent of fiscalAgents) {
        for(let projectUser of fiscalAgent.discretionaryProjectUsers) {
            list.push(`${fiscalAgent.fiscalAgentId}${fiscalAgentUserDelimiter}${projectUser.applicationUserId}`);
        }
    }

    return list;
}

export function createProjectUsersSelectList(users) {
    if(isArrayNullOrEmpty(users)) return [];

    return users.map(user => {
        return {
            ...createListItem(`${user.institutionId}|${user.userId}`, `${user.fullName} (${user.institution})`),
            fiscalAgentId: user.institutionId
        };
    });
}

export function createSaveFiscalAgentsUsersArray(fiscalAgentIds, projectUserIds) {
    if(isArrayNullOrEmpty(fiscalAgentIds)) return [];

    let saveList = [];
    for(let fiscalAgentId of fiscalAgentIds) {
        const fiscalAgentUsers = projectUserIds.filter(f => f.startsWith(fiscalAgentId));
        saveList.push(createSaveFiscalAgentUsersObject(fiscalAgentId, fiscalAgentUsers));
    }

    return saveList;
}

function createSaveFiscalAgentUsersObject(fiscalAgentId, fiscalAgentUsers) {
    const fiscalAgentUserIds = fiscalAgentUsers.map(user => {
        const userDetails = user.split(fiscalAgentUserDelimiter);
        return userDetails.length === 2 ? userDetails[1] : null;
    });

    return {
        fiscalAgentId,
        fiscalAgentUserIds: fiscalAgentUserIds.filter(f => !isNullOrUndefined(f))
    };
}

export function removeProjectUsersAfterFiscalAgentsUpdated(fiscalAgentIds, projectUserIds) {
    if(isArrayNullOrEmpty(fiscalAgentIds)) return [];

    return projectUserIds.filter(user => {
        const userDetails = user.split(fiscalAgentUserDelimiter);
        const fiscalAgentId = userDetails.length === 2 ? userDetails[0] : "";
        return fiscalAgentIds.includes(fiscalAgentId);
    });
}