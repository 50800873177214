import React from 'react';
import PropTypes from "prop-types";
import AuditBar from "../../../../components/common/buttons/AuditBar";
import * as ActionBarPositions from "../../../../constants/ActionBarPositions";
import * as apiParameters from "../../../../constants/api/apiParameters";
import audit_outcomes from "../../../../constants/auditOutcomes";
import gmsApi from "../../gmsApi";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import GmsApplicationFormAuditHistorySection from "../ApplicationForm/GmsApplicationFormAuditHistorySection";
import {allow} from '../../../../components/authorization/AuthorizationUtilities';
import {GmsPolicy, policyEvents} from '../../../../components/authorization/policies/GmsPolicy';

export const GmsApplicationBudgetAudit = ({
                                           actions,
                                           applicationId,
                                           budget,
                                           budgetId,
                                           canUserEditBudget,
                                           isFiscalAgentUser,
                                           setBudget
                                       }) => {
    const handleAuditResponseAsync = (auditAction) => {
        actions.executeApi(gmsApi.auditResponse, [applicationId, budget.budgetElementId, auditAction])
            .then((result) => setBudget(result));
    };

    const canAudit = allow(GmsPolicy, policyEvents.ADMIN.audit);

    const handleChangeOutcome = canAudit && !isFiscalAgentUser && canUserEditBudget
        ? (event) => {
            event.preventDefault();
            const auditOutcome = event.target.id.split("_")[0];

            switch (auditOutcome) {
                case audit_outcomes.ACCEPTED.name:
                    handleAuditResponseAsync(apiParameters.audit.APPROVE);
                    break;
                case audit_outcomes.NEEDS_REVISION.name:
                    handleAuditResponseAsync(apiParameters.audit.REVISE);
                    break;
                case audit_outcomes.RESET.name:
                    handleAuditResponseAsync(apiParameters.audit.RESET);
                    break;
            }
        }
        : undefined;

    return (
        <>
            {
                handleChangeOutcome &&
                !isArrayNullOrEmpty(budget.applicationSectionAudits) &&
                <AuditBar
                    disabled={!canUserEditBudget}
                    elementId={`budget-audit-${budgetId}`}
                    onOutcomeChange={handleChangeOutcome}
                    outcome={budget.currentApplicationSectionAuditStatus}
                    policy={GmsPolicy}
                    policyEvent={policyEvents.ADMIN.audit}
                    position={ActionBarPositions.TOP_RIGHT}
                />
            }
            <GmsApplicationFormAuditHistorySection
                applicationId={applicationId}
                actions={actions}
                audits={budget.applicationSectionAudits}
                isFiscalAgentUser={isFiscalAgentUser}
                sectionId={budget.budgetElementId}
                setSection={setBudget}
            />
        </>
    );
};


GmsApplicationBudgetAudit.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    budget: PropTypes.object.isRequired,
    budgetId: PropTypes.string.isRequired,
    canUserEditBudget: PropTypes.bool.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    setBudget: PropTypes.func.isRequired
};