import React from "react";
import {MONITORING_COMPLIANCE} from "../../constants/contexts";
import {FilterView, FilterViewPropTypes} from "../common/searchCriterias/FilterView";

export const McFilter = ({
                             dateWindows,
                             handleExport,
                             handleSearchFiltersChange,
                             numberOfFilterColumns = 0,
                             searchCriteria
                         }) => {
    return <FilterView
        context={MONITORING_COMPLIANCE}
        dateWindows={dateWindows}
        handleExport={handleExport}
        handleSearchFiltersChange={handleSearchFiltersChange}
        numberOfFilterColumns={numberOfFilterColumns}
        searchCriteria={searchCriteria}
    />;
};

McFilter.propTypes = FilterViewPropTypes;