import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy} from "../../../../components/authorization/policies/BpiePolicy";
import {bpie} from "../../../../constants/policyEvents";
import {BPIE_LANDING_WITH_SCHOOL_BPIE} from "../../bpieLocations";
import BpiePrioritiesView from "../../Assessment/AssessmentPriorities/BpiePrioritiesView";
import sbpieAssessmentApi from "../sbpieAssessmentApi";

const SchoolAssessmentPrioritiesContainer = ({
                                     actions,
                                     dataChanged,
                                     handleAssessmentRedirect,
                                     handleDataChanged,
                                     handleSummaryRedirect,
                                     history,
                                     loadAssessment,
                                     schoolBpie
                                 }) => {
    const [assessment, setAssessment] = useState(null);
    const canEdit = AuthorizationUtilities.allow(BpiePolicy, bpie.schoolBpieAssessments.MODIFY);

    const savePriorityIndicators = (priorityBpieIndicatorIds) => {
        actions.executeApi(sbpieAssessmentApi.saveSbpieIndicatorPriorities, [schoolBpie.schoolBpieId, priorityBpieIndicatorIds]);
    }

    const returnToSummary = () => {
        handleSummaryRedirect(schoolBpie.schoolBpieId);
    }

    const onEditAssessment = (desiredIndicator) => {
        handleAssessmentRedirect(schoolBpie.schoolBpieId, desiredIndicator);
    }

    useEffect(() => {
        if (!schoolBpie.isActive)
            history.push(BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(schoolBpie.schoolBpieId));
    }, []);

    useEffect(() => {
        if (!schoolBpie)
            return;

        loadAssessment(setAssessment);
    }, [schoolBpie]);

    if (!schoolBpie || !assessment)
        return null;

    return <BpiePrioritiesView
                canEdit={canEdit}
                dataChanged={dataChanged}
                handleDataChanged={handleDataChanged}
                indicators={assessment.indicators}
                initialResponses={assessment.responses}
                isBpieActive={schoolBpie.isActive}
                onEditAssessment={onEditAssessment}
                pageTitle={`School Priority Indicators`}
                returnToSummary={returnToSummary}
                savePriorityIndicators={savePriorityIndicators}
            />;
}

SchoolAssessmentPrioritiesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSummaryRedirect: PropTypes.func.isRequired,
    history: PropTypes.object,
    loadAssessment: PropTypes.func.isRequired,
    schoolBpie: PropTypes.object.isRequired
}

export default SchoolAssessmentPrioritiesContainer;
