import React from 'react';
import PropTypes from 'prop-types';

const GridTable = ({
                       className = "",
                       children,
                       disableHorizontalScroll,
                       id = "",
                   }) => {

    const containerClass = disableHorizontalScroll ? "" : "table-scroll";

    return (
        <div className={containerClass} id={id}>
            <table className={"table-grid " + className}>
                {children}
            </table>
        </div>
    );
};

GridTable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
    className: PropTypes.string,
    disableHorizontalScroll: PropTypes.bool,
    id: PropTypes.string,
};

export default GridTable;