import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {SMALL_STICKY_HEIGHT} from "../../constants/buttonStickyHeights";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import Button from "./buttons/Button";
import ButtonBar from "./buttons/ButtonBar";
import {isTrimmedStringEmpty} from "./commonUtilities";
import * as ButtonTypes from "../../constants/ButtonTypes";
import FormTable from "./FormTable";
import {convertLineBreaksToBreakTags} from "./HtmlUtilities";
import DisplayField from "./inputs/DisplayField";
import TextArea from "./inputs/TextArea";

const SubmissionModule = ({
                              buttonBarPosition = ButtonBarPositions.MODULE,
                              disableSubmissionFeedback = false,
                              displaySubmissionFeedback = false,
                              handlePrimarySubmit,
                              handleSecondarySubmit,
                              handleSecondaryDownload,
                              hidePrimarySubmissionButton = false,
                              hideSecondarySubmissionButton = false,
                              hideSecondaryDownloadButton = false,
                              makeMainButtonSecondary = false,
                              name,
                              primaryButtonLabel = "Submit",
                              primaryDisabled = false,
                              secondaryDisabled,
                              secondaryButtonLabel = "Submit",
                              secondaryDownloadDisabled,
                              secondaryDownloadButtonLabel = "Download",
                              submissionFeedbackLabel = "",
                              submissionFeedbackValue = "",
                              submissionMessage,
                              submissionTitle,
                              wrapperClass = ""
                          }) => {
    const [feedback, setFeedback] = useState(submissionFeedbackValue);

    const primarySubmitHandler = (event) => {
        event.preventDefault();
        handlePrimarySubmit(feedback);
    }

    const secondarySubmitHandler = (event) => {
        event.preventDefault();
        handleSecondarySubmit(feedback);
    }

    const secondaryDownloadHandler = (event) => {
        event.preventDefault();
        handleSecondaryDownload();
    }

    const primaryButtonType = makeMainButtonSecondary ? ButtonTypes.SECONDARY : ButtonTypes.PRIMARY;
    const secondaryButtonType = makeMainButtonSecondary ? ButtonTypes.PRIMARY : ButtonTypes.SECONDARY;

    return (
        <ButtonBar className={`buttonBar--submission-module ${wrapperClass}`} position={buttonBarPosition}
                   stickyHeight={SMALL_STICKY_HEIGHT}>
            {
                !isTrimmedStringEmpty(submissionTitle) &&
                <h3>{submissionTitle}</h3>
            }
            {
                displaySubmissionFeedback &&
                <FormTable>
                    {
                        !disableSubmissionFeedback &&
                        <TextArea
                            disabled={false}
                            label={submissionFeedbackLabel}
                            name={`feedback`}
                            onChange={(event) => setFeedback(event.target.value)}
                            showLabel
                            value={feedback}
                            wrapperClass={`feedback`}
                        />
                    }
                    {
                        disableSubmissionFeedback &&
                        <DisplayField
                            label={submissionFeedbackLabel}
                            name={`feedback`}
                            showLabel
                            wrapperClass={`feedback-container`}
                        >
                            <HtmlDisplayBlock html={convertLineBreaksToBreakTags(feedback)}/>
                        </DisplayField>
                    }
                </FormTable>
            }
            {
                !isTrimmedStringEmpty(submissionMessage) &&
                <HtmlDisplayBlock html={submissionMessage}/>
            }
            <div className={`button--container`}>
                {
                    !hideSecondarySubmissionButton &&
                    handleSecondarySubmit &&
                    <Button
                        buttonType={secondaryButtonType}
                        disabled={secondaryDisabled}
                        label={secondaryButtonLabel}
                        name={`${name}_secondary`}
                        onClick={secondarySubmitHandler}
                        showLabel
                    />
                }
                {
                    !hideSecondaryDownloadButton &&
                    handleSecondaryDownload &&
                    <Button
                        buttonType={secondaryButtonType}
                        disabled={secondaryDownloadDisabled}
                        label={secondaryDownloadButtonLabel}
                        name={`${name}_secondary_download`}
                        onClick={secondaryDownloadHandler}
                        showLabel
                    />
                }
                {
                    !hidePrimarySubmissionButton &&
                    <Button
                        buttonType={primaryButtonType}
                        disabled={primaryDisabled}
                        label={primaryButtonLabel}
                        name={name}
                        onClick={primarySubmitHandler}
                        showLabel
                    />
                }

            </div>
        </ButtonBar>
    );
};

SubmissionModule.propTypes = {
    buttonBarPosition: PropTypes.string,
    disableSubmissionFeedback: PropTypes.bool,
    displaySubmissionFeedback: PropTypes.bool,
    handlePrimarySubmit: PropTypes.func.isRequired,
    handleSecondarySubmit: PropTypes.func,
    handleSecondaryDownload: PropTypes.func,
    hidePrimarySubmissionButton: PropTypes.bool,
    hideSecondarySubmissionButton: PropTypes.bool,
    hideSecondaryDownloadButton: PropTypes.bool,
    makeMainButtonSecondary: PropTypes.bool,
    name: PropTypes.string.isRequired,
    primaryButtonLabel: PropTypes.string,
    primaryDisabled: PropTypes.bool,
    secondaryButtonLabel: PropTypes.string,
    secondaryDisabled: PropTypes.bool,
    secondaryDownloadButtonLabel: PropTypes.string,
    secondaryDownloadDisabled: PropTypes.bool,
    submissionFeedbackLabel: PropTypes.string,
    submissionFeedbackValue: PropTypes.string,
    submissionMessage: PropTypes.string,
    submissionTitle: PropTypes.string,
    wrapperClass: PropTypes.string
};

export default SubmissionModule;