import * as config from "../constants/config";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import * as ApiUtility from "../api/ApiUtility";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";
import {
	getDateWindows,
	getInstitutions,
	getFakeSystemMessage,
	getBeessIndicators,
	getSppIndicators,
	getPtsIndicators} from "../mockData/sharedDataMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSharedDataApi {
	static getInstitutions() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'institutions',
			RequestTypes.GET));
	}

	static addInstitution(institution) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'institutions',
			RequestTypes.POST,
			JSON.stringify(institution),
			ApiSuccessMessages.SHARED_DATA_ADD_INSTITUTION,
			'application/json'));
	}

	static updateInstitution(institution) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'institutions',
			RequestTypes.PUT,
			JSON.stringify(institution),
			ApiSuccessMessages.SHARED_DATA_UPDATE_INSTITUTION,
			'application/json'));
	}

	static deleteInstitution(institution) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'institutions',
			RequestTypes.DELETE,
			JSON.stringify(institution),
			ApiSuccessMessages.SHARED_DATA_DELETE_INSTITUTION,
			'application/json'));
	}

	static getSppIndicators() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/spp-indicators',
			RequestTypes.GET));
	}

	static getPtsIndicators() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/pts-indicators',
			RequestTypes.GET));
	}

	static getBeessIndicators(protocolStandardsYearToCheck) {
		let data = { protocolStandardsYearToCheck };
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/beess-indicators',
			RequestTypes.GET,
			data
		));
	}

	static getAllIndicators(){
		let request = AjaxRequestOptions(
			config.apiUrl + 'indicators',
			RequestTypes.GET,
			{includeDeleted: false});

		request.contentType = 'application/json';

        return ApiUtility.sendRequest(request);
	}

	static addSppIndicator(indicator) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/spp-indicators',
			RequestTypes.POST,
			JSON.stringify(indicator),
			ApiSuccessMessages.SHARED_DATA_ADD_INDICATOR));
	}

	static addPtsIndicator(indicator) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/pts-indicators',
			RequestTypes.POST,
			JSON.stringify(indicator),
			ApiSuccessMessages.SHARED_DATA_ADD_INDICATOR));
	}

	static addBeessIndicator(indicator) {
		let request = AjaxRequestOptions(
			config.apiUrl + 'indicators/beess-indicators',
			RequestTypes.POST,
			JSON.stringify(indicator),
			ApiSuccessMessages.SHARED_DATA_ADD_INDICATOR);

		request.dataType = 'json';

		return ApiUtility.sendRequest(request);
	}

	static updateIndicator(indicator) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators/indicators',
			RequestTypes.PUT,
			JSON.stringify(indicator),
			ApiSuccessMessages.SHARED_DATA_UPDATE_INDICATOR));
	}

	static deleteIndicator(indicator) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'indicators',
			RequestTypes.DELETE,
			JSON.stringify(indicator),
			ApiSuccessMessages.SHARED_DATA_DELETE_INDICATOR));
	}

	static getAllDateWindows() {
		let request = AjaxRequestOptions(
			config.apiUrl + 'dateWindows',
			RequestTypes.GET);

		request.dataType = 'json';

		return ApiUtility.sendRequest(request);
	}

	static getSystemMessage() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'settings/system-message',
			RequestTypes.GET));
	}

	static saveSystemMessage(message) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'settings/system-message',
			RequestTypes.POST,
			JSON.stringify(message),
			ApiSuccessMessages.SHARED_DATA_SAVE_SYSTEM_MESSAGE));
	}

	static getAppSettings() {
		let request = AjaxRequestOptions(
			config.apiUrl + 'appSettings',
			RequestTypes.GET);

		request.dataType = 'json';

		return ApiUtility.sendRequest(request);
	}

	static saveSystemAccessRestriction(accessIsRestricted){
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'accounts/settings/system-restrict',
			RequestTypes.POST,
			{restrict: accessIsRestricted},
			ApiSuccessMessages.SHARED_DATA_SAVE_SYSTEM_ACCESS_RESTRICTION,
			`application/x-www-form-urlencoded`));
	}
}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockSharedDataApi {
	static getInstitutions() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(getInstitutions());
			}, config.environment.delay);
		});
	}

	static addInstitution() {
		const newId = Math.random().toString();

		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static updateInstitution() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteInstitution() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getSppIndicators() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(getSppIndicators());
			}, config.environment.delay);
		});
	}

	static getPtsIndicators() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(getPtsIndicators());
			}, config.environment.delay);
		});
	}
	static getBeessIndicators() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(getBeessIndicators());
			}, config.environment.delay);
		});
	}

    static getAllIndicators() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve([...getSppIndicators(), ...getBeessIndicators()]);
			}, config.environment.delay);
		});
	}

	static addSppIndicator() {
		const newId = Math.random().toString();

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static addBeessIndicator() {
		const newId = Math.random().toString();

		return new Promise(resolve => {
			setTimeout(() => {
				resolve(newId);
			}, config.environment.delay);
		});
	}

	static updateIndicator() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteIndicator() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getAllDateWindows() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(getDateWindows());
			}, config.environment.delay);
		});
	}

	static getSystemMessage() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(getFakeSystemMessage());
			}, config.environment.delay);
		});
	}

	static saveSystemMessage() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getAppSettings() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveSystemAccessRestriction(){
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}
}

const SharedDataApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSharedDataApi : MockSharedDataApi;
export default SharedDataApi;