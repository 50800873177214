import React, {useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {createListItem} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {FieldsetLayout} from "../../../../components/common/inputs";
import CheckBoxList from "../../../../components/common/inputs/CheckBoxList";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {gridConstants} from "../../../../constants/inputConstants";
import gmsApi from "../../gmsApi";
import {FORM_TYPE} from "../../gmsConstants";

export const GmsApplicationPrintSetupView = ({
                                                 actions,
                                                 application,
                                                 handleReturnToSummary,
                                                 handlePrintApplication,
                                                 reportStatuses,
                                                 selectedApplicationHistory,
                                             }) => {
    const [selectedSections, setSelectedSections] = useState([]);

    const getMainSections = () => {
        let mainSections = [];
        for (let index = 1; index < application.elements.length; index++) {
            const currentSectionElements = application.elements[index].elements.map((e) => createListItem(e.elementId, e.elementText));
            mainSections = [...mainSections, ...currentSectionElements];
        }

        return mainSections;
    };

    const mainSections = getMainSections();

    const handleClickReturnToSummary = (event) => {
        event.preventDefault();
        handleReturnToSummary();
    };

    const handleClickPrintApplication = (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            handlePrintApplication(selectedSections);
        }
    };

    const validateForm = () => {
        const isValid = selectedSections.length > 0;
        if (!isValid)
            NotifyUser.Warning("Please select at least one section.");

        return isValid;
    };

    const handleChangeSelectedSections = (event) => {
        setSelectedSections(event.target.value);
    };

    const handleClickGenerateForm = (event, formType) => {
        event.preventDefault();
        const applicationHistoryId = selectedApplicationHistory || emptyGuid;
        actions.executeApi(gmsApi.downloadGeneratedForm, [application.applicationId, formType, applicationHistoryId]);
    }

    const handleClickSignedForm = (event, formType) => {
        event.preventDefault();
        const applicationHistoryId = selectedApplicationHistory || emptyGuid;
        actions.executeApi(gmsApi.downloadSignedForm, [application.applicationId, formType, applicationHistoryId]);
    }

    return (
        <>
            <FormTable>
                <GridRow large={gridConstants.column.TWO}>
                    <GridColumn columnClass={`padding-right`}>

                        <CheckBoxList
                            onChange={handleChangeSelectedSections}
                            legend={`Sections`}
                            name={`cbSelectedSections`}
                            options={mainSections}
                            showSelectAll
                            value={selectedSections}
                        />
                        <br/>
                    </GridColumn>
                    <GridColumn>
                        <FieldsetLayout legend={`Individually Downloaded PDFs`} showLegend={reportStatuses.anyFormsAvailable}>
                            <ul className={`reportList`}>
                                {
                                    reportStatuses.hasUnsignedDoe100Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"Unsigned DOE 100 Form"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickGenerateForm(e, FORM_TYPE.doe100)}
                                        />
                                    </li>
                                }
                                {
                                    reportStatuses.hasSignedDoe100Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"Signed DOE 100 Form"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickSignedForm(e, FORM_TYPE.doe100)}
                                        />
                                    </li>
                                }
                                {
                                    reportStatuses.hasDoe101Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"DOE 101"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickGenerateForm(e, FORM_TYPE.doe101)}
                                            />
                                        </li>
                                }

                                {
                                    reportStatuses.hasUnsignedDoe150Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"Unsigned DOE 150 Form"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickGenerateForm(e, FORM_TYPE.doe150)}
                                            />
                                        </li>
                                }
                                {
                                    reportStatuses.hasSignedDoe150Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"Signed DOE 150 Form"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickSignedForm(e, FORM_TYPE.doe150)}
                                        />
                                    </li>}
                                {
                                    reportStatuses.hasDoe151Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"DOE 151"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickGenerateForm(e, FORM_TYPE.doe151)}
                                            />
                                        </li>
                                }

                                {
                                    reportStatuses.hasSignedDoe200Form &&
                                    <li>
                                        <Button
                                            showLabel
                                            buttonType={ButtonTypes.DOWNLOAD}
                                            label={"DOE 200"}
                                            name="btnDownload"
                                            onClick={(e) => handleClickSignedForm(e, FORM_TYPE.doe200)}
                                            />
                                        </li>
                                }
                            </ul>
                        </FieldsetLayout>
                    </GridColumn>
                </GridRow>
            </FormTable>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.PRINT}
                    label={"Print Selected Sections"}
                    name="btnPrint"
                    onClick={handleClickPrintApplication}
                />
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={"Return"}
                    name="btnReturn"
                    onClick={handleClickReturnToSummary}
                />
            </ButtonBar>
        </>
    );
};

GmsApplicationPrintSetupView.propTypes = {
    actions: PropTypes.object.isRequired,
    application: PropTypes.object,
    handleReturnToSummary: PropTypes.func.isRequired,
    handlePrintApplication: PropTypes.func.isRequired,
    reportStatuses: PropTypes.object.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
};