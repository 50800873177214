import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import * as commonUtilities from "../../components/common/commonUtilities";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import AssessmentIndicatorRequirement from "../../components/MonitoringCompliance/AssessmentIndicatorRequirement";
import AssessmentIndicatorRequirementForm from "../../components/MonitoringCompliance/AssessmentIndicatorRequirementForm";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import {emptyGuid} from "../../constants/config";
import Classification from "../../components/MonitoringCompliance/Classification";
import {NotifyUser} from "../../components/common/NotifyUser";
import {Allow} from "../../components/authorization/Allow";
import {monitoring_compliance} from "../../constants/policyEvents";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";

export class ManageDistrictProtocolsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			titleIsSet: false,
			classification: "",
			indicatorsLoaded: false
		};

		this.onClickAddAssessment = this.onClickAddAssessment.bind(this);
		this.onClickAddRequirement = this.onClickAddRequirement.bind(this);
		this.onClickEditRequirement = this.onClickEditRequirement.bind(this);
		this.onClickDeleteRequirement = this.onClickDeleteRequirement.bind(this);
		this.onChangeRequirement = this.onChangeRequirement.bind(this);
		this.onSaveRequirement = this.onSaveRequirement.bind(this);
		this.onCancelRequirement = this.onCancelRequirement.bind(this);
		this.onClassificationChange = this.onClassificationChange.bind(this);
		this.onClickDeleteAssessment = this.onClickDeleteAssessment.bind(this);
		this.onClickEditClassification = this.onClickEditClassification.bind(this);
		this.onClickCancelEditClassification = this.onClickCancelEditClassification.bind(this);
		this.onClickSaveClassification = this.onClickSaveClassification.bind(this);
	}

	componentDidMount() {
		this.updatePageTitle();

		if(this.props.dateWindows.length > 0) {
			const dateWindowStartYear = this.props.dateWindows.find(f => f.value === this.props.dateWindowId).text.substring(0, 4);
			this.props.actions.loadBeessIndicators(dateWindowStartYear);
		}
	}

	componentDidUpdate(prevProps) {
		if((prevProps.dateWindowId !== this.props.dateWindowId || !this.state.indicatorsLoaded) && this.props.dateWindows.length > 0) {
			const dateWindowStartYear = this.props.dateWindows.find(f => f.value === this.props.dateWindowId).text.substring(0, 4);
			this.props.actions.loadBeessIndicators(dateWindowStartYear);
			this.setState({indicatorsLoaded: true});
		}

		this.updatePageTitle();

		if(this.state.classification === "" &&
			this.props.selectedAssessment &&
			this.props.selectedAssessment.monitoringAssessmentId !== undefined &&
			this.props.selectedAssessment.monitoringAssessmentId !== emptyGuid) {
			this.setState({classification: this.props.selectedAssessment.classification});
		}
	}

	updatePageTitle() {
		if (!this.state.titleIsSet && this.props.institutions.length > 0) {
			const pageTitle = MonitoringComplianceUtilities.createPageTitle(this.props.institutions, this.props.districtId, "Required Protocols");
			this.props.actions.updatePageTitle(pageTitle);

			this.setState({
				titleIsSet: true
			});
		}
	}

	onClassificationChange(event) {
		this.setState({classification: event.target.value});
		this.props.handleDataChanged();
	}

	assessmentValid() {
		let isValid = true;

		if(this.state.classification === "") {
			NotifyUser.Error("District type is required");
			isValid = false;
		}

		return isValid;
	}

	onClickAddAssessment() {
		if(this.assessmentValid()) {
			const assessmentId = emptyGuid;

			const newAssessment = MonitoringComplianceUtilities.newComplianceAssessment(
				this.props.districtId,
				this.props.dateWindowId,
				assessmentId,
				[],
				this.state.classification);

			this.props.actions.createDistrictComplianceAssessment(newAssessment)
				.then(() => {
					this.props.handleDataChanged(false);
				});
		}
	}

	onClickDeleteAssessment(event) {
		event.preventDefault();

		if(confirm("Are you sure you want to delete this compliance assessment?  It will remove all associated information, including student records and validations.")) {
			this.props.actions.deleteDistrictComplianceAssessment(this.props.districtId, this.props.selectedAssessment.monitoringAssessmentId)
				.then(() => {
					this.props.handleDataChanged(false);
					this.props.history.push(ComplianceLocations.LANDING_DOE_DATE_WINDOW.path
						.replace(ComplianceLocations.DATE_WINDOW_ID, this.props.dateWindowId));
				});
		}
	}

	onClickEditClassification(event) {
		event.preventDefault();

		this.props.actions.editClassification();
	}

	onClickCancelEditClassification(event) {
		event.preventDefault();
		this.props.actions.cancelClassificationEdit();
		this.props.handleDataChanged(false);
	}

	onClickSaveClassification(event) {
		event.preventDefault();
		if(this.assessmentValid()) {
			let assessment = Object.assign({}, this.props.selectedAssessment);
			assessment.classification = this.state.classification;
			this.props.actions.updateDistrictComplianceAssessment(assessment)
				.then(() => {
					this.props.handleDataChanged(false);
					this.props.loadComplianceAssessment();
				});
		}
	}

	onClickAddRequirement() {
		const newRequirement = MonitoringComplianceUtilities.newIndicatorRequirement();
		this.props.actions.addProtocolRequirement(this.props.selectedAssessment.monitoringAssessmentId, newRequirement);
		this.props.handleDataChanged();
	}

	onClickEditRequirement(event, indicatorRequirement) {
		event.preventDefault();
		this.props.actions.editProtocolRequirement(indicatorRequirement);
		this.props.handleDataChanged();
	}

	onClickDeleteRequirement(event, indicatorRequirementId) {
		if (confirm("Are you sure you want to delete this requirement?  All associated student records will be deleted as well.")) {
			const updatedRequirements = this.props.selectedAssessment.indicatorRequirements
				.reduce((agg, ind) => {
					if (ind.indicatorRequirementId !== indicatorRequirementId)
						agg.push(ind);

					return agg;
				}, []);

			this.props.actions.saveDistrictRequiredProtocols(
				this.props.selectedAssessment.monitoringAssessmentId,
				this.props.dateWindowId,
				updatedRequirements);

			this.props.handleDataChanged(false);
		}
	}

	onChangeRequirement(event, indicatorRequirementId) {
		const theRequirement = MonitoringComplianceUtilities.getProtocolRequirement(
			this.props.selectedAssessment.indicatorRequirements,
			indicatorRequirementId);

		const name = event.target.id;
		theRequirement[name] = event.target.value;

		if (name === "indicatorId") {
			//needed for display purposes
			theRequirement["indicator"] = this.props.beessIndicators.find(ind => ind.id === event.target.value).text;
		}

		this.props.actions.changeProtocolRequirement(theRequirement);
	}

	onSaveRequirement(event) {
		event.preventDefault();
		let updatedRequirements = [...this.props.selectedAssessment.indicatorRequirements];

		updatedRequirements = updatedRequirements.sort(commonUtilities.dynamicSort(updatedRequirements));

		this.props.actions.saveDistrictRequiredProtocols(
			this.props.selectedAssessment.monitoringAssessmentId,
			this.props.dateWindowId,
			updatedRequirements)
			.then(() => {
				this.props.handleDataChanged(false);
				this.props.loadComplianceAssessment();
			});
	}

	onCancelRequirement(event) {
		event.preventDefault();
		this.props.actions.cancelProtocolRequirementEdit();
		this.props.handleDataChanged(false);
	}

	isEditingClassification() {
		return this.isNewAssessment() ||
			(this.props.isEditing && !this.props.editItemOriginalCopy.indicatorRequirementId);
	}

	isNewAssessment() {
		return !this.props.selectedAssessment ||
			this.props.selectedAssessment.monitoringAssessmentId === emptyGuid;
	}

	indicatorsAvailable() {
		let indicatorIdsAdded = [];
		if(this.props.selectedAssessment &&
			this.props.selectedAssessment.indicatorRequirements) {
			indicatorIdsAdded = this.props.selectedAssessment.indicatorRequirements.map(f => f.indicatorId);
		}
		let beessIndicatorsAvailable = this.props.beessIndicators.filter(f => indicatorIdsAdded.indexOf(f.id) === -1);

		return beessIndicatorsAvailable;
	}

	render() {
		return (
			<section>
				{
                    this.props.dateWindowId &&
                    this.props.districtId &&
					<section className="compliance__requiredProtocol">
						{
							!this.isNewAssessment() &&
							<h3>Required Protocols</h3>
						}
						<Classification
							isEditing={this.isEditingClassification()}
							isNew={this.isNewAssessment()}
							classification={this.state.classification}
							onClassificationChange={this.onClassificationChange}
							onDeleteClick={(this.isNewAssessment()) ? null : this.onClickDeleteAssessment}
							onEditClick={(this.isNewAssessment()) ? null : this.onClickEditClassification}
							isLoading={this.props.isLoading}
							onCancelClick={this.onClickCancelEditClassification}
							onSaveClick={this.onClickSaveClassification}
						/>
						{
							this.props.selectedAssessment &&
							this.props.selectedAssessment.indicatorRequirements &&
							this.props.selectedAssessment.indicatorRequirements.map(ind => {
								if (!this.props.isEditing || ind.indicatorRequirementId !== this.props.editItemOriginalCopy.indicatorRequirementId) {
									return (
										<AssessmentIndicatorRequirement
											key={ind.indicatorRequirementId}
											indicatorRequirement={ind}
											onDeleteClick={this.onClickDeleteRequirement}
											onEditClick={this.onClickEditRequirement}
											isEditing={this.props.isEditing}
											isLoading={this.props.isLoading}
										/>
									);
								}

								else {
									return (
										<AssessmentIndicatorRequirementForm
											key={ind.indicatorRequirementId}
											indicatorRequirement={ind}
											availableIndicators={this.indicatorsAvailable()}
											onChange={this.onChangeRequirement}
											onSaveClick={this.onSaveRequirement}
											onCancelClick={this.onCancelRequirement}
											indicatorList={this.props.indicators}
											isLoading={this.props.isLoading}
										/>
									);
								}
							})
						}
						{
                            !this.isNewAssessment() &&
							!this.props.isEditing &&
							this.indicatorsAvailable().length > 0 &&
							<Allow policy={MonitoringCompliancePolicy} policyEvent={monitoring_compliance.protocolRequirement.MODIFY}>
								<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
									<Tile body={"Add Protocol Requirement"}
										  onClick={this.onClickAddRequirement}
										  isNew={true}
										  isMedium={true}/>
								</TileContainer>
							</Allow>
						}
						{
							this.isNewAssessment() &&
							<Allow policy={MonitoringCompliancePolicy} policyEvent={monitoring_compliance.assessment.MODIFY}>
								<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
									<Tile body={"Create Compliance Assessment"}
										  onClick={this.onClickAddAssessment}
										  isNew={true}
										  isMedium={true}/>
								</TileContainer>
							</Allow>
						}
					</section>
				}
			</section>
		);
	}
}

ManageDistrictProtocolsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	beessIndicators: PropTypes.arrayOf(PropTypes.object),
	dateWindowId: PropTypes.string,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	districtId: PropTypes.string,
	editItemOriginalCopy: PropTypes.object,
	handleDataChanged: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	indicators: PropTypes.arrayOf(PropTypes.object),
	institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	loadComplianceAssessment: PropTypes.func.isRequired,
	selectedAssessment: PropTypes.object,
	selectedDateWindowId: PropTypes.string
};

function mapStateToProps(state) {
	const filteredBeessIndicators = state.sharedData.beessIndicators.filter(f => f.hasProtocols);

	const indicatorListItems = filteredBeessIndicators.map(
		ind => {
			return {value: ind.id, text: MonitoringComplianceUtilities.createIndicatorTitle(ind.title, ind.publicIdentifier)};
		}
	);

	return {
		isEditing: state.monitoringCompliance.isEditing,
		editItemOriginalCopy: state.monitoringCompliance.editItemOriginalCopy,
		indicators: indicatorListItems,
		beessIndicators: filteredBeessIndicators
	};
}

export default connect(mapStateToProps)(ManageDistrictProtocolsPage);