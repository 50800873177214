import React from "react";
import PropTypes from 'prop-types';
import {DisputeResolutionType} from "../drConstants";
import DrDistrictDueProcess from "./DrDistrictDueProcess";
import DrDistrictMediation from "./DrDistrictMediation";
import DrDistrictStateComplaint from "./DrDistrictStateComplaint";

export const DrDistrictView = ({
                                   actions,
                                   districtId,
                                   history,
                                   searchCriteria
                               }) => {

    const handleClickView = (url) => history.push(url);

    const showStateComplaint = searchCriteria.disputeResolutionType === DisputeResolutionType.StateComplaints;
    const showDueProcessHearingRequests = searchCriteria.disputeResolutionType === DisputeResolutionType.DueProcess;
    const ShowMediation = searchCriteria.disputeResolutionType === DisputeResolutionType.Mediation;

    return (
        <>
            {
                showStateComplaint &&
                <DrDistrictStateComplaint
                    actions={actions}
                    dateWindowId={searchCriteria.dateWindowId}
                    districtId={districtId}
                    handleClickView={handleClickView}
                />
            }
            {
                showDueProcessHearingRequests &&
                <DrDistrictDueProcess
                    actions={actions}
                    dateWindowId={searchCriteria.dateWindowId}
                    districtId={districtId}
                    handleClickView={handleClickView}
                />
            }
            {
                ShowMediation &&
                <DrDistrictMediation
                    actions={actions}
                    dateWindowId={searchCriteria.dateWindowId}
                    districtId={districtId}
                    handleClickView={handleClickView}
                />
            }
        </>
    );
};

DrDistrictView.propTypes = {
    actions: PropTypes.object,
    districtId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired,
};

export default DrDistrictView;