import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import gmsManageApplicationsApi from "./gmsManageApplicationsApi";
import {createGmsSettingsObject, updateMainSectionsObject} from "./gmsManageApplicationsObjectFactory";
import {GmsManageApplicationsView} from "./GmsManageApplicationsView";

const GmsManageApplicationsContainer = ({
                                            actions,
                                            dataChanged,
                                            dateWindowId,
                                            handleDataChanged,
                                        }) => {
    const [gmsSettings, setGmsSettings] = useState(null);
    const [applicationSections, setApplicationSections] = useState(null);

    const handleSaveGmsSettings = (updatedSettings, sectionsObject) => {
        const updatedSections = updateMainSectionsObject(applicationSections, sectionsObject);

        actions.executeApi(gmsManageApplicationsApi.saveGmsSettings, [dateWindowId, updatedSettings])
            .then(actions.executeApi(gmsManageApplicationsApi.saveMainSections, [updatedSections]))
                .then(() => handleDataChanged(false));
    };

    const initializeData = async () => {
        await actions.executeApi(gmsManageApplicationsApi.getGmsSettings, [dateWindowId])
            .then((result) => setGmsSettings(createGmsSettingsObject({...result, dateWindowId})));

        await actions.executeApi(gmsManageApplicationsApi.getMainSections)
            .then((result) => setApplicationSections(result));
    };

    useEffect(() => {
        if (!dateWindowId) return;

        initializeData();
    }, [dateWindowId]);

    if (!gmsSettings || !applicationSections)
        return null;

    return (
        <GmsManageApplicationsView
            applicationSections={applicationSections}
            dataChanged={dataChanged}
            gmsSettings={gmsSettings}
            handleDataChanged={handleDataChanged}
            handleSaveGmsSettings={handleSaveGmsSettings}
        />
    );
};

GmsManageApplicationsContainer.propTypes = {
    actions: PropTypes.object,
    dataChanged: PropTypes.bool,
    dateWindowId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
};

export default GmsManageApplicationsContainer;
