import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import GridTable from "../../../components/common/GridTable";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";

const DrAdminIntakeVersionView = ({
                                      handleDeleteIntakeRequirementVersion,
                                      intakeRequirements,
                                      setEditVersionId,
                                      setViewVersionId
                                  }) => {
    const onAddEditClick = (event, versionId) => {
        event.preventDefault();
        setEditVersionId(versionId);
    };

    const onViewClick = (event, versionId) => {
        event.preventDefault();
        setViewVersionId(versionId);
    };

    const onDeleteClick = (event, versionId) => {
        event.preventDefault();
        handleDeleteIntakeRequirementVersion(versionId);
    };

    return (
        <>
            <ButtonBar position={ButtonBarPositions.STICKY}>
                <Button
                    buttonType={ButtonTypes.ADD}
                    label={`Add Intake Version`}
                    name={`btnAddIntakeVersion`}
                    onClick={(event) => onAddEditClick(event, emptyGuid)}
                    showLabel
                />
            </ButtonBar>
            <GridTable className={`intake-versions-table`}>
                <thead>
                <tr>
                    <th>Version</th>
                    <th>Status</th>
                    <th>Date Created</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    intakeRequirements.map((version, key) =>
                        (<tr key={key}>
                            <td>{version.description}</td>
                            <td>{version.statusOutput}</td>
                            <td>{version.dateCreated}</td>
                            <td className={"text-center actions"}>
                                <Button
                                    buttonType={ButtonTypes.CONTENT}
                                    label={`View Intake Requirements for ${version.description}`}
                                    name={`btnView_${version.versionId}`}
                                    onClick={(event) => onViewClick(event, version.versionId)}
                                    showLabel={false}
                                />
                                <Button
                                    buttonType={ButtonTypes.EDIT}
                                    label={`Edit Intake Version for ${version.description}`}
                                    name={`btnEdit_${version.versionId}`}
                                    onClick={(event) => onAddEditClick(event, version.versionId)}
                                    showLabel={false}
                                />
                                {
                                    !version.hasResponses &&
                                    intakeRequirements.length > 1 &&
                                    <Button
                                        buttonType={ButtonTypes.DELETE}
                                        label={`Delete Intake Version for ${version.description}`}
                                        name={`btnDelete_${version.versionId}`}
                                        onClick={(event) => onDeleteClick(event, version.versionId)}
                                        showLabel={false}
                                    />
                                }
                            </td>
                        </tr>)
                    )
                }
                </tbody>
            </GridTable>
        </>
    );
};

DrAdminIntakeVersionView.propTypes = {
    handleDeleteIntakeRequirementVersion: PropTypes.func.isRequired,
    intakeRequirements: PropTypes.array.isRequired,
    setEditVersionId: PropTypes.func.isRequired,
    setViewVersionId: PropTypes.func.isRequired
};

export default DrAdminIntakeVersionView;