import React from "react";
import PropTypes from "prop-types";
import TextField from "../common/inputs/TextField";
import ButtonIcon from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const ResourceForm = ({resource, isLoading, onSave, onCancel, onChange}) => {
	return (
		<tr id={resource.id}>
			<td>
				<TextField name={"text"}
				           label={"Resource Text"}
				           showLabel={false}
				           value={resource.text}
				           placeholder={"Resource Text"}
				           onChange={onChange}/>
			</td>
			<td className="button-cell">
				<ButtonBar position={ButtonBarPositions.TABLE}>
					<ButtonIcon name={"save"}
					            label={"save"}
					            showLabel={false}
					            onClick={onSave}
					            disabled={isLoading}
					            buttonType={ButtonTypes.SAVE}
					/>
					<ButtonIcon name={"cancel"}
					            label={"cancel"}
					            showLabel={false}
					            onClick={onCancel}
					            disabled={isLoading}
					            buttonType={ButtonTypes.CANCEL}
					/>
				</ButtonBar>
			</td>
		</tr>
	);
};

ResourceForm.propTypes = {
	resource: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired
};

export default ResourceForm;