import "babel-polyfill";

import React from "react";
import {render} from "react-dom";
import configureStore, {history} from "./store/configureStore";
import Root from './containers/Root';
import '@progress/kendo-theme-default/dist/all.css';

const store = configureStore();

render(
	<Root store={store} history={history} />, document.getElementById("app")
);