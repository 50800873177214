import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import PrintTable from "../../../components/common/PrintTable";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import api from "../../../api/MonitoringComplianceReportsApi";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";

const StudentPrintView = ({
                             actions,
                             dateWindowId,
                             districtId,
                             dateWindows
                         }) => {
    const [studentReportInfo, setStudentReportInfo] = useState(null);

    const handleUpdateStudentReportInfo = (studentReportInfo) => {
        setStudentReportInfo(studentReportInfo);
    };

    const getStudentReportInfo = async () => {
        await actions.executeApi(api.GetStudentReport, [districtId, dateWindowId])
            .then((results) => handleUpdateStudentReportInfo(results));
    };

    useEffect(() => {
        getStudentReportInfo();
    }, []);

    if(!studentReportInfo)
        return null;

    return (
        <div className={"compliance-print-report"}>
            {
                studentReportInfo.districtName === "" &&
                <p className={"no-report"}>No report available for this district.</p>
            }
            {
                studentReportInfo.districtName !== "" &&
                <>
                    <div className={"report-header"}>
                        <h2>{fetchDate(dateWindows, dateWindowId)} Self-Assessment</h2>
                        <h2>Findings of Noncompliance — {studentReportInfo.districtName} District Student Report</h2>
                    </div>
                    <div className={"report-summary"}>
                        <p>This report includes the results for individual student record reviews and must be used in implementing student-specific corrective actions.
                            Results are based on the following:</p>
                        <GridRow>
                            <GridColumn medium={"6"}>

                                {
                                    studentReportInfo.indicators.map((info, index) => {
                                        return (
                                            <ul key={index}>
                                                <li>Number of {info.title} protocols completed: {info.totalRecords}</li>
                                                <li>Number of standards per {info.title} protocol: {info.totalStandards}</li>
                                            </ul>
                                        );
                                    })
                                }
                            </GridColumn>
                            <GridColumn medium={"6"}>
                                <ul>
                                    <li>Total number of protocols: {studentReportInfo.totalProtocols}</li>
                                    <li>Total number of standards: {studentReportInfo.totalStandards}</li>
                                    <li>Total number of findings of noncompliance (NC): {studentReportInfo.totalFindingsOfNonCompliance}</li>
                                    <li>Overall % findings of noncompliance: {studentReportInfo.percentNonCompliance}</li>
                                </ul>

                                <ul>
                                    <li>Total number of different standards assessed: {studentReportInfo.totalUniqueStandards}</li>
                                    <li>Total number of different standards for which noncompliance was identified: {studentReportInfo.totalUniqueFindingsOfNonCompliance}</li>
                                    <li>% of different standards for which noncompliance was identified: {studentReportInfo.percentUniqueNonCompliance}</li>
                                </ul>
                            </GridColumn>
                        </GridRow>
                        <p><strong>* Correctable for this student:</strong> A finding which requires immediate action(s) to correct the noncompliance</p>
                        <p><strong>** Ensure Future Compliance:</strong> A finding which cannot be corrected for the individual student and which therefore requires immediate
                            action(s) to address how the district will ensure future compliance</p>

                        <p>Note: Each record review has a unique student identifier assigned. When reviewing corrective actions to be implemented for individual students,
                            be aware that multiple protocols/student identifiers may apply to a given student (e.g., IEP_ABC_1 and T16_ABC_1 reflect one student`s record),
                            and correction should be planned accordingly.</p>
                    </div>
                    <PrintTable>
                        <thead>
                        <tr>
                            <th>Student ID</th>
                            <th colSpan={"2"}>Findings of Noncompliance</th>
                            <th>*Correctable for This Student</th>
                            <th>**Ensure Future Compliance</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            studentReportInfo.nonComplianceRecords.map((record, index) => {
                                const rowClass = index % 2 === 1 ? "odd" : "";

                                if(record.protocolResponses.length === 0)
                                    return (
                                        <tr key={`${index}_None`} className={rowClass}>
                                            <td valign={"top"} className={"response-no-wrap"}>{record.studentId}</td>
                                            <td colSpan={"4"}>No responses yet.</td>
                                        </tr>
                                    );

                                return record.protocolResponses.map((response, rIndex) => {
                                    return (
                                        <tr key={`${index}_${rIndex}`} className={rowClass}>
                                            {
                                                rIndex === 0 &&
                                                <td rowSpan={record.protocolResponses.length} valign={"top"} className={"response-no-wrap"}>{record.studentId}</td>
                                            }
                                            <td valign={"top"} className={"response-no-wrap"}>{response.protocol}</td>
                                            <td><HtmlDisplayBlock html={`${response.standardDescription}<br/>${response.citation}`}/></td>
                                            <td className={"text-center"} valign={"top"}>{response.correctable}</td>
                                            <td className={"text-center"} valign={"top"}>{response.ensuresFutureCompliance}</td>
                                        </tr>
                                    );
                                });
                            })
                        }
                        </tbody>
                    </PrintTable>
                </>
            }
        </div>
    );
};

StudentPrintView.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    dateWindows: PropTypes.array.isRequired
};

export default React.memo(StudentPrintView);