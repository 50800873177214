import React from "react";
import PropTypes from 'prop-types';
import CollapsibleContainer from "../../../components/common/CollapsibleContainer";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DrComplaintTimelineRequirementResponseOptionsEnum} from "../../drConstants";
import DrStateComplaintRoutingReview from "./DrStateComplaintRoutingReview";

const DrStateComplaintRoutingSummary = ({
                                                    timelineResponses,
                                                    routingSummaryDetails
                                                }) => {
    const buildRoutingSummaryTrigger = (detail) => {
        return (
            <GridRow>
                <GridColumn medium={"6"}>
                    {detail.activity}
                </GridColumn>
                <GridColumn medium={"6"}>
                    Due: {detail.dateDue}, Completed: {!detail.dateCompleted ? "" : detail.dateCompleted}
                </GridColumn>
            </GridRow>);
    };

    return (
        <>
            {
                routingSummaryDetails.map((detail, key) => {
                    const currentTimelineResponse = timelineResponses.find(f => f.timelineResponseId === detail.timelineResponseId);
                    let reviews = [];
                    for(let review = currentTimelineResponse.currentRoutingRequirementReviewNumber; review >= 1; review--)
                        reviews.push(review);

                    return (
                        <CollapsibleContainer trigger={buildRoutingSummaryTrigger(detail)} key={key}>
                            <>
                                <p>Team Member - {detail.responder}</p>
                                {
                                    reviews.map((reviewNumber) => {
                                        const routingRequirementResponse = currentTimelineResponse.routingResponseModels.find(f => f.reviewNumber === reviewNumber);
                                        if(!routingRequirementResponse || (routingRequirementResponse.response !== DrComplaintTimelineRequirementResponseOptionsEnum.Approved
                                            && routingRequirementResponse.response !== DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved))
                                            return null;

                                        const routingFeedback = currentTimelineResponse.routingCaseNotes.find(f => f.reviewNumber === reviewNumber);
                                        return (
                                            <DrStateComplaintRoutingReview
                                                key={`review_${reviewNumber}`}
                                                dateReviewed={routingRequirementResponse.dateReviewed}
                                                reviewNumber={reviewNumber}
                                                routingFeedback={routingFeedback}
                                                routingRequirementResponse={routingRequirementResponse}
                                            />
                                        );
                                    })
                                }
                            </>
                        </CollapsibleContainer>
                    );
                })
            }
        </>
    );
};

DrStateComplaintRoutingSummary.propTypes = {
    timelineResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    routingSummaryDetails: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintRoutingSummary;

