import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { Allow } from "../../../components/authorization/Allow";
import { GmsPolicy, policyEvents } from "../../../components/authorization/policies/GmsPolicy";
import GridTable from "../../../components/common/GridTable";
import { isArrayNullOrEmpty } from "../../../components/common/commonUtilities";
import {NotifyUser} from "../../../components/common/NotifyUser";
import ResultsPager from "../../../components/common/pagers/ResultsPager";
import { handleScroll } from "../../../components/common/scrollUtilities";
import Tile from "../../../components/common/Tiles/Tile";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import { emptyGuid } from "../../../constants/config";
import {fileUploadTypes} from "../../../constants/inputConstants";
import * as gmsLocations from "../gmsLocations";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import gmsSummaryApi from "./gmsSummaryApi";
import FileUploadButton from "../../../components/common/inputs/FileUploadButton";

export const GmsSummarySearchResults = ({
                                            actions,
                                            handleClickOrder,
                                            handleSearch,
                                            history,
                                            isFiscalAgentUser,
                                            searchCriteria,
                                            summary
                                        }) => {
    const containerId = "gms-admin-summary";

    const first = (event) => {
        event.preventDefault();
        moveToFirstPage();
    };

    const moveToFirstPage = () => {
        let criteria = { ...searchCriteria };
        criteria.First();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const last = (event) => {
        event.preventDefault();
        let criteria = { ...searchCriteria };
        criteria.Last(summary.length);
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const next = (event) => {
        event.preventDefault();
        let criteria = { ...searchCriteria };
        criteria.Next();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const previous = (event) => {
        event.preventDefault();
        let criteria = { ...searchCriteria };
        criteria.Previous();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const handleViewApplicationClick = (event, applicationId) => {
        event.preventDefault();
        const resolvedPath = gmsLocations.GMS_APPLICATION.getUrl(applicationId);
        history.push(resolvedPath);
    };

    const handleModifyApplicationSettingsClick = (event, applicationId) => {
        event.preventDefault();
        if(!searchCriteria.dateWindowId) {
            NotifyUser.Warning("Please select a date window.");
            return;
        }

        const resolvedPath = gmsLocations.GMS_ADMIN_MODIFY_APPLICATION_SETTINGS.getUrl(searchCriteria.dateWindowId, applicationId);
        history.push(resolvedPath);
    };

    const handleDeleteApplicationSettingsClick = (event, applicationId) => {
        event.preventDefault();
        if(confirm("Are you sure you want to delete the application?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            actions.executeApi(gmsSummaryApi.deleteApplication, [applicationId])
                .then(() => handleSearch(searchCriteria));
    };

    const handleImportClick = (event) => {
        event.preventDefault();
        if(!searchCriteria.dateWindowId) {
            NotifyUser.Warning("Please select a date window.");
            return;
        }

        const importApplications = document.getElementById("importApplications");
        importApplications.click();
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        const file = [...event.target.files];

        if (confirm("Are you sure you want to upload the applications?")) {
            await actions.executeApi(gmsSummaryApi.importApplications, [searchCriteria.dateWindowId, file])
                .then(() => {
                    handleSearch(searchCriteria);
                    clearFileSelection();
                });
        }
    };

    const clearFileSelection = () => {
        const importApplications = document.getElementById("importApplications");
        importApplications.value = "";
    };

    const handleExportClick = (event) => {
        event.preventDefault();
        if(!searchCriteria.dateWindowId) {
            NotifyUser.Warning("Please select a date window.");
            return;
        }

        actions.executeApi(gmsSummaryApi.exportApplications, [searchCriteria.dateWindowId]);
    }

    useEffect(() => {
        if (!searchCriteria.onValidPage(summary.length))
            moveToFirstPage();

    }, [summary.length]);

    const totalColumns = isFiscalAgentUser ? 4 : 5;

    return (
        <>
            <Allow policy={GmsPolicy} policyEvent={policyEvents.ADMIN.manage}>
                <TileContainer rowTilesMedium={"3"} rowTilesLarge={"3"}>
                    <Tile onClick={handleImportClick}
                        body={"Import Applications"}
                        isMedium
                    />
                    <Tile onClick={(event) => handleModifyApplicationSettingsClick(event, emptyGuid)}
                        body={`Add Application`}
                        isMedium
                        isNew
                    />
                    <Tile onClick={handleExportClick}
                        body={"Export Applications"}
                        isMedium
                    />
                </TileContainer>
            </Allow>
            <GridTable id={containerId}>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id="datesCovered" className="is-clickable">
                        Date Window
                    </th>
                    {
                        (!isFiscalAgentUser) &&
                        <th onClick={handleClickOrder} data-id="fiscalAgentName" className="is-clickable">
                            Fiscal Agent
                        </th>
                    }
                    <th onClick={handleClickOrder} data-id={`applicationTypeDescription`} className={`is-clickable`}>
                        Type
                    </th>
                    <th onClick={handleClickOrder} data-id={`applicationStatusDescription`} className={`is-clickable`}>
                        Status
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                    {
                        isArrayNullOrEmpty(summary) &&
                        <tr>
                            <td colSpan={totalColumns} className={"center"}>
                                No results match the search criteria specified.
                            </td>
                        </tr>
                    }
                    {
                        searchCriteria.GetCurrentPageRecords(summary).map((application, key) =>
                        (<tr key={key}>
                            <td>{application.datesCovered}</td>
                            {
                                (!isFiscalAgentUser) &&
                                <td>{application.fiscalAgentName}</td>
                            }
                            <td>{application.applicationTypeDescription}</td>
                            <td>{application.applicationStatusDescription}</td>
                            <td>
                                <Button name={`btnApplication_${application.fiscalAgentName}_${application.applicationTypeDescription}`}
                                    label={`View application for ${application.fiscalAgentName} ${application.applicationTypeDescription} ${application.datesCovered}`}
                                    buttonType={ButtonTypes.VIEW}
                                    showLabel={false}
                                    onClick={(event) => handleViewApplicationClick(event, application.applicationId)}
                                />
                                {
                                    application.canEditApplicationSettings &&
                                    <Allow policy={GmsPolicy} policyEvent={policyEvents.ADMIN.manage}>
                                        <Button name={`btnModifyApplicationSettings_${application.fiscalAgentName}_${application.applicationTypeDescription}`}
                                            label={`Modify application settings for ${application.fiscalAgentName} ${application.applicationTypeDescription} ${application.datesCovered}`}
                                            buttonType={ButtonTypes.EDIT}
                                            showLabel={false}
                                            onClick={(event) => handleModifyApplicationSettingsClick(event, application.applicationId)}
                                        />
                                    </Allow>
                                }
                                {
                                    application.canDeleteApplication &&
                                    <Allow policy={GmsPolicy} policyEvent={policyEvents.ADMIN.manage}>
                                        <Button name={`btnDeleteApplicationSettings_${application.fiscalAgentName}_${application.applicationTypeDescription}`}
                                            label={`Delete application settings for ${application.fiscalAgentName} ${application.applicationTypeDescription} ${application.datesCovered}`}
                                            buttonType={ButtonTypes.DELETE}
                                            showLabel={false}
                                            onClick={(event) => handleDeleteApplicationSettingsClick(event, application.applicationId)}
                                        />
                                    </Allow>
                                }
                            </td>
                        </tr>)
                        )
                    }
                </tbody>
                <>
                    {
                        summary.length > 0 &&
                        <tfoot>
                            <tr>
                                <td colSpan={totalColumns}>
                                    <ResultsPager
                                        currentPage={searchCriteria.Page}
                                        disabled={false}
                                        first={first}
                                        last={last}
                                        next={next}
                                        previous={previous}
                                        recordsPerPage={searchCriteria.GetRecordsPerPage(summary.length)}
                                        totalRecords={summary.length}
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    }
                </>
            </GridTable>
            <FileUploadButton
                className={`is-hidden`}
                disabled={false}
                fileUploadType={fileUploadTypes.EXCEL}
                label={"Import Applications"}
                name={"importApplications"}
                onChange={handleUpload}
            />
        </>
    );
};

GmsSummarySearchResults.propTypes = {
    actions: PropTypes.object.isRequired,
    handleClickOrder: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.object.isRequired,
    summary: PropTypes.array
};