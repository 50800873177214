import React from "react";
import PropTypes from "prop-types";
import {emptyGuid} from "../../../constants/config";
import api from "../drStateComplaintsApi";
import DrStateComplaintResponseView from "./DrStateComplaintResponseView";

const DrStateComplaintResponseContainer = ({
	                                           actions,
	                                           complaint,
	                                           handleUpdateComplaint
                                           }) => {

	const handleSaveResponseAsync = async (response, deadline, issueId) => {
		return await actions.executeApi(api.saveResponse, [response, deadline, complaint.id, issueId])
			.then((updatedComplaint) => {
				handleUpdateComplaint(updatedComplaint);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleSaveResponseReviewAsync = async (review, deadline, issueId) => {
		return await actions.executeApi(api.saveResponseReview, [review, deadline, complaint.id, issueId])
			.then((updatedComplaint) => {
				handleUpdateComplaint(updatedComplaint);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleAuditResponseAsync = async (response, deadline, issueId, auditAction) => {
		return await actions.executeApi(api.auditResponse, [response, deadline, complaint.id, issueId, auditAction])
			.then((updatedComplaint) => {
				handleUpdateComplaint(updatedComplaint);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const saveAuditCommentAsync = async (responseId, deadline, issueId, comment, existing = false) => {
		return await actions.executeApi(api.saveAuditComment, [responseId, deadline, complaint.id, issueId, comment, existing])
			.then((updatedComplaint) => {
				handleUpdateComplaint(updatedComplaint);
				return true;
			})
			.catch(() => {
				return false;
			});
	};

	const handleSaveAuditCommentAsync = async (responseId, deadline, issueId, comment) => {
		if (comment.auditCommentId === "") {
				const auditCommentId = emptyGuid;
				comment = Object.assign({}, comment, {auditCommentId});
				return await saveAuditCommentAsync(responseId, deadline, issueId, comment);
		}
		else {
			return await saveAuditCommentAsync(responseId, deadline, issueId, comment, true);
		}
	};

	if (!complaint) return null;

	return <DrStateComplaintResponseView
		complaint={complaint}
		handleAuditResponseAsync={handleAuditResponseAsync}
		handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
		handleSaveResponseAsync={handleSaveResponseAsync}
		handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
	/>;
};

DrStateComplaintResponseContainer.propTypes = {
	actions: PropTypes.object.isRequired,
	complaint: PropTypes.object.isRequired,
	handleUpdateComplaint: PropTypes.func.isRequired,
};

export default DrStateComplaintResponseContainer;
