import React from 'react';
import PropTypes from 'prop-types';
import Button from "../buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import FieldsetLayout from "./FieldsetLayout";

const FileViewDelete = ({
							onView,
							onDelete,
							name,
							legend,
							showLegend,
							legendClass,
							label,
							wrapperClass,
							error,
							disabled
						}) => {

	return (
		<FieldsetLayout
			legend={legend}
			showLegend={showLegend}
			legendClass={legendClass}
			error={error}
			wrapperClass={wrapperClass + " input-button"}>

			<Button name={name}
					id={name}
					label={label}
					buttonType={ButtonTypes.DOWNLOAD}
					onClick={onView}/>
			{
				!disabled &&
				onDelete !== null &&
				<Button name={name}
						label="Delete"
						showLabel={false}
						onClick={onDelete}
						buttonType={ButtonTypes.DELETE}
						disabled={disabled}/>
			}
		</FieldsetLayout>
	);
};

FileViewDelete.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	legend: PropTypes.string,
	legendClass: PropTypes.string,
	wrapperClass: PropTypes.string,
	showLegend: PropTypes.bool,
	error: PropTypes.object,
	onView: PropTypes.func,
	onDelete: PropTypes.func,
	disabled: PropTypes.bool
};

FileViewDelete.defaultProps = {
	label: "Download File",
	onDelete: null,
	showLegend: true,
	wrapperClass: ""
};

export default FileViewDelete;