import AccountUtility from "../../api/AccountUtility";
import audit_outcomes from "../../constants/auditOutcomes";
import {
    approvalNeeded,
    studentCorrections,
    studentCorrectionsWithAdditionalInfoRequirement
} from "../../constants/monitoringCompliance/correctionResults";
import {
    convertToNumber,
    createListItem,
    dynamicSort,
    getDistinctIds,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../common/commonUtilities";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import * as responseOptions from "../../constants/monitoringCompliance/responseOptions";
import {emptyGuid} from "../../constants/config";
import {appendedOtherValueExists, isValueInArray} from "../common/inputs/inputUtility";

export const defaultId = emptyGuid;

export function createPageTitle(districts, districtId, pageDescription) {
    let currentDistrictName = "";

    if(districts && districtId) {
      let districtFound = districts.filter(ins => ins.id === districtId);
      if(districtFound.length === 1)
          currentDistrictName = `: ${districtFound[0].name}`;
    }

    return `Monitoring for Compliance - ${pageDescription}${currentDistrictName}`;
}

export function newComplianceAssessment(districtId = "", dateWindowId = "", monitoringAssessmentId = "", indicatorRequirements = [], classification = ""){
    return {
        districtId,
        dateWindowId,
        monitoringAssessmentId,
        indicatorRequirements,
        classification,
        doesMonitoringAssessmentExist: true
    };
}

export function newIndicatorRequirement(indicatorRequirementId = defaultId, indicatorId = "", studentRecordsRequirementCount = ""){
    return {
        indicatorRequirementId,
        indicatorId,
        studentRecordsRequirementCount
    };
}

export function newProtocolResponse(protocolResponseId, protocolId, response, responseType, totalSubQuestions){
    let subQuestionResponses = [];
    for(let i = 0; i < totalSubQuestions; i++)
        subQuestionResponses.push(response);

    response = (totalSubQuestions > 0) ? "" : response;

    return {
        protocolResponseId,
        protocolId,
        response,
        responseType,
        subQuestionResponses
    };
}

export function newReassessProtocolResponse(protocolResponseId, originalAssessProtocolResponse){
    let subQuestionResponses = [...originalAssessProtocolResponse.subQuestionResponses];

    return {
        protocolResponseId: protocolResponseId,
        studentRecordId: originalAssessProtocolResponse.studentRecordId,
        protocolId: originalAssessProtocolResponse.protocolId,
        response: originalAssessProtocolResponse.response,
        responseType: responseTypes.REASSESS,
        subQuestionResponses: subQuestionResponses
    };
}

export function updateSubQuestionArray(subQuestions, index, response) {
    if(subQuestions.length >= index + 1) {
        subQuestions[index] = response;
        return subQuestions;
    }

    for(let i = 0; i <= index; i++) {
        if(i === index)
            subQuestions.push(response);
        else if(subQuestions.length <= i)
            subQuestions.push("");
    }

    return subQuestions;
}

export function prepareProtocolResponsesForSave(responses, responseType){
    return responses.filter(w => w.responseType === responseType).map(r => {
        return ({
            protocolResponseId: r.protocolResponseId,
            protocolId: r.protocolId,
            response: r.response,
            responseType: responseType,
            subQuestionResponses: r.subQuestionResponses,
            studentRecordId: r.studentRecordId
        });
    });
}

export function getProtocolRequirement(requiredProtocolList, indicatorRequirementId){
    if (!requiredProtocolList)
        return;

    for (let i = 0; i < requiredProtocolList.length; i++){
        if (requiredProtocolList[i].indicatorRequirementId === indicatorRequirementId)
            return Object.assign({}, requiredProtocolList[i]);
    }
}

export function getProtocolRequirementStatus({
                                                 allStudentRecordsSubmitted,
                                                 assessmentApproved,
                                                 allAssessmentsApproved,
                                                 studentRecordsWithDiscrepanciesRequiringReassessment,
                                                 validationSubmitted
                                             } = {}) {
    const isAssessmentApproved = isNullOrUndefined(assessmentApproved) ? false : assessmentApproved;
    return {
        allStudentRecordsSubmitted: isNullOrUndefined(allStudentRecordsSubmitted) ? false : allStudentRecordsSubmitted,
        assessmentApproved: isAssessmentApproved,
        allAssessmentsApproved: isNullOrUndefined(allAssessmentsApproved) ? isAssessmentApproved : allAssessmentsApproved,
        studentRecordsWithDiscrepanciesRequiringReassessment: isNullOrUndefined(studentRecordsWithDiscrepanciesRequiringReassessment) ? false : studentRecordsWithDiscrepanciesRequiringReassessment,
        validationSubmitted: isNullOrUndefined(validationSubmitted) ? false : validationSubmitted,
    };
}

export function getGeneralProtocolRequirementStatus(assessment) {
    const indicatorRequirements = !assessment || !assessment.indicatorRequirements ? [] : assessment.indicatorRequirements;

    return getProtocolRequirementStatus({
        allStudentRecordsSubmitted: false,
        assessmentApproved: indicatorRequirements.some(f => f.assessmentApproved),
        allAssessmentsApproved: indicatorRequirements.every(f => f.assessmentApproved),
        studentRecordsWithDiscrepanciesRequiringReassessment: indicatorRequirements.some(f => f.studentRecordsWithDiscrepanciesRequiringReassessment),
        validationSubmitted: indicatorRequirements.some(f => f.validationSubmitted)
    });
}

export function newStudentRecord(studentRecordId, indicatorsAssignedTo, isRecordSubmitted = false, hasRevisionsBeenRequested = false, protocolResponses = []){
    return {
        studentRecordId,
        name,
        indicatorsAssignedTo,
        isRecordSubmitted,
        hasRevisionsBeenRequested,
        protocolResponses
    };
}

export function isNew(id){
    return id === defaultId;
}

export function getStudentRecordsForIndicatorRequirement(indicatorId, studentRecords){
    return(
        studentRecords.reduce((agg, rec) => {
            let indicatorIdsAssignedTo = [];
            if(rec.indicatorsAssignedTo !== null)
                indicatorIdsAssignedTo = rec.indicatorsAssignedTo.map(i => i.id);

            if (isValueInArray(indicatorIdsAssignedTo, indicatorId))
                agg.push(rec);

            return agg;
        }, []));
}

export function getProtocolResponse(protocolResponses, protocolId, responseType, returnResponseText = false){
    let defaultResponse = "";
    if(!protocolResponses) return defaultResponse;

    const studentRecord = protocolResponses.find(response => {
        return response.protocolId === protocolId && response.responseType === responseType;
    });

    return studentRecord
        ? !isTrimmedStringEmpty(studentRecord.responseText) && returnResponseText ? studentRecord.responseText : studentRecord.response
        : defaultResponse;
}

export function getProtocolApprovedResponse(stateRecordResponse, studentRecordReassessResponse, studentRecordResponse) {
    if(stateRecordResponse !== "" && stateRecordResponse !== responseOptions.NV && stateRecordResponse !== studentRecordResponse)
        return `${stateRecordResponse}**`;
    if(studentRecordReassessResponse !== "")
        return studentRecordReassessResponse;

    return studentRecordResponse;
}

export function getMatrixProtocolApprovedResponse(stateRecordResponse, studentRecordReassessResponse, studentRecordResponse) {
    if(stateRecordResponse !== "" && studentRecordResponse.reviewedDistrict !== stateRecordResponse.toString())
        return {reportedDistrict: stateRecordResponse.toString(), reviewedDistrict: stateRecordResponse.toString(), stateResponse: true};
    if(studentRecordReassessResponse.reviewedDistrict !== "")
        return Object.assign({}, {stateResponse: false}, studentRecordReassessResponse);

    return Object.assign({}, {stateResponse: false}, studentRecordResponse);
}

export function getMatrixReportedReviewedResponses(protocolResponses, protocolId, responseType){
    let response = getProtocolResponse(protocolResponses, protocolId, responseType).split(",");

    let reportedDistrict = "";
    let reviewedDistrict = "";
    if(response.length === 2) {
        reportedDistrict = response[0];
        reviewedDistrict = response[1];
    }

    return {
        reportedDistrict,
        reviewedDistrict
    };
}

export function getSubQuestionResponse(protocolResponses, protocolId, responseType){
    let defaultResponse = [];
    if(!protocolResponses) return defaultResponse;

    const studentRecord = protocolResponses.find(response => {
        return response.protocolId === protocolId && response.responseType === responseType;
    });

    return studentRecord ? studentRecord.subQuestionResponses : defaultResponse;
}

export function createIndicatorTitle(title, publicIdentifier) {
    if(!title)
        return publicIdentifier;

    return title + " (" + publicIdentifier + ")";
}

export function createBeessIndicatorList(beessIndicators) {
    const beessIndicatorsWithProtocols = beessIndicators.filter(f => f.hasProtocols);
    return beessIndicatorsWithProtocols.map(ind => createListItem(ind.id, createIndicatorTitle(ind.title, ind.publicIdentifier)));
}

export function generateStudentRecordStatusText(record){
    if (!record.isRecordStarted)
        return "Not Started";
    if(record.isRecordValidated)
        return "Validated";
    if (record.isRecordSubmitted)
        return "Submitted";
    if (record.hasRevisionBeenRequested)
        return "Revisions Requested";
    if(allRecordsComplete(record))
        return "Complete";

    return "In Progress";
}

export function generateMatrixCostFactor(totalPoints) {
    if(totalPoints <= 5)
        return "";
    if(totalPoints <= 9)
        return 251;
    if(totalPoints <= 13)
        return 252;
    if(totalPoints <= 17)
        return 253;
    if(totalPoints <= 21)
        return 254;

    return 255;
}
export function allRecordsComplete(records) {
    if (!Array.isArray(records))
        records = [records];

    let allComplete = true;

    records.filter(f => !f.isMatrix).map((record) => {
        let isComplete = !record.protocolResponses.some(rec => rec.response === "");
        if(!isComplete)
            allComplete = false;
    });

    records.filter(f => f.isMatrix).map((record) => {
        let isComplete = !record.protocolResponses.some(rec => rec.response === "" || rec.response.startsWith(",") || rec.response.endsWith(","));
        if(!isComplete)
            allComplete = false;
    });

    return allComplete;
}

export function isValidId(id){
    return (id && id !== "-1" && id !== emptyGuid);
}

export function isAssessmentRecordsSubmitted(studentRecords){
    if (!studentRecords || studentRecords.length === 0)
        return false;

    return !studentRecords.some(rec => {
        return !rec.isRecordSubmitted;
    });
}

export function validateIsReadyToSubmit(selectedRequirement, selectedStudentRecords) {
    let submissionDisabledWarning = "";
    const requiredRecordsCreated = selectedStudentRecords && selectedRequirement.studentRecordsRequirementCount <= selectedStudentRecords.length;
    if (!requiredRecordsCreated)
        submissionDisabledWarning = "<li>The required number of student records have not been added.</li>";
    if(!allRecordsComplete(selectedStudentRecords))
        submissionDisabledWarning = "<li>Not all student records have a status of complete.</li>";

    const isSubmitted = isAssessmentRecordsSubmitted(selectedStudentRecords);
    const isSubmissionModuleDisabled = !isTrimmedStringEmpty(submissionDisabledWarning) || isSubmitted;
    const indicatorTitle = createIndicatorTitle(selectedRequirement.title, selectedRequirement.publicIdentifier);
    const submissionMessage = isSubmissionModuleDisabled
        ? isSubmitted
            ? `The student records for ${indicatorTitle} have already been submitted for the selected report window.`
            : `The student records for ${indicatorTitle} are <strong>NOT</strong> ready for submission due to the following reasons:<ul>${submissionDisabledWarning}</ul>`
        : `All student records for ${indicatorTitle} are ready for submission.`;

    return {
        submissionMessage: submissionMessage,
        isSubmissionModuleDisabled: isSubmissionModuleDisabled,
        isSubmitted: isSubmitted
    };
}

export function reviewReadyForAccepted(review) {
    if(!review)
        return false;

    let allValid = true;

    if(!review.dateDocumentationReceived || !review.dateDocumentationReviewed || !review.dateCorrectionDemonstrated || !review.status)
        allValid = false;

    return allValid;
}

export function isViewOnlyDueToStatus(status) {
    const isDistrictUser = AccountUtility.isDistrictUser();
    const isDistrictViewOnlyStatus = isDistrictUser && (status === audit_outcomes.SUBMITTED.value || status === audit_outcomes.ACCEPTED.value);
    const isStateViewOnlyStatus = !isDistrictUser && status !== audit_outcomes.SUBMITTED.value;
    return isDistrictViewOnlyStatus || isStateViewOnlyStatus;
}

export function isSubmissionStudentCorrectionsEnabledDueToStatus(corrections) {
    if(!corrections || corrections.length === 0)
        return false;

    let isEnabled = true;

    for(const correction of corrections) {
        const isViewOnly = isViewOnlyDueToStatus(correction.status);
        if (!correction.status.startsWith("Ready to Submit") && !isViewOnly)
             isEnabled = false;
    }

    return isEnabled;
}

export function getIndicatorRequirementApprovalStatus(indicatorId, indicatorRequirements) {
    if(!indicatorRequirements)
        return false;

    const indicatorRequirement = indicatorRequirements.find(f => f.indicatorId === indicatorId);
    return indicatorRequirement?.assessmentApproved ?? false;
}

export function buildStudentRecordsNeedingCorrectionsList(studentProtocolCorrectiveResults, indicatorRequirements) {
    const distinctRecordIds = getDistinctIds(studentProtocolCorrectiveResults, "studentRecordId");
    let list = [];

    for (let recordId of distinctRecordIds) {
        const corrections = studentProtocolCorrectiveResults.filter(f => f.studentRecordId === recordId);
        let correctionsList = [];
        let recordName = "";
        let studentRecordId = "";
        for (let correction of corrections) {
            recordName = correction.name;
            studentRecordId = recordId;
            const assessmentApproved = getIndicatorRequirementApprovalStatus(correction.indicatorId, indicatorRequirements);
            correctionsList.push({
                protocol: correction.publicIdentifier,
                protocolId: correction.protocolId,
                status: getCorrectionStatus(assessmentApproved, isValid(correction, studentCorrectionsWithAdditionalInfoRequirement, studentCorrections), correction.status, correction.acknowledgeNeedsRevisionComplete)
            })
        }
        list.push({
            corrections: correctionsList,
            name: recordName,
            status: getOverallCorrectionStatus(correctionsList),
            studentRecordId,
            totalProtocols: correctionsList.length
        })
    }

    return list;
}

function getCorrectionStatus(assessmentApproved, isValid, auditStatus, acknowledgeNeedsRevisionComplete) {
    if(!assessmentApproved)
        return approvalNeeded;

    if (auditStatus === audit_outcomes.IN_PROGRESS.value && isValid)
        return "Ready to Submit";

    if (auditStatus === audit_outcomes.NEEDS_REVISION.value && isValid && acknowledgeNeedsRevisionComplete)
        return "Ready to Submit Revisions"

    return auditStatus;
}

function getOverallCorrectionStatus(corrections) {
    if (corrections.find(f => f.status === audit_outcomes.NEEDS_REVISION.value))
        return audit_outcomes.NEEDS_REVISION.value;

    if (corrections.find(f => f.status === audit_outcomes.IN_PROGRESS.value))
        return audit_outcomes.IN_PROGRESS.value;

    return corrections.length > 0 ? corrections[0].status : "";

}

export function isValid(record, additionalInfoRequirement, correctionOptions) {
    if (!record.dateCorrected || !record.corrected || !record.corrections || record.corrections.length === 0)
        return false;

    let isValid = true;
    Object.keys(additionalInfoRequirement).filter(f => additionalInfoRequirement[f])
        .map(correction => {
            if (!appendedOtherValueExists(correctionOptions[correction], record.corrections))
                isValid = false;
        });

    return isValid;
}

export function sortProtocolPublicIdentifiers(publicIdentifiers) {
    if(!publicIdentifiers || publicIdentifiers.length === 0)
        return [];

    let identifiersObjectArray = [];
    for(let identifier of publicIdentifiers.filter(f => !!f)) {
        let temp = identifier.split("-");
        let displayNumber = temp.length > 1 ? convertToNumber(temp[1]) : "";
        identifiersObjectArray.push({
            protocol: temp[0],
            displayNumber: isNullOrUndefined(displayNumber) ? "" : displayNumber
        });
    }

    identifiersObjectArray = identifiersObjectArray.sort(dynamicSort("protocol", false, "displayNumber"));

    return identifiersObjectArray.map(identifier => `${identifier.protocol}${identifier.displayNumber === "" ? "" : `-${identifier.displayNumber}`}`);
}