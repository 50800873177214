import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";

export const DrDistrictSummaryDueProcess = ({
										handleClickDistrict,
	                                    handleClickOrder,
	                                    summary
                                    }) => {
	return (
		<GridTable className={`dueProcess__district-list`}>
			<thead>
			<tr>
				<th rowSpan={3} onClick={handleClickOrder} valign={`bottom`} data-id="districtName" className="is-clickable">
					District
				</th>
				<th rowSpan={3} onClick={handleClickOrder} valign={`bottom`} data-id="dueProcessOpenCount" className="is-clickable center">
					Open Cases
				</th>
				<th rowSpan={3} onClick={handleClickOrder} valign={`bottom`} data-id="dueProcessTotalCount" className="is-clickable center">
					Total Due Process Hearing Requests  Filed
				</th>
				<th rowSpan={3} onClick={handleClickOrder} valign={`bottom`} data-id="dueProcessTotalCountAdjudicated" className="is-clickable center">
					Total Due Process Hearing Requests Adjudicated
				</th>
				<th colSpan={6} className={`center`}>Tracking</th>
			</tr>
			<tr>
				<th colSpan={3} className={"center resolution-process"}>Resolution Process Status Reports</th>
				<th colSpan={3} className={"center"}>Final Order - Ordered Actions</th>
			</tr>
			<tr>
				<th onClick={handleClickOrder} data-id="statusReportsInProgress" className="is-clickable center resolution-process">
					District In Progress
				</th>
				<th onClick={handleClickOrder} data-id="statusReportsNeedsRevisionCount" className="is-clickable center resolution-process">
					District Needs Revision
				</th>
				<th onClick={handleClickOrder} data-id="statusReportsNeedingReviewCount" className="is-clickable center resolution-process">
					Bureau Needs Review
				</th>
				<th onClick={handleClickOrder} data-id="responsesDistrictInProgress" className="is-clickable center">
					District In Progress
				</th>
				<th onClick={handleClickOrder} data-id="responsesDistrictNeedsRevisionCount" className="is-clickable center">
					District Needs Revision
				</th>
				<th onClick={handleClickOrder} data-id="responsesNeedingReviewCount" className="is-clickable center">
					Bureau Needs Review
				</th>
			</tr>
			</thead>
			<tbody>
			{
				isArrayNullOrEmpty(summary) &&
				<tr>
					<td colSpan={10} className={"center"}>
						No districts match the search criteria specified.
					</td>
				</tr>
			}
			{
				summary.map((district, key) =>
					(<tr
						key={key}
						onClick={(event) => handleClickDistrict(event, district.districtName)}
						className={"is-clickable"}
					>
						<td>{district.districtName}</td>
						<td className={"center"}>{district.dueProcessOpenCount}</td>
						<td className={"center"}>{district.dueProcessTotalCount}</td>
						<td className={"center"}>{district.dueProcessTotalCountAdjudicated}</td>
						<td className={"center resolution-process"}>{district.statusReportsInProgress}</td>
						<td className={"center resolution-process"}>{district.statusReportsNeedsRevisionCount}</td>
						<td className={"center resolution-process"}>{district.statusReportsNeedingReviewCount}</td>
						<td className={"center"}>{district.responsesDistrictInProgress}</td>
						<td className={"center"}>{district.responsesDistrictNeedsRevisionCount}</td>
						<td className={"center"}>{district.responsesNeedingReviewCount}</td>
					</tr>)
				)
			}
			</tbody>
		</GridTable>
	);
};

DrDistrictSummaryDueProcess.propTypes = {
	handleClickDistrict: PropTypes.func.isRequired,
	handleClickOrder: PropTypes.func.isRequired,
	summary: PropTypes.array
};

export default DrDistrictSummaryDueProcess;

export const MemoizedDrDistrictSummaryDueProcess = React.memo(DrDistrictSummaryDueProcess);