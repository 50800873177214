import React, {PureComponent} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import $ from 'jquery';
import * as layoutActions from "../../actions/layoutActions";
import LocalStorage from "../../components/shared/LocalStorage";
import SppElementSet from "../../components/spp/SppElementSet";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import * as sppActions from "../../actions/sppActions";
import * as fileActions from "../../actions/fileActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {NotifyUser} from "../../components/common/NotifyUser";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import Allow from "../../components/authorization/Allow";
import {SppPolicy} from "../../components/authorization/policies/SppPolicy";
import {emptyGuid} from "../../constants/config";
import * as policyEvents from "../../constants/policyEvents";
import SppElement from "../../components/spp/SppElement";
import * as elementTypes from "../../constants/spp/sppElementTypes";
import * as sppUtilities from "../../components/spp/SppUtilities";
import * as sppLocations from "../../constants/spp/sppLocations";
import { animateScroll as scroll } from "react-scroll";
import { getParams } from "../../components/layout/getParams";

export class SppSectionPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			currentStep: 1,
			isTitleSet: false,
			isSubtitleSet: false,
			params: {}
		};

		this.back = this.back.bind(this);
		this.next = this.next.bind(this);
		this.handleFormChange = this.handleFormChange.bind(this);
		this.fileOnView = this.fileOnView.bind(this);
		this.fileOnDelete = this.fileOnDelete.bind(this);
		this.saveResponses = this.saveResponses.bind(this);
		this.getNextButtonText = this.getNextButtonText.bind(this);
		this.getBackButtonText = this.getBackButtonText.bind(this);
		this.markReadyToSubmit = this.markReadyToSubmit.bind(this);
		this.submit = this.submit.bind(this);
		this.getPageResponses = this.getPageResponses.bind(this);
		this.validateSectionResponses = this.validateSectionResponses.bind(this);
		this.showReadyToSubmitButton = this.showReadyToSubmitButton.bind(this);
		this.showSubmitButton = this.showSubmitButton.bind(this);
		this.documentHierarchy = this.documentHierarchy.bind(this);
		this.totalSteps = this.totalSteps.bind(this);
		this.clearResponseAudits = this.clearResponseAudits.bind(this);
		this.documentPage = this.documentPage.bind(this);
	}

	componentDidMount() {
		const params = getParams(this.props.location.pathname, sppLocations.SECTION_DISTRICT);

		if (params !== null) {
			this.setState({params});

			if (params.documentId !== undefined) {
				const currentUser = LocalStorage.getLoggedInUser();
				this.props.actions.loadInstitutionResponses(currentUser.InstitutionId, params.documentId);

				this.props.actions.loadPolicyCompliance(params.documentId, currentUser.InstitutionId);

				this.props.actions.loadSppDocument(params.documentId);

				this.props.actions.loadSppDocumentElements(params.documentId);
			}
		}

		if (this.props.allDateWindows.length === 0)
			this.props.actions.loadAllDateWindows();

		if (this.props.institutions.length === 0)
			this.props.actions.loadInstitutions();
	}

    componentDidUpdate(nextProps) {
		if (!this.state.isTitleSet &&
			nextProps.allDateWindows.length > 0 &&
			nextProps.document.id !== undefined &&
			nextProps.policyCompliance.id !== undefined &&
			nextProps.institutions.length > 0) {
			const pageTitle = sppUtilities.generatePageTitle(
				nextProps.allDateWindows,
				nextProps.policyCompliance,
				nextProps.document,
				nextProps.institutions,
				LocalStorage.getLoggedInUser().InstitutionId
			);

			this.props.actions.updatePageTitle(pageTitle);

			this.setState({
				isTitleSet: true
			});
		}

		if(nextProps.elements.length > 0 &&
			!this.state.isSubtitleSet) {
			const section = nextProps.elements.filter(el => el.id === this.state.params.sectionId)[0];

			if(section)
				this.props.actions.updatePageSubTitle(section.text);

			this.setState({isSubtitleSet: true});
		}
	}

	componentWillUnmount() {
		this.props.actions.clearSubTitle();
	}

	getNextButtonText(curStep, totalSteps) {
		return (curStep === totalSteps) ? "Overview" : "Next";
	}

	getBackButtonText(curStep) {
		return (curStep === 1) ? "Overview" : "Back";
	}

	async handleFormChange(event) {
		let response;
		let elementId;
		let shouldSaveUploadedFile = false;

		if (event.target.type === "radio") elementId = event.target.name;
		else elementId = event.target.id;

		const elementResponses = this.props.responses.filter(re => re.elementId === elementId);

		const responseExists = elementResponses.length > 0;
		if (!responseExists) {
			const element = this.props.elements.filter(e => e.id === elementId)[0];
			response = sppUtilities.getNewResponse(LocalStorage.getLoggedInUser(), element);
		}
		else response = Object.assign({}, elementResponses[0]);

		if (event.target.type === "checkbox")
			response.data = event.target.checked.toString();
		else if (event.target.type === "file") {
			const fileId = await this.uploadFile(event, emptyGuid);
			if(!fileId) return;
			response.data = fileId;
			shouldSaveUploadedFile = true;
		}
		else
			response.data = event.target.value;

		 let actionMethod = !responseExists
			 ? this.props.actions.createPolicyResponse
			 : this.props.actions.changePolicyResponse;

		actionMethod(response)
			.then(() => {
				if (shouldSaveUploadedFile)
					this.saveResponses();
			});
	}

	clearOutFileUploadField(fileHtmlId) {
		$("#" + fileHtmlId).val("");
	}

	async uploadFile(event, fileId) {
		const files = event.target.files;
		const fileHtmlId = event.target.id;
		return await this.props.actions.uploadFile(files, fileId)
            .then((fileId) =>
            {
                this.clearOutFileUploadField(fileHtmlId);
                return fileId;
            })
	}

	fileOnView(event) {
		event.preventDefault();
		const responses = [...this.props.responses];
		const response = responses.filter(re => re.elementId === event.target.id)[0];

		const indexOfResponse = responses.indexOf(response);
		const fileId = responses[indexOfResponse].data;
		this.props.actions.downloadFile(fileId);
	}

	fileOnDelete(event) {
		event.preventDefault();

		if (!confirm("Are you sure you want to delete this file?\n\nPress \'OK\' to continue or \'Cancel\' to return to the page."))
			return;

		const responses = [...this.props.responses];
		const response = Object.assign({}, responses.filter(re => re.elementId === event.target.id)[0]);

		const fileId = response.data;
		this.props.actions.deleteFile(fileId)
			.then((isFileDeleted) => {
				if(isFileDeleted) {
					response.data = "";
					this.props.actions.changePolicyResponse(response);
					this.saveResponses();
				}
			});
	}

	back(event) {
		event.preventDefault();

		this.saveResponses()
			.then((isValid) => {
				if(!isValid)
					return;

				if (this.state.currentStep === 1)
					this.props.history.push(this.documentPage());
				else {
					this.setState({
						currentStep: this.state.currentStep - 1
					});
					scroll.scrollToTop();
				}
			});
	}

	next(event) {
		event.preventDefault();

		this.saveResponses()
			.then((isValid) => {
				if(!isValid)
					return;
				if (this.state.currentStep === this.totalSteps())
					this.props.history.push(this.documentPage());
				else {
					this.setState({
						currentStep: this.state.currentStep + 1
					});
					scroll.scrollToTop();
				}
			}
		);
	}

	documentPage() {
		return sppLocations.DOCUMENT_DISTRICT.path.replace(sppLocations.DOCUMENT_ID, this.state.params.documentId);
	}

	clearResponseAudits(responses) {
		return responses.map(re => Object.assign({}, re, {audits: []}));
	}

	saveResponses(event) {
		if (event !== undefined)
			event.preventDefault();
		const responses = this.clearResponseAudits(this.getPageResponses());

		return this.props.actions.saveSppResponses(this.state.params.documentId, responses)
			.then((isValidSave) => {
				return new Promise((resolve) => {
					resolve(isValidSave);
				});
			});
	}

	getPageResponses() {
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, this.documentHierarchy());

		const currentElementSetHierarchy = sectionHierarchy.childElements[this.state.currentStep - 1].childElements;

		const elements = currentElementSetHierarchy.map(
			elementHierarchy => {
				return this.props.elements.filter(el => el.id === elementHierarchy.elementId)[0];
			}).filter(el => el.layout !== elementTypes.Display);

		return elements.map(el => {
			return this.props.responses.filter(response => response.elementId === el.id)[0];
		});
	}

	validateSectionResponses() {
		let isValid = true;

		const sectionElements = sppUtilities.getSectionElements(this.state.params.sectionId, this.documentHierarchy(), this.props.elements, false);

		const sectionResponses = sppUtilities.getElementResponses(sectionElements, this.props.responses);

		for (let elementIndex = 0; elementIndex < sectionElements.length; elementIndex++) {
			const element = sectionElements[elementIndex];
			const elementResponse = sectionResponses.filter(response => response.elementId === element.id)[0];

			if (element.isRequired &&
				element.layout === elementTypes.Checkbox &&
				elementResponse.data === "false") {
				isValid = false;
				break;
			}
			else if (element.isRequired && elementResponse.data === "") {
				isValid = false;
				break;
			}
		}

		return isValid;
	}

	showReadyToSubmitButton() {
		const sectionResponses = sppUtilities.getSectionResponses(
			this.state.params.sectionId,
			this.documentHierarchy(),
			this.props.elements,
			this.props.responses);

		const notReadyResponses = sectionResponses.filter(response => !response.isReadyToSubmit && (response.audits.length === 0
			|| !response.audits[0].isLocked));

		return notReadyResponses.length > 0 && !this.props.isLoading;
	}

	markReadyToSubmit(event) {
		event.preventDefault();
		const documentId = this.state.params.documentId;

		if (this.validateSectionResponses()) {
			const sectionResponses = sppUtilities.getSectionResponses(this.state.params.sectionId, this.documentHierarchy(),
				this.props.elements, this.props.responses);

			const readyToSubmitResponses = sectionResponses.map(response => {
				response.isReadyToSubmit = true;
				return response;
			});

			this.props.actions.saveSppResponses(documentId, this.clearResponseAudits(readyToSubmitResponses), true)
				.then((isValid) => {
					if(isValid)
						this.props.history.push(this.documentPage());
				});
		}
		else NotifyUser.Warning("This section can not be marked ready to submit. Some required fields have no answer.");
	}

	showSubmitButton() {
		const unlockedResponses = sppUtilities.getSectionResponses(
			this.state.params.sectionId,
			this.documentHierarchy(),
			this.props.elements,
			this.props.responses).filter(response => {
			return response.audits.length === 0 || !response.audits[0].isLocked;
		});

		return unlockedResponses.length > 0 && !this.props.isLoading;
	}

	submit(event) {
		event.preventDefault();

		if (this.validateSectionResponses()) {
			const responses = sppUtilities.getSectionResponses(
				this.state.params.sectionId,
				this.documentHierarchy(),
				this.props.elements,
				this.props.responses);

			const documentId = this.state.params.documentId;

			this.props.actions.submitSppResponses(documentId, this.clearResponseAudits(responses))
				.then((isValid) => {
					if(isValid)
						this.props.history.push(this.documentPage());
				});
		}
		else NotifyUser.Warning("This section can not be submitted. Some required fields have no answer.");
	}

	documentHierarchy() {
		return JSON.parse(this.props.document.componentsHierarchy);
	}

	totalSteps() {
		const documentHierarchy = this.documentHierarchy();
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, documentHierarchy);
		return sectionHierarchy ? sectionHierarchy.childElements.length : 0;
	}

	render() {
		if (this.props.allDateWindows.length === 0 ||
			this.props.document.componentsHierarchy === undefined ||
			this.props.elements.length === 0 ||
			this.props.responses.length === 0) return null;

		const documentHierarchy = this.documentHierarchy();
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.state.params.sectionId, documentHierarchy);
		const totalSteps = this.totalSteps();

		const sectionElements = sppUtilities.getSectionElements(this.state.params.sectionId, documentHierarchy, this.props.elements, true);
		const sectionInputElements = sppUtilities.getSectionElements(this.state.params.sectionId, documentHierarchy, this.props.elements, false);

		const responses = sppUtilities.getElementResponses(sectionInputElements, this.props.responses);

		//get the current element set's hierarchy children
		const elementListForCurrentPage = (sectionHierarchy) ? sectionHierarchy.childElements[this.state.currentStep - 1].childElements : [];

		return (
			<div>
				<ButtonBar position={ButtonBarPositions.STICKY}>
					<Allow policy={SppPolicy} policyEvent={policyEvents.MARK_READY_TO_SUBMIT}>
						<Button name="btnMarkAsReady" label="Ready to Submit" onClick={this.markReadyToSubmit}
						        disabled={!this.showReadyToSubmitButton()}/>
					</Allow>
					<Allow policy={SppPolicy} policyEvent={policyEvents.SUBMIT_SPP_SECTION}>
						<Button name="btnSubmit" label="Submit" onClick={this.submit}
						        disabled={!this.showSubmitButton()}/>
					</Allow>
				</ButtonBar>

				<SppElementSet>
					{
						elementListForCurrentPage.map(childElement => {
							const element = sectionElements.filter(el => el.id === childElement.elementId)[0];
							const response = responses.filter(r => r.elementId === childElement.elementId)[0];

							return (<SppElement key={element.id}
							                    element={element}
							                    response={response}
							                    handleFormChange={this.handleFormChange}
							                    fileOnView={this.fileOnView}
							                    fileOnDelete={this.fileOnDelete}/>);
						})
					}
				</SppElementSet>

				<BackNextPager
					next={this.next}
					back={this.back}
					backText={this.getBackButtonText(this.state.currentStep)}
					nextText={this.getNextButtonText(this.state.currentStep, totalSteps)}
					currentPage={this.state.currentStep}
					totalPages={totalSteps}
					isBottom
					disableBack={this.props.isLoading}
					disableNext={this.props.isLoading}
				/>
			</div>
		);
	}
}

SppSectionPage.propTypes = {
	actions: PropTypes.object.isRequired,
	document: PropTypes.object.isRequired,
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	allDateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	responses: PropTypes.arrayOf(PropTypes.object),
	policyCompliance: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		allDateWindows: state.sharedData.dateWindows,
		document: state.spp.selectedDocument,
		elements: state.spp.documentElements,
		responses: state.spp.documentResponses,
		policyCompliance: state.spp.selectedPolicyCompliance,
		institutions: state.sharedData.institutions,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sppActions,
		fileActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppSectionPage);