import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../common/commonUtilities";
import RadioField from "../common/inputs/RadioField";
import {inputStyles} from '../../constants/inputConstants';
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import * as AuthorizationUtilities from "../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";

const IndicatorProtocolFormNoSubQuestions = ({
                                                 protocol,
                                                 response,
                                                 responseText,
                                                 onResponseChange,
                                                 showState,
                                                 districtResponse,
                                                 options,
                                                 showMissingResponses
                                             }) => {
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    const missingData = showMissingResponses && isTrimmedStringEmpty(response) ? "missing-data-highlight" : "";
    return (
        <div className={`responseContainer ${missingData}`}>
            {
                showState &&
                options.length > 0 &&
                <div>
                    <p>District Response: <HtmlDisplayInline html={districtResponse}/></p>
                    <GridRow>
                        <GridColumn columnClass={"shrink"}>
                            <strong>State Response:</strong>
                        </GridColumn>
                        <GridColumn columnClass={"shrink"}>
                            <RadioField
                                name={protocol.protocolId}
                                legend={`Protocol Responses for &quot;${protocol.standardDescription} ${protocol.citation}&quot;`}
                                showLegend={false}
                                options={options}
                                value={response}
                                onChange={onResponseChange}
                                direction={inputStyles.radio.direction.HORIZONTAL}
                                disabled={!canEdit}
                            />
                        </GridColumn>
                    </GridRow>
                </div>
            }

            {
                showState &&
                options.length === 0 &&
                <div>
                    <p>Generated District Response: <HtmlDisplayInline html={districtResponse}/></p>
                    <div>
                        <strong>Generated State Response:</strong> <HtmlDisplayInline html={responseText}/>
                        <br/><span className={"clarifying-text"}>(Updated after saved)</span>
                    </div>
                </div>
            }

            {
                !showState &&
                options.length > 0 &&
                <RadioField
                    name={protocol.protocolId}
                    legend={`Responses for &quot;${protocol.standardDescription} ${protocol.citation}&quot;`}
                    showLegend={false}
                    options={options}
                    value={response}
                    onChange={onResponseChange}
                    disabled={!canEdit}
                />
            }
            {
                !showState &&
                options.length === 0 &&
                <div>
                    Generated Response: <HtmlDisplayInline html={responseText}/>
                    <br/><span className={"clarifying-text"}>(Updated after saved)</span>
                </div>
            }
        </div>
    );
};

IndicatorProtocolFormNoSubQuestions.defaultProps = {
    response: "",
    responseText: "",
    districtResponse: ""
};

IndicatorProtocolFormNoSubQuestions.propTypes = {
    protocol: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    response: PropTypes.string.isRequired,
    responseText: PropTypes.string.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    showState: PropTypes.bool.isRequired,
    showMissingResponses: PropTypes.bool.isRequired,
    districtResponse: PropTypes.string.isRequired
};

export default IndicatorProtocolFormNoSubQuestions;