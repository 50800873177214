import React from 'react';
import PropTypes from "prop-types";
import MapAdInfinitum from "./MapAdInfinitum";

const FormTable = ({
                       children,
                       className,
                       includesRow,
                   }) => {

    const createRow = (child, index) => {

		if (!child || !child.type) return;

		return includesRow
			? <React.Fragment key={index}>{child}</React.Fragment>
			: <tr key={index}><td>{child}</td></tr>;
    };

    return <>
        <table className={"table-form " + className}>
            <tbody>
            <MapAdInfinitum
                component={createRow}
                yutes={children}
            />
            </tbody>
        </table>
    </>;
};

FormTable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    className: PropTypes.string,
	includesRow: PropTypes.bool,
};

export default FormTable;