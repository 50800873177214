import React from 'react';
import PropTypes from 'prop-types';
import FieldsetLayout from "./FieldsetLayout";
import FileUploadButton from "./FileUploadButton";

const FileUploadField = ({
                             disabled,
                             error,
                             legend,
                             legendClass,
                             name,
                             onChange,
                             showLegend = true,
                             label,
                             wrapperClass,
                         }) => {

    return (
        <FieldsetLayout
            disabled={disabled}
            error={error}
            legend={legend}
            legendClass={legendClass}
            showLegend={showLegend}
            wrapperClass={wrapperClass + " input-button"}
        >
            {!disabled &&
                <FileUploadButton
                    className={""}
                    disabled={disabled}
                    label={label}
                    name={name}
                    onChange={onChange}
                />
            }
            {
                disabled &&
                <p>No attachment.</p>
            }
        </FieldsetLayout>
    );
};

FileUploadField.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.object,
    label: PropTypes.string,
    legend: PropTypes.string,
    legendClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    showLegend: PropTypes.bool,
    wrapperClass: PropTypes.string,
};

export default FileUploadField;