import React from "react";
import {BPIE} from "../../constants/contexts";
import {FilterView, FilterViewPropTypes} from "../../components/common/searchCriterias/FilterView";

export const BpieFilter = ({
                               dateWindows,
                               handleExport,
                               handleSearchFiltersChange,
                               numberOfFilterColumns = 0,
                               searchCriteria
                           }) => {

    return <FilterView
        context={BPIE}
        dateWindows={dateWindows}
        handleExport={handleExport}
        handleSearchFiltersChange={handleSearchFiltersChange}
        numberOfFilterColumns={numberOfFilterColumns}
        searchCriteria={searchCriteria}
    />;
};

BpieFilter.propTypes = FilterViewPropTypes;