import React from 'react';
import FlexSpacer from "../../../../components/common/FlexSpacer";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import Tile from "../../../../components/common/Tiles/Tile";
import PropTypes from "prop-types";
import {themeConstants} from "../../../../constants/commonStyleConstants";
import bpieApi from "../../bpieApi";
import {sbpieAssessmentStatusList} from "../../bpieConstants";
import {getTextByValue} from "../../../../components/common/commonUtilities";

const BpieTileView = ({actions, archivedBpies}) => {
    const handleTileClick = (event, schoolBpieId) => {
        event.preventDefault();
        actions.executeApi(bpieApi.downloadBpieSchoolAssessment, [schoolBpieId]);
    };

    if(!archivedBpies || archivedBpies.length === 0)
        return null;

    return (
        <>
            <div className={`theme-${themeConstants.BPIE}`}>
                {
                    <TileContainer title="SBPIE Assessments">
                        {
                            archivedBpies.map((assessment, i) => {
                                return (
                                    <Tile
                                        key={`BpieTile${i}`}
                                        header={assessment.datesCovered}
                                        body={""}
                                        footer={getTextByValue(sbpieAssessmentStatusList, assessment.status)}
                                        onClick={(event) => handleTileClick(event, assessment.schoolBpieId)}
                                    />
                                );
                            })
                        }
                    </TileContainer>
                }
            </div>
            <FlexSpacer/>
            <hr/>
        </>
    );
}

BpieTileView.propTypes = {
    actions: PropTypes.object.isRequired,
    archivedBpies: PropTypes.array.isRequired
};

export default BpieTileView;

