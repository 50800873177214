import React from 'react';
import PropTypes from 'prop-types';
import * as sppUtilities from "./SppUtilities";
import SectionPrint from "./SectionPrint";

const DocumentPrint = ({ partId, documentHierarchy, elements, responses, fileOnView}) => {
    const partHierarchy = documentHierarchy.filter(hier => hier.elementId === partId)[0];
    const partElement = elements.filter(element => element.id === partId.toLowerCase())[0];
    const partTitle = partElement.text;

    return (
            <section>
                <h2>{partTitle}</h2>

                {partHierarchy.childElements.map(sectionHierarchyItem => {
                    const sectionElement = elements.filter(el => el.id === sectionHierarchyItem.elementId.toLowerCase())[0];

                    const sectionTitle = sectionElement.text;

                    const sectionElements =
                            sppUtilities.getSectionElements(
                                    sectionHierarchyItem.elementId,
                                    documentHierarchy,
                                    elements, true);

                    const sectionResponses =
                            sppUtilities.getSectionResponses(
                                    sectionHierarchyItem.elementId,
                                    documentHierarchy,
                                    elements,
                                    responses);

                    return (<SectionPrint
                            key={sectionElement.id}
                            sectionId={sectionElement.id}
                            sectionTitle={sectionTitle}
                            elements={sectionElements}
                            responses={sectionResponses}
                            fileOnView={fileOnView}
                    />);
                })}

            </section>
    );
};


DocumentPrint.propTypes = {
    partId: PropTypes.string.isRequired,
    documentHierarchy: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)]).isRequired,
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,
    fileOnView: PropTypes.func
};

export default DocumentPrint;