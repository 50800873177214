import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {AdminPolicy} from "../../components/authorization/policies/AdminPolicy";
import Button from "../../components/common/buttons/Button";
import {MANAGE} from "../../constants/policyEvents";
import * as systemLocations from "../../constants/systemLocations";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";

export class SystemAdminWrapper extends React.PureComponent {
	constructor(props) {
		super(props);

		this.navButtonClick = this.navButtonClick.bind(this);
		this.getCurrentLocation = this.getCurrentLocation.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("System Admin");
	}

	navButtonClick(event, location) {
		event.preventDefault();

		if (location === this.getCurrentLocation()) return;

		const navigateAway = () => this.props.history.push(location);

		if (this.props.isEditing){
			if (confirm("Leaving this page will lose unsaved changes. Continue?")){
				this.props.actions.leaveEditMode();
				navigateAway();
			}
		}
		else
			navigateAway();
	}

	getCurrentLocation() {
		return this.props.path;
	}

	render() {
        const {component: Component} = this.props;
		const canEdit = allow(AdminPolicy, MANAGE);

		return (
			<GridRow isPageNav>
				<GridColumn isPageNavLinks
				            isStickyLinks
				            xlarge="3">
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_APP_SETTINGS.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_APP_SETTINGS.path)}
						label={"Settings"}
						name={systemLocations.ADMIN_APP_SETTINGS.path}
					/>
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_SYSTEM_MESSAGE.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_SYSTEM_MESSAGE.path)}
						label={"System Message"}
						name={systemLocations.ADMIN_SYSTEM_MESSAGE.path}
					/>
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_INSTITUTIONS.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_INSTITUTIONS.path)}
						label={"Institutions"}
						name={systemLocations.ADMIN_INSTITUTIONS.path}
					/>
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_SPP_INDICATORS.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_SPP_INDICATORS.path)}
						label={"SPP Indicators"}
						name={systemLocations.ADMIN_SPP_INDICATORS.path}
					/>
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_BEESS_INDICATORS.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_BEESS_INDICATORS.path)}
						label={"BEESS Indicators"}
						name={systemLocations.ADMIN_BEESS_INDICATORS.path}
					/>
					<Button
						btnClass={this.getCurrentLocation() === systemLocations.ADMIN_PTS_INDICATORS.path ? "is-active" : ""}
						onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_PTS_INDICATORS.path)}
						label={"PTS Indicators"}
						name={systemLocations.ADMIN_PTS_INDICATORS.path}
					/>
					{
						canEdit &&
						<Button
							btnClass={this.getCurrentLocation() === systemLocations.ADMIN_FILE_UPLOAD.path ? "is-active" : ""}
							onClick={(event) => this.navButtonClick(event, systemLocations.ADMIN_FILE_UPLOAD.path)}
							label={"File Upload"}
							name={systemLocations.ADMIN_FILE_UPLOAD.path}
						/>
					}
				</GridColumn>

				<GridColumn isPageNavContent
				            xlarge="9">
                    <Component {...this.props}
						canEdit={canEdit}
					/>
				</GridColumn>
			</GridRow>
		);
	}
}

SystemAdminWrapper.propTypes = {
	actions: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
	isEditing: PropTypes.bool.isRequired,
    layout: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		isEditing: state.sharedData.isEditing,
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sharedDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SystemAdminWrapper));