import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import userApi from "../../../../../api/UserApi";
import {isArrayNullOrEmpty, isNullOrUndefined, setPageTitle} from "../../../../../components/common/commonUtilities";
import {UserSearchCriteria} from "../../../../../components/common/searchCriterias/userSearchCriteria";
import * as behaviors from "../../../../../constants/behaviors";
import * as contexts from "../../../../../constants/contexts";
import * as userConstants from "../../../../../constants/users/userConstants";
import * as gmsLocations from "../../../gmsLocations";
import gmsManageDiscretionaryProjectsApi from "../gmsManageDiscretionaryProjectsApi";
import {createDiscretionaryProjectObject} from "../gmsManageDiscretionaryProjectsObjectFactory";
import gmsManageFiscalAgentsApi from "./gmsManageFiscalAgentsUsersApi";
import {GmsManageFiscalAgentsUsersView} from "./GmsManageFiscalAgentsUsersView";
import {createProjectUsersSelectList, createSaveFiscalAgentsUsersArray} from "./gmsManageFiscalAgentUsersUtilities";

const GmsManageFiscalAgentsUsersContainer = ({
                                            allFiscalAgents,
                                            actions,
                                            discretionaryProjectId,
                                            gotoLocation,
                                            handleDataChanged,
                                            isLoading
                                        }) => {
    const [discretionaryProject, setDiscretionaryProject] = useState(null);
    const [users, setUsers] = useState(null);

    const handleSaveGmsDiscretionaryProjectFiscalAgentsUsers = async (fiscalAgentIds, projectUserIds) => {
        const fiscalAgentUsers = createSaveFiscalAgentsUsersArray(fiscalAgentIds, projectUserIds);

        await actions.executeApi(gmsManageFiscalAgentsApi.saveDiscretionaryProjectFiscalAgents, [discretionaryProjectId, fiscalAgentUsers]);
        gotoLocation(gmsLocations.GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS.path, true);
    };

    const initializeData = async () => {
        const result = await actions.executeApi(gmsManageDiscretionaryProjectsApi.getDiscretionaryProject, [discretionaryProjectId]);
        const project = createDiscretionaryProjectObject(result);
        setDiscretionaryProject(project);

        let userSearchCriteria = new UserSearchCriteria("", `${contexts.GMS}.${behaviors.VIEW}`, userConstants.USER_TYPES.District, userConstants.STATUS.ACTIVE);
        userSearchCriteria.RecordsPerPage = 0;
        userSearchCriteria.SortByColumn = "LastName";
        userApi.getUsers(userSearchCriteria).then((result) => setUsers(result.data));
    };

    useEffect(() => {
        initializeData();
    }, []);

    useEffect(() => {
        if(isNullOrUndefined(discretionaryProject)) return;

        actions.updatePageTitle(`GMS Admin - ${discretionaryProject.projectName}`);
        setPageTitle(`GMS Admin - ${discretionaryProject.projectName} - Associate Fiscal Agents and Users`);
    }, [discretionaryProject]);

    if (isNullOrUndefined(discretionaryProject) || isArrayNullOrEmpty(allFiscalAgents))
        return null;

    return (
        <GmsManageFiscalAgentsUsersView
            discretionaryProject={discretionaryProject}
            fiscalAgents={allFiscalAgents}
            gotoLocation={gotoLocation}
            handleDataChanged={handleDataChanged}
            handleSaveGmsDiscretionaryProjectFiscalAgentsUsers={handleSaveGmsDiscretionaryProjectFiscalAgentsUsers}
            isLoading={isLoading}
            users={createProjectUsersSelectList(users)}
        />
    );
};

GmsManageFiscalAgentsUsersContainer.propTypes = {
    allFiscalAgents: PropTypes.array,
    actions: PropTypes.object,
    discretionaryProjectId: PropTypes.string.isRequired,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default GmsManageFiscalAgentsUsersContainer;
