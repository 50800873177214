import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import {monitoring_performance_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import { ToastrNotificationFactory } from './notifications/ToastrNotificationFactory';
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';

const notificationFactory = new ToastrNotificationFactory();

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringPerformanceApi {
	static loadMonitoringPerformanceStatuses() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments',
			RequestTypes.GET));
	}

	static loadMonitoringPerformanceAssessment(assessmentId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + assessmentId,
			RequestTypes.GET));
	}

	static createMonitoringPerformanceAssessment(assessment) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments',
			RequestTypes.POST,
			JSON.stringify(assessment),
			null,
			'application/json'));
	}

	static updateMonitoringPerformanceAssessment(assessment) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments',
			RequestTypes.PUT,
			JSON.stringify(assessment),
			ApiSuccessMessages.assessment.DETAILS_UPDATED,
			'application/json'));
	}

	static deleteAssessmentIndicator(assessmentId, assessmentIndicatorId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + "/indicators/" + assessmentIndicatorId,
			RequestTypes.DELETE,
			null,
			ApiSuccessMessages.indicator.DELETE));
	}

	static loadAssessmentIndicatorActions(assessmentId, assessmentIndicatorId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + "/indicators/" + assessmentIndicatorId + "/actions",
			RequestTypes.GET));
	}

	static deleteAssessmentIndicatorAction(assessmentId, assessmentIndicatorId, assessmentIndicatorActionId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + "/indicators/" + assessmentIndicatorId + "/actions/" + assessmentIndicatorActionId,
			RequestTypes.DELETE,
			null,
			ApiSuccessMessages.action.DELETE));
	}

	static createAssessmentIndicator(assessmentIndicator) {
		const successMessage = assessmentIndicator.indicatorAssessmentId === config.emptyGuid ? ApiSuccessMessages.indicator.CREATE : ApiSuccessMessages.indicator.UPDATE;
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}monitoring/performance/assessments/${assessmentIndicator.monitoringAssessmentId}/indicators`,
			RequestTypes.POST,
			JSON.stringify(assessmentIndicator),
			successMessage,
			'application/json'));
	}

	static createIndicatorAction(indicatorActionDto) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + indicatorActionDto.monitoringAssessmentId +
				'/indicators/' + indicatorActionDto.indicatorAssessmentId + "/actions",
			RequestTypes.POST,
			JSON.stringify(indicatorActionDto),
			ApiSuccessMessages.action.CREATE,
			'application/json'));
	}

	static updateIndicatorAction(indicatorActionDto) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + indicatorActionDto.monitoringAssessmentId +
				'/indicators/' + indicatorActionDto.indicatorAssessmentId + "/actions/" + indicatorActionDto.actionId,
			RequestTypes.PUT,
			JSON.stringify(indicatorActionDto),
			ApiSuccessMessages.action.UPDATE,
			'application/json'));
	}

	static createIndicatorActionResponse(response) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}monitoring/performance/assessments/${response.monitoringAssessmentId}/indicators/
				${response.indicatorAssessmentId}/actions/${response.actionId}/responses`,
			RequestTypes.POST,
			JSON.stringify(response),
			ApiSuccessMessages.response.CREATE,
			'application/json'));
	}

	static updateIndicatorActionResponse(response) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			`${config.apiUrl}monitoring/performance/assessments/${response.monitoringAssessmentId}/indicators/
				${response.indicatorAssessmentId}/actions/${response.actionId}/responses/${response.responseId}`,
			RequestTypes.PUT,
			JSON.stringify(response),
			ApiSuccessMessages.response.UPDATE,
			'application/json'));
	}

	static loadIndicatorActionResponse(assessmentId, indicatorId, actionId, responseId) {
		return new Promise((resolve, reject) => {
			let request = AjaxRequestOptions(
				config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + '/indicators/' + indicatorId + "/actions/" + actionId + "/responses/" + responseId,
				RequestTypes.GET);

			request.contentType = 'application/json';
			request.setCallbacks(function(){
				notificationFactory.createNone().notify();
				resolve(assessmentId);
			}, function(data){
				reject(data);
			});

			return ApiUtility.sendRequest(request);
		});
	}

	static updateDistrictAccess(assessmentId, allowAccess) {
		return new Promise((resolve, reject) => {
			let request = AjaxRequestOptions(
				config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + '/district-access-allowed',
				RequestTypes.PUT,
				JSON.stringify(allowAccess.toString()));

			request.contentType = 'application/json';
			request.setCallbacks(function() {
				notificationFactory.createSuccess(ApiSuccessMessages.response.UPDATE).notify();
				resolve(assessmentId);
			}, function(data){
				reject(data);
			});

			return ApiUtility.sendRequest(request);
		});
	}

	static submitDistrictActionResponse(assessmentId, indicatorId, actionId) {
		return new Promise((resolve, reject) => {
			let request = AjaxRequestOptions(
				config.apiUrl + 'monitoring/performance/assessments/' + assessmentId + '/indicators/' + indicatorId + '/actions/' + actionId + '/submit',
				RequestTypes.POST);

			request.contentType = 'application/json';
			request.setCallbacks(function() {
				notificationFactory.createSuccess(ApiSuccessMessages.response.SUBMIT).notify();
				resolve(assessmentId);
			}, function(data) {
				reject(data);
			});

			return ApiUtility.sendRequest(request);
		});
	}

	static rejectDistrictActionResponse(outcomeDto) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + outcomeDto.assessmentId + '/indicators/' + outcomeDto.indicatorAssessmentId + '/actions/' + outcomeDto.actionId + '/revise',
			RequestTypes.POST,
			null,
			ApiSuccessMessages.response.REJECT));
	}

	static approveDistrictActionResponse(outcomeDto) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + outcomeDto.assessmentId + '/indicators/' + outcomeDto.indicatorAssessmentId + '/actions/' + outcomeDto.actionId + '/approve',
			RequestTypes.POST,
			null,
			ApiSuccessMessages.response.APPROVE));
	}

	static resetDistrictActionResponse(outcomeDto) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + 'monitoring/performance/assessments/' + outcomeDto.assessmentId + '/indicators/' + outcomeDto.indicatorAssessmentId + '/actions/' + outcomeDto.actionId + '/reset',
			RequestTypes.POST,
			null,
			ApiSuccessMessages.response.RESET));
	}

	static ResponseAuditComment(auditCommentDto, isCreate, assessmentId, indicatorId, actionId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
			config.apiUrl + `monitoring/performance/assessments/${assessmentId}/indicators/${indicatorId}/actions/${actionId}/audits/${auditCommentDto.auditId}/comments`,
			isCreate ? RequestTypes.POST : RequestTypes.PUT,
			JSON.stringify(auditCommentDto),
			ApiSuccessMessages.action.COMMENT));
	}
}

//************************************************** MOCK API SERVER CALLS ****************************************

const districtStatuses = [
	{
		monitoringAssessmentId: "aaaaaa",
		districtId: "1",
		districtName: "Alachua",
		monitoringType: 1,
		visitStartDate: "5/1/2018",
		visitEndDate: "7/1/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "AAAA",
				indicatorId: "IND1",
				publicIdentifier: "1A",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "BBBB",
				indicatorId: "IND2",
				publicIdentifier: "1B",
				isRequired: "true"
			}
		]
	},
	{
		monitoringAssessmentId: "bbbbbb",
		districtId: "2",
		districtName: "Brevard",
		monitoringType: 2,
		visitStartDate: "5/10/2018",
		visitEndDate: "7/10/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "CCCC",
				indicatorId: "IND3",
				publicIdentifier: "1C",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "DDDD",
				indicatorId: "IND4",
				publicIdentifier: "1D",
				isRequired: "true"
			}
		]
	},
	{
		monitoringAssessmentId: "cccccc",
		districtId: "3",
		districtName: "Broward",
		monitoringType: 1,
		visitStartDate: "5/20/2018",
		visitEndDate: "7/20/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "EEEE",
				indicatorId: "IND5",
				publicIdentifier: "1E",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "FFFF",
				indicatorId: "IND6",
				publicIdentifier: "1F",
				isRequired: "true"
			}
		]
	}
];

const performanceAssessments = [
	{
		monitoringAssessmentId: "aaaaaa",
		districtId: "1",
		monitoringType: 1,
		visitStartDate: "05/01/2018",
		visitEndDate: "07/01/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "1",
				indicatorId: "a",
				publicIdentifier: "SPP 1A",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "2",
				indicatorId: "b",
				publicIdentifier: "SPP 2B",
				isRequired: "true"
			}
		]
	},
	{
		monitoringAssessmentId: "bbbbbb",
		districtId: "2",
		monitoringType: 1,
		visitStartDate: "05/05/2018",
		visitEndDate: "07/05/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "3",
				indicatorId: "c",
				publicIdentifier: "SPP 3C",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "4",
				indicatorId: "d",
				publicIdentifier: "SPP 4D",
				isRequired: "true"
			}
		]
	},
	{
		monitoringAssessmentId: "cccccc",
		districtId: "3",
		monitoringType: 2,
		visitStartDate: "05/11/2018",
		visitEndDate: "07/11/2018",
		indicatorAssessments: [
			{
				indicatorAssessmentId: "5",
				indicatorId: "e",
				publicIdentifier: "SPP 5E",
				isRequired: "true"
			},
			{
				indicatorAssessmentId: "6",
				indicatorId: "f",
				publicIdentifier: "SPP 6F",
				isRequired: "true"
			}
		]
	}
];

const indicatorActions = [
	{
		monitoringAssessmentId: "aaaaaa", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "1",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION1",
		actionSummary: "Summary for action 1 - aaaaaa:1",
		dueDate: "5/31/2018"
	},
	{
		monitoringAssessmentId: "aaaaaa", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "1",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION2",
		actionSummary: "Summary for action 2 - aaaaaa:1",
		dueDate: "6/1/2018"
	},
	{
		monitoringAssessmentId: "aaaaaa", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "2",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION3",
		actionSummary: "Summary for action 3 - aaaaaa:2",
		dueDate: "6/2/2018"
	},
	{
		monitoringAssessmentId: "bbbbbb", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "3",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION4",
		actionSummary: "Summary for action 4 - bbbbbb:3",
		dueDate: "6/3/2018"
	},
	{
		monitoringAssessmentId: "bbbbbb", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "3",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION5",
		actionSummary: "Summary for action 5 - bbbbbb:3",
		dueDate: "6/4/2018"
	},
	{
		monitoringAssessmentId: "cccccc", //For Mock purposes. Not part of DTO
		indicatorAssessmentId: "5",  //For Mock purposes.  Not part of DTO
		actionId: "ACTION6",
		actionSummary: "Summary for action 6 - cccccc:5",
		dueDate: "6/5/2018"
	}
];

const actionResponses = [
	{
		responseId: "RESPONSE1",
		monitoringAssessmentId: "aaaaaa",
		indicatorAssessmentId: "1",
		actionId: "ACTION1",
		resolutionDescription: "Visited the parents",
		dateResolutionCompleted: "7/1/2018",
		fileItems: []
	}
];

class MockMonitoringPerformanceApi {
	static loadMonitoringPerformanceStatuses() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve([...districtStatuses]);
			}, config.environment.delay);
		});
	}

	static loadMonitoringPerformanceAssessment(assessmentId) {
		return new Promise((resolve) => {
			const assessment = performanceAssessments.filter(ass => ass.monitoringAssessmentId === assessmentId)[0];

			setTimeout(() => {
				resolve(Object.assign(assessment));
			}, config.environment.delay);
		});
	}

	static createMonitoringPerformanceAssessment(assessment) {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(assessment);
			}, config.environment.delay);
		});
	}

	static deleteAssessmentIndicator() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static loadAssessmentIndicatorActions(assessmentId, assessmentIndicatorId) {
		return new Promise((resolve) => {
			const actions = indicatorActions.filter(ia => ia.monitoringAssessmentId === assessmentId && ia.indicatorAssessmentId === assessmentIndicatorId);

			setTimeout(() => {
				resolve(actions);
			}, config.environment.delay);
		});
	}

	static deleteAssessmentIndicatorAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static createAssessmentIndicator(assessmentIndicator) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(assessmentIndicator);
			}, config.environment.delay);
		});
	}

	static createIndicatorAction() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static createIndicatorActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static updateIndicatorActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static loadIndicatorActionResponse(assessmentId, indicatorId, actionId, responseId) {
		return new Promise(resolve => {
			const response = actionResponses.filter(r => r.monitoringAssessmentId === assessmentId && indicatorId === indicatorId && actionId === actionId && responseId === responseId)[0];

			setTimeout(() => {
				resolve(response);
			}, config.environment.delay);
		});
	}

	static updateDistrictAccess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static submitDistrictActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static rejectDistrictActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static approveDistrictActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static resetDistrictActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static ResponseAuditComment() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}
}

const MonitoringPerformanceApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringPerformanceApi : MockMonitoringPerformanceApi;
export default MonitoringPerformanceApi;