import * as actionTypes from "../constants/actionTypes";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import MonitoringComplianceStudentCorrectionsApi from "../api/MonitoringComplianceStudentCorrectionsApi";

export function loadComplianceWithStudentCorrectiveResultsSuccess(studentCorrectionResults){
    return { type: actionTypes.LOAD_COMPLIANCE_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS, data: studentCorrectionResults};
}

export function loadComplianceWithStudentCorrectiveResults(monitoringAssessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.loadComplianceWithStudentCorrectiveResults(monitoringAssessmentId)
            .then((data) => {
                dispatch(loadComplianceWithStudentCorrectiveResultsSuccess(data));
                return data;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadComplianceWithMatrixCorrectiveResultsSuccess(studentCorrectionResults){
    return { type: actionTypes.LOAD_COMPLIANCE_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS, data: studentCorrectionResults};
}

export function loadComplianceWithMatrixCorrectiveResults(monitoringAssessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.loadComplianceWithMatrixCorrectiveResults(monitoringAssessmentId)
            .then((data) => {
                dispatch(loadComplianceWithMatrixCorrectiveResultsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadDistrictComplianceWithStudentCorrectiveResultsSuccess(studentCorrectionResults){
    return { type: actionTypes.LOAD_COMPLIANCE_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS, data: studentCorrectionResults};
}

export function loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId)
            .then((data) => {
                dispatch(loadDistrictComplianceWithStudentCorrectiveResultsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadDistrictComplianceWithMatrixCorrectiveResultsSuccess(matrixCorrectionResults){
    return { type: actionTypes.LOAD_COMPLIANCE_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS, data: matrixCorrectionResults};
}

export function loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId)
            .then((data) => {
                dispatch(loadDistrictComplianceWithMatrixCorrectiveResultsSuccess(data));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function saveStudentCorrectiveResultsSuccess(){
    return { type: actionTypes.SAVE_DISTRICT_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS };
}

export function saveStudentCorrectiveResults(assessmentId, studentRecordId, studentCorrectionResults){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.saveStudentCorrectiveResults(assessmentId, studentRecordId, studentCorrectionResults)
            .then((() => {
                dispatch(saveStudentCorrectiveResultsSuccess());
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function saveMatrixCorrectiveResultSuccess(){
    return { type: actionTypes.SAVE_DISTRICT_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS };
}

export function saveMatrixCorrectiveResult(assessmentId, studentRecordId, matrixCorrectionResult){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.saveMatrixCorrectiveResult(assessmentId, studentRecordId, matrixCorrectionResult)
            .then((() => {
                dispatch(saveMatrixCorrectiveResultSuccess());
            }))
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function changeStudentCorrectionResult(studentProtocolCorrectiveResultId, studentProtocolCorrectiveResult) {
    return { type: actionTypes.CHANGE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT, data: {studentProtocolCorrectiveResult, studentProtocolCorrectiveResultId} };
}

export function changeMatrixCorrectionResult(matrixProtocolCorrectiveResultId, matrixProtocolCorrectiveResult) {
    return { type: actionTypes.CHANGE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT, data: {matrixProtocolCorrectiveResult, matrixProtocolCorrectiveResultId} };
}

export function submitStudentCorrectiveResultsSuccess(assessmentId){
    return { type: actionTypes.SUBMIT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS, data: {assessmentId} };
}

export function submitStudentCorrectiveResults(assessmentId, studentRecordId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.submitDistrictStudentCorrectiveResults(assessmentId, studentRecordId)
            .then(() => {
                dispatch(submitStudentCorrectiveResultsSuccess(assessmentId));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function submitMatrixCorrectiveResultsSuccess(assessmentId){
    return { type: actionTypes.SUBMIT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS, data: {assessmentId} };
}

export function submitMatrixCorrectiveResults(assessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.submitDistrictMatrixCorrectiveResults(assessmentId)
            .then(() => {
                dispatch(submitMatrixCorrectiveResultsSuccess(assessmentId));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function rejectStudentProtocolCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.REJECT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS , data: outcomeDto };
}

export function rejectStudentProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.rejectDistrictStudentCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(rejectStudentProtocolCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function approveStudentCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.APPROVE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS, data: outcomeDto };
}

export function approveStudentProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.approveDistrictStudentCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(approveStudentCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function resetStudentProtocolCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.RESET_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS, data: outcomeDto };
}

export function resetStudentProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.resetDistrictStudentCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(resetStudentProtocolCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function rejectMatrixProtocolCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.REJECT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS , data: outcomeDto };
}

export function rejectMatrixProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.rejectDistrictMatrixCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(rejectMatrixProtocolCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function approveMatrixCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.APPROVE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS, data: outcomeDto };
}

export function approveMatrixProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.approveDistrictMatrixCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(approveMatrixCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function resetMatrixProtocolCorrectiveResultSuccess(outcomeDto){
    return { type: actionTypes.RESET_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS, data: outcomeDto };
}

export function resetMatrixProtocolCorrectiveResult(outcomeDto){
    return dispatch => {
        dispatch(beginAjaxCall());

        return MonitoringComplianceStudentCorrectionsApi.resetDistrictMatrixCorrectiveResult(outcomeDto)
            .then(() => {
                dispatch(resetMatrixProtocolCorrectiveResultSuccess(outcomeDto));
            })
            .catch(() => {
                dispatch(ajaxCallError());
            });
    };
}

export function createComment(auditId, correctionId){
    return { type: actionTypes.CREATE_COMPLIANCE_AUDIT_COMMENT, data: { auditId, correctionId}};
}

export function editComment(auditCommentId){
    return { type: actionTypes.EDIT_COMPLIANCE_AUDIT_COMMENT, data: auditCommentId };
}

export function cancelComment(){
    return { type: actionTypes.CANCEL_COMPLIANCE_AUDIT_COMMENT };
}

export function changeComment(comment, correctionId){
    return { type: actionTypes.CHANGE_COMPLIANCE_AUDIT_COMMENT, data: { comment, correctionId } };
}

export function addAuditCommentSuccess(auditCommentViewModel, correctionId, auditCommentId){
    return { type: actionTypes.ADD_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS, data: { comment: auditCommentViewModel, correctionId, auditCommentId } };
}

export function addAuditComment(auditCommentViewModel, correctionId, assessmentId){
    return dispatch => {
        dispatch(beginAjaxCall());
        return MonitoringComplianceStudentCorrectionsApi.ResponseAuditComment(auditCommentViewModel, true, assessmentId, correctionId)
            .then((auditCommentId) => {
                dispatch(addAuditCommentSuccess(auditCommentViewModel, correctionId, auditCommentId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}

export function updateAuditCommentSuccess(){
    return { type: actionTypes.UPDATE_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS };
}

export function updateAuditComment(auditCommentViewModel, assessmentId, correctionId){
    return dispatch => {
        dispatch(beginAjaxCall());
        return MonitoringComplianceStudentCorrectionsApi.ResponseAuditComment(auditCommentViewModel, false, assessmentId, correctionId)
            .then(() => {
                dispatch(updateAuditCommentSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}