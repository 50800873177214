import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Allow from "../../../components/authorization/Allow";
import {
    MonitoringCompliancePolicy,
    policyEvents
} from "../../../components/authorization/policies/MonitoringCompliancePolicy";
import ActionBar from "../../../components/common/buttons/ActionBar";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";

export const ValidationNotesView = ({
                                        actions,
                                        assessmentId,
                                        currentValidationNote,
                                        editMode,
                                        handleDataChanged,
                                        indicatorRequirementId,
                                        indicatorTitle,
                                        isLoading,
                                        loadComplianceAssessment,
                                        setEditModId
                                    }) => {
    const [validationNote, setValidationNote] = useState("");


    const onValidationEdit = (event) => {
        event.preventDefault();

        setEditModId(indicatorRequirementId);
    };

    const onValidationNotesSave = (event) => {
        event.preventDefault();
        actions.updateValidationNotes(assessmentId, indicatorRequirementId, validationNote)
            .then(() => {
                loadComplianceAssessment();
            })
        handleDataChanged(false);
        setEditModId("");
    }

    const onValidationNotesCancel = (event) => {
        event.preventDefault();

        setValidationNote(currentValidationNote);
        setEditModId("");
        handleDataChanged(false);
    }

    const onValidationNotesChange = (event) => {
        setValidationNote(event.target.value);
        handleDataChanged();
    }

    useEffect(() => {
        setValidationNote(isTrimmedStringEmpty(currentValidationNote) ? "None" : currentValidationNote);
    }, [currentValidationNote])

    return (
        <section className={"compliance__validationNotes"}>
            {
                !editMode &&
                <Allow policy={MonitoringCompliancePolicy}
                       policyEvent={policyEvents.monitoring_compliance.assessment.SUBMIT}>
                    <ActionBar
                        elementId={`validationNotesActionBar_${indicatorRequirementId}`}
                        onEditClick={onValidationEdit}
                        disabled={isLoading}
                    />
                </Allow>
            }
            <h3>Validation Notes for {indicatorTitle}</h3>
            <p><em>These notes are visible to the district after validation is completed.</em></p>

            {
                !editMode &&
                <HtmlDisplayBlock html={validationNote}/>
            }
            {
                editMode &&
                <>
                    <TextAreaEditor
                        disabled={isLoading}
                        label={"Validation Notes"}
                        name={`taValidationNotes_${indicatorRequirementId}`}
                        onChange={onValidationNotesChange}
                        showLabel={false}
                        value={validationNote}
                    />
                    <ButtonBar position={ButtonBarPositions.BOTTOM}>
                        <Button
                            buttonType={ButtonTypes.SAVE}
                            disabled={isLoading}
                            label={"Save"}
                            name={`save_${indicatorRequirementId}`}
                            onClick={onValidationNotesSave}
                        />
                        <Button
                            buttonType={ButtonTypes.CANCEL}
                            disabled={isLoading}
                            label={"Cancel"}
                            name={`cancel_${indicatorRequirementId}`}
                            onClick={onValidationNotesCancel}
                        />
                    </ButtonBar>
                </>
            }
        </section>
    );
};

ValidationNotesView.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    currentValidationNote: PropTypes.string,
    editMode: PropTypes.bool.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    indicatorRequirementId: PropTypes.string.isRequired,
    indicatorTitle: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    setEditModId: PropTypes.func.isRequired
};