import * as ApiUtility from "./ApiUtility";
import * as config from "../constants/config";
import {spp_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import * as sppElementTypes from "../constants/spp/sppElementTypes";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerSppApi {

	static getSppDistrictTiles(institutionId, dateWindowGroupingId, routeUrl) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Spp/statuses/${dateWindowGroupingId}/tiles/institutions/${institutionId}`,
            RequestTypes.POST,
            { routeUrl },
            null,
            `application/x-www-form-urlencoded`));
	}

	static GetSppInstances() {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'Spp/GetDocuments',
            RequestTypes.GET));
	}

	static GetSppDocument(documentId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'Spp/GetSppDocument',
            RequestTypes.GET,
            {documentId: documentId}));
	}

    static GetSppDocumentElements(documentId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'Spp/GetSppDocumentElements',
            RequestTypes.GET,
            {documentId: documentId}));
	}

	static SaveSppResponses(documentId, responses, markReadyToSubmit) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Spp/documents/${documentId}`,
            RequestTypes.POST,
            JSON.stringify(responses),
            markReadyToSubmit ? ApiSuccessMessages.response.READY : null));
	}

	static GetInstitutionResponses(institutionId, documentId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Spp/documents/${documentId}/Institutions/${institutionId}`,
            RequestTypes.GET));
	}

	static SubmitResponses(documentId, responses) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Spp/documents/${documentId}/submit`,
            RequestTypes.POST,
            JSON.stringify(responses),
            ApiSuccessMessages.response.SUBMIT));
	}

	static GetDateWindowGroupingElements(dateWindowGroupingId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'Spp/GetSppDateWindowGroupingElements',
            RequestTypes.GET,
            { dateWindowGroupingId: dateWindowGroupingId }));
	}

	static GetPolicyCompliance(documentId, institutionId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "Spp/PolicyCompliance/" + documentId + "/" + institutionId,
            RequestTypes.GET));
	}

	static LoadSppDistrictStatuses(dateWindowId) {
		return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "Spp/statuses/district-compliances",
            RequestTypes.GET,
            { dateWindowId }));
	}

    static saveCopyStrategy(documentId, sectionCopyConfigs) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Spp/documents/${documentId}/saveSectionCopyStrategies`,
            RequestTypes.POST,
            JSON.stringify(sectionCopyConfigs),
            ApiSuccessMessages.copyStrategy.SAVE));
    }

}
//************************************************** MOCK API SERVER CALLS ****************************************

const documentHierarchy = JSON.stringify([
    {
        elementId: "1",
        childElements:[
            {
                elementId: "3",
                childElements:[
                    {
                        elementId: "7",
                        childElements:[
                            {
                                elementId: "15",
                                childElements: []
                            },
                            {
                                elementId: "16",
                                childElements: []
                            },
                            {
                                elementId: "18",
                                childElements: []
                            }
                        ]
                    }
                ]
            },
            {
                elementId: "4",
                childElements:[
                    {
                        elementId: "9",
                        childElements:[
                            {
                                elementId: "19",
                                childElements: []
                            },
                            {
                                elementId: "20",
                                childElements: []
                            }
                        ]
                    },
                    {
                        elementId: "10",
                        childElements:[
                            {
                                elementId: "21",
                                childElements: []
                            },
                            {
                                elementId: "22",
                                childElements: []
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        elementId: "2",
        childElements: [
            {
                elementId: "5",
                childElements:[
                    {
                        elementId: "11",
                        childElements:[
                            {
                                elementId: "23",
                                childElements: []
                            },
                            {
                                elementId: "24",
                                childElements: []
                            }
                        ]
                    },
                    {
                        elementId: "12",
                        childElements:[
                            {
                                elementId: "25",
                                childElements: []
                            },
                            {
                                elementId: "26",
                                childElements: []
                            }
                        ]
                    }
                ]
            }
        ]
    }
]);

const spps = [
    {
        id: "1",
        dateWindowGroupingId: "1",
        dateWindowId: "3",
        name: "",
        status: "Approved",
        componentsHierarchy: documentHierarchy
    },
    {
        id: "1",
        dateWindowGroupingId: "1",
        dateWindowId: "2299574e-6ca2-4997-83b8-46c3019a18a7",
        name: "Revision 1",
        status: "Approved",
        componentsHierarchy: documentHierarchy
    },
    {
        id: "1",
        dateWindowGroupingId: "1",
        dateWindowId: "b6364825-d523-4797-af2b-6a56735cfe44",
        name: "Revision 2",
        status: "Approved",
        componentsHierarchy: documentHierarchy
    },
    {
        id: "1",
        dateWindowGroupingId: "2",
        dateWindowId: "9c644736-eea5-43ca-1088-08d466512670",
        name: "",
        status: "Not Started",
        componentsHierarchy: documentHierarchy
    }
];

const elements = [
    {
        id: "1",
        text: "Part 1: How to train your cat",
        value: "",
        layout: sppElementTypes.Progress,
        isRequired: false,
    },
        {
            id: "3",
            text: "Section 1: Use of nip",
            value: "",
            layout: sppElementTypes.Progress,
            isRequired: false,
        },
            {
                id: "7",
                text: "",
                value: "",
                layout: sppElementTypes.Progress,
                isRequired: false,
            },
                {
                    id: "15",
                    text: "We recommend that you give you cat nip whenever possible because it's fun to watch him or her bug out. If you really think you can train your cat, you always give it nip when it's being nice too.",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "16",
                    text: "How do you use nip to train your cat?",
                    value: "",
                    layout: sppElementTypes.TextArea,
                    isRequired: true,
                },
                {
                    id: "17",
                    text: "There are several times of the day during which it is best to give you cat nip according to us. Those times are Morning, Noon (when you go home for lunch because you miss your cat so much and you know it has anziety issues while you're gone), and Night.",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "18",
                    text: "At what time of the day do you usually give your cat nip?",
                    layout: sppElementTypes.Radio,
                    isRequired: true,
                    value: JSON.stringify([
                        {
                            text: "Morning",
                            value: "Morning"
                        },
                        {
                            text: "Afternoon",
                            value: "Afternoon"
                        },
                        {
                            text: "Evening",
                            value: "Evening"
                        }
                    ])
                },
        {
            id: "4",
            text: "Section 2: Use of toys",
            value: "",
            layout: sppElementTypes.Progress,
            isRequired: false,
        },
            {
                id: "9",
                text: "",
                value: "",
                layout: sppElementTypes.Progress,
                isRequired: false,
            },
                {
                    id: "19",
                    text: "<strong>Toys are important for indoor cats</strong><p>We recommend that you give you toys to your indoor cat that it doesn't go mad from boredom</p>",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "20",
                    text: "By checking this box, you are indicating that you understand that your cat gets bored all day, and you should give him toys.",
                    value: "",
                    layout: sppElementTypes.Checkbox,
                    isRequired: true,
                },
            {
                id: "10",
                text: "",
                value: "",
                layout: sppElementTypes.Progress,
                isRequired: false,
            },
                {
                    id: "21",
                    text: "<h2>Toy Types</h2><p>If you take time to learn your cat's toy preferences, you can provide your cat with a higher quality of life. Most cat owners agree that maintaining the highest quality of life possible for your feline overlord is of paramount importance. So we recommend that you do that.</p>",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "22",
                    text: "What is the brand of your cat's favorite toy?",
                    value: "",
                    layout: sppElementTypes.TextBox,
                    isRequired: true,
                },

    {
        id: "2",
        text: "Part 2: How to train your dog",
        value: "",
        layout: sppElementTypes.Progress,
        isRequired: false,
    },
        {
            id: "5",
            text: "Section 1: Use of your humanity",
            value: "",
            layout: sppElementTypes.Progress,
            isRequired: false,
        },
            {
                id: "11",
                text: "",
                value: "",
                layout: sppElementTypes.Progress,
                isRequired: false,
            },
                {
                    id: "23",
                    text: "Lucky you. You are human. This means that, thanks to the last 10,000 or so years of selective breeding, your dog is pretty much just going to listen to you fot the most part.",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "24",
                    text: "Please upload a file that has a picture of your dog and maybe you if you're feeling adventurous",
                    value: "",
                    layout: sppElementTypes.File,
                    isRequired: true,
                },
            {
                id: "12",
                text: "",
                value: "",
                layout: sppElementTypes.Progress,
                isRequired: false,
            },
                {
                    id: "25",
                    text: "Your humanity also means you're intelligent. In fact, we asked 1000 dogs if they thought their owners were the smarted people in the world and measured owner intelligence by number of tails wags in the 10 seconds following the question.",
                    value: "",
                    layout: sppElementTypes.Display,
                    isRequired: false,
                },
                {
                    id: "26",
                    text: "If we ask your dog how intelligent its owner is, how many wags will it give in the following ten seconds?",
                    layout: sppElementTypes.DropDown,
                    isRequired: true,
                    value:JSON.stringify([
                        {
                            text: "So Many Wags",
                            value: "1"
                        },
                        {
                            text: "Soh Meny Waggs",
                            value: "2"
                        },
                        {
                            text: "Sew Mini Wigs",
                            value: "3"
                        }
                    ])
                }
];

const responses = [
    {
        elementId: "16",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "Ready to Submit",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }]
    },
    {
        elementId: "18",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "Ready to Submit",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }]
    },
    {
        elementId: "20",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "Ready to Submit",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }]
    },
    {
        elementId: "22",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "Ready to Submit",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }],
    },
    {
        elementId: "24",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: false,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "Ready to Submit",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }],
    },
    {
        elementId: "26",
        institutionId: "1",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadyToSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }],
    },
    {
        elementId: "26",
        institutionId: "2",
        policyResponseId: "",
        applicationUserId: "",
        data: "",
        isReadytTSubmit: true,
        audits: [{
            auditId: "",
            isLocked: false,
            outcome: "",
            comments: {
                auditCommentId: "",
                auditId: "",
                applicationUserId: "",
                text: ""
            }
        }],
    },
];

const districtStatuses = [
    {
        districtId: "1",
        documentId: "1",
        districtName: "Alachua",
        progress: "submitted",
        needsReviewCount: "4",
        needsRevisionCount: "4",
        sectionsNeedingReview: [],
        sectionsNeedingRevision: [],
    }
];

const districtSppTile = {
	header: "2016-2017",
	footer: "Accepted",
	url: "/Spp/Document/1"
};

class MockSppApi {
	static getSppDistrictTiles() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(districtSppTile);
			}, config.environment.delay);
		});
	}

    static GetSppInstances() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...spps]);
            }, config.environment.delay);
        });
    }

    static GetSppDocument(documentId) {
        return new Promise(resolve => {
            const selectedDocument = spps.filter(d => d.id === documentId)[0];

            setTimeout(() => {
                resolve(Object.assign(selectedDocument));
            }, config.environment.delay);
        });
    }

    static GetSppDocumentElements() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...elements]);
            }, config.environment.delay);
        });
    }

    static SaveSppResponses() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetInstitutionResponses() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(responses);
            }, config.environment.delay);
        });
    }

    static SubmitResponses() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetDateWindowGroupingElements() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetPolicyCompliance() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static LoadSppDistrictStatuses() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(districtStatuses);
            }, config.environment.delay);
        });
    }

    static saveCopyStrategy() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}
const SppApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSppApi : MockSppApi;
export default SppApi;
