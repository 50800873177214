import React from "react";
import PropTypes from "prop-types";
import {createListIfExistsInIdArray} from "../common/commonUtilities";
import TextareaField from "../common/inputs/TextArea";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as indicatorTypes from "../../constants/indicatorTypes";
import ListManagerField from "../common/inputs/ListManagerField";
import IndicatorMetrics from "./IndicatorMetrics";
import FileUploadField from "../common/inputs/FileUploadField";

const StrategyIndicatorForm = ({
	                               sppIndicators,
	                               strategyIndicator,
	                               metricId,
	                               filters,
	                               onChange,
	                               onSave,
	                               onCancel,
	                               isLoading
                               }) => {

	const addedSppIndicators = strategyIndicator.indicators.filter(indicator =>
		indicator.indicatorType === indicatorTypes.SPP).map(ind => {
			return ind.text;
		}
	);

	const sppIndicatorSelectListItems = sppIndicators.filter(ind => !ind.isDeleted).map(ind => {
		return {text: ind.publicIdentifier, value: ind.publicIdentifier, id: ind.publicIdentifier};
	});

	return (
		<section className={"strategy__indicator strategy__indicator--editing "}
		         id={strategyIndicator.id}>
			<header>

				<TextareaField label={"BEESS Indicator"}
				               name={indicatorTypes.BEESS}
				               onChange={onChange}
				               value={strategyIndicator.indicators[0].text}
				               showLabel/>
				<ListManagerField managerLabel={"SPP Indicators"}
				                  name={"updateSppIndicators"}
				                  listItems={sppIndicatorSelectListItems}
				                  onChange={onChange}
								  listValues={createListIfExistsInIdArray(addedSppIndicators, sppIndicatorSelectListItems)}
				                  disabled={isLoading}/>

				<FileUploadField name={"triangleFileId"}
				                 label={"Tiered Districts Document"}
				                 disabled={isLoading}
				                 onChange={onChange}/>

				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<Button name={"save"}
					        label={"Save"}
					        buttonType={ButtonTypes.SAVE}
					        onClick={onSave}
					        disabled={isLoading}/>
					<Button name={"cancel"}
					        onClick={onCancel}
					        label={"Cancel"}
					        buttonType={ButtonTypes.CANCEL}
					        disabled={isLoading}/>
				</ButtonBar>
			</header>

			{
				filters.showIndicators &&
				(strategyIndicator.baseline !== null || strategyIndicator.metrics.length > 0) &&
				<IndicatorMetrics strategyIndicator={strategyIndicator}
				                  metricId={metricId}
				                  filters={filters}
				                  isEditing={true}
				                  isLoading={isLoading}
				                  planStartYear={strategyIndicator.baseline.startYear}
				                  onEditMetrics={function () {
				                  }}/>
			}

		</section>
	);
};

StrategyIndicatorForm.propTypes = {
	sppIndicators: PropTypes.array.isRequired,
	metricId: PropTypes.string.isRequired,
	filters: PropTypes.object.isRequired,
	strategyIndicator: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default StrategyIndicatorForm;