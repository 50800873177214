import React, {useState} from "react";
import PropTypes from "prop-types";
import ButtonIcon from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import GridTable from "../../components/common/GridTable";
import {ReadMore} from "../common/ReadMore";
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {emptyGuid} from "../../constants/config";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ReminderForm from "./ReminderForm";

const ReminderView = ({
                          deleteNotification,
                          getNotifications,
                          reminders,
                          saveNotification
                      }) => {
    const [editReminderId, setEditReminderId] = useState("");

    const handleClickDelete = async (event, reminderId) => {
        event.preventDefault();

        if (!confirm("Are you sure you want to remove this reminder?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            return;

        await deleteNotification(reminderId);
    };

    const handleClickEdit = (event, reminderId) => {
        event.preventDefault();

        setEditReminderId(reminderId);
    };

    const handleReturn = async (reloadList = false) => {
        if (reloadList)
            await getNotifications();

        setEditReminderId("");
    }

    if (editReminderId) {
        return (
            <ReminderForm
                handleReturn={handleReturn}
                reminder={reminders.find(f => f.reminderId === editReminderId)}
                saveNotification={saveNotification}
            />
        );
    }

    return (
        <>
            <GridTable className={`reminders-table`}>
                <thead>
                <tr>
                    <th className={"text-center remind-on"}>Remind On</th>
                    <th>Reminder Notes</th>
                    <th className={"text-center"}>Actions</th>
                </tr>
                </thead>
                <tbody>
                {reminders.map(
                    (remind, index) => {
                        return (
                            <tr key={index}>
                                <td className={"text-center"}>{remind.remindDateOutput}</td>
                                <td>
                                    <ReadMore text={remind.reminderNotes} />
                                </td>
                                <td className={"text-center"}>
                                    <ButtonBar position={ButtonBarPositions.TABLE}>
                                        <ButtonIcon
                                            buttonType={ButtonTypes.EDIT}
                                            label={`Edit reminder for ${remind.remindDateOutput}`}
                                            name={`btnEdit_${remind.reminderId}`}
                                            onClick={(event) => handleClickEdit(event, remind.reminderId)}
                                            showLabel={false}
                                        />
                                        <ButtonIcon
                                            buttonType={ButtonTypes.DELETE}
                                            label={`Delete reminder for ${remind.remindDateOutput}`}
                                            name={`btnDelete_${remind.reminderId}`}
                                            onClick={(event) => handleClickDelete(event, remind.reminderId)}
                                            showLabel={false}
                                        />
                                    </ButtonBar>
                                </td>
                            </tr>
                        );
                    }
                )}
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={3}>
                        <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                            <Tile body={"Add Reminder"}
                                  onClick={(event) => handleClickEdit(event, emptyGuid)}
                                  isNew={true}
                                  isMedium={true}
                            />
                        </TileContainer>
                    </td>
                </tr>
                </tfoot>
            </GridTable>
        </>
    );
};

ReminderView.propTypes = {
    deleteNotification: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    reminders: PropTypes.array,
    saveNotification: PropTypes.func.isRequired
};

export default ReminderView;