import React from "react";
import PropTypes from 'prop-types';
import IndicatorProtocol from "../../../components/MonitoringCompliance/IndicatorProtocol";
import {Link} from 'react-router-dom';
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";

export const McReviewStudentRecordProtocolsView = ({
                                                       handleEditClick,
                                                       indicatorProtocols,
                                                       studentRecord
                                                   }) => {
    return (
        <>
            <h2>Student Record - {studentRecord.name} (<Link to={ComplianceLocations.COMPLIANCE_RECORD_DISTRICT.path} onClick={(event) => handleEditClick(event, studentRecord.studentRecordId)}>Edit</Link>)</h2>
            <IndicatorProtocol
                assessmentApproved={false}
                highlightNonCompliance
                indicatorProtocols={indicatorProtocols}
                protocolResponses={studentRecord.protocolResponses}
            />
        </>
    );
};

McReviewStudentRecordProtocolsView.propTypes = {
    handleEditClick: PropTypes.func.isRequired,
    indicatorProtocols: PropTypes.array.isRequired,
    studentRecord: PropTypes.object.isRequired,
};

export default McReviewStudentRecordProtocolsView;