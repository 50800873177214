import PropTypes from 'prop-types';
import React from "react";
import AccountUtility from "../api/AccountUtility";
import Tile from "../components/common/Tiles/Tile";
import TileContainer from "../components/common/Tiles/TileContainer";

const ReportContextComponent = ({
	                                dateWindowId,
	                                districtId,
									districtReportTiles,
									exportReport,
									isBpieStateUser,
									isDistrictUser,
									printReport,
	                                reportInfo,
									stateReportTiles
								}) => {

	const handleReport = (event, reportIndex, isState) => {
		event.preventDefault();

		const whichTile = isState
			? stateReportTiles[reportIndex]
			: districtReportTiles[reportIndex];

		const isPrintReport = whichTile.component;

		if (isPrintReport) {
			printReport(whichTile.name, reportInfo.context, isState, dateWindowId, districtId, whichTile.title);
			return;
		}

		let apiUrl = whichTile.api.path;
		exportReport(apiUrl, dateWindowId, districtId);
	};

	const isOnlyDistrictUser = isDistrictUser && !isBpieStateUser;
	const userDistrictId = AccountUtility.getUserDetails().InstitutionId;
	const userDistrictIdMatchesSelectedDistrictId = userDistrictId === districtId;
	const someDistrictTilesAllowBpieStateUser = districtReportTiles?.some(s => s.allowBpieStateUser) && isBpieStateUser;
	const stateUserWithSelectedDistrict = districtId && !isDistrictUser;
	const stateBpieUserWithSelectedDistrict =  districtId && (someDistrictTilesAllowBpieStateUser || userDistrictIdMatchesSelectedDistrictId);
	const displayDistrictReportTiles = isOnlyDistrictUser || stateUserWithSelectedDistrict || stateBpieUserWithSelectedDistrict;

	const someStateTilesAllowBpieStateUser = stateReportTiles?.some(s => s.allowBpieStateUser) && isBpieStateUser;
	const displayStateReportTiles = !districtId && (!isDistrictUser || someStateTilesAllowBpieStateUser);

	return (
		<>
			{
				displayDistrictReportTiles &&
				districtReportTiles &&
				districtReportTiles.length > 0 &&
				<div className={reportInfo.themeCss}>
					<TileContainer title={reportInfo.districtTileContainerTitle} rowTilesLarge={"4"}>
						{
							districtReportTiles.map((tile, i) => {
								const showTileForStateUser = !isDistrictUser;
								const showTileForStateBpieUser = tile.allowBpieStateUser && isBpieStateUser || userDistrictIdMatchesSelectedDistrictId;
								if(!showTileForStateUser && !isDistrictUser && !showTileForStateBpieUser) return null;

								return (<Tile key={`${reportInfo.themeCss}-district|${i}`}
								              body={tile.name}
								              onClick={(event) => handleReport(event, i, false)}
								/>);
							})
						}
					</TileContainer>
				</div>
			}
			{
				displayStateReportTiles &&
				stateReportTiles &&
				stateReportTiles.length > 0 &&
				<div className={reportInfo.themeCss}>
					<TileContainer title={reportInfo.stateTileContainerTitle} rowTilesLarge={"4"}>
						{
							stateReportTiles.map((tile, i) => {
								if(!tile.allowBpieStateUser && isDistrictUser) return null;

								return (<Tile key={`${reportInfo.themeCss}-state${i}`}
								              body={tile.name}
								              onClick={(event) => handleReport(event, i, true)}
								/>);
							})
						}
					</TileContainer>
				</div>
			}
		</>
	);
};

ReportContextComponent.propTypes = {
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string,
	districtReportTiles: PropTypes.arrayOf(PropTypes.object),
	exportReport: PropTypes.func.isRequired,
	isBpieStateUser: PropTypes.bool.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	printReport: PropTypes.func.isRequired,
	reportInfo: PropTypes.object,
	stateReportTiles: PropTypes.arrayOf(PropTypes.object)
};

export default ReportContextComponent;