import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AccountUtility from "../../../api/AccountUtility";
import {allow} from "../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy, policyEvents} from "../../../components/authorization/policies/GmsPolicy";
import CaseNotesView from "../../../components/CaseNotes/CaseNotesView";
import {DASHBOARD} from "../../../constants/systemLocations";
import * as gmsLocations from "../gmsLocations";
import {GMS_CASE_NOTE_TYPE, GMS_CASE_NOTE_TYPE_LIST} from "./gmsCaseNoteConstants";
import gmsCaseNotesApi from "./GmsCaseNotesApi";

const GmsCaseNotesContainer = ({
                                   actions,
                                   applicationId,
                                   history
                               }) => {
    const [application, setApplication] = useState(null);
    const canUserEdit = allow(GmsPolicy, policyEvents.ADMIN.CASE_NOTE.modify);

    const handleSaveCaseNote = (caseNote) => {
        return actions.executeApi(gmsCaseNotesApi.saveCaseNote, [applicationId, caseNote])
            .then((result) => {
                setApplication(result);
                return true;
            });
    };

    const handleDeleteCaseNote = (caseNoteId) => {
        return actions.executeApi(gmsCaseNotesApi.deleteCaseNote, [applicationId, caseNoteId])
            .then((result) => {
                setApplication(result);
                return true;
            });
    };

    const handleExportCaseNotes = (caseNoteFilter) => {
        actions.executeApi(gmsCaseNotesApi.exportCaseNotes, [applicationId, caseNoteFilter]);
    };

    const handleReturn = () => {
        const applicationUrl = gmsLocations.GMS_APPLICATION.getUrl(applicationId);
        history.push(applicationUrl);
    };

    const initializeData = () => {
        actions.executeApi(gmsCaseNotesApi.getApplicationWithCaseNotes, [applicationId])
            .then((result) => {
                setApplication(result);
                actions.updatePageTitle(`Case Notes - ${result.applicationTitle}`);
            });
    };

    useEffect(() => {
        if (AccountUtility.isFiscalAgentUser()) {
            history.push(DASHBOARD.path);
            return;
        }

        initializeData();
    }, []);

    if (!application) return null;

    return (
        <div className="caseNotes">
            <CaseNotesView
                caseNotes={application.gmsCaseNotes}
                caseNoteType={GMS_CASE_NOTE_TYPE}
                caseNoteTypeList={GMS_CASE_NOTE_TYPE_LIST}
                handleDeleteCaseNote={handleDeleteCaseNote}
                handleDownloadCaseInvestigationReport={handleExportCaseNotes}
                handleReturn={handleReturn}
                handleSaveCaseNote={handleSaveCaseNote}
                isMinimumCaseNote
                readOnly={!canUserEdit}
            />
        </div>
    );
}

GmsCaseNotesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string,
    history: PropTypes.object.isRequired
};

export default GmsCaseNotesContainer;