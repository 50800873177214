import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonMock from "../../../components/common/buttons/ButtonMock";
import DisplayField from "../../../components/common/inputs/DisplayField";
import GridTable from "../../../components/common/GridTable";
import RadioField from "../../../components/common/inputs/RadioField";
import SelectField from "../../../components/common/inputs/SelectField";
import TextField from "../../../components/common/inputs/TextField";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import Tile from "../../../components/common/Tiles/Tile";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {createFakeEvent, createYesNoList} from "../../../components/common/inputs/inputUtility";
import {createCitationObject} from "../drStateComplaintObjectFactory";
import {getRandomInt} from "../../../components/common/HtmlUtilities";
import {isArrayNullOrEmpty, isInSelectList} from "../../../components/common/commonUtilities";
import {inputStyles} from '../../../constants/inputConstants';
import {styles as ActionButton} from "../../../constants/ActionButton";
import {CitationTypesList} from "../drStateComplaintConstants";

const DrStateComplaintCitationEdit = ({
                                          onChange: setCitations,
                                          value: citations
                                      }) => {
    const handleClickAddCitation = () => {
        let updatedCitations = [...citations];
        const id = `${getRandomInt()}`;
        updatedCitations.push(createCitationObject({id}));
        handleSetCitations(updatedCitations);
    };

    const handleClickRemoveCitation = (event) => {
        let updatedCitations = [...citations];
        const id = event.target.id;
        updatedCitations = updatedCitations.filter(s => s.id !== id);
        handleSetCitations(updatedCitations);
    };

    const handleChangeCitation = (event, id) => {
        let updatedCitations = [...citations];
        let citation = updatedCitations.filter(s => s.id === id)[0];
        const field = event.target.name;
        citation[field] = event.target.value;
        handleSetCitations(updatedCitations);
    };

    const handleSetCitations = (updatedCitations) => {
        setCitations(createFakeEvent(updatedCitations, "citations"));
    };

    return (
        <DisplayField label={"Citations"} name="citations" showLabel>
            {
                !isArrayNullOrEmpty(citations) &&
                <>
                    <p>Only include the bold part for your citation text - State Rule <strong>6A-6.0331</strong>, F.A.C. or Federal 34 CFR §<strong>300.306</strong>.</p>
                    <GridTable>
                        <thead>
                        <tr className="text-center">
                            <th>Type</th>
                            <th>Citation</th>
                            <th>Violation</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            citations.map((citation, key) => {
                                const isNew = !citation.citationType;
                                const isInList = isInSelectList(CitationTypesList, citation.citationType);
                                const canEdit = isNew || isInList;

                                return <tr className="text-center" key={key}>
                                    <td>
                                        {
                                            canEdit &&
                                            <SelectField
                                                includeDefaultOption
                                                label="Citation Type"
                                                name="citationType"
                                                onChange={(event) => handleChangeCitation(event, citation.id)}
                                                options={CitationTypesList}
                                                value={citation.citationType}
                                            />
                                        }
                                        {
                                            !canEdit &&
                                            <ButtonMock label={`${citation.citationType} (archived)`}/>
                                        }
                                    </td>
                                    <td>
                                        <TextField
                                            label={"Citation Text"}
                                            name={"citationText"}
                                            showLabel={false}
                                            value={citation.citationText}
                                            onChange={(event) => handleChangeCitation(event, citation.id)}
                                        />
                                    </td>
                                    <td>
                                        <RadioField
                                            direction={inputStyles.radio.direction.HORIZONTAL}
                                            index={key}
                                            legend={"Is Violation"}
                                            showLegend={false}
                                            name={`isViolation`}
                                            onChange={(event) => handleChangeCitation(event, citation.id)}
                                            options={createYesNoList()}
                                            value={citation.isViolation}
                                        />

                                        <span className={ActionButton.position.RIGHT}>
										<Button name={`${citation.id}`}
                                                label={"Remove"}
                                                showLabel={false}
                                                onClick={(event) => handleClickRemoveCitation(event)}
                                                buttonType={ButtonTypes.REMOVE}/>
									</span>
                                    </td>
                                </tr>;
                            })
                        }
                        </tbody>
                    </GridTable>
                </>
            }

            <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                <Tile body={"Add Citation"}
                      onClick={handleClickAddCitation}
                      isNew={true}
                      isMedium={true}/>
            </TileContainer>
        </DisplayField>
    );
};

DrStateComplaintCitationEdit.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
};

export default DrStateComplaintCitationEdit;