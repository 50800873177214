import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {MatrixCorrectionsStateSummaryView} from "./MatrixCorrectionsStateSummaryView";
import {StudentCorrectionsStateSummaryView} from "./StudentCorrectionsStateSummaryView";

export const CorrectionsStateSummaryContainer = ({
                                                     actions,
                                                     dateWindowId,
                                                     districtId,
                                                     history,
                                                     institutions,
                                                     isMatrix = false,
                                                     matrixProtocolCorrectiveResults,
                                                     selectedAssessment,
                                                     studentProtocolCorrectiveResults
                                                 }) => {
    return isMatrix
        ? <MatrixCorrectionsStateSummaryView
            actions={actions}
            dateWindowId={dateWindowId}
            districtId={districtId}
            history={history}
            institutions={institutions}
            matrixProtocolCorrectiveResults={matrixProtocolCorrectiveResults}
        />
        : <StudentCorrectionsStateSummaryView
            actions={actions}
            dateWindowId={dateWindowId}
            districtId={districtId}
            history={history}
            institutions={institutions}
            selectedAssessment={selectedAssessment}
            studentProtocolCorrectiveResults={studentProtocolCorrectiveResults}
        />;
}

CorrectionsStateSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMatrix: PropTypes.bool,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAssessment: PropTypes.object.isRequired,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
    return {
        matrixProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.matrixProtocolCorrectiveResults,
        studentProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.studentProtocolCorrectiveResults
    };
}

export default connect(mapStateToProps)(CorrectionsStateSummaryContainer);