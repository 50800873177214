import React from "react";
import PropTypes from "prop-types";
import SearchableSingleSelectField from "../common/inputs/SearchableSingleSelectField";
import TextField from "../common/inputs/TextField";
import SelectField from "../common/inputs/SelectField";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonTypes from "../../constants/ButtonTypes";
import GridColumn from "../common/GridColumn";
import GridRow from "../common/GridRow";
import AccountUtility from "../../api/AccountUtility";
import {createYesNoList} from "../common/inputs/inputUtility";
import * as userConstants from "../../constants/users/userConstants";
import {gridConstants} from "../../constants/inputConstants";
import {
	createListFromObject,
} from "../common/commonUtilities";

const UserSearchForm = ({criteria, behaviors, onFormChange, clearCriteria, search, institutions, addUser, isLoading}) => {
	const isDistrictUser = AccountUtility.isDistrictUser();
	return (
		<form>
			<GridRow rowClass="filterBar" medium={gridConstants.column.TWO} large={gridConstants.column.FOUR}>
				<GridColumn>
					<TextField name="EmailContains"
					           label="Email or Name"
					           onChange={onFormChange}
					           showLabel
					           value={criteria.EmailContains}
					/>
				</GridColumn>
				<GridColumn>
					<TextField name="UsernameContains"
					           label="Username"
					           onChange={onFormChange}
					           showLabel
					           value={criteria.UsernameContains}
					/>
				</GridColumn>
				<GridColumn>
					<SearchableSingleSelectField
						disabled={isDistrictUser}
						includeDefaultOption
						label="District / Institution"
						name={`InstitutionId`}
						onChange={onFormChange}
						options={institutions}
						showLabel
						value={criteria.InstitutionId}
					/>
				</GridColumn>
				<GridColumn>
					<SearchableSingleSelectField
						includeDefaultOption
						label="Behavior"
						name="Behaviors"
						onChange={onFormChange}
						options={behaviors}
						showLabel
						value={criteria.Behaviors}
					/>
				</GridColumn>
				<GridColumn>
					<SelectField name="Status"
								 label="User Status"
								 onChange={onFormChange}
								 showLabel
								 options={userConstants.UserStatusList}
								 value={criteria.Status}
					/>
				</GridColumn>
				<GridColumn>
					<SelectField name="IsSchoolAdminOnly"
								 label="Is School Admin Only"
								 onChange={onFormChange}
								 showLabel
								 options={createYesNoList(false)}
								 value={criteria.IsSchoolAdminOnly}
								 includeDefaultOption={false}
					/>
				</GridColumn>
				{!isDistrictUser &&
					<GridColumn>
						<SelectField name="UserType"
									 label="User Type"
									 onChange={onFormChange}
									 showLabel
									 options={createListFromObject(userConstants.USER_TYPES)}
									 value={criteria.UserType}
									 includeDefaultOption={false}
						/>
					</GridColumn>
				}
				<ButtonBar position={""}>
					<Button
						buttonType={ButtonTypes.SEARCH}
						disabled={isLoading}
						isSubmitButton
						label="Search"
						name="btnSearchUsers"
						onClick={search}
					/>
					<Button
						buttonType={ButtonTypes.SYSTEM}
						disabled={isLoading}
						name="btnClearUserSearch"
						onClick={clearCriteria}
						label="Clear"
					/>
					<Button
						btnClass="l-float-right"
						buttonType={ButtonTypes.ADD}
						disabled={isLoading}
						label="Add user"
						name="btnNewUser"
						onClick={addUser}
					/>
				</ButtonBar>
			</GridRow>
		</form>
	);
};

UserSearchForm.propTypes = {
	criteria: PropTypes.object.isRequired,
	behaviors: PropTypes.arrayOf(PropTypes.object),
	institutions: PropTypes.arrayOf(PropTypes.object),
	onFormChange: PropTypes.func.isRequired,
	search: PropTypes.func.isRequired,
	clearCriteria: PropTypes.func.isRequired,
	addUser: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default UserSearchForm;