import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";
import ClickableDiv from "./buttons/ClickableDiv";
import {isString} from "./commonUtilities";
import {convertLineBreaksToBreakTags, stripHtmlTags} from "./HtmlUtilities";

export const ReadMore = ({
                             ariaLabelledBy,
                             characters = 60,
                             hideBorderWhenNotUseTruncate,
                             less = 'Show less',
                             more = 'Read more',
                             text = "",
                         }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [useTruncate, setUseTruncate] = useState(true);

    const handleToggleMoreClick = (event) => {
        event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const handleClick = useTruncate ? handleToggleMoreClick : null;
    const borderClassName = `htmlDisplay htmlDisplay--truncated`;
    const truncatedClass = isExpanded ? "" : "--isTruncated";
    const sanitizedText = isString(text) ? text.trim() : "";
    const previewText = stripHtmlTags(sanitizedText).substring(0, characters);
    const expandedText = convertLineBreaksToBreakTags(sanitizedText);
    const displayText = isExpanded ? expandedText : previewText;

    useEffect(() => {
        const newState = text.length > characters;
        if (useTruncate !== newState) {
            setUseTruncate(text.length > characters);
        }
    }, [characters, text]);

    return useTruncate
        ? <>
            <ClickableDiv onClick={handleClick} className={borderClassName} title={isExpanded ? less : more}>
                <HtmlDisplayInline ariaLabelledBy={ariaLabelledBy} html={displayText} className={truncatedClass}/>
            </ClickableDiv>
        </>
        : <>
            <div className={`${hideBorderWhenNotUseTruncate ? "" : borderClassName}`}>
                <HtmlDisplayInline ariaLabelledBy={ariaLabelledBy} html={expandedText}/>
            </div>
        </>;
};

ReadMore.propTypes = {
    ariaLabelledBy: PropTypes.string,
    characters: PropTypes.number,
    hideBorderWhenNotUseTruncate: PropTypes.bool,
    less: PropTypes.string,
    more: PropTypes.string,
    text: PropTypes.string.isRequired,
};