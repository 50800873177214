import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {convertToString, createListFromObject, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import {datesEqual} from "../../../components/common/dateUtilities";
import CheckBoxList from "../../../components/common/inputs/CheckBoxList";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import FormTable from "../../../components/common/FormTable";
import MaskInputField from "../../../components/common/inputs/MaskInputField";
import SelectField from "../../../components/common/inputs/SelectField";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import DrSchoolInformationEdit from "../../DrComponents/DrSchoolInformationEdit";
import {getTeamMemberId, updateTeamMembers, validateSchoolInformationRecords} from "../../drUtilities";
import * as drDueProcessConstants from '../drDueProcessConstants';
import {
	DrRoles,
	DrRolesAssociatedToDueProcess,
	RadioButtonYesNoList
} from "../../drConstants";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as DrDueProcessObjectFactory from "../drDueProcessObjectFactory";
import * as drLocations from "../../drLocations";
import {
	appendedOtherValueExists,
	clearInputFormErrorFields,
	convertFormInputObjectToObject, createMaskFormat, inputFormIsValid,
	useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {getDateNdaysFromGivenDate} from "../../../components/shared/sharedDataUtilities";
import SectionHeader from "../../../components/common/SectionHeader";
import DisplayField from "../../../components/common/inputs/DisplayField";

export const DrDueProcessOverviewView = ({
	                                         dueProcess,
	                                         handleClickDelete,
	                                         handleDataChanged,
											 handleSave,
											 handleSelectSection,
	                                         judges,
	                                         readOnly,
											 teamMembers
                                         }) => {
	const [dueProcessLiaisons, setDueProcessLiaisons] = useState(createListFromObject([]));
	const caseNumberMask = "00-000000EDM";
	const caseNumberMaskFormat = [
		createMaskFormat("0", /\d/),
		createMaskFormat("E", /[E]/),
		createMaskFormat("D", /[D]/),
		createMaskFormat("M", /[M]/)
	];
	const dueProcessForm = useFormInputObject(DrDueProcessObjectFactory.createDueProcessObject(dueProcess), () => handleDataChanged());

	const handleClickCancel = (event) => {
		handleSelectSection(event, drDueProcessConstants.DueProcessSections.Overview, true);
	};

	const validateForm = () => {
		clearInputFormErrorFields(dueProcessForm);

		const allSchoolInformationValid = validateSchoolInformationRecords(dueProcessForm);
		if(!allSchoolInformationValid)
			dueProcessForm.schoolInformationRecords.setError("All associated school data is required.");

		if(isTrimmedStringEmpty(dueProcessForm.parentRepresentationStatusOtherAtFiling.value) && convertToString(dueProcessForm.parentRepresentationStatusAtFiling.value) === drDueProcessConstants.ParentRepresentationStatus.Other.id.toString())
			dueProcessForm.parentRepresentationStatusOtherAtFiling.setError("Parent Representation Status Other Value is required.");

		const otherString = drDueProcessConstants.FinalOrderIssuesWithTextField.toString();
		if (!appendedOtherValueExists(otherString, dueProcessForm.finalOrderIssuesAtFiling.value))
			dueProcessForm.finalOrderIssuesAtFiling.setError(`More details are required when ${otherString} is selected for Issues.`)

		if (isTrimmedStringEmpty(dueProcessForm.complaintType.value))
			dueProcessForm.complaintType.setError("Required.")

		if (isTrimmedStringEmpty(dueProcessForm.dateReceived.value))
			dueProcessForm.dateReceived.setError("Required.")

		const caseNumberMaskLength = caseNumberMask.length;
		const caseNumber = dueProcessForm.caseNumber.value.replace("_", "");
		if (isTrimmedStringEmpty(caseNumber) || (caseNumber.length !== (caseNumberMaskLength - 2) && caseNumber.length !== caseNumberMaskLength))
			dueProcessForm.caseNumber.setError("Required.")

		if (isTrimmedStringEmpty(dueProcessForm.DueProcessLiaison.value))
			dueProcessForm.DueProcessLiaison.setError("Required.")

		const dueProcessStatus = convertToString(dueProcessForm.dueProcessStatus.value);
		const resolutionSessionOutcome = convertToString(dueProcessForm.resolutionSessionOutcome.value);
		if (isTrimmedStringEmpty(resolutionSessionOutcome) && dueProcessStatus !== drDueProcessConstants.DueProcessStatus.Pending.id.toString())
			dueProcessForm.resolutionSessionOutcome.setError("Required.")

		const dueProcessTimeline = convertToString(dueProcessForm.dueProcessTimeline.value);
		if (isTrimmedStringEmpty(dueProcessTimeline) && dueProcessStatus === drDueProcessConstants.DueProcessStatus.HearingFullyAdjudicated.id.toString())
			dueProcessForm.dueProcessTimeline.setError("Required.")

		const writtenSettlementAgreement = convertToString(dueProcessForm.writtenSettlementAgreement.value);
		if (isTrimmedStringEmpty(writtenSettlementAgreement) && resolutionSessionOutcome === drDueProcessConstants.ResolutionSessionOutcome.ResolutionSessionHeld.id.toString())
			dueProcessForm.writtenSettlementAgreement.setError("Required.")

		if (isTrimmedStringEmpty(dueProcessForm.caseManagementOrderDate.value))
			dueProcessForm.caseManagementOrderDate.setError("Required.")

		if (isTrimmedStringEmpty(dueProcessForm.resolutionMeetingDeadline.value))
			dueProcessForm.resolutionMeetingDeadline.setError("Required.")

		let isValid = inputFormIsValid(dueProcessForm);
		if (!isValid)
			NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

		return isValid;
	};

	const saveOverview = (event) => {
		event.preventDefault();
		const isValid = validateForm();
		if (isValid) {
			let result = convertFormInputObjectToObject(dueProcessForm);

			let currentTeamMembers = [...dueProcess.teamMembers];
			result.teamMembers = updateTeamMembers(currentTeamMembers, DrRolesAssociatedToDueProcess, dueProcessForm);

			handleSave(result);
		}
	};

	const updateCaseMgmtDates = (event) => {
		const dateReceived = event.target.value;
		const oldDateReceived = dueProcessForm.dateReceived.value;
		dueProcessForm.dateReceived.setValue(dateReceived);

		const shouldUpdateDates = validateShouldUpdateCaseMgmtDates(oldDateReceived, dateReceived);

		if(!shouldUpdateDates) return;

		for(let caseMgmtDateToUpdate in drDueProcessConstants.CaseManagementDatesDaysAhead)
			setCaseMgmtDate(caseMgmtDateToUpdate, dateReceived);
	}

	const validateShouldUpdateCaseMgmtDates = (oldDateReceived, dateReceived) => {
		if(dueProcessForm.complaintType.value && dueProcessForm.complaintType.value.toString() === drDueProcessConstants.DueProcessComplaintTypes.Expedited
			|| datesEqual(oldDateReceived, dateReceived))
			return false;

		let anyDatesHaveValue = false;
		for(let caseMgmtDateToUpdate in drDueProcessConstants.CaseManagementDatesDaysAhead) {
			if(!isTrimmedStringEmpty(dueProcessForm[caseMgmtDateToUpdate].value))
				anyDatesHaveValue = true;
		}

		return anyDatesHaveValue
			? confirm("One or more dates have been set in the case management information section.  Do you want to update them with the new date received?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")
			: true;
	}

	const setCaseMgmtDate = (caseMgmtDateToUpdate, dateReceived) => {
		const daysAhead = drDueProcessConstants.CaseManagementDatesDaysAhead[caseMgmtDateToUpdate];
		const caseMgmtDate = isTrimmedStringEmpty(dateReceived) ? "" : getDateNdaysFromGivenDate(dateReceived, daysAhead);

		dueProcessForm[caseMgmtDateToUpdate].setValue(caseMgmtDate);
	}

	useEffect(() => {
		setDueProcessLiaisons(teamMembers.filter(a => (!a.isDisabled && a.role === DrRoles.DueProcessLiaison.id) || a.id === getTeamMemberId(dueProcess.teamMembers, DrRoles.DueProcessLiaison.id)));
	}, [teamMembers, dueProcess]);

	return (
		<>
			{ dueProcess.dueProcessLiaisonInfo &&
			  dueProcess.dueProcessLiaisonInfo.email &&
				<>
					<p>For questions or comments regarding this Due Process Hearing Request, please contact:</p>
					<p>{`${dueProcess.dueProcessLiaisonInfo.fullName}`} at <a href={`mailto:${dueProcess.dueProcessLiaisonInfo.email}`}>{`${dueProcess.dueProcessLiaisonInfo.email}`}</a>.</p>
				</>
			}
			<SectionHeader>
				<h2>Section I. General Information</h2>
			</SectionHeader>

			<FormTable>
				<SelectField
					name={`complaintType`}
					label={"Complaint Type:"}
					medium={6}
					options={drDueProcessConstants.DueProcessComplaintTypesList}
					{...dueProcessForm.complaintType}
					showLabel
					disabled={readOnly}
				/>

				<MaskInputField
					columnsMedium={6}
					name={"caseNumber"}
					label={"DOAH Case #<br/>(last 3 characters can be E or EDM)"}
					mask={caseNumberMask}
					maskFormat={caseNumberMaskFormat}
					{...dueProcessForm.caseNumber}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="dateReceived"
					label="Date Received:"
					showLabel
					{...dueProcessForm.dateReceived}
					onChange={updateCaseMgmtDates}
					medium={6}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="dateFiled"
					label="Date Filed with DOAH:"
					showLabel
					{...dueProcessForm.dateFiled}
					medium={6}
					disabled={readOnly}
				/>

				<SelectField
					name={`alj`}
					label={"Assigned Administrative Law Judge:"}
					medium={6}
					options={judges}
					{...dueProcessForm.alj}
					showLabel
					disabled={readOnly}
				/>

				<SelectField
					showLabel
					medium={6}
					label={"Status:"}
					name={"dueProcessStatus"}
					options={drDueProcessConstants.DueProcessStatusList}
					{...dueProcessForm.dueProcessStatus}
					includeDefaultOption={false}
					disabled={readOnly}
				/>

				{
					dueProcessForm.dueProcessStatus.value.toString() === drDueProcessConstants.DueProcessStatus.HearingFullyAdjudicated.id.toString() &&
					<>
						<SelectField
							name={`dueProcessTimeline`}
							label={`Timeline:`}
							medium={6}
							showLabel
							options={drDueProcessConstants.TimelineList}
							{...dueProcessForm.dueProcessTimeline}
							disabled={readOnly}
						/>
						{
							dueProcessForm.complaintType.value === drDueProcessConstants.DueProcessComplaintTypes.Expedited &&
							(dueProcessForm.dueProcessTimeline.value.toString() === drDueProcessConstants.Timeline.WithinTimeline.id.toString() ||
							dueProcessForm.dueProcessTimeline.value.toString() === drDueProcessConstants.Timeline.WithinExtendedTimeline.id.toString()) &&
							<SelectField
								showLabel
								medium={6}
								label={"Change of Placement Ordered:"}
								name={"ChangeOfPlacement"}
								options={RadioButtonYesNoList}
								{...dueProcessForm.changeOfPlacement}
								disabled={readOnly}
							/>
						}
					</>
				}
				{
					dueProcessForm.dueProcessStatus.value.toString() !== drDueProcessConstants.DueProcessStatus.Pending.id.toString() &&
					<DateTimePickerField
						name="dateClosed"
						label="Closure Date:"
						showLabel
						{...dueProcessForm.dateClosed}
						medium={6}
						disabled={readOnly}
					/>
				}

				<SelectField
					name="DueProcessLiaison"
					label="Assigned Due Process Liaison"
					showLabel
					options={dueProcessLiaisons}
					{...dueProcessForm.DueProcessLiaison}
					medium={6}
					disabled={readOnly}
				/>
				{ dueProcess.dueProcessLiaisonInfo &&
				  dueProcess.dueProcessLiaisonInfo.email &&
					<DisplayField name={`liaisonEmail`} label={`Liaison's Email: `} columnsMedium={6} showLabel>
						<a href={`mailto:${dueProcess.dueProcessLiaisonInfo.email}`}>{`${dueProcess.dueProcessLiaisonInfo.email}`}</a>
					</DisplayField>
				}
			</FormTable>

			<SectionHeader>
				<h2>Section II. Case Management Information</h2>
			</SectionHeader>

			<FormTable>

				<DateTimePickerField
					name="caseManagementOrderDate"
					label="Case Management Order Date:"
					showLabel
					{...dueProcessForm.caseManagementOrderDate}
					medium={6}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="partyResponseDate"
					label="Anticipated Party Response Date:"
					showLabel
					{...dueProcessForm.partyResponseDate}
					medium={6}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="resolutionMeetingDeadline"
					label="Anticipated Resolution Meeting Deadline:"
					showLabel
					{...dueProcessForm.resolutionMeetingDeadline}
					medium={6}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="resolutionProcessDeadline"
					label="Anticipated Resolution Period Deadline:"
					showLabel
					{...dueProcessForm.resolutionProcessDeadline}
					medium={6}
					disabled={readOnly}
				/>

				<DateTimePickerField
					name="hearingDecisionDeadline"
					label="Anticipated Hearing Decision Deadline:"
					showLabel
					{...dueProcessForm.hearingDecisionDeadline}
					medium={6}
					disabled={readOnly}
				/>
			</FormTable>

			<SectionHeader>
				<h2>Section III. Demographics</h2>
			</SectionHeader>
			<FormTable>
				<SelectField
					showLabel
					medium={6}
					label={"Parent Representation Status:"}
					name={"parentRepresentationStatusAtFiling"}
					options={drDueProcessConstants.ParentRepresentationStatusList}
					{...dueProcessForm.parentRepresentationStatusAtFiling}
					disabled={readOnly}
				/>
				{
					convertToString(dueProcessForm.parentRepresentationStatusAtFiling.value) === drDueProcessConstants.ParentRepresentationStatus.Other.id.toString() &&
					<TextField
						name="parentRepresentationStatusOtherAtFiling"
						label="Parent Representation Status Other Value:"
						showLabel
						{...dueProcessForm.parentRepresentationStatusOtherAtFiling}
						columnsMedium={6}
						disabled={readOnly}
					/>
				}
				<DrSchoolInformationEdit
					{...dueProcessForm.schoolInformationRecords}
					readOnly={readOnly}
				/>
			</FormTable>

			<SectionHeader>
				<h2>Section IV. Issues</h2>
			</SectionHeader>

			<FormTable>
				<CheckBoxList
					disabled={readOnly}
					legend={"Issues:"}
					listHasTextField={drDueProcessConstants.FinalOrderIssuesWithTextField}
					name={"finalOrderIssuesAtFiling"}
					options={drDueProcessConstants.FinalOrderIssues}
					showLabel
					{...dueProcessForm.finalOrderIssuesAtFiling}
				/>
			</FormTable>

			<SectionHeader>
				<h2>Section V. Resolution Session</h2>
			</SectionHeader>

			<FormTable>

				<SelectField
					showLabel
					medium={6}
					label={"Resolution Session Outcome:"}
					name={"resolutionSessionOutcome"}
					options={drDueProcessConstants.ResolutionSessionOutcomeList}
					{...dueProcessForm.resolutionSessionOutcome}
					disabled={readOnly}
				/>

				{
					dueProcessForm.resolutionSessionOutcome.value.toString() === drDueProcessConstants.ResolutionSessionOutcome.Other.id.toString() &&
					<TextField
						name="resolutionSessionOutcomeOther"
						label="Not Held, Other:"
						showLabel
						{...dueProcessForm.resolutionSessionOutcomeOther}
						columnsMedium={6}
						disabled={readOnly}
					/>
				}

				{
					dueProcessForm.resolutionSessionOutcome.value.toString() === drDueProcessConstants.ResolutionSessionOutcome.ResolutionSessionHeld.id.toString() &&
					<SelectField
						showLabel
						medium={6}
						label={"Written Settlement Status:"}
						name={"writtenSettlementAgreement"}
						options={drDueProcessConstants.WrittenSettlementStatusList}
						{...dueProcessForm.writtenSettlementAgreement}
						disabled={readOnly}
					/>
				}

			</FormTable>

			{
				!readOnly &&
				<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
					<Button
						name="btnSave"
						label="Save"
						buttonType={ButtonTypes.SAVE}
						onClick={saveOverview}
					/>

					{
						dueProcess.id &&
						dueProcess.id !== drLocations.DUE_PROCESS_ID_NEW &&
						<Button
							name="btnDelete"
							label="Delete"
							buttonType={ButtonTypes.DELETE}
							onClick={handleClickDelete}
						/>
					}

					<Button name="btnCancel"
							label="Cancel and Reload"
							buttonType={ButtonTypes.CANCEL}
							onClick={handleClickCancel}/>

				</ButtonBar>
			}
		</>
	);
};

DrDueProcessOverviewView.propTypes = {
	dueProcess: PropTypes.object.isRequired,
	handleClickDelete: PropTypes.func,
	handleDataChanged: PropTypes.func.isRequired,
	handleSave: PropTypes.func.isRequired,
	handleSelectSection: PropTypes.func.isRequired,
	judges: PropTypes.array.isRequired,
	readOnly: PropTypes.bool.isRequired,
	teamMembers: PropTypes.arrayOf(PropTypes.object)
};

export default DrDueProcessOverviewView;