import React, {useState} from "react";
import {
    clearInputFormErrorFields, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {createDiscretionaryProjectObject} from "../ptsObjectFactory";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {DateTimePickerField, DisplayField} from "../../../components/common/inputs";
import PropTypes from "prop-types";
import GridColumn from "../../../components/common/GridColumn";
import {gridConstants} from "../../../constants/inputConstants";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import GridRow from "../../../components/common/GridRow";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {dateCompare} from "../../../components/common/dateUtilities";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {PtsPolicy} from "../../../components/authorization/policies/PtsPolicy";
import * as policyEvents from "../../../constants/policyEvents";

export const PtsManageEndDateContainer = ({
                                              handleDataChanged,
                                              isLoading,
                                              isStateUser,
                                              project,
                                              updateProjectEndDate
                                          }) => {
    const [isEditing, setIsEditing] = useState(false);
    const canEdit = isStateUser && AuthorizationUtilities.allow(PtsPolicy, policyEvents.MANAGE) && !project.isCompleted;
    const projectForm = useFormInputObject(createDiscretionaryProjectObject(project), () => handleDataChanged());
    const handleEditEndDate = (event) => {
        event.preventDefault();
        setIsEditing(true);
    };

    const isValid = () => {
        clearInputFormErrorFields(projectForm);

        if (projectForm.projectEndDate.value && dateCompare(project.projectStartDate, projectForm.projectEndDate.value) > 0)
            projectForm.projectEndDate.setError("End date must be after start date.");

        let isValid = inputFormIsValid(projectForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleSaveEndDate = (event) => {
        event.preventDefault();
        if (isValid()) {
            updateProjectEndDate(projectForm.projectEndDate.value);
            handleDataChanged(false);
            setIsEditing(false);
        }
    };

    const handleCancelEndDate = (event) => {
        event.preventDefault();

        projectForm.projectEndDate.setValue(project.projectEndDate);
        handleDataChanged(false);
        setIsEditing(false);
    };

    if (!isStateUser || !canEdit)
        return <DisplayField name={`datesCovered`} label={`Project Dates`}
                             showLabel>{`${project.projectStartDateOutput} - ${project.projectEndDateOutput}`}</DisplayField>;

    return <GridRow rowClass={`project__dates`}>
        {
            !isEditing &&
            <>
                <GridColumn small={gridConstants.column.TEN}>
                    <DisplayField name={`datesCovered`} label={`Project Dates`}
                                  showLabel>{`${project.projectStartDateOutput} - ${outputStandardDateFormat(projectForm.projectEndDate.value, false)}`}</DisplayField>
                </GridColumn>
                <GridColumn small={gridConstants.column.TWO}>
                    <GridRow align={`right`}>
                        <Button name={`btnEditEditEndDate`} label={`Edit`} showLabel={false}
                                buttonType={ButtonTypes.EDIT}
                                onClick={handleEditEndDate} disabled={isLoading}/>
                    </GridRow>
                </GridColumn>
            </>
        }
        {
            isEditing &&
            <>
                <GridColumn small={gridConstants.column.FIVE}>
                    <DisplayField name={`startDate`} label={`Start Date`}
                                  showLabel>{`${project.projectStartDateOutput}`}</DisplayField>
                </GridColumn>
                <GridColumn small={gridConstants.column.FIVE}>
                    <DateTimePickerField {...projectForm.projectEndDate} disabled={isLoading} label={"End Date"}
                                         showLabel/>
                </GridColumn>
                <GridColumn small={gridConstants.column.TWO}>
                    <GridRow align={`right`}>
                        <Button name={`btnSaveEditEndDate`} label={`Save`} showLabel={false}
                                buttonType={ButtonTypes.SAVE}
                                onClick={handleSaveEndDate} disabled={isLoading}/>

                        <Button name={`btnCancelEditEndDate`} label={`Cancel`} showLabel={false}
                                buttonType={ButtonTypes.CANCEL}
                                onClick={handleCancelEndDate} disabled={isLoading}/>
                    </GridRow>
                </GridColumn>
            </>
        }
    </GridRow>;
};

PtsManageEndDateContainer.propTypes = {
    handleDataChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool.isRequired,
    project: PropTypes.object,
    updateProjectEndDate: PropTypes.func,
};