import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {
    ComplainantRelationshipToStudent,
    ComplainantRelationshipToStudentList,
    RadioButtonChoicesOfExtendRelatedToDueProcess,
    ReasonForAssistance
} from "../drStateComplaintConstants";
import TextField from "../../../components/common/inputs/TextField";
import FormTable from "../../../components/common/FormTable";
import CheckBoxList from "../../../components/common/inputs/CheckBoxList";
import {RadioButtonYesNoList, RadioButtonYesNoObject, RadioButtonYesNoUnknownList} from "../../drConstants";
import {inputStyles} from "../../../constants/inputConstants";
import RadioField from "../../../components/common/inputs/RadioField";
import SelectField from "../../../components/common/inputs/SelectField";
import drApi from "../../drApi";
import {createDueProcessCaseNumberList} from "../../drObjectFactory";
import {commonStyles} from "../../../constants/commonStyleConstants";
import SectionHeader from "../../../components/common/SectionHeader";

export const DrStateComplaintIntakeOtherInfoView = ({
                                                        complaint,
                                                        intakeForm,
                                                        readOnly
                                                    }) => {
    const [dueProcessCaseNumbers, setDueProcessCaseNumbers] = useState([]);

    useEffect(() => {
        drApi.GetDueProcessCaseNumbers(complaint.dateWindowId, complaint.districtId)
            .then((result) => setDueProcessCaseNumbers(createDueProcessCaseNumberList(result)));
    }, [complaint.dateWindowId, complaint.districtId]);

    const getFormElementMissingClassIfNeeded = (formElement) => {
        return complaint.isIntakeRequirementCompleted
            && (!intakeForm[formElement].value || intakeForm[formElement].value.length === 0)
            ? commonStyles.highlight.MISSING
            : "";
    };

    return (
        <>
            <SectionHeader>
                <h2>Section II. Other Additional Information</h2>
            </SectionHeader>
            
                <p>Directions - Review the state complaint and supporting documentation to determine the following:</p>
                <ul>
                    <li>The complainant’s relationship to the student;</li>
                    <li>The student’s age, specifically, whether the student has reached the age of majority (18 years old);</li>
                    <li>Whether the complaint is also the subject of a due process hearing request;</li>
                    <li>Whether an issue raised in the complaint has previously been decided in a due process hearing request involving the same parties;</li>
                    <li>Whether the complainant requires assistance due to disability, limited English proficiency or otherwise limited communication skills;</li>
                    <li>Whether the complainant agrees to receive electronic correspondence; and</li>
                    <li>Whether the complainant is interested in resolving the complaint through mediation or other alternative means of dispute resolution.</li>
                </ul>
                <FormTable>
                    <CheckBoxList
                        disabled={readOnly}
                        legend={"Please indicate the complainant’s relationship to the student:"}
                        options={ComplainantRelationshipToStudentList}
                        {...intakeForm.complainantRelationshipToStudent}
                        wrapperClass={getFormElementMissingClassIfNeeded(`complainantRelationshipToStudent`)}
                    />

                    {
                        intakeForm.complainantRelationshipToStudent.value.includes(ComplainantRelationshipToStudent.Other) &&
                        <TextField
                            label="Please specify the other value:"
                            showLabel
                            {...intakeForm.otherRelationshipToStudent}
                            columnsMedium={6}
                            disabled={readOnly}
                        />
                    }


                    <RadioField
                        legend={"Please indicate whether the student has reached the age of majority."}
                        options={RadioButtonYesNoList}
                        {...intakeForm.reachedAgeOfMajority}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`reachedAgeOfMajority`)}
                    />

                    <RadioField
                        legend={"Please indicate whether the complaint is also the subject of a due process hearing request."}
                        options={RadioButtonYesNoList}
                        {...intakeForm.isComplaintSubjectOfDueProcess}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`isComplaintSubjectOfDueProcess`)}
                    />

                    {
                        intakeForm.isComplaintSubjectOfDueProcess.value === RadioButtonYesNoObject.Yes &&
                        <>
                            <RadioField
                                legend={"Issue classification:"}
                                options={RadioButtonChoicesOfExtendRelatedToDueProcess}
                                {...intakeForm.extendRelatedToDueProcess}
                                disabled={readOnly}
                                direction={inputStyles.radio.direction.HORIZONTAL}
                            />

                            <SelectField
                                disabled={readOnly}
                                label={"Related Due Process Hearing Request Case No:"}
                                inputClass={"large-6"}
                                options={dueProcessCaseNumbers}
                                showLabel
                                {...intakeForm.relatedDueProcessId}
                            />
                        </>
                    }

                    <RadioField
                        legend={"Please indicate whether an issue raised in the complaint has previously been decided in a due process hearing request involving the same parties."}
                        options={RadioButtonYesNoList}
                        {...intakeForm.issuesInvolvedTheSamePartiesAndDecided}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`issuesInvolvedTheSamePartiesAndDecided`)}
                    />

                    {
                        intakeForm.issuesInvolvedTheSamePartiesAndDecided.value === RadioButtonYesNoObject.Yes &&
                        <>
                            <RadioField
                                legend={"Issue classification:"}
                                options={RadioButtonChoicesOfExtendRelatedToDueProcess}
                                {...intakeForm.extendIssuesInvolved}
                                disabled={readOnly}
                                direction={inputStyles.radio.direction.HORIZONTAL}
                            />

                            <SelectField
                                disabled={readOnly}
                                label={"Previously Decided Due Process Hearing Request Case No:"}
                                inputClass={"large-6"}
                                options={dueProcessCaseNumbers}
                                showLabel
                                {...intakeForm.involvedDueProcessId}
                            />
                        </>
                    }

                    <RadioField
                        legend={"Please indicate whether the complainant requires assistance due to disability, limited English proficiency or otherwise limited communication skills."}
                        options={RadioButtonYesNoList}
                        {...intakeForm.complainantRequireAssistance}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`complainantRequireAssistance`)}

                    />
                    {
                        intakeForm.complainantRequireAssistance.value === RadioButtonYesNoObject.Yes &&
                        <CheckBoxList
                            disabled={readOnly}
                            legend={"Which assistance is required?"}
                            options={ReasonForAssistance}
                            {...intakeForm.reasonForAssistance}
                        />
                    }

                    <RadioField
                        legend={"Please indicate whether the complainant agrees to receive electronic correspondence."}
                        options={RadioButtonYesNoList}
                        {...intakeForm.receiveCorrespondenceElectronically}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`receiveCorrespondenceElectronically`)}
                    />

                    <RadioField
                        legend={"Please indicate whether the complainant is interested in resolve the state complaint through state-sponsored mediation."}
                        options={RadioButtonYesNoUnknownList}
                        {...intakeForm.resolveComplaintThroughMediation}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`resolveComplaintThroughMediation`)}
                    />

                    <RadioField
                        legend={"Please indicate whether the complainant is interested in resolve the state complaint through state-sponsored facilitation."}
                        options={RadioButtonYesNoUnknownList}
                        {...intakeForm.resolveComplaintThroughFacilitation}
                        disabled={readOnly}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        wrapperClass={getFormElementMissingClassIfNeeded(`resolveComplaintThroughFacilitation`)}
                    />
                </FormTable>
        </>
    );

};

DrStateComplaintIntakeOtherInfoView.propTypes = {
    complaint: PropTypes.object.isRequired,
    intakeForm: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired
};