import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../api/requests/RequestTypes";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import {BpieApiMessages} from "../../bpieConstants";
import {API_ROOT} from "../../bpieLocations";
import {mockResolveApiCall} from "../../../../api/ApiUtility";

class ServerFinFacilitatorAdminApi {
    static SaveFinFaclitators(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/finUsers/`,
                RequestTypes.PUT,
                JSON.stringify(model),
                BpieApiMessages.facilitators.SAVED));
    }

    static getFinUsers(){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/finUsers`,
                RequestTypes.GET));
    }
}

class MockFinFacilitatorAdminApi {

    static ServerFinFacilitatorAdminApi(){
        return mockResolveApiCall({}, BpieApiMessages.facilitators.SAVED);
    }

    static getFinUsers(){
        return mockResolveApiCall({});
    }
}

const FinFacilitatorAdminApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerFinFacilitatorAdminApi: MockFinFacilitatorAdminApi;
export default FinFacilitatorAdminApi;