import React from "react";
import PropTypes from 'prop-types';
import {convertToBoolean, isArrayNullOrEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {CheckBoxList, DateTimePickerField, RadioField, SelectField} from "../../../components/common/inputs";
import {clearInputFormErrorFields, inputFormIsValid} from "../../../components/common/inputs/inputUtility";
import NumberField from "../../../components/common/inputs/NumberField";
import TextArea from "../../../components/common/inputs/TextArea";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {gridConstants, inputStyles} from "../../../constants/inputConstants";
import {PRODUCT_DISSEMINATION_METHODS_LIST, PTS_TIME_AND_EFFORTS_LIST, RadioButtonYesNoList} from "../ptsConstants";
import {PtsDeliverableSummaryAudienceEdit} from "./PtsDeliverableSummaryAudienceEdit";
import {PtsDeliverableSummaryButtons} from "./PtsDeliverableSummaryButtons";
import {DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG} from "./ptsDeliverableSummaryConstants";

export const PtsDeliverableSummaryProductEdit = ({
                                                     disableButtons,
                                                     disableFields,
                                                     disableExtraAudienceDetails,
                                                     disableExtraOptionalAudienceDetails,
                                                     districtsList,
                                                     handleClickCancelEdit,
                                                     handleClickDelete,
                                                     handleClickSave,
                                                     handleDataChanged,
                                                     hasDetails,
                                                     implementationForm,
                                                     implementationStatusDetailsLabel,
                                                     isAdding
                                                 }) => {
    const validateForm = () => {
        clearInputFormErrorFields(implementationForm);

        if (isTrimmedStringEmpty(implementationForm.implementationName.value))
            implementationForm.implementationName.setError("Required.");

        if (!hasDetails) {
            if (isTrimmedStringEmpty(implementationForm.implementationDate.value))
                implementationForm.implementationDate.setError("Required.");
        } else {
            if (isTrimmedStringEmpty(implementationForm.typeOfProduct.value))
                implementationForm.typeOfProduct.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.productDisseminatedDate.value))
                implementationForm.productDisseminatedDate.setError("Required.");

            if (isTrimmedStringEmpty(implementationForm.timeAndEffort.value))
                implementationForm.timeAndEffort.setError("Required.");

            if (isTrimmedStringEmpty(String(implementationForm.numberOfProducts.value)))
                implementationForm.numberOfProducts.setError("Required.");

            if (isTrimmedStringEmpty(String(implementationForm.wasProductDisseminated.value)))
                implementationForm.wasProductDisseminated.setError("Required.");

            if (productDisseminationRequired() && isArrayNullOrEmpty(implementationForm.howProductWasDisseminated.value))
                implementationForm.howProductWasDisseminated.setError("Required.");

            if (numDisseminatedRequired()){
                if(isTrimmedStringEmpty(String(implementationForm.numberDisseminated.value)))
                    implementationForm.numberDisseminated.setError("Required.");
                else if (implementationForm.numberDisseminated.value < 0)
                    implementationForm.numberDisseminated.setError("Must be positive.");
            }

            if (isTrimmedStringEmpty(String(implementationForm.correctiveAction.value)))
                implementationForm.correctiveAction.setError("Required.");

            if (isArrayNullOrEmpty(implementationForm.audienceTypes.value))
                implementationForm.audienceTypes.setError("Required.");

            const isIncludeAllDistrictsChecked = convertToBoolean(implementationForm.includeAllDistricts.value);
            if (!disableExtraAudienceDetails && !isIncludeAllDistrictsChecked && isArrayNullOrEmpty(implementationForm.districts.value))
                implementationForm.districts.setError("Required.");

            if (!disableExtraAudienceDetails && isArrayNullOrEmpty(implementationForm.schoolTypes.value))
                implementationForm.schoolTypes.setError("Required.");
        }

        let isValid = inputFormIsValid(implementationForm, DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleWasProductDisseminatedChange = (event) => {
        event.preventDefault();
        implementationForm.wasProductDisseminated.setValue(event.target.value);
        if (!productDisseminationRequired()) {
            implementationForm.howProductWasDisseminated.setValue("");
            resetNumberDismissed();
        }
    };

    const handleHowProductWasDisseminatedChange = (event) => {
        event.preventDefault();
        implementationForm.howProductWasDisseminated.setValue(event.target.value);
        resetNumberDismissed();
    };

    const resetNumberDismissed = () => {
        if (!numDisseminatedRequired())
            implementationForm.numberDisseminated.setValue(null);
    };

    const productDisseminationRequired = () => {
        return String(implementationForm.wasProductDisseminated.value).toLowerCase() === "true";
    }

    const enableProductDissemination = productDisseminationRequired();

    const numDisseminatedRequired = () => {
        return implementationForm.howProductWasDisseminated.value?.includes("Hard Copy");
    }

    const enableNumDisseminated = numDisseminatedRequired();

    return <>
        <TextField label={`Name of Product`} showLabel disabled={disableFields}
                   {...implementationForm.implementationName} />

        <TextArea label={implementationStatusDetailsLabel} showLabel disabled={disableFields}
                  {...implementationForm.implementationDetails} />

        {
            hasDetails &&
            <>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField
                            {...implementationForm.typeOfProduct}
                            label={`Type of Product`}
                            showLabel
                            disabled={disableFields}
                        />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            {...implementationForm.numberOfProducts}
                            decimalPlaces={0}
                            disabled={disableFields}
                            inputFieldClass={`text-left`}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number of Products`}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <DateTimePickerField
                            {...implementationForm.productDisseminatedDate}
                            disabled={disableFields}
                            label="Product Date"
                            showLabel
                        />
                    </GridColumn>
                    <GridColumn>
                        <RadioField
                            {...implementationForm.wasProductDisseminated}
                            direction={inputStyles.radio.direction.HORIZONTAL}
                            disabled={disableFields}
                            legend={"Was the Product Disseminated?"}
                            options={RadioButtonYesNoList}
                            onChange={handleWasProductDisseminatedChange}
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <SelectField
                            label={`Time and Effort`}
                            options={PTS_TIME_AND_EFFORTS_LIST}
                            showLabel
                            disabled={disableFields}
                            {...implementationForm.timeAndEffort}
                        />
                    </GridColumn>
                    <GridColumn>
                        <CheckBoxList
                            {...implementationForm.howProductWasDisseminated}
                            legend={`How was Product Disseminated`} showLegend
                            disabled={disableFields || !enableProductDissemination}
                            options={PRODUCT_DISSEMINATION_METHODS_LIST}
                            onChange={handleHowProductWasDisseminatedChange}
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <RadioField
                            {...implementationForm.correctiveAction}
                            direction={inputStyles.radio.direction.HORIZONTAL}
                            disabled={disableFields}
                            legend={"Corrective Actions"}
                            options={RadioButtonYesNoList}
                        />
                    </GridColumn>
                    <GridColumn>
                        <NumberField
                            {...implementationForm.numberDisseminated}
                            decimalPlaces={0}
                            disabled={disableFields || !enableNumDisseminated}
                            inputFieldClass={`text-left`}
                            isInline={false}
                            isPositiveNumberOnly
                            isWholeNumberOnly
                            label={`Number Disseminated`}
                            showLabel
                        />
                    </GridColumn>
                </GridRow>
                <GridRow medium={gridConstants.column.TWO} rowClass={`twoColumnRow`}>
                    <GridColumn>
                        <TextField {...implementationForm.collaborators} label={`Collaborators (optional)`} showLabel
                                   disabled={disableFields}/>
                    </GridColumn>
                </GridRow>

                <PtsDeliverableSummaryAudienceEdit
                    disableExtraAudienceDetails={disableExtraAudienceDetails}
                    disableExtraOptionalAudienceDetails={disableExtraOptionalAudienceDetails}
                    disableFields={disableFields}
                    districtsList={districtsList}
                    handleDataChanged={handleDataChanged}
                    implementationForm={implementationForm}
                />
            </>
        }

        <PtsDeliverableSummaryButtons
            disableButtons={disableButtons}
            disableFields={disableFields}
            handleClickCancelEdit={handleClickCancelEdit}
            handleClickDelete={handleClickDelete}
            handleClickSave={handleClickSave}
            isAdding={isAdding}
            validateForm={validateForm}
        />
    </>;
};

PtsDeliverableSummaryProductEdit.propTypes = {
    disableButtons: PropTypes.bool.isRequired,
    disableFields: PropTypes.bool.isRequired,
    disableExtraAudienceDetails: PropTypes.bool.isRequired,
    disableExtraOptionalAudienceDetails: PropTypes.bool.isRequired,
    districtsList: PropTypes.array.isRequired,
    handleClickCancelEdit: PropTypes.func.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
    handleClickSave: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    hasDetails: PropTypes.bool.isRequired,
    implementationForm: PropTypes.object.isRequired,
    implementationStatusDetailsLabel: PropTypes.string.isRequired,
    isAdding: PropTypes.bool.isRequired
};