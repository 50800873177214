import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {
    createListIfExistsInIdArray,
    isInArray
} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import ListManagerField from "../../../components/common/inputs/ListManagerField";
import {
    convertFormInputObjectToObject,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import SelectField from "../../../components/common/inputs/SelectField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {createTeamMembersUpdateObject} from "../../drObjectFactory";
import {SingleSelectionDrRoles, CreateDrRolesListForDrAdmin} from "../../drConstants";
import {gridConstants} from "../../../constants/inputConstants";

const DrAdminTeamMembersEdit = ({
                                    fullListOfUsersSorted,
                                    handleChangeDrRole,
                                    handleSaveTeamMembers,
                                    selectedDrRole,
                                    selectedTeamMemberUserIds,
                                    stateUsers
                                }) => {
    const teamMembersForm = useFormInputObject(createTeamMembersUpdateObject(selectedTeamMemberUserIds));

    useEffect(() => {
        const newForm = createTeamMembersUpdateObject(selectedTeamMemberUserIds);
        teamMembersForm.selectedTeamMemberUserIds.setValue(newForm.selectedTeamMemberUserIds);
        teamMembersForm.selectedTeamMemberUserId.setValue(newForm.selectedTeamMemberUserId);
    }, [selectedTeamMemberUserIds]);

    const validateForm = (form) => {
        let errors = "";
        if (form.selectedTeamMemberUserIds.length === 0)
            errors += "At least one team member needs to be selected.";

        if (errors) {
            NotifyUser.Warning(errors);
            return false;
        }

        return true;
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        saveTeamMembers();
    };

    const saveTeamMembers = () => {
        let result = convertFormInputObjectToObject(teamMembersForm);
        if(isInArray(SingleSelectionDrRoles, selectedDrRole))
            result.selectedTeamMemberUserIds = result.selectedTeamMemberUserId === 0 || !result.selectedTeamMemberUserId
                ? []
                : [result.selectedTeamMemberUserId];

        const isValid = validateForm(result);
        if (isValid) {
            handleSaveTeamMembers(result.selectedTeamMemberUserIds);
        }

        return isValid;
    };

    const handleOnChangeDrRole = (event) => {
        if(saveTeamMembers())
            handleChangeDrRole(event.target.value);
    };

    const AddArchiveLabelForSelectField = (users) => {
        const archivedLabel = "Deleted/No Access";
        let updatedUsers = [];
        for(let user of users) {
            const archived = stateUsers.some(selected => selected.value === user.value) ? "" : ` (${archivedLabel})`;
            updatedUsers.push({...user, ...{text: `${user.text}${archived}`}});
        }

        return updatedUsers;
    }

    return (
        <>
            <GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
                <GridColumn>
                    <SelectField
                        name={"drRole"}
                        label={"Role"}
                        showLabel
                        options={CreateDrRolesListForDrAdmin()}
                        includeDefaultOption={false}
                        value={selectedDrRole}
                        onChange={handleOnChangeDrRole}
                    />
                </GridColumn>
            </GridRow>
            <FormTable>
                {
                    !isInArray(SingleSelectionDrRoles, selectedDrRole) &&
                    <ListManagerField
                        {...teamMembersForm.selectedTeamMemberUserIds}
                        archivedLabel={"Deleted/No Access"}
                        listItems={stateUsers}
                        managerLabel={`Team Members`}
                        name={`selectedTeamMemberUserIds`}
                        listValues={createListIfExistsInIdArray(teamMembersForm.selectedTeamMemberUserIds.value, fullListOfUsersSorted)}
                    />
                }
                {
                    isInArray(SingleSelectionDrRoles, selectedDrRole) &&
                    <SelectField
                        {...teamMembersForm.selectedTeamMemberUserId}
                        label={"Team Member"}
                        includeDefaultOption
                        options={AddArchiveLabelForSelectField(fullListOfUsersSorted)}
                        defaultOption={"Select a State User"}
                        showLabel
                    />
                }
            </FormTable>
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>
            </ButtonBar>
        </>
    );
};

DrAdminTeamMembersEdit.propTypes = {
    fullListOfUsersSorted: PropTypes.array.isRequired,
    handleChangeDrRole: PropTypes.func.isRequired,
    handleSaveTeamMembers: PropTypes.func.isRequired,
    selectedDrRole: PropTypes.number.isRequired,
    selectedTeamMemberUserIds: PropTypes.array.isRequired,
    stateUsers: PropTypes.array.isRequired
};

export default DrAdminTeamMembersEdit;