export const ADD = "button--icon button--add";
export const ADD_INPUT = "button--icon button--add button--round button--inputAction";
export const BACK = "button--icon button--back";
export const CANCEL = "button--icon button--cancel";
export const CLEAR = "button--icon button--clear button--inputAction";
export const CLONE = "button--icon button--clone";
export const CONFIGURE = "button--icon button--configure";
export const CONTEXT = "button--context";
export const CONTENT = "button--content";
export const DELETE = "button--icon button--delete";
export const REACTIVATE = "button--icon button--add";
export const DISABLED = "is-disabled";
export const DOWNLOAD = "button--icon button--download";
export const EDIT = "button--icon button--edit";
export const EDIT_ROUND = "button--round button--edit";
export const EXIT = "button--icon button--round button--exit";
export const ORDER = "button--icon button--order";
export const EMAIL = "button--icon button--email";
export const FIRST = "button--first";
export const FORWARD = "button--icon button--forward";
export const HISTORY = "button--icon button--history";
export const IMPERSONATE = "button--icon button--impersonate";
export const HELPTEXT = "button--icon button--round button--helpText";
export const INFO = "button--icon button--info";
export const INSERT_BEFORE = "button--icon button--insertBefore";
export const INSERT_AFTER = "button--icon button--insertAfter";
export const LAST = "button--last";
export const LINK = "button--link";
export const LINK_INLINE = "button--link --inline";
export const MENU = "button--menu";
export const MOCK = "button--mock";
export const NEEDS_REVIEW = "button--icon button--needsReview";
export const NEEDS_REVISIONS = "button--icon button--needsRevision";
export const NEXT = "button--icon button--next";
export const NOTIFICATION = "button--icon button--round button--notification";
export const PREVIOUS = "button--previous";
export const PRIMARY = "button--primary";
export const PRINT = "button--icon button--print";
export const REMOVE = "button--icon button--remove button--round";
export const REMOVE_INPUT = "button--icon button--remove button--round button--inputAction";
export const REPLACE = "button--icon button--replace";
export const RESET = "button--icon button--reset";
export const RESTRICT = "button--icon button--restrict";
export const REVIEWED = "button--icon button--reviewed";
export const SAVE = "button--icon button--save";
export const SEARCH = "button--icon button--primary button--search";
export const SECONDARY = "button--secondary";
export const SUBMIT = "button--submit";
export const SUBMIT_ICON = "button--icon button--save";
export const SYSTEM = "button--system";
export const UPLOAD = "button--icon button--upload";
export const VIEW = "button--icon button--view";
export const CHECKED = "button--icon button--checked";
export const UNCHECKED = "button--icon button--unchecked";






