import React from 'react';
import PropTypes from 'prop-types';
import {inputStyles} from "../../constants/inputConstants";
import {createYesNoList, createYesNoObject} from "../common/inputs/inputUtility";
import RadioField from "../common/inputs/RadioField";
import SearchableSingleSelectField from "../common/inputs/SearchableSingleSelectField";
import TextField from "../common/inputs/TextField";
import DisplayField from "../common/inputs/DisplayField";
import FormTable from "../common/FormTable";
import AccountUtility from "../../api/AccountUtility";

const UserProfileAccountForm = ({
                                    errors,
                                    institutions,
                                    isStateUser,
                                    onFormChange,
                                    user
                                }) => {
    const isDistrictUser = AccountUtility.isDistrictUser();
    const yesNoObject = createYesNoObject(false);

    return (
        <section className="user-account">
            <h3>Account Information</h3>
            <FormTable>
                <TextField
                    name="firstName"
                    label="First Name"
                    onChange={onFormChange}
                    placeholder="FirstName"
                    showLabel
                    value={user.firstName}
                    error={errors.firstName}
                    readonly={user.hasActiveBpie}/>

                <TextField
                    name="lastName"
                    label="Last Name"
                    onChange={onFormChange}
                    placeholder="LastName"
                    showLabel
                    value={user.lastName}
                    error={errors.lastName}
                    readonly={user.hasActiveBpie}/>

                <TextField
                    name="email"
                    label="Email"
                    onChange={onFormChange}
                    placeholder="Email"
                    showLabel
                    value={user.email}
                    error={errors.email}
                    readonly={user.hasActiveBpie}/>
                <TextField
                    name="username"
                    label="Username"
                    onChange={onFormChange}
                    placeholder="Username"
                    showLabel
                    value={user.username}
                    error={errors.username}
                    readonly={user.hasActiveBpie}/>

                {
                    !isDistrictUser &&
                    <RadioField
                        name={"isStateUser"}
                        legend={"State User"}
                        options={createYesNoList(false)}
                        onChange={onFormChange}
                        value={isStateUser ? yesNoObject.Yes : yesNoObject.No}
                        direction={inputStyles.radio.direction.HORIZONTAL}
                        showLegend
                        disabled={user.hasActiveBpie}
                    />
                }
                {
                    !isStateUser &&
                    <SearchableSingleSelectField
                        disabled={isDistrictUser || user.hasActiveBpie}
                        error={errors.institutionId}
                        label="District / Institution"
                        name="institutionId"
                        onChange={onFormChange}
                        options={institutions}
                        showLabel
                        value={user.institutionId || ""}
                    />
                }
                <DisplayField
                    name="dfStatus"
                    label="Status:"
                    showLabel
                >
                    {user.status ? user.status : ""}
                </DisplayField>
            </FormTable>
        </section>
    );
};

UserProfileAccountForm.propTypes = {
    errors: PropTypes.object,
    institutions: PropTypes.array,
    isStateUser: PropTypes.bool.isRequired,
    onFormChange: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

export default UserProfileAccountForm;