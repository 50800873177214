import React, {useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import Collapsible from "../../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../../components/common/CollapsibleTrigger";
import {
    createListIfExistsInIdArray, dynamicSort,
    isArrayNullOrEmpty,
    isNullOrUndefined
} from "../../../../../components/common/commonUtilities";
import FormTable from "../../../../../components/common/FormTable";
import GridTable from "../../../../../components/common/GridTable";
import {SelectField} from "../../../../../components/common/inputs";
import ListManagerField from "../../../../../components/common/inputs/ListManagerField";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {PTS_ROLES} from "../../../ptsConstants";
import * as ptsLocations from "../../../ptsLocations";
import {
    createProgramUserIdsArray,
    createProjectManagersSelectList,
    removeProgramUsersAfterFiscalAgentsUpdated,
    createStateUsersSelectList,
    updateStateUsersSelectedArray,
    findSelectedStateUser
} from "./ptsManageFiscalAgentUsersUtilities";

export const PtsManageFiscalAgentsUsersView = ({
                                                   program,
                                                   fiscalAgents,
                                                   gmsUsers,
                                                   gotoLocation,
                                                   handleDataChanged,
                                                   handleSavePtsProgramFiscalAgentsUsers,
                                                   isLoading,
                                                   users
                                               }) => {
    const [fiscalAgentIds, setFiscalAgentIds] = useState(program.programFiscalAgents.map(fiscalAgent => fiscalAgent.fiscalAgentId));
    const [projectManagerUserIds, setProjectManagerUserIds] = useState(createProgramUserIdsArray(program.programFiscalAgents, PTS_ROLES.projectManager.id));
    const [liaisonUserIds, setLiaisonUserIds] = useState(createProgramUserIdsArray(program.programFiscalAgents, PTS_ROLES.liaisonUser.id));
    const [grantManagementUserIds, setGrantManagementUserIds] = useState(createProgramUserIdsArray(program.programFiscalAgents, PTS_ROLES.grantsManagementUser.id));

    const handleSaveFiscalAgentsClick = async (event) => {
        event.preventDefault();

        await handleSavePtsProgramFiscalAgentsUsers(fiscalAgentIds, projectManagerUserIds, liaisonUserIds, grantManagementUserIds);
    };

    const handleReturnToProgramListClick = (event) => {
        event.preventDefault();
        handleDataChanged(false);
        gotoLocation(ptsLocations.PTS_ADMIN_MANAGE_PROGRAMS.path);
    };

    const handleModifyFiscalAgents = (event) => {
        event.preventDefault();
        const updatedFiscalAgentIds = event.target.value;
        const updateProjectManagerUserIds = removeProgramUsersAfterFiscalAgentsUpdated(updatedFiscalAgentIds, projectManagerUserIds);
        const updateLiaisonUserIds = removeProgramUsersAfterFiscalAgentsUpdated(updatedFiscalAgentIds, liaisonUserIds);
        const updateGrantManagementUserIds = removeProgramUsersAfterFiscalAgentsUpdated(updatedFiscalAgentIds, grantManagementUserIds);

        if ((updateProjectManagerUserIds.length !== projectManagerUserIds.length
            || updateLiaisonUserIds.length !== liaisonUserIds.length
            || updateGrantManagementUserIds.length !== grantManagementUserIds.length)
            && !confirm("By removing the fiscal agent, this will also remove the associations to project managers, BEESS liaisons, and grants management users. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setFiscalAgentIds(sortFiscalAgentIds(updatedFiscalAgentIds));
        setProjectManagerUserIds(updateProjectManagerUserIds);
        setLiaisonUserIds(updateLiaisonUserIds);
        setGrantManagementUserIds(updateGrantManagementUserIds);
        handleDataChanged(true);
    };

    const sortFiscalAgentIds = (fiscalAgentIds) => {
        let sortedFiscalAgents = [];
        for(const fiscalAgentId of fiscalAgentIds) {
            const fiscalAgent = fiscalAgents.find(f => f.id === fiscalAgentId);
            if(!isNullOrUndefined(fiscalAgent))
                sortedFiscalAgents.push(fiscalAgent);
        }

        sortedFiscalAgents = sortedFiscalAgents.sort(dynamicSort("text"));
        return sortedFiscalAgents.map(f => f.id);
    }

    const handleModifyProjectManagerUsers = (event) => {
        event.preventDefault();
        setProjectManagerUserIds(event.target.value);
        handleDataChanged(true);
    };

    const handleModifyStateUsers = (event, fiscalAgentId, stateUserType) => {
        event.preventDefault();

        const selectedUserId = event.target.value;
        if(stateUserType === PTS_ROLES.liaisonUser.id)
            setLiaisonUserIds(updateStateUsersSelectedArray(fiscalAgentId, selectedUserId, liaisonUserIds));
        else
            setGrantManagementUserIds(updateStateUsersSelectedArray(fiscalAgentId, selectedUserId, grantManagementUserIds));

        handleDataChanged(true);
    };

    const grantsManagementUsersList = createStateUsersSelectList(gmsUsers);
    const liaisonUsersList = createStateUsersSelectList(users.filter(f => isNullOrUndefined(f.institutionId)));
    const availableProjectManagers = createProjectManagersSelectList(users.filter(f => fiscalAgentIds.includes(f.institutionId)));

    const fiscalAgentsTrigger = <CollapsibleTrigger text={`Associate Fiscal Agents`}/>;
    const projectManagersTrigger = <CollapsibleTrigger text={`Associate Project Managers`}/>;
    const stateUsersTrigger = <CollapsibleTrigger text={`Associate BEESS Liaisons and Grants Management Users`}/>;

    return (
        <>
            <p>Collapse the fiscal agents container to easily get to the list of associated project managers.</p>
            <Collapsible id={`fiscalAgents-container`} open trigger={fiscalAgentsTrigger}>
                <FormTable>
                    <ListManagerField
                        archivedLabel={"Deleted"}
                        listItems={fiscalAgents}
                        managerLabel={`Fiscal Agents`}
                        name={`fiscalAgentIds`}
                        onChange={handleModifyFiscalAgents}
                        listValues={createListIfExistsInIdArray(fiscalAgentIds, fiscalAgents)}
                        selectToAdd
                    />
                </FormTable>
            </Collapsible>

            <Collapsible id={`projectManagers-container`} open trigger={projectManagersTrigger}>
                <FormTable>
                    {
                        (!isArrayNullOrEmpty(availableProjectManagers) || !isArrayNullOrEmpty(projectManagerUserIds)) &&
                        <ListManagerField
                            archivedLabel={"Deleted"}
                            listItems={availableProjectManagers}
                            managerLabel={`Project Managers`}
                            name={`projectManagerUserIds`}
                            onChange={handleModifyProjectManagerUsers}
                            listValues={createListIfExistsInIdArray(projectManagerUserIds, availableProjectManagers)}
                            selectToAdd
                        />
                    }
                    {
                        isArrayNullOrEmpty(availableProjectManagers) && isArrayNullOrEmpty(projectManagerUserIds) &&
                        <p>No project managers available to associate.  Make sure expected project managers have at least &quot;View&quot; access for the PTS section.</p>
                    }
                </FormTable>
            </Collapsible>

            <Collapsible id={`stateUsers-container`} open trigger={stateUsersTrigger}>
                <GridTable>
                    <thead>
                    <tr>
                        <th>District / Institution</th>
                        <th>BEESS Liaison</th>
                        <th>Grants Management User</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        fiscalAgentIds.map((fiscalAgentId, index) => {
                            const fiscalAgentName = fiscalAgents.find(f => f.id === fiscalAgentId).text;
                            const selectedLiaisonId = findSelectedStateUser(fiscalAgentId, liaisonUserIds);
                            const selectedGrantsManagementUserId = findSelectedStateUser(fiscalAgentId, grantManagementUserIds);
                            const grantsManagementUsersListFiltered = grantsManagementUsersList.filter(f => f.id === selectedGrantsManagementUserId || !f.isDisabled);

                            return (
                                <tr key={index}>
                                    <td>{fiscalAgentName}</td>
                                    <td>
                                        <SelectField
                                            label={`BEESS Liaison for ${fiscalAgentName}`}
                                            value={selectedLiaisonId}
                                            options={liaisonUsersList}
                                            showLabel={false}
                                            name={`liaisonUserId_${fiscalAgentId}`}
                                            onChange={(event) => handleModifyStateUsers(event, fiscalAgentId, PTS_ROLES.liaisonUser.id)}
                                        />
                                    </td>
                                    <td>
                                        <SelectField
                                            label={`Grants Management User for ${fiscalAgentName}`}
                                            value={selectedGrantsManagementUserId}
                                            options={grantsManagementUsersListFiltered}
                                            showLabel={false}
                                            name={`grantsManagementUserIds`}
                                            onChange={(event) => handleModifyStateUsers(event, fiscalAgentId, PTS_ROLES.grantsManagementUser.id)}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </GridTable>
            </Collapsible>

            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button
                    name={"save"}
                    label={"Save Program Details"}
                    onClick={handleSaveFiscalAgentsClick}
                    buttonType={ButtonTypes.SAVE}
                    diabled={isLoading}
                />
                <Button
                    name={"btnReturn"}
                    label={"Return to Programs"}
                    onClick={handleReturnToProgramListClick}
                    buttonType={ButtonTypes.BACK}
                    diabled={isLoading}
                />
            </ButtonBar>
        </>
    );
};

PtsManageFiscalAgentsUsersView.propTypes = {
    program: PropTypes.object,
    fiscalAgents: PropTypes.array,
    gmsUsers: PropTypes.array,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSavePtsProgramFiscalAgentsUsers: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    users: PropTypes.array
};