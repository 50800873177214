import React from 'react';
import PropTypes from "prop-types";
import {convertLineBreaksToBreakTags} from "../../../../components/common/HtmlUtilities";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import FormTable from "../../../../components/common/FormTable";
import {bpieTeamRoles} from "../../bpieConstants";
import {meetingLeaderTitles} from "./bpieSchoolInfoConstants";

const BpieAssignMemberView = ({
                                  schoolBpie
                              }) => {

    const meetingLeader = schoolBpie.schoolBpieTeam.find(f => f.role === bpieTeamRoles.meetingLeader);

    const finFacilitator = schoolBpie.schoolBpieTeam.find(f => f.role === bpieTeamRoles.finFacilitator);

    const getMeetingLeaderTitle = () => {
        if(!meetingLeader)
            return "";

        return meetingLeader.title === meetingLeaderTitles.Other
            ? `${meetingLeader.title}: ${meetingLeader.titleOther}`
            : meetingLeader.title;
    };

    const displayAdditionalMeetingLeaderInfo = () => {
        if(!meetingLeader)
            return false;

        return meetingLeader.title !== meetingLeaderTitles.SchoolPrincipal
            && meetingLeader.title !== meetingLeaderTitles.FINFacilitator;
    }

    const finFacilitatorContactInfo = !finFacilitator ? "" : `${finFacilitator.firstName} ${finFacilitator.lastName}, ${finFacilitator.email}`;
    return (
        <>
            <h2>ESSA Targets</h2>
            <FormTable>
                <DisplayField
                    name={"hasMetEssaTarget"}
                    label={`Students with disabilities subgroup met the target for <a href=' https://www.fldoe.org/academics/essa.stml' target='_blank' rel='noopener noreferrer'>Every Student Succeeds Act (ESSA)</a> Federal Percent of Points Index.`}
                    showLabel>
                    {schoolBpie.hasMetEssaTarget}
                </DisplayField>
            </FormTable>

            <h2>FIN Facilitator Contact Name</h2>
            <DisplayField
                name={"finFacilitatorId"}
                label={"Contact Information: "}
                showLabel
                isInline>
                {finFacilitatorContactInfo}
            </DisplayField>

            <h2>The School&apos;s FIN Trained Meeting Leader</h2>
            <FormTable>
                <DisplayField
                    name={"title"}
                    label={"Meeting Leader Title: "}
                    showLabel
                    isInline>
                    {getMeetingLeaderTitle()}
                </DisplayField>
                {
                    displayAdditionalMeetingLeaderInfo() &&
                    <>
                        <DisplayField
                            isInline
                            label={"Meeting Leader First Name: "}
                            name={"firstName"}
                            showLabel
                        >
                            {meetingLeader?.firstName}
                        </DisplayField>
                        <DisplayField
                            isInline
                            label={"Meeting Leader Last Name: "}
                            name={"lastName"}
                            showLabel
                        >
                            {meetingLeader?.lastName}
                        </DisplayField>
                        <DisplayField
                            isInline
                            label={"Meeting Leader Email Address: "}
                            name={"email"}
                            showLabel
                        >
                            {meetingLeader?.email}
                        </DisplayField>
                    </>
                }
                {
                    meetingLeader?.title !== meetingLeaderTitles.FINFacilitator &&
                    <DisplayField
                        label={"Has the Meeting Leader completed the SBPIE Assessment training?"}
                        name={"hasCompletedBpieTraining"}
                        showLabel
                    >
                        {schoolBpie.hasCompletedBpieTraining ? "Yes" : "No"}
                    </DisplayField>
                }
                <DisplayField
                    label={"Team member names and titles/positions"}
                    name={"assessmentTeam"}
                    showLabel
                >
                    {convertLineBreaksToBreakTags(schoolBpie.assessmentTeam)}
                </DisplayField>
                <DisplayField
                    label={`Date Meeting Held`}
                    name={`dateMeetingHeld`}
                    showLabel
                >
                    {schoolBpie.dateMeetingHeldOutput}
                </DisplayField>
            </FormTable>
        </>
    );
}

BpieAssignMemberView.propTypes = {
    schoolBpie: PropTypes.object.isRequired
};

export default BpieAssignMemberView;