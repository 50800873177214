import React from 'react';
import PropTypes from 'prop-types';
import GridTable from "../common/GridTable";
import * as MonitoringComplianceUtilities from "./MonitoringComplianceUtilities";
import MatrixOfServiceRadioAdapter from "./MatrixOfServiceRadioAdapter";
import MatrixOfServiceCheckBoxAdapter from "./MatrixOfServiceCheckBoxAdapter";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";

const MatrixOfService = ({
                             indicatorProtocols,
                             protocolResponses,
                             showState,
                             isEditing,
                             onResponseChange,
                             studentRecordId,
                             isReassess,
                             assessmentApproved
}) => {
    let totalDomainReported = 0;
    let totalDomainReviewed = 0;
    let totalDomainStateReviewed = 0;
    let totalConsiderationsReported = 0;
    let totalConsiderationsReviewed = 0;
    let totalConsiderationsStateReviewed = 0;
    let rowIndex = 0;
    const totalColumns = showState && !assessmentApproved ? 4 : 3;
    const responseType = isReassess && isEditing ? responseTypes.REASSESS : responseTypes.ASSESS;

    return (
        <div className="compliance__matrixOfService">
            {
                assessmentApproved &&
                <p>Note - any responses overwritten by DOE are marked with two asterisks (**).</p>
            }
            <GridTable>
                <thead>
                <tr>
                    <th>Category</th>
                    <th className={"text-center"}>Reported by District</th>
                    <th className={"text-center"}>Reviewed by District</th>
                    {
                        showState &&
                        !assessmentApproved &&
                        <th className={"text-center"}>Reviewed by DOE</th>
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th colSpan={totalColumns}>Domains</th>
                </tr>
                {
                    indicatorProtocols.filter(f => f.districtResponseOptions.length > 1).map((protocol) => {
                        let overriddenStateResponse = "";
                        const districtReassessResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
                            protocolResponses,
                            protocol.protocolId,
                            responseTypes.REASSESS);

                        let districtResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
                            protocolResponses,
                            protocol.protocolId,
                            responseType);

                        const stateResponse = MonitoringComplianceUtilities.getProtocolResponse(
                            protocolResponses,
                            protocol.protocolId,
                            responseTypes.VALIDATION);

                        if(assessmentApproved) {
                            districtResponse = MonitoringComplianceUtilities.getMatrixProtocolApprovedResponse(stateResponse, districtReassessResponse, districtResponse);

                            if(districtResponse.stateResponse)
                                overriddenStateResponse = "**";
                        }

                        totalDomainReported += districtResponse.reportedDistrict === "" ? 0 : parseInt(districtResponse.reportedDistrict);
                        totalDomainReviewed += districtResponse.reviewedDistrict === "" ? 0 : parseInt(districtResponse.reviewedDistrict);
                        totalDomainStateReviewed += stateResponse === "" ? 0 : parseInt(stateResponse);
                        rowIndex++;
                        return (
                            <tr key={rowIndex}>
                                <td>{protocol.standardDescription}</td>
                                <td className={"response-no-wrap text-center"}>
                                    <MatrixOfServiceRadioAdapter
                                        name={"reportedDistrict"}
                                        index={rowIndex}
                                        value={districtResponse.reportedDistrict + overriddenStateResponse}
                                        protocolId={protocol.protocolId}
                                        onResponseChange={onResponseChange}
                                        isEditing={isEditing && !showState}
                                        options={protocol.districtResponseOptions}
                                        studentRecordId={studentRecordId}
                                        legend={`Reported by district for &quot;${protocol.standardDescription}&quot;`}
                                    />
                                </td>
                                <td className={"response-no-wrap text-center"}>
                                    <MatrixOfServiceRadioAdapter
                                        name={"reviewedDistrict"}
                                        index={rowIndex}
                                        value={districtResponse.reviewedDistrict + overriddenStateResponse}
                                        protocolId={protocol.protocolId}
                                        onResponseChange={onResponseChange}
                                        isEditing={isEditing && !showState}
                                        options={protocol.districtResponseOptions}
                                        studentRecordId={studentRecordId}
                                        legend={`Reviewed by district for &quot;${protocol.standardDescription}&quot;`}
                                    />
                                </td>
                                {
                                    showState &&
                                    !assessmentApproved &&
                                    <td className={"response-no-wrap text-center"}>
                                        <MatrixOfServiceRadioAdapter
                                            name={"stateResponse"}
                                            index={rowIndex}
                                            value={stateResponse}
                                            protocolId={protocol.protocolId}
                                            onResponseChange={onResponseChange}
                                            isEditing={isEditing && showState}
                                            options={protocol.stateResponseOptions}
                                            studentRecordId={studentRecordId}
                                            legend={`Reviewed by DOE for &quot;${protocol.standardDescription}&quot;`}
                                        />
                                    </td>
                                }
                            </tr>
                        );
                    })
                }
                <tr className={"tfoot"}>
                    <td>Total of Domain Ratings</td>
                    <td className={"text-center"}>{totalDomainReported}</td>
                    <td className={"text-center"}>{totalDomainReviewed}</td>
                    {
                        showState &&
                        !assessmentApproved &&
                        <td className={"text-center"}>{totalDomainStateReviewed}</td>
                    }
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <th colSpan={totalColumns}>Special Considerations</th>
                </tr>
                {
                    indicatorProtocols.filter(f => f.districtResponseOptions.length === 1).map((protocol) => {
                        let overridenStateResponse = "";
                        const districtReassessResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
                            protocolResponses,
                            protocol.protocolId,
                            responseTypes.REASSESS);

                        let districtResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
                            protocolResponses,
                            protocol.protocolId,
                            responseType);

                        const stateResponse = MonitoringComplianceUtilities.getProtocolResponse(
                            protocolResponses,
                            protocol.protocolId,
                            responseTypes.VALIDATION);

                        if(assessmentApproved) {
                            districtResponse = MonitoringComplianceUtilities.getMatrixProtocolApprovedResponse(stateResponse, districtReassessResponse, districtResponse);
                            if(districtResponse.stateResponse)
                                overridenStateResponse = "**";
                        }

                        totalConsiderationsReported += districtResponse.reportedDistrict === "" ? 0 : parseInt(districtResponse.reportedDistrict);
                        totalConsiderationsReviewed += districtResponse.reviewedDistrict === "" ? 0 : parseInt(districtResponse.reviewedDistrict);
                        totalConsiderationsStateReviewed += stateResponse === "" ? 0 : parseInt(stateResponse);
                        rowIndex++;
                        return (
                            <tr key={rowIndex}>
                                <td>{protocol.standardDescription}</td>
                                <td className={"response-no-wrap text-center"}>
                                    <MatrixOfServiceCheckBoxAdapter
                                        name={"reportedDistrict"}
                                        label={`Reported by District - ${protocol.standardDescription}`}
                                        index={rowIndex}
                                        isEditing={isEditing && !showState}
                                        onResponseChange={onResponseChange}
                                        protocolId={protocol.protocolId}
                                        value={protocol.districtResponseOptions[0].value}
                                        isChecked={districtResponse.reportedDistrict === protocol.districtResponseOptions[0].value}
                                        studentRecordId={studentRecordId}
                                        overridenStateResponse={overridenStateResponse}
                                    />
                                </td>
                                <td className={"response-no-wrap text-center"}>
                                    <MatrixOfServiceCheckBoxAdapter
                                        name={"reviewedDistrict"}
                                        label={`Reviewed by District - ${protocol.standardDescription}`}
                                        index={rowIndex}
                                        isEditing={isEditing && !showState}
                                        onResponseChange={onResponseChange}
                                        protocolId={protocol.protocolId}
                                        value={protocol.districtResponseOptions[0].value}
                                        isChecked={districtResponse.reviewedDistrict === protocol.districtResponseOptions[0].value}
                                        studentRecordId={studentRecordId}
                                        overridenStateResponse={overridenStateResponse}
                                    />
                                </td>
                                {
                                    showState &&
                                    !assessmentApproved &&
                                    <td className={"response-no-wrap text-center"}>
                                        <MatrixOfServiceCheckBoxAdapter
                                            name={"stateResponse"}
                                            label={`Reviewed by State - ${protocol.standardDescription}`}
                                            index={rowIndex}
                                            isEditing={isEditing && showState}
                                            onResponseChange={onResponseChange}
                                            protocolId={protocol.protocolId}
                                            value={protocol.stateResponseOptions[0].value}
                                            isChecked={stateResponse === protocol.stateResponseOptions[0].value}
                                            studentRecordId={studentRecordId}
                                            overridenStateResponse={overridenStateResponse}
                                        />
                                    </td>
                                }
                            </tr>
                        );
                    })
                }
                <tr className={"tfoot"}>
                    <td>Total of Special Considerations Rating:</td>
                    <td className={"text-center"}>{totalConsiderationsReported}</td>
                    <td className={"text-center"}>{totalConsiderationsReviewed}</td>
                    {
                        showState &&
                        !assessmentApproved &&
                        <td className={"text-center"}>{totalConsiderationsStateReviewed}</td>
                    }
                </tr>
                </tbody>
                <tbody>
                <tr className={"tfoot"}>
                    <td>Total of Domain and Special Considerations Ratings:</td>
                    <td className={"text-center"}>{totalDomainReported + totalConsiderationsReported}</td>
                    <td className={"text-center"}>{totalDomainReviewed + totalConsiderationsReviewed}</td>
                    {
                        showState &&
                        !assessmentApproved &&
                        <td className={"text-center"}>{totalDomainStateReviewed + totalConsiderationsStateReviewed}</td>
                    }
                </tr>
                <tr className={"tfoot"}>
                    <td>
                        <p>Total Ratings to Cost Factor</p>
                        <GridRow>
                            <GridColumn columnClass={"shrink"}>
                                <ul>
                                    <li>6 - 9</li>
                                    <li>10 - 13</li>
                                    <li>14 - 17</li>
                                    <li>18 - 21</li>
                                    <li>22+</li>
                                </ul>
                            </GridColumn>
                            <GridColumn columnClass={"shrink"}>
                                <ul className={"no-bullet"}>
                                    <li> = 251</li>
                                    <li> = 252</li>
                                    <li> = 253</li>
                                    <li> = 254</li>
                                    <li> = 255</li>
                                </ul>
                            </GridColumn>
                        </GridRow>
                    </td>
                    <td className={"text-center"} id={"totalDistrictReported"}>{MonitoringComplianceUtilities.generateMatrixCostFactor(totalDomainReported + totalConsiderationsReported)}</td>
                    <td className={"text-center"} id={"totalDistrictReviewed"}>{MonitoringComplianceUtilities.generateMatrixCostFactor(totalDomainReviewed + totalConsiderationsReviewed)}</td>
                    {
                        showState &&
                        !assessmentApproved &&
                        <td className={"text-center"}>{MonitoringComplianceUtilities.generateMatrixCostFactor(totalDomainStateReviewed + totalConsiderationsStateReviewed)}</td>
                    }
                </tr>
                </tbody>
            </GridTable>
        </div>
    );
};

MatrixOfService.propTypes = {
    indicatorProtocols: PropTypes.array.isRequired,
    protocolResponses: PropTypes.array.isRequired,
    showState: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    studentRecordId: PropTypes.string.isRequired,
    isReassess: PropTypes.bool.isRequired,
    assessmentApproved: PropTypes.bool.isRequired
};

export default MatrixOfService;