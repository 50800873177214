import React from "react";
import PropTypes from 'prop-types';
import {Allow} from "../../components/authorization/Allow";
import DrDueProcessCaseload from "../DrComponents/DrDueProcessCaseload";
import DrMediationCaseload from "../DrComponents/DrMediationCaseload";
import DrStateComplaintCaseload from "../DrComponents/DrStateComplaintCaseload";
import * as contexts from "../../constants/contexts";
import {DisputeResolutionType} from "../drConstants";

export const DrCaseloadView = ({
                                        caseload,
                                        drCaseloadSearchCriteria,
                                        handleClickOrder,
                                        handleUpdateDrSearchCriteria,
                                        mediationTeamMembers
                                    }) => {
    const handleClickCaseload = async (event, type, userId = "", status = "", role = "") => {
        event.preventDefault();
        handleUpdateDrSearchCriteria(type, userId, status, role);
    };

    const validateSelectedDisputeResolutionType = (type) => {
        return drCaseloadSearchCriteria.disputeResolutionTypeWithDefault === "" || drCaseloadSearchCriteria.disputeResolutionTypeWithDefault === type;
    }

    const {complaintCaseloads, mediationCaseload, dueProcessCaseloads} = caseload;

    return (
        <>
            <Allow context={contexts.STATE_COMPLAINTS}>
                {
                    validateSelectedDisputeResolutionType(DisputeResolutionType.StateComplaints) &&
                    <DrStateComplaintCaseload
                        complaintCaseloads={drCaseloadSearchCriteria.applyFiltersAndOrder(complaintCaseloads)}
                        handleClickCaseload={handleClickCaseload}
                        handleClickOrder={handleClickOrder}
                        includeUserStatus
                        showName
                    />
                }
            </Allow>

            <Allow context={contexts.DUE_PROCESS}>
                {
                    validateSelectedDisputeResolutionType(DisputeResolutionType.DueProcess) &&
                    <DrDueProcessCaseload
                        dueProcessCaseloads={drCaseloadSearchCriteria.applyFiltersAndOrder(dueProcessCaseloads)}
                        handleClickCaseload={handleClickCaseload}
                        handleClickOrder={handleClickOrder}
                        includeUserStatus
                        showName
                    />
                }
            </Allow>

            <Allow context={contexts.MEDIATIONS}>
                {
                    validateSelectedDisputeResolutionType(DisputeResolutionType.Mediation) &&
                    <DrMediationCaseload
                        mediationCaseload={mediationCaseload}
                        handleClickCaseload={handleClickCaseload}
                        mediationTeamMembers={mediationTeamMembers}
                    />
                }
            </Allow>
        </>
    );
};

DrCaseloadView.propTypes = {
    caseload: PropTypes.object.isRequired,
    drCaseloadSearchCriteria: PropTypes.object.isRequired,
    handleClickOrder: PropTypes.func.isRequired,
    handleUpdateDrSearchCriteria: PropTypes.func.isRequired,
    mediationTeamMembers: PropTypes.array
};

export default DrCaseloadView;