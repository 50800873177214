import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../api/requests/RequestTypes';
import {dr_api as ApiSuccessMessages} from "../../constants/api/ApiSuccessMessages";
import * as config from "../../constants/config";
import * as mockData from "../drMockData";
import * as drDueProcessObjectFactory from "../DueProcesses/drDueProcessObjectFactory";
import {convertObjectToFormData} from "../../api/ApiUtility";
const disputeApi = 'dispute-resolution';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrDueProcessApi {
	static getSummary(dateWindowId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcessSummaries/${dateWindowId}`,
				RequestTypes.GET));
	}

	static getDistrictDueProcessesByDate(districtId, routeTemplate, dateWindowId) {

		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/${districtId}/dueProcesses/Date/${dateWindowId}/route`,
				RequestTypes.GET,
				{routeTemplate}));
	}

	static getDueProcess(dueProcessId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/`,
				RequestTypes.GET));
	}

	static saveDueProcess(model) {
		const existing = model.id !== config.emptyGuid;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${model.id}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(model),
				ApiSuccessMessages.dueProcesses.SAVED,
				'application/json'));
	}

	static saveDueProcessFinalOrder(model) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${model.id}/finalOrder`,
				RequestTypes.PUT,
				JSON.stringify(model),
				ApiSuccessMessages.dueProcesses.SAVED,
				'application/json'));
	}

	static saveDueProcessAction(model) {
		const existing = model.id !== config.emptyGuid;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${model.dueProcessId}/actions/${model.id}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(model),
				ApiSuccessMessages.dueProcesses.action.SAVED,
				'application/json'));
	}

	static deleteDueProcessAction(dueProcessId, actionId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${actionId}`,
				RequestTypes.DELETE,
				JSON.stringify(actionId),
				ApiSuccessMessages.dueProcesses.action.DELETED,
				'application/json'));
	}

	static deleteDueProcess(dueProcessId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/`,
				RequestTypes.DELETE));
	}

	static getJudges() {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/judges`,
				RequestTypes.GET));
	}

	static getFederalReport(dateWindowId, includeGifted) {
		const url = includeGifted
			? `${config.apiUrl}${disputeApi}/dueProcess/reports/state/federalWithGiftedPrint/Dates/${dateWindowId}`
			: `${config.apiUrl}${disputeApi}/dueProcess/reports/state/federalPrint/Dates/${dateWindowId}`;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				url,
				RequestTypes.GET));
	}

	static saveResponse(model, dueProcessId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${model.actionId}/responses`,
				RequestTypes.PUT,
				JSON.stringify(model),
				ApiSuccessMessages.dueProcesses.SAVED,
				'application/json'));
	}

	static saveResponseReview(review, dueProcessId, actionId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${actionId}/responses/${review.responseId}/reviews`,
				RequestTypes.PUT,
				JSON.stringify(review),
				ApiSuccessMessages.dueProcesses.response.review.SAVED,
				'application/json'));
	}

	static removeResponseReview(dueProcessId, actionId, responseId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${actionId}/responses/${responseId}/review`,
				RequestTypes.DELETE,
				null,
				ApiSuccessMessages.dueProcesses.response.review.DELETED,
				'application/json'));
	}

	static auditResponse(response, dueProcessId, auditAction) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${response.actionId}/responses/${response.id}/${auditAction}`,
				RequestTypes.POST,
				null,
				ApiSuccessMessages.dueProcesses.response.audit.getMessage(auditAction),
				'application/json'));
	}

	static saveAuditComment(responseId, actionId, dueProcessId, comment, existing, auditAction="comments") {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/actions/${actionId}/responses/${responseId}/audits/${comment.auditId}/${auditAction}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(comment),
				ApiSuccessMessages.dueProcesses.response.audit.getMessage(auditAction),
				'application/json'));
	}

	static saveResolutionProcess(dueProcessId, model) {
		const existing = model.id !== config.emptyGuid;
		const message = model.shouldSubmitReport
			? ApiSuccessMessages.dueProcesses.RESOLUTION_PROCESS.SUBMITTED
			: ApiSuccessMessages.dueProcesses.RESOLUTION_PROCESS.SAVED;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/resolutionProcesses/${model.resolutionProcessId}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(model),
				message,
				'application/json'));
	}

	static auditResolutionProcess(dueProcessId, auditAction) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/resolutionProcess/${auditAction}`,
				RequestTypes.POST,
				null,
				ApiSuccessMessages.dueProcesses.RESOLUTION_PROCESS.audit.getMessage(auditAction),
				'application/json'));
	}

	static saveResolutionProcessAuditComment(dueProcessId, comment, existing, auditAction="comments") {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/resolutionProcess/audits/${comment.auditId}/${auditAction}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(comment),
				ApiSuccessMessages.dueProcesses.RESOLUTION_PROCESS.audit.getMessage(auditAction),
				'application/json'));
	}

	static saveCaseNote(model) {
		const isNewCaseNote = !model.caseNoteId;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${model.dueProcessId}/case-notes`,
				isNewCaseNote ? RequestTypes.POST : RequestTypes.PUT,
				JSON.stringify(model),
				isNewCaseNote ? ApiSuccessMessages.dueProcesses.caseNote.ADDED : ApiSuccessMessages.dueProcesses.caseNote.SAVED,
				'application/json'));
	}

	static deleteCaseNote(dueProcessId, caseNoteId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcesses/${dueProcessId}/case-note/${caseNoteId}`,
				RequestTypes.DELETE,
				null,
				ApiSuccessMessages.dueProcesses.caseNote.DELETED,
				'application/json'));
	}

	static downloadCaseInvestigationReport(dueProcessId, caseNoteFilter) {
		return ApiUtility.downloadBlob(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcess/reports/state/investigationLogReport/dueProcesses/${dueProcessId}?startDate=${caseNoteFilter.startDate}&endDate=${caseNoteFilter.endDate}&noteType=${caseNoteFilter.noteType}`,
				RequestTypes.GET,
				null,
				ApiSuccessMessages.dueProcesses.caseNote.DOWNLOAD
			));
	}

	static exportCaseSearchResults(caseSearchFilters) {
		return ApiUtility.downloadBlob(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/dueProcess/reports/state/caseSearchResultsExport/dates/${caseSearchFilters.dateWindowId}`,
				RequestTypes.POST,
				convertObjectToFormData(caseSearchFilters),
				ApiSuccessMessages.dueProcesses.CASE_RESEARCH.DOWNLOAD
			));
	}
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrDueProcessApi {
	static getSummary() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getDueProcessSummary());
			}, config.environment.delay);
		});
	}

	static getDistrictDueProcessesByDate() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getDistrictDueProcessesByDate());
			}, config.environment.delay);
		});
	}

	static getDueProcess(dueProcessId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getDueProcesses().filter(a => a.Id == dueProcessId));
			}, config.environment.delay);
		});
	}

	static addDueProcess(NewDueProcess) {
		const DueProcessDTO = drDueProcessObjectFactory.createDueProcessObject(NewDueProcess);
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(DueProcessDTO);
			}, config.environment.delay);
		});
	}

	static saveDueProcess(dueProcess) {
		return new Promise(resolve => {
			setTimeout(() => {
				const OtherDueProcesses = mockData.getDueProcesses().filter(a => a.id !== dueProcess.id);

				mockData.saveDueProcesses([...OtherDueProcesses, dueProcess]);
				resolve();
			}, config.environment.delay);
		});
	}

	static saveDueProcessFinalOrder(dueProcess) {
		return new Promise(resolve => {
			setTimeout(() => {
				const OtherDueProcesses = mockData.getDueProcesses().filter(a => a.id !== dueProcess.id);

				mockData.saveDueProcesses([...OtherDueProcesses, dueProcess]);
				resolve();
			}, config.environment.delay);
		});
	}

	static saveDueProcessAction(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteDueProcessAction(dueProcessId, actionId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteDueProcess(dueProcessId) {
		return new Promise(resolve => {
			setTimeout(() => {
				const otherDueProcesses = mockData.getDueProcesses().filter(a => a.id !== dueProcessId);

				mockData.saveDueProcesses([...otherDueProcesses]);
				resolve();
			}, config.environment.delay);
		});
	}

	static getJudges() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getFederalReport() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveActionResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveResponseReview() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static removeResponseReview() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static auditResponse() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveAuditComment() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveResolutionProcess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static auditResolutionProcess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveResolutionProcessAuditComment() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveCaseNote() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteCaseNote() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static downloadCaseInvestigationReport() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static exportCaseSearchResults() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}
}

const DrDueProcessApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrDueProcessApi : MockDrDueProcessApi;
export default DrDueProcessApi;