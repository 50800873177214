import React from "react";
import PropTypes from 'prop-types';
import DisplayField from "../../../components/common/inputs/DisplayField";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";

const DrDueProcessActionResponseReviewDisplay = ({
                                                      review
                                                  }) => {

    return (
        <>
            <DisplayField
                label="Date Documentation Received"
                name={`dateDocumentationReceived${review.id}`}
                showLabel
            >
                {outputStandardDateFormat(review.dateDocumentationReceived, false)}
            </DisplayField>
            <DisplayField
                label="Date Documentation Reviewed"
                name={`dateDocumentationReviewed${review.id}`}
                showLabel
            >
                {outputStandardDateFormat(review.dateDocumentationReviewed, false)}
            </DisplayField>
        </>
    );
};

DrDueProcessActionResponseReviewDisplay.propTypes = {
    review: PropTypes.object
};

export default DrDueProcessActionResponseReviewDisplay;