import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {generateSelectListFromArray, isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {
    DrFilterElements,
    DrFilterVisibilityRules,
    DrSearchCriteria
} from "../../components/common/searchCriterias/DrSearchCriteria";
import DrApi from "../drApi";
import {DrNavigation} from "../DrComponents/DrNavigation";
import {DrFilter} from "../DrComponents/DrFilter";
import stateComplaintsApi from "../StateComplaints/drStateComplaintsApi";
import dueProcessApi from "../DueProcesses/drDueProcessApi";
import mediationsApi from "../Mediations/drMediationsApi";
import * as drCaseResearchObjectFactory from "./drCaseResearchObjectFactory";
import {DisputeResolutionType} from "../drConstants";

const DrCaseResearchContainer = ({
                                 actions,
                                 allDistricts,
                                 allDistrictsLoaded,
                                 currentLocation,
                                 dateWindowsAnnualList: dateWindows,
                                 defaultDateWindowId,
                                 history,
                                 searchCriteria,
                             }) => {
    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if(reloadsData) {
            criteria.clearOptionalSearchItems();
            await initializeData(criteria);
        }
        else
            actions.saveSearchCriteria(criteria);
    };

    const handleExport = (caseSearchFilters) => {
        switch (searchCriteria[DrFilterElements.disputeResolutionType]) {
            case DisputeResolutionType.StateComplaints:
                actions.executeApi(stateComplaintsApi.exportCaseSearchResults, [drCaseResearchObjectFactory.createStateComplaintCaseResearchFilterObject(caseSearchFilters) ]);
                break;
            case DisputeResolutionType.DueProcess:
                actions.executeApi(dueProcessApi.exportCaseSearchResults, [drCaseResearchObjectFactory.createDueProcessCaseResearchFilterObject(caseSearchFilters)]);
                break;
            case DisputeResolutionType.Mediation:
                actions.executeApi(mediationsApi.exportCaseSearchResults, [drCaseResearchObjectFactory.createMediationCaseResearchFilterObject(caseSearchFilters)]);
                break;
        }

    }

    const getCaseResearchVisibilityRules = (type) => {
        switch (type) {
            case DisputeResolutionType.StateComplaints:
                return DrFilterVisibilityRules.DrStateComplaintCaseResearch;

            case DisputeResolutionType.DueProcess:
                return DrFilterVisibilityRules.DrDueProcessCaseResearch;

            case DisputeResolutionType.Mediation:
                return DrFilterVisibilityRules.DrMediationCaseResearch;
        }
    };

    const initializeData = async (criteria) => {
        const {dateWindowId, disputeResolutionType} = criteria;
        if (!dateWindowId || !disputeResolutionType) {
            return;
        }

        const teamMembers = await actions.executeApi(DrApi.getSummaryTeamMembers, [dateWindowId, disputeResolutionType]);
        criteria.teamMembers = teamMembers;

        if (disputeResolutionType === DisputeResolutionType.StateComplaints) {

            const issueCodes = await actions.executeApi(stateComplaintsApi.getIssueCodes);
            criteria.issueCodes = generateSelectListFromArray(issueCodes);

            const citations = await actions.executeApi(stateComplaintsApi.getCitations, [dateWindowId]);
            criteria.citations = generateSelectListFromArray(citations);
        }

        criteria.setVisibilityRules(getCaseResearchVisibilityRules(disputeResolutionType));

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if(!defaultDateWindowId || !allDistrictsLoaded) return;

        let criteria = {...searchCriteria};
        criteria.setDistrictList(allDistricts);
        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        initializeData(criteria);

    }, [defaultDateWindowId, allDistrictsLoaded]);

    useEffect(() => {
        actions.updatePageTitle(`Case Research`);
    }, []);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(getCaseResearchVisibilityRules(searchCriteria.disputeResolutionType)))
        return null;

    return (
        <>
            <DrFilter
                dateWindows={dateWindows}
                handleExport={handleExport}
                handleSearchFiltersChange={handleSearchFiltersChange}
                numberOfFilterColumns={2}
                searchCriteria={searchCriteria}
            />
            <DrNavigation currentLocation={currentLocation} history={history} />
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
        ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    let allDistricts = [...state.sharedData.institutionsWithStateAgencies].map(ins => {
        return {value: ins.id, text: ins.name};
    });

    const currentLocation = props.location.pathname;
    const allDistrictsLoaded = !isArrayNullOrEmpty(allDistricts);

    return {
        allDistricts,
        allDistrictsLoaded,
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrCaseResearchContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDistrictsLoaded: PropTypes.bool.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrCaseResearchContainer);

