import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function layoutReducer(state = initialState.layout, action) {
    switch(action.type){
        case actionTypes.UPDATE_PAGETITLE:
            return Object.assign({}, state, { pageTitle: action.pageTitle });
        case actionTypes.UPDATE_PAGE_SUBTITLE:
            return Object.assign({}, state, { subTitle: action.subTitle});
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
	    case actionTypes.LOGOUT_AJAX_SUCCESS: {
		    return initialState.layout;
	    }
        default:
            return state;
    }
}