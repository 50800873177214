import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as shardDataActions from "../../actions/sharedDataActions";
import SppAdminDocumentHierarchySelector from "../../components/sppAdmin/SppAdminDocumentHierarchySelector";
import SppAdminSectionElementsEdit from "../../components/sppAdmin/SppAdminSectionElementsEdit";
import { getParamsMultiple } from "../../components/layout/getParams";
import * as sppLocations from "../../constants/spp/sppLocations";

class SppAdminEditorPage extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {

	}

	render() {
		const params = getParamsMultiple(this.props.location.pathname,
										[
											sppLocations.ADMIN_EDITOR,
											sppLocations.ADMIN_EDITOR_DOCUMENT,
											sppLocations.ADMIN_EDITOR_PART,
                                            sppLocations.ADMIN_EDITOR_SECTION
										]);

        return (
			<div className={`sppAdminContainer`}>
				<SppAdminDocumentHierarchySelector
					isLoading={this.props.isLoading}
					params={params}
				/>
				<SppAdminSectionElementsEdit
					isLoading={this.props.isLoading}
					params={params}
					history={this.props.history}
				/>

			</div>
		);
	}
}

SppAdminEditorPage.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		shardDataActions,
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SppAdminEditorPage));