import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sppAdminActions from "../../actions/sppAdminActions";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import {getParams} from "../../components/layout/getParams";
import * as config from "../../constants/config";
import {SPP} from "../../constants/contexts";
import * as sppLocations from "../../constants/spp/sppLocations";
import {catchError} from "../../actions/actionUtility";
import OrderDragDrop from "../../components/common/OrderDragDrop";
import * as documentStatus from "../../constants/spp/documentStatus";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as sppActions from "../../actions/sppActions";
import * as sppUtilities from "../../components/spp/SppUtilities";

export class OrderSppElementsPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            params: {},
            componentsHierarchy: this.props.selectedDocument.componentsHierarchy
        };

        this.onDragEnd = this.onDragEnd.bind(this);
        this.returnToEditor = this.returnToEditor.bind(this);
    }
    componentDidMount() {
        const params = getParams(this.props.location.pathname, sppLocations.ADMIN_EDITOR_SECTION_ORDER);
        if (params !== null) {
            this.setState({params});

            this.loadSppDocument(params.documentId);
        }
    }

    loadSppDocument(documentId) {
        if (documentId && (
            !this.props.selectedDocument.id ||
            this.props.selectedDocument.id !== documentId)
        ) {
            this.props.actions.loadSppDocument(documentId);
            this.props.actions.loadSppDocumentElements(documentId);
        }
    }

    onDragEnd(result) {
        const elements = this.getTrimmedElementSets();
        let siblingElementId = elements[result.destination.index].id;
        let addBeforeSibling = result.destination.index - result.source.index < 0;

        this.props.actions.saveElementOrder(this.state.params.documentId, {
            MovedElementId: result.draggableId,
            SiblingElementId: siblingElementId,
            AddBeforeSibling: addBeforeSibling,
            ElementViewModel: {}
        })
        .then(() => {
            this.props.actions.loadSppDocument(this.state.params.documentId);
            this.props.actions.loadSppDocumentElements(this.state.params.documentId);
        })
        .catch(error => catchError(error));
    }

    returnToEditor() {
        this.props.history.push(sppLocations.ADMIN_EDITOR_SECTION.path
            .replace(sppLocations.DOCUMENT_ID, this.state.params.documentId)
            .replace(sppLocations.PART_ID, this.state.params.partId)
            .replace(sppLocations.SECTION_ID, this.state.params.sectionId));
    }

    getTrimmedElementSets() {
        if(!this.props.selectedDocument || !this.props.documentElements || this.props.documentElements.length === 0 || !this.state.params.sectionId)
            return [];

        const hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);
        return sppUtilities.getSectionElements(this.state.params.sectionId, hierarchyJson, this.props.documentElements, true);
    }

    render() {
        const isEditingMode = this.props.selectedDocument.status === documentStatus.EDITING;
        const elementSets = this.getTrimmedElementSets();

        return (
            <>
                <ButtonBar position={ButtonBarPositions.TOP}>
                    <Button label={"Back"} name={"btnReturn"} onClick={this.returnToEditor} buttonType={ButtonTypes.BACK} />
                </ButtonBar>
                {
                   !isEditingMode && !this.props.isLoading &&
                   <p>The policies and procedures document is currently not editable, which means the order cannot be changed.
                       If you believe you are seeing this message in error, try reloading the page or
                       {config.supportDetails(SPP)}.
                   </p>
                }
                {
                    isEditingMode && elementSets.length === 0 && !this.props.isLoading &&
                    <p>There are no elements to order. Please go back to the Elements page to add new elements for this
                        section.
                        If you believe you are seeing this message in error, try reloading the page or
                        {config.supportDetails(SPP)}.
                    </p>
                }
                {
                    isEditingMode && elementSets.length > 0 &&
                    <section>
                        <p>Drag the element to update its order. It automatically saves after you place the element.</p>
                        <OrderDragDrop elementIdName={"id"} elementSets={elementSets} containerId={"draggable"}
                                       onDragEnd={this.onDragEnd} elementContentName={"abbreviatedText"}/>
                    </section>
                }
            </>
        );
    }
}

OrderSppElementsPage.propTypes = {
    actions: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,

    selectedDocument: PropTypes.object,
    documentElements: PropTypes.array,
    isEditing: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        selectedDocument: state.spp.selectedDocument,
        documentElements: state.spp.documentElements,
        isEditing: state.spp.isInEditMode,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        sharedDataActions,
        sppActions,
        sppAdminActions
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSppElementsPage);