import React from 'react';
import PropTypes from "prop-types";
import FormTable from "../../../../components/common/FormTable";
import {NumberField, TextField, TextArea} from "../../../../components/common/inputs";
import {useFormInputObject} from "../../../../components/common/inputs/inputUtility";
import {createGmsSalary} from "../../gmsObjectFactory";

const GmsApplicationSalaryEditForm = ({
                                          formCallback,
                                          salary={},
                                      }) => {

    const siForm = useFormInputObject(createGmsSalary(salary), formCallback);

    return <>
        <FormTable>
            <TextField label={`Position Title (Including Function/ Object Code)`} showLabel {...siForm.positionTitle} />
            <NumberField isPositiveNumberOnly label={`Current Salary`} showLabel {...siForm.currentSalary} />
            <NumberField isPositiveNumberOnly label={`Salary Increase`} showLabel {...siForm.amountIncrease} />
            <NumberField isPositiveNumberOnly label={`New Salary`} showLabel {...siForm.newSalary} />
            <NumberField isPositiveNumberOnly label={`Percent Increase`} showLabel {...siForm.percentChange} />
            <TextArea label={"Is FTE being increased? If so, provide current approved FTE and the resulting FTE."}
                      showLabel {...siForm.fteChange} />
            <TextArea label={"Are benefits being increased?"} showLabel {...siForm.benefitsIncreased} />
            <TextArea label={"Why is the salary increase being requested?"} showLabel {...siForm.changeReason} />
            <TextArea
                label={"How does the position align/qualify for an increase according to the documentation provided?"}
                showLabel {...siForm.changeQualification} />
            <TextArea label={"What lines are the funds being pulled to cover the increases?"} showLabel
                      {...siForm.changeFunding} />
            <TextArea label={"How will the project ensure that services are not reduced/impacted?"} showLabel
                      {...siForm.serviceImpact} />
            <TextArea label={"Explain the plan to recoup funds."} showLabel {...siForm.recoupFunds} />
        </FormTable>
    </>;
};


GmsApplicationSalaryEditForm.propTypes = {
    formCallback: PropTypes.func.isRequired,
    salary: PropTypes.object,
};

export default GmsApplicationSalaryEditForm;