import LocalStorage from "../shared/LocalStorage";
import {outputStandardDateFormat, outputStandardTimeOnlyFormat} from "../shared/sharedDataUtilities";

export const createCaseNoteObject = ({
                                         activityDuration,
                                         author,
                                         authorId,
                                         caseNoteId,
                                         content,
                                         dateAdded,
                                         endTime,
                                         noteType,
                                         otherNoteType,
                                         startTime,
                                     } = {}) => ({
    activityDuration,
    author: author || `${LocalStorage.getLoggedInUser().FirstName} ${LocalStorage.getLoggedInUser().LastName}`,
    authorId,
    caseNoteId,
    content,
    dateAdded: dateAdded ? outputStandardDateFormat(dateAdded, false) : "",
    endTime: endTime ? outputStandardTimeOnlyFormat(endTime) : "",
    noteType,
    otherNoteType: otherNoteType || "",
    startTime: startTime ? outputStandardTimeOnlyFormat(startTime) : "",
    updatedStartTime: outputStandardTimeOnlyFormat(startTime, true),
    updatedEndTime: outputStandardTimeOnlyFormat(endTime, true)
});