import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import * as policyEvents from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class MonitoringPerformancePolicy extends DefaultPolicy {
	constructor() {
		super([contexts.MONITORING_PERFORMANCE]);
		this.updatePolicy();
	}

	updatePolicy() {

		//must have EDIT to edit action response
		this._policy[policyEvents.monitoring_performance.response.MODIFY] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
			};
		//must have EDIT to add/edit/delete actions
		this._policy[policyEvents.monitoring_performance.action.MODIFY] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
			};
		//must have EDIT to add/edit/delete indicators
		this._policy[policyEvents.monitoring_performance.indicator.MODIFY] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
			};

		//must have EDIT or CERTIFY to access response action bar
		this._policy[policyEvents.monitoring_performance.response.ACTION_BAR] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT, behaviors.CERTIFY], userBehaviors);
			};

		//must have CERTIFY to audit action response
		this._policy[policyEvents.monitoring_performance.response.AUDIT] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
			};
		//must have CERTIFY to submit action response
		this._policy[policyEvents.monitoring_performance.response.SUBMIT] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
			};
		//must have CERTIFY to submit assessment to district
		this._policy[policyEvents.monitoring_performance.assessment.SUBMIT] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
			};

		//must have MANAGE to add/edit/delete assessment details
		this._policy[policyEvents.monitoring_performance.assessment.MODIFY] =
			function (allowedContexts, userBehaviors) {
				return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
			};
	}
}

export {MonitoringPerformancePolicy, policyEvents};

