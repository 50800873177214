import * as types from './../constants/actionTypes';
import initialState from './initialState';

function actionTypeEndsInSuccess(type, postfixForSuccess) {
    const totalLengthOfPostFixSuccess = postfixForSuccess.length;
    return type.substring(type.length - totalLengthOfPostFixSuccess) === postfixForSuccess;
}

export function ajaxStatusReducer(state = initialState.ajaxCallsInProgress, action) {
    if (action.type === types.BEGIN_AJAX_CALL) {
        return state + 1;
    }
    else if (action.type === types.AJAX_CALL_ERROR ||
        actionTypeEndsInSuccess(action.type, '_AJAX_SUCCESS')) {
        return state > 0 ? state - 1 : 0;
    }

    return state;
}

export function dashboardAjaxStatusReducer(state = initialState.dashboardAjaxCallsInProgress, action) {
    if (action.type === types.BEGIN_AJAX_DASHBOARD_CALL) {
        return state + 1;
    }
    else if (action.type === types.AJAX_CALL_DASHBOARD_ERROR ||
        actionTypeEndsInSuccess(action.type, '_AJAX_DASHBOARD_SUCCESS')) {
        return state > 0 ? state - 1 : 0;
    }

    return state;
}