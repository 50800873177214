import React, {useState} from "react";
import PropTypes from 'prop-types';
import DrStateComplaintResponseIssue from "./DrStateComplaintResponseIssue";
import DrStateComplaintResponseAction from "./DrStateComplaintResponseAction";
import Collapsible from "../../../components/common/CollapsibleContainer";
import * as drObjectFactory from "../drStateComplaintObjectFactory";

const DrStateComplaintResponseView = ({
	                                      complaint,
	                                      handleAuditResponseAsync,
	                                      handleSaveAuditCommentAsync,
	                                      handleSaveResponseAsync,
	                                      handleSaveResponseReviewAsync
                                      }) => {

	const [editDeadlineId, setEditDeadlineId] = useState("");
	const isMany = complaint.issueModels.length > 1;

	const createIssueComponent = (rawIssue) => {
		const issue = drObjectFactory.createIssueObject(rawIssue);
		return (<DrStateComplaintResponseIssue
			issue={issue}
			editDeadlineId={editDeadlineId}
			handleAuditResponseAsync={handleAuditResponseAsync}
			handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
			handleSaveResponseAsync={handleSaveResponseAsync}
			handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
			setEditDeadlineId={setEditDeadlineId}
			showIssueNumber={!isMany}
		/>);
	};

	return (
		<>
			{
				isMany &&
				complaint.issueModels.map((iss, key) => {
					return <Collapsible key={key} trigger={`Issue ${iss.issueNumber}`}>
						{createIssueComponent(iss)}
					</Collapsible>;
				})
			}
			{
				complaint.issueModels.length === 1 &&
				createIssueComponent(complaint.issueModels[0])
			}
			{
				complaint.actionModels.map((act, key) => {
					const action = drObjectFactory.createActionObject(act);
					return <DrStateComplaintResponseAction
						key={key}
						action={action}
						editDeadlineId={editDeadlineId}
						handleAuditResponseAsync={handleAuditResponseAsync}
						handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
						handleSaveResponseAsync={handleSaveResponseAsync}
						handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
						setEditDeadlineId={setEditDeadlineId}
					/>;
				})
			}
		</>
	);
};

DrStateComplaintResponseView.propTypes = {
	complaint: PropTypes.object.isRequired,
	handleAuditResponseAsync: PropTypes.func,
	handleSaveAuditCommentAsync: PropTypes.func.isRequired,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired
};

export default DrStateComplaintResponseView;