import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function dashboardReducer(state = initialState.dashboard, action){
    switch(action.type){
        case actionTypes.LOAD_BPIE_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    bpieTiles: action.data
                });
        case actionTypes.LOAD_PTS_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    ptsTiles: action.data
                });
        case actionTypes.LOAD_GMS_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    gmsTiles: action.data
                });
        case actionTypes.LOAD_SPP_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    sppTiles: action.data
                });
        case actionTypes.LOAD_MONITORING_PERFORMANCE_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    monitoringPerformanceTiles: action.data
                });
        case actionTypes.LOAD_MONITORING_COMPLIANCE_TILES_AJAX_SUCCESS:
            return Object.assign({}, state, {monitoringComplianceTiles: action.data.tiles});
        case actionTypes.LOAD_EDITABLE_SURVEYS_BY_RESPONDENT_EMAIL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    editableSurveys: action.data
                });

        case actionTypes.LOAD_RESPONDENT_BY_EMAIL_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    loggedInRespondent: action.data
                });

        case actionTypes.LOAD_MEDIATION_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    mediationTiles: action.data
                });

        case actionTypes.LOAD_DUEPROCESS_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    dueProcessTiles: action.data
                });

        case actionTypes.LOAD_COMPLAINT_TILES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    complaintTiles: action.data
                });

        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
        case actionTypes.LOGOUT_AJAX_SUCCESS: {
		    return initialState.dashboard;
	    }
        default: {
            return state;
        }
    }
}
