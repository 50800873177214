import {deepCopyArray} from "./commonUtilities";

export function updateOrder(orderResults) {
    let listToSort = deepCopyArray(orderResults.listToSort);
    const [removed] = listToSort.splice(orderResults.startIndex, 1);
    listToSort.splice(orderResults.endIndex, 0, removed);

    return listToSort;
}

export const orderResults = (result, listToSort) => ({
    id: result.draggableId,
    startIndex: result.source.index,
    endIndex: result.destination.index,
    listToSort
});