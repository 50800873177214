import {cloneDeep} from "lodash/lang";
import {
    dateSelectOptionChoices,
    futureDateSelectFieldOptions,
    futureDateSelectFieldOptionsList
} from "../../../constants/inputConstants";
import {RecordsPerPage} from "../../../constants/searchOptions";
import {isDateValid, outputStandardDateFormat} from "../../shared/sharedDataUtilities";
import * as commonUtilities from "../commonUtilities";
import {getPropName, isArrayNullOrEmpty} from "../commonUtilities";
import {convertToDate} from "../dateUtilities";

export function searchCriteria(pageId = "",
                               defaultSortByColumn = "",
                               baseSortByColumn = "",
                               recordsPerPage = 10,
                               dateRange = "",
                               dateWindowId = "",
                               district = "",
                               endDate = "",
                               sourceParams = {},
                               startDate = "",
                               defaultStatus = "") {

    return {
        allDistricts: [],
        allFiscalAgents: [],
        allowAllDateWindows: false,
        BaseSortByColumn: baseSortByColumn,
        currentVisibilityRulesName: "",
        dateRange: dateRange,
        dateRangeOptionsToUse: dateSelectOptionChoices.future,
        dateWindowId,
        district,
        endDate,
        fiscalAgent: district,
        includeDefaultOptionDateRange: true,
        Page: 1,
        PageId: pageId,
        RecordsPerPage: recordsPerPage,
        SortByColumn: defaultSortByColumn,
        SortType: "",
        SortDescending: false,
        sourceParams,
        startDate,
        status: defaultStatus,
        statusSingle: "",
        statusSingleColumnName: "status",
        statusSingleOptions: [],
        teamMember: "",
        teamMembers: [],
        textSearch: "",
        textSearchLabel: "",
        visibilityRules: [],
        baseApplyFilters: BaseApplyFilters,
        baseAreRulesCurrent: BaseAreRulesCurrent,
        baseClearOptionalSearchItems: BaseClearOptionalSearchItems,
        baseSetVisibilityRules: BaseSetVisibilityRules,
        baseSort: BaseSort,
        First: first,
        GetCurrentPageRecords: getCurrentPageRecords,
        GetRecordsPerPage: getRecordsPerPage,
        isVisible: IsVisible,
        Last: last,
        Next: next,
        onValidPage: OnValidPage,
        outputDateRange: OutputDateRange,
        Previous: previous,
        ShouldReloadSearch,
        setDateRangeOptionsToUse: SetDateRangeOptionsToUse,
        setDistrictList: SetDistrictList,
        setFiscalAgentList: SetFiscalAgentList,
        UpdateSorting: updateSorting,
        UpdateSourceParams,
    };
}

export const SearchFilterElements = {
    clearButton: "clearButton",
    dateRange: "dateRange",
    dateRangeCustomOnly: "dateRangeCustomOnly",
    dateWindowId: "dateWindowId",
    disputeResolutionType: "disputeResolutionType",
    district: "district",
    districtHidden: "districtHidden",
    endDate: "endDate",
    fiscalAgent: "fiscalAgent",
    fiscalAgentHidden: "fiscalAgentHidden",
    includeNextInSection: "includeNextInSection",
    indicatorType: "indicatorType",
    programName: "programName",
    RecordsPerPage: "RecordsPerPage",
    startDate: "startDate",
    status: "status",
    statusSingle: "statusSingle",
    teamMember: "teamMember",
    teamMemberHidden: "teamMemberHidden",
    teamMembers: "teamMembers",
    textSearch: "textSearch"
};

function updateSorting(columnId, sortType = "", baseSortByColumnId) {
    if (this.SortByColumn === columnId) {
        this.SortDescending = !this.SortDescending;
    } else {
        this.SortDescending = false;
        this.SortByColumn = columnId;
    }

    this.SortType = sortType;
    this.BaseSortByColumn = baseSortByColumnId || this.BaseSortByColumn;
}

function first() {
    this.Page = 1;
}

function last(totalRecords) {
    const recordsPerPage = this.GetRecordsPerPage(totalRecords);
    this.Page = Math.ceil(totalRecords / recordsPerPage);
}

function next() {
    this.Page = this.Page + 1;
}

function previous() {
    this.Page--;
}

function OnValidPage(totalRecords) {
    const recordsPerPage = this.GetRecordsPerPage(totalRecords);
    return (this.Page - 1) * recordsPerPage <= totalRecords;
}

function getRecordsPerPage(allRecordsPerPageLength) {
    if (this.RecordsPerPage !== RecordsPerPage.All)
        return parseInt(this.RecordsPerPage, 10);
    else
        return allRecordsPerPageLength;
}

function getCurrentPageRecords(array) {
    const recordsPerPage = this.GetRecordsPerPage(array.length);
    return array.slice((this.Page - 1) * recordsPerPage, this.Page * recordsPerPage);
}

function SetDistrictList(allDistricts) {
    this.allDistricts = allDistricts.map(district => ({text: district.text, value: district.text, institutionType: district.institutionType}));
}

function SetFiscalAgentList(allFiscalAgents) {
    this.allFiscalAgents = allFiscalAgents.map(fiscalAgent => ({text: fiscalAgent.text, value: fiscalAgent.text, institutionType: fiscalAgent.institutionType}));
}


function SetDateRangeOptionsToUse(includeDefault, optionsToUse) {
    this.includeDefaultOptionDateRange = includeDefault;
    this.dateRangeOptionsToUse = optionsToUse;
}

function BaseSetVisibilityRules(rulesName, whichRules, baseSortByColumnId) {
    this.currentVisibilityRulesName = rulesName;
    this.visibilityRules = whichRules;

    if (this.BaseSortByColumn !== baseSortByColumnId) {
        this.BaseSortByColumn = baseSortByColumnId;
        this.SortDescending = false;
        this.SortByColumn = baseSortByColumnId;
    }
}

function BaseAreRulesCurrent(whichRules, visibilityRules) {
    const rulesName = getPropName(visibilityRules, whichRules);
    return this.currentVisibilityRulesName === rulesName;
}

function IsVisible(whichElement) {
    return this.visibilityRules.indexOf(whichElement) > -1;
}

function BaseClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    if (this.isVisible(SearchFilterElements.dateRange) || this.isVisible(SearchFilterElements.dateRangeCustomOnly) || clearOutAll) {
        this.dateRange = "";
        this.endDate = "";
        this.startDate = "";
    }

    if ((this.isVisible(SearchFilterElements.dateWindowId) || clearOutAll) && this.allowAllDateWindows) {
        this.dateWindowId = "";
    }

    if (this.isVisible(SearchFilterElements.district) || clearOutAll)
        this.district = "";

    if (this.isVisible(SearchFilterElements.fiscalAgent) || clearOutAll)
        this.fiscalAgent = "";

    if (this.isVisible(SearchFilterElements.status) || clearOutAll) {
        this.status = "";
    }

    if (this.isVisible(SearchFilterElements.statusSingle) || clearOutAll) {
        this.statusSingle = "";
    }

    if (this.isVisible(SearchFilterElements.textSearch) || clearOutAll) {
        this.textSearch = "";
    }

    if (this.isVisible(SearchFilterElements.teamMember) || clearOutAll) {
        this.teamMember = "";
        if (clearTeamMembersAsWell)
            this.teamMembers = [];
    }

    this.First();
}

function BaseApplyFilters(summaryData) {
    if (isArrayNullOrEmpty(summaryData))
        return [];

    let summary = cloneDeep(summaryData);

    if (this.status && this.isVisible(SearchFilterElements.status)) {
        summary = summary.filter(f => f[this.status] > 0);
    }

    if (this.statusSingle && this.isVisible(SearchFilterElements.statusSingle))
        summary = summary.filter(f => f[this.statusSingleColumnName].toString() === this.statusSingle.toString());

    if (this.district && (this.isVisible(SearchFilterElements.districtHidden) || this.isVisible(SearchFilterElements.district)))
        summary = summary.filter(f => f.districtName.toLowerCase().indexOf(this.district.toLowerCase()) > -1);

    if (this.fiscalAgent && (this.isVisible(SearchFilterElements.fiscalAgentHidden) || this.isVisible(SearchFilterElements.fiscalAgent)))
        summary = summary.filter(f => f.fiscalAgentName.toLowerCase() === this.fiscalAgent.toLowerCase());

    if (this.dateWindowId && this.isVisible(SearchFilterElements.dateWindowId))
        summary = summary.filter(f => !f.dateWindowId || f.dateWindowId === this.dateWindowId);

    if (this.startDate && (this.isVisible(SearchFilterElements.dateRange) || this.isVisible(SearchFilterElements.dateRangeCustomOnly))) {
        summary = summary.filter(f => f.tasks.find(t => convertToDate(t.dueDate) >= convertToDate(this.startDate)));
        for (let element of summary)
            element.tasks = element.tasks.filter(t => convertToDate(t.dueDate) >= convertToDate(this.startDate));
    }

    if (this.endDate && (this.isVisible(SearchFilterElements.dateRange) || this.isVisible(SearchFilterElements.dateRangeCustomOnly))) {
        const includeNextInSection = this.isVisible(SearchFilterElements.includeNextInSection);
        summary = summary.filter(f => f.tasks.find(t => convertToDate(t.dueDate) <= convertToDate(this.endDate) || (t.includeNextInSection && includeNextInSection)));
        for (let element of summary)
            element.tasks = element.tasks.filter(t => convertToDate(t.dueDate) <= convertToDate(this.endDate) || (t.includeNextInSection && includeNextInSection));
    }

    if (!this.startDate && !this.endDate && (this.isVisible(SearchFilterElements.dateRange) || this.isVisible(SearchFilterElements.dateRangeCustomOnly))) {
        for (let element of summary)
            element.tasks = element.tasks.filter(t => t.includeWithNoDateRange);
    }

    return summary;
}

function BaseSort(summary) {
    for (let element of summary) {
        if (element.tasks)
            element.tasks = element.tasks.sort(commonUtilities.dynamicSortDates("dueDate"));
    }

    summary = summary.sort(commonUtilities.dynamicSort(this.SortByColumn, this.SortDescending, this.BaseSortByColumn, this.SortType));

    return summary;
}

function OutputDateRange() {
    const selectedDateRange = futureDateSelectFieldOptionsList.find(f => f.value === this.dateRange);

    if (selectedDateRange.value !== futureDateSelectFieldOptions.Custom)
        return selectedDateRange.text;

    const startDate = isDateValid(this.startDate) ? outputStandardDateFormat(this.startDate, false) : "";
    const endDate = isDateValid(this.endDate) ? outputStandardDateFormat(this.endDate, false) : "";

    if (startDate && endDate)
        return `${startDate} to ${endDate}`;

    if (startDate)
        return `${startDate} and later`;

    return `${endDate} and earlier`;
}

const DataReloadElements = [
    SearchFilterElements.dateWindowId,
    SearchFilterElements.district,
    SearchFilterElements.disputeResolutionType,
    SearchFilterElements.fiscalAgent,
    SearchFilterElements.indicatorType,
    SearchFilterElements.programName
];

function ShouldReloadSearch(filterOption) {
    // existing logic, could be refactored
    if (filterOption === SearchFilterElements.disputeResolutionType || filterOption === SearchFilterElements.indicatorType)
        return true;

    const isReloadElement = DataReloadElements.includes(filterOption);

    const isInData = doesSummaryContainData(this);

    return isReloadElement && !isInData;
}

function UpdateSourceParams() {
    const result = {};

    for (let element of DataReloadElements) {
        result[element] = this[element];
    }

    return result;
}

const doesSummaryContainData = (source) => {
    const sourceDistrictFiscalAgent = source.district || source.fiscalAgent;
    const sourceParamsDistrictFiscalAgent = source.sourceParams.district || source.sourceParams.fiscalAgent;
    const noSourceParams = !source.sourceParams.dateWindowId && !sourceParamsDistrictFiscalAgent && !source.sourceParams.programName;
    if (noSourceParams)
        return false;

    const noChangeSourceParams = source.sourceParams.dateWindowId === source.dateWindowId
        && source.sourceParams.programName === source.programName
        && sourceParamsDistrictFiscalAgent === sourceDistrictFiscalAgent;
    if (noChangeSourceParams)
        return true;

    const sourceContainsAllDistricts = source.sourceParams.dateWindowId === source.dateWindowId
        && source.sourceParams.programName === source.programName
        && !sourceParamsDistrictFiscalAgent;
    if (sourceContainsAllDistricts)
        return true;

    const sourceContainsAllYears = !source.sourceParams.dateWindowId
        && source.sourceParams.programName === source.programName
        && sourceParamsDistrictFiscalAgent === sourceDistrictFiscalAgent;
    if (sourceContainsAllYears)
        return true;

    const sourceContainsAllPrograms = source.sourceParams.dateWindowId === source.dateWindowId
        && !source.sourceParams.programName
        && sourceParamsDistrictFiscalAgent === sourceDistrictFiscalAgent;
    if (sourceContainsAllPrograms)
        return true;

    return false;
};