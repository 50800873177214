import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function accountReducer(state = initialState.account, action) {
    switch(action.type){
        case actionTypes.LOGIN_AJAX_SUCCESS:
            return Object.assign({}, state, { isLoggedIn: action.isLoggedIn, redirectUrl: state.redirectUrl });
        case actionTypes.LOGIN_SET_RETURNURL:
            return Object.assign({}, state, { isLoggedIn: state.isLoggedIn, redirectUrl: action.redirectUrl });
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.account;
        default:
            return state;
    }
}