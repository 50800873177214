import React from "react";
import PropTypes from "prop-types";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import CheckBoxList from "../common/inputs/CheckBoxList";
import {
    convertFormInputObjectToObject,
    useFormInputObject
} from "../common/inputs/inputUtility";
import {createNotificationSettingObject, createNotificationSettingsFormObject} from "./notificationObjectFactory";

export const NotificationSettingsView = ({
                                      notificationSettings,
                                      saveNotificationSettings
                                  }) => {
    const notificationSettingsForm = useFormInputObject(createNotificationSettingsFormObject(notificationSettings));

    const handleClickSave = async (event) => {
        event.preventDefault();

        const form = convertFormInputObjectToObject(notificationSettingsForm);

        await saveNotificationSettings(convertToNotificationType(form.notificationSettings));
    };

    const convertToNotificationType = (selectedNotifications) => {
        let notifications = [];
        for(let setting of notificationSettings) {
            const isSelected = selectedNotifications.find(f => f === setting.notificationTypeDescription);
            notifications.push(createNotificationSettingObject(setting, !!isSelected));
        }

        return notifications;
    };

    const getNotificationSettingOptions = () => {
        return notificationSettings.map((setting) => {
            return setting.notificationTypeDescription;
        });
    };

    return (
        <div className={`notification__settings`}>
            <p>Check the box next to all the notifications you wish to continue to receive and then click &quot;Save&quot;.</p>
            <CheckBoxList
                {...notificationSettingsForm.notificationSettings}
                legend={`Current Notification Subscriptions`}
                legendClass={`notification__legend`}
                options={getNotificationSettingOptions()}
            />

            <ButtonBar position={ButtonBarPositions.BOTTOM} className={`notification__buttons`}>
                <Button name="btnSave"
                        label={`Save Settings`}
                        buttonType={ButtonTypes.SAVE}
                        onClick={handleClickSave}
                />
            </ButtonBar>
        </div>
    );
};

NotificationSettingsView.propTypes = {
    notificationSettings: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveNotificationSettings: PropTypes.func.isRequired
};

export default NotificationSettingsView;