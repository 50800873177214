import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from '../../actions/layoutActions';

export class Congratulations extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Congratulations!");
	}

	render() {
		return (
			<div className="progress-complete">
				<div className="graphic-star"/>
				<p>Thank you for successfully completing the {this.props.surveyInfo.title}!</p>
				<p>You may now close this window.</p>
			</div>
		);
	}
}

Congratulations.propTypes = {
	actions: PropTypes.object,
	surveyInfo: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		surveyInfo: state.survey.selectedSurvey
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(layoutActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Congratulations);