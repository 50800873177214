export const NOT_STARTED = "Not Started";
export const STARTED = "Started";
export const READY_TO_SUBMIT = "Ready to Submit";
export const SUBMITTED = "Submitted";
export const ACCEPTED = "Accepted";
export const NEEDS_REVISION = "Needs Revision";
export const NEEDS_REVIEW = "Needs Review";

export const APPROVED = "Approved";
export const PRE_APPROVED = "Pre-approved";

export const AllProgressValues = [NOT_STARTED, STARTED, READY_TO_SUBMIT, SUBMITTED, NEEDS_REVIEW, ACCEPTED, NEEDS_REVISION, PRE_APPROVED, APPROVED];