import React from 'react';
import PropTypes from 'prop-types';
import CheckBoxList from "./CheckBoxList";
import {convertToArray} from "../commonUtilities";
import {createFakeEvent} from "./inputUtility";

const Switch = ({
                    name,
                    onChange,
                    checked,
                    legend,
                    showLegend,
                    legendClass,
                    label,
                    showLabel,
                    labelClass,
                    inputClass,
                    wrapperClass,
                    error,
                    disabled,
                    align,
                    small
                }) => {
    const option = "Toggled";
    const options = convertToArray(option);
    const value = checked ? option : "";

    const handleChange = () => {
        const newChecked = !checked;
        const event = createFakeEvent(newChecked, name, {}, {checked: newChecked});
        onChange(event);
    };

    return (

        <CheckBoxList
            name={name}
            options={options}
            onChange={handleChange}
            label={label}
            showLabel={showLabel}
            legend={legend}
            showLegend={showLegend}
            legendClass={legendClass}
            fieldsetClass={"switch"}
            inputClass={inputClass + " switch-input"}
            labelClass={labelClass + " switch-paddle"}
            wrapperClass={wrapperClass}
            error={error}
            disabled={disabled}
            useInputTheme={false}
            value={value}
            align={align}
            small={small}
        />

    );
};

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    legend: PropTypes.string,
    legendClass: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool.isRequired,
    showLabel: PropTypes.bool,
    showLegend: PropTypes.bool,
    disabled: PropTypes.bool,
    inputClass: PropTypes.string,
    error: PropTypes.object,
    wrapperClass: PropTypes.string,
    align: PropTypes.string,
    small: PropTypes.string,
};

Switch.defaultProps = {
    showLabel: true,
    showLegend: true,
    checked: false,
    labelClass: "",
    inputClass: "",
    legendClass: "",
    onChange: () => {
    }
};

export default Switch;