import {mockResolveApiCall} from "../../api/ApiUtility";
import * as config from "../../constants/config";
import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../api/requests/RequestTypes';
import {API_ROOT} from "./ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsApi {
    static getGmsUsers() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/gmsUsers`,
                RequestTypes.GET));
    }

    static getDiscretionaryProject(dateWindowId, discretionaryProjectId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}`,
                RequestTypes.GET));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsApi {
    static getGmsUsers() {
        return mockResolveApiCall([]);
    }

    static getDiscretionaryProject() {
        return mockResolveApiCall({});
    }
}

const ptsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsApi : MockPtsApi;
export default ptsApi;