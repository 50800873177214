import {dr_api as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import { RequestTypes } from '../../../api/requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceNotificationSettingsApi {

    static getMcUserNotificationSettings(){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/reminders`,
            RequestTypes.GET));
    }

    static saveMcUserNotificationSettings(notifications) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}monitoring/compliances/reminders`,
                RequestTypes.PUT,
                JSON.stringify({notifications: notifications}),
                ApiSuccessMessages.reminders.SETTINGS_SAVED
            )
        );
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceNotificationSettingsApi {
    static getMcUserNotificationSettings(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveMcUserNotificationSettings() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceNotificationSettingsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceNotificationSettingsApi : MockMonitoringComplianceNotificationSettingsApi;
export default MonitoringComplianceNotificationSettingsApi;