import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../../components/authorization/policies/MonitoringCompliancePolicy";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isViewOnlyDueToStatus} from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import {SMALL_STICKY_HEIGHT} from "../../../constants/buttonStickyHeights";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";
import {monitoring_compliance} from "../../../constants/policyEvents";
import {StudentResultsGridForm} from "./StudentResultsGridForm";

export const StudentCorrectionsView = ({
                                           actions,
                                           assessmentId,
                                           dateWindowId,
                                           handleDataChanged,
                                           handleScroll,
                                           history,
                                           isLoading,
                                           onChangeStudentCorrectionResult,
                                           protocolId,
                                           studentProtocolCorrectiveResults,
                                           studentRecordId
                                       }) => {
    const onSaveClick = (event, shouldRedirect = false) => {
        event.preventDefault();
        handleDataChanged(false);

        actions.saveStudentCorrectiveResults(assessmentId,
            studentRecordId,
            studentProtocolCorrectiveResults.filter(f => f.studentRecordId === studentRecordId))
            .then(() => {
                actions.loadComplianceWithStudentCorrectiveResults(assessmentId)
                    .then(() => {
                        if (shouldRedirect)
                            returnToStudentSummary();
                    });
            });
    }

    const returnToStudentSummary = () => {
        history.push(ComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS_SUMMARY_FOR_STUDENT.path
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId)
            .replace(ComplianceLocations.ASSESSMENT_ID, assessmentId));
    }

    const areCorrectionsViewOnly = () => {
        let areCorrectionsSubmitted = true;
        for(let result of studentCorrectionResults) {
            if (!isViewOnlyDueToStatus(result.status))
                areCorrectionsSubmitted = false;
        }

        return areCorrectionsSubmitted;
    }

    useEffect(() => {
        if (MonitoringComplianceUtilities.isValidId(assessmentId)) {
            actions.loadComplianceWithStudentCorrectiveResults(assessmentId)
                .then((results) => {
                    let studentRecordName = "";
                    if(results) {
                        const studentCorrectionResults = results.filter(f => f.studentRecordId === studentRecordId);
                        if(studentCorrectionResults)
                            studentRecordName = ` - ${studentCorrectionResults[0].name}`;
                    }
                    actions.updatePageTitle(`Student Protocol Corrections${studentRecordName}`);
                })
        }
        else
            actions.updatePageTitle(`Student Protocol Corrections`);
    }, []);

    useEffect(() => {
        if(protocolId)
            handleScroll(protocolId);
    }, [protocolId]);

    if (!studentProtocolCorrectiveResults || studentProtocolCorrectiveResults.length === 0) return null;

    const studentCorrectionResults = studentProtocolCorrectiveResults.filter(f => f.studentRecordId === studentRecordId);

    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);
    const areViewOnly = areCorrectionsViewOnly() || !canEdit;

    return (
        <section className={"compliance__studentCorrectionResults"}>
            <StudentResultsGridForm
                correctionResults={studentCorrectionResults}
                onResponseChange={onChangeStudentCorrectionResult}
                isLoading={isLoading}
            />
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} stickyHeight={SMALL_STICKY_HEIGHT}>
                {
                    !areViewOnly &&
                    <>
                        <Button name={"btnSave"}
                                label={"Save"}
                                onClick={onSaveClick}
                                disabled={isLoading}
                                buttonType={ButtonTypes.SAVE}
                        />
                        <Button buttonType={ButtonTypes.BACK}
                                disabled={isLoading}
                                label={"Save and Return"}
                                name={"btnSaveAndReturn"}
                                onClick={(event) => onSaveClick(event, true)}
                        />
                    </>
                }
                {
                    areViewOnly &&
                    <Button buttonType={ButtonTypes.BACK}
                            label={"Return"}
                            name={"btnReturn"}
                            onClick={returnToStudentSummary}
                    />
                }
            </ButtonBar>
        </section>
    );
}

StudentCorrectionsView.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    dateWindowId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChangeStudentCorrectionResult: PropTypes.func.isRequired,
    protocolId: PropTypes.string,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    studentRecordId: PropTypes.string
};
