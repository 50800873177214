import {emptyGuid} from "../../../../constants/config";

export const createApplicationSettingsObject = ({
                                                    agencyCode = "",
                                                    applicationId = emptyGuid,
                                                    applicationType = "",
                                                    canEditApplicationSettings = true,
                                                    canEditFinalAllocationOnly = false,
                                                    canEditTargetedAllocationOnly = true,
                                                    finalAllocation = null,
                                                    finalFundsRollForward = null,
                                                    fiscalAgentId = "",
                                                    grantNumber = "",
                                                    projectNumber = "",
                                                    tapsCode = "",
                                                    targetedAllocation = 0
                                                } = {}) => ({
    agencyCode,
    applicationId,
    applicationType: applicationType || "",
    canEditApplicationSettings,
    canEditFinalAllocationOnly,
    canEditTargetedAllocationOnly,
    finalAllocation,
    finalFundsRollForward,
    fiscalAgentId: fiscalAgentId || "",
    grantNumber,
    projectNumber,
    tapsCode,
    targetedAllocation
});
