import PropTypes from "prop-types";
import React from "react";
import {savePDF} from "@progress/kendo-react-pdf";
import ButtonBar from "./buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "./buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";

const PdfContainer = ({
                          afterPrint = null,
                          beforePrint = null,
                          children,
                          fileName,
                          landscape = false,
                          otherButtons
                      }) => {
    const pdfRef = React.useRef(null);
    const a4DefaultWidth = 900;

    const handlePdfExport = (event) => {
        event.preventDefault();
        if (!beforePrint)
            printPdf();
        else
            beforePrint(a4DefaultWidth, printPdf);
    };

    const printPdf = () => {
        let element = pdfRef.current || document.body;
        savePDF(element, {
            paperSize: "A4",
            landscape: landscape,
            margin: "1cm",
            scale: 0.6,
            fileName: fileName.replace(/\s+/g, '_').replace(/\.+/g, ''),
            repeatHeaders: true,
            forcePageBreak: ".pdfPageBreak"
        }, afterPrint);
    }

    return <>
        <div ref={pdfRef}>
            {children}
        </div>
        <br/>
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`pdfReportNavigation`}>
            <Button buttonType={ButtonTypes.PRINT}
                    label={`Export to PDF`}
                    name={`btnExportToPdf`}
                    onClick={handlePdfExport}
            />
            {otherButtons}
        </ButtonBar>
    </>;
};

PdfContainer.propTypes = {
    afterPrint: PropTypes.func,
    beforePrint: PropTypes.func,
    children: PropTypes.any,
    fileName: PropTypes.string.isRequired,
    landscape: PropTypes.bool,
    otherButtons: PropTypes.any
};

export default PdfContainer;
