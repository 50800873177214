import React from 'react';
import InputAdapter, {sharedProps} from "./InputAdapter";

const TextField = (props) => {
	return <InputAdapter
		{...props}
		inputType={`text`}
	/>;
};

TextField.propTypes = {
	...sharedProps
};

export default TextField;