import SharedDataApi from "../api/SharedDataApi";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import * as indicatorTypes from "../constants/indicatorTypes";

export function leaveEditMode() {
    return {type: actionTypes.CANCEL_ADMIN_EDIT_MODE};
}

export function enterEditMode(editItemId = "") {
    return {type: actionTypes.ENTER_ADMIN_EDIT_MODE, data: editItemId};
}

//INSTITUTIONS
export function loadInstitutionsSuccess(institutions) {
    return {type: actionTypes.LOAD_INSTITUTIONS_AJAX_SUCCESS, institutions};
}

export function loadInstitutions() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getInstitutions()
            .then(institutions => {
                dispatch(loadInstitutionsSuccess(institutions));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function addInstitutionSuccess(institutionId) {
    return {type: actionTypes.ADD_INSTITUTION_AJAX_SUCCESS, data: institutionId};
}

export function addInstitution(institution) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return SharedDataApi.addInstitution(institution)
            .then(institutionId => {
                dispatch(addInstitutionSuccess(institutionId));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error); //needed
                },
            );
    };
}

export function updateInstitutionSuccess() {
    return {type: actionTypes.UPDATE_INSTITUTION_AJAX_SUCCESS};
}

export function updateInstitution(institution) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.updateInstitution(institution)
            .then(() => {
                dispatch(updateInstitutionSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw (error); //needed
                },
            );
    };
}

export function deleteInstitutionSuccess(institution) {
    return {type: actionTypes.DELETE_INSTITUTION_AJAX_SUCCESS, data: institution};
}

export function deleteInstitution(institution) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.deleteInstitution(institution)
            .then(() => {
                dispatch(deleteInstitutionSuccess(institution));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function createInstitution() {
    return {type: actionTypes.CREATE_INSTITUTION};
}

export function changeInstitution(institution) {
    return {type: actionTypes.CHANGE_INSTITUTION, data: institution};
}

//SPP INDICATORS
export function loadSppIndicatorsSuccess(indicators) {
    return {type: actionTypes.LOAD_SPP_INDICATORS_AJAX_SUCCESS, data: indicators};
}

export function loadSppIndicators() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getSppIndicators()
            .then((indicators) => {
                dispatch(loadSppIndicatorsSuccess(indicators));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

//PTS INDICATORS
export function loadPtsIndicatorsSuccess(indicators) {
    return {type: actionTypes.LOAD_PTS_INDICATORS_AJAX_SUCCESS, data: indicators};
}

export function loadPtsIndicators() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getPtsIndicators()
            .then((indicators) => {
                dispatch(loadPtsIndicatorsSuccess(indicators));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}
export function loadBeessIndicatorsSuccess(indicators) {
    return {type: actionTypes.LOAD_BEESS_INDICATORS_AJAX_SUCCESS, data: indicators};
}

export function loadBeessIndicators(protocolStandardsYearToCheck = 0) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getBeessIndicators(protocolStandardsYearToCheck)
            .then((indicators) => {
                dispatch(loadBeessIndicatorsSuccess(indicators));
                return indicators;
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function loadAllIndicatorsSuccess(indicators) {
    return {type: actionTypes.LOAD_INDICATORS_All_AJAX_SUCCESS, data: indicators};
}

export function loadAllIndicators() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getAllIndicators()
            .then((indicators) => {
                dispatch(loadAllIndicatorsSuccess(indicators));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function addSppIndicatorSuccess(id) {
    return {type: actionTypes.ADD_SPP_INDICATOR_AJAX_SUCCESS, data: id};
}

export function tryAddSppIndicator(indicator) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.addSppIndicator(indicator)
            .then((id) => {
                dispatch(addSppIndicatorSuccess(id));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw error;
                },
            );
    };
}

export function addPtsIndicatorSuccess(id) {
    return {type: actionTypes.ADD_SPP_INDICATOR_AJAX_SUCCESS, data: id};
}

export function tryAddPtsIndicator(indicator) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.addPtsIndicator(indicator)
            .then((id) => {
                dispatch(addPtsIndicatorSuccess(id));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw error;
                },
            );
    };
}

export function addBeessIndicatorSuccess(id) {
    return {type: actionTypes.ADD_BEESS_INDICATOR_AJAX_SUCCESS, data: id};
}

export function tryAddBeessIndicator(indicator) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.addBeessIndicator(indicator)
            .then((id) => {
                dispatch(addBeessIndicatorSuccess(id));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw error;
                },
            );
    };
}

export function updateSharedIndicatorSuccess() {
    return {type: actionTypes.UPDATE_INDICATOR_AJAX_SUCCESS};
}

export function tryUpdateSharedIndicator(indicator) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.updateIndicator(indicator)
            .then(() => {
                dispatch(updateSharedIndicatorSuccess());
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw error;
                },
            );
    };
}

export function deleteSppIndicatorSuccess(indicator) {
    return {type: actionTypes.DELETE_SPP_INDICATOR_AJAX_SUCCESS, data: indicator};
}

export function deletePtsIndicatorSuccess(indicator) {
    return {type: actionTypes.DELETE_PTS_INDICATOR_AJAX_SUCCESS, data: indicator};
}
export function deleteBeessIndicatorSuccess(indicator) {
    return {type: actionTypes.DELETE_BEESS_INDICATOR_AJAX_SUCCESS, data: indicator};
}

export function tryDeleteSharedIndicator(indicator, indicatorType) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.deleteIndicator(indicator)
            .then(() => {
                if (indicatorType === indicatorTypes.SPP)
                    dispatch(deleteSppIndicatorSuccess(indicator));
                else if (indicatorType === indicatorTypes.PTS)
                    dispatch(deletePtsIndicatorSuccess(indicator));
                else if (indicatorType === indicatorTypes.BEESS)
                    dispatch(deleteBeessIndicatorSuccess(indicator));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                    throw error;
                },
            );
    };
}

export function createSppIndicator() {
    return {type: actionTypes.CREATE_SPP_INDICATOR};
}

export function changeSppIndicator(indicator) {
    return {type: actionTypes.CHANGE_SPP_INDICATOR, data: indicator};
}

export function createBeessIndicator() {
    return {type: actionTypes.CREATE_BEESS_INDICATOR};
}

export function changeBeessIndicator(indicator) {
    return {type: actionTypes.CHANGE_BEESS_INDICATOR, data: indicator};
}

export function createPtsIndicator() {
    return {type: actionTypes.CREATE_PTS_INDICATOR};
}

export function changePtsIndicator(indicator) {
    return {type: actionTypes.CHANGE_PTS_INDICATOR, data: indicator};
}

//DATE WINDOWS
export function loadAllDateWindowsSuccess(data) {
    return {type: actionTypes.LOAD_DATE_WINDOWS_AJAX_SUCCESS, data};
}

export function loadAllDateWindows() {
    return (dispatch) => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getAllDateWindows()
            .then(dateWindows => {
                dispatch(loadAllDateWindowsSuccess(dateWindows));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function changeSelectedDateWindow(id) {
    return {type: actionTypes.CHANGE_SELECTED_DATE_WINDOW, data: id};
}

export function changeSelectedMultiDateWindow(id) {
    return {type: actionTypes.CHANGE_SELECTED_MULTI_DATE_WINDOW, data: id};
}


//SYSTEM MESSAGE
export function saveMessageDisplayed() {
    return {type: actionTypes.SAVE_SYSTEM_MESSAGE_DISPLAYED};
}

export function loadSystemMessageSuccess(message) {
    return {type: actionTypes.LOAD_SYSTEM_MESSAGE_AJAX_SUCCESS, data: message};
}

export function loadSystemMessage() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getSystemMessage()
            .then(message => {
                dispatch(loadSystemMessageSuccess(message));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function saveSystemMessageSuccess(message) {
    return {type: actionTypes.SAVE_SYSTEM_MESSAGE_AJAX_SUCCESS, data: message};
}

export function saveSystemMessage(message) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.saveSystemMessage(message)
            .then(() => {
                dispatch(saveSystemMessageSuccess(message));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function loadAppSettingsSuccess(settings) {
    return {type: actionTypes.LOAD_SYSTEM_CONFIGURATION_AJAX_SUCCESS, data: settings};
}

export function loadAppSettings() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return SharedDataApi.getAppSettings()
            .then((settings) => {
                dispatch(loadAppSettingsSuccess(settings));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                },
            );
    };
}

export function changeSystemMessage(message) {
    return {type: actionTypes.CHANGE_SYSTEM_MESSAGE, data: message};
}

export function restrictSystemAccessSuccess(restrictAccess) {
    return {type: actionTypes.RESTRICT_SYSTEM_ACCESS_AJAX_SUCCESS, data: restrictAccess};
}

export function restrictSystemAccess(restrictAccess) {
    return dispatch => {
        return SharedDataApi.saveSystemAccessRestriction(restrictAccess)
            .then(() => {
                dispatch(restrictSystemAccessSuccess(restrictAccess));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}