import React from "react";
import PropTypes from "prop-types";
import {DisplayField} from "../../../components/common/inputs";
import SectionHeader from "../../../components/common/SectionHeader";
import GridRow from "../../../components/common/GridRow";
import GridColumn from "../../../components/common/GridColumn";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {PtsProgramUserView} from "./PtsProgramUserView";
import {gridConstants} from "../../../constants/inputConstants";
import FormTable from "../../../components/common/FormTable";
import GridTable from "../../../components/common/GridTable";
import {PtsManageEndDateContainer} from "./PtsManageDateContainer";

export const PtsInformationView = ({
                                       handleDataChanged,
                                       isLoading,
                                       isStateUser,
                                       project,
                                       updateProgramUser,
                                       updateProjectEndDate,
                                   }) => {
    return <div className={`project-information`}>
        <SectionHeader>
            <h2>Project</h2>
        </SectionHeader>
        <FormTable>
            <DisplayField name={`projectName`} label={`Project Name`} showLabel>{project.programFiscalAgent.programName}</DisplayField>
            <DisplayField name={`fiscalAgent`} label={`Fiscal Agent`}
                          showLabel>{project.programFiscalAgent.fiscalAgentName}</DisplayField>
            <PtsManageEndDateContainer handleDataChange={handleDataChanged} isLoading={isLoading}
                                       handleDataChanged={handleDataChanged} isStateUser={isStateUser} project={project}
                                       updateProjectEndDate={updateProjectEndDate}/>
        </FormTable>
        <div className={`info-section`}>
            <h3>Budgets</h3>
            <GridTable>
                <thead>
                <tr className={`text-center`}>
                    <th>Project Number</th>
                    <th>TAPS Code</th>
                    <th>Budget</th>
                </tr>
                </thead>
                <tbody>
                {
                    project.budgets.map((b, key) => (
                        <tr key={key}>
                            <td className={`text-center`}>{b.budgetProjectNumber}</td>
                            <td className={`text-center`}>{b.tapsCode}</td>
                            <td className={`text-right`}>{b.finalAllocationDisplay}</td>
                        </tr>))
                }
                <tr>
                    <td colSpan={2} className={"text-right bold"}>Budget Total:</td>
                    <td className={"text-right bold"}>{project.budgetTotalDisplay}</td>
                </tr>
                </tbody>
            </GridTable>
        </div>

        {
            !isArrayNullOrEmpty(project.projectManagers) &&
            <>
                <SectionHeader>
                    <h2>Project Manager</h2>
                </SectionHeader>
                <GridRow large={gridConstants.column.THREE} medium={gridConstants.column.TWO}
                         small={gridConstants.column.ONE}>
                    {
                        project.projectManagers.map((manager, key) => (
                            <GridColumn key={key}>
                                <PtsProgramUserView isCompleted={project.isCompleted}
                                                    programUser={manager} sectionClass={`info-section`}
                                                    updateProgramUser={updateProgramUser}
                                                    handleDataChanged={handleDataChanged} isLoading={isLoading}/>
                            </GridColumn>)
                        )
                    }
                </GridRow>
            </>
        }

        <PtsProgramUserView programUser={project.doeProjectLiaison} sectionClass={`info-section`}
                            sectionTitle={`DOE Project Liaison`} handleDataChanged={handleDataChanged}
                            isLoading={isLoading}/>

        <PtsProgramUserView programUser={project.grantManagementLiaison} sectionClass={`info-section`}
                            sectionTitle={`Office of Grants Management Liaison`} handleDataChanged={handleDataChanged}
                            isLoading={isLoading}/>

    </div>;
};

PtsInformationView.propTypes = {
    handleDataChanged: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool.isRequired,
    project: PropTypes.object,
    updateProgramUser: PropTypes.func,
    updateProjectEndDate: PropTypes.func,
};