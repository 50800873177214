import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";
import * as sppUtilities from "../components/spp/SppUtilities";
import audit_outcomes from "../constants/auditOutcomes";

export default function sppReducer(state = initialState.spp, action) {
	switch(action.type){
		case actionTypes.LOAD_SPP_DISTRICT_TILES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					sppTiles: action.data
				}
			);
		case actionTypes.LOAD_SPP_INSTANCES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					sppInstances: [...action.instances]
				}
			);
		case actionTypes.LOAD_SPP_DOCUMENT_VIEW_MODEL_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedDocument: action.document
				}
			);
		case actionTypes.LOAD_SPP_DOCUMENT_ELEMENTS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					documentElements: [...action.elements]
				}
			);
		case actionTypes.SET_SPP_SECTION_HIERARCHY:
			return Object.assign(
				{},
				state,
				{
					sectionHierarchy: action.hierarchy
				}
			);
		case actionTypes.LOAD_SPP_RESPONSES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					documentResponses: action.responses,
					isInEditMode: false,
					commentId: ""
				}
			);
		case actionTypes.CLEAR_SPP_DATA:
			return Object.assign(
				{},
				state,
				{
					sppTiles: [],
					sppInstances: [],
					selectedPolicyCompliance: {},
					selectedDocument: {},
					documentElements: [],
					sectionHierarchy: {},
					documentResponses: []
				}
			);
		case actionTypes.LOAD_SPP_POLICY_COMPLIANCE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedPolicyCompliance: Object.assign({}, action.data)
				}
			);
		case actionTypes.PRE_APPROVE_SPP_INSTITUTION_DOCUMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedPolicyCompliance: Object.assign(
						{},
						state.selectedPolicyCompliance,
						{
							preApprovalFileId: action.data
						}
					)
				});
		case actionTypes.APPROVE_INSTITUTION_DOCUMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedPolicyCompliance: Object.assign(
						{},
						state.selectedPolicyCompliance,
						{
							approvalFileId: action.data
						}
					)
				});
		case actionTypes.LOAD_POLICY_COMPLIANCES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					policyCompliances: [...action.data]
				});
		case actionTypes.UNDO_PREAPPROVE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedPolicyCompliance: Object.assign(
						{},
						state.selectedPolicyCompliance,
						{
							preApprovalFileId: null
						}
					)
				});
		case actionTypes.UNDO_APPROVE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedPolicyCompliance: Object.assign(
						{},
						state.selectedPolicyCompliance,
						{
							approvalFileId: null
						}
					)
				});
		case actionTypes.CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
                    selectedPolicyCompliance: Object.assign(
                        {},
                        state.selectedPolicyCompliance,
                        {
                            stateCertificationFileId: action.data
                        }
                    )
				});
		case actionTypes.UNDO_CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
	                selectedPolicyCompliance: Object.assign(
						{},
						state.selectedPolicyCompliance,
						{
                            stateCertificationFileId: null
						}
					)
                });
		case actionTypes.CHANGE_POLICY_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.elementId !== action.data.elementId || r.institutionId !== action.data.institutionId) return r;

						return action.data;
					})
				});
		case actionTypes.SUBMIT_SPP_ACCEPT_ELEMENT_RESPONSE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

                        const audits = [...r.audits];
                        audits.unshift(sppUtilities.newAudit(action.data.policyResponseId, audit_outcomes.ACCEPTED.enum, audit_outcomes.ACCEPTED.value, action.data.auditId));
                        r.audits = audits;

						return r;
					})
				}
			);
		case actionTypes.SUBMIT_SPP_ELEMENT_RESPONSE_REVISION_REQUIRED_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

                        const audits = [...r.audits];
                        audits.unshift(sppUtilities.newAudit(action.data.policyResponseId, audit_outcomes.NEEDS_REVISION.enum, audit_outcomes.NEEDS_REVISION.value, action.data.auditId));
                        r.audits = audits;

						return r;
					})
				}
			);
		case actionTypes.SUBMIT_SPP_REQUEST_AUDIT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

                        const audits = [...r.audits];
                        audits.unshift(sppUtilities.newAudit(action.data.policyResponseId, audit_outcomes.SUBMITTED.enum, audit_outcomes.SUBMITTED.value, action.data.auditId));
                        r.audits = audits;

						return r;
					})
				}
			);
		case actionTypes.CREATE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					isInEditMode: true,
					commentId: "",
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

						r.audits = [...r.audits].map(a => {
							a = Object.assign({}, a);

							if (a.auditId !== action.data.auditId) return a;

							const comments = [...a.comments];
							comments.unshift(sppUtilities.newComment(action.data.auditId));
							a.comments = comments;

							return a;
						});

						return r;
					})
				}
			);
		case actionTypes.EDIT_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					isInEditMode: true,
					commentId: action.data,
				}
			);
		case actionTypes.CHANGE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

						r.audits = [...r.audits].map(a => {
							a = Object.assign({}, a);

							if (a.auditId !== action.data.comment.auditId) return a;

							a.comments = [...a.comments].map(c => {
								c = Object.assign({}, c);

								if(c.auditCommentId !== action.data.comment.auditCommentId) return c;

								return action.data.comment;
							});

							return a;
						});

						return r;
					})
				}
			);
		case actionTypes.UPDATE_AUDIT_COMMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isInEditMode: false,
					commentId: "",
				}
			);
		case actionTypes.ADD_AUDIT_COMMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isInEditMode: false,
					commentId: "",
					documentResponses: [...state.documentResponses].map(r => {
						r = Object.assign({}, r);

						if (r.policyResponseId !== action.data.policyResponseId) return r;

						r.audits = [...r.audits].map(a => {
							a = Object.assign({}, a);

							if (a.auditId !== action.data.comment.auditId) return a;

							a.comments = [...a.comments].map(c => {
								c = Object.assign({}, c);

								if(c.auditCommentId !== "") return c;

								return {...action.data.comment, auditCommentId: action.data.auditCommentId};
							});

							return a;
						});

						return r;
					})
				}
			);
		case actionTypes.CREATE_POLICY_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					documentResponses: [...state.documentResponses, action.data]
				}
			);
		case actionTypes.LOAD_DISTRICT_STATUSES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					districtStatuses: action.data
				}
			);
		case actionTypes.EDIT_SPP_ELEMENT:
			return Object.assign(
				{},
				state,
				{
					editItemId: action.data,
					isInEditMode: true
				}
			);
		case actionTypes.CANCEL_EDIT_SPP_ELEMENT:
			return Object.assign(
				{},
				state,
				{
					editItemId: "",
					isInEditMode: false
				}
			);
		case actionTypes.SAVE_SPP_ELEMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					editItemId: "",
					isInEditMode: false,
					documentElements: [...state.documentElements].map(r => {
						r = Object.assign({}, r);

						if (action.data.id !== r.id) return r;

						return action.data;
					})
				}
			);
		case actionTypes.IMPERSONATING_AJAX_SUCCESS:
		case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
		case actionTypes.LOGOUT_AJAX_SUCCESS:
			return initialState.spp;
		default:
			return state;
	}
}