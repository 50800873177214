import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import GridTable from "../../components/common/GridTable";
import ButtonIcon from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as strategicPlanActions from "../../actions/strategicPlanActions";
import ResourceForm from "../../components/strategicPlanAdmin/ResourceForm";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import {NotifyUser} from "../../components/common/NotifyUser";

class ManageResourcesPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onCreate = this.onCreate.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.onSave = this.onSave.bind(this);
		this.isResourceValid = this.isResourceValid.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadActionResources();
	}

	componentWillUnmount() {
		this.props.actions.clearPlanData();
	}

	onCreate(event) {
		event.preventDefault();
		this.props.actions.createResource();
	}

	onEdit(resourceId) {
		this.props.actions.editResource(resourceId);
	}

	onChange(event, resourceId) {
		event.preventDefault();
		const changedResource = Object.assign({}, this.props.resources.filter(re => re.id === resourceId)[0]);
		changedResource[event.target.name] = event.target.value;
		this.props.actions.changeResource(changedResource);
	}

	onCancel(event) {
		event.preventDefault();
		this.props.actions.loadActionResources();
	}

	onSave(event, resourceId) {
		event.preventDefault();
		const savedResource = Object.assign({}, this.props.resources.filter(re => re.id === resourceId)[0]);

		if (this.isResourceValid(savedResource)) {
			if (resourceId === "")
				this.props.actions.addResource(savedResource);

			else
				this.props.actions.updateResource(savedResource);

		}
	}

	onDelete(deletedResource) {
		if (confirm("Are you sure you want to delete the resource?")) {
			this.props.actions.deleteResource(deletedResource);
		}
	}

	isResourceValid(resource) {
		let isValid = true;

		if (resource.text === "") {
			isValid = false;
			NotifyUser.Warning("Resource text is required.");
		}

		return isValid;
	}

	render() {
		if (this.props.resources.length === 0)
			return null;

		return (
			<section>
				<GridTable>
					<thead>
					<tr>
						<th>Resource</th>
						<th className="text-center">Actions</th>
					</tr>
					</thead>
					<tbody>
					{
						this.props.resources.map(re => {
							if (this.props.isEditing && this.props.editItemId === re.id)
								return (<ResourceForm key={re.id}
								                      resource={re}
								                      isLoading={this.props.isLoading}
								                      onSave={(event) => this.onSave(event, re.id)}
								                      onCancel={this.onCancel}
								                      onChange={(event) => this.onChange(event, re.id)}/>);

							return (

								<tr key={re.id} id={re.id}>
									<td>{re.text}</td>
									<td className="button-cell">
										{
											<ButtonBar position={ButtonBarPositions.TABLE}>
												<ButtonIcon name={"edit|" + re.id}
												            label={""}
												            onClick={() => this.onEdit(re.id)}
												            buttonType={ButtonTypes.EDIT}
												            disabled={this.props.isLoading || this.props.isEditing}/>
												<ButtonIcon name={"delete|" + re.id}
												            label={""}
												            onClick={() => this.onDelete(re)}
												            buttonType={ButtonTypes.DELETE}
												            disabled={this.props.isLoading || this.props.isEditing}/>
											</ButtonBar>
										}
									</td>
								</tr>);
						})
					}
					</tbody>
					<tfoot>
					{
						<tr key={"new"}>
							<td colSpan={2}>
								<TileContainer rowTilesLarge={"1"}
								               rowTilesMedium={"1"}
								>
									<Tile body={"Add Resource"}
									      onClick={!this.props.isEditing ? this.onCreate : null}
									      isNew
									      isMedium
									/>
								</TileContainer>
							</td>
						</tr>
					}
					</tfoot>
				</GridTable>
			</section>
		);
	}
}

ManageResourcesPage.propTypes = {
	actions: PropTypes.object.isRequired,
	resources: PropTypes.array.isRequired,
	editItemId: PropTypes.string.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		resources: state.strategicPlan.resources,
		editItemId: state.strategicPlan.editItemId,
		isEditing: state.strategicPlan.isEditing,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		strategicPlanActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageResourcesPage);