import React from "react";
import PropTypes from 'prop-types';
import {getEndOfCurrentDay} from "../../../components/common/dateUtilities";
import {McFilterVisibilityRules, MonitoringComplianceDistrictSearchCriteria} from "../../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import SummaryView from "../../../components/summary/SummaryView";
import {MONITORING_COMPLIANCE} from "../../../constants/contexts";
import {futureDateSelectFieldOptions} from "../../../constants/inputConstants";

export const McSummaryDueNow = ({
                                    mcSummarySearchCriteria,
                                    handleClickGeneral,
                                    setMcSummarySearchCriteria,
                                    summary
                                }) => {
    const buildMcSearchCriteria = () => {
        return new MonitoringComplianceDistrictSearchCriteria(mcSummarySearchCriteria.dateWindowId, futureDateSelectFieldOptions.Custom, "", getEndOfCurrentDay(), "title", "title");
    };

    return <SummaryView
        buildSearchCriteria={buildMcSearchCriteria}
        containerId={"due-now"}
        context={MONITORING_COMPLIANCE}
        handleClickGeneral={handleClickGeneral}
        isDueNow
        setSummarySearchCriteria={setMcSummarySearchCriteria}
        summary={summary}
        summarySearchCriteria={mcSummarySearchCriteria}
        summaryViewTitle={"Due Now"}
        visibilityRules={McFilterVisibilityRules.McSummaryDueNow}
    />;
};

McSummaryDueNow.propTypes = {
    mcSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    setMcSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array
};

export default McSummaryDueNow;