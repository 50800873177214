import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {setPageTitle} from "../../../../components/common/commonUtilities";
import BpieReportApi from "../bpieReportApi";
import ChartContainer from "../../../../components/common/ChartContainer";
import PdfContainer from "../../../../components/common/PdfContainer";
import AccountUtility from "../../../../api/AccountUtility";
import BpieSchoolFilterContainer from "../BpieSchoolFilterContainer";

let printArray = [];

const SchoolStatusTrendChartContainer = ({
                                             actions,
                                             districtId,
                                             reportTitle
                                         }) => {
    const [reportData, setReportData] = useState(null);
    const [increments, setIncrements] = useState(null);
    const [schools, setSchools] = useState(null);
    const [school, setSchool] = useState("");
    const [schoolName, setSchoolName] = useState("");

    const validDistrictId = AccountUtility.getValidatedBpieDistrictId(districtId);

    const handleUpdateReportData = (result) => {
        setReportData(result);
        splitIndicatorsIntoEqualParts(result);
    };

    const loadReportData = (event) => {
        event.preventDefault();
        setSchool(event.target.value);
    };

    const labelContent = (e) => {
        if (e.value === 1) return "NY";
        if (e.value === 2) return "PB";
        if (e.value === 3) return "PA";
        if (e.value === 4) return "F";
        return "";
    };

    const getPrint = (getFunc) => {
        printArray.push(getFunc);
    }

    const usePrint = (width, callback) => {
        printArray.forEach(print => print(width));
        if (callback)
            callback();
    };

    const splitIndicatorsIntoEqualParts = (data) => {
        if (!data)
            return;
        const indicatorsCount = data.indicators.length + 1;
        const increment = indicatorsCount / data.schoolBpies.length;

        let values = [];
        let counter = 0;
        while (counter < indicatorsCount) {
            const lower = counter;
            counter = counter + increment;
            const upper = indicatorsCount < (counter + increment) ? indicatorsCount : counter;
            values.push({lower: lower, upper: upper});
        }
        setIncrements(values);
    };

    useEffect(() => {
        setPageTitle(reportTitle);
    }, []);

    useEffect(() => {
        if (!school)
            return;
        const label = schools.find(s => s.value === school).text;
        const values = school.split(';');
        actions.executeApi(BpieReportApi.getSchoolSummaryData, [values[0], values[1]])
            .then((result) => {
                setSchoolName(label);
                handleUpdateReportData(result);
            });
    }, [school]);

    return <div className={"theme-bpie"}>
        <BpieSchoolFilterContainer
            actions={actions}
            districtId={validDistrictId}
            school={school}
            schools={schools}
            setSchools={setSchools}
            loadReportData={loadReportData}
        />
        {
            reportData && increments &&
            <PdfContainer
                afterPrint={usePrint}
                beforePrint={usePrint}
                fileName={`${schoolName}_${reportTitle}`}
            >
                <>
                    <div className="report-header">
                        <h1>{reportTitle}</h1>
                        <h2>District: {reportData.districtName}</h2>
                        <h2>School: {schoolName}</h2>
                    </div>
                    {
                        increments.map((increment, key) => {
                            return (<ChartContainer
                                key={`bpieChart-${key}`}
                                chartRefValue={key}
                                categoryLabels={reportData.indicators.filter(i => increment.lower <= i.indicatorNumber && i.indicatorNumber < increment.upper).map(a => a.indicatorNumber)}
                                chartClass={"pdfPageBreak"}
                                chartTitle={reportTitle}
                                data={reportData.schoolBpies.map((bpie) => {
                                    return (
                                        {
                                            "name": bpie.dateCompletedOutput,
                                            "data": bpie.assessment.responses.filter(i => increment.lower <= i.indicatorNumber && i.indicatorNumber < increment.upper).map((response) => {
                                                return response.statusLevel;
                                            })
                                        });
                                })}
                                getPrint={getPrint}
                                isMultiSeries={true}
                                labelContent={labelContent}
                                chartHeight={400 * reportData.schoolBpies.length}
                            />);
                        })
                    }
                </>
            </PdfContainer>
        }
    </div>;
};


SchoolStatusTrendChartContainer.propTypes = {
    actions: PropTypes.object,
    districtId: PropTypes.string,
    reportTitle: PropTypes.string
};

export default SchoolStatusTrendChartContainer;