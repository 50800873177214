import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import OrderDragDrop from "../../../components/common/OrderDragDrop";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";

const DrAdminIntakeOrderRequirements = ({
                                            handleSaveOrder,
                                            intakeRequirements,
                                            setOrderVersionId
                                        }) => {
    const onCancelClick = (event) => {
        event.preventDefault();
        setOrderVersionId(null);
    };

    const onDragEnd = (result) => {
        if (!result.destination)
            return;

        handleSaveOrder(result);
    };

    return (
        <>
            <ButtonBar position={ButtonBarPositions.STICKY}>
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={`Return to Intake Requirements`}
                    name={`btnReturnIntakeRequirements`}
                    onClick={onCancelClick}
                    showLabel
                />
            </ButtonBar>

            <p>Drag the element to update its order. It automatically saves after you place the element.</p>

            <OrderDragDrop
                containerId={"draggable"}
                elementContentName={"requirementDescription"}
                elementIdName={"requirementId"}
                elementSets={intakeRequirements}
                onDragEnd={onDragEnd}
            />
        </>
    );
};

DrAdminIntakeOrderRequirements.propTypes = {
    handleSaveOrder: PropTypes.func.isRequired,
    intakeRequirements: PropTypes.array.isRequired,
    setOrderVersionId: PropTypes.func.isRequired
};

export default DrAdminIntakeOrderRequirements;