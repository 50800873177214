import React from 'react';
import PropTypes from 'prop-types';
import InputLayout from "./InputLayout";

class TextArea extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    onChange(event) {
        this.props.onChange(event);
    }

    onInput(event) {
        this.onChange = null;
        this.props.onChange(event);
    }

    render() {
        let {
            align = "",
            disabled,
            elementEditBar,
            error,
            helpText,
            inputClass = "",
            label,
            labelClass = "",
            maxLength,
            name,
            placeholder,
            showLabel,
            value,
            wrapperClass = "",
        } = this.props;

        if (!showLabel) {
            labelClass += " is-visuallyhidden";
        }

        if (error && error.length > 0) {
            wrapperClass += " has-error";
        }

        wrapperClass += (disabled ? " is-disabled" : "");

        return (
            <InputLayout
                name={name}
                label={label}
                showLabel={showLabel}
                error={error}
                helpText={helpText}
                wrapperClass={wrapperClass}
                labelClass={labelClass}
                inputClass={inputClass}
                disabled={disabled}
                elementEditBar={elementEditBar}
                align={align}
            >
					<textarea
                        name={name}
                        id={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={this.onChange}
                        onInput={this.onInput}
                        readOnly={disabled}
                        maxLength={maxLength}
                    />
            </InputLayout>
        );
    }
}

TextArea.propTypes = {
    align: PropTypes.string,
    disabled: PropTypes.bool,
    elementEditBar: PropTypes.object,
    error: PropTypes.string,
    helpText: PropTypes.string,
    inputClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool,
    value: PropTypes.string,
    wrapperClass: PropTypes.string,
};

export default TextArea;