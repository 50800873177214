import React from 'react';
import PropTypes from 'prop-types';
import IndicatorProtocolForm from "./IndicatorProtocolForm";

const IndicatorProtocolDistrictForm = ({
                                           protocol,
                                           response,
                                           responseText,
                                           subQuestionResponses,
                                           onResponseChange,
                                           onSubQuestionResponseChange,
                                           showMissingResponses
                                       }) => {
    return (
        <IndicatorProtocolForm
            onResponseChange={onResponseChange}
            protocol={protocol}
            response={response}
            responseText={responseText}
            subQuestionResponses={subQuestionResponses}
            showState={false}
            options={protocol.districtResponseOptions}
            onSubQuestionResponseChange={onSubQuestionResponseChange}
            showMissingResponses={showMissingResponses}
        />

    );
};

IndicatorProtocolDistrictForm.propTypes = {
    protocol: PropTypes.object.isRequired,
    response: PropTypes.string.isRequired,
    responseText: PropTypes.string.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    subQuestionResponses: PropTypes.array.isRequired,
    showMissingResponses: PropTypes.bool.isRequired
};

export default IndicatorProtocolDistrictForm;