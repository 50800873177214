import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as fileActions from "../../actions/fileActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import FileUploadButton from "../../components/common/inputs/FileUploadButton";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {emptyGuid} from "../../constants/config";

export class FileUploadPage extends React.PureComponent {
	static clearFileSelection() {
		const fuSystemAdmin = document.getElementById("fuSystemAdmin");
		fuSystemAdmin.value = "";
	}

	constructor(props) {
		super(props);

		this.handleFileUpload = this.handleFileUpload.bind(this);
	}

	componentDidMount() {
	}

	handleFileUpload(event) {
		event.preventDefault();

		const file = [...event.target.files];

		if (confirm("Are you sure you want to upload an orphaned file? Unless you are going to dig in the database then you don't.")) {
			const fileId = emptyGuid;
			this.props.actions.uploadFile(file, fileId);
		}

		FileUploadPage.clearFileSelection();
	}

	render() {
		if(!this.props.canEdit) return null;

		return (
			<div>
				<p>This page is used to get a file into the database so that missing files can be replaced. Unless you
					are planning on making changes to the database then you should not use this tool.</p>
				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<FileUploadButton name={"fuSystemAdmin"}
					                  label={"Upload orphan file"}
					                  onChange={this.handleFileUpload}
					                  disabled={this.props.isLoading}/>
				</ButtonBar>
			</div>
		);
	}
}

FileUploadPage.propTypes = {
	actions: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
	return {
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		fileActions,
		sharedDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUploadPage);