import React from "react";
import PropTypes from "prop-types";
import {formatMetricDateWindow} from "./strategicPlanUtilities";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import SelectField from "../common/inputs/SelectField";
import TextareaField from "../common/inputs/TextArea";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import * as policyEvents from "../../constants/policyEvents";
import Allow from "../authorization/Allow";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {gridConstants} from "../../constants/inputConstants";

const IndicatorMetricsForm = ({
	                              strategyIndicator,
	                              planStartYear,
	                              metricYearSelectListItems,
	                              onChange,
	                              isLoading,
	                              onCancel,
	                              onSave,
	                              onCreateMetric
                              }) => {
	const itemId = strategyIndicator.baseline === null ? "" : strategyIndicator.baseline.id;

	const targetMetMetricExists = strategyIndicator.metrics.length > 0 &&
		strategyIndicator.metrics.filter(m => m.startYear === planStartYear).length > 0;

	let targetMetMetric = strategyIndicator.metrics.filter(m => m.startYear === planStartYear)[0];

	return (

		<section className={"strategy__metrics strategy__metrics--editing "} id={itemId}>
			<GridRow>
				<GridColumn large={"6"}>
					<SelectField name={"baseline.startYear"}
					             showLabel
					             label={"Baseline Year"}
					             onChange={(event, strategyId, indicatorId) => onChange(event, strategyId, indicatorId, itemId)}
					             options={metricYearSelectListItems}
					             value={strategyIndicator.baseline.startYear}/>
				</GridColumn>
				<GridColumn large={"6"}>
					<TextareaField name={"baseline.baseline"}
					               showLabel
					               label={"Baseline Value"}
					               onChange={(event, strategyId, indicatorId) => onChange(event, strategyId, indicatorId, itemId)}
					               value={strategyIndicator.baseline.baseline}/>
				</GridColumn>
			</GridRow>

			<GridRow small={gridConstants.column.ONE} large={gridConstants.column.FIVE} id={"target" + itemId}>
				{
					strategyIndicator.baseline.startYear !== "" &&
					strategyIndicator.metrics.length > 0 &&
					strategyIndicator.metrics.map((metric, i) => {
						return (
							<GridColumn key={metric.id} small={"1"} id={metric.id}>
								<label htmlFor={"metric.target|" + metric.id} className={i > 0 ? "is-invisible" : ""}>Target</label>

								<TextareaField name={"metric.target|" + metric.id}
								               label={formatMetricDateWindow(metric.startYear)}
								               showLabel={true}
								               value={metric.target}
								               onChange={(event, strategyId, indicatorId) => onChange(event, strategyId, indicatorId, metric.id)}
								/>

								<label htmlFor={"metric.actual|" + metric.id} className={i > 0  ? "is-invisible" : ""}>Actual</label>

								<TextareaField name={"metric.actual|" + metric.id}
								               label={formatMetricDateWindow(metric.startYear)}
								               showLabel={true}
								               value={metric.actual}
								               onChange={(event, strategyId, indicatorId) => onChange(event, strategyId, indicatorId, metric.id)}
								/>
							</GridColumn>
						);
					})
				}
			</GridRow>

			{
				strategyIndicator.baseline.startYear !== "" &&
				strategyIndicator.metrics.length < 5 &&
				<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_METRICS}>
					<TileContainer rowTilesLarge={"1"}>
						<Tile isNew={true} body={"Add Data"} onClick={onCreateMetric}/>
					</TileContainer>
				</Allow>
			}

			{
				targetMetMetricExists &&
				<GridRow>
					<GridColumn>
						<SelectField name="hasMetTarget"
						             showLabel
						             label="Target Met"
						             onChange={onChange}
						             options={[{text: "Yes", value: "true"}, {text: "No", value: "false"}]}
						             value={targetMetMetric.hasMetTarget.toString()}
						/>
					</GridColumn>
				</GridRow>
			}

			{
				targetMetMetricExists &&
				<GridRow>
					<GridColumn>
						<TextareaField name={"targetVerificationComment"}
						               showLabel
						               label={"Comments"} onChange={onChange}
						               value={targetMetMetric.targetVerificationComment}
						/>
					</GridColumn>
				</GridRow>
			}

			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name={"save"}
				        label={"Save"}
				        buttonType={ButtonTypes.SAVE}
				        onClick={onSave}
				        disabled={isLoading}/>
				<Button name={"cancel"}
				        label={"Cancel"}
				        buttonType={ButtonTypes.CANCEL}
				        onClick={onCancel}
				        disabled={isLoading}/>
			</ButtonBar>
		</section>
	);
};

IndicatorMetricsForm.propTypes = {
	strategyIndicator: PropTypes.object.isRequired,
	metricYearSelectListItems: PropTypes.array.isRequired,
	onChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCreateMetric: PropTypes.func.isRequired,
	planStartYear: PropTypes.string.isRequired
};

export default IndicatorMetricsForm;