import React, { useState } from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import { createListItem } from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import CheckBoxList from "../../../../components/common/inputs/CheckBoxList";
import { NotifyUser } from "../../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {emptyGuid} from "../../../../constants/config";
import { gridConstants } from "../../../../constants/inputConstants";

export const GmsApplicationAmendmentSetupView = ({
    application,
    handleReturnToSummary,
    handleAmendmentApplication
}) => {
    const [selectedSections, setSelectedSections] = useState([]);

    const getMainSections = () => {
        let mainSections = [createListItem(emptyGuid, 'Budget')];
        for (let index = 0; index < application.elements.length; index++) {
            var elements = application.elements[index].elements.filter(x => x.canBeAmended);
            const currentSectionElements = elements.map((e) => createListItem(e.elementId, e.elementText));
            mainSections = [...mainSections, ...currentSectionElements];
        }

        return mainSections;
    };

    const mainSections = getMainSections();

    const handleClickReturnToSummary = (event) => {
        event.preventDefault();
        handleReturnToSummary();
    };

    const handleClickAmendmentApplication = (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            const isBudgetAmended = selectedSections.includes(emptyGuid);
            handleAmendmentApplication(selectedSections, isBudgetAmended);
        }
    };

    const validateForm = () => {
        const isValid = selectedSections.length > 0;
        if (!isValid)
            NotifyUser.Warning("Please select at least one section.");

        return isValid;
    };

    const handleChangeSelectedSections = (event) => {
        setSelectedSections(event.target.value);
    };

    return (
        <>
            <FormTable>
                <GridRow large={gridConstants.column.TWO}>
                    <GridColumn columnClass={`padding-right`}>
                        <CheckBoxList
                            onChange={handleChangeSelectedSections}
                            legend={`Sections`}
                            name={`cbSelectedSections`}
                            options={mainSections}
                            showSelectAll
                            value={selectedSections}
                        />
                        <br />
                    </GridColumn>
                </GridRow>
            </FormTable>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.SUBMIT}
                    label={"Create Amendment"}
                    name="btnAmendment"
                    onClick={handleClickAmendmentApplication}
                />
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={"Return"}
                    name="btnReturn"
                    onClick={handleClickReturnToSummary}
                />
            </ButtonBar>
        </>
    );
};

GmsApplicationAmendmentSetupView.propTypes = {
    application: PropTypes.object,
    handleReturnToSummary: PropTypes.func.isRequired,
    handleAmendmentApplication: PropTypes.func.isRequired,
};