import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import * as SharedDataActions from "../../actions/sharedDataActions";
import * as MonitoringPerformanceActions from "../../actions/monitoringPerformanceActions";
import * as LayoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {isArrayNullOrEmpty, dynamicSort, generateSelectListFromArray} from "../../components/common/commonUtilities";
import {bindActionCreators} from "redux";
import {NotifyUser} from "../../components/common/NotifyUser";
import GridTable from "../../components/common/GridTable";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import SelectField from "../../components/common/inputs/SelectField";
import TextField from "../../components/common/inputs/TextField";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as monitoringPerformanceLocations from "../../constants/monitoringPerformance/monitoringPerformanceLocations";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Allow from "../../components/authorization/Allow";
import {
	MonitoringPerformancePolicy,
	policyEvents
} from "../../components/authorization/policies/MonitoringPerformancePolicy";
import {
	convertBooleanToYesNoText,
	filterCurrentAnnualDateWindows,
	generateDateWindowSelectListItems,
	generateIndicatorSelectListItems,
	isValueInList,
	monitoring_performance, outputStandardDateFormat
} from "../../components/shared/sharedDataUtilities";
import {MonitoringPerformanceDistrictSearchCriteria} from "../../components/common/searchCriterias/MonitoringPerformanceDistrictSearchCriteria";
import {createFakeEvent} from "../../components/common/inputs/inputUtility";
import {getParams} from "../../components/layout/getParams";
import {gridConstants} from "../../constants/inputConstants";

export class MonitoringPerformanceLandingPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loadData: false
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onDistrictClick = this.onDistrictClick.bind(this);
		this.onNewAssessmentClick = this.onNewAssessmentClick.bind(this);
		this.onOrderChange = this.onOrderChange.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Monitoring for Performance - Districts");

		this.props.actions.loadDistrictStatuses();

		if (isArrayNullOrEmpty(this.props.dateWindows))
			this.props.actions.loadAllDateWindows();

		if (isArrayNullOrEmpty(this.props.sppIndicators))
			this.props.actions.loadSppIndicators();

		this.props.actions.createSearchCriteria(new MonitoringPerformanceDistrictSearchCriteria());
	}

	componentDidUpdate()
	{
		if (!this.state.loadData &&
			!isArrayNullOrEmpty(this.props.dateWindows) &&
			!isArrayNullOrEmpty(this.props.sppIndicators) &&
			this.props.searchCriteria !== undefined)
		{
			let selectedDateWindowId = this.props.searchCriteria.dateWindowId
				|| this.props.selectedDateWindowId
				|| this.props.dateWindows[0].value;

			this.loadBeessIndicators(selectedDateWindowId);

			this.onFilterChange(createFakeEvent(selectedDateWindowId, "dateWindowId"));

			this.setState({loadData: true});
		}
	}

	onDistrictClick(event, assessmentId) {
		event.preventDefault();

		this.props.history.push(monitoringPerformanceLocations.ASSESSMENT_DOE.path
			.replace(monitoringPerformanceLocations.ASSESSMENT_ID, assessmentId));
	}

	onNewAssessmentClick(event, dateWindowId) {
		event.preventDefault();

		if (!dateWindowId) {
			return NotifyUser.Warning("Date is invalid.");
		}

		this.props.history.push(monitoringPerformanceLocations.ASSESSMENT_NEW_DOE.path
			.replace(monitoringPerformanceLocations.DATE_WINDOW_ID, dateWindowId));
	}

	loadBeessIndicators(selectedDateWindowId, forceReload = false) {
		if (isArrayNullOrEmpty(this.props.beessIndicators) || forceReload) {
			const dateWindowStartYear = this.props.dateWindows.find(f => f.value === selectedDateWindowId).text.substring(0, 4);
			this.props.actions.loadBeessIndicators(dateWindowStartYear);
		}
	}

	onFilterChange(event) {
		event.preventDefault();

		let criteria = Object.assign({}, this.props.searchCriteria);

		const selectedDateWindowId = criteria.dateWindowId;
		criteria[event.target.id] = event.target.value;

		this.props.actions.saveSearchCriteria(criteria);

		if(selectedDateWindowId !== criteria.dateWindowId) {
			this.loadBeessIndicators(criteria.dateWindowId, true);
			this.props.history.push(monitoringPerformanceLocations.LANDING_DOE_WITH_DATEWINDOW.path
				.replace(monitoringPerformanceLocations.DATE_WINDOW_ID, criteria.dateWindowId));
		}
	}

	onOrderChange(event) {
		event.preventDefault();

		let criteria = Object.assign({}, this.props.searchCriteria);
		criteria.UpdateSorting(event.target.dataset.id);

		this.props.actions.saveSearchCriteria(criteria);
	}

	filterDistrictList() {
		let criteria = this.props.searchCriteria;

		let filteredDistricts = this.props.districtStatuses.filter(ins => ins.dateWindowId === criteria.dateWindowId);

		if (criteria.district !== "" && !isArrayNullOrEmpty(this.props.districtStatuses)) {
			const institutionSearchStrings = criteria.district.split(",").map(d => d.trim());

			filteredDistricts = filteredDistricts.filter(ins => institutionSearchStrings.some(function (search) {
				return (ins.districtName.toLowerCase().indexOf(search.toLowerCase()) >= 0);
			}));
		}

		if(criteria.needsReview !== "") {
			if(criteria.needsReview === "Yes")
				filteredDistricts = filteredDistricts.filter(p => p.totalSubmittedAndReviewable > 0);
			else
				filteredDistricts = filteredDistricts.filter(p => p.totalSubmittedAndReviewable === 0);
		}

		return filteredDistricts;
	}

	render() {
		if (!this.state.loadData)
			return null;

		let filteredDistricts = this.filterDistrictList();
		let criteria = this.props.searchCriteria;
		filteredDistricts = filteredDistricts.sort(dynamicSort(criteria.SortByColumn, criteria.SortDescending, criteria.BaseSortByColumn));

		const indicators = !isArrayNullOrEmpty(this.props.allIndicators) ? this.props.allIndicators : [];

		return (
			<div>
				<GridRow rowClass="filterBar" medium={gridConstants.column.THREE}>
					<GridColumn>
						<SelectField
							name="dateWindowId"
							showLabel
							label="Date Window"
							onChange={this.onFilterChange}
							options={this.props.dateWindows}
							value={criteria.dateWindowId}
							includeDefaultOption={false}/>
					</GridColumn>
					<GridColumn>
						<SelectField
							name="needsReview"
							showLabel={true}
							label="Needs Review"
							onChange={this.onFilterChange}
							options={generateSelectListFromArray(["Yes", "No"])}
							value={criteria.needsReview} />
					</GridColumn>
					<GridColumn>
						<TextField
							name="district"
							showLabel={true}
							label="District"
							onChange={this.onFilterChange}
							value={criteria.district}/>
					</GridColumn>
					<Allow policy={MonitoringPerformancePolicy}
					       policyEvent={policyEvents.monitoring_performance.assessment.MODIFY}>
						<ButtonBar position={""}>
							<Button name={"btnCreateAssessment"}
							        label={"New Assessment"}
							        onClick={(event) => this.onNewAssessmentClick(event, criteria.dateWindowId)}
							        buttonType={ButtonTypes.ADD}
							        disabled={this.props.isLoading}
							/>
						</ButtonBar>
					</Allow>
				</GridRow>

				{
					!this.props.isLoading &&
					filteredDistricts.length === 0 &&
					<p>There are no monitoring for performance districts associated with the selected academic year or that meet the search criteria.</p>
				}
				{
					!this.props.isLoading &&
					filteredDistricts.length > 0 &&
					<GridTable>
						<thead>
						<tr>
							<th onClick={this.onOrderChange} data-id="districtName" className="is-clickable">District</th>
							<th onClick={this.onOrderChange} data-id="visitStartDate" className="is-clickable">Date</th>
							<th>Target Areas</th>
							<th onClick={this.onOrderChange} data-id="isVisibleToDistrict" className="is-clickable">District Enabled</th>
							<th onClick={this.onOrderChange} data-id="totalSubmittedAndReviewable" className="is-clickable">Needs Review</th>
						</tr>
						</thead>
						<tbody>
						{
							filteredDistricts.map(district =>
								<tr key={district.monitoringAssessmentId}
								    onClick={(event) => this.onDistrictClick(event, district.monitoringAssessmentId)}
								    className="is-clickable">
									<td>{district.districtName}</td>
									<td className={"text-center"}>{outputStandardDateFormat(district.visitStartDate, false)}</td>
									<td>{district.indicatorAssessments.map(function (ind) {
											const indicator = indicators.filter(i => i.value === ind.indicatorId)[0];
											return indicator ? indicator.text : "";
										}).join(", ")}</td>
									<td className={"text-center"}>{convertBooleanToYesNoText(district.isVisibleToDistrict)}</td>
									<td className={"text-center"}>{district.totalSubmittedAndReviewable}</td>
								</tr>
							)
						}
						</tbody>
					</GridTable>
				}

			</div>
		);
	}
}

MonitoringPerformanceLandingPage.propTypes = {
	actions: PropTypes.object.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	sppIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	beessIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	allIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedDateWindowId: PropTypes.string.isRequired,
	districtStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
	isLoading: PropTypes.bool,
	history: PropTypes.object.isRequired,
	searchCriteria: PropTypes.object
};

function mapStateToProps(state, ownProps) {
	const params = getParams(ownProps.location.pathname, monitoringPerformanceLocations.LANDING_DOE_WITH_DATEWINDOW);

	let dateWindows = [...state.sharedData.dateWindows];
	let statuses = [...state.monitoringPerformance.districtStatuses].sort(dynamicSort(monitoring_performance.DISTRICT));
	let selectedDateWindowId = (params && params.dateWindowId)? params.dateWindowId : state.sharedData.selectedDateWindowId;

	if (dateWindows.length > 0) {
		const annualWindows = filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindows = generateDateWindowSelectListItems(annualWindows);

		if (!isValueInList(dateWindows, selectedDateWindowId))
		 	selectedDateWindowId = dateWindows[0].value;
	}

	let sppIndicators = generateIndicatorSelectListItems([...state.sharedData.sppIndicators], true);

	let beessIndicators = generateIndicatorSelectListItems([...state.sharedData.beessIndicators]);

	const allIndicators = sppIndicators.concat(beessIndicators);

	return {
		dateWindows: dateWindows,
		selectedDateWindowId: selectedDateWindowId,
		beessIndicators,
		sppIndicators,
		allIndicators,
		districtStatuses: statuses,
		searchCriteria: state.searchCriteria.MonitoringPerformanceDistrictSearchCriteria
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, LayoutActions, SharedDataActions, MonitoringPerformanceActions, searchCriteriaActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringPerformanceLandingPage);