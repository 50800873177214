import * as config from '../constants/config';
import LocalStorage from "../components/shared/LocalStorage";
import * as ApiUtility from "./ApiUtility";
import AjaxRequestOptions from "../api/requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';
import {getMockAccounts} from "./AccountMockData";

class ServerDashboardApi {
    static getPtsTiles(dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Pts/dates/${dateWindowId}/tiles`,
            RequestTypes.GET
        ));
    }

    static getGmsTiles(dateWindowId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}Gms/dates/${dateWindowId}/applications/statuses/tiles`,
            RequestTypes.GET
        ));
    }

    static getBpieTiles(dateWindowId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}bpies/dates/${dateWindowId}/statuses/tiles`,
            RequestTypes.GET
        ));
    }

    static getSppTiles(routeUrl, dateWindowId) {
        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        let apiUrl = `dates/${dateWindowId}/statuses/tiles`;
        let data = { routeUrl };

        if (institutionId !== null) {
            data.institutionId = institutionId;
            apiUrl += "/institutions/" + institutionId;
        }

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}spp/${apiUrl}`,
            RequestTypes.POST,
            data,
            null,
            `application/x-www-form-urlencoded`
        ));
    }

    static getMonitoringPerformanceTiles(routeUrl, dateWindowId) {
        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        let apiUrl = `dates/${dateWindowId}/statuses/tiles`;
        let data = { routeUrl };

        if (institutionId !== null) {
            data.institutionId = institutionId;
            apiUrl += "/institutions/" + institutionId;
        }

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/performance/assessments/${apiUrl}`,
            RequestTypes.POST,
            data,
            null,
            `application/x-www-form-urlencoded`
        ));
    }

    static getMonitoringComplianceTiles(dateWindowId) {
        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        let apiUrl = "statuses/tiles";

        if (institutionId !== null)
            apiUrl += `/institutions/${institutionId}`;

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/${dateWindowId}/assessments/${apiUrl}`,
            RequestTypes.GET
        ));
    }

    static getEditableSurveysByRespondentEmail(email) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "survey/GetEditableSurveysByRespondentEmail",
            RequestTypes.GET,
            { email: email }
        ));
    }

    static getRespondentByEmail(email) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "survey/GetRespondentByEmail",
            RequestTypes.GET,
            { email: email }
        ));
    }

    static getMediationTiles(dateWindowId) {
        let apiUrl = `dispute-resolution/mediations/dates/${dateWindowId}/tiles`;

        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        if (institutionId !== null)
            apiUrl += `/institutions/${institutionId}`;

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}${apiUrl}`,
            RequestTypes.GET
        ));
    }

    static getDueProcessTiles(dateWindowId) {
        let apiUrl = `dispute-resolution/dueProcesses/dates/${dateWindowId}/tiles`;

        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        if (institutionId !== null)
            apiUrl += `/institutions/${institutionId}`;

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}${apiUrl}`,
            RequestTypes.GET
        ));
    }

    static getComplaintTiles(dateWindowId) {
        let apiUrl = `dispute-resolution/complaints/dates/${dateWindowId}/tiles`;

        const institutionId = LocalStorage.getLoggedInUser().InstitutionId;
        if (institutionId !== null)
            apiUrl += `/institutions/${institutionId}`;

        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}${apiUrl}`,
            RequestTypes.GET
        ));
    }
}

//Server should only return a single SP&P document
const doeBpieTile = [{
    header: "School BPIEs",
    body: "12",
    footer: "2022-2023",
    url: "/Bpie/Institutions"
}];

const doeSppTile = [{
    header: "Needs Review",
    body: "12",
    footer: "2016-2017",
    url: "/Spp/Institution/Date/1"
}];

const doePtsTile = [{
    header: "Projects",
    body: "12",
    footer: "2024-2025",
    url: "/Pts/Summary"
}];

const doeGmsTile = [{
    header: "Applications",
    body: "12",
    footer: "2022-2023",
    url: "/Applications/Institutions"
}];

const doeMonitoringPerformanceTile = [{
    header: "Needs Review",
    body: "12",
    footer: "2016-2017",
    url: "/MonitoringPerformance/Institutions/"
}];

const doeMonitoringComplianceTile = [{
    header: "Needs Review",
    body: "12",
    footer: "2016-2017",
    url: "/MonitoringCompliance/Institutions/"
}];

const mediationTiles = [{
    header: "Open",
    body: "12",
    footer: "2016-2017",
    url: "/DisputeResolution"
}];

const dueProcessTiles = [{
    header: "Open",
    body: "15",
    footer: "2017-2018",
    url: "/DisputeResolution"
}];

const complaintTiles = [{
    header: "Open",
    body: "5",
    footer: "2017-2018",
    url: "/DisputeResolution"
}];

const surveys = [
    {
        instanceId: "cccccccc-cccc-cccc-cccc-cccccccccccc",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 1",
        progress: "In Progress",
        endDate: "7/21/17",
        canEditResponse: true
    },
    {
        instanceId: "bbbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 2",
        progress: "Not Started",
        endDate: "8/1/17",
        canEditResponse: true
    },
    {
        instanceId: "dddddddd-dddd-dddd-dddd-dddddddddddd",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 3",
        progress: "Not Started",
        endDate: "9/11/17",
        canEditResponse: true
    }
];

class MockDashboardApi {
    static getBpieTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doeBpieTile);
            }, config.environment.delay);
        });
    }

    static getPtsTiles(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doePtsTile);
            }, config.environment.delay);
        });
    }

    static getGmsTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doeGmsTile);
            }, config.environment.delay);
        });
    }

    static getSppTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doeSppTile);
            }, config.environment.delay);
        });
    }

    static getMonitoringPerformanceTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doeMonitoringPerformanceTile);
            }, config.environment.delay);
        });
    }

    static getMonitoringComplianceTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(doeMonitoringComplianceTile);
            }, config.environment.delay);
        });
    }

    static getEditableSurveysByRespondentEmail() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(surveys);
            }, config.environment.delay);
        });
    }

    static getRespondentByEmail(email) {
        return new Promise(resolve => {
            let user = getMockAccounts().filter(r => r.email === email)[0];

            setTimeout(() => {
                resolve(user);
            }, config.environment.delay);
        });
    }

    static getMediationTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(mediationTiles);
            }, config.environment.delay);
        });
    }

    static getDueProcessTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(dueProcessTiles);
            }, config.environment.delay);
        });
    }

    static getComplaintTiles() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(complaintTiles);
            }, config.environment.delay);
        });
    }
}

const DashboardApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDashboardApi : MockDashboardApi;
export default DashboardApi;