import React from 'react';
import PropTypes from 'prop-types';
import ButtonBar from "../common/buttons/ButtonBar";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import Button from "../common/buttons/Button";
import DateTimePickerField from "../common/inputs/DateTimePickerField";
import FileUploadButton from "../common/inputs/FileUploadButton";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import FileViewDelete from "../common/inputs/FileViewDelete";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";

const ActionResponseForm = ({
								response,
								onSave,
								onCancel,
								onChange,
								onDocumentUpload,
								isLoading,
								onFileView,
								onFileDelete
}) => {
	return (
		<section className={"performance__response performance__response--editing"} id={"actionResponse--editing"}>
			<h4>Response</h4>
			<GridRow>
				<GridColumn>
					<TextAreaEditor
						name={"resolutionDescription"}
						label={"Action Taken Description"}
						showLabel={true}
						value={!response.resolutionDescription ? "" : response.resolutionDescription}
						disabled={isLoading}
						onChange={onChange}
					/>
				</GridColumn>
			</GridRow>
			<GridRow>
				<GridColumn>
					<DateTimePickerField
						name={"dateResolutionCompleted"}
						label={"Date Completed"}
						showLabel={true}
						onChange={onChange}
						value={response.dateResolutionCompleted}
						disabled={isLoading}
					/>
				</GridColumn>
			</GridRow>
			<GridRow rowClass={`performance__response__upload`}>
				<GridColumn>
					<div><strong>Attachments:</strong></div>
					<FileUploadButton
						name={"actionResponseDocument"}
						label={"Upload Document"}
						onChange={onDocumentUpload}
						disabled={isLoading}
					/>
					{
						response.fileItems &&
						response.fileItems.map((fi) => {
							return (
								<FileViewDelete
									key={fi.fileItemId}
									name={fi.fileItemId}
									label={fi.name}
									onView={onFileView}
									onDelete={onFileDelete}
									disabled={isLoading}
								/>);
							})
					}
				</GridColumn>
			</GridRow>
			<GridRow>
				<GridColumn>
					<ButtonBar position={ButtonBarPositions.BOTTOM}>
						<Button name={"save"}
								label={"Save"}
								onClick={onSave}
								buttonType={ButtonTypes.SAVE}
								diabled={isLoading} />
						<Button name={"cancel"}
								label={"Cancel"}
								onClick={onCancel}
								buttonType={ButtonTypes.CANCEL}
								diabled={isLoading} />
					</ButtonBar>
				</GridColumn>
			</GridRow>
		</section>
	);
};

ActionResponseForm.propTypes = {
	response: PropTypes.object,
	onSave: PropTypes.func,
	onCancel: PropTypes.func,
	onChange: PropTypes.func,
	onDocumentUpload: PropTypes.func,
	isLoading: PropTypes.bool,
	onFileView: PropTypes.func,
	onFileDelete: PropTypes.func
};

export default ActionResponseForm;