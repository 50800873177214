import Location from "../components/shared/Location";

const ROOT = "/Reports";
export const REPORT_ID = ":reportId";
export const DATE_WINDOW_ID = ":dateWindowId";
export const DISTRICT_ID = ":districtId";
export const CONTEXT = ":context";
export const REPORT_AREA = ":reportArea";
export const REPORT_TITLE = ":reportTitle";

export const REPORTS = Location(() => `${ROOT}`);

export const REPORTS_PRINT = Location((
    reportId = REPORT_ID,
    context = CONTEXT,
    reportArea = REPORT_AREA,
    dateWindowId = DATE_WINDOW_ID,
    districtId = DISTRICT_ID,
    title = REPORT_TITLE) => `${ROOT}/${reportId}/Contexts/${context}/Areas/${reportArea}/Dates/${dateWindowId}/Districts/${districtId}/ReportTitle/${title}`);