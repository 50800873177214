import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../../constants/AuditConstants";
import {emptyGuid} from "../../../../constants/config";
import * as ComplianceLocations from "../../../../constants/monitoringCompliance/monitoringComplianceLocations";
import audit_outcomes from "../../../../constants/auditOutcomes";
import {catchError} from "../../../../actions/actionUtility";
import {MatrixCorrectionsStateView} from "./MatrixCorrectionsStateView";
import {StudentCorrectionsStateView} from "./StudentCorrectionsStateView";

export const CorrectionsStateDetailsContainer = ({
                                                     actions,
                                                     commentId,
                                                     dateWindowId,
                                                     districtId,
                                                     handleScrollToTop,
                                                     history,
                                                     institutions,
                                                     isCommentInEditMode,
                                                     isLoading,
                                                     isMatrix = false,
                                                     matrixProtocolCorrectiveResults,
                                                     page,
                                                     studentProtocolCorrectiveResults,
                                                     studentRecordId
                                                 }) => {
    const onProtocolCorrectiveResultOutcomeChange = (event, outcomeDto, auditType, loadAction) => {
        event.preventDefault();
        const auditOutcome = event.target.id.split("_")[0];

        let auditOutcomeAction = "";
        switch (auditOutcome) {
            case audit_outcomes.ACCEPTED.name:
                auditOutcomeAction = `approve${auditType}ProtocolCorrectiveResult`;
                break;
            case audit_outcomes.NEEDS_REVISION.name:
                auditOutcomeAction = `reject${auditType}ProtocolCorrectiveResult`;
                break;
            case audit_outcomes.RESET.name:
                auditOutcomeAction = `reset${auditType}ProtocolCorrectiveResult`;
                break;
        }

        if (auditOutcomeAction !== "") {
            actions[auditOutcomeAction](outcomeDto)
                .then(() => {
                    actions[loadAction](districtId, dateWindowId);
                });
        }
    };

    const returnToMainPage = () => {
        let path = ComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path;
        if (isMatrix)
            path = ComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path;

        history.push(path
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.DISTRICT_ID, districtId)
            .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX));
    };

    const onCommentCancel = (event, loadAction) => {
        event.preventDefault();
        actions.cancelComment();
        actions[loadAction](districtId, dateWindowId);
    };

    const onCommentSave = (event, comment, correctionId, assessmentId) => {
        event.preventDefault();

        if (comment.auditCommentId === "") {
            const newCommentId = emptyGuid;
            comment = Object.assign({}, comment, {auditCommentId: newCommentId});

            actions.addAuditComment(comment, correctionId, assessmentId)
                .catch(error => catchError(error));
        } else {
            actions.updateAuditComment(comment, assessmentId, correctionId)
                .catch(error => catchError(error));
        }
    };

    const onCommentEdit = (commentId) => {
        actions.editComment(commentId);
    };

    const onCommentCreate = (auditId, correctionId) => {
        actions.createComment(auditId, correctionId);
    };

    const handleCommentChange = (event, comment, correctionId) => {
        event.preventDefault();

        let commentUpdated = {...comment};

        const fieldUpdated = event.target.name;
        const updatedValue = event.target.value;

        if (fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
            commentUpdated.isVisibleToDistrict = updatedValue;
        else
            commentUpdated.text = updatedValue;

        actions.changeComment(commentUpdated, correctionId);
    };

    if (!dateWindowId) return null;

    return isMatrix
        ? <MatrixCorrectionsStateView
            actions={actions}
            dateWindowId={dateWindowId}
            districtId={districtId}
            editCommentItemId={commentId}
            handleCommentChange={handleCommentChange}
            handleScrollToTop={handleScrollToTop}
            history={history}
            institutions={institutions}
            isCommentInEditMode={isCommentInEditMode}
            isLoading={isLoading}
            matrixProtocolCorrectiveResults={matrixProtocolCorrectiveResults}
            onCommentCancel={onCommentCancel}
            onCommentCreate={onCommentCreate}
            onCommentEdit={onCommentEdit}
            onCommentSave={onCommentSave}
            onMatrixProtocolCorrectiveResultOutcomeChange={onProtocolCorrectiveResultOutcomeChange}
            page={page}
            returnToMainPage={returnToMainPage}
        />
        : <StudentCorrectionsStateView
            actions={actions}
            districtId={districtId}
            dateWindowId={dateWindowId}
            editCommentItemId={commentId}
            handleCommentChange={handleCommentChange}
            institutions={institutions}
            isCommentInEditMode={isCommentInEditMode}
            isLoading={isLoading}
            onCommentCancel={onCommentCancel}
            onCommentCreate={onCommentCreate}
            onCommentEdit={onCommentEdit}
            onCommentSave={onCommentSave}
            onStudentProtocolCorrectiveResultOutcomeChange={onProtocolCorrectiveResultOutcomeChange}
            returnToMainPage={returnToMainPage}
            studentProtocolCorrectiveResults={studentProtocolCorrectiveResults}
            studentRecordId={studentRecordId}
        />;
}

CorrectionsStateDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    commentId: PropTypes.string.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    handleScrollToTop: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMatrix: PropTypes.bool.isRequired,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    page: PropTypes.string,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    studentRecordId: PropTypes.string
};

function mapStateToProps(state) {
    return {
        studentProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.studentProtocolCorrectiveResults,
        matrixProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.matrixProtocolCorrectiveResults,
        isCommentInEditMode: state.monitoringComplianceStudentCorrection.isCommentInEditMode,
        commentId: state.monitoringComplianceStudentCorrection.commentId
    };
}

export default connect(mapStateToProps)(CorrectionsStateDetailsContainer);