import _ from "lodash";
import audit_outcomes from "../../constants/auditOutcomes";
import {emptyGuid} from "../../constants/config";
import * as contexts from "../../constants/contexts";
import {themeConstants} from "../../constants/commonStyleConstants";
import {SortType} from "../../constants/sharedData/dynamicSorting";
import {convertToDate} from "./dateUtilities";

export function generateDateWindowFormat(dateWindows, dateWindowId) {
    const selectedDateWindow = dateWindows.filter(dw => dw.id === dateWindowId)[0];

    return formatDateWindow(selectedDateWindow);
}

export function formatDateWindow(selectedDateWindow) {
    return selectedDateWindow ? new Date(selectedDateWindow.openDate).getFullYear() + "-" + new Date(selectedDateWindow.closeDate).getFullYear() : "";
}

export function setPageTitle(pageTitle) {
    if (pageTitle && pageTitle !== "")
        document.title = "GSW | " + pageTitle.replace("<br>", " ").replace("<br/>", " ");
    else
        document.title = "GSW";
}

export function makeWordPlural(word, total) {
    return total === 1 ? word : `${word}s`;
}

export function isInArray(array, string) {
    return !!array.filter(value => value === string).length;
}

export function isInSelectList(list, string) {
    return !!list.filter(item => item.value === string || item.text === string).length;
}

export function isObjectNullOrEmpty(obj) {
    return !(obj && Object.getOwnPropertyNames(obj).length !== 0);
}

export function isArrayNullOrEmpty(arr) {
    if (isNullOrUndefined(arr)) return true;

    return !(isArray(arr) && arr.length !== 0);
}

export function isArray(value) {
    return (typeof value === 'object' && value.constructor === Array);
}

export function partialOptionIndexInArray(array, option = "") {
    if (array.length === 0) return -1;

    const optionValue = option.value?.toLowerCase() || option?.toLowerCase();

    for (let i = 0; i < array.length; i++) {
        const arrayValue = array[i].toLowerCase();
        if (optionValue.startsWith(arrayValue)) return i;
    }

    return -1;
}

export function isString(value) {
    return (typeof value === 'string' && value.constructor === String);
}

export function isBoolean(value) {
    return (typeof value === 'boolean');
}

export function isObject(value) {
    return (typeof value === 'object' && value.constructor === Object);
}

export function convertToBoolean(value) {
    return String(value).toLowerCase() === "true";
}

export function isNumber(value) {
    return !isNullOrUndefined(value) && !isNaN(value);
}

export function isGuidEmpty(guid) {
    return !guid || guid === emptyGuid;
}

export function isGuid(guid) {
    if (!guid) return false;

    const isGuidRegEx = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const totalMatches = guid.match(isGuidRegEx);

    return !isNullOrUndefined(totalMatches);
}

export function isTrimmedStringEmpty(value) {
    return isNullOrUndefined(value) || !isString(value) || value.trim().length === 0;
}

export function convertToString(value) {
    if (isNullOrUndefined(value)) return "";

    return String(value);
}

export function isNullOrUndefined(value) {
    return value === null || value === undefined;
}

export function convertToArray(unknownType) {
    if (isString(unknownType)) {
        unknownType = unknownType.split(',');
    } else if (!Array.isArray(unknownType)) {
        unknownType = [unknownType];
    }
    return unknownType;
}

export function deepCopyObject(object) {
    if (!object) return {};
    return JSON.parse(JSON.stringify(object));
}

export function deepCopyArray(array) {
    if (isArrayNullOrEmpty(array)) return [];
    return deepCopyObject(array);
}

export function arraysEqual(a, b) {
    /*
        Array-aware equality checker:
        Returns whether arguments a and b are === to each other;
        however if they are equal-lengthed arrays, returns whether their
        elements are pairwise === to each other recursively under this
        definition.
    */
    if (a instanceof Array && b instanceof Array) {
        if (a.length !== b.length)  // assert same length
            return false;
        for (let i = 0; i < a.length; i++)  // assert each element equal
            if (!arraysEqual(a[i], b[i]))
                return false;
        return true;
    } else {
        return a === b;  // if not both arrays, should be the same
    }
}

export function arraysOfObjectsEqual(array1, array2) {
    const string1 = JSON.stringify(array1);
    const string2 = JSON.stringify(array2);
    return string1 === string2;
}


export function dynamicSort(property, SortDescending = false, baseProperty = "", sortType = "") {
    let sortOrder = SortDescending ? -1 : 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    if (sortType === SortType.Date) {
        return dynamicSortDates(property, SortDescending);
    }

    return function (a, b) {
        let result = 0;
        const aProp = isString(a[property]) ? a[property].toLowerCase() : a[property];
        const bProp = isString(b[property]) ? b[property].toLowerCase() : b[property];
        if (aProp === bProp && baseProperty !== "") {
            const aBaseProp = isString(a[baseProperty]) ? a[baseProperty].toLowerCase() : a[baseProperty];
            const bBaseProp = isString(b[baseProperty]) ? b[baseProperty].toLowerCase() : b[baseProperty];
            result = (aBaseProp < bBaseProp) ? -1 : (aBaseProp > bBaseProp) ? 1 : 0;
        } else
            result = (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
        return result * sortOrder;
    };
}

export function dynamicSortDates(property, SortDescending = false) {
    let sortOrder = SortDescending ? -1 : 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        let result = 0;
        const aProp = convertToDate(a[property]);
        const bProp = convertToDate(b[property]);
        result = (aProp < bProp) ? -1 : (aProp > bProp) ? 1 : 0;
        return result * sortOrder;
    };
}

export function filterObjectArrayByField(array, filter, field) {
    let result = [...array];
    if (filter && !isArrayNullOrEmpty(array)) {
        const searchStrings = filter.split(",").map(d => d.trim());
        result =
            array
                .filter(ins => searchStrings
                    .some(function (search) {
                        return (ins[field].toLowerCase().indexOf(search.toLowerCase()) >= 0);
                    }));
    }

    return result;
}

export function generateSelectListFromArray(array) {
    let selectItems = [];

    if (isArrayNullOrEmpty(array)) return selectItems;

    array.map((item) => {
        selectItems.push(createListItem(item, convertCamelCaseToSpaceDelimited(item)));
    });

    return selectItems;
}

export function generateObjectFromArray(array) {
    let object = {};

    if (isArrayNullOrEmpty(array)) return object;

    array.map((item) => {
        object = Object.assign(object, {[item]: item});
    });

    return object;
}

export function generateArrayFromObjectValues(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result.push(object[property]);
        }
    }

    return result;
}

export function createRedirectUrl(pathname, searchStr) {
    return (searchStr && searchStr !== "")
        ? pathname + searchStr
        : pathname;
}

export function createListIfExistsInIdArray(ids, fullList) {
    let list = [];
    fullList.map((listItem) => {
        const listItemExists = ids.find(f => f === listItem.id);
        if (!listItemExists)
            return;

        list.push(createListItem(listItem.id, listItem.text));
    });

    return list;
}

export function createDescriptionObjectForList(id, description) {
    return {
        id,
        description,
    };
}

export function createListFromObject(object) {
    let result = [];
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            const value = !object[property].id ? object[property] : object[property].id;
            const id = !object[property].id ? property : object[property].id;
            const description = !object[property].description ? convertCamelCaseToSpaceDelimited(property) : object[property].description;

            result.push(createListItem(value, description, id));
        }
    }
    return result;
}

export function createObjectFromObjectPropertyNames(object) {
    let result = {};
    for (let property in object) {
        if (objectHasProperty(object, property)) {
            result = {...result, [property]: convertCamelCaseToSpaceDelimited(property)};
        }
    }
    return result;
}

export function createListFromArray(array) {
    let list = [];

    if (isArrayNullOrEmpty(array)) return list;

    array.map((item) => {
        if (isObject(item))
            return list.push(createListItemFromObject(item));
        return list.push(createListItem(item, convertCamelCaseToSpaceDelimited(item)));
    });

    return list;
}

export function createListItemFromObject({value, text, id, publicIdentifier}) {
    return {
        text: text ? text :
            value ? value :
                publicIdentifier,
        value: value ? value :
            publicIdentifier,
        id: id ? id : value,
    };
}

export function createListItem(value, text, id) {
    return {
        text: text ? text : value,
        value: value,
        id: id ? id : value,
    };
}

export function removeDuplicatesFromListArray(array) {
    return [...array.filter((value, index, array) => index === array.findIndex(f => f.id === value.id))];
}

export const generateArchivableSelectListFromArray = (items = [], value) => {
    let list = [];

    if (isString(value) && value.length > 1 && !items.includes(value))
        list.push(createListItem(value, `${value} (archived)`));

    if (isArray(items))
        items.map((item) => {
            list.push(createListItem(item));
        });
    return list;
};

export function convertCamelCaseToSpaceDelimited(string = "") {
    return _.startCase(string);
}

export function convertToNumber(value = "not a number") {
    const conversion = Number(value);
    const result = isNaN(conversion) ? undefined : conversion;
    return result;
}

export function convertToValidNumber(value) {
    const result = convertToNumber(value);
    return result || 0;
}

export function optionIndexInArray(array, option = "", wholeWord = true) {
    const optionValue = option.toString().toLowerCase();

    for (let i = 0; i < array.length; i++) {
        if (isNullOrUndefined(array[i]))
            continue;

        const arrayValue = array[i].toString().toLowerCase();
        if ((wholeWord && arrayValue === optionValue) || (!wholeWord && arrayValue.startsWith(optionValue)))
            return i;
    }

    return -1;
}

export function getIndexOfMaxValueInArray(optionSizes) {
    const maxSize = Math.max(...optionSizes);
    return optionSizes.indexOf(maxSize);
}

export function getPropName(prop, value) {
    let listPropertyNames = Object.keys(prop);
    let result = listPropertyNames.find(f => prop[f] === value);

    if (!result)
        result = listPropertyNames.find(f => prop[f].id === value);

    return result;
}

export function getListOfPropNames(object, propStartsWith = "") {
    let listPropertyNames = Object.keys(object);
    return listPropertyNames.filter(f => f.toUpperCase().startsWith(propStartsWith.toUpperCase()));
}

export function getDistinctIds(array, id) {
    if (!array)
        return [];

    return [...new Set(array.map(item => {
        return item[id];
    }))];
}

export function getPropertyIfDefined(obj, prop) {
    if (isObjectNullOrEmpty(obj) || !obj[prop])
        return "";

    return obj[prop];
}

export function objectHasProperty(object, property) {
    return !isObjectNullOrEmpty(object) && !!Object.prototype.hasOwnProperty.call(object, property);
}

export function convertContextForThemeStyling(currentContext) {
    let contextTheme = currentContext;
    if (isArray(currentContext))
        contextTheme = currentContext[0];

    switch (contextTheme) {
        case contexts.USERS:
            return themeConstants.USERS;
        case contexts.SURVEYS:
            return themeConstants.SURVEYS;
        case contexts.SPP:
            return themeConstants.SPP;
        case contexts.STRATEGIC_PLAN:
            return themeConstants.STRATEGIC_PLAN;
        case contexts.ADMIN:
            return themeConstants.ADMIN;
        case contexts.MONITORING_PERFORMANCE:
            return themeConstants.MONITORING_PERFORMANCE;
        case contexts.MONITORING_COMPLIANCE:
            return themeConstants.MONITORING_COMPLIANCE;
        case contexts.STATE_COMPLAINTS:
            return themeConstants.STATE_COMPLAINTS;
        case contexts.DUE_PROCESS:
            return themeConstants.DUE_PROCESS;
        case contexts.MEDIATIONS:
            return themeConstants.MEDIATIONS;
        default:
            return contextTheme;
    }
}

export function limitTextLength(str, limit = 200) {
    return str.length > limit ? str.substring(0, limit) + "..." : str;
}

export function getNeedsRevisionAuditComment(audits) {
    const notFoundMessage = "<p>None.  Please contact FDOE for further information.</p>";
    const needsRevisionAudit = audits.find(f => f.outcomeString === audit_outcomes.NEEDS_REVISION.value);

    if (!needsRevisionAudit || !needsRevisionAudit.comments)
        return notFoundMessage;

    const needsRevisionMessage = needsRevisionAudit.comments.find(f => f.isVisibleToDistrict);

    return !needsRevisionMessage ? notFoundMessage : needsRevisionMessage.text;
}

export function convertNotUsedParamToEmptyString(paramValue) {
    return (!paramValue || paramValue === "-1") ? "" : paramValue;
}

export function getTextByValue(list, value) {
    if (!value)
        return "";
    const result = list.find(l => String(l.value) === String(value));
    return result ? result.text : "";
}

export function validateDecimal(value = 0, digits = 2) {
    if (!value) return 0.00;
    return convertToNumber(`${value}`).toFixed(digits);
}

export function numberOfDecimalPlaces(number = "") {
    const b = String(number).split(".");
    return b[1] ? b[1].length : 0;
}

export function emptyFunction() {
}

export function convertToCurrencyFormat(decimalValue) {
    const decimalAsString = convertToString(decimalValue);
    if (isTrimmedStringEmpty(decimalAsString)) return "";

    const cleanedString = decimalAsString.replace("$", "").replace(",", "");

    const dollarValue = convertToNumber(cleanedString);

    if (isNullOrUndefined(dollarValue)) return "";

    const dollarString = dollarValue.toLocaleString("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});
    return `$${dollarString}`;
}

export function convertToPercentFormat(decimalValue) {
    if (isTrimmedStringEmpty(decimalValue)) return "";

    const cleanedString = decimalValue.replace("%", "").replace(",", "");

    return `${cleanedString}%`;
}