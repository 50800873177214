import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../../ptsConstants";
import {API_ROOT} from "../../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsAdminActivitiesApi {
    static ImportActivities(dateWindowId, files){
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        let data = new FormData();
        data.append('file', files[0]);

        let request = AjaxRequestOptions(
            `${config.apiUrl}${API_ROOT}/admin/dates/${dateWindowId}/activities/import`,
            RequestTypes.POST,
            data,
            API_MESSAGES.ADMIN.ACTIVITY.IMPORT.success);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static DownloadSampleActivitiesImportFile() {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}${API_ROOT}/admin/activities/sample`,
                RequestTypes.GET,
                null,
                API_MESSAGES.ADMIN.PROJECT.IMPORT.sample));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsAdminActivitiesApi {
    static ImportActivities() {
        return ApiUtility.mockResolveApiCall("", API_MESSAGES.ADMIN.ACTIVITY.IMPORT.success);
    }
}

const PtsAdminActivitiesApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsAdminActivitiesApi : MockPtsAdminActivitiesApi;
export default PtsAdminActivitiesApi;