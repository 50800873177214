import React from "react";
import PropTypes from 'prop-types';
import AccountUtility from "../../../api/AccountUtility";
import {monitoring_compliance} from "../../../constants/policyEvents";
import * as AuthorizationUtilities from "../../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../authorization/policies/MonitoringCompliancePolicy";
import {isViewOnlyDueToStatus} from "../MonitoringComplianceUtilities";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import Button from "../../../components/common/buttons/Button";
import GridTable from "../../../components/common/GridTable";

export const StudentSummaryGrid = (
    {
        selectStudentCorrections,
        studentRecords
    }) => {

    const onSelectClick = (event, studentRecordId) => {
        event.preventDefault();
        selectStudentCorrections(studentRecordId);
    };

    if(!studentRecords || studentRecords.length === 0)
        return  <p>No student specific corrections are needed at this time.</p>;

    const isDistrictUser = AccountUtility.isDistrictUser();
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.MODIFY);

    return (
        <GridTable>
            <thead>
            <tr>
                <th className={"text-center"}>Student Record</th>
                <th className={"text-center"}>Total Protocols Needing Corrections</th>
                <th className={"text-center"}>Status</th>
                <th className={"text-center"}>Actions</th>
            </tr>
            </thead>
            <tbody>
            {
                studentRecords.map((studentRecord, index) => {
                    const viewOnly = isViewOnlyDueToStatus(studentRecord.status) || !canEdit;
                    const buttonType = viewOnly ? ButtonTypes.VIEW : ButtonTypes.EDIT;
                    const label = viewOnly ? "View" : isDistrictUser ? "Edit" : "Review";
                    return (
                        <tr key={index}>
                            <td className={"text-center"}>{studentRecord.name}</td>
                            <td className={"text-center"}>{studentRecord.totalProtocols}</td>
                            <td className={"text-center"}>{studentRecord.status}</td>
                            <td className={"text-center"}>
                                <Button
                                    buttonType={buttonType}
                                    label={`${label} Student Corrections`}
                                    name={`editButton_${index}`}
                                    onClick={(event) => onSelectClick(event, studentRecord.studentRecordId)}
                                    showLabel={false}
                                />
                            </td>
                        </tr>
                    );
                })
            }
            </tbody>
        </GridTable>
    );
};

StudentSummaryGrid.propTypes = {
    selectStudentCorrections: PropTypes.func.isRequired,
    studentRecords: PropTypes.arrayOf(PropTypes.object)
};
