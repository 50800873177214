import React from 'react';
import PropTypes from 'prop-types';
import Button from "./Button";
import ButtonMock from "../buttons/ButtonMock";
import FileUploadButton from "../inputs/FileUploadButton";
import {childrenOf} from "../../../constants/CustomPropTypes";
import Allow from "../../authorization/Allow";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";

const ButtonBar = ({
                       children,
                       className,
                       name,
                       position,
                       stickyHeight
                   }) => {


    let isHidden = (children === undefined || children === null || children.length === 0) ? " is-hidden " : "";

    className = !className ? "" : ` ${className} `;
    stickyHeight = !stickyHeight ? "" : ` ${stickyHeight} `;

    return (
        <div className={"buttonBar " + position + isHidden + className + stickyHeight} name={name} id={name}>
            {children}
        </div>
    );
};

ButtonBar.propTypes = {
    children: PropTypes.oneOfType([
        childrenOf(Button, ButtonMock, FileUploadButton, Allow),
        PropTypes.object,
        PropTypes.array
    ]),
    className: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    stickyHeight: PropTypes.string
};

ButtonBar.defaultProps = {
    position: ButtonBarPositions.STICKY
};

export default ButtonBar;