import PropTypes from 'prop-types';
import React from "react";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {isValueInArray} from "../common/inputs/inputUtility";
import Button from "./buttons/Button";
import ButtonBar from "./buttons/ButtonBar";

const EditComponentButtons = ({
	                         cancelDisabled,
	                         cancelHidden,
	                         saveDisabled,
	                         saveHidden,
	                         handleClickCancel,
	                         handleClickSave,
	                         position,
                         }) => {

	position = isValueInArray(ButtonBarPositions.LIST, position) ? position : ButtonBarPositions.BOTTOM;

	return (
		<ButtonBar position={position}>
			{
				handleClickSave &&
				!saveHidden &&
				<Button
					buttonType={ButtonTypes.SAVE}
					disabled={saveDisabled}
					label="Save"
					name="btnSave"
					onClick={handleClickSave}
				/>
			}
			{
				handleClickCancel &&
				!cancelHidden &&
				<Button
					buttonType={ButtonTypes.CANCEL}
					disabled={cancelDisabled}
					label="Cancel"
					name="btnCancel"
					onClick={handleClickCancel}
				/>
			}
		</ButtonBar>
	);
};

EditComponentButtons.propTypes = {
	cancelDisabled: PropTypes.bool,
	cancelHidden: PropTypes.bool,
	handleClickCancel: PropTypes.func,
	handleClickSave: PropTypes.func,
	position: PropTypes.string,
	saveDisabled: PropTypes.bool,
	saveHidden: PropTypes.bool
};

export default EditComponentButtons;