import React from "react";
import PropTypes from 'prop-types';
import DisplayField from "../../../components/common/inputs/DisplayField";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import {getYesNoOption} from "../../../components/common/inputs/inputUtility";
import SelectField from "../../../components/common/inputs/SelectField";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {RadioButtonYesNoList} from "../../drConstants";
import {TimelineMediationResponseOutcomesList, TimelineRequirements} from "../drStateComplaintConstants";
import {getRole} from "../../drUtilities";

const DrStateComplaintTimelineRequirement = ({
                                                 handleChangeCompletionDate,
                                                 handleChangeMediationResponse,
                                                 isAbleToEditTimelineCompletionDate,
                                                 readOnly,
                                                 timeline
                                             }) => {
    const handleMediationResponseOptionChange = (event, requirementId) => {
        event.preventDefault();
        handleChangeMediationResponse(requirementId, event.target.id, event.target.value);
    }

    const accessInformation =  !isAbleToEditTimelineCompletionDate ? ` (${getRole(timeline.roleResponsibleForCompletion).description} only)` : "";

    return (
        <FieldsetLayout
            legend={`${timeline.activity}${accessInformation}`}
            showLegend
        >
            <DateTimePickerField
                name={`timelineDue${timeline.timelineRequirementId}`}
                label="Due"
                showLabel
                value={timeline.dueDate}
                disabled
                medium={6}
            />
            {
                timeline.dueDateWarning &&
                <p><strong className={`color--alert`}>{timeline.dueDateWarning}</strong></p>
            }
            <DateTimePickerField
                name={`timelineCompleted${timeline.timelineRequirementId}`}
                label="Completed"
                showLabel
                value={timeline.dateCompleted}
                onChange={(event) => handleChangeCompletionDate(timeline.timelineRequirementId, event.target.value)}
                medium={6}
                disabled={readOnly}
            />
            {
                !!timeline.dateCompleted &&
                timeline.timelineRequirementType === TimelineRequirements.MediationTask &&
                <>
                    <SelectField
                        disabled={readOnly}
                        error={timeline.mediationResponseOutcomeError}
                        label={`Outcome`}
                        medium={6}
                        name={`mediationResponseOutcome`}
                        onChange={(event) => handleMediationResponseOptionChange(event, timeline.timelineRequirementId)}
                        options={TimelineMediationResponseOutcomesList}
                        showLabel
                        value={timeline.mediationResponseOutcome}
                    />
                    <SelectField
                        disabled={readOnly}
                        error={timeline.mediationResponseExtensionGrantedError}
                        label={"Extension Granted"}
                        medium={6}
                        name={"mediationResponseExtensionGranted"}
                        onChange={(event) => handleMediationResponseOptionChange(event, timeline.timelineRequirementId)}
                        options={RadioButtonYesNoList}
                        showLabel
                        value={getYesNoOption(timeline.mediationResponseExtensionGranted)}
                    />
                </>
            }
            <DisplayField
                label={"Last Modified"}
                name={"DateModified"}
                showLabel
                columnsMedium={6}
            >{timeline.dateModified ? outputStandardDateFormat(timeline.dateModified) : ""}</DisplayField>
        </FieldsetLayout>
    );
};

DrStateComplaintTimelineRequirement.propTypes = {
    handleChangeCompletionDate: PropTypes.func.isRequired,
    handleChangeMediationResponse: PropTypes.func.isRequired,
    isAbleToEditTimelineCompletionDate: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    timeline: PropTypes.object.isRequired
};

export default DrStateComplaintTimelineRequirement;