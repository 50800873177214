import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as classHooks from "../../constants/sharedData/classHooks";
import * as ButtonTypes from "../../constants/ButtonTypes";
import IndicatorForm from "../../components/admin/IndicatorForm";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import GridTable from "../../components/common/GridTable";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import * as indicatorTypes from "../../constants/indicatorTypes";
import {catchError} from "../../actions/actionUtility";
import HtmlDisplayBlock from "../../components/htmlDisplay/HtmlDisplayBlock";

//eslint-disable react/no-danger

class SppIndicatorsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onCreateIndicator = this.onCreateIndicator.bind(this);
		this.onChangeIndicator = this.onChangeIndicator.bind(this);
		this.onEditIndicator = this.onEditIndicator.bind(this);
		this.onSaveIndicator = this.onSaveIndicator.bind(this);
		this.onCancelIndicator = this.onCancelIndicator.bind(this);
		this.onDeleteIndicator = this.onDeleteIndicator.bind(this);
	}

	componentDidMount() {
		this.props.actions.loadSppIndicators();
	}

	onCreateIndicator(event) {
		event.preventDefault();
		this.props.actions.createSppIndicator();
	}

	onChangeIndicator(event, indicatorId) {
		event.preventDefault();

		const changedIndicator = Object.assign({}, this.props.sppIndicators.filter(i => i.id === indicatorId)[0]);

		changedIndicator[event.target.name] = event.target.value;

		this.props.actions.changeSppIndicator(changedIndicator);
	}

	onEditIndicator(event, indicatorId) {
		event.preventDefault();
		this.props.actions.enterEditMode(indicatorId);
	}

	onSaveIndicator(event, indicatorId) {
		event.preventDefault();

		const savedIndicator = this.props.sppIndicators.filter(i => i.id === indicatorId)[0];


		const savedAction = (this.props.editItemId === "")
			? () => this.props.actions.tryAddSppIndicator(savedIndicator)
			: () => this.props.actions.tryUpdateSharedIndicator(savedIndicator);

		savedAction()
			.then(() => {
				this.reloadAfterEvent();
			})
			.catch(errors => catchError(errors));
	}

	reloadAfterEvent() {
		this.props.actions.leaveEditMode();
		this.props.actions.loadSppIndicators();
	}

	onCancelIndicator(event) {
		event.preventDefault();

		this.reloadAfterEvent();
	}

	onDeleteIndicator(event, indicatorId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this SPP indicator?")) {
			const deletedIndicator = this.props.sppIndicators.filter(i => i.id === indicatorId)[0];

			this.props.actions.tryDeleteSharedIndicator(deletedIndicator, indicatorTypes.SPP)
				.then(() => {
					this.reloadAfterEvent();
				})
				.catch(errors => catchError(errors));
		}
	}

	render() {
		return (
			<section>
				{
					this.props.canEdit &&
					<p>SPP Indicators which are deleted will not have a delete icon. We soft delete indicators so that
					contexts which use them can still display the indicator information for previous years after they
					are deleted.</p>
				}
				<GridTable>
				<>
					<thead>
					<tr>
						<th>Identifier</th>
						<th>Indicator</th>
						{
							this.props.canEdit &&
							<th className="text-center">Actions</th>
						}
					</tr>
					</thead>
					<tbody>
					{
						this.props.sppIndicators.map(ind => {
							if (this.props.editItemId === ind.id)
								return (<IndicatorForm key={ind.id}
								                          indicator={ind}
								                          isLoading={this.props.isLoading}
								                          onChangeIndicator={(event) => this.onChangeIndicator(event, ind.id)}
								                          onSaveIndicator={(event) => this.onSaveIndicator(event, ind.id)}
								                          onCancelIndicator={this.onCancelIndicator}
													   showTitleField={false}
								/>);

							return (
								<tr key={ind.id} id={ind.id}>
									<td>{ind.publicIdentifier}</td>
									<td><HtmlDisplayBlock html={ind.text} /></td>
									{
										this.props.canEdit &&
										<td className="button-cell">
											{
												<ButtonBar position={ButtonBarPositions.TABLE}>
													<Button name={"edit|" + ind.id}
															label={"EDIT"}
															showLabel={false}
															onClick={(event) => this.onEditIndicator(event, ind.id)}
															buttonType={ButtonTypes.EDIT}
															disabled={this.props.isLoading || this.props.isEditing}/>

													{
														!ind.isDeleted &&
														<Button name={"delete|" + ind.id}
																label={"DELETE"}
																showLabel={false}
																onClick={(event) => this.onDeleteIndicator(event, ind.id)}
																buttonType={ButtonTypes.DELETE}
																disabled={this.props.isLoading || this.props.isEditing}/>
													}

												</ButtonBar>
											}
										</td>
									}
								</tr>
							);
						})
					}
					</tbody>
					{
						this.props.canEdit &&
						<tfoot>
							<tr id={"NEW_INDICATOR"} className={classHooks.INDICATOR}>
								<td colSpan={4}>
									<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
										<Tile body={"Add SPP Indicator"}
											  onClick={!this.props.isEditing && !this.props.isLoading ? this.onCreateIndicator : null}
											  isNew
											  isMedium/>
									</TileContainer>
								</td>
							</tr>
						</tfoot>
					}
				</>
				</GridTable>
			</section>
		);
	}
}

SppIndicatorsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	canEdit: PropTypes.bool.isRequired,
	editItemId: PropTypes.string.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	sppIndicators: PropTypes.array.isRequired
};

function mapStateToProps(state) {
	return {
		isEditing: state.sharedData.isEditing,
		editItemId: state.sharedData.editItemId,
		sppIndicators: state.sharedData.sppIndicators,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sharedDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppIndicatorsPage);