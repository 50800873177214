import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";

class ServerSurveyApi {
    static getSurveysByRespondentId(respondentId){
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "survey/GetSurveysByRespondentId",
                RequestTypes.GET,
                {id: respondentId});

            request.dataType = 'json';
            request.setCallbacks(function(data){
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static getSurveyById(instanceId, respondentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "survey/GetSurveyById",
            RequestTypes.GET,
            {
                instanceId: instanceId,
                respondentId: respondentId
            }));
    }

    static getSurveyElementsForTakeSurvey(surveyId, respondentId){
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "survey/GetElementViewModelsForTakingSurvey",
                RequestTypes.GET,
                {surveyRespondentId: respondentId, surveyId: surveyId});

            request.setCallbacks(function(data) {
                resolve([...data]);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static saveRespondentSurveyElements(surveyElements, componentHistory){
        const elementResponses = surveyElements.map(surveyElement => {
            return {
                ComponentId: surveyElement.componentId,
                SurveyRespondentId: surveyElement.surveyRespondentId,
                Answer: surveyElement.answer
            };
         });

        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "survey/SaveElementSetResponses",
                RequestTypes.POST,
                JSON.stringify({models: elementResponses, componentHistory: componentHistory}));

            request.setCallbacks(function(data) {
                resolve({data});
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static submitRespondentSurvey(surveyElements, componentHistory, surveyId, dateWindowId){
        const elementResponses = surveyElements.map(surveyElement => {
            return {
                ComponentId: surveyElement.componentId,
                SurveyRespondentId: surveyElement.surveyRespondentId,
                Answer: surveyElement.answer
            };
        });

        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + "survey/Submit",
                RequestTypes.POST,
                JSON.stringify({models: elementResponses, componentHistory: componentHistory, surveyId: surveyId, dateWindowId: dateWindowId}));

            request.setCallbacks(function(data){
                resolve({data});
            }, function(data) {
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
const surveys = [
    {
        instanceId: "cccccccc-cccc-cccc-cccc-cccccccccccc",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 1",
        progress: "Completed",
        endDate: "5/1/17",
        canEditResponse: false
    },
    {
        instanceId: "bbbbbbbb-bbbb-bbbb-bbbb-bbbbbbbbbbbb",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 2",
        progress: "Completed",
        endDate: "5/1/17",
        canEditResponse: false
    },
    {
        instanceId: "dddddddd-dddd-dddd-dddd-dddddddddddd",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 3",
        progress: "In progress",
        endDate: "9/11/17",
        canEditResponse: true
    },
    {
        instanceId: "eeeeeeee-eeee-eeee-eeee-eeeeeeeeeeee",
        surveyRespondentId: 1,
        lastComponentViewed: "",
        title: "Title 4",
        progress: "In progress",
        endDate: "9/11/17",
        canEditResponse: true
    },
    {
        instanceId: "ffffffff-ffff-ffff-ffff-ffffffffffff",
        surveyRespondentId: 2,
        lastComponentViewed: "",
        title: "Title 5",
        progress: "Completed",
        endDate: "5/1/17",
        canEditResponse: false
    },
    {
        instanceId: "gggggggg-gggg-gggg-gggg-gggggggggggg",
        surveyRespondentId: 2,
        lastComponentViewed: "",
        title: "Title 6",
        progress: "Completed",
        endDate: "5/1/17",
        canEditResponse: false
    },
    {
        instanceId: "hhhhhhhh-hhhh-hhhh-hhhh-hhhhhhhhhhhh",
        surveyRespondentId: 2,
        lastComponentViewed: "",
        title: "Title 7",
        progress: "In progress",
        endDate: "9/11/17",
        canEditResponse: true
    },
    {
        instanceId: "iiiiiiii-iiii-iiii-iiii-iiiiiiiiiiii",
        surveyRespondentId: 2,
        lastComponentViewed: "",
        title: "Title 8",
        progress: "In progress",
        endDate: "9/11/17",
        canEditResponse: true
    }
];

const surveyElementViewModels = [
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "d0fc147d-cbe7-48d9-9d60-02331d4d7064",
        "labelText": "DJJ",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fb4bb6ac-dcbe-424f-beea-0f87683930f3",
        "labelText": "Elementary/Middle School Counseling",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "182cb593-f1a9-4a2e-a337-117c076d6e33",
        "labelText": "Databook",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3ac62546-5201-4e61-810f-21f317b5e1fd",
        "labelText": "Birth-2 years",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5009e50f-4c4c-4007-bf97-279250a2c0d5",
        "labelText": "Director's Call",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b6f576af-8ba0-47ab-9ebc-2810e2e4a262",
        "labelText": "We have provided a space below for additional information you may not have been able to address in the previous questions.",
        "subtext": "",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a89cf768-0c00-4269-9270-287dccdd8bf2",
        "labelText": "Policy and Procedure Updates",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5b2b26fd-de0c-4837-a5f5-3518b264d23f",
        "labelText": "MTSS",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "26de59eb-573f-448b-a89d-35f89d279b24",
        "labelText": "PROGRAM ACCOUNTABILITY ASSESSMENT AND DATA SYSTEMS (PAADS)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "86b9374c-f188-4290-8721-3a035fbafd8a",
        "labelText": "LEA Determinations",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c99c0b8e-6ce6-43ba-8ec5-415b4045775a",
        "labelText": "Transition",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c78b5325-71ed-4eaa-8ee7-415fa3673ede",
        "labelText": "ASD",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8e3322c5-00e8-4caf-ba6e-468581c2603b",
        "labelText": "LEA Profiles",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9cefacdb-5c8b-42ce-88e4-46e26821960e",
        "labelText": "BUREAU RESOURCES AND INFORMATION CENTER (BRIC)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "52078704-2ea9-46d8-8fb5-47b9e7398d24",
        "labelText": "SLD",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "df27f668-de78-4bb9-9f20-4824d59d2c50",
        "labelText": "504 Plan",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c9938b42-9ff8-4ea3-9ac2-49186afe71f5",
        "labelText": "EBD",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "03aed25b-6e4d-4bc5-a1bb-4a550966efda",
        "labelText": "ADMINISTRATION",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1f066f52-126a-439b-b32f-4df5b7560813",
        "labelText": "Social Work",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e1cd90ad-c57f-4f2d-a54d-4ec616c5cd8f",
        "labelText": "AMM",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7890c027-adcd-4d68-9555-528f254ba5f5",
        "labelText": "BEESS Website",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "cca8f705-bb3d-48fa-b007-5293df8222b3",
        "labelText": "DHH",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8bf9756d-f73b-4ee5-b364-52d3df5476de",
        "labelText": "Topical Call",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "75c1ea16-6885-481a-acba-557eb1b53906",
        "labelText": "HH",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "cb66856b-c7ad-4d2c-bb45-5709710f4162",
        "labelText": "Regional Size-a-like Meetings",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b72fc9f8-c547-43ae-9342-595e02ca71cb",
        "labelText": "VI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2a61b1e3-7d9e-41a8-b883-5eb1e41a636f",
        "labelText": "DISPUTE RESOLUTION AND MONITORING (DRM)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "69c2d184-9825-4997-88a5-60726de2e20b",
        "labelText": "e-Connections",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3ff45c6e-1875-4341-9a22-60b287639d27",
        "labelText": "State Facilitated IEP",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1be33436-85d5-46cf-a14a-647d36afe715",
        "labelText": "Welcome to the Bureau of Exceptional  Education and Student Services (BEESS) Survey. ",
        "subtext": "This survey will take approximately 10 to 15 minutes.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "da9b0716-aa4e-453b-834d-6df70fa28443",
        "labelText": "Florida Standards Alternate Assessment",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "aa50cb6d-bf40-4f02-8b2b-71e2bcc1031e",
        "labelText": "TBI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2c125b5f-7204-4ed0-9865-74601496ca84",
        "labelText": "District Liaisons Support",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "72e59ca2-52d8-456d-88d0-75542a4f25a6",
        "labelText": "High School Counseling",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "13940d05-4ed4-4a77-905a-777ae9773380",
        "labelText": "General Supervision Website (GSW) Data Management System",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0e48dd94-e685-4960-8d97-77db345d993d",
        "labelText": "InD",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "090df721-0554-493d-aa37-7a1c6d0363cd",
        "labelText": "Facilitated IEP Training",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "95f5636b-cac8-4f49-b470-7a423ee9d0c1",
        "labelText": "Parent Services (e.g. Parent Survey)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "bde813d2-9972-43f7-a231-7d87befe6c49",
        "labelText": "BEESS Weekly",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "94c087cc-b56a-43e4-9518-805ab5cdda70",
        "labelText": "School Psychology",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "697d7518-177d-4c2e-a750-812a9739ffb0",
        "labelText": "Nursing",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2372ab93-f405-408a-bd4c-8e82e389b4e0",
        "labelText": "District Self-Assessment",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5688ae0e-20ec-423e-8d1b-9356e86acb85",
        "labelText": "INSTRUCTIONAL SUPPORT SERVICES (ISS)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2f445748-a304-435b-8d6c-971597f75a51",
        "labelText": "Publications/Resources",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "bf3b70a9-fe83-4b8e-afc7-9bdc1f03b9a6",
        "labelText": "OI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "448a00a6-7769-4cc8-a3b4-a09d77fa3c0c",
        "labelText": "TAPS",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "246fc892-659e-48a7-8f53-a2322cf90644",
        "labelText": "Complaint Investigations",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "120e27c2-35d1-4276-9536-a5da5db328bc",
        "labelText": "Graduation",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "48874e3b-7cc3-4bf4-8922-b49fcc2ae07d",
        "labelText": "LRP Conference Calls",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e89c83d4-3b2c-49e4-9154-b4a4d7253879",
        "labelText": "On-Site Monitoring Visits",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9b2ae832-d155-4d21-b1ef-bdf45b293e31",
        "labelText": "DSI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "18555259-a5ff-4189-ad9c-c53b437cf519",
        "labelText": "Statewide IEP System (PEER)",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f890ccc9-485e-495f-8b5e-c6a707c6081b",
        "labelText": "Bureau Chief",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7cf11303-000c-4a80-a8e0-cff7c5ae6378",
        "labelText": "STUDENT SUPPORT SERVICES (SSS) PROJECT TEAM",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7af2cd0d-79de-44fa-a4fb-d2f257c32239",
        "labelText": "OT/PT",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dbb57f00-eccb-4ce6-a515-d4954647fa69",
        "labelText": "IDEA (Part B and Preschool) District Entitlement Grants",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "34d1f40e-eeca-40fb-98db-df2394929e86",
        "labelText": "PreK",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b40a273d-edd2-4bbc-9a86-dfceaa625333",
        "labelText": "SI/LI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7174fb07-b190-4f33-953c-e0e31bf332cd",
        "labelText": "Mediation",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f11d2656-b3db-4af8-b4f7-e215e192d637",
        "labelText": "OHI",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "df4ab054-9022-496b-a2db-e9c1a21b0ad8",
        "labelText": "Annual Discretionary Project Survey",
        "subtext": "Please provide ratings to the statements below. The ratings are 5 (Strongly Agree), 4 (Agree), 3 (Neutral), 2 (Disagree), and 1 (Strongly Disagree). Questions left blanks will be counted as N/A responses. For a rating of 3 or lower, please add a comment to help BEESS better understand your concerns or needs.",
        "elementType": "Title",
        "isRequired": false,
        "parentId": "00000000-0000-0000-0000-000000000000",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "03610342-3240-4124-98b7-02a54b342148",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "d0fc147d-cbe7-48d9-9d60-02331d4d7064",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "673d9373-94d6-4282-a8ab-42e2b4d04e20",
                "parentId": "03610342-3240-4124-98b7-02a54b342148"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "98075e12-f949-4e97-98c6-36cb4ac8fb68",
                "parentId": "03610342-3240-4124-98b7-02a54b342148"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "78a8fd75-4880-43da-b22c-bc0bcc9702ad",
                "parentId": "03610342-3240-4124-98b7-02a54b342148"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "43c2141f-45f0-4ad6-863d-b457cab64ec9",
                "parentId": "03610342-3240-4124-98b7-02a54b342148"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ac31aedf-a386-4189-a941-2ff1bae6dd40",
                "parentId": "03610342-3240-4124-98b7-02a54b342148"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "af9a0894-4888-44c4-8594-252746959283",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "d0fc147d-cbe7-48d9-9d60-02331d4d7064",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "7831a759-5f18-4476-89b2-e55974ef54a3",
                "parentId": "af9a0894-4888-44c4-8594-252746959283"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2003d49b-affe-43db-abf0-e26ca6431d2b",
                "parentId": "af9a0894-4888-44c4-8594-252746959283"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "9026e07c-9747-4029-aa3a-6963cbf8236d",
                "parentId": "af9a0894-4888-44c4-8594-252746959283"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "8ca3c947-35f8-442e-8a42-8d698381bd7b",
                "parentId": "af9a0894-4888-44c4-8594-252746959283"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "710d56e8-ff08-421c-ab93-79d1a00edb55",
                "parentId": "af9a0894-4888-44c4-8594-252746959283"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "26ec100c-a08a-45f3-b27c-d324ced74f84",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "d0fc147d-cbe7-48d9-9d60-02331d4d7064",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "fb4bb6ac-dcbe-424f-beea-0f87683930f3",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "22d5a1af-8d4f-49b7-832e-a45f0b13ec5e",
                "parentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d7785158-4c75-4416-8fb1-503fa827d1af",
                "parentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "1b50b679-2d1c-4774-9a10-4864dcfbcbff",
                "parentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e722b69c-104b-4fdf-8cba-5b8dfa3bc435",
                "parentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "e017143e-ba46-451b-b205-89036c9d4218",
                "parentId": "7786c2a9-e27d-43c3-9ae0-6aa7e567f063"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "fb4bb6ac-dcbe-424f-beea-0f87683930f3",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3b86e409-b685-4d2b-9f3e-4fa61cefe7ac",
                "parentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c60e6b4d-5cda-415e-a8e6-7d5f457645d5",
                "parentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "8ff98bc2-f55d-46a0-8c5e-0d0c003a0242",
                "parentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "543530bf-e420-4419-a1d9-749524c5b907",
                "parentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "8ce96aa7-4003-4707-b60c-4ab6992ff9d8",
                "parentId": "73e236ec-9d93-4a0f-98c9-8bc83ff627f7"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "59208385-7216-4b99-b2ab-9741a15bf516",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "fb4bb6ac-dcbe-424f-beea-0f87683930f3",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "182cb593-f1a9-4a2e-a337-117c076d6e33",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9d9bfba7-a248-4b95-b1d5-3e7e91cfa30b",
                "parentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c010adbc-21d7-4092-a0eb-f8fdefa10dd4",
                "parentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "d1e53d25-155f-4224-a94b-4dc29d79956a",
                "parentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a097a7c6-2405-419e-9059-29abf1039f15",
                "parentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a2eb5891-7caa-4090-80e5-10b2bcf05a55",
                "parentId": "ce1aceee-349b-4fa6-91b2-324328fc8f3c"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "182cb593-f1a9-4a2e-a337-117c076d6e33",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "fc65602f-e59b-4432-b9d1-fd52940bb580",
                "parentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7fa475ed-703c-42f8-8fea-ec54c5f86754",
                "parentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "f8bd42a1-c769-436a-a422-0b60caeb5be5",
                "parentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "4b5d5f47-7f27-45ff-8b82-3941c98dd19d",
                "parentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6caf9a8a-acf2-4636-909a-fe15ecafed2e",
                "parentId": "5b5faa6d-7a8b-438e-b51b-8abda310c83d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b67e9ae1-8298-4e5b-99b2-c3e205cdd022",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "182cb593-f1a9-4a2e-a337-117c076d6e33",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1009fb68-9678-4975-b435-2febccf389bb",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "3ac62546-5201-4e61-810f-21f317b5e1fd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5cede5db-1047-4c5a-a642-d66bcfd1c515",
                "parentId": "1009fb68-9678-4975-b435-2febccf389bb"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "bd4cfd23-2e29-4a2e-9f03-3f1e7385530e",
                "parentId": "1009fb68-9678-4975-b435-2febccf389bb"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0acf5d9c-d1d5-4e02-911a-25b965d644d3",
                "parentId": "1009fb68-9678-4975-b435-2febccf389bb"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "29e15d10-0651-4fd6-b945-970cc978a55b",
                "parentId": "1009fb68-9678-4975-b435-2febccf389bb"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "55f2ec5f-1e50-4529-8840-da431f26d55f",
                "parentId": "1009fb68-9678-4975-b435-2febccf389bb"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3a129b75-0d08-4ca8-9fb5-73a419f2ee54",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "3ac62546-5201-4e61-810f-21f317b5e1fd",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "3ac62546-5201-4e61-810f-21f317b5e1fd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "357f99d1-388d-4644-8594-e3bbad5e6b5c",
                "parentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b2a795a1-2c29-4f6a-b20e-aaabbfe1f95d",
                "parentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "65d014c4-c48d-4375-a655-7e9e348376d0",
                "parentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e733d2b7-b8be-4415-9bc9-34013df17ac2",
                "parentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "21af09ed-b829-45b2-beb7-02f31a7ff203",
                "parentId": "820e9874-8dcf-4f2a-b044-9df6c1f0b650"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5009e50f-4c4c-4007-bf97-279250a2c0d5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "0dbbcc57-23c2-4dea-bdeb-7f4a7f3d3ddf",
                "parentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2d5ef5df-fdc0-47ed-92f3-f1c0b931cc08",
                "parentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "986a81bc-5f5e-4450-978f-c49343bda368",
                "parentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a09f2fa8-4de0-4a67-8583-d1441e6a181c",
                "parentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "cca3a3b5-03c7-408a-a0c6-21428549af8f",
                "parentId": "ad529844-1f35-43f5-acc2-13bd7f81ffd4"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c8758494-af50-4d6f-ac48-33184baf1e71",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "5009e50f-4c4c-4007-bf97-279250a2c0d5",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8ddb7f72-0705-400b-a714-b71898293344",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5009e50f-4c4c-4007-bf97-279250a2c0d5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "16490de5-88a4-4d6a-a7d2-816c48676e81",
                "parentId": "8ddb7f72-0705-400b-a714-b71898293344"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7b0df5bc-6e7f-46ae-bc01-ebbbb88b7e80",
                "parentId": "8ddb7f72-0705-400b-a714-b71898293344"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "39a21942-d86f-4564-a6bf-44f548f2e83e",
                "parentId": "8ddb7f72-0705-400b-a714-b71898293344"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "2f441342-1100-4a06-941b-ce5250268d58",
                "parentId": "8ddb7f72-0705-400b-a714-b71898293344"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "179e4b11-c68d-49c0-bbe0-2353e1385b2c",
                "parentId": "8ddb7f72-0705-400b-a714-b71898293344"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a40c2a43-8ec2-47ef-bbbf-4842a57a15f2",
        "labelText": "General Comments:",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "b6f576af-8ba0-47ab-9ebc-2810e2e4a262",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "a89cf768-0c00-4269-9270-287dccdd8bf2",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9016f684-12f0-41e4-8a6e-395737c23521",
                "parentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "0dd368f1-ac23-4e74-8070-b2ffb44b2b50",
                "parentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "9490fc3d-b3cf-4e1f-a871-4a5ae3a9f5e0",
                "parentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "5bce111d-df80-4790-96a2-ef8fe485f6f1",
                "parentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "37c51dff-61c9-4b5b-8e2f-a79f6926558d",
                "parentId": "c272b6da-4681-4ca6-9982-5e2e89ddd48d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6496c20f-b014-4aec-aacb-702995418fe5",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "a89cf768-0c00-4269-9270-287dccdd8bf2",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "af989240-2046-476a-bc27-ae05c474ddce",
                "parentId": "6496c20f-b014-4aec-aacb-702995418fe5"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7c3df7ed-ecae-48ef-8c21-d3f854b9dc5b",
                "parentId": "6496c20f-b014-4aec-aacb-702995418fe5"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3dccdecc-fd4c-431f-ba43-595f76c11e78",
                "parentId": "6496c20f-b014-4aec-aacb-702995418fe5"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a824458d-6e59-46c5-a7ff-c42af0cad95c",
                "parentId": "6496c20f-b014-4aec-aacb-702995418fe5"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6f93f864-72c4-436f-a890-78fae8babfb1",
                "parentId": "6496c20f-b014-4aec-aacb-702995418fe5"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "aea86a49-5e78-4de0-9535-80b9d245ba58",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "a89cf768-0c00-4269-9270-287dccdd8bf2",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5b2b26fd-de0c-4837-a5f5-3518b264d23f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "305ac552-3ba2-432a-a8ca-e09deeef65b9",
                "parentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d91eb170-ceb2-4dd7-b458-c4b1723c8223",
                "parentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e02270a1-befe-437b-80e1-76c08771e58c",
                "parentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "02c6a549-20aa-4b82-a21f-7f45d832c2aa",
                "parentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "21665e04-7e00-401f-8de6-2283bd3cfded",
                "parentId": "e4a5e5e8-25b2-4708-8a5e-018564f648b6"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "85617610-0b29-476d-b388-614679c0ff35",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5b2b26fd-de0c-4837-a5f5-3518b264d23f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5d9767c8-7011-4378-8a7c-65bdbbf1df1a",
                "parentId": "85617610-0b29-476d-b388-614679c0ff35"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "dd58a8d0-2d2d-40e1-b123-ad5cbe31cc69",
                "parentId": "85617610-0b29-476d-b388-614679c0ff35"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0052795e-42bf-4d7a-b0f8-daeb6ceab22c",
                "parentId": "85617610-0b29-476d-b388-614679c0ff35"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "38e8f05a-298d-484e-95b6-fed9021cd190",
                "parentId": "85617610-0b29-476d-b388-614679c0ff35"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "7d8dfb06-db87-4168-b907-76240f4b9d0f",
                "parentId": "85617610-0b29-476d-b388-614679c0ff35"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fc00ecb0-ee1a-401e-91cc-a29dc76c250c",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "5b2b26fd-de0c-4837-a5f5-3518b264d23f",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "31de25dd-b330-405d-9f61-3d7b727ec014",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "26de59eb-573f-448b-a89d-35f89d279b24",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "6ff50a72-2dc6-4821-92ec-98e1c2ad86be",
                "parentId": "31de25dd-b330-405d-9f61-3d7b727ec014"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "6e728901-aac1-43d1-a7db-fff86c8f241c",
                "parentId": "31de25dd-b330-405d-9f61-3d7b727ec014"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "aff6fac7-c77c-4e9b-9842-2924458bd1b7",
                "parentId": "31de25dd-b330-405d-9f61-3d7b727ec014"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "7b228bbd-0112-4d93-8046-a245933eed9b",
                "parentId": "31de25dd-b330-405d-9f61-3d7b727ec014"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "4dd2cf45-93c1-44c4-ae21-99c9c5ee278c",
                "parentId": "31de25dd-b330-405d-9f61-3d7b727ec014"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8dee9c91-6887-4436-8900-88c00c973665",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "26de59eb-573f-448b-a89d-35f89d279b24",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4d3082df-6c6c-432e-b32e-9016a9b53982",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "26de59eb-573f-448b-a89d-35f89d279b24",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "91b785e1-f274-4abe-b04d-cb00cd8735b9",
                "parentId": "4d3082df-6c6c-432e-b32e-9016a9b53982"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "1f545efc-0239-48d9-bf4a-37a0b41f7044",
                "parentId": "4d3082df-6c6c-432e-b32e-9016a9b53982"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "92674c86-ee04-407b-870f-9b6cbb5b616f",
                "parentId": "4d3082df-6c6c-432e-b32e-9016a9b53982"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a5b96c96-fd1e-4fce-afa3-07fca8288f62",
                "parentId": "4d3082df-6c6c-432e-b32e-9016a9b53982"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "58a877c6-b216-45ea-ab44-1662b4a4ade7",
                "parentId": "4d3082df-6c6c-432e-b32e-9016a9b53982"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "86b9374c-f188-4290-8721-3a035fbafd8a",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "daafaffb-b143-4dbd-865e-70e9dfd92fc6",
                "parentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "31b58e33-d4c6-4c68-83cf-368eac160867",
                "parentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3a8ea605-7c81-457a-97c5-c140b44c916a",
                "parentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "30d7a670-708d-4355-b8c5-a7740a0ecc19",
                "parentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a620b5ed-29d1-4c42-967e-c6ac947f7482",
                "parentId": "9ab004e6-4a10-4fe9-977e-b3eb078efd69"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "86b9374c-f188-4290-8721-3a035fbafd8a",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "ac4e184c-b7d9-45a7-b5cf-ffd582a30c2e",
                "parentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "8f120a31-d082-4168-8173-b65ee09843a6",
                "parentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3b894030-16b3-402a-8f0a-fc1a415a1b1e",
                "parentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d0c1e231-379f-463d-8cd4-6d8735d088bd",
                "parentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "189d41bd-7488-4717-83c6-13c62549aeaf",
                "parentId": "a2184aad-adf3-4eeb-8687-b4d247f7bbf9"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "23afc982-caa6-4ce8-a2c9-fdb695d25b2f",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "86b9374c-f188-4290-8721-3a035fbafd8a",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9734fcbd-85b5-4921-8094-77ee9b655688",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c99c0b8e-6ce6-43ba-8ec5-415b4045775a",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "28734aad-b8e2-468a-9f73-782eb0440501",
                "parentId": "9734fcbd-85b5-4921-8094-77ee9b655688"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "54b283ac-2fc3-4de8-a11f-819f1f7f3fcd",
                "parentId": "9734fcbd-85b5-4921-8094-77ee9b655688"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e1fb6d89-8f58-4ac1-9aba-3c56cb624d4a",
                "parentId": "9734fcbd-85b5-4921-8094-77ee9b655688"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "b9c2a902-e27c-4318-abec-697405ebd22e",
                "parentId": "9734fcbd-85b5-4921-8094-77ee9b655688"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "db627c7c-3d43-4230-95d8-80faf3751bdc",
                "parentId": "9734fcbd-85b5-4921-8094-77ee9b655688"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c99c0b8e-6ce6-43ba-8ec5-415b4045775a",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "99df2c25-4020-4933-b59f-a49e9c1ad7da",
                "parentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "f02e9dcd-f480-48cf-9faa-568456f0cd69",
                "parentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "08fce332-f635-48c9-8381-34f4d4b15dc7",
                "parentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c96a0601-5fa4-40a6-b9ec-b9aa38377e78",
                "parentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "9645e46b-894c-41bc-bdff-1369520cf248",
                "parentId": "fa2f5e5d-1e4a-440d-bf2e-97f21574dd67"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e29720bc-5e0f-4e90-99c6-d3971b59227b",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "c99c0b8e-6ce6-43ba-8ec5-415b4045775a",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c78b5325-71ed-4eaa-8ee7-415fa3673ede",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "f7562886-15c6-4e53-a672-57c4a6070fad",
                "parentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "373d2c37-68b1-407a-afa3-758f94f7aba6",
                "parentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "f86abc4e-660f-4251-af47-0bdd9033ec17",
                "parentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e0ea5f8a-04fa-4d02-a113-d66ec5ef4a98",
                "parentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "d7c84163-ac99-4d5d-a105-ce1132f9a397",
                "parentId": "627f0b54-01ef-4187-85d9-a8a544fab8c6"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c78b5325-71ed-4eaa-8ee7-415fa3673ede",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "b747d770-4a56-4426-befb-c1f40bbb7cf6",
                "parentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "caa159ee-d267-4508-b315-410b74be4e1a",
                "parentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b3ab7648-780f-4900-9b6c-47b38f8f804e",
                "parentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "08f5459a-25ef-49ae-b2d7-cccbbd96ee95",
                "parentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "bd81a30c-a05c-42e3-928b-81e9f023608a",
                "parentId": "dc6b2563-db33-4eec-b889-ca1cc3fb5de6"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b93020c6-15d7-47dc-990a-e8936c584ba7",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "c78b5325-71ed-4eaa-8ee7-415fa3673ede",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e3a9b499-1549-4cee-a202-167d61cbdca5",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "8e3322c5-00e8-4caf-ba6e-468581c2603b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "6fff7f45-2b6b-4318-94fd-39ec26b7f462",
                "parentId": "e3a9b499-1549-4cee-a202-167d61cbdca5"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "e63907d9-2c6c-4f30-839e-2b7efcabd545",
                "parentId": "e3a9b499-1549-4cee-a202-167d61cbdca5"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "d99f7b3a-8421-4d1c-a8ec-5dfbc0c41a2e",
                "parentId": "e3a9b499-1549-4cee-a202-167d61cbdca5"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d51c9033-6003-45d8-8c51-15660b6c1787",
                "parentId": "e3a9b499-1549-4cee-a202-167d61cbdca5"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "9a52b11b-e76b-4664-b677-20b06ae1c8c2",
                "parentId": "e3a9b499-1549-4cee-a202-167d61cbdca5"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1871b2b4-8bc6-430b-9acf-1b7838445efe",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "8e3322c5-00e8-4caf-ba6e-468581c2603b",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "8e3322c5-00e8-4caf-ba6e-468581c2603b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5eb213d7-85ea-4c9c-8391-e57b3d70f6aa",
                "parentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d5f3dc34-a671-4b05-8cb0-fd6fe40f0579",
                "parentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "7db36ece-5abe-4712-82cc-42eb62700a60",
                "parentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "96e73bb6-249a-4787-b563-5957d0f630a9",
                "parentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "4d81f5b5-3bfb-4c2a-bf8e-ae4711387c27",
                "parentId": "a1b469e5-6d84-4c1a-8314-85dece44ac14"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "9cefacdb-5c8b-42ce-88e4-46e26821960e",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5b6eb157-612d-4456-9daa-634bd1b94578",
                "parentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "a4df02ea-5a29-42fa-8f55-0b206f8033c1",
                "parentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e04fd4dd-54ab-4b73-9049-a8c5c646ffbb",
                "parentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "2f8abcad-1601-4385-b613-9e1020dc775b",
                "parentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "0a54ee7e-10d1-44fb-b6da-0d94f5a6e51b",
                "parentId": "85f7ca44-9a54-4071-bba1-02c7df432ea9"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0a19b5f9-d06d-465f-9d50-17d851e329f2",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "9cefacdb-5c8b-42ce-88e4-46e26821960e",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "9cefacdb-5c8b-42ce-88e4-46e26821960e",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "57024589-2dcc-4a3f-964b-035a772cde7f",
                "parentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7a1283fa-3f6f-46e0-95ec-3c5819c5641c",
                "parentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c91530be-2b34-4212-b962-07467376acdf",
                "parentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "136e718c-efdc-4f21-8bf3-94e5b8c4f83e",
                "parentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "36c1cd73-e065-47eb-a6c4-686b85b9cbda",
                "parentId": "1aab9b61-898d-4451-9ed0-8bd700658ebd"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "883214a0-8150-4b43-8064-23c7eaa52c7b",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "52078704-2ea9-46d8-8fb5-47b9e7398d24",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "2a650422-729f-47a8-8daf-3927611737f9",
                "parentId": "883214a0-8150-4b43-8064-23c7eaa52c7b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "ad882ff8-60a9-4064-b433-f3c7ba6cc04d",
                "parentId": "883214a0-8150-4b43-8064-23c7eaa52c7b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "da79e214-8e44-4d47-9b51-c563d1328485",
                "parentId": "883214a0-8150-4b43-8064-23c7eaa52c7b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "adc3be39-0e76-40c2-af6c-a8eaaccd9165",
                "parentId": "883214a0-8150-4b43-8064-23c7eaa52c7b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a0c5df27-dd95-4bdc-befd-0cda4e446c85",
                "parentId": "883214a0-8150-4b43-8064-23c7eaa52c7b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "52078704-2ea9-46d8-8fb5-47b9e7398d24",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9a56febb-d788-4ac4-9706-deed063068b7",
                "parentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "46d15fd1-a885-4253-9c8c-24665d93da32",
                "parentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "86a957e1-d092-44c8-b538-bff8070c7f41",
                "parentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "5d7f7bd2-230c-4479-85d5-b13e956158e1",
                "parentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ac91dc3f-56d4-492f-afdb-d3b2bdbf017f",
                "parentId": "9d8eb8b1-64a3-47f4-b7c6-ab38e17976fc"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "671df5b5-ee34-4de2-9577-fdfca1b2d5dd",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "52078704-2ea9-46d8-8fb5-47b9e7398d24",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7572276a-0be5-45da-96f9-d4618d280f07",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "df27f668-de78-4bb9-9f20-4824d59d2c50",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9ffcd925-bec4-41d1-b547-99465fe70f93",
                "parentId": "7572276a-0be5-45da-96f9-d4618d280f07"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "4e167521-511d-4c99-8f88-0c70de875e65",
                "parentId": "7572276a-0be5-45da-96f9-d4618d280f07"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3e14fcdc-2801-4c12-9d74-f6a34af50fbb",
                "parentId": "7572276a-0be5-45da-96f9-d4618d280f07"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "142498ed-f1e6-444e-a971-cdf3ba3bed6a",
                "parentId": "7572276a-0be5-45da-96f9-d4618d280f07"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "091017bb-edd8-4a69-86e5-aea7ae79383b",
                "parentId": "7572276a-0be5-45da-96f9-d4618d280f07"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "52e3668d-ff32-4905-ab20-f23a16866bdc",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "df27f668-de78-4bb9-9f20-4824d59d2c50",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "df27f668-de78-4bb9-9f20-4824d59d2c50",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "0781dec9-74ce-47f5-b3a9-6526d65be1da",
                "parentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "fe2315a9-d7c8-4a18-bf62-c34631b5f847",
                "parentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e279d320-03e2-417b-b084-edcacc1a4037",
                "parentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "38de9bc0-c1bc-4c7c-994b-bc7675559344",
                "parentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "fc7c9fac-c79d-46f3-9bb2-d23740b34929",
                "parentId": "ddd0513d-f719-478e-a56c-fb78cf9d5929"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c9938b42-9ff8-4ea3-9ac2-49186afe71f5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "4624b694-10bb-41fd-8791-e22dcb3ee621",
                "parentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "fb5ebc31-d35a-44d6-99fa-6483c5cbfcc6",
                "parentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c72f1f77-8391-41c6-81a9-59ec6d8f1906",
                "parentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "6c6b1b45-21e6-4d20-b0df-f36c909bd485",
                "parentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "46a549e5-1569-428c-ae51-7c0cd272a319",
                "parentId": "ebaa3a9e-c750-4202-8e73-1fb2aa34c65e"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "415f6879-03b5-478a-b23f-a388d3fb4373",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "c9938b42-9ff8-4ea3-9ac2-49186afe71f5",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "c9938b42-9ff8-4ea3-9ac2-49186afe71f5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "65bd4976-86df-4e41-a97f-c3b1d0b436ff",
                "parentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "03d284a6-75db-4a89-af4d-b4115878cb79",
                "parentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "1d3f86c1-a9ab-4ea5-b406-e97f4fce3ce1",
                "parentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "1a8bb05d-7808-42d0-bc37-7ccd9fb4feaa",
                "parentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "06fd8960-20cd-4d0c-ae82-0d519b380cf8",
                "parentId": "1dbd9c0c-6712-4969-9442-d0d7fd7d8d3f"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "03aed25b-6e4d-4bc5-a1bb-4a550966efda",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "ec1f80cd-3296-422c-b6f2-ff51eef12732",
                "parentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c3fddda9-64a3-438b-8f28-aa58477046ba",
                "parentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "13ba9859-df9e-43fc-8a33-dbe729a3f454",
                "parentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "39ff2f1e-afc7-4c73-80cc-c145296ae88c",
                "parentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "665b0119-4f17-4563-96cc-c44638a4c58f",
                "parentId": "f2984fa3-a7b2-464d-9171-4249d95d08b9"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "35012e68-7948-4622-8dea-9de800f4842d",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "03aed25b-6e4d-4bc5-a1bb-4a550966efda",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "add38949-ec7f-4571-a5e1-b6c5ea2d6ee3",
                "parentId": "35012e68-7948-4622-8dea-9de800f4842d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "25473d05-6059-4c6b-82b8-6120c6a30ca1",
                "parentId": "35012e68-7948-4622-8dea-9de800f4842d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e2b5f0fc-c38f-4b08-94ef-ba320c87a3c0",
                "parentId": "35012e68-7948-4622-8dea-9de800f4842d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "3f246386-cbac-4102-80a6-26863ae8ef3b",
                "parentId": "35012e68-7948-4622-8dea-9de800f4842d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "5a9fd968-5da9-44d3-815e-784e1ab9c5e4",
                "parentId": "35012e68-7948-4622-8dea-9de800f4842d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3013c632-56dd-4b57-8e0b-a3de3fa73836",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "03aed25b-6e4d-4bc5-a1bb-4a550966efda",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "1f066f52-126a-439b-b32f-4df5b7560813",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "4cd32fb6-ed23-4f43-b52a-476867b06068",
                "parentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2f641be5-249e-42c0-ad46-9bbf6aa4019c",
                "parentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "a3948aa7-9114-4776-bcaf-80229258cac5",
                "parentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "dd84cc24-8b69-4e88-b0a3-3f4856567bf0",
                "parentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ecab3093-bbab-4d1f-9d1c-877476dabb03",
                "parentId": "521e7519-fe2f-470a-be4e-0b61864fb1ad"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "1f066f52-126a-439b-b32f-4df5b7560813",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "58c8a07f-6ea7-4502-8ad2-503875c7111f",
                "parentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "64cfe8f2-d729-45de-b91c-3c0b1a15a487",
                "parentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c14f4553-3aa7-48d6-a86c-08a9f4b09050",
                "parentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "caf66af3-651b-4588-a500-0662795133e0",
                "parentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "becd1250-b45b-4f0f-b6dd-f9a23dd1fe2e",
                "parentId": "45e9b5e4-6879-4e89-b657-8cb344b570e6"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6eb60d82-496f-4f97-a831-d5527f67e69e",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "1f066f52-126a-439b-b32f-4df5b7560813",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "59169055-b0cc-45ea-af8a-80ff104f0c26",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "e1cd90ad-c57f-4f2d-a54d-4ec616c5cd8f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "60503fde-2244-4482-aad2-56ba6d66f18f",
                "parentId": "59169055-b0cc-45ea-af8a-80ff104f0c26"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "a370a14f-3e3a-4f1d-b62a-e4044ef46931",
                "parentId": "59169055-b0cc-45ea-af8a-80ff104f0c26"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3ec62fc0-0789-457f-9bb6-6b8474fe8244",
                "parentId": "59169055-b0cc-45ea-af8a-80ff104f0c26"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "aaf5cbf6-4528-46c7-b88c-4368abb582fd",
                "parentId": "59169055-b0cc-45ea-af8a-80ff104f0c26"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "20880aaa-f42a-4d23-8a50-7b262db7faf4",
                "parentId": "59169055-b0cc-45ea-af8a-80ff104f0c26"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "e1cd90ad-c57f-4f2d-a54d-4ec616c5cd8f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "be09596b-5759-4028-b746-1330cb38749b",
                "parentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "154899ec-7d61-4cd1-96d9-9f6bf936a839",
                "parentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "ee962047-b0cd-41bd-8936-55dc16ded6f4",
                "parentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "3dacc1cb-f482-4088-b998-f85f145e5e94",
                "parentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "f9cbfa73-7e27-4e02-a065-77c901cea197",
                "parentId": "4ba5d3c0-0f94-4fe7-98ab-bedb920eddd1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "31671994-5eb2-47cf-a75f-fcfd6d23ea67",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "e1cd90ad-c57f-4f2d-a54d-4ec616c5cd8f",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7890c027-adcd-4d68-9555-528f254ba5f5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "c79ad728-9c2f-487a-9878-d6e28af683db",
                "parentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "e852b6e0-e201-4fd0-8a30-ab623b84f2bf",
                "parentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0efed83b-ed3d-4e9a-8c13-adbe9a7c8395",
                "parentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "94e9dbf4-9a78-4ef5-865b-a99cceec7fea",
                "parentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "68cf9a94-535d-426b-bbdc-c78d2183ecc8",
                "parentId": "0ec70185-9efa-4fbb-ba90-4dbf7f89d325"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "76be59f8-58e6-4371-aa48-9a794e39abd1",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7890c027-adcd-4d68-9555-528f254ba5f5",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "e5b81317-4f3e-4e6b-8dc1-484158136509",
                "parentId": "76be59f8-58e6-4371-aa48-9a794e39abd1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "ce983c27-31b6-4721-a209-06b2244c8259",
                "parentId": "76be59f8-58e6-4371-aa48-9a794e39abd1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b8b4f018-acf1-49ba-bceb-44bf5d77a8ff",
                "parentId": "76be59f8-58e6-4371-aa48-9a794e39abd1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d0cd2367-759f-4cb1-ad18-465c9a0c536a",
                "parentId": "76be59f8-58e6-4371-aa48-9a794e39abd1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "7296c1c5-3bc2-4fb7-916e-a9ffdda7dfd3",
                "parentId": "76be59f8-58e6-4371-aa48-9a794e39abd1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6f07f7c0-8db1-4799-bf15-bb362e3e6491",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "7890c027-adcd-4d68-9555-528f254ba5f5",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "98e54883-c12b-4fed-8353-02b0d6fb559b",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "cca8f705-bb3d-48fa-b007-5293df8222b3",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "93e181df-8d9f-49bd-8f1e-9823991281dd",
                "parentId": "98e54883-c12b-4fed-8353-02b0d6fb559b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c8daff25-e691-4baf-9f33-d436ce5cbf3f",
                "parentId": "98e54883-c12b-4fed-8353-02b0d6fb559b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "184c0df9-4725-43a8-a69f-6868333dad3c",
                "parentId": "98e54883-c12b-4fed-8353-02b0d6fb559b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "2d47043b-d8f5-4c8b-868f-eec77822d50c",
                "parentId": "98e54883-c12b-4fed-8353-02b0d6fb559b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "bfd81f0a-e4d5-44eb-bc73-ded033417fa1",
                "parentId": "98e54883-c12b-4fed-8353-02b0d6fb559b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "cca8f705-bb3d-48fa-b007-5293df8222b3",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "1d947c40-3083-444c-9f41-2fc33906c654",
                "parentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "e42eee42-ea6f-4574-8cbe-055e8af01dd2",
                "parentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e6748342-484c-4ecc-ab2b-35e29cd0d249",
                "parentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "7312ccf3-83b5-4f75-bd5a-f701e4364559",
                "parentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "d1b9016c-2b21-4b7b-bf8b-26b4b546e3c8",
                "parentId": "36843a67-3fb7-4be2-ac0d-3e7b1f9b03d2"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e9501a13-58a9-43cc-bb7a-90f5c25d7538",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "cca8f705-bb3d-48fa-b007-5293df8222b3",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5a8b61a3-309b-4055-b8b1-70d19a07f158",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "8bf9756d-f73b-4ee5-b364-52d3df5476de",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "8bf9756d-f73b-4ee5-b364-52d3df5476de",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "b119cb3d-ae66-4c0d-8a8e-9510f19b7990",
                "parentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2469f331-85f8-41ec-8df6-b836bddce9ef",
                "parentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0f74e209-d31c-4c4d-a2f3-e98073b0ce20",
                "parentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "07ed417a-aa67-4d3a-8978-94173a43e081",
                "parentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "15ee2b3d-956f-4585-8a8a-3368aa7ddb14",
                "parentId": "1e573f01-0ac7-4bd9-8b92-993126db2ec4"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1c88ac35-d6f1-4e0b-8734-af164431241f",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "8bf9756d-f73b-4ee5-b364-52d3df5476de",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "4d1550f6-1e94-4192-8124-436683a2408b",
                "parentId": "1c88ac35-d6f1-4e0b-8734-af164431241f"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "4de7b049-ce4e-4de4-bb7c-34a0b6355b4b",
                "parentId": "1c88ac35-d6f1-4e0b-8734-af164431241f"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "97536d87-55ef-46cb-a78b-3c602cf2238c",
                "parentId": "1c88ac35-d6f1-4e0b-8734-af164431241f"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "bf6aea47-c943-455f-b47a-3c8939cade45",
                "parentId": "1c88ac35-d6f1-4e0b-8734-af164431241f"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "b88ed1f8-5c50-402e-ad97-b45db10a3224",
                "parentId": "1c88ac35-d6f1-4e0b-8734-af164431241f"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "befa0ba6-57f9-4b3a-9b05-22598df70b40",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "75c1ea16-6885-481a-acba-557eb1b53906",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "75c1ea16-6885-481a-acba-557eb1b53906",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "a1b06470-f57f-4b50-b02e-812c28123812",
                "parentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b88d6dbc-796e-43b4-a922-ad88c8204ebd",
                "parentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "f2a6abb1-18d3-485c-951e-d7821639def4",
                "parentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "6dfe4d3f-ea9f-4abc-bf6e-4ad1d1c5da61",
                "parentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "560d4d2f-ce8f-4b05-8d5a-3630076a5585",
                "parentId": "7aed93b9-0e5b-4810-9561-5eadce5daa06"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "75c1ea16-6885-481a-acba-557eb1b53906",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "438f3592-d255-4734-bae3-18a7d59adeaa",
                "parentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "22e3be02-aef3-4455-89af-6aef37d8a9e2",
                "parentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "510e1a56-4b8e-4213-9322-abfadd34ec42",
                "parentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "cba3fb89-b041-4716-9653-942e46405e10",
                "parentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6ea62952-0a09-4c72-abbf-119ea4c3bf32",
                "parentId": "dd4d828c-2b7e-4c58-951d-f80bedce5cf3"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a524c4b4-1081-46bc-992b-375e4e12e2ad",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "cb66856b-c7ad-4d2c-bb45-5709710f4162",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5999f36e-a061-42df-b142-7eb5a6c944ce",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "cb66856b-c7ad-4d2c-bb45-5709710f4162",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3fefae09-887d-46a6-b87d-692db101b976",
                "parentId": "5999f36e-a061-42df-b142-7eb5a6c944ce"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7c283010-f024-49e6-a600-d617c9fd8bb8",
                "parentId": "5999f36e-a061-42df-b142-7eb5a6c944ce"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "4fbe073b-e601-45a8-a7ed-360199e7aae9",
                "parentId": "5999f36e-a061-42df-b142-7eb5a6c944ce"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "7ebd2609-8587-4295-95bf-22bc221343ac",
                "parentId": "5999f36e-a061-42df-b142-7eb5a6c944ce"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "9d05aee8-d57c-4b87-9e0d-efae5cd63353",
                "parentId": "5999f36e-a061-42df-b142-7eb5a6c944ce"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "aacf7723-0763-4df2-abb5-afdda8eae656",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "cb66856b-c7ad-4d2c-bb45-5709710f4162",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "780e34ac-6f73-4dcb-9d77-5f5369a47b2f",
                "parentId": "aacf7723-0763-4df2-abb5-afdda8eae656"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d557b8c4-60fc-4789-82b5-14dfff74e8bb",
                "parentId": "aacf7723-0763-4df2-abb5-afdda8eae656"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "2447aca9-9c03-4898-be2a-333d7ea4c389",
                "parentId": "aacf7723-0763-4df2-abb5-afdda8eae656"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "915c5f75-8176-4970-aa31-9e20a5febe05",
                "parentId": "aacf7723-0763-4df2-abb5-afdda8eae656"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6d70f42c-d017-4f36-bead-50fc959c6b39",
                "parentId": "aacf7723-0763-4df2-abb5-afdda8eae656"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "904efb58-b7ef-4e92-951e-41bf669147f2",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "b72fc9f8-c547-43ae-9342-595e02ca71cb",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "b72fc9f8-c547-43ae-9342-595e02ca71cb",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "4a12e88a-335b-4cc0-9079-c44ddd7945f1",
                "parentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "143f2e21-076d-4597-8b96-0f258d3e0cf9",
                "parentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "74ff4c53-1860-4d68-a6a8-cb49baf59a25",
                "parentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "ef6b4129-95df-4f1b-99ca-4d5f8e63614b",
                "parentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "30046b3b-ee7a-4da9-a559-0289aa8c5d81",
                "parentId": "0e615e93-ae7f-4058-8ff4-95b2e4839b0b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "b72fc9f8-c547-43ae-9342-595e02ca71cb",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5272732e-b0b6-46d7-b665-f3b4d349ff6c",
                "parentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "837b416d-c775-471a-b0d8-76d634d2a2af",
                "parentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "9a5a9442-71de-4639-9c45-ef505cb0b729",
                "parentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "780db3a1-a656-4a03-bb0c-490765b7a061",
                "parentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "7d0f9a3b-4240-4be2-9705-eebdd9714d9f",
                "parentId": "9a30c9e9-e8df-4b85-ae9b-b67e6eaffb25"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "23f45b37-d933-41cd-b75d-78eb80eebd3f",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "2a61b1e3-7d9e-41a8-b883-5eb1e41a636f",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "84f5e3ec-aef8-4122-8640-8279af80be30",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2a61b1e3-7d9e-41a8-b883-5eb1e41a636f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "e9d70973-9818-4c31-9014-03d01a114fc0",
                "parentId": "84f5e3ec-aef8-4122-8640-8279af80be30"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "615f6e85-1984-49f6-aa8c-4772842f9cda",
                "parentId": "84f5e3ec-aef8-4122-8640-8279af80be30"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "33e6367a-f180-4a71-b6c0-6236cf2b1778",
                "parentId": "84f5e3ec-aef8-4122-8640-8279af80be30"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "2aac691f-4030-4114-b503-ab569ff6f964",
                "parentId": "84f5e3ec-aef8-4122-8640-8279af80be30"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "37481ef9-9762-4f9c-9de2-9c6be92471c2",
                "parentId": "84f5e3ec-aef8-4122-8640-8279af80be30"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2a61b1e3-7d9e-41a8-b883-5eb1e41a636f",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "db922c3d-eff3-4acb-a343-9ff7fd6d97aa",
                "parentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "5d71c42b-733a-4061-b0f3-f799b15b3e7a",
                "parentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "91f5b0e1-1d02-49ef-9ebe-e0b0cadc583e",
                "parentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e4833fef-818e-4096-af35-8652dfb4a55d",
                "parentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6b72c86e-54e3-4177-808b-f1c2bb94dc12",
                "parentId": "5489d47e-9a1b-4813-a9f2-d9cde9700045"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "d68426e0-359c-4e04-b627-673d9066c5d7",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "69c2d184-9825-4997-88a5-60726de2e20b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "d687949c-b50a-4de0-afeb-e83da073104d",
                "parentId": "d68426e0-359c-4e04-b627-673d9066c5d7"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "5a5eebc9-8e38-4a7c-83f1-1e0c72560fe5",
                "parentId": "d68426e0-359c-4e04-b627-673d9066c5d7"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "f4f621d9-11c4-4b56-97a7-0c923af84298",
                "parentId": "d68426e0-359c-4e04-b627-673d9066c5d7"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "3d056d5e-8b22-420c-a586-9a8e655aaa34",
                "parentId": "d68426e0-359c-4e04-b627-673d9066c5d7"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "b6115caa-79ac-45f2-9f4c-bb3ac41a10fa",
                "parentId": "d68426e0-359c-4e04-b627-673d9066c5d7"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "83be5690-ad4b-4d35-9241-9c0c93d690af",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "69c2d184-9825-4997-88a5-60726de2e20b",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "69c2d184-9825-4997-88a5-60726de2e20b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "dfd3d5e9-5d85-4895-8b29-478e112aee69",
                "parentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d20fe280-dff8-44ae-9493-1485937349ba",
                "parentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0237ad44-91fc-44e3-a6be-f4ce80402ade",
                "parentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "1a7db987-19e4-45c8-b1ef-82324daae195",
                "parentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "3d503071-3dc3-453b-a33e-3a5044f4d09b",
                "parentId": "7452b38d-739f-4ef0-9c7f-e913bd97c9b5"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "94394acc-b75e-422a-bde7-01676f50740a",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "3ff45c6e-1875-4341-9a22-60b287639d27",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "3ff45c6e-1875-4341-9a22-60b287639d27",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "2b25a135-21ea-4acd-866b-3eb9450bf935",
                "parentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "12618430-de0a-4bb8-a105-ca6bd92205a3",
                "parentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "28ea3ca2-ce50-419f-b862-941b251cd6a3",
                "parentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "84d0cc12-2062-4af9-ab26-a9b1bc7ccda8",
                "parentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "890487e7-16c0-43ba-8ab5-a2b07f7d3c80",
                "parentId": "7e41f80c-4946-4dcc-abbf-b99d0b9a4bec"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "acf9f453-a912-4e71-a403-c8def5edbe53",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "3ff45c6e-1875-4341-9a22-60b287639d27",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "8d3b969e-9ee7-48c0-b935-b7178d1c539e",
                "parentId": "acf9f453-a912-4e71-a403-c8def5edbe53"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "9f306ccf-9a28-4aba-b0b9-b9639b5f4307",
                "parentId": "acf9f453-a912-4e71-a403-c8def5edbe53"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "eccf60f3-5966-435e-9e3b-d9bee706c116",
                "parentId": "acf9f453-a912-4e71-a403-c8def5edbe53"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "20ae44ab-5d14-42dd-b4b4-7911f7256e26",
                "parentId": "acf9f453-a912-4e71-a403-c8def5edbe53"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "1384c709-5e2c-40bb-b62c-816a80e29b7a",
                "parentId": "acf9f453-a912-4e71-a403-c8def5edbe53"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3b533b6a-12b7-4963-b61b-330d317dad5d",
        "labelText": "Name",
        "subtext": "",
        "elementType": "TextBox",
        "isRequired": true,
        "parentId": "1be33436-85d5-46cf-a14a-647d36afe715",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f0381da3-06d4-4c34-93c6-56cf39cbce5e",
        "labelText": "Title or Position",
        "subtext": "",
        "elementType": "TextBox",
        "isRequired": true,
        "parentId": "1be33436-85d5-46cf-a14a-647d36afe715",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "da9b0716-aa4e-453b-834d-6df70fa28443",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "737b734f-0c9b-4008-97f0-6d1b5f6bc726",
                "parentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "0d1c723f-6278-43ca-90ec-b685ada43352",
                "parentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "8bcb9695-4d02-4d75-8f61-b20d998f20f1",
                "parentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "ccfa8d1e-7998-4b97-9161-480c580f7c21",
                "parentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "21353202-d044-41fc-9b2d-0d27b50580fa",
                "parentId": "ce15b279-02f8-4e83-bbe0-16d200ecc9d7"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "31410eee-df2a-421e-b7ea-55f4c87792cb",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "da9b0716-aa4e-453b-834d-6df70fa28443",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "da9b0716-aa4e-453b-834d-6df70fa28443",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "92101ed9-aceb-4f81-9021-ef0c74f85648",
                "parentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "543a3f7a-00c9-44bc-85b8-14c89674be3d",
                "parentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "500614c8-40d5-4535-82e7-2a6bcdbf078d",
                "parentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "fe73f99c-3fa8-4df8-8f86-032efb454fca",
                "parentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "17bfc3d8-03e9-42e3-9d1c-2b5b1fb31581",
                "parentId": "f50f27a0-237f-42b8-8355-a0ec5f7ea823"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "aa50cb6d-bf40-4f02-8b2b-71e2bcc1031e",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "1774794b-0e62-4140-b705-663356d39791",
                "parentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b05ae6bd-52ec-4f0e-ade7-1d5826c54d01",
                "parentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "87789fe6-3f38-4b32-bec5-f871c34eb6c8",
                "parentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "8a400d61-a8b3-4965-8ec6-66a2268991d4",
                "parentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "4a9e29fc-1359-45b0-8f96-9e7d40b774f0",
                "parentId": "0bbf7679-51a2-4d3e-abc0-45e251e51c43"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "924bab04-f1c8-4b56-8097-6b99b6bd2f67",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "aa50cb6d-bf40-4f02-8b2b-71e2bcc1031e",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "aa50cb6d-bf40-4f02-8b2b-71e2bcc1031e",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "babdbd42-fbd9-4322-b284-89e5fd5d3d6b",
                "parentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "008d2bce-b37a-4483-9c32-29f4385734f6",
                "parentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "a7efa430-2086-4a62-8236-09c2df7eda6c",
                "parentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "eb20208b-74ac-43a6-9f23-a846537da6fa",
                "parentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "c481f6d9-eb52-49aa-b0b7-97fe8b88ca27",
                "parentId": "1ad7be4c-8b13-4bc6-aff3-ad49b6e3ac7b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fe717543-8919-4f39-8be3-71f378107415",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2c125b5f-7204-4ed0-9865-74601496ca84",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "440a265b-bb7e-4b5f-bc61-4811f0d17a88",
                "parentId": "fe717543-8919-4f39-8be3-71f378107415"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d2dd2701-6be5-40f8-8972-82454e6b62b4",
                "parentId": "fe717543-8919-4f39-8be3-71f378107415"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "75cbadf6-ce15-4c8d-9f62-439d678fe7a3",
                "parentId": "fe717543-8919-4f39-8be3-71f378107415"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "1bf4d047-67b4-4803-90f2-de6abbfc5861",
                "parentId": "fe717543-8919-4f39-8be3-71f378107415"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "4c048be6-5463-4825-9b07-9eda1007eaaf",
                "parentId": "fe717543-8919-4f39-8be3-71f378107415"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1081d5b2-0fa7-4d15-a5bc-838bc35fb5d1",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "2c125b5f-7204-4ed0-9865-74601496ca84",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2c125b5f-7204-4ed0-9865-74601496ca84",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "034a62bb-8d63-49e8-a477-046f5f5d5493",
                "parentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "361eefdc-e6ec-4081-a607-548b2c364e22",
                "parentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "10ebb3f1-779b-41fe-b861-73755c41957c",
                "parentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "2762b9ad-941f-4edb-92d7-caca7b83e9dd",
                "parentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ef734785-1764-4a87-b399-5de697ab7428",
                "parentId": "c6253e6f-3157-4ae1-bea2-fc1b5b8f7810"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c693ea1c-f480-4657-a264-83568234384d",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "72e59ca2-52d8-456d-88d0-75542a4f25a6",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3b377b69-fbdd-4c94-8e43-6d06c8e6fb5a",
                "parentId": "c693ea1c-f480-4657-a264-83568234384d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d61bd464-696e-4492-81b7-0a7b79b5a295",
                "parentId": "c693ea1c-f480-4657-a264-83568234384d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c0c8f922-5ce9-4e39-b940-0a3f699a213a",
                "parentId": "c693ea1c-f480-4657-a264-83568234384d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c74c5922-7cfc-47a8-8b6d-1244bed1a0b5",
                "parentId": "c693ea1c-f480-4657-a264-83568234384d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "b710673a-2ec8-45a8-a253-011be15af529",
                "parentId": "c693ea1c-f480-4657-a264-83568234384d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ed9a77a5-79eb-4e5d-8dbb-84290d7b5e1e",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "72e59ca2-52d8-456d-88d0-75542a4f25a6",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "72e59ca2-52d8-456d-88d0-75542a4f25a6",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "f46d6d52-5b5f-4c09-bd43-835710099ef4",
                "parentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "83696b7f-ee64-4146-88d4-7d209eb8b62a",
                "parentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "7da757bf-3ab9-40f6-af9f-bd6d0557b488",
                "parentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "0557ae69-c994-4194-ba5a-2f8f6dd02aeb",
                "parentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "82566b5d-25ef-4873-a8f7-b71523d16e20",
                "parentId": "c185ce83-ed17-438e-a33a-b44d1b3833f1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "13940d05-4ed4-4a77-905a-777ae9773380",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5bdd6a7d-6dcc-4544-8daf-3314ca579e37",
                "parentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "952036fd-8309-4623-a30b-f23e968ce095",
                "parentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "59f2a0e1-3a44-4079-adea-37db52123548",
                "parentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "027ccf96-0fae-4a59-af46-aa959b9e5907",
                "parentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "9014be01-3660-44d9-b7c1-43c3607e8c33",
                "parentId": "26007480-c0c4-42b2-87ee-3c5a415d3cdb"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3b28d897-cf2d-411f-a705-e90fbe125b57",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "13940d05-4ed4-4a77-905a-777ae9773380",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "d3108329-90e8-4447-8e5d-abd0e695401e",
                "parentId": "3b28d897-cf2d-411f-a705-e90fbe125b57"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "e8e2dbb6-5f59-4705-a336-0145902a67b8",
                "parentId": "3b28d897-cf2d-411f-a705-e90fbe125b57"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c95fdccc-2720-4e1a-b55e-fcc15f0ca6e2",
                "parentId": "3b28d897-cf2d-411f-a705-e90fbe125b57"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e4ac23de-1459-4a1e-b3da-7bddbd8456f5",
                "parentId": "3b28d897-cf2d-411f-a705-e90fbe125b57"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "af103d08-9f56-45d2-894f-d9a59bbd0079",
                "parentId": "3b28d897-cf2d-411f-a705-e90fbe125b57"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "56e9d19b-6965-4655-8782-ee314d8e693d",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "13940d05-4ed4-4a77-905a-777ae9773380",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3ef30636-2c5f-4294-938a-882cc424b351",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "0e48dd94-e685-4960-8d97-77db345d993d",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "0e48dd94-e685-4960-8d97-77db345d993d",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9900f409-ec44-4ab5-8384-d0acd384f0c8",
                "parentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "5557ad93-afb9-41f4-8651-0e197831969d",
                "parentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b1df0ee6-3c9f-4102-bbc1-342ba79f7660",
                "parentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "9e5b4818-3f51-4c5d-a3aa-569582da47e6",
                "parentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "e3810189-1442-47a7-8d65-5bbe67bef2b9",
                "parentId": "40a8eb36-536f-4d6d-bd6b-a3ce7ce06e6b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "0e48dd94-e685-4960-8d97-77db345d993d",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "feb122e8-cdbb-4531-b984-460209e1bc2e",
                "parentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "3cce965a-a98a-4585-b44c-b3d4c1932aa4",
                "parentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "74ba51e4-ff6d-4089-bd3a-450227839670",
                "parentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "7c8aeeb0-175a-4352-9b8e-b79973649f12",
                "parentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "d0672983-cfa6-42c7-b76c-f49e11e85fae",
                "parentId": "d5c36a13-70c1-4b10-b35a-b3dfd810fe46"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "090df721-0554-493d-aa37-7a1c6d0363cd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "7400e9d8-0444-48bc-89b4-3a5590be087b",
                "parentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "49cff6be-f871-46b3-ab9a-89765d818f4b",
                "parentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c36f1b4e-79f5-424b-9d29-06e8df3ef564",
                "parentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "961b8b94-0131-4b9b-b252-d743ce445617",
                "parentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "10913641-10e2-4933-8765-ada8b861c4a2",
                "parentId": "d0a7d6cd-e761-4cbd-a949-6d2974f7bbc9"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "090df721-0554-493d-aa37-7a1c6d0363cd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "fa96c18b-454a-4177-baa2-4dfc26f7e213",
                "parentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "04dc7cd1-59a3-4e56-8631-4eb65a70b45a",
                "parentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "91eb9cc0-61bc-46e8-bb6c-99c327dda41b",
                "parentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a7515aee-488c-445d-afda-7215f3373022",
                "parentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "fb6087ac-71df-4783-991c-5b0d8ca62656",
                "parentId": "1f3f76b8-0c01-4783-82e5-cc37bbd8718d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5878f851-df5b-4deb-b45b-e63aa2def535",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "090df721-0554-493d-aa37-7a1c6d0363cd",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "bb2a6efd-1198-4155-a798-2a51accb96d8",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "95f5636b-cac8-4f49-b470-7a423ee9d0c1",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "1c69aee5-7dca-4144-81ed-1eb8e2cf581b",
                "parentId": "bb2a6efd-1198-4155-a798-2a51accb96d8"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "0259e588-d67e-4796-ab94-9d7f49f658ee",
                "parentId": "bb2a6efd-1198-4155-a798-2a51accb96d8"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "159508f1-3cda-4cd2-b2b6-6ac42a22d3fe",
                "parentId": "bb2a6efd-1198-4155-a798-2a51accb96d8"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d6789993-8151-4978-87b8-60103242caa1",
                "parentId": "bb2a6efd-1198-4155-a798-2a51accb96d8"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ec59ba51-81f1-4a2b-aedf-279a6aa972ff",
                "parentId": "bb2a6efd-1198-4155-a798-2a51accb96d8"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "63105187-61a2-4597-bf12-478235a6a587",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "95f5636b-cac8-4f49-b470-7a423ee9d0c1",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "95f5636b-cac8-4f49-b470-7a423ee9d0c1",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "d854e55b-a8f3-4fa7-9cfd-8806edb8c341",
                "parentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "06003059-e12c-45cc-89fe-5b4d5c43d1b6",
                "parentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "9f3220b0-2988-45ed-9947-aadf998ab6a6",
                "parentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "3e11b67c-18c5-478f-acb0-49de748197df",
                "parentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ffa8ca2e-a381-453a-b3db-f72763dcc0fe",
                "parentId": "fc919c81-ead4-44b7-a69e-8584f2842d8e"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "db429a97-6d4e-482d-962a-25c9c4241f6c",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "bde813d2-9972-43f7-a231-7d87befe6c49",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6bd34529-0251-4ca2-8037-264e6368e59d",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "bde813d2-9972-43f7-a231-7d87befe6c49",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "6d3b0969-3e1a-4d32-b5f8-2e26f8ba78ea",
                "parentId": "6bd34529-0251-4ca2-8037-264e6368e59d"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "df125997-f19f-47f9-b458-100f8325107e",
                "parentId": "6bd34529-0251-4ca2-8037-264e6368e59d"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "fea0638f-1f4f-4d6c-a6e7-1d02393ec40c",
                "parentId": "6bd34529-0251-4ca2-8037-264e6368e59d"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a691d922-69e7-4b71-9e72-68ef27d163a5",
                "parentId": "6bd34529-0251-4ca2-8037-264e6368e59d"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a8bd2fe4-307d-4ee1-a5ea-6b913539a450",
                "parentId": "6bd34529-0251-4ca2-8037-264e6368e59d"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1cb0a000-41a4-418b-9e82-413cfea0a495",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "bde813d2-9972-43f7-a231-7d87befe6c49",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "b7f361c7-a5e7-4378-8a74-2baf971f76b1",
                "parentId": "1cb0a000-41a4-418b-9e82-413cfea0a495"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b51e2d70-2736-459d-b5e4-3ba6fd769369",
                "parentId": "1cb0a000-41a4-418b-9e82-413cfea0a495"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "36dd8060-f21a-4b68-a58d-453a611c3626",
                "parentId": "1cb0a000-41a4-418b-9e82-413cfea0a495"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "bbdc14b9-32ad-49a6-b072-cea94e2cd9d0",
                "parentId": "1cb0a000-41a4-418b-9e82-413cfea0a495"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "bc4c6fc3-1bd6-4380-89b5-47c2f6fe0981",
                "parentId": "1cb0a000-41a4-418b-9e82-413cfea0a495"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "94c087cc-b56a-43e4-9518-805ab5cdda70",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "ec69f074-90f9-4ddb-a335-e474d0690e0d",
                "parentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "fe1c0fb1-eed6-4544-9d62-3fa2b4d98c86",
                "parentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e09981a4-5535-426e-bc24-5e5873e4e916",
                "parentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c4656f18-179c-4978-a6fb-8f3e46afc509",
                "parentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "2d86e80e-97e2-492c-8310-1e15d1210071",
                "parentId": "c7375d65-8af1-4bf6-9676-4992e77dedd0"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8b61554e-ffb6-4234-b208-94546bdf3d94",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "94c087cc-b56a-43e4-9518-805ab5cdda70",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "82a14ba2-ebda-4434-9b56-7abe381efa40",
                "parentId": "8b61554e-ffb6-4234-b208-94546bdf3d94"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "585cdb78-6dd7-4367-a173-9bb1fb5fcefe",
                "parentId": "8b61554e-ffb6-4234-b208-94546bdf3d94"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "5423fb59-f677-4a00-b981-28cfe499ea5c",
                "parentId": "8b61554e-ffb6-4234-b208-94546bdf3d94"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "37d179b0-c647-40db-9d07-8dd3ada27114",
                "parentId": "8b61554e-ffb6-4234-b208-94546bdf3d94"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6803fd6a-261b-4b72-8ee3-2918eb45f953",
                "parentId": "8b61554e-ffb6-4234-b208-94546bdf3d94"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3aae9616-9357-4f9c-b978-b90024fdb8af",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "94c087cc-b56a-43e4-9518-805ab5cdda70",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "697d7518-177d-4c2e-a750-812a9739ffb0",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3e96b55a-a960-492b-83f7-629036910c48",
                "parentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "20d51a65-0ce7-49e3-be4b-653e15bad20a",
                "parentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "2baa0751-532c-4a64-b81d-ce4c09a5dd9f",
                "parentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "fb729dcc-aab6-48a2-a4e2-7106cea7c3ae",
                "parentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "fb20ff49-81bc-471c-a4ab-494147f7fc6e",
                "parentId": "6e6961f4-d690-47af-9783-260dfe4b5d2a"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "697d7518-177d-4c2e-a750-812a9739ffb0",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "eed40c66-4510-4329-ad70-03b98897d2b2",
                "parentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "4b2f3fd7-2fb2-446c-948f-17c746d5d2a6",
                "parentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e84fa42e-138f-4fc3-a111-215efa910b9a",
                "parentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d7e73872-78e1-4c1f-a9cb-f3c362f8b7d8",
                "parentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "7cf5a053-a942-4614-bf16-7270aab30e90",
                "parentId": "e791a3b9-dc55-4a90-ab37-e52ce5172507"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dc4d0c8f-f20b-41e2-8728-e9c91e2f5729",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "697d7518-177d-4c2e-a750-812a9739ffb0",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a553b511-c783-4682-8777-22cc7db16356",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2372ab93-f405-408a-bd4c-8e82e389b4e0",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "556095a9-8318-4fe8-af22-94b4105dc665",
                "parentId": "a553b511-c783-4682-8777-22cc7db16356"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7c40eb09-51cc-4edf-9480-0a81e7f24bb3",
                "parentId": "a553b511-c783-4682-8777-22cc7db16356"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b1bab4d1-b1a1-401b-94d2-80e0da2e6478",
                "parentId": "a553b511-c783-4682-8777-22cc7db16356"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a23b3efd-583f-471d-b6e1-5a9271aaed6f",
                "parentId": "a553b511-c783-4682-8777-22cc7db16356"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "870dd0fc-edf2-4d83-9a2a-0cedd133fe5e",
                "parentId": "a553b511-c783-4682-8777-22cc7db16356"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "107d611f-1f39-4ca4-bab7-7cac845987bf",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "2372ab93-f405-408a-bd4c-8e82e389b4e0",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0a542844-7eb5-4167-9daf-80e925b81133",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2372ab93-f405-408a-bd4c-8e82e389b4e0",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "7759d215-2ea5-45c7-9961-6042ac551d3f",
                "parentId": "0a542844-7eb5-4167-9daf-80e925b81133"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "da1e0d5b-5c71-4711-884b-446bc2e50fcb",
                "parentId": "0a542844-7eb5-4167-9daf-80e925b81133"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "e0793e64-bf44-4180-b2f4-a89f49a87a1c",
                "parentId": "0a542844-7eb5-4167-9daf-80e925b81133"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d3f793cb-8358-4587-8b0d-f25b197d6385",
                "parentId": "0a542844-7eb5-4167-9daf-80e925b81133"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "e7cd1ddc-9381-40f3-ac5a-174f3f011913",
                "parentId": "0a542844-7eb5-4167-9daf-80e925b81133"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "461b57ef-c776-4d5f-a803-3babe12392b1",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5688ae0e-20ec-423e-8d1b-9356e86acb85",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "05ded51d-72d9-42a6-9539-7028fc214067",
                "parentId": "461b57ef-c776-4d5f-a803-3babe12392b1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "61a7f2cc-746f-4b8b-bf11-62204699366a",
                "parentId": "461b57ef-c776-4d5f-a803-3babe12392b1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "62bb7641-1214-4928-8b49-a5d8658e2b52",
                "parentId": "461b57ef-c776-4d5f-a803-3babe12392b1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "b3580870-6c8c-4e42-801d-f18061479cc2",
                "parentId": "461b57ef-c776-4d5f-a803-3babe12392b1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "3afe55fb-0311-48af-9dee-9c9ad8569475",
                "parentId": "461b57ef-c776-4d5f-a803-3babe12392b1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f239e6a3-56ec-4347-b53b-cde180b071cb",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "5688ae0e-20ec-423e-8d1b-9356e86acb85",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3e21ef3b-c4c2-4b2d-96de-d53d138b8030",
                "parentId": "f239e6a3-56ec-4347-b53b-cde180b071cb"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "47192ac7-ef5b-43cc-bc33-c7e2cd201c1d",
                "parentId": "f239e6a3-56ec-4347-b53b-cde180b071cb"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "bf591947-4e91-40c0-b4c5-3c7d76d69c31",
                "parentId": "f239e6a3-56ec-4347-b53b-cde180b071cb"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "fb5b9b78-8696-44f5-8b93-9b221408d7c6",
                "parentId": "f239e6a3-56ec-4347-b53b-cde180b071cb"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "c9163b0e-b5b1-44e8-b92d-d9fec7c4251e",
                "parentId": "f239e6a3-56ec-4347-b53b-cde180b071cb"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6b8ae43a-03e5-4133-b853-f3ce9f6f5ea5",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "5688ae0e-20ec-423e-8d1b-9356e86acb85",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a3b92444-6715-4766-a222-0dc34ebbef68",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "2f445748-a304-435b-8d6c-971597f75a51",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2f445748-a304-435b-8d6c-971597f75a51",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "82ac8bac-ab18-43be-af05-4be77c7610ef",
                "parentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "53c5f7cc-291d-4bb7-8173-70eb916ae33b",
                "parentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "a187085b-9b6d-40f6-9957-2e8ab3891078",
                "parentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e50640b6-6a09-4dd9-b8a7-6e684b840039",
                "parentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "c0efb7a1-e5c2-47bd-9dbc-fcd09d12a3f0",
                "parentId": "5b30a11e-9f06-490c-8af4-6dfc97d051bd"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "2f445748-a304-435b-8d6c-971597f75a51",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "5a125229-1847-4ff4-abc9-ce0a16f22e6f",
                "parentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "57700e22-2e24-4855-925b-74ac1a6e3463",
                "parentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "38dc4064-9e05-4c50-94f8-fdaaf3ac0c0c",
                "parentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "aca369af-71ac-4dc4-abcc-ff2665bf391b",
                "parentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "831b3868-2d71-4057-bd1c-0c3d969d22f1",
                "parentId": "3ce7c428-853f-4e96-9f8f-b9a62d388856"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "bf3b70a9-fe83-4b8e-afc7-9bdc1f03b9a6",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "fb4b68ae-c757-4676-8bf9-ef4a3e5f0922",
                "parentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "a6a4958e-51ed-41c6-9a7c-6dae8e1bf952",
                "parentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "8ffb2e06-ca4c-40c0-bfeb-d2a9dcdd0885",
                "parentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "6e898457-a5c4-4e38-b393-ded81f57c24d",
                "parentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "25113212-49aa-4559-a36c-097306ae4370",
                "parentId": "5c8ac1b7-d6ca-43da-9587-1dbd154f27a1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "bf3b70a9-fe83-4b8e-afc7-9bdc1f03b9a6",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "22092abc-9726-4013-a865-3e7e826435de",
                "parentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c10d8bd7-6ae7-4e05-b8c1-74616daa460b",
                "parentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "6c36d14b-ac4e-40c0-a62c-8870f7a420f9",
                "parentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e3397fa2-f52d-4d4d-9108-4ff80a18c63b",
                "parentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "12928b99-36db-4e88-858a-5095f22ab487",
                "parentId": "0eb1d908-b29d-47c1-aafa-a9adbe6857ae"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "830cb26d-6007-4381-80d7-cb471d5109bc",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "bf3b70a9-fe83-4b8e-afc7-9bdc1f03b9a6",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "448a00a6-7769-4cc8-a3b4-a09d77fa3c0c",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "abf299e7-9cf6-497d-adf3-70827c853787",
                "parentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "1359bdef-d590-43a5-a83f-4812034a0589",
                "parentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "8e84be24-1a6d-4bb3-9293-34ee5ee285b0",
                "parentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "03048100-50b5-45d4-9573-eff41174accd",
                "parentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "5c28e3d2-65b7-4184-a883-cf1b3a7de8d1",
                "parentId": "dba7a251-b3b3-4397-b127-6d0e029d9a21"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1d411cda-8ec8-4ec8-8cee-a02c13d3c57c",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "448a00a6-7769-4cc8-a3b4-a09d77fa3c0c",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "448a00a6-7769-4cc8-a3b4-a09d77fa3c0c",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "fda264b8-136e-4afe-bc92-17265f28738e",
                "parentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "bfbd7947-bfe9-439c-957b-9041a6eac97e",
                "parentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0914fb22-85e8-4339-95e2-229bd02042dd",
                "parentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a3b9a357-1867-45a2-a66d-382d443a622f",
                "parentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "1d73f769-456f-4c06-8bfa-b31a1209eb02",
                "parentId": "b6c9d489-b1d7-43b2-a5a9-add3bd79c644"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "246fc892-659e-48a7-8f53-a2322cf90644",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "1eac31da-cd7c-446e-a01e-8de1cb56d8b6",
                "parentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "58afb879-51eb-4996-8be7-bb5a22906ae9",
                "parentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "30b7d2d0-d619-493d-8fa0-480b1a282342",
                "parentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a0786330-74a0-45b5-b0ec-6238170db77a",
                "parentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a7cc839a-5248-413c-b82b-7d174ec3f570",
                "parentId": "9582fcd5-1564-496b-9e1d-16ebd7900e07"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "7d516779-3e9d-499a-aa65-9ae04ba129f8",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "246fc892-659e-48a7-8f53-a2322cf90644",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "246fc892-659e-48a7-8f53-a2322cf90644",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "06e6d4a4-9f7e-41c3-b3e4-bfce674c7425",
                "parentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "dbfecfb8-d75d-420b-ae87-67b2bee87db6",
                "parentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "86eeeaf2-0632-46a1-91d9-9f1ddd998a02",
                "parentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "44356921-cb6f-45b0-9067-db02ee9f5088",
                "parentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "851da525-ed23-4571-b05f-1db7666867e3",
                "parentId": "eba1d600-d864-4f6c-9034-ef03c422ce2c"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "120e27c2-35d1-4276-9536-a5da5db328bc",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "d55ae9fc-7b93-484b-aa77-40e147323917",
                "parentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "4737762c-13c5-4673-a36f-8e352a52863e",
                "parentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "07de575a-2a27-484c-9357-3725ab03fa03",
                "parentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "94c3c39a-fb18-4ee6-b10c-a62473261bc1",
                "parentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "35395c15-6e96-4660-8e30-979eeaab2ac0",
                "parentId": "97a1f248-78bd-41d0-b11a-0f9824ea0aba"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "05cd9ace-2e5b-41d6-85fc-96e695417e09",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "120e27c2-35d1-4276-9536-a5da5db328bc",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "6d43ceff-d24b-4aa8-8438-deac391066a4",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "120e27c2-35d1-4276-9536-a5da5db328bc",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3077cf45-ca21-424f-9eb4-e99e51cb15e0",
                "parentId": "6d43ceff-d24b-4aa8-8438-deac391066a4"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2d4390be-74e2-4a16-9070-fba7154da678",
                "parentId": "6d43ceff-d24b-4aa8-8438-deac391066a4"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "6568de22-40d8-4e95-bd64-d70434672477",
                "parentId": "6d43ceff-d24b-4aa8-8438-deac391066a4"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "6625ed7e-ebc2-430d-8a90-1a486c13d033",
                "parentId": "6d43ceff-d24b-4aa8-8438-deac391066a4"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "10f11e1f-7c0d-47e4-96a3-2f5a8c24946b",
                "parentId": "6d43ceff-d24b-4aa8-8438-deac391066a4"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5a015e5f-8aab-493f-94a2-70a7947b1810",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "48874e3b-7cc3-4bf4-8922-b49fcc2ae07d",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "48874e3b-7cc3-4bf4-8922-b49fcc2ae07d",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "1725b892-56e5-4255-a5b1-c6f2f5c7f83f",
                "parentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d228aed7-5230-4f85-ac03-04b0a5b3719f",
                "parentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "13656a49-88a0-4b1c-a0d7-2bd04fdac8a1",
                "parentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "b597c53b-b0e6-4080-917c-c50e5e4f8659",
                "parentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "c592744f-dc8c-4c64-b24f-11c0de44d8e6",
                "parentId": "59ab7fa8-a908-438c-8dcb-d1cddefc830a"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "48874e3b-7cc3-4bf4-8922-b49fcc2ae07d",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "01d1ab3a-f5ee-4daa-9c02-fc47f0aa74d5",
                "parentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b6c38be4-00f7-43e5-ac78-1a337d2ecf05",
                "parentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "24828721-646e-45c0-bd32-e80f74e5e15a",
                "parentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "288aa7be-37cd-4b36-b9fe-92cf3362049f",
                "parentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "1fcc43f6-33b6-43a6-8cb2-bf7e8d70ed41",
                "parentId": "11103d10-2723-4bad-9d24-f8a9d4e33bf3"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2d581c68-932c-44b2-ae69-60fe3c028263",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "e89c83d4-3b2c-49e4-9154-b4a4d7253879",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "338e952b-0193-4800-aebe-2a801910e497",
                "parentId": "2d581c68-932c-44b2-ae69-60fe3c028263"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d565b106-0946-4e9c-93ae-f8145dbc2ba5",
                "parentId": "2d581c68-932c-44b2-ae69-60fe3c028263"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b4ad5704-76ed-4405-a1f7-20e446a34549",
                "parentId": "2d581c68-932c-44b2-ae69-60fe3c028263"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "05497567-b3b1-47d6-8cb2-86bc52830a1b",
                "parentId": "2d581c68-932c-44b2-ae69-60fe3c028263"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "2f2246cd-9c0c-489a-be32-4b52c5211374",
                "parentId": "2d581c68-932c-44b2-ae69-60fe3c028263"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "e89c83d4-3b2c-49e4-9154-b4a4d7253879",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "a04f51cb-0a51-42e9-89c4-ca200d282904",
                "parentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "d89cf75f-8fac-45d4-88f6-3651a252b581",
                "parentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c99f6f86-962a-4d46-9ebd-2ddb21ca603e",
                "parentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c932d971-acbe-43b6-9262-98b684ff634f",
                "parentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "20a5ea4b-7e2c-4e57-b24b-ef3fdb9a53bc",
                "parentId": "3127c45f-04f3-4a98-91a6-84b138cccdfb"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "a37892ac-1805-4ef1-af28-dd971b13bd53",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "e89c83d4-3b2c-49e4-9154-b4a4d7253879",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c033277c-d034-4231-a7c7-1fd256671b1e",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "9b2ae832-d155-4d21-b1ef-bdf45b293e31",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "e40962ca-8a57-4f7a-b49d-f685c626268b",
                "parentId": "c033277c-d034-4231-a7c7-1fd256671b1e"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "a2f9a874-85b2-4f8b-b648-17c6a5f67134",
                "parentId": "c033277c-d034-4231-a7c7-1fd256671b1e"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "a2a9ee7d-ccd4-4bbb-951c-ad2a5272c84c",
                "parentId": "c033277c-d034-4231-a7c7-1fd256671b1e"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c101f3b4-eaa3-4d8c-8918-b9b26be8be67",
                "parentId": "c033277c-d034-4231-a7c7-1fd256671b1e"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "46e7dbdc-10a9-4cbf-976d-48ee58a8286e",
                "parentId": "c033277c-d034-4231-a7c7-1fd256671b1e"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "9b2ae832-d155-4d21-b1ef-bdf45b293e31",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "0e7cd3a1-795c-4e62-b350-3c7147c46450",
                "parentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "7ea2d06b-b4a6-4dc8-b517-5553cc4326bf",
                "parentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "c61f5665-5573-49da-a242-461a77020134",
                "parentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "bc856f08-5972-4a5b-b3cb-84b1a369684e",
                "parentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "1ae62321-2cc3-4479-87aa-2318dadb6f30",
                "parentId": "0c5cc8ba-088f-4d71-9cdd-83ac3904eac8"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "ab889ce8-689f-44fb-b8c0-9bab7f68b0eb",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "9b2ae832-d155-4d21-b1ef-bdf45b293e31",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "18555259-a5ff-4189-ad9c-c53b437cf519",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "75bd2755-0d85-48d6-a834-6593e7cb5110",
                "parentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "15ce820b-9d8b-493d-a638-b3299d0fc8f2",
                "parentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "fe06d315-ba9b-439b-ab5e-8c9bcca5e789",
                "parentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "44c662fe-15a7-4379-a419-c7a2de0260fd",
                "parentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "dd171686-6987-46c2-98a9-c24db55a9fe3",
                "parentId": "34e2c74c-07f9-4411-b876-0e978c95c6e5"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3374659f-289d-4b46-a169-9329c06cb5f1",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "18555259-a5ff-4189-ad9c-c53b437cf519",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "615aef70-9497-4a70-b2ff-bd09b1c444f8",
                "parentId": "3374659f-289d-4b46-a169-9329c06cb5f1"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "76e98f0d-104b-48e3-989c-a2af8ac6de27",
                "parentId": "3374659f-289d-4b46-a169-9329c06cb5f1"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3036d891-8c6f-4195-bb4e-6d69ef938bd5",
                "parentId": "3374659f-289d-4b46-a169-9329c06cb5f1"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "9b35d771-9eca-4b9f-a8a8-63206c39a11d",
                "parentId": "3374659f-289d-4b46-a169-9329c06cb5f1"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "3cce0ebc-338e-463d-96ae-181d28fce536",
                "parentId": "3374659f-289d-4b46-a169-9329c06cb5f1"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4fe58d68-999c-4ea0-8d36-a5381b3d3349",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "18555259-a5ff-4189-ad9c-c53b437cf519",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "f890ccc9-485e-495f-8b5e-c6a707c6081b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9498ebef-7a16-4992-a2e4-2c3d33294e30",
                "parentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b3d0c5de-53de-4751-ad51-48e4a08dc8a0",
                "parentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "79e0733d-a0e5-4bd4-b86a-6bdaa6dd1583",
                "parentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "3d44dcfc-9076-4bd8-9002-3b442c6c69a7",
                "parentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "6caae9f6-7e65-433d-a8ed-02de9a07e2fe",
                "parentId": "4b495cfc-9744-4eb4-9d1a-287307e7e163"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f438566a-bc60-4a0e-adf4-47b80df8913d",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "f890ccc9-485e-495f-8b5e-c6a707c6081b",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "f890ccc9-485e-495f-8b5e-c6a707c6081b",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "88900a28-84ea-4807-b37f-41c068939ab1",
                "parentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c0482f9c-289b-46e7-aa5a-880419e5e880",
                "parentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "aed6188a-61bd-47ea-9caf-8ee278c87344",
                "parentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "21459028-1553-4c62-b902-ab59bd383262",
                "parentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "ea747c78-dd9a-41e8-8649-85ac6ae2ba16",
                "parentId": "9c7f045a-cd06-4048-b443-9d1b9593aeec"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7cf11303-000c-4a80-a8e0-cff7c5ae6378",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "0ecdc631-c8f5-415a-b0e0-e8f3850c2d88",
                "parentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "519fd777-7e6a-4790-a677-2bd5afa71fd9",
                "parentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "70d39183-9793-4935-ae78-77c56647148c",
                "parentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "6401de34-8ba8-4ab6-b1a5-6a4bbc754221",
                "parentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "5b7100c5-6a20-48f2-aef7-47d79a1a8476",
                "parentId": "3e82dfbf-a12c-4dea-8924-0aed195d0877"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7cf11303-000c-4a80-a8e0-cff7c5ae6378",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "6b496104-f0ff-4867-baa9-a5ae298f00b8",
                "parentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "6872299e-f3d2-4b69-ad15-bf29483fb1a6",
                "parentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "24a061f0-7ec0-47cd-a1e2-f16a6aca5efa",
                "parentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "85f60ff0-bfbc-4b7d-9ab4-fb1d8cbe2c95",
                "parentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "54decc13-44e4-45b5-b22f-24880e51b72d",
                "parentId": "f770b1a2-8da0-46c8-9c48-cdc1d977c3a2"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4501e1d2-8cd6-47bd-91ca-fc250b16503e",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "7cf11303-000c-4a80-a8e0-cff7c5ae6378",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1a50ec9b-12d7-433f-82d7-39827e0c37fa",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "7af2cd0d-79de-44fa-a4fb-d2f257c32239",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7af2cd0d-79de-44fa-a4fb-d2f257c32239",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "871cc386-22d1-495a-a618-0ad250d754fe",
                "parentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c92e34c6-1d40-4a67-9b9b-6de1dd957346",
                "parentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "cba0bf71-a606-4907-ad34-79ea5ecf7b0b",
                "parentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "ca01261a-44cf-404a-abde-50189c640b24",
                "parentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a669b91c-d640-4eaf-bfe5-1249352c105c",
                "parentId": "e4e80be3-cf28-4fe2-aa5c-a66fd755142b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7af2cd0d-79de-44fa-a4fb-d2f257c32239",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "dae40f77-50ae-4073-a444-caa8fa7f0d97",
                "parentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b35955d2-d046-4f80-bfd8-006cb2d8a194",
                "parentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "4d0d8515-c847-4754-ac5f-93d1c8aeda07",
                "parentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "7531a6c8-ca67-42e4-82bd-ac5c2213493e",
                "parentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "0acebd7f-efd3-4bc8-933d-7da469b82fec",
                "parentId": "875b83e0-1375-4a4e-96e1-d1b1f7f8f59b"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "dbb57f00-eccb-4ce6-a515-d4954647fa69",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "4dbd7eb0-58a8-47e6-813c-f522e3145744",
                "parentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "395f5949-57a4-4a1b-9da7-e736a6f3bf2f",
                "parentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "892452a6-3d6d-453b-b578-787d12edb6a7",
                "parentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "e07319da-76c1-4a52-8c79-0c5227e21873",
                "parentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "53f14560-9da3-41ac-a833-f02193cfe649",
                "parentId": "4720dbbb-cbe0-43f1-85d9-05c1da343b37"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "fbb34794-392d-4937-a50f-0e18a4316fcb",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "dbb57f00-eccb-4ce6-a515-d4954647fa69",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "3cfd2d12-b22c-4a91-a1ba-3d6e81d8fece",
                "parentId": "fbb34794-392d-4937-a50f-0e18a4316fcb"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "e1b1833c-06c5-41c8-b750-8cdebfc00121",
                "parentId": "fbb34794-392d-4937-a50f-0e18a4316fcb"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "7fd816fd-fb40-4f3e-88f8-157550147a38",
                "parentId": "fbb34794-392d-4937-a50f-0e18a4316fcb"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "bd4be5c6-2a0f-45c0-95ee-ed4f738fef60",
                "parentId": "fbb34794-392d-4937-a50f-0e18a4316fcb"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "84241a88-f4ba-461e-8d3f-dd7fc33afe44",
                "parentId": "fbb34794-392d-4937-a50f-0e18a4316fcb"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "e68b894a-a525-42b4-b2f2-41257a5c6e61",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "dbb57f00-eccb-4ce6-a515-d4954647fa69",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2014438d-6989-44fa-b468-13d8ad36312a",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "34d1f40e-eeca-40fb-98db-df2394929e86",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "f9742150-f4eb-4f19-8c37-cf57a5fd977c",
                "parentId": "2014438d-6989-44fa-b468-13d8ad36312a"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "c5228863-58a3-47b3-bec5-c4c66ce088e5",
                "parentId": "2014438d-6989-44fa-b468-13d8ad36312a"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "089bb280-df3b-400f-823e-0d8990ee76ec",
                "parentId": "2014438d-6989-44fa-b468-13d8ad36312a"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "cbe9b29e-32dd-45d1-ad64-22c16e21142f",
                "parentId": "2014438d-6989-44fa-b468-13d8ad36312a"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "cf5e6725-e57f-4ab6-a2f7-ff83c11f99fc",
                "parentId": "2014438d-6989-44fa-b468-13d8ad36312a"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "cbde6b77-d06b-4501-abfb-8c19d0c4eac1",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "34d1f40e-eeca-40fb-98db-df2394929e86",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "34d1f40e-eeca-40fb-98db-df2394929e86",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "f34d7e13-18ea-4f41-9a04-b72542db1249",
                "parentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "2a31b5c9-a42e-4d8c-a589-9c30d218aafa",
                "parentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "fe674f6b-e260-4bac-b69c-d12ee7abfcbd",
                "parentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "dc008eb9-3389-4205-85cc-bdcefcd44f0b",
                "parentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "91b750b7-ff57-4f5d-b08f-a1cd3e062cc8",
                "parentId": "5b31a03e-c7c8-4e74-bad9-edbd9ef82ed7"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "b40a273d-edd2-4bbc-9a86-dfceaa625333",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "da705984-6c4f-4cf7-bea5-0f01b4de018a",
                "parentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b14995cd-cf82-49a5-8e75-5a386ab4c7b2",
                "parentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "090a7437-0e5b-4ec0-bcdb-2ae6e340d921",
                "parentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d263b770-8d3c-4e3d-a2c0-c893343af980",
                "parentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "560ee3dd-6bb6-4737-a6c3-2b02d5625b46",
                "parentId": "1e9d9b84-f247-449f-bdb4-9f1b6cf1c6e7"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "b40a273d-edd2-4bbc-9a86-dfceaa625333",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "7fe49134-798f-4ecb-b0ea-941cb613519f",
                "parentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "b6bd3e9d-d21f-45a1-b710-29e89412f0db",
                "parentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "41f3386b-4c83-40a8-8de4-9a20b55968f3",
                "parentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "49d6cd00-3adf-4558-a0e6-743afc2543d4",
                "parentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "e9f9e62a-5b0b-4e3d-b0ab-36841809d909",
                "parentId": "46ca47fe-abf7-48b9-b36f-a559965dffd4"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "03736af2-3a52-45e2-aa6a-f227eab2b05a",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "b40a273d-edd2-4bbc-9a86-dfceaa625333",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "dfd34639-c534-49c2-88eb-133757f18b15",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "7174fb07-b190-4f33-953c-e0e31bf332cd",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7174fb07-b190-4f33-953c-e0e31bf332cd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "92deb7cf-e830-4fe2-8df2-0f2e190cc994",
                "parentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "1a29ccc2-ef00-466b-83e0-dca460d139cc",
                "parentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "b64f39f3-edd2-4196-9e75-8023b436f627",
                "parentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "ad26c1a0-bf63-421d-aa82-3a60866775fe",
                "parentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "30390f1b-8f00-4d47-aac3-7437c4fc7579",
                "parentId": "c7544b6f-a298-40a4-931e-8a7dce41fe36"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "7174fb07-b190-4f33-953c-e0e31bf332cd",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "f8d8c308-6e1c-47c1-9617-64eeb6656316",
                "parentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "fdac50e1-3d54-43f1-a22c-b75d22834311",
                "parentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "3aeeed95-44d4-44c9-bc8d-85152d8d5316",
                "parentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "d9e65529-7c68-4601-8e57-6c16689d8a8f",
                "parentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "8620e40b-49c3-421a-9538-087176da472c",
                "parentId": "2768820b-3ef4-47c5-9418-c2b913bdb8b8"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "49a45d04-ea7c-40f2-b3af-203a64cfb82e",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "f11d2656-b3db-4af8-b4f7-e215e192d637",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "f4bcc062-9d65-4534-886e-cb8e84e74515",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "f11d2656-b3db-4af8-b4f7-e215e192d637",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "9aee6644-86b7-4726-a6b1-a01dc0bfef27",
                "parentId": "f4bcc062-9d65-4534-886e-cb8e84e74515"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "78138271-253f-4ca5-92ec-7e25e9274d7b",
                "parentId": "f4bcc062-9d65-4534-886e-cb8e84e74515"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "6b8d8507-c95c-4738-8ff1-53df59a337a9",
                "parentId": "f4bcc062-9d65-4534-886e-cb8e84e74515"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "95bfd5df-3a61-432f-bf2a-65b8220cc374",
                "parentId": "f4bcc062-9d65-4534-886e-cb8e84e74515"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "d8384373-d9ee-437d-a73d-69634f4d82b0",
                "parentId": "f4bcc062-9d65-4534-886e-cb8e84e74515"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "f11d2656-b3db-4af8-b4f7-e215e192d637",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "2191ed28-5284-43e1-9c45-38524dcf83fa",
                "parentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "440b772d-d224-43d2-934e-4ce5bb39cc27",
                "parentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "19f073bc-867e-4330-905a-72ef7d69230e",
                "parentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "a85b591a-f233-405a-a305-5604e22a7f85",
                "parentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "c1d46aa5-2669-4017-a400-65db2805525a",
                "parentId": "8150cee5-3a2c-409a-a5f6-ce0eaed8511c"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918",
        "labelText": "Is responsive to my school district's needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "df4ab054-9022-496b-a2db-e9c1a21b0ad8",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "dd3c839a-851e-471d-a875-66930d53baa8",
                "parentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "14af138e-0661-4894-9506-7e0b053c276d",
                "parentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "94e70b06-01b8-4d39-93ed-729fc9b11f3f",
                "parentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "14d5305c-5baa-4a5f-ae35-27322a2f788d",
                "parentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "a45ddbad-4b42-419d-8ec9-03101106b26a",
                "parentId": "eef5f671-5c3e-4a4c-b1bf-01a655fc7918"
            }
        ]
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "bf16d42a-d80c-4875-a88b-0fc7961b3d17",
        "labelText": "Please provide feedback or comments as to how the specific BEESS areas can better assist your school district in improving student outcomes. ",
        "subtext": "",
        "elementType": "TextArea",
        "isRequired": false,
        "parentId": "df4ab054-9022-496b-a2db-e9c1a21b0ad8",
        "answerChoices": []
    },
    {
        "surveyRespondentId": "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
        "answer": "",
        "componentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0",
        "labelText": "District staff perceives the support provided to be high quality and assists them in meeting identified needs. ",
        "subtext": "",
        "elementType": "Dropdown",
        "isRequired": false,
        "parentId": "df4ab054-9022-496b-a2db-e9c1a21b0ad8",
        "answerChoices": [
            {
                "text": "5 (Strongly Agree)",
                "value": "5",
                "componentId": "0739d12d-da6a-413f-8305-cd42c42a1e5e",
                "parentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0"
            },
            {
                "text": "4 (Agree)",
                "value": "4",
                "componentId": "cad6f3f6-a513-48c7-98bb-913732603d0f",
                "parentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0"
            },
            {
                "text": "3 (Neutral)",
                "value": "3",
                "componentId": "0dfb72cc-21ab-481f-a5d5-dcd54e234285",
                "parentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0"
            },
            {
                "text": "2 (Disagree)",
                "value": "2",
                "componentId": "c4e74142-c4fb-4c91-b74a-0ebe26858f9b",
                "parentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0"
            },
            {
                "text": "1 (Strongly Disagree)",
                "value": "1",
                "componentId": "3849dfa0-0735-45f2-a6dc-3a1784c4801d",
                "parentId": "3666a7d8-c08e-40ab-b2d7-c667a493d0d0"
            }
        ]
    }
];

class MockSurveyApi{
    static getSurveysByRespondentId(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...surveys]);
            }, config.environment.delay);
        });
    }

    static getSurveyById(instanceId, surveyRespondentId){
        return new Promise(resolve => {
            let userSurvey = surveys.filter(s =>
                s.surveyRespondentId.toString() === surveyRespondentId &&
                s.instanceId.toString() === instanceId)[0];

            setTimeout(() => {
                resolve(userSurvey);
            }, config.environment.delay);
        });
    }

    //Mock data contains elements for a single survey, as it is currently returned in old GSW.  No need to filter
    static getSurveyElementsForTakeSurvey(){
        return new Promise(resolve => {
            let surveyElements = surveyElementViewModels;

            setTimeout(() => {
                resolve([...surveyElements]);
            }, config.environment.delay);
        });
    }

    static saveRespondentSurveyElements(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static submitRespondentSurvey(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const SurveyApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSurveyApi : MockSurveyApi;
export default SurveyApi;