import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";
import * as surveyStatuses from "../constants/surveys/surveyStatuses";
import * as surveyRespondentProgresses from "../constants/surveys/surveyRespondentProgesses";
import * as surveyUtils from "../components/surveyAdmin/surveyUtilities";
import * as elementTypes from "../constants/surveys/elementTypes";
import {emptyGuid} from "../constants/config";

export default function surveyAdminReducer(state = initialState.surveyAdmin, action) {
    switch(action.type){
        case actionTypes.LOAD_SURVEY_INSTANCES_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    surveyInstances: [...action.data]
                }
            );
        case actionTypes.LOAD_SURVEY_DETAILS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyInstance: Object.assign({}, action.data)
                }
            );
        case actionTypes.UPDATE_OPEN_COMPONENTS: {
            const isAlreadyOpen = state.openComponents.filter(f => f === action.data).length > 0;

            return Object.assign(
                {},
                state,
                {
                    openComponents: (isAlreadyOpen)
                        ? [...state.openComponents.filter(m => m !== action.data)]
                        : [...state.openComponents, action.data]
                }
            );
        }
        case actionTypes.PUBLISH_SURVEY_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyInstance: Object.assign({}, state.selectedSurveyInstance, { status: surveyStatuses.Published})
                }
            );
        case actionTypes.CLOSE_SURVEY_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyInstance: Object.assign({}, state.selectedSurveyInstance, { status: surveyStatuses.Closed})
                }
            );
        case actionTypes.SAVE_SURVEY_DETAILS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyInstance: Object.assign({}, action.data)
                }
            );
        case actionTypes.LOAD_SURVEY_RESPONDENTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    instanceRespondents: [...action.data]
                }
            );
        case actionTypes.DELETE_SURVEY_RESPONDENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    instanceRespondents: [...state.instanceRespondents.filter(respondents => respondents.surveyRespondentId !== action.data)]
                }
            );
        case actionTypes.UNSUBMIT_SURVEY_RESPONDENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    instanceRespondents: state.instanceRespondents.map(respondent => {
                        if(respondent.surveyRespondentId !== action.data)
                            return Object.assign({}, respondent);

                        return Object.assign(
                            {},
                            respondent,
                            {
                                progress: surveyRespondentProgresses.InProgress
                            });
                    })
                }
            );
        case actionTypes.LOAD_SURVEY_ELEMENTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...action.data],
                    isEditing: false,
                    editItemId: "",
                    isEditItemNew: false
                }
            );
        case actionTypes.DELETE_SURVEY_ELEMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements].filter(element =>
                        element.componentId !== action.data &&
                        element.parentId !== action.data
                    )
                }
            );
        case actionTypes.ADD_SURVEY_ELEMENTS_AJAX_SUCCESS:
        case actionTypes.UPDATE_SURVEY_ELEMENTS_AJAX_SUCCESS:
        case actionTypes.CLONE_SURVEY_ELEMENT_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    isEditing: false,
                    editItemId: "",
                    isEditItemNew: false
                }
            );
        case actionTypes.CLEAR_SURVEY_INSTANCE_DATA:
            return Object.assign(
                {},
                initialState.surveyAdmin
            );
        case actionTypes.CHANGE_SURVEY_DETAILS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyInstance: action.data
                });
        case actionTypes.CREATE_ELEMENT_SET:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements, surveyUtils.newElement(action.data, emptyGuid, elementTypes.TITLE)],
                    isEditing: true,
                    editItemId: action.data,
                    isEditItemNew: true
                });
        case actionTypes.CHANGE_ELEMENT:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements].map(el => {
                        el = Object.assign({}, el);

                        if(action.data.componentId !== el.componentId) return el;

                        return action.data;
                    })
                });
        case actionTypes.CREATE_ELEMENT:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements, surveyUtils.newElement(action.data.componentId, action.data.parentId)],
                    isEditing: true,
                    editItemId: action.data.componentId,
                    isEditItemNew: true
                });
        case actionTypes.EDIT_ELEMENT:
            return Object.assign(
                {},
                state,
                {
                    isEditing: true,
                    editItemId: action.data,
                    isEditItemNew: false
                });
        case actionTypes.CREATE_ANSWER_CHOICE:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements].map(el => {
                        el = Object.assign({}, el);
                        if (el.componentId !== action.data.parentId) return el;
                        el.answerChoices = [...el.answerChoices, surveyUtils.newAnswerChoice(action.data.componentId, action.data.parentId)];
                        return el;
                    })
                });
        case actionTypes.REMOVE_ANSWER_CHOICE:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements].map(el => {
                        el = Object.assign({}, el);
                        if (el.componentId !== action.data.parentId) return el;

                        el.answerChoices = el.answerChoices.filter(ac => ac.componentId !== action.data.componentId);
                        return el;
                    })
                }
            );
        case actionTypes.CHANGE_ANSWER_CHOICE:
            return Object.assign(
                {},
                state,
                {
                    instanceElements: [...state.instanceElements].map(el => {
                        el = Object.assign({}, el);
                        if (el.componentId !== action.data.parentId) return el;

                        el.answerChoices = [...el.answerChoices].map(ac => {
                            ac = Object.assign({}, ac);
                            if(ac.componentId !== action.data.answerChoice.componentId) return ac;
                            return action.data.answerChoice;
                        });

                        return el;
                    })
                }
            );
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.surveyAdmin;
        default:
            return state;
    }
}