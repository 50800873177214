import React from 'react';
import PropTypes from 'prop-types';
import Tile from '../common/Tiles/Tile';
import TileContainer from "../common/Tiles/TileContainer";

const SurveyListEditable = ({surveys, onClick, title, description}) => {
	let list = <div />;
	if (surveys.length > 0) {
		list = (
			<TileContainer title={title} description={description}>
				{surveys.map((survey, index) =>
					<Tile
						key={index}
						header={survey.title}
						footer={survey.progress}
						body={"Closes on " + survey.endDate}
						onClick={() => onClick(survey.instanceId)}
						id={survey.instanceId}
					/>
				)}
			</TileContainer>
		);
	}

	return list;
};

SurveyListEditable.propTypes = {
	surveys: PropTypes.array.isRequired,
	onClick: PropTypes.func.isRequired,
	title: PropTypes.string,
	description: PropTypes.string
};

export default SurveyListEditable;