import * as actionTypes from "../constants/actionTypes";
import {setPageTitle} from "../components/common/commonUtilities";

export function updatePageTitle(headerLeft, headerCenter = "", headerRight = "") {
    setPageTitle(headerLeft);
    const pageTitle = {
        headerLeft,
        headerCenter,
        headerRight
    };
    return {type: actionTypes.UPDATE_PAGETITLE, pageTitle};
}

export function clearSubTitle(){
    return {type: actionTypes.UPDATE_PAGE_SUBTITLE, subTitle: "" };
}

export function updatePageSubTitle(subTitle) {
    return {type: actionTypes.UPDATE_PAGE_SUBTITLE, subTitle};
}
