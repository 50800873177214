import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../components/common/inputs/inputUtility";

const DrStateComplaintResponseReviewEdit = ({
	                                            deadline,
	                                            handleClickCancel,
	                                            handleSaveResponseReviewAsync,
	                                            issueId,
	                                            review
                                            }) => {

	const reviewForm = useFormInputObject(drObjectFactory.createReviewObject(review));

	const handleClickSave = async () => {
		let updatedReview = convertFormInputObjectToObject(reviewForm);
		updatedReview.responseId = deadline.response.id;
		const isSuccess = await handleSaveResponseReviewAsync(updatedReview, deadline, issueId);

		if (isSuccess)
			handleClickCancel();
	};

	return (
		<>
			<DateTimePickerField
				inputClass={`input--small`}
				label="Date Documentation Received"
				name="dateDocumentationReceived"
				showLabel
				{...reviewForm.dateDocumentationReceived}
			/>
			<DateTimePickerField
				inputClass={`input--small`}
				label="Date Documentation Reviewed"
				name="dateDocumentationReviewed"
				showLabel
				{...reviewForm.dateDocumentationReviewed}
			/>
			<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
				<Button name="btnSave"
				        label="Save"
				        buttonType={ButtonTypes.SAVE}
				        onClick={handleClickSave}
				/>
				<Button name="btnCancel"
				        label="Cancel"
				        buttonType={ButtonTypes.CANCEL}
				        onClick={handleClickCancel}
				/>
			</ButtonBar>
		</>
	);
};

DrStateComplaintResponseReviewEdit.propTypes = {
	deadline: PropTypes.object.isRequired,
	handleClickCancel: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired,
	issueId: PropTypes.string,
	review: PropTypes.object.isRequired
};

export default DrStateComplaintResponseReviewEdit;