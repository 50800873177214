import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {setPageTitle} from "../../../../components/common/commonUtilities";
import gmsApi from "../../gmsApi";
import * as gmsLocations from "../../gmsLocations";
import {GmsApplicationPrintView} from "./GmsApplicationPrintView";

const GmsApplicationPrintContainer = ({
                                          actions,
                                          applicationHistoryId,
                                          applicationId,
                                          currentUserFiscalAgentId,
                                          history,
                                          isFiscalAgentUser,
                                          selectedElements
                                      }) => {
    const [application, setApplication] = useState({});
    selectedElements = !selectedElements ? [] : selectedElements.split(`|`);
    const {
        applicationTitle = "",
        fiscalAgentName = "",
        elements = [],
        reportTitle = ""
    } = application;

    useEffect(() => {
        const initializeData = async () => {
            await actions.executeApi(gmsApi.getCompleteApplication, [applicationId, applicationHistoryId])
                .then((result) => {
                    const isFiscalAgentUserViewingIncorrectFiscalAgent = isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId.toLowerCase();
                    if (!result.canViewApplication || isFiscalAgentUserViewingIncorrectFiscalAgent) {
                        const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                        history.push(resolvedPath);
                        return;
                    }

                    setApplication(result);
                });
        };

        initializeData();

    }, []);

    useEffect(() => {
        if (applicationTitle)
            setPageTitle(applicationTitle);
    }, [application]);

    if (elements.length === 0)
        return null;

    const selectedMainAreas = elements.filter(element => element.elements.some(s => selectedElements.some(se => se === s.elementId)));
    return (
        <>
            {
                selectedMainAreas.map((element) => {
                    return <GmsApplicationPrintView
                        fiscalAgentName={fiscalAgentName}
                        element={element}
                        reportTitle={reportTitle}
                        selectedElements={selectedElements}
                        key={element.elementId}
                    />;
                })
            }
        </>
    );
};


GmsApplicationPrintContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistoryId: PropTypes.string,
    applicationId: PropTypes.string.isRequired,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    selectedElements: PropTypes.string.isRequired
};

export default GmsApplicationPrintContainer;