import React from "react";
import PropTypes from 'prop-types';
import {McFilterVisibilityRules, MonitoringComplianceDistrictSearchCriteria} from "../../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import SummaryView from "../../../components/summary/SummaryView";
import {MONITORING_COMPLIANCE} from "../../../constants/contexts";
import {dateSelectOptionChoices} from "../../../constants/inputConstants";

export const McSummaryUpcoming = ({
                                      mcSummarySearchCriteria,
                                      handleClickGeneral,
                                      setDateRangeSearchCriteria,
                                      setMcSummarySearchCriteria,
                                      summary
                                  }) => {
    const buildMcSearchCriteria = (dateRangeType, startDate, endDate) => {
        return new MonitoringComplianceDistrictSearchCriteria(mcSummarySearchCriteria.dateWindowId, dateRangeType, startDate, endDate, "title", "title");
    };

    return <SummaryView
        buildSearchCriteria={buildMcSearchCriteria}
        containerId={"upcoming"}
        context={MONITORING_COMPLIANCE}
        dateSelectOptionChoice={dateSelectOptionChoices.upcoming}
        handleClickGeneral={handleClickGeneral}
        setDateRangeSearchCriteria={setDateRangeSearchCriteria}
        setSummarySearchCriteria={setMcSummarySearchCriteria}
        summary={summary}
        summarySearchCriteria={mcSummarySearchCriteria}
        summaryViewTitle={"Upcoming"}
        visibilityRules={McFilterVisibilityRules.McSummaryUpcoming}
    />;
};

McSummaryUpcoming.propTypes = {
    mcSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    setDateRangeSearchCriteria: PropTypes.func.isRequired,
    setMcSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array
};

export default McSummaryUpcoming;