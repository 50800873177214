import React from 'react';
import PropTypes from 'prop-types';
import {gridConstants} from "../../../../constants/inputConstants";
import BpieSchoolAdministratorForm from "../../School/BpieSchoolAdministratorForm";
import {createSchoolAdmin} from "../../School/bpieSchoolAdminFactory";
import DisplayField from "../../../../components/common/inputs/DisplayField";

const BpieDistrictSummaryRowEdit = ({
                                        actions,
                                        bpie,
                                        createSchoolAdminFromBpie,
                                        handleCancelClick,
                                        handleReloadSchoolAdministrator,
                                        isReplacement
                                    }) => {
    return (
        <td colSpan={gridConstants.column.SIX}>
            <DisplayField name={`editSchoolName`} label={`School Name`} showLabel>
                {bpie.schoolName} {bpie.msidNumber}
            </DisplayField>
            <BpieSchoolAdministratorForm
                actions={actions}
                handleCancelClick={handleCancelClick}
                handleReloadSchoolAdministrator={handleReloadSchoolAdministrator}
                isReplacement={isReplacement}
                schoolAdmin={isReplacement ? createSchoolAdmin({}) : createSchoolAdminFromBpie(bpie.schoolBpieId)}
                schoolBpieId={bpie.schoolBpieId}
            />
        </td>
    );
}

BpieDistrictSummaryRowEdit.propTypes = {
    actions: PropTypes.object.isRequired,
    bpie: PropTypes.object.isRequired,
    createSchoolAdminFromBpie: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
    handleReloadSchoolAdministrator: PropTypes.func.isRequired,
    isReplacement: PropTypes.bool.isRequired,
};

export default BpieDistrictSummaryRowEdit;