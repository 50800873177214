import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import {SBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";
import {BPIE_LANDING_WITH_SCHOOL_BPIE} from "../../bpieLocations";

const BpieAssessmentIntroContainer = ({
                                          handleStartBpieThenRedirectToAssessment,
                                          handleSummaryRedirect,
                                          history,
                                          schoolBpie,
                                      }) => {
    let buttonLabel = "BPIE Assessment";

    switch (schoolBpie.status) {
        case SBPIE_ASSESSMENT_STATUS.Started.id:
            buttonLabel = "Begin SBPIE Assessment";
            break;
        case SBPIE_ASSESSMENT_STATUS.InProgress.id:
            buttonLabel = "Continue SBPIE Assessment";
            break;
        case SBPIE_ASSESSMENT_STATUS.Archived.id:
            buttonLabel = "View SBPIE Assessment";
            break;
    }

    const handleRedirect = (event) => {
        event.preventDefault();
        if (schoolBpie.status === SBPIE_ASSESSMENT_STATUS.Started.id)
            handleStartBpieThenRedirectToAssessment(schoolBpie.schoolBpieId);
        else
            handleSummaryRedirect(schoolBpie.schoolBpieId);
    };

    useEffect(() => {
        if (schoolBpie.status < SBPIE_ASSESSMENT_STATUS.Started.id)
            history.push(BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(schoolBpie.schoolBpieId));
    }, []);

    return (
        <>
            <h1>SBPIE Assessment Process: Steps-at-a-Glance</h1>
            <p><strong>Step 1:</strong> FIN facilitator or FIN-trained leader reads each indicator aloud (in
                multiple-school settings, this task may be completed by a team member).</p>
            <p><strong>Step 2:</strong> Review and think about your individual rating from the Implementation Status
                column.</p>
            <p><strong>Step 3:</strong> When prompted, vote for the indicator rating you selected by holding up your
                Response Card (NY, PA, PB, or F).</p>
            <p><strong>Step 4:</strong> If team member ratings are all the same, the FIN facilitator or FIN-trained
                leader indicates the final rating and records the data source or evidence in the appropriate sections of
                the online assessment. Each indicator rating will be given 3 minutes to reach consensus.</p>
            <p><strong>Step 5:</strong> If team ratings are not all the same, the team discusses the data or supporting
                evidence and repeats Step 3 for a final vote. If a re-vote is necessary, an additional 2 minutes will be
                provided.</p>
            <p><strong>Step 6:</strong> If the team does not reach consensus on a final rating for an indicator, the
                decision rules are used:</p>
            <ul>
                <li>A team majority vote decides the final rating,</li>
                <li>The school administrator decides the final rating,</li>
                <li>The school administrator designates another to decide the final rating, or</li>
                <li>A combination of these rules may be applied to decide the final rating, depending on the
                    indicator.
                </li>
            </ul>
            <p><strong>Step 7:</strong> After all indicators have been rated, the FIN facilitator or FIN-trained leader
                leads a team discussion to identify and select the priority indicators (for further action planning).
                Final priority indicators are also noted on the online assessment in the appropriate section.</p>

            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button
                    name={"btnBeginAssessment"}
                    label={buttonLabel}
                    buttonType={ButtonTypes.SYSTEM}
                    onClick={(event) => handleRedirect(event)}
                />
            </ButtonBar>
        </>
    );
}

BpieAssessmentIntroContainer.propTypes = {
    handleStartBpieThenRedirectToAssessment: PropTypes.func.isRequired,
    handleSummaryRedirect: PropTypes.func.isRequired,
    history: PropTypes.object,
    schoolBpie: PropTypes.object.isRequired,
};

export default BpieAssessmentIntroContainer;