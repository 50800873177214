import React from "react";
import PropTypes from "prop-types";
import DisplayField from "../../../components/common/inputs/DisplayField";
import GridTable from "../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {convertBooleanToYesNoText} from "../../../components/shared/sharedDataUtilities";

const DrStateComplaintCitation = ({
                                     citations
                                 }) => {

    return (
        <DisplayField label={"Citations"} name={"citations"} showLabel>
            {
                !isArrayNullOrEmpty(citations) &&
                <GridTable>
                    <thead>
                    <tr className="text-center">
                        <th>Type</th>
                        <th>Citation</th>
                        <th>Violation</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        citations.map((citation, key) =>
                            <tr className="text-center" key={key}>
                                <td>{citation.citationType}</td>
                                <td>{citation.citationText}</td>
                                <td>{convertBooleanToYesNoText(citation.isViolation)}</td>
                            </tr>
                        )
                    }
                    </tbody>
                </GridTable>
            }
            {
                isArrayNullOrEmpty(citations) &&
                <div>None</div>
            }
        </DisplayField>
    );
};

DrStateComplaintCitation.propTypes = {
    citations: PropTypes.array.isRequired
};

export default DrStateComplaintCitation;