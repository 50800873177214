import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import {DollarField, NumberField, SelectField, TextArea, TextField} from "../../../../components/common/inputs";
import SearchableMultiSelectField from "../../../../components/common/inputs/SearchableMultiSelectField";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {gridConstants} from "../../../../constants/inputConstants";
import {createGmsDeliverableItem} from "../../gmsObjectFactory";
import {useFormInputObject, convertFormInputObjectToObject} from "../../../../components/common/inputs/inputUtility";
import {FUNDING_SOURCE_LIST} from "./gmsApplicationDeliverablesConstants";
import {getTypesForCategory} from "./gmsApplicationDeliverablesUtilities";

const GmsApplicationDeliverablesTableRowEdit = ({
                                                    colSpan,
                                                    deliverableItem = {},
                                                    gmsIndicators = [],
                                                    handleCancel,
                                                    handleSave,
                                                }) => {
    const dliForm = useFormInputObject(createGmsDeliverableItem(deliverableItem));

    const newHeaderId = `new-deliverable-header`;
    const newTextId = `new-deliverable-text`;
    const headerText = dliForm.id.value === emptyGuid ? `Add Deliverable` : `Edit Deliverable`;
    const rowColor = dliForm.id.value === emptyGuid ? `add-row` : `edit-row`;
    const typeList = getTypesForCategory(deliverableItem.deliverableCategory);

    const handleSaveClick = () => {
        const updatedDeliverable = convertFormInputObjectToObject(dliForm);
        handleSave(updatedDeliverable);
    };

    return <>
        <tr className={`show-for-sr`}>
            <th id={newHeaderId} aria-describedby={newTextId} scope={'row'}/>
        </tr>
        <tr className={rowColor}>
            <td colSpan={colSpan} headers={newHeaderId}>
                <GridRow>
                    <h3 id={newTextId}>{headerText}</h3>
                </GridRow>
                <GridRow>
                    <GridColumn medium={gridConstants.column.EIGHT}>
                        <SelectField
                            label={`Type`}
                            options={typeList}
                            showLabel
                            {...dliForm.deliverableType}
                        />
                        <TextField label={"Title"} showLabel {...dliForm.deliverableTitle} />
                        <TextArea label={"Description"} showLabel {...dliForm.deliverableDescription} />
                        <SearchableMultiSelectField
                            label={`Indicators`}
                            options={gmsIndicators}
                            showLabel
                            {...dliForm.gmsIndicators}
                        />
                        <TextField label={"Source Documentation"} showLabel {...dliForm.sourceDocumentation} />
                    </GridColumn>
                    <GridColumn medium={gridConstants.column.THREE} columnClass={"medium-offset-1"}>
                        <SelectField
                            label={`Funding Source`}
                            options={FUNDING_SOURCE_LIST}
                            showLabel
                            {...dliForm.fundingSourceType}
                        />
                        <DollarField isPositiveNumberOnly label={`Deliverable Budget`}
                                     showLabel {...dliForm.deliverableBudget} />
                        <NumberField isWholeNumberOnly isPositiveNumberOnly label={"Quarter 1 Deliverable Units"}
                                     showLabel {...dliForm.q1Units} />
                        <NumberField isWholeNumberOnly isPositiveNumberOnly label={"Quarter 2 Deliverable Units"}
                                     showLabel {...dliForm.q2Units} />
                        <NumberField isWholeNumberOnly isPositiveNumberOnly label={"Quarter 3 Deliverable Units"}
                                     showLabel {...dliForm.q3Units} />
                        <NumberField isWholeNumberOnly isPositiveNumberOnly label={"Quarter 4 Deliverable Units"}
                                     showLabel {...dliForm.q4Units} />
                    </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                        <ButtonBar position={ButtonBarPositions.TABLE}>
                            <Button
                                buttonType={ButtonTypes.SAVE}
                                label={`Save`}
                                name={`btnSave`}
                                onClick={handleSaveClick}
                            />
                            <Button
                                buttonType={ButtonTypes.CANCEL}
                                label={`Cancel`}
                                name={`btnCancel`}
                                onClick={handleCancel}
                            />
                        </ButtonBar>
                    </GridColumn>
                </GridRow>
            </td>
        </tr>
    </>;
};


GmsApplicationDeliverablesTableRowEdit.propTypes = {
    colSpan: PropTypes.number,
    deliverableItem: PropTypes.object,
    gmsIndicators: PropTypes.array.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
};

export default GmsApplicationDeliverablesTableRowEdit;