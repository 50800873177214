import { combineReducers } from 'redux';
import {ajaxStatusReducer as ajaxCallsInProgress, dashboardAjaxStatusReducer as dashboardAjaxCallsInProgress} from "./ajaxStatusReducer";
import currentContext from "./authorizationReducer";
import account from "./accountReducer";
import layout from "./layoutReducer";
import menu from "./menuReducer";
import user from "./userReducer";
import survey from "./surveyReducer";
import spp from "./sppReducer";
import dashboard from "./dashboardReducer";
import sharedData from "./sharedDataReducer";
import surveyAdmin from "./surveyAdminReducer";
import strategicPlan from "./strategicPlanReducer";
import monitoringPerformance from "./monitoringPerformanceReducer";
import monitoringCompliance from "./monitoringComplianceReducer";
import monitoringComplianceStudentCorrection from "./monitoringComplianceStudentCorrectionReducer";
import searchCriteria from "./searchCriteriaReducer";
import bpie from "./bpieReducer";
import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    account,
    ajaxCallsInProgress,
    dashboardAjaxCallsInProgress,
    currentContext,
    layout,
	menu,
    user,
    survey,
    surveyAdmin,
    spp,
    dashboard,
    sharedData,
    strategicPlan,
    monitoringPerformance,
    monitoringCompliance,
    monitoringComplianceStudentCorrection,
    searchCriteria,
    bpie,
    router: connectRouter(history)
});