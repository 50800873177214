import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";
import {dispute_resolution as policyEvents}  from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";

class MediationPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.MEDIATIONS]);
        this.updatePolicy();
    }

    updatePolicy(){
        //must have Edit to add/edit/delete mediation
        this._policy[policyEvents.mediation.MODIFY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [behaviors.EDIT], userBehaviors);
            };
    }

}

export {MediationPolicy, policyEvents};