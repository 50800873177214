import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FormTable from "../../../components/common/FormTable";
import RadioField from "../../../components/common/inputs/RadioField";
import {outputCurrentDate} from "../../../components/shared/sharedDataUtilities";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {inputStyles} from "../../../constants/inputConstants";
import {RadioButtonYesNoList, RadioButtonYesNoObject} from "../../drConstants";
import {StateComplaintSections} from "../drStateComplaintConstants";
import * as drObjectFactory from '../drStateComplaintObjectFactory';
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {StateComplaintPolicy} from "../../../components/authorization/policies/StateComplaintPolicy";
import {dispute_resolution} from "../../../constants/policyEvents";
import {
    convertFormInputObjectToObject,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";

export const DrStateComplaintClosureNoActionsView = ({
                                                complaint,
                                                handleSaveComplaint,
                                                handleDataChanged,
                                                handleSelectSection
                                            }) => {
    const complaintForm = useFormInputObject(drObjectFactory.createComplaintObject(complaint), () => handleDataChanged());
    const canEdit = AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY);

    const handleClickCancel = (event) => {
        handleSelectSection(event, StateComplaintSections.ClosureWithNoActions, true);
    };

    const handleClickSave = (event) => {
        event.preventDefault();

        const form = convertFormInputObjectToObject(complaintForm);

        form.dateWithdrawnOrDismissedOrIssuedWithNoActions = form.isIssuedWithNoCorrectiveActions === RadioButtonYesNoObject.Yes
            ? outputCurrentDate()
            : "";

        handleSaveComplaint(form);
    };

    if (isNullOrUndefined(complaint) || !complaint.issueModels) return null;

    return (
        <>
            <FormTable>
                <DisplayField
                    label={`Total Issues`}
                    name={`lblTotalIssues`}
                    showLabel
                >
                    {complaint.issueModels.length.toString()}
                </DisplayField>
                <RadioField
                    direction={inputStyles.radio.direction.HORIZONTAL}
                    legend={`Have all issues been added?`}
                    options={RadioButtonYesNoList}
                    showLegend
                    {...complaintForm.isIssuedWithNoCorrectiveActions}
                />
            </FormTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                {
                    canEdit &&
                    <Button name="btnSave"
                            label="Save"
                            buttonType={ButtonTypes.SAVE}
                            onClick={handleClickSave}/>
                }
                <Button name="btnCancel"
                        label="Cancel and Reload"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={handleClickCancel}/>
            </ButtonBar>
        </>
    );
};

DrStateComplaintClosureNoActionsView.propTypes = {
    complaint: PropTypes.object,
    handleSaveComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired
};

export default DrStateComplaintClosureNoActionsView;