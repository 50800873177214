import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayBlock from "../../htmlDisplay/HtmlDisplayBlock";
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";
import Notification from '../Notification';
import GridColumn from "../GridColumn";
import GridRow from "../GridRow";
import {inputStyles, gridConstants} from '../../../constants/inputConstants';
import {isArrayNullOrEmpty} from "../commonUtilities";

const FieldsetLayout = ({
                            align,
                            children,
                            disabled,
                            error,
                            fieldsetClass = "",
                            helpText,
                            legend = "",
                            legendClass = "",
                            medium,
                            showLegend,
                            small,
                            useInputTheme = true,
                            wrapperClass = "",
                        }) => {
    if (!showLegend)
        legendClass = `${legendClass} ${inputStyles.HIDDEN}`;

    if (!isArrayNullOrEmpty(error))
        wrapperClass = `${wrapperClass} ${inputStyles.ERROR}`;

    if (disabled)
        wrapperClass = `${wrapperClass} ${inputStyles.DISABLED}`;

    if (useInputTheme)
        wrapperClass = `${wrapperClass} ${inputStyles.THEME}`;

    const legendContent = helpText
        ? <><HtmlDisplayInline html={legend}/><Notification helpText={helpText}/></>
        : <HtmlDisplayBlock html={legend}/>;

    return (
        <GridRow rowClass={wrapperClass} align={align}>
            <GridColumn small={small} medium={medium}>
                <fieldset disabled={disabled} className={fieldsetClass}>
                    <legend className={legendClass}>{legendContent}</legend>

                    {children}

                    {error && <Notification error={error}/>}
                </fieldset>
            </GridColumn>
        </GridRow>
    );
};

const columnPropType = gridConstants.column.PROP_TYPE;

FieldsetLayout.propTypes = {
    align: PropTypes.string,
    children: PropTypes.any, //eslint-disable-line react/forbid-prop-types
    disabled: PropTypes.bool,
    error: PropTypes.string,
    fieldsetClass: PropTypes.string,
    helpText: PropTypes.string,
    legend: PropTypes.string,
    legendClass: PropTypes.string,
    medium: columnPropType,
    showLegend: PropTypes.bool,
    small: columnPropType,
    useInputTheme: PropTypes.bool,
    wrapperClass: PropTypes.string,
};


export default FieldsetLayout;