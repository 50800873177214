import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../bpieLocations"
import {BpieApiMessages} from "../../bpieConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerBpieDistrictPieProgressMonitoringsApi {
    static removeProgressMonitoring(dateWindowId, districtId, districtPieId, dpieProgressMonitoringId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/progressMonitorings/${dpieProgressMonitoringId}`,
                RequestTypes.DELETE,
                null,
                BpieApiMessages.DPIE.PROGRESS_MONITORING_REMOVED
            )
        );
    }

    static saveDistrictPieProgressMonitoring(dateWindowId, districtId, districtPieId, dbpieProgressMonitoringId, dpieProgressMonitoringsModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/progressMonitorings/${dbpieProgressMonitoringId}`,
                RequestTypes.PUT,
                JSON.stringify(dpieProgressMonitoringsModel),
                BpieApiMessages.DPIE.PROGRESS_MONITORING_SAVED
            )
        );
    }

    static saveDistrictPieActionStepUpdate(dateWindowId, districtId, districtPieId, dbpieProgressMonitoringId, actionStepUpdatesModel){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/districts/${districtId}/dpies/${districtPieId}/progressMonitorings/${dbpieProgressMonitoringId}/actionStepUpdates`,
                RequestTypes.PUT,
                JSON.stringify(actionStepUpdatesModel),
                BpieApiMessages.DPIE.ACTION_STEP_UPDATE_SAVED
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieDistrictPieProgressMonitoringsApi {
    static removeProgressMonitoring(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.PROGRESS_MONITORING_REMOVED);
    }

    static saveDistrictPieProgressMonitoring(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.PROGRESS_MONITORING_SAVED);
    }

    static saveDistrictPieActionStepUpdate(){
        return mockResolveApiCall({}, BpieApiMessages.DPIE.ACTION_STEP_UPDATE_SAVED);
    }
}

const bpieDistrictPieProgressMonitoringsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieDistrictPieProgressMonitoringsApi : MockBpieDistrictPieProgressMonitoringsApi;
export default bpieDistrictPieProgressMonitoringsApi;