import React from 'react';
import PropTypes from 'prop-types';

export const TabPanel = ({
                             children,
                             className = "",
                             selectedTab = "",
                             tabPanelId = ""
                         }) => {

    className = selectedTab === tabPanelId ? `${className} is-active` : className;
    return (
        <div className={`tabs-panel ${className}`} id={`tab-${tabPanelId}`}>
            {children}
        </div>
    );
};

TabPanel.propTypes = {
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    className: PropTypes.string,
    selectedTab: PropTypes.string,
    tabPanelId: PropTypes.string.isRequired
};
