import {createListItem, dynamicSort, isArray} from "../common/commonUtilities";

export const createTeamMemberObject = ({
                                           districtId,
                                           email,
                                           firstName,
                                           fullName,
                                           id,
                                           isDisabled,
                                           lastName,
                                           role,
                                           roleDescription,
                                           userId,
                                       } = {}) => ({
    districtId,
    email,
    firstName,
    fullName: fullName || `${firstName} ${lastName}`,
    id: id ? id : userId,
    isDisabled,
    lastName,
    role,
    roleDescription,
    userId,
});

export const createDistinctTeamMemberNameList = (teamMembers = []) => {
    let list = [];

    if (!isArray(teamMembers)) return list;

    teamMembers.map(i => {
        let teamMember = createTeamMemberObject(i);
        if (!list.find(f => f.id === teamMember.userId))
            list.push(createListItem(teamMember.userId, teamMember.fullName));
    });

    list = list.sort(dynamicSort("text"));

    return list;
}