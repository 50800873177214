import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";

class SurveyPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.SURVEYS]);
    }
}

export {SurveyPolicy};

