import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux';
import { setRedirectUrl, loginSuccess} from '../../actions/accountActions';
import localStorage from "../../components/shared/LocalStorage";
import * as publicLocations from "../../constants/publicLocations";
import AuthenticatedLayout from "../layout/AuthenticatedLayout";
import {createRedirectUrl} from "../../components/common/commonUtilities";

export class EnsureLoggedIn extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.verifyUserIsLoggedIn();
    }

    verifyUserIsLoggedIn(){
        const { dispatch, isLoggedIn, location, history } = this.props;

        if (!localStorage.isLoggedIn()) {
            const redirectUrl = createRedirectUrl(location.pathname, location.search);
            dispatch(setRedirectUrl(redirectUrl));
            history.push(publicLocations.USER_SIGN_IN.path);
        }
        else if(!isLoggedIn) {
            dispatch(loginSuccess(true));
        }
    }

    render() {
	    const {layout: Layout, isLoggedIn} = this.props;

        if (isLoggedIn) {
            return (
                <Layout
	                {...this.props}
                />
            );
        } else {
            return null;
        }
    }
}

EnsureLoggedIn.defaultProps = {
    layout: AuthenticatedLayout,
    authorizationRequired: false
};

EnsureLoggedIn.propTypes = {
    dispatch: PropTypes.func,
    redirectUrl: PropTypes.string,
    children: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    layout: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string.isRequired,
    context: PropTypes.array,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    authorizationRequired: PropTypes.bool,
    wrapperPage: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};

function mapStateToProps(state) {
    return {
        isLoggedIn: state.account.isLoggedIn
    };
}

export default withRouter(connect(mapStateToProps)(EnsureLoggedIn));
