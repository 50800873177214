import * as contexts from "../constants/contexts";
import {themeConstants} from "../constants/commonStyleConstants";
import {
    createDistrictReportApiEndPoint,
    createReportInfoObject,
    createReportTileObject,
    createStateReportApiEndPoint,
    createReportPrintTileObject
} from "./reportObjectFactory";
import TrackingSheetPrintView from "../DisputeResolution/StateComplaints/PrintReports/TrackingSheetPrintView";
import DueProcessFederalReportPrintView from "../DisputeResolution/DueProcesses/PrintReports/FederalReportPrintView";
import DueProcessFederalWithGiftedReportPrintView from "../DisputeResolution/DueProcesses/PrintReports/FederalWithGiftedReportPrintView";
import MediationFederalReportPrintView from "../DisputeResolution/Mediations/PrintReports/FederalReportPrintView";
import StateComplaintFederalReportPrintView from "../DisputeResolution/StateComplaints/PrintReports/FederalReportPrintView";
import StudentPrintView from "../containers/monitoringCompliance/printReports/StudentPrintView";
import DistrictSummaryPrintView from "../containers/monitoringCompliance/printReports/DistrictSummaryPrintView";
import CapPrintView from "../containers/monitoringCompliance/printReports/CapPrintView";
import StudentCorrectionsPrintView from "../containers/monitoringCompliance/printReports/StudentCorrectionsPrintView";
import IndicatorPriorityReportContainer from "../containers/bpie/Reports/PrintReports/IndicatorPriorityReportContainer";
import {BPIE_REPORT_ROOT} from "../containers/bpie/bpieLocations";
import SchoolStatusTrendReportContainer from "../containers/bpie/Reports/PrintReports/SchoolStatusTrendReportContainer";
import SchoolPriorityTrendReportContainer
    from "../containers/bpie/Reports/PrintReports/SchoolStatusPriorityReportContainer";
import SchoolStatusTrendChartContainer from "../containers/bpie/Reports/PrintReports/SchoolStatusTrendChartContainer";

// ************************************ SPP Report configuration ****************************
export const SPP_ROOT = `Spp/reports`;
export const SPP_STATE_ROOT = `${SPP_ROOT}/state`;

export const spp = {
    info: createReportInfoObject(
        `theme-${themeConstants.SPP}`,
        "Policies and Procedures District Reports",
        "Policies and Procedures State Reports",
        contexts.SPP
    ),
    stateReports: [
        createReportTileObject("Needs Review and Revision Report", createStateReportApiEndPoint(SPP_STATE_ROOT, "needs-revision-review")),
        createReportTileObject("Restraint Report", createStateReportApiEndPoint(SPP_STATE_ROOT, "restraint")),
        createReportTileObject("Seclusion Report", createStateReportApiEndPoint(SPP_STATE_ROOT, "seclusion")),
    ]
};


// ************************************ Monitoring for Compliance Report configuration ****************************
export const MONITORING_COMPLIANCE_ROOT = `monitoring/compliances/reports`;
export const MONITORING_COMPLIANCE_STATE_ROOT = `${MONITORING_COMPLIANCE_ROOT}/state`;

export const monitoringCompliance = {
    info: createReportInfoObject(
        `theme-${themeConstants.MONITORING_COMPLIANCE}`,
        "Monitoring for Compliance District Reports",
        "Monitoring for Compliance State Reports",
        contexts.MONITORING_COMPLIANCE
    ),
    districtReports: [
        createReportPrintTileObject("Student Report Print Version", StudentPrintView),
        createReportPrintTileObject("District Summary Report Print Version", DistrictSummaryPrintView),
        createReportPrintTileObject("Correction of Noncompliance Print Version", CapPrintView),
        createReportTileObject("Matrix of Services", createDistrictReportApiEndPoint(MONITORING_COMPLIANCE_ROOT, "matrix")),
        createReportTileObject("Correction of Matrix of Services Discrepancies", createDistrictReportApiEndPoint(MONITORING_COMPLIANCE_ROOT, "matrixCorrections")),
        createReportPrintTileObject("Student-Specific Corrections Print Version", StudentCorrectionsPrintView),
    ],
    stateReports: [
        createReportTileObject("All District Statuses", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "districtStatus")),
        createReportTileObject("Findings of Noncompliance by Standard", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "nonComplianceStandard")),
        createReportTileObject("Correction of Noncompliance", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "nonCompliance")),
        createReportTileObject("Matrix of Services", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "matrix")),
        createReportTileObject("Correction of Matrix Discrepancies", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "matrixCorrections")),
        createReportTileObject("CAP State Summary Report", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "cap")),
        createReportTileObject("Student-Specific Corrections", createStateReportApiEndPoint(MONITORING_COMPLIANCE_STATE_ROOT, "studentCorrections"))
    ]
};

// ************************************ Monitoring for Performance Report configuration ****************************
export const MONITORING_PERFORMANCE_ROOT = `monitoring/performance/reports`;
export const MONITORING_PERFORMANCE_STATE_ROOT = `${MONITORING_PERFORMANCE_ROOT}/state`;

export const monitoringPerformance = {
    info: createReportInfoObject(
        `theme-${themeConstants.MONITORING_PERFORMANCE}`,
        "Monitoring for Performance District Reports",
        "Monitoring for Performance State Reports",
        contexts.MONITORING_PERFORMANCE
    ),
    districtReports: [
        createReportTileObject("On-Site Tracking", createDistrictReportApiEndPoint(MONITORING_PERFORMANCE_ROOT, "onSiteTracking")),
    ],
    stateReports: [
        createReportTileObject("On-Site Tracking", createStateReportApiEndPoint(MONITORING_PERFORMANCE_STATE_ROOT, "onSiteTracking")),
    ]
};

// ************************************ Dispute Resolution State Complaints Report configuration ****************************
export const DISPUTE_RESOLUTION_COMPLAINTS_ROOT = `dispute-resolution/complaints/reports`;
export const DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT = `${DISPUTE_RESOLUTION_COMPLAINTS_ROOT}/state`;

export const disputeResolutionComplaints = {
    info: createReportInfoObject(
        `theme-${themeConstants.STATE_COMPLAINTS}`,
        "Dispute Resolution State Complaints District Reports",
        "Dispute Resolution State Complaints State Reports",
        contexts.STATE_COMPLAINTS
    ),
    districtReports: [
        createReportTileObject("Summary Report", createDistrictReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_ROOT, "summary"))
    ],
    stateReports: [
        createReportTileObject("Tracking Sheet", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "tracking")),
        createReportPrintTileObject("Tracking Sheet Print Version", TrackingSheetPrintView),
        createReportTileObject("IDEA Part B Dispute Resolution Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "federal")),
        createReportPrintTileObject("IDEA Part B Dispute Resolution Report Print Version", StateComplaintFederalReportPrintView),
        createReportTileObject("Report (A)", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "report-a")),
        createReportTileObject("Corrective Action Summary Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "actions")),
        createReportTileObject("Citations and Violations Data Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "citationViolations")),
        createReportTileObject("IDEA Part B Dispute Resolution Report By Team Member", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "complaintDataByTeamMember")),
        createReportTileObject("Extension Tracker", createStateReportApiEndPoint(DISPUTE_RESOLUTION_COMPLAINTS_STATE_ROOT, "extensionTracker"))
    ]
};

// ************************************ Dispute Resolution Due Process Report configuration ****************************
export const DISPUTE_RESOLUTION_DUE_PROCESS_ROOT = `dispute-resolution/dueProcess/reports`;
export const DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT = `${DISPUTE_RESOLUTION_DUE_PROCESS_ROOT}/state`;

export const disputeResolutionDueProcess = {
    info: createReportInfoObject(
        `theme-${themeConstants.DUE_PROCESS}`,
        "Dispute Resolution Due Process Hearing Requests District Reports",
        "Dispute Resolution Due Process Hearing Requests State Reports",
        contexts.DUE_PROCESS
    ),
    districtReports: [
        createReportTileObject("Summary Report", createDistrictReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_ROOT, "summary"))
    ],
    stateReports: [
        createReportTileObject("Summary Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT, "summary")),
        createReportTileObject("Final Order Timeline Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT, "timeline")),
        createReportTileObject("IDEA Part B Dispute Resolution Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT, "federal")),
        createReportPrintTileObject("IDEA Part B Dispute Resolution Report Print Version", DueProcessFederalReportPrintView),
        createReportTileObject("ESE Dispute Resolution Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT, "federalWithGifted")),
        createReportPrintTileObject("ESE Dispute Resolution Report Print Version", DueProcessFederalWithGiftedReportPrintView),
        createReportTileObject("Resolution Process Status Report Tracking", createStateReportApiEndPoint(DISPUTE_RESOLUTION_DUE_PROCESS_STATE_ROOT, "resolution-process")),
    ]
};

// ************************************ Dispute Resolution Mediation Report configuration ****************************
export const DISPUTE_RESOLUTION_MEDIATION_ROOT = `dispute-resolution/mediation/reports`;
export const DISPUTE_RESOLUTION_MEDIATION_STATE_ROOT = `${DISPUTE_RESOLUTION_MEDIATION_ROOT}/state`;

export const disputeResolutionMediation = {
    info: createReportInfoObject(
        `theme-${themeConstants.MEDIATIONS}`,
        "Dispute Resolution Mediation District Reports",
        "Dispute Resolution Mediation State Reports",
        contexts.MEDIATIONS
    ),
    districtReports: [
        createReportTileObject("Summary Report", createDistrictReportApiEndPoint(DISPUTE_RESOLUTION_MEDIATION_ROOT, "summary"))
    ],
    stateReports: [
        createReportTileObject("Summary Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_MEDIATION_STATE_ROOT, "summary")),
        createReportTileObject("IDEA Part B Dispute Resolution Report", createStateReportApiEndPoint(DISPUTE_RESOLUTION_MEDIATION_STATE_ROOT, "federal")),
        createReportPrintTileObject("IDEA Part B Dispute Resolution Report Print Version", MediationFederalReportPrintView)
    ]
};

// ************************************ User Report configuration ****************************
export const USER_ROOT = `users/reports`;
export const USER_STATE_ROOT = `${USER_ROOT}/state`;

export const user = {
    info: createReportInfoObject(
        `theme-${themeConstants.USERS}`,
        "User District Reports",
        "User State Reports",
        contexts.USERS
    ),
    stateReports: [
        createReportTileObject("Login Activity Report", createStateReportApiEndPoint(USER_STATE_ROOT, "login-activity")),
        createReportTileObject("User Details Report", createStateReportApiEndPoint(USER_STATE_ROOT, "user-details"))
    ]
};

// ************************************ Bpie Report configuration ****************************
export const bpie = {
    info: createReportInfoObject(
        `theme-${themeConstants.BPIE}`,
        "SBPIE District Reports",
        "SBPIE Reports",
        contexts.BPIE
    ),
    districtReports: [
        createReportPrintTileObject("School District Indicator Priority", IndicatorPriorityReportContainer, "SBPIE Priority Indicators", true),
        createReportTileObject("District Summary", createDistrictReportApiEndPoint(BPIE_REPORT_ROOT, "districtSummaryReport"), true),
        createReportPrintTileObject("School Status Trend", SchoolStatusTrendReportContainer, "School Status Trend Report", true),
        createReportPrintTileObject("School Status Trend Chart", SchoolStatusTrendChartContainer, "School Status Trend Chart", true),
        createReportPrintTileObject("School Priority Trend", SchoolPriorityTrendReportContainer, "School Priority Trend Report", true),
    ],
    stateReports: [
        createReportPrintTileObject("School Indicator Priority", IndicatorPriorityReportContainer, "SBPIE Priority Indicators", true),
        createReportPrintTileObject("School Status Trend", SchoolStatusTrendReportContainer, "School Status Trend Report", true),
        createReportPrintTileObject("School Status Trend Chart", SchoolStatusTrendChartContainer, "School Status Trend Chart", true),
        createReportPrintTileObject("School Priority Trend", SchoolPriorityTrendReportContainer, "School Priority Trend Report", true),
    ]
};

// ***************************************** List of reports to include *********************************
export const allReports = [spp, monitoringCompliance, monitoringPerformance, disputeResolutionComplaints, disputeResolutionDueProcess, disputeResolutionMediation, user, bpie];
export const allReportContexts = allReports.map(report => report.info.context);

export const getReportComponent = (reportId, context, reportArea) => {
    const contextReports = allReports.filter(f => f.info.context === context);

    if(!contextReports)
        return null;

    let report = null;

    for(let i = 0; i < contextReports.length; i++) {
        if(contextReports[i][`${reportArea}Reports`]) {
            report = contextReports[i][`${reportArea}Reports`].find(f => f.name === reportId);

            if(report)
                return report.component;
        }
    }

    return null;
};
