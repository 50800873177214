import React from 'react';
import AbstractNumberField, {AbstractNumberFieldProps} from "./AbstractNumberField";

const NumberField = (props) => {

    const {inputClass = ""} = props;

    return <AbstractNumberField
        {...props}
        inputClass={`${inputClass} input-icon --dollar`}
        isPositiveNumberOnly
    />;
};

NumberField.propTypes = AbstractNumberFieldProps;

export default NumberField;