import SppAdminApi from "../api/SppAdminApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function CopySppDocumentSuccess() {
	return {type: actionTypes.COPY_SPP_DOCUMENT_AJAX_SUCCESS};
}

export function CopySppDocument(sourceDocumentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.CopySppDocument(sourceDocumentId)
			.then(() => {
				dispatch(CopySppDocumentSuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function CopySppResponsesSuccess(data) {
	return {type: actionTypes.COPY_SPP_DOCUMENT_AJAX_SUCCESS, data};
}

export function CopySppResponses(destinationDocumentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.CopySppResponses(destinationDocumentId)
			.then((data) => {
				dispatch(CopySppResponsesSuccess(data));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function ToggleSppDocumentStatusSuccess(instances) {
	return {type: actionTypes.LOAD_SPP_INSTANCES_AJAX_SUCCESS, instances};
}

export function ToggleSppDocumentStatus(documentId) {
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.ToggleSppDocumentStatus(documentId)
			.then((data) => {
				dispatch(ToggleSppDocumentStatusSuccess(data));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function editSppElement(elementId){
    return { type: actionTypes.EDIT_SPP_ELEMENT, data: elementId };
}

export function saveSppElementSuccess(element){
	return { type: actionTypes.SAVE_SPP_ELEMENT_AJAX_SUCCESS, data: element};
}

export function saveSppElement(element){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.saveSppDocumentElement(element)
			.then(() => {
				dispatch(saveSppElementSuccess(element));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

export function addSppElementSuccess(){
	return { type: actionTypes.ADD_SPP_ELEMENT_AJAX_SUCCESS };
}

export function addSppElement(createElementViewModel){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.addSppDocumentElement(createElementViewModel)
			.then(() => {
				dispatch(addSppElementSuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw(error); //needed
			});
	};
}

export function deleteSppElementSuccess(){
	return { type: actionTypes.DELETE_SPP_ELEMENT_AJAX_SUCCESS };
}

export function deleteSppElement(documentId, deletedElementId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.deleteSppDocumentElements(documentId, [deletedElementId])
			.then(() => {
				dispatch(deleteSppElementSuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw(error); //needed
			});
	};
}

export function cancelEditSppElement(){
    return { type: actionTypes.CANCEL_EDIT_SPP_ELEMENT };
}

export function saveSppElementOrderSuccess() {
	return {type: actionTypes.SAVE_SPP_ELEMENT_ORDER_AJAX_SUCCESS};
}

export function saveElementOrder(documentId, moveElementViewModel){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppAdminApi.saveElementOrder(documentId, moveElementViewModel)
			.then(() => {
				dispatch(saveSppElementOrderSuccess());
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				throw(error); //needed
			});
	};
}
