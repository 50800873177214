import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as MonitoringComplianceActions from "../../actions/monitoringComplianceActions";
import {getProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import LocalStorage from "../../components/shared/LocalStorage";
import { getParams } from "../../components/layout/getParams";
import MatrixOfServiceDistrict from "../../components/MonitoringCompliance/MatrixOfServiceDistrict";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import {NotifyUser} from "../../components/common/NotifyUser";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class MatrixOfServiceDistrictPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            studentRecord: null,
            protocolResponses: [],
            indicatorName: "",
            districtName: "",
            params: {},
            showDelete: true
        };

        this.onChangeProtocolResponse = this.onChangeProtocolResponse.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickReturn = this.onClickReturn.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
    }

    componentDidMount() {
        const params = getParams(this.props.location.pathname, ComplianceLocations.COMPLIANCE_MATRIX_RECORD_DISTRICT);
        if (params !== null)
            this.setState({params});

        if (MonitoringComplianceUtilities.isValidId(params.assessmentId)) {
            this.props.actions.loadComplianceAssessment(params.assessmentId)
                .then(() => {
                    this.props.actions.setEditMode(!this.props.indicatorRequirementStatus.allStudentRecordsSubmitted || false);

                    if (this.props.selectedAssessment.indicatorRequirements) {
                        const indicatorRequirementArray = this.props.selectedAssessment.indicatorRequirements.filter(r => r.indicatorRequirementId === params.indicatorRequirementId);
                        if(indicatorRequirementArray.length === 1)
                            this.props.actions.loadIndicatorProtocols(this.props.selectedAssessment.dateWindowId, indicatorRequirementArray[0].indicatorId);
                    }
                });
        }

        if (!this.props.institution)
            this.props.actions.loadInstitutions();
    }

    componentDidUpdate() {
        if (!this.state.studentRecord &&
            this.props.institution.id &&
            this.props.currentIndicatorRequirement &&
            this.props.selectedAssessment.studentRecords &&
            this.props.indicatorProtocols &&
            this.props.indicatorProtocols.length > 0) {

            let studentRecord = this.props.selectedAssessment.studentRecords.find(rec => {
                return rec.studentRecordId === this.state.params.studentRecordId;
            });

            if (studentRecord) {
                this.props.actions.updatePageTitle("Matrix of Service: " + studentRecord.name);
                let protocolResponses = [...studentRecord.protocolResponses.map(rec => Object.assign({}, rec))];
                this.setState({
                    studentRecord: studentRecord,
                    protocolResponses: this.setDefaultResponse(protocolResponses)
                });

                return;
            }

            //Student record was not found.  Create new student record
            const studentRecordId = this.state.params.studentRecordId;
            const newRecord = MonitoringComplianceUtilities.newStudentRecord(studentRecordId, [this.props.currentIndicatorRequirement.indicatorId]);
            this.props.actions.updatePageTitle("Matrix of Service: New Student Record");
            this.setState({
                studentRecord: newRecord,
                protocolResponses: this.setDefaultResponse([]),
                showDelete: false
            });


        }
    }

    onChangeProtocolResponse(event, protocolId, name) {
        const districtResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
            this.state.protocolResponses,
            protocolId,
            responseTypes.ASSESS);

        districtResponse[name] = event.target.name.startsWith("cb") && !event.target.checked ? "" : event.target.value;

        this.setState({
            protocolResponses: this.state.protocolResponses.map(rec => {
                if (rec.protocolId === protocolId && rec.responseType === responseTypes.ASSESS)
                    rec.response = `${districtResponse.reportedDistrict},${districtResponse.reviewedDistrict}`;

                return rec;
            })
        });
    }

    createNewProtocolResponses(protocolResponses) {
        this.props.indicatorProtocols.map(protocol => {
            let response = protocolResponses.find(r => r.protocolId === protocol.protocolId && r.responseType === responseTypes.ASSESS);
            if (!response) {
                let protocolResponseId = emptyGuid;
                let newResponse = MonitoringComplianceUtilities.newProtocolResponse(protocolResponseId, protocol.protocolId, "", responseTypes.ASSESS);
                protocolResponses.push(newResponse);
            }
        });
    }

    setDefaultResponse(protocolResponses) {
        if(protocolResponses.filter(f => f.responseType === responseTypes.ASSESS).length !== this.props.indicatorProtocols.length && this.props.isEditing && this.canUserEdit()) {
                this.createNewProtocolResponses(protocolResponses);
        }

        return protocolResponses;
    }

    validate() {
        let isValid = true;

        const reportedByDistrict = document.getElementById("totalDistrictReported").innerText;
        const districtReportedCostFactor = (reportedByDistrict === "") ? 0 : parseInt(reportedByDistrict);
        const reviewedByDistrict = document.getElementById("totalDistrictReviewed").innerText;
        const districtReviewedCostFactor = (reviewedByDistrict === "") ? 0 : parseInt(reviewedByDistrict);

        if(districtReportedCostFactor < 254 || districtReviewedCostFactor < 254) {
            NotifyUser.Error("Both the reported and reviewed need to have a cost factor of 254 or 255.  Otherwise the Matrix of Service is not submittable for review.");
            isValid = false;
        }

        return isValid;
    }

    onClickSave(event) {
        event.preventDefault();
        this.save(true);
    }

    save(reload) {
        if((!this.props.isEditing || !this.canUserEdit()) && !reload)
            this.returnToMainPage();

        if (this.props.isEditing && this.canUserEdit() && this.validate()) {
            this.props.actions.saveStudentRecord(this.props.institution.id,
                this.props.selectedAssessment.monitoringAssessmentId,
                this.state.studentRecord.studentRecordId,
                MonitoringComplianceUtilities.prepareProtocolResponsesForSave(this.state.protocolResponses, responseTypes.ASSESS),
                responseTypes.ASSESS)
                .then((newStudentRecordId) => {
                    if(!newStudentRecordId) return;
                    if (reload) {
                        if(this.state.params.studentRecordId === emptyGuid) {
                            this.setState({
                                params: {...this.state.params, studentRecordId: newStudentRecordId}
                            });
                            this.props.history.push(ComplianceLocations.COMPLIANCE_MATRIX_RECORD_DISTRICT.path
                                .replace(ComplianceLocations.ASSESSMENT_ID, this.state.params.assessmentId)
                                .replace(ComplianceLocations.REQUIREMENT_ID, this.state.params.indicatorRequirementId)
                                .replace(ComplianceLocations.STUDENT_RECORD_ID, newStudentRecordId));
                        }

                        this.props.actions.loadComplianceAssessment(this.state.params.assessmentId)
                            .then(() => {
                                this.setState({studentRecord: null});
                                this.props.actions.setEditMode(!this.props.indicatorRequirementStatus.allStudentRecordsSubmitted || false);
                            });
                    } else
                        this.returnToMainPage();
                });
        }
    }

    returnToMainPage() {
        this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);
    }

    onClickReturn(event) {
        event.preventDefault();
        this.save(false);
    }

    onClickDelete(event) {
        event.preventDefault();

        if(confirm("Are you sure you want to delete this student record?")) {
            this.props.actions.deleteStudentRecord(this.props.institution.id,
                this.props.selectedAssessment.monitoringAssessmentId,
                this.state.studentRecord.studentRecordId)
                .then(() => {
                    this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);
                });
        }
    }

    canUserEdit() {
        return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    }

    render() {
        if (!this.props.selectedAssessment.studentRecords || !this.state.studentRecord ) return null;
        const canEdit = this.canUserEdit();
        const isEditing = this.props.isEditing && canEdit;
        return (
            <div>
                {
                    isEditing &&
                    <ButtonBar position={ButtonBarPositions.STICKY}>
                        {
                            this.state.showDelete &&
                            <Button name={"delete"}
                                    label={"Delete"}
                                    onClick={this.onClickDelete}
                                    disabled={this.props.isLoading}
                                    buttonType={ButtonTypes.DELETE}/>
                        }
                        <Button name={"save"}
                                label={"Save"}
                                onClick={this.onClickSave}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.SAVE}/>
                        <Button name={"cancel"}
                                label={"Save and Return"}
                                onClick={this.onClickReturn}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.BACK}/>
                    </ButtonBar>
                }
                {
                    !isEditing &&
                    <ButtonBar position={ButtonBarPositions.STICKY}>
                        <Button name={"cancel"}
                                label={"Return"}
                                onClick={this.onClickReturn}
                                disabled={this.props.isLoading}
                                buttonType={ButtonTypes.BACK}/>
                    </ButtonBar>
                }
                {
                    this.props.indicatorProtocols.length > 0 &&
                    <MatrixOfServiceDistrict
                        indicatorProtocols={this.props.indicatorProtocols}
                        protocolResponses={this.state.protocolResponses}
                        onResponseChange={this.onChangeProtocolResponse}
                        isEditing={isEditing}
                        assessmentApproved={this.props.indicatorRequirementStatus.assessmentApproved}
                    />
                }
            </div>
        );
    }
}

MatrixOfServiceDistrictPage.propTypes = {
    actions: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object.isRequired,
    indicatorProtocols: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentIndicatorRequirement: PropTypes.object,
    indicatorRequirementStatus: PropTypes.object,
    institution: PropTypes.object,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    let institution = {};
    let currentIndicatorRequirement = {};
    const params = getParams(ownProps.location.pathname, ComplianceLocations.COMPLIANCE_MATRIX_RECORD_DISTRICT);

    const currentUser = LocalStorage.getLoggedInUser();

    if (state.sharedData.institutions.length > 0) {
        const institutionArray = state.sharedData.institutions.filter(i => i.id === currentUser.InstitutionId);
        if(institutionArray.length === 1)
            institution = Object.assign({}, institutionArray[0]);
    }

    if (state.monitoringCompliance.selectedAssessment && state.monitoringCompliance.selectedAssessment.indicatorRequirements) {
        const currentIndicatorRequirementArray  = state.monitoringCompliance.selectedAssessment.indicatorRequirements.filter(ir => ir.indicatorRequirementId === params.indicatorRequirementId);
        if(currentIndicatorRequirementArray.length === 1)
            currentIndicatorRequirement = Object.assign({}, currentIndicatorRequirementArray[0]);
    }

    const indicatorRequirementStatus = getProtocolRequirementStatus(currentIndicatorRequirement);

    return {
        selectedAssessment: state.monitoringCompliance.selectedAssessment,
        indicatorProtocols: state.monitoringCompliance.indicatorProtocols,
        currentIndicatorRequirement: currentIndicatorRequirement,
        indicatorRequirementStatus: indicatorRequirementStatus,
        institution: institution,
        isEditing: state.monitoringCompliance.isEditing,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        sharedDataActions,
        MonitoringComplianceActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MatrixOfServiceDistrictPage);