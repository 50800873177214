import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {emptyGuid} from "../../../../constants/config";
import * as gmsLocations from "../../gmsLocations";
import gmsApplicationSettingsApi from "./gmsApplicationSettingsApi";
import {GmsApplicationSettingsEdit} from "./GmsApplicationSettingsEdit";
import {createApplicationSettingsObject} from "./gmsApplicationSettingsObjectFactory";

const GmsApplicationSettingsContainer = ({
                                             actions,
                                             allFiscalAgents,
                                             applicationId,
                                             dateWindowId,
                                             history
                                         }) => {
    const [application, setApplication] = useState(null);

    const handleSaveApplicationSettings = (saveApplicationSettings) => {
        actions.executeApi(gmsApplicationSettingsApi.saveApplicationSettings, [applicationId, dateWindowId, saveApplicationSettings])
            .then(redirectToSummary);
    };

    const redirectToSummary = () => {
        const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
        history.push(resolvedPath);
    }

    useEffect(() => {
        const pageTitle = applicationId === emptyGuid ? `Add an Application` : `Update an Application`;
        actions.updatePageTitle(pageTitle);

        const initializeData = async () => {
            await actions.executeApi(gmsApplicationSettingsApi.getApplicationSettings, [applicationId])
                .then((result) => {

                    if(!result.canEditApplicationSettings) {
                        redirectToSummary();
                        return;
                    }

                    setApplication(createApplicationSettingsObject(result));
                });
        };

        initializeData();

    }, []);

    if(!application)
        return null;

    return (<GmsApplicationSettingsEdit
        allFiscalAgents={allFiscalAgents}
        application={application}
        handleCancelApplicationSettings={redirectToSummary}
        handleSaveApplicationSettings={handleSaveApplicationSettings}
        isFinalAllocationOnlyEditable={application.canEditFinalAllocationOnly}
        isTargetedAllocationOnlyEditable={application.canEditTargetedAllocationOnly}
    />);
};


GmsApplicationSettingsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    applicationId: PropTypes.string.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default GmsApplicationSettingsContainer;