import {mockResolveApiCall} from "../../../../../api/ApiUtility";
import * as ApiUtility from "../../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../../api/requests/RequestTypes";
import * as config from "../../../../../constants/config";
import {API_MESSAGES} from "../../../ptsConstants";
import {API_ROOT} from "../../../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsManageFiscalAgentsUsersApi {
    static saveProgramFiscalAgents(programId, fiscalAgentUsers) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/programs/${programId}/agents`,
                RequestTypes.PUT,
                JSON.stringify(fiscalAgentUsers),
                API_MESSAGES.ADMIN.PROGRAMS.FISCAL_AGENTS_USERS.saved)
        );
    }

    static saveProgramUserPhone(programId, fiscalAgentId, programUserId, phoneNumber) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/programs/${programId}/fiscalAgent/${fiscalAgentId}/programUser/${programUserId}`,
                RequestTypes.PUT,
                JSON.stringify(phoneNumber),
                API_MESSAGES.ADMIN.PROGRAMS.PROGRAM_USER.saved
            )
        );
    }}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsManageFiscalAgentsUsersApi {
    static saveProgramFiscalAgents() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.PROGRAMS.FISCAL_AGENTS_USERS.saved);
    }

    static saveProgramUserPhone() {
        return ApiUtility.mockResolveApiCall();
    }
}

const PtsManageFiscalAgentsUsersApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsManageFiscalAgentsUsersApi : MockPtsManageFiscalAgentsUsersApi;
export default PtsManageFiscalAgentsUsersApi;