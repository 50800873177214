import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import * as layoutActions from "../../../actions/layoutActions";
import * as sharedDataActions from "../../../actions/sharedDataActions";
import {fetchStartYearFromList} from "../../../components/common/dateUtilities";
import {removeDomainForInternalRoute} from "../../../components/common/routingUtilities";
import {createBeessIndicatorList} from "../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import LocalStorage from "../../../components/shared/LocalStorage";
import * as sharedDataUtilities from "../../../components/shared/sharedDataUtilities";
import * as monitoringComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as searchCriteriaActions from '../../../actions/searchCriteriaActions';
import McApi from "./McSummaryApi";
import {
    McFilterVisibilityRules,
    MonitoringComplianceDistrictSearchCriteria
} from "../../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import {McFilter} from "../../../components/MonitoringCompliance/McFilter";
import {McNavigation} from "../../../components/MonitoringCompliance/McNavigation";
import McSummaryView from "./McSummaryView";

const McSummaryContainer = ({
                                actions,
                                currentLocation,
                                dateWindows,
                                defaultDateWindowId,
                                history,
                                institutions,
                                searchCriteria
                            }) => {
    const [summary, setSummary] = useState(null);

    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if (reloadsData) {
            await initializeData(criteria);
        } else
            actions.saveSearchCriteria(criteria);
    };

    const setDateRangeSearchCriteria = async (localSearchCriteria) => {
        let criteria = {...searchCriteria};
        criteria.dateRange = localSearchCriteria.dateRange;
        criteria.endDate = localSearchCriteria.endDate;
        criteria.startDate = localSearchCriteria.startDate;

        await handleSearchFiltersChange(criteria, false);
    };

    const setSearchCriteria = async (localSearchCriteria, summarySearch) => {
        const district = institutions.find(f => f.id === summarySearch.districtId);
        const currentUserId = LocalStorage.getLoggedInUser().Id;
        const teamMemberAccount = !district || !district.districtSiteSectionTeamMemberDtos
            ? null
            : district.districtSiteSectionTeamMemberDtos.find(f => f.userId === currentUserId);

        let criteria = {...searchCriteria};
        criteria.dateRange = localSearchCriteria.dateRange;
        criteria.district = summarySearch.districtName;
        criteria.endDate = localSearchCriteria.endDate;
        criteria.startDate = localSearchCriteria.startDate;
        criteria.teamMember = !teamMemberAccount ? "" : currentUserId;

        await handleSearchFiltersChange(criteria, false);
    };

    const handleClickGeneral = (dateWindowId, summarySearch) => {
        if (!summarySearch.url)
            summarySearch.url = monitoringComplianceLocations.MANAGE_PROTOCOLS_DOE_DISTRICT.path
                .replace(monitoringComplianceLocations.DISTRICT_ID, summarySearch.districtId)
                .replace(monitoringComplianceLocations.DATE_WINDOW_ID, dateWindowId);

        history.push(removeDomainForInternalRoute(summarySearch.url));
    };

    const initializeData = async (criteria, forceLoadIndicators = false) => {
        const {dateWindowId} = criteria;
        if (!dateWindowId)
            return;

        if(searchCriteria.dateWindowId !== criteria.dateWindowId || forceLoadIndicators) {
            let beessIndicators = await actions.loadBeessIndicators(fetchStartYearFromList(dateWindows, dateWindowId));
            let indicators = createBeessIndicatorList(beessIndicators);
            criteria.allIndicators = indicators;
            criteria.indicatorType = criteria.indicatorType !== "" && forceLoadIndicators ? criteria.indicatorType : indicators.length > 0 ? indicators[0].value : "";
        }

        if(criteria.indicatorType) {
            let result = await actions.executeApi(McApi.getMcSummaryData, [dateWindowId, criteria.indicatorType]);
            setSummary(result);
        }

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if (!defaultDateWindowId) return;

        let criteria = {...searchCriteria};

        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(McFilterVisibilityRules.McSummary);

        initializeData(criteria, true);

    }, [defaultDateWindowId]);

    useEffect(() => {
        actions.updatePageTitle(`My Summary`);
    }, []);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(McFilterVisibilityRules.McSummary))
        return null;

    return (
        <>
            <McFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            {
                !searchCriteria.indicatorType &&
                <p>No indicators have been created for this year.</p>
            }
            <McSummaryView
                mcSummarySearchCriteria={searchCriteria}
                handleClickGeneral={handleClickGeneral}
                setDateRangeSearchCriteria={setDateRangeSearchCriteria}
                setMcSummarySearchCriteria={setSearchCriteria}
                summary={summary}
            />

            <McNavigation currentLocation={currentLocation} history={history}/>
        </>
    );
};

const mapStateToProps = (state, props) => {
    let defaultDateWindowId = state.sharedData.selectedDateWindowId;
    const institutions = [...state.sharedData.institutions];

    const searchCriteria = state.searchCriteria.MonitoringComplianceDistrictSearchCriteria || new MonitoringComplianceDistrictSearchCriteria(defaultDateWindowId);

    const currentLocation = props.location.pathname;

    let dateWindows = [];
    if (state.sharedData.dateWindows.length > 0) {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
        if (!sharedDataUtilities.isValueInList(dateWindows, state.sharedData.selectedDateWindowId))
            defaultDateWindowId = dateWindows[0].value;
    }

    return {
        currentLocation,
        dateWindows,
        defaultDateWindowId,
        institutions,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        sharedDataActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

McSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.array.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(McSummaryContainer);

