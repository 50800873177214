import {convertObjectToFormData} from "../../api/ApiUtility";
import * as config from "../../constants/config";
import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../api/requests/RequestTypes';
import {dr_api as ApiSuccessMessages} from "../../constants/api/ApiSuccessMessages";
import * as mockData from "../drMockData";

const disputeApi = 'dispute-resolution';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrStateComplaintApi {
	static getSummary(dateWindowId) {
        return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/summaries/${dateWindowId}`,
				RequestTypes.GET));
	}

	static getComplaints(dateWindowId, status) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/Dates/${dateWindowId}/statuses/${status}`,
				RequestTypes.GET));
	}

    static getDistrictComplaintsByDate(districtId, routeTemplate, dateWindowId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/${districtId}/complaints/Date/${dateWindowId}/route`,
                RequestTypes.GET,
                { routeTemplate }));
    }

	static addComplaint(model) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/${model.districtId}/complaints/`,
				RequestTypes.POST,
				JSON.stringify(model),
				ApiSuccessMessages.state_complaints.ADDED,
				'application/json'));
	}

	static saveComplaint(model, displaySuccessMessage = true) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/${model.districtId}/complaints/`,
				RequestTypes.PUT,
				JSON.stringify(model),
				displaySuccessMessage ? ApiSuccessMessages.state_complaints.SAVED : "",
				'application/json'));
	}

	static getComplaint(complaintId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/`,
				RequestTypes.GET));
	}

	static getComplaintTasks(complaintId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/tasks`,
				RequestTypes.GET));
	}

	static getDefaultComplaintCaseNumber(dateWindowId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/Date/${dateWindowId}/caseNumber/`,
				RequestTypes.GET));
	}

	static deleteComplaint(complaintId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/`,
				RequestTypes.DELETE));
	}

	static getIntakeRequirements(intakeRequirementVersionId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/intake-requirements/${intakeRequirementVersionId}/`,
				RequestTypes.GET));
	}

	static saveIntakeResponseForm(intakeResponseFormDataModel) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${intakeResponseFormDataModel.complaintId}/intake-requirements`,
				RequestTypes.PUT,
				JSON.stringify(intakeResponseFormDataModel),
				ApiSuccessMessages.state_complaints.intake.SAVED,
				'application/json'));
	}

	static saveTimeline(model) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/timeline`,
				RequestTypes.PUT,
				JSON.stringify(model),
				ApiSuccessMessages.state_complaints.timeline.SAVED,
				'application/json'));
	}

	static getIssueCodes() {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/issue-codes`,
				RequestTypes.GET));
	}

	static getCitations(dateWindowId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/Dates/${dateWindowId}/Citations`,
				RequestTypes.GET));
	}

	static getStudentExceptionalityTypes() {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/student-exceptionalities`,
				RequestTypes.GET));
	}

	static saveAction(model) {
		const url = model.issueId
			? `${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/issue/${model.issueId}/action`
			: `${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/action`;

		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				url,
				RequestTypes.POST,
				JSON.stringify(model),
				ApiSuccessMessages.state_complaints.action.SAVED,
				'application/json'));
	}

	static deleteAction(model) {
		const url = model.issueId
			? `${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/issue/${model.issueId}/action`
			: `${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/action`;

		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				url,
				RequestTypes.DELETE,
				JSON.stringify(model.id),
				ApiSuccessMessages.state_complaints.action.DELETED,
				'application/json'));
	}

	static saveIssue(model) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/issue`,
				RequestTypes.PUT,
				JSON.stringify(model),
				ApiSuccessMessages.state_complaints.issue.SAVED,
				'application/json'));
	}

	static deleteIssue(complaintId, issueId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/issue`,
				RequestTypes.DELETE,
				JSON.stringify(issueId),
				ApiSuccessMessages.state_complaints.issue.DELETED,
				'application/json'));
	}

	static saveResponse(response, deadline, complaintId, issueId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}${issueId ? `/issues/${issueId}`
					: ""}/actions/${deadline.actionId}/deadlines/${deadline.id}/responses`,
				RequestTypes.PUT,
				JSON.stringify(response),
				ApiSuccessMessages.state_complaints.response.SAVED,
				'application/json'));
	}

	static saveResponseReview(review, deadline, complaintId, issueId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}${issueId ? `/issues/${issueId}`
					: ""}/actions/${deadline.actionId}/deadlines/${deadline.id}/responses/${review.responseId}/reviews`,
				RequestTypes.PUT,
				JSON.stringify(review),
				ApiSuccessMessages.state_complaints.response.SAVED,
				'application/json'));
	}

	static auditResponse(response, deadline, complaintId, issueId, auditAction) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}${issueId ? `/issues/${issueId}`
					: ""}/actions/${deadline.actionId}/deadlines/${deadline.id}/responses/${response.id}/${auditAction}`,
				RequestTypes.POST,
				null,
				ApiSuccessMessages.state_complaints.response.audit.getMessage(auditAction),
				'application/json'));
	}

	static saveAuditComment(responseId, deadline, complaintId, issueId, comment, existing, auditAction="comments") {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}${issueId ? `/issues/${issueId}`
					: ""}/actions/${deadline.actionId}/deadlines/${deadline.id}/responses/${responseId}/audits/${comment.auditId}/${auditAction}`,
				existing ? RequestTypes.PUT : RequestTypes.POST,
				JSON.stringify(comment),
				ApiSuccessMessages.state_complaints.response.audit.getMessage(auditAction),
				'application/json'));
	}

	static createClosureProcess(complaintId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/closure-process`,
				RequestTypes.POST,
				null,
				ApiSuccessMessages.state_complaints.closure.ADDED,
				'application/json'));
	}

	static saveClosureProcess(complaintId, closureProcessRequirements) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/closure-process`,
				RequestTypes.PUT,
				JSON.stringify(closureProcessRequirements),
				ApiSuccessMessages.state_complaints.closure.SAVED,
				'application/json'));
	}

	static removeClosureProcess(complaintId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/closure-process`,
				RequestTypes.DELETE,
				null,
				ApiSuccessMessages.state_complaints.closure.DELETED,
				'application/json'));
	}

	static getFederalReport(dateWindowId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/reports/state/federalPrint/Dates/${dateWindowId}`,
				RequestTypes.GET));
	}

	static saveCaseNote(model) {
		const isNewCaseNote = !model.caseNoteId;
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${model.complaintId}/case-notes`,
				isNewCaseNote ? RequestTypes.POST : RequestTypes.PUT,
				JSON.stringify(model),
				isNewCaseNote ? ApiSuccessMessages.state_complaints.caseNote.ADDED : ApiSuccessMessages.state_complaints.caseNote.SAVED,
				'application/json'));
	}

	static deleteCaseNote(complaintId, caseNoteId) {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/${complaintId}/case-note/${caseNoteId}`,
				RequestTypes.DELETE,
				null,
				ApiSuccessMessages.state_complaints.caseNote.DELETED,
				'application/json'));
	}

	static downloadCaseInvestigationReport(complaintId, caseNoteFilter) {
		return ApiUtility.downloadBlob(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/reports/state/investigationLogReport/complaints/${complaintId}?startDate=${caseNoteFilter.startDate}&endDate=${caseNoteFilter.endDate}&noteType=${caseNoteFilter.noteType}`,
				RequestTypes.GET,
				null,
				ApiSuccessMessages.state_complaints.caseNote.DOWNLOAD
			));
	}

	static exportCaseSearchResults(caseSearchFilters) {
		return ApiUtility.downloadBlob(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/complaints/reports/state/caseSearchResultsExport/dates/${caseSearchFilters.dateWindowId}`,
				RequestTypes.POST,
				convertObjectToFormData(caseSearchFilters),
				ApiSuccessMessages.state_complaints.CASE_RESEARCH.DOWNLOAD
			));
	}
}
//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrStateComplaintApi {
	static getSummary(dateWindowId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getStateComplaintsSummary(dateWindowId));
			}, config.environment.delay);
		});
	}

	static getComplaints() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getComplaints());
			}, config.environment.delay);
		});
	}

	static getComplaintTasks() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getDefaultComplaintCaseNumber() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(1);
			}, config.environment.delay);
		});
	}

	static getIssueCodes() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getCitations() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getStudentExceptionalityTypes() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getDistrictComplaintsByDate(districtId, routeTemplate, dateWindowId){
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getDistrictComplaintTilesByDate(districtId, routeTemplate, dateWindowId));
			}, config.environment.delay);
		});
	}

	static addComplaint(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveComplaint(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getComplaint(complaintId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve(mockData.getComplaint(complaintId));
			}, config.environment.delay);
		});
	}

    static deleteComplaint(complaintId) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

	static saveIntakeResponseForm(intakeResponseFormDataModel){
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveTimeline(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveAction(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteAction(complaintId, actionId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveIssue(model) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteIssue(complaintId, issueId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveResponse(response, deadline, complaintId, issueId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveResponseReview(review, deadline, complaintId, issueId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static submitResponse(response, deadline, complaintId, issueId) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveAuditComment(response, deadline, complaintId, issueId, comment, existing) {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static createClosureProcess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveClosureProcess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static removeClosureProcess() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getFederalReport() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static saveCaseNote() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static deleteCaseNote() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static downloadCaseInvestigationReport() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}
}


const DrStateComplaintsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrStateComplaintApi : MockDrStateComplaintApi;
export default DrStateComplaintsApi;