import AccountUtility from "../../api/AccountUtility";
import {CONDITIONALS} from "../../components/common/inputs/inputUtility";
import {allSameStatusTypeGroupsCollapseSection, BUDGET_FTE_VALUE, ELEMENT_TYPE} from "./gmsConstants";
import {
    isArrayNullOrEmpty,
    isObjectNullOrEmpty,
    objectHasProperty,
    convertToValidNumber,
    numberOfDecimalPlaces, validateDecimal, isGuid, createListItem, isTrimmedStringEmpty, isNullOrUndefined, isArray,
} from "../../components/common/commonUtilities";
import {NotifyUser} from "../../components/common/NotifyUser";
import {ELEMENT_TYPE_SUFFIX} from "./gmsObjectFactory";

export function isBudgetFteDisabled(objectCodes, code, exception) {
    const selectedObjectCode = objectCodes.find(o => o.code === code);

    if (isObjectNullOrEmpty(selectedObjectCode))
        return false;

    const requiredException = selectedObjectCode.objectCodeAssociatedFteValue === BUDGET_FTE_VALUE.required && exception;
    const notPermittedNoException = selectedObjectCode.objectCodeAssociatedFteValue === BUDGET_FTE_VALUE.notPermissible && !exception;

    return !!(requiredException || notPermittedNoException);
}

export function isFteExceptionDisabled(objectCodes, code) {
    const selectedObjectCode = objectCodes.find(o => o.code === code);

    if (isObjectNullOrEmpty(selectedObjectCode))
        return false;

    return selectedObjectCode.objectCodeAssociatedFteValue === BUDGET_FTE_VALUE.permissible;
}

export function canSaveApplicationSection(section = {}) {
    for (let [key, element] of Object.entries(section)) {
        if (element.error) {
            NotifyUser.Warning("Required responses must be completed before saving.");
            return false;
        }
    }

    return true;
}

export function isRequiredForElementType(currentElementType) {
    return currentElementType !== ELEMENT_TYPE.display
        && currentElementType !== ELEMENT_TYPE.file
        && currentElementType !== ELEMENT_TYPE.section
        && currentElementType !== ELEMENT_TYPE.doe100;
}

export function isCalculatedElementType(currentElementType) {
    return currentElementType === ELEMENT_TYPE.dollarTextbox
        || currentElementType === ELEMENT_TYPE.percentTextbox
        || currentElementType === ELEMENT_TYPE.wholeDollarTextbox
        || currentElementType === ELEMENT_TYPE.wholeNumberTextbox;
}


export function getCalculatedElementValue(options, responses) {
    const roundingPlaceholder = "#";
    let equation = "";

    options.map((item) => {
        const response = responses[item] || responses[item.toLowerCase()];
        const value = isGuid(item) ? getResponseNumberValue(response) : item;
        equation += `${value}`;
    });

    const shouldRound = equation.includes(roundingPlaceholder);
    if (shouldRound)
        equation = equation.replace(roundingPlaceholder, "");

    const evaluated = eval(equation);
    const resultNumber = convertToValidNumber(evaluated);

    const foundDigits = numberOfDecimalPlaces(resultNumber);
    const validNumberDigits = shouldRound ? 0 : 2;

    return foundDigits > 0 || shouldRound ? validateDecimal(resultNumber, validNumberDigits) : resultNumber;
}

export function getResponseObject(responseValue) {
    let response = responseValue || {value: ""};
    return objectHasProperty(response, "value") ? response : {value: response};
}

export function getResponseNumberValue(responseObject) {
    const responseValue = getResponseObject(responseObject).value;
    return convertToValidNumber(responseValue);
}

export function buildSuffixForSectionsInOverview(subElements) {
    if (isArrayNullOrEmpty(subElements))
        return "";

    let suffix = "";
    for (let groupStatusToCheck of allSameStatusTypeGroupsCollapseSection(AccountUtility.isFiscalAgentUser())) {
        for (let statusToCheck of groupStatusToCheck) {
            const isAllOfTheSameStatus = subElements.every(i => i.status === statusToCheck.id);
            if (!suffix && isAllOfTheSameStatus)
                suffix = statusToCheck.description;
        }

        const isAllOfTheGroupStatus = subElements.every(i => groupStatusToCheck.some(g => g.id === i.status));
        if (!suffix && isAllOfTheGroupStatus) {
            const descriptionArray = groupStatusToCheck.map(m => m.description);
            suffix = descriptionArray.join(" or ");
        }

        if (suffix)
            break;
    }

    return suffix;
}

export function createHistoryListItem(history) {
    return {
        ...createListItem(history.applicationHistoryId, history.applicationHistoryTitle),
        hasAmendedData: history.hasAmendedData,
        reportStatuses: history.reportStatuses
    };
}

export function updateResponseInNestedElements(elements, elementId, response) {
    for (let i = 0; i < elements.length; i++) {
        let parent = elements[i];
        if (parent.elementId === elementId)
            parent.response = response;
        else {
            for (let j = 0; j < parent.elements.length; j++) {
                let child = parent.elements[j];
                if (child.elementId === elementId)
                    child.response = response;
            }
        }
    }
}

export function getApplicationFormFileId(applicationForms, formType) {
    return applicationForms.find(f => f.formType === formType.id)?.fileId;
}

export function allowsResponse(element, responses) {
    if(!element.disableIfNotRequired || isTrimmedStringEmpty(element.requiredIf))
        return true;

    const requiredIf = JSON.parse(element.requiredIf);

    let allowStatuses = [];
    for (let requiredIfElement of requiredIf.RequiredIfElements) {
        const dependentElementId = requiredIfElement.ElementId.toLowerCase();
        const dependentResponseValue = responses[dependentElementId]?.value;
        const dependentElementType = responses[`${dependentElementId}${ELEMENT_TYPE_SUFFIX}`];

        if (isTrimmedStringEmpty(dependentResponseValue)
            || isNullOrUndefined(dependentElementType)) {
            allowStatuses.push(true);
            continue;
        }

        if (dependentElementType === ELEMENT_TYPE.checkboxList) {
            const requiredValues = JSON.parse(requiredIfElement.ResponseValue.toLowerCase());
            const dependentResponseValues = JSON.parse(dependentResponseValue.toLowerCase());

            if (!isArray(dependentResponseValues) || !isArray(requiredValues)) {
                allowStatuses.push(true);
                continue;
            }

            allowStatuses.push(dependentResponseValues.some(a => requiredValues.includes(a)));
        }
        else
            allowStatuses.push(dependentResponseValue.toLowerCase() === requiredIfElement.ResponseValue.toLowerCase());
    }

    const requiredIfCondition = requiredIf.RequiredIfCondition || CONDITIONALS.or;

    return (requiredIfCondition === CONDITIONALS.or && allowStatuses.some(a => a))
        || (requiredIfCondition === CONDITIONALS.and && allowStatuses.every(a => a));
}