import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../common/GridTable";
import ResultsGridForm from "./ResultsGridForm";
import * as correctionResultConstants from "../../../constants/monitoringCompliance/correctionResults";

const MatrixResultsGridForm = (
    {
        correctionResults,
        onResponseChange,
        isLoading
    }) =>
{
    const mainAreaWidthProps = {
        medium: "12"
    };
    return (
        <div>
            <p><strong>For matrix record: {correctionResults[0].name}</strong></p>
            <p>Highlighted items are missing data or still require to be corrected.</p>
            <GridTable>
                <thead>
                <tr>
                    <th>Correction of Cost Factor Discrepancy</th>
                </tr>
                </thead>
                <ResultsGridForm
                    correctionResults={correctionResults}
                    isLoading={isLoading}
                    onResponseChange={onResponseChange}
                    idPropName={"matrixProtocolCorrectiveResultId"}
                    correctionOptions={correctionResultConstants.matrixCorrectionOptions}
                    correctionsWithAdditionalInfoRequirement={correctionResultConstants.matrixCorrectionsWithAdditionalInfoRequirement}
                    mainAreaWidthProps={mainAreaWidthProps}
                />
            </GridTable>
        </div>
    );
};

MatrixResultsGridForm.propTypes = {
    correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onResponseChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default MatrixResultsGridForm;