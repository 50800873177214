import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../components/common/GridTable";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import HtmlDisplayInline from "../../../components/htmlDisplay/HtmlDisplayInline";
import {DrComplaintTimelineRequirementResponseOptionsList} from "../../drConstants";

const DrStateComplaintRoutingReview = ({
                                           dateReviewed,
                                           reviewNumber,
                                           routingFeedback,
                                           routingRequirementResponse
                                       }) => {
    const response = DrComplaintTimelineRequirementResponseOptionsList.find(f => f.value === routingRequirementResponse.response);
    return (
        <>
            <p><strong>Review {reviewNumber} Response</strong> - {!response ? "No Response" : response.text} on {dateReviewed}</p>
            {
                routingFeedback &&
                routingFeedback.content &&
                <>
                    <p><strong>Comments</strong></p>
                    <HtmlDisplayBlock html={routingFeedback.content} className={`routing-feedback`}/>
                </>
            }
        </>
    );
};

DrStateComplaintRoutingReview.propTypes = {
    dateReviewed: PropTypes.string.isRequired,
    reviewNumber: PropTypes.number.isRequired,
    routingFeedback: PropTypes.object,
    routingRequirementResponse: PropTypes.object.isRequired
};

export default DrStateComplaintRoutingReview;

