import {outputStandardDateFormat} from "../shared/sharedDataUtilities";
import {isNullOrUndefined, isTrimmedStringEmpty} from "./commonUtilities";

export function convertToDate(dateString) {
    if(isNullOrUndefined(dateString) || isTrimmedStringEmpty(dateString))
        return null;

    const dateParts = dateString.split('-');
    if(dateParts.length === 3) {
        const datePart0 = parseInt(dateParts[0]);
        const datePart1 = parseInt(dateParts[1]);
        const datePart2 = parseInt(dateParts[2]);
        if(dateParts[0].length === 4)
            return new Date(datePart0, datePart1 - 1, datePart2, 0, 0, 0,0);

        return new Date(datePart2, datePart0 - 1, datePart1, 0, 0, 0, 0);
    }

    return new Date(dateString);
}

export function isDifferenceInDatesGreaterThanOneYear(startDateString, endDateString) {
    if(isNullOrUndefined(startDateString) || isTrimmedStringEmpty(startDateString))
        return false;

    if(isNullOrUndefined(endDateString) || isTrimmedStringEmpty(endDateString))
        return false;

    const startDate = convertToDate(startDateString);
    const endDate = convertToDate(endDateString);

    let differenceInYears =  Math.abs(startDate.getFullYear() - endDate.getFullYear());

    if(startDate.getMonth() === endDate.getMonth() && startDate.getDate() === endDate.getDate() && differenceInYears === 1) return false;

    if(differenceInYears > 1) return true;

    const differenceInSeconds = (startDate.getTime() - endDate.getTime()) / 1000;
    const differenceInDays = differenceInSeconds / (60 * 60 * 24);
    differenceInYears = Math.abs(differenceInDays / 365.25);

    return differenceInYears > 1.0;
}

export function getEndOfCurrentDay(curentDate = new Date()) {
    const endOfDayDate = new Date(curentDate.getFullYear(), curentDate.getMonth(), curentDate.getDate(), 23, 59, 59);
    return outputStandardDateFormat(endOfDayDate, true);
}

export function fetchStartYearFromList(dateWindows, dateWindowId) {
    const result = dateWindows.find(ins => ins.id === dateWindowId || ins.value === dateWindowId);
    if (result) {
        return result.text.substring(0, 4);
    }

    return "";
}

export function fetchEndYear(dateWindows, dateWindowId) {
    const result = dateWindows.filter(ins => ins.id === dateWindowId)[0];
    if (result) {
        return new Date(result.closeDate).getFullYear();
    }

    return "";
}

export function getCurrentDateTime() {
    return new Date();
}

export function datesEqual(firstDateString, secondDateString){
    const firstDate = convertToDate(firstDateString);
    const secondDate = convertToDate(secondDateString);

    if(isNullOrUndefined(firstDate) && !isNullOrUndefined(secondDate))
        return false;
    if(!isNullOrUndefined(firstDate) && isNullOrUndefined(secondDate))
        return false;
    if(isNullOrUndefined(firstDate) && isNullOrUndefined(secondDate))
        return true;
    return firstDate.getMonth() === secondDate.getMonth() && firstDate.getDate() === secondDate.getDate() && firstDate.getFullYear() === secondDate.getFullYear();
}

export function dateCompare(firstDateString, secondDateString) {
    const firstDate = convertToDate(firstDateString);
    const secondDate = convertToDate(secondDateString);

    if(isNullOrUndefined(firstDate) || isNullOrUndefined(secondDate))
        return null;

    if(firstDate > secondDate)
        return 1;
    else if(firstDate < secondDate)
        return -1;

    return 0;
}