import React from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import GridTable from "../../../../components/common/GridTable";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import * as config from "../../../../constants/config";

const SchoolImportView = ({
                                    schoolImportErrors,
                                    numberOfSchoolsImported,
                                    hasSchoolImportErrors,
                                    displayImportResults,
                                    handleFileUpload
                                }) => {


    const notificationTrigger = <CollapsibleTrigger
        text={`Errors when importing schools`}/>;


    return (
        <>
            {hasSchoolImportErrors && <span>There were errors in the MSID file. You can:
                <ul>
                    <li>Fix the excel file and try again. (Click the <strong>Import MSID file</strong> button and select the corrected MSID
                        File.)
                    </li>
                    <li>Contact us if there are errors in the data types collected. (<a
                        href={`mailto:` + config.supportEmail}>contact support via email for help</a>)
                    </li>
                    <li>Import the records that are correct and deal with the errors in the file later. (Click
                       the <strong>Import MSID file</strong> button and re-upload the MSID file and the system will import all correct records.)
                    </li>
                </ul>
            </span>}
            <FileUploadButton name={"fuMsId"} label={'Import MSID file'} onChange={handleFileUpload}/>
            {displayImportResults && <p>Number of Schools Imported: {numberOfSchoolsImported}</p>}
            {hasSchoolImportErrors && <CollapsibleContainer
                id={`import-errors-container`}
                open={hasSchoolImportErrors}
                trigger={notificationTrigger}
            >
                <GridTable>
                    <thead>
                    <tr>
                        <th>District Name</th>
                        <th>School Name</th>
                        <th>Error</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        schoolImportErrors.map((schoolImportError, key) => {
                            return (
                                <tr key={key}>
                                    <td>{schoolImportError.districtName}</td>
                                    <td>{schoolImportError.schoolName}</td>
                                    <td>{schoolImportError.errorMessage}</td>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </GridTable>
            </CollapsibleContainer>}
        </>
    );
}

SchoolImportView.propTypes = {
    schoolImportErrors: PropTypes.array.isRequired,
    numberOfSchoolsImported: PropTypes.number.isRequired,
    hasSchoolImportErrors: PropTypes.bool.isRequired,
    displayImportResults: PropTypes.bool.isRequired,
    handleFileUpload: PropTypes.func.isRequired
};

export default SchoolImportView;