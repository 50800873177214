import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import DrApi from "../drApi";
import {DrNavigation} from "../DrComponents/DrNavigation";
import {DrFilter} from "../DrComponents/DrFilter";
import {DrCaseListSearchResults} from "./DrCaseListSearchResults";

const DrCaseListContainer = ({
                                 actions,
                                 allDistricts,
                                 allDistrictsLoaded,
                                 currentLocation,
                                 dateWindowsAnnualList: dateWindows,
                                 defaultDateWindowId,
                                 history,
                                 searchCriteria,
                             }) => {
    const [summary, setSummary] = useState(null);

    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if(reloadsData) {
            criteria.clearOptionalSearchItems();
            await initializeData(criteria);
        }
        else
            actions.saveSearchCriteria(criteria);
    };

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const initializeData = async (criteria) => {
        const {dateWindowId, disputeResolutionType} = criteria;
        if (!dateWindowId || !disputeResolutionType)
            return;

        const result = await actions.executeApi(DrApi.getCaseListData, [dateWindowId, disputeResolutionType]);
        setSummary(result);

        const teamMembers = await actions.executeApi(DrApi.getSummaryTeamMembers, [dateWindowId, disputeResolutionType]);
        criteria.teamMembers = teamMembers;

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if(!defaultDateWindowId || !allDistrictsLoaded) return;

        let criteria = {...searchCriteria};
        criteria.setDistrictList(allDistricts);
        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrCaseList);
        initializeData(criteria);

    }, [defaultDateWindowId, allDistrictsLoaded]);

    useEffect(() => {
        actions.updatePageTitle(`Case List`);
    }, []);

    if (!searchCriteria || !summary ||  !searchCriteria.areRulesCurrent(DrFilterVisibilityRules.DrCaseList))
        return null;

    return (
        <>
            <DrFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <DrCaseListSearchResults
                allDistricts={allDistricts}
                disputeResolutionType={searchCriteria.disputeResolutionType}
                handleClickOrder={handleClickOrder}
                history={history}
                selectedDateWindowId={searchCriteria.dateWindowId}
                selectedInstitution={searchCriteria.district}
                summary={searchCriteria.applyFiltersAndOrder(summary)}
            />
            <DrNavigation currentLocation={currentLocation} history={history} />
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
            ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    let allDistricts = [...state.sharedData.institutionsWithStateAgencies].map(ins => {
        return {value: ins.id, text: ins.name};
    });

    const currentLocation = props.location.pathname;
    const allDistrictsLoaded = !isArrayNullOrEmpty(allDistricts);

    return {
        allDistricts,
        allDistrictsLoaded,
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrCaseListContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allDistricts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allDistrictsLoaded: PropTypes.bool.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrCaseListContainer);

