export const PLAN_DATE_WINDOW_ID = ":planDateWindowId";
export const DATE_WINDOW_ID = ":dateWindowId";
export const ROOT = `/StrategicPlans`;
//VIEW/EDIT
export const LANDING = {
    path: `${ROOT}/Landing`,
    params: {}
};

export const PLAN = {
    path: `${ROOT}/${PLAN_DATE_WINDOW_ID}`,
    params: {
        planDateWindowId: PLAN_DATE_WINDOW_ID
    }
};

export const REPORT = {
    path: `${ROOT}/FiveYearReport/${DATE_WINDOW_ID}`,
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

//MANAGE
export const MANAGE = {
    path: `${ROOT}/Manage`,
    params: {}
};

export const MANAGE_PLANS = {
    path: `${MANAGE.path}/Plans`,
    params: {}
};

export const MANAGE_RESOURCES = {
    path: `${MANAGE.path}/Resources`,
    params: {}
};

export const MANAGE_GOALS = {
    path: `${MANAGE.path}/Goals`,
    params: {}
};

export const MANAGE_REPORTS = {
    path: `${MANAGE.path}/Reports`,
    params: {}
};
