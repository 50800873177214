import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function bpieReducer(state = initialState.bpie, action) {
    switch (action.type) {
        case actionTypes.LOAD_FIN_FACILITATOR_DISTRICTS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    finFacilitatorDistricts: action.associatedDistricts,
                    finFacilitatorDistrictsLoaded: true,
                });
        default:
            return state;
    }
}