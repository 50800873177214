import {
    getPropName,
    isArrayNullOrEmpty,
    isInArray,
    isNullOrUndefined,
    isNumber
} from "../../components/common/commonUtilities";
import LocalStorage from "../../components/shared/LocalStorage";
import {DrRoles, DrRolesAssociatedToComplaint, SingleSelectionDrRolesAssociatedToComplaint} from "../drConstants";
import {
    AbeyanceStatus,
    IsIntakeRequirementMet,
    ReadOnlyCaseNoteTypes,
    ResolutionTypeAbeyance
} from "./drStateComplaintConstants";
import {createUserRoleObject} from "../drObjectFactory";

export function isAbleToEditTimelineCompletionDate(usersDrTeamMemberRoles, currentRoutingProcessRole) {
    if(isNullOrUndefined(usersDrTeamMemberRoles) || !isNumber(currentRoutingProcessRole) ||  isArrayNullOrEmpty(usersDrTeamMemberRoles))
        return false;

    // temp removed to allow for any team member to edit completion dates
    // const roleIds = usersDrTeamMemberRoles.map(r => r.role);
    // return isInArray(roleIds, currentRoutingProcessRole) || isInArray(roleIds, DrRoles.AdminSecretary.id);
    return true;
}

export function getAssociatedRoles(complaint, teamMembers) {
    const user = LocalStorage.getLoggedInUser();
    const userId = !isNullOrUndefined(user) ? user.Id : "";
    let userRoles = [];
    for(let role of DrRolesAssociatedToComplaint) {
        const complaintTeamMember = complaint.teamMembers.find(f => f.role === role);
        const complaintTeamMemberId = !complaintTeamMember ? "" : complaintTeamMember.id;
        if (teamMembers.find(f => f.id === complaintTeamMemberId && f.userId === userId && !f.isDisabled))
            userRoles.push(createUserRoleObject(role, complaintTeamMemberId));
    }

    for(let role of SingleSelectionDrRolesAssociatedToComplaint) {
        const teamMember = teamMembers.find(f => f.userId === userId && f.role === role && !f.isDisabled);
        if(teamMember)
            userRoles.push(createUserRoleObject(role, teamMember.id));
    }

    return userRoles;
}

export const isNoteTypeReadOnly = (noteType) => {
    return !!getPropName(ReadOnlyCaseNoteTypes, noteType);
};

export function isIntakeConditionalParentYesSelected(responses, parentId) {
    if(isNullOrUndefined(responses)) return false;

    const parentIntake = responses.find(f => f.requirementId === parentId);
    if(!parentIntake) return false;

    return (parentIntake.isRequirementMet === IsIntakeRequirementMet.Yes);
}

export function getNewResolutionTypeBasedUponAbeyanceStatus(abeyanceStatus, resolutionType) {
    return abeyanceStatus.toString() === AbeyanceStatus.InAbeyance.id.toString()
        ? ResolutionTypeAbeyance
        : resolutionType === ResolutionTypeAbeyance
            ? ""
            : resolutionType;
}

export function getAbeyanceStatusBasedUponResolutionTypeAndAbeyanceStartDate(resolutionType, abeyanceExitDate) {
    return resolutionType === ResolutionTypeAbeyance
        ? AbeyanceStatus.InAbeyance.id
        : abeyanceExitDate
            ? AbeyanceStatus.Resolved.id
            : AbeyanceStatus.NotRequired.id;
}