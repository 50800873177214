import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ButtonBar from "../common/buttons/ButtonBar";
import Button from "../common/buttons/Button";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";

const SppElementForm = ({
	                        element,
	                        onChange,
	                        onSave,
	                        onCancel,
	                        isLoading
                        }) => {
	return (
		<div>
			<TextAreaEditor
				name={"text"}
				value={element.text}
				label={""}
				height={400}
				includeAdvancedTools
				onChange={(event) => onChange(event, element)}
			/>

			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button
					name={"save"}
					label={"Save"}
					onClick={() => onSave(element)}
					buttonType={ButtonTypes.SAVE}
					diabled={isLoading}
				/>
				<Button
					name={"cancel"}
					label={"Cancel"}
					onClick={onCancel}
					buttonType={ButtonTypes.CANCEL}
					diabled={isLoading}
				/>
			</ButtonBar>
		</div>
	);
};

SppElementForm.propTypes = {
	element: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool
};

export default SppElementForm;