import {mockResolveApiCall} from "../../../api/ApiUtility";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../ptsConstants";
import {API_ROOT} from "../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsAuditsApi {
    static approveDiscretionaryProjectQuarterlyReport(dateWindowId, discretionaryProjectId, quarterSubmitted) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/quarters/${quarterSubmitted}/approve`,
                RequestTypes.POST,
                null,
                API_MESSAGES.QUARTERLY_REPORTS.approved
            )
        );
    }

    static revisionNeededForDiscretionaryProject(dateWindowId, discretionaryProjectId, quarterSubmitted) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/quarters/${quarterSubmitted}/revision-request`,
                RequestTypes.POST,
                null,
                API_MESSAGES.QUARTERLY_REPORTS.revisionRequest
            )
        );
    }

    static resetDiscretionaryProjectAudit(dateWindowId, discretionaryProjectId, quarterSubmitted){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/quarters/${quarterSubmitted}/reset`,
                RequestTypes.POST,
                null,
                API_MESSAGES.QUARTERLY_REPORTS.reset
            )
        );
    }

    static saveDiscretionaryProjectAuditComment(dateWindowId, discretionaryProjectId, auditId, auditCommentId, auditCommentModel) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/projects/${discretionaryProjectId}/audits/${auditId}/comments`,
                auditCommentId ? RequestTypes.PUT : RequestTypes.POST,
                JSON.stringify(auditCommentModel),
                API_MESSAGES.QUARTERLY_REPORTS.commentsSaved
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsAuditsApi {
    static approveDiscretionaryProjectQuarterlyReport() {
        return mockResolveApiCall([]);
    }

    static revisionNeededForDiscretionaryProject() {
        return mockResolveApiCall([]);
    }

    static resetDiscretionaryProjectAudit() {
        return mockResolveApiCall([]);
    }

    static saveDiscretionaryProjectAuditComment() {
        return mockResolveApiCall([]);
    }
}

const ptsAuditsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsAuditsApi : MockPtsAuditsApi;
export default ptsAuditsApi;