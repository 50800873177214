export const DASHBOARD = {
    path: "/Dashboard"
};

//Admin
export const ROOT = `/Admin`;

export const ADMIN_APP_SETTINGS = {
    path: `${ROOT}/Settings`,
    params: {}
};

export const ADMIN_SYSTEM_MESSAGE = {
    path: `${ROOT}/SystemMessage`,
    params: {}
};

export const ADMIN_INSTITUTIONS = {
    path: `${ROOT}/Institutions`,
    params: {}
};

export const ADMIN_FILE_UPLOAD = {
    path: `${ROOT}/FileUpload`,
    params: {}
};

export const ADMIN_SPP_INDICATORS = {
    path: `${ROOT}/SppIndicators`,
    params: {}
};

export const ADMIN_BEESS_INDICATORS = {
    path: `${ROOT}/BeessIndicators`,
    params: {}
};

export const ADMIN_PTS_INDICATORS = {
    path: `${ROOT}/PtsIndicators`,
    params: {}
};