import React from 'react';
import GridRow from "../../../../components/common/GridRow";
import GridColumn from "../../../../components/common/GridColumn";
import PropTypes from "prop-types";
import {quartersDateSelectFieldOptions} from "../../../../constants/inputConstants";
import {DISCRETIONARY_PROJECT_QUARTERS_LIST} from "../../ptsConstants";
import ActivitiesPrintView from "./ActivitiesPrintView";

const QuarterlyScheduleOfDeliverablesPrintView = ({actions, dateRangeType, htmlReportInfo}) => {
    if (!htmlReportInfo)
        return null;

    const handleFileDownload = (fileId) => {
        actions.downloadFile(fileId);
    };

    const quarter = DISCRETIONARY_PROJECT_QUARTERS_LIST.find(f => f.text === dateRangeType);
    const quarterDetails = htmlReportInfo.quarterlyReports.find(f => f.quarter === quarter?.id ?? 0);
    const quarterDatesCovered = dateRangeType === quartersDateSelectFieldOptions.yearToDate.description
        ? ""
        : ` (${quarterDetails.dateRange.datesCovered})`;

    return <section>
        <header className={`report-header`}>
            <h1>Bureau of Exceptional Education and Student Services,<br/>Florida Department of Education</h1>
            <h2>{htmlReportInfo.datesCovered} Quarterly Schedule of Deliverables Report</h2>
            <h2>{dateRangeType}{quarterDatesCovered}</h2>
        </header>

        <GridRow>
            <GridColumn medium={`6`}>
                <b>IDEA Funded State Project:</b> {htmlReportInfo.programFiscalAgent?.programName}
            </GridColumn>
            <GridColumn medium={`6`}>
                <b>Fiscal Agent:</b> {htmlReportInfo.programFiscalAgent?.fiscalAgentName}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={`6`}>
                <b>Project Number:</b> {htmlReportInfo.projectNumbers}
            </GridColumn>
            <GridColumn medium={`6`}>
                <b>TAPS:</b> {htmlReportInfo.tapsCodes}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={`6`}>
                <b>Project Manager:</b> {htmlReportInfo.projectManagerNames}
            </GridColumn>
            <GridColumn medium={`6`}>
                <b>BEESS Liaison:</b> {htmlReportInfo.doeProjectLiaison?.fullName}
            </GridColumn>
        </GridRow>
        <GridRow>
            <GridColumn medium={`6`}>
                <b>Project Dates:</b> {htmlReportInfo.projectStartDateOutput} - {htmlReportInfo.projectEndDateOutput}
            </GridColumn>
            <GridColumn medium={`6`}>
                <b>Award Amount:</b> {htmlReportInfo.budgetTotalDisplay}
            </GridColumn>
        </GridRow>

        <ActivitiesPrintView activities={htmlReportInfo.products} activitySectionClass={`product`} activityLabel={`PRODUCT`} dateRangeType={dateRangeType} handleFileDownload={handleFileDownload} />

        <ActivitiesPrintView activities={htmlReportInfo.trainings} activitySectionClass={`training`} activityLabel={`TRAINING`} dateRangeType={dateRangeType} handleFileDownload={handleFileDownload} />

        <ActivitiesPrintView activities={htmlReportInfo.services} activitySectionClass={`service`} activityLabel={`SERVICE DELIVERY`} dateRangeType={dateRangeType} handleFileDownload={handleFileDownload} />
    </section>;
};

QuarterlyScheduleOfDeliverablesPrintView.propTypes = {
    actions: PropTypes.object.isRequired,
    dateRangeType: PropTypes.string,
    htmlReportInfo: PropTypes.object,
};

export default QuarterlyScheduleOfDeliverablesPrintView;