import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import api from "../../../api/MonitoringComplianceReportsApi";
import PrintTable from "../../../components/common/PrintTable";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const StudentCorrectionsPrintView = ({
	                                     actions,
	                                     dateWindowId,
	                                     districtId,
	                                     dateWindows
                                     }) => {
	const [studentCorrectionsInfo, setStudentCorrectionsInfo] = useState(null);

	const handleUpdateStudentCorrectionsInfo = (studentCorrectionsInfo) => {
		setStudentCorrectionsInfo(studentCorrectionsInfo);
	};

	const getStudentCorrectionsInfo = async () => {
		await actions.executeApi(api.GetStudentCorrectionsReport, [districtId, dateWindowId])
			.then((results) => handleUpdateStudentCorrectionsInfo(results));
	};

	useEffect(() => {
		getStudentCorrectionsInfo();
	}, []);

	if (!studentCorrectionsInfo)
		return null;

	return (
		<div className={"compliance-print-report"}>
			{
				studentCorrectionsInfo.districtName === "" &&
				<p className={"no-report"}>No report available for this district.</p>
			}
			{
				studentCorrectionsInfo.districtName !== "" &&
				<>
					<div className={"report-header"}>
						<h2>{fetchDate(dateWindows, dateWindowId)} Self-Assessment</h2>
						<h2>Student-Specific Corrections — {studentCorrectionsInfo.districtName} District Student
							Report</h2>
					</div>
					<div className={"report-summary"}>
						<p>This report provides a summary of the student-specific corrections for the standards found to
							be noncompliant as a result of the ESE Compliance Self-Assessment
							or on-site visit conducted during this school year. Only the standards for which
							noncompliance was identified are included in this report.</p>
					</div>
					<PrintTable>
						<thead>
						<tr>
							<th rowSpan={"2"} className={"text-center"}>Student ID</th>
							<th rowSpan={"2"} className={"response-no-wrap text-center"}>Standard #</th>
							<th rowSpan={"2"}>Standard Description</th>
							<th rowSpan={"2"} className={"text-center"}>Date Corrected By District</th>
							<th rowSpan={"2"} className={"text-center"}>Date Reviewed by FDOE</th>
							<th colSpan={"7"} className={"text-center"}>Student-Specific Corrections</th>
						</tr>
						<tr>
							<th className={"text-center"}>Convened IEP, EP, or SP team to amend/ develop new plan</th>
							<th className={"text-center"}>Amended IEP, EP, or SP without a meeting</th>
							<th className={"text-center"}>Student withdrew from district</th>
							<th className={"text-center"}>Staff training/ technical assistance</th>
							<th className={"text-center"}>Provided progress report, as required</th>
							<th className={"text-center"}>Revised form, as required</th>
							<th className={"text-center"}>Other</th>
						</tr>
						</thead>
						<tbody>
						{
							studentCorrectionsInfo.studentCorrections.map((record, index) => {
								const rowClass = index % 2 === 1 ? "odd" : "";

								if (record.protocolResponseCorrections.length === 0)
									return (
										<tr key={`${index}_None`} className={rowClass}>
											<td valign={"top"}
											    className={"response-no-wrap text-center"}>{record.studentId}</td>
											<td colSpan={"11"}>No responses yet.</td>
										</tr>
									);

								return record.protocolResponseCorrections.map((response, rIndex) => {
									return (
										<tr key={`${index}_${rIndex}`} className={rowClass}>
											{
												rIndex === 0 &&
												<td rowSpan={record.protocolResponseCorrections.length} valign={"top"}
												    className={"response-no-wrap text-center"}>{record.studentId}</td>
											}
											<td valign={"top"}
											    className={"response-no-wrap text-center"}>{response.standardIdentifier}</td>
											<td><HtmlDisplayBlock
												html={`${response.standardDescription}<br/>${response.citation}`}/></td>
											<td valign={"top"} className={"text-center"}>{response.dateCorrected}</td>
											<td valign={"top"} className={"text-center"}>{response.dateReviewed}</td>
											<td valign={"top"}
											    className={"text-center"}>{response.newPlanFromMeeting}</td>
											<td valign={"top"}
											    className={"text-center"}>{response.amendedWithoutMeeting}</td>
											<td valign={"top"} className={"text-center"}>{response.studentWithdrew}</td>
											<td valign={"top"} className={"text-center"}>{response.staffTraining}</td>
											<td valign={"top"}
											    className={"text-center"}>{response.providedProgressReport}</td>
											<td valign={"top"} className={"text-center"}>{response.revisedForm}</td>
											<td valign={"top"} className={"text-center"}>{response.other}</td>
										</tr>
									);
								});
							})
						}
						</tbody>
					</PrintTable>
				</>
			}
		</div>
	);
};

StudentCorrectionsPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string.isRequired,
	dateWindows: PropTypes.array.isRequired
};

export default StudentCorrectionsPrintView;