import React from "react";
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import * as drLocations from "../drLocations";
import {StateComplaintSections} from "./drStateComplaintConstants";

export const DrStateComplaintNextSteps = ({
                                              complaint,
                                              handleSelectSection,
                                          }) => {

    const outputNextStep = (nextStep) => {
        return (
          <div><strong>Next Step - <Link to={drLocations.DR_SUMMARY.path}
              onClick={(event) => handleSelectSection(event, nextStep)}>{nextStep}</Link></strong></div>
        );
    };

    return (
        <>
            {
                complaint.isTimelineEnabled &&
                !complaint.isTimelineStarted &&
                outputNextStep(StateComplaintSections.Timeline)
            }

            {
                complaint.isTimelineEnabled &&
                complaint.isTimelineStarted &&
                complaint.timelineModel &&
                complaint.timelineModel.displayRoutingSection &&
                !complaint.timelineModel.isRoutingStarted &&
                outputNextStep(StateComplaintSections.Routing)
            }

            {
                complaint.isTimelineEnabled &&
                complaint.isOrdered &&
                !complaint.isIssueEnabled &&
                !complaint.isActionEnabled &&
                outputNextStep(StateComplaintSections.Outcome)
            }

            {
                complaint.isIssueEnabled &&
                !complaint.isIssueStarted &&
                outputNextStep(StateComplaintSections.IssuesActions)
            }

            {
                complaint.isActionEnabled &&
                !complaint.isActionStarted &&
                outputNextStep(StateComplaintSections.Actions)
            }

            {
                complaint.allActionsAccepted &&
                complaint.isEditable &&
                outputNextStep(StateComplaintSections.Closure)
            }
        </>
    );
};

DrStateComplaintNextSteps.propTypes = {
    complaint: PropTypes.object,
    handleSelectSection: PropTypes.func.isRequired,
};

export default DrStateComplaintNextSteps;