import React from "react";
import PropTypes from "prop-types";
import * as progressValues from "../../constants/spp/progressValues";
import GridTable from "../common/GridTable";
import {NotifyUser} from "../common/NotifyUser";

const SppDistrictSearchResults = ({districts, sppDetails}) => {

	const alertUserSppUnavailable = () => NotifyUser.Warning("The Policies and Procedures document for the district you selected is not available at this time. Please check back again later.");

	return (
		<div id="district-search-results">
			{districts !== undefined && districts.length !== 0 &&
			<GridTable>
				<thead>
				<tr>
					<th>District</th>
					<th>Availability</th>
				</tr>
				</thead>
				<tbody>
				{districts.map(
					(district, key) => {
						const isApproved = district.progress === progressValues.APPROVED;
						return (
							<tr key={key}
							    id={district.id}
							    onClick={
								    isApproved ?
									    (event) => sppDetails(event, district.id, district.documentId) :
									    alertUserSppUnavailable
							    }
							    className="is-clickable">
								<td>{district.name}</td>
								<td>
									{
										isApproved ?
											"Yes" : "No"
									}
								</td>
							</tr>);
					}
				)}
				</tbody>
			</GridTable>
			}

			{(districts === undefined || districts.length === 0) &&
			<p>Search did not match any districts.</p>
			}
		</div>
	);
};

SppDistrictSearchResults.propTypes = {
	districts: PropTypes.arrayOf(PropTypes.object),
	sppDetails: PropTypes.func.isRequired
};

export default SppDistrictSearchResults;