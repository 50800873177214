import React from 'react';
import PropTypes from 'prop-types';
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import Button from "../common/buttons/Button";
import SelectField from "../common/inputs/SelectField";
import ButtonBar from "../common/buttons/ButtonBar";
import DateTimePickerField from "../common/inputs/DateTimePickerField";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";

const IndicatorActionForm = ({
								 action,
								 actionTypes,
								 onSave,
								 onCancel,
								 onChange,
								 isLoading,
								 isNewAction,
							 }) => {
	return (
		<section className={"performance__action performance__action--editing performance__action__details"} id={action.actionId}>
			<GridRow>
				<GridColumn>
					<TextAreaEditor
						name={"actionSummary"}
						label={"Action Summary"}
						showLabel={true}
						value={!action.actionSummary ? "" : action.actionSummary}
						disabled={isLoading}
						onChange={(event, indicatorAssessmentId, actionId) => onChange(event, indicatorAssessmentId, actionId)}
					/>
				</GridColumn>
			</GridRow>
			{
				isNewAction &&
				<GridRow>
					<GridColumn>
						<SelectField
							name={"districtActionType"}
							label={"Action Type"}
							showLabel={true}
							options={actionTypes}
							disabled={isLoading}
							value={action.districtActionType}
							onChange={(event, indicatorAssessmentId, actionId) => onChange(event, indicatorAssessmentId, actionId)}
						/>
					</GridColumn>
				</GridRow>
			}
			<GridRow>
				<GridColumn>
					<DateTimePickerField
						name={"dueDate"}
						label={"Due Date"}
						showLabel
						onChange={(event, indicatorAssessmentId, actionId) => onChange(event, indicatorAssessmentId, actionId)}
						value={action.dueDate}
						disabled={isLoading}
					/>
				</GridColumn>
			</GridRow>
			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name={"save"}
						label={"Save"}
						onClick={(event, indicatorAssessmentId) => onSave(event, indicatorAssessmentId)}
						buttonType={ButtonTypes.SAVE}
						diabled={isLoading}/>
				<Button name={"cancel"}
						label={"Cancel"}
						onClick={onCancel}
						buttonType={ButtonTypes.CANCEL}
						diabled={isLoading}/>
			</ButtonBar>
		</section>
	);
};

IndicatorActionForm.propTypes = {
	action: PropTypes.object,
	actionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
	onSave: PropTypes.func,
	onCancel: PropTypes.func,
	onChange: PropTypes.func,
	isLoading: PropTypes.bool,
	isNewAction: PropTypes.bool
};

export default IndicatorActionForm;