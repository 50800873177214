import {generateSelectListFromArray} from "../components/common/commonUtilities";

export const audit_outcomes = {
	NOT_SUBMITTED: {
		value: `Not Submitted`,
	},
	IN_PROGRESS: {
		value: `In Progress`,
	},
	SUBMITTED: {
		enum: 0,
		value: `Submitted`,
	},
	ACCEPTED: {
		enum: 10,
		name: `Accepted`,
		value: `Accepted`,
	},
	NEEDS_REVISION: {
		enum: 1,
		name: `NeedsRevision`,
		value: `Needs Revision`,
	},
	RESET: {
		name: `ResetSelection`,
		value: `Reset Selection`,
	}
};

export default audit_outcomes;

const reviewOutcomesOptionsText = [ audit_outcomes.SUBMITTED.value, audit_outcomes.ACCEPTED.value, audit_outcomes.NEEDS_REVISION.value];
export const reviewOutcomesOptions = generateSelectListFromArray(reviewOutcomesOptionsText);