import React from 'react';
import PropTypes from 'prop-types';
import SelectField from '../common/inputs/SelectField';
import TextField from '../common/inputs/TextField';
import TextArea from '../common/inputs/TextArea';
import GridColumn from "../common/GridColumn";
import GridRow from "../common/GridRow";
import * as elementTypes from '../../constants/surveys/elementTypes';

const SurveyElementSet = ({elements, onFormChangeHandler}) => {
	return (
		<section className="grid-form">
			{elements.map(function (element) {
				if (element.elementType === elementTypes.TITLE) {
					return (
						<GridRow key={element.componentId}>
							<GridColumn>
								<h2>{element.labelText}</h2>
								<p>{element.subtext}</p>
							</GridColumn>
						</GridRow>
					);
				}
				else if (element.elementType === elementTypes.SELECT) {
					return (<SelectField key={element.componentId}
					                     name={element.componentId}
					                     label={element.labelText}
					                     value={element.answer}
					                     showLabel={true}
					                     options={element.answerChoices}
					                     defaultOption={!element.isRequired ? "Not Applicable" : null}
					                     onChange={onFormChangeHandler}
					                     labelClass="medium-8"
					                     inputClass="medium-4"
					/>);
				}
				else if (element.elementType === elementTypes.TEXTBOX) {
					return (<TextField key={element.componentId}
					                   name={element.componentId}
					                   label={element.labelText}
					                   value={element.answer}
					                   showLabel={true}
					                   onChange={onFormChangeHandler}
					                   labelClass="medium-8"
					                   inputClass="medium-4"
					/>);
				}
				else if (element.elementType === elementTypes.TEXTAREA) {
					return (<TextArea key={element.componentId}
					                  name={element.componentId}
					                  label={element.labelText}
					                  value={element.answer}
					                  showLabel={true}
					                  onChange={onFormChangeHandler}
					                  labelClass="small-12"
					                  inputClass="small-12"
					/>);
				}
			})}

		</section>
	);
};

SurveyElementSet.propTypes = {
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
	onFormChangeHandler: PropTypes.func
};

export default SurveyElementSet;