import React from 'react';
import PropTypes from "prop-types";
import {formatBasicTextForHtmlReport} from "../../../../components/common/HtmlUtilities";
import TextReadOnly from "../../../../components/common/inputs/TextReadOnly";
import HtmlDisplay from "../../../../components/htmlDisplay/HtmlDisplay";
import {ELEMENT_TYPE} from "../../gmsConstants";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import {
    convertToArray,
    isTrimmedStringEmpty, convertToCurrencyFormat,
} from "../../../../components/common/commonUtilities";
import {GmsApplicationElementWrapper} from "../GmsApplicationElementWrapper";

export const GmsApplicationPrintElement = (props) => {
    const {
        element,
        isChecked,
        parentId = "",
        response,
    } = props;

    const buildListForCheckBoxResponse = (cbResponse) => {
        let selectedList = convertToArray(cbResponse);
        selectedList = [...selectedList].filter(a => a !== "");
        let listText = selectedList.length > 0 ? "<ul>" : "";

        for (const listItem of selectedList)
            listText += `<li>${listItem}</li>`;

        return isTrimmedStringEmpty(listText) ? "" : `${listText}</ul>`;
    };

    const getFileName = (fileResponse) => {
        try {
            return JSON.parse(fileResponse)?.fileName;
        }
        catch {
            return fileResponse;
        }
    };

    return (
        <>
            {
                element.elementType === ELEMENT_TYPE.display &&
                <HtmlDisplay html={element.elementText} name={element.elementId}/>
            }
            {
                (
                    element.elementType === ELEMENT_TYPE.textbox ||
                    element.elementType === ELEMENT_TYPE.dropdown ||
                    element.elementType === ELEMENT_TYPE.radio ||
                    element.elementType === ELEMENT_TYPE.wholeNumberTextbox
                ) &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={response || ""}
                />
            }
            {
                element.elementType === ELEMENT_TYPE.textarea &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={formatBasicTextForHtmlReport(response)}
                />
            }

            {
                element.elementType === ELEMENT_TYPE.checkbox &&
                <CheckBoxField checked={isChecked} describedBy={parentId} label={element.elementText} disabled
                               name={element.elementId} showLabel value={isChecked ? `` : `true`}/>
            }

            {
                element.elementType === ELEMENT_TYPE.section &&
                <>
                    <HtmlDisplay html={`<h2>${element.elementText}</h2>`} name={element.elementId}/>
                    {
                        element.elements?.map((el) => {
                            return <GmsApplicationElementWrapper {...props} element={el} key={el.elementId} isPrint
                                                                 parentId={element.elementId}/>;
                        })
                    }
                </>
            }

            {
                element.elementType === ELEMENT_TYPE.checkboxList &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={buildListForCheckBoxResponse(response)}
                />
            }

            {
                (element.elementType === ELEMENT_TYPE.wholeDollarTextbox ||
                    element.elementType === ELEMENT_TYPE.dollarTextbox) &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={`${convertToCurrencyFormat(response)}`}
                />
            }

            {
                element.elementType === ELEMENT_TYPE.percentTextbox &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={`${response}%`}
                />
            }
            {
                element.elementType === ELEMENT_TYPE.file &&
                <TextReadOnly
                    label={element.elementText}
                    name={element.elementId}
                    value={getFileName(response)}
                />
            }
        </>
    );
};


GmsApplicationPrintElement.propTypes = {
    calculatedValue: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    element: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.element,
        PropTypes.bool,
        PropTypes.array,
    ]).isRequired,
    isChecked: PropTypes.bool,
    parentId: PropTypes.string,
    response: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    responses: PropTypes.object.isRequired,
};