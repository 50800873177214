import React from 'react';
import PropTypes from 'prop-types';
import IndicatorProtocolGrid from "./IndicatorProtocolGrid";

const IndicatorProtocolValidation = ({
                               indicatorProtocols,
                               protocolResponses,
                               assessmentApproved
                           }) => {
    return (
          <IndicatorProtocolGrid indicatorProtocols={indicatorProtocols} protocolResponses={protocolResponses} showState={true} assessmentApproved={assessmentApproved}/>
    );
};

IndicatorProtocolValidation.propTypes = {
    indicatorProtocols: PropTypes.array.isRequired,
    protocolResponses: PropTypes.array.isRequired,
    assessmentApproved: PropTypes.bool.isRequired
};

export default IndicatorProtocolValidation;