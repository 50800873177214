import {
    FUNDING_SOURCE_LIST,
    CATEGORIES,
    PRODUCT_TYPES_LIST,
    TRAINING_TYPES_LIST,
    SERVICE_TYPES_LIST,
} from "./gmsApplicationDeliverablesConstants";

export function displayFundingSource(value) {
    if (!value) return "";
    return FUNDING_SOURCE_LIST.find(f => f.value === value)?.text;
}

export function getTypesForCategory(category) {
    if (category === CATEGORIES.products)
        return PRODUCT_TYPES_LIST;
    if (category === CATEGORIES.training)
        return TRAINING_TYPES_LIST;
    if (category === CATEGORIES.serviceDelivery)
        return SERVICE_TYPES_LIST;
}