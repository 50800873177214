import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import {
    CollapsibleContainerCollection,
    createAllContainerDetailsObject
} from "../../../../../components/common/CollapsibleContainerCollection";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../../constants/config";
import {DistrictPieTask} from "../../../bpieConstants";
import {createActionStep} from "../../bpieDistrictObjectFactory";
import {BpieDistrictPieGoalActionForm} from "./BpieDistrictPieGoalActionForm";
import {BpieDistrictPieGoalActionInfo} from "./BpieDistrictPieGoalActionInfo";
import {BpieDistrictPieGoalDetailsInfo} from "./BpieDistrictPieGoalDetailsInfo";
import {BpieDistrictPieDetailsGoalForm} from "./BpieDistrictPieGoalForm";

export const BpieDistrictPieGoalDetailsView = ({
                                                   dataChanged,
                                                   dbpiePriorityIndicators,
                                                   districtPieId,
                                                   districtPieStartYear,
                                                   dpieGoal,
                                                   editingActionStepId,
                                                   editingDpieGoalId,
                                                   handleDataChanged,
                                                   handleDistrictPieSectionsRedirect,
                                                   handleRemoveActionStep,
                                                   handleSaveActionStep,
                                                   handleSaveDistrictPieGoalDetails,
                                                   isAdd,
                                                   isEditable,
                                                   isEditingActionStep,
                                                   isEditingGoal,
                                                   sbpiePriorityIndicators,
                                                   sbpiePriorityYearRange,
                                                   setEditingDpieGoalId,
                                                   setEditingActionStepId
                                               }) => {
    const handleCancelDpieGoalDetailsClick = (event) => {
        event.preventDefault();

        if (editingDpieGoalId === emptyGuid)
            redirectToGoalSummary();

        setEditingDpieGoalId(null);
        handleDataChanged(false);
    };

    const redirectToGoalSummary = () => {
        if (dataChanged) {
            if (!confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
                return;
        }
        handleDataChanged(false);
        handleDistrictPieSectionsRedirect(districtPieId, DistrictPieTask.Goals.id);
    };

    const handleClickAddActionStep = (event) => {
        event.preventDefault();
        setEditingActionStepId(emptyGuid);
    };

    const allContainerDetails = dpieGoal.actionSteps.map(actionStep =>
        createAllContainerDetailsObject(actionStep.actionStepId,
            `Action Step ${actionStep.actionStepNumber}`,
            actionStep)
    );

    return <>
        {
            isEditingGoal &&
            <BpieDistrictPieDetailsGoalForm
                dbpiePriorityIndicators={dbpiePriorityIndicators}
                dpieGoal={dpieGoal}
                handleCancelDpieGoalDetailsClick={handleCancelDpieGoalDetailsClick}
                handleDataChanged={handleDataChanged}
                handleSaveDistrictPieGoalDetails={handleSaveDistrictPieGoalDetails}
                isAdd={isAdd}
                sbpiePriorityIndicators={sbpiePriorityIndicators}
                sbpiePriorityYearRange={sbpiePriorityYearRange}
            />
        }
        {
            !isEditingGoal &&
            <>
                <BpieDistrictPieGoalDetailsInfo
                    dpieGoal={dpieGoal}
                    isEditable={isEditable}
                    isEditingActionStep={isEditingActionStep}
                    redirectToGoalSummary={redirectToGoalSummary}
                    sbpiePriorityYearRange={sbpiePriorityYearRange}
                    setEditingDpieGoalId={setEditingDpieGoalId}
                />

                <CollapsibleContainerCollection
                    allContainerDetails={allContainerDetails}
                    containerClassName={`actionStep--info`}
                    expandCollapseAllLinkName={`btnExpandCollapseAll`}
                    expandCollapseAllLinkSuffix={`Action Steps`}
                    expandCollapseAllLinkWrapperClass={`actionStep--expandCollapse`}
                >
                    <BpieDistrictPieGoalActionForm
                        districtPieStartYear={districtPieStartYear}
                        editingActionStepId={editingActionStepId}
                        handleDataChanged={handleDataChanged}
                        handleSaveActionStep={handleSaveActionStep}
                        isAdd={false}
                        setEditingActionStepId={setEditingActionStepId}
                    />
                    <BpieDistrictPieGoalActionInfo
                        editingActionStepId={editingActionStepId}
                        handleRemoveActionStep={handleRemoveActionStep}
                        isEditable={isEditable}
                        isEditingActionStep={isEditingActionStep}
                        setEditingActionStepId={setEditingActionStepId}
                    />
                </CollapsibleContainerCollection>
                {
                    editingActionStepId === emptyGuid &&
                    <BpieDistrictPieGoalActionForm
                        districtPieStartYear={districtPieStartYear}
                        editingActionStepId={editingActionStepId}
                        handleDataChanged={handleDataChanged}
                        handleSaveActionStep={handleSaveActionStep}
                        isAdd={true}
                        parentObject={createActionStep()}
                        setEditingActionStepId={setEditingActionStepId}
                    />
                }
                {
                    isNullOrUndefined(editingActionStepId) && isEditable &&
                    <ButtonBar position={ButtonBarPositions.BOTTOM} className={`actionStep--addActionStep`}>
                        <Button
                            buttonType={ButtonTypes.ADD}
                            btnClass={`button--large`}
                            label={`Add Action Step`}
                            name={`btnAddActionStep`}
                            onClick={handleClickAddActionStep}
                            showLabel
                        />
                    </ButtonBar>
                }
            </>
        }
    </>;
};

BpieDistrictPieGoalDetailsView.propTypes = {
    dataChanged: PropTypes.bool.isRequired,
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    districtPieId: PropTypes.string.isRequired,
    districtPieStartYear: PropTypes.number.isRequired,
    dpieGoal: PropTypes.object.isRequired,
    editingActionStepId: PropTypes.string,
    editingDpieGoalId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    handleRemoveActionStep: PropTypes.func.isRequired,
    handleSaveActionStep: PropTypes.func.isRequired,
    handleSaveDistrictPieGoalDetails: PropTypes.func.isRequired,
    isAdd: PropTypes.bool.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditingActionStep: PropTypes.bool.isRequired,
    isEditingGoal: PropTypes.bool.isRequired,
    sbpiePriorityIndicators: PropTypes.array.isRequired,
    sbpiePriorityYearRange: PropTypes.string.isRequired,
    setEditingDpieGoalId: PropTypes.func.isRequired,
    setEditingActionStepId: PropTypes.func.isRequired
};