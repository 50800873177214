import * as actionTypes from "../constants/actionTypes";
import bpieApi from "../containers/bpie/bpieApi";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";

export function loadFinFacilitatorDistrictsSuccess(associatedDistricts) {
    return {type: actionTypes.LOAD_FIN_FACILITATOR_DISTRICTS_AJAX_SUCCESS, associatedDistricts};
}

export function loadFinFacilitatorDistricts() {
    return dispatch => {
        dispatch(beginAjaxCall());

        return bpieApi.getFinFacilitatorAssociatedDistricts()
            .then(associatedDistricts => {
                dispatch(loadFinFacilitatorDistrictsSuccess(associatedDistricts));
            })
            .catch(error => {
                    dispatch(ajaxCallError(error));
                }
            );
    };
}