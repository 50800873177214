import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as sppActions from "../../actions/sppActions";
import * as sppAdminActions from "../../actions/sppAdminActions";
import * as ActionBarPositions from "../../constants/ActionBarPositions";
import { emptyGuid } from "../../constants/config";
import * as sppUtilities from "../spp/SppUtilities";
import BackNextPager from "../common/pagers/BackNextPager";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import SppElementForm from "../spp/SppElementForm";
import ActionBar from "../common/buttons/ActionBar";
import * as documentStatus from "../../constants/spp/documentStatus";
import CollapsibleContainer from "../common/CollapsibleContainer";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../common/buttons/Button";
import * as sppLocations from "../../constants/spp/sppLocations";
import {limitTextLength} from "../common/commonUtilities";

class SppAdminSectionElementsEdit extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			updatedElement: {}
		};

		this.onChange = this.onChange.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onAddElement = this.onAddElement.bind(this);
		this.onDeleteElement = this.onDeleteElement.bind(this);
		this.onEditElement = this.onEditElement.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.back = this.back.bind(this);
		this.next = this.next.bind(this);
		this.resetCurrentStep = this.resetCurrentStep.bind(this);
		this.orderSectionElements = this.orderSectionElements.bind(this);
	}

	componentDidMount() {
		this.resetCurrentStep();
	}

	onChange(event, element) {
		if (event) {
			let updatedElement = Object.assign({}, element);
			updatedElement[event.target.name] = event.target.value;
			this.setState({updatedElement});
		}
	}

	onSave(updatedElement) {
		const element = this.props.documentElements.filter(el => el.id === updatedElement.id)[0];

		if (element === updatedElement)
			return this.onCancelEdit();

		this.props.actions.saveSppElement(updatedElement);

		this.setState({updatedElement: {}});
	}

	onAddElement(siblingElementId, addBeforeSibling) {
		const documentId = this.props.params.documentId;
		const newElementId = emptyGuid;
		const createElementViewModel = {
			siblingElementId,
			addBeforeSibling,
			elementViewModel: sppUtilities.newElement(newElementId, documentId)
		};

		this.props.actions.addSppElement(createElementViewModel)
			.then(() => {
				this.props.actions.loadSppDocument(documentId);
				this.props.actions.loadSppDocumentElements(documentId);
			})
			.catch();
	}

	onDeleteElement(deletedElementId) {
		const documentId = this.props.params.documentId;
		this.props.actions.deleteSppElement(documentId, deletedElementId)
			.then(() => {
				this.props.actions.loadSppDocument(documentId);
			})
			.catch();
	}

	onEditElement(updatedElement) {
		this.props.actions.editSppElement(updatedElement.id);

		this.setState({updatedElement});
	}

	onCancelEdit() {
		this.props.actions.cancelEditSppElement();

		this.setState({updatedElement: {}});
	}

	resetCurrentStep() {
		this.setState({
			currentStep: 1
		});
	}

	back(event) {
		event.preventDefault();

		if (this.state.currentStep !== 1) {
			this.setState({
				currentStep: this.state.currentStep - 1
			});

			if (this.props.isEditing)
				this.props.actions.cancelEditSppElement();
		}
	}

	next(event, totalSteps) {
		event.preventDefault();

		if (this.state.currentStep !== totalSteps) {
			this.setState({
				currentStep: this.state.currentStep + 1
			});

			if (this.props.isEditing)
				this.props.actions.cancelEditSppElement();
		}
	}

	orderSectionElements(event) {
		event.preventDefault();

		this.props.history.push(sppLocations.ADMIN_EDITOR_SECTION_ORDER.path
			.replace(sppLocations.DOCUMENT_ID, this.props.params.documentId)
			.replace(sppLocations.PART_ID, this.props.params.partId)
			.replace(sppLocations.SECTION_ID, this.props.params.sectionId));
	}

	render() {
		if (!this.props.params ||
			!this.props.params.sectionId ||
			this.props.params.sectionId === "-1" ||
			this.props.documentElements.length === 0 ||
			!this.props.selectedDocument.id ||
            this.state.currentStep === undefined)
			return null;

		const hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);
		const sectionHierarchy = sppUtilities.getSectionHierarchy(this.props.params.sectionId, hierarchyJson);
		const isEditingMode = this.props.selectedDocument.status === documentStatus.EDITING;

		if (this.state.currentStep > sectionHierarchy.childElements.length)
			this.resetCurrentStep();

		const elementListForCurrentPage = sectionHierarchy.childElements[this.state.currentStep - 1].childElements;

		return (
			<section>
				{
					elementListForCurrentPage.length > 0 &&
					<BackNextPager
						next={(event) => this.next(event, sectionHierarchy.childElements.length)}
						back={this.back}
						backText={"Back"}
						nextText={"Next"}
						currentPage={this.state.currentStep}
						totalPages={sectionHierarchy.childElements.length}
					/>
				}

				{
					isEditingMode &&
					elementListForCurrentPage.length >0 &&
					<ButtonBar position={ButtonBarPositions.TOP}>
						<Button label={"Order Section Elements"} name={"btnOrderSectionElements"} onClick={this.orderSectionElements} />
					</ButtonBar>
				}

				{
					elementListForCurrentPage.length > 0 &&
					elementListForCurrentPage.map((childElement, index) => {
						const itemToDisplay = this.props.documentElements.filter(el => el.id === childElement.elementId)[0];

						if(itemToDisplay === undefined)
							return;

						if (this.props.editItemId === itemToDisplay.id) {
							return (<SppElementForm
								key={itemToDisplay.id}
								element={this.state.updatedElement}
								onChange={this.onChange}
								onSave={this.onSave}
								onCancel={this.onCancelEdit}/>);
						}
						else {
							return (
								<CollapsibleContainer key={itemToDisplay.id}
													  id={itemToDisplay.id}
													  trigger={limitTextLength(itemToDisplay.text)}

								>
									<HtmlDisplayBlock
										name={itemToDisplay.id}
										html={itemToDisplay.text}
										isEditable
									/>
									<ActionBar
										elementId={itemToDisplay.id}
										insertLabel2={`Add New Before`}
										insertLabel={`Add New After`}
										onInsertClick2={!index && isEditingMode ? () => this.onAddElement(itemToDisplay.id, true) : null}
										onInsertClick={isEditingMode ? () => this.onAddElement(itemToDisplay.id, false) : null}
										onDeleteClick={isEditingMode ? () => this.onDeleteElement(itemToDisplay.id) : null}
										onEditClick={isEditingMode ? () => this.onEditElement(itemToDisplay) : null}
										disabled={this.props.isLoading || this.props.isEditing}
										position={ActionBarPositions.TOP_RIGHT}
									/>

								</CollapsibleContainer>
							);
						}
					})
				}
			</section>
		);
	}
}

SppAdminSectionElementsEdit.propTypes = {
	actions: PropTypes.object.isRequired,
	documentElements: PropTypes.array,
	params: PropTypes.object,
	selectedDocument: PropTypes.object,
	editItemId: PropTypes.string,
	isEditing: PropTypes.bool,
	isLoading: PropTypes.bool,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		documentElements: state.spp.documentElements,
		selectedDocument: state.spp.selectedDocument,
		editItemId: state.spp.editItemId,
		isEditing: state.spp.isInEditMode,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		sharedDataActions,
		sppActions,
		sppAdminActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppAdminSectionElementsEdit);