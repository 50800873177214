import * as config from "../constants/config";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import * as ApiUtility from "../api/ApiUtility";
import { ToastrNotificationFactory } from "./notifications/ToastrNotificationFactory";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";
import {getMockAccounts} from "./AccountMockData";
import {isNullOrUndefined} from "../components/common/commonUtilities";

const notificationFactory = new ToastrNotificationFactory();

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerAccountApi {
    static attemptLogin(loginViewModel) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'accounts/login',
            RequestTypes.POST,
            JSON.stringify({ username: loginViewModel.username, password: loginViewModel.password })));
    }

    static requestPasswordReset(forgotPasswordViewModel) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'accounts/forgot-password',
            RequestTypes.POST,
            JSON.stringify(forgotPasswordViewModel),
            ApiSuccessMessages.ACCOUNT_REQUEST_PASSWORD_RESET));
    }

    static resetPassword(resetPasswordViewModel) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + "accounts/reset-password",
            RequestTypes.POST,
            JSON.stringify(resetPasswordViewModel),
            ApiSuccessMessages.ACCOUNT_RESET_PASSWORD));
    }

    static impersonating(userId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'accounts/users/' + userId,
            RequestTypes.GET,
            JSON.stringify({userId: userId})
            )
        );
    }

    static getUserTokenFromRefreshToken(refreshTokenViewModel){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'accounts/tokens/refresh',
            RequestTypes.POST,
            JSON.stringify(refreshTokenViewModel)
            )
        );
    }

}

//************************************************** MOCK API SERVER CALLS ****************************************
// This file mocks a web API by working with the hard-coded in AccountMockData.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockAccountApi {
    static attemptLogin(loginViewModel) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getMockAccounts().find(account =>
                    account.username === loginViewModel.username &&
                    account.password === loginViewModel.password);

                if (isNullOrUndefined(userAcct))
                    reject("There was an error while attempting to login.");
                else
                    resolve({access_token: userAcct.access_token, refresh_token: userAcct.refresh_token});

            }, config.environment.delay);
        });
    }

    static requestPasswordReset(forgotPasswordViewModel) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getMockAccounts().find(account => account.email === forgotPasswordViewModel.email);

                if (isNullOrUndefined(userAcct))
                    reject(
                        {
                            toastrMsg: "There was an error processing this request.",
                            errors: {
                                email: "Email not found!"
                            }
                        });

                resolve({});
            }, config.environment.delay);
        });
    }

    static resetPassword() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static impersonating() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getUserTokenFromRefreshToken() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const AccountApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerAccountApi : MockAccountApi;
export default AccountApi;