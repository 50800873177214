import React, {useEffect} from 'react';
import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import PropTypes from "prop-types";
import {PtsInformationView} from "./PtsInformationView";
import ptsManageFiscalAgentsUsersApi
    from "../Admin/ManagePrograms/ManageFiscalAgentsUsers/ptsManageFiscalAgentsUsersApi";
import ptsAdminDiscretionaryProjectsApi from "../Admin/ptsAdminDiscretionaryProjectsApi";

export const PtsInformationContainer = ({
                                            actions,
                                            dateWindowId,
                                            discretionaryProjectId,
                                            getDiscretionaryProject,
                                            handleDataChanged,
                                            history,
                                            isLoading,
                                            isStateUser = false,
                                            pageSubTitle,
                                            project,
                                        }) => {
    const updateProgramUser = async (programUserId, phoneNumber) => {
        await actions.executeApi(ptsManageFiscalAgentsUsersApi.saveProgramUserPhone, [project.programFiscalAgent.programId, project.programFiscalAgent.fiscalAgentId, programUserId, phoneNumber])
            .then(async () => {
                await getDiscretionaryProject(dateWindowId, discretionaryProjectId);
            })
    };

    const updateProjectEndDate = async (newProjectEndDate) => {
        await actions.executeApi(ptsAdminDiscretionaryProjectsApi.SaveProjectEndDate, [discretionaryProjectId, newProjectEndDate])
            .then(async () => {
                await getDiscretionaryProject(dateWindowId, discretionaryProjectId);
            })
    }

    useEffect(() => {
        actions.updatePageTitle(`Project Information ${pageSubTitle}`);
    }, []);

    if (isNullOrUndefined(project))
        return null;

    return <>
        <PtsInformationView
            discretionaryProjectId={discretionaryProjectId}
            handleDataChanged={handleDataChanged}
            history={history}
            isLoading={isLoading}
            isStateUser={isStateUser}
            project={project}
            updateProgramUser={updateProgramUser}
            updateProjectEndDate={updateProjectEndDate}
        />
    </>;
};

PtsInformationContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    discretionaryProjectId: PropTypes.string.isRequired,
    getDiscretionaryProject: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isStateUser: PropTypes.bool,
    pageSubTitle: PropTypes.string.isRequired,
    project: PropTypes.object.isRequired,
};
