import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../../components/common/buttons/Button";
import PropTypes from "prop-types";
import React from "react";
import * as ptsLocations from "./ptsLocations";
import * as ButtonTypes from "../../constants/ButtonTypes";

export const PtsNavigation = ({
                                  currentLocation,
                                  dataChanged,
                                  dateWindowId,
                                  discretionaryProjectId,
                                  handleDataChanged,
                                  history,
                              }) => {
    const projectInfoUrl = ptsLocations.PTS_PROJECT_INFORMATION.getUrl(dateWindowId, discretionaryProjectId);
    const deliverablesSummaryUrl = ptsLocations.PTS_DELIVERABLES_SUMMARY.getUrl(dateWindowId, discretionaryProjectId);
    const reportsUrl = ptsLocations.PTS_REPORTS.getUrl(dateWindowId, discretionaryProjectId);

    const navButtonClick = (event, location) => {
        event.preventDefault();

        if (currentLocation.startsWith(location)) return;

        if (dataChanged && !confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        handleDataChanged(false);
        history.push(location);
    };

    return (
        <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} className={`ptsNavigation`}>
            <Button
                buttonType={ButtonTypes.BACK}
                label={"Return to summary"}
                name="btnReturn"
                onClick={(event) => navButtonClick(event, ptsLocations.PTS_SUMMARY.path)}
            />
            <Button
                btnClass={currentLocation === projectInfoUrl ? "is-active" : ""}
                name={`btnProject_${discretionaryProjectId}`}
                label={`Project Information`}
                onClick={(event) => navButtonClick(event, projectInfoUrl)}
            />
            <Button
                btnClass={currentLocation === deliverablesSummaryUrl ? "is-active" : ""}
                name={`btnDeliverableSummary_${discretionaryProjectId}`}
                label={`Deliverable Summary`}
                onClick={(event) => navButtonClick(event, deliverablesSummaryUrl)}
            />
            <Button
                btnClass={currentLocation === reportsUrl ? "is-active" : ""}
                name={`btnReport_${discretionaryProjectId}`}
                label={`View/Submit Reports`}
                onClick={(event) => navButtonClick(event, reportsUrl)}
            />
        </ButtonBar>
    );
};

PtsNavigation.propTypes = {
    currentLocation: PropTypes.string,
    dataChanged: PropTypes.bool.isRequired,
    dateWindowId: PropTypes.string,
    discretionaryProjectId: PropTypes.string,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object,
};