import React from "react";
import PropTypes from "prop-types";
import {formatMetricDateWindow} from "./strategicPlanUtilities";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import Allow from "../authorization/Allow";
import ActionBar from "../common/buttons/ActionBar";

const IndicatorMetrics = ({
	                          strategyIndicator,
	                          metricId,
	                          isEditing,
	                          onEditMetrics,
	                          filters,
	                          planStartYear
                          }) => {

	const targetMetMetricExists = strategyIndicator.metrics.length > 0 &&
		strategyIndicator.metrics.filter(m => m.startYear === planStartYear).length > 0;

	let targetMetMetric = strategyIndicator.metrics.filter(m => m.startYear === planStartYear)[0];

	return (
		<section className={"strategy__metrics "} id={metricId}>
			{
				!isEditing &&
				filters.showButtons &&
				<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_METRICS}>
					<ActionBar
						elementId={metricId}
						onEditClick={onEditMetrics}/>
				</Allow>
			}

			{
				strategyIndicator.baseline !== null &&
				<TileContainer title={"Baseline"} rowTilesSmall={"1"} rowTilesMedium={"1"} rowTilesLarge={"1"}>
					<Tile body={strategyIndicator.baseline.baseline}
					      footer={formatMetricDateWindow(strategyIndicator.baseline.startYear)}
					      isLarge={false}/>
				</TileContainer>
			}

			{
				strategyIndicator.metrics.length > 0 &&
				<TileContainer title={"Targets"} rowTilesSmall="1" rowTilesLarge="5">
					{
						strategyIndicator.metrics.map((metric) =>
							<Tile key={"target|" + metric.id}
							      body={metric.target}
							      footer={formatMetricDateWindow(metric.startYear)}
							      isLarge={false}/>)
					}
				</TileContainer>
			}

			{
				strategyIndicator.metrics.length > 0 &&
				<TileContainer title={"Actual"} rowTilesSmall="1" rowTilesLarge="5">
					{
						strategyIndicator.metrics.map((metric) =>
							<Tile key={"actual|" + metric.id}
							      body={metric.actual}
							      footer={formatMetricDateWindow(metric.startYear)}
							      isLarge={false}
							/>)
					}
				</TileContainer>
			}

			{
				targetMetMetricExists && targetMetMetric.hasMetTarget !== "" &&
				<p>
					<strong>Met Target</strong>: <span>{targetMetMetric.hasMetTarget === "true" ? "Yes" : "No"}</span>
				</p>
			}

			{
				targetMetMetricExists &&
				targetMetMetric.targetVerificationComment !== null &&
				targetMetMetric.targetVerificationComment !== "" &&
				<p>
					<strong>Target Achievement Comments</strong>:
					<span>{targetMetMetric.targetVerificationComment}</span>
				</p>
			}
		</section>
	);
};

IndicatorMetrics.propTypes = {
	strategyIndicator: PropTypes.object.isRequired,
	metricId: PropTypes.string.isRequired,
	isEditing: PropTypes.bool.isRequired,
	onEditMetrics: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	planStartYear: PropTypes.string.isRequired
};

export default IndicatorMetrics;