import {cloneDeep} from "lodash/lang";
import {getPropName, isArrayNullOrEmpty} from "../commonUtilities";
import {
    searchCriteria,
    SearchFilterElements
} from "./searchCriteria";

export function MonitoringComplianceDistrictSearchCriteria(dateWindowId = "", dateRange = "", startDate = "", endDate = "", sortByColumn = "districtName", baseSortByColumn = "districtName") {
    return {
        ...searchCriteria("MonitoringComplianceDistrictSearchCriteria", sortByColumn, baseSortByColumn, 5, dateRange, dateWindowId, endDate, startDate),
        allIndicators: [],
        indicatorType: "",
        nonComplianceStatus: "",
        applyFiltersAndOrder: ApplyFiltersAndOrder,
        areRulesCurrent: AreRulesCurrent,
        clearOptionalSearchItems: ClearOptionalSearchItems,
        setVisibilityRules: SetVisibilityRules,
    };
}

export const McFilterElements = {
    ...SearchFilterElements,
    ...{
        nonComplianceStatus: "nonComplianceStatus"
    }
};

export const McFilterVisibilityRules = {
    McDistrictSummary: [McFilterElements.dateWindowId, McFilterElements.district, McFilterElements.status, McFilterElements.nonComplianceStatus, McFilterElements.indicatorType, McFilterElements.teamMember, McFilterElements.clearButton],
    McSummary: [McFilterElements.dateWindowId, McFilterElements.indicatorType, McFilterElements.RecordsPerPage],
    McSummaryDueNow: [McFilterElements.dateRange],
    McSummaryUpcoming: [McFilterElements.dateRange],
    DistrictWrapper: [McFilterElements.dateWindowId],
    ComplianceAssessmentDetails: [McFilterElements.dateWindowId]
}

function SetVisibilityRules(whichRules) {
    const rulesName = getPropName(McFilterVisibilityRules, whichRules);
    this.baseSetVisibilityRules(rulesName, whichRules, this.BaseSortByColumn);
}

function AreRulesCurrent(whichRules) {
    return this.baseAreRulesCurrent(whichRules, McFilterVisibilityRules)
}

function ClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    this.baseClearOptionalSearchItems(clearTeamMembersAsWell, clearOutAll);

    if(this.isVisible(McFilterElements.nonComplianceStatus) || clearOutAll)
        this.nonComplianceStatus = "";
}

function ApplyFiltersAndOrder(summaryData) {
    if(isArrayNullOrEmpty(summaryData))
        return [];

    let summary = cloneDeep(summaryData);

    summary = this.baseApplyFilters(summary);

    if(!isArrayNullOrEmpty(summary) && this.nonComplianceStatus && this.isVisible(McFilterElements.nonComplianceStatus)) {
        summary = summary.filter(f => f[this.nonComplianceStatus] > 0);
    }

    if(!isArrayNullOrEmpty(summary) && this.teamMember && this.isVisible(McFilterElements.teamMember)) {
        summary = summary.filter(f => f.associatedLiaisonId && f.associatedLiaisonId.toString() === this.teamMember.toString());
    }

    return this.baseSort(summary);
}