import React from 'react';
import PropTypes from "prop-types";
import {convertToCurrencyFormat, convertToPercentFormat} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import TableRowReadOnly from "../../../../components/common/inputs/TableRowReadOnly";

const GmsApplicationSalaryForm = ({
                                          salary,
                                      }) => {


    return <>
        <FormTable includesRow>
            <TableRowReadOnly
                label={`Current Salary:`}
                name={`currentSalary`}
                value={convertToCurrencyFormat(salary.currentSalary)}
            />
            <TableRowReadOnly
                label={`Salary Increase:`}
                name={`amountIncrease`}
                value={convertToCurrencyFormat(salary.amountIncrease)}
            />
            <TableRowReadOnly
                label={`New Salary:`}
                name={`newSalary`}
                value={convertToCurrencyFormat(salary.newSalary)}
            />
            <TableRowReadOnly
                label={`Percent Increase:`}
                name={`percentChange`}
                value={convertToPercentFormat(salary.percentChange)}
            />
            <TableRowReadOnly
                label={`Is FTE being increased? If so, provide current approved FTE and the resulting FTE.`}
                name={`fteChange`}
                value={salary.fteChange}
            />
            <TableRowReadOnly
                label={`Are benefits being increased?`}
                name={`benefitsIncreased`}
                value={salary.benefitsIncreased}
            />
            <TableRowReadOnly
                label={`Why is the salary increase being requested?`}
                name={`changeReason`}
                value={salary.changeReason}
            />
            <TableRowReadOnly
                label={`How does the position align/qualify for an increase according to the documentation provided?`}
                name={`changeQualification`}
                value={salary.changeQualification}
            />
            <TableRowReadOnly
                label={`What lines are the funds being pulled to cover the increases?`}
                name={`changeFunding`}
                value={salary.changeFunding}
            />
            <TableRowReadOnly
                label={`How will the project ensure that services are not reduced/impacted?`}
                name={`serviceImpact`}
                value={salary.serviceImpact}
            />
            <TableRowReadOnly
                label={`Explain the plan to recoup funds.`}
                name={`recoupFunds`}
                value={salary.recoupFunds}
            />
        </FormTable>
    </>;
};


GmsApplicationSalaryForm.propTypes = {
    salary: PropTypes.object.isRequired,
};

export default GmsApplicationSalaryForm;