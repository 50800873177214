import {
    createListItem,
    dynamicSort,
    isArray
} from "../common/commonUtilities";

export const createProgramObject = ({
                                                    programId,
                                                    fiscalAgentId,
                                                    programName
                                                } = {}) => ({
    programId,
    fiscalAgentId,
    programName
});

export const createProgramsList = (programs = []) => {
    let list = [];
    if (isArray(programs))
        programs.map((i) => {
            let program = createProgramObject(i);

            if (!program.programId) return;

            const fiscalAgentId = !program.fiscalAgentId ? `` : `|${program.fiscalAgentId}`;

            list.push({
                ...createListItem(`${program.programId}${fiscalAgentId}`, program.programName)
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};