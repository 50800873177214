import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import React, {Fragment} from "react";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const SourceDocumentationPrintView = ({
                                                 activityNumberOutput,
                                                 handleFileDownload,
                                                 isYearToDate,
                                                 quarter,
                                                 sourceDocumentationFiles
                                             }) => {
    const handleClickDownloadSourceDocument = (event, fileId) => {
        event.preventDefault();
        handleFileDownload(fileId);
    };

    const sourceDocumentationForQuarter = sourceDocumentationFiles.filter(f => f.discretionaryProjectQuarter === quarter?.id);
    const hasDocuments = sourceDocumentationForQuarter.length > 0;

    if(!hasDocuments || isNullOrUndefined(quarter)) return null;

    const containerClass = isYearToDate ? `sourceDocumentation-container__yearToDate` : `sourceDocumentation-container__quarter`;

    return (
        <span className={`sourceDocumentation-container ${containerClass}`}>
            <strong>Source Documentation: </strong>
            {
                sourceDocumentationForQuarter.map((doc, index) => {
                    const delimiter = index < sourceDocumentationForQuarter.length - 1 ? ", " : "";
                    return <Fragment key={`${activityNumberOutput}_doc_${doc.sourceDocumentationFileId}`}>
                        <Button
                            buttonType={ButtonTypes.LINK}
                            inputDescription={`Source Documentation for Activity ${activityNumberOutput} - ${quarter.text}`}
                            label={doc.name}
                            name={`btnSourceDoc_${activityNumberOutput}_${quarter.id}_${doc.sourceDocumentationFileId}`}
                            onClick={(event) => handleClickDownloadSourceDocument(event, doc.sourceDocumentationFileId)}
                            showLabel
                        />{delimiter}
                    </Fragment>;
                })
            }
        </span>
    );
};

SourceDocumentationPrintView.propTypes = {
    activityNumberOutput: PropTypes.string.isRequired,
    handleFileDownload: PropTypes.func.isRequired,
    isYearToDate: PropTypes.bool.isRequired,
    quarter: PropTypes.object,
    sourceDocumentationFiles: PropTypes.array,
};
