import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplay from "./HtmlDisplay";

const HtmlDisplayBlock = ({
	                     className,
	                     name,
	                     html = "",
	                     onClick
                     }) => {
	return <HtmlDisplay isInline={false} onClick={onClick} className={className} html={html} name={name} />;
};

HtmlDisplayBlock.propTypes = {
	className: PropTypes.string,
	html: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func
};

export default HtmlDisplayBlock;