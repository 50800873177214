import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import gmsApi from "../../gmsApi";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import * as gmsLocations from "../../gmsLocations";
import {isArrayNullOrEmpty, isObjectNullOrEmpty, isGuid} from "../../../../components/common/commonUtilities";
import {createSaveApplicationResponseRequest, createResponseFormObject} from "../../gmsObjectFactory";
import {canSaveApplicationSection, updateResponseInNestedElements} from "../../gmsUtilities";
import {GmsApplicationFormView} from "./GmsApplicationFormView";
import {policyEvents} from '../../../../components/authorization/policies/DefaultPolicy';
import {allow} from '../../../../components/authorization/AuthorizationUtilities';
import {GmsPolicy} from '../../../../components/authorization/policies/GmsPolicy';

const GmsApplicationFormContainer = ({
                                         actions,
                                         applicationId,
                                         applicationHistories,
                                         currentLocation,
                                         currentUserFiscalAgentId,
                                         history,
                                         isFiscalAgentUser,
                                         sectionId,
                                         selectedApplicationHistory,
                                         setSelectedApplicationHistory,
                                     }) => {
    const [section, setSection] = useState({});
    const [shouldReinitializeResponses, setShouldReinitializeResponses] = useState(false);
    const [updatedFiles, setUpdatedFiles] = useState([]);
    const [updatedApp, setUpdatedApp] = useState(null);
    const isDirty = !isObjectNullOrEmpty(updatedApp);
    const {
        applicationForms = [],
        applicationTitle = "",
        canEditApplication = false,
        elements = [],
        isEditingDisabledMessageVisible = false,
        nextAmendmentNumber = undefined,
        programName = "",
        selectedSection,
        subtitle = "",
        targetedAllocation = 0,
    } = section;

    const canEditBasedOnApplicationStatus = canEditApplication;
    const canEditBasedOnUserSpecificPermission = allow(GmsPolicy, policyEvents.GMS.APPLICATION.modify);
    const canUserEditApplication = canEditBasedOnApplicationStatus && canEditBasedOnUserSpecificPermission;

    const initializeData = () => {
        actions.executeApi(gmsApi.getApplicationSection, [applicationId, sectionId])
            .then((result) => {
                const isFiscalAgentUserWrongFiscalAgent = isFiscalAgentUser && currentUserFiscalAgentId !== result.fiscalAgentId;
                if (!result.canViewApplication || isFiscalAgentUserWrongFiscalAgent) {
                    const resolvedPath = gmsLocations.GMS_SUMMARY.getUrl();
                    history.push(resolvedPath);
                    return;
                }

                setSection(result);
            });
    };

    const handleSave = async () => {
        const isValid = canSaveApplicationSection(updatedApp);
        if (!isValid)
            return false;

        let applicationResponses = createSaveApplicationResponseRequest(updatedApp);

        const saveApplicationResponse = (responses) => {
            return actions.executeApi(gmsApi.saveApplicationResponse, [applicationId, sectionId, responses])
                .then(() => {
                    setUpdatedApp(null);
                    setUpdatedFiles([]);
                    return true;
                });
        };

        if (isArrayNullOrEmpty(updatedFiles))
            return saveApplicationResponse(applicationResponses);

        const saveApplicationFiles = () => {
            const promises = updatedFiles.map((event) => {
                const file = [...event.target.files];
                const {name} = file[0];
                const elementId = event.target.id;
                const existingResponses = createResponseFormObject(elements);
                const existingValue = existingResponses[elementId];
                const existingFileId = isGuid(existingValue) ? existingValue : undefined;



                return actions.uploadFile(file, existingFileId)
                    .then((fileId) => {
                        if (!fileId) return false;

                        const responseValue = JSON.stringify({
                            fileId,
                            fileName: name
                        });

                        applicationResponses.responses.find(r => r.elementId === elementId)
                            .responseValue = responseValue;
                        updateResponseInNestedElements(elements, elementId, responseValue)

                        return true;
                    });
            });

            return Promise.all(promises);
        };

        return saveApplicationFiles()
            .then(() => {
                setShouldReinitializeResponses(true);
                return saveApplicationResponse(applicationResponses);
            });
    };

    const callback = (event, data) => {
        if (event.target.type === "file") {
            let newUpdatedFiles = updatedFiles.filter(f => f.target.id !== event.target.id);
            newUpdatedFiles.push({...event});
            setUpdatedFiles(newUpdatedFiles);
        }
        setUpdatedApp(data);
    };

    useEffect(initializeData, []);

    useEffect(() => {
        if (applicationTitle)
            actions.updatePageTitle(applicationTitle);
        if (subtitle)
            actions.updatePageSubTitle(subtitle);

        return actions.clearSubTitle;
    }, [section]);

    if (!section) return null;

    return (
        <>
            {
                !isArrayNullOrEmpty(elements) &&
                <GmsApplicationFormView
                    actions={actions}
                    applicationId={applicationId}
                    applicationForms={applicationForms}
                    applicationHistories={applicationHistories}
                    callback={callback}
                    canUserEditApplication={canUserEditApplication}
                    elements={elements}
                    isDirty={isDirty}
                    isFiscalAgentUser={isFiscalAgentUser}
                    isEditingDisabledMessageVisible={isEditingDisabledMessageVisible}
                    nextAmendmentNumber={nextAmendmentNumber}
                    programName={programName}
                    selectedApplicationHistory={selectedApplicationHistory}
                    selectedSection={selectedSection}
                    setSection={setSection}
                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                    setShouldReinitializeResponses={setShouldReinitializeResponses}
                    shouldReinitializeResponses={shouldReinitializeResponses}
                    targetedAllocation={targetedAllocation}
                />
            }
            {
                isArrayNullOrEmpty(elements) &&
                <>
                    <p>Empty Section</p>
                </>
            }
            <GmsApplicationNavigation
                applicationId={applicationId}
                canEditApplication={canUserEditApplication}
                currentLocationForFastCancel={currentLocation}
                handleSave={handleSave}
                history={history}
                isDirty={isDirty}
                sectionId={sectionId}
            />
        </>
    );
};


GmsApplicationFormContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    currentLocation: PropTypes.string,
    currentUserFiscalAgentId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    sectionId: PropTypes.string.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
};

export default GmsApplicationFormContainer;