import {matchPath} from "react-router-dom";

const exception = () => {throw new Error("Method getUrl not implemented.");};

export default function Location(getUrl = exception) {
	let result = {getUrl};
	result.path = getUrl();
	result.getParams = (currentPath) => getParams(currentPath, result.path);

	return result;
}

function getParams(currentPath, routeToMatch){
	const matchedInfo = matchPath(currentPath, {
		path: routeToMatch,
		exact: false,
		strict: false
	});

	return matchedInfo && matchedInfo.params || null;
}