import React from "react";
import PropTypes from 'prop-types';
import {getEndOfCurrentDay} from "../../components/common/dateUtilities";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import SummaryView from "../../components/summary/SummaryView";
import {DISPUTE_RESOLUTION_CONTEXT} from "../../constants/contexts";
import {futureDateSelectFieldOptions} from "../../constants/inputConstants";

export const DrSummaryDueNow = ({
                                    drSummarySearchCriteria,
                                    handleClickGeneral,
                                    setDrSummarySearchCriteria,
                                    summary
                                }) => {
    const buildDrSearchCriteria = () => {
        return new DrSearchCriteria(drSummarySearchCriteria.dateWindowId, futureDateSelectFieldOptions.Custom, "", getEndOfCurrentDay(), "title", "title", drSummarySearchCriteria.disputeResolutionTypeWithDefault);
    };

    return <SummaryView
        buildSearchCriteria={buildDrSearchCriteria}
        containerId={"due-now"}
        context={DISPUTE_RESOLUTION_CONTEXT}
        handleClickGeneral={handleClickGeneral}
        isDueNow
        setSummarySearchCriteria={setDrSummarySearchCriteria}
        summary={summary}
        summarySearchCriteria={drSummarySearchCriteria}
        summaryViewTitle={"Due Now"}
        visibilityRules={DrFilterVisibilityRules.DrSummaryDueNow}
    />;
};

DrSummaryDueNow.propTypes = {
    drSummarySearchCriteria: PropTypes.object.isRequired,
    handleClickGeneral: PropTypes.func.isRequired,
    setDrSummarySearchCriteria: PropTypes.func.isRequired,
    summary: PropTypes.array
};

export default DrSummaryDueNow;