import AjaxRequestWithRetry from './AjaxRequestWithRetry';
import DownloadUtility from './DownloadUtility';

export default (localStorage, notificationFactory, maxRetryCount, tokenUrl, retryTimeout = 150) => {
    function send(ajaxRequestOptions) {
        return new Promise((resolve, reject) => {
            ajaxRequestOptions.contentType = false;
            ajaxRequestOptions.processData = false;
            ajaxRequestOptions.xhr = () => {
                let xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if(xhr.readyState === 2) {
                        if(xhr.status === 200)
                            xhr.responseType = 'blob';
                        else
                            xhr.responseType = 'text';
                    }
                };
                return xhr;
            };

            const successMessage = ajaxRequestOptions.successMessage;
            ajaxRequestOptions.setCallbacks(function(data, status, xhrObject) {
                let filename = DownloadUtility.getFilenameFromHeader(xhrObject);

                if (window.navigator && window.navigator.msSaveOrOpenBlob) // for IE
                    window.navigator.msSaveOrOpenBlob(data, filename);
                else {
                    const a = document.createElement("a");
                    const url = URL.createObjectURL(new Blob([data]));
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.download = filename;
                    a.href = url;
                    a.click();

                    if (url)
                        URL.revokeObjectURL(a.href);

                    a.remove();
                }

                if (successMessage)
                    notificationFactory.createSuccess(successMessage).notify();

                resolve(data);
            }, function (data) {
                notificationFactory.createErrorsFromXhrObject(data).notify();
                reject(data);
            });
            AjaxRequestWithRetry(localStorage, notificationFactory, maxRetryCount, tokenUrl, retryTimeout).send(ajaxRequestOptions);
        });
    }

    return { send };
};