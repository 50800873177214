import {convertObjectToFormData} from "../../api/ApiUtility";
import * as config from "../../constants/config";
import * as ApiUtility from "../../api/ApiUtility";
import AjaxRequestOptions from "../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../api/requests/RequestTypes';
import * as mockData from "../drMockData";
import {dr_api as ApiSuccessMessages} from "../../constants/api/ApiSuccessMessages";
import * as drMediationObjectFactory from "./drMediationObjectFactory";

const disputeApi = 'dispute-resolution';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrMediationApi {
    static getSummary(dateWindowId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediationSummaries/${dateWindowId}`,
                RequestTypes.GET));
    }

    static getDistrictMediationsByDate(districtId, routeTemplate,dateWindowId){

        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/${districtId}/mediations/Date/${dateWindowId}/route`,
                RequestTypes.GET,
                { routeTemplate }));
    }

    static getMediation(mediationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediations/${mediationId}/`,
                RequestTypes.GET));
    }

    static saveMediation(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/${model.districtId}/mediations/`,
                RequestTypes.PUT,
                JSON.stringify(model),
                ApiSuccessMessages.mediations.SAVED,
                'application/json'));
    }

    static addMediation(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/${model.districtId}/mediations/`,
                RequestTypes.POST,
                JSON.stringify(model),
                ApiSuccessMessages.mediations.ADDED,
                'application/json'));
    }

    static deleteMediation(mediationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediations/${mediationId}/`,
                RequestTypes.DELETE));
    }

    static GetDefaultCaseNumber(dateWindowId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediations/Date/${dateWindowId}/caseNumber`,
                RequestTypes.GET));
    }

	static getPrimaryExceptionalityTypes() {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/primary-exceptionalities`,
				RequestTypes.GET));
	}

	static getMediators() {
		return ApiUtility.sendRequest(
			AjaxRequestOptions(
				`${config.apiUrl}${disputeApi}/mediators`,
				RequestTypes.GET));
	}

    static getFederalReport(dateWindowId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediation/reports/state/federalPrint/Dates/${dateWindowId}`,
                RequestTypes.GET));
    }

    static exportCaseSearchResults(caseSearchFilters) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeApi}/mediation/reports/state/caseSearchResultsExport/dates/${caseSearchFilters.dateWindowId}`,
                RequestTypes.POST,
                convertObjectToFormData(caseSearchFilters),
                ApiSuccessMessages.mediations.CASE_RESEARCH.DOWNLOAD
            ));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrMediationApi {
    static getSummary() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(mockData.getMediationSummary());
            }, config.environment.delay);
        });
    }

    static getMediation(mediationId) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(mockData.getMediationById(mediationId));
            }, config.environment.delay);
        });
    }

    static addMediation(newMediation){
        const mediationDTO = drMediationObjectFactory.createMediationObject(newMediation);
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(mediationDTO);
            }, config.environment.delay);
        });
    }

    static deleteMediation(mediationId){
        return new Promise(resolve => {
            setTimeout(() => {
                const otherMediations = mockData.getMediations().filter(a => a.id !== mediationId);

                mockData.saveMediations([...otherMediations]);
                resolve();
            }, config.environment.delay);
        });
    }

    static saveMediation(mediation){
        return new Promise(resolve => {
            setTimeout(() => {
                const OtherMediations = mockData.getMediations().filter(a => a.id !== mediation.id);

                mockData.saveMediations([...OtherMediations, mediation]);
                resolve();
            }, config.environment.delay);
        });
    }

	static getPrimaryExceptionalityTypes() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

	static getMediators() {
		return new Promise(resolve => {
			setTimeout(() => {
				resolve();
			}, config.environment.delay);
		});
	}

    static getFederalReport() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static exportCaseSearchResults() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const DrMediationsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrMediationApi : MockDrMediationApi;
export default DrMediationsApi;