import {getPropName} from "../components/common/commonUtilities";
import * as responseTypes from "../constants/monitoringCompliance/responseTypes";
import * as responseOptions from "../constants/monitoringCompliance/responseOptions";
import {
    ComplianceMonitoringOverallStatus,
    NOT_REQUIRED_SHORTHAND
} from "../constants/monitoringCompliance/statusValues";
import * as correctionResultConstants from "../constants/monitoringCompliance/correctionResults";
import audit_outcomes from "../constants/auditOutcomes";

let districtStatuses = null;
let complianceAssessments = null;
let protocols = null;
let protocolCorrectiveAction = null;
let correctiveActivities = null;
let complianceReassessment = null;
let studentProtocolCorrectiveResults = null;
let matrixProtocolCorrectiveResults = null;

export function getDistrictStatuses() {
    if (districtStatuses === null) {
        districtStatuses = [
            {
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                districtId: "1",
                district: "Alachua",
                status: audit_outcomes.SUBMITTED.value,
                districtStudentSpecificStatus: audit_outcomes.IN_PROGRESS.value,
                districtCapActivityStatus: NOT_REQUIRED_SHORTHAND,
                districtCapActivityResultStatus: NOT_REQUIRED_SHORTHAND,
                districtMatrixCorrectiveActionStatus: NOT_REQUIRED_SHORTHAND,
                bureauStudentSpecificStatus: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus["Not Started"]),
                bureauCapActivityStatus: NOT_REQUIRED_SHORTHAND,
                bureauMatrixCorrectiveActionStatus: NOT_REQUIRED_SHORTHAND
            },
            {
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                districtId: "2",
                district: "Brevard",
                status: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus["Not Started"]),
                districtStudentSpecificStatus: NOT_REQUIRED_SHORTHAND,
                districtCapActivityStatus: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus.Approved),
                districtCapActivityResultStatus: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus.Approved),
                districtMatrixCorrectiveActionStatus: NOT_REQUIRED_SHORTHAND,
                bureauStudentSpecificStatus: NOT_REQUIRED_SHORTHAND,
                bureauCapActivityStatus: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus.Approved),
                bureauCapActivityResultStatus: getPropName(ComplianceMonitoringOverallStatus, ComplianceMonitoringOverallStatus.Approved),
                bureauMatrixCorrectiveActionStatus: NOT_REQUIRED_SHORTHAND
            }
        ];
    }

    return districtStatuses;
}


export function getComplianceAssessments() {
    if(complianceAssessments === null) {
        complianceAssessments = [
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 1",
                indicatorRequirements: [
                    {
                        indicatorRequirementId: "1",
                        studentRecordsRequirementCount: "3",
                        indicatorId: "1",
                        indicator: "SPP 1",
                        isMatrix: false,
                        publicIdentifier: "T16",
                        allStudentRecordsSubmitted: true,
                        validationSubmitted: false,
                        studentRecordsWithDiscrepanciesRequiringReassessment: false,
                        assessmentApproved: false,
                        validationNotes: "",
                    },
                    {
                        indicatorRequirementId: "2",
                        studentRecordsRequirementCount: "2",
                        indicatorId: "2",
                        indicator: "SPP 2",
                        isMatrix: false,
                        publicIdentifier: "T16",
                        allStudentRecordsSubmitted: true,
                        validationSubmitted: false,
                        studentRecordsWithDiscrepanciesRequiringReassessment: false,
                        assessmentApproved: false,
                        validationNotes: "",
                    },
                    {
                        indicatorRequirementId: "3",
                        studentRecordsRequirementCount: "2",
                        indicatorId: "3",
                        indicator: "SPP 3",
                        isMatrix: false,
                        publicIdentifier: "T16",
                        allStudentRecordsSubmitted: true,
                        validationSubmitted: false,
                        studentRecordsWithDiscrepanciesRequiringReassessment: false,
                        assessmentApproved: false,
                        validationNotes: "",
                    }
                ],
                studentRecords: [
                    {
                        studentRecordId: "1",
                        name: "Alachua-Spp1-1",
                        isMatrix: false,
                        isRecordSubmitted: true,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        protocolResponses: [
                            {
                                protocolResponseId: "1111",
                                protocolId: "1",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "2222",
                                protocolId: "2",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "3333",
                                protocolId: "3",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "4444",
                                protocolId: "4",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "5555",
                                protocolId: "5",
                                response: responseOptions.NO,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "1111s",
                                protocolId: "1",
                                response: responseOptions.NO,
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "2222s",
                                protocolId: "2",
                                response: responseOptions.NV,
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "3333s",
                                protocolId: "3",
                                response: responseOptions.YES,
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "4444s",
                                protocolId: "4",
                                response: responseOptions.NO,
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "5555s",
                                protocolId: "5",
                                response: responseOptions.NO,
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "1111r",
                                protocolId: "1",
                                response: responseOptions.NO,
                                responseType: responseTypes.REASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "4444r",
                                protocolId: "1",
                                response: responseOptions.NO,
                                responseType: responseTypes.REASSESS,
                                subQuestionResponses: []
                            }
                        ],
                        indicatorsAssignedTo: [
                            {
                                id: "1",
                                name: "SPP 1"
                            }
                        ]
                    },
                    {
                        studentRecordId: "2",
                        isRecordSubmitted: false,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        name: "Alachua-Spp1-2",
                        isMatrix: false,
                        protocolResponses: [
                            {
                                protocolResponseId: "11112",
                                protocolId: "1",
                                response: responseOptions.NO,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "11113",
                                protocolId: "2",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "11114",
                                protocolId: "3",
                                response: responseOptions.NO,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "11115",
                                protocolId: "4",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            }
                        ],
                        indicatorsAssignedTo: [
                            {
                                id: "3",
                                name: "SPP 3"
                            }
                        ]
                    },
                    {
                        studentRecordId: "3",
                        isRecordSubmitted: false,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        name: "Alachua-Spp3-1",
                        isMatrix: false,
                        protocolResponses: [],
                        indicatorsAssignedTo: [
                            {
                                id: "3",
                                name: "SPP 3"
                            }
                        ]
                    }
                ]
            },
            {
                monitoringAssessmentId: "2",
                districtId: "2",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 2",
                indicatorRequirements: [
                    {
                        indicatorRequirementId: "4",
                        studentRecordsRequirementCount: "4",
                        indicatorId: "5",
                        indicator: "SPP 5",
                        isMatrix: false,
                        publicIdentifier: "T16",
                        allStudentRecordsSubmitted: false,
                        validationSubmitted: false,
                        studentRecordsWithDiscrepanciesRequiringReassessment: false,
                        assessmentApproved: false,
                        validationNotes: "",
                    }
                ],
                studentRecords: [
                    {
                        studentRecordId: "4",
                        isRecordSubmitted: false,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        name: "Alachua-Spp13-1",
                        isMatrix: false,
                        protocolResponses: [],
                        indicatorsAssignedTo: [
                            {
                                id: "5",
                                name: "SPP 5"
                            }
                        ]
                    }
                ]

            },
            {
                monitoringAssessmentId: "3",
                districtId: "3",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 3",
                indicatorRequirements: [],
                studentRecords: []
            },
            {
                monitoringAssessmentId: "4",
                districtId: "4",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 1",
                indicatorRequirements: [],
                studentRecords: []
            },
            {
                monitoringAssessmentId: "5",
                districtId: "4",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 1",
                indicatorRequirements: [{
                    indicatorRequirementId: "45",
                    studentRecordsRequirementCount: "1",
                    indicatorId: "ind_M",
                    indicator: "M",
                    isMatrix: true,
                    publicIdentifier: "M",
                    allStudentRecordsSubmitted: false,
                    validationSubmitted: false,
                    studentRecordsWithDiscrepanciesRequiringReassessment: false,
                    assessmentApproved: false,
                    validationNotes: "",
                }],
                studentRecords: [
                    {
                        studentRecordId: "25",
                        isRecordSubmitted: false,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        name: "M-Alachua-1",
                        isMatrix: true,
                        protocolResponses: [
                            {
                                protocolResponseId: "987",
                                protocolId: "6",
                                response: "2,3",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "986",
                                protocolId: "7",
                                response: "4,5",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "985",
                                protocolId: "8",
                                response: "13",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "984",
                                protocolId: "9",
                                response: "3",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "983",
                                protocolId: "6",
                                response: "1",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "982",
                                protocolId: "7",
                                response: "3",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "981",
                                protocolId: "8",
                                response: "12",
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            },
                            {
                                protocolResponseId: "980",
                                protocolId: "9",
                                response: "5",
                                responseType: responseTypes.VALIDATION,
                                subQuestionResponses: []
                            }
                        ],
                        indicatorsAssignedTo: [
                            {
                                id: "ind_M",
                                name: "M"
                            }
                        ]
                    }
                ]
            },
            {
                monitoringAssessmentId: "6",
                districtId: "1",
                dateWindowId: "dffa3ab7-7f95-40f4-89a2-3a2167ad41d9",
                isReadyForResubmission: false,
                anyProtocolsNotMeetingCompliance: false,
                anyMatrixProtocolsNotMeetingCompliance: false,
                classification: "Tier 2",
                indicatorRequirements: [
                    {
                        indicatorRequirementId: "6_ireq_1",
                        studentRecordsRequirementCount: "1",
                        indicatorId: "5",
                        indicator: "SPP 5",
                        isMatrix: false,
                        publicIdentifier: "T16",
                        allStudentRecordsSubmitted: false,
                        validationSubmitted: false,
                        studentRecordsWithDiscrepanciesRequiringReassessment: false,
                        assessmentApproved: false,
                        validationNotes: "",
                    }
                ],
                studentRecords: [
                    {
                        studentRecordId: "6_1",
                        isRecordSubmitted: false,
                        hasRevisionsBeenRequested: false,
                        isRecordValidated: false,
                        name: "Alachua-Spp5-1",
                        isMatrix: false,
                        protocolResponses: [
                            {
                                protocolResponseId: "6_pr_1",
                                protocolId: "5",
                                response: responseOptions.YES,
                                responseType: responseTypes.ASSESS,
                                subQuestionResponses: []
                            }
                        ],
                        indicatorsAssignedTo: [
                            {
                                id: "5",
                                name: "SPP 5"
                            }
                        ]
                    }
                ]

            }

        ];
    }

    return complianceAssessments;
}

export function getProtocols() {
    if(protocols === null) {
        protocols = [
            {
                protocolId: "1",
                standardDescription: "Participation and Performance on Statewide Assessments - Participation and performance of children with IEPs on statewide assessments:<ol type=\"A\" start=\"2\"><li>Participation rate for children with IEPs</li><ol><li>Reading</li></ol></ol>",
                citation: "(34 CFR §300.322(b)(2))",
                publicIdentifier: "SPP-1",
                subQuestionDirections: "",
                isMatrix: false,
                districtResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    }
                ],
                stateResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "2",
                standardDescription: "Participation and Performance on Statewide Assessments - Participation and performance of children with IEPs on statewide assessments<ol type=\"A\"><li>Percent of the districts with a disability subgroup that meets the State&rsquo;s minimum &ldquo;n&rdquo; size that meet the State&rsquo;s AYP/AMO targets for the disability subgroup.<ol><li>Reading</li></ol></li></ol>",
                citation: "(34 CFR §300.322(b)(3))",
                publicIdentifier: "SPP-2",
                subQuestionDirections: "",
                isMatrix: false,
                districtResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NA,
                        value: responseOptions.NA
                    }
                ],
                stateResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NA,
                        value: responseOptions.NA
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "3",
                standardDescription: "Preschool Children in General Education Settings (Pre-School LRE) - Percent of children aged 3 through 5 with IEPs attending:<ol type=\"A\" start=\"2\"><li>Separate special education class, separate school or residential facility.</li></ol>",
                citation: "(34 CFR §300.322(b)(4))",
                publicIdentifier: "SPP-3",
                subQuestionDirections: "",
                isMatrix: false,
                districtResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    }
                ],
                stateResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "4",
                standardDescription: "Participation and Performance on Statewide Assessments - Participation and performance of children with IEPs on statewide assessments:<ol type=\"A\" start=\"2\"><li>Participation rate for children with IEPs</li><ol><li>Reading</li></ol></ol>",
                citation: "(34 CFR §300.322(b)(5))",
                publicIdentifier: "SPP-4",
                subQuestionDirections: "",
                isMatrix: false,
                districtResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    }
                ],
                stateResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "5",
                standardDescription: "Timeframe Between Evaluation and Identification (Child Find) - Percent of children who were evaluated within 60 days of receiving parental consent for initial evaluation or, if the State establishes a timeframe within which the evaluation must be conducted, within that timeframe.",
                citation: "(34 CFR §300.322(b)(6))",
                publicIdentifier: "SPP-5",
                subQuestionDirections: "",
                isMatrix: false,
                districtResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    }
                ],
                stateResponseOptions: [
                    {
                        text: responseOptions.YES,
                        value: responseOptions.YES
                    },
                    {
                        text: responseOptions.NO,
                        value: responseOptions.NO
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "6",
                standardDescription: "Domain A",
                citation: "",
                publicIdentifier: "M-1",
                subQuestionDirections: "",
                isMatrix: true,
                districtResponseOptions: [
                    {
                        text: "1",
                        value: "1"
                    },
                    {
                        text: "2",
                        value: "2"
                    },
                    {
                        text: "3",
                        value: "3"
                    },
                    {
                        text: "4",
                        value: "4"
                    },
                    {
                        text: "5",
                        value: "5"
                    }

                ],
                stateResponseOptions: [
                    {
                        text: "1",
                        value: "1"
                    },
                    {
                        text: "2",
                        value: "2"
                    },
                    {
                        text: "3",
                        value: "3"
                    },
                    {
                        text: "4",
                        value: "4"
                    },
                    {
                        text: "5",
                        value: "5"
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "7",
                standardDescription: "Domain B",
                citation: "",
                publicIdentifier: "M-2",
                subQuestionDirections: "",
                isMatrix: true,
                districtResponseOptions: [
                    {
                        text: "1",
                        value: "1"
                    },
                    {
                        text: "2",
                        value: "2"
                    },
                    {
                        text: "3",
                        value: "3"
                    },
                    {
                        text: "4",
                        value: "4"
                    },
                    {
                        text: "5",
                        value: "5"
                    }
                ],
                stateResponseOptions: [
                    {
                        text: "1",
                        value: "1"
                    },
                    {
                        text: "2",
                        value: "2"
                    },
                    {
                        text: "3",
                        value: "3"
                    },
                    {
                        text: "4",
                        value: "4"
                    },
                    {
                        text: "5",
                        value: "5"
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "8",
                standardDescription: "Add 13 points",
                citation: "",
                publicIdentifier: "M-6",
                subQuestionDirections: "",
                isMatrix: true,
                districtResponseOptions: [
                    {
                        value: "13"
                    }
                ],
                stateResponseOptions: [
                    {
                        value: "13"
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
            {
                protocolId: "9",
                standardDescription: "Add 3 points",
                citation: "",
                publicIdentifier: "M-7",
                subQuestionDirections: "",
                isMatrix: true,
                districtResponseOptions: [
                    {
                        value: "3"
                    }
                ],
                stateResponseOptions: [
                    {
                        value: "3"
                    },
                    {
                        text: responseOptions.NV,
                        value: responseOptions.NV
                    }
                ],
                subQuestions: []
            },
        ];
    }

    return protocols;
}

export function getProtocolCorrectiveActions(useNewCopyOfData = false) {
    if(protocolCorrectiveAction === null || useNewCopyOfData) {
        protocolCorrectiveAction = [
            {
                correctiveActionPlanId: "AAAA",
                standardDisplayNumber: "1",
                publicIdentifier: "T16",
                citation: "(34 CFR 300.320)",
                title: "SPP 13 – Secondary Transition Age 16",
                description: "The measurable postsecondary goal",
                hasRequiredActivities: true,
                associatedCapActivityIds: ["101", "102"]
            },
            {
                correctiveActionPlanId: "BBBB",
                standardDisplayNumber: "2",
                publicIdentifier: "T16",
                citation: "(34 CFR 300.320)",
                title: "SPP 13 – Secondary Transition Age 16",
                description: "The measurable postsecondary goal",
                hasRequiredActivities: false,
                associatedCapActivityIds: []
            },
            {
                correctiveActionPlanId: "CCCC",
                standardDisplayNumber: "3",
                publicIdentifier: "T16",
                citation: "(34 CFR 300.320)",
                title: "SPP 13 – Secondary Transition Age 16",
                description: "The measurable postsecondary goal",
                hasRequiredActivities: true,
                associatedCapActivityIds: ["103", "104"],
            },
            {
                correctiveActionPlanId: "DDDD",
                standardDisplayNumber: "4",
                publicIdentifier: "T16",
                citation: "(34 CFR 300.320)",
                title: "SPP 13 – Secondary Transition Age 16",
                description: "The measurable postsecondary goal",
                hasRequiredActivities: true,
                associatedCapActivityIds: ["105", "106"],
            }
        ];
    }

    return protocolCorrectiveAction;
}

export function getStudentProtocolCorrectiveResults(useNewCopyOfData = false) {
    if(studentProtocolCorrectiveResults === null || useNewCopyOfData) {
        studentProtocolCorrectiveResults = [
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "1",
                name: "Alachua-Spp1-1",
                studentProtocolCorrectiveResultId: "spc-result-1",
                indicatorId: "1",
                protocolId: "1",
                publicIdentifier: "SPP-1",
                citation: "(34 CFR 300.320)",
                standardDescription: "The measurable postsecondary goal",
                corrections: [correctionResultConstants.studentCorrections.NewPlanFromMeeting, correctionResultConstants.studentCorrections.AmendedWithoutMeeting, `${correctionResultConstants.studentCorrections.StudentWithdrew}${correctionResultConstants.correctionOtherTextSeparator}Student withdrew other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.SUBMITTED.value,
                acknowledgeNeedsRevisionComplete: false,
                audits: [{
                    auditId: "spc-1-audit-1",
                    isLocked: false,
                    comments: [],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.IN_PROGRESS.value
                }]
            },
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "1",
                name: "Alachua-Spp2-1",
                studentProtocolCorrectiveResultId: "spc-result-2",
                indicatorId: "2",
                protocolId: "2",
                publicIdentifier: "SPP-2",
                citation: "(34 CFR 300.320)",
                standardDescription: "The measurable postsecondary goal",
                corrections: [`${correctionResultConstants.studentCorrections.StaffTraining}${correctionResultConstants.correctionOtherTextSeparator}staff training other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.IN_PROGRESS.value,
                acknowledgeNeedsRevisionComplete: false,
                audits: []
            },
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "2",
                name: "Alachua-Spp1-2",
                studentProtocolCorrectiveResultId: "spc-result-3",
                indicatorId: "1",
                protocolId: "1",
                publicIdentifier: "SPP-1",
                citation: "(34 CFR 300.320)",
                standardDescription: "The measurable postsecondary goal",
                corrections: [`${correctionResultConstants.studentCorrections.ProvidedProgressReport}${correctionResultConstants.correctionOtherTextSeparator}provided progress report other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.NEEDS_REVISION.value,
                acknowledgeNeedsRevisionComplete: true,
                audits: [{
                    auditId: "spc-3-audit-1",
                    isLocked: false,
                    comments: [{
                        auditCommentId: "spc-3-audit-1-comm-1",
                        commentOwnerEmail: "steveg@fcim.org",
                        isCommentOwner: true,
                        displayDate: "1/2/2019",
                        text: "this is needs some work!"
                    }],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.NEEDS_REVISION.value
                }]
            },
            {
                monitoringAssessmentId: "2",
                districtId: "2",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "4",
                name: "Alachua-Spp2-4",
                studentProtocolCorrectiveResultId: "spc-result-4",
                indicatorId: "2",
                protocolId: "2",
                publicIdentifier: "SPP-2",
                citation: "(34 CFR 300.320)",
                standardDescription: "The measurable postsecondary goal",
                corrections: [correctionResultConstants.studentCorrections.NewPlanFromMeeting, `${correctionResultConstants.studentCorrections.RevisedForm}${correctionResultConstants.correctionOtherTextSeparator}revised form other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.ACCEPTED.value,
                acknowledgeNeedsRevisionComplete: false,
                audits: [{
                    auditId: "spc-4-audit-1",
                    isLocked: false,
                    comments: [],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.ACCEPTED.value
                }]
            }
        ];
    }

    return studentProtocolCorrectiveResults;
}

export function getMatrixProtocolCorrectiveResults(useNewCopyOfData = false) {
    if(matrixProtocolCorrectiveResults === null || useNewCopyOfData) {
        matrixProtocolCorrectiveResults = [
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "1",
                name: "Alachua-Spp1-1",
                matrixProtocolCorrectiveResultId: "mpc-result-1",
                reportedCostFactor: 255,
                reviewedCostFactor: 254,
                corrections: [correctionResultConstants.matrixCorrections.Corrected, correctionResultConstants.matrixCorrections.NewIepWithChangedCostFactor, `${correctionResultConstants.matrixCorrections.Other}${correctionResultConstants.correctionOtherTextSeparator}Other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.SUBMITTED.value,
                audits: [{
                    auditId: "mpc-1-audit-1",
                    isLocked: false,
                    comments: [],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.IN_PROGRESS.value
                }]
            },
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "2",
                name: "Alachua-Spp2-2",
                matrixProtocolCorrectiveResultId: "mpc-result-2",
                reportedCostFactor: 254,
                reviewedCostFactor: 253,
                corrections: [`${correctionResultConstants.matrixCorrections.Other}${correctionResultConstants.correctionOtherTextSeparator}more other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.IN_PROGRESS.value,
                audits: []
            },
            {
                monitoringAssessmentId: "1",
                districtId: "1",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "3",
                name: "Alachua-Spp1-3",
                matrixProtocolCorrectiveResultId: "mpc-result-3",
                reportedCostFactor: 253,
                reviewedCostFactor: 252,
                corrections: [`${correctionResultConstants.matrixCorrections.Other}${correctionResultConstants.correctionOtherTextSeparator}last other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.NEEDS_REVISION.value,
                audits: [{
                    auditId: "mpc-3-audit-1",
                    isLocked: false,
                    comments: [{
                        auditCommentId: "mpc-3-audit-1-comm-1",
                        commentOwnerEmail: "steveg@fcim.org",
                        isCommentOwner: true,
                        displayDate: "1/2/2019",
                        text: "this is great!"
                    }],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.NEEDS_REVISION.value
                }]
            },
            {
                monitoringAssessmentId: "2",
                districtId: "2",
                dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                studentRecordId: "4",
                name: "Alachua-Spp2-4",
                matrixProtocolCorrectiveResultId: "mpc-result-4",
                reportedCostFactor: 255,
                reviewedCostFactor: 251,
                corrections: [correctionResultConstants.matrixCorrections.NewIepWithUnchangedCostFactor, `${correctionResultConstants.studentCorrections.Other}${correctionResultConstants.correctionOtherTextSeparator}again other text`],
                dateCorrected: "1/1/2019",
                corrected: correctionResultConstants.corrected.Yes,
                status: audit_outcomes.ACCEPTED.value,
                audits: [{
                    auditId: "mpc-4-audit-1",
                    isLocked: false,
                    comments: [],
                    dateCreated: "1/1/2019",
                    outcomeString: audit_outcomes.ACCEPTED.value
                }]
            }
        ];
    }

    return matrixProtocolCorrectiveResults;
}

export function getCorrectiveActivities() {
    if(correctiveActivities === null) {
        correctiveActivities = [
            {
                monitoringAssessmentId: "1",
                activityId: "101",
                activityNumber: 1,
                activitySummary: "Work needs to be done with students",
                resources: "These are the resources used.",
                timelineStartDate: "10/18/2018",
                timelineEndDate: "10/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "10/18/2019",
                    results: "Here are the results",
                    capActivityResultReview: {
                        reviewId: "1",
                        results: [correctionResultConstants.reviewResultOptions[0], `${correctionResultConstants.correctionOtherText}${correctionResultConstants.correctionOtherTextSeparator}What another message?`],
                        comments: "Another comment",
                        dateDocumentationReceived: "1/1/2019",
                        dateDocumentationReviewed: "1/1/2019",
                        dateCorrectionDemonstrated: "1/1/2019",
                        status: "Corrected"
                    }
                }
            },
            {
                monitoringAssessmentId: "1",
                activityId: "102",
                activityNumber: 2,
                activitySummary: "Stuff",
                resources: "Other resources",
                timelineStartDate: "1/18/2018",
                timelineEndDate: "1/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "1/18/2019",
                    results: "More results",
                    capActivityResultReview: {}
                }
            },
            {
                monitoringAssessmentId: "1",
                correctiveActionPlanId: "CCCC",
                activityId: "103",
                activityNumber: 1,
                activitySummary: "CCCC-3 Work needs to be done with students",
                resources: "These are the resources used.",
                timelineStartDate: "10/18/2018",
                timelineEndDate: "10/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "10/18/2019",
                    results: "Here are the results",
                    capActivityResultReview: {
                        reviewId: "2",
                        results: [correctionResultConstants.reviewResultOptions[1]],
                        comments: "Another comment",
                        dateDocumentationReceived: "1/1/2019",
                        dateDocumentationReviewed: "1/1/2019",
                        dateCorrectionDemonstrated: "1/1/2019",
                        status: "Corrected"
                    }
                }
            },
            {
                monitoringAssessmentId: "1",
                correctiveActionPlanId: "CCCC",
                activityId: "104",
                activityNumber: 2,
                activitySummary: "CCCC-4 Stuff",
                resources: "Other resources",
                timelineStartDate: "1/18/2018",
                timelineEndDate: "1/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "1/18/2019",
                    results: "More results",
                    capActivityResultReview: {}
                }
            },
            {
                monitoringAssessmentId: "1",
                correctiveActionPlanId: "DDDD",
                activityId: "105",
                activityNumber: 1,
                activitySummary: "DDDD-5 Work needs to be done with students",
                resources: "These are the resources used.",
                timelineStartDate: "10/18/2018",
                timelineEndDate: "10/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "10/18/2019",
                    results: "Here are the results",
                    capActivityResultReview: {
                        reviewId: "3",
                        results: [correctionResultConstants.reviewResultOptions[0], correctionResultConstants.reviewResultOptions[1]],
                        comments: "Another comment",
                        dateDocumentationReceived: "1/1/2019",
                        dateDocumentationReviewed: "1/1/2019",
                        dateCorrectionDemonstrated: "1/1/2019",
                        status: "Corrected"
                    }
                }
            },
            {
                monitoringAssessmentId: "1",
                correctiveActionPlanId: "DDDD",
                activityId: "106",
                activityNumber: 2,
                activitySummary: "DDDD-6 Stuff",
                resources: "Other resources",
                timelineStartDate: "1/18/2018",
                timelineEndDate: "1/18/2019",
                capActivityStatus: audit_outcomes.SUBMITTED.value,
                capActivityResultDto: {
                    dateCorrected: "1/18/2019",
                    results: "More results",
                    capActivityResultReview: {}
                }
            }
        ];
    }

    return correctiveActivities;
}

export function getComplianceReassessments() {
    if (complianceReassessment === null) {
        const assessments = getComplianceAssessments();
        complianceReassessment = {
            monitoringAssessmentId: "1",
            indicatorRequirements: assessments.find(f => f.monitoringAssessmentId === "1").indicatorRequirements,
            district: "Alachua",
            districtId: "1",
            dateWindowId: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
            protocolsToBeReassessed: [
                {
                    protocolId: "HH-1",
                    standardDescription: "There is an annual medical statement from a licensed physician that includes all required components.",
                    publicIdentifier: "HH-1",
                    indicatorTitle: "Homebound or Hospitalized",
                    isMatrix: false,
                    citation: "(Rule 6A-6.03020(4)(a), F.A.C.)",
                    subQuestionDirections: "The minimum evaluation for a student to determine eligibility is an annual medical statement from a licensed physician that includes all of the following required components:",
                    districtResponseOptions: [],
                    stateResponseOptions: [],
                    subQuestions: [
                        {
                            text: "A description of the disabling condition or diagnosis with any medical implications for instruction",
                            districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                            stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }]
                        },
                        {
                            text: "A statement that the student is unable to attend school",
                            districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                            stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }]
                        },
                        {
                            text: "A description of the plan of treatment",
                            districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                            stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }]
                        },
                        {
                            text: "Recommendations regarding school reentry",
                            districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                            stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }]
                        },
                        {
                            text: "The estimated duration of the condition or prognosis",
                            districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                            stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }]
                        }
                    ]
                },
                {
                    protocolId: "M-1",
                    standardDescription: "Domain A - Curriculum and Learning Environment",
                    publicIdentifier: "M-1",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    stateResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-2",
                    standardDescription: "Domain B - Social/Emotional Behavior",
                    publicIdentifier: "M-2",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    stateResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-3",
                    standardDescription: "Domain C - Independent Functioning",
                    publicIdentifier: "M-3",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    stateResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-4",
                    standardDescription: "Domain D - Health Care",
                    publicIdentifier: "M-4",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    stateResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-5",
                    standardDescription: "Domain E - Communication",
                    publicIdentifier: "M-5",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    stateResponseOptions: [{ text: "1", value: "1" }, { text: "2", value: "2" }, { text: "3", value: "3" }, { text: "4", value: "4" }, { text: "5", value: "5" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-6",
                    standardDescription: "Add 13 points - Student is eligible for the homebound or hospitalized program and is receiving individual instruction at home or at a hospital",
                    publicIdentifier: "M-6",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "13" }],
                    stateResponseOptions: [{ text: null, value: "13" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-7",
                    standardDescription: "Add 13 points - Prekindergarten child with a disability and is being served in the home or hospital on a one-to-one basis",
                    publicIdentifier: "M-7",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "13" }],
                    stateResponseOptions: [{ text: null, value: "13" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-8",
                    standardDescription: "Add 3 points - Prekindergarten student earning less than .5 FTE during an FTE survey period",
                    publicIdentifier: "M-8",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "3" }],
                    stateResponseOptions: [{ text: null, value: "3" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-9",
                    standardDescription: "Add 3 points - Student is identified with a visual impairment or dual-sensory impairment",
                    publicIdentifier: "M-9",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "3" }],
                    stateResponseOptions: [{ text: null, value: "3" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-10",
                    standardDescription: "Add 1 point - Student has a score of 17 total points and is rated Level 5 in three of the five domains above",
                    publicIdentifier: "M-10",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "1" }],
                    stateResponseOptions: [{ text: null, value: "1" }],
                    subQuestions: []
                },
                {
                    protocolId: "M-11",
                    standardDescription: "Add 1 point - Student has a score of 21 total points and is rated Level 5 in four of the five domains above",
                    publicIdentifier: "M-11",
                    indicatorTitle: "Matrix of Service",
                    isMatrix: true,
                    citation: null,
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: null, value: "1" }],
                    stateResponseOptions: [{ text: null, value: "1" }],
                    subQuestions: []
                },
                {
                    protocolId: "T16-1",
                    standardDescription: "The notice of the IEP team meeting included a statement that a purpose of the meeting was the consideration of postsecondary goals and transition services, that the student would be invited, and identified any agency that would be invited to send a representative.",
                    publicIdentifier: "T16-1",
                    indicatorTitle: "SPP 13 – Secondary Transition Age 16",
                    isMatrix: false,
                    citation: "(34 CFR §300.322(b)(2))",
                    subQuestionDirections: null,
                    districtResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }],
                    stateResponseOptions: [{ text: responseOptions.YES, value: responseOptions.YES }, { text: responseOptions.NO, value: responseOptions.NO }, { text: responseOptions.NV, value: responseOptions.NV }],
                    subQuestions: []
                }
            ],
            protocolResponsesToBeReassessed: [
                {
                    studentRecordId: "T16-Alachua-2",
                    studentRecordName: "T16-Alachua-2",
                    protocolResponseId: "PR-1",
                    protocolId: "T16-1",
                    response: responseOptions.YES,
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "T16-Alachua-2",
                    studentRecordName: "T16-Alachua-2",
                    protocolResponseId: "PR-26-1",
                    protocolId: "T16-1",
                    response: responseOptions.NO,
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "T16-Alachua-3",
                    studentRecordName: "T16-Alachua-3",
                    protocolResponseId: "PR-14",
                    protocolId: "T16-1",
                    response: responseOptions.YES,
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "T16-Alachua-1",
                    studentRecordName: "T16-Alachua-1",
                    protocolResponseId: "PR-26",
                    protocolId: "T16-1",
                    response: responseOptions.NO,
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "T16-Alachua-1",
                    studentRecordName: "T16-Alachua-1",
                    protocolResponseId: "PR-27",
                    protocolId: "T16-1",
                    response: responseOptions.YES,
                    responseType: responseTypes.ASSESS,
                    dateCreated: "2019-05-07T17:00:55.2721698Z",
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-2",
                    protocolId: "M-11",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-3",
                    protocolId: "M-7",
                    response: "13,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-4",
                    protocolId: "M-10",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-5",
                    protocolId: "M-6",
                    response: "13,13",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-6",
                    protocolId: "M-1",
                    response: "1,2",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-7",
                    protocolId: "M-5",
                    response: "3,2",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-8",
                    protocolId: "M-9",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-9",
                    protocolId: "M-2",
                    response: "2,2",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-10",
                    protocolId: "M-3",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-11",
                    protocolId: "M-8",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-2",
                    studentRecordName: "M-Alachua-2",
                    protocolResponseId: "PR-12",
                    protocolId: "M-4",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "HH-Alachua-2",
                    studentRecordName: "HH-Alachua-2",
                    protocolResponseId: "PR-13",
                    protocolId: "HH-1",
                    response: responseOptions.YES,
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: [
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES
                    ]
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-15",
                    protocolId: "M-3",
                    response: "3,5",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-16",
                    protocolId: "M-7",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-17",
                    protocolId: "M-2",
                    response: "3,5",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-18",
                    protocolId: "M-11",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-19",
                    protocolId: "M-6",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-20",
                    protocolId: "M-8",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-21",
                    protocolId: "M-1",
                    response: "5,5",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-22",
                    protocolId: "M-9",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-23",
                    protocolId: "M-10",
                    response: "0,0",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-24",
                    protocolId: "M-4",
                    response: "3,2",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-3",
                    studentRecordName: "M-Alachua-3",
                    protocolResponseId: "PR-25",
                    protocolId: "M-5",
                    response: "4,4",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-28",
                    protocolId: "M-4",
                    response: "4",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-29",
                    protocolId: "M-3",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-30",
                    protocolId: "M-8",
                    response: "3,3",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-31",
                    protocolId: "M-10",
                    response: "",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-32",
                    protocolId: "M-2",
                    response: "1,2",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-33",
                    protocolId: "M-10",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-34",
                    protocolId: "M-8",
                    response: "",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-35",
                    protocolId: "M-9",
                    response: "3,3",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-36",
                    protocolId: "M-11",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-37",
                    protocolId: "M-2",
                    response: "4",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-38",
                    protocolId: "M-5",
                    response: "5",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-39",
                    protocolId: "M-6",
                    response: "13,13",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-40",
                    protocolId: "M-4",
                    response: "2,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-41",
                    protocolId: "M-3",
                    response: "5",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-42",
                    protocolId: "M-7",
                    response: "",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-43",
                    protocolId: "M-9",
                    response: "",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-44",
                    protocolId: "M-1",
                    response: "1,1",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-45",
                    protocolId: "M-11",
                    response: "",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-46",
                    protocolId: "M-5",
                    response: "2,3",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-47",
                    protocolId: "M-7",
                    response: "13,13",
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-48",
                    protocolId: "M-1",
                    response: "4",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "M-Alachua-1",
                    studentRecordName: "M-Alachua-1",
                    protocolResponseId: "PR-49",
                    protocolId: "M-6",
                    response: "13",
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: []
                },
                {
                    studentRecordId: "HH-Alachua-1",
                    studentRecordName: "HH-Alachua-1",
                    protocolResponseId: "PR-50",
                    protocolId: "HH-1",
                    response: responseOptions.YES,
                    responseType: responseTypes.ASSESS,
                    subQuestionResponses: [
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES,
                        responseOptions.YES
                    ]
                },
                {
                    studentRecordId: "HH-Alachua-1",
                    studentRecordName: "HH-Alachua-1",
                    protocolResponseId: "PR-51",
                    protocolId: "HH-1",
                    response: responseOptions.NO,
                    responseType: responseTypes.VALIDATION,
                    subQuestionResponses: [
                        responseOptions.NO,
                        responseOptions.NV,
                        responseOptions.NV,
                        responseOptions.NV,
                        responseOptions.NV
                    ]
                }
            ]
        };
    }

    return complianceReassessment;
}

function saveComplianceAssessments(newComplianceAssessmentList) {
    complianceAssessments = newComplianceAssessmentList;
}

export {saveComplianceAssessments};

function saveComplianceReassessment(newComplianceReassessment) {
    complianceReassessment = newComplianceReassessment;
}

export {saveComplianceReassessment};

function saveStudentProtocolCorrectiveResults(newStudentProtocolCorrectiveResults) {
    studentProtocolCorrectiveResults = newStudentProtocolCorrectiveResults;
}

export {saveStudentProtocolCorrectiveResults};

function saveMatrixProtocolCorrectiveResults(newMatrixProtocolCorrectiveResults) {
    matrixProtocolCorrectiveResults = newMatrixProtocolCorrectiveResults;
}

export {saveMatrixProtocolCorrectiveResults};