import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {StateComplaintSections} from "../drStateComplaintConstants";
import api from "../drStateComplaintsApi";
import DrStateComplaintClosureProcessView from "./DrStateComplaintClosureProcessView";

const DrStateComplaintClosureProcessContainer = ({
	                                                 actions,
	                                                 complaint,
	                                                 handleUpdateComplaint,
	                                                 handleDataChanged,
													 handleSelectSection
                                                 }) => {

	const [closureProcessRequirements, setClosureProcessRequirements] = useState([]);

	const handleCreateClosureProcess = () => {
		handleDataChanged(false);
		actions.executeApi(api.createClosureProcess, [complaint.id])
			.then((result) => handleUpdateComplaint(result));
	};

	const handleRemoveClosureProcess = (event) => {
		event.preventDefault();
		if (!confirm("Are you sure you want to remove all of the closure letter process data? By doing so, it will make the complaint editable again.\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
			return;

		handleDataChanged(false);
		actions.executeApi(api.removeClosureProcess, [complaint.id])
			.then((result) => {
				handleUpdateComplaint(result);
				setClosureProcessRequirements([]);
			});
	};

	const handleSaveClosureProcess = (event) => {
		event.preventDefault();
		handleDataChanged(false);

		actions.executeApi(api.saveClosureProcess, [complaint.id, closureProcessRequirements])
			.then((result) => handleUpdateComplaint(result));
	};

	const handleClickCancel = (event) => {
		handleSelectSection(event, StateComplaintSections.Closure, true);
	};

	const handleChangeCompletionDate = (closureProcessRequirementType, dateCompleted) => {
		handleDataChanged();
		let updatedState = [...closureProcessRequirements];
		let updatedRequirement = updatedState.filter(d => d.closureProcessRequirementType === closureProcessRequirementType)[0];
		if (updatedRequirement) {
			updatedRequirement.dateCompleted = dateCompleted;
			setClosureProcessRequirements(updatedState);
		}
	};

	useEffect(() => {
		if (!complaint || !complaint.closureProcessRequirementModels) return;
		const closureProcessRequirementModels = complaint.closureProcessRequirementModels;
		if (!isArrayNullOrEmpty(closureProcessRequirementModels) && closureProcessRequirements !== closureProcessRequirementModels)
			setClosureProcessRequirements(closureProcessRequirementModels);
	}, [[complaint]]);

	if (!complaint) return null;

	return <DrStateComplaintClosureProcessView
		complaint={complaint}
		closureProcessRequirements={closureProcessRequirements}
		handleChangeCompletionDate={handleChangeCompletionDate}
		handleClickCancel={handleClickCancel}
		handleCreateClosureProcess={handleCreateClosureProcess}
		handleDataChanged={handleDataChanged}
		handleRemoveClosureProcess={handleRemoveClosureProcess}
		handleSaveClosureProcess={handleSaveClosureProcess}
	/>;
};

DrStateComplaintClosureProcessContainer.propTypes = {
	actions: PropTypes.object,
	complaint: PropTypes.object.isRequired,
	handleUpdateComplaint: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleSelectSection: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => {
	const combinedActions = Object.assign({}, apiForLocalStateActions);
	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
};

export default connect(
	null,
	mapDispatchToProps
)(DrStateComplaintClosureProcessContainer);