import PropTypes from 'prop-types';
import React from "react";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import Tile from "../../../components/common/Tiles/Tile";
import TileContainer from "../../../components/common/Tiles/TileContainer";
import audit_outcomes from "../../../constants/auditOutcomes";
import {newActionId} from "../drDueProcessConstants";
import * as drObjectFactory from '../drDueProcessObjectFactory';
import DrDueProcessActionComponent from "./DrDueProcessActionComponent";
import DrDueProcessActionComponentEdit from "./DrDueProcessActionComponentEdit";
import DrDueProcessActionResponseAuditComponent from "./DrDueProcessActionResponseAuditComponent";
import DrDueProcessActionResponseReview from "./DrDueProcessActionResponseReview";

const DrDueProcessActionView = ({
                                    actionModels,
                                    canAudit,
                                    canEditAction,
                                    canRespond,
                                    canStateReview,
                                    canSubmit,
                                    editActionId,
                                    editResponseId,
                                    handleAuditResponseAsync,
                                    handleDeleteAction,
                                    handleRemoveResponseReviewAsync,
                                    handleSaveActionAsync,
                                    handleSaveAuditCommentAsync,
                                    handleSaveResponseAsync,
                                    handleSaveResponseReviewAsync,
                                    handleSubmitResponseAsync,
                                    handleDataChanged,
                                    isDistrictUser,
                                    isEditMode,
                                    setEditActionId,
                                    setEditResponseId
                                }) => {

    const noActions = isArrayNullOrEmpty(actionModels);
    const showCancel = !noActions;

    const handleCancel = () => {
        handleDataChanged(false);
        setEditActionId('');
        setEditResponseId('');
    };

    return (
        <>
            {
                !noActions &&
                actionModels.map((act, key) => {
                    const action = drObjectFactory.createActionObject(act);
                    const isEditing = editActionId === action.id;
                    return <section
                        className="dueProcessAction"
                        key={key}
                    >
                        <h2>
                            Corrective Action {key + 1}
                        </h2>
                        {
                            canEditAction &&
                            isEditing ?
                                <DrDueProcessActionComponentEdit
                                    action={action}
                                    handleCancel={handleCancel}
                                    handleSaveActionAsync={handleSaveActionAsync}
                                    handleDataChanged={handleDataChanged}
                                />
                                :
                                <DrDueProcessActionComponent
                                    action={action}
                                    canAudit={canAudit}
                                    canEditAction={canEditAction && action.districtResponse.currentStatus !== audit_outcomes.ACCEPTED.value}
                                    canRespond={canRespond && !action.isSubmittedToState}
                                    canSubmit={canSubmit}
                                    isEditing={isEditing}
                                    isEditMode={isEditMode}
                                    handleAuditResponseAsync={handleAuditResponseAsync}
                                    handleCancel={handleCancel}
                                    handleDataChanged={handleDataChanged}
                                    handleDeleteAction={handleDeleteAction}
                                    handleEdit={setEditActionId}
                                    handleSaveResponseAsync={handleSaveResponseAsync}
                                    handleSubmitResponseAsync={handleSubmitResponseAsync}
                                    isDistrictUser={isDistrictUser}
                                />
                        }

                        <DrDueProcessActionResponseReview
                            response={action.districtResponse}
                            canStateReview={canStateReview && action.isSubmittedToState && action.districtResponse.currentStatus !== audit_outcomes.ACCEPTED.value}
                            editResponseId={editResponseId}
                            handleClickCancel={handleCancel}
                            handleEdit={setEditResponseId}
                            handleRemoveResponseReviewAsync={handleRemoveResponseReviewAsync}
                            handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
                            isDistrictUser={isDistrictUser}
                            isSubmitted={action.isSubmittedToState || action.districtResponse.currentStatus === audit_outcomes.NEEDS_REVISION.value}
                        />

                        <DrDueProcessActionResponseAuditComponent
							actionId={action.id}
                            audits={action.districtResponse.responseAudits}
                            canAudit={canAudit}
                            isDistrictUser={isDistrictUser}
                            handleDataChanged={handleDataChanged}
                            handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
                        />

                    </section>;
                })
            }
            {
                !noActions &&
                !isEditMode &&
                canEditAction &&
                editActionId !== newActionId &&
                <TileContainer rowTilesLarge="1" rowTilesMedium="1">
                    <Tile body={"Add Corrective Action"}
                          onClick={() => setEditActionId(newActionId)}
                          isNew={true}/>
                </TileContainer>
            }
            {
                (
                    noActions ||
                    editActionId === newActionId
                ) &&
                canEditAction &&
                <DrDueProcessActionComponentEdit
                    action={drObjectFactory.createActionObject()}
                    handleCancel={handleCancel}
                    handleDataChanged={handleDataChanged}
                    handleSaveActionAsync={handleSaveActionAsync}
                    showCancel={showCancel}
                />
            }
            {
                noActions && !canEditAction &&
                <p><strong>No corrective actions have been created yet.</strong></p>
            }
        </>
    );
};

DrDueProcessActionView.propTypes = {
    actionModels: PropTypes.array.isRequired,
    canAudit: PropTypes.bool.isRequired,
    canEditAction: PropTypes.bool.isRequired,
    canRespond: PropTypes.bool.isRequired,
    canStateReview: PropTypes.bool.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    editActionId: PropTypes.string.isRequired,
    editResponseId: PropTypes.string.isRequired,
    handleAuditResponseAsync: PropTypes.func.isRequired,
    handleDeleteAction: PropTypes.func.isRequired,
    handleRemoveResponseReviewAsync: PropTypes.func.isRequired,
    handleSaveActionAsync: PropTypes.func.isRequired,
    handleSaveAuditCommentAsync: PropTypes.func.isRequired,
    handleSaveResponseAsync: PropTypes.func.isRequired,
    handleSaveResponseReviewAsync: PropTypes.func.isRequired,
    handleSubmitResponseAsync: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    setEditActionId: PropTypes.func.isRequired,
    setEditResponseId: PropTypes.func.isRequired
};

export default DrDueProcessActionView;