import React from 'react';
import PropTypes from 'prop-types';
import InputLayout from './InputLayout';
import MaskInput from 'react-maskinput';
import {createFakeEvent} from "./inputUtility";

const MaskInputField = ({
                            disabled,
                            error,
                            inputClass,
                            label,
                            labelClass,
                            mask, // default mask, i.e. "00-0000"
                            maskChar = "_", // what to replace mask characters with, i.e. "__-____"
                            maskFormat, // call createMaskFormat to help generate each object in array, redefines what each character is
                            maskString = "", // overrides maskChar, i.e. "YY-####" for "00-0000"
                            name,
                            onChange,
                            showLabel = true,
                            showMask = true,
                            value = "",
                            wrapperClass,
                            columnsSmall,
                            columnsMedium,
                            columnsLarge
                        }) => {

    // More details - https://github.com/xnimorz/masked-input/tree/master/packages/react-maskinput
    // Mask Props Not Used - Size, Placeholder, showMask
    // mask -
    //     0 — any number 0-9
    //     * — any symbol
    //     a — A-Z, a-z
    //     q — "q" letter, 2 — "2" letter etc.
    //    \a — "a" letter
    // default is undefined

    const changeMaskInput = (data) => {
        const event = createFakeEvent(data.value, name);
        onChange(event)
    }

    const size = maskString.length;

    return (
        <InputLayout name={name}
                     label={label}
                     showLabel={showLabel}
                     error={error}
                     wrapperClass={wrapperClass}
                     labelClass={labelClass}
                     inputClass={inputClass}
                     disabled={disabled}
                     small={columnsSmall}
                     medium={columnsMedium}
                     large={columnsLarge}>
            {
                disabled &&
                <div className={`input input--mock`}>{value}</div>
            }
            {
                !disabled &&
                <MaskInput
                    alwaysShowMask={showMask}
                    maskChar={maskChar}
                    mask={mask}
                    maskFormat={maskFormat}
                    maskString={maskString}
                    name={name}
                    size={size}
                    type={"text"}
                    value={value}
                    onValueChange={changeMaskInput}
                />
            }
        </InputLayout>
    );
};

MaskInputField.propTypes = {
    columnsSmall: PropTypes.number,
    columnsMedium: PropTypes.number,
    columnsLarge: PropTypes.number,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    inputClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    mask: PropTypes.string.isRequired,
    maskChar: PropTypes.string,
    maskFormat: PropTypes.array,
    maskString: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showLabel: PropTypes.bool,
    showMask: PropTypes.bool,
    useInputTheme: PropTypes.bool,
    value: PropTypes.string,
    wrapperClass: PropTypes.string
};

export default MaskInputField;