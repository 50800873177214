import userApi from "../api/UserApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

export function loadBehaviorsSuccess(behaviors){
    return {type: actionTypes.LOAD_BEHAVIORS_AJAX_SUCCESS, behaviors};
}

export function loadBehaviors(){
    return dispatch => {
        dispatch(beginAjaxCall());

        return userApi.getBehaviors()
            .then(behaviors =>
            {
                dispatch(loadBehaviorsSuccess(behaviors));
            })
            .catch(error =>
            {
                dispatch(ajaxCallError(error));
                throw (error);
            }
        );
    };
}