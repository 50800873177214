import React from 'react';
import PropTypes from 'prop-types';
import * as elementTypes from "../../constants/spp/sppElementTypes";
import TextField from "../../components/common/inputs/TextField";
import TextReadOnly from "../../components/common/inputs/TextReadOnly";
import CheckBoxField from "../../components/common/inputs/CheckBoxField";
import FileUploadField from "../../components/common/inputs/FileUploadField";
import FileViewDelete from "../../components/common/inputs/FileViewDelete";
import SelectField from "../../components/common/inputs/SelectField";
import RadioField from "../../components/common/inputs/RadioField";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import SppElementContainer from "./SppElementOutcome";
import SppElementReviewOutcome from "../sppReview/SppElementReviewOutcome";
import AuditHistory from "../audit/AuditHistory";
import LocalStorage from "../shared/LocalStorage";
import AccountUtility from "../../api/AccountUtility";

const SppElement = ({
	                    element,
	                    response,
	                    handleFormChange,
	                    fileOnView,
	                    fileOnDelete,
	                    outcomeChange,
	                    handleCommentChange,
	                    onCommentEdit,
	                    onCommentSave,
	                    onCommentCancel,
	                    onCommentCreate,
	                    editItemId,
	                    isInEditMode,
	                    isLoading
                    }) => {
	let options;

	let isReviewer = false;
	const isLoggedIn = LocalStorage.isLoggedIn();
	if (isLoggedIn)
		isReviewer = !AccountUtility.isDistrictUser();

	let disabled = false;
	if ((response !== undefined &&
		response.audits.length > 0 &&
		response.audits[0].isLocked) || isReviewer) {
		disabled = true;
	}

	let inputField = <div/>;

	if (disabled && (element.layout === elementTypes.TextBox || element.layout === elementTypes.TextArea)) {
		inputField = (
			<TextReadOnly
				key={element.id}
				label={element.text}
				name={element.id}
				value={response.data}
			/>
		);
	}
	else if (element.layout === elementTypes.TextBox) {
		inputField = (
			<TextField
				key={element.id}
				label={element.text}
				name={element.id}
				onChange={handleFormChange}
				showLabel
				value={response.data}
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.TextArea) {
		inputField = (
			<TextAreaEditor
				key={element.id}
				label={element.text}
				name={element.id}
				onChange={handleFormChange}
				showLabel
				value={response.data}
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.Display) {
		inputField = (
			<HtmlDisplayBlock
				name={element.id}
				html={element.text}
			/>
		);
	}
	else if (element.layout === elementTypes.Checkbox) {
		inputField = (
			<CheckBoxField
				key={element.id}
				label={element.text}
				onChange={handleFormChange}
				name={element.id}
				checked={(response.data.toString() === "true")}
				showLabel
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.File) {
		if (response.data === "" && isLoggedIn)
			inputField = (
				<FileUploadField
					key={element.id}
					name={element.id}
					legend={element.text}
					onChange={handleFormChange}
					disabled={disabled}
				/>
			);
		else
			inputField = (
				<FileViewDelete
					key={element.id}
					name={element.id}
					legend={element.text}
					onView={fileOnView}
					onDelete={fileOnDelete}
					disabled={disabled}
				/>
			);
	}
	else if (element.layout === elementTypes.DropDown) {
		options = JSON.parse(element.value);
		inputField = (
			<SelectField
				key={element.id}
				name={element.id}
				label={element.text}
				onChange={handleFormChange}
				showLabel
				value={response.data}
				includeDefaultOption
				options={options}
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.Radio) {
		options = JSON.parse(element.value);
		inputField = (
			<RadioField
				key={element.id}
				name={element.id}
				legend={element.text}
				onChange={handleFormChange}
				options={options}
				value={response.data}
				disabled={disabled}
			/>
		);
	}

	if (element.layout === elementTypes.Display) {
		return (
			<div className="element">
				{inputField}
			</div>
		);
	}
	else if (isReviewer) {
		let policyResponseId = "";
		if (response !== undefined &&
			response.policyResponseId !== undefined) {
			policyResponseId = response.policyResponseId;
		}

		return (
			<div className="element">
				<SppElementReviewOutcome
					onOutcomeChange={outcomeChange}
					policyResponseId={policyResponseId}
					audits={response.audits}
				>
					{inputField}
				</SppElementReviewOutcome>
				<AuditHistory
					audits={response.audits}
					onCommentCancel={onCommentCancel}
					onCommentSave={onCommentSave}
					onCommentEdit={onCommentEdit}
					handleCommentChange={handleCommentChange}
					editItemId={editItemId}
					isInEditMode={isInEditMode}
					isLoading={isLoading}
					onCommentCreate={onCommentCreate}
					parentIdPropName={"policyResponseId"}
				/>
			</div>
		);
	}
	else {
		return (
			<div className="element">
				<SppElementContainer response={response}>
					{inputField}
				</SppElementContainer>
				{isLoggedIn &&
				<AuditHistory
					audits={response.audits}
				/>
				}
			</div>

		);
	}
};

SppElement.propTypes = {
	element: PropTypes.object.isRequired,
	response: PropTypes.object,
	handleFormChange: PropTypes.func,
	fileOnView: PropTypes.func,
	fileOnDelete: PropTypes.func,
	outcomeChange: PropTypes.func,
	handleCommentChange: PropTypes.func,
	onCommentEdit: PropTypes.func,
	onCommentSave: PropTypes.func,
	onCommentCancel: PropTypes.func,
	editItemId: PropTypes.string,
	onCommentCreate: PropTypes.func,
	isInEditMode: PropTypes.bool,
	isLoading: PropTypes.bool
};

SppElement.defaultProps = {
	isReviewer: false
};

export default SppElement;