import PropTypes from 'prop-types';
import React, {useEffect, useState} from "react";
import api from "../../../api/MonitoringComplianceReportsApi";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import PrintTable from "../../../components/common/PrintTable";
import HtmlDisplayBlock from "../../../components/htmlDisplay/HtmlDisplayBlock";
import {fetchDate} from "../../../components/shared/sharedDataUtilities";

const DistrictSummaryPrintView = ({
	                                  actions,
	                                  dateWindowId,
	                                  districtId,
	                                  dateWindows
                                  }) => {
	const [districtSummaryInfo, setDistrictSummaryInfo] = useState(null);

	const handleUpdateDistrictSummaryInfo = (districtSummaryInfo) => {
		setDistrictSummaryInfo(districtSummaryInfo);
	};

	const getDistrictSummaryInfo = async () => {
		await actions.executeApi(api.GetDistrictSummaryReport, [districtId, dateWindowId])
			.then((results) => handleUpdateDistrictSummaryInfo(results));
	};

	useEffect(() => {
		getDistrictSummaryInfo();
	}, []);

	if (!districtSummaryInfo)
		return null;

	return (
		<div className={"compliance-print-report"}>
			{
				districtSummaryInfo.districtName === "" &&
				<p className={"no-report"}>No report available for this district.</p>
			}
			{
				districtSummaryInfo.districtName !== "" &&
				<>
					<div className={"report-header"}>
						<h2>{fetchDate(dateWindows, dateWindowId)} Self-Assessment</h2>
						<h2>Findings of Noncompliance by Standard — {districtSummaryInfo.districtName} District
							Report</h2>
					</div>
					<div className={"report-summary"}>
						<p>This report provides a summary of the district`s results and must be used when developing
							corrective actions. See the <em>Student Report: Findings of Noncompliance</em> for
							student-specific findings. Results are reported by standard, and are based on the following:
						</p>
						<GridRow>
							<GridColumn medium={"6"}>

								{
									districtSummaryInfo.indicators.map((info, index) => {
										return (
											<ul key={index}>
												<li>Number of {info.title} protocols completed: {info.totalRecords}</li>
												<li>Number of standards
													per {info.title} protocol: {info.totalStandards}</li>
											</ul>
										);
									})
								}
							</GridColumn>
							<GridColumn medium={"6"}>
								<ul>
									<li>Total number of protocols: {districtSummaryInfo.totalProtocols}</li>
									<li>Total number of standards: {districtSummaryInfo.totalStandards}</li>
									<li>Total number of findings of noncompliance
										(NC): {districtSummaryInfo.totalFindingsOfNonCompliance}</li>
									<li>Overall % findings of
										noncompliance: {districtSummaryInfo.percentNonCompliance}</li>
								</ul>

								<ul>
									<li>Total number of different standards
										assessed: {districtSummaryInfo.totalUniqueStandards}</li>
									<li>Total number of different standards for which noncompliance was
										identified: {districtSummaryInfo.totalUniqueFindingsOfNonCompliance}</li>
									<li>% of different standards for which noncompliance was
										identified: {districtSummaryInfo.percentUniqueNonCompliance}</li>
								</ul>
							</GridColumn>
						</GridRow>
						<p>Percent of noncompliance is calculated as the # of findings of noncompliance for a given
							standard divided by the # of protocols reviewed for that standard, multiplied by 100.</p>
						<p><strong>* Correctable for the student(s):</strong> A finding which requires immediate
							action(s) to correct the noncompliance</p>
						<p><strong>** Ensure future compliance:</strong> For findings which cannot be corrected for
							individual students, corrective actions are required to
							address how the district will ensure future compliance</p>
					</div>
					<PrintTable>
						<thead>
						<tr>
							<th colSpan={"2"}>Noncompliance (NC)</th>
							<th>*Correctable for the Student(s)</th>
							<th>**Ensure Future Compliance</th>
							<th># NC</th>
							<th>% NC</th>
						</tr>
						</thead>
						<tbody>
						{
							districtSummaryInfo.nonComplianceSummaries.map((record, index) => {
								const rowClass = index % 2 === 1 ? "odd" : "";
								return (
									<tr key={`${index}`} className={rowClass}>
										<td valign={"top"} className={"response-no-wrap"}>{record.protocol}</td>
										<td><HtmlDisplayBlock html={`${record.standardDescription}<br/>${record.citation}`}/>
										</td>
										<td className={"text-center"} valign={"top"}>{record.correctable}</td>
										<td className={"text-center"}
										    valign={"top"}>{record.ensuresFutureCompliance}</td>
										<td className={"text-center"} valign={"top"}>{record.numberNonCompliance}</td>
										<td className={"text-center"} valign={"top"}>{record.percentNonCompliance}</td>
									</tr>
								);
							})
						}
						</tbody>
					</PrintTable>
				</>
			}
		</div>
	);
};

DistrictSummaryPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	districtId: PropTypes.string.isRequired,
	dateWindows: PropTypes.array.isRequired
};

export default DistrictSummaryPrintView;