import React from 'react';
import PropTypes from 'prop-types';

const PrintTable = ({
                        caption = null,
                        className = "",
                        children,
                        id = ""
                    }) => {
    return (
        <div className="table-scroll" id={id}>
            <table className={"table-print " + className}>
                {
                    caption &&
                    <caption>{caption}</caption>
                }
                {children}
            </table>
        </div>
    );
};

PrintTable.propTypes = {
    caption: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
    ]),
    className: PropTypes.string,
    id: PropTypes.string
};

export default PrintTable;