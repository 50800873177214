import {isNullOrUndefined} from "../../../components/common/commonUtilities";

export function getIndicatorAndResponse(indicators, responses, indicatorNumber) {
    const indicator = indicators.find(f => f.indicatorNumber === indicatorNumber);

    if (!indicator)
        return {};

    return {
        indicator: indicator,
        response: getResponse(responses, indicator.bpieIndicatorId)
    };
}

export function getResponse(responses, bpieIndicatorId) {
    const response = responses.find(f => f.bpieIndicatorId === bpieIndicatorId);
    return createBpieResponse(
        {...response, bpieIndicatorId: bpieIndicatorId}
    );
}

export const createBpieResponse = ({
                                       bpieIndicatorId,
                                       implementationStatus,
                                       isPriority,
                                       supportingEvidence
                                   } = {}) => (
    {
        bpieIndicatorId: bpieIndicatorId || "",
        implementationStatus: implementationStatus || null,
        isPriority: isNullOrUndefined(isPriority) ? false : isPriority,
        supportingEvidence: supportingEvidence || ""
    }
);

export const createBpieIndicator = ({
                                        bpieIndicatorId,
                                        description,
                                        evidence,
                                        implementationStatusOptions,
                                        indicatorDomainType,
                                        indicatorNumber,
                                        suggestedMeasures,
                                        summary
                                    } = {}) => (
    {
        bpieIndicatorId: bpieIndicatorId || "0",
        description: description || "",
        evidence: evidence || "",
        implementationStatusOptions: !implementationStatusOptions ? [] : implementationStatusOptions.map(option => createImplementationStatusOption(option)),
        indicatorDomainType: indicatorDomainType || "",
        indicatorNumber: indicatorNumber || 1,
        suggestedMeasures: suggestedMeasures || "",
        summary: summary || ""
    }
);

const createImplementationStatusOption = ({
                                              isSupportingEvidenceEnabled,
                                              isSupportingEvidenceRequired,
                                              text,
                                              value
                                          } = {}) => ({
    isSupportingEvidenceEnabled: isNullOrUndefined(isSupportingEvidenceEnabled) ? true : isSupportingEvidenceEnabled,
    isSupportingEvidenceRequired: isNullOrUndefined(isSupportingEvidenceRequired) ? true : isSupportingEvidenceRequired,
    text: text || "",
    value: value || ""
});
