//MONITORING FOR PERFORMANCE
export const monitoring_performance = {
	DISTRICT: `districtName`
};

export const dispute_resolution = {
	COMPLAINT_EXTENSIONS: `extendedOrderSignedDate`
};

export const SortType = {
	Date: "Date"
};