import PropTypes from "prop-types";
import PrintTable from "../../../../components/common/PrintTable";
import React, {Fragment} from "react";
import SectionHeader from "../../../../components/common/SectionHeader";
import {DISCRETIONARY_PROJECT_QUARTERS_LIST} from "../../ptsConstants";
import {isArrayNullOrEmpty, isNumber} from "../../../../components/common/commonUtilities";
import {GetImplementationsPerQuarter} from "../../ptsUtilities";
import {ImplementationsPrintView} from "./ImplementationsPrintView";
import {SourceDocumentationPrintView} from "./SourceDocumentationPrintView";

const ActivitiesPrintView = ({activities, activitySectionClass, activityLabel, dateRangeType, handleFileDownload}) => {
    if (isArrayNullOrEmpty(activities)) {
        return null;
    }

    const quarter = DISCRETIONARY_PROJECT_QUARTERS_LIST.find(f => f.text === dateRangeType);

    return <section className={`activity-print ${activitySectionClass}`}>
        <div className={`section--header`}>
            <h3>{activityLabel}</h3>
        </div>
        <div>
            {
                activities.map((activity) => {
                    const implementationsByQuarter = GetImplementationsPerQuarter(activity.implementations);

                    return <Fragment key={`${activity.activityNumberOutput}_activity`}>
                        <SectionHeader>
                            <h4>
                                {activity.activityNumberOutput} - {activity.activityTitle}
                            </h4>
                        </SectionHeader>
                        <p className={`activity--description`}>
                            <b>Description:</b> {activity.activityDescription}
                            <SourceDocumentationPrintView
                                activityNumberOutput={activity.activityNumberOutput}
                                handleFileDownload={handleFileDownload}
                                isYearToDate={false}
                                quarter={quarter}
                                sourceDocumentationFiles={activity.sourceDocumentationFiles}
                            />
                        </p>

                        <PrintTable>
                            <thead className={`no-head-group`}>
                            <tr className="text-center">
                                <th rowSpan={2}>
                                    Total Deliverables Units Required (per year)
                                </th>
                                <th colSpan={4}>
                                    Projected Deliverable Units (per quarter)
                                </th>
                                <th colSpan={4}>
                                    Completed Deliverable Units (per quarter)
                                </th>
                            </tr>
                            <tr>
                                <th className={`text-center`}>1</th>
                                <th className={`text-center`}>2</th>
                                <th className={`text-center`}>3</th>
                                <th className={`text-center`}>4</th>
                                <th className={`text-center`}>1</th>
                                <th className={`text-center`}>2</th>
                                <th className={`text-center`}>3</th>
                                <th className={`text-center`}>4</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={`text-center`}>{activity.grantYearTotalUnits}</td>
                                {
                                    DISCRETIONARY_PROJECT_QUARTERS_LIST.map((q, index) => {
                                        return <td key={`${activity.activityNumberOutput}_quarters_targets_${index}`}
                                                   className={`text-center`}>{isNumber(activity.units[index]) ? activity.units[index] : 0}</td>;
                                    })
                                }
                                {
                                    DISCRETIONARY_PROJECT_QUARTERS_LIST.map((q, index) => {
                                        return <td key={`${activity.activityNumberOutput}_quarters_completed_${index}`}
                                                   className={`text-center`}>{implementationsByQuarter[q.id].reduce((a, b) => a + b.implementedUnits, 0)}</td>;
                                    })
                                }
                            </tr>
                            </tbody>
                        </PrintTable>

                        {
                            DISCRETIONARY_PROJECT_QUARTERS_LIST.map((quarter, index) => {
                                return (
                                    <ImplementationsPrintView
                                        key={`${activity.activityNumberOutput}_${quarter.id}_implementations_${index}`}
                                        activityNumberOutput={activity.activityNumberOutput}
                                        dateRangeType={dateRangeType}
                                        handleFileDownload={handleFileDownload}
                                        implementationsByQuarter={implementationsByQuarter}
                                        quarter={quarter}
                                        sourceDocumentationFiles={activity.sourceDocumentationFiles}
                                    />
                                );
                            })
                        }
                    </Fragment>;
                })
            }
        </div>
    </section>;
};

ActivitiesPrintView.propTypes = {
    activities: PropTypes.array,
    activitySectionClass: PropTypes.string.isRequired,
    activityLabel: PropTypes.string,
    dateRangeType: PropTypes.string,
    handleFileDownload: PropTypes.func.isRequired
};

export default ActivitiesPrintView;
