import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import api from "../drStateComplaintsApi";
import DrStateComplaintActionView from "./DrStateComplaintActionView";
import {generateSelectListFromArray} from "../../../components/common/commonUtilities";

const DrStateComplaintActionContainer = ({
	                                         complaint,
	                                         handleDataChanged,
	                                         handleDeleteAction,
	                                         handleSaveActionAsync,
											 handleScroll,
	                                         readOnly
                                         }) => {

	const [editActionId, setEditActionId] = useState('');
	const [studentExceptionalities, setStudentExceptionalities] = useState([]);

	useEffect(() => {
		api.getStudentExceptionalityTypes()
			.then((result) => setStudentExceptionalities(generateSelectListFromArray(result)));
	}, []);

	if (!complaint) return null;

	return <DrStateComplaintActionView
		actionModels={complaint.actionModels}
		dateOrdered={complaint.dateOrdered}
		editActionId={editActionId}
		handleDeleteAction={handleDeleteAction}
		handleSaveActionAsync={handleSaveActionAsync}
		handleScroll={handleScroll}
		handleDataChanged={handleDataChanged}
		isEditMode={!!editActionId}
		setEditActionId={setEditActionId}
		studentExceptionalities={studentExceptionalities}
		readOnly={readOnly}
	/>;
};

DrStateComplaintActionContainer.propTypes = {
	complaint: PropTypes.object.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleDeleteAction: PropTypes.func.isRequired,
	handleSaveActionAsync: PropTypes.func.isRequired,
	handleScroll: PropTypes.func.isRequired,
	readOnly: PropTypes.bool.isRequired
};

export default DrStateComplaintActionContainer;
