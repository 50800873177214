import {convertToArray} from "../../components/common/commonUtilities";
import {Status} from "./drMediationConstants";

export const createMediationSummaryObject = (
    districtId,
    districtName,
    mediationOpenCount,
    mediationClosedCount,
    mediationWithdrawnCount,
    mediationOverallCount,
    dateWindowId) =>
    ({
        districtId,
        districtName,
        mediationOpenCount,
        mediationClosedCount,
        mediationWithdrawnCount: mediationWithdrawnCount,
        mediationOverallCount,
        dateWindowId
    });

export const createMediationTile = ({
                                        body = "New Mediation",
                                        footer,
                                        header,
                                        isNew = false,
                                        key,
                                        url
                                    } = {}) => ({
    body,
    footer,
    header,
    isNew,
    key,
    url
});

export const createMediationObject = ({
                                          id,
                                          dateWindowId,
                                          districtId,
                                          mediationStatus,
                                          caseNumber,
                                          caseNumberCount,
                                          caseNumberYear,
                                          caseNumberType,
                                          dateRequested,
                                          requestedBy,
	                                      primaryExceptionality,

                                          dateMeetingHeld,
                                          agreement,

                                          interpreterNeeded,
                                          typeOfInterpreter,

                                          dateMeetingScheduled,
                                          placeMeetingScheduled,
                                          dateMediatorAssigned,
                                          mediatorName,

                                          filedStateComplaint,
                                          filedDueProcess,

                                          mediationRelatedToDueProcess,
                                          complaintId,
                                          agreementRelatedToDueProcess,

                                          comment
                                      } = {}) => ({
    id,
    dateWindowId,
    districtId,
    mediationStatus: mediationStatus ? mediationStatus : Status.Pending,

    caseNumber,
    caseNumberCount,
    caseNumberYear,
    caseNumberType,
    dateRequested: dateRequested ? dateRequested : undefined,
    requestedBy: requestedBy ? convertToArray(requestedBy) : [],
	primaryExceptionality: primaryExceptionality,

    dateMeetingHeld: dateMeetingHeld ? dateMeetingHeld : undefined,
    agreement: agreement ? agreement : "",

    interpreterNeeded: interpreterNeeded ? interpreterNeeded : undefined,
    typeOfInterpreter: typeOfInterpreter ? typeOfInterpreter : "",

    dateMeetingScheduled: dateMeetingScheduled? dateMeetingScheduled: undefined,
    placeMeetingScheduled: placeMeetingScheduled ? placeMeetingScheduled :  "",
    dateMediatorAssigned: dateMediatorAssigned ? dateMediatorAssigned : undefined,
    mediatorName: mediatorName ? mediatorName : "",

    filedStateComplaint: filedStateComplaint ? filedStateComplaint : undefined,
    filedDueProcess: filedDueProcess ? filedDueProcess : undefined,

    mediationRelatedToDueProcess: mediationRelatedToDueProcess ? mediationRelatedToDueProcess :  undefined,
    complaintId: complaintId ? complaintId : undefined,
    agreementRelatedToDueProcess: agreementRelatedToDueProcess ? agreementRelatedToDueProcess :  undefined,

    comment: comment ? comment : ""
});