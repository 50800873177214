import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import ResultsPager from "../../../components/common/pagers/ResultsPager";
import {handleScroll} from "../../../components/common/scrollUtilities";
import * as ptsLocations from "../ptsLocations";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {PtsPolicy, policyEvents as ptsPolicyEvents} from "../../../components/authorization/policies/PtsPolicy";
import * as policyEvents from "../../../constants/policyEvents";

export const PtsSummarySearchResults = ({
                                            actions,
                                            handleClickOrder,
                                            handleDeletePtsProject,
                                            history,
                                            isStateUser,
                                            searchCriteria,
                                            summary
                                        }) => {
    const allowEditImplementation = AuthorizationUtilities.allow(PtsPolicy, ptsPolicyEvents.IMPLEMENTATIONS.edit);
    const canDelete = isStateUser && AuthorizationUtilities.allow(PtsPolicy, policyEvents.MANAGE);
    const containerId = "pts-admin-summary";

    const first = (event) => {
        event.preventDefault();
        moveToFirstPage();
    };

    const moveToFirstPage = () => {
        let criteria = {...searchCriteria};
        criteria.First();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const last = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Last(summary.length);
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const next = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Next();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const previous = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Previous();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    };

    const handleViewProjectInformationClick = (event, dateWindowId, discretionaryProjectId) => {
        event.preventDefault();
        const resolvedPath = ptsLocations.PTS_PROJECT_INFORMATION.getUrl(dateWindowId, discretionaryProjectId);
        history.push(resolvedPath);
    };

    const handleNavigateToDeliverablesSummaryClick = (event, dateWindowId, discretionaryProjectId) => {
        event.preventDefault();
        const resolvedPath = ptsLocations.PTS_DELIVERABLES_SUMMARY.getUrl(dateWindowId, discretionaryProjectId);
        history.push(resolvedPath);
    };

    const handleNavigateToReportsClick = (event, dateWindowId, discretionaryProjectId) => {
        event.preventDefault();
        const resolvedPath = ptsLocations.PTS_REPORTS.getUrl(dateWindowId, discretionaryProjectId);
        history.push(resolvedPath);
    };

    const handleDeleteProjectClick = (event, discretionaryProjectId) => {
        event.preventDefault();

        handleDeletePtsProject(discretionaryProjectId);
    };

    useEffect(() => {
        if (!searchCriteria.onValidPage(summary.length))
            moveToFirstPage();

    }, [summary.length]);

    return (
        <GridTable id={containerId}>
            <thead>
            <tr>
                <th onClick={handleClickOrder} data-id="datesCovered" className="is-clickable">
                    Date Window
                </th>
                <th onClick={handleClickOrder} data-id="projectName" className="is-clickable">
                    Project
                </th>
                <th onClick={handleClickOrder} data-id={`discretionaryProjectStatusDescription`}
                    className={`is-clickable`}>
                    Status
                </th>
                <th>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            {
                isArrayNullOrEmpty(summary) &&
                <tr>
                    <td colSpan={4} className={"center"}>
                        No results match the search criteria specified.
                    </td>
                </tr>
            }
            {
                searchCriteria.GetCurrentPageRecords(summary).map((project, key) => {
                        const canEdit = allowEditImplementation && !project.isCompleted;
                        const projectNameAndDates = `${project.projectName} ${project.datesCovered}`;
                        return <tr key={key}>
                            <td>{project.datesCovered}</td>
                            <td>{project.projectName}</td>
                            <td>{project.discretionaryProjectStatusDescription}</td>
                            <td className={`action-no-wrap`}>
                                <Button
                                    name={`btnProject_${project.discretionaryProjectId}`}
                                    label={`View project information for ${projectNameAndDates}`}
                                    buttonType={ButtonTypes.INFO}
                                    showLabel={false}
                                    onClick={(event) => handleViewProjectInformationClick(event, project.dateWindowId, project.discretionaryProjectId)}
                                />
                                {
                                    canEdit &&
                                    <Button
                                        name={`btnEditProject_${project.discretionaryProjectId}`}
                                        label={`Edit project deliverables for ${projectNameAndDates}`}
                                        buttonType={ButtonTypes.EDIT}
                                        showLabel={false}
                                        onClick={(event) => handleNavigateToDeliverablesSummaryClick(event, project.dateWindowId, project.discretionaryProjectId)}
                                    />
                                }
                                {
                                    !canEdit &&
                                    <Button
                                        name={`btnViewProject_${project.discretionaryProjectId}`}
                                        label={`View project deliverables for ${projectNameAndDates}`}
                                        buttonType={ButtonTypes.VIEW}
                                        showLabel={false}
                                        onClick={(event) => handleNavigateToDeliverablesSummaryClick(event, project.dateWindowId, project.discretionaryProjectId)}
                                    />
                                }
                                <Button
                                    name={`btnReports_${project.discretionaryProjectId}`}
                                    label={`View reports for ${project.projectName} ${project.datesCovered}`}
                                    buttonType={ButtonTypes.CONTENT}
                                    showLabel={false}
                                    onClick={(event) => handleNavigateToReportsClick(event, project.dateWindowId, project.discretionaryProjectId)}
                                />
                                {
                                    canDelete &&
                                    <Button
                                        name={`btnDeleteProject_${project.discretionaryProjectId}`}
                                        label={`Delete ${projectNameAndDates}${0 < project.implementationCount ? ' not allowed due to existing implementations' : ''}`}
                                        buttonType={ButtonTypes.DELETE}
                                        showLabel={false}
                                        onClick={(event) => handleDeleteProjectClick(event, project.discretionaryProjectId)}
                                        disabled={0 < project.implementationCount}/>
                                }
                            </td>
                        </tr>;
                    }
                )
            }
            </tbody>
            <>
                {
                    summary.length > 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={4}>
                            <ResultsPager
                                currentPage={searchCriteria.Page}
                                disabled={false}
                                first={first}
                                last={last}
                                next={next}
                                previous={previous}
                                recordsPerPage={searchCriteria.GetRecordsPerPage(summary.length)}
                                totalRecords={summary.length}
                            />
                        </td>
                    </tr>
                    </tfoot>
                }
            </>
        </GridTable>
    );
};

PtsSummarySearchResults.propTypes = {
    actions: PropTypes.object.isRequired,
    handleClickOrder: PropTypes.func.isRequired,
    handleDeletePtsProject: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isStateUser: PropTypes.bool,
    searchCriteria: PropTypes.object.isRequired,
    summary: PropTypes.array
};