import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import SectionHeader from "../../../../../components/common/SectionHeader";
import * as bpieLocations from "../../../bpieLocations";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../../../bpieConstants";
import {getDistrictPiePageSubTitle, isPageDependenciesLoaded} from "../../bpieDistrictPieUtilities";
import bpieDistrictPieProgressMonitoringsApi from "../bpieDistrictPieProgressMonitoringsApi";
import {BpieDistrictPieActionStepUpdateDetailsView} from "./BpieDistrictPieActionStepUpdateDetailsView";

export const BpieDistrictPieActionStepUpdateDetailsContainer = ({
                                                                    actions,
                                                                    dataChanged,
                                                                    dateWindowId,
                                                                    districtBpie,
                                                                    districtBpieId,
                                                                    districtId,
                                                                    districtPie,
                                                                    districtPieId,
                                                                    dpieGoalId,
                                                                    dpieProgressMonitoringId,
                                                                    handleDataChanged,
                                                                    handleDistrictBpieSearchRedirect,
                                                                    isDistrictUser,
                                                                    loadDistrictPie,
                                                                    normalizeUrlAndRedirect,
                                                                    updatePageTitleDetails
                                                                }) => {
    const progressMonitoring = districtPie?.dpieProgressMonitorings.find(pm => pm.dpieProgressMonitoringId === dpieProgressMonitoringId);
    const dpieGoal = districtPie?.dpieGoals.find(goal => goal.dpieGoalId === dpieGoalId);
    const pageSubTitle = `Action Step Status Updates for Progress Monitoring ${progressMonitoring?.dateMeetingHeldOutput}`;

    const handleSaveActionStepUpdates = (actionStepUpdatesModel) => {
        actions.executeApi(bpieDistrictPieProgressMonitoringsApi.saveDistrictPieActionStepUpdate, [dateWindowId, districtId, districtPieId, dpieProgressMonitoringId, actionStepUpdatesModel])
            .then(() => {
                loadDistrictPie();
                handleDataChanged(false);
            });
    };

    const redirectToActionStepUpdateSummary = () => {
        if (dataChanged) {
            if (!confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
                return;
        }

        handleDataChanged(false);
        const viewActionStepUpdatesUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_ACTION_STEP_UPDATE.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId)
            : bpieLocations.STATE_DPIE_ACTION_STEP_UPDATE.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId);

        normalizeUrlAndRedirect(viewActionStepUpdatesUrl);
    };

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
            return;
        }

        loadDistrictPie();
    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtPie)) return;

        updatePageTitleDetails(getDistrictPiePageSubTitle(pageSubTitle, districtPie));

        const progressMonitoringStatus = districtPie.districtPieTaskStatuses.find(taskStatus => taskStatus.dpieTask === DistrictPieTask.ProgressMonitoring.id);
        if (progressMonitoringStatus?.isDisabled ?? true) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtPie]);

    if(!isPageDependenciesLoaded([districtBpie, progressMonitoring, dpieGoal], districtPie, districtPieId)) return null;

    const fullPageSubTitle = getDistrictPiePageSubTitle(pageSubTitle, districtPie);

    return <>
        <SectionHeader>
            <h2>{fullPageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieActionStepUpdateDetailsView
            actionStepUpdates={progressMonitoring.actionStepUpdates}
            dpieGoal={dpieGoal}
            handleDataChanged={handleDataChanged}
            handleSaveActionStepUpdates={handleSaveActionStepUpdates}
            redirectToActionStepUpdateSummary={redirectToActionStepUpdateSummary}
        />
    </>;
}

BpieDistrictPieActionStepUpdateDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtBpieId: PropTypes.string.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    dpieGoalId: PropTypes.string.isRequired,
    dpieProgressMonitoringId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    normalizeUrlAndRedirect: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};