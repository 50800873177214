import React, {useEffect} from "react";
import PropTypes from 'prop-types';
import {Allow} from "../../../../components/authorization/Allow";
import {BpiePolicy} from "../../../../components/authorization/policies/BpiePolicy";
import {bpie} from "../../../../constants/policyEvents";
import Button from "../../../../components/common/buttons/Button";
import GridTable from "../../../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import ResultsPager from "../../../../components/common/pagers/ResultsPager";
import {handleScroll} from "../../../../components/common/scrollUtilities";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {SortType} from "../../../../constants/sharedData/dynamicSorting";
import * as systemLocations from "../../../../constants/systemLocations";
import * as bpieLocations from "../../bpieLocations";

export const SbpieSearchResults = ({
                                       actions,
                                       currentLocation,
                                       handleClickOrder,
                                       history,
                                       isDistrictUser,
                                       searchCriteria,
                                       summary
                                   }) => {
    const containerId = "sbpie-summary";

    const handleImpersonateSchool = (event, userId) => {
        event.preventDefault();
        actions.impersonating(userId, currentLocation, true)
            .then(() => {
                history.push(systemLocations.DASHBOARD.path);
            });
    };

    const handleViewSchoolInfo = (event, districtId, schoolNumber, schoolBpieId) => {
        if (isDistrictUser)
            history.push(bpieLocations.DISTRICT_SCHOOL_SUMMARY.getUrl(districtId, schoolNumber, schoolBpieId));
        else
            history.push(bpieLocations.STATE_SCHOOL_SUMMARY.getUrl(districtId, schoolNumber, schoolBpieId));
    }

    const first = (event) => {
        event.preventDefault();
        moveToFirstPage();
    }

    const moveToFirstPage = () => {
        let criteria = {...searchCriteria};
        criteria.First();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const last = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Last(summary.length);
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const next = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Next();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    const previous = (event) => {
        event.preventDefault();
        let criteria = {...searchCriteria};
        criteria.Previous();
        actions.saveSearchCriteria(criteria);
        handleScroll(containerId);
    }

    useEffect(() => {
        if (!searchCriteria.onValidPage(summary.length))
            moveToFirstPage();

    }, [summary.length]);

    const totalColumns = isDistrictUser ? 7 : 8;

    return (
        <>
            <GridTable id={containerId} className={`sbpie-summary-table`}>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id="datesCovered" className="is-clickable">
                        Date Window
                    </th>
                    {
                        !isDistrictUser &&
                        <th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
                            District
                        </th>
                    }
                    <th onClick={handleClickOrder} data-id="schoolName" className="is-clickable">
                        School Title
                    </th>
                    <th onClick={handleClickOrder} data-id="msidNumber" className="is-clickable">
                        MSID
                    </th>
                    <th onClick={handleClickOrder} data-id="statusOutput" className="is-clickable">
                        Status
                    </th>
                    <th onClick={handleClickOrder} data-id={`dateStatusUpdated`} data-sorttype={SortType.Date}
                        className={`is-clickable`}>
                        Date Status Updated
                    </th>
                    <th onClick={handleClickOrder} data-id={`dateMeetingHeld`} data-sorttype={SortType.Date}
                        className={`is-clickable`}>
                        Date Meeting Held
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(summary) &&
                    <tr>
                        <td colSpan={totalColumns} className={"center"}>
                            No results match the search criteria specified.
                        </td>
                    </tr>
                }
                {
                    searchCriteria.GetCurrentPageRecords(summary).map((school, key) =>
                        (<tr key={key}>
                            <td>{school.datesCovered}</td>
                            {
                                !isDistrictUser &&
                                <td>{school.districtName}</td>
                            }
                            <td>{school.schoolName}</td>
                            <td>{school.msidNumber}</td>
                            <td>{school.statusOutput}</td>
                            <td>{school.dateStatusUpdated}</td>
                            <td>{school.dateMeetingHeld}</td>
                            <td className={`actions`}>
                                {
                                    school.principalUserId &&
                                    <Allow policy={BpiePolicy} policyEvent={bpie.schoolBpieAssessments.IMPERSONATE}>
                                        <Button name={`btnImpersonate_${school.principalUserId}`}
                                                label={`Impersonate ${school.schoolName}`}
                                                buttonType={ButtonTypes.IMPERSONATE}
                                                showLabel={false}
                                                onClick={(event) => handleImpersonateSchool(event, school.principalUserId)}
                                        />
                                    </Allow>
                                }
                                <Button
                                    buttonType={ButtonTypes.VIEW}
                                    label={`View School Information for ${school.schoolName}`}
                                    name={`btnSchoolDetails`}
                                    onClick={(event) => handleViewSchoolInfo(event, school.districtId, school.msidNumber, school.schoolBpieId)}
                                    showLabel={false}
                                />
                            </td>
                        </tr>)
                    )
                }
                </tbody>
                <>
                    {
                        summary.length > 0 &&
                        <tfoot>
                        <tr>
                            <td colSpan={totalColumns}>
                                <ResultsPager
                                    currentPage={searchCriteria.Page}
                                    disabled={false}
                                    first={first}
                                    last={last}
                                    next={next}
                                    previous={previous}
                                    recordsPerPage={searchCriteria.GetRecordsPerPage(summary.length)}
                                    totalRecords={summary.length}
                                />
                            </td>
                        </tr>
                        </tfoot>
                    }
                </>
            </GridTable>
        </>
    );
};

SbpieSearchResults.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    handleClickOrder: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    searchCriteria: PropTypes.object.isRequired,
    summary: PropTypes.array
};