import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonTypes from "../../../constants/ButtonTypes";

const ButtonMock = ({
                        addToTabbing,
                        name,
                        label,
                        btnClass = "",
                        buttonType = ButtonTypes.MOCK,
                        showLabel = true,
                        disabled,
                    }) => {

    const className = "button " + buttonType + " is-notClickable " + btnClass + (disabled ? " is-disabled " : "");

    return <>
        <span className={className}
              title={!showLabel ? label : ""}
              id={name}
              tabIndex={addToTabbing ? "0" : null}
        ><span className={!showLabel ? " is-visuallyhidden " : ""}>{label}</span></span>
    </>;
};

ButtonMock.propTypes = {
    addToTabbing: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    btnClass: PropTypes.string,
    showLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    buttonType: PropTypes.string,
};

export default ButtonMock;
