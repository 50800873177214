import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import AccountUtility from "../../../api/AccountUtility";
import {createListItem, isNullOrUndefined, limitTextLength} from "../../../components/common/commonUtilities";
import {orderResults, updateOrder} from "../../../components/common/sortUtilities";
import {DASHBOARD} from "../../../constants/systemLocations";
import {cloneDeep} from 'lodash/lang';
import {createIntakeRequirementObject, createIntakeVersionObject} from "../../drObjectFactory";
import {IntakeRequirementType} from "../../StateComplaints/drStateComplaintConstants";
import drAdminIntakeApi from "./drAdminIntakeApi";
import DrAdminIntakeOrderRequirements from "./DrAdminIntakeOrderRequirements";
import DrAdminIntakeRequirementEdit from "./DrAdminIntakeRequirementEdit";
import DrAdminIntakeRequirementView from "./DrAdminIntakeRequirementView";
import DrAdminIntakeVersionEdit from "./DrAdminIntakeVersionEdit";
import DrAdminIntakeVersionView from "./DrAdminIntakeVersionView";

const DrAdminIntakeContainer = ({history}) => {
    const [intakeRequirements, setIntakeRequirements] = useState(null);
    const [editVersionId, setEditVersionId] = useState(null);
    const [viewVersionId, setViewVersionId] = useState(null);
    const [editRequirementId, setEditRequirementId] = useState(null);
    const [orderVersionId, setOrderVersionId] = useState(null);

    const handleSaveIntakeRequirementVersion = (intakeVersion) => {
        drAdminIntakeApi.saveIntakeRequirementVersion(intakeVersion)
            .then((result) => {
                setIntakeRequirements(result);
                setEditVersionId(null);
            });
    };

    const handleSaveIntakeRequirement = (intakeRequirement) => {
        drAdminIntakeApi.saveIntakeRequirement(intakeRequirement)
            .then((result) => {
                setIntakeRequirements(result);
                setEditRequirementId(null);
            });
    };

    const handleDeleteIntakeRequirementVersion = (versionId) => {
        if(confirm("Are you sure you want to delete this version?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            drAdminIntakeApi.deleteIntakeVersion(versionId).then(setIntakeRequirements);
    };

    const handleDeleteIntakeRequirement = (requirementId) => {
        if(confirm("Are you sure you want to delete this intake requirement?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            drAdminIntakeApi.deleteIntakeRequirement(viewVersionId, requirementId).then(setIntakeRequirements);
    };

    const handleOrder = () => {
        setOrderVersionId(viewVersionId);
    }

    const handleSaveOrder = (result) => {
        let clonedIntakeRequirements = cloneDeep(intakeRequirements);
        let selectedVersion = clonedIntakeRequirements.find(f => f.versionId === orderVersionId);

        let sortedElement = orderResults(result, selectedVersion.intakeRequirements);
        const sortedRequirements = updateOrder(sortedElement);

        let displayOrder = 1;
        for(let requirement of sortedRequirements)
            requirement.displayOrder = displayOrder++;

        selectedVersion.intakeRequirements = sortedRequirements;
        setIntakeRequirements(clonedIntakeRequirements);

        drAdminIntakeApi.saveElementOrder(orderVersionId, sortedElement);
    }

    useEffect(() => {
        if (!AccountUtility.isStateUserDrAuthorized())
            history.push(DASHBOARD.path);

        drAdminIntakeApi.getIntakeRequirements().then(setIntakeRequirements);
    }, []);

    const getSelectedVersion = (versionId) => {
        return createIntakeVersionObject(intakeRequirements.find(f => f.versionId === versionId));
    }

    const getSelectedRequirement = (requirementId) => {
        const intakeVersion = intakeRequirements.find(f => f.versionId === viewVersionId);
        const intakeRequirement = intakeVersion.intakeRequirements.find(f => f.requirementId === requirementId);
        const totalIntakeRequirements = intakeVersion.intakeRequirements ? intakeVersion.intakeRequirements.length : 0;
        return intakeRequirement
            ? createIntakeRequirementObject(intakeRequirement)
            : createIntakeRequirementObject({displayOrder: totalIntakeRequirements + 1, versionId: viewVersionId});
    }

    const getConditionalRequirements = () => {
        const version = getSelectedVersion(viewVersionId);
        return version.intakeRequirements
            .filter(f => f.requirementType === IntakeRequirementType.Conditional)
            .map(requirement => createListItem(requirement.requirementId, limitTextLength(requirement.requirementDescriptionNoHtml, 50), requirement.requirementId));
    }

    if (!intakeRequirements)
        return null;

    return (
        <>
            {
                isNullOrUndefined(editVersionId) &&
                isNullOrUndefined(viewVersionId) &&
                <DrAdminIntakeVersionView
                    handleDeleteIntakeRequirementVersion={handleDeleteIntakeRequirementVersion}
                    intakeRequirements={intakeRequirements}
                    setEditVersionId={setEditVersionId}
                    setViewVersionId={setViewVersionId}
                />
            }
            {
                !isNullOrUndefined(editVersionId) &&
                <DrAdminIntakeVersionEdit
                    handleSaveIntakeRequirementVersion={handleSaveIntakeRequirementVersion}
                    intakeVersion={getSelectedVersion(editVersionId)}
                    isOnlyVersion={intakeRequirements.length <= 1}
                    setEditVersionId={setEditVersionId}
                />
            }
            {
                !isNullOrUndefined(viewVersionId) &&
                isNullOrUndefined(editRequirementId) &&
                isNullOrUndefined(orderVersionId) &&
                <DrAdminIntakeRequirementView
                    handleDeleteIntakeRequirement={handleDeleteIntakeRequirement}
                    handleOrder={handleOrder}
                    hasResponses={getSelectedVersion(viewVersionId).hasResponses}
                    intakeRequirements={getSelectedVersion(viewVersionId).intakeRequirements}
                    setEditRequirementId={setEditRequirementId}
                    setViewVersionId={setViewVersionId}
                />
            }
            {
                !isNullOrUndefined(editRequirementId) &&
                <DrAdminIntakeRequirementEdit
                    conditionalRequirements={getConditionalRequirements()}
                    handleSaveIntakeRequirement={handleSaveIntakeRequirement}
                    intakeRequirement={getSelectedRequirement(editRequirementId)}
                    setEditRequirementId={setEditRequirementId}
                />
            }
            {
                !isNullOrUndefined(orderVersionId) &&
                <DrAdminIntakeOrderRequirements
                    handleSaveOrder={handleSaveOrder}
                    setOrderVersionId={setOrderVersionId}
                    intakeRequirements={getSelectedVersion(viewVersionId).intakeRequirements}
                />
            }
        </>
    );
};

DrAdminIntakeContainer.propTypes = {
    history: PropTypes.object
};

export default DrAdminIntakeContainer;
