import accountApi from "../api/AccountApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import storage from "../components/shared/LocalStorage";
import AccountUtility from "../api/AccountUtility";

export function loginSuccess(isLoggedIn) {
    return { type: actionTypes.LOGIN_AJAX_SUCCESS, isLoggedIn };
}

export function logoutSuccess() {
    return { type: actionTypes.LOGOUT_AJAX_SUCCESS };
}

export function attemptLogin(loginViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return accountApi.attemptLogin(loginViewModel)
            .then((loginInfo) => {
                const userIdentity = AccountUtility.getUserIdentityFromToken(loginInfo.access_token);

                storage.persistLogin(loginInfo.access_token, loginInfo.refresh_token, userIdentity, userIdentity.Behaviors);
                dispatch(loginSuccess(true));
            })
            .catch(error => {
                storage.logout();
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function setRedirectUrl(redirectUrl) {
    return { type: actionTypes.LOGIN_SET_RETURNURL, redirectUrl };
}

//REQUEST PASSWORD RESET EMAIL
export function requestPasswordReset(forgotPasswordViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return accountApi.requestPasswordReset(forgotPasswordViewModel)
            .then(() => {
                dispatch(requestPasswordResetSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
            });
    };
}

export function requestPasswordResetSuccess() {
    return { type: actionTypes.REQUEST_PASSWORD_AJAX_SUCCESS };
}

//RESET PASSWORD
export function resetPasswordSuccess() {
    return { type: actionTypes.RESET_PASSWORD_AJAX_SUCCESS };
}
export function resetPassword(resetPasswordViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return accountApi.resetPassword(resetPasswordViewModel)
            .then(() => {
                dispatch(resetPasswordSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

//IMPERSONATING
export function impersonatingSuccess() {
    return { type: actionTypes.IMPERSONATING_AJAX_SUCCESS };
}
export function impersonating(userId, url, shouldOverrideDashboardRedirectRule = false) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return accountApi.impersonating(userId)
            .then((data) => {
                storage.persistAdminRefreshToken();
                storage.persistAdminAccessToken();
                storage.persistAdminReturnUrl(url, shouldOverrideDashboardRedirectRule);

                const userIdentity = AccountUtility.getUserIdentityFromToken(data.access_token);
                storage.persistLogin(data.access_token, data.refresh_token, userIdentity, userIdentity.Behaviors);

                dispatch(impersonatingSuccess());


            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function returnAsAdminSuccess() {
    return { type: actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS };
}

export function getUserTokenFromRefreshToken(refreshTokenViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return accountApi.getUserTokenFromRefreshToken(refreshTokenViewModel)
            .then((data) => {
                storage.removeAdminAccessToken();
                storage.removeAdminRefreshToken();
                storage.removeAdminReturnUrl();

                const userIdentity = AccountUtility.getUserIdentityFromToken(data.access_token);
                storage.persistLogin(data.access_token, data.refresh_token, userIdentity, userIdentity.Behaviors);

                dispatch(returnAsAdminSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}
