import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import {convertFormInputObjectToObject, useFormInputObject} from "../../components/common/inputs/inputUtility";
import SearchableSingleSelectField from "../../components/common/inputs/SearchableSingleSelectField";
import {EDIT} from "../../constants/behaviors";
import {SiteSectionsForTeamMemberList} from "../../constants/config";
import {
    buildTeamMembers,
    buildTeamMembersObjectProperty, createDistrictSiteSectionTeamMemberObject, getAssignedUser,
} from "./userObjectFactory";

const StateUserDistrictAssignmentDistrict = ({
                                                 buildUniqueCombinedList,
                                                 district,
                                                 getUpdateTeamMembers,
                                                 stateUsers
                                             }) => {
    const teamMembersForm = useFormInputObject(buildTeamMembers(district));

    const handleChangeForm = (event, districtId, siteSection) => {
        const userId = event.target.value;
        SiteSectionsForTeamMemberList.map((section) => {
            const currentAssignedUserId = teamMembersForm[buildTeamMembersObjectProperty(districtId, section.value)];

            const isSelectedUserInContext = stateUsers.some(user => user.value === userId && user.claimValues.some(o => o === `${section.context}.${EDIT}`));

            const updatedUserId = section.value === siteSection
                ? userId
                : isTrimmedStringEmpty(currentAssignedUserId.value) && isSelectedUserInContext
                    ? userId
                    : currentAssignedUserId.value;

            teamMembersForm[buildTeamMembersObjectProperty(districtId, section.value)].setValue(updatedUserId);
        });
    };

    const updateTeamMembers = (teamMembersForm) => {
        let result = convertFormInputObjectToObject(teamMembersForm);
        let teamMembers = [];
        const currentDistrictValues = buildTeamMembers(district);

        for (let property in result) {
            const userId = result[property];
            const districtSiteSectionInfo = property.split("_");
            if (districtSiteSectionInfo.length !== 2) continue;

            const districtId = districtSiteSectionInfo[0];
            const siteSection = parseInt(districtSiteSectionInfo[1]);

            if (userId !== currentDistrictValues[property])
                teamMembers.push(createDistrictSiteSectionTeamMemberObject(userId, districtId, siteSection));
        }

        return teamMembers;
    };

    const getDistrictTeamMembers = () => {
        return updateTeamMembers(teamMembersForm);
    };

    useEffect(() => {
        const currentDistrictValues = buildTeamMembers(district);
        for (let property in currentDistrictValues) {
            if (teamMembersForm[property].value !== currentDistrictValues[property]) {
                teamMembersForm[property].setValue(currentDistrictValues[property]);
            }
        }

        getUpdateTeamMembers(getDistrictTeamMembers);
    }, [district]);

    return (
        <tr>
            <td>{district.name}</td>
            {
                SiteSectionsForTeamMemberList.map((section, index) => {
                    const propertyName = buildTeamMembersObjectProperty(district.id, section.value);
                    const currentDistrictTeamMemberForSection = getAssignedUser(district.districtSiteSectionTeamMemberDtos, district.id, section.value);
                    return (
                        <td key={index}>
                            <SearchableSingleSelectField
                                {...teamMembersForm[propertyName]}
                                onChange={(event) => handleChangeForm(event, district.id, section.value)}
                                includeDefaultOption={true}
                                includePlaceHolder={false}
                                label={`${section.text} for ${district.name}`}
                                name={propertyName}
                                options={buildUniqueCombinedList(teamMembersForm[propertyName].value, currentDistrictTeamMemberForSection.firstName, currentDistrictTeamMemberForSection.lastName, section.context)}
                                showLabel={false}
                            />
                        </td>
                    );
                })
            }
        </tr>
    );
};

StateUserDistrictAssignmentDistrict.propTypes = {
    buildUniqueCombinedList: PropTypes.func.isRequired,
    district: PropTypes.object.isRequired,
    getUpdateTeamMembers: PropTypes.func.isRequired,
    stateUsers: PropTypes.array.isRequired
};

export default StateUserDistrictAssignmentDistrict;