import {baseLinePrefix, teamPrefix, metricPrefix} from "../../constants/strategicPlan/idPrefixes";
import {isArrayNullOrEmpty, isObjectNullOrEmpty} from "../common/commonUtilities";
import {generateId} from "../common/IdGeneratorUtility";

export const createPlanObject = (plan) =>
    ({
        planDateWindowId: plan.planDateWindowId,
        planId: plan.planId,
        dateWindowId: plan.dateWindowId,
        startYear: plan.startYear,
        name: plan.name,
        status: plan.status,
        teamMembers: createTeamMembersObject(plan.teamMembers)
    });

const createTeamMembersObject = (teamMembers) => {
    if(isArrayNullOrEmpty(teamMembers))
        return [];

    return teamMembers.map((teamMember, index) => ({
        id: generateId(index + 1, teamPrefix),
        role: teamMember.role,
        text: teamMember.text
    }));
};

export const createFiveYearPlanObject = (plan) =>
    ({
        planDateWindowId: plan.planDateWindowId,
        id: plan.id,
        goals: plan.goals,
        strategyActions: plan.strategyActions,
        strategyIndicators: createStrategyIndicatorsObject(plan.strategyIndicators)
    });

const createStrategyIndicatorsObject = (strategyIndicators) => {
    if(isArrayNullOrEmpty(strategyIndicators))
        return [];

    return strategyIndicators.map((indicator) => ({
        baseline: isObjectNullOrEmpty(indicator.baseline) ? null : {...indicator.baseline, ...{id: generateId(0, baseLinePrefix, indicator.id)}},
        id: indicator.id,
        indicators: indicator.indicators,
        strategyId: indicator.strategyId,
        triangleFileId: indicator.triangleFileId,
        metrics: createMetricsObject(indicator.metrics, indicator.id)
    }));
};

const createMetricsObject = (metrics, indicatorId) => {
    if(isArrayNullOrEmpty(metrics))
        return [];

    return metrics.map((metric, index) => ({...metric, ...{id: generateId(index + 1, metricPrefix, indicatorId)}}));
};