import React from 'react';
import PropTypes from 'prop-types';
import {getSupportTicket} from "../../constants/config";
import LoginField from '../common/inputs/LoginField';
import PasswordField from '../common/inputs/PasswordField';
import Button from '../common/buttons/Button';
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as publicLocations from "../../constants/publicLocations";
import {Link} from "react-router-dom";
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";

const LoginForm = ({loginViewModel, onClick, onChange, isLoading, errors}) => {
	return (
		<main id="main" role="main">
			<p>The Bureau of Exceptional Education and Student Services (BEESS) general supervision system ensures that
				Florida school districts comply with federal and state requirements related to exceptional student
				education programs, meet requirements related to the State Performance Plan and the BEESS Strategic
				Plan, and make progress toward indicator targets.</p>

			<form>
				<LoginField
					name="username"
					label="Login Name"
					placeholder="Login Name..."
					value={loginViewModel.username}
					onChange={onChange}
					showLabel={false}
					error={(errors) ? errors.username : ""}
				/>
				<PasswordField
					name="password"
					label="Password"
					placeholder="Password..."
					value={loginViewModel.password}
					onChange={onChange}
					showLabel={false}
					error={(errors) ? errors.password : ""}
				/>

				<Button
					name="btnLogin"
					label="Sign in"
					onClick={onClick}
					buttonType={ButtonTypes.SUBMIT}
					disabled={isLoading}
				/>
				<div>
					<Link to={publicLocations.USER_FORGOT_PASSWORD.path}>Forgot your password?</Link> | <HtmlDisplayInline html={getSupportTicket(`GSW Login Name Help`, `Forgot your Login Name?`)} />
				</div>
			</form>
		</main>
	);
};

LoginForm.propTypes = {
	loginViewModel: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	errors: PropTypes.object
};

export default LoginForm;
