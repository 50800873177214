import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";

export class AppSettingsPage extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        this.props.actions.loadAppSettings();
    }

    render() {
        if(this.props.appSettings.Smtp === undefined)
            return null;

        return (
            <pre>
                {JSON.stringify(this.props.appSettings, undefined, 2)}
            </pre>
        );
    }
}

AppSettingsPage.propTypes = {
    appSettings: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        appSettings: state.sharedData.appSettings
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        sharedDataActions
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSettingsPage);