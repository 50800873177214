import React from 'react';
import AbstractNumberField, {AbstractNumberFieldProps} from "./AbstractNumberField";

const PercentField = (props) => {

    const {inputClass = "", minValue = 0} = props;

    return <AbstractNumberField
        {...props}
        maxValue={100}
        minValue={minValue}
        inputClass={`${inputClass} input-icon --percent`}
    />;
};

PercentField.propTypes = AbstractNumberFieldProps;

export default PercentField;