import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import {
    convertToString,
    deepCopyArray,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import Button from "../../../../components/common/buttons/Button";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import toastrOptions from "../../../../constants/toastrOptions";
import {createBpieResponse, getResponse} from "../bpieAssessmentFactory";

const BpiePrioritiesView = ({
                                canEdit,
                                dataChanged,
                                handleDataChanged,
                                indicators,
                                initialResponses,
                                isBpieActive,
                                onEditAssessment,
                                pageTitle,
                                returnToSummary,
                                savePriorityIndicators
                            }) => {
    const [responses, setResponses] = useState(null);

    const handleCheckIndicator = (event, id) => {
        const newResponses = deepCopyArray(responses);
        const index = newResponses.findIndex(f => f.bpieIndicatorId === id);
        newResponses[index] = createBpieResponse({...newResponses[index], isPriority: event.target.checked});
        setResponses(newResponses);
        handleDataChanged(true);
    };

    const validatePriorityIndicators = () => {
        const totalPriorities = responses.filter(c => c.isPriority).length;

        if (totalPriorities === 0) {
            NotifyUser.Error("At least one priority indicator must be selected.");
            return false;
        }

        if (totalPriorities > 5)
            NotifyUser.Warning("NOTE - selecting more than five priority indicators is not recommended.", "", toastrOptions.NoAutoClosingWarning);

        return true;
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        const isValid = validatePriorityIndicators();
        if (!isValid) return;

        const priorityBpieIndicatorIds = responses.filter(c => c.isPriority).map(m => m.bpieIndicatorId);
        savePriorityIndicators(priorityBpieIndicatorIds);
        handleDataChanged(false);
    }

    const onReturnClick = (event) => {
        event.preventDefault();

        if (verifyNavigation()) {
            handleDataChanged(false);
            returnToSummary();
        }
    }

    const verifyNavigation = () => {
        return !dataChanged || (dataChanged && confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."));
    }

    const onComponentClick = (event, desiredIndicator) => {
        event.preventDefault();
        if (verifyNavigation()) {
            handleDataChanged(false);
            onEditAssessment(desiredIndicator);
        }
    }

    const arePriorityIndicatorsDisabled = () => {
        return indicators.length !== responses.filter(r => !isTrimmedStringEmpty(convertToString(r.implementationStatus))).length;
    };

    useEffect(() => {
        if(responses)
            return;

        setResponses(indicators.map((i) => {
            return getResponse(initialResponses, i.bpieIndicatorId);
        }));
    }, [indicators]);

    if(!responses)
        return null;

    return (
        <>
            <h2>{pageTitle}</h2>
            <p>Based on your team&apos;s final indicator ratings, please specify your school&apos;s priority indicators
                by selecting them on the list below.</p>
            {
                arePriorityIndicatorsDisabled() &&
                <p className={`missing-data-highlight`}><strong>NOTE: all indicators need an implementation status
                    before selecting your priority
                    indicators.</strong></p>
            }
            <GridTable>
                <thead>
                <tr>
                    <th>Is Indicator a Priority</th>
                    <th>Status</th>
                    {
                        canEdit &&
                        <th>Edit</th>
                    }
                </tr>
                </thead>
                <tbody>
                {
                    indicators.map((indicator, key) => {
                        const response = responses.find(r => r.bpieIndicatorId === indicator.bpieIndicatorId);
                        return (
                            <tr key={key} valign={`top`}>
                                <td>
                                    <CheckBoxField
                                        checked={isNullOrUndefined(response.isPriority) ? false : response.isPriority}
                                        disabled={!canEdit || !isBpieActive}
                                        label={`${indicator.indicatorNumber}. ${indicator.summary}`}
                                        name={`ch_${indicator.bpieIndicatorId}`}
                                        onChange={(event) => handleCheckIndicator(event, response.bpieIndicatorId)}
                                        showLabel={true}
                                        value={`isPriority`}
                                        wrapperClass={`priority-indicators-summary`}
                                    />
                                </td>
                                <td>{response.implementationStatus}</td>
                                {
                                    canEdit &&
                                    <td className={'text-center'}>
                                        <Button
                                            name={`btnEdit_${indicator.indicatorNumber}`}
                                            onClick={(event) => onComponentClick(event, indicator.indicatorNumber)}
                                            label={`${isBpieActive ? "Edit" : "View"} Indicator Response ${indicator.indicatorNumber}`}
                                            buttonType={isBpieActive ? ButtonTypes.EDIT : ButtonTypes.VIEW}
                                            showLabel={false}
                                        />
                                    </td>
                                }
                            </tr>);
                    })
                }
                </tbody>
            </GridTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button
                    buttonType={ButtonTypes.BACK}
                    label={"Return to Summary"}
                    name="btnReturn"
                    onClick={onReturnClick}
                />
                {
                    isBpieActive &&
                    <Button
                        buttonType={ButtonTypes.SAVE}
                        disabled={arePriorityIndicatorsDisabled()}
                        label={"Save Priority Indicators"}
                        name="btnSave"
                        onClick={onSaveClick}
                    />
                }
            </ButtonBar>
        </>
    );
}

BpiePrioritiesView.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    dataChanged: PropTypes.bool,
    handleDataChanged: PropTypes.func.isRequired,
    indicators: PropTypes.array.isRequired,
    initialResponses: PropTypes.array.isRequired,
    isBpieActive: PropTypes.bool.isRequired,
    onEditAssessment: PropTypes.func.isRequired,
    pageTitle: PropTypes.string.isRequired,
    returnToSummary: PropTypes.func.isRequired,
    savePriorityIndicators: PropTypes.func.isRequired
}

export default BpiePrioritiesView;
