import {
    validateDecimal,
    isArrayNullOrEmpty,
    objectHasProperty,
    createListItem,
    dynamicSort,
    isArray,
    isNullOrUndefined,
    convertToCurrencyFormat,
    convertToValidNumber,
    isString,
} from "../../components/common/commonUtilities";
import {BUDGET_TYPES} from "./Application/ApplicationBudget/gmsApplicationBudgetConstants";
import {BUDGET_CODE_TYPE, ELEMENT_TYPE} from "./gmsConstants";
import {createTeamMemberObject} from "../../components/users/teamMemberObjectFactory";
import {emptyGuid} from "../../constants/config";

export const ELEMENT_TYPE_SUFFIX = "_type";

export const createResponseFormObject = (elements) => {
    let result = {};
    if (!isArrayNullOrEmpty(elements)) {
        elements.map((element) => {
            result[`${element.elementId}${ELEMENT_TYPE_SUFFIX}`] = element.elementType;
            const isSectionWithElements = element?.elementType === ELEMENT_TYPE.section && element?.elements;
            if (isSectionWithElements) {
                element.elements.map((child) => {
                    result[child.elementId] = child.response;
                });
            } else if (element?.elementType === ELEMENT_TYPE.checkboxList) {
                let response = element.response;
                response = response ? JSON.parse(response) : response;
                result[element.elementId] = response;
            } else if (element?.elementType === ELEMENT_TYPE.dollarTextbox) {
                let response = element.response;
                response = response ? JSON.parse(response) : response;
                response = validateDecimal(response);
                result[element.elementId] = response;
            } else
                result[element.elementId] = element.response;
        });
    }
    return result;
};

export const createSaveApplicationResponseRequest = (responses) => {
    let result = [];
    for (let key in responses) {
        if (objectHasProperty(responses, key)) {
            let {name = "", value = ""} = responses[key];

            if (name.indexOf(ELEMENT_TYPE_SUFFIX) > -1)
                continue;

            if (isArray(value))
                value = JSON.stringify(value);

            const item = {
                elementId: name,
                responseValue: value,
            };
            result.push(item);
        }
    }
    return {responses: result};
};

export const createDoe100Data = ({
                                     applicantAddress = "",
                                     applicantCity = "",
                                     applicantZip = "",
                                     applicantPhysicalAddress = "",
                                     applicantUeiNumber = "",
                                     applicantFeinNumber = "",
                                     contactNameAgency = "",
                                     contactNameFiscal = "",
                                     contactName = "",
                                     contactAddress = "",
                                     contactCity = "",
                                     contactZip = "",
                                     contactEmail = "",
                                     contactPhone = "",
                                     contactPhoneExt = "",
                                     contactFax = "",
                                     fundsRollForward = "0.00",
                                 }) => ({
    applicantAddress,
    applicantCity,
    applicantZip,
    applicantPhysicalAddress,
    applicantUeiNumber,
    applicantFeinNumber,
    contactNameAgency,
    contactNameFiscal,
    contactName,
    contactAddress,
    contactCity,
    contactZip,
    contactEmail,
    contactPhone,
    contactPhoneExt,
    contactFax,
    fundsRollForward,
});

export const createDoe150Data = ({
                                     amendmentNumber = 1,
                                     narrative = "",
                                     contactNameAgency = "",
                                     contactName = "",
                                     contactAddress = "",
                                     contactCity = "",
                                     contactZip = "",
                                     contactEmail = "",
                                     contactPhone = "",
                                 }, nextAmendmentNumber) => ({
    amendmentNumber: nextAmendmentNumber || amendmentNumber,
    narrative: !isNullOrUndefined(nextAmendmentNumber) ? "" : narrative,
    contactNameAgency,
    contactName,
    contactAddress,
    contactCity,
    contactZip,
    contactEmail,
    contactPhone,
});

export const createDoe150Labels = () => ({
    amendmentNumber: {
        label: `Amendment Number`,
        required: true,
    },
    narrative: {
        label: `Narrative`,
        required: true,
    },
    contactNameAgency: {
        label: "Agency Head",
        required: true,
    },
    contactName: {
        label: "Contact Name",
        required: true,
    },
    contactAddress: {
        label: "Contact Address",
        required: true,
    },
    contactCity: {
        label: "Contact City",
        required: true,
    },
    contactZip: {
        label: "Contact Zip",
        required: true,
    },
    contactEmail: {
        label: "Email",
        required: true,
    },
    contactPhone: {
        label: "Phone",
        required: true,
    },
});

export const createDoe100Labels = () => ({
    applicantAddress: {
        label: `Address`,
        required: true,
    },
    applicantCity: {
        label: `City`,
        required: true,
    },
    applicantZip: {
        label: `Zip`,
        required: true,
    },
    applicantPhysicalAddress: {
        label: "Physical/Facility Address",
        required: true,
    },
    applicantUeiNumber: {
        label: "UEI Number",
        required: true,
    },
    applicantFeinNumber: {
        label: "FEIN Number",
        required: true,
    },
    contactNameAgency: {
        label: "Agency Head",
        required: true,
    },
    contactNameFiscal: {
        label: "Fiscal Contact Name",
        required: true,
    },
    contactName: {
        label: "Contact Name",
        required: true,
    },
    contactAddress: {
        label: "Contact Address",
        required: true,
    },
    contactCity: {
        label: "Contact City",
        required: true,
    },
    contactZip: {
        label: "Contact Zip",
        required: true,
    },
    contactEmail: {
        label: "Email",
        required: true,
    },
    contactPhone: {
        label: "Phone",
        required: true,
    },
    contactPhoneExt: {
        label: "Phone Extension",
        required: false,
    },
    contactFax: {
        label: "Fax",
        required: false,
    },
    fundsProgramName: {
        label: "Program Name",
        readonly: true,
        required: false,
    },
    fundsAllocated: {
        label: "Funds Allocated",
        readonly: true,
        required: false,
    },
    fundsRollForward: {
        label: "Estimated Roll Forward",
        required: true,
    },
});

export const createBudgetElementConfigObject = (
    isDisabledForAmendmentDeleted = true,
    isDisabledForEditOnlyFeedback = true,
    isDisabledForCurrentBudgetLineItem = false,
    isDisabledForFteException = false,
    isDisabledForFte = false,
    isDisabledForNotEditingFeedback = false,
) => ({
    isDisabledForAmendmentDeleted,
    isDisabledForEditOnlyFeedback,
    isDisabledForCurrentBudgetLineItem,
    isDisabledForFteException,
    isDisabledForFte,
    isDisabledForNotEditingFeedback,
});

export const createBudgetElementsConfigObject = () => ({
    fundingCategoryCode: createBudgetElementConfigObject(),
    functionCode: createBudgetElementConfigObject(),
    objectCode: createBudgetElementConfigObject(),
    exceptionToFteRule: createBudgetElementConfigObject(true, true, true, true),
    alternateDescription: createBudgetElementConfigObject(true, true, true),
    accountDescription: createBudgetElementConfigObject(),
    fullTimeEquivalent: createBudgetElementConfigObject(true, true, false, false, true),
    amount: createBudgetElementConfigObject(),
    percentage: createBudgetElementConfigObject(true, true, true),
    amendmentComment: createBudgetElementConfigObject(false, true, false),
    includeCommentsInNarrative: createBudgetElementConfigObject(false, true, false),
    feedback: createBudgetElementConfigObject(false, false, false, false, false, true),
});

export const createBudgetLineItem = ({
                                         accountDescription = "",
                                         alternateDescription = "",
                                         amendmentComment = "",
                                         amount,
                                         budgetId = emptyGuid,
                                         budgetLineItemId = emptyGuid,
                                         dateCreated = "",
                                         dateModified = "",
                                         entryNumber = "N/A",
                                         exceptionToFteRule = false,
                                         feedback = "",
                                         fullTimeEquivalent,
                                         functionCode = "",
                                         fundingCategoryCode = "",
                                         includeCommentsInNarrative = false,
                                         isAmendmentLineItem = false,
                                         isDeletedForAmendment = false,
                                         objectCode = "",
                                         percentage,
                                     }) => ({
    accountDescription,
    alternateDescription,
    amendmentComment,
    amount: isNullOrUndefined(amount) ? `` : validateDecimal(amount),
    amountForDisplay: isNullOrUndefined(amount) ? `` : convertToCurrencyFormat(amount),
    budgetId,
    budgetLineItemId,
    dateCreated,
    dateModified,
    entryNumber,
    exceptionToFteRule,
    feedback: feedback || "",
    fullTimeEquivalent: isNullOrUndefined(fullTimeEquivalent) ? `` : validateDecimal(fullTimeEquivalent, 3),
    functionCode,
    fundingCategoryCode,
    includeCommentsInNarrative,
    isAmendmentLineItem,
    isDeletedForAmendment,
    objectCode,
    percentage: isNullOrUndefined(percentage) ? `` : validateDecimal(percentage),
});

export function createBudgetSummary({
                                        budgetLineItems = [],
                                        budgetType = BUDGET_TYPES.applicationBudget.id,
                                        requestedTargetedAllocation = 0,
                                        requestedFinalAllocation,
                                    }) {
    let requested = budgetType === BUDGET_TYPES.applicationBudget.id
        ? requestedTargetedAllocation
        : requestedFinalAllocation || requestedTargetedAllocation;

    let total = 0,
        remaining;

    budgetLineItems && budgetLineItems.map((budgetLineItem) => {
        const {amount} = budgetLineItem;
        total += amount;
    });

    remaining = validateDecimal(requested - total);

    const isOverBudget = remaining < 0;
    const overBudget = isOverBudget ? remaining * -1 : "";

    return {
        isOverBudget,
        overBudget: convertToCurrencyFormat(overBudget),
        remaining: convertToCurrencyFormat(remaining),
        requested: convertToCurrencyFormat(requested),
        total: convertToCurrencyFormat(total),
    };
}


export const createFundingCategoryCodeList = (codes) => {
    return filterCodes(codes, BUDGET_CODE_TYPE.fundingCategory);
};

export const createFunctionCodeList = (codes) => {
    return filterCodes(codes, BUDGET_CODE_TYPE.function);
};

export const createObjectCodeList = (codes) => {
    return filterCodes(codes, BUDGET_CODE_TYPE.object);
};

const filterCodes = (codes, type) => {
    let result = [];
    codes.map((codeItem) => {
        let {codeType} = codeItem;

        if (codeType !== type)
            return;

        result.push(codeItem);
    });
    return result;
};

export const createCodeList = (codes) => {
    let result = [];
    codes.map((codeItem) => {
        let {code = "", description = ""} = codeItem;
        const item = {
            text: `${code} - ${description}`,
            value: code,
        };
        result.push(item);
    });
    return result;
};


export const createGmsTeamMemberList = (teamMembers = [], showInactiveLabel = false) => {
    let list = [];
    if (isArray(teamMembers))
        teamMembers.map((i) => {
            let teamMember = createTeamMemberObject(i);

            if (!teamMember.id) return;

            let fullName = `${teamMember.fullName}${teamMember.isDisabled && showInactiveLabel ? " (Inactive)" : ""}`;
            list.push({
                ...createListItem(teamMember.id, fullName),
                isDisabled: teamMember.isDisabled,
                role: teamMember.role,
                userId: teamMember.userId,
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};

export const createGmsTeamMembersUpdateObject = (selectedTeamMemberUserIds) => ({
    selectedTeamMemberUserIds: selectedTeamMemberUserIds ? selectedTeamMemberUserIds : [],
    selectedTeamMemberUserId: !isArrayNullOrEmpty(selectedTeamMemberUserIds) ? selectedTeamMemberUserIds[0] : 0,
});

export const createGmsDeliverableItem = ({
                                             id = emptyGuid,
                                             applicationId = emptyGuid,
                                             deliverableCategory = 0,
                                             deliverableDescription = "",
                                             deliverableIdentifier = "",
                                             deliverableTitle = "",
                                             deliverableType = "",
                                             gmsIndicators = [],
                                             sourceDocumentation = "",
                                             fundingSourceType = 0,
                                             deliverableBudget = 0,
                                             q1Units = 0,
                                             q2Units = 0,
                                             q3Units = 0,
                                             q4Units = 0,
                                             totalUnits = 0,
                                             unitCost = 0,
                                         }, isExport = false) => {

    return {
        id,
        applicationId,
        categoryNumber: deliverableCategory,
        deliverableCategory,
        deliverableDescription,
        deliverableIdentifier,
        deliverableTitle,
        deliverableType,
        gmsIndicators: convertGmsIndicators(gmsIndicators, isExport),
        sourceDocumentation,
        fundingSourceType,
        deliverableBudget: validateDecimal(deliverableBudget),
        totalUnits: convertToValidNumber(totalUnits),
        unitCost: validateDecimal(unitCost),
        q1Units: convertToValidNumber(q1Units),
        q2Units: convertToValidNumber(q2Units),
        q3Units: convertToValidNumber(q3Units),
        q4Units: convertToValidNumber(q4Units),
    };
};

function convertGmsIndicators(gmsIndicators, isExport) {
    if (isExport) {
        return isString(gmsIndicators) ? gmsIndicators : JSON.stringify(gmsIndicators);
    }
    return isString(gmsIndicators) ? JSON.parse(gmsIndicators) : gmsIndicators;
}

export const createGmsSalary = ({
                                    id = emptyGuid,
                                    applicationId = emptyGuid,
                                    positionTitle = "",
                                    currentSalary = "",
                                    amountIncrease = "",
                                    newSalary = "",
                                    percentChange = "",
                                    fteChange = "",
                                    benefitsIncreased = "",
                                    changeReason = "",
                                    changeQualification = "",
                                    changeFunding = "",
                                    serviceImpact = "",
                                    recoupFunds = "",
                                }) => {

    return {
        id,
        applicationId,
        positionTitle,
        currentSalary,
        amountIncrease,
        newSalary,
        percentChange,
        fteChange,
        benefitsIncreased,
        changeReason,
        changeQualification,
        changeFunding,
        serviceImpact,
        recoupFunds,
    };
};
