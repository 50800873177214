import {isTrimmedStringEmpty} from "../components/common/commonUtilities";
import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from "./requests/RequestTypes";
import * as contexts from "../constants/contexts";
import * as behaviors from "../constants/behaviors";
import {getMockUsers} from "./AccountMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerUserApi {
    static getUsers(userSearchCriteria) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users',
            RequestTypes.POST,
            JSON.stringify(userSearchCriteria)));
    }

    static getUserById(userId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users',
            RequestTypes.GET,
            { id: userId }));
    }

    static resetUserPassword(userId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'accounts/{id}/reset-password',
            RequestTypes.POST,
            { id: userId },
            ApiSuccessMessages.USER_PASSWORD_RESET_EMAIL,
            `application/x-www-form-urlencoded`));
    }

    static deleteUser(userId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users',
            RequestTypes.DELETE,
            { id: userId },
            ApiSuccessMessages.USER_DELETED,
            `application/x-www-form-urlencoded`));
    }

    static reactivateUser(userId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users/reactivate',
            RequestTypes.PUT,
            { id: userId },
            ApiSuccessMessages.USER_REACTIVATED,
            `application/x-www-form-urlencoded`));
    }

    static getBehaviors() {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users/behaviors',
            RequestTypes.GET));
    }

    static saveUser(user) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'users',
            RequestTypes.PUT,
            JSON.stringify(user),
            ApiSuccessMessages.USER_SAVED));
    }

    static getInstitutionsWithTeamMembers() {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'admin/institutions/teamMembers',
            RequestTypes.GET));
    }

    static saveInstitutionWithTeamMembers(model) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'admin/institutions/teamMembers',
            RequestTypes.PUT,
            JSON.stringify(model),
            ApiSuccessMessages.INSTITUTIONTEAMMEMBER_SAVED));
    }

    static getProgramForFiscalAgent(fiscalAgentId, userId) {
        const agentPath = isTrimmedStringEmpty(fiscalAgentId) ? `` : `agents/${fiscalAgentId}/`;
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `users/${userId}/${agentPath}programs`,
            RequestTypes.GET));
    }

    static saveUserPrograms(user, selectedPrograms) {
        const institutionId = isTrimmedStringEmpty(user.institutionId) ? `` : `agents/${user.institutionId}/`;
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `users/${user.id}/${institutionId}programs`,
            RequestTypes.PUT,
            JSON.stringify(selectedPrograms),
            ApiSuccessMessages.USER_SAVED));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************

const behaviorsArray = [
    { text: "View Users" },
    { text: "Edit Users" },
    { text: "Certify Users" },
    { text: "Manage Users" },
    { text: "Manage Survey" },
    { text: "View Spp" },
    { text: "Edit Spp" },
    { text: "Certify Spp" },
    { text: "Manage Spp" },
    { text: "View BPIE"},
    { text: "Edit BPIE"}
];

const newUser = {
    userId: "not-really-a-bummer",
    username: "",
    email: "",
    status: "Active",
    institution: "",
    institutionId: "",
    isDeleted: false,
    behaviors: [
        {
            securityArea: contexts.USERS,
            claimValue: behaviors.VIEW,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.USERS,
            claimValue: behaviors.EDIT,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.USERS,
            claimValue: behaviors.CERTIFY,
            enabled: false,
            selected: false
        },
        {
            securityArea: contexts.USERS,
            claimValue: behaviors.MANAGE,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.SURVEYS,
            claimValue: behaviors.VIEW,
            enabled: false,
            selected: false
        },
        {
            securityArea: contexts.SURVEYS,
            claimValue: behaviors.EDIT,
            enabled: false,
            selected: false
        },
        {
            securityArea: contexts.SURVEYS,
            claimValue: behaviors.CERTIFY,
            enabled: false,
            selected: false
        },
        {
            securityArea: contexts.SURVEYS,
            claimValue: behaviors.MANAGE,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.SPP,
            claimValue: behaviors.VIEW,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.SPP,
            claimValue: behaviors.EDIT,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.SPP,
            claimValue: behaviors.CERTIFY,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.SPP,
            claimValue: behaviors.MANAGE,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.BPIE,
            claimValue: behaviors.VIEW,
            enabled: true,
            selected: false
        },
        {
            securityArea: contexts.BPIE,
            claimValue: behaviors.EDIT,
            enabled: true,
            selected: false
        }
    ]
};

class MockUserApi {
    static getUsers() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign({}, { data: getMockUsers(), totalRecords: 30 }));
            }, config.environment.delay);
        });
    }

    static getUserById(userId) {
        return new Promise((resolve) => {
            setTimeout(() => {
                let user;

                if (userId) {
                    user = getMockUsers().filter(u => {
                        return u.userId === userId;
                    })[0];
                }
                else {
                    user = newUser;
                }

                resolve(Object.assign({}, user));
            }, config.environment.delay);
        });
    }

    static resetUserPassword() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteUser() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static reactivateUser() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getBehaviors() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign([], behaviorsArray));
            }, config.environment.delay);
        });
    }

    static saveUser(user) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(Object.assign({}, user));
            }, config.environment.delay);
        });
    }

    static getInstitutionsWithTeamMembers() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveInstitutionWithTeamMembers() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static getProgramForFiscalAgent() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveUserPrograms() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const UserApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerUserApi : MockUserApi;
export default UserApi;