import {getCurrentDateTime} from "../../../components/common/dateUtilities";
import audit_outcomes from "../../../constants/auditOutcomes";
import {emptyGuid} from "../../../constants/config";

export const createActivityObject = (assessmentId, {
    acknowledgeNeedsRevisionComplete = false,
    activityId = emptyGuid,
    activityNumber = "",
    activitySummary = "",
    audits,
    capActivityResultDto,
    capActivityStatus = audit_outcomes.IN_PROGRESS.value,
    defaultActivitySummary = "",
    isDeletable = true,
    isResultsReadyToSubmit = false,
    isReviewEnabled = false,
    isSamplingActivity = false,
    monitoringAssessmentId,
    resources = "",
    timelineEndDate = "",
    timelineStartDate = ""
} = {}) => {
    const activityResults = createActivityResultObject(assessmentId, activityId, capActivityResultDto ?? {});
    const activityResultsReview = createActivityResultReviewObject(assessmentId, activityId, activityResults.activityResultId, activityResults.capActivityResultReview ?? {});

    const activity = {
        acknowledgeNeedsRevisionComplete,
        activityId,
        activityNumber,
        activitySummary: activitySummary || "",
        associatedProtocolPlanIds: [],
        audits: !audits ? [] : audits.map(audit => createCapAudit(audit)),
        capActivityStatus,
        defaultActivitySummary,
        isDeletable,
        isResultsReadyToSubmit,
        isReviewEnabled,
        isSamplingActivity,
        monitoringAssessmentId: monitoringAssessmentId || assessmentId,
        resources: resources || "",
        timelineEndDate: timelineEndDate || "",
        timelineStartDate: timelineStartDate || ""
    };

    return {...activityResults, ...activityResultsReview, ...activity};
};

const createCapAudit = ({
                            activityId,
                            auditCreatedBy,
                            auditId,
                            comments,
                            dateCreated,
                            isDistrictUser,
                            isLocked,
                            outcomeString
                        } = {}) => ({
    activityId,
    auditCreatedBy,
    auditId,
    comments: comments.map(comment => createCapAuditComment(comment)),
    dateCreated,
    isDistrictUser,
    isLocked,
    outcomeString
});

const createCapAuditComment = ({
                                   applicationUserId = "",
                                   auditCommentId = emptyGuid,
                                   auditId,
                                   auditorFirstName = "",
                                   auditorLastName = "",
                                   commentOwnerEmail = "",
                                   currentUserId = "",
                                   dateCreated = getCurrentDateTime(),
                                   dateModified,
                                   displayDate = getCurrentDateTime(),
                                   isCommentOwner = true,
                                   isVisibleToDistrict = true,
                                   text = ""
                               } = {}) => ({
    applicationUserId,
    auditCommentId,
    auditId,
    auditorFirstName,
    auditorLastName,
    commentOwnerEmail,
    currentUserId,
    dateCreated,
    dateModified,
    displayDate,
    isCommentOwner,
    isVisibleToDistrict,
    text
});

export const createActivityResultObject = (assessmentId, activityId, {
    acknowledgeNeedsRevisionComplete = false,
    activityResultId = emptyGuid,
    audits,
    capActivityResultReview = {},
    capActivityResultsStatus = audit_outcomes.IN_PROGRESS.value,
    dateCorrected = "",
    results = ""
} = {}) =>
    ({
        acknowledgeResultsNeedsRevisionComplete: acknowledgeNeedsRevisionComplete,
        activityId,
        activityResultId,
        capActivityResultReview,
        capActivityResultsStatus,
        dateCorrected,
        monitoringAssessmentId: assessmentId,
        resultAudits: !audits ? [] : audits.map(audit => createCapAudit(audit)),
        results
    });

export const createActivityResultReviewObject = (assessmentId, activityId, activityResultId, {
    reviewId = emptyGuid,
    comments = "",
    dateCorrectionDemonstrated = "",
    dateDocumentationReceived = "",
    dateDocumentationReviewed = "",
    resultsFromReview = [],
    timelineStatus = ""
} = {}) =>
    ({
        activityId,
        activityResultId,
        comments,
        dateCorrectionDemonstrated,
        dateDocumentationReceived,
        dateDocumentationReviewed,
        monitoringAssessmentId: assessmentId,
        resultsFromReview,
        reviewId,
        timelineStatus
    });

export const createNewCapAuditComment = (auditId) => createCapAuditComment({auditId});