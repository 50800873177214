import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import DisplayField from "../../../components/common/inputs/DisplayField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import SelectField from "../../../components/common/inputs/SelectField";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {emptyGuid} from "../../../constants/config";
import {IntakeStatusList} from "../../drConstants";

const DrAdminIntakeVersionEdit = ({
                                      handleSaveIntakeRequirementVersion,
                                      intakeVersion,
                                      isOnlyVersion,
                                      setEditVersionId
                                  }) => {
    const intakeVersionForm = useFormInputObject({...intakeVersion});

    const onSaveClick = (event) => {
        event.preventDefault();
        saveIntakeVersion();
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        setEditVersionId(null);
    };

    const validateForm = () => {
        clearInputFormErrorFields(intakeVersionForm);

        if (isTrimmedStringEmpty(intakeVersionForm.description.value))
            intakeVersionForm.description.setError("Required.")

        if (isTrimmedStringEmpty(intakeVersionForm.status.value.toString()))
            intakeVersionForm.status.setError("Required.")

        let isValid = inputFormIsValid(intakeVersionForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const saveIntakeVersion = () => {
        const isValid = validateForm();
        if (isValid) {
            let result = convertFormInputObjectToObject(intakeVersionForm);
            handleSaveIntakeRequirementVersion(result);
        }
    };

    const outputStatus = () => {
        return isOnlyVersion
            ? "Cannot be deleted until another version is created"
            : intakeVersion.hasResponses ? "Cannot be deleted as it contains responses" : "Can be deleted";
    }

    const canUpdateStatus = () => {
        return !isOnlyVersion && intakeVersion.intakeRequirements.length > 0;
    };

    return (
        <>
            <FormTable>
                {
                    intakeVersion.versionId !== emptyGuid &&
                    <DisplayField
                        label={`Deletion Status`}
                        name={`lblDeletionStatus`}
                        showLabel
                    >
                        {outputStatus()}
                    </DisplayField>
                }
                <DisplayField
                    label={`Date Created`}
                    name={`lblDateCreated`}
                    showLabel
                >
                    {intakeVersion.dateCreated}
                </DisplayField>
               <TextField
                   label={`Description`}
                   showLabel
                   {...intakeVersionForm.description}
               />
                {
                    canUpdateStatus() &&
                    <SelectField
                        label={`Status`}
                        options={IntakeStatusList}
                        showLabel
                        {...intakeVersionForm.status}
                    />
                }
                {
                    !canUpdateStatus() &&
                    <DisplayField
                        label={`Status`}
                        name={`lblStatus`}
                        showLabel
                    >
                        {intakeVersion.statusOutput}
                    </DisplayField>
                }
            </FormTable>
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>
                <Button name="btnCancel"
                        label="Cancel"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={onCancelClick}/>
            </ButtonBar>
        </>
    );
};

DrAdminIntakeVersionEdit.propTypes = {
    handleSaveIntakeRequirementVersion: PropTypes.func.isRequired,
    intakeVersion: PropTypes.object.isRequired,
    isOnlyVersion: PropTypes.bool.isRequired,
    setEditVersionId: PropTypes.func.isRequired
};

export default DrAdminIntakeVersionEdit;