import React, {useEffect} from 'react';
import PropTypes from "prop-types";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy, policyEvents} from "../../../../components/authorization/policies/GmsPolicy";
import AuditBar from "../../../../components/common/buttons/AuditBar";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import * as ActionBarPositions from "../../../../constants/ActionBarPositions";
import * as apiParameters from "../../../../constants/api/apiParameters";
import audit_outcomes from "../../../../constants/auditOutcomes";
import gmsApi from "../../gmsApi";
import {GmsApplicationEditingDisabledView} from "../GmsApplicationEditingDisabledView";
import {useFormInputObject, reinitializeInputFormFields} from "../../../../components/common/inputs/inputUtility";
import {createResponseFormObject} from "../../gmsObjectFactory";
import {GmsApplicationElementWrapper} from "../GmsApplicationElementWrapper";
import GmsApplicationFormAuditHistorySection from "./GmsApplicationFormAuditHistorySection";

export const GmsApplicationFormView = (props) => {
    const {
        actions,
        applicationId,
        callback,
        canUserEditApplication,
        elements,
        isFiscalAgentUser,
        isEditingDisabledMessageVisible,
        selectedSection,
        setSection,
        setShouldReinitializeResponses,
        shouldReinitializeResponses,
    } = props;

    const responses = useFormInputObject(createResponseFormObject(elements), callback);

    const handleAuditResponseAsync = (sectionId, auditAction) => {
        actions.executeApi(gmsApi.auditResponse, [applicationId, sectionId, auditAction])
            .then((result) => setSection(result));
    };

    const canAudit = allow(GmsPolicy, policyEvents.ADMIN.audit);

    const handleChangeOutcome = canAudit && !isFiscalAgentUser && canUserEditApplication
        ? (event) => {
            event.preventDefault();
            const auditOutcome = event.target.id.split("_")[0];

            switch (auditOutcome) {
                case audit_outcomes.ACCEPTED.name:
                    handleAuditResponseAsync(selectedSection.elementId, apiParameters.audit.APPROVE);
                    break;
                case audit_outcomes.NEEDS_REVISION.name:
                    handleAuditResponseAsync(selectedSection.elementId, apiParameters.audit.REVISE);
                    break;
                case audit_outcomes.RESET.name:
                    handleAuditResponseAsync(selectedSection.elementId, apiParameters.audit.RESET);
                    break;
            }
        }
        : undefined;

    useEffect(() => {
        if (shouldReinitializeResponses) {
            reinitializeInputFormFields(responses, createResponseFormObject(elements));
            setShouldReinitializeResponses(false);
        }
    }, [shouldReinitializeResponses]);

    return <>
        <GmsApplicationEditingDisabledView isEditingDisabledMessageVisible={isEditingDisabledMessageVisible}/>
        {
            handleChangeOutcome &&
            !isArrayNullOrEmpty(selectedSection.applicationSectionAudits) &&
            <AuditBar
                disabled={!canUserEditApplication}
                elementId={`application-section-audit-${selectedSection.elementId}`}
                onOutcomeChange={handleChangeOutcome}
                outcome={selectedSection.currentApplicationSectionAuditStatus}
                policy={GmsPolicy}
                policyEvent={policyEvents.ADMIN.audit}
                position={ActionBarPositions.TOP_RIGHT}
            />
        }
        <GmsApplicationFormAuditHistorySection
            applicationId={applicationId}
            actions={actions}
            audits={selectedSection.applicationSectionAudits}
            isFiscalAgentUser={isFiscalAgentUser}
            sectionId={selectedSection.elementId}
            setSection={setSection}
        />

        <FormTable className={`idea`}>
            {
                elements.map((element) => {
                    return <GmsApplicationElementWrapper
                        element={element}
                        isPrint={false}
                        responses={responses}
                        sectionId={selectedSection.elementId}
                        key={element.elementId}
                        {...props}
                    />;
                })
            }
        </FormTable>
    </>;
};


GmsApplicationFormView.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    canUserEditApplication: PropTypes.bool.isRequired,
    elements: PropTypes.array.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    isEditingDisabledMessageVisible: PropTypes.bool.isRequired,
    nextAmendmentNumber: PropTypes.number,
    selectedSection: PropTypes.object,
    setSection: PropTypes.func.isRequired,
    setShouldReinitializeResponses: PropTypes.func.isRequired,
    shouldReinitializeResponses: PropTypes.bool.isRequired,
};