import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../components/common/GridTable";
import ResultsGridForm from "../../../components/MonitoringCompliance/StudentCorrections/ResultsGridForm";
import * as correctionResultConstants from "../../../constants/monitoringCompliance/correctionResults";

export const StudentResultsGridForm = (
    {
        correctionResults,
        onResponseChange,
        isLoading
    }) =>
{
    const mainAreaWidthProps = {
        columnClass: "medium-offset-1",
        medium: "11"
    };

    return (
        <div>
            <p>Noted items are missing data or still require to be corrected.</p>
            <GridTable>
                <thead>
                <tr>
                    <th>Finding(s) of Noncompliance</th>
                </tr>
                </thead>
                <ResultsGridForm
                    correctionResults={correctionResults}
                    isLoading={isLoading}
                    onResponseChange={onResponseChange}
                    idPropName={"studentProtocolCorrectiveResultId"}
                    correctionOptions={correctionResultConstants.studentCorrectionOptions}
                    correctionsWithAdditionalInfoRequirement={correctionResultConstants.studentCorrectionsWithAdditionalInfoRequirement}
                    mainAreaWidthProps={mainAreaWidthProps}
                />
            </GridTable>
        </div>
    );
};

StudentResultsGridForm.propTypes = {
    correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onResponseChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};