import * as config from "../constants/config";
import * as ApiSuccessMessages from "../constants/api/ApiSuccessMessages";
import * as ApiUtility from "./ApiUtility";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerFileApi {
    static uploadFile(files, fileId) {
        let data = new FormData();

        // Currently the back end only support single file upload,
        // we need to throw an error if we receive more than one file
        if (files.length > 1)
            throw new Error('File upload limit exceeded. Only one file can be uploaded at a time.');

        data.append('file', files[0]);

        let request = AjaxRequestOptions(
            config.apiUrl + `files/${fileId}`,
            RequestTypes.POST,
            data,
            ApiSuccessMessages.files_api.upload.SUCCESS);

        request.processData = false;
        request.enctype = 'multipart/form-data';
        request.contentType = false;

        return ApiUtility.sendRequest(request);
    }

    static downloadFile(fileId) {
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}files/${fileId}`,
            RequestTypes.GET,
            null,
            ApiSuccessMessages.files_api.download.SUCCESS));
    }

    static deleteFile(fileId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
                config.apiUrl + 'files/' + fileId,
                RequestTypes.DELETE,
                null,
                ApiSuccessMessages.files_api.delete.SUCCESS));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockFileApi {
    static uploadFile() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static downloadFile() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteFile() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const FileApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerFileApi : MockFileApi;
export default FileApi;