import React from "react";
import PropTypes from "prop-types";
import TextField from "../common/inputs/TextField";
import Button from "../common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";

const PlanDateWindowForm = ({planDateWindow, onChange, onSave, onCancel, isLoading}) => {
	return (
		<tr id={planDateWindow.planDateWindowId}>
			<td>
				<TextField
					name={"name"}
					label={"Plan Name"}
					onChange={onChange}
					placeholder={"Plan Name"}
					value={planDateWindow.name}
					showLabel={false}/>
			</td>
			<td>New</td>
			<td className="button-cell">
				<ButtonBar position={ButtonBarPositions.TABLE}>
					<Button disabled={isLoading}
					        label={"save"}
					        showLabel={false}
					        name={"save"}
					        onClick={() => onSave(planDateWindow)}
					        buttonType={ButtonTypes.REVIEWED}/>
					<Button disabled={isLoading}
					        label={"cancel"}
					        showLabel={false}
					        name={"cancel"}
					        onClick={onCancel}
					        buttonType={ButtonTypes.CANCEL}/>
				</ButtonBar>
			</td>
		</tr>
	);
};

PlanDateWindowForm.propTypes = {
	planDateWindow: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default PlanDateWindowForm;