import React from 'react';
import PropTypes from 'prop-types';
import GridColumn from "../common/GridColumn";
import GridRow from "../common/GridRow";
import SelectField from "../common/inputs/SelectField";
import {gridConstants} from "../../constants/inputConstants";

const SppReviewDocumentHierarchySelector = ({selectPartItems, selectSectionItems, partId, sectionId, buildSectionMenu, redirectToSection, updatePageSubTitle}) => {
    const onSelectPartChange = (event) => {
        event.preventDefault();
        buildSectionMenu(event.target.value);
    };

    const onSelectSectionChange = (event) => {
        event.preventDefault();
        const sectionId = event.target.value;
        redirectToSection(sectionId);
        buildSectionMenu(partId, event.target.value);
        const indexSel = event.target.selectedIndex;
        const label = event.target[indexSel].label;
        updatePageSubTitle(label);
    };

    return (
        <GridRow rowClass={"filter-part-section"} medium={gridConstants.column.TWO}>
            <GridColumn>
                <SelectField
                    name="selectedPart"
                    showLabel={true}
                    label="Part"
                    onChange={onSelectPartChange}
                    options={selectPartItems}
                    value={partId}
                    includeDefaultOption={false}
                />
            </GridColumn>
            <GridColumn>
                <SelectField
                    name="selectedSection"
                    showLabel={true}
                    label="Section"
                    onChange={onSelectSectionChange}
                    options={selectSectionItems}
                    value={sectionId}
                    includeDefaultOption
                    defaultOption={"Select a Section"}
                />
            </GridColumn>
        </GridRow>
    );
};

SppReviewDocumentHierarchySelector.propTypes = {
    selectPartItems: PropTypes.array,
    selectSectionItems: PropTypes.array,
    partId: PropTypes.string,
    sectionId: PropTypes.string,
    buildSectionMenu: PropTypes.func.isRequired,
    redirectToSection: PropTypes.func.isRequired,
    updatePageSubTitle: PropTypes.func.isRequired
};

export default SppReviewDocumentHierarchySelector;