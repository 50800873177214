import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {actionTypes, gridConstants, inputStyles} from "../../../constants/inputConstants";
import Notification from "../Notification";
import SelectField from "./SelectField";
import Button from "../buttons/Button";
import ButtonMock from "../buttons/ButtonMock";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import GridRow from "../GridRow";
import GridColumn from "../GridColumn";
import {getRandomInt} from "../HtmlUtilities";
import {createFakeEvent} from "./inputUtility";
import {generateSelectListFromArray, isNullOrUndefined, isTrimmedStringEmpty} from "../commonUtilities";

const ListManagerField = ({
                              archivedLabel = "archived",
                              disabled = false,
                              error,
                              listLabel = "Selected Options",
                              listItems,
                              listValues = [],
                              managerLabel = "",
                              name,
                              onChange: handleChange,
                              selectLabel = "Available Options",
                              selectToAdd = false,
                              singleSelectionPerItem = true
                          }) => {
    const [identifier] = useState(getRandomInt());
    const listManagerName = `listManager${identifier}`;
    const id = name ? name : listManagerName;
    const selectedListName = `${listManagerName}_selected`;
    const selectFieldId = `listManagerSelectField${identifier}`;

    const [availableListItems, setAvailableListItems] = useState([]);
    const [selectedListItems, setSelectedListItems] = useState(generateSelectListFromArray([]));
    const [selectedAvailableItem, setSelectedAvailableItem] = useState("");

    const handleAddSelectListItem = (id, selectedValue) => {
        if(!selectedValue && !selectedAvailableItem) return;

        const updatedCode = listValues.map(item => item.value);
        updatedCode.push(!selectedValue ? selectedAvailableItem : selectedValue);
        handleCallback(id, updatedCode, {action: actionTypes.ADD, value: selectedAvailableItem});
    };

    const handleRemoveSelectedListItem = (id, selectedValue) => {
        const updatedCode = listValues.map(item => item.value).filter(c => c !== selectedValue);
        handleCallback(id, updatedCode, {action: actionTypes.REMOVE, value: selectedValue});
    };

    const handleOnChangeSelectField = (id, selectedValue) => {
        setSelectedAvailableItem(selectToAdd ? "" : selectedValue);
        return selectToAdd ? handleAddSelectListItem(id, selectedValue) : null;
    };

    const handleCallback = (id, value, source) => {
        handleChange(createFakeEvent(value, id, {source}));
    };

    const setSelectedAvailableItemIfNoDefault = (currentList) => {
        if(currentList.length > 0 && !selectToAdd)
            setSelectedAvailableItem(currentList[0].id);
        else
            setSelectedAvailableItem("");
    }

    useEffect(() => {
        let updateAvailableList = listItems;

        if (singleSelectionPerItem)
            updateAvailableList = listItems.filter(li => !listValues.some(selected => selected.value === li.value));

        setSelectedListItems(listValues);
        setAvailableListItems(updateAvailableList);
        setSelectedAvailableItemIfNoDefault(updateAvailableList);
    }, [listValues]);

    const wrapperClass = !isNullOrUndefined(error) && error.length > 0 ? inputStyles.ERROR : "";

    const isAddDisabled = disabled || availableListItems.length === 0;

    return (
        <div className={wrapperClass}>
            <label htmlFor={id}>{managerLabel}</label>
            {error && <Notification error={error}/> }
            <GridRow rowClass={"listManager"} id={id}>
                <GridColumn large={"6"} columnClass="listManager__available">
                    <label htmlFor={selectFieldId}>{selectLabel}</label>
                    <GridRow>
                        <GridColumn large={gridConstants.column.ELEVEN}>
                            <SelectField name={selectFieldId}
                                         label={""}
                                         options={availableListItems}
                                         onChange={(event) => handleOnChangeSelectField(name, event.target.value)}
                                         includeDefaultOption={selectToAdd}
                                         disabled={isAddDisabled}
                                         value={selectedAvailableItem}
                            />

                            {
                                !selectToAdd &&
                                <Button label={"Add"}
                                        name={actionTypes.ADD}
                                        showLabel={false}
                                        onClick={() => handleAddSelectListItem(name)}
                                        buttonType={ButtonTypes.ADD_INPUT}
                                        disabled={isAddDisabled}/>

                            }
                        </GridColumn>
                    </GridRow>

                </GridColumn>
                <GridColumn large={"6"} columnClass="listManager__selected">
                    <label htmlFor={selectedListName}>{listLabel}</label>
                    <ul id="selectedList" aria-labelledby={selectedListName}>
                        {
                            selectedListItems.map((li) => {
                                const selectedValue = li.value;
                                const archived = listItems.some(selected => selected.value === li.value) || isTrimmedStringEmpty(archivedLabel) ? "" : `(${archivedLabel})`;
                                return (
                                    <li key={selectedValue}>
                                        <ButtonMock label={`${li.text} ${archived}`}/>
                                        <Button name={"remove|" + selectedValue}
                                                label={"Remove"}
                                                showLabel={false}
                                                onClick={() => handleRemoveSelectedListItem(name, selectedValue)}
                                                buttonType={ButtonTypes.REMOVE_INPUT}
                                                disabled={disabled}
                                        />
                                    </li>);
                            })
                        }
                    </ul>
                </GridColumn>
            </GridRow>
        </div>
    );
};

ListManagerField.propTypes = {
    archivedLabel: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    listLabel: PropTypes.string,
    listItems: PropTypes.array.isRequired,
    listValues: PropTypes.array,
    managerLabel: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selectLabel: PropTypes.string,
    selectToAdd: PropTypes.bool,
    singleSelectionPerItem: PropTypes.bool
};

export default ListManagerField;