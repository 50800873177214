import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {convertToNumber, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import GridColumn from "../../../../components/common/GridColumn";
import DisplayField from "../../../../components/common/inputs/DisplayField";
import DollarField from "../../../../components/common/inputs/DollarField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../components/common/inputs/SelectField";
import TextField from "../../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import {gridConstants} from "../../../../constants/inputConstants";
import {APPLICATION_TYPE_FORM_LIST} from "../../gmsConstants";

export const GmsApplicationSettingsEdit = ({
                                               allFiscalAgents,
                                               application,
                                               handleCancelApplicationSettings,
                                               handleSaveApplicationSettings,
                                               isFinalAllocationOnlyEditable,
                                               isTargetedAllocationOnlyEditable
                                           }) => {
    const applicationSettingsForm = useFormInputObject({...application});
    const isAllocationOnlyEditable = isTargetedAllocationOnlyEditable || isFinalAllocationOnlyEditable;
    const handleCancelApplicationSettingsClick = (event) => {
        event.preventDefault();
        handleCancelApplicationSettings();
    }

    const handleSaveApplicationSettingsClick = (event) => {
        event.preventDefault();
        const isValid = validateForm();
        if (isValid) {
            const result = convertFormInputObjectToObject(applicationSettingsForm);
            handleSaveApplicationSettings(result);
        }
    };

    const validateForm = () => {
        clearInputFormErrorFields(applicationSettingsForm);

        if (isTrimmedStringEmpty(applicationSettingsForm.fiscalAgentId.value.toString()))
            applicationSettingsForm.fiscalAgentId.setError("Required");

        if (isTrimmedStringEmpty(applicationSettingsForm.applicationType.value.toString()))
            applicationSettingsForm.applicationType.setError("Required");

        if (isTrimmedStringEmpty(applicationSettingsForm.agencyCode.value))
            applicationSettingsForm.agencyCode.setError("Required");

        if (isTrimmedStringEmpty(applicationSettingsForm.grantNumber.value))
            applicationSettingsForm.grantNumber.setError("Required");

        if (isTrimmedStringEmpty(applicationSettingsForm.projectNumber.value))
            applicationSettingsForm.projectNumber.setError("Required");

        if (isTrimmedStringEmpty(applicationSettingsForm.tapsCode.value))
            applicationSettingsForm.tapsCode.setError("Required");

        const targetedAllocationValue = convertToNumber(applicationSettingsForm.targetedAllocation.value);
        if (targetedAllocationValue === 0)
            applicationSettingsForm.targetedAllocation.setError("Must be greater than 0");

        const finalAllocationValue = convertToNumber(applicationSettingsForm.finalAllocation.value);
        if (finalAllocationValue === 0 && isFinalAllocationOnlyEditable)
            applicationSettingsForm.finalAllocation.setError("Must be greater than 0");

        const finalFundsRollForwardValue = convertToNumber(applicationSettingsForm.finalFundsRollForward.value);
        if (finalFundsRollForwardValue === 0 && isFinalAllocationOnlyEditable)
            applicationSettingsForm.finalFundsRollForward.setError("Must be greater than 0");

        let isValid = inputFormIsValid(applicationSettingsForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const isNewApplication = application.applicationId === emptyGuid;

    return (
        <>
            <FormTable>
                <SelectField
                    {...applicationSettingsForm.fiscalAgentId}
                    defaultOption={`Select Fiscal Agent`}
                    disabled={isAllocationOnlyEditable || !isNewApplication}
                    includeDefaultOption
                    isInline
                    label={`Fiscal Agent`}
                    large={gridConstants.column.EIGHT}
                    medium={gridConstants.column.SIX}
                    options={allFiscalAgents}
                    showLabel
                />
                <SelectField
                    {...applicationSettingsForm.applicationType}
                    defaultOption={`Select Application Type`}
                    disabled={isAllocationOnlyEditable || !isNewApplication}
                    includeDefaultOption
                    isInline
                    label={`Application Type`}
                    large={gridConstants.column.EIGHT}
                    medium={gridConstants.column.SIX}
                    options={APPLICATION_TYPE_FORM_LIST}
                    showLabel
                />
                <TextField
                    {...applicationSettingsForm.agencyCode}
                    columnsLarge={gridConstants.column.EIGHT}
                    columnsMedium={gridConstants.column.SIX}
                    disabled={isAllocationOnlyEditable}
                    isInline
                    label={'Agency Code'}
                    showLabel
                />
                <TextField
                    {...applicationSettingsForm.grantNumber}
                    columnsLarge={gridConstants.column.EIGHT}
                    columnsMedium={gridConstants.column.SIX}
                    disabled={isAllocationOnlyEditable}
                    isInline
                    label={'Grant Number'}
                    showLabel
                />
                <TextField
                    {...applicationSettingsForm.projectNumber}
                    columnsLarge={gridConstants.column.EIGHT}
                    columnsMedium={gridConstants.column.SIX}
                    disabled={isAllocationOnlyEditable}
                    isInline
                    label={'Project Number'}
                    showLabel
                />
                <TextField
                    {...applicationSettingsForm.tapsCode}
                    columnsLarge={gridConstants.column.EIGHT}
                    columnsMedium={gridConstants.column.SIX}
                    disabled={isAllocationOnlyEditable}
                    isInline
                    label={'Taps Code'}
                    showLabel
                />
                <DollarField
                    {...applicationSettingsForm.targetedAllocation}
                    columnsLarge={gridConstants.column.EIGHT}
                    columnsMedium={gridConstants.column.SIX}
                    disabled={isFinalAllocationOnlyEditable}
                    label={'Targeted Allocation'}
                    showLabel
                />
                {
                    isFinalAllocationOnlyEditable &&
                    <>
                        <DollarField
                            {...applicationSettingsForm.finalAllocation}
                            columnsLarge={gridConstants.column.EIGHT}
                            columnsMedium={gridConstants.column.SIX}
                            label={'Final Allocation'}
                            showLabel
                        />
                        <DollarField
                            {...applicationSettingsForm.finalFundsRollForward}
                            columnsLarge={gridConstants.column.EIGHT}
                            columnsMedium={gridConstants.column.SIX}
                            label={'Final Roll Forward'}
                            showLabel
                        />
                    </>
                }
            </FormTable>
            <ButtonBar>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={"Save Application Settings"}
                    name="btnSave"
                    onClick={handleSaveApplicationSettingsClick}
                />
                <Button
                    buttonType={ButtonTypes.CANCEL}
                    label={"Cancel"}
                    name="btnCancel"
                    onClick={handleCancelApplicationSettingsClick}
                />
            </ButtonBar>
        </>
    );
};

GmsApplicationSettingsEdit.propTypes = {
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    application: PropTypes.object,
    handleCancelApplicationSettings: PropTypes.func.isRequired,
    handleSaveApplicationSettings: PropTypes.func.isRequired,
    isFinalAllocationOnlyEditable: PropTypes.bool.isRequired,
    isTargetedAllocationOnlyEditable: PropTypes.bool.isRequired
};