import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import {
    DrComplaintTimelineRequirementResponseNotApproved,
    DrComplaintTimelineRequirementResponseOptionsEnum
} from "../../drConstants";
import {TimelineRequirements} from "../drStateComplaintConstants";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import {getNewResolutionTypeBasedUponAbeyanceStatus} from "../StateComplaintsUtilities";
import {getTeamMemberId} from "../../drUtilities";
import DrStateComplaintTimelineView from "./DrStateComplaintTimelineView";
import {
    isArrayNullOrEmpty,
    isBoolean,
    isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import api from "../drStateComplaintsApi";

const DrStateComplaintTimelineContainer = ({
                                               actions,
                                               complaint,
                                               readOnly,
                                               handleSelectSection,
                                               handleScroll,
                                               handleUpdateComplaint,
                                               handleDataChanged,
                                               userDrTeamMemberRoles
                                           }) => {
    const [timelineResponses, setTimelineResponses] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [stageOpen, setStageOpen] = useState(0);

    const checkIsLoading = () => {
        let result = true;
        if (complaint && complaint.dateComplaintFiled) {
            if (complaint.timelineModel && complaint.timelineModel.timelineId) {
                if (!isArrayNullOrEmpty(timelineResponses))
                    result = false;
            } else
                result = false;
        }
        if(result)
            whichStageOpen(complaint.timelineModel.timelineResponseModels);

        return result;
    };

    const whichStageOpen = (allTimelineResponses) => {
        const firstMissingCompletionDate = allTimelineResponses.find(f => !f.dateCompleted);
        const whichStage = firstMissingCompletionDate ? firstMissingCompletionDate.stage : -1;
        setStageOpen(whichStage);
    };

    const handleSaveTimeline = async (form, abeyanceForm, callback = undefined) => {
        form.complaintId = complaint.id;
        form.timelineId = complaint.timelineModel.timelineId;
        form.currentResponseIdForRouting = complaint.timelineModel.currentResponseIdForRouting;

        if(!isValidMediationResponse(form)) return;

        await actions.executeApi(api.saveTimeline, [form])
            .then((result) => {
                if (callback)
                    callback();

                let complaintForm = drObjectFactory.createComplaintObject(result);
                complaintForm.resolutionType = getNewResolutionTypeBasedUponAbeyanceStatus(abeyanceForm.abeyanceStatus, result.resolutionType);
                complaintForm.abeyanceStartedDate = abeyanceForm.abeyanceStartedDate;
                complaintForm.additionalResponsesFromIntake.extendRelatedToDueProcess = abeyanceForm.extendRelatedToDueProcess;
                complaintForm.additionalResponsesFromIntake.relatedDueProcessId = abeyanceForm.relatedDueProcessId;
                complaintForm.abeyanceExitDate = abeyanceForm.abeyanceExitDate;
                handleSaveComplaint(complaintForm);
            });

        handleDataChanged(false);
        whichStageOpen(form.timelineResponseModels);
        scrollTimelineView();
    };

    const handleSaveComplaint = (form) => {
        actions.executeApi(api.saveComplaint, [form, false])
            .then((result) => {
                handleUpdateComplaint(result);
            });
    };

    const isValidMediationResponse = (form) => {
        let isValid = true;

        const mediationResponsesToCheck = ["mediationResponseOutcome", "mediationResponseExtensionGranted"];

        for(let response of form.timelineResponseModels) {
            if(!!response.dateCompleted && response.timelineRequirementType === TimelineRequirements.MediationTask) {
                for(let mediationResponse of mediationResponsesToCheck) {
                    const missingResponse = isTrimmedStringEmpty(response[mediationResponse]) && !isBoolean(response[mediationResponse]);
                    if (missingResponse) {
                        isValid = false;
                        if (!response[`${mediationResponse}Error`])
                            handleChangeMediationResponse(response.timelineRequirementId, `${mediationResponse}Error`, "Required");
                    } else if (!missingResponse && !!response[`${mediationResponse}Error`])
                        handleChangeMediationResponse(response.timelineRequirementId, `${mediationResponse}Error`, "");
                }
            }
        }

        if(!isValid)
            NotifyUser.Error("Responses regarding mediation have missing additional details.");

        return isValid;
    };

    const handleChangeCompletionDate = (timelineRequirementId, dateCompleted) => {
        let updatedState = [...timelineResponses];
        let updatedRequirement = updatedState.find(d => d.timelineRequirementId === timelineRequirementId);
        if (updatedRequirement) {
            updatedRequirement.responderId = getTeamMemberId(userDrTeamMemberRoles, updatedRequirement.roleResponsibleForCompletion, true, true);
            updatedRequirement.dateCompleted = dateCompleted;
            if(dateCompleted) {
                for (const responseModel of updatedRequirement.routingResponseModels.filter(f => f.response === DrComplaintTimelineRequirementResponseNotApproved)) {
                    responseModel.response = DrComplaintTimelineRequirementResponseOptionsEnum.Approved;
                }
            }
            setTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const handleChangeMediationResponse = (timelineRequirementId, id, value) => {
        let updatedState = [...timelineResponses];
        let updatedRequirement = updatedState.find(d => d.timelineRequirementId === timelineRequirementId);
        if (updatedRequirement) {
            updatedRequirement[id] = value;
            setTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const scrollTimelineView = (scrollToContainerId) => {
        if (isLoadingData) return;
        const id = !scrollToContainerId ? "timeline-view" : scrollToContainerId;
        handleScroll(id);
    };

    useEffect(() => {
        if (!complaint || !complaint.timelineModel || !complaint.timelineModel.timelineResponseModels) return;
        const timelineResponseModels = complaint.timelineModel.timelineResponseModels;
        if (!isArrayNullOrEmpty(timelineResponseModels) && timelineResponses !== timelineResponseModels)
            setTimelineResponses(timelineResponseModels);
    }, [[complaint]]);

    useEffect(() => {
        setIsLoadingData(checkIsLoading());
    }, [[complaint, timelineResponses]]);

    if (isLoadingData) return null;

    return <DrStateComplaintTimelineView
        complaint={complaint}
        dateFiled={complaint.dateComplaintFiled}
        handleChangeCompletionDate={handleChangeCompletionDate}
        handleChangeMediationResponse={handleChangeMediationResponse}
        handleDataChanged={handleDataChanged}
        handleSaveTimeline={handleSaveTimeline}
        handleSelectSection={handleSelectSection}
        hasNonAbeyanceExtension={complaint.hasNonAbeyanceExtension}
        readOnly={readOnly}
        scrollTimelineView={scrollTimelineView}
        stageOpen={stageOpen}
        timelineModel={complaint.timelineModel}
        timelineResponses={timelineResponses}
        userDrTeamMemberRoles={userDrTeamMemberRoles}
    />;
};

DrStateComplaintTimelineContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    complaint: PropTypes.object.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintTimelineContainer;
