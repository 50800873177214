import * as contexts from "../../../constants/contexts";
import {
    DisputeResolutionType, DrFiltersComplaintStatus,
    DrRolesAssociatedToComplaint, DrRolesAssociatedToDueProcess
} from "../../../DisputeResolution/drConstants";
import {DrFilterDueProcessStatus} from "../../../DisputeResolution/DueProcesses/drDueProcessConstants";
import {verifyAccessToContext} from "../../authorization/AuthorizationUtilities";
import LocalStorage from "../../shared/LocalStorage";
import {getPropName, isArrayNullOrEmpty} from "../commonUtilities";
import * as commonUtilities from "../commonUtilities";
import {convertToDate} from "../dateUtilities";
import {cloneDeep} from 'lodash/lang';
import {unionBy} from 'lodash/array';
import {
    searchCriteria,
    SearchFilterElements
} from "./searchCriteria";

export function DrSearchCriteria(dateWindowId = "", dateRange = "", startDate = "", endDate = "", sortByColumn = "districtName", baseSortByColumn = "districtName", disputeResolutionTypeWithDefault = "") {
    const showDrStateComplaint = verifyAccessToContext(contexts.STATE_COMPLAINTS, LocalStorage.getBehaviorClaims());
    const showDrMediation = verifyAccessToContext(contexts.MEDIATIONS, LocalStorage.getBehaviorClaims());
    const defaultDisputeResolutionType = showDrStateComplaint ? DisputeResolutionType.StateComplaints :
        showDrMediation ? DisputeResolutionType.Mediation : DisputeResolutionType.DueProcess;
    const defaultStatus = defaultDisputeResolutionType === DisputeResolutionType.DueProcess ? DrFilterDueProcessStatus.Pending : DrFiltersComplaintStatus.Open;

    return {
        ...searchCriteria("DrSearchCriteria", sortByColumn, baseSortByColumn, 5, dateRange, dateWindowId, endDate, startDate, defaultStatus),
        ...{
            advocateAttorney: "",
            assignedALJ: "",
            caseNumber: "",
            changeOfPlacement: "",
            citationMulti: [],
            citations: [],
            complaintType: "",
            disputeResolutionType: defaultDisputeResolutionType,
            disputeResolutionTypeWithDefault: disputeResolutionTypeWithDefault,
            districtMulti: [],
            enrollmentLocation: "",
            finalOrderIssue: [],
            issuesCodeMulti: [],
            issueCodes: [],
            itemsToPin: [],
            outcomeFindings: "",
            outcomeFindingsMulti: [],
            parentRepresentationStatus: "",
            pinItem: "",
            prevailingParty: "",
            resolutionSessionOutcome: "",
            statusMulti: [],
            systemic: "",
            teamMemberMulti: [],
            teamMemberRole: "",
            teamMemberRoleMulti: [],
            tiedToDueProcessMediation: "",
            userStatus: "",
            applyFiltersAndOrder: ApplyFiltersAndOrder,
            clearOptionalSearchItems: ClearOptionalSearchItems,
            setVisibilityRules: SetVisibilityRules,
            areRulesCurrent: AreRulesCurrent
        }
    };
}

export const DrFilterElements = {
    ...SearchFilterElements,
    ...{
        advocateAttorney: "advocateAttorney",
        assignedALJ: "assignedALJ",
        caseNumber: "caseNumber",
        changeOfPlacement: "changeOfPlacement",
        citationMulti: "citationMulti",
        complaintType: "complaintType",
        disputeResolutionTypeWithDefault: "disputeResolutionTypeWithDefault",
        districtMulti: "districtMulti",
        enrollmentLocation: "enrollmentLocation",
        finalOrderIssue: "finalOrderIssue",
        issuesCodeMulti: "issuesCodeMulti",
        outcomeFindings: "outcomeFindings",
        outcomeFindingsMulti: "outcomeFindingsMulti",
        parentRepresentationStatus: "parentRepresentationStatus",
        pinItem: "pinItem",
        prevailingParty: "prevailingParty",
        resolutionSessionOutcome: "resolutionSessionOutcome",
        statusMulti: "statusMulti",
        systemic: "systemic",
        teamMemberMulti: "teamMemberMulti",
        teamMemberRole: "teamMemberRole",
        teamMemberRoleMulti: "teamMemberRoleMulti",
        tiedToDueProcessMediation: "tiedToDueProcessMediation",
        userStatus: "userStatus"
    }
}

const dateWindowAndDrTypeFilters = [DrFilterElements.dateWindowId, DrFilterElements.disputeResolutionType];

export const DrFilterVisibilityRules = {
    DrDistrictSummary: [...dateWindowAndDrTypeFilters, DrFilterElements.district, DrFilterElements.status, DrFilterElements.clearButton],
    DrDistrictTiles: [...dateWindowAndDrTypeFilters],
    DrCaseList: [...dateWindowAndDrTypeFilters, DrFilterElements.dateRange, DrFilterElements.district, DrFilterElements.status, DrFilterElements.outcomeFindings, DrFilterElements.teamMember, DrFilterElements.teamMemberRole, DrFilterElements.clearButton, DrFilterElements.caseNumber],
    DrSummary: [DrFilterElements.dateWindowId, DrFilterElements.disputeResolutionTypeWithDefault, DrFilterElements.RecordsPerPage],
    DrSummaryDueNow: [DrFilterElements.dateRange, DrFilterElements.includeNextInSection],
    DrSummaryUpcoming: [DrFilterElements.dateRange, DrFilterElements.pinItem],
    DrCaseload: [DrFilterElements.dateWindowId, DrFilterElements.userStatus, DrFilterElements.disputeResolutionTypeWithDefault],
    DrStateComplaintCaseResearch: [
        DrFilterElements.advocateAttorney,
        DrFilterElements.citationMulti,
        DrFilterElements.dateWindowId,
        DrFilterElements.dateRangeCustomOnly,
        DrFilterElements.disputeResolutionType,
        DrFilterElements.districtMulti,
        DrFilterElements.enrollmentLocation,
        DrFilterElements.issuesCodeMulti,
        DrFilterElements.outcomeFindingsMulti,
        DrFilterElements.statusMulti,
        DrFilterElements.systemic,
        DrFilterElements.teamMemberMulti,
        DrFilterElements.teamMemberRoleMulti,
        DrFilterElements.tiedToDueProcessMediation,
        DrFilterElements.clearButton
    ],
    DrDueProcessCaseResearch: [
        DrFilterElements.dateWindowId,
        DrFilterElements.dateRangeCustomOnly,
        DrFilterElements.districtMulti,
        DrFilterElements.disputeResolutionType,
        DrFilterElements.statusMulti,
        DrFilterElements.teamMemberMulti,
        DrFilterElements.assignedALJ,
        DrFilterElements.complaintType,
        DrFilterElements.finalOrderIssue,
        DrFilterElements.changeOfPlacement,
        DrFilterElements.parentRepresentationStatus,
        DrFilterElements.prevailingParty,
        DrFilterElements.resolutionSessionOutcome,
        DrFilterElements.clearButton
    ],
    DrMediationCaseResearch: [
        DrFilterElements.dateWindowId,
        DrFilterElements.dateRangeCustomOnly,
        DrFilterElements.districtMulti,
        DrFilterElements.disputeResolutionType,
        DrFilterElements.statusMulti,
        DrFilterElements.clearButton
    ],
}

function SetVisibilityRules(whichRules) {
    const rulesName = getPropName(DrFilterVisibilityRules, whichRules);
    const baseSortByColumnId = rulesName === getPropName(DrFilterVisibilityRules, DrFilterVisibilityRules.DrCaseload) ? "fullName" : "districtName";
    this.baseSetVisibilityRules(rulesName, whichRules, baseSortByColumnId);
}

function AreRulesCurrent(whichRules) {
    return this.baseAreRulesCurrent(whichRules, DrFilterVisibilityRules);
}

function ClearOptionalSearchItems(clearTeamMembersAsWell = true, clearOutAll = false) {
    this.baseClearOptionalSearchItems(clearTeamMembersAsWell, clearOutAll);

    if(this.isVisible(DrFilterElements.districtMulti) || clearOutAll)
        this.districtMulti = [];

    if(this.isVisible(DrFilterElements.pinItem) || clearOutAll)
        this.pinItem = "";

    if(this.isVisible(DrFilterElements.statusMulti) || clearOutAll) {
        this.statusMulti = [];
    }

    if(this.isVisible(DrFilterElements.teamMemberMulti) || clearOutAll) {
        this.teamMemberMulti = [];
        if(clearTeamMembersAsWell)
            this.teamMembers = [];
    }

    if(this.isVisible(DrFilterElements.teamMemberRole) || clearOutAll)
        this.teamMemberRole = "";

    if(this.isVisible(DrFilterElements.teamMemberRoleMulti) || clearOutAll)
        this.teamMemberRoleMulti = [];

    if(this.isVisible(DrFilterElements.userStatus) || clearOutAll)
        this.userStatus = "";

    if(this.isVisible(DrFilterElements.outcomeFindings) || clearOutAll)
        this.outcomeFindings = "";

    if(this.isVisible(DrFilterElements.outcomeFindingsMulti) || clearOutAll)
        this.outcomeFindingsMulti = [];

    if(this.isVisible(DrFilterElements.issuesCodeMulti) || clearOutAll)
        this.issuesCodeMulti = [];

    if(this.isVisible(DrFilterElements.citationMulti) || clearOutAll)
        this.citationMulti = [];

    if(this.isVisible(DrFilterElements.advocateAttorney) || clearOutAll)
        this.advocateAttorney = "";

    if(this.isVisible(DrFilterElements.enrollmentLocation) || clearOutAll)
        this.enrollmentLocation = "";

    if(this.isVisible(DrFilterElements.tiedToDueProcessMediation) || clearOutAll)
        this.tiedToDueProcessMediation = "";

    if(this.isVisible(DrFilterElements.systemic) || clearOutAll)
        this.systemic = "";

    if(this.isVisible(DrFilterElements.complaintType) || clearOutAll)
        this.complaintType = "";

    if(this.isVisible(DrFilterElements.assignedALJ) || clearOutAll)
        this.assignedALJ = "";

    if(this.isVisible(DrFilterElements.resolutionSessionOutcome) || clearOutAll)
        this.resolutionSessionOutcome = "";

    if(this.isVisible(DrFilterElements.changeOfPlacement) || clearOutAll)
        this.changeOfPlacement = "";

    if(this.isVisible(DrFilterElements.prevailingParty) || clearOutAll)
        this.prevailingParty = "";

    if(this.isVisible(DrFilterElements.parentRepresentationStatus) || clearOutAll)
        this.parentRepresentationStatus = "";

    if(this.isVisible(DrFilterElements.finalOrderIssue) || clearOutAll)
        this.finalOrderIssue = [];

    if(this.isVisible(DrFilterElements.caseNumber) || clearOutAll)
        this.caseNumber = "";
}

function ApplyFiltersAndOrder(summaryData, includePinnedItems = false, filterByDisputeResolutionTypeWithDefault = false) {
    if(isArrayNullOrEmpty(summaryData))
        return summaryData;

    let summary = cloneDeep(summaryData);

    if(!isArrayNullOrEmpty(summary) && this.disputeResolutionTypeWithDefault && filterByDisputeResolutionTypeWithDefault) {
        summary = summary.filter(f => f.type === this.disputeResolutionTypeWithDefault);
    }

    let pinnedItems = cloneDeep(summary.filter(f => f.isPinned));

    summary = this.baseApplyFilters(summary);

    if(!isArrayNullOrEmpty(summary) && this.userStatus && this.isVisible(DrFilterElements.userStatus)) {
        summary = summary.filter(f => f.userStatus === this.userStatus);
    }

    if(!isArrayNullOrEmpty(summary) && this.caseNumber && this.isVisible(DrFilterElements.caseNumber))
        summary = summary.filter(f => f.title.toLowerCase().indexOf(this.caseNumber.toLowerCase()) > -1);

    if(!isArrayNullOrEmpty(summary) && this.outcomeFindings && this.isVisible(DrFilterElements.outcomeFindings) && this.disputeResolutionType === DisputeResolutionType.StateComplaints)
        summary = summary.filter(f => f.outcomeFindings && f.outcomeFindings.toLowerCase().indexOf(this.outcomeFindings.toLowerCase()) > -1);

    if(!isArrayNullOrEmpty(summary) && this.teamMember && this.isVisible(DrFilterElements.teamMember) && this.teamMembers && this.teamMembers.length > 0) {
        const userDetails = this.teamMembers.filter(f => f.userId === this.teamMember);

        const rolesForType = this.disputeResolutionType === DisputeResolutionType.StateComplaints ? DrRolesAssociatedToComplaint : DrRolesAssociatedToDueProcess;

        summary = summary.filter(f => f.teamMembers && f.teamMembers.find(m => m.userId.toString() === this.teamMember.toString())
            || f.tasks.find(t => userDetails && userDetails.find(u => u.role.toString() === t.associatedRole && rolesForType.indexOf(parseInt(u.role)) === -1)));

        if (!isArrayNullOrEmpty(summary) && this.teamMemberRole && this.isVisible(DrFilterElements.teamMemberRole)) {
            summary = summary.filter(f => f.teamMembers && f.teamMembers.find(m => m.userId.toString() === this.teamMember.toString() && m.role.toString() === this.teamMemberRole.toString())
                || f.tasks.find(t => t.associatedRole === this.teamMemberRole.toString() && userDetails && userDetails.find(u => u.role.toString() === t.associatedRole && rolesForType.indexOf(parseInt(u.role)) === -1)));
        }
    }

    if(!isArrayNullOrEmpty(summary) && this.startDate && (this.isVisible(DrFilterElements.dateRange) || this.isVisible(DrFilterElements.dateRangeCustomOnly))) {
        for(let element of pinnedItems)
            element.tasks = element.tasks.filter(t => convertToDate(t.dueDate) >= convertToDate(this.startDate));
    }

    if(!isArrayNullOrEmpty(summary) && this.endDate && (this.isVisible(DrFilterElements.dateRange) || this.isVisible(DrFilterElements.dateRangeCustomOnly))) {
        for(let element of pinnedItems)
            element.tasks = element.tasks.filter(t => convertToDate(t.dueDate) <= convertToDate(this.endDate));
    }

    summary = this.baseSort(summary);

    pinnedItems = pinnedItems.sort(commonUtilities.dynamicSort(this.SortByColumn, this.SortDescending, this.BaseSortByColumn, this.SortType));

    if(isArrayNullOrEmpty(pinnedItems) || !includePinnedItems) return summary;

    return unionBy(pinnedItems, summary, 'id');
}