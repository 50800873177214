import React from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import ActionBar from "../../../components/common/buttons/ActionBar";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintCitation from "../Citations/DrStateComplaintCitation";
import DrStateComplaintStudent from "../Students/DrStateComplaintStudent";
import FormTable from "../../../components/common/FormTable";

const DrStateComplaintIssueComponent = ({
	                                        handleClickDelete,
	                                        handleClickEdit,
	                                        isEditMode,
	                                        issue,
	                                        readOnly
                                        }) => {

	return (
		<div>
			{
				!readOnly &&
				<ActionBar
					elementId={`issue-${issue.issueId}`}
					onEditClick={() => handleClickEdit(issue.issueId)}
					onDeleteClick={(e) => handleClickDelete(e, issue.issueId)}
					disabled={isEditMode}
				/>
			}
			<FormTable>
				{
					issue.issueScope &&
					<DisplayField
						name="issueScope"
						label="Type"
						showLabel
					>
						{issue.issueScope}
					</DisplayField>
				}
				{
					issue.issueDescription &&
					<DisplayField
						name="issueDescription"
						label="Description"
						showLabel
					>
						{issue.issueDescription}
					</DisplayField>
				}
				{
					issue.generalCitationDetails &&
					<DisplayField
						name="generalCitationDetails"
						label="General Citation Details"
						showLabel
					>
						{issue.generalCitationDetails}
					</DisplayField>
				}
				{
					!isArrayNullOrEmpty(issue.citations) &&
					<DrStateComplaintCitation citations={issue.citations} />
				}
				{
					!isArrayNullOrEmpty(issue.issueCodeDescriptions) &&
					<DisplayField
						name="issueCodeDescriptions"
						label="Codes"
						showLabel
					>
						<ul>
							{
								issue.issueCodeDescriptions.map((issue, key) =>
									<li key={key}>{issue}</li>)
							}
						</ul>
					</DisplayField>
				}
				{
					!isArrayNullOrEmpty(issue.students) &&
					<DrStateComplaintStudent
						students={issue.students}
					/>
				}
			</FormTable>
		</div>
	);
};

DrStateComplaintIssueComponent.propTypes = {
	handleClickDelete: PropTypes.func.isRequired,
	handleClickEdit: PropTypes.func.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	readOnly: PropTypes.bool.isRequired,
	issue: PropTypes.object.isRequired,
};

export default DrStateComplaintIssueComponent;