import React from 'react';
import PropTypes from 'prop-types';
import * as AuthorizationUtilities from "../../../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../../../components/authorization/policies/MonitoringCompliancePolicy";
import {convertToBoolean, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {dateCompare} from "../../../../components/common/dateUtilities";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {
    clearInputFormErrorFields, convertFormInputObjectToObject,
    inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import audit_outcomes from "../../../../constants/auditOutcomes";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {monitoring_compliance} from "../../../../constants/policyEvents";
import ActivityForm from "../ActivitiesComponents/ActivityForm";
import ActivityResultsForm from "../ActivitiesComponents/ActivityResultsForm";
import ActivityResultsReviewForm from "../ActivitiesComponents/ActivityResultsReviewForm";
import {createActivityObject} from "../capObjectFactory";
import {enableCapActivityResults, getAssociatedProtocolPlanIds, isStatusEditable} from "../capUtilities";

const DistrictActivityForm = ({
                          assessmentId,
                          capActivity,
                          isLoading,
                          onCancel,
                          onSave,
                          protocolCorrectiveActions,
                          readOnly
                      }) => {
    const formattedCapActivity = createActivityObject(assessmentId, capActivity);
    formattedCapActivity.associatedProtocolPlanIds = getAssociatedProtocolPlanIds(formattedCapActivity.activityId, protocolCorrectiveActions);
    const activityForm = useFormInputObject(formattedCapActivity);

    const isCapActivityEditable = !readOnly && isStatusEditable(activityForm.capActivityStatus.value);
    const isCapActivityResultsEnabled = enableCapActivityResults(activityForm.capActivityStatus.value);
    const isCapActivityResultsEditable = !readOnly && isCapActivityResultsEnabled && isStatusEditable(activityForm.capActivityResultsStatus.value);
    const isEditable = isCapActivityEditable || isCapActivityResultsEditable;

    const validateActivityForm = () => {
        const isResourcesRequired = protocolCorrectiveActions.every(i => i.capCorrectionRequiresResources);

        if (isTrimmedStringEmpty(activityForm.activitySummary.value) && !activityForm.isSamplingActivity.value)
            activityForm.activitySummary.setError("Missing activity summary.");

        if (isResourcesRequired && isTrimmedStringEmpty(activityForm.resources.value))
            activityForm.resources.setError("Missing activity resources.");

        if (isTrimmedStringEmpty(activityForm.timelineStartDate.value))
            activityForm.timelineStartDate.setError("Missing timeline start date.");
        else if(!isTrimmedStringEmpty(activityForm.timelineEndDate.value) && dateCompare(activityForm.timelineStartDate.value, activityForm.timelineEndDate.value) > 0)
            activityForm.timelineStartDate.setError("Start date needs to come before end date.")

        if (isTrimmedStringEmpty(activityForm.timelineEndDate.value))
            activityForm.timelineEndDate.setError("Missing timeline end date.");

        if(activityForm.associatedProtocolPlanIds.value.length === 0)
            activityForm.associatedProtocolPlanIds.setError("Missing associated protocols.");
    };

    const validateActivityResultsForm = () => {
        if (isTrimmedStringEmpty(activityForm.results.value))
            activityForm.results.setError("Missing activity results.");

        if (isTrimmedStringEmpty(activityForm.dateCorrected.value))
            activityForm.dateCorrected.setError("Missing date corrected.");

        if(activityForm.capActivityResultsStatus.value === audit_outcomes.NEEDS_REVISION.value && !convertToBoolean(activityForm.acknowledgeResultsNeedsRevisionComplete.value))
            activityForm.acknowledgeResultsNeedsRevisionComplete.setError("Missing acknowledgement that the revisions are completed.")
    };

    const validateForm = () => {
        clearInputFormErrorFields(activityForm);

        if(isCapActivityEditable)
            validateActivityForm();

        if(isCapActivityResultsEditable)
            validateActivityResultsForm();

        let isValid = inputFormIsValid(activityForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleClickSave = (event, shouldSubmit = false) => {
        event.preventDefault();
        if (validateForm()) {
            if (!shouldSubmit || confirm("Are you sure you want to submit the activity results?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
                const form = convertFormInputObjectToObject(activityForm);
                onSave(form, isCapActivityResultsEnabled, shouldSubmit);
            }
        }
    };

    const handleClickCancel = (event) => {
        event.preventDefault();
        onCancel();
    }

    const canSubmit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentCorrections.SUBMIT);

    return (
        <>
            {
                isCapActivityResultsEnabled &&
                <>
                    {
                        activityForm.isReviewEnabled.value &&
                        <ActivityResultsReviewForm
                            activityResultsReviewForm={activityForm}
                            canDistrictEdit={!readOnly}
                            isLoading={isLoading}
                        />
                    }
                    <ActivityResultsForm
                        activityResultsForm={activityForm}
                        disabledForm={isLoading || !isCapActivityResultsEditable}
                    />
                </>
            }

            <ActivityForm
                activityForm={activityForm}
                disabledForm={isLoading || !isCapActivityEditable}
                isLoading={isLoading}
                protocolCorrectiveActions={protocolCorrectiveActions}
            />

            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                {
                    isEditable &&
                    <Button
                        buttonType={ButtonTypes.SAVE}
                        disabled={isLoading}
                        label={"Save"}
                        name={"btnSave"}
                        onClick={handleClickSave}
                    />
                }
                {
                    canSubmit &&
                    isEditable &&
                    isCapActivityResultsEnabled &&
                    <Button
                        buttonType={ButtonTypes.SUBMIT}
                        disabled={isLoading}
                        label={"Save and Submit"}
                        name={"btnSaveSubmit"}
                        onClick={(e) => handleClickSave(e, true)}
                    />
                }
                <Button
                    buttonType={isEditable ? ButtonTypes.CANCEL : ButtonTypes.BACK}
                    disabled={isLoading}
                    label={isEditable ? "Cancel" : "Return to Activities"}
                    name={"btnCancel"}
                    onClick={handleClickCancel}
                />
            </ButtonBar>
        </>
    );
};

DistrictActivityForm.propTypes = {
    assessmentId: PropTypes.string.isRequired,
    capActivity: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    protocolCorrectiveActions: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool.isRequired
};

export default DistrictActivityForm;