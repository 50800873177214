import {mockResolveApiCall} from "../../../api/ApiUtility";
import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_ROOT} from "../bpieLocations"
import * as mockData from "../bpieMockData";
import {BpieApiMessages} from "../bpieConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerSbpieAssessmentApi {
    static getSbpieAssessment(schoolBpieId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}`,
                RequestTypes.GET));
    }

    static saveSbpieIndicatorResponse(schoolBpieId, bpieIndicatorId, response){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/indicator/${bpieIndicatorId}/response`,
                RequestTypes.PUT,
                JSON.stringify(response),
                BpieApiMessages.ASSESSMENT.UPDATE_RESPONSE.SUCCESS));
    }

    static saveSbpieIndicatorPriorities(schoolBpieId, priorityBpieIndicatorIds){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/${schoolBpieId}/priorities`,
                RequestTypes.PUT,
                JSON.stringify(priorityBpieIndicatorIds),
                BpieApiMessages.ASSESSMENT.SAVE_PRIORITIES.SUCCESS
            )
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockSbpieAssessmentApi {
    static getSbpieAssessment(){
        return mockResolveApiCall(mockData.getSchoolBpie());
    }

    static saveSbpieIndicatorResponse(schoolBpieId, bpieIndicatorId, response){
        mockData.saveIndicatorResponse(bpieIndicatorId, response);
        return mockResolveApiCall({}, BpieApiMessages.ASSESSMENT.UPDATE_RESPONSE.SUCCESS);
    }

    static saveSbpieIndicatorPriorities(schoolBpieId, priorityBpieIndicatorIds){
        mockData.saveIndicatorPriorities(priorityBpieIndicatorIds);
        return mockResolveApiCall({}, BpieApiMessages.ASSESSMENT.SAVE_PRIORITIES.SUCCESS);
    }
}

const sbpieAssessmentApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerSbpieAssessmentApi : MockSbpieAssessmentApi;
export default sbpieAssessmentApi;