import React, {useState} from "react";
import PropTypes from 'prop-types';
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintResponseActionDeadline from "./DrStateComplaintResponseActionDeadline";
import {ComplaintActionType} from "../drStateComplaintConstants";
import {StateComplaintPolicy} from "../../../components/authorization/policies/StateComplaintPolicy";
import {dispute_resolution} from "../../../constants/policyEvents";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import AccountUtility from "../../../api/AccountUtility";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";

const DrStateComplaintResponseAction = ({
	                                        action,
	                                        editDeadlineId,
	                                        handleAuditResponseAsync,
	                                        handleSaveAuditCommentAsync,
	                                        handleSaveResponseAsync,
	                                        handleSaveResponseReviewAsync,
	                                        issueId,
	                                        setEditDeadlineId
                                        }) => {

	const [isDistrictUser] = useState(AccountUtility.isDistrictUser());
	const [canSubmit] = useState(AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.response.SUBMIT));
	const [canAudit] = useState(AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.response.AUDIT));
	const [canRespond] = useState(AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.response.MODIFY));
	const [canReview] = useState(AuthorizationUtilities.allow(StateComplaintPolicy, dispute_resolution.stateComplaint.response.REVIEW));

	return (
		<section className={"actionResponse"}>
			<DisplayField
				displayClass={"bold"}
				name="actionType"
				label="Type"
			>
				{
					action.actionType ? `${action.actionType} Action ${action.idDisplay}` : `Action ${ComplaintActionType.ToBeCompleted}`
				}
			</DisplayField>
			{
				action.description &&
				<DisplayField
					name="description"
					label="Description"
				>
					{action.description}
				</DisplayField>
			}
			{
				action.deadlines.map((deadline, key) => {
					deadline = drObjectFactory.createDeadlineObject(deadline);

					const handleAuditResponse = !isDistrictUser && canAudit && !!deadline.hasResponse
					&& !!deadline.response.hasReview && deadline.isSubmittedToState
						? handleAuditResponseAsync
						: undefined;

					const handleSubmitResponse = isDistrictUser && canSubmit && !!deadline.hasResponse
					&& !deadline.isSubmittedToState
						? handleAuditResponseAsync
						: undefined;

					const canDistrictEdit = isDistrictUser && canRespond && !deadline.isSubmittedToState;

					const canStateEdit = !isDistrictUser && canReview && !!deadline.hasResponse
						&& deadline.isSubmittedToState;

					return <DrStateComplaintResponseActionDeadline
						key={key}
						audits={deadline.response.responseAudits}
						canAudit={canAudit}
						canEdit={canDistrictEdit || canStateEdit}
						deadline={deadline}
						editDeadlineId={editDeadlineId}
						handleAuditResponseAsync={handleAuditResponse}
						handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
						handleSaveResponseAsync={handleSaveResponseAsync}
						handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
						handleSubmitResponseAsync={handleSubmitResponse}
						issueId={issueId}
						setEditDeadlineId={setEditDeadlineId}
					/>;
				})
			}
		</section>
	);
};

DrStateComplaintResponseAction.propTypes = {
	action: PropTypes.object.isRequired,
	editDeadlineId: PropTypes.string.isRequired,
	handleAuditResponseAsync: PropTypes.func.isRequired,
	handleSaveAuditCommentAsync: PropTypes.func,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired,
	issueId: PropTypes.string,
	setEditDeadlineId: PropTypes.func.isRequired,
};

export default DrStateComplaintResponseAction;