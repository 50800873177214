import React from "react";
import PropTypes from "prop-types";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import TextField from "../common/inputs/TextField";
import SelectField from "../common/inputs/SelectField";
import Button from "../common/buttons/Button";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import ButtonBar from "../common/buttons/ButtonBar";
import * as teamMemberRoles from "../../constants/strategicPlan/teamMemberRoles";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import {styles as ActionButton} from "../../constants/ActionButton";

const TeamMemberForm = ({
	                        members,
	                        isLoading,
	                        onCreateMember,
	                        onChangeMember,
	                        onSaveMembers,
	                        onRemoveMember,
	                        onCancel
                        }) => {
	return (
		<section className="strategyPlanTeam strategyPlanTeam--editing">
			<header>
				<h2>Team Members</h2>
			</header>
			{
				members.map((member) => {
					return (
						<GridRow key={member.id} id={member.id}>
							<GridColumn large={"7"}>
								<TextField name={"text|" + member.id}
								           label={"Member"}
								           value={member.text}
								           placeholder={"Name, Position, Organization, etc."}
								           onChange={(event) => onChangeMember(event, member.id)}
								           showLabel={false}/>
							</GridColumn>
							<GridColumn large={"5"}>
								<SelectField name={"role|" + member.id}
								             label={"Role"} value={member.role}
								             options={teamMemberRoles.ALL}
								             showLabel={false}
								             includeDefaultOption={false}
								             onChange={(event) => onChangeMember(event, member.id)}/>
							</GridColumn>
							<span className={ActionButton.position.RIGHT}>
								<Button name={"delete|" + member.id}
								        label={"Delete"}
								        showLabel={false}
								        onClick={(event) => onRemoveMember(event, member.id)}
								        disabled={isLoading}
								        buttonType={ButtonTypes.REMOVE}/>
							</span>
						</GridRow>);
				})
			}
			<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
				<Tile body={"Add Member"}
				      onClick={onCreateMember}
				      isNew={true}
				      isMedium={true}/>
			</TileContainer>

			<ButtonBar position={ButtonBarPositions.BOTTOM}>
				<Button name={"save"}
				        label={"Save"}
				        onClick={onSaveMembers}
				        buttonType={ButtonTypes.SAVE}
				        disabled={isLoading}/>
				<Button onClick={onCancel}
				        label={"Cancel"}
				        name={"cancel"}
				        buttonType={ButtonTypes.CANCEL}
				        disabled={isLoading}/>
			</ButtonBar>
		</section>
	);
};

TeamMemberForm.propTypes = {
	members: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onChangeMember: PropTypes.func.isRequired,
	onSaveMembers: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	onRemoveMember: PropTypes.func.isRequired,
	onCreateMember: PropTypes.func.isRequired
};

export default TeamMemberForm;