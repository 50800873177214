import {mockResolveApiCall} from "../../../../api/ApiUtility";
import {emptyGuid} from "../../../../constants/config";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_ROOT} from "../../gmsLocations";
import * as mockData from "../../gmsMockData";
import {API_MESSAGES} from "../../gmsConstants";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsApplicationSettingsApi {
    static getApplicationSettings(applicationId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/applications/${applicationId}`,
                RequestTypes.GET)
        );
    }

    static saveApplicationSettings(applicationId, dateWindowId, saveApplicationSettings) {
        let apiPath = `${config.apiUrl}${API_ROOT}/admin/dates/${dateWindowId}/applications`;
        let requestType = RequestTypes.POST;
        if(applicationId !== emptyGuid) {
            apiPath += `/${applicationId}`;
            requestType = RequestTypes.PUT;
        }
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                apiPath,
                requestType,
                JSON.stringify(saveApplicationSettings),
                API_MESSAGES.ADMIN.APPLICATION_SETTINGS.saved)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsApplicationSettingsApi {
    static getApplicationSettings() {
        return mockResolveApiCall(mockData.getApplication());
    }

    static saveApplicationSettings() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.APPLICATION_SETTINGS.saved);
    }
}

const gmsApplicationSettingsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsApplicationSettingsApi : MockGmsApplicationSettingsApi;
export default gmsApplicationSettingsApi;