import * as PolicyEvents from "../../../constants/policyEvents";
import {DefaultPolicy} from "./DefaultPolicy";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";
import * as contexts from "../../../constants/contexts";

class SppPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.SPP]);
        this.updatePolicy();
    }

	updatePolicy() {
        //must have EDITOR to MARK_READY_FOR_SUBMIT
        this._policy[PolicyEvents.MARK_READY_TO_SUBMIT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.EDIT ], userBehaviors);
            };

        //must have CERTIFY to SUBMIT_SPP_SECTION
        this._policy[PolicyEvents.SUBMIT_SPP_SECTION] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.CERTIFY ], userBehaviors);
            };

        //must have CERTIFY to AUDIT_SPP_RESPONSES
        this._policy[PolicyEvents.AUDIT_SPP_RESPONSES] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.CERTIFY ], userBehaviors);
            };

        //must have MANAGE to PRE_APPROVE_SPP_DOCUMENT
        this._policy[PolicyEvents.PRE_APPROVE_SPP_DOCUMENT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to upload district superintendent signature doc
        this._policy[PolicyEvents.APPROVE_SPP_DOCUMENT] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };
    }
}

export {SppPolicy};

