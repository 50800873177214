import React, {PureComponent} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sppActions from "../../actions/sppActions";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as fileActions from "../../actions/fileActions";
import {openInNewTab} from "../../components/common/HtmlUtilities";
import * as sppUtilities from "../../components/spp/SppUtilities";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import DocumentPart from "../../components/spp/DocumentPart";
import * as publicLocations from "../../constants/publicLocations";
import { getParams } from "../../components/layout/getParams";

export class SppPublicDocumentPage extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isTitleSet: false,
			params: {}
		};

		this.selectSection = this.selectSection.bind(this);
		this.onPrintClick = this.onPrintClick.bind(this);
		this.onBackClick = this.onBackClick.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.onPrintPartClick = this.onPrintPartClick.bind(this);
	}

	componentDidMount() {
		const params = getParams(this.props.location.pathname, publicLocations.SPP_DOCUMENT);

		if(params !== null)
			this.setState({params});

		this.props.actions.loadInstitutionResponses(params.institutionId, params.documentId);

		if (this.props.allDateWindows.length === 0)
			this.props.actions.loadAllDateWindows();

		if (this.props.institutions.length === 0)
			this.props.actions.loadInstitutions();

		if (!this.props.selectedDocument || this.props.selectedDocument.id !== params.documentId)
			this.props.actions.loadSppDocument(params.documentId);

		if (!this.props.elements[0] || this.props.elements[0].documentId !== params.documentId)
			this.props.actions.loadSppDocumentElements(params.documentId);

		if (!this.props.policyCompliance
			|| this.props.policyCompliance.documentId !== params.documentId
			|| this.props.policyCompliance.institutionId !== params.institutionId)
			this.props.actions.loadPolicyCompliance(params.documentId, params.institutionId);
	}

	componentDidUpdate(){
        if (!this.state.isTitleSet &&
            this.props.allDateWindows.length > 0 &&
            this.props.selectedDocument.id !== undefined &&
            this.props.policyCompliance.id !== undefined &&
            this.props.institutions.length > 0) {

            const pageTitle = sppUtilities.generatePageTitle(
                this.props.allDateWindows,
                null,
                this.props.selectedDocument,
                this.props.institutions,
                this.state.params.institutionId);

            this.props.actions.updatePageTitle(pageTitle);

            this.setState({
                isTitleSet: true
            });
        }
	}

	onBackClick(event) {
		event.preventDefault();
		this.props.history.push(publicLocations.SPP_LANDING.path);
	}

	onPrintClick(event) {
		event.preventDefault();
		openInNewTab(publicLocations.SPP_DOCUMENT_PRINT.path
			.replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
			.replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId));
	}

	onPrintPartClick(event, sectionId) {
		event.preventDefault();
		openInNewTab(publicLocations.SPP_DOCUMENT_SECTION_PRINT.path
			.replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
			.replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId)
			.replace(publicLocations.SPP_SECTION_ID, sectionId));
	}

	selectSection(event, sectionId) {
		event.preventDefault();

		this.props.history.push(publicLocations.SPP_SECTION.path
			.replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
			.replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId)
			.replace(publicLocations.SPP_SECTION_ID, sectionId));
	}

	downloadFile(event, fileId) {
		event.preventDefault();

		this.props.actions.downloadFile(fileId);
	}

	render() {
		if (this.props.allDateWindows.length === 0 ||
			this.props.selectedDocument.id !== this.state.params.documentId ||
			this.props.policyCompliance.documentId !== this.state.params.documentId ||
			this.props.elements.length === 0 ||
			(this.props.elements.length !== 0 && (this.props.elements[0].documentId !== this.state.params.documentId)) ||
			this.props.responses.length === 0)
			return null;

		const inputElements = sppUtilities.getInputElements(this.props.elements);

		const hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);

		const documentResponses =
			sppUtilities.getElementResponses(inputElements, this.props.responses);

		return (
			<section>
				<ButtonBar>

					<Button name={"btnBack"}
					        label={"District Search"}
					        onClick={this.onBackClick}
					        buttonType={ButtonTypes.BACK}
					/>

					<Button name="btnPrint"
					        label="Print All Parts"
					        buttonType={ButtonTypes.PRINT}
					        onClick={this.onPrintClick}/>

					{
						this.props.policyCompliance.approvalFileId !== null &&
						<Button name="btnDownloadApproval"
						        label="Download Signed Page"
						        onClick={event => this.downloadFile(event, this.props.policyCompliance.approvalFileId)}
						        buttonType={ButtonTypes.DOWNLOAD}
						        disabled={this.props.isLoading}/>
					}

					{//Download State Certification Letter
						this.props.policyCompliance.stateCertificationFileId &&
						<Button name="btnDownloadStateCertification"
						        label="Download State Approval Letter"
						        onClick={event => this.downloadFile(event, this.props.policyCompliance.stateCertificationFileId)}
						        buttonType={ButtonTypes.DOWNLOAD}
						        disabled={this.props.isLoading}/>
					}

				</ButtonBar>

				{
					hierarchyJson.map((part, index) => {
						return (<DocumentPart
							key={"part" + index}
							partId={part.elementId}
							documentHierarchy={hierarchyJson}
							elements={this.props.elements}
							selectSection={this.selectSection}
							responses={documentResponses}
							onPrintPartClick={this.onPrintPartClick}
						/>);
					})
				}
			</section>
		);
	}
}

SppPublicDocumentPage.propTypes = {
	actions: PropTypes.object.isRequired,
	allDateWindows: PropTypes.array.isRequired,
	selectedDocument: PropTypes.object.isRequired,
	responses: PropTypes.array.isRequired,
	elements: PropTypes.array.isRequired,
	policyCompliance: PropTypes.object.isRequired,
	institutions: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	return {
		allDateWindows: state.sharedData.dateWindows,
		selectedDocument: state.spp.selectedDocument,
		elements: state.spp.documentElements,
		responses: state.spp.documentResponses,
		policyCompliance: state.spp.selectedPolicyCompliance,
		institutions: state.sharedData.institutions,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		sppActions,
		fileActions,
		layoutActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppPublicDocumentPage);