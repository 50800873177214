import React from 'react';
import PropTypes from 'prop-types';
import IndicatorProtocolFormNoSubQuestions from "./InidicatorProtocolFormNoSubQuestions";
import IndicatorProtocolFormWithSubQuestions from "./IndicatorProtocolFormWithSubQuestions";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

const IndicatorProtocolForm = ({
                                   protocol,
                                   response,
                                   responseText,
                                   subQuestionResponses,
                                   onResponseChange,
                                   onSubQuestionResponseChange,
                                   showState,
                                   districtResponse,
                                   districtSubQuestionResponses,
                                   options,
                                   showMissingResponses = false
                               }) => {

    const citation = protocol.standardDescription.indexOf("<p>") >= 0 ? `<p>${protocol.citation}</p>` : protocol.citation;

    return (
        <div className="compliance__indicatorProtocolForm">
            <h2>{protocol.publicIdentifier}</h2>
            <HtmlDisplayBlock html={protocol.standardDescription + " " + citation}/>
            {
                protocol.subQuestions.length === 0 &&
                <IndicatorProtocolFormNoSubQuestions
                    protocol={protocol}
                    onResponseChange={onResponseChange}
                    showState={showState}
                    options={options}
                    districtResponse={districtResponse}
                    response={response}
                    responseText={responseText}
                    showMissingResponses={showMissingResponses}
                />
            }

            {
                protocol.subQuestions.length > 0 &&
                <IndicatorProtocolFormWithSubQuestions
                    protocol={protocol}
                    subQuestionResponses={subQuestionResponses}
                    showState={showState}
                    districtSubQuestionResponses={districtSubQuestionResponses}
                    onSubQuestionResponseChange={onSubQuestionResponseChange}
                    response={response}
                    districtResponse={districtResponse}
                    showMissingResponses={showMissingResponses}
                />
            }
        </div>
    );
};

IndicatorProtocolForm.defaultProps = {
    response: "",
    responseText: "",
    districtResponse: ""
};

IndicatorProtocolForm.propTypes = {
    protocol: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    response: PropTypes.string.isRequired,
    responseText: PropTypes.string.isRequired,
    subQuestionResponses: PropTypes.array.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    showState: PropTypes.bool.isRequired,
    districtResponse: PropTypes.string,
    districtSubQuestionResponses: PropTypes.array,
    showMissingResponses: PropTypes.bool
};

export default IndicatorProtocolForm;