import React from "react";
import PropTypes from 'prop-types';
import * as responseTypes from "../../../constants/monitoringCompliance/responseTypes";
import GridTable from "../../common/GridTable";
import ReassessNoSubQuestions from "./ReassessNoSubQuestions";
import ReassessSubQuestionList from "./ReassessSubQuestionList";

const ReassessGrid = (
    {
        isLastPage,
        protocolToBeReassessed,
        protocolResponses,
        onResponseChange,
        onSubQuestionResponseChange
    }) => {
    return (
        <>
            <h2>Step 1</h2>
            <p>Review the following record(s) for {protocolToBeReassessed.publicIdentifier} that contained discrepancies when validated by the state, noting how the state changed the original district response.</p>
            <GridTable>
                <thead>
                <tr>
                    <th>Record ID</th>
                    <th colSpan={2}>{protocolToBeReassessed.indicatorTitle}</th>
                    <th className={"text-center"}>Original Response</th>
                    <th className={"text-center"}>State Response</th>
                </tr>
                </thead>
                <tbody>
                {
                    protocolToBeReassessed.subQuestions.length === 0 &&
                    <ReassessNoSubQuestions
                        protocolToBeReassessed={protocolToBeReassessed}
                        protocolResponses={protocolResponses}
                        onResponseChange={onResponseChange}
                        responseTypeDisplayed={responseTypes.VALIDATION}
                    />
                }
                {
                    protocolToBeReassessed.subQuestions.length > 0 &&
                    <ReassessSubQuestionList
                        protocolToBeReassessed={protocolToBeReassessed}
                        protocolResponses={protocolResponses}
                        onSubQuestionResponseChange={onSubQuestionResponseChange}
                        responseTypeDisplayed={responseTypes.VALIDATION}
                    />
                }
                </tbody>
            </GridTable>
            <h2>Step 2</h2>
            <p>Now review all of the other student record(s) to update the original district response if needed based upon the feedback from the validation by FDOE.</p>
            <GridTable>
                <thead>
                <tr>
                    <th>Record ID</th>
                    <th colSpan={"2"}>{protocolToBeReassessed.indicatorTitle}</th>
                    <th className={"text-center"}>Original Response</th>
                    <th className={"text-center"}>Change the Response If Needed</th>
                </tr>
                </thead>
                <tbody>
                {
                    protocolToBeReassessed.subQuestions.length === 0 &&
                    <ReassessNoSubQuestions
                        protocolToBeReassessed={protocolToBeReassessed}
                        protocolResponses={protocolResponses}
                        onResponseChange={onResponseChange}
                        responseTypeDisplayed={responseTypes.REASSESS}
                    />
                }
                {
                    protocolToBeReassessed.subQuestions.length > 0 &&
                    <ReassessSubQuestionList
                        protocolToBeReassessed={protocolToBeReassessed}
                        protocolResponses={protocolResponses}
                        onSubQuestionResponseChange={onSubQuestionResponseChange}
                        responseTypeDisplayed={responseTypes.REASSESS}
                    />
                }
                </tbody>
            </GridTable>
            <h2>Step 3</h2>
            {
                !isLastPage &&
                <p>Click &quot;Next&quot; to continue to the next protocol that has discrepancies.</p>
            }
            {
                isLastPage &&
                <p>Click &quot;Summary Page&quot; to continue.</p>
            }
        </>
    );
};

ReassessGrid.propTypes = {
    isLastPage: PropTypes.bool.isRequired,
    protocolToBeReassessed: PropTypes.object.isRequired,
    protocolResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onResponseChange: PropTypes.func.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired
};

export default ReassessGrid;