import React from "react";
import AccountUtility from "../../api/AccountUtility";
import {GmsSearchCriteria} from "../../components/common/searchCriterias/GmsSearchCriteria";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import * as accountActions from "../../actions/accountActions";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as fileActions from "../../actions/fileActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getParamsMultiple} from "../../components/layout/getParams";
import {GmsWrapperApplicationHistory} from "./GmsWrapperApplicationHistory";
import {ALL_GMS_ROUTES} from "./gmsLocations";
import {generateDateWindowSelectListItemsAfterYear} from "../../components/shared/sharedDataUtilities";

const GmsWrapper = ({
                        actions,
                        allFiscalAgents,
                        allFiscalAgentsLoaded,
                        Component,
                        currentLocation,
                        dateWindows,
                        gmsIndicators,
                        params,
                        props,
                        searchCriteria,
                    }) => {

    if (!allFiscalAgentsLoaded) return null;

    const userDetails = AccountUtility.getUserDetails();

    return (
        <GmsWrapperApplicationHistory
            {...props}
            {...params}
            actions={actions}
            allFiscalAgents={allFiscalAgents}
            allFiscalAgentsLoaded={allFiscalAgentsLoaded}
            Component={Component}
            currentLocation={currentLocation}
            currentUserFiscalAgent={userDetails.Institution || ""}
            currentUserFiscalAgentId={userDetails.InstitutionId?.toLowerCase() || ""}
            dateWindows={dateWindows}
            gmsIndicators={gmsIndicators}
            isFiscalAgentUser={AccountUtility.isFiscalAgentUser()}
            searchCriteria={searchCriteria}
        />
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    let searchCriteria = state.searchCriteria.GmsSearchCriteria || new GmsSearchCriteria();
    const institutions = [...state.sharedData.institutionsWithDiscretionaryAgencies];
    const allFiscalAgents = institutions.map(ins => {
        return {
            districtSiteSectionTeamMemberDtos: ins.districtSiteSectionTeamMemberDtos,
            text: ins.name,
            value: ins.id,
            institutionType: ins.institutionType,
        };
    });

    const gmsIndicators = [...state.sharedData.gmsIndicators];

    const dateWindows = generateDateWindowSelectListItemsAfterYear([...state.sharedData.dateWindows], "2022");

    const multipleParams = getParamsMultiple(props.location.pathname, ALL_GMS_ROUTES);
    const params = !multipleParams ? {} : multipleParams;

    const currentLocation = props.location.pathname;
    const allFiscalAgentsLoaded = !isArrayNullOrEmpty(allFiscalAgents);

    return {
        allFiscalAgents,
        allFiscalAgentsLoaded,
        Component,
        currentLocation,
        dateWindows,
        gmsIndicators,
        params,
        props,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        accountActions,
        apiForLocalStateActions,
        fileActions,
        layoutActions,
        searchCriteriaActions,
        sharedDataActions,
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch),
    };
};

GmsWrapper.propTypes = {
    actions: PropTypes.object,
    allFiscalAgents: PropTypes.arrayOf(PropTypes.object),
    allFiscalAgentsLoaded: PropTypes.bool,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindows: PropTypes.array,
    gmsIndicators: PropTypes.array,
    params: PropTypes.object,
    props: PropTypes.object,
    searchCriteria: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(GmsWrapper);