import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import * as layoutActions from "../../../actions/layoutActions";
import AccountUtility from "../../../api/AccountUtility";
import {McNavigation} from "../../../components/MonitoringCompliance/McNavigation";
import {createNotificationSettingsArray} from "../../../components/Notifications/notificationObjectFactory";
import NotificationSettingsView from "../../../components/Notifications/NotificationSettingsView";
import mcNotificationSettingsApi from "./McNotificationSettingsApi";

export const McNotificationSettingsContainer = ({
                                                    actions,
                                                    currentLocation,
                                                    history
                                                }) => {
    const [notificationSettings, setNotificationSettings] = useState(null);
    const isDistrictUser = AccountUtility.isDistrictUser();

    const getUserNotificationSettings = async () => {
        await actions.executeApi(mcNotificationSettingsApi.getMcUserNotificationSettings)
            .then((result) => setNotificationSettings(createNotificationSettingsArray(result)));
    };

    const saveNotificationSettings = async (notifications) => {
        await actions.executeApi(mcNotificationSettingsApi.saveMcUserNotificationSettings, [notifications]);
    }

    useEffect(() => {
        getUserNotificationSettings();
        actions.updatePageTitle(`Notification Settings`);
    }, []);

    if (!notificationSettings) return null;

    return (
        <>
            <NotificationSettingsView
                notificationSettings={notificationSettings}
                saveNotificationSettings={saveNotificationSettings}
            />
            {
                !isDistrictUser &&
                <McNavigation currentLocation={currentLocation} history={history}/>
            }
        </>
    );
};

const mapStateToProps = (state, props) => {
    const currentLocation = props.location.pathname;

    return {
        currentLocation
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

McNotificationSettingsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    history: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(McNotificationSettingsContainer);