import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import * as sharedDataActions from '../../actions/sharedDataActions';
import * as sppActions from '../../actions/sppActions';
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import SelectField from "../../components/common/inputs/SelectField";
import TextField from "../../components/common/inputs/TextField";
import GridTable from "../../components/common/GridTable";
import {getSectionMenuOptions} from "../../components/spp/SppUtilities";
import * as sppLocations from "../../constants/spp/sppLocations";
import { getParams } from "../../components/layout/getParams";
import {AllProgressValues, NEEDS_REVIEW, NEEDS_REVISION} from "../../constants/spp/progressValues";
import {
	generateSelectListFromArray,
	dynamicSort,
	isInArray,
	isArrayNullOrEmpty
} from "../../components/common/commonUtilities";
import {SppReviewDistrictSearchCriteria} from "../../components/common/searchCriterias/SppReviewDistrictSearchCriteria";
import {gridConstants} from "../../constants/inputConstants";

export class SppReviewDistrictPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			loadData: false
		};
		this.dateWindowChange = this.dateWindowChange.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.viewDistrictSppDetails = this.viewDistrictSppDetails.bind(this);
		this.onOrderChange = this.onOrderChange.bind(this);
		this.onSelectSectionChange = this.onSelectSectionChange.bind(this);
		this.onSelectPartChange = this.onSelectPartChange.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Policies and Procedures - Districts");

		if (this.props.dateWindows.length === 0)
			this.props.actions.loadAllDateWindows();

		this.props.actions.createSearchCriteria(new SppReviewDistrictSearchCriteria());
	}

    componentDidUpdate()
	{
		if(!this.state.loadData &&
            this.props.dateWindows.length > 0 &&
            this.props.searchCriteria !== undefined)
		{
			const selectedDateWindowId = this.props.searchCriteria.dateWindowId
				|| this.props.selectedDateWindowId
				|| this.props.dateWindows[0].value;

			this.dateWindowChange(selectedDateWindowId);

			this.setState({loadData: true});
		}
	}

	dateWindowChange(dateWindowId) {
        let criteria = Object.assign({}, this.props.searchCriteria);
        const isNewDateWindow = criteria.dateWindowId !== dateWindowId;
		criteria.partId = isNewDateWindow ? "" : criteria.partId;
		criteria.sectionId = isNewDateWindow ? "" : criteria.sectionId;
        criteria.dateWindowId = dateWindowId;
        this.props.actions.saveSearchCriteria(criteria);
		this.props.actions.loadDistrictStatuses(dateWindowId)
			.then((result) => {
				if(isArrayNullOrEmpty(result))
					return;

				const documentId = result[0].documentId;
				this.props.actions.loadSppDocument(documentId)
					.then(() => {
						this.buildSectionMenu("", this.props.searchCriteria.sectionId);
					})
				this.props.actions.loadSppDocumentElements(documentId)
					.then(() => {
						this.buildSectionMenu("", this.props.searchCriteria.sectionId);
					})
			});
	}

	onFormChange(event) {
		event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria[event.target.id] = event.target.value;

        if(event.target.id === "progress")
        	criteria.sectionId = "";

        this.props.actions.saveSearchCriteria(criteria);
	}

	viewDistrictSppDetails(districtId, documentId) {
		this.props.history.push(sppLocations.DOCUMENT_DOE.path
			.replace(sppLocations.INSTITUTION_ID, districtId)
			.replace(sppLocations.DOCUMENT_ID, documentId));
	}

    onOrderChange(event) {
        event.preventDefault();

        let criteria = Object.assign({}, this.props.searchCriteria);
        criteria.UpdateSorting(event.target.dataset.id);

        this.props.actions.saveSearchCriteria(criteria);
    }

	filterDistrictList() {
        let filteredDistricts;

        let criteria = this.props.searchCriteria;

		if (criteria.district !== "" && this.props.districtStatuses.length > 0) {
			const institutionSearchStrings = criteria.district.split(",").map(d => d.trim());

			filteredDistricts = this.props.districtStatuses.filter(ins => institutionSearchStrings.some(function (search) {
				return (ins.districtName.toLowerCase().indexOf(search.toLowerCase()) >= 0);
			}));
		}
		else {
			filteredDistricts = [...this.props.districtStatuses];
		}

        if(criteria.progress !== "") {
        	if(criteria.progress === NEEDS_REVIEW)
        		filteredDistricts = filteredDistricts.filter(p => p.progress.toLowerCase() === criteria.progress.toLowerCase() || p.needsReviewCount > 0);
        	else if(criteria.progress === NEEDS_REVISION)
				filteredDistricts = filteredDistricts.filter(p => p.progress.toLowerCase() === criteria.progress.toLowerCase() || p.needsRevisionCount > 0);
        	else
				filteredDistricts = filteredDistricts.filter(p => p.progress.toLowerCase() === criteria.progress.toLowerCase());
		}

		if(criteria.sectionId !== "") {
			if(criteria.progress === NEEDS_REVIEW)
				filteredDistricts = filteredDistricts.filter(p => isInArray(p.sectionsNeedingReview, criteria.sectionId));
			else
				filteredDistricts = filteredDistricts.filter(p => isInArray(p.sectionsNeedingRevision, criteria.sectionId));
		}

        return filteredDistricts;
	}

	showPartSectionFilters() {
		return this.props.searchCriteria.progress === NEEDS_REVIEW || this.props.searchCriteria.progress === NEEDS_REVISION;
	}

	onSelectPartChange(event) {
		event.preventDefault();
		this.buildSectionMenu(event.target.value);
	}

	buildSectionMenu(partId, sectionId) {
		const selectedDocument = this.props.document;
		const documentElements = this.props.elements;

		const updatedSearchCriteria = getSectionMenuOptions(selectedDocument, documentElements, this.props.searchCriteria, partId, sectionId);

		this.props.actions.saveSearchCriteria(updatedSearchCriteria);
	}

	onSelectSectionChange(event) {
		event.preventDefault();
		const sectionId = event.target.value;
		this.buildSectionMenu(this.props.searchCriteria.partId, sectionId);
	}

	render() {
		if (this.props.dateWindows.length === 0 ||
			this.props.searchCriteria === undefined)
			return null;

		let filteredDistricts = this.filterDistrictList();
		let criteria = this.props.searchCriteria;
		filteredDistricts = filteredDistricts.sort(dynamicSort(criteria.SortByColumn, criteria.SortDescending, criteria.BaseSortByColumn));

		return (
			<div>
				<GridRow rowClass="filterBar" medium={gridConstants.column.THREE}>
					<GridColumn>
						<SelectField
							name="dateWindow"
							showLabel={true}
							label="Date Window"
							onChange={(e) => this.dateWindowChange(e.target.value)}
							options={this.props.dateWindows}
							value={criteria.dateWindowId}
							includeDefaultOption={false}/>
					</GridColumn>
                    <GridColumn>
                        <SelectField
                            name="progress"
                            showLabel={true}
                            label="Progress"
                            onChange={this.onFormChange}
                            options={generateSelectListFromArray(AllProgressValues)}
                            value={criteria.progress} />
                    </GridColumn>
					<GridColumn>
						<TextField
							name="district"
							showLabel={true}
							label="District"
							onChange={this.onFormChange}
							value={criteria.district}/>
					</GridColumn>
					{
						this.showPartSectionFilters() &&
						<>
							<GridColumn>
								<SelectField
									name="selectedPart"
									showLabel={true}
									label="Part"
									onChange={this.onSelectPartChange}
									options={criteria.selectPartItems}
									value={criteria.partId}
									includeDefaultOption={false}
								/>
							</GridColumn>
							<GridColumn>
								<SelectField
									name="selectedSection"
									showLabel={true}
									label={`Sections that ${criteria.progress}`}
									onChange={this.onSelectSectionChange}
									options={criteria.selectSectionItems}
									value={criteria.sectionId}
									includeDefaultOption
									defaultOption={"Select a Section"}
								/>
							</GridColumn>
						</>
					}
				</GridRow>

				{
					!this.props.isLoading &&
					filteredDistricts.length === 0 &&
					<p>There are no Policies and Procedures documents associated with the selected filters.</p>
				}
				{
					!this.props.isLoading &&
					filteredDistricts.length > 0 &&
					<GridTable>
						<thead>
						<tr>
							<th onClick={this.onOrderChange} data-id="districtName" className="is-clickable">District</th>
							<th onClick={this.onOrderChange} data-id="progress" className="is-clickable">Progress</th>
							<th onClick={this.onOrderChange} data-id="needsReviewCount" className="is-clickable text-center">Total Responses<br/> Needing Review</th>
							<th onClick={this.onOrderChange} data-id="needsRevisionCount" className="is-clickable text-center">Total Responses<br/> Needing Revision</th>
						</tr>
						</thead>
						<tbody>
						{
							filteredDistricts.map(d =>
								<tr key={d.districtId}
								    onClick={() => this.viewDistrictSppDetails(d.districtId, d.documentId)}
								    className="is-clickable">
									<td>{d.districtName}</td>
									<td>{d.progress}</td>
									<td className={"text-center"}>{d.needsReviewCount}</td>
									<td className={"text-center"}>{d.needsRevisionCount}</td>
								</tr>
							)
						}
						</tbody>
					</GridTable>
				}
			</div>
		);
	}
}

SppReviewDistrictPage.propTypes = {
	actions: PropTypes.object.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedDateWindowId: PropTypes.string.isRequired,
	districtStatuses: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object,
	document: PropTypes.object.isRequired,
	elements: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state, ownProps) {
	const params = getParams(ownProps.location.pathname, sppLocations.LANDING_DOE_DATE_WINDOW);

	let dateWindows = (this.props && this.props.dateWindows) ? this.props.dateWindows : [];
	let selectedDateWindowId = (params && params.dateWindowId)? params.dateWindowId : state.sharedData.selectedDateWindowId;

	if (dateWindows.length === 0 && state.sharedData.dateWindows.length > 0) {
		const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);

		if (!sharedDataUtilities.isValueInList(dateWindows, state.sharedData.selectedDateWindowId))
			selectedDateWindowId = dateWindows[0].value;
	}
	return {
		dateWindows: dateWindows,
		selectedDateWindowId: selectedDateWindowId,
		isLoading: state.ajaxCallsInProgress > 0,
		districtStatuses: state.spp.districtStatuses,
        searchCriteria: state.searchCriteria.SppReviewDistrictSearchCriteria,
		document: state.spp.selectedDocument,
		elements: state.spp.documentElements,
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, layoutActions, sharedDataActions, sppActions, searchCriteriaActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppReviewDistrictPage);