import React from 'react';
import PropTypes from 'prop-types';
import GridRow from "../GridRow";
import GridColumn from "../GridColumn";
import {getRandomInt} from "../HtmlUtilities";
import {childrenOf} from "../../../constants/CustomPropTypes";
import Allow from "../../authorization/Allow";
import Tile from "./Tile";
import {convertToArray} from "../commonUtilities";
import {gridConstants} from "../../../constants/inputConstants";

const TileContainer = ({
                           children,
                           title,
                           description,
                           rowTilesLarge = gridConstants.column.THREE,
                           rowTilesMedium = gridConstants.column.TWO,
                           rowTilesSmall = gridConstants.column.ONE
                       }) => {
    children = convertToArray(children);

    const id = "tileContainer" + getRandomInt();

    return (
        <div className={`tileContainer`}>
            <label htmlFor={id}>
                {(title && title.length > 0) && <div className="tileContainer__title">{title}</div>}
                {(description && description.length > 0) &&
                    <div className="tileContainer__description">{description}</div>}
            </label>

            <GridRow large={rowTilesLarge} medium={rowTilesMedium} small={rowTilesSmall} id={id}>
                {children.map((child, index) => {
                        if (child && child.type) {
                            return <GridColumn key={index}>
                                {child}
                            </GridColumn>;
                        } else if (Array.isArray(child)) {
                            return child.map((grandchild, subindex) => {
                                if (grandchild && grandchild.type) {
                                    return <GridColumn key={subindex}>
                                        {grandchild}
                                    </GridColumn>;
                                }
                            })
                        }
                    }
                )}
            </GridRow>
        </div>
    );
};

const columnPropType = gridConstants.column.PROP_TYPE;

TileContainer.propTypes = {
    children: PropTypes.oneOfType([
        childrenOf(Tile, Allow),
        PropTypes.object,
        PropTypes.array
    ]),
    title: PropTypes.string,
    description: PropTypes.string,
    rowTilesLarge: columnPropType,
    rowTilesMedium: columnPropType,
    rowTilesSmall: columnPropType
};

export default TileContainer;