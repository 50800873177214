import React from "react";
import PropTypes from "prop-types";
import TextArea from "../common/inputs/TextArea";
import TextField from "../common/inputs/TextField";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import ProgressMonitoringActivity from "./ProgressMonitoringActivity";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const SubActionForm = ({
	                       filters,
	                       subAction,
                           actionIndex,
						   subActionIndex,
	                       onChange,
	                       onSave,
	                       onCancel,
	                       isLoading
                       }) => {
	return (
		<section className={"strategy__subaction strategy__subaction--editing "} id={subAction.id}>
			<header>
                <TextArea label={"Sub-action " + (actionIndex + 1) + "." + (subActionIndex + 1)}
                          name={"text"}
                          onChange={onChange}
                          value={subAction.text}
                          showLabel/>

				<TextField label={"Responsible Person"}
				           name={"responsibleParty"}
				           onChange={onChange}
				           value={subAction.responsibleParty}
				           showLabel
				           placeholder={"First and Last Name"}/>

				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<Button name={"save"}
					        label={"Save"}
					        buttonType={ButtonTypes.SAVE}
					        onClick={onSave}
					        disabled={isLoading}/>
					<Button name={"cancel"}
					        onClick={onCancel}
					        buttonType={ButtonTypes.CANCEL}
					        label={"Cancel"}
					        disabled={isLoading}/>
				</ButtonBar>
			</header>

			{
				filters.showProgress &&
				subAction.progressMonitoringNoteEntries.length > 0 &&
				<section className="strategy__progress">
					<header>
						<h5>Progress Monitoring Activities</h5>
					</header>
					{
						subAction.progressMonitoringNoteEntries.map(activity => {
							return (<ProgressMonitoringActivity key={activity.id}
							                                    activity={activity}
							                                    isLoading={isLoading}
							                                    isEditing={true}
							/>);
						})
					}

				</section>
			}
		</section>
	);
};

SubActionForm.propTypes = {
	filters: PropTypes.object.isRequired,
	subAction: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
    actionIndex: PropTypes.number.isRequired,
    subActionIndex: PropTypes.number.isRequired
};

export default SubActionForm;