import React from 'react';
import PropTypes from 'prop-types';
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import * as SharedDataActions from "../../actions/sharedDataActions";
import Main from "../../components/layout/Main";
import {
	filterCurrentAnnualDateWindows,
	generateDateWindowSelectListItems, generateInstitutionsSelectListItems
} from "../../components/shared/sharedDataUtilities";
import {bindActionCreators} from "redux";
import {themeConstants} from "../../constants/commonStyleConstants";

export class PrintLayout extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.actions.loadAllDateWindows();
		this.props.actions.loadInstitutions();
	}

	render() {
        const {exact, path} = this.props;

		return (
            <Route {...exact} {...path} render={componentProps => (
				<div className={`l-flex-fullHeight theme-${themeConstants.PRINT}`}>

					<Main
						{...this.props}
						{...componentProps}
					/>
				</div>
			)} />
		);
	}
}

PrintLayout.propTypes = {
	actions: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    component: PropTypes.func.isRequired,
    exact: PropTypes.bool,
    path: PropTypes.string,
	dateWindows: PropTypes.array,
	dateWindowsAnnualList: PropTypes.array,
	institutions: PropTypes.array,
	institutionsList: PropTypes.array,
	wrapperPage: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function mapStateToProps(state) {
	const dateWindows = [...state.sharedData.dateWindows];
	let dateWindowsAnnualList = [];

	if (dateWindows.length > 0) {
		const annualWindows = filterCurrentAnnualDateWindows(state.sharedData.dateWindows, true);
		dateWindowsAnnualList = generateDateWindowSelectListItems(annualWindows);
	}

	const institutions = [...state.sharedData.institutions];
	let institutionsList = [];
	if(institutions.length > 0) {
		institutionsList = generateInstitutionsSelectListItems(institutions);
	}

	return {
		layout: state.layout,
		isLoading: state.ajaxCallsInProgress > 0,
		dateWindows,
		dateWindowsAnnualList,
		institutions,
		institutionsList,
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign({}, SharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(PrintLayout);
