import React from 'react';
import PropTypes from "prop-types";
import {
    isArrayNullOrEmpty,
    createListFromArray,
} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import Tile from "../../../../components/common/Tiles/Tile";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import ToolTip from "../../../../components/common/ToolTip";
import {createGmsDeliverableItem} from "../../gmsObjectFactory";
import {TIPS} from "./gmsApplicationDeliverablesConstants";
import GmsApplicationDeliverablesTableRow from "./GmsApplicationDeliverablesTableRow";
import {emptyGuid} from "../../../../constants/config";
import GmsApplicationDeliverablesTableRowEdit from "./GmsApplicationDeliverablesTableRowEdit";


const GmsApplicationDeliverablesTable = ({
                                             canUserEditDeliverables,
                                             deliverableCategory = 0,
                                             deliverables = [],
                                             editId,
                                             gmsIndicators,
                                             handleCancel,
                                             handleDelete,
                                             handleEdit,
                                             handleSave,
                                             isEditCategory,
                                         }) => {

    const colSpan = canUserEditDeliverables ? 11 : 10;
    const isEditing = !!editId;
    const indicatorList = createListFromArray(gmsIndicators);

    const handleAddClick = () => handleEdit(emptyGuid, deliverableCategory);

    return (
        <GridTable>
            <thead>
            <tr className={`small-column text-center`}>
                <th><ToolTip tip={TIPS.deliverableNumber}>Id</ToolTip></th>
                <th>Type</th>
                <th>Title/Description</th>
                <th>Indicators</th>
                <th><ToolTip showIcon tip={TIPS.sourceDoc}>Source Docs</ToolTip></th>
                <th><ToolTip showIcon tip={TIPS.fundingSourceType}>Funding Source</ToolTip></th>
                <th><ToolTip showIcon tip={TIPS.deliverableBudget}>Deliverable Budget</ToolTip></th>
                <th><ToolTip showIcon tip={TIPS.totalUnits}>Total Units</ToolTip></th>
                <th>Unit Cost</th>
                <th><ToolTip showIcon tip={TIPS.quarterUnits}>Quarterly Units</ToolTip></th>
                {
                    canUserEditDeliverables &&
                    <th>Actions</th>
                }
            </tr>
            </thead>
            <tbody>
            {
                deliverables.map((di) => {
                    const deliverableItem = createGmsDeliverableItem(di);
                    const handleDeleteClick = () => handleDelete(deliverableItem.id);
                    const handleEditClick = () => handleEdit(deliverableItem.id, deliverableCategory);

                    if (editId === deliverableItem.id && isEditCategory)
                        return <GmsApplicationDeliverablesTableRowEdit
                            key={deliverableItem.id}
                            colSpan={colSpan}
                            deliverableItem={deliverableItem}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            gmsIndicators={indicatorList}
                        />;
                    else
                        return <GmsApplicationDeliverablesTableRow
                            key={deliverableItem.id}
                            canUserEditDeliverables={canUserEditDeliverables}
                            deliverableItem={deliverableItem}
                            handleDeleteClick={handleDeleteClick}
                            handleEditClick={handleEditClick}
                            isEditing={isEditing}
                        />;
                })
            }
            {
                editId === emptyGuid && isEditCategory &&
                <GmsApplicationDeliverablesTableRowEdit
                    key={emptyGuid}
                    colSpan={colSpan}
                    deliverableItem={{deliverableCategory}}
                    handleCancel={handleCancel}
                    handleSave={handleSave}
                    gmsIndicators={indicatorList}
                />
            }

            {
                isArrayNullOrEmpty(deliverables) &&
                <tr className={`text-center`}>
                    <td colSpan={colSpan}>
                        <p>No items.</p>
                    </td>
                </tr>
            }

            </tbody>
            {
                canUserEditDeliverables &&
                <tfoot>
                <tr>
                    <td colSpan={colSpan}>
                        <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                            <Tile body={"Add Deliverable"}
                                  isDisabled={!!editId}
                                  isNew={true}
                                  isMedium={true}
                                  onClick={handleAddClick}
                            />
                        </TileContainer>
                    </td>
                </tr>
                </tfoot>
            }
        </GridTable>
    );
};


GmsApplicationDeliverablesTable.propTypes = {
    canUserEditDeliverables: PropTypes.bool,
    deliverableCategory: PropTypes.number.isRequired,
    deliverables: PropTypes.array.isRequired,
    editId: PropTypes.string,
    handleCancel: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    gmsIndicators: PropTypes.array.isRequired,
    isEditCategory: PropTypes.bool,
};

export default GmsApplicationDeliverablesTable;