import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sppActions from "../../actions/sppActions";
import * as layoutActions from "../../actions/layoutActions";
import {openInNewTab} from "../../components/common/HtmlUtilities";
import Switch from "../../components/common/inputs/Switch";
import DocumentPart from "../../components/spp/DocumentPart";
import * as sharedDataActions from "../../actions/sharedDataActions";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {SppPolicy} from "../../components/authorization/policies/SppPolicy";
import {emptyGuid} from "../../constants/config";
import * as policyEvents from "../../constants/policyEvents";
import FileUploadButton from "../../components/common/inputs/FileUploadButton";
import Allow from "../../components/authorization/Allow";
import * as fileActions from "../../actions/fileActions";
import * as sppUtilities from "../../components/spp/SppUtilities";
import audit_outcomes from "../../constants/auditOutcomes";
import * as sppReviewActions from "../../actions/sppReviewActions";
import * as sppLocations from "../../constants/spp/sppLocations";
import * as publicLocations from "../../constants/publicLocations";
import {getParams} from "../../components/layout/getParams";

export class SppReviewDocumentPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            titleIsSet: false,
            params: {},
            showNeeds: false,
        };

        this.clearFileSelection = this.clearFileSelection.bind(this);
        this.selectSection = this.selectSection.bind(this);
        this.showPreApprovalButton = this.showPreApprovalButton.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.onPrintClick = this.onPrintClick.bind(this);
        this.onPreApprovalUpload = this.onPreApprovalUpload.bind(this);
        this.onApprovalUpload = this.onApprovalUpload.bind(this);
        this.undoApproval = this.undoApproval.bind(this);
        this.undoPreApproval = this.undoPreApproval.bind(this);
        this.onStateCertificationUpload = this.onStateCertificationUpload.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.onChangeShowNeeds = this.onChangeShowNeeds.bind(this);
        this.onPrintPartClick = this.onPrintPartClick.bind(this);
    }

    componentDidMount() {
        const params = getParams(this.props.location.pathname, sppLocations.DOCUMENT_DOE);

        if (params !== null)
            this.setState({params});

        this.props.actions.loadInstitutionResponses(params.institutionId, params.documentId);

        if (this.props.institutions.length === 0) {
            this.props.actions.loadInstitutions();
        }

        if (this.props.allDateWindows.length === 0) {
            this.props.actions.loadAllDateWindows();
        }

        this.props.actions.loadSppDocument(params.documentId);
        this.props.actions.loadSppDocumentElements(params.documentId);
        this.props.actions.loadPolicyCompliance(params.documentId, params.institutionId);
    }

    componentDidUpdate() {
        if (!this.state.titleIsSet &&
            this.props.allDateWindows.length > 0 &&
            this.props.selectedDocument.id === this.state.params.documentId &&
            this.props.policyCompliance.documentId === this.state.params.documentId &&
            this.props.institutions.length > 0) {

            const pageTitle = sppUtilities.generatePageTitle(
                this.props.allDateWindows,
                this.props.policyCompliance,
                this.props.selectedDocument,
                this.props.institutions,
                this.state.params.institutionId);

            if (pageTitle !== "") {
                this.props.actions.updatePageTitle(pageTitle);

                this.setState({
                    titleIsSet: true
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.actions.clearSubTitle();
        this.props.actions.clearSppData();
    }

    onBackClick(event) {
        event.preventDefault();
        this.props.history.push(sppLocations.LANDING_DOE.path);
    }

    onPrintClick(event) {
        event.preventDefault();
        openInNewTab(publicLocations.SPP_DOCUMENT_PRINT.path
            .replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
            .replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId));
    }

    onPrintPartClick(event, sectionId) {
        event.preventDefault();
        openInNewTab(publicLocations.SPP_DOCUMENT_SECTION_PRINT.path
            .replace(publicLocations.SPP_INSTITUTION_ID, this.state.params.institutionId)
            .replace(publicLocations.SPP_DOCUMENT_ID, this.state.params.documentId)
            .replace(publicLocations.SPP_SECTION_ID, sectionId));
    }

    onPreApprovalUpload(event) {
        event.preventDefault();

        const currentInstitution = this.props.institutions.filter(ins => ins.id === this.state.params.institutionId)[0];

        const selectedDocumentDateWindow = this.props.allDateWindows.filter(dw => dw.id === this.props.selectedDocument.dateWindowId)[0];
        const dateWindow = new Date(selectedDocumentDateWindow.openDate).getFullYear() + "-" + new Date(selectedDocumentDateWindow.closeDate).getFullYear();

        const preApprovalFileUploadFieldId = "fuPreApproval";

        if (confirm("Are you sure you want to pre-approve " + currentInstitution.name + "'s Policies and Procedures document for " + dateWindow + "?")) {
            this.props.actions.uploadFile(event.target.files, emptyGuid)
                .then((fileId) => {
                    if (!fileId) return;
                    this.props.actions.preApproveDocument(this.props.policyCompliance.id, fileId);
                });
        }

        this.clearFileSelection(preApprovalFileUploadFieldId);
    }

    onApprovalUpload(event) {
        event.preventDefault();

        const currentInstitution = this.props.institutions.filter(ins => ins.id === this.state.params.institutionId)[0];

        const selectedDocumentDateWindow = this.props.allDateWindows.filter(dw => dw.id === this.props.selectedDocument.dateWindowId)[0];
        const dateWindow = new Date(selectedDocumentDateWindow.openDate).getFullYear() + "-" + new Date(selectedDocumentDateWindow.closeDate).getFullYear();

        const approvalFileUploadFieldId = "fuApproval";

        if (confirm("Are you sure you want to upload " + currentInstitution.name + "'s Policies and Procedures approval letter for " + dateWindow + "?")) {
            this.props.actions.uploadFile(event.target.files, emptyGuid)
                .then((fileId) => {
                    if (!fileId) return;
                    this.props.actions.approveInstitutionDocument(this.props.policyCompliance.id, fileId);
                });
        }

        this.clearFileSelection(approvalFileUploadFieldId);
    }

    clearFileSelection(fieldId) {
        const uploadField = document.getElementById(fieldId);
        uploadField.value = "";
    }

    selectSection(event, sectionId) {
        event.preventDefault();

        this.props.history.push(sppLocations.SECTION_DOE.path
            .replace(sppLocations.INSTITUTION_ID, this.state.params.institutionId)
            .replace(sppLocations.DOCUMENT_ID, this.state.params.documentId)
            .replace(sppLocations.SECTION_ID, sectionId));
    }

    showPreApprovalButton() {
        //if it's not already preapproved and if the accepted responses are equal to answerable questions
        return this.props.policyCompliance.preApprovalFileId === null &&
            this.props.responses
                .filter(response => response.audits.length > 0
                    && response.audits[0].outcome === audit_outcomes.ACCEPTED.enum).length >= sppUtilities.getInputElements(this.props.elements).length;
    }

    undoApproval(event) {
        event.preventDefault();

        if (confirm("Are you sure you would like to delete the Approval file?")) {
            this.props.actions.undoApproval(this.props.policyCompliance.id);
        }
    }

    undoPreApproval(event) {
        event.preventDefault();

        if (confirm("Are you sure you would like to delete the Approval file?")) {
            this.props.actions.undoPreApprove(this.props.policyCompliance.id);
        }
    }

    deleteStateCertification(event) {
        event.preventDefault();

        if (confirm("Are you sure you would like to delete the State Approval Letter?")) {
            this.props.actions.undoInstitutionCertification(this.props.policyCompliance.id);
        }
    }

    onStateCertificationUpload(event) {
        event.preventDefault();

        const currentInstitution = this.props.institutions.filter(ins => ins.id === this.state.params.institutionId)[0];

        const selectedDocumentDateWindow = this.props.allDateWindows.filter(dw => dw.id === this.props.selectedDocument.dateWindowId)[0];
        const dateWindow = new Date(selectedDocumentDateWindow.openDate).getFullYear() + "-" + new Date(selectedDocumentDateWindow.closeDate).getFullYear();

        const certificationFileUploadFieldId = "fuStateCertification";

        if (confirm("Are you sure you want to approve " + currentInstitution.name + "'s Policies and Procedures document for " + dateWindow + "?")) {
            this.props.actions.uploadFile(event.target.files, emptyGuid)
                .then((fileId) => {
                    if (!fileId) return;
                    this.props.actions.certifyInstitutionDocument(this.props.policyCompliance.id, fileId);
                });
        }

        this.clearFileSelection(certificationFileUploadFieldId);
    }

    downloadFile(event, fileId) {
        event.preventDefault();

        this.props.actions.downloadFile(fileId);
    }

    onChangeShowNeeds(event) {
        const showNeeds = event.target.checked;
        this.setState({showNeeds: showNeeds});
    }

    render() {
        if (this.props.allDateWindows.length === 0 ||
            this.props.selectedDocument.id === undefined ||
            this.props.selectedDocument.id !== this.state.params.documentId ||
            this.props.policyCompliance.id === undefined ||
            this.props.policyCompliance.documentId !== this.state.params.documentId ||
            this.props.elements.length === 0 ||
            this.props.elements[0].documentId !== this.state.params.documentId ||
            this.props.responses.length === 0)
            return null;

        const hierarchyJson = JSON.parse(this.props.selectedDocument.componentsHierarchy);

        const documentResponses = sppUtilities.getElementResponses(this.props.elements, this.props.responses);

        return (
            <section>
                <ButtonBar>
                    <Button name={"btnBack"}
                            label={"District Search"}
                            onClick={this.onBackClick}
                            buttonType={ButtonTypes.BACK}
                    />
                    <Button name="btnPrint"
                            label="Print All Parts"
                            buttonType={ButtonTypes.PRINT}
                            onClick={this.onPrintClick}
                    />
                    {//Upload Signature Page
                        this.showPreApprovalButton() &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <FileUploadButton name={"fuPreApproval"}
                                              label={"Upload Signature Page"}
                                              onChange={this.onPreApprovalUpload}
                                              disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Download Signature Page
                        this.props.policyCompliance.preApprovalFileId &&
                        !this.props.policyCompliance.approvalFileId &&
                        <Button name="btnDownloadPreApproval"
                                label="Download Signature Page"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.preApprovalFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }

                    {//Delete Signature Page
                        this.props.policyCompliance.preApprovalFileId &&
                        !this.props.policyCompliance.approvalFileId &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <Button name="btnDeletePreApproval"
                                    label="Delete Signature Page"
                                    onClick={this.undoPreApproval}
                                    buttonType={ButtonTypes.DELETE}
                                    disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Upload Signed Page
                        !this.props.policyCompliance.approvalFileId &&
                        this.props.policyCompliance.preApprovalFileId &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <FileUploadButton name={"fuApproval"}
                                              label={"Upload Signed Page"}
                                              onChange={this.onApprovalUpload}
                                              disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Download Signed Page
                        this.props.policyCompliance.approvalFileId &&
                        <Button name="btnDownloadApproval"
                                label="Download Signed Page"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.approvalFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }

                    {//Delete Signed Page
                        this.props.policyCompliance.approvalFileId &&
                        !this.props.policyCompliance.stateCertificationFileId &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <Button name="btnDeleteApproval"
                                    label="Delete Signed Page"
                                    onClick={this.undoApproval}
                                    buttonType={ButtonTypes.DELETE}
                                    disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Upload State Certification Letter
                        this.props.policyCompliance.approvalFileId &&
                        this.props.policyCompliance.preApprovalFileId &&
                        !this.props.policyCompliance.stateCertificationFileId &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <FileUploadButton name={"fuStateCertification"}
                                              label={"Upload State Approval Letter"}
                                              onChange={this.onStateCertificationUpload}
                                              disabled={this.props.isLoading}/>
                        </Allow>
                    }

                    {//Download State Certification Letter
                        this.props.policyCompliance.stateCertificationFileId &&
                        <Button name="btnDownloadStateCertification"
                                label="Download State Approval Letter"
                                onClick={event => this.downloadFile(event, this.props.policyCompliance.stateCertificationFileId)}
                                buttonType={ButtonTypes.DOWNLOAD}
                                disabled={this.props.isLoading}/>
                    }

                    {//Delete State Certification Letter
                        this.props.policyCompliance.stateCertificationFileId &&
                        <Allow policy={SppPolicy} policyEvent={policyEvents.PRE_APPROVE_SPP_DOCUMENT}>
                            <Button name="btnDeleteStateCertification"
                                    label="Delete State Approval Letter"
                                    onClick={event => this.deleteStateCertification(event, this.props.policyCompliance.stateCertificationFileId)}
                                    buttonType={ButtonTypes.DELETE}
                                    disabled={this.props.isLoading}/>
                        </Allow>
                    }
                    <Switch
                        label={"Toggle Needs Review"}
                        name={"needsReviewViewToggle"}
                        checked={this.state.showNeeds}
                        onChange={this.onChangeShowNeeds}
                        legend={"Show Needs Review Only"}
                        showLegend
                        showLabel={false}
                        align={"center"}
                        small={"4"}
                    />
                </ButtonBar>
                {
                    hierarchyJson.map((part, index) => {
                        return (<DocumentPart
                                key={"part" + index}
                                partId={part.elementId}
                                documentHierarchy={hierarchyJson}
                                elements={this.props.elements}
                                selectSection={this.selectSection}
                                responses={documentResponses}
                                showOnlyNeeds={this.state.showNeeds}
                                onPrintPartClick={this.onPrintPartClick}
                            />
                        );
                    })
                }
            </section>
        );
    }
}

SppReviewDocumentPage.propTypes = {
    actions: PropTypes.object.isRequired,
    allDateWindows: PropTypes.array.isRequired,
    selectedDocument: PropTypes.object.isRequired,
    responses: PropTypes.array.isRequired,
    institutions: PropTypes.array.isRequired,
    elements: PropTypes.array.isRequired,
    policyCompliance: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        institutions: state.sharedData.institutions,
        allDateWindows: state.sharedData.dateWindows,
        selectedDocument: state.spp.selectedDocument,
        elements: state.spp.documentElements,
        responses: state.spp.documentResponses,
        policyCompliance: state.spp.selectedPolicyCompliance,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        sppActions,
        fileActions,
        layoutActions,
        sppReviewActions,
        sharedDataActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SppReviewDocumentPage);