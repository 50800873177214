import React from "react";
import PropTypes from "prop-types";
import ProgressMonitoringActivity from "./ProgressMonitoringActivity";
import ProgressMonitoringActivityForm from "./ProgressMonitoringActivityForm";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../authorization/policies/StrategicPlanPolicy";
import Allow from "../authorization/Allow";
import ActionBar from "../common/buttons/ActionBar";


const SubAction = ({
	                   actionIndex,
					   subActionIndex,
	                   subAction,
	                   filters,
	                   isEditing,
	                   editItemId,
	                   onEditSubAction,
	                   onDeleteSubAction,
	                   onEditActivity,
	                   onAddActivity,
	                   onChangeActivity,
                       onChangeActivityDate,
	                   onSaveActivity,
	                   onDeleteActivity,
	                   onCancel,
	                   isLoading
                   }) => {

	const subActionText = filters.showSubActions ? subAction.text : subAction.text.substring(0, 100) + "...";

	return (
		<section className={"strategy__subaction "} id={subAction.id}>
			<header>
				{
					!isEditing &&
					filters.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_SUB_ACTION}>
						<ActionBar
							elementId={subAction.id}
							onEditClick={(event) => onEditSubAction(event, subAction.id)}
							onDeleteClick={onDeleteSubAction}
							disabled={isLoading}
						/>
					</Allow>
				}
				<h4>Sub-action {(actionIndex + 1) + "." + (subActionIndex + 1)}</h4>
				{
					filters.showSubActions &&
					<div><em>{subAction.responsibleParty}</em></div>
				}
				<div>{subActionText}</div>
			</header>

			{
				filters.showProgress &&
				(filters.showButtons ||
				subAction.progressMonitoringNoteEntries.length > 0) &&
				<section className="strategy__progress">
					<header>
						<h5>Progress Monitoring Activities</h5>
					</header>
					{
						subAction.progressMonitoringNoteEntries.map(activity => {
							if (isEditing && activity.id === editItemId) {
								return (<ProgressMonitoringActivityForm
									key={activity.id}
									activity={activity}
									onChangeActivity={(event, strategyId, actionId, subActionId) => onChangeActivity(event, strategyId, actionId, subActionId, activity.id)}
									onDateChange={(event, strategyId, actionId, subActionId) => onChangeActivityDate(event, strategyId, actionId, subActionId, activity.id)}
									onSaveActivity={(event, strategyId, actionId, subActionId) => onSaveActivity(event, strategyId, actionId, subActionId, activity.id)}
									onCancel={onCancel}
									isLoading={isLoading}/>);
							}
							else
								return (<ProgressMonitoringActivity key={activity.id}
								                                    activity={activity}
								                                    isLoading={isLoading}
								                                    isEditing={isEditing}
								                                    onEditActivity={(event) => onEditActivity(event, activity.id)}
								                                    onDeleteActivity={(event, strategyId, actionId, subActionId) => onDeleteActivity(event, strategyId, actionId, subActionId, activity.id)}
																	filters={filters}/>);
						})
					}

					{
                        filters.showButtons &&
						filters.showProgress &&
						<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_PROGRESS_MONITORING}>
							<TileContainer rowTilesLarge={"1"}  rowTilesMedium={"1"}>
								<Tile onClick={!isEditing ? onAddActivity : null} body={"Add Progress Notes"} isNew={true}/>
							</TileContainer>
						</Allow>
					}

				</section>
			}

		</section>
	);
};

SubAction.propTypes = {
    actionIndex: PropTypes.number,
    subActionIndex: PropTypes.number,
	subAction: PropTypes.object.isRequired,
	filters: PropTypes.object.isRequired,
	isEditing: PropTypes.bool.isRequired,
	editItemId: PropTypes.string.isRequired,
	onChangeActivity: PropTypes.func,
    onChangeActivityDate: PropTypes.func,
	onSaveActivity: PropTypes.func,
	onCancel: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onAddActivity: PropTypes.func,
	onEditSubAction: PropTypes.func,
	onEditActivity: PropTypes.func,
	onDeleteActivity: PropTypes.func,
	onDeleteSubAction: PropTypes.func
};

export default SubAction;