import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Tile from "../../components/common/Tiles/Tile";
import TileContainer from "../../components/common/Tiles/TileContainer";
import DrMediationsApi from "../Mediations/drMediationsApi";
import * as drLocations from "../drLocations";

const DrDistrictMediation = ({
	                             actions,
	                             dateWindowId,
	                             districtId,
	                             handleClickView
                             }) => {

	const [mediations, setMediations] = useState([]);
	const getMediations = () => {
		actions.executeApi(DrMediationsApi.getDistrictMediationsByDate, [districtId, drLocations.MEDIATION_TILE, dateWindowId])
			.then((result) => {
				setMediations(result);
			});
	};

	const tileContainerTitle = (mediations.length === 0) ? "No mediations are available at this time" : "Mediations";

	useEffect(() => {
		if (districtId && dateWindowId)
			getMediations();
	}, [districtId, dateWindowId]);

	return (
		<TileContainer title={tileContainerTitle}>
			{
				mediations.map((tile, i) => {
					return (<Tile key={i}
					              header={tile.header}
					              body={tile.body}
					              footer={tile.footer}
					              isNew={tile.isNew}
					              onClick={() => handleClickView(tile.url)}
					/>);
				})
			}

		</TileContainer>
	);
};

DrDistrictMediation.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string,
	districtId: PropTypes.string,
	handleClickView: PropTypes.func.isRequired
};

export default DrDistrictMediation;