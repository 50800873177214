import React from 'react';
import PropTypes from 'prop-types';

const SectionHeader = ({ children }) => {
    return (
        <div className="SectionHeader">
            {children}
        </div>
    );
};

SectionHeader.propTypes = {
    children: PropTypes.any
}

export default SectionHeader;