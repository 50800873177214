import React from "react";
import PropTypes from "prop-types";
import {convertToString, isGuidEmpty, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import EditComponentButtons from "../../../components/common/EditComponentButtons";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FormTable from "../../../components/common/FormTable";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as drDueProcessConstants from "../drDueProcessConstants";
import * as drObjectFactory from "../drDueProcessObjectFactory";
import {
	clearInputFormErrorFields,
	convertFormInputObjectToObject, inputFormIsValid,
	useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import DrDueProcessActionResponse from "./DrDueProcessActionResponse";

const DrDueProcessActionComponentEdit = ({
	                                         action,
	                                         handleCancel,
	                                         handleDataChanged,
	                                         handleSaveActionAsync,
	                                         showCancel
                                         }) => {

	const actionForm = useFormInputObject(drObjectFactory.createActionObject(action), () => handleDataChanged());

	const validateForm = () => {
		clearInputFormErrorFields(actionForm);

		if (isTrimmedStringEmpty(actionForm.description.value))
			actionForm.description.setError("Required.")

		let isValid = inputFormIsValid(actionForm);
		if (!isValid)
			NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

		return isValid;
	};

	const handleClickSave = async () => {
		const isValid = validateForm();
		if (isValid) {

			if(isTrimmedStringEmpty(actionForm.dateDue.value) && !confirm("By leaving the deadline blank, it will default to one year from the closing date.  Press \"Okay\" to continue or \"Cancel\" to return to the page."))
				return;

			const form = convertFormInputObjectToObject(actionForm);
			const result = await handleSaveActionAsync(form);
			if (result) {
				handleCancel();
			}
		}
	};

	return (
		<FormTable className={`dueProcessAction__response`}>
			{
				isGuidEmpty(action.id) &&
				<DisplayField label={`New Corrective Action`} name={`newAction`} showLabel={false}>
					<h2>New Corrective Action</h2>
				</DisplayField>
			}
			<TextAreaEditor
				label="Description"
				name="description"
				showLabel
				{...actionForm.description}
			/>
			<DateTimePickerField
				name="dateDue"
				label="Deadline:"
				showLabel
				{...actionForm.dateDue}
			/>
			{
				!isGuidEmpty(action.districtResponse.id) &&
				<DrDueProcessActionResponse
					response={action.districtResponse}
				/>
			}
			<EditComponentButtons
				handleClickCancel={handleCancel}
				handleClickSave={handleClickSave}
				cancelHidden={!showCancel}
			/>
		</FormTable>
	);
};

DrDueProcessActionComponentEdit.defaultProps = {
	showCancel: true
};

DrDueProcessActionComponentEdit.propTypes = {
	action: PropTypes.object.isRequired,
	handleCancel: PropTypes.func.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	handleSaveActionAsync: PropTypes.func.isRequired,
	showCancel: PropTypes.bool
};

export default DrDueProcessActionComponentEdit;