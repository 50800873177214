import {cloneDeep} from "lodash/lang";
import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {isArrayNullOrEmpty, isNullOrUndefined, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import {convertToDate} from "../../../components/common/dateUtilities";
import GridTable from "../../../components/common/GridTable";
import {getDateRangeFromDescription} from "../../../components/common/inputs/inputUtility";
import PrintTable from "../../../components/common/PrintTable";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../../components/common/searchCriterias/DrSearchCriteria";
import {isDateValid} from "../../../components/shared/sharedDataUtilities";
import {dateSelectOptionChoices, futureDateSelectFieldOptions} from "../../../constants/inputConstants";
import {DrFilter} from "../../DrComponents/DrFilter";
import {TaskItemForTableRow} from "../../../components/tasks/TaskItem";

export const DrStateComplaintUpcomingTasks = ({
                                                  complaintTasks,
                                                  handleClickRecord,
                                                  printSearchCriteria,
                                                  updatePrintSearchCriteria
                                              }) => {
    const [upcomingSearchCriteria, setUpcomingSearchCriteria] = useState(null);
    const [upcomingTasks, setUpcomingTasks] = useState([]);

    const isPrint = !isNullOrUndefined(printSearchCriteria);

    const handleUpcomingSearchFiltersChange = async (criteria) => {
        setUpcomingSearchCriteria(criteria);
        if (!isNullOrUndefined(updatePrintSearchCriteria))
            updatePrintSearchCriteria(criteria);
    };

    useEffect(() => {
        if (isNullOrUndefined(upcomingSearchCriteria)) return;

        let tasks = cloneDeep(complaintTasks);
        if (!isArrayNullOrEmpty(tasks) && upcomingSearchCriteria.startDate)
            tasks = tasks.filter(t => convertToDate(t.dueDate) >= convertToDate(upcomingSearchCriteria.startDate));

        if (!isArrayNullOrEmpty(tasks) && upcomingSearchCriteria.endDate)
            tasks = tasks.filter(t => convertToDate(t.dueDate) <= convertToDate(upcomingSearchCriteria.endDate));

        setUpcomingTasks(tasks);
    }, [upcomingSearchCriteria, complaintTasks]);

    useEffect(() => {
        if (!isNullOrUndefined(upcomingSearchCriteria)) return;

        const dateRangeType = !isNullOrUndefined(printSearchCriteria) ? printSearchCriteria.dateRange : futureDateSelectFieldOptions.ThisWeek;
        const newDateRanges = getDateRangeFromDescription(dateRangeType);
        let newStartDate = newDateRanges.startDate;
        let newEndDate = newDateRanges.endDate;
        if(dateRangeType === futureDateSelectFieldOptions.Custom) {
            newStartDate = decodeURIComponent(printSearchCriteria.startDate);
            newEndDate = decodeURIComponent(printSearchCriteria.endDate);

            newStartDate = isDateValid(newStartDate) && newStartDate !== "-1" ? newStartDate : "";
            newEndDate = isDateValid(newEndDate) && newEndDate !== "-1" ? newEndDate : "";
        }

        let criteria = new DrSearchCriteria("", dateRangeType, newStartDate, newEndDate);
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrSummaryUpcoming);
        criteria.setDateRangeOptionsToUse(false, dateSelectOptionChoices.upcoming);

        setUpcomingSearchCriteria(criteria);
        if (!isNullOrUndefined(updatePrintSearchCriteria))
            updatePrintSearchCriteria(criteria);
    }, [])

    if (!upcomingSearchCriteria) return null;

    const tableDetails = (
        <>
            <thead>
            <tr>
                <th>
                    Tasks
                </th>
            </tr>
            </thead>
            <tbody>
            {
                isArrayNullOrEmpty(upcomingTasks) &&
                <tr>
                    <td className={"center"}>
                        <p><strong>No results match the search criteria specified.</strong></p>
                    </td>
                </tr>
            }
            {
                !isArrayNullOrEmpty(upcomingTasks) &&
                upcomingTasks.map((task, key) => {
                    return <TaskItemForTableRow
                        key={key}
                        isPrint={isPrint}
                        task={task}
                        viewRecord={(event) => handleClickRecord(event, task.url)}
                    />;
                })
            }
            </tbody>
        </>
    );

    return (
        <>
            {
                !isPrint &&
                <>
                    <h2>Upcoming</h2>
                    <DrFilter
                        handleSearchFiltersChange={handleUpcomingSearchFiltersChange}
                        numberOfFilterColumns={1}
                        searchCriteria={upcomingSearchCriteria}
                    />
                    <GridTable className={`dr-summary-upcoming-table`}>
                        {tableDetails}
                    </GridTable>
                </>
            }
            {
                isPrint &&
                <PrintTable caption={`Upcoming - ${upcomingSearchCriteria.outputDateRange()}`} className={"add-padding dr-summary-upcoming-table"}>
                    {tableDetails}
                </PrintTable>
            }
        </>
    );
};

DrStateComplaintUpcomingTasks.propTypes = {
    complaintTasks: PropTypes.array.isRequired,
    handleClickRecord: PropTypes.func,
    printSearchCriteria: PropTypes.object,
    updatePrintSearchCriteria: PropTypes.func
};

export default DrStateComplaintUpcomingTasks;