import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sppActions from "../../actions/sppActions";
import * as layoutActions from "../../actions/layoutActions";
import * as shardDataActions from "../../actions/sharedDataActions";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import SelectField from "../../components/common/inputs/SelectField";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as sppLocations from "../../constants/spp/sppLocations";
import LocalStorage from "../../components/shared/LocalStorage";
import {isSppThreeYearDateWindow} from "../../components/spp/SppUtilities";

export class SppLandingPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.dateWindowChange = this.dateWindowChange.bind(this);
	}

	componentDidMount() {
		const currentUser = LocalStorage.getLoggedInUser();

		if (this.props.dateWindows.length === 0) {
			this.props.actions.loadAllDateWindows()
				.then(() => {
					if(this.props.selectedMultiYearDateWindowId !== "") {
						this.props.actions.loadSppDistrictTiles(currentUser.InstitutionId, this.props.selectedMultiYearDateWindowId, sppLocations.DASHBOARD_TILE_DISTRICT.path);
					}
				});
		}
		else if (this.props.sppTiles.length === 0){
			this.props.actions.loadSppDistrictTiles(currentUser.InstitutionId, this.props.selectedMultiYearDateWindowId, sppLocations.DASHBOARD_TILE_DISTRICT.path);
		}

		this.props.actions.updatePageTitle("Policies and Procedures Documents");
	}

	componentWillUnmount() {
		//this.props.actions.clearSppData();
	}

	dateWindowChange(event) {
		event.preventDefault();
		const currentUser = LocalStorage.getLoggedInUser();

		const selectedMultiYearDateWindowId = event.target.value;

		this.props.actions.changeSelectedMultiDateWindow(selectedMultiYearDateWindowId);
		this.props.actions.loadSppDistrictTiles(currentUser.InstitutionId, selectedMultiYearDateWindowId, sppLocations.DASHBOARD_TILE_DISTRICT.path);
	}


	render() {
		if (this.props.dateWindows.length === 0)
			return null;

		return (
			<div>
				<GridRow rowClass="filterBar">
					<GridColumn>
						<SelectField
							name={"ddlSppDateWindow"}
							label={"Date Window"}
							onChange={this.dateWindowChange}
							options={this.props.dateWindows}
							showLabel
							includeDefaultOption={false}
							labelClass="small-12"
							inputClass="small-12"
							value={this.props.selectedMultiYearDateWindowId}
						/>
					</GridColumn>
				</GridRow>
				{
					this.props.sppTiles &&
					<TileContainer title="Policies and Procedures">
						{
							this.props.sppTiles.map((tile, i) => {
								return (<Tile key={"SPP|" + i}
								              header={tile.header}
								              body={tile.body}
								              footer={tile.footer}
								              onClick={() => this.props.history.push(tile.url)}
								/>);
							})
						}

					</TileContainer>
				}
			</div>
		);
	}
}

SppLandingPage.propTypes = {
	actions: PropTypes.object.isRequired,
	sppTiles: PropTypes.array.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	selectedMultiYearDateWindowId: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const threeYearDateWindows = state.sharedData.dateWindows
		.filter(dw => isSppThreeYearDateWindow(dw.openDate, dw.closeDate));

	const dateWindowSelectItems = sharedDataUtilities.generateDateWindowSelectListItems(threeYearDateWindows);
	const defaultMultiYearDateWindowId = isArrayNullOrEmpty(dateWindowSelectItems) ? "" : dateWindowSelectItems[0].value;
	const selectedMultiYearDateWindowId = state.sharedData.selectedMultiYearDateWindowId || defaultMultiYearDateWindowId;
	return {
		sppTiles: state.spp.sppTiles,
		selectedMultiYearDateWindowId,
		dateWindows: dateWindowSelectItems
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		layoutActions,
		sppActions,
		shardDataActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppLandingPage);