import {isNullOrUndefined} from "../../../components/common/commonUtilities";
import {PTS_ROLES, PTS_ROLES_LIST} from "../ptsConstants";

export const createTeamMembersList = (summary = []) => {
    if (isNullOrUndefined(summary) || summary.length === 0)
        return [];

    let distinctTeamMembers = [];
    for (let project of summary) {
        for (let user of project.discretionaryProjectUsers) {
            if (user.ptsRole !== PTS_ROLES.projectManager.id)
                distinctTeamMembers.push(createTeamMemberListItem(user));
        }
    }
    return distinctTeamMembers;
};

export const createTeamMemberListItem = ({
                                             applicationUserId,
                                             firstName = "",
                                             gmsUserId,
                                             lastName = "",
                                             ptsRole
                                         }) => {
    const role = PTS_ROLES_LIST.find(f => f.id === ptsRole) ?? PTS_ROLES.liaisonUser;
    return {
        userId: applicationUserId || gmsUserId,
        fullName: `${firstName} ${lastName} (${role.text})`
    };
};