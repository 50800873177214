import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {setPageTitle} from "../../../components/common/commonUtilities";
import {DrSearchCriteria} from "../../../components/common/searchCriterias/DrSearchCriteria";
import * as drLocations from "../../drLocations";
import api from "../drStateComplaintsApi";
import DrStateComplaintDueNowTasks from "../Tasks/DrStateComplaintDueNowTasks";
import DrStateComplaintUpcomingTasks from "../Tasks/DrStateComplaintUpcomingTasks";

const StateComplaintTasksContainer = ({
                                          actions,
                                          complaintId,
                                          dateRange,
                                          endDate,
                                          startDate
                                      }) => {
    const [complaintTasks, setComplaintTasks] = useState(null);
    const [printSearchCriteria, setPrintSearchCriteria] = useState(null);
    const [complaintTitle, setComplaintTitle] = useState("");

    const getComplaintTasks = () => {
        if(!complaintId)
            return;

        actions.executeApi(api.getComplaintTasks, [complaintId])
            .then((result) => setComplaintTasks(result));
    };

    const getComplaint = () => {
        actions.executeApi(api.getComplaint, [complaintId])
            .then(complaint => {
                let title = `State Complaint`;
                title += complaint.complaintNumber ? ` ${complaint.complaintNumber}` : "";
                title += " Tasks";

                setComplaintTitle(title)
                setPageTitle(title);
            });
    };

    useEffect(() => {
        getComplaintTasks();
        getComplaint();

        setPrintSearchCriteria(new DrSearchCriteria("", dateRange, startDate, endDate));
    }, []);

    if (!complaintTasks || !printSearchCriteria) return null;

    return (
        <section>
            <header className={`report-header`}>
                <h1>Florida Department of Education
                    <br/>Bureau of Exceptional Education and Student Services</h1>
                <h2>{complaintTitle}</h2>
            </header>
            <DrStateComplaintDueNowTasks
                complaintTasks={complaintTasks}
                isPrint
            />

            <DrStateComplaintUpcomingTasks
                complaintTasks={complaintTasks}
                printSearchCriteria={printSearchCriteria}
            />
        </section>
    );
};

StateComplaintTasksContainer.propTypes = {
    actions: PropTypes.object,
    complaintId: PropTypes.string.isRequired,
    dateRange: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const params = drLocations.STATE_COMPLAINT_TASK_PRINT.getParams(ownProps.location.pathname);
    return {
        ...params
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StateComplaintTasksContainer);
