import React from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import { Link } from 'react-router-dom';
import * as publicLocations from "../../constants/publicLocations";
import LocalStorage from "../../components/shared/LocalStorage";

export class NotAuthorized extends React.PureComponent {
    componentDidMount() {
        this.props.actions.updatePageTitle("Not Authorized");
    }

    render() {
        return (
            <p>You are not authorized to view this content.  If your access was recently changed, you may need to <Link onClick={LocalStorage.logout} to={publicLocations.USER_SIGN_IN.path}>sign back in</Link> again.  If you continue to receive this message, please contact technical support.</p>
        );
    }
}

NotAuthorized.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(layoutActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (NotAuthorized);