import * as PolicyEvents from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";
import {DefaultPolicy} from "./DefaultPolicy";
import * as contexts from "../../../constants/contexts";

class StrategicPlanPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.STRATEGIC_PLAN]);
        this.updatePolicy();
    }

	updatePolicy() {
        //must have MANAGE to ADD_STRAT_PLAN
        this._policy[PolicyEvents.ADD_STRATEGIC_PLAN] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to ADD_STRATEGY
        this._policy[PolicyEvents.ADD_STRATEGY] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to ADD_INDICATOR
        this._policy[PolicyEvents.ADD_INDICATOR] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to ADD_ACTION
        this._policy[PolicyEvents.ADD_ACTION] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have CERTIFY to ADD_SUB_ACTION
        this._policy[PolicyEvents.ADD_SUB_ACTION] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.CERTIFY ], userBehaviors);
            };

        //must have MANAGE to ADD_GOAL
        this._policy[PolicyEvents.ADD_GOAL] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have EDIT to ADD_PROGRESS_MONITORING
        this._policy[PolicyEvents.ADD_PROGRESS_MONITORING] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.EDIT ], userBehaviors);
            };

        //must have CERTIFY to ADD_TEAM_MEMBERs
        this._policy[PolicyEvents.ADD_TEAM_MEMBER] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.CERTIFY ], userBehaviors);
            };

        //must have CERTIFY to ADD_METRICS
        this._policy[PolicyEvents.ADD_METRICS] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.CERTIFY ], userBehaviors);
            };

        //must have EDIT to EDIT_PLAN
        this._policy[PolicyEvents.EDIT_PLAN] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.EDIT ], userBehaviors);
            };
    }
}

export {StrategicPlanPolicy};