import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";
import audit_outcomes from "../constants/auditOutcomes";
import * as sharedDataUtilities from "../components/shared/sharedDataUtilities";

export default function monitoringPerformanceReducer(state = initialState.monitoringPerformance, action) {
	switch (action.type) {
		case actionTypes.LOAD_MONITORING_PERFORMANCE_DISTRICT_STATUSES_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					districtStatuses: [...action.data]
				});
		case actionTypes.SET_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT_ID:
			return Object.assign(
				{},
				state,
				{
					selectedAssessmentId: action.data
				});
		case actionTypes.LOAD_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: action.data,
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.CLEAR_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT:
			return Object.assign(
				{},
				state,
				{
					selectedAssessmentId: "",
					selectedAssessment: {}
				});
		case actionTypes.CREATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: action.data
				});
		case actionTypes.UPDATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.EDIT_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					editItemId: action.data
				});
		case actionTypes.CANCEL_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS: {
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: action.data
				});
		}

		case actionTypes.CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DISTRICT_ACCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							isVisibleToDistrict: action.data
						})
				});
		case actionTypes.EDIT_MONITORING_ASSESSMENT_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isAddingIndicator: true,
					editItemId: action.data
				});
		case actionTypes.ADD_MONITORING_ASSESSMENT_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isAddingIndicator: true
				});
		case actionTypes.CANCEL_ADD_MONITORING_ASSESSMENT_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isAddingIndicator: false,
					editItemId: ""
				});
		case actionTypes.CREATE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isAddingIndicator: false,
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							 indicatorAssessments: state.selectedAssessment.indicatorAssessments ? [...state.selectedAssessment.indicatorAssessments, {...action.data.indicatorAssessment, indicatorAssessmentId: action.data.newId }] : [{...action.data.indicatorAssessment, indicatorAssessmentId: action.data.newId }]
						}
					)
				});
		case actionTypes.DELETE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments
								.filter(ind => ind.indicatorAssessmentId !== action.data.indicatorAssessmentId &&
									ind.indicatorId !== action.data.indicatorId)
						})
				});
		case actionTypes.LOAD_MONITORING_ASSESSMENT_INDICATOR_ACTIONS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(assessment => {
								if (assessment.indicatorAssessmentId === action.data.indicatorAssessmentId)
									return Object.assign(
										{},
										assessment,
										{
											indicatorActions: action.data.indicatorActions
										});
								else
									return Object.assign({}, assessment);
							})
						}
					)
				});
		case actionTypes.DELETE_MONITORING_ASSESSMENT_INDICATOR_ACTION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: ind.indicatorActions ?
											ind.indicatorActions
												.filter(indicatorAction => indicatorAction.actionId !== action.data.indicatorAssessmentActionId) :
											null
									}
								);
							})
						}
					)
				});
		case actionTypes.ADD_INDICATOR_ACTION:
			return Object.assign(
				{},
				state,
				{
					isAddingAction: true,
					isEditing: true,
					editItemId: action.data.newAction.actionId,
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								if (ind.indicatorAssessmentId === action.data.indicatorAssessmentId) {
									return Object.assign(
										{},
										ind,
										{
											indicatorActions: ind.indicatorActions ? [...ind.indicatorActions, action.data.newAction] : [action.data.newAction]
										});
								}

								return ind;
							})
						})
				});
		case actionTypes.CHANGE_INDICATOR_ACTION:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: !ind.indicatorActions ? null : ind.indicatorActions.map(indicatorAction => {
											if (indicatorAction.actionId === action.data.actionId) {
												return Object.assign(
													{},
													action.data
												);
											}

											return indicatorAction;
										})
									});
							})
						})
				});
		case actionTypes.SAVE_INDICATOR_ACTION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					isAddingAction: false,
					editItemId: ""
				});
		case actionTypes.UPDATE_INDICATOR_ACTION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					isAddingAction: false,
					editItemId: ""
				});
		case actionTypes.CANCEL_ADD_INDICATOR_ACTION:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					isAddingAction: false,
					editItemId: ""
				});
		case actionTypes.EDIT_INDICATOR_ACTION:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					editItemId: action.data
				});
		case actionTypes.CREATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.UPDATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.LOAD_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ass => {
								return Object.assign(
									{},
									ass,
									{
										indicatorActions: action.data.indicatorActions.map(act => {
											if (state.selectedAssessment.monitoringAssessmentId === action.data.monitoringAssessmentId && action.actionId === act.actionId) {
												return Object.assign(
													{},
													{
														act
													},
													{
														response: action.data
													}
												);
											}
											else {
												return Object.assign({}, act);
											}
										})
									});
							})
						}
					)
				}
			);
		case actionTypes.EDIT_INDICATOR_ACTION_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					isNew: false,
					editItemId: action.data
				});
		case actionTypes.ADD_INDICATOR_ACTION_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					isNew: true,
					editItemId: action.data.responseId,
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
                                        indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
											if (act.actionId === action.data.actionId) {
												return Object.assign(
													{},
													act,
													{
														response: action.data
													}
												);
											}
											else {
												return Object.assign({}, act);
											}
										})
									});
							})
						})
				});
		case actionTypes.CHANGE_INDICATOR_ACTION_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
											if (!act.response) {
												return Object.assign({}, act);
											}
											else if (act.response.responseId !== action.data.responseId) {
												return Object.assign({}, act);
											}
											else {
												return Object.assign(
													{},
													act,
													{
														response: action.data
													});
											}
										})
									});
							})
						})
				});
		case actionTypes.CANCEL_ADD_INDICATOR_ACTION_RESPONSE:
			return Object.assign(
				{},
				state,
				{
					isEditing: false,
					editItemId: ""
				});
		case actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_SUBMIT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								if (ind.indicatorAssessmentId !== action.data.indicatorId) {
									return Object.assign({}, ind);
								}
								else {
									return Object.assign(
										{},
										ind,
										{
											totalInProgressOrNeedsRevision: ind.totalInProgressOrNeedsRevision - 1,
											totalSubmittedAndReviewable: ind.totalSubmittedAndReviewable + 1,
											indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
												if (act.actionId !== action.data.actionId) {
													return Object.assign({}, act);
												}
												else {

													return Object.assign(
														{},
														act,
														{
															canDistrictEditData: false,
															actionStatus: audit_outcomes.SUBMITTED.value
														});
												}
											})
										});
								}
							})
						})
				});
		case actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_REJECT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								if (ind.indicatorAssessmentId !== action.data.indicatorAssessmentId) {
									return Object.assign({}, ind);
								}
								else {
									return Object.assign(
										{},
										ind,
										{
											totalInProgressOrNeedsRevision: ind.totalInProgressOrNeedsRevision + 1,
											totalSubmittedAndReviewable: ind.totalSubmittedAndReviewable - 1,
											indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
												if (act.actionId !== action.data.actionId) {
													return Object.assign({}, act);
												}
												else {
													return Object.assign(
														{},
														act,
														{
															canDistrictEditData: true,
															actionStatus: audit_outcomes.NEEDS_REVISION.value
														});
												}
											})
										});
								}
							})
						})
				});

		case actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_APPROVE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								if (ind.indicatorAssessmentId !== action.data.indicatorAssessmentId) {
									return Object.assign({}, ind);
								}
								else {
									return Object.assign(
										{},
										ind,
										{
											totalSubmittedAndReviewable: ind.totalSubmittedAndReviewable - 1,
											indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
												if (act.actionId !== action.data.actionId) {
													return Object.assign({}, act);
												}
												else {
													return Object.assign(
														{},
														act,
														{
															actionStatus: audit_outcomes.ACCEPTED.value
														});
												}
											})
										});
								}
							})
						})
				});

		case actionTypes.SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_RESET_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								if (ind.indicatorAssessmentId !== action.data.indicatorAssessmentId) {
									return Object.assign({}, ind);
								}
								else {
									return Object.assign(
										{},
										ind,
										{
											totalSubmittedAndReviewable: ind.totalSubmittedAndReviewable + 1,
											indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
												if (act.actionId !== action.data.actionId) {
													return Object.assign({}, act);
												}
												else {
													return Object.assign(
														{},
														act,
														{
															actionStatus: audit_outcomes.SUBMITTED.value
														});
												}
											})
										});
								}
							})
						})
				});
		case actionTypes.CREATE_PERFORMANCE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					isCommentInEditMode: true,
					commentId: "",
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
											act = Object.assign({}, act);

											if(act.actionId !== action.data.actionId) return act;

											act.audits = [...act.audits].map(a => {
												a = Object.assign({}, a);

												if (a.auditId !== action.data.auditId) return a;

												const comments = [...a.comments];
												comments.unshift(sharedDataUtilities.newComment(action.data.auditId));
												a.comments = comments;

												return a;
											});

											return act;
										})
									});
							})
						})
				}
			);
		case actionTypes.CANCEL_PERFORMANCE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					isCommentInEditMode: false,
					commentId: "",
				}
			);
		case actionTypes.EDIT_PERFORMANCE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					isCommentInEditMode: true,
					commentId: action.data,
				}
			);
		case actionTypes.CHANGE_PERFORMANCE_AUDIT_COMMENT:
			return Object.assign(
				{},
				state,
				{
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
											act = Object.assign({}, act);

											if(act.actionId !== action.data.actionId) return act;

											act.audits = [...act.audits].map(a => {
												a = Object.assign({}, a);

												if (a.auditId !== action.data.comment.auditId) return a;

												a.comments = [...a.comments].map(c => {
													c = Object.assign({}, c);

													if(c.auditCommentId !== action.data.comment.auditCommentId) return c;

													return action.data.comment;
												});

												return a;
											});

											return act;
										})
									});
							})
						})
				}
			);
		case actionTypes.UPDATE_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isCommentInEditMode: false,
					commentId: "",
				}
			);
		case actionTypes.ADD_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					isCommentInEditMode: false,
					commentId: "",
					selectedAssessment: Object.assign(
						{},
						state.selectedAssessment,
						{
							indicatorAssessments: state.selectedAssessment.indicatorAssessments.map(ind => {
								return Object.assign(
									{},
									ind,
									{
										indicatorActions: (!ind.indicatorActions || ind.indicatorActions.length === 0) ? null : ind.indicatorActions.map(act => {
											act = Object.assign({}, act);

											if(act.actionId !== action.data.actionId) return act;

											act.audits = [...act.audits].map(a => {
												a = Object.assign({}, a);

												if (a.auditId !== action.data.comment.auditId) return a;

												a.comments = [...a.comments].map(c => {
													c = Object.assign({}, c);

													if(c.auditCommentId !== "") return c;

													return {...action.data.comment, auditCommentId: action.data.auditCommentId};
												});

												return a;
											});

											return act;
										})
									});
							})
						})
				}
			);
		case actionTypes.IMPERSONATING_AJAX_SUCCESS:
		case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
		case actionTypes.LOGOUT_AJAX_SUCCESS:
			return initialState.monitoringPerformance;
		default:
			return state;
	}
}