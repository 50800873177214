import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../buttons/ButtonBar";
import ButtonIcon from "../buttons/Button";
import ButtonMock from "../buttons/ButtonMock";
import * as ButtonTypes from "../../../constants/ButtonTypes";

const ResultsPager = ({first, previous, next, last, totalRecords, recordsPerPage, currentPage, disabled }) => {

    let firstVisibleRecord = (currentPage - 1) * recordsPerPage + 1;

    let recordsOnCurrentPage = currentPage * recordsPerPage;
    let lastVisibleRecord = totalRecords > recordsOnCurrentPage ?
        ((currentPage - 1) * recordsPerPage) + recordsPerPage :
        totalRecords;

    let cantNavigateBackward = currentPage === 1;
    let cantNavigateForward = lastVisibleRecord >= totalRecords;

    return (
        <ButtonBar position={""}>
            <ButtonIcon onClick={first}
                        buttonType={ButtonTypes.FIRST}
                        name="btnFirstPage"
                        label="First Page"
                        showLabel={false}
                        disabled={disabled || cantNavigateBackward} />
            <ButtonIcon onClick={previous}
                        buttonType={ButtonTypes.PREVIOUS}
                        name="btnPreviousPage"
                        label="Previous Page"
                        showLabel={false}
                        disabled={disabled || cantNavigateBackward} />

            <ButtonMock name="record-information"
                        label={firstVisibleRecord + " - " + lastVisibleRecord + " of " + totalRecords} />

            <ButtonIcon onClick={next}
                        buttonType={ButtonTypes.NEXT}
                        name="btnNextPage"
                        label="Next Page"
                        showLabel={false}
                        disabled={disabled || cantNavigateForward} />
            <ButtonIcon onClick={last}
                        buttonType={ButtonTypes.LAST}
                        name="btnLastPage"
                        label="Last Page"
                        showLabel={false}
                        disabled={disabled || cantNavigateForward} />

        </ButtonBar>
    );
};

ResultsPager.propTypes = {
    first: PropTypes.func.isRequired,
    previous:PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    last: PropTypes.func.isRequired,
    totalRecords: PropTypes.number,
    recordsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    disabled: PropTypes.bool.isRequired
};

ResultsPager.defaultProps = {
    disabled: false
};

export default ResultsPager;