import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import {
    createListFromObject, isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty,
    setPageTitle
} from "../../components/common/commonUtilities";
import drApi from "../drApi";
import {CaseNumberPostFix, DisputeResolutionType, RadioButtonYesNoObject} from "../drConstants";
import {createTeamMemberList} from "../drObjectFactory";
import DrNotificationsContainer from "../Notifications/DrNotificationsContainer";
import {getAssociatedRoles} from "./StateComplaintsUtilities";
import api from "./drStateComplaintsApi";
import Button from "../../components/common/buttons/Button";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import * as LayoutActions from "../../actions/layoutActions";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as drLocations from "../drLocations";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {createComplaintObject} from "./drStateComplaintObjectFactory";
import {dispute_resolution} from "../../constants/policyEvents";
import {StateComplaintPolicy} from "../../components/authorization/policies/StateComplaintPolicy";
import {fetchDate, fetchDistrict,} from "../../components/shared/sharedDataUtilities";
import {StateComplaintSections} from "./drStateComplaintConstants";
import DrStateComplaintTasksContainer from "./Tasks/DrStateComplaintTasksContainer";
import {createCaseNumberObject} from "../drUtilities";

const DrStateComplaintWrapper = ({
                                     actions,
                                     allInstitutions,
                                     complaintId,
                                     Component,
                                     currentLocation,
                                     dateWindowId,
                                     dateWindows,
                                     history,
                                     institutionId,
                                     props
                                 }) => {

    const [dataChanged, setDataChanged] = useState(false);
    const [district, setDistrict] = useState("");
    const [date, setDate] = useState("");
    const [complaint, setComplaint] = useState(null);
    const [userDrTeamMemberRoles, setUserDrTeamMemberRoles] = useState([]);
    const [teamMembers, setTeamMembers] = useState(createListFromObject([]));

    const returnCaseListUrl = drLocations.LANDING_CASE_LIST.getUrl();
    const returnDrSummaryUrl = drLocations.DR_SUMMARY.getUrl();
    const overviewUrl = drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, institutionId, complaintId);
    const intakeUrl = drLocations.STATE_COMPLAINT_INTAKE.getUrl(dateWindowId, institutionId, complaintId);
    const timelineUrl = drLocations.STATE_COMPLAINT_TIMELINE.getUrl(dateWindowId, institutionId, complaintId);
    const routingUrl = drLocations.STATE_COMPLAINT_ROUTING.getUrl(dateWindowId, institutionId, complaintId);
    const outcomeUrl = drLocations.STATE_COMPLAINT_OUTCOME.getUrl(dateWindowId, institutionId, complaintId);
    const issuesUrl = drLocations.STATE_COMPLAINT_ISSUES.getUrl(dateWindowId, institutionId, complaintId);
    const actionsUrl = drLocations.STATE_COMPLAINT_ACTIONS.getUrl(dateWindowId, institutionId, complaintId);
    const responsesUrl = drLocations.STATE_COMPLAINT_RESPONSES.getUrl(dateWindowId, institutionId, complaintId);
    const closureUrl = drLocations.STATE_COMPLAINT_CLOSURE_PROCESS.getUrl(dateWindowId, institutionId, complaintId);
    const closureWithNoActionsUrl = drLocations.STATE_COMPLAINT_CLOSURE_WITH_NO_ACTIONS.getUrl(dateWindowId, institutionId, complaintId);
    const caseNotesUrl = drLocations.STATE_COMPLAINT_CASE_NOTES.getUrl(dateWindowId, institutionId, complaintId);

    const scroller = Scroll.scroller;
    const animateScroll = Scroll.animateScroll;
    const scrollConfig = {
        duration: 500,
        delay: 50,
        smooth: true
    };
    const handleScroll = (containerId) => {
        window.setTimeout(() => {
            scroller.scrollTo(containerId, scrollConfig);
        }, 150);
    };

    const handleScrollToTop = () => {
        window.setTimeout(() => {
            animateScroll.scrollToTop(scrollConfig);
        }, 150);
    }

    const handleReturn = () => history.push(returnCaseListUrl);

    const handleSelectSection = (event, section, forceReplace = false) => {
        let url = "";
        switch (section) {
            case StateComplaintSections.Overview:
                url = overviewUrl;
                break;
            case StateComplaintSections.Intake:
                url = intakeUrl;
                break;
            case StateComplaintSections.Timeline:
                url = timelineUrl;
                break;
            case StateComplaintSections.Routing:
                url = routingUrl;
                break;
            case StateComplaintSections.Outcome:
                url = outcomeUrl;
                break;
            case StateComplaintSections.IssuesActions:
            case StateComplaintSections.Issues:
                url = issuesUrl;
                break;
            case StateComplaintSections.Actions:
                url = actionsUrl;
                break;
            case StateComplaintSections.Responses:
                url = responsesUrl;
                break;
            case StateComplaintSections.Closure:
                url = closureUrl;
                break;
            case StateComplaintSections.ClosureWithNoActions:
                url = closureWithNoActionsUrl;
                break;
            case StateComplaintSections.CaseNotes:
                url = caseNotesUrl;
                break;
        }

        if (url)
            navButtonClick(event, url, forceReplace);

        if (forceReplace)
            loadComplaint();
    };

    const getSectionFromCurrentLocation = () => {
        switch (currentLocation) {
            case overviewUrl:
                return StateComplaintSections.Overview;
            case intakeUrl:
                return StateComplaintSections.Intake;
            case timelineUrl:
                return StateComplaintSections.Timeline;
            case routingUrl:
                return StateComplaintSections.Routing;
            case outcomeUrl:
                return StateComplaintSections.Outcome;
            case issuesUrl:
                return complaint.hasCorrectiveActions === RadioButtonYesNoObject.Yes ? StateComplaintSections.IssuesActions : StateComplaintSections.Issues;
            case actionsUrl:
                return StateComplaintSections.Actions;
            case responsesUrl:
                return StateComplaintSections.Responses;
            case closureUrl:
                return StateComplaintSections.Closure;
            case closureWithNoActionsUrl:
                return StateComplaintSections.ClosureWithNoActions;
            case caseNotesUrl:
                return StateComplaintSections.CaseNotes;
        }

        return "";
    }

    const navButtonClick = (event, location, forceReplace = false) => {
        event.preventDefault();

        if (forceReplace) {
            history.replace(location);
            setDataChanged(false);
            return;
        }

        if (location === currentLocation) return;

        if (dataChanged) {
            if (confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page.")) {
                if (location !== returnCaseListUrl && location !== returnDrSummaryUrl)
                    loadComplaint();
            } else
                return;
        }

        setDataChanged(false);
        history.push(location);

        handleScrollToTop();
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const handleUpdateComplaint = (complaint) => {
        setComplaint(createComplaintObject(complaint));
        setDataChanged(false);
    };

    const getComplaint = () => {
        actions.executeApi(api.getComplaint, [complaintId])
            .then((result) => handleUpdateComplaint(result));
    };

    const getDefaultComplaintNumber = () => {
        actions.executeApi(api.getDefaultComplaintCaseNumber, [dateWindowId])
            .then((result) => {
                const caseNumberObject = createCaseNumberObject(result, CaseNumberPostFix.Complaint, dateWindows, dateWindowId);

                handleUpdateComplaint(
                    {
                        caseNumberCount: result,
                        caseNumberYear: caseNumberObject.caseNumberYear,
                        caseNumberType: caseNumberObject.caseNumberType,
                        complaintNumber: caseNumberObject.caseNumber
                });
            });
    };

    const handleAddComplaint = (form) => {
        setDataChanged(false);
        form.dateWindowId = dateWindowId;
        form.districtId = institutionId;
        actions.executeApi(api.addComplaint, [form])
            .then((result) => {
                const url = drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, institutionId, result.id);
                handleUpdateComplaint(result);
                history.push(url);
            });
    };

    const handleSaveActionAsync = async (action) => {
        handleDataChanged(false);
        action.complaintId = complaintId;
        return actions.executeApi(api.saveAction, [action])
            .then((result) => {
                handleUpdateComplaint(result);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleDeleteAction = (action) => {
        action.complaintId = complaintId;
        if (action && confirm("Are you sure you want to delete this action?")) {
            actions.executeApi(api.deleteAction, [action])
                .then((result) => {
                    handleUpdateComplaint(result);
                });
        }
    };

    const isUserAuthorizedAndStateComplaintEditable = () => {
        const isAuthorized = allow(StateComplaintPolicy, dispute_resolution.stateComplaint.MODIFY);
        return isAuthorized && complaint.isEditable;
    };

    const loadComplaint = () => {
        if (complaintId && complaintId !== drLocations.COMPLAINT_ID_NEW)
            getComplaint();
        else
            getDefaultComplaintNumber();
    }
    useEffect(() => {
        if(date || isArrayNullOrEmpty(dateWindows)) return;

        loadComplaint();
    }, [complaintId, date, dateWindows]);

    useEffect(() => {
        if (allInstitutions && institutionId)
            setDistrict(fetchDistrict(allInstitutions, institutionId));
    }, [allInstitutions, institutionId]);

    useEffect(() => {
        if(date || isArrayNullOrEmpty(dateWindows)) return;

        setDate(fetchDate(dateWindows, dateWindowId));
    }, [dateWindows, dateWindowId]);

    useEffect(() => {
        if (district && date && complaint) {
            let complaintDetails = complaint.complaintNumber ? `Case No ${complaint.complaintNumber}` : "";

            const currentSection = getSectionFromCurrentLocation();
            const headerLeft = `Complaint`;
            const headerCenter = `${district} (${date})`;
            actions.updatePageTitle(headerLeft, headerCenter, complaintDetails);
            setPageTitle(`${headerLeft}: ${headerCenter}${complaint.complaintNumber ? `, ${complaintDetails}` : ""}${isTrimmedStringEmpty(currentSection) ? "" : ` - ${currentSection}`}`);
        }

    }, [district, date, complaint, currentLocation]);

    useEffect(() => {
        drApi.getTeamMembers()
            .then((result) => setTeamMembers(createTeamMemberList(result, true)));
    }, []);

    useEffect(() => {
        if (isNullOrUndefined(complaint) || teamMembers.length === 0)
            return;

        setUserDrTeamMemberRoles(getAssociatedRoles(complaint, teamMembers));

    }, [teamMembers, complaint]);

    if (!complaint) return null;

    return (
        <GridRow
            isPageNav
        >
            <GridColumn large="3">
                <GridRow>
                    <GridColumn
                        isPageNavLinks
                        isStickyLinks
                    >
                        <Button
                            btnClass={currentLocation === overviewUrl ? "is-active" : ""}
                            onClick={(event) => navButtonClick(event, overviewUrl)}
                            label={StateComplaintSections.Overview}
                            name={overviewUrl}
                        />
                        {
                            complaint.isIntakeRequirementEnabled &&
                            <Button
                                btnClass={currentLocation === intakeUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, intakeUrl)}
                                label={StateComplaintSections.Intake}
                                name={intakeUrl}
                            />
                        }
                        {
                            complaint.isTimelineEnabled &&
                            <Button
                                btnClass={currentLocation === timelineUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, timelineUrl)}
                                label={StateComplaintSections.Timeline}
                                name={timelineUrl}
                            />
                        }
                        {
                            complaint.timelineModel &&
                            complaint.timelineModel.displayRoutingSection &&
                            <Button
                                btnClass={currentLocation === routingUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, routingUrl)}
                                label={StateComplaintSections.Routing}
                                name={routingUrl}
                            />
                        }
                        {
                            complaint.id &&
                            <Button
                                btnClass={currentLocation === outcomeUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, outcomeUrl)}
                                label={StateComplaintSections.Outcome}
                                name={outcomeUrl}
                            />
                        }
                        {
                            complaint.isIssueEnabled &&
                            complaint.hasCorrectiveActions === RadioButtonYesNoObject.Yes &&
                            <Button
                                btnClass={currentLocation === issuesUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, issuesUrl)}
                                label={StateComplaintSections.IssuesActions}
                                name={issuesUrl}
                            />
                        }
                        {
                            complaint.isIssueEnabled &&
                            complaint.hasCorrectiveActions !== RadioButtonYesNoObject.Yes &&
                            <Button
                                btnClass={currentLocation === issuesUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, issuesUrl)}
                                label={StateComplaintSections.Issues}
                                name={issuesUrl}
                            />
                        }
                        {
                            complaint.isActionEnabled &&
                            <Button
                                btnClass={currentLocation === actionsUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, actionsUrl)}
                                label={StateComplaintSections.Actions}
                                name={actionsUrl}
                            />
                        }
                        {
                            complaint.isResponsesEnabled &&
                            complaint.hasCorrectiveActions === RadioButtonYesNoObject.Yes &&
                            <Button
                                btnClass={currentLocation === responsesUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, responsesUrl)}
                                label={StateComplaintSections.Responses}
                                name={responsesUrl}
                            />
                        }
                        {
                            complaint.allActionsAccepted &&
                            <Button
                                btnClass={currentLocation === closureUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, closureUrl)}
                                label={StateComplaintSections.Closure}
                                name={closureUrl}
                            />
                        }
                        {
                            complaint.hasCorrectiveActions === RadioButtonYesNoObject.No &&
                            complaint.issueModels &&
                            complaint.issueModels.length > 0 &&
                            <Button
                                btnClass={currentLocation === closureWithNoActionsUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, closureWithNoActionsUrl)}
                                label={StateComplaintSections.ClosureWithNoActions}
                                name={closureWithNoActionsUrl}
                            />
                        }
                        {
                            complaint.id &&
                            <Button
                                btnClass={currentLocation === caseNotesUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, caseNotesUrl)}
                                label={StateComplaintSections.CaseNotes}
                                name={caseNotesUrl}
                            />
                        }
                        <Button
                            onClick={(event) => navButtonClick(event, returnCaseListUrl)}
                            label={"Return to Case List"}
                            name={`btnBackCaseList`}
                        />
                        <Button
                            onClick={(event) => navButtonClick(event, returnDrSummaryUrl)}
                            label={"Return to My Summary"}
                            name={`btnBackDrSummary`}
                        />
                    </GridColumn>
                    <DrStateComplaintTasksContainer
                        actions={actions}
                        api={api}
                        complaint={complaint}
                        history={history}
                    />
                </GridRow>
            </GridColumn>
            <GridColumn
                isPageNavContent
                large="9"
            >
                <DrNotificationsContainer
                    actions={actions}
                    id={complaintId}
                    drType={DisputeResolutionType.StateComplaints}
                />
                <Component
                    {...props}
                    actions={actions}
                    api={api}
                    complaint={complaint}
                    dataChanged={dataChanged}
                    handleAddComplaint={handleAddComplaint}
                    handleDeleteAction={handleDeleteAction}
                    handleReturn={handleReturn}
                    handleSaveActionAsync={handleSaveActionAsync}
                    handleScroll={handleScroll}
                    handleScrollToTop={handleScrollToTop}
                    handleSelectSection={handleSelectSection}
                    handleUpdateComplaint={handleUpdateComplaint}
                    handleDataChanged={handleDataChanged}
                    readOnly={!isUserAuthorizedAndStateComplaintEditable()}
                    teamMembers={teamMembers}
                    userDrTeamMemberRoles={userDrTeamMemberRoles}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;
    const params = drLocations.STATE_COMPLAINT.getParams(currentLocation);

    return {
        Component,
        currentLocation,
        ...params,
        props
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrStateComplaintWrapper.propTypes = {
    actions: PropTypes.object,
    allInstitutions: PropTypes.array,
    complaintId: PropTypes.string,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindowId: PropTypes.string,
    dateWindows: PropTypes.array,
    history: PropTypes.object,
    institutionId: PropTypes.string,
    props: PropTypes.object,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrStateComplaintWrapper);