import React from "react";
import PropTypes from "prop-types";
import {INSTITUTION_TYPE_FORM_NAME, INSTITUTION_FORM_TYPES_LIST} from "../../constants/sharedData/institutionTypes";
import TextField from "../common/inputs/TextField";
import ButtonIcon from "../common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import SelectField from "../common/inputs/SelectField";
import * as classHooks from "../../constants/sharedData/classHooks";

const InstitutionForm = ({
	                         institution,
	                         isLoading,
	                         onChangeInstitution,
	                         onSaveInstitution,
	                         onCancelInstitution
                         }) => {

	return (
		<tr id={institution.id} className={classHooks.INSTITUTION}>
			<td>
				<TextField name={"edwId"}
				           label={"Number"}
				           onChange={onChangeInstitution}
				           value={institution.edwId}/>
			</td>
			<td>
				<TextField name={"name"}
				           label={"Name"}
				           onChange={onChangeInstitution}
				           value={institution.name}/></td>
			<td>
				<SelectField name={INSTITUTION_TYPE_FORM_NAME}
				             label={"Type"}
				             onChange={onChangeInstitution}
				             value={institution.institutionType}
				             options={INSTITUTION_FORM_TYPES_LIST}/></td>
			<td className="button-cell">
				<ButtonBar position={ButtonBarPositions.TABLE}>
					<ButtonIcon name={"btnSaveInstitution"}
					            label={"save"}
					            buttonType={ButtonTypes.SAVE}
					            onClick={onSaveInstitution}
					            showLabel={false}
					            disabled={isLoading}/>
					<ButtonIcon name={"btnCancelInstitution"}
					            label={"cancel"}
					            buttonType={ButtonTypes.CANCEL}
					            onClick={onCancelInstitution}
					            disabled={isLoading}/>
				</ButtonBar>
			</td>
		</tr>
	);
};

InstitutionForm.propTypes = {
	institution: PropTypes.object.isRequired,
	onChangeInstitution: PropTypes.func.isRequired,
	onSaveInstitution: PropTypes.func.isRequired,
	onCancelInstitution: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default InstitutionForm;