import React, {useState} from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isDifferenceInDatesGreaterThanOneYear} from "../../../components/common/dateUtilities";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import GridRow from "../../../components/common/GridRow";
import GridColumn from "../../../components/common/GridColumn";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import TextField from "../../../components/common/inputs/TextField";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {styles as ActionButton} from "../../../constants/ActionButton";
import {createFakeEvent} from "../../../components/common/inputs/inputUtility";
import {isDateValid} from "../../../components/shared/sharedDataUtilities";
import {createDeadlineExtensionObject, createDeadlineObject} from "../drStateComplaintObjectFactory";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import * as buttonBarPositions from "../../../constants/ButtonBarPositions";

const DrStateComplaintActionDeadlinesEdit = ({
                                                 dateOrdered,
                                                 onChange: setDeadlines,
                                                 value: deadlines
                                             }) => {

    const [dateDue, setDateDue] = useState("");
    const [extendedDateDue, setExtendedDateDue] = useState("");
    const [extendedDateDueReason, setExtendedDateDueReason] = useState("");
    const [extendedDeadlineId, setExtendedDeadlineId] = useState("");


    const handleClickAddDeadline = (event) => {
        const newDateDue = event.target.value;
        const isExisting = !isArrayNullOrEmpty(deadlines.filter(d => d.dateDue === newDateDue));

        setDateDue(newDateDue);

        if (!isDateValid(newDateDue) || isExisting) return clearNewDeadlineAfterSave();

        let updatedDeadlines = [...deadlines];
        const newDeadline = createDeadlineObject({dateDue: newDateDue});
        updatedDeadlines.push(newDeadline);
        handleSetDeadlines(updatedDeadlines);

        clearNewDeadlineAfterSave();
    };

    const clearNewDeadlineAfterSave = () => {
        setDateDue("");
    };

    const handleClickRemoveDeadline = (removeDate) => {
        let updatedDeadlines = [...deadlines];
        updatedDeadlines = updatedDeadlines.filter(d => d.dateDue !== removeDate);
        handleSetDeadlines(updatedDeadlines);
    };

    const handleSetDeadlines = (updatedDeadlines) => {
        setDeadlines(createFakeEvent(updatedDeadlines, "deadlines"));
    };

    const handleAddExtensionDateDue = (event, deadlineId) => {
        event.preventDefault();

        clearNewExtendedDeadline();
        setExtendedDeadlineId(deadlineId);
    }

    const validateExtensionDueDate = () => {
        let errors = "";
        if (extendedDateDue === "")
            errors += "<li>Extended due date is required.</li>";
        else if (extendedDateDueReason === "")
            errors += "<li>Extended due date reason is required.</li>";

        if (errors === "")
            return true;

        NotifyUser.Error(errors);
        return false;
    }

    const handleSaveDeadlineExtension = (event) => {
        event.preventDefault();

        let updatedDeadlines = [...deadlines];
        const currentDeadline = updatedDeadlines.find(f => f.id === extendedDeadlineId);

        if (!currentDeadline) return;

        if (!validateExtensionDueDate()) return;

        if (isDifferenceInDatesGreaterThanOneYear(dateOrdered, extendedDateDue)
                && !confirm("Warning: In exercising its monitoring responsibilities under paragraph (d) of this section, the State must ensure that when it identifies noncompliance with the requirements of this part by LEAs, the noncompliance is corrected as soon as possible, and in no case later than one year after the State’s identification of the noncompliance.\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            return;

        const isExisting = !isArrayNullOrEmpty(currentDeadline.deadlineExtensions.filter(d => d.extendedDateDue === extendedDateDue));

        if (!isDateValid(extendedDateDue) || isExisting) return clearNewExtendedDeadline();

        const newExtensionDeadline = createDeadlineExtensionObject({
            deadlineId: extendedDeadlineId,
            extendedDateDue: extendedDateDue,
            reasonForExtension: extendedDateDueReason
        });
        currentDeadline.deadlineExtensions.push(newExtensionDeadline);

        handleSetDeadlines(updatedDeadlines);

        clearNewExtendedDeadline();
    };

    const clearNewExtendedDeadline = () => {
        setExtendedDateDue("");
        setExtendedDateDueReason("");
        setExtendedDeadlineId("");
    };

    const cancelExtendedDeadlineClick = (event) => {
        event.preventDefault();
        clearNewExtendedDeadline();
    }

    const handleRemoveExtensionClick = (event, removedExtendedDateDue, deadlineId) => {
        event.preventDefault();

        let updatedDeadlines = [...deadlines];
        const currentDeadline = updatedDeadlines.find(f => f.id === deadlineId);
        if (!currentDeadline) return;

        currentDeadline.deadlineExtensions = currentDeadline.deadlineExtensions.filter(d => d.extendedDateDue !== removedExtendedDateDue);

        handleSetDeadlines(updatedDeadlines);
    }

    return (
        <DisplayField
            label={"Deadlines"}
            name="deadlines"
            showLabel
        >
            {
                deadlines.map((deadline, key) => {
                    return (
                        <div key={key} className={`deadline-container`}>
                            <GridRow>
                                <GridColumn large={`6`}>
                                    <DateTimePickerField
                                        disabled
                                        label={`deadline${key}`}
                                        name={`deadline${key}`}
                                        showLabel={false}
                                        value={deadline.dateDue}
                                        wrapperClass={`align-center`}
                                    />
                                    <span className={ActionButton.position.RIGHT}>
                                        <Button
                                            buttonType={ButtonTypes.REMOVE}
                                            label={"Delete"}
                                            name={`delete${key}`}
                                            onClick={() => handleClickRemoveDeadline(deadline.dateDue)}
                                            showLabel={false}
                                        />
                                    </span>
                                </GridColumn>
                            </GridRow>
                            {
                                deadline.deadlineExtensions.map((extension, extensionKey) => {
                                    return (
                                        <FieldsetLayout legend={`Action Deadline Extensions`} key={extensionKey}>
                                            <GridRow>
                                                <GridColumn>
                                                     <span className={ActionButton.position.RIGHT}>
                                                        <Button
                                                            label={"Remove Extension"}
                                                            name={"btnRemoveExtension"}
                                                            buttonType={ButtonTypes.REMOVE}
                                                            showLabel={false}
                                                            onClick={(event) => handleRemoveExtensionClick(event, extension.extendedDateDue, deadline.id)}
                                                        />
                                                    </span>
                                                    <DateTimePickerField
                                                        name={`DeadlineExtendedDate`}
                                                        label={`Extended Deadline Date #${extensionKey + 1}`}
                                                        showLabel
                                                        value={extension.extendedDateDue}
                                                        disabled
                                                        medium={6}
                                                    />
                                                </GridColumn>
                                            </GridRow>
                                            <DisplayField
                                                name={`DeadlineExtendedReason`}
                                                label={`Reason`}
                                                columnsMedium={6}
                                                showLabel
                                            >
                                                <p>{extension.reasonForExtension}</p>
                                            </DisplayField>
                                        </FieldsetLayout>
                                    );
                                })
                            }
                            {
                                !!deadline.actionId &&
                                !!deadline.dateDue &&
                                !extendedDeadlineId &&
                                <ButtonBar position={buttonBarPositions.COLUMN}>
                                    <Button
                                        label={`Add Extension For Due Date`}
                                        name={`btnAddExtension`}
                                        showLabel
                                        onClick={(event) => handleAddExtensionDateDue(event, deadline.id)}
                                    />
                                </ButtonBar>
                            }
                            {
                                !!deadline.dateDue &&
                                extendedDeadlineId === deadline.id &&
                                <GridRow>
                                    <GridColumn>
                                        <DateTimePickerField
                                            name="extendedDateDue"
                                            label="If extending the deadline, provide the new date"
                                            showLabel
                                            medium={6}
                                            value={extendedDateDue}
                                            onChange={(event) => setExtendedDateDue(event.target.value)}
                                        />
                                        {
                                            !!extendedDateDue &&
                                            <TextField
                                                name="reasonForExtension"
                                                label="Reason for Extension"
                                                showLabel
                                                columnsMedium={6}
                                                value={extendedDateDueReason}
                                                onChange={(event) => setExtendedDateDueReason(event.target.value)}
                                            />
                                        }
                                        <ButtonBar position={buttonBarPositions.COLUMN}>
                                            <Button label={`Save`} name={`btnSaveExtension`}
                                                    onClick={handleSaveDeadlineExtension} showLabel/>
                                            <Button label={`Cancel`} name={`btnCancelExtension`}
                                                    onClick={cancelExtendedDeadlineClick} showLabel/>
                                        </ButtonBar>
                                    </GridColumn>
                                </GridRow>
                            }
                        </div>);
                })
            }
            <DateTimePickerField
                value={dateDue}
                label="Add Due Date"
                showLabel
                name={`newDeadline`}
                large={6}
                onChange={(event) => handleClickAddDeadline(event)}
            />
        </DisplayField>
    );
};

DrStateComplaintActionDeadlinesEdit.propTypes = {
    dateOrdered: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
};

export default DrStateComplaintActionDeadlinesEdit;