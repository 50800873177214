import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {NotifyUser} from "../../../components/common/NotifyUser";
import LocalStorage from "../../../components/shared/LocalStorage";
import {getDifferentBetweenTimesInMinutes} from "../../../components/shared/sharedDataUtilities";
import {DrComplaintTimelineRequirementResponseOptionsEnum} from "../../drConstants";
import DrStateComplaintRoutingView from "./DrStateComplaintRoutingView";
import {
    isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../components/common/commonUtilities";
import {createRoutingCaseNoteObject} from "../drStateComplaintObjectFactory";
import api from "../drStateComplaintsApi";

const DrStateComplaintRoutingContainer = ({
                                               actions,
                                               complaint,
                                               readOnly,
                                               handleSelectSection,
                                               handleScroll,
                                               handleUpdateComplaint,
                                               handleDataChanged,
                                               userDrTeamMemberRoles
                                           }) => {
    const [timelineResponses, setTimelineResponses] = useState([]);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const checkIsLoading = () => {
        let result = true;
        if (complaint && complaint.dateComplaintFiled) {
            if (complaint.timelineModel && complaint.timelineModel.timelineId) {
                if (!isArrayNullOrEmpty(timelineResponses))
                    result = false;
            } else
                result = false;
        }

        return result;
    };


    const handleSaveRouting = async (form, callback = undefined) => {
        form.complaintId = complaint.id;
        form.timelineId = complaint.timelineModel.timelineId;
        form.currentResponseIdForRouting = complaint.timelineModel.currentResponseIdForRouting;

        if (await ValidateCaseNote(form)) {
            actions.executeApi(api.saveTimeline, [form])
                .then((result) => {
                    if (callback)
                        callback();

                    handleUpdateComplaint(result)
                });

            handleDataChanged(false);
            if(!isNullOrUndefined(form.isApproved))
                scrollRoutingView();
        }
    };

    const ValidateCaseNote = async (form) => {
        if (isNullOrUndefined(form.isApproved)) return true;

        const feedbackType = form.isApproved ? "Approved" : "Not Approved";

        const currentTimelineResponse = form.timelineResponseModels.find(f => f.timelineResponseId === form.currentResponseIdForRouting);

        if(!currentTimelineResponse.startTime && currentTimelineResponse.endTime) {
            NotifyUser.Warning("Review start time is required when the end time is specified.");
            return false;
        }
        else if(currentTimelineResponse.startTime && !currentTimelineResponse.endTime) {
            NotifyUser.Warning("Review end time is required when the start time is specified.");
            return false;
        }
        else if(currentTimelineResponse.startTime && currentTimelineResponse.endTime && getDifferentBetweenTimesInMinutes(currentTimelineResponse.startTime, currentTimelineResponse.endTime) <= 0) {
            NotifyUser.Warning("Review start time needs to come before the end time.");
            return false;
        }

        if (!confirm(`Are you sure you want to mark this as \"${feedbackType}\"?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`)) return false;

        if(currentTimelineResponse.routingInformationFeedback || currentTimelineResponse.startTime || currentTimelineResponse.endTime)
            await saveCaseNote(currentTimelineResponse.routingInformationFeedback,
                currentTimelineResponse.timelineResponseId,
                currentTimelineResponse.startTime,
                currentTimelineResponse.endTime);

        return true;
    };

    const saveCaseNote = async (responseFeedback, timelineResponseId, startTime, endTime) => {
        let caseNote = createRoutingCaseNoteObject(responseFeedback, complaint.id, LocalStorage.getLoggedInUser().Id, timelineResponseId, startTime, endTime);
        await actions.executeApi(api.saveCaseNote, [caseNote]);
    };

    const handleChangeCompletionDate = (timelineRequirementId, dateCompleted) => {
        let updatedState = [...timelineResponses];
        let updatedRequirement = updatedState.find(d => d.timelineRequirementId === timelineRequirementId);
        if (updatedRequirement) {
            updatedRequirement.dateCompleted = dateCompleted;
            setTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const handleChangeRoutingRequirementResponse = (timelineResponseId, timelineRoutingRequirementResponseId, response) => {
        let updatedState = [...timelineResponses];
        let updatedResponse = updatedState.find(d => d.timelineResponseId === timelineResponseId);
        if (updatedResponse) {
            const fixedResponse = isTrimmedStringEmpty(response) ? 0 : parseInt(response);
            if (fixedResponse === DrComplaintTimelineRequirementResponseOptionsEnum.NotApproved)
                updatedResponse.dateCompleted = "";

            let updatedRequirement = updatedResponse.routingResponseModels.find(r => r.timelineRoutingRequirementResponseId === timelineRoutingRequirementResponseId);
            updatedRequirement.response = fixedResponse;
            setTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const handleChangeRoutingFeedback = (timelineResponseId, property, value, isFeedback) => {
        let updatedState = [...timelineResponses];
        let updatedResponse = updatedState.find(d => d.timelineResponseId === timelineResponseId);
        if (updatedResponse) {
            if (!isFeedback)
                updatedResponse[property] = value;
            setTimelineResponses(updatedState);
            handleDataChanged(true);
        }
    };

    const scrollRoutingView = () => {
        if (isLoadingData) return;
        handleScroll(`routing-view`);
    };

    useEffect(() => {
        if (!complaint || !complaint.timelineModel || !complaint.timelineModel.timelineResponseModels) return;
        const timelineResponseModels = complaint.timelineModel.timelineResponseModels;
        if (!isArrayNullOrEmpty(timelineResponseModels) && timelineResponses !== timelineResponseModels)
            setTimelineResponses(timelineResponseModels);
    }, [complaint]);

    useEffect(() => {
        setIsLoadingData(checkIsLoading());
    }, [complaint, timelineResponses]);

    if (isLoadingData) return null;

    return <DrStateComplaintRoutingView
        complaint={complaint}
        handleChangeCompletionDate={handleChangeCompletionDate}
        handleChangeRoutingFeedback={handleChangeRoutingFeedback}
        handleChangeRoutingRequirementResponse={handleChangeRoutingRequirementResponse}
        handleDataChanged={handleDataChanged}
        handleSaveRouting={handleSaveRouting}
        handleSelectSection={handleSelectSection}
        readOnly={readOnly}
        timelineModel={complaint.timelineModel}
        timelineResponses={timelineResponses}
        userDrTeamMemberRoles={userDrTeamMemberRoles}
    />;
};

DrStateComplaintRoutingContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    complaint: PropTypes.object.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintRoutingContainer;
