import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceReportsApi {
    static GetStudentReport(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/reports/students/dates/${dateWindowId}/districts/${districtId}`,
            RequestTypes.GET));
    }

    static GetDistrictSummaryReport(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/reports/districtSummary/dates/${dateWindowId}/districts/${districtId}`,
            RequestTypes.GET));
    }

    static GetCapReport(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/reports/cap/dates/${dateWindowId}/districts/${districtId}`,
            RequestTypes.GET));
    }

    static GetStudentCorrectionsReport(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/reports/studentCorrectionsPrint/dates/${dateWindowId}/districts/${districtId}`,
            RequestTypes.GET));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceReportsApi {
    static GetStudentReport(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetDistrictSummaryReport(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetCapReport(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static GetStudentCorrectionsReport(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceReportsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceReportsApi : MockMonitoringComplianceReportsApi;
export default MonitoringComplianceReportsApi;
