import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Scroll from "react-scroll";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as fileActions from "../../actions/fileActions";
import * as layoutActions from "../../actions/layoutActions";
import * as monitoringComplianceActions from "../../actions/monitoringComplianceActions";
import * as monitoringComplianceCaseNoteActions from "../../actions/monitoringComplianceCaseNoteActions";
import * as MonitoringComplianceStudentCorrectionAction from "../../actions/monitoringComplianceStudentCorrectionActions";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import * as SharedDataActions from "../../actions/sharedDataActions";
import Button from "../../components/common/buttons/Button";
import {isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import {
    McFilterVisibilityRules,
    MonitoringComplianceDistrictSearchCriteria
} from "../../components/common/searchCriterias/MonitoringComplianceDistrictSearchCriteria";
import {McFilter} from "../../components/MonitoringCompliance/McFilter";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import * as monitoringComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import {getParamsMultiple} from "../../components/layout/getParams";
import {emptyGuid} from "../../constants/config";
import McNotificationsContainer from "./Notifications/McNotificationsContainer";

const protocolPath = monitoringComplianceLocations.MANAGE_PROTOCOLS_DOE_DISTRICT.path;
const validatePath = monitoringComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path;
const correctivePath = monitoringComplianceLocations.MANAGE_CORRECTIVE_ACTIONS_DOE_DISTRICT.path;
const studentCorrectionsPath = monitoringComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path;
const matrixCorrectionsPath = monitoringComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path;
const caseNotesPath = monitoringComplianceLocations.DOE_CASE_NOTES.path;

export class MonitoringComplianceWrapperPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentPath: null,
            dataChanged: false
        };

        this.navButtonClick = this.navButtonClick.bind(this);
        this.getCurrentLocation = this.getCurrentLocation.bind(this);
        this.comparePaths = this.comparePaths.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollToTop = this.handleScrollToTop.bind(this);
        this.loadComplianceAssessment = this.loadComplianceAssessment.bind(this);
        this.handleDataChanged = this.handleDataChanged.bind(this);
        this.onChangeDateWindow = this.onChangeDateWindow.bind(this);
    }

    componentDidMount() {
        if (this.props.dateWindows.length === 0)
            this.props.actions.loadAllDateWindows()
                .then(() => {
                    this.loadComplianceAssessment();
                });
        else
            this.loadComplianceAssessment();

        if (this.props.institutions.length === 0)
            this.props.actions.loadInstitutions();

        this.setState({currentPath: this.getCurrentPath()});
    }

    componentDidUpdate(prevProps) {
        if(prevProps.location.pathname !== this.props.location.pathname)
            this.setState({currentPath: this.getCurrentPath()});
    }

    getScrollConfig() {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    }

    handleScroll(containerId) {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, this.getScrollConfig());
        }, 300);
    }

    handleScrollToTop() {
        const animateScroll = Scroll.animateScroll;
        window.setTimeout(() => {
            animateScroll.scrollToTop(this.getScrollConfig());
        }, 300);
    }

    loadComplianceAssessment(callback = null) {
        const {assessmentId, dateWindowId} = this.props;

        if(assessmentId) {
            this.props.actions.loadComplianceAssessment(assessmentId)
                .then((assessment) => {
                    if(!assessment) return;
                    this.initializeSearchCriteria(assessment.dateWindowId);
                    if(callback) callback();
                })
        }
        else {
            const selectedDateWindowId =
                dateWindowId ? dateWindowId
                    : this.props.searchCriteria && this.props.searchCriteria.dateWindowId !== ""
                        ? this.props.searchCriteria.dateWindowId
                        : this.props.dateWindows[0].value;

            if(selectedDateWindowId !== "" && this.props.districtId !== "") {
                this.props.actions.loadDistrictComplianceAssessments(this.props.districtId, selectedDateWindowId)
                    .then(() => {
                        if(callback) callback();
                    });
                this.initializeSearchCriteria(selectedDateWindowId);
            }
        }
    }

    initializeSearchCriteria(dateWindowId) {
        let criteria = {...this.props.searchCriteria};

        if(criteria.dateWindowId === dateWindowId  && criteria.areRulesCurrent(McFilterVisibilityRules.ComplianceAssessmentDetails))
            return;

        criteria.dateWindowId = dateWindowId;
        criteria.setVisibilityRules(McFilterVisibilityRules.ComplianceAssessmentDetails);
        this.props.actions.saveSearchCriteria(criteria);
    }

    handleDataChanged(dataUpdated = true) {
        this.setState({dataChanged: dataUpdated});
    }

    navButtonClick(event, location) {
        event.preventDefault();

        if (this.comparePaths(location)) return;

        if(this.confirmDataChanged()) {
            if(this.state.dataChanged)
                this.loadComplianceAssessment();
            this.redirectNav(location);
        }
    }

    confirmDataChanged() {
        return !this.state.dataChanged || confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page.");
    }

    redirectNav(location) {
        this.setState({dataChanged: false});
        this.props.history.push(this.buildPath(location));
        this.handleScrollToTop();
    }

    comparePaths(path) {
        path = this.buildPath(path);

        return (path === this.getCurrentLocation());
    }

    getCurrentLocation() {
        return this.props.location.pathname;
    }

    getCurrentPath() {
        if(this.comparePaths(protocolPath))
            return protocolPath;

        if(this.comparePaths(validatePath))
            return validatePath;

        if(this.comparePaths(correctivePath))
            return correctivePath;

        if(this.comparePaths(studentCorrectionsPath))
            return studentCorrectionsPath;

        if(this.comparePaths(matrixCorrectionsPath))
            return matrixCorrectionsPath;

        if(this.comparePaths(caseNotesPath))
            return caseNotesPath;

        return null;
    }

    buildPath(location) {
        let {dateWindowId, districtId} = this.props;
        if ((dateWindowId === null || districtId == null) &&
            this.props.selectedAssessment &&
            this.props.selectedAssessment.districtId &&
            this.props.selectedAssessment.districtId !== emptyGuid) {
            dateWindowId = dateWindowId || this.props.selectedAssessment.dateWindowId;
            districtId = districtId || this.props.selectedAssessment.districtId;
        }

        return location.replace(monitoringComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(monitoringComplianceLocations.DISTRICT_ID, districtId)
            .replace(monitoringComplianceLocations.IS_MATRIX, monitoringComplianceLocations.MATRIX);
    }

    onChangeDateWindow(criteria) {
        if(this.props.districtId === "") return;

        if(this.confirmDataChanged()) {
            this.props.actions.loadDistrictComplianceAssessments(this.props.districtId, criteria.dateWindowId)
                .then(() => {
                    this.redirectNav(this.state.currentPath.replace(monitoringComplianceLocations.DATE_WINDOW_ID, criteria.dateWindowId))
                });
            this.props.actions.saveSearchCriteria(criteria);
        }
    }

    render() {
        const {component: Component} = this.props;

        return (
            <GridRow isPageNav>
                <GridColumn isPageNavLinks
                            isStickyLinks
                            large="3">
                    <Button btnClass={this.comparePaths(protocolPath) ? "is-active" : ""}
                            onClick={(event) => this.navButtonClick(event, protocolPath)}
                            label={"Required Protocols"}
                            name={monitoringComplianceLocations.MANAGE_PROTOCOLS_DOE_DISTRICT.path}/>
                    {
                        this.props.selectedAssessment.doesMonitoringAssessmentExist &&
                        <>
                            <Button btnClass={this.comparePaths(validatePath) ? "is-active" : ""}
                                    onClick={(event) => this.navButtonClick(event, validatePath)}
                                    label={"Validate Records"}
                                    name={monitoringComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path}/>
                            <Button btnClass={this.comparePaths(studentCorrectionsPath) ? "is-active" : ""}
                                    onClick={(event) => this.navButtonClick(event, studentCorrectionsPath)}
                                    label={"Student Specific Corrections"}
                                    name={monitoringComplianceLocations.DOE_STUDENT_CORRECTIVE_ACTIONS_SUMMARY.path}/>
                            <Button btnClass={this.comparePaths(correctivePath) ? "is-active" : ""}
                                    onClick={(event) => this.navButtonClick(event, correctivePath)}
                                    label={"Corrective Actions"}
                                    name={monitoringComplianceLocations.MANAGE_CORRECTIVE_ACTIONS_DOE_DISTRICT.path}/>
                            <Button btnClass={this.comparePaths(matrixCorrectionsPath) ? "is-active" : ""}
                                    onClick={(event) => this.navButtonClick(event, matrixCorrectionsPath)}
                                    label={"Matrix Specific Corrections"}
                                    name={monitoringComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS_SUMMARY.path}/>
                            <Button
                                btnClass={this.comparePaths(caseNotesPath) ? "is-active" : ""}
                                onClick={(event) => this.navButtonClick(event, caseNotesPath)}
                                label={"Case Notes"}
                                name={monitoringComplianceLocations.DOE_CASE_NOTES.path}/>
                        </>
                    }
                    <Button label={"Return to District List"}
                            onClick={(event) => this.navButtonClick(event, monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW.path)}
                            name={monitoringComplianceLocations.LANDING_DOE_DATE_WINDOW.path}/>
                    <Button label={"Return to My Summary"}
                            onClick={(event) => this.navButtonClick(event, monitoringComplianceLocations.MC_SUMMARY.path)}
                            name={monitoringComplianceLocations.MC_SUMMARY.path}/>
                </GridColumn>

                <GridColumn isPageNavContent large="9">
                    {
                        this.props.selectedAssessment &&
                        this.props.selectedAssessment.monitoringAssessmentId &&
                        <McNotificationsContainer
                            actions={this.props.actions}
                            assessmentId={this.props.selectedAssessment.monitoringAssessmentId}
                        />
                    }
                    {
                        this.state.currentPath &&
                        this.props.searchCriteria &&
                        this.props.searchCriteria.areRulesCurrent(McFilterVisibilityRules.ComplianceAssessmentDetails) &&
                        <McFilter
                            dateWindows={this.props.dateWindows}
                            handleSearchFiltersChange={this.onChangeDateWindow}
                            searchCriteria={this.props.searchCriteria}
                        />
                    }
                    {
                        this.props.selectedAssessment &&
                        <Component
                            {...this.props}
                            assessmentId={this.props.selectedAssessment.monitoringAssessmentId}
                            handleDataChanged={this.handleDataChanged}
                            handleScroll={this.handleScroll}
                            handleScrollToTop={this.handleScrollToTop}
                            loadComplianceAssessment={this.loadComplianceAssessment}
                        />
                    }
                    {
                        MonitoringComplianceUtilities.isValidId(this.props.dateWindowId) &&
                        MonitoringComplianceUtilities.isValidId(this.props.districtId) &&
                        !this.props.selectedAssessment &&
                        <div>
                            There is no assessment for this district for this selected date window.
                        </div>
                    }
                </GridColumn>
            </GridRow>
        );
    }
}

MonitoringComplianceWrapperPage.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string,
    component: PropTypes.func.isRequired,
    dateWindowId: PropTypes.string,
    dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
    districtId: PropTypes.string,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isMatrix: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    searchCriteria: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    let dateWindows = [...state.sharedData.dateWindows];
    if (dateWindows.length > 0) {
        const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
        dateWindows = sharedDataUtilities.generateDateWindowSelectListItems(annualWindows);
    }

    const params = getParamsMultiple(ownProps.location.pathname, monitoringComplianceLocations.AllDoeRoutes);
    const selectedDateWindowId = (params && params.dateWindowId) ? params.dateWindowId : state.monitoringCompliance.selectedDateWindow;
    const searchCriteria = state.searchCriteria.MonitoringComplianceDistrictSearchCriteria || new MonitoringComplianceDistrictSearchCriteria(selectedDateWindowId);
    return {
        ...params,
        assessmentId: params && params.assessmentId ? params.assessmentId : null,
        dateWindows,
        dateWindowId: params && params.dateWindowId ? params.dateWindowId : null,
        districtId: params && params.districtId ? params.districtId : null,
        institutions: state.sharedData.institutions,
        isLoading: state.ajaxCallsInProgress > 0,
        isMatrix: params && !isTrimmedStringEmpty(params.isMatrix),
        searchCriteria,
        selectedAssessment: state.monitoringCompliance.selectedAssessment,
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({},
        layoutActions,
        monitoringComplianceActions,
        apiForLocalStateActions,
        SharedDataActions,
        searchCriteriaActions,
        MonitoringComplianceStudentCorrectionAction,
        monitoringComplianceCaseNoteActions,
        fileActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MonitoringComplianceWrapperPage));