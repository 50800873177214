import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../../api/requests/RequestTypes';
import {API_MESSAGES} from "../../ptsConstants";
import {API_ROOT} from "../../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsAdminGmsUsersApi {
    static saveGmsUser(gmsUserId, model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/gmsUsers/${gmsUserId}`,
                RequestTypes.PUT,
                JSON.stringify(model),
                API_MESSAGES.ADMIN.GMS_USERS.saved));
    }

    static deleteGmsUser(gmsUserId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/gmsUsers/${gmsUserId}`,
                RequestTypes.DELETE,
                null,
                API_MESSAGES.ADMIN.GMS_USERS.deleted));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsAdminGmsUsersApi {
    static saveGmsUser(){
        return ApiUtility.mockResolveApiCall({}, API_MESSAGES.ADMIN.GMS_USERS.saved);
    }

    static deleteGmsUser(){
        return ApiUtility.mockResolveApiCall({}, API_MESSAGES.ADMIN.GMS_USERS.deleted);
    }
}

const PtsAdminGmsUsersApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsAdminGmsUsersApi : MockPtsAdminGmsUsersApi;
export default PtsAdminGmsUsersApi;