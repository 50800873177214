import React from 'react';
import PropTypes from 'prop-types';
import $ from "jquery";
import * as systemConfig from "../../constants/config";

class AutoSave extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            event: {
                type: systemConfig.autoSaveEventType,
                preventDefault: () => {}
            }
        };
    }

    componentDidMount(){
        const {save} = this.props;
        const context = this;

        $(window).on("unload",function() {
            return save(context.state.event);
        });
    }

    componentDidUpdate() {
        const { save, millisecondInterval } = this.props;

        if (this.saveTimeout) clearTimeout(this.saveTimeout);
        this.saveTimeout = setTimeout(() => {
            save(this.state.event);
        }, millisecondInterval);
    }

    componentWillUnmount(){
        this.props.save(this.state.event);
    }

    render() {
        return <div>{this.props.children}</div>;
    }
}

AutoSave.propTypes = {
    save: PropTypes.func.isRequired,
    millisecondInterval: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element,
        PropTypes.object]).isRequired
};

AutoSave.defaultProps = {
    millisecondInterval: systemConfig.defaultAutoSaveMillisecondInterval
};

export default AutoSave;