import React, {useEffect} from "react";
import PropTypes from "prop-types";
import LocalStorage from "../../../components/shared/LocalStorage";
import {DASHBOARD} from "../../../constants/systemLocations";
import api from "../drDueProcessApi";
import SectionHeader from "../../../components/common/SectionHeader";
import * as drDueProcessConstants from "../drDueProcessConstants";
import CaseNotesView from "../../../components/CaseNotes/CaseNotesView";

export const DrDueProcessCaseNotesContainer = ({
                                                   actions,
                                                   dueProcess,
                                                   handleDataChanged,
                                                   handleScroll,
                                                   handleScrollToTop,
                                                   handleUpdateDueProcess,
                                                   history,
                                                   isDistrictUser,
                                                   userDrTeamMemberRoles
                                               }) => {
    useEffect(() => {
        if (isDistrictUser)
            history.push(DASHBOARD.path);
    }, []);

    const handleSaveCaseNote = async (caseNote) => {
        handleDataChanged(false);
        caseNote.dueProcessId = dueProcess.id;
        caseNote.authorId = LocalStorage.getLoggedInUser().Id;
        return actions.executeApi(api.saveCaseNote, [caseNote])
            .then((updatedDueProcess) => {
                handleUpdateDueProcess(updatedDueProcess);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleDeleteCaseNote = (caseNoteId) => {
        actions.executeApi(api.deleteCaseNote, [dueProcess.id, caseNoteId])
            .then((updatedDueProcess) => {
                handleUpdateDueProcess(updatedDueProcess);
            });
    };

    const handleDownloadCaseInvestigationReport = (caseInvestigationFilter) => {
        actions.executeApi(api.downloadCaseInvestigationReport, [dueProcess.id, caseInvestigationFilter]);
    };

    if (!dueProcess) return null;

    const isDueProcessTeamMember = !!userDrTeamMemberRoles && userDrTeamMemberRoles.length > 0;

    return (
        <>
            <SectionHeader>
                <h2>Case Notes</h2>
            </SectionHeader>

            <CaseNotesView
                caseNotes={dueProcess.caseNoteModels}
                caseNoteType={drDueProcessConstants.CaseNoteType}
                caseNoteTypeList={drDueProcessConstants.CaseNoteTypeList}
                handleDataChanged={handleDataChanged}
                handleDeleteCaseNote={handleDeleteCaseNote}
                handleDownloadCaseInvestigationReport={handleDownloadCaseInvestigationReport}
                handleSaveCaseNote={handleSaveCaseNote}
                handleScroll={handleScroll}
                handleScrollToTop={handleScrollToTop}
                readOnly={!isDueProcessTeamMember}
                readOnlyCaseNoteTypes={drDueProcessConstants.ReadOnlyCaseNoteTypes}
            />
        </>
    );
};

DrDueProcessCaseNotesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dueProcess: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    handleUpdateDueProcess: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrDueProcessCaseNotesContainer;