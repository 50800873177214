import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import * as commonUtilities from "../../components/common/commonUtilities";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import DrApi from "../drApi";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import {DrFilter} from "../DrComponents/DrFilter";
import {DrNavigation} from "../DrComponents/DrNavigation";
import {DrRoles} from "../drConstants";
import * as drLocations from "../drLocations";
import DrCaseloadView from "./DrCaseloadView";

const DrCaseloadContainer = ({
                                        actions,
                                        currentLocation,
                                        dateWindowsAnnualList: dateWindows,
                                        defaultDateWindowId,
                                        history,
                                        searchCriteria
                                    }) => {
    const [caseload, setCaseload] = useState(null);
    const [mediationTeamMembers, setMediationTeamMembers] = useState(null);

    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if (reloadsData) {
            criteria.clearOptionalSearchItems();
            await initializeData(criteria);
        } else
            actions.saveSearchCriteria(criteria);
    };

    const handleClickOrder = async (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);

        await handleSearchFiltersChange(criteria, false);
    };

    const handleUpdateDrSearchCriteria = async (disputeResolutionType, userId, status, role) => {
        let criteria = {...searchCriteria};
        criteria.clearOptionalSearchItems(true, true);
        criteria.disputeResolutionType = disputeResolutionType;
        criteria.teamMember = userId;
        criteria.teamMemberRole = role;
        criteria.status = status;

        await handleSearchFiltersChange(criteria, false);

        history.push(drLocations.LANDING_CASE_LIST.getUrl());
    };

    const initializeData = async (criteria) => {
        const {dateWindowId} = criteria;
        if (!dateWindowId)
            return;

        const result = await actions.executeApi(DrApi.getCaseloadData, [dateWindowId]);
        setCaseload(result);

        const teamMembers = await actions.executeApi(DrApi.getTeamMembers, [true]);
        let mediationMembers = teamMembers.filter(f => f.role === DrRoles.MediationLiaison.id);
        mediationMembers = mediationMembers.sort(commonUtilities.dynamicSort("fullName"));
        setMediationTeamMembers(mediationMembers);

        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if (!defaultDateWindowId) return;

        let criteria = {...searchCriteria};

        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrCaseload);

        initializeData(criteria);

    }, [defaultDateWindowId]);

    useEffect(() => {
        actions.updatePageTitle(`Caseload Summary`);
    }, []);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(DrFilterVisibilityRules.DrCaseload) || !caseload)
        return null;

    return (
        <>
            <DrFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <DrCaseloadView
                caseload={caseload}
                drCaseloadSearchCriteria={searchCriteria}
                handleClickOrder={handleClickOrder}
                handleUpdateDrSearchCriteria={handleUpdateDrSearchCriteria}
                mediationTeamMembers={mediationTeamMembers}
            />

            <DrNavigation currentLocation={currentLocation} history={history}/>
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
        ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    const currentLocation = props.location.pathname;

    return {
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrCaseloadContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrCaseloadContainer);

