import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {StudentCorrectionsSummaryGrid} from "./StudentCorrectionsSummaryGrid";
import * as ComplianceLocations from "../../../constants/monitoringCompliance/monitoringComplianceLocations";

export const StudentCorrectionsProtocolSummaryView = ({
                                                   actions,
                                                   assessmentId,
                                                   clearEditStudentCorrections,
                                                   dateWindowId,
                                                   history,
                                                   isLoading,
                                                   studentRecord
                                               }) => {

    const submitStudentCorrections = () => {
        if (confirm("Are you sure you want to submit this student's corrections?")) {
            actions.submitStudentCorrectiveResults(assessmentId, studentRecord.studentRecordId)
                .then(() => {
                    actions.loadComplianceWithStudentCorrectiveResults(assessmentId);
                });
        }
    }

    const editProtocolCorrection = (studentRecordId, protocolId) => {
        history.push(ComplianceLocations.DISTRICT_STUDENT_CORRECTIVE_ACTIONS.path
            .replace(ComplianceLocations.ASSESSMENT_ID, assessmentId)
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.STUDENT_RECORD_ID, studentRecordId)
            .replace(ComplianceLocations.PROTOCOL_ID, protocolId));
    };

    useEffect(() => {
        actions.updatePageTitle(`Student Protocol Corrections Summary - ${studentRecord.name}`);
    }, [studentRecord.name]);

    return (
        <StudentCorrectionsSummaryGrid
            clearEditStudentCorrections={clearEditStudentCorrections}
            editProtocolCorrection={editProtocolCorrection}
            isLoading={isLoading}
            studentRecord={studentRecord}
            submitStudentCorrections={submitStudentCorrections}
        />
    );
};


StudentCorrectionsProtocolSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    clearEditStudentCorrections: PropTypes.func.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    studentRecord: PropTypes.object.isRequired
};
