import {getAnnualDateWindows, getInstitutions} from "../../mockData/sharedDataMockData";
import {createListFromObject, formatDateWindow} from "../../components/common/commonUtilities";
import {emptyGuid} from "../../constants/config";
import {
    CATEGORIES,
    FUNDING_SOURCE,
    TRAINING_TYPES, SERVICE_TYPES, PRODUCT_TYPES,
} from "./Application/ApplicationDeliverables/gmsApplicationDeliverablesConstants";
import {
    APPLICATION_ACCESS_STATUS,
    APPLICATION_TYPE,
    BUDGET_FTE_VALUE,
    ELEMENT_TYPE,
} from "./gmsConstants";
import {createFunctionCodeList, createObjectCodeList, createFundingCategoryCodeList} from "./gmsObjectFactory";

const dates = getAnnualDateWindows();
const fiscalAgents = getInstitutions();
const summaryData = getAllSummaryData();

export function getSummary(dateWindowId) {
    const result = dateWindowId && dateWindowId !== emptyGuid
        ? filterSummaryByDate(summaryData, dateWindowId)
        : summaryData;

    return result;
}

const MultiselectOptions = {
    Yes: 1,
    No: 2,
    Maybe: 3,
};

const MultiselectOptionsList = createListFromObject(MultiselectOptions);

const MultiselectOptionsJson = JSON.stringify(MultiselectOptionsList);

const budgetMockData = {
    amountTotal: 100,
    applicationId: "c3c29c24-6d23-44ef-0d4f-08dae2a2cf65",
    applicationTitle: "Mock Fiscal Agent - 2022 IDEA entitlement Application",
    budgetElementId: "",
    budgetId: "0a89c1e1-4087-4eeb-a822-9cc390090c92",
    budgetLineItems: [
        {
            budgetLineItemId: "02ee15ca-b0e2-4d7b-b945-ad62273f9d85",
            budgetId: "0a89c1e1-4087-4eeb-a822-9cc390090c92",
            fundingCategoryCode: "1001",
            functionCode: "1002",
            objectCode: "1003",
            exceptionToFteRule: false,
            alternateDescription: "item 1 alt desc",
            accountDescription: "item 1 acc desc",
            fullTimeEquivalent: 4.66,
            amount: 30.55,
            percentage: 30.44,
            dateCreated: "2023-02-26T19:00:00-05:00",
            dateModified: "2023-02-26T19:00:00-05:00",
        },
        {
            budgetLineItemId: "02ee25ca-b0e2-4d7b-b945-ad62273f9d85",
            budgetId: "0a89c1e1-4087-4eeb-a822-9cc390090c92",
            fundingCategoryCode: "2001",
            functionCode: "2002",
            objectCode: "2003",
            exceptionToFteRule: false,
            alternateDescription: "item 2 alt desc",
            accountDescription: "item 2 acc desc",
            fullTimeEquivalent: 4.77,
            amount: 50.11,
            percentage: 50.99,
            dateCreated: "2023-02-26T19:00:00-05:00",
            dateModified: "2023-02-26T19:00:00-05:00",
        },
        {
            budgetLineItemId: "02ee35ca-b0e2-4d7b-b945-ad62273f9d85",
            budgetId: "0a89c1e1-4087-4eeb-a822-9cc390090c92",
            fundingCategoryCode: "3001",
            functionCode: "3002",
            objectCode: "3003",
            exceptionToFteRule: true,
            alternateDescription: "item 3 alt desc",
            accountDescription: "item 3 acc desc Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            fullTimeEquivalent: 4,
            amount: 20,
            percentage: 20,
            dateCreated: "2023-02-26T19:00:00-05:00",
            dateModified: "2023-02-26T19:00:00-05:00",
        },

    ],
    budgetTitle: "Budget Example",
    canEditBudget: true,
    canEditBudgetLineItemFeedback: false,
    canImportBudget: false,
    canViewBudgetLineItemFeedback: false,
    codes: [
        {
            code: "0001",
            description: null,
            total: 100,
        },
    ],
    codesAmountTotal: 100,
    dateCreated: "2023-02-22T05:20:58.673-05:00",
    dateModified: "0001-01-01T00:00:00",
    fullTimeEquivalentTotal: 4,
};

let budgetState;

export function getApplicationBudget() {
    if (!budgetState)
        budgetState = budgetMockData;
    return budgetState;
}

export function deleteBudgetItem(budgetLineItemId) {
    if (!budgetState) return false;

    budgetState.budgetLineItems = budgetState.budgetLineItems.filter(b => b.budgetLineItemId !== budgetLineItemId);

    return true;
}

export function saveBudgetItem(budgetLineItem) {
    if (!budgetState) return false;

    deleteBudgetItem(budgetLineItem.budgetLineItemId);

    budgetState.budgetLineItems.push(budgetLineItem);

    return true;
}

const budgetCodes = [
    {
        code: "A",
        codeType: 1,
        description: "Not Applicable",
        objectCodeAssociatedFteValue: "",
        programCodeType: "IDEA",
    },
    {
        code: "I-1",
        codeType: 1,
        description: "Coordinated Early Intervening Services (CEIS)",
        objectCodeAssociatedFteValue: "",
        programCodeType: "IDEA",
    },
    {
        code: "C",
        codeType: 1,
        description: "Proportionate Share for Services to Parentally Placed Private School Children",
        objectCodeAssociatedFteValue: "",
        programCodeType: "IDEA",
    },
    {
        code: "0000",
        codeType: 2,
        description: "Account codes",
        objectCodeAssociatedFteValue: "",
        programCodeType: "",
    },
    {
        code: "1000",
        codeType: 2,
        description: "Assets and Other Debits",
        objectCodeAssociatedFteValue: "",
        programCodeType: "",
    },
    {
        code: "2000",
        codeType: 2,
        description: "Liabilities, Other Credits & Fund Equity",
        objectCodeAssociatedFteValue: "",
        programCodeType: "",
    },
    {
        code: "130",
        codeType: 3,
        description: "REQUIRED",//""Other Certified Instructional Personnel",
        objectCodeAssociatedFteValue: BUDGET_FTE_VALUE.required,
        programCodeType: "",
    },
    {
        code: "233",
        codeType: 3,
        description: "NOT PERMISSIBLE",//""Commercial or Merit and Insurance Annuity Plan",
        objectCodeAssociatedFteValue: BUDGET_FTE_VALUE.notPermissible,
        programCodeType: "",
    },
    {
        code: "312",
        codeType: 3,
        description: "PERMISSIBLE",//"Subagreements greater than $25,000",
        objectCodeAssociatedFteValue: BUDGET_FTE_VALUE.permissible,
        programCodeType: "",
    },
];

export function getBudgetCodes() {
    const functionCodes = createFunctionCodeList(budgetCodes);
    const objectCodes = createObjectCodeList(budgetCodes);
    const fundingCategoryCodes = createFundingCategoryCodeList(budgetCodes);

    return {
        functionCodes,
        objectCodes,
        fundingCategoryCodes,
    };
}

export function getApplicationSection() {
    return {
        elementId: "11",
        applicationTitle: "Mock Fiscal Agent - 2022 IDEA entitlement Application",
        subtitle: "Section Title",
        elements: [
            {
                elementId: "1101",
                elementText: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id volutpat lacus laoreet non curabitur gravida. Faucibus nisl tincidunt eget nullam non nisi est. In tellus integer feugiat scelerisque. Non consectetur a erat nam at lectus. Lorem sed risus ultricies tristique nulla aliquet enim. Arcu cursus euismod quis viverra nibh cras pulvinar mattis nunc. Non tellus orci ac auctor augue mauris augue neque gravida. Tincidunt vitae semper quis lectus nulla. Quam nulla porttitor massa id neque aliquam vestibulum. Nibh cras pulvinar mattis nunc sed blandit libero volutpat sed. Nulla facilisi morbi tempus iaculis urna id volutpat lacus. Habitant morbi tristique senectus et netus et malesuada fames.</p>",
                elementType: ELEMENT_TYPE.display,
                applicationSectionAudits: [],
                currentApplicationSectionAuditStatus: "",
                historyResponse: "",
            },
            {
                elementId: "1102",
                elementText: "This is a element of type TextBox.",
                elementType: ELEMENT_TYPE.textbox,
                response: "Sure is!",
            },
            {
                elementId: "1103",
                elementText: "This is a element of type TextArea.",
                elementType: ELEMENT_TYPE.textarea,
                response: "That computes.",
            },
            {
                elementId: "1104",
                elementText: "This is a element of type DropDown.",
                elementType: ELEMENT_TYPE.dropdown,
                elementValue: MultiselectOptionsList,
                response: MultiselectOptions.Yes,
            },
            {
                elementId: "1105",
                elementText: "This is a element of type Radio.",
                elementType: ELEMENT_TYPE.radio,
                elementValue: MultiselectOptionsJson,
                response: MultiselectOptions.Yes,
            },
            {
                elementId: "1106",
                elementText: "A. Parents of children with disabilities are involved in meeting the full educational opportunity goal through the following activities:",
                elementType: ELEMENT_TYPE.checkboxList,
                elementValue: `["Participation on district advisory committee.","Participation on school advisory committees.","Attendance at meetings where information regarding rules and regulations are discussed.","Attendance at meetings where instructional strategies are shared.","Participation in the evaluation, reevaluation, and eligibility processes.","Participation in the IEP/family support plan development and review process.","Participation in classroom activities.","Participation in family support meetings or activities.","Participation in group or individual parent conferences or meetings.","Participation in any group that makes decisions on the educational placement of their child.","Appraisal of school board agenda items through the public notice process.","Assistance in evaluating program effectiveness.","Other"]`,
                response: `Yes`,
            },
            {
                elementId: "1107",
                elementText: "B. Parents of children with disabilities were provided the opportunity to participate in the development of the project application through the following methods:",
                elementType: ELEMENT_TYPE.checkboxList,
                elementValue: `["Parents were specifically requested to offer suggestions for project planning during classroom activities or during the IEP development process.","A questionnaire, requesting suggestions for project planning, was mailed to parents.","Participants on the district advisory council (including parents) provided suggestions for project development.","Suggestions for project development were solicited from school improvement team members representing parents.","Public notice was provided to solicit parental input in project development through newspaper notices, memoranda, telephone calls, or individual letters.","Other"]`,
                response: `Yes`,
            },
            {
                elementId: "1108",
                elementText: "These figures are calculated:",
                elementType: ELEMENT_TYPE.checkboxList,
                elementValue: `["Using local funds only","the combination of state and local funds"]`,
                response: `Yes`,
            },
            {
                elementId: "1109",
                elementText: "These figures represent:",
                elementType: ELEMENT_TYPE.checkboxList,
                elementValue: `["Total amounts","Per capita amounts"]`,
                response: `Yes`,
            },
            {
                elementId: "1110",
                elementText: "This is a element of type Checkbox.",
                elementType: ELEMENT_TYPE.checkbox,
                response: true,
            },
        ],
    };
}

export function getGmsSettings() {
    return {
        applicationAccessStatus: APPLICATION_ACCESS_STATUS.notVisible.id,
    };
}

export function getMainSections() {
    const sections = getApplicationSections();
    let result = [];

    sections.forEach((section) => {
            section.elements.forEach(element => result.push(element));
        },
    );

    return result;
}

export function getApplicationSections() {
    return [
        {
            elementId: "1",
            elementText: "IDEA General and Special Assurances",
            elements: [
                {
                    elementId: "11",
                    elementText: "IDEA General and Special Assurances",
                    elementCopyBehavior: 0,
                },
                {
                    elementId: "12",
                    elementText: "IDEA Assurances Private Schools",
                    elementCopyBehavior: 2,
                },
                {
                    elementId: "13",
                    elementText: "Schoolwide Programs Under Title I of the ESEA",
                    elementCopyBehavior: 0,
                },
                {
                    elementId: "14",
                    elementText: "Parent Involvement and Participation",
                    elementCopyBehavior: 1,
                },
            ],
        },
        {
            elementId: "2",
            elementText: "Permissive Use of Funds",
            elements: [
                {
                    elementId: "21",
                    elementText: "Administrative Case Management",
                    elementCopyBehavior: 1,
                },
                {
                    elementId: "22",
                    elementText: "Benefits to Nondisabled Students",
                    elementCopyBehavior: 2,
                },
                {
                    elementId: "23",
                    elementText: "Coordinated Early Intervening Services (CEIS)",
                    elementCopyBehavior: 0,
                },
            ],
        },
        {
            elementId: "3",
            elementText: "Entitlement Narratives",
            elements: [
                {
                    elementId: "31",
                    elementText: "Support for Strategic Plan",
                    elementCopyBehavior: 0,
                },
                {
                    elementId: "32",
                    elementText: "General Education Provisions Act",
                    elementCopyBehavior: 2,
                },
                {
                    elementId: "33",
                    elementText: "Private School Consultation",
                    elementCopyBehavior: 2,
                },
                {
                    elementId: "34",
                    elementText: "Proportionate Share",
                    elementCopyBehavior: 1,
                },
                {
                    elementId: "35",
                    elementText: "Support to Charter Schools",
                    elementCopyBehavior: 0,
                },
            ],
        },
    ];
}

export function getApplication() {
    return {
        applicationId: "1",
        applicationTitle: "Mock Fiscal Agent - 2022 IDEA entitlement Application",
        applicationType: APPLICATION_TYPE.k12.id,
        instructions: "<b>Instructions:</b> Applicants are required to complete the following sections.",
        elements: getApplicationSections(),
    };
}

const filterSummaryByDate = (data, dateWindowId) => {
    const date = dates.find(d => d.id === dateWindowId);
    const dateRange = formatDateWindow(date);

    return data.filter(r => r.datesCovered === dateRange);
};

function getAllSummaryData() {
    let result = [];

    for (let fiscalAgent of fiscalAgents) {
        for (let date of dates) {
            const application = createSummary({fiscalAgent, date});
            result.push(application);
        }
    }

    return result;
}

function createSummary({fiscalAgent, date}) {
    return {
        applicationId: 1,
        dateWindowId: date.id,
        fiscalAgentId: fiscalAgent.id,
        fiscalAgentName: fiscalAgent.name,
        datesCovered: formatDateWindow(date),
    };
}

const deliverablesMockData = {
    requested: 30000, //this from budget
    total: 0, //generated
    remaining: 0, //generated = requested-total
    isOverBudget: false, //generated = remaining < 0
    applicationId: "c3c29c24-6d23-44ef-0d4f-08dae2a2cf65",
    applicationTitle: "Mock Fiscal Agent - 2022 IDEA Discretionary Application", //from application
    deliverables:
        [{
            id: "10001",
            deliverableIdentifier: "T1",
            deliverableCategory: CATEGORIES.training,
            deliverableType: TRAINING_TYPES.facilitation,
            deliverableTitle: "Access Trainings",
            deliverableDescription: "Provide training targeting available instructional resources/strategies to support students with the most significant cognitive disabilities. This item totals four (4) deliverables.",
            gmsIndicators: ["SPP 3", "SPP 5", "SPP 13"],
            sourceDocumentation: "PTS, Sign-in Sheets posted in ShareFile",
            fundingSourceType: FUNDING_SOURCE.federal,
            deliverableBudget: 1000.00,
            totalUnits: 10.00, //calculated - q1Units + q2Units + q3Units + q4Units
            unitCost: 10,
            q1Units: 2.00,
            q2Units: 3.00,
            q3Units: 2.00,
            q4Units: 3.00,
        }, {
            id: "10002",
            deliverableIdentifier: "T2",
            deliverableCategory: CATEGORIES.training,
            deliverableType: TRAINING_TYPES.provision,
            deliverableTitle: "Access Trainings",
            deliverableDescription: "Provide training targeting available instructional resources/strategies to support students with the most significant cognitive disabilities. This item totals four (4) deliverables.",
            gmsIndicators: ["SPP 3", "SPP 13"],
            sourceDocumentation: "PTS, Sign-in Sheets posted in ShareFile",
            fundingSourceType: FUNDING_SOURCE.federal,
            deliverableBudget: 1000.00,
            totalUnits: 10.00,
            unitCost: 10,
            q1Units: 2.00,
            q2Units: 3.00,
            q3Units: 2.00,
            q4Units: 3.00,
        }, {
            id: "20001",
            deliverableIdentifier: "S1",
            deliverableCategory: CATEGORIES.serviceDelivery,
            deliverableType: SERVICE_TYPES.direct,
            deliverableTitle: "Technical Assistance",
            deliverableDescription: "Support students with the most significant cognitive disabilities.",
            gmsIndicators: ["SPP 3"],
            sourceDocumentation: "PTS",
            fundingSourceType: FUNDING_SOURCE.state,
            deliverableBudget: 10000.00,
            totalUnits: 100.00,
            unitCost: 10,
            q1Units: 20.00,
            q2Units: 30.00,
            q3Units: 20.00,
            q4Units: 30.00,
        }, {
            id: "20002",
            deliverableIdentifier: "S2",
            deliverableCategory: CATEGORIES.serviceDelivery,
            deliverableType: SERVICE_TYPES.leadership,
            deliverableTitle: "Technical Assistance",
            deliverableDescription: "Support students with the most significant cognitive disabilities.",
            gmsIndicators: ["SPP 3"],
            sourceDocumentation: "PTS",
            fundingSourceType: FUNDING_SOURCE.state,
            deliverableBudget: 1000.00,
            totalUnits: 10.00,
            unitCost: 10,
            q1Units: 2.00,
            q2Units: 3.00,
            q3Units: 2.00,
            q4Units: 3.00,
        }, {
            id: "30001",
            deliverableIdentifier: "P1",
            deliverableCategory: CATEGORIES.products,
            deliverableType: PRODUCT_TYPES.analytical,
            deliverableTitle: "Technical Assistance",
            deliverableDescription: "Support students with the most significant cognitive disabilities.",
            gmsIndicators: ["SPP 3"],
            sourceDocumentation: "PTS",
            fundingSourceType: FUNDING_SOURCE.state,
            deliverableBudget: 10000.00,
            totalUnits: 100.00,
            unitCost: 10,
            q1Units: 20.00,
            q2Units: 30.00,
            q3Units: 20.00,
            q4Units: 30.00,
        }, {
            id: "30002",
            deliverableIdentifier: "P2",
            deliverableCategory: CATEGORIES.products,
            deliverableType: PRODUCT_TYPES.informational,
            deliverableTitle: "Technical Assistance",
            deliverableDescription: "Support students with the most significant cognitive disabilities.",
            gmsIndicators: ["SPP 3"],
            sourceDocumentation: "PTS",
            fundingSourceType: FUNDING_SOURCE.state,
            deliverableBudget: 1000.00,
            totalUnits: 10.00,
            unitCost: 10,
            q1Units: 2.00,
            q2Units: 3.00,
            q3Units: 2.00,
            q4Units: 3.00,
        }],
};


let deliverablesState;

export function getApplicationDeliverables() {
    if (!deliverablesState)
        deliverablesState = {...deliverablesMockData};

    const updatedSummary = createDeliverableSummary(deliverablesState);

    deliverablesState = {
        ...deliverablesState,
        ...updatedSummary,
    };

    return deliverablesState;
}

//NOTE: this started as a utility for the front end to create the summary data.
// However, since I believe the backend will need this for print, reports, etc.
// I moved it here, which cause the implementation to become a little awkward.
// I chose this over refactoring so that it can be reverted to utility if needed.
function createDeliverableSummary(summary) {
    let requested = 0;
    let total = 0;
    const remaining = () => requested - total;
    const createSummary = () => {
        return {
            requestedTargetedAllocation: `$${requested}`,
            total: `$${total}`,
            remainingTargetedAllocation: `$${remaining()}`,
            isOverBudget: remaining() < 0,
        };
    };

    if (!summary) return createSummary();
    requested = summary?.requested;

    summary?.deliverables.map((dli) => {
        total += dli.deliverableBudget;
    });

    return createSummary();
}


const salaryMockData = {
    applicationId: "c3c29c24-6d23-44ef-0d4f-08dae2a2cf65",
    applicationTitle: "Mock Fiscal Agent - 2022 IDEA Discretionary Application", //from application
    salaries:
        [
            {
                id: "10001",
                positionTitle: "Code Monkey 1000-122",
                currentSalary: "100000",
                amountIncrease: "10000",
                newSalary: "110000",
                percentChange: "10",
                fteChange: "Nope.",
                benefitsIncreased: "Nope.",
                changeReason: "Tendies",
                changeQualification: "Need Tendies",
                changeFunding: "$GME stonks",
                serviceImpact: "diamond hands",
                recoupFunds: "rockets to the moon... Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
            },
            {
                id: "10002",
                positionTitle: "Aspiring Novelist 0000-000",
                currentSalary: "40000",
                amountIncrease: "1000",
                newSalary: "41000",
                percentChange: "2.5",
                fteChange: "trivial",
                benefitsIncreased: "doubled from zero",
                changeReason: "pity",
                changeQualification: "dramatically underpaid",
                changeFunding: "couch cushions",
                serviceImpact: "none",
                recoupFunds: "skip starbucks a few days",
            },
        ],
};

let salariesState;

export function getApplicationSalaries() {
    if (!salariesState)
        salariesState = {...salaryMockData};

    return salariesState;
}