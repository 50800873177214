import React from 'react';
import PropTypes from 'prop-types';
import GridTable from "../common/GridTable";
import IndicatorProtocolGridRow from "./IndicatorProtocolGridRow";

const IndicatorProtocolGrid = ({
                                   assessmentApproved,
                                   highlightNonCompliance = false,
                                   indicatorProtocols,
                                   protocolResponses,
                                   showState
                           }) => {
    return (
        <div className="compliance__indicatorProtocol">
            {
                assessmentApproved &&
                <p>Note - any responses overwritten by DOE are marked with two asterisks (**).</p>
            }
            <GridTable>
                <thead>
                <tr>
                    <th>Protocol</th>
                    <th>Description</th>
                    <th className={"text-center"}>District Response</th>
                    {
                        showState &&
                        !assessmentApproved &&
                        <th className={"text-center"}>State Response</th>
                    }
                </tr>
                </thead>
                <tbody>
                {
                    indicatorProtocols.map((protocol, index) => {
                        return (
                            <IndicatorProtocolGridRow
                                assessmentApproved={assessmentApproved}
                                highlightNonCompliance={highlightNonCompliance}
                                key={index}
                                protocol={protocol}
                                protocolResponses={protocolResponses}
                                showState={showState}
                            />
                        );
                    })
                }
                </tbody>
            </GridTable>
        </div>
    );
};

IndicatorProtocolGrid.propTypes = {
    assessmentApproved: PropTypes.bool.isRequired,
    highlightNonCompliance: PropTypes.bool,
    indicatorProtocols: PropTypes.array.isRequired,
    protocolResponses: PropTypes.array.isRequired,
    showState: PropTypes.bool.isRequired
};

export default IndicatorProtocolGrid;