import {DefaultPolicy} from "./policies/DefaultPolicy";
import * as strategicPlanLocations from "../../constants/strategicPlan/strategicPlanLocations";
import * as surveyLocations from "../../constants/surveys/surveyLocations";
import * as systemLocations from "../../constants/systemLocations";
import * as sppLocations from "../../constants/spp/sppLocations";
import * as usersLocations from "../../constants/users/usersLocations";
import * as monitoringPerformanceLocations from "../../constants/monitoringPerformance/monitoringPerformanceLocations";
import * as monitoringComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as disputeResolutionLocations from "../../DisputeResolution/drLocations";
import * as contexts from "../../constants/contexts";
import {convertToArray} from "../common/commonUtilities";
import * as bpieLocations from "../../containers/bpie/bpieLocations";

export function convertBehaviorsApiToJsObject(apiBehaviors) {
    apiBehaviors = convertToArray(apiBehaviors);
    let jsBehaviorsObj = [];
    let previousContext = "";
    let numberOfContexts = -1;
    let numberOfClaims = 0;
    for(let i = 0; i < apiBehaviors.length; i++){
        let currentClaim = apiBehaviors[i].split(".");
        if(currentClaim.length === 2) {
            if(previousContext !== currentClaim[0]) {
                previousContext = currentClaim[0];
                numberOfContexts++;
                numberOfClaims = 0;
                jsBehaviorsObj[numberOfContexts] = {
                    context: currentClaim[0],
                    behavior: []
                };
            }
            jsBehaviorsObj[numberOfContexts].behavior[numberOfClaims] = currentClaim[1];
            numberOfClaims++;
        }
    }

    return jsBehaviorsObj;
}

export function verifyAccessToContext(allowedContexts, userBehaviors) {
    if (!userBehaviors) return false;
    let allowed = false;
    let index = 0;
    while (!allowed && index < userBehaviors.length) {
        allowed = allowedContexts.includes(userBehaviors[index].context);
        index++;
    }

    return allowed;
}

export function verifyAccessToContextAndClaim(allowedContexts, allowedClaims, userBehaviors) {
    if (!userBehaviors) return false;
    let allowed = false;
    let contextIndex = 0;
    while (!allowed && contextIndex < userBehaviors.length) {
        allowed = allowedContexts.includes(userBehaviors[contextIndex].context);

        if (allowed) {
            let claimIndex = 0;
            let claims = userBehaviors[contextIndex].behavior;
            let claimAllowed = false;
            while (!claimAllowed && claimIndex < claims.length) {
                claimAllowed = allowedClaims.includes(claims[claimIndex]);
                claimIndex++;
            }

            allowed = claimAllowed;
        }

        contextIndex++;
    }

    return allowed;
}

export function allow(policy, policyEvent) {
    policy = policy ? new policy : new DefaultPolicy();
    return policy.permit(policyEvent);
}

export function getCurrentContextFromUrl() {
    const location = window.location.href.toLowerCase();

    if (!location) return [""];

    if (location.includes(strategicPlanLocations.ROOT.toLowerCase()))
        return [contexts.STRATEGIC_PLAN];

    if (location.includes(surveyLocations.ROOT.toLowerCase()))
        return [contexts.SURVEYS];

    if (location.includes(systemLocations.ROOT.toLowerCase()))
        return [contexts.ADMIN];

    if (location.includes(sppLocations.ROOT.toLowerCase()))
        return [contexts.SPP];

    if (location.includes(usersLocations.ROOT.toLowerCase()))
        return [contexts.USERS];

    if (location.includes(monitoringPerformanceLocations.ROOT.toLowerCase()))
        return [contexts.MONITORING_PERFORMANCE];

    if (location.includes(monitoringComplianceLocations.ROOT))
        return [contexts.MONITORING_COMPLIANCE];

    if(location.includes(disputeResolutionLocations.ROOT))
        return contexts.DISPUTE_RESOLUTION_CONTEXTS;

    if(location.includes(bpieLocations.ROOT))
        return contexts.BPIE;

    return [""];
}