import React from "react";
import PropTypes from "prop-types";
import DrStateComplaintOverviewView from "./DrStateComplaintOverviewView";

export const DrStateComplaintOverviewContainer = ({
                                                       actions,
                                                       api,
                                                       complaint,
                                                       handleAddComplaint,
                                                       handleDataChanged,
                                                       handleReturn,
                                                       handleSelectSection,
                                                       handleUpdateComplaint,
                                                       readOnly,
                                                       teamMembers,
                                                   }) => {
    const handleSaveComplaint = (form) => {
        !complaint.id
            ? handleAddComplaint(form)
            : actions.executeApi(api.saveComplaint, [form])
                .then((result) => {
                    handleUpdateComplaint(result);
                });
    };

    const handleDeleteComplaint = complaint.id
        ? () => actions.executeApi(api.deleteComplaint, [complaint.id]).then(handleReturn)
        : null;

    if (!complaint || teamMembers.length === 0) return null;

    return <DrStateComplaintOverviewView
        complaint={complaint}
        handleDeleteComplaint={handleDeleteComplaint}
        handleSaveComplaint={handleSaveComplaint}
        handleSelectSection={handleSelectSection}
        handleDataChanged={handleDataChanged}
        readOnly={readOnly}
        teamMembers={teamMembers}
    />;
};

DrStateComplaintOverviewContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    api: PropTypes.func.isRequired,
    complaint: PropTypes.object.isRequired,
    handleAddComplaint: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    teamMembers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintOverviewContainer;
