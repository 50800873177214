import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/common/buttons/Button";
import {ExternalLinkButton} from "../../components/common/ExternalLink";
import * as ButtonTypes from "../../constants/ButtonTypes";
import bpieApi from "./bpieApi";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import schoolBpieAssessmentTemplate from "../../documents/School BPIE Assessment.docx";

export const BpieDownloadButtonsView = ({
                                            actions,
                                            bpieId,
                                            isDistrictBpie = false
                                        }) => {
    const handleDownloadAssessment = (event) => {
        event.preventDefault();
        const api = isDistrictBpie ? bpieApi.downloadBpieDistrictAssessment : bpieApi.downloadBpieSchoolAssessment;
        actions.executeApi(api, [bpieId]);
    }

    return (
        <ButtonBar position={!bpieId ? ButtonBarPositions.COLUMN : ButtonBarPositions.STICKY_BOTTOM}>
            {
                !bpieId && !isDistrictBpie &&
                <ExternalLinkButton url={schoolBpieAssessmentTemplate} label={`Download SBPIE Assessment`} />
            }
            {
                bpieId &&
                <Button name="btnAssessment"
                        label={isDistrictBpie ? `Download DBPIE Assessment` : `Download SBPIE Assessment`}
                        buttonType={ButtonTypes.SYSTEM}
                        onClick={handleDownloadAssessment}/>
            }
        </ButtonBar>
    );
};

BpieDownloadButtonsView.propTypes = {
    actions: PropTypes.object.isRequired,
    bpieId: PropTypes.string,
    isDistrictBpie: PropTypes.bool
};

export default BpieDownloadButtonsView;
