import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {DisputeResolutionType, DrFiltersComplaintStatus, DrRoles} from "../drConstants";
import SectionHeader from "../../components/common/SectionHeader";

export const DrStateComplaintCaseload = ({
                                             complaintCaseloads,
                                             handleClickCaseload,
                                             handleClickOrder = null,
                                             includeUserStatus = false,
                                             showName = false
                                         }) => {
    const sortClass = handleClickOrder ? "is-clickable" : "";

    return (
        <>
            {
                complaintCaseloads &&
                complaintCaseloads.length > 0 &&
                <>
                    <SectionHeader>
                        <h2>State Complaint Caseload</h2>
                    </SectionHeader>
                    
                    <GridTable className={`caseload-table`}>
                        <thead>
                        <tr>
                            {
                                showName &&
                                <th rowSpan={2} onClick={handleClickOrder} data-id="fullName" className={sortClass}>Name</th>
                            }
                            {
                                includeUserStatus &&
                                <th rowSpan={2} onClick={handleClickOrder} data-id="userStatus" className={sortClass}>Status</th>
                            }
                            <th colSpan={4} className={`text-center`}>Complaint Status</th>
                            <th colSpan={4} className={`text-center`}>Open Assignments</th>
                        </tr>
                        <tr>
                            <th onClick={handleClickOrder} data-id="open" className={sortClass}>Open</th>
                            <th onClick={handleClickOrder} data-id="abeyance" className={sortClass}>Abeyance</th>
                            <th onClick={handleClickOrder} data-id="resolved" className={sortClass}>Resolved with Pending Corrective Actions</th>
                            <th onClick={handleClickOrder} data-id="lead" className={sortClass}>Lead</th>
                            <th onClick={handleClickOrder} data-id="backup" className={sortClass}>Backup</th>
                            <th onClick={handleClickOrder} data-id="adminSecretary" className={sortClass}>Admin Secretary</th>
                            <th onClick={handleClickOrder} data-id="director" className={sortClass}>Director</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            complaintCaseloads.map((complaint, index) => {
                                return (
                                    <tr key={index}>
                                        {
                                            showName &&
                                            <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId)}
                                                className={"is-clickable"}>{complaint.fullName}</td>
                                        }
                                        {
                                            includeUserStatus &&
                                            <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId)}
                                                className={"is-clickable"}>{complaint.userStatus}</td>
                                        }
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.Open)}
                                            className={"is-clickable"}>{complaint.open}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus["Open (In Abeyance)"])}
                                            className={"is-clickable"}>{complaint.abeyance}</td>
                                      <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.CorrectiveActionsInProgress)}
                                            className={"is-clickable"}>{complaint.resolved}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.Open, DrRoles.ComplaintLeadInvestigator.id)}
                                            className={"is-clickable"}>{complaint.lead}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.Open, DrRoles.ComplaintBackupInvestigator.id)}
                                            className={"is-clickable"}>{complaint.backup}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.Open, DrRoles.AdminSecretary.id)}
                                            className={"is-clickable"}>{complaint.adminSecretary}</td>
                                        <td onClick={(event) => handleClickCaseload(event, DisputeResolutionType.StateComplaints, complaint.userId, DrFiltersComplaintStatus.Open, DrRoles.ProgramDirector.id)}
                                            className={"is-clickable"}>{complaint.director}</td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                </>
            }
        </>
    );
};

DrStateComplaintCaseload.propTypes = {
    complaintCaseloads: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleClickCaseload: PropTypes.func.isRequired,
    handleClickOrder: PropTypes.func,
    includeUserStatus: PropTypes.bool,
    showName: PropTypes.bool
};

export default DrStateComplaintCaseload;