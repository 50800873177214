import Location from "../../components/shared/Location";

export const API_ROOT = `Gms`;
export const ROOT = `/${API_ROOT}`;
export const GMS_STATE_ROOT = `/${API_ROOT}/Admin`;
export const GMS_DATE_WINDOW_ID = ":dateWindowId";
export const APPLICATION_ID = ":applicationId";
export const BUDGET_ID = ":budgetId";
export const SECTION_ID = ":sectionId";
export const SELECTED_ELEMENTS = ":selectedElements";
export const APPLICATION_HISTORY_ID = ":applicationHistoryId";
export const GMS_STATUS_DASHBOARD_PARAM = "gmsStatus";
export const DATE_WINDOW_DASHBOARD_PARAM = "dateWindowId";
export const DISCRETIONARY_PROJECT_ID = ":discretionaryProjectId";
export const SALARY_ID = ":salaryId";

// ************************ SHARED ROUTES ************************
export const GMS_SUMMARY = Location(() => `${ROOT}/Summary`);

export const GMS_APPLICATION = Location((applicationId = APPLICATION_ID) => `${ROOT}/Application/${applicationId}/Overview`);
export const GMS_APPLICATION_BUDGET = Location((applicationId = APPLICATION_ID, budgetId = BUDGET_ID) => `${ROOT}/Application/${applicationId}/Budget/${budgetId}/`);
export const GMS_APPLICATION_DELIVERABLES = Location((applicationId = APPLICATION_ID) => `${ROOT}/Application/${applicationId}/Deliverables/`);
export const GMS_APPLICATION_SALARIES = Location((applicationId = APPLICATION_ID) => `${ROOT}/Application/${applicationId}/Salaries/`);
export const GMS_APPLICATION_SALARIES_EDIT = Location((applicationId = APPLICATION_ID, salaryId = SALARY_ID) => `${ROOT}/Application/${applicationId}/Salaries/${salaryId}`);
export const GMS_APPLICATION_SECTION = Location((applicationId = APPLICATION_ID, sectionId = SECTION_ID) => `${ROOT}/Application/${applicationId}/Section/${sectionId}/`);
export const GMS_APPLICATION_PRINT_SETUP = Location((applicationId = APPLICATION_ID) => `${ROOT}/Application/${applicationId}/PrintSetup`);
export const GMS_APPLICATION_PRINT = Location((applicationId = APPLICATION_ID, selectedElements = SELECTED_ELEMENTS) => `${ROOT}/Application/${applicationId}/Elements/${selectedElements}/Print`);
export const GMS_APPLICATION_HISTORY_PRINT = Location((applicationId = APPLICATION_ID, selectedElements = SELECTED_ELEMENTS, applicationHistoryId = APPLICATION_HISTORY_ID) => `${ROOT}/Application/${applicationId}/ApplicationHistory/${applicationHistoryId}/Elements/${selectedElements}/Print`);
export const GMS_APPLICATION_AMENDMENT_SETUP = Location((applicationId = APPLICATION_ID) => `${ROOT}/Application/${applicationId}/Amendment`);

// ************************ STATE ADMIN ROUTES ********************************

export const GMS_ADMIN_CASE_NOTES = Location((applicationId = APPLICATION_ID) => `${GMS_STATE_ROOT}/Applications/${applicationId}/case-notes`);
export const GMS_ADMIN_TEAM_MEMBERS = Location(() => `${GMS_STATE_ROOT}/TeamMembers/`);
export const GMS_ADMIN_MANAGE_APPLICATIONS = Location(() => `${GMS_STATE_ROOT}/Applications/`);
export const GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS = Location(() => `${GMS_STATE_ROOT}/DiscretionaryProjects/`);
export const GMS_ADMIN_MANAGE_FISCAL_AGENTS_USERS = Location((discretionaryProjectId = DISCRETIONARY_PROJECT_ID) => `${GMS_STATE_ROOT}/DiscretionaryProjects/${discretionaryProjectId}/agentsUsers`);
export const GMS_ADMIN_MODIFY_APPLICATION_SETTINGS = Location((dateWindowId = GMS_DATE_WINDOW_ID, applicationId = APPLICATION_ID) => `${GMS_STATE_ROOT}/dates/${dateWindowId}/Applications/${applicationId}`);

export const ALL_GMS_ROUTES = [
    GMS_SUMMARY,
    GMS_APPLICATION,
    GMS_APPLICATION_BUDGET,
    GMS_APPLICATION_DELIVERABLES,
    GMS_APPLICATION_SALARIES_EDIT,
    GMS_APPLICATION_SALARIES,
    GMS_APPLICATION_SECTION,
    GMS_APPLICATION_PRINT_SETUP,
    GMS_APPLICATION_PRINT,
    GMS_APPLICATION_AMENDMENT_SETUP,
    GMS_APPLICATION_HISTORY_PRINT,
    GMS_ADMIN_CASE_NOTES,
    GMS_ADMIN_TEAM_MEMBERS,
    GMS_ADMIN_MANAGE_APPLICATIONS,
    GMS_ADMIN_MANAGE_FISCAL_AGENTS_USERS,
    GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS,
    GMS_ADMIN_MODIFY_APPLICATION_SETTINGS
];