import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import BpieDownloadButtonsView from "../../BpieDownloadButtonsView";
import {getResponse} from "../../Assessment/bpieAssessmentFactory";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {SBPIE_ASSESSMENT_STATUS} from "../../bpieConstants";
import {BPIE_LANDING_WITH_SCHOOL_BPIE} from "../../bpieLocations";
import BpieAssessmentSummaryView from "../../Assessment/AssessmentSummary/BpieAssessmentSummaryView";
import bpieApi from "../../bpieApi";
import {createSubmissionModuleOptions} from "../../bpieObjectFactory";

const SchoolAssessmentSummaryContainer = ({
                                            actions,
                                            handleAssessmentRedirect,
                                            handlePrioritiesRedirect,
                                            history,
                                            loadAssessment,
                                            loadSchoolBpies,
                                            schoolBpie
                                        }) => {
    const [assessment, setAssessment] = useState(null);
    const [responses, setResponses] = useState(null);

    const onEditIndicator = (event, desiredIndicator) => {
        event.preventDefault();
        handleAssessmentRedirect(schoolBpie.schoolBpieId, desiredIndicator);
    }

    const submitSchoolBpie = () => {
        if (confirm("Are you sure you want to submit the SBPIE Assessment?")) {
            actions.executeApi(bpieApi.submitSchoolBpieAssessment, [schoolBpie.schoolBpieId])
                .then(() => {
                    loadSchoolBpies();
                });
        }
    }

    const priorityButtonClick = () => {
        handlePrioritiesRedirect(schoolBpie.schoolBpieId)
    }

    const buildSubmissionModuleOptions = (dataMissing, priorityIndicatorDataMissing, isModuleReview) => {
        if(!schoolBpie.isActive)
            return createSubmissionModuleOptions(schoolBpie.isIncomplete,
                true,
                `Submit Review of the SBPIE Assessment`,
                `The SBPIE Assessment has been submitted.`,
                `SBPIE Assessment Submission`);

        if(dataMissing || priorityIndicatorDataMissing)
            return createSubmissionModuleOptions(schoolBpie.isIncomplete,
                true,
                `Submit Review of the SBPIE Assessment`,
                dataMissing ? `Highlighted responses above need to be completed.` : `Priority indicators need to be selected.`,
                `SBPIE Assessment Review`);

        return isModuleReview ? createSubmissionModuleOptions(schoolBpie.isIncomplete,
                false,
                `Submit Review of the SBPIE Assessment`,
                `IMPORTANT: Once your SBPIE Assessment is submitted, it CANNOT be edited. Please review your SBPIE Assessment and make any corrections before submitting your final version.`,
                `SBPIE Assessment Review`)
            : createSubmissionModuleOptions(schoolBpie.isIncomplete,
                false,
                `Submit Final SBPIE Assessment`,
                `By selecting Submit Final SBPIE Assessment, I understand that the SBPIE process is complete and our team cannot go back in to edit the document.`,
                `SBPIE Assessment Submission`);
    };

    useEffect(() => {
        if (schoolBpie.status < SBPIE_ASSESSMENT_STATUS.InProgress.id)
            history.push(BPIE_LANDING_WITH_SCHOOL_BPIE.getUrl(schoolBpie.schoolBpieId));
    }, []);

    useEffect(() => {
        if (!schoolBpie)
            return;

        loadAssessment(setAssessment);
    }, [schoolBpie]);

    useEffect(() => {
        if (!assessment)
            return;

        const builtResponses = assessment.indicators.map((i) => {
            return getResponse(assessment.responses, i.bpieIndicatorId);
        });

        if(!schoolBpie.isConcluded && builtResponses.every(f => isTrimmedStringEmpty(f.implementationStatus))) {
            handleAssessmentRedirect(schoolBpie.schoolBpieId, 1);
            return;
        }

        setResponses(builtResponses);
    }, [assessment]);

    if (!schoolBpie || !responses)
        return null;

    return (
        <>
            <h2>SBPIE Assessment Summary</h2>

            <BpieAssessmentSummaryView
                bpieLabel={`SBPIE`}
                buildSubmissionModuleOptions={buildSubmissionModuleOptions}
                indicators={assessment.indicators}
                isIncomplete={schoolBpie.isIncomplete}
                isSubmitted={!schoolBpie.isActive}
                onEditIndicator={onEditIndicator}
                priorityButtonClick={priorityButtonClick}
                responses={responses}
                submitBpie={submitSchoolBpie}
                submitPolicyEvent={policyEvents.schoolBpieAssessments.SUBMIT}
            />

            {
                schoolBpie.isArchived &&
                <BpieDownloadButtonsView
                    actions={actions}
                    bpieId={schoolBpie.schoolBpieId}
                />
            }
        </>
    );
}

SchoolAssessmentSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    handleAssessmentRedirect: PropTypes.func.isRequired,
    handlePrioritiesRedirect: PropTypes.func.isRequired,
    history: PropTypes.object,
    loadAssessment: PropTypes.func.isRequired,
    loadSchoolBpies: PropTypes.func.isRequired,
    schoolBpie: PropTypes.object.isRequired
}

export default SchoolAssessmentSummaryContainer;