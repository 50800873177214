import PropTypes from "prop-types";
import React from "react";

const VideoAdapter = ({
    name,
    title,
    url,
}) => {


    return (
        <div className={`video--responsive height500`}>
            <iframe
                className={`fitScreen`}
                name={name}
                src={url}
                frameBorder="0"
                title={title}
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
            />
        </div>
    );
};

VideoAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    url: PropTypes.string.isRequired,
};

export default VideoAdapter;