import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplay from "./HtmlDisplay";

const HtmlDisplayInline = ({
                               ariaLabelledBy,
                               className,
                               name,
                               html,
                               onClick,
                           }) => {
    return <HtmlDisplay ariaLabelledBy={ariaLabelledBy} isInline={true} name={name} html={html} className={className}
                        onClick={onClick}/>;
};

HtmlDisplayInline.propTypes = {
    ariaLabelledBy: PropTypes.string,
    className: PropTypes.string,
    html: PropTypes.string.isRequired,
    name: PropTypes.string,
    onClick: PropTypes.func,
};

export default HtmlDisplayInline;