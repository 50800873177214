import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {
    isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid, reinitializeInputFormFields,
    useFormInputObject,
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import {emptyGuid} from "../../../../constants/config";
import {createProgramObject} from "./ptsManageProgramsObjectFactory";
import {PtsManageProgramsTableRowDisplay} from "./PtsManageProgramsTableRowDisplay";
import {PtsManageProgramsTableRowEdit} from "./PtsManageProgramsTableRowEdit";
import * as ptsLocations from "../../ptsLocations";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import Button from "../../../../components/common/buttons/Button";

export const PtsManageProgramsView = ({
                                          actions,
                                          dataChanged,
                                          gotoLocation,
                                          handleDataChanged,
                                          handleDeletePtsProgram,
                                          handleSavePtsProgram,
                                          programs,
                                          searchCriteria
                                      }) => {
    const programForm = useFormInputObject(createProgramObject());
    const [programIdEditing, setProgramIdEditing] = useState(null);

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const isValidForm = () => {
        clearInputFormErrorFields(programForm);

        if (isTrimmedStringEmpty(programForm.programName.value.toString()))
            programForm.programName.setError("Required");

        let isValid = inputFormIsValid(programForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleSaveProgramClick = async (event) => {
        event.preventDefault();
        if (!isValidForm())
            return;

        const program = convertFormInputObjectToObject(programForm);

        await handleSavePtsProgram(programIdEditing, program);
        setProgramIdEditing(null);
    };

    const handleCancelProgramClick = (event) => {
        event.preventDefault();
        handleDataChanged(false);
        setProgramIdEditing(null);
    };

    const handleAddProgramClick = (event) => {
        event.preventDefault();
        reinitializeInputFormFields(programForm, createProgramObject());
        setProgramIdEditing(emptyGuid);
        handleDataChanged(true);
    };

    const handleModifyProgramClick = (event, program) => {
        event.preventDefault();

        reinitializeInputFormFields(programForm, program);
        setProgramIdEditing(program.programId);
        handleDataChanged(true);
    };

    const handleDeleteProgramClick = (event, programId) => {
        event.preventDefault();
        handleDeletePtsProgram(programId);
    };

    const handleViewProgramClick = async (event, programId) => {
        event.preventDefault();
        gotoLocation(ptsLocations.PTS_ADMIN_MANAGE_FISCAL_AGENTS_USERS.getUrl(programId));
    };

    useEffect(() => {
        if (!dataChanged && !isNullOrUndefined(programIdEditing))
            setProgramIdEditing(null);
    }, [dataChanged]);

    const rowsDisabled = !isNullOrUndefined(programIdEditing);
    const isNewProgram = programIdEditing === emptyGuid;
    const filteredPrograms = searchCriteria.applyFiltersAndOrder(programs);
    const totalColums = 3;

    return (
        <>
            <GridTable>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id={`programName`} className={`is-clickable`}>
                        Program
                    </th>
                    <th>
                        ROI Report Required
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(filteredPrograms) && !isNewProgram &&
                    <tr>
                        <td colSpan={totalColums} className={"center"}>
                            No programs found.
                        </td>
                    </tr>
                }
                {
                    filteredPrograms.map((program, key) => {
                        if (program.programId === programIdEditing)
                            return <PtsManageProgramsTableRowEdit
                                handleCancelProgramClick={handleCancelProgramClick}
                                handleSaveProgramClick={handleSaveProgramClick}
                                program={programForm}
                                key={key}
                            />;

                        return <PtsManageProgramsTableRowDisplay
                            handleDeleteProgramClick={handleDeleteProgramClick}
                            handleModifyProgramClick={handleModifyProgramClick}
                            handleViewProgramClick={handleViewProgramClick}
                            program={program}
                            key={key}
                            rowDisabled={rowsDisabled}
                        />;
                    })
                }
                {
                    isNewProgram &&
                    <PtsManageProgramsTableRowEdit
                        handleCancelProgramClick={handleCancelProgramClick}
                        handleSaveProgramClick={handleSaveProgramClick}
                        program={programForm}
                        isNewProgram
                    />
                }
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={totalColums}>
                        <Button
                            buttonType={ButtonTypes.ADD}
                            btnClass={`button--large`}
                            label={`Add Program`}
                            name={`btnAddProgram`}
                            onClick={handleAddProgramClick}
                            showLabel
                        />
                    </td>
                </tr>
                </tfoot>
            </GridTable>
        </>
    );
};

PtsManageProgramsView.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeletePtsProgram: PropTypes.func.isRequired,
    handleSavePtsProgram: PropTypes.func.isRequired,
    programs: PropTypes.array,
    searchCriteria: PropTypes.object.isRequired
};