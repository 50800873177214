import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {GmsPolicy, policyEvents} from "../../../../components/authorization/policies/GmsPolicy";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import {emptyGuid} from "../../../../constants/config";
import gmsApi from "../../gmsApi";
import {createGmsDeliverableItem} from "../../gmsObjectFactory";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import {CATEGORIES_LIST} from "./gmsApplicationDeliverablesConstants";
import GmsApplicationDeliverablesTable from "./GmsApplicationDeliverablesTable";

const GmsApplicationDeliverablesContainer = ({
                                                 actions,
                                                 applicationId,
                                                 gmsIndicators,
                                                 history,
                                             }) => {
    const [summary, setSummary] = useState(null);
    const [editId, setEditId] = useState(undefined);
    const [editCategory, setEditCategory] = useState(undefined);
    const isEditing = !!editId;
    //TODO: summary?.canEditDeliverables &&
    const canUserEditDeliverables = allow(GmsPolicy, policyEvents.APPLICATION.modify);

    const loadApplicationDeliverables = () => {
        actions.executeApi(gmsApi.getApplicationDeliverables, [applicationId])
            .then((result) => {
                setSummary(result);
            });
    };

    const handleCancel = () => {
        setEditId(undefined);
        setEditCategory(undefined);
    };

    const handleDelete = (deliverableId) => {
        if (confirm("Deleting this deliverable is permanent. Continue?"))
            actions.executeApi(gmsApi.deleteApplicationDeliverable, [applicationId, deliverableId])
                .then((result) => {
                    setSummary(result);
                });
    };

    const handleEdit = (id = emptyGuid, category = 0) => {
        setEditId(id);
        setEditCategory(category);
    };

    const handleSave = (deliverable) => {
        const validateDeliverable = createGmsDeliverableItem(deliverable, true);

        actions.executeApi(gmsApi.saveApplicationDeliverable, [applicationId, validateDeliverable])
            .then((result) => {
                setSummary(result);
                handleCancel();
            });
    };

    useEffect(() => {
        loadApplicationDeliverables();
        if (isArrayNullOrEmpty(gmsIndicators))
            actions.loadGmsIndicators();
    }, []);

    useEffect(() => {
        if (summary?.applicationTitle)
            actions.updatePageTitle(summary.applicationTitle);

        actions.updatePageSubTitle('Deliverables');

        return actions.clearSubTitle;
    }, [summary]);

    return (
        <>
            <p>Requested: ${summary?.requestedTargetedAllocation} |
                Total: ${summary?.total} |
                Remaining: <span className={summary?.isOverBudget ? `color--alert` : ""}>
                    ${summary?.remainingTargetedAllocation}</span>
            </p>
            {
                summary?.deliverables &&
                CATEGORIES_LIST.map((category) => {
                    const deliverables = summary.deliverables.filter(d => d.deliverableCategory === category.value);
                    const deliverableCount = deliverables.length;
                    const isEditCategory = category.value === editCategory;
                    const isOpen = !isArrayNullOrEmpty(deliverables);

                    return <CollapsibleContainer key={`deliverable-type${category.value}`} open={isOpen}
                                                 trigger={`${category.text} (${deliverableCount})`}>
                        <GmsApplicationDeliverablesTable
                            canUserEditDeliverables = {canUserEditDeliverables}
                            deliverableCategory={category.value}
                            deliverables={deliverables}
                            editId={editId}
                            gmsIndicators={gmsIndicators}
                            handleCancel={handleCancel}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            handleSave={handleSave}
                            isEditCategory={isEditCategory}
                        />
                    </CollapsibleContainer>;
                })
            }
            <GmsApplicationNavigation
                applicationId={applicationId}
                history={history}
                isDirty={isEditing}
                sectionId={"Deliverables"}
            />
        </>
    );
};


GmsApplicationDeliverablesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    gmsIndicators: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
};

export default GmsApplicationDeliverablesContainer;