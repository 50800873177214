import React from "react";
import PropTypes from 'prop-types';
import DrStateComplaintResponseComponentEdit from "./DrStateComplaintResponseComponentEdit";
import DrStateComplaintResponseComponentDisplay from "./DrStateComplaintResponseComponentDisplay";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";

const DrStateComplaintResponseComponent = ({
	                                           deadline,
	                                           editDeadlineId,
	                                           handleClickCancel,
	                                           handleSaveResponseAsync,
	                                           isDistrictUser,
	                                           issueId
                                           }) => {

	return (
		<FieldsetLayout
			legend={`Correction Results`}
			showLegend
			fieldsetClass={`actionResponse__response`}
		>
			{
				isDistrictUser &&
				deadline.id === editDeadlineId &&
				<DrStateComplaintResponseComponentEdit
					deadline={deadline}
					handleSaveResponseAsync={handleSaveResponseAsync}
					handleClickCancel={handleClickCancel}
					issueId={issueId}
					response={deadline.response}
				/>
			}
			{
				(!isDistrictUser ||
					deadline.id !== editDeadlineId) &&
				<DrStateComplaintResponseComponentDisplay
					response={deadline.response}
				/>
			}
		</FieldsetLayout>
	);
};

DrStateComplaintResponseComponent.propTypes = {
	deadline: PropTypes.object.isRequired,
	editDeadlineId: PropTypes.string.isRequired,
	handleClickCancel: PropTypes.func.isRequired,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	issueId: PropTypes.string
};

export default DrStateComplaintResponseComponent;