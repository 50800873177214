import React from "react";
import PropTypes from 'prop-types';
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import DrStateComplaintResponseReviewDisplay from "./DrStateComplaintResponseReviewDisplay";
import DrStateComplaintResponseReviewEdit from "./DrStateComplaintResponseReviewEdit";
import {isObjectNullOrEmpty} from "../../../components/common/commonUtilities";

const DrStateComplaintResponseReview = ({
	                                        deadline,
	                                        editDeadlineId,
	                                        handleClickCancel,
	                                        handleSaveResponseReviewAsync,
	                                        isDistrictUser,
	                                        issueId
                                        }) => {

	const isStarted = !isObjectNullOrEmpty(deadline.response.review);
	const isEditView = !isDistrictUser && deadline.id === editDeadlineId;

	if (!deadline.response.corrected) return null;

	return (
		<FieldsetLayout
			legend={`Correction Review`}
			showLegend
			fieldsetClass={`actionResponse__response`}
		>
			{
				!isEditView &&
				!isStarted &&
				<p>Not Started</p>
			}
			{
				isEditView &&
				<DrStateComplaintResponseReviewEdit
					deadline={deadline}
					handleClickCancel={handleClickCancel}
					handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
					issueId={issueId}
					review={deadline.response.review}
				/>
			}
			{
				isStarted &&
				!isEditView &&
				<DrStateComplaintResponseReviewDisplay
					review={deadline.response.review}
				/>
			}
		</FieldsetLayout>
	);
};

DrStateComplaintResponseReview.propTypes = {
	deadline: PropTypes.object.isRequired,
	editDeadlineId: PropTypes.string.isRequired,
	handleClickCancel: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired,
	isDistrictUser: PropTypes.bool.isRequired,
	issueId: PropTypes.string
};

export default DrStateComplaintResponseReview;