import React from 'react';
import PropTypes from 'prop-types';
import InputLayout from "./InputLayout";

const SelectField = ({
                         align = "middle",
                         defaultOption,
                         disabled,
                         elementEditBar,
                         error,
                         helpText,
                         includeDefaultOption = true,
                         inputClass = "",
                         isInline,
                         label,
                         labelClass = "",
                         large,
                         medium,
                         name,
                         onChange,
                         options,
                         showLabel,
                         small,
                         value,
                         wrapperClass = "",
                     }) => {

    if (!defaultOption)
        defaultOption = "Select an Option";

    let selectListOptions = [];
    if (options && options.length > 0) {
        selectListOptions = options;
    }

    if (value === null) {
        value = "";
    }

    return (
        <InputLayout
            name={name}
            label={label}
            showLabel={showLabel}
            error={error}
            helpText={helpText}
            wrapperClass={wrapperClass}
            labelClass={labelClass}
            inputClass={inputClass}
            align={align}
            disabled={disabled}
            elementEditBar={elementEditBar}
            small={small}
            medium={medium}
            large={large}
        >
            <div className={isInline ? `l-inline-block` : ""}>
                <select
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                >
                    {includeDefaultOption && <option value="">{defaultOption}</option>}
                    {selectListOptions.map((listItem, index) => {
                        return <option key={index} value={listItem.value}>{listItem.text}</option>;
                    })
                    }
                </select>
            </div>
        </InputLayout>
    );
};

SelectField.propTypes = {
    align: PropTypes.string,
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
    elementEditBar: PropTypes.object,
    error: PropTypes.string,
    helpText: PropTypes.string,
    includeDefaultOption: PropTypes.bool,
    inputClass: PropTypes.string,
    isInline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    large: PropTypes.number,
    medium: PropTypes.number,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    small: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    wrapperClass: PropTypes.string
};

export default SelectField;