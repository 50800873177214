import React from 'react';
import PropTypes from "prop-types";
import SchoolSummaryReportContainer from "./SchoolSummaryReportContainer";

const SchoolPriorityTrendReportContainer = ({
                                          actions,
                                          districtId,
                                          reportTitle,
                                      }) => {
    return <SchoolSummaryReportContainer
        actions={actions}
        districtId={districtId}
        reportTitle={reportTitle}
        isStatusReport={false}
    />;
};


SchoolPriorityTrendReportContainer.propTypes = {
    actions: PropTypes.object,
    districtId: PropTypes.string,
    reportTitle: PropTypes.string
};

export default SchoolPriorityTrendReportContainer;