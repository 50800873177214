import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Button from "../../components/common/buttons/Button";
import CheckBoxList from "../../components/common/inputs/CheckBoxList";
import DateTimePickerField from "../../components/common/inputs/DateTimePickerField";
import FormTable from "../../components/common/FormTable";
import DisplayField from "../../components/common/inputs/DisplayField";
import RadioField from "../../components/common/inputs/RadioField";
import SelectField from "../../components/common/inputs/SelectField";
import TextAreaEditor from "../../components/common/inputs/textAreaEditor/TextAreaEditor";
import TextField from "../../components/common/inputs/TextField";
import api from "./drMediationsApi";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as drMediationConstants from './drMediationConstants';
import {RadioButtonYesNoList, RadioButtonYesNoObject} from "../drConstants";
import * as DrMediationObjectFactory from "./drMediationObjectFactory";
import AccountUtility from "../../api/AccountUtility";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {convertFormInputObjectToObject, useFormInputObject} from "../../components/common/inputs/inputUtility";
import {inputStyles} from '../../constants/inputConstants';
import {MediationPolicy} from "../../components/authorization/policies/MediationPolicy";
import {dispute_resolution} from "../../constants/policyEvents";
import {
	generateArchivableSelectListFromArray,
	generateSelectListFromArray, isNumber,
	isObjectNullOrEmpty
} from "../../components/common/commonUtilities";
import drApi from "../drApi";
import {createComplaintCaseNumberList} from "../drObjectFactory";
import {getFullCaseNumber} from "../drUtilities";

export const DrMediationOverviewView = ({
	                                        handleClickDelete,
	                                        handleClickSave,
	                                        handleReturn,
	                                        mediation,
											dateWindowId,
											institutionId
                                        }) => {


	const [commentIsEditing, setCommentIsEditing] = useState(!mediation.comment || []);
	const [readOnly, setReadOnly] = useState(true);
	const [primaryExceptionality, setPrimaryExceptionality] = useState([]);
	const [mediators, setMediators] = useState([]);
	const [complaintCaseNumbers, setComplaintCaseNumbers] = useState([]);

	const mediationForm = useFormInputObject(DrMediationObjectFactory.createMediationObject(mediation));

	const fetchFormData = () => {
		mediationForm.requestedBy.setValue(mediationForm.requestedBy.value.toString());
		return convertFormInputObjectToObject(mediationForm);
	};

	const onCaseNumberChange = (event) => {
		const caseNumberCount = event.target.value;

		if(caseNumberCount.length > 4 || !isNumber(caseNumberCount)) return;

		mediationForm.caseNumber.setValue(getFullCaseNumber(mediationForm.caseNumberYear.value, caseNumberCount, mediationForm.caseNumberType.value));
		mediationForm.caseNumberCount.setValue(caseNumberCount);
	}

	useEffect(() => {
		const isDistrictUser = AccountUtility.isDistrictUser();
		const canEdit = AuthorizationUtilities.allow(MediationPolicy, dispute_resolution.mediation.MODIFY);
		setReadOnly(isDistrictUser || !canEdit);
	}, []);

	useEffect(() => {
		api.getPrimaryExceptionalityTypes()
			.then((result) => setPrimaryExceptionality(generateSelectListFromArray(result)));
	}, []);

	useEffect(() => {
		if (!isObjectNullOrEmpty(mediation))
			api.getMediators()
				.then((result) => setMediators(generateArchivableSelectListFromArray(result, mediation.mediatorName)));
	}, [mediation]);

	useEffect(() => {
			drApi.GetComplaintNumbers(dateWindowId, institutionId)
				.then((result) => setComplaintCaseNumbers(createComplaintCaseNumberList(result)));
	}, [mediation, dateWindowId, institutionId]);

	return (
		<>
			<FormTable>
				<RadioField
					legend={"Status"}
					name={"mediationStatus"}
					options={drMediationConstants.StatusList}
					{...mediationForm.mediationStatus}
					direction={inputStyles.radio.direction.HORIZONTAL}
					disabled={readOnly}
				/>

				<DisplayField label={`Case Number (Fiscal Year-&lt;Number&gt;M)`} name={`caseNumber`} showLabel>
					{mediationForm.caseNumber.value}
				</DisplayField>

				<TextField
					disabled={readOnly}
					inputClass={"large-6"}
					label="Update Number in the Case Number"
					name="caseNumberCount"
					showLabel
					{...mediationForm.caseNumberCount}
					onChange={onCaseNumberChange}
				/>

				<DateTimePickerField
					name="dateRequested"
					label="Date Received"
					showLabel
					{...mediationForm.dateRequested}
					inputClass={"large-6"}
					disabled={readOnly}
				/>

				<CheckBoxList
					disabled={readOnly}
					legend={"Requested by: (check all the apply) "}
					name={"requestedBy"}
					options={drMediationConstants.RequestedBy}
					{...mediationForm.requestedBy}
				/>

				<RadioField
					legend={"A request was made for an interpreter."}
					name={"interpreterNeeded"}
					options={RadioButtonYesNoList}
					{...mediationForm.interpreterNeeded}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				{
					mediationForm.interpreterNeeded.value === "True" &&
					<TextField
						name="typeOfInterpreter"
						label="Type of interpreter needed: [enter language, sign language]"
						showLabel
						{...mediationForm.typeOfInterpreter}
						inputClass={"large-6"}
						disabled={readOnly}
					/>
				}

				<SelectField
					disabled={readOnly}
					label={"Primary Exceptionality"}
					inputClass={"large-6"}
					name={"primaryExceptionality"}
					options={primaryExceptionality}
					showLabel
					{...mediationForm.primaryExceptionality}
				/>

				<DateTimePickerField
					name="dateMeetingScheduled"
					label="Scheduled Mediation Session"
					showLabel
					{...mediationForm.dateMeetingScheduled}
					inputClass={"large-6"}
					disabled={readOnly}
				/>
				<TextField
					name="placeMeetingScheduled"
					label="Mediation Location"
					showLabel
					{...mediationForm.placeMeetingScheduled}
					inputClass={"large-6"}
					disabled={readOnly}
				/>
				<DateTimePickerField
					name="dateMediatorAssigned"
					label="Date Assigned to Mediator:"
					showLabel
					{...mediationForm.dateMediatorAssigned}
					inputClass={"large-6"}
					disabled={readOnly}
				/>

				<SelectField
					disabled={readOnly}
					name={`mediatorName`}
					label={"Name of Mediator:"}
					inputClass={"large-6"}
					options={mediators}
					showLabel
					{...mediationForm.mediatorName}
				/>

				<DateTimePickerField
					name="dateMeetingHeld"
					label="Date Mediation Held:"
					showLabel
					{...mediationForm.dateMeetingHeld}
					inputClass={"large-6"}
					disabled={readOnly}
				/>

				<RadioField
					legend={"The parent or representative has filed a formal state complaint on these issues (at the time of this requested mediation)."}
					name={"filedStateComplaint"}
					options={RadioButtonYesNoList}
					{...mediationForm.filedStateComplaint}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				{
					mediationForm.filedStateComplaint.value === RadioButtonYesNoObject.Yes &&
					<SelectField
						disabled={readOnly}
						name={`complaintId`}
						label={"Complaint Case Number:"}
						inputClass={"large-6"}
						options={complaintCaseNumbers}
						showLabel
						{...mediationForm.complaintId}
					/>
				}

				<RadioField
					legend={"The parent, or their representative or district has filed for due process hearing request (at the time of this requested mediation)."}
					name={"filedDueProcess"}
					options={RadioButtonYesNoList}
					{...mediationForm.filedDueProcess}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				<RadioField
					legend={"Is Agreement Reached?"}
					name={"agreement"}
					options={drMediationConstants.AgreementList}
					{...mediationForm.agreement}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				<RadioField
					legend={"Is mediation held & related to due process hearing request?"}
					name={"mediationRelatedToDueProcess"}
					options={RadioButtonYesNoList}
					{...mediationForm.mediationRelatedToDueProcess}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				<RadioField
					legend={"Is mediation agreement related to due process hearing request?"}
					name={"agreementRelatedToDueProcess"}
					options={RadioButtonYesNoList}
					{...mediationForm.agreementRelatedToDueProcess}
					disabled={readOnly}
					direction={inputStyles.radio.direction.HORIZONTAL}
				/>

				<TextAreaEditor
					name="comment"
					label="Comments"
					showLabel
					onClickEdit={() => setCommentIsEditing(!commentIsEditing)}
					disabled={readOnly || !commentIsEditing}
					{...mediationForm.comment}
				/>

			</FormTable>
			{
				<ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
					{
						handleClickDelete && !readOnly &&
						<Button name="btnDelete"
						        label="Delete"
						        buttonType={ButtonTypes.DELETE}
						        onClick={handleClickDelete}
						/>
					}
					{
						!readOnly &&
						<Button name="btnSave"
								label="Save"
								buttonType={ButtonTypes.SAVE}
								onClick={(event) => handleClickSave(event, fetchFormData())}
						/>
					}
					{
						handleReturn &&
						<Button name="btnCancel"
						        label={readOnly ? "Back" : "Cancel"}
						        buttonType={readOnly ? ButtonTypes.BACK : ButtonTypes.CANCEL}
						        onClick={handleReturn}
						/>
					}
				</ButtonBar>
			}
		</>
	);
};

DrMediationOverviewView.propTypes = {
	handleClickDelete: PropTypes.func,
	handleClickSave: PropTypes.func.isRequired,
	handleReturn: PropTypes.func.isRequired,
	mediation: PropTypes.object.isRequired,
	dateWindowId: PropTypes.string.isRequired,
	institutionId: PropTypes.string.isRequired,
};

export default DrMediationOverviewView;