import React from 'react';
import PropTypes from 'prop-types';
import HtmlDisplayInline from "../../htmlDisplay/HtmlDisplayInline";
import Notification from '../Notification';
import GridColumn from "../GridColumn";
import GridRow from "../GridRow";
import {inputStyles} from "../../../constants/inputConstants";
import {isArrayNullOrEmpty} from "../commonUtilities";

const InputLayout = ({
                         children,
                         name,
                         label,
                         helpText,
                         showLabel,
                         error,
                         wrapperClass = "",
                         labelClass = "",
                         inputClass = "",
                         disabled,
                         small = 12,
                         medium,
                         large,
                         align = "",
                         elementEditBar,
                         useInputTheme = true
                     }) => {
    if (!showLabel)
        labelClass = `${labelClass} ${inputStyles.HIDDEN}`;

    if (!isArrayNullOrEmpty(error))
        wrapperClass = `${wrapperClass} ${inputStyles.ERROR}`;

    if (disabled)
        wrapperClass = `${wrapperClass} ${inputStyles.DISABLED}`;

    if (useInputTheme)
        wrapperClass = `${wrapperClass} ${inputStyles.THEME}`;

    const smallColumnLabel = !small ? 12 : small === 6 || small === 12 ? small : 12 - small;
    const mediumColumnLabel = !medium ? "" : medium === 6 ? medium : 12 - medium;
    const largeColumnLabel = !large ? "" : large === 6 ? large : 12 - large;

    return (
        <GridRow rowClass={wrapperClass} align={align}>
            <GridColumn columnClass={labelClass}
                        small={`${smallColumnLabel}`}
                        medium={`${mediumColumnLabel}`}
                        large={`${largeColumnLabel}`}
            >
                <label htmlFor={name} className={`input-layout-label`}><HtmlDisplayInline html={label}/>{helpText &&
                    <Notification helpText={helpText}/>}</label>
            </GridColumn>
            <GridColumn columnClass={inputClass}
                        small={small}
                        medium={medium}
                        large={large}
            >
                {children}
                {elementEditBar}
                {error && <Notification error={error}/>}
            </GridColumn>
        </GridRow>
    );
};

InputLayout.propTypes = {
    children: PropTypes.any, //eslint-disable-line react/forbid-prop-types
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    helpText: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    wrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    small: PropTypes.number,
    medium: PropTypes.number,
    large: PropTypes.number,
    align: PropTypes.string,
    elementEditBar: PropTypes.object,
    useInputTheme: PropTypes.bool
};

export default InputLayout;