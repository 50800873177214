import PropTypes from "prop-types";
import React, {useState} from "react";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {renderChildrenWithNewProps} from "../layout/layoutUtility";
import Button from "./buttons/Button";
import CollapsibleContainer from "./CollapsibleContainer";
import CollapsibleTrigger from "./CollapsibleTrigger";
import {objectHasProperty} from "./commonUtilities";

export function createAllContainerDetailsObject(id, notificationTriggerText, parentObject) {
    return {
        id,
        notificationTriggerText,
        parentObject
    };
}

/**
 * CollapsibleContainerCollection - Creates a collection of collapsible containers that specializes in Expand/Collapse all functionality
 * @param {array} allContainerDetails - use createAllContainerDetailsObject above when creating array, this object contains the ID for each container, the notificationTriggerText and an optional parentObject that will get passed to all children.
 * @param {children} children - what is displayed in each collapsible container
 * @param {string} containerClassName - class added to each collapsible container
 * @param {string} expandCollapseAllLinkSuffix - text used for the expand or collapse all string, i.e. Expand All {Label}
 * @param {string} expandCollapseAllLinkName - name for the link for expand or collapse all
 * @param {string} expandCollapseAllLinkWrapperClass - class used in div wrapped around link
 */

export const CollapsibleContainerCollection = ({
                                                   allContainerDetails = [],
                                                   children,
                                                   containerClassName = "",
                                                   expandCollapseAllLinkSuffix = "",
                                                   expandCollapseAllLinkName = "",
                                                   expandCollapseAllLinkWrapperClass = ""
                                               }) => {
    const [containerIdsExpanded, setContainerIdsExpanded] = useState([]);

    if(allContainerDetails.length === 0)
        return null;

    // check to make sure allContainerDetails uses createAllContainerDetailsObject
    let isAllContainerDetailsValid = true;
    for(let detail of allContainerDetails) {
        if(objectHasProperty(detail, "id") && objectHasProperty(detail, "notificationTriggerText"))
            isAllContainerDetailsValid = false;
    }
    if(isAllContainerDetailsValid)
        throw ``;

    const allContainerIds = allContainerDetails.map(m => m.id);

    const handleActionOpened = (containerId) => {
        let currentOpened = [...containerIdsExpanded];
        if (!currentOpened.some(s => s === containerId)) {
            currentOpened.push(containerId);
            setContainerIdsExpanded(currentOpened);
        }
    };

    const handleActionClosed = (containerId) => {
        setContainerIdsExpanded(containerIdsExpanded.filter(f => f !== containerId));
    };

    const handleExpandCollapseAllClick = (event) => {
        event.preventDefault();
        const isAllExpanded = containerIdsExpanded.length === allContainerIds.length;
        if (isAllExpanded)
            setContainerIdsExpanded([]);
        else
            setContainerIdsExpanded(allContainerIds);
    };

    const expandCollapseAllLabel = containerIdsExpanded.length === allContainerIds.length
        ? `Collapse All`
        : `Expand All`;

    return <>
        <div className={expandCollapseAllLinkWrapperClass}>
            <Button
                buttonType={ButtonTypes.LINK}
                label={`${expandCollapseAllLabel} ${expandCollapseAllLinkSuffix}`}
                name={expandCollapseAllLinkName}
                onClick={handleExpandCollapseAllClick}
                showLabel
            />
        </div>
        {
            allContainerIds.map((containerId, index) => {
                const isExpanded = containerIdsExpanded.some(s => s === containerId);
                const containerDetails = allContainerDetails.find(f => f.id === containerId);
                const notificationTrigger = <CollapsibleTrigger text={containerDetails.notificationTriggerText} />;
                return (
                    <CollapsibleContainer
                        key={`${index}_${containerId}`}
                        id={`cc-${containerId}`}
                        trigger={notificationTrigger}
                        contentOuterClassName={containerClassName}
                        open={isExpanded}
                        onOpen={() => handleActionOpened(containerId)}
                        onClose={() => handleActionClosed(containerId)}
                    >
                        {renderChildrenWithNewProps(children, {parentObject: containerDetails.parentObject})}
                    </CollapsibleContainer>
                );
            })
        }
    </>
        ;
};

CollapsibleContainerCollection.propTypes = {
    allContainerDetails: PropTypes.array.isRequired, // use createAllContainerDetailsObject when creating array
    children: PropTypes.any, //eslint-disable-line react/forbid-prop-types
    containerClassName: PropTypes.string,
    expandCollapseAllLinkSuffix: PropTypes.string.isRequired,
    expandCollapseAllLinkName: PropTypes.string.isRequired,
    expandCollapseAllLinkWrapperClass: PropTypes.string
};