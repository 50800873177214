import {createListFromObject} from "../../components/common/commonUtilities";

export const NOT_REQUIRED_SHORTHAND = "N/A";

export const ComplianceMonitoringOverallStatus = {
    "Not Required": "notRequired",
    "Not Started": "notStarted",
    "Not Submitted": "notSubmitted",
    Submitted: "submitted",
    "Being Reassessed": "beingReassessed",
    "Approved with Findings": "approvedWithFindings",
    Approved: "approved"
};

export const ComplianceMonitoringOverallStatusList = createListFromObject(ComplianceMonitoringOverallStatus);

export const ComplianceMonitoringNonComplianceStatus = {
    "Not Started": "nonComplianceNotStarted",
    "In Progress": "nonComplianceInProgress",
    "CAP Activities Submitted": "nonComplianceCapActivitiesSubmitted",
    "CAP Results Submitted": "nonComplianceCapResultsSubmitted",
    "Partial or All Submitted": "nonCompliancePartialSubmitted",
    Finished: "nonComplianceFinished"
};

export const ComplianceMonitoringNonComplianceStatusList = createListFromObject(ComplianceMonitoringNonComplianceStatus);


