import AccountUtility from "../../../api/AccountUtility";
import {MANAGE_TEAM_MEMBER_DISTRICT_ASSIGNMENT} from "../../../constants/policyEvents";
import * as PolicyEvents from "../../../constants/policyEvents";
import {DefaultPolicy} from "./DefaultPolicy";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContextAndClaim} from "../AuthorizationUtilities";
import * as contexts from "../../../constants/contexts";

class UserPolicy extends DefaultPolicy {
    constructor() {
        super([contexts.USERS]);
        this.updatePolicy();
    }

	updatePolicy() {
        this._policy[PolicyEvents.MANAGE_TEAM_MEMBER_DISTRICT_ASSIGNMENT] =
            function (allowedContexts, userBehaviors, isFinFacilitator) {
                return !isFinFacilitator && verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to impersonate
        this._policy[PolicyEvents.IMPERSONATE] =
            function (allowedContexts, userBehaviors) {
                return !AccountUtility.isDistrictUser() &&
                    verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
        };

        //must have MANAGE to DELETE
        this._policy[PolicyEvents.DELETE] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
        };

        //must have MANAGE to MODIFY_BEHAVIORS
        this._policy[PolicyEvents.MODIFY_BEHAVIORS] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };

        //must have MANAGE to MODIFY_BEHAVIORS
        this._policy[PolicyEvents.ASSIGN_INSTITUTION] =
            function (allowedContexts, userBehaviors) {
                return verifyAccessToContextAndClaim(allowedContexts, [ behaviors.MANAGE ], userBehaviors);
            };
    }
}

export {UserPolicy};
