import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as sppActions from "../../actions/sppActions";
import * as sppAdminActions from "../../actions/sppAdminActions";
import * as sppUtilities from "../spp/SppUtilities";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import RadioField from "../common/inputs/RadioField";
import {inputStyles} from "../../constants/inputConstants";
import {
    DefaultPolicyResponseCopyStrategy,
    PolicyResponseCopyStrategyList
} from "../../constants/spp/policyResponseCopyStrategy";
import FormTable from "../common/FormTable";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {catchError} from "../../actions/actionUtility";
import * as sppLocations from "../../constants/spp/sppLocations";
import {deepCopyArray} from "../common/commonUtilities";

class SppAdminDocumentResponseCopyStrategy extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            sectionCopyConfigs: []
        };

        this.initSectionCopyConfigs = this.initSectionCopyConfigs.bind(this);
        this.onChangeCopyStrategy = this.onChangeCopyStrategy.bind(this);
        this.saveCopyStrategy = this.saveCopyStrategy.bind(this);
        this.cancel = this.cancel.bind(this);
        this.redirectUser = this.redirectUser.bind(this);

    }

    componentDidMount() {
        if(this.props.selectedDocument.id ){
            this.initSectionCopyConfigs();
        }
    }

    componentDidUpdate(prevProps) {
        if( this.props.selectedDocument.id  && prevProps.selectedDocument.id !== this.props.selectedDocument.id){
            this.initSectionCopyConfigs();
        }
    }

    initSectionCopyConfigs(){
        let sectionCopyConfigs = deepCopyArray(this.props.selectedDocument.sectionCopyConfigurations);
        this.setState({sectionCopyConfigs: sectionCopyConfigs});
    }

    onChangeCopyStrategy(partId, sectionId, propertyToToUpdate, event){
            event.preventDefault();

            this.props.setDataChanged(true);

            let updatedState = [...this.state.sectionCopyConfigs];
            let updatedCopyStrategy = updatedState.find(d => d.partId === partId && d.sectionId === sectionId);

            if (updatedCopyStrategy) {
                updatedCopyStrategy[propertyToToUpdate] = event.target.value;
            }

            this.setState({sectionCopyConfigs: updatedState});
    }

    saveCopyStrategy(event) {
        event.preventDefault();

            this.props.actions.saveCopyStrategy(this.props.selectedDocument.id, this.state.sectionCopyConfigs)
                .then(() => {
                    this.props.setDataChanged(false);
                    this.props.actions.loadSppDocument(this.props.selectedDocument.id);
                })
                .catch(error => {
                    catchError(error);
                });
    }

    cancel(event) {
        event.preventDefault();
        this.props.setDataChanged(false);
        this.redirectUser();
    }

    redirectUser() {
         this.props.history.push(sppLocations.ADMIN_COPY.path);
    }

    render() {
        if (!this.props.params ||
            this.props.documentElements.length === 0 ||
            !this.props.selectedDocument.id ||
            this.state.sectionCopyConfigs.length === 0 ||
            !this.props.params.partId
        )
            return null;

        const {partId} = this.props.params;

        const selectSectionItems = sppUtilities.getSectionTitles(this.props.selectedDocument, this.props.documentElements, partId)

        let buttonBar =
            (
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                   <Button onClick={this.saveCopyStrategy}
                            label={"Save"}
                            name="btnSaveUser"
                            buttonType={ButtonTypes.SUBMIT}
                   />

                    <Button onClick={this.cancel}
                            label="Cancel"
                            buttonType={ButtonTypes.SYSTEM}
                            name="btnCancel"/>
                </ButtonBar>
            );

        return (
            <div>
                {
                    selectSectionItems.length > 0 &&
                    <FormTable>
                        {
                            selectSectionItems.map((section, key)  => {
                                    const copyStrategyObject =  this.state.sectionCopyConfigs.find(x => x.partId === partId && x.sectionId === section.value);
                                    const copyStrategy = !copyStrategyObject ? DefaultPolicyResponseCopyStrategy : copyStrategyObject.copyStrategy;
                                    return (
                                        <div key={key}>
                                            <HtmlDisplayBlock html={section.text}  className={`bold`} />
                                            <RadioField
                                                direction={inputStyles.radio.direction.VERTICAL}
                                                disabled={false}
                                                legend={`Policy response copy strategy for section ${section.value}`}
                                                name={`${section.value}`}
                                                onChange={event => this.onChangeCopyStrategy(partId, section.value, "copyStrategy", event)}
                                                options={PolicyResponseCopyStrategyList}
                                                showLegend={false}
                                                value={copyStrategy}
                                                wrapperClass={`policyResponse-copyStrategy`}
                                            />
                                        </div>
                                    );
                                }
                            )
                        }
                    </FormTable>
                }

                {buttonBar}
            </div>
        );

    }
}

SppAdminDocumentResponseCopyStrategy.propTypes = {
    actions: PropTypes.object.isRequired,
    documentElements: PropTypes.array,
    params: PropTypes.object.isRequired,
    selectedDocument: PropTypes.object,
    history: PropTypes.object.isRequired,
    setDataChanged: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        documentElements: state.spp.documentElements,
        selectedDocument: state.spp.selectedDocument
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        sharedDataActions,
        sppActions,
        sppAdminActions
    );

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SppAdminDocumentResponseCopyStrategy);