import React from 'react';
import PropTypes from 'prop-types';

const PrintSectionHeader = ({
                                children,
                            }) => {
    return (
        <div className={`section--header`}>
            {children}
        </div>
    );
};

PrintSectionHeader.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
    ])
};

export default PrintSectionHeader;