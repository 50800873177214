import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import {isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import {TextField} from "../../../../components/common/inputs";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject,
    inputFormIsValid
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const PtsManageGmsUserTableRowEdit = ({
                                                 handleCancelGmsUserClick,
                                                 handleSaveGmsUser,
                                                 isNewGmsUser = false,
                                                 gmsUserForm
                                             }) => {
    const isValidForm = () => {
        clearInputFormErrorFields(gmsUserForm);

        if (isTrimmedStringEmpty(gmsUserForm.firstName.value.toString()))
            gmsUserForm.firstName.setError("Required");

        if (isTrimmedStringEmpty(gmsUserForm.lastName.value.toString()))
            gmsUserForm.lastName.setError("Required");

        if (isTrimmedStringEmpty(gmsUserForm.email.value.toString()))
            gmsUserForm.email.setError("Required");

        let isValid = inputFormIsValid(gmsUserForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleSaveGmsUserClick = async (event) => {
        event.preventDefault();
        if (!isValidForm())
            return;

        const gmsUser = convertFormInputObjectToObject(gmsUserForm);

        await handleSaveGmsUser(gmsUser);
    };

    const cancelBtnLabelDetails = isNewGmsUser ? `adding` : `editing`;
    return (
        <tr>
            <td>
                <TextField
                    {...gmsUserForm.firstName}
                    label={`First Name`}
                    showLabel={false}
                />
            </td>
            <td>
                <TextField
                    {...gmsUserForm.lastName}
                    label={`Last Name`}
                    showLabel={false}
                />
            </td>
            <td>
                <TextField
                    {...gmsUserForm.email}
                    label={`Email`}
                    showLabel={false}
                />
            </td>
            <td className={`no-wrap`}>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={`Save GMS user for ${gmsUserForm.email.value}`}
                    name={`btnSaveGmsUser_${gmsUserForm.gmsUserId.value}`}
                    onClick={handleSaveGmsUserClick}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.CANCEL}
                    label={`Cancel ${cancelBtnLabelDetails} of GMS user for ${gmsUserForm.email.value}`}
                    name={`btnCancelGmsUser_${gmsUserForm.gmsUserId.value}`}
                    onClick={handleCancelGmsUserClick}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

PtsManageGmsUserTableRowEdit.propTypes = {
    gmsUserForm: PropTypes.object.isRequired,
    handleCancelGmsUserClick: PropTypes.func.isRequired,
    handleSaveGmsUser: PropTypes.func.isRequired,
    isNewGmsUser: PropTypes.bool
};