import React from "react";
import PropTypes from 'prop-types';
import RadioField from "../common/inputs/RadioField";
import {inputStyles} from '../../constants/inputConstants';

const MatrixOfServiceRadioAdapter = ({name, index, value, onResponseChange, options, protocolId, isEditing, studentRecordId, legend}) => {
    return (
        <>
            {
                isEditing &&
                <RadioField
                    name={`rb_${name}_${index}_${studentRecordId}`}
                    options={options}
                    direction={inputStyles.radio.direction.HORIZONTAL}
                    onChange={(event) => onResponseChange(event, protocolId, name, studentRecordId)}
                    value={value}
                    legend={legend}
                    showLegend={false}
                />
            }
            {
                !isEditing &&
                <div>
                    {value}
                </div>
            }
        </>
    );
};

MatrixOfServiceRadioAdapter.propTypes = {
    name: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    protocolId: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    studentRecordId: PropTypes.string.isRequired,
    legend: PropTypes.string.isRequired
};

export default MatrixOfServiceRadioAdapter;