import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {reports as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";
import {mockResolveApiCall} from "../../../api/ApiUtility";
import {getReportData} from "../bpieMockData";
import {BPIE_REPORT_ROOT} from "../bpieLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerBpieReportsApi {
    static getBpieIndicatorPriorityReportData(dateWindowId, districtId){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(`${config.apiUrl}${BPIE_REPORT_ROOT}/indicatorPriorityReport/dates/${dateWindowId}${districtId ? `/districts/${districtId}` : ``}`,
                RequestTypes.GET,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }

    static getSchoolSummaryData(districtId, schoolNumber){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(`${config.apiUrl}${BPIE_REPORT_ROOT}/schoolSummaryData/districts/${districtId}/school/${schoolNumber}`,
                RequestTypes.GET,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }

    static getSchoolStatusTrendReport(districtId, schoolNumber){
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}${BPIE_REPORT_ROOT}/schoolStatusTrendReport/districts/${districtId}/school/${schoolNumber}`,
                RequestTypes.GET,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }

    static getSchoolStatusPriorityReport(districtId, schoolNumber){
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}${BPIE_REPORT_ROOT}/schoolStatusPriorityReport/districts/${districtId}/school/${schoolNumber}`,
                RequestTypes.GET,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }

    static getBpieDistrictSummaryReport(dateWindowId, districtId){
        return ApiUtility.downloadBlob(
            AjaxRequestOptions(`${config.apiUrl}${BPIE_REPORT_ROOT}/districtSummaryReport/dates/${dateWindowId}/districts/${districtId}`,
                RequestTypes.GET,
                ApiSuccessMessages.REPORT_DOWNLOADED));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockBpieReportsApi {
    static getBpieIndicatorPriorityReportData() {
        return mockResolveApiCall(getReportData());
    }

    static getSchoolSummaryData(){
        return mockResolveApiCall({});
    }

    static getSchoolStatusTrendReport(){
        return mockResolveApiCall({});
    }

    static getSchoolStatusPriorityReport(){
        return mockResolveApiCall({});
    }

    static getBpieDistrictSummaryReport(){
        return mockResolveApiCall({});
    }
}


const BpieReportApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerBpieReportsApi : MockBpieReportsApi;
export default BpieReportApi;