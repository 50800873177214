import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {UserSearchCriteria} from "../../../../components/common/searchCriterias/userSearchCriteria";
import userApi from "../../../../api/UserApi";
import gmsApi from "../../gmsApi";
import {createGmsTeamMemberList} from "../../gmsObjectFactory";
import gmsAdminTeamMembersApi from "./gmsAdminTeamMembersApi";
import GmsAdminTeamMembersEdit from "./GmsAdminTeamMembersEdit";
import {dynamicSort, isNullOrUndefined, arraysOfObjectsEqual} from "../../../../components/common/commonUtilities";
import  * as userConstants from "../../../../constants/users/userConstants";
import * as behaviors from "../../../../constants/behaviors";
import * as contexts from "../../../../constants/contexts";

const GmsAdminTeamMembersContainer = ({
                                          actions,
                                          dataChanged,
                                          handleDataChanged,
                                      }) => {
    const [fullListOfUsersSorted, setFullListOfUsersSorted] = useState(null);
    const [teamMembers, setTeamMembers] = useState(null);
    const [stateUsers, setStateUsers] = useState(null);
    const [selectedGmsRole, setSelectedGmsRole] = useState(1);

    const handleSaveTeamMembers = (selectedTeamMemberUserIds) => {
        actions.executeApi(gmsAdminTeamMembersApi.saveTeamMembers, [{userIds: selectedTeamMemberUserIds, role: selectedGmsRole}])
            .then((result) => {
                handleDataChanged(false);
                setTeamMembers(result);
            });
    };

    useEffect(() => {
        gmsApi.getTeamMembers(true).then(setTeamMembers);

        let userSearchCriteria = new UserSearchCriteria("", `${contexts.GMS}.${behaviors.EDIT}`, userConstants.USER_TYPES.State, userConstants.STATUS.ACTIVE);
        userSearchCriteria.RecordsPerPage = 0;
        userSearchCriteria.SortByColumn = "LastName";
        userApi.getUsers(userSearchCriteria).then((result) => setStateUsers(result.data));
    }, []);

    const buildUniqueCombinedList = () => {
        const nonActiveDoeUsers = teamMembers.filter(f => f.role === selectedGmsRole).map((teamMember) => {
            const userExists = stateUsers.find(f => f.userId === teamMember.userId);
            if(userExists)
                return;

            teamMember.id = null;
            return teamMember;
        });

        const allUsers = [...nonActiveDoeUsers.filter(f => !isNullOrUndefined(f)), ...stateUsers];

        const newListSortedUsers = allUsers.sort(dynamicSort("lastName", false, "firstName"))

        if (!arraysOfObjectsEqual(newListSortedUsers, fullListOfUsersSorted))
            setFullListOfUsersSorted(newListSortedUsers);
        else
            handleDataChanged(false);
    };

    useEffect(() => {
        if(!teamMembers || !stateUsers)
            return;

        buildUniqueCombinedList();
    }, [teamMembers, stateUsers, selectedGmsRole]);

    const handleChangeGmsRole = (gmsRole) => {
        setSelectedGmsRole(parseInt(gmsRole));
    };

    if(!teamMembers || !stateUsers || !fullListOfUsersSorted)
        return null;

    return <GmsAdminTeamMembersEdit
        dataChanged={dataChanged}
        fullListOfUsersSorted={createGmsTeamMemberList(fullListOfUsersSorted)}
        handleChangeGmsRole={handleChangeGmsRole}
        handleDataChanged={handleDataChanged}
        handleSaveTeamMembers={handleSaveTeamMembers}
        selectedGmsRole={selectedGmsRole}
        selectedTeamMemberUserIds={teamMembers.filter(f => f.role === selectedGmsRole).map(i => i.userId)}
        stateUsers={createGmsTeamMemberList(stateUsers)}
    />;
};

GmsAdminTeamMembersContainer.propTypes = {
    actions: PropTypes.object,
    dataChanged: PropTypes.bool,
    handleDataChanged: PropTypes.func.isRequired,
};

export default GmsAdminTeamMembersContainer;
