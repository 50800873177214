import React from 'react';
import PropTypes from 'prop-types';
import ButtonMock from "../../components/common/buttons/ButtonMock";
import * as ButtonTypes from "../../constants/ButtonTypes";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

const Notification = ({helpText, error}) => {

    if (!helpText && !error)
        return;

    const className = helpText ? "helpText" : "notification";
    const buttonType = helpText ? ButtonTypes.HELPTEXT : ButtonTypes.NOTIFICATION;

    return <>
        <div aria-haspopup="true"
             className={className}
             data-disable-hover="false">
            <ButtonMock
                addToTabbing
                label=""
                buttonType={buttonType}
            />
            <HtmlDisplayBlock html={helpText || error} className={`${className}__message`}/>
        </div>
    </>;
};

Notification.propTypes = {
    error: PropTypes.string,
    helpText: PropTypes.string,
};

export default Notification;