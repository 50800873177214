import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import ActionBar from "../../../components/common/buttons/ActionBar";
import AuditHistory from "../../../components/audit/AuditHistory";
import AuditBar from "../../../components/common/buttons/AuditBar";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FormTable from "../../../components/common/FormTable";
import {IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME} from "../../../constants/AuditConstants";
import DrStateComplaintResponseComponent from "./DrStateComplaintResponseComponent";
import DrStateComplaintResponseReview from "./DrStateComplaintResponseReview";
import {newComment, outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {deepCopyArray, isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import {
	StateComplaintPolicy,
	policyEvents
} from "../../../components/authorization/policies/StateComplaintPolicy";
import audit_outcomes from "../../../constants/auditOutcomes";
import AccountUtility from "../../../api/AccountUtility";
import * as ActionBarPositions from "../../../constants/ActionBarPositions";
import * as apiParameters from "../../../constants/api/apiParameters";

const DrStateComplaintResponseActionDeadline = ({
	                                                           audits,
	                                                           canAudit,
	                                                           canEdit,
	                                                           deadline,
	                                                           editDeadlineId,
	                                                           handleAuditResponseAsync,
	                                                           handleSaveAuditCommentAsync,
	                                                           handleSaveResponseAsync,
	                                                           handleSaveResponseReviewAsync,
	                                                           handleSubmitResponseAsync,
	                                                           issueId,
	                                                           setEditDeadlineId
                                                           }) => {

	const [updatedAudits, setUpdatedAudits] = useState([]);
	const [editAuditId, setEditAuditId] = useState('');
	const [isDistrictUser] = useState(AccountUtility.isDistrictUser());
	const [isInEditMode, setIsInEditMode] = useState(false);

	const clearEditMode = () => {
		setEditDeadlineId('');
		setEditAuditId('');
		setIsInEditMode(false);
	};

	const handleClickEdit = (deadlineId) => {
		setEditDeadlineId(deadlineId);
		setIsInEditMode(true);
	};

	const handleCreateComment = (auditId) => {
		let tempAudits = deepCopyArray(updatedAudits);
		let audit = tempAudits.filter(a => a.auditId === auditId)[0];
		audit.comments.unshift(newComment(auditId));
		setUpdatedAudits(tempAudits);
		setIsInEditMode(true);
	};

	const handleEditComment = (commentId) => {
		setEditAuditId(commentId);
		setIsInEditMode(true);
	};

	const handleCancelComment = () => {
		setUpdatedAudits(deepCopyArray(audits));
		clearEditMode();
	};

	const handleChangeComment = (event, updateComment) => {
		event.preventDefault();
		let tempAudits = deepCopyArray(updatedAudits);

		const fieldUpdated = event.target.name;
		const updatedValue = event.target.value;

		let updatedComment = tempAudits.find(a => a.auditId === updateComment.auditId)
			.comments
			.find(c => c.auditCommentId === updateComment.auditCommentId);

		if(fieldUpdated === IS_VISIBLE_TO_DISTRICT_FORM_FIELD_NAME)
			updatedComment.isVisibleToDistrict = updatedValue;
		else
			updatedComment.text = updatedValue;

		setUpdatedAudits(tempAudits);
	};

	const handleSaveComment = async (event, comment, responseId) => {
		event.preventDefault();
		const isSuccess = await handleSaveAuditCommentAsync(responseId, deadline, issueId, comment);

		if (isSuccess) {
			clearEditMode();
		}
	};

	const returnAuditClickHandler = (handler, action) => {
		return handler
			? (event, auditAction = action) => {
				handler(deadline.response, deadline, issueId, auditAction);
			}
			: undefined;
	};

	const handleClickAudit = returnAuditClickHandler(handleAuditResponseAsync);

	const handleClickSubmit = returnAuditClickHandler(handleSubmitResponseAsync, apiParameters.audit.SUBMIT);

	const handleClickCancel = () => {
		clearEditMode();
	};

	const handleChangeOutcome = handleClickAudit
		? (event) => {
			event.preventDefault();
			const auditOutcome = event.target.id.split("_")[0];

			switch (auditOutcome) {
				case audit_outcomes.ACCEPTED.name:
					handleClickAudit(event, apiParameters.audit.APPROVE);
					break;
				case audit_outcomes.NEEDS_REVISION.name:
					handleClickAudit(event, apiParameters.audit.REVISE);
					break;
				case audit_outcomes.RESET.name:
					handleClickAudit(event, apiParameters.audit.RESET);
					break;
			}
		}
		: undefined;

	useEffect(() => {
		setUpdatedAudits(deepCopyArray(audits));
	}, [audits]);

	return (
		<FormTable className={`actionResponse__deadline`}>
			<DisplayField
				label="Date Due"
				name={`dateDue${deadline.id}`}
				showLabel
			>
				{outputStandardDateFormat(deadline.overallDateDue, false)}
			</DisplayField>

			<div className={`actionBar--shiftOut`}>
				{
					!isArrayNullOrEmpty(updatedAudits) &&
					<AuditBar
						disabled={isInEditMode}
						elementId={`deadline-${deadline.id}`}
						onOutcomeChange={handleChangeOutcome}
						outcome={deadline.response.currentStatus}
						policy={StateComplaintPolicy}
						policyEvent={policyEvents.stateComplaint.response.AUDIT}
						position={ActionBarPositions.RIGHT_TOP}
					/>
				}
				{
					canEdit &&
					deadline.id !== editDeadlineId &&
					deadline.response.currentStatus !== audit_outcomes.ACCEPTED.value &&
					<ActionBar
						disabled={isInEditMode}
						elementId={`deadline-${deadline.id}`}
						onEditClick={() => handleClickEdit(deadline.id)}
						onSubmitClick={handleClickSubmit}
						position={ActionBarPositions.RIGHT_BOTTOM}
					/>
				}
				<DrStateComplaintResponseComponent
					deadline={deadline}
					editDeadlineId={editDeadlineId}
					handleClickCancel={handleClickCancel}
					handleSaveResponseAsync={handleSaveResponseAsync}
					isDistrictUser={isDistrictUser}
					issueId={issueId}
				/>

				<DrStateComplaintResponseReview
					deadline={deadline}
					editDeadlineId={editDeadlineId}
					handleClickCancel={handleClickCancel}
					handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
					isDistrictUser={isDistrictUser}
					issueId={issueId}
				/>
			</div>

			{
				!isDistrictUser &&
				canAudit &&
				<AuditHistory
					audits={updatedAudits}
					editItemId={editAuditId}
					isInEditMode={isInEditMode}
					isLoading={false}
					onCommentCreate={handleCreateComment}
					onCommentCancel={handleCancelComment}
					onCommentEdit={handleEditComment}
					onCommentSave={handleSaveComment}
					handleCommentChange={handleChangeComment}
					parentIdPropName={`responseId`}
				/>
			}
			{
				(isDistrictUser || !canAudit) &&
				<AuditHistory
					audits={updatedAudits}
				/>
			}
		</FormTable>
	);
};

DrStateComplaintResponseActionDeadline.propTypes = {
	audits: PropTypes.array,
	canAudit: PropTypes.bool.isRequired,
	canEdit: PropTypes.bool.isRequired,
	deadline: PropTypes.object.isRequired,
	editDeadlineId: PropTypes.string.isRequired,
	handleAuditResponseAsync: PropTypes.func,
	handleSaveAuditCommentAsync: PropTypes.func,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired,
	handleSubmitResponseAsync: PropTypes.func,
	issueId: PropTypes.string,
	setEditDeadlineId: PropTypes.func.isRequired,
};

export default DrStateComplaintResponseActionDeadline;