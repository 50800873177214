import * as ButtonTypes from "../../../constants/ButtonTypes";
import audit_outcomes from "../../../constants/auditOutcomes";

export function getOutcomeButtonStyleFromOutcome(outcome) {
	if (outcome === audit_outcomes.ACCEPTED.value)
		return ButtonTypes.REVIEWED;
	if (outcome === audit_outcomes.NEEDS_REVISION.value)
		return ButtonTypes.NEEDS_REVISIONS;
	if (outcome === audit_outcomes.SUBMITTED.value)
		return ButtonTypes.NEEDS_REVIEW;
	return ButtonTypes.NEEDS_REVIEW;
}

export function getOutcomeButtonTitleFromOutcome(outcome) {
	if (outcome)
		return outcome;

	return audit_outcomes.NOT_SUBMITTED;
}