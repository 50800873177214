import {getPropName} from "../common/commonUtilities";

export const isNoteTypeReadOnly = (readOnlyCaseNoteTypes, noteType) => {
    if(!readOnlyCaseNoteTypes) return false;
    return !!getPropName(readOnlyCaseNoteTypes, noteType);
};

export const isNoteTypeOtherAssociatedArea = (otherAreaCaseNoteTypes, noteType) => {
    if(!otherAreaCaseNoteTypes) return false;
    return !!getPropName(otherAreaCaseNoteTypes, noteType);
};