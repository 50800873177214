function createBaseCaseResearchFilterObject(searchCriteria) {
    return {
        dateWindowId: searchCriteria.dateWindowId,
        startDate: searchCriteria.startDate,
        endDate: searchCriteria.endDate,
        districts: searchCriteria.districtMulti,
        statuses: searchCriteria.statusMulti,
        teamMembers: searchCriteria.teamMemberMulti
    };
}

export function createStateComplaintCaseResearchFilterObject(searchCriteria) {
    return {
        ...createBaseCaseResearchFilterObject(searchCriteria),
        roles: searchCriteria.teamMemberRoleMulti,
        outcomes: searchCriteria.outcomeFindingsMulti,
        issuesCodes: searchCriteria.issuesCodeMulti,
        citations: searchCriteria.citationMulti,
        advocateAttorney: searchCriteria.advocateAttorney,
        enrollmentLocation: searchCriteria.enrollmentLocation,
        systemic: searchCriteria.systemic,
        tiedToDueProcessMediation: searchCriteria.tiedToDueProcessMediation
    };
}

export function createDueProcessCaseResearchFilterObject(searchCriteria) {
    return {
        ...createBaseCaseResearchFilterObject(searchCriteria),
        alj: searchCriteria.assignedALJ,
        complaintType: searchCriteria.complaintType,
        finalOrderIssues: searchCriteria.finalOrderIssue,
        changeOfPlacement: searchCriteria.changeOfPlacement,
        parentRepresentationStatus: searchCriteria.parentRepresentationStatus,
        prevailingParty: searchCriteria.prevailingParty,
        resolutionSessionOutcome: searchCriteria.resolutionSessionOutcome
    };
}

export function createMediationCaseResearchFilterObject(searchCriteria) {
    return {
        ...createBaseCaseResearchFilterObject(searchCriteria)
    };
}