import React from "react";
import {Switch} from "react-router-dom";
import * as contexts from "../../constants/contexts";
import EnsureLoggedIn from "../../containers/account/EnsureLoggedIn";
import withTracker from "../../withTracker";
import PrintLayout from "../layout/PrintLayout";
import * as gmsLocations from "./gmsLocations";
import GmsAdminWrapper from "./Admin/GmsAdminWrapper";
import GmsWrapper from "./GmsWrapper";
import GmsSummaryContainer from "./Summary/GmsSummaryContainer";
import GmsApplicationOverviewContainer from "./Application/ApplicationOverview/GmsApplicationOverviewContainer";
import GmsApplicationBudgetContainer from "./Application/ApplicationBudget/GmsApplicationBudgetContainer";
import GmsApplicationDeliverablesContainer
    from "./Application/ApplicationDeliverables/GmsApplicationDeliverablesContainer";
import GmsCaseNotesContainer from "./CaseNotes/GmsCaseNotesContainer";
import GmsAdminTeamMembersContainer from "./Admin/TeamMembers/GmsAdminTeamMembersContainer";
import GmsManageApplicationsContainer from "./Admin/ManageApplications/GmsManageApplicationsContainer";
import GmsManageDiscretionaryProjectsContainer
    from "./Admin/ManageDiscretionaryProjects/GmsManageDiscretionaryProjectsContainer";
import GmsManageFiscalAgentsUsersContainer
    from "./Admin/ManageDiscretionaryProjects/ManageFiscalAgentsUsers/GmsManageFiscalAgentsUsersContainer";
import GmsApplicationSettingsContainer from "./Application/ApplicationSettings/GmsApplicationSettingsContainer";
import GmsApplicationPrintSetupContainer from "./Application/ApplicationPrintSetup/GmsApplicationPrintSetupContainer";
import GmsApplicationPrintContainer from "./Application/ApplicationPrint/GmsApplicationPrintContainer";
import GmsApplicationFormContainer from "./Application/ApplicationForm/GmsApplicationFormContainer";
import GmsApplicationAmendmentSetupContainer
    from "./Application/ApplicationAmendmentSetup/GmsApplicationAmendmentSetupContainer";
import GmsApplicationSalaryContainer from "./Application/ApplicationSalary/GmsApplicationSalaryContainer";
import GmsApplicationSalaryEditContainer from "./Application/ApplicationSalary/GmsApplicationSalaryEditContainer";

const GmsRoutes = () => {

    return (
        <Switch>
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_SUMMARY.path}
                component={withTracker(GmsSummaryContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_BUDGET.path}
                component={withTracker(GmsApplicationBudgetContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_DELIVERABLES.path}
                component={withTracker(GmsApplicationDeliverablesContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_SALARIES.path}
                component={withTracker(GmsApplicationSalaryContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_SALARIES_EDIT.path}
                component={withTracker(GmsApplicationSalaryEditContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_SECTION.path}
                component={withTracker(GmsApplicationFormContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION.path}
                component={withTracker(GmsApplicationOverviewContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_CASE_NOTES.path}
                component={withTracker(GmsCaseNotesContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_PRINT_SETUP.path}
                component={withTracker(GmsApplicationPrintSetupContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_APPLICATION_AMENDMENT_SETUP.path}
                component={withTracker(GmsApplicationAmendmentSetupContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_MODIFY_APPLICATION_SETTINGS.path}
                component={withTracker(GmsApplicationSettingsContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_TEAM_MEMBERS.path}
                component={withTracker(GmsAdminTeamMembersContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_MANAGE_APPLICATIONS.path}
                component={withTracker(GmsManageApplicationsContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_MANAGE_FISCAL_AGENTS_USERS.path}
                component={withTracker(GmsManageFiscalAgentsUsersContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsAdminWrapper}
            />
            <EnsureLoggedIn
                authorizationRequired
                exact
                path={gmsLocations.GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS.path}
                component={withTracker(GmsManageDiscretionaryProjectsContainer)}
                context={[contexts.GMS]}
                wrapperPage={GmsAdminWrapper}
            />
            <PrintLayout
                path={gmsLocations.GMS_APPLICATION_PRINT.path}
                component={withTracker(GmsApplicationPrintContainer)}
                context={[contexts.GMS]}
                authorizationRequired
                wrapperPage={GmsWrapper}
            />
            <PrintLayout
                path={gmsLocations.GMS_APPLICATION_HISTORY_PRINT.path}
                component={withTracker(GmsApplicationPrintContainer)}
                context={[contexts.GMS]}
                authorizationRequired
                wrapperPage={GmsWrapper}
            />
        </Switch>
    );
};

export default GmsRoutes;