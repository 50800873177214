import * as policyEvents from "../../../constants/policyEvents";
import * as behaviors from "../../../constants/behaviors";
import {verifyAccessToContext, verifyAccessToContextAndClaim, getCurrentContextFromUrl} from "../AuthorizationUtilities";
import LocalStorage from "../../shared/LocalStorage";

class DefaultPolicy {
    constructor(allowedContexts) {
        this._policy = {};
        this._allowedContexts = allowedContexts ? allowedContexts : getCurrentContextFromUrl();
        this._userBehaviors = LocalStorage.getBehaviorClaims();

        this.initializePolicy();
    }

    permit(policyEvent, isFinFacilitator = false) {
        if (!this._allowedContexts) return false;

        if (!policyEvent)
            return verifyAccessToContext(this._allowedContexts, this._userBehaviors);

        if (this._policy[policyEvent])
            return this._policy[policyEvent](this._allowedContexts, this._userBehaviors, isFinFacilitator);

        return false;
    }

    initializePolicy() {
        this._policy[policyEvents.VIEW] = function (allowedContexts, userBehaviors) {
            return verifyAccessToContextAndClaim(allowedContexts, [behaviors.VIEW, behaviors.EDIT, behaviors.CERTIFY, behaviors.MANAGE], userBehaviors);
        };
	    this._policy[policyEvents.CERTIFY] = function (allowedContexts, userBehaviors) {
		    return verifyAccessToContextAndClaim(allowedContexts, [behaviors.CERTIFY], userBehaviors);
	    };
	    this._policy[policyEvents.MANAGE] = function (allowedContexts, userBehaviors) {
		    return verifyAccessToContextAndClaim(allowedContexts, [behaviors.MANAGE], userBehaviors);
	    };
    }

}

export {DefaultPolicy, policyEvents};