import React from "react";
import PropTypes from "prop-types";
import CollapsibleContainer from "../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../components/common/CollapsibleTrigger";
import GridColumn from "../../components/common/GridColumn";
import {NotificationReminderType} from "../../constants/NotificationTypes";
import GeneralNotificationsView from "./GeneralNotificationsView";
import ReminderView from "./ReminderView";

export const NotificationsView = ({
                                      deleteNotification,
                                      getNotifications,
                                      notifications,
                                      saveNotification
                                  }) => {
    const notificationTrigger = <CollapsibleTrigger text={`Set Reminder Notification`}/>;

    if (!notifications) return null;

    return (
        <GridColumn>
            <CollapsibleContainer
                trigger={notificationTrigger}
                id={`notification-container`}
            >
                <ReminderView
                    deleteNotification={deleteNotification}
                    getNotifications={getNotifications}
                    reminders={notifications.filter(f => f.description === NotificationReminderType.Reminder)}
                    saveNotification={saveNotification}
                />

                <GeneralNotificationsView
                    notifications={notifications.filter(f => f.description !== NotificationReminderType.Reminder)}
                />
            </CollapsibleContainer>

        </GridColumn>
    );
};

NotificationsView.propTypes = {
    deleteNotification: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    notifications: PropTypes.array,
    saveNotification: PropTypes.func.isRequired
};

export default NotificationsView;
