import React from "react";
import PropTypes from 'prop-types';
import DateTimePickerField from "../../../components/common/inputs/DateTimePickerField";
import DisplayField from "../../../components/common/inputs/DisplayField";
import FieldsetLayout from "../../../components/common/inputs/FieldsetLayout";
import {outputStandardDateFormat} from "../../../components/shared/sharedDataUtilities";
import {getRole} from "../../drUtilities";


export const DrDueProcessFinalOrderActivityTimeline = ({
                                                           activityTimeline,
                                                           handleChangeCompletionDate,
                                                           isAbleToEditTimelineCompletionDate,
                                                           readOnly
                                                       }) => {

    const accessInformation = !isAbleToEditTimelineCompletionDate ? ` (${getRole(activityTimeline.roleResponsibleForCompletion).description} only)` : "";

    return (
        <FieldsetLayout
            legend={`${activityTimeline.activity}${accessInformation}`}
            showLegend
        >
            <DateTimePickerField
                name={`activityTimelineDue${activityTimeline.dueProcessActivityTimelineRequirementId}`}
                label="Due"
                showLabel
                value={activityTimeline.dueDate}
                disabled
                medium={6}
            />

            {
                activityTimeline.dueDateWarning &&
                <p><strong className={`color--alert`}>{activityTimeline.dueDateWarning}</strong></p>
            }
            <DateTimePickerField
                name={`activityTimelineCompleted${activityTimeline.dueProcessActivityTimelineRequirementId}`}
                label="Completed"
                showLabel
                value={activityTimeline.dateCompleted}
                onChange={(event) => handleChangeCompletionDate(activityTimeline.dueProcessActivityTimelineRequirementId, event.target.value)}
                medium={6}
                disabled={readOnly}
            />
            <DisplayField
                label={"Last Modified"}
                name={"DateModified"}
                showLabel
                columnsMedium={6}
            >{activityTimeline.dateModified ? outputStandardDateFormat(activityTimeline.dateModified) : ""}</DisplayField>
        </FieldsetLayout>
    );
};

DrDueProcessFinalOrderActivityTimeline.propTypes = {
    activityTimeline: PropTypes.object.isRequired,
    handleChangeCompletionDate: PropTypes.func.isRequired,
    isAbleToEditTimelineCompletionDate: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default DrDueProcessFinalOrderActivityTimeline;