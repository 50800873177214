import React from "react";
import PropTypes from 'prop-types';

export const DrDistrictSummaryView = ({
	                              ComponentResults,
								  handleClickDistrict,
	                              handleClickOrder,
	                              summary
                              }) => {

	return (
		<ComponentResults
			handleClickDistrict={handleClickDistrict}
			handleClickOrder={handleClickOrder}
			summary={summary}
		/>
	);
};

DrDistrictSummaryView.propTypes = {
	ComponentResults: PropTypes.object.isRequired,
	handleClickDistrict: PropTypes.func.isRequired,
	handleClickOrder: PropTypes.func.isRequired,
	summary: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrDistrictSummaryView;