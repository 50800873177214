import {createListItem, isArray, isNullOrUndefined} from "../../components/common/commonUtilities";
import {SiteSectionsForTeamMemberList} from "../../constants/config";
import * as contexts from "../../constants/contexts";

export const createStateUserList = (users) => {
    let list = [];
    if (isArray(users)) {
        users.map((user) => {
            list.push({
                ...createListItem(user.userId, user.fullName),
                lastName: user.lastName,
                firstName: user.firstName,
                claimValues: user.claimValues
            });
        });
    }
    return list;
}

export function getAllDistrictTeamMembers(districts, siteSection) {
    let list = [];

    if(!isArray(districts))
        return list;

    districts.map((district) => {
        if(!district.districtSiteSectionTeamMemberDtos ) return;

        const user = district.districtSiteSectionTeamMemberDtos.find(f => f.siteSection === siteSection);
        if(user)
            list.push(user);
    });
    return list;
}

export const buildTeamMembersObjectProperty = (districtId, siteSection) => {
    return `${districtId}_${siteSection}`;
}

export const buildTeamMembers = (district) => {
    let teamMembersObject = {};
    SiteSectionsForTeamMemberList.map((section) => {
        let currentAssignedUser = getAssignedUser(district.districtSiteSectionTeamMemberDtos, district.id, section.value);
        const propertyName = buildTeamMembersObjectProperty(currentAssignedUser.districtId, currentAssignedUser.siteSection);
        teamMembersObject[propertyName] = currentAssignedUser.userId;
    });

    return teamMembersObject;
}

export const getAssignedUser = (teamMembersList, districtId, section) => {
    const assignedUser = teamMembersList.find(f => f.siteSection === section && f.districtId === districtId);

    return !assignedUser ? createDistrictSiteSectionTeamMemberObject("", districtId, section) : assignedUser;
};

export const createDistrictSiteSectionTeamMemberObject = (userId = "", districtId = "", siteSection = "") => {
    return {
        userId,
        districtId,
        siteSection
    };
}

export function isAssignedToProgram(user) {
  return !isNullOrUndefined(user.behaviors)
    && user.behaviors.some(behavior => behavior.claimValue.startsWith(contexts.PTS) && behavior.enabled && behavior.selected);
}