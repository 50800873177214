import React from "react";
import PropTypes from 'prop-types';
import Button from "../../../../components/common/buttons/Button";
import {TextField} from "../../../../components/common/inputs";
import * as ButtonTypes from "../../../../constants/ButtonTypes";

export const GmsManageDiscretionaryProjectsTableRowEdit = ({
                                                               discretionaryProject,
                                                               handleCancelProjectClick,
                                                               handleSaveProjectClick,
                                                               isNewDiscretionaryProject = false
                                                           }) => {
    const cancelBtnLabelDetails = isNewDiscretionaryProject ? `adding` : `editing`;
    return (
        <tr>
            <td>
                <TextField
                    {...discretionaryProject.projectName}
                    label={`Project Name`}
                    name={`projectName`}
                    showLabel={false}
                />
            </td>
            <td>
                <Button
                    buttonType={ButtonTypes.SAVE}
                    label={`Save discretionary project for ${discretionaryProject.projectName.value}`}
                    name={`btnSaveProject_${discretionaryProject.discretionaryProjectId.value}`}
                    onClick={handleSaveProjectClick}
                    showLabel={false}
                />
                <Button
                    buttonType={ButtonTypes.CANCEL}
                    label={`Cancel ${cancelBtnLabelDetails} of discretionary project for ${discretionaryProject.projectName.value}`}
                    name={`btnCancelProject_${discretionaryProject.discretionaryProjectId.value}`}
                    onClick={handleCancelProjectClick}
                    showLabel={false}
                />
            </td>
        </tr>
    );
};

GmsManageDiscretionaryProjectsTableRowEdit.propTypes = {
    discretionaryProject: PropTypes.object.isRequired,
    handleCancelProjectClick: PropTypes.func.isRequired,
    handleSaveProjectClick: PropTypes.func.isRequired,
    isNewDiscretionaryProject: PropTypes.bool
};