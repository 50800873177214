import {STATUS, USER_TYPES} from "../../../constants/users/userConstants";
import {createYesNoObject} from "../inputs/inputUtility";
import {searchCriteria} from "./searchCriteria";

const yesNoObject = createYesNoObject(false);

export function UserSearchCriteria(institutionId = "", behaviors = "", userType = USER_TYPES.All, status = STATUS.ACTIVE, isSchoolAdminOnly = yesNoObject.No) {

    return Object.assign({}, searchCriteria("UserSearchCriteria", "Email"), {
        InstitutionId: institutionId,
        EmailContains: "",
        UsernameContains: "",
        Behaviors: behaviors,
        Status: status,
        IsSchoolAdminOnly: isSchoolAdminOnly,
        UserType: userType,
        clearOptionalSearchItems: ClearOptionalSearchItems
    });
}

function ClearOptionalSearchItems() {
    this.baseClearOptionalSearchItems();

    this.InstitutionId = "";
    this.EmailContains = "";
    this.UsernameContains = "";
    this.Behaviors = "";
    this.Status = "";
    this.IsSchoolAdminOnly = yesNoObject.No;
    this.UserType = USER_TYPES.All;
}
