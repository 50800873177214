import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from "../common/buttons/ActionBar";
import Allow from "../../components/authorization/Allow";
import { MonitoringCompliancePolicy, policyEvents } from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {createIndicatorTitle} from "./MonitoringComplianceUtilities";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";

const AssessmentIndicatorRequirement = ({
                                            indicatorRequirement,
                                            onDeleteClick,
                                            isEditing,
                                            isLoading,
                                            onEditClick
                                       }) => {
    return(
        <div className="compliance__requiredProtocol__indicator">
            {
                !isEditing &&
                <Allow policy={MonitoringCompliancePolicy}
                       policyEvent={policyEvents.monitoring_compliance.protocolRequirement.ACTION_BAR}>
                    <ActionBar
                        elementId={indicatorRequirement.indicatorRequirementId}
                        onDeleteClick={(event) => onDeleteClick(event, indicatorRequirement.indicatorRequirementId)}
                        onEditClick={(event) => onEditClick(event, indicatorRequirement)}
                        disabled={isLoading}
                    />
                </Allow>
            }
            <div>
                <p><strong>{createIndicatorTitle(indicatorRequirement.title, indicatorRequirement.publicIdentifier)}</strong></p>
                <HtmlDisplayBlock html={indicatorRequirement.indicator} />
                <p><strong>Required Records:</strong> {indicatorRequirement.studentRecordsRequirementCount}</p>
            </div>
        </div>
    );
};

AssessmentIndicatorRequirement.propTypes = {
    indicatorRequirement: PropTypes.object.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};

export default AssessmentIndicatorRequirement;