import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as layoutActions from "../../actions/layoutActions";
import * as stratPlanActions from "../../actions/strategicPlanActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import * as fileActions from "../../actions/fileActions";
import {catchError} from "../../actions/actionUtility";
import GridRow from "../../components/common/GridRow";
import GridColumn from "../../components/common/GridColumn";
import CheckBoxField from "../../components/common/inputs/CheckBoxField";
import Switch from "../../components/common/inputs/Switch";
import Strategy from "../../components/strategicPlan/Strategy";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import Allow from "../../components/authorization/Allow";
import {emptyGuid} from "../../constants/config";
import * as policyEvents from "../../constants/policyEvents";
import {StrategicPlanPolicy} from "../../components/authorization/policies/StrategicPlanPolicy";
import {NotifyUser} from "../../components/common/NotifyUser";
import * as indicatorTypes from "../../constants/indicatorTypes";
import TeamMembers from "../../components/strategicPlan/TeamMembers";
import TeamMemberForm from "../../components/strategicPlan/TeamMemberForm";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import {generatePageTitle} from "../../components/strategicPlan/strategicPlanUtilities";
import {generateNewId} from "../../components/common/IdGeneratorUtility";
import { getParams } from "../../components/layout/getParams";
import {activityPrefix, metricPrefix, teamPrefix} from "../../constants/strategicPlan/idPrefixes";
import * as strategicPlanLocations from "../../constants/strategicPlan/strategicPlanLocations";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import { scroller } from "react-scroll";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {gridConstants} from "../../constants/inputConstants";

export class StrategicPlanPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isTitleSet: false,
			params: {}
		};

		this.onFilterChange = this.onFilterChange.bind(this);
		this.onCancel = this.onCancel.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.deleteFile = this.deleteFile.bind(this);

		this.onAddStrategy = this.onAddStrategy.bind(this);
		this.onDeleteStrategy = this.onDeleteStrategy.bind(this);

		this.onEditMembers = this.onEditMembers.bind(this);
		this.onChangeMember = this.onChangeMember.bind(this);
		this.onSaveMembers = this.onSaveMembers.bind(this);
		this.onRemoveMember = this.onRemoveMember.bind(this);
		this.onCreateMember = this.onCreateMember.bind(this);

		this.onEditGoals = this.onEditGoals.bind(this);
		this.onChangeGoals = this.onChangeGoals.bind(this);
		this.onSaveGoals = this.onSaveGoals.bind(this);

		this.onAddIndicator = this.onAddIndicator.bind(this);
		this.onEditIndicator = this.onEditIndicator.bind(this);
		this.onSaveIndicator = this.onSaveIndicator.bind(this);

		this.onChangeIndicator = this.onChangeIndicator.bind(this);
		this.onDeleteIndicator = this.onDeleteIndicator.bind(this);
		this.strategyIndicatorIsValid = this.strategyIndicatorIsValid.bind(this);
		this.onCreateMetric = this.onCreateMetric.bind(this);
		this.onEditMetrics = this.onEditMetrics.bind(this);

		this.onAddAction = this.onAddAction.bind(this);
		this.onEditAction = this.onEditAction.bind(this);
		this.onChangeAction = this.onChangeAction.bind(this);
		this.onSaveAction = this.onSaveAction.bind(this);
		this.actionIsValid = this.actionIsValid.bind(this);
		this.onDeleteAction = this.onDeleteAction.bind(this);

		this.onAddSubAction = this.onAddSubAction.bind(this);
		this.onEditSubAction = this.onEditSubAction.bind(this);
		this.onChangeSubAction = this.onChangeSubAction.bind(this);
		this.onSaveSubAction = this.onSaveSubAction.bind(this);
		this.subActionIsValid = this.subActionIsValid.bind(this);
		this.onDeleteSubAction = this.onDeleteSubAction.bind(this);

		this.onAddActivity = this.onAddActivity.bind(this);
		this.onEditActivity = this.onEditActivity.bind(this);
		this.onChangeActivity = this.onChangeActivity.bind(this);
		this.onChangeActivityDate = this.onChangeActivityDate.bind(this);
		this.onSaveActivity = this.onSaveActivity.bind(this);
		this.onDeleteActivity = this.onDeleteActivity.bind(this);
		this.progressMonitoringActivityIsValid = this.progressMonitoringActivityIsValid.bind(this);

		this.onPrintClick = this.onPrintClick.bind(this);
	}

	componentDidMount() {
        const params = getParams(this.props.location.pathname, strategicPlanLocations.PLAN);

        if (params !== null)
        	this.setState({params});

		this.props.actions.loadStrategies(params.planDateWindowId);

		this.props.actions.loadPlanDateWindow(params.planDateWindowId);

		this.props.actions.loadAllDateWindows()
			.catch(error => catchError(error));

		this.props.actions.loadSppIndicators()
			.catch(error => catchError(error));

		this.props.actions.loadActionResources();

		this.props.actions.loadStrategicGoals();
	}

    componentDidUpdate(newProps) {
		if (!this.state.isTitleSet &&
			newProps.planDateWindow.planDateWindowId) {

			const pageTitle = generatePageTitle(newProps.dateWindows, newProps.planDateWindow.dateWindowId, newProps.planDateWindow.name);

			this.props.actions.updatePageTitle(pageTitle);

			this.setState({isTitleSet: true});
		}
	}

	componentWillUnmount() {
		this.props.actions.clearPlanData();
	}

	onPrintClick(event) {
		event.preventDefault();
		window.print();
	}

	onFilterChange(event) {
		let filterSettings = Object.assign({}, this.props.filterSettings);
		filterSettings[event.target.name] = event.target.checked;
		this.props.actions.updatePlanFilters(filterSettings);
	}

	scrollToPosition(positionName) {
		scroller.scrollTo(positionName);
	}

	onAddStrategy(event) {
		event.preventDefault();

		this.props.actions.addStrategy(this.state.params.planDateWindowId);
	}

	onDeleteStrategy(event, strategyId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this strategy?")) {
			this.props.actions.deleteStrategy(strategyId);
		}
	}

	onEditGoals(event, strategyId) {
		event.preventDefault();

		this.props.actions.editStrategyGoals(strategyId);
	}

	onAddIndicator(event, strategyId) {
		event.preventDefault();

		const newStrategyIndicatorId = emptyGuid;
		this.props.actions.createStrategyIndicator(strategyId, newStrategyIndicatorId);
	}

	onEditIndicator(event, strategyIndicatorId) {
		event.preventDefault();

		this.props.actions.editStrategyIndicator(strategyIndicatorId);
	}

	onSaveIndicator(event, strategyId, strategyIndicatorId) {
		event.preventDefault();

		const strategyWithAlteredStrategicIndicator = this.props.strategies.filter(s => s.id === strategyId)[0];
		const alteredStrategyIndicator = strategyWithAlteredStrategicIndicator.strategyIndicators.filter(si => si.id === strategyIndicatorId)[0];

		if (this.strategyIndicatorIsValid(alteredStrategyIndicator)) {
			this.props.actions.trySaveStrategicIndicator(alteredStrategyIndicator)
				.then(() => {
					if(strategyIndicatorId === emptyGuid)
						this.props.actions.loadStrategies(this.state.params.planDateWindowId);
					else
						this.scrollToPosition(strategyIndicatorId)
				})
				.catch(error => catchError(error));
		}
	}

	strategyIndicatorIsValid(strategyIndicator) {
		let isValid = true;

		const beessIndicator = strategyIndicator.indicators.filter(ind => ind.indicatorType === indicatorTypes.BEESS)[0];
		const sppIndicators = strategyIndicator.indicators.filter(ind => ind.indicatorType === indicatorTypes.SPP);
		if (beessIndicator.text === "" && sppIndicators.length === 0) {
			NotifyUser.Warning("A BEESS Indicator or SPP Indicator(s) is required");
			isValid = false;
		}

		return isValid;
	}

	onCreateMetric(event, strategyId, strategyIndicatorId) {
		event.preventDefault();

		const strategyWithAlteredStrategicIndicator = this.props.strategies.find(s => s.id === strategyId);
		const alteredStrategyIndicator = strategyWithAlteredStrategicIndicator.strategyIndicators.find(si => si.id === strategyIndicatorId);

		const metrics = alteredStrategyIndicator.metrics;
		const newMetricId = generateNewId(isArrayNullOrEmpty(metrics) ? [] : metrics, metricPrefix, alteredStrategyIndicator.id);

		this.props.actions.createMetric(strategyId, strategyIndicatorId, newMetricId);
	}

	onEditMetrics(event, baselineId) {
		event.preventDefault();

		this.props.actions.editMetrics(baselineId);
	}

	onCancel(event) {
		event.preventDefault();

		this.props.actions.loadStrategies(this.state.params.planDateWindowId);

		this.props.actions.loadPlanDateWindow(this.state.params.planDateWindowId);
	}

	onChangeIndicator(event, strategyId, strategyIndicatorId, metricId) {
		const changedStrategy = this.props.strategies.filter(s => s.id === strategyId)[0];
		const changedStrategyIndicator = changedStrategy.strategyIndicators.filter(si => si.id === strategyIndicatorId)[0];

		if (event.target.id === indicatorTypes.BEESS) {
			this.props.actions.updateIndicator(strategyIndicatorId, event.target.value);
		}
		else if (event.target.id === "updateSppIndicators") {
			const value = event.source.value;
			if (event.source.action === "add")
				this.props.actions.addIndicator(strategyIndicatorId, value);
			else if (event.source.action === "remove")
				this.props.actions.removeIndicator(strategyIndicatorId, value);
		}
		else if (event.target.id === "triangleFileId") {
			const files = event.target.files;

			this.props.actions.uploadFile(files, emptyGuid)
				.then((fileId) => {
					if(!fileId) return;
					this.props.actions.addIndicatorTriangleFile(strategyId, strategyIndicatorId, fileId);
				});
		}
		else if (event.target.id.split('|')[0].startsWith("baseline")) {
			if (event.target.id === "baseline.startYear" &&
				changedStrategyIndicator.metrics.length > 0 &&
				!confirm("Changing the baseline start year for this indicator will automatically update all metric years. Are you sure you want to update the baseline year?")) {
				return;
			}

			let changedBaselineMetric = Object.assign({}, changedStrategyIndicator.baseline);

			const changedBaselineField = event.target.id.split('|')[0].split('.')[1];

			changedBaselineMetric[changedBaselineField] = event.target.value;

			this.props.actions.updateBaselineMetric(strategyId, strategyIndicatorId, changedBaselineMetric);
		}
		else if (event.target.id.split('|')[0].startsWith("metric")) {
			let changedMetric = Object.assign({}, changedStrategyIndicator.metrics.filter(m => m.id === metricId)[0]);

			const changedMetricField = event.target.id.split('|')[0].split('.')[1];

			changedMetric[changedMetricField] = event.target.value;

			this.props.actions.updateMetric(strategyId, strategyIndicatorId, changedMetric);
		}
		else if (event.target.id === "hasMetTarget") {
			let targetMetMetric = Object.assign({}, changedStrategyIndicator.metrics.filter(m => m.startYear === this.props.planDateWindow.startYear.toString())[0]);
			targetMetMetric[event.target.id] = event.target.value;

			this.props.actions.updateMetric(strategyId, strategyIndicatorId, targetMetMetric);
		}
		else if (event.target.id === "targetVerificationComment") {
			let targetMetMetric = Object.assign({}, changedStrategyIndicator.metrics.filter(m => m.startYear === this.props.planDateWindow.startYear.toString())[0]);
			targetMetMetric[event.target.id] = event.target.value;

			this.props.actions.updateMetric(strategyId, strategyIndicatorId, targetMetMetric);
		}
	}

	onDeleteIndicator(event, strategyId, strategyIndicatorId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete this indicator along with all its data?")) {
			const strategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
			const deletedStrategyIndicator = strategy.strategyIndicators.filter(s => s.id === strategyIndicatorId)[0];

			if (deletedStrategyIndicator.triangleFileId !== null) {
				this.props.actions.tryDeleteIndicator(strategyId, strategyIndicatorId)
					.then(() => {
						this.props.actions.deleteFile(deletedStrategyIndicator.triangleFileId);
					})
					.catch(error => catchError(error));
			}
			else {
				this.props.actions.tryDeleteIndicator(strategyId, strategyIndicatorId)
					.catch(error => catchError(error));
			}
		}
	}

	onAddAction(event, strategyId) {
		event.preventDefault();

		this.props.actions.addStrategyAction(strategyId);
	}

	onEditAction(event, strategyActionId) {
		event.preventDefault();
		this.props.actions.editStrategyAction(strategyActionId);
	}

	onChangeAction(event, strategyId, strategyActionId) {
		event.preventDefault();

		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === strategyActionId)[0]);

		changedAction[event.target.id] = event.target.value;

		this.props.actions.updateStrategyAction(changedAction);
	}

	onSaveAction(event, strategyId, strategyActionId) {
		event.preventDefault();

		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const savedAction = [...changedStrategy.strategyActions].filter(sa => sa.id === strategyActionId)[0];

		if (this.actionIsValid(savedAction)) {
			if (savedAction.id === "")
				this.props.actions.addAction(savedAction);
			else
				this.props.actions.saveAction(savedAction)
					.then(this.scrollToPosition(strategyActionId));
		}
	}

	onDeleteAction(event, strategyId, strategyActionId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete the action?")) {
			this.props.actions.deleteAction(strategyId, strategyActionId);
		}
	}

	actionIsValid(action) {
		let isValid = true;
		if (action.notes === "") {
			isValid = false;
			NotifyUser.Warning("Progress Monitoring Process is required to save an action");
		}

		if (action.responsibleParty === "") {
			isValid = false;
			NotifyUser.Warning("Responsible Entity is required to save an action");
		}

		if (action.text === "") {
			isValid = false;
			NotifyUser.Warning("Action text is required to save an action");
		}
		else if (action.text.length < 5) {
			isValid = false;
			NotifyUser.Warning("Action text must be at least 5 characters in length");
		}

		return isValid;
	}

	onAddSubAction(event, strategyId, actionId) {
		event.preventDefault();

		this.props.actions.createSubAction(strategyId, actionId);
	}

	onEditSubAction(event, subActionId) {
		event.preventDefault();

		this.props.actions.editSubAction(subActionId);
	}

	onChangeSubAction(event, strategyId, actionId, subActionId) {
		event.preventDefault();
		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
		const changedSubAction = Object.assign({}, [...changedAction.subActions].filter(sa => sa.id === subActionId)[0]);

		changedSubAction[event.target.name] = event.target.value;

		this.props.actions.updateSubAction(actionId, changedSubAction);
	}

	onSaveSubAction(event, strategyId, actionId, subActionId) {
		event.preventDefault();
		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
		const savedSubAction = Object.assign({}, [...changedAction.subActions].filter(sa => sa.id === subActionId)[0]);

		if (this.subActionIsValid(savedSubAction)) {
			if (savedSubAction.id === "")
				this.props.actions.addSubAction(actionId, savedSubAction);
			else
				this.props.actions.saveSubAction(actionId, savedSubAction);
		}
	}

	onDeleteSubAction(event, strategyId, actionId, subActionId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete the sub-action including progress monitoring activities?")) {
			this.props.actions.deleteSubAction(strategyId, actionId, subActionId);
		}
	}

	subActionIsValid(subAction) {
		let isValid = true;

		if (subAction.text === "") {
			NotifyUser.Warning("Sub-action text is required to add a sub-action");
			isValid = false;
		}
		else if (subAction.text.length < 5) {
			isValid = false;
			NotifyUser.Warning("Sub-action text must be at least 5 characters in length");
		}

		if (subAction.responsibleParty === "") {
			NotifyUser.Warning("Sub-actions must have a responsible person");
			isValid = false;
		}

		return isValid;
	}

	onAddActivity(event, strategyId, actionId, subActionId) {
		event.preventDefault();

		const changedStrategy = [...this.props.strategies].find(s => s.id === strategyId);
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].find(sa => sa.id === actionId));
		const changedSubAction = Object.assign({}, [...changedAction.subActions].find(sa => sa.id === subActionId));
		const activities  = changedSubAction.progressMonitoringNoteEntries;

		const newProgressMonitoringNoteId = generateNewId(isArrayNullOrEmpty(activities) ? [] : activities, activityPrefix, subActionId);

		this.props.actions.createProgressMonitoringActivity(strategyId, actionId, subActionId, newProgressMonitoringNoteId);
	}

	onEditActivity(event, activityId) {
		event.preventDefault();

		this.props.actions.editProgressMonitoringActivity(activityId);
	}

	onChangeActivity(event, strategyId, actionId, subActionId, activityId) {

		event.preventDefault();

		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
		const changedSubAction = Object.assign({}, [...changedAction.subActions].filter(sa => sa.id === subActionId)[0]);
		const changedActivity = Object.assign({}, [...changedSubAction.progressMonitoringNoteEntries].filter(act => act.id === activityId)[0]);

		changedActivity[event.target.name] = event.target.value;

		this.props.actions.updateProgressMonitoringActivity(strategyId, actionId, subActionId, changedActivity);
	}

	onChangeActivityDate(event, strategyId, actionId, subActionId, activityId) {
		const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
		const changedSubAction = Object.assign({}, [...changedAction.subActions].filter(sa => sa.id === subActionId)[0]);
		const changedActivity = Object.assign({}, [...changedSubAction.progressMonitoringNoteEntries].filter(act => act.id === activityId)[0]);

		changedActivity[event.target.id] = event.target.value ? event.target.value : "";

		this.props.actions.updateProgressMonitoringActivity(strategyId, actionId, subActionId, changedActivity);
	}

	onSaveActivity(event, strategyId, actionId, subActionId, activityId) {
		event.preventDefault();

		const savedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
		const savedAction = Object.assign({}, [...savedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
		const savedSubAction = Object.assign({}, [...savedAction.subActions].filter(sa => sa.id === subActionId)[0]);
		const savedActivity = Object.assign({}, [...savedSubAction.progressMonitoringNoteEntries].filter(act => act.id === activityId)[0]);

		if (this.progressMonitoringActivityIsValid(savedActivity)) {
			this.props.actions.saveSubAction(actionId, savedSubAction);
		}
	}

	onDeleteActivity(event, strategyId, actionId, subActionId, activityId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete the Progress Monitoring Activity?")) {
			const changedStrategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];
			const changedAction = Object.assign({}, [...changedStrategy.strategyActions].filter(sa => sa.id === actionId)[0]);
			const changedSubAction = Object.assign({}, [...changedAction.subActions].filter(sa => sa.id === subActionId)[0]);

			changedSubAction.progressMonitoringNoteEntries = [...changedSubAction.progressMonitoringNoteEntries].filter(ne => ne.id !== activityId);

			this.props.actions.deleteProgressMonitoringActivity(strategyId, actionId, changedSubAction);
		}
	}

	progressMonitoringActivityIsValid(activity) {
		let isValid = true;

		if (activity.dateNoteCreated === "") {
			isValid = false;
			NotifyUser.Warning("Activity Date is required to save a Progress Monitoring Activity");
		}
		else if (!sharedDataUtilities.isDateValid(activity.dateNoteCreated)) {
			isValid = false;
			NotifyUser.Warning("Activity Date must be a date");
		}

		if (activity.text === "") {
			isValid = false;
			NotifyUser.Warning("Activity is required to save a Progress Monitoring Activity");
		}

		return isValid;
	}

	onSaveGoals(event, strategyId) {
		event.preventDefault();

		let strategy = [...this.props.strategies].filter(s => s.id === strategyId)[0];

		this.props.actions.saveStrategyGoals(strategyId, strategy.goals);
	}

	onChangeGoals(event, strategyId) {
		event.preventDefault();
		let goals = event.target.value;
		if (!isArrayNullOrEmpty(goals)) {
			goals = goals.map(goal => {
				return {
					id: '',
					text: goal
				};
			});
		}

		this.props.actions.updateStrategyGoals(strategyId, goals);
	}

	onEditMembers(event) {
		event.preventDefault();

		this.props.actions.editTeamMembers(this.state.params.planDateWindowId);
	}

	onChangeMember(event, teamMemberId) {
		event.preventDefault();

		const changedField = event.target.name.split('|')[0];
		let changedMember = Object.assign({}, this.props.planDateWindow.teamMembers.filter(m => m.id === teamMemberId)[0]);

		changedMember[changedField] = event.target.value;

		this.props.actions.updateTeamMember(changedMember);
	}

	onSaveMembers(event) {
		event.preventDefault();

		if (this.props.planDateWindow.teamMembers.some(tm => tm.text === "")) {
			NotifyUser.Warning("All team members must have a name.");
			return;
		}

		this.props.actions.saveTeamMembers(this.state.params.planDateWindowId, this.props.planDateWindow.teamMembers);
	}

	onRemoveMember(event, teamMemberId) {
		event.preventDefault();

		const memberToRemove = this.props.planDateWindow.teamMembers.filter(m => m.id === teamMemberId)[0];

		this.props.actions.removeTeamMember(memberToRemove);
	}

	onCreateMember(event) {
		event.preventDefault();

		const teamMembers = this.props.planDateWindow.teamMembers;
		const newId = generateNewId(isArrayNullOrEmpty(teamMembers) ? [] : teamMembers, teamPrefix);

		this.props.actions.createTeamMember(newId);
	}

	downloadFile(event) {
		event.preventDefault();

		this.props.actions.downloadFile(event.target.id);
	}

	deleteFile(event, strategyId, strategyIndicatorId) {
		event.preventDefault();

		if (confirm("Are you sure you want to delete the indicator's Tiered Districts Document?")) {
			const strategy = this.props.strategies.filter(s => s.id === strategyId)[0];
			const strategyIndicator = Object.assign({}, strategy.strategyIndicators.filter(si => si.id === strategyIndicatorId)[0]);

			strategyIndicator.triangleFileId = null;

			event.persist();

			this.props.actions.trySaveStrategicIndicator(strategyIndicator)
				.then(() => {
					this.props.actions.removeIndicatorTriangleFile(strategyId, strategyIndicatorId);

					this.props.actions.deleteFile(event.target.id);

				})
				.catch(error => catchError(error));
		}
	}

	render() {
		if (this.props.sppIndicators.length === 0 ||
			this.props.actionResources.length === 0 ||
			this.props.strategicGoals === 0 ||
			this.props.filterSettings === undefined ||
			this.props.planDateWindow.planDateWindowId === undefined)
			return null;

		return (
			<div>
				<GridRow rowClass="filterBar">
					{
						this.props.planDateWindow.status === "Open" &&
						<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.EDIT_PLAN}>
							<GridColumn medium="4" large="3" xxlarge="2">
								<Switch label={"toggle showing buttons"}
								        name={"showButtons"}
								        checked={this.props.filterSettings.showButtons}
								        onChange={this.onFilterChange}
								        legend={"Edit Mode"}
								        showLegend={true}
								/>
							</GridColumn>
						</Allow>
					}
					<GridColumn medium="8" large="9" xxlarge="10">
						<GridRow small={gridConstants.column.ONE} medium={gridConstants.column.ONE} large={gridConstants.column.TWO} xlarge={gridConstants.column.THREE} xxlarge={gridConstants.column.FOUR}>
							<GridColumn>
								<CheckBoxField label={"Team"}
								               name={"showTeam"}
								               checked={this.props.filterSettings.showTeam}
								               onChange={this.onFilterChange}
								               showLabel/>
							</GridColumn>
							<GridColumn>
								<CheckBoxField label={"Indicator Data"}
								               name={"showIndicators"}
								               checked={this.props.filterSettings.showIndicators}
								               onChange={this.onFilterChange}
								               showLabel/>
							</GridColumn>
							<GridColumn>
								<CheckBoxField label={"Action Details"}
								               name={"showActions"}
								               checked={this.props.filterSettings.showActions}
								               onChange={this.onFilterChange}
								               showLabel/>
							</GridColumn>
							<GridColumn>
								<CheckBoxField label={"Sub-actions"}
								               name={"showSubActions"}
								               checked={this.props.filterSettings.showSubActions}
								               onChange={this.onFilterChange}
								               showLabel/>
							</GridColumn>
							<GridColumn>
								<CheckBoxField label={"Progress"}
								               name={"showProgress"}
								               checked={this.props.filterSettings.showProgress}
								               onChange={this.onFilterChange}
								               showLabel/>
							</GridColumn>
							<ButtonBar position={""}>
								<Button name="btnPrint"
										label="Print"
										buttonType={ButtonTypes.PRINT}
										onClick={this.onPrintClick}
										btnClass={"l-float-right"}
								/>
							</ButtonBar>
						</GridRow>
					</GridColumn>
				</GridRow>

				{
					this.props.filterSettings.showTeam &&
					this.props.filterSettings.showButtons &&
					!this.props.isEditing &&
					this.props.planDateWindow.teamMembers.length === 0 &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_TEAM_MEMBER}>
						<TileContainer rowTilesLarge={"1"}>
							<Tile body={"Add Team Members"} isNew isLarge onClick={this.onCreateMember}/>
						</TileContainer>
					</Allow>
				}

				{
					this.props.filterSettings.showTeam &&
					this.props.planDateWindow.teamMembers.length > 0 &&
					(!this.props.isEditing ||
						(this.props.isEditing &&
							this.props.editItemId !== this.state.params.planDateWindowId)) &&
					<TeamMembers members={this.props.planDateWindow.teamMembers}
					             filters={this.props.filterSettings}
					             isLoading={this.props.isLoading}
					             isEditing={this.props.isEditing}
					             onEditTeam={this.onEditMembers}/>
				}

				{
					this.props.isEditing &&
					this.props.editItemId === this.state.params.planDateWindowId &&
					this.props.filterSettings.showTeam &&
					<TeamMemberForm members={this.props.planDateWindow.teamMembers}
					                isLoading={this.props.isLoading}
					                onChangeMember={this.onChangeMember}
					                onSaveMembers={this.onSaveMembers}
					                onCancel={this.onCancel}
					                onCreateMember={this.onCreateMember}
					                onRemoveMember={this.onRemoveMember}/>
				}

				{
					this.props.strategies.map((strategy, i) => {
						return (<Strategy key={strategy.id}
						                  strategyIndex={i}
						                  strategy={strategy}
						                  onDeleteStrategy={this.onDeleteStrategy}
						                  filters={this.props.filterSettings}
						                  onAddIndicator={this.onAddIndicator}
						                  onEditGoals={this.onEditGoals}
						                  onAddAction={this.onAddAction}
						                  isEditing={this.props.isEditing}
						                  isLoading={this.props.isLoading}
						                  editItemId={this.props.editItemId}
						                  onSaveIndicator={this.onSaveIndicator}
						                  onCancel={this.onCancel}
						                  onChangeIndicator={this.onChangeIndicator}
						                  sppIndicators={this.props.sppIndicators}
						                  metricYearSelectListItems={this.props.metricYearSelectListItems}
						                  onChangeAction={this.onChangeAction}
						                  onSaveAction={this.onSaveAction}
						                  resources={this.props.actionResources}
						                  goals={this.props.strategicGoals}
						                  onChangeGoals={this.onChangeGoals}
						                  onSaveGoals={this.onSaveGoals}
						                  onSaveSubAction={this.onSaveSubAction}
						                  onChangeSubAction={this.onChangeSubAction}
						                  onAddSubAction={this.onAddSubAction}
						                  onChangeActivity={this.onChangeActivity}
						                  onChangeActivityDate={this.onChangeActivityDate}
						                  onSaveActivity={this.onSaveActivity}
						                  onAddActivity={this.onAddActivity}
						                  onEditAction={this.onEditAction}
						                  onEditSubAction={this.onEditSubAction}
						                  onEditIndicator={this.onEditIndicator}
						                  onEditActivity={this.onEditActivity}
						                  onDeleteActivity={this.onDeleteActivity}
						                  onDeleteSubAction={this.onDeleteSubAction}
						                  onDeleteAction={this.onDeleteAction}
						                  onCreateMetric={this.onCreateMetric}
						                  onEditMetrics={this.onEditMetrics}
						                  onDeleteIndicator={this.onDeleteIndicator}
						                  onFileDownload={this.downloadFile}
						                  onFileDelete={this.deleteFile}
						                  planStartYear={this.props.planDateWindow.startYear}/>);
					})
				}

				{
					this.props.filterSettings.showButtons &&
					<Allow policy={StrategicPlanPolicy} policyEvent={policyEvents.ADD_STRATEGY}>
						<TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
							<Tile body={"Add Strategy"} onClick={!this.props.isEditing ? this.onAddStrategy : null}
							      isNew={true}/>
						</TileContainer>
					</Allow>
				}
			</div>
		);
	}
}

StrategicPlanPage.propTypes = {
	actions: PropTypes.object.isRequired,
	strategies: PropTypes.array.isRequired,
	planDateWindow: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,
	filterSettings: PropTypes.object.isRequired,
	sppIndicators: PropTypes.array.isRequired,
	actionResources: PropTypes.array.isRequired,
	strategicGoals: PropTypes.array.isRequired,
	isEditing: PropTypes.bool.isRequired,
	editItemId: PropTypes.string,
	dateWindows: PropTypes.array.isRequired,
	metricYearSelectListItems: PropTypes.array.isRequired,
	location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
	const annualWindows = sharedDataUtilities.filterCurrentAnnualDateWindows(state.sharedData.dateWindows);
	const metricYearSelectListItems =
		annualWindows.map(win => {
			return {
				text: new Date(win.openDate).getFullYear() + ' - ' + new Date(win.closeDate).getFullYear().toString().substring(2, 4),
				value: new Date(win.openDate).getFullYear().toString()
			};
		});

	return {
		metricYearSelectListItems: metricYearSelectListItems,
		dateWindows: state.sharedData.dateWindows,
		strategies: state.strategicPlan.strategies,
		sppIndicators: state.sharedData.sppIndicators,
		filterSettings: state.strategicPlan.filterSettings,
		isLoading: state.ajaxCallsInProgress > 0,
		isEditing: state.strategicPlan.isEditing,
		editItemId: state.strategicPlan.editItemId,
		actionResources: state.strategicPlan.resources,
		strategicGoals: state.strategicPlan.goals,
		planDateWindow: state.strategicPlan.planDateWindow
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		fileActions,
		layoutActions,
		stratPlanActions,
		sharedDataActions);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(StrategicPlanPage);