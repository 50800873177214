import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {API_ROOT} from "../ptsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerPtsSummaryApi {
    static getSummary(dateWindowId, programId, fiscalAgentId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/programs/${programId}/fiscalAgents/${fiscalAgentId}/projects`,
                RequestTypes.GET)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockPtsSummaryApi {
    static getSummary(){
        return ApiUtility.mockResolveApiCall();
    }
}

const PtsSummaryApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerPtsSummaryApi : MockPtsSummaryApi;
export default PtsSummaryApi;