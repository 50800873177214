import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function surveyReducer(state = initialState.survey, action){
    switch(action.type){
        case actionTypes.LOAD_SURVEYS_BY_RESPONDENT_ID_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    surveys: action.data
                });
        case actionTypes.LOAD_SURVEY_ELEMENTS_FOR_TAKESURVEY_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyElements: action.data
                });
        case actionTypes.LOAD_SURVEY_BY_ID_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    selectedSurvey: action.data
                });
        case actionTypes.CHANGE_SURVEY_ELEMENT_RESPONSE:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyElements: [...state.selectedSurveyElements].map(e => {
                        e = Object.assign({}, e);

                        if(e.componentId !== action.data.componentId) return e;

                        return action.data;
                    })
                });
        case actionTypes.APPEND_VIEWED_COMPONENT_HISTORY:
            return Object.assign(
                {},
                state,
                {
                    viewedComponentHistory: [...state.viewedComponentHistory, action.data]
                });
        case actionTypes.GO_TO_STEP:
            return Object.assign(
                {},
                state,
                {
                    currentStep: action.data
                });
        case actionTypes.CLEAR_SURVEY_DATA:
            return Object.assign(
                {},
                state,
                {
                    selectedSurveyElements: [],
                    surveys: []
                });
        case actionTypes.IMPERSONATING_AJAX_SUCCESS:
        case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
	    case actionTypes.LOGOUT_AJAX_SUCCESS:
		    return initialState.survey;
	    default: {
            return state;
        }
    }
}