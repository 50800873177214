import {generateInstitutionCollections} from "../components/shared/sharedDataUtilities";
import * as actionTypes from "../constants/actionTypes";
import {INSTITUTION_TYPES} from "../constants/sharedData/institutionTypes";
import initialState from "./initialState";
import * as sharedUtils from "../components/shared/sharedDataUtilities";
import moment from "moment/moment";

export default function sharedDataReducer(state = initialState.sharedData, action) {
	switch(action.type){
		case actionTypes.ENTER_ADMIN_EDIT_MODE:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					editItemId: action.data
				});
		case actionTypes.CANCEL_ADMIN_EDIT_MODE:
			return Object.assign(
				{},
				state,
				{
					editItemId: "",
					isEditing: false
				});

		case actionTypes.LOAD_INSTITUTIONS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				generateInstitutionCollections(action.institutions)
			);
		case actionTypes.CREATE_INSTITUTION:
			return Object.assign(
				{},
				state,
				generateInstitutionCollections([...state.allInstitutions, sharedUtils.newInstitution()])
			);
		case actionTypes.ADD_INSTITUTION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				generateInstitutionCollections([...state.allInstitutions].map(ins => {
					return ins.id !== "" ?
						Object.assign({}, ins) :
						Object.assign({}, ins, { id: action.data });
					}))
				);
		case actionTypes.CHANGE_INSTITUTION:
			return Object.assign(
				{},
				state,
				generateInstitutionCollections([...state.allInstitutions].map(ins => {
					return ins.id !== action.data.id ?
						Object.assign({}, ins) :
						Object.assign({}, action.data);
					}))
				);
		case actionTypes.DELETE_INSTITUTION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				generateInstitutionCollections([...state.allInstitutions].filter(ins => ins.id !== action.data.id))
			);
		case actionTypes.LOAD_SPP_INDICATORS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					sppIndicators: [...action.data]
				}
			);
		case actionTypes.LOAD_PTS_INDICATORS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					ptsIndicators: [...action.data]
				}
			);
		case actionTypes.LOAD_INDICATORS_All_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					allIndicators: [...action.data]
				}
			);
        case actionTypes.LOAD_BEESS_INDICATORS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    beessIndicators: [...action.data]
                }
            );
		case actionTypes.CREATE_SPP_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					sppIndicators: [...state.sppIndicators, sharedUtils.newIndicator()]
				}
			);
		case actionTypes.CREATE_BEESS_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					beessIndicators: [...state.beessIndicators, sharedUtils.newIndicator()]
				}
			);
		case actionTypes.CREATE_PTS_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					isEditing: true,
					ptsIndicators: [...state.ptsIndicators, sharedUtils.newIndicator()]
				}
			);
		case actionTypes.ADD_SPP_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					sppIndicators: [...state.sppIndicators].map(ind => {
						return ind.id !== "" ?
							Object.assign({}, ind) :
							Object.assign({}, ind, { id: action.data });
					})
				}
			);
		case actionTypes.ADD_PTS_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					ptsIndicators: [...state.ptsIndicators].map(ind => {
						return ind.id !== "" ?
							Object.assign({}, ind) :
							Object.assign({}, ind, { id: action.data });
					})
				}
			);
		case actionTypes.ADD_BEESS_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					beessIndicators: [...state.beessIndicators].map(ind => {
						return ind.id !== "" ?
							Object.assign({}, ind) :
							Object.assign({}, ind, { id: action.data });
					})
				}
			);
		case actionTypes.CHANGE_SPP_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					sppIndicators: [...state.sppIndicators].map(ind => {
						return ind.id !== state.editItemId ?
							Object.assign({}, ind) :
							Object.assign({}, action.data);
					})
				});
		case actionTypes.CHANGE_BEESS_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					beessIndicators: [...state.beessIndicators].map(ind => {
						return ind.id !== state.editItemId ?
							Object.assign({}, ind) :
							Object.assign({}, action.data);
					})
				});
		case actionTypes.CHANGE_PTS_INDICATOR:
			return Object.assign(
				{},
				state,
				{
					ptsIndicators: [...state.ptsIndicators].map(ind => {
						return ind.id !== state.editItemId ?
							Object.assign({}, ind) :
							Object.assign({}, action.data);
					})
				});
		case actionTypes.DELETE_SPP_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					sppIndicators: [...state.sppIndicators].map(ind => {
						ind = Object.assign({}, ind);

						if(action.data.id !== ind.id) return ind;

						ind.isDeleted = true;

						return ind;
					})
				}
			);
		case actionTypes.DELETE_PTS_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					ptsIndicators: [...state.ptsIndicators].map(ind => {
						ind = Object.assign({}, ind);

						if(action.data.id !== ind.id) return ind;

						ind.isDeleted = true;

						return ind;
					})
				}
			);
		case actionTypes.DELETE_BEESS_INDICATOR_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					beessIndicators: [...state.beessIndicators].map(ind => {
						ind = Object.assign({}, ind);

						if(action.data.id !== ind.id) return ind;

						ind.isDeleted = true;

						return ind;
					})
				}
			);

		case actionTypes.LOAD_DATE_WINDOWS_AJAX_SUCCESS:
		{
			let selectedDateWindowId = state.selectedDateWindowId;
			let selectedMultiYearDateWindowId = state.selectedMultiYearDateWindowId;

			if (!(action.data.filter(item => item.id ===selectedDateWindowId).length > 0))
			{
				const now = moment(new Date()).format();
				action.data.forEach(
					(item) => {
						if ( now < item.closeDate && now > item.openDate )
						{
							const diff = moment(item.closeDate).diff(moment(item.openDate), 'years');
							if (diff > 1)
								selectedMultiYearDateWindowId = item.id;
							else
								selectedDateWindowId = item.id;
						}
					}
				);
			}
			return Object.assign(
				{},
				state,
				{
					dateWindows: [...action.data],
					selectedDateWindowId: selectedDateWindowId,
					selectedMultiYearDateWindowId: selectedMultiYearDateWindowId
				});
		}
		case actionTypes.CHANGE_SELECTED_DATE_WINDOW:
			return Object.assign(
				{},
				state,
				{
					selectedDateWindowId: action.data
				});
		case actionTypes.CHANGE_SELECTED_MULTI_DATE_WINDOW:
			return Object.assign(
				{},
				state,
				{
					selectedMultiYearDateWindowId: action.data
				});
		case actionTypes.LOAD_SYSTEM_MESSAGE_AJAX_SUCCESS:
		case actionTypes.SAVE_SYSTEM_MESSAGE_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					systemMessage: {...action.data, ...{messageDisplayed: false}}
				});
		case actionTypes.SAVE_SYSTEM_MESSAGE_DISPLAYED:
			return Object.assign(
				{},
				state,
				{
					systemMessage: {...state.systemMessage, ...{messageDisplayed: true}}
				});
		case actionTypes.CHANGE_SYSTEM_MESSAGE:
			return Object.assign(
				{},
				state,
				{
					systemMessage: Object.assign({}, action.data, ...{messageDisplayed: false})
				});
		case actionTypes.LOAD_SYSTEM_CONFIGURATION_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					appSettings: Object.assign({}, action.data)
				});
		case actionTypes.RESTRICT_SYSTEM_ACCESS_AJAX_SUCCESS:
			return Object.assign(
				{},
				state,
				{
					systemIsLocked: action.data
				});
		case actionTypes.LOGOUT_AJAX_SUCCESS: {
			return initialState.sharedData;
		}
		default:
			return state;
	}
}