export const OnSite = {text: 'On-site', value: 1};
export const Desktop = {text: 'Desktop', value: 2};

export const Text = (type="") => {
	const defaultErrorMsg = "None Selected";
	if(!type)
		return defaultErrorMsg;

	switch(type.toString()) {
		case OnSite.value.toString():
			return OnSite.text;
		case Desktop.value.toString():
			return Desktop.text;
		default:
			return defaultErrorMsg;
	}
};