import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../components/common/GridTable";

export const MatrixSummaryGrid = (
    {
        correctionResults,
        onClick
    }) =>
{
    return (
        <div>
            <GridTable>
                <thead>
                <tr>
                    <th>Matrix Record</th>
                    <th className={"text-center"}>Corrections Missing Data</th>
                    <th className={"text-center"}>Total Ready for Submission</th>
                    <th className={"text-center"}>Total Submitted</th>
                </tr>
                </thead>
                <tbody>
                {
                    correctionResults.map((result, index) => {
                        const totalAvailableToBeSubmitted = result.totalAvailableToBeSubmitted > 0 ? `${result.totalToBeSubmitted} out of ${result.totalAvailableToBeSubmitted}` : `All Complete`;
                        return (
                            <tr key={`sr_${index}`} onClick={(event) => onClick(event, index)} className={`is-clickable`}>
                                <td>{result.name}</td>
                                <td>{result.recordsWithMissingData}</td>
                                <td>{totalAvailableToBeSubmitted} {result.recordsToBeSubmitted}</td>
                                <td className={"text-center"}>{result.totalSubmitted} out of {result.totalRecords}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </GridTable>
        </div>
    );
};

MatrixSummaryGrid.propTypes = {
    correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired
};