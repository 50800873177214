import {createListFromObject, generateSelectListFromArray} from "../../components/common/commonUtilities";

export const readyToSubmit = "Ready to Submit";

export const reviewResultOptions = [
    "Training/technical assistance was completed.",
    "A sampling of the appropriate plans (IEP, EP, or SP) demonstrates compliance.",
    "Corrected: T16-16 (Standards T16-2 and T16-9 through T16-15 are compliant.)",
    "Other"
];
export const correctionOtherText = "Other";
export const correctionOtherTextArray = [correctionOtherText];
export const correctionOtherTextSeparator = " - ";
export const correctionOtherTextId = "otherText";

export const approvalNeeded = "Waiting for FDOE Approval";
export const approvalNeededMessage = "Final assessment approval by FDOE needs to be completed first before corrections can be submitted.";

export const studentCorrections = {
    NewPlanFromMeeting: "Convened IEP, EP, or SP team to amend/develop new plan",
    AmendedWithoutMeeting: "Amended IEP, EP, or SP without a meeting",
    StudentWithdrew: "Student withdrew from district",
    StaffTraining: "Staff training/technical assistance",
    ProvidedProgressReport: "Provided progress report, as required",
    RevisedForm: "Revised form, as required",
    Other: "Other"
};

export const studentCorrectionsWithAdditionalInfoRequirement = {
    NewPlanFromMeeting: false,
    AmendedWithoutMeeting: false,
    StudentWithdrew: true,
    StaffTraining: true,
    ProvidedProgressReport: true,
    RevisedForm: true,
    Other: true
};

export const studentCorrectionOptions = createListFromObject(studentCorrections);

export const corrected = {
    Yes: "1",
    No: "2"
};

export const correctedOptions = createListFromObject(corrected);

export const matrixCorrections = {
    Corrected: "Corrected; cost factor submitted to the Automated Student Information System Database",
    NewIepWithUnchangedCostFactor: "Developed new IEP; cost factor unchanged",
    NewIepWithChangedCostFactor: "Developed new IEP; cost factor changed",
    Other: "Other"
};

export const matrixCorrectionsWithAdditionalInfoRequirement = {
    Corrected: false,
    NewIepWithUnchangedCostFactor: false,
    NewIepWithChangedCostFactor: false,
    Other: true
};

export const matrixCorrectionOptions = createListFromObject(matrixCorrections);

const reviewStatusOptionsText = ["Corrected; More than one year", "Corrected within the established timeline", "Corrected within a year"];
export const reviewStatusOptions = generateSelectListFromArray(reviewStatusOptionsText);