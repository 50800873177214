import {ELEMENT_TYPE} from "../../gmsConstants";

export function isElementTypeForDeliverables(type) {
    return type === ELEMENT_TYPE.deliverableSection;
}

export function isElementTypeForBudgets(type) {
    return type === ELEMENT_TYPE.doe101Section || type === ELEMENT_TYPE.doe151Section;
}

export function isElementTypeForSalaries(type) {
    return type === ELEMENT_TYPE.salarySection;
}