import PropTypes from "prop-types";
import React from "react";
import {isTrimmedStringEmpty} from "../common/commonUtilities";
import overdueIcon  from "../../images/overdue-20.png";
import notOverdueIcon  from "../../images/not-overdue-20.png";

const props = {
    task: PropTypes.object.isRequired,
    viewRecord: PropTypes.func.isRequired
};

export const TaskItemForListItem = ({task, viewRecord}) => {
    return (
        <li className={`task-icon-list`}>
            <TaskItem isPrint={false} task={task} viewRecord={viewRecord} />
        </li>
    );
};

TaskItemForListItem.propTypes = props;

export const TaskItemForTableRow = ({isPrint = false, task, viewRecord}) => {
    return (
        <tr>
            <td className={`task-icon-row`}>
                <div>
                    <TaskItem isPrint={isPrint} task={task} viewRecord={viewRecord} />
                </div>
            </td>
        </tr>
    );
};

TaskItemForTableRow.propTypes = {
    ...props,
    isPrint: PropTypes.bool.isRequired
};

const TaskItem = ({ isPrint, task, viewRecord }) => {
    const getTaskStatusIcon = (isOverdue) => {
        const taskStatusIconUrl = isOverdue ? overdueIcon : notOverdueIcon;
        const taskStatusIconAltDescription = isOverdue ? "Overdue task" : "Not overdue task";
        return <img src={taskStatusIconUrl} alt={taskStatusIconAltDescription} width={20} height={20} />;
    };

    if(isPrint || isTrimmedStringEmpty(task.url))
        return (
            <>
                {getTaskStatusIcon(task.isOverdue)}
                {task.description}
            </>
        );

    return (
        <>
            {getTaskStatusIcon(task.isOverdue)}
            <a onClick={viewRecord} href={"#"}>
                {task.description}
            </a>
        </>
    );
};

TaskItem.propTypes = {
    ...props,
    isPrint: PropTypes.bool.isRequired
};
