import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";

export const DrDistrictSummaryStateComplaint = ({
											handleClickDistrict,
	                                        handleClickOrder,
	                                        summary
                                        }) => {
	return (
		<GridTable>
			<thead>
			<tr>
				<th onClick={handleClickOrder} data-id="districtName" className="is-clickable" rowSpan={3}>
					District
				</th>
				<th onClick={handleClickOrder} data-id="totalOpenCases" className="is-clickable center" rowSpan={3}>
					Open Cases
				</th>
				<th onClick={handleClickOrder} data-id="totalFiled" className="is-clickable center" rowSpan={3}>
					Total Filed
				</th>
				<th className="center" colSpan={4}>Findings of Noncompliance Tracking Status</th>
			</tr>
			<tr>
				<th className="center" colSpan={2}>District</th>
				<th className="center" colSpan={2}>Bureau</th>
			</tr>
			<tr>
				<th onClick={handleClickOrder} data-id="activeComplaintsWithNonCompliance" className="is-clickable center">
					Reports with FNC (Active)
				</th>
				<th onClick={handleClickOrder} data-id="responsesNeedingRevisionCount" className="is-clickable center">
					Submissions Needing Revision
				</th>
				<th onClick={handleClickOrder} data-id="closedComplaintsWithNonCompliance" className="is-clickable center">
					Reports with FNC (Closed)
				</th>
				<th onClick={handleClickOrder} data-id="responsesSubmittedCount" className="is-clickable center">
					Submissions Needing Review
				</th>
			</tr>
			</thead>
			<tbody>
			{
				isArrayNullOrEmpty(summary) &&
				<tr>
					<td colSpan={7} className={"center"}>
						No districts match the search criteria specified.
					</td>
				</tr>
			}
			{
				summary.map((district, key) =>
					(<tr
						key={key}
						onClick={(event) => handleClickDistrict(event, district.districtName)}
						className={"is-clickable"}
					>
						<td>{district.districtName}</td>
						<td className={"center"}>{district.totalOpenCases}</td>
						<td className={"center"}>{district.totalFiled}</td>
						<td className={"center"}>{district.activeComplaintsWithNonCompliance}</td>
						<td className={"center"}>{district.responsesNeedingRevisionCount}</td>
						<td className={"center"}>{district.closedComplaintsWithNonCompliance}</td>
						<td className={"center"}>{district.responsesSubmittedCount}</td>
					</tr>)
				)
			}
			</tbody>
		</GridTable>
	);
};

DrDistrictSummaryStateComplaint.propTypes = {
	handleClickDistrict: PropTypes.func.isRequired,
	handleClickOrder: PropTypes.func.isRequired,
	summary: PropTypes.array
};

export default DrDistrictSummaryStateComplaint;

export const MemoizedDrDistrictSummaryStateComplaint = React.memo(DrDistrictSummaryStateComplaint);
