import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {isGuidEmpty, setPageTitle} from "../../../../components/common/commonUtilities";
import PrintTable from "../../../../components/common/PrintTable";
import BpieReportApi from "../bpieReportApi";
import ChartContainer from "../../../../components/common/ChartContainer";
import PdfContainer from "../../../../components/common/PdfContainer";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import AccountUtility from "../../../../api/AccountUtility";

let print = null;

const IndicatorPriorityReportContainer = ({
                                              actions,
                                              dateWindowId,
                                              districtId,
                                              reportTitle,
                                          }) => {
    const [reportData, setReportData] = useState(null);

    const validDistrictId = AccountUtility.getValidatedBpieDistrictId(districtId);

    const handleUpdateReportData = (result) => {
        setReportData(result);
    };

    const loadReportData = () => {
        actions.executeApi(BpieReportApi.getBpieIndicatorPriorityReportData, [dateWindowId, validDistrictId])
            .then((result) => {
                handleUpdateReportData(result);
            });
    };

    const getPrint = (getFunc) => {
        print = getFunc;
    };

    const usePrint = (width, callback) => {
        print(width);
        if(callback)
            callback();
    };

    const handleDistrictSummaryClick = (event) => {
        event.preventDefault();
        actions.executeApi(BpieReportApi.getBpieDistrictSummaryReport, [dateWindowId, validDistrictId]);
    };

    const DistrictSummaryButton = () => <Button buttonType={ButtonTypes.DOWNLOAD}
                                                label={`Export District Summary`}
                                                name={`btnExportDistrictSummary`}
                                                onClick={handleDistrictSummaryClick} />;

    useEffect(() => {
        setPageTitle(reportTitle);
        loadReportData();
    }, []);

    if (!reportData)
        return null;

    return <div className={"theme-bpie"}>
        <PdfContainer
            afterPrint={usePrint}
            beforePrint={usePrint}
            fileName={`${reportData ? reportData.districtName : ``}_${reportTitle}`}
            otherButtons={!isGuidEmpty(validDistrictId) ? <DistrictSummaryButton /> : null}
        >
            <>
                <div className="report-header">
                    <h1>School Best Practices for Inclusive Education (SBPIE) Assessment<br/> Priority Indicators</h1>
                    <h2>District: {reportData.districtName}</h2>
                    <h2>Dates: {reportData.reportPeriod}</h2>
                </div>
                <PrintTable className={"table-print-center add-padding"}>
                    <thead>
                    <tr>
                        <th>SBPIE Assessment Indicator Priorities</th>
                        <th>Number of Schools That Selected Out of {reportData.schoolCount}</th>
                        <th>%</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reportData.indicatorDetailsList.map((indicatorDetails, key) => {
                            return (<tr key={`indicatorRow-${key}`}>
                                <td><p className="report-indicator-indent">{`${indicatorDetails.indicatorNumber}. ${indicatorDetails.summary}`}</p></td>
                                <td className={"text-center"}>{indicatorDetails.selectedCount}</td>
                                <td className={"text-center"}>{indicatorDetails.selectedPercent === "0" ? "-" : `${indicatorDetails.selectedPercent}%`}</td>
                            </tr>);
                        })
                    }
                    </tbody>
                </PrintTable>
                <br/>
                <ChartContainer
                    categoryLabels={reportData.indicatorDetailsList.map(a => a.indicatorNumber)}
                    chartClass={"pdfPageBreak"}
                    chartTitle={reportTitle}
                    data={reportData.indicatorDetailsList.map(a => a.selectedPercent)}
                    dataFormat={"{0}%"}
                    getPrint={getPrint}
                />
            </>
        </PdfContainer>
    </div>;
};


IndicatorPriorityReportContainer.propTypes = {
    actions: PropTypes.object,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    reportTitle: PropTypes.string
};

export default IndicatorPriorityReportContainer;