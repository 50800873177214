import React from 'react';
import PropTypes from 'prop-types';
import AuditHistoryOutcomeComment from "./AuditHistoryOutcomeComment";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";
import {AuditHistoryOutcomeResponse} from "./AuditHistoryOutcomeResponse";

const AuditHistoryOutcome = ({
                                 audit,
                                 editItemId,
                                 handleCommentChange,
                                 isFirstAudit,
                                 isInEditMode,
                                 isLoading,
                                 labelForVisibility,
                                 onCommentEdit,
                                 onCommentSave,
                                 onCommentCancel,
                                 onCommentCreate,
                                 parentIdPropName
                             }) => {
    const auditCreatedBy = `${audit.outcomeString}${audit.auditCreatedBy ? `, updated by ${audit.auditCreatedBy}` : ``}`;
    const dateCreated = outputStandardDateFormat(audit.dateCreated);
    const auditUserDetails = `${dateCreated}: ${auditCreatedBy}`;

    return (
        <>
            <AuditHistoryOutcomeComment
                audit={audit}
                editItemId={editItemId}
                handleCommentChange={handleCommentChange}
                isFirstAudit={isFirstAudit}
                isInEditMode={isInEditMode}
                isLoading={isLoading}
                labelForVisibility={labelForVisibility}
                onCommentCreate={onCommentCreate}
                onCommentCancel={onCommentCancel}
                onCommentEdit={onCommentEdit}
                onCommentSave={onCommentSave}
                parentIdPropName={parentIdPropName}
            />
            <li>
                {auditUserDetails}
                <AuditHistoryOutcomeResponse response={audit.historyResponse}/>
            </li>
        </>
    );
};

AuditHistoryOutcome.propTypes = {
    audit: PropTypes.object.isRequired,
    editItemId: PropTypes.string,
    handleCommentChange: PropTypes.func,
    isFirstAudit: PropTypes.bool,
    isInEditMode: PropTypes.bool,
    isLoading: PropTypes.bool,
    labelForVisibility: PropTypes.string,
    onCommentEdit: PropTypes.func,
    onCommentSave: PropTypes.func,
    onCommentCancel: PropTypes.func,
    onCommentCreate: PropTypes.func,
    parentIdPropName: PropTypes.string
};

export default AuditHistoryOutcome;