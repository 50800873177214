import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import GridRow from "../../../../components/common/GridRow";
import GridColumn from "../../../../components/common/GridColumn";
import {
    isTrimmedStringEmpty,
    objectHasProperty,
} from "../../../../components/common/commonUtilities";
import FieldsetLayout from "../../../../components/common/inputs/FieldsetLayout";
import FileUploadButton from "../../../../components/common/inputs/FileUploadButton";
import {convertFormInputObjectToObject, useFormInputObject} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import {FORM_TYPE} from "../../gmsConstants";
import { createDoe150Data, createDoe150Labels } from "../../gmsObjectFactory";
import TextField from "../../../../components/common/inputs/TextField";
import _ from "lodash";
import FormTable from "../../../../components/common/FormTable";
import TextArea from "../../../../components/common/inputs/TextArea";
import Button from "../../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import gmsApi from '../../gmsApi';
import DownloadUtility from '../../../../api/requests/DownloadUtility';
import NumberField from '../../../../components/common/inputs/NumberField';
import { gridConstants } from "../../../../constants/inputConstants";
import { GmsApplicationHistorySelectField } from "../GmsApplicationHistorySelectField";

export const GmsApplicationFormElementDoe150 = ({
                                                    actions,
                                                    applicationHistories,
                                                    applicationId,
                                                    canEditApplication,
                                                    initialSignedDoe150FileId,
                                                    isDirty,
                                                    isFiscalAgentUser,
                                                    nextAmendmentNumber,
                                                    response = { value: {} },
                                                    selectedApplicationHistory,
                                                    setSelectedApplicationHistory,
                                                    }) => {
    const [signedDoe150FileId, setSignedDoe150FileId] = useState(initialSignedDoe150FileId);
    const [isReadyToPrint, setIsReadyToPrint] = useState(false);
    const responseValue = _.isString(response.value) && !isTrimmedStringEmpty(response.value) ? JSON.parse(response.value) : response.value;
    const filteredApplicationHistories= applicationHistories.filter(x => x.hasAmendedData);

    const responses = useFormInputObject(createDoe150Data(responseValue, nextAmendmentNumber));
    const labels = createDoe150Labels();

    const handlePrintClick = (event) => {
        event.preventDefault();
        actions.executeApi(gmsApi.downloadGeneratedForm, [applicationId, FORM_TYPE.doe150]);
    };

    const handleUploadSignedDoe150Click = () => {
        const uploadDoe150 = document.getElementById("uploadDoe150");
        uploadDoe150.click();
    };

    const handleFileUploadSignedDoe150Change = (event) => {
        const file = [...event.target.files];
        actions.executeApi(gmsApi.uploadSignedForm, [file, applicationId, FORM_TYPE.doe150])
            .then((newFileId) => {
                setSignedDoe150FileId(newFileId);
                DownloadUtility.clearFileUploadSelection("uploadDoe150");
            });
    };

    const handleDownloadSignedDoe150Click = (event) => {
        event.preventDefault();
        actions.executeApi(gmsApi.downloadSignedFormWithFileId, [signedDoe150FileId, FORM_TYPE.doe150]);
    };

    const handleClickDownloadUnsignedDoe150History = async (event) => {
        event.preventDefault();
        if (!selectedApplicationHistory) {
            NotifyUser.Warning("Please select a DOE 150 History option before downloading.");
            return;
        }
        await actions.executeApi(gmsApi.downloadGeneratedForm, [applicationId, FORM_TYPE.doe150, selectedApplicationHistory]);
    };

    const handleClickDownloadSignedDoe150History = async (event) => {
        event.preventDefault();
        if (!selectedApplicationHistory) {
            NotifyUser.Warning("Please select a DOE 150 History option before downloading.");
            return;
        }
        await actions.executeApi(gmsApi.downloadSignedForm, [applicationId, FORM_TYPE.doe150, selectedApplicationHistory]);
    };

    useEffect(() => {
        if(filteredApplicationHistories.every(a => selectedApplicationHistory !== a.id))
            setSelectedApplicationHistory("");
    }, []);

    useEffect(() => {
        let hasError = false;
        const updatedResponse = convertFormInputObjectToObject(responses);
        if (!_.isEqual(updatedResponse, response.value)) {
            response.setValue(JSON.stringify(updatedResponse));
        }
        const validateProperty = (property) => {
            const hasErrorNotYetMarked = labels[property].required && !responses[property].value
                && !responses[property].error;
            if (hasErrorNotYetMarked) {
                responses[property].setError(`${labels[property].label} is required.`);
            }

            const hasErrorMarkedCorrectly = labels[property].required && !responses[property].value
                && responses[property].error;
            if (hasErrorNotYetMarked || hasErrorMarkedCorrectly) {
                hasError = true;
            }

            const hasErrorMarkedShouldNot = labels[property].required && responses[property].value
                && responses[property].error;
            if (hasErrorMarkedShouldNot) {
                responses[property].setError("");
            }
        };

        for (let property in responses) {
            const isPropertyInResponsesObject = objectHasProperty(responses, property);
            const isPropertyInLabelsObject = objectHasProperty(labels, property);

            if (isPropertyInResponsesObject && isPropertyInLabelsObject)
                validateProperty(property);
        }

        setIsReadyToPrint(!hasError && !isDirty)

        if (isFiscalAgentUser) return;

        if (hasError)
            response.setError("Complete required fields.");
        else
            response.setError("");

    }, [responses, response]);

    return (
        <>
            <div className={`doe100DownloadContainer`}>
                <h3>Download and Upload Forms</h3>

                {
                    isReadyToPrint &&
                    <>
                        <p>All required form fields have been completed, you can now
                            <Button
                                buttonType={ButtonTypes.LINK_INLINE}
                                disabled={!isReadyToPrint}
                                label={`download an unsigned PDF`}
                                name={`btnPrintDoe150`}
                                onClick={handlePrintClick}
                            />
                            copy of this form.</p>
                        <ButtonBar position={ButtonBarPositions.MODULE}>
                            <Button
                                disabled={!canEditApplication}
                                label={!signedDoe150FileId ? `Upload Signed DOE 150 Form` : `Replace Signed DOE 150 Form`}
                                name={`btnUploadSignedDoe150`}
                                onClick={handleUploadSignedDoe150Click}
                                showLabel
                            />
                            <Button
                                disabled={!signedDoe150FileId}
                                label={`Download Signed DOE 150 Form`}
                                name={`btnDownloadSignedDoe150`}
                                onClick={handleDownloadSignedDoe150Click}
                                showLabel
                            />
                        </ButtonBar>
                    </>
                }
                {
                    !isReadyToPrint &&
                    <p className={`color--alert`}>When all required form fields have been completed and saved then you
                        will
                        be able to
                        &quot;Download a PDF&quot; copy of this form.</p>
                }

                {
                    filteredApplicationHistories.length > 0 &&
                    <>
                        <p>Download DOE 150 Historical PDF Files.</p>
                        <GridRow rowClass={`applicationHistoryBar applicationHistoryBar--noBorder`}>
                            <GridColumn columnClass={`text-right`} medium={gridConstants.column.FIVE}>
                                <GmsApplicationHistorySelectField
                                    applicationHistories={filteredApplicationHistories}
                                    label={`DOE 150 History`}
                                    selectedApplicationHistory={selectedApplicationHistory}
                                    setSelectedApplicationHistory={setSelectedApplicationHistory}
                                    showLabel={false}
                                />
                            </GridColumn>
                            <GridColumn columnClass={`applicationHistoryBar__buttonColumn`} medium={gridConstants.column.SEVEN}>
                                <Button
                                    label={`Download Unsigned DOE 150 History`}
                                    name={`btnDownloadUnsignedDoe150History`}
                                    onClick={handleClickDownloadUnsignedDoe150History}
                                />

                                <Button
                                    label={`Download Signed DOE 150 History`}
                                    name={`btnDownloadSignedDoe150History`}
                                    onClick={handleClickDownloadSignedDoe150History}
                                />
                            </GridColumn>
                        </GridRow>
                    </>
                }

            </div>

            <h3>Amendment Requested</h3>
            <p>Provide sufficient narrative to describe and justify the type of amendment being requested. Narrative should include the purpose of the amendment and description of the amended services or budget changes i.e.  changes in scope or objectives, changes in deliverables or work tasks and how these changes affect the original application.   Any budget change will require details on the increase/decrease and how the change affects the original application.</p>
            <FieldsetLayout disabled={!canEditApplication}>
                <FormTable>
                    <NumberField showLabel isPositiveNumberOnly isWholeNumberOnly name={`amendmentNumber`}
                        {...responses.amendmentNumber}
                        {...labels.amendmentNumber}
                    />
                    <TextArea showLabel
                                 {...responses.narrative}
                                 {...labels.narrative}
                    />
                </FormTable>

                <h3>Contact Information</h3>
                <p>Enter the name of the contact person.In the &quot;Agency Head&quot; text box enter the name of the district superintendent or agency head whose signature will be certifying the DOE 150.</p>

                <FormTable>
                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactNameAgency}
                               {...labels.contactNameAgency}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactName}
                               {...labels.contactName}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                        {...responses.contactAddress}
                        {...labels.contactAddress}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                        {...responses.contactCity}
                        {...labels.contactCity}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                        {...responses.contactZip}
                        {...labels.contactZip}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactEmail}
                               {...labels.contactEmail}
                    />

                    <TextField columnsMedium={6} columnsLarge={8} showLabel
                               {...responses.contactPhone}
                               {...labels.contactPhone}
                    />
                </FormTable>
                <FileUploadButton
                    className={`is-hidden`}
                    label={"Upload DOE 150"}
                    name={"uploadDoe150"}
                    onChange={handleFileUploadSignedDoe150Change}
                />
            </FieldsetLayout>
        </>
    );
};

GmsApplicationFormElementDoe150.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationHistories: PropTypes.array.isRequired,
    applicationId: PropTypes.string.isRequired,
    canEditApplication: PropTypes.bool.isRequired,
    initialSignedDoe150FileId: PropTypes.string,
    isDirty: PropTypes.bool.isRequired,
    isFiscalAgentUser: PropTypes.bool.isRequired,
    nextAmendmentNumber: PropTypes.number,
    response: PropTypes.object.isRequired,
    selectedApplicationHistory: PropTypes.string.isRequired,
    setSelectedApplicationHistory: PropTypes.func.isRequired,
};