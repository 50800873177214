import * as surveyLocations from "./surveys/surveyLocations";
import * as sppLocations from "./spp/sppLocations";

export const EXTERNAL_ID = "Public";
export const USER_ID = ":userId";

export const SPP_SECTION_ID = sppLocations.SECTION_ID;
export const SPP_DOCUMENT_ID = sppLocations.DOCUMENT_ID;
export const SPP_INSTITUTION_ID = sppLocations.INSTITUTION_ID;

export const SURVEY_RESPONDENT_ID = surveyLocations.RESPONDENT_ID;
export const SURVEY_INSTANCE_ID = surveyLocations.INSTANCE_ID;

export const PAGE_NOT_FOUND = {
    path: `/PageNotFound`,
    params: {}
};

//USER
export const USER_SIGN_IN = {
    path: `/`
};

export const USER_RESET_PASSWORD = {
    path: `/ResetPassword/${USER_ID}`,
    params: {
        userId: USER_ID
    }
};
export const USER_FORGOT_PASSWORD = {
    path: `/ForgotPassword`
};

//SURVEYS
export const SURVEYS_LANDING = {
    path: `${surveyLocations.LANDING.path}/Respondent/${SURVEY_RESPONDENT_ID}`,
    params: {
        respondentId: surveyLocations.RESPONDENT_ID
    }
};
export const SURVEYS_TAKE = {
    path: `${surveyLocations.TAKE.path}/${EXTERNAL_ID}`,
    params: {...surveyLocations.TAKE.params}
};
export const SURVEYS_CONGRATULATIONS = {
    path: `${surveyLocations.CONGRATULATIONS.path}/${EXTERNAL_ID}`
};

//SPP
export const SPP_LANDING = {
    path: `${sppLocations.LANDING_DOE.path}/${EXTERNAL_ID}`
};
export const SPP_DOCUMENT = {
    path: `${sppLocations.DOCUMENT_DOE.path}/${EXTERNAL_ID}`,
    params: {
        institutionId: sppLocations.INSTITUTION_ID,
        documentId: sppLocations.DOCUMENT_ID
    }
};
export const SPP_SECTION = {
    path: `${sppLocations.SECTION_DOE.path}/${EXTERNAL_ID}`,
    params: {
        institutionId: sppLocations.INSTITUTION_ID,
        documentId: sppLocations.DOCUMENT_ID,
        sectionId: sppLocations.SECTION_ID
    }
};
export const SPP_DOCUMENT_PRINT = {
    path: `${sppLocations.DOCUMENT_DOE.path}/Print/${EXTERNAL_ID}`,
    params: {
        institutionId: sppLocations.INSTITUTION_ID,
        documentId: sppLocations.DOCUMENT_ID
    }
};
export const SPP_DOCUMENT_SECTION_PRINT = {
    path: `${sppLocations.DOCUMENT_DOE.path}/Section/${SPP_SECTION_ID}/Print/${EXTERNAL_ID}`,
    params: {
        institutionId: sppLocations.INSTITUTION_ID,
        documentId: sppLocations.DOCUMENT_ID,
        sectionId: sppLocations.SECTION_ID
    }
};