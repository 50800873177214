import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import {monitoring_compliance_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';
import {
    getStudentProtocolCorrectiveResults,
    getMatrixProtocolCorrectiveResults,
    saveStudentProtocolCorrectiveResults,
    saveMatrixProtocolCorrectiveResults
} from "../mockData/monitoringComplianceMockData";
import {ToastrNotificationFactory} from "./notifications/ToastrNotificationFactory";

const notificationFactory = new ToastrNotificationFactory();

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceStudentCorrectionsApi {
    static loadComplianceWithStudentCorrectiveResults(assessmentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/student-corrections`,
            RequestTypes.GET));
    }

    static loadComplianceWithMatrixCorrectiveResults(assessmentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/matrix-corrections`,
            RequestTypes.GET));
    }

    static loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${dateWindowId}/districts/${districtId}/student-corrections`,
            RequestTypes.GET));
    }

    static loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${dateWindowId}/districts/${districtId}/matrix-corrections`,
            RequestTypes.GET));
    }

    static saveStudentCorrectiveResults(assessmentId, studentRecordId, studentProtocolCorrectiveResults){
        const dto = {
            monitoringAssessmentId: assessmentId,
            studentRecordId: studentRecordId,
            studentProtocolCorrectiveResults: studentProtocolCorrectiveResults
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/student-corrections`,
            RequestTypes.PUT,
            JSON.stringify(dto),
            ApiSuccessMessages.studentCorrectionResults.SAVE,
            'application/json'));
    }

    static saveMatrixCorrectiveResult(assessmentId, studentRecordId, matrixProtocolCorrectiveResult){
        const dto = {
            monitoringAssessmentId: assessmentId,
            studentRecordId: studentRecordId,
            matrixProtocolCorrectiveResult: matrixProtocolCorrectiveResult
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/matrix-corrections`,
            RequestTypes.PUT,
            JSON.stringify(dto),
            ApiSuccessMessages.matrixCorrectionResults.SAVE,
            'application/json'));
    }

    static submitDistrictStudentCorrectiveResults(assessmentId, studentRecordId) {
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/students/${studentRecordId}/student-corrections/submit`,
                RequestTypes.POST);

            request.contentType = 'application/json';
            request.setCallbacks(function () {
                notificationFactory
                    .createSuccess(ApiSuccessMessages.studentCorrectionResults.SUBMIT)
                    .notify();

                resolve(assessmentId);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static submitDistrictMatrixCorrectiveResults(assessmentId) {
        return new Promise((resolve, reject) => {
            let request = AjaxRequestOptions(
                config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/matrix-corrections/submit`,
                RequestTypes.POST);

            request.contentType = 'application/json';
            request.setCallbacks(function () {
                notificationFactory
                    .createSuccess(ApiSuccessMessages.matrixCorrectionResults.SUBMIT)
                    .notify();

                resolve(assessmentId);
            }, function(data){
                reject(data);
            });

            return ApiUtility.sendRequest(request);
        });
    }

    static rejectDistrictStudentCorrectiveResult(outcomeDto) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/student-corrections/${outcomeDto.studentProtocolCorrectiveResultId}/revise`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.studentCorrectionResults.REJECT);

        return ApiUtility.sendRequest(request);
    }

    static approveDistrictStudentCorrectiveResult(outcomeDto) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/student-corrections/${outcomeDto.studentProtocolCorrectiveResultId}/approve`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.studentCorrectionResults.APPROVE));
    }

    static resetDistrictStudentCorrectiveResult(outcomeDto) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/student-corrections/${outcomeDto.studentProtocolCorrectiveResultId}/reset`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.studentCorrectionResults.RESET));
    }

    static rejectDistrictMatrixCorrectiveResult(outcomeDto) {
        let request = AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/matrix-corrections/${outcomeDto.matrixProtocolCorrectiveResultId}/revise`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.matrixCorrectionResults.REJECT);

        return ApiUtility.sendRequest(request);
    }

    static approveDistrictMatrixCorrectiveResult(outcomeDto) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/matrix-corrections/${outcomeDto.matrixProtocolCorrectiveResultId}/approve`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.matrixCorrectionResults.APPROVE));
    }

    static resetDistrictMatrixCorrectiveResult(outcomeDto) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            `${config.apiUrl}monitoring/compliances/assessments/${outcomeDto.monitoringAssessmentId}/matrix-corrections/${outcomeDto.matrixProtocolCorrectiveResultId}/reset`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.matrixCorrectionResults.RESET));
    }

    static ResponseAuditComment(auditCommentDto, isCreate, assessmentId, correctionId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/corrections/${correctionId}/audits/${auditCommentDto.auditId}/comments`,
            isCreate ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(auditCommentDto),
            ApiSuccessMessages.auditHistoryComments.SAVE));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceStudentCorrectionsApi {
    static loadComplianceWithStudentCorrectiveResults(monitoringAssessmentId){
        const assessment = getStudentProtocolCorrectiveResults().filter(f => f.monitoringAssessmentId === monitoringAssessmentId);

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment[0]);
            }, config.environment.delay);
        });
    }

    static loadComplianceWithMatrixCorrectiveResults(monitoringAssessmentId){
        const assessment = getMatrixProtocolCorrectiveResults().filter(f => f.monitoringAssessmentId === monitoringAssessmentId);

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment[0]);
            }, config.environment.delay);
        });
    }

    static loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId){
        const assessment = getStudentProtocolCorrectiveResults().filter(f => f.districtId === districtId && f.dateWindowId === dateWindowId);

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment[0]);
            }, config.environment.delay);
        });
    }

    static loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId){
        const assessment = getMatrixProtocolCorrectiveResults().filter(f => f.districtId === districtId && f.dateWindowId === dateWindowId);

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment[0]);
            }, config.environment.delay);
        });
    }

    static saveStudentCorrectiveResults(monitoringAssessmentId, studentRecordId, studentProtocolCorrectiveResults){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = [...getStudentProtocolCorrectiveResults()];
                const otherAssessments = assessments.filter(f => f.monitoringAssessmentId !== monitoringAssessmentId && f.studentRecordId === studentRecordId);
                let currentAssessment = assessments.find(f => f.monitoringAssessmentId === monitoringAssessmentId && f.studentRecordId === studentRecordId);
                currentAssessment.studentProtocolCorrectiveResults = [...studentProtocolCorrectiveResults.map(r => {
                    return (Object.assign({}, r, {
                        corrections: r.corrections,
                        dateCorrected: r.dateCorrected,
                        corrected: r.corrected
                    }));
                })];

                saveStudentProtocolCorrectiveResults([...otherAssessments, currentAssessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static saveMatrixCorrectiveResult(monitoringAssessmentId, studentRecordId, matrixProtocolCorrectiveResult){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = [...getMatrixProtocolCorrectiveResults()];
                const otherAssessments = assessments.filter(f => f.monitoringAssessmentId !== monitoringAssessmentId && f.studentRecordId === studentRecordId);
                let currentAssessment = assessments.find(f => f.monitoringAssessmentId === monitoringAssessmentId && f.studentRecordId === studentRecordId);
                currentAssessment = Object.assign({}, currentAssessment, {
                        corrections: matrixProtocolCorrectiveResult.corrections,
                        dateCorrected: matrixProtocolCorrectiveResult.dateCorrected,
                        corrected: matrixProtocolCorrectiveResult.corrected
                    });

                saveMatrixProtocolCorrectiveResults([...otherAssessments, currentAssessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static submitDistrictStudentCorrectiveResults(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static submitDistrictMatrixCorrectiveResults(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static rejectDistrictStudentCorrectiveResult(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static approveDistrictStudentCorrectiveResult() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static resetDistrictStudentCorrectiveResult() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static rejectDistrictMatrixCorrectiveResult(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static approveDistrictMatrixCorrectiveResult() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static resetDistrictMatrixCorrectiveResult() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static ResponseAuditComment() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceStudentCorrectionsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceStudentCorrectionsApi : MockMonitoringComplianceStudentCorrectionsApi;
export default MonitoringComplianceStudentCorrectionsApi;
