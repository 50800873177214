import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function menuReducer(state = initialState.menu, action) {
	switch(action.type){
		case actionTypes.TOGGLE_MAIN_MENU:
			return Object.assign({}, state, { isActive: action.isActive });
		case actionTypes.TOGGLE_ADMIN_MENU:
			return Object.assign({}, state, { isAdminMenuOpen: action.isAdminMenuOpen });
		case actionTypes.IMPERSONATING_AJAX_SUCCESS:
		case actionTypes.RETURN_AS_ADMIN_AJAX_SUCCESS:
		case actionTypes.LOGOUT_AJAX_SUCCESS: {
			return initialState.menu;
		}
		default:
			return state;
	}
}