import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getDistinctIds} from "../../../../components/common/commonUtilities";
import * as MonitoringComplianceUtilities
    from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import audit_outcomes from "../../../../constants/auditOutcomes";
import * as ComplianceLocations from "../../../../constants/monitoringCompliance/monitoringComplianceLocations";
import MatrixStateSummaryGrid from "./MatrixStateSummaryGrid";

export const MatrixCorrectionsStateSummaryView = ({
                                                      actions,
                                                      dateWindowId,
                                                      districtId,
                                                      history,
                                                      institutions,
                                                      matrixProtocolCorrectiveResults
                                                  }) => {
    const [titleIsSet, setTitleIsSet] = useState(false);
    const [currentDateWindowId, setCurrentDateWindowId] = useState("");

    const onSummaryClick = (event, index) => {
        event.preventDefault();

        history.push(ComplianceLocations.DOE_MATRIX_CORRECTIVE_ACTIONS.path
            .replace(ComplianceLocations.DISTRICT_ID, districtId)
            .replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
            .replace(ComplianceLocations.CURRENT_PAGE, index + 1)
            .replace(ComplianceLocations.IS_MATRIX, ComplianceLocations.MATRIX));
    };

    const updateTitle = () => {
        if (titleIsSet || !institutions || institutions.length === 0) return;

        const pageTitle = MonitoringComplianceUtilities.createPageTitle(institutions, districtId, "Matrix Specific Corrections Summary");
        actions.updatePageTitle(pageTitle);

        setTitleIsSet(true);
    }

    const buildMatrixList = () => {
        const nameProp = "matrix";
        const distinctStudentRecordIds = getDistinctIds(matrixProtocolCorrectiveResults, "studentRecordId");

        let list = [];
        distinctStudentRecordIds.map((studentRecordId) => {
            const corrections = matrixProtocolCorrectiveResults.filter(f => f.studentRecordId === studentRecordId);

            const recordsToBeReviewed = corrections.reduce((agg, record) => {
                let output = "Ready for review";
                if (record[nameProp])
                    output = record[nameProp];
                if (record.status === audit_outcomes.SUBMITTED.value)
                    agg.push(<li key={output}>{output}</li>);

                return agg;
            }, []);

            const totalRecords = corrections.length;
            const totalInProgress = corrections.filter(f => f.status === audit_outcomes.IN_PROGRESS.value).length;
            const totalAccepted = corrections.filter(f => f.status === audit_outcomes.ACCEPTED.value).length;
            const totalToBeRevised = corrections.filter(f => f.status === audit_outcomes.NEEDS_REVISION.value).length;

            list.push({
                name: corrections[0].name,
                totalInProgress,
                recordsToBeReviewed: recordsToBeReviewed.length === 0 ? <em>None</em> : <ul>{recordsToBeReviewed}</ul>,
                totalToBeRevised,
                totalAccepted,
                totalRecords
            })
        });

        return list;
    };

    useEffect(() => {
        if (!MonitoringComplianceUtilities.isValidId(dateWindowId)
            || !MonitoringComplianceUtilities.isValidId(districtId)
            || currentDateWindowId === dateWindowId) return;

        actions.loadDistrictComplianceWithMatrixCorrectiveResults(districtId, dateWindowId);
        setCurrentDateWindowId(dateWindowId);

    }, [dateWindowId, districtId]);

    useEffect(() => {
        updateTitle();
    }, [institutions]);

    if (!matrixProtocolCorrectiveResults) return null;

    return (
        <section className={"compliance__studentCorrectionResultsSummary"}>
            {
                matrixProtocolCorrectiveResults.length === 0 &&
                <p>No matrix specific corrections are needed at this time.</p>
            }
            {
                matrixProtocolCorrectiveResults.length > 0 &&
                <div>
                    <MatrixStateSummaryGrid
                        correctionResults={buildMatrixList()}
                        onClick={onSummaryClick}
                    />
                </div>
            }
        </section>
    );
}

MatrixCorrectionsStateSummaryView.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    history: PropTypes.object.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    matrixProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired
};
