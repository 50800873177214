import PropTypes from "prop-types";
import React from "react";
import GridTable from "../../../../components/common/GridTable";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import SelectField from "../../../../components/common/inputs/SelectField";

const ManageFinView = ({
                           bpieUsers,
                           cancelAndReload,
                           handleChangeRegion,
                           regions,
                           saveBpieUsers
                       }) => {

    const onSaveClick = (event) => {
        event.preventDefault();
        saveBpieUsers();
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        cancelAndReload();
    };

    const handleOnChangeRegion = (event, userId) => {
        event.preventDefault();
        handleChangeRegion(event.target.value, userId);
    }

    return (
        <>
            <GridTable>
                <thead>
                <tr>
                    <th>Facilitator</th>
                    <th>Region</th>
                </tr>
                </thead>
                <tbody>
                {
                    bpieUsers.map((user) => {
                        const regionName = `region${user.userId}`;
                        const defaultOption = user.isInactive ? "Remove" : "FIN Admin";
                        return (<tr key={user.userId}>
                            <td>{user.fullName}</td>
                            <td>
                                <SelectField
                                    defaultOption={defaultOption}
                                    includeDefaultOption={true}
                                    label={`Region for ${user.fullName}`}
                                    name={regionName}
                                    onChange={(event) => handleOnChangeRegion(event, user.userId)}
                                    options={regions}
                                    showLabel={false}
                                    value={user.regionId}
                                />
                            </td>
                        </tr>)
                    })
                }
                </tbody>
            </GridTable>
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>

                <Button name="btnCancel"
                        label="Cancel and Reload"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={onCancelClick}/>
            </ButtonBar>
        </>
    );
};

ManageFinView.propTypes = {
    bpieUsers: PropTypes.array.isRequired,
    cancelAndReload: PropTypes.func.isRequired,
    handleChangeRegion: PropTypes.func.isRequired,
    regions: PropTypes.array.isRequired,
    saveBpieUsers: PropTypes.func.isRequired,
};

export default ManageFinView;