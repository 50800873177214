import React from "react";
import PropTypes from 'prop-types';
import FormTable from "../../../components/common/FormTable";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintCitation from "../Citations/DrStateComplaintCitation";
import DrStateComplaintResponseAction from "./DrStateComplaintResponseAction";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";
import * as drObjectFactory from "../drStateComplaintObjectFactory";

const DrStateComplaintResponseIssue = ({
	                                       issue,
	                                       editDeadlineId,
	                                       handleAuditResponseAsync,
	                                       handleSaveAuditCommentAsync,
	                                       handleSaveResponseAsync,
	                                       handleSaveResponseReviewAsync,
	                                       setEditDeadlineId,
	                                       showIssueNumber,
                                       }) => {

	const noActions = isArrayNullOrEmpty(issue.actionModels);

	return (
		<FormTable>
			{
				showIssueNumber &&
				issue.issueNumber &&
				<DisplayField
					displayClass={"bold"}
					name="issueNumber"
					label="Issue Number"
				>
					Issue {issue.issueNumber}
				</DisplayField>
			}
			{
				issue.issueDescription &&
				<DisplayField
					name="issueDescription"
					label="Description"
					showLabel
				>
					{issue.issueDescription}
				</DisplayField>
			}
			{
				issue.generalCitationDetails &&
				<DisplayField
					name="generalCitationDetails"
					label="General Citation Details"
					showLabel
				>
					{issue.generalCitationDetails}
				</DisplayField>
			}
			{
				!isArrayNullOrEmpty(issue.citations) &&
				<DrStateComplaintCitation citations={issue.citations} />
			}
			{
				!noActions &&
				issue.actionModels.map((act, key) => {
					const action = drObjectFactory.createActionObject(act);
					return <DrStateComplaintResponseAction
						key={key}
						action={action}
						editDeadlineId={editDeadlineId}
						handleAuditResponseAsync={handleAuditResponseAsync}
						handleSaveAuditCommentAsync={handleSaveAuditCommentAsync}
						handleSaveResponseAsync={handleSaveResponseAsync}
						handleSaveResponseReviewAsync={handleSaveResponseReviewAsync}
						issueId={issue.issueId}
						setEditDeadlineId={setEditDeadlineId}
					/>;
				})
			}
		</FormTable>
	);
};

DrStateComplaintResponseIssue.propTypes = {
	editDeadlineId: PropTypes.string.isRequired,
	handleAuditResponseAsync: PropTypes.func.isRequired,
	handleSaveAuditCommentAsync: PropTypes.func,
	handleSaveResponseAsync: PropTypes.func.isRequired,
	handleSaveResponseReviewAsync: PropTypes.func.isRequired,
	issue: PropTypes.object.isRequired,
	setEditDeadlineId: PropTypes.func.isRequired,
	showIssueNumber: PropTypes.bool.isRequired
};

export default DrStateComplaintResponseIssue;