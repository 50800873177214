import {
	createListFromObject,
	createListItem,
	createDescriptionObjectForList, createListFromArray
} from "../../components/common/commonUtilities";
import {Other} from "../../constants/caseNotes/genericCaseNoteConstants";

export const newActionId = "NEW_ACTION";

export const CaseManagementDatesDaysAhead = {
	partyResponseDate: 10,
	resolutionMeetingDeadline: 15,
	resolutionProcessDeadline: 30,
	hearingDecisionDeadline: 75
};

export const DueProcessSections = {
	Overview: "Overview",
	ResolutionProcess: "Resolution Process<br/>Status Report",
	FinalOrder: "Final Order",
	CorrectiveActions: "Corrective Actions",
	CaseNotes: "Case Notes"
};

export const DueProcessComplaintTypes = {
	Regular: "Regular",
	Expedited: "Expedited",
	Gifted: "Gifted"
}

export const DueProcessComplaintTypesList = createListFromObject(DueProcessComplaintTypes);

export const DueProcessStatus = {
	Pending: createDescriptionObjectForList(1, "Pending"),
	WithdrawnOrDismissedOrResolved: createDescriptionObjectForList(2, "Withdrawn or dismissed or resolved without a hearing"),
	HearingFullyAdjudicated: createDescriptionObjectForList(3, "Hearing fully adjudicated")
};
export const DueProcessStatusList = createListFromObject(DueProcessStatus);

export const PrevailingParty = {
	Parent: createDescriptionObjectForList(1, "Parent"),
	AdultStudent: createDescriptionObjectForList(2, "Adult Student"),
	PublicAgency: createDescriptionObjectForList(3, "Public Agency")
};
export const PrevailingPartyList = createListFromObject(PrevailingParty);

export const ParentRepresentationStatus = {
	ProSe: createDescriptionObjectForList(1, "Pro Se"),
	Attorney: createDescriptionObjectForList(2, "Attorney"),
	QualifiedRepresentative: createDescriptionObjectForList(3, "Qualified Representative"),
	Other: createDescriptionObjectForList(4, "Other")
};
export const ParentRepresentationStatusList = createListFromObject(ParentRepresentationStatus);

export const FinalOrderIssues = [
	"Identification",
	"Evaluation",
	"Educational Placement",
	"Provision of FAPE",
	"Discipline",
	"Independent Educational Evaluations",
	"Extraordinary Exemptions (Statewide Assessments)",
	"Other"
];

export const FinalOrderIssuesList = createListFromArray(FinalOrderIssues);

export const FinalOrderIssuesWithTextField = [
	"Other"
];

export const DrFilterDueProcessStatus = {
	Pending: "pending",
	WithdrawnOrDismissedOrResolvedWithoutHearing: "withdrawnOrDismissedOrResolvedWithoutHearing",
	HearingFullyAdjudicated: "hearingFullyAdjudicated",
	HearingFullyAdjudicatedWithOrderedActions: "hearingFullyAdjudicatedWithOrderedActions",
	OrderedActionResponsesNeedingReview: "orderedActionResponsesNeedingReview",
	OrderedActionResponsesOverdue: "orderedActionResponsesOverdue",
	StatusReportUpcoming: "statusReportUpcoming",
	StatusReportOverdue: "statusReportOverdue",
	StatusReportNeedingReview: "statusReportNeedingReview",
	StatusReportNeedingRevisions: "statusReportNeedingRevisions",
	StatusReportExtensionRequested: "statusReportExtensionRequested"
};
export const DrFilterDueProcessStatusList = createListFromObject(DrFilterDueProcessStatus);

export const Timeline = {
	WithinTimeline: createDescriptionObjectForList(1, "Hearing decided within timeline"),
	WithinExtendedTimeline: createDescriptionObjectForList(2, "Hearing decided with extended timeline"),
	OutsideTimeline: createDescriptionObjectForList(3, "Hearing decided outside timeline")
};
export const TimelineList = createListFromObject(Timeline);

export const ResolutionSessionOutcome = {
	ResolutionSessionHeld: createDescriptionObjectForList(1, "Resolution Session Held"),
	WaivedByBothParties: createDescriptionObjectForList(2, "Resolution session not held, waived by both parties"),
	DistrictFiled: createDescriptionObjectForList(3, "Resolution session not held, district filed"),
	Mediation: createDescriptionObjectForList(4, "Resolution session not held, parties agreed to participate in mediation"),
	DismissedOrWithdrawnPriorToDeadline: createDescriptionObjectForList(5, "Resolution session not held, dismissed or withdrawn prior to deadline"),
	ParentFailedToRespond: createDescriptionObjectForList(6, "Resolution session not held, parent failed to respond or participate"),
	Other: createDescriptionObjectForList(7, "Resolution session not held, other"),
}
export const ResolutionSessionOutcomeList = createListFromObject(ResolutionSessionOutcome);

export const WrittenSettlementStatus = {
	WrittenSettlementReached: createDescriptionObjectForList(1, "A written settlement agreement was reached"),
	WrittenSettlementVoided: createDescriptionObjectForList(2, "A written settlement agreement was reached but voided within three business days"),
	WrittenSettlementNotReached: createDescriptionObjectForList(3, "A written settlement agreement was not reached")
}
export const WrittenSettlementStatusList = createListFromObject(WrittenSettlementStatus);

export const ResolutionProcessOptions = {
	Option1: "ResolutionViaMeeting",
	Option2: "ResolutionWithMeetingWaived",
	Option3: "ResolutionViaMediation",
	Option4: "ResolutionWithoutMeeting",
	Option5: "ResolutionEndedWithdrawOrDismissed",
	Option6: "ResolutionEndedWithoutParentParticipation",
	Option7: "ResolutionOther",
	Option8: "ResolutionNoInformationYet"
};

export const ResolutionProcessOptionsList = [
	createListItem("ResolutionViaMeeting", "Option 1. Both parties <strong>agreed to participate</strong> in the resolution meeting."),
	createListItem("ResolutionWithMeetingWaived", "Option 2. Both parties agreed in writing to <strong>waive</strong> the resolution meeting."),
	createListItem("ResolutionViaMediation", "Option 3. Both parties agreed to participate in <strong>mediation</strong> in lieu of a resolution meeting."),
	createListItem("ResolutionWithoutMeeting", "Option 4. The district filed the due process hearing request and was <strong>not required</strong> to hold a resolution meeting."),
	createListItem("ResolutionEndedWithdrawOrDismissed", "Option 5. The request for a due process hearing request was <strong>withdrawn</strong> or <strong>dismissed</strong> prior to the resolution meeting deadline."),
	createListItem("ResolutionEndedWithoutParentParticipation", "Option 6. The district was unable to obtain the participation of the parent in the resolution meeting after reasonable efforts had been made (and documented using the procedures in §300.322(d)), and the district, at the conclusion of the 30-day period, requested that a hearing officer dismiss the parent’s due process complaint."),
	createListItem("ResolutionOther", "Option 7. Other.")
];

export const ResolutionProcessOption1Outcomes = [
	createListItem("A written settlement agreement was reached.", "A written settlement agreement <strong>was</strong> reached."),
	createListItem("A written settlement agreement was reached, but voided within three business days.", "A written settlement agreement was reached, but <strong>voided</strong> within three business days."),
	createListItem("A written settlement agreement was not reached.", "A written settlement agreement was <strong>not</strong> reached.")
];

export const ResolutionProcessOption3OutcomesList = {
	Outcome1: "A mediation agreement was reached.",
	Outcome2: "A mediation agreement was not reached."
};
export const ResolutionProcessOption3Outcomes = [
	createListItem("A mediation agreement was reached.", "A mediation agreement <strong>was</strong> reached."),
	createListItem("A mediation agreement was not reached.", "A mediation agreement was <strong>not</strong> reached.")
];

export const ResolutionProcessOption3AgreementList = [
	createListItem("Full agreement reached", "<strong>Full </strong>agreement reached."),
	createListItem("Partial agreement reached", "<strong>Partial </strong>agreement reached.")
];

let GeneralCaseNoteType = {
	TechnicalAssistance:  createDescriptionObjectForList(1, "Technical assistance"),
	Redaction: createDescriptionObjectForList(2, "Redaction"),
	MonitoringOrderedActions: createDescriptionObjectForList(3, "Monitoring (Ordered Actions)")
};
GeneralCaseNoteType[Other] = createDescriptionObjectForList(4, "Other");
export { GeneralCaseNoteType };

export const ReadOnlyCaseNoteTypes = {
	CaseAssignment: createDescriptionObjectForList(5, "Case Assignment")
};

export const CaseNoteType = {...GeneralCaseNoteType, ...ReadOnlyCaseNoteTypes};

export const CaseNoteTypeList = createListFromObject(CaseNoteType);