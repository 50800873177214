import React from 'react';
import PropTypes from 'prop-types';
import * as elementTypes from "../../constants/spp/sppElementTypes";
import CheckBoxField from "../../components/common/inputs/CheckBoxField";
import FileViewDelete from "../../components/common/inputs/FileViewDelete";
import SelectField from "../../components/common/inputs/SelectField";
import RadioField from "../../components/common/inputs/RadioField";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import TextReadOnly from "../common/inputs/TextReadOnly";
import * as sppUtilities from "../../components/spp/SppUtilities";

const SppElementPrint = ({
	                         element,
	                         response,
	                         fileOnView
                         }) => {
	let options;

	let disabled = true;

	let inputField = <div/>;

	if (element.layout === elementTypes.TextBox || element.layout === elementTypes.TextArea) {
		inputField = (
			<TextReadOnly
				key={element.id}
				label={element.text}
				name={element.id}
				value={response.data}
			/>
		);
	}
	else if (element.layout === elementTypes.Display) {
		inputField = (
			<HtmlDisplayBlock
				name={element.id}
				html={element.text}
			/>
		);
	}
	else if (element.layout === elementTypes.Checkbox && sppUtilities.checkboxShouldPrint(element, response)) {
		inputField = (
			<CheckBoxField
				key={element.id}
				label={element.text}
				name={element.id}
				checked={(response.data === "true")}
				showLabel
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.File) {
		if (response.data === "")
			inputField = ("");
		else
			inputField = (
				<FileViewDelete
					name={element.id}
					label={element.text}
					fileName="Download File"
					onView={fileOnView}
					disabled={disabled}
				/>
			);
	}
	else if (element.layout === elementTypes.DropDown) {
		options = JSON.parse(element.value);
		inputField = (
			<SelectField
				key={element.id}
				name={element.id}
				label={element.text}
				showLabel
				value={response.data}
				includeDefaultOption
				options={options}
				disabled={disabled}
			/>
		);
	}
	else if (element.layout === elementTypes.Radio) {
		options = JSON.parse(element.value);
		inputField = (
			<RadioField
				key={element.id}
				name={element.id}
				legend={element.text}
				options={options}
				onChange={() => {}}
				value={response.data}
				disabled={disabled}
			/>
		);
	}

	return (
		<div>
			{inputField}
		</div>
	);

};

SppElementPrint.propTypes = {
	element: PropTypes.object.isRequired,
	response: PropTypes.object,
	fileOnView: PropTypes.func
};

SppElementPrint.defaultProps = {
	response: {data: ''}
};

export default SppElementPrint;