// ------------ IMPERSONATING ACTIONS ------------ //
export const IMPERSONATING_AJAX_SUCCESS = 'IMPERSONATING_AJAX_SUCCESS';
export const RETURN_AS_ADMIN_AJAX_SUCCESS = 'RETURN_AS_ADMIN_AJAX_SUCCESS';

// ------------ LAYOUT ACTIONS ------------ //
export const UPDATE_PAGETITLE = "UPDATE_PAGETITLE";
export const UPDATE_PAGE_SUBTITLE = "UPDATE_PAGE_SUBTITLE";

// ------------ AJAX ACTIONS ------------ //
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const BEGIN_AJAX_DASHBOARD_CALL = 'BEGIN_AJAX_DASHBOARD_CALL';
export const AJAX_CALL_DASHBOARD_ERROR = 'AJAX_CALL_DASHBOARD_ERROR';

// ------------ LOGIN ACTIONS ------------ //
export const LOGIN_AJAX_SUCCESS = "LOGIN_AJAX_SUCCESS";
export const LOGIN_SET_RETURNURL = "LOGIN_SET_RETURNURL";
export const LOGOUT_AJAX_SUCCESS = "LOGOUT_AJAX_SUCCESS";

// ------------ GENERIC ACTION  ----------- //
export const GENERIC_LOCAL_STATE_AJAX_SUCCESS = "GENERIC_LOCAL_STATE_AJAX_SUCCESS";

// ------------ DASHBOARD ACTIONS -------------//
export const LOAD_PTS_TILES_AJAX_SUCCESS = "LOAD_PTS_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_GMS_TILES_AJAX_SUCCESS = "LOAD_GMS_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_BPIE_TILES_AJAX_SUCCESS = "LOAD_BPIE_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_SPP_TILES_AJAX_SUCCESS = "LOAD_SPP_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_MONITORING_PERFORMANCE_TILES_AJAX_SUCCESS = "LOAD_MONITORING_PERFORMANCE_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_MONITORING_COMPLIANCE_TILES_AJAX_SUCCESS = "LOAD_MONITORING_COMPLIANCE_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_EDITABLE_SURVEYS_BY_RESPONDENT_EMAIL_AJAX_SUCCESS = "LOAD_EDITABLE_SURVEYS_BY_RESPONDENT_EMAIL_AJAX_DASHBOARD_SUCCESS";
export const LOAD_MEDIATION_TILES_AJAX_SUCCESS = "LOAD_MEDIATION_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_DUEPROCESS_TILES_AJAX_SUCCESS = "LOAD_DUEPROCESS_TILES_AJAX_DASHBOARD_SUCCESS";
export const LOAD_COMPLAINT_TILES_AJAX_SUCCESS = "LOAD_COMPLAINT_TILES_AJAX_DASHBOARD_SUCCESS";
// ------------ MENU ACTIONS -------------//
export const TOGGLE_MAIN_MENU = "TOGGLE_MAIN_MENU";
export const TOGGLE_ADMIN_MENU = "TOGGLE_ADMIN_MENU";

// ------------ COMMON DATA ACTIONS ------------ //
export const SAVE_SEARCH_CRITERIA = "SAVE_SEARCH_CRITERIA";
export const CREATE_SEARCH_CRITERIA = "CREATE_SEARCH_CRITERIA";

// ------------ SHARED DATA ACTIONS ------------ //
export const LOAD_INSTITUTIONS_AJAX_SUCCESS = "LOAD_INSTITUTIONS_AJAX_SUCCESS";
export const LOAD_DATE_WINDOWS_AJAX_SUCCESS = "LOAD_DATE_WINDOWS_AJAX_SUCCESS";
export const LOAD_SYSTEM_MESSAGE_AJAX_SUCCESS = "LOAD_SYSTEM_MESSAGE_AJAX_SUCCESS";
export const SAVE_SYSTEM_MESSAGE_DISPLAYED = "SAVE_SYSTEM_MESSAGE_DISPLAYED";
export const LOAD_SPP_INDICATORS_AJAX_SUCCESS = "LOAD_SPP_INDICATORS_AJAX_SUCCESS";
export const LOAD_PTS_INDICATORS_AJAX_SUCCESS = "LOAD_PTS_INDICATORS_AJAX_SUCCESS";
export const LOAD_BEESS_INDICATORS_AJAX_SUCCESS = "LOAD_BEESS_INDICATORS_AJAX_SUCCESS";
export const LOAD_INDICATORS_All_AJAX_SUCCESS = "LOAD_INDICATORS_All_AJAX_SUCCESS";
export const FILE_UPLOAD_AJAX_SUCCESS = "FILE_UPLOAD_AJAX_SUCCESS";
export const FILE_DOWNLOAD_AJAX_SUCCESS = "FILE_DOWNLOAD_AJAX_SUCCESS";
export const FILE_DELETE_AJAX_SUCCESS = "FILE_DELETE_AJAX_SUCCESS";
export const CHANGE_SYSTEM_MESSAGE = "CHANGE_SYSTEM_MESSAGE";
export const SAVE_SYSTEM_MESSAGE_AJAX_SUCCESS = "SAVE_SYSTEM_MESSAGE_AJAX_SUCCESS";
export const CREATE_INSTITUTION = "CREATE_INSTITUTION";
export const ENTER_ADMIN_EDIT_MODE = "ENTER_ADMIN_EDIT_MODE";
export const CHANGE_INSTITUTION = "CHANGE_INSTITUTION";
export const ADD_INSTITUTION_AJAX_SUCCESS = "ADD_INSTITUTION_AJAX_SUCCESS";
export const UPDATE_INSTITUTION_AJAX_SUCCESS = "UPDATE_INSTITUTION_AJAX_SUCCESS";
export const DELETE_INSTITUTION_AJAX_SUCCESS = "DELETE_INSTITUTION_AJAX_SUCCESS";
export const CANCEL_ADMIN_EDIT_MODE = "CANCEL_ADMIN_EDIT_MODE";
export const CREATE_SPP_INDICATOR = "CREATE_SPP_INDICATOR";
export const CHANGE_SPP_INDICATOR = "CHANGE_SPP_INDICATOR";
export const CREATE_BEESS_INDICATOR = "CREATE_BEESS_INDICATOR";
export const CHANGE_BEESS_INDICATOR = "CHANGE_BEESS_INDICATOR";
export const CREATE_PTS_INDICATOR = "CREATE_PTS_INDICATOR";
export const CHANGE_PTS_INDICATOR = "CHANGE_PTS_INDICATOR";
export const ADD_SPP_INDICATOR_AJAX_SUCCESS = "ADD_SPP_INDICATOR_AJAX_SUCCESS";
export const ADD_PTS_INDICATOR_AJAX_SUCCESS = "ADD_SPP_INDICATOR_AJAX_SUCCESS";
export const ADD_BEESS_INDICATOR_AJAX_SUCCESS = "ADD_BEESS_INDICATOR_AJAX_SUCCESS";
export const UPDATE_INDICATOR_AJAX_SUCCESS = "UPDATE_INDICATOR_AJAX_SUCCESS";
export const DELETE_SPP_INDICATOR_AJAX_SUCCESS = "DELETE_SPP_INDICATOR_AJAX_SUCCESS";
export const DELETE_PTS_INDICATOR_AJAX_SUCCESS = "DELETE_PTS_INDICATOR_AJAX_SUCCESS";
export const DELETE_BEESS_INDICATOR_AJAX_SUCCESS = "DELETE_BEESS_INDICATOR_AJAX_SUCCESS";
export const LOAD_SYSTEM_CONFIGURATION_AJAX_SUCCESS = "LOAD_SYSTEM_CONFIGURATION_AJAX_SUCCESS";
export const CHANGE_SELECTED_DATE_WINDOW = "CHANGE_SELECTED_DATE_WINDOW";
export const CHANGE_SELECTED_MULTI_DATE_WINDOW = "CHANGE_SELECTED_MULTI_DATE_WINDOW";
export const RESTRICT_SYSTEM_ACCESS_AJAX_SUCCESS = "RESTRICT_SYSTEM_ACCESS_AJAX_SUCCESS";

// ------------ USER ACTIONS ------------ //
export const LOAD_USERS_AJAX_SUCCESS = "LOAD_USERS_AJAX_SUCCESS";
export const LOAD_BEHAVIORS_AJAX_SUCCESS = "LOAD_BEHAVIORS_AJAX_SUCCESS";
export const LOAD_USER_BY_ID_AJAX_SUCCESS = "LOAD_USER_BY_ID_AJAX_SUCCESS";
export const RESET_USER_PASSWORD_AJAX_SUCCESS = "RESET_USER_PASSWORD_AJAX_SUCCESS";
export const DELETE_USER_AJAX_SUCCESS = "DELETE_USER_AJAX_SUCCESS";
export const REACTIVATE_USER_AJAX_SUCCESS = "REACTIVATE_USER_AJAX_SUCCESS";
export const CLEAR_LOADED_USER = "CLEAR_LOADED_USER";
export const SAVE_USER_AJAX_SUCCESS = "SAVE_USER_AJAX_SUCCESS";
export const SAVE_USER_PROGRAMS_AJAX_SUCCESS = "SAVE_USER_PROGRAMS_AJAX_SUCCESS";
export const REQUEST_PASSWORD_AJAX_SUCCESS = "REQUEST_PASSWORD_AJAX_SUCCESS";
export const RESET_PASSWORD_AJAX_SUCCESS = "RESET_PASSWORD_AJAX_SUCCESS";
export const LOAD_PROGRAMS_FOR_FISCAL_AGENT_AJAX_SUCCESS = "LOAD_PROGRAMS_FOR_FISCAL_AGENT_AJAX_SUCCESS";


// ----------- SURVEY ACTIONS ------------//
export const LOAD_SURVEYS_BY_RESPONDENT_ID_AJAX_SUCCESS = "LOAD_SURVEYS_BY_RESPONDENT_ID_AJAX_SUCCESS";
export const LOAD_SURVEY_BY_ID_AJAX_SUCCESS = "LOAD_SURVEY_BY_ID_AJAX_SUCCESS";
export const LOAD_SURVEY_ELEMENTS_FOR_TAKESURVEY_AJAX_SUCCESS = "LOAD_SURVEY_ELEMENTS_FOR_TAKESURVEY_AJAX_SUCCESS";
export const SAVE_RESPONDENT_SURVEY_ELEMENTS_AJAX_SUCCESS = "SAVE_RESPONDENT_SURVEY_ELEMENTS_AJAX_SUCCESS";
export const SUBMIT_RESPONDENT_SURVEY_AJAX_SUCCESS = "SUBMIT_RESPONDENT_SURVEY_AJAX_SUCCESS";
export const CHANGE_SURVEY_ELEMENT_RESPONSE = "CHANGE_SURVEY_ELEMENT_RESPONSE";
export const APPEND_VIEWED_COMPONENT_HISTORY = "APPEND_VIEWED_COMPONENT_HISTORY";
export const GO_TO_STEP = "GO_TO_STEP";
export const CLEAR_SURVEY_DATA = "CLEAR_SURVEY_DATA";
export const LOAD_SURVEY_RESPONSES_REPORT_AJAX_SUCCESS = "LOAD_SURVEY_RESPONSES_REPORT_AJAX_SUCCESS";
export const LOAD_SURVEY_PROGRESS_REPORT_AJAX_SUCCESS = "LOAD_SURVEY_PROGRESS_REPORT_AJAX_SUCCESS";
export const LOAD_SURVEY_DISCRETIONARY_PROJECT_REPORT_AJAX_SUCCESS = "LOAD_SURVEY_DISCRETIONARY_PROJECT_REPORT_AJAX_SUCCESS";

// ----------- SURVEY ADMIN ACTIONS ------------//
export const LOAD_SURVEY_INSTANCES_AJAX_SUCCESS = "LOAD_SURVEY_INSTANCES_AJAX_SUCCESS";
export const LOAD_SURVEY_DETAILS_AJAX_SUCCESS = "LOAD_SURVEY_DETAILS_AJAX_SUCCESS";
export const UPDATE_OPEN_COMPONENTS = "UPDATE_OPEN_COMPONENTS";
export const SAVE_SURVEY_DETAILS_AJAX_SUCCESS = "SAVE_SURVEY_DETAILS_AJAX_SUCCESS";
export const SAVE_ELEMENT_ORDER_AJAX_SUCCESS = "SAVE_ELEMENT_ORDER_AJAX_SUCCESS";
export const PUBLISH_SURVEY_AJAX_SUCCESS = "PUBLISH_SURVEY_AJAX_SUCCESS";
export const UNPUBLISH_SURVEY_AJAX_SUCCESS = "UNPUBLISH_SURVEY_AJAX_SUCCESS";
export const CLOSE_SURVEY_AJAX_SUCCESS = "CLOSE_SURVEY_AJAX_SUCCESS";
export const REMIND_SURVEY_RESPONDENTS_AJAX_SUCCESS = "REMIND_SURVEY_RESPONDENTS_AJAX_SUCCESS";
export const DELETE_SURVEY_RESPONDENT_AJAX_SUCCESS = "DELETE_SURVEY_RESPONDENT_AJAX_SUCCESS";
export const UNSUBMIT_SURVEY_RESPONDENT_AJAX_SUCCESS = "UNSUBMIT_SURVEY_RESPONDENT_AJAX_SUCCESS";
export const LOAD_SURVEY_RESPONDENTS_AJAX_SUCCESS = "LOAD_SURVEY_RESPONDENTS_AJAX_SUCCESS";
export const COPY_SURVEY_AJAX_SUCCESS = "COPY_SURVEY_AJAX_SUCCESS";
export const LOAD_SURVEY_ELEMENTS_AJAX_SUCCESS = "LOAD_SURVEY_ELEMENTS_AJAX_SUCCESS";
export const DELETE_SURVEY_ELEMENT_AJAX_SUCCESS = "DELETE_SURVEY_ELEMENT_AJAX_SUCCESS";
export const ADD_SURVEY_ELEMENTS_AJAX_SUCCESS = "ADD_SURVEY_ELEMENTS_AJAX_SUCCESS";
export const CLONE_SURVEY_ELEMENT_AJAX_SUCCESS = "CLONE_SURVEY_ELEMENT_AJAX_SUCCESS";
export const UPDATE_SURVEY_ELEMENTS_AJAX_SUCCESS = "UPDATE_SURVEY_ELEMENTS_AJAX_SUCCESS";
export const CLEAR_SURVEY_INSTANCE_DATA = "CLEAR_SURVEY_INSTANCE_DATA";
export const CHANGE_SURVEY_DETAILS = "CHANGE_SURVEY_DETAILS";
export const CREATE_ELEMENT_SET = "CREATE_ELEMENT_SET";
export const CREATE_ELEMENT = "CREATE_ELEMENT";
export const CHANGE_ELEMENT = "CHANGE_ELEMENT";
export const EDIT_ELEMENT = "EDIT_ELEMENT";
export const CREATE_ANSWER_CHOICE = "CREATE_ANSWER_CHOICE";
export const REMOVE_ANSWER_CHOICE = "REMOVE_ANSWER_CHOICE";
export const CHANGE_ANSWER_CHOICE = "CHANGE_ANSWER_CHOICE";
export const DOWNLOAD_SAMPLE_RESPONDENT_IMPORT_FILE_AJAX_SUCCESS = "DOWNLOAD_SAMPLE_RESPONDENT_IMPORT_FILE_AJAX_SUCCESS";

// ------------ AUTHORIZATION ACTIONS ------------ //
export const UPDATE_CURRENT_CONTEXT = "UPDATE_CURRENT_CONTEXT";

// ------------ SPP ACTIONS ------------ //
export const LOAD_SPP_INSTANCES_AJAX_SUCCESS = "LOAD_SPP_INSTANCES_AJAX_SUCCESS";
//export const LOAD_SPP_DOCUMENT_PROGRESS_VIEW_MODELS_AJAX_SUCCESS = "LOAD_SPP_DOCUMENT_PROGRESS_VIEW_MODELS_AJAX_SUCCESS";
export const LOAD_SPP_DOCUMENT_ELEMENTS_AJAX_SUCCESS = "LOAD_SPP_DOCUMENT_ELEMENTS_AJAX_SUCCESS";
export const LOAD_SPP_DOCUMENT_VIEW_MODEL_AJAX_SUCCESS = "LOAD_SPP_DOCUMENT_VIEW_MODEL_AJAX_SUCCESS";
export const SET_SPP_SECTION_HIERARCHY = "SET_SPP_SECTION_HIERARCHY";
export const SAVE_SPP_RESPONSES_AJAX_SUCCESS = "SAVE_SPP_RESPONSES_AJAX_SUCCESS";
export const LOAD_SPP_RESPONSES_AJAX_SUCCESS = "LOAD_SPP_RESPONSES_AJAX_SUCCESS";
export const SUBMIT_SPP_RESPONSES_AJAX_SUCCESS = "SUBMIT_SPP_RESPONSES_AJAX_SUCCESS";
export const LOAD_RESPONDENT_BY_EMAIL_AJAX_SUCCESS = "LOAD_RESPONDENT_BY_EMAIL_AJAX_DASHBOARD_SUCCESS";
export const SUBMIT_SPP_ACCEPT_ELEMENT_RESPONSE_AJAX_SUCCESS = "SUBMIT_SPP_ACCEPT_ELEMENT_RESPONSE_AJAX_SUCCESS";
export const SUBMIT_SPP_ELEMENT_RESPONSE_REVISION_REQUIRED_AJAX_SUCCESS = "SUBMIT_SPP_ELEMENT_RESPONSE_REVISION_REQUIRED_AJAX_SUCCESS";
export const SUBMIT_SPP_REQUEST_AUDIT_AJAX_SUCCESS = "SUBMIT_SPP_REQUEST_AUDIT_AJAX_SUCCESS";
export const ADD_AUDIT_COMMENT_AJAX_SUCCESS = "ADD_AUDIT_COMMENT_AJAX_SUCCESS";
export const UPDATE_AUDIT_COMMENT_AJAX_SUCCESS = "UPDATE_AUDIT_COMMENT_AJAX_SUCCESS";
export const PRE_APPROVE_SPP_INSTITUTION_DOCUMENT_AJAX_SUCCESS = "PRE_APPROVE_SPP_INSTITUTION_DOCUMENT_AJAX_SUCCESS";
export const LOAD_SPP_POLICY_COMPLIANCE_AJAX_SUCCESS = "LOAD_SPP_POLICY_COMPLIANCE_AJAX_SUCCESS";
export const LOAD_POLICY_COMPLIANCES_AJAX_SUCCESS = "LOAD_POLICY_COMPLIANCES_AJAX_SUCCESS";
export const APPROVE_INSTITUTION_DOCUMENT_AJAX_SUCCESS = "APPROVE_INSTITUTION_DOCUMENT_AJAX_SUCCESS";
export const UNDO_APPROVE_AJAX_SUCCESS = "UNDO_APPROVE_AJAX_SUCCESS";
export const UNDO_PREAPPROVE_AJAX_SUCCESS = "UNDO_PREAPPROVE_AJAX_SUCCESS";
export const CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS = "CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS";
export const UNDO_CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS = "UNDO_CERTIFY_INSTITUTION_DOCUMENT_AJAX_SUCCESS";
export const CHANGE_POLICY_RESPONSE = "CHANGE_POLICY_RESPONSE";
export const CREATE_AUDIT_COMMENT = "CREATE_AUDIT_COMMENT";
export const EDIT_AUDIT_COMMENT = "EDIT_AUDIT_COMMENT";
export const CHANGE_AUDIT_COMMENT = "CHANGE_AUDIT_COMMENT";
export const CREATE_POLICY_RESPONSE = "CREATE_POLICY_RESPONSE";
export const LOAD_DISTRICT_STATUSES_AJAX_SUCCESS = "LOAD_DISTRICT_STATUSES_AJAX_SUCCESS";
export const EDIT_SPP_ELEMENT = "EDIT_SPP_ELEMENT";
export const SAVE_SPP_ELEMENT_AJAX_SUCCESS = "SAVE_SPP_ELEMENT_AJAX_SUCCESS";
export const ADD_SPP_ELEMENT_AJAX_SUCCESS = "ADD_SPP_ELEMENT_AJAX_SUCCESS";
export const DELETE_SPP_ELEMENT_AJAX_SUCCESS = "DELETE_SPP_ELEMENT_AJAX_SUCCESS";
export const CANCEL_EDIT_SPP_ELEMENT = "CANCEL_EDIT_SPP_ELEMENT";
export const SAVE_SPP_ELEMENT_ORDER_AJAX_SUCCESS = "SAVE_SPP_ELEMENT_ORDER_AJAX_SUCCESS";

export const COPY_SPP_DOCUMENT_AJAX_SUCCESS = "COPY_SPP_DOCUMENT_AJAX_SUCCESS";
export const COPY_SPP_RESPONSES_AJAX_SUCCESS = "COPY_SPP_RESPONSES_AJAX_SUCCESS";
export const SAVE_SPP_SECTION_COPY_STRATEGY_AJAX_SUCCESS = "SAVE_SPP_SECTION_COPY_STRATEGY_AJAX_SUCCESS";
export const CLEAR_SPP_DATA = "CLEAR_SPP_DATA";
export const LOAD_SPP_DISTRICT_TILES_AJAX_SUCCESS = "LOAD_SPP_DISTRICT_TILES_AJAX_SUCCESS";

// ------------ STRATEGIC PLAN ACTIONS -------------//
export const LOAD_FIVE_YEAR_REPORT_AJAX_SUCCESS = "LOAD_FIVE_YEAR_REPORT_AJAX_SUCCESS";
export const LOAD_PLANS_AJAX_SUCCESS = "LOAD_PLANS_AJAX_SUCCESS";
export const SAVE_INDICATORS_AJAX_SUCCESS = "SAVE_INDICATORS_AJAX_SUCCESS";
export const LOAD_STRATEGIES_AJAX_SUCCESS = "LOAD_STRATEGIES_AJAX_SUCCESS";
export const ADD_PLAN_AJAX_SUCCESS = "ADD_PLAN_AJAX_SUCCESS";
export const ADD_STRATEGY_AJAX_SUCCESS = "ADD_STRATEGY_AJAX_SUCCESS";
export const DELETE_STRATEGY_AJAX_SUCCESS = "DELETE_STRATEGY_AJAX_SUCCESS";
export const UPDATE_PLAN_FILTERS = "UPDATE_PLAN_FILTERS";
export const CREATE_STRATEGY_INDICATOR = "CREATE_STRATEGY_INDICATOR";
export const EDIT_STRATEGY_INDICATOR = "EDIT_STRATEGY_INDICATOR";
export const CREATE_INDICATOR = "CREATE_INDICATOR";
export const UPDATE_INDICATOR = "UPDATE_INDICATOR";
export const REMOVE_INDICATOR = "REMOVE_INDICATOR";
export const ADD_ACTION_AJAX_SUCCESS = "ADD_ACTION_AJAX_SUCCESS";
export const CREATE_STRATEGY_ACTION = "CREATE_STRATEGY_ACTION";
export const UPDATE_STRATEGY_ACTION = "UPDATE_STRATEGY_ACTION";
export const EDIT_STRATEGY_ACTION = "EDIT_STRATEGY_ACTION";
export const UPDATE_ACTION_AJAX_SUCCESS = "UPDATE_ACTION_AJAX_SUCCESS";
export const LOAD_ACTION_RESOURCES_AJAX_SUCCESS = "LOAD_ACTION_RESOURCES_AJAX_SUCCESS";
export const CLEAR_PLAN_DATA = "CLEAR_PLAN_DATA";
export const EDIT_STRATEGY_GOALS = "EDIT_STRATEGY_GOALS";
export const UPDATE_STRATEGY_GOALS = "UPDATE_STRATEGY_GOALS";
export const LOAD_STRATEGIC_GOALS_AJAX_SUCCESS = "LOAD_STRATEGIC_GOALS_AJAX_SUCCESS";
export const SAVE_STRATEGY_GOALS_AJAX_SUCCESS = "SAVE_STRATEGY_GOALS_AJAX_SUCCESS";
export const CREATE_SUBACTION = "CREATE_SUBACTION";
export const UPDATE_SUBACTION = "UPDATE_SUBACTION";
export const EDIT_SUBACTION = "EDIT_SUBACTION";
export const ADD_SUBACTION_AJAX_SUCCESS = "ADD_SUBACTION_AJAX_SUCCESS";
export const UPDATE_SUBACTION_AJAX_SUCCESS = "UPDATE_SUBACTION_AJAX_SUCCESS";
export const CREATE_PROGRESS_MONITORING_ACTIVITY = "CREATE_PROGRESS_MONITORING_ACTIVITY";
export const UPDATE_PROGRESS_MONITORING_ACTIVITY = "UPDATE_PROGRESS_MONITORING_ACTIVITY";
export const EDIT_PROGRESS_MONITORING_ACTIVITY = "EDIT_PROGRESS_MONITORING_ACTIVITY";
export const DELETE_PROGRESS_MONITORING_ACTIVITY_AJAX_SUCCESS = "DELETE_PROGRESS_MONITORING_ACTIVITY_AJAX_SUCCESS";
export const DELETE_SUBACTION_AJAX_SUCCESS = "DELETE_SUBACTION_AJAX_SUCCESS";
export const DELETE_ACTION_AJAX_SUCCESS = "DELETE_ACTION_AJAX_SUCCESS";
export const LOAD_PLAN_DATE_WINDOW_AJAX_SUCCESS = "LOAD_PLAN_DATE_WINDOW_AJAX_SUCCESS";
export const EDIT_TEAM_MEMBERS = "EDIT_TEAM_MEMBERS";
export const CREATE_TEAM_MEMBER = "CREATE_TEAM_MEMBER";
export const UPDATE_TEAM_MEMBER = "UPDATE_TEAM_MEMBER";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const SAVE_TEAM_MEMBERS_AJAX_SUCCESS = "SAVE_TEAM_MEMBERS_AJAX_SUCCESS";
export const UPDATE_BASELINE_METRIC = "UPDATE_BASELINE_METRIC";
export const UPDATE_METRIC = "UPDATE_METRIC";
export const CREATE_METRIC = "CREATE_METRIC";
export const EDIT_METRICS = "EDIT_METRICS";
export const DELETE_STRATEGY_INDICATOR_AJAX_SUCCESS = "DELETE_STRATEGY_INDICATOR_AJAX_SUCCESS";
export const ADD_INDICATOR_FILE = "ADD_INDICATOR_FILE";
export const REMOVE_INDICATOR_FILE = "REMOVE_INDICATOR_FILE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const EDIT_RESOURCE = "EDIT_RESOURCE";
export const CHANGE_RESOURCE = "CHANGE_RESOURCE";
export const ADD_RESOURCE_AJAX_SUCCESS = "ADD_RESOURCE_AJAX_SUCCESS";
export const UPDATE_RESOURCE_AJAX_SUCCESS = "UPDATE_RESOURCE_AJAX_SUCCESS";
export const DELETE_RESOURCE_AJAX_SUCCESS = "DELETE_RESOURCE_AJAX_SUCCESS";
export const CREATE_GOAL = "CREATE_GOAL";
export const EDIT_GOAL = "EDIT_GOAL";
export const CHANGE_GOAL = "CHANGE_GOAL";
export const ADD_GOAL_AJAX_SUCCESS = "ADD_GOAL_AJAX_SUCCESS";
export const UPDATE_GOAL_AJAX_SUCCESS = "UPDATE_GOAL_AJAX_SUCCESS";
export const DELETE_GOAL_AJAX_SUCCESS = "DELETE_GOAL_AJAX_SUCCESS";
export const CREATE_PLAN_DATE_WINDOW = "CREATE_PLAN_DATE_WINDOW";
export const CHANGE_PLAN_DATE_WINDOW = "CHANGE_PLAN_DATE_WINDOW";
export const DELETE_PLAN_DATE_WINDOW_AJAX_SUCCESS = "DELETE_PLAN_DATE_WINDOW_AJAX_SUCCESS";
export const COPY_PLAN_DATE_WINDOW_AJAX_SUCCESS = "COPY_PLAN_DATE_WINDOW_AJAX_SUCCESS";
export const UPDATE_PLAN_DATE_WINDOW_AJAX_SUCCESS = "UPDATE_PLAN_DATE_WINDOW_AJAX_SUCCESS";
export const IMPORT_SURVEY_RESPONDENTS_AJAX_SUCCESS = "IMPORT_SURVEY_RESPONDENTS_AJAX_SUCCESS";

// ------------ MONITORING FOR PERFORMANCE -------------//

//ASSESSMENT
export const LOAD_MONITORING_PERFORMANCE_DISTRICT_STATUSES_AJAX_SUCCESS ="LOAD_MONITORING_PERFORMANCE_DISTRICT_STATUSES_AJAX_SUCCESS";
export const SET_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT_ID = "SET_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT";
export const LOAD_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS = "LOAD_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS";
export const CLEAR_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT = "CLEAR_MONITORING_PERFORMANCE_SELECTED_ASSESSMENT";

export const CREATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS = "CREATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS";
export const UPDATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS = "UPDATE_MONITORING_PERFORMANCE_ASSESSMENT_AJAX_SUCCESS";

export const CANCEL_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS = "CANCEL_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS";
export const EDIT_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS = "EDIT_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS";
export const CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS = "CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DETAILS";



//INDICATOR
export const DELETE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS = "DELETE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS";
export const LOAD_MONITORING_ASSESSMENT_INDICATOR_ACTIONS_AJAX_SUCCESS = "LOAD_MONITORING_ASSESSMENT_INDICATOR_ACTIONS_AJAX_SUCCESS";
export const DELETE_MONITORING_ASSESSMENT_INDICATOR_ACTION_AJAX_SUCCESS = "DELETE_MONITORING_ASSESSMENT_INDICATOR_ACTION_AJAX_SUCCESS";
export const ADD_MONITORING_ASSESSMENT_INDICATOR = "ADD_MONITORING_ASSESSMENT_INDICATOR";
export const EDIT_MONITORING_ASSESSMENT_INDICATOR = "EDIT_MONITORING_ASSESSMENT_INDICATOR";
export const CREATE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS = "CREATE_MONITORING_ASSESSMENT_INDICATOR_AJAX_SUCCESS";
export const CANCEL_ADD_MONITORING_ASSESSMENT_INDICATOR = "CANCEL_ADD_MONITORING_ASSESSMENT_INDICATOR";

//ACTION
export const ADD_INDICATOR_ACTION = "ADD_INDICATOR_ACTION";
export const CANCEL_ADD_INDICATOR_ACTION = "CANCEL_ADD_INDICATOR_ACTION";
export const CHANGE_INDICATOR_ACTION = "CHANGE_INDICATOR_ACTION";
export const SAVE_INDICATOR_ACTION_AJAX_SUCCESS = "SAVE_INDICATOR_ACTION_AJAX_SUCCESS";
export const UPDATE_INDICATOR_ACTION_AJAX_SUCCESS = "UPDATE_INDICATOR_ACTION_AJAX_SUCCESS";
export const EDIT_INDICATOR_ACTION = "EDIT_INDICATOR_ACTION";
export const CREATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS = "CREATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS";
export const UPDATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS = "UPDATE_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS";
export const LOAD_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS = "LOAD_INDICATOR_ACTION_RESPONSE_AJAX_SUCCESS";
export const EDIT_INDICATOR_ACTION_RESPONSE = "EDIT_INDICATOR_ACTION_RESPONSE";
export const ADD_INDICATOR_ACTION_RESPONSE = "ADD_INDICATOR_ACTION_RESPONSE";
export const CHANGE_INDICATOR_ACTION_RESPONSE = "CHANGE_INDICATOR_ACTION_RESPONSE";
export const CANCEL_ADD_INDICATOR_ACTION_RESPONSE = "CANCEL_ADD_INDICATOR_ACTION_RESPONSE";
export const CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DISTRICT_ACCESS = "CHANGE_MONITORING_PERFORMANCE_ASSESSMENT_DISTRICT_ACCESS";
export const UPDATE_MONITORING_PERFORMANCE_DISTRICT_ACCESS_AJAX_SUCCESS = "UPDATE_MONITORING_PERFORMANCE_DISTRICT_ACCESS_AJAX_SUCCESS";
export const SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_SUBMIT_AJAX_SUCCESS = "SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_SUBMIT_AJAX_SUCCESS";
export const SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_REJECT_AJAX_SUCCESS = "SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_REJECT_AJAX_SUCCESS";
export const SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_APPROVE_AJAX_SUCCESS = "SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_APPROVE_AJAX_SUCCESS";
export const SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_RESET_AJAX_SUCCESS = "SUBMIT_INDICATOR_ACTION_RESPONSE_AUDIT_RESET_AJAX_SUCCESS";

// audit comments
export const CREATE_PERFORMANCE_AUDIT_COMMENT = "CREATE_PERFORMANCE_AUDIT_COMMENT";
export const EDIT_PERFORMANCE_AUDIT_COMMENT = "EDIT_PERFORMANCE_AUDIT_COMMENT";
export const CANCEL_PERFORMANCE_AUDIT_COMMENT = "CANCEL_PERFORMANCE_AUDIT_COMMENT";
export const CHANGE_PERFORMANCE_AUDIT_COMMENT = "CHANGE_PERFORMANCE_AUDIT_COMMENT";
export const ADD_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS = "ADD_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS";
export const UPDATE_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS = "UPDATE_PERFORMANCE_AUDIT_COMMENT_AJAX_SUCCESS";
export const CREATE_COMPLIANCE_AUDIT_COMMENT = "CREATE_COMPLIANCE_AUDIT_COMMENT";
export const EDIT_COMPLIANCE_AUDIT_COMMENT = "EDIT_COMPLIANCE_AUDIT_COMMENT";
export const CANCEL_COMPLIANCE_AUDIT_COMMENT = "CANCEL_COMPLIANCE_AUDIT_COMMENT";
export const CHANGE_COMPLIANCE_AUDIT_COMMENT = "CHANGE_COMPLIANCE_AUDIT_COMMENT";
export const ADD_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS = "ADD_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS";
export const UPDATE_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS = "UPDATE_COMPLIANCE_AUDIT_COMMENT_AJAX_SUCCESS";



// --------------- MONITORING FOR COMPLIANCE ------------------//
export const LOAD_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "LOAD_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const CREATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "CREATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const UPDATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "UPDATE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const DELETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "DELETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const SAVE_DISTRICT_ASSIGNED_PROTOCOL_AJAX_SUCCESS = "SAVE_DISTRICT_ASSIGNED_PROTOCOL_AJAX_SUCCESS";
export const CLEAR_COMPLIANCE_MONITORING_SELECTED_ASSESSMENT = "CLEAR_COMPLIANCE_MONITORING_SELECTED_ASSESSMENT";
export const EDIT_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION = "EDIT_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION";
export const CANCEL_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION = "CANCEL_COMPLIANCE_MONITORING_ASSESSMENT_CLASSIFICATION";
export const ADD_COMPLIANCE_PROTOCOL_REQUIREMENT = "ADD_COMPLIANCE_PROTOCOL_REQUIREMENT";
export const EDIT_COMPLIANCE_PROTOCOL_REQUIREMENT = "EDIT_COMPLIANCE_PROTOCOL_REQUIREMENT";
export const CHANGE_COMPLIANCE_PROTOCOL_REQUIREMENT = "CHANGE_COMPLIANCE_PROTOCOL_REQUIREMENT";
export const CANCEL_COMPLIANCE_PROTOCOL_REQUIREMENT_EDIT = "CANCEL_COMPLIANCE_PROTOCOL_REQUIREMENT_EDIT";
export const CLEAR_MONITORING_COMPLIANCE_DATA = "CLEAR_MONITORING_COMPLIANCE_DATA";
export const LOAD_DISTRICT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "LOAD_DISTRICT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const LOAD_INDICATOR_PROTOCOLS_AJAX_SUCCESS = "LOAD_INDICATOR_PROTOCOLS_AJAX_SUCCESS";
export const SET_COMPLIANCE_EDIT_MODE = "SET_COMPLIANCE_EDIT_MODE ";
export const SAVE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS = "SAVE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS";
export const SAVE_DISTRICT_REASSESSMENT_AJAX_SUCCESS = "SAVE_DISTRICT_REASSESSMENT_AJAX_SUCCESS";
export const DELETE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS = "DELETE_DISTRICT_STUDENT_RECORD_AJAX_SUCCESS";
export const REMOVE_DISTRICT_STUDENT_RECORD_VALIDATION_AJAX_SUCCESS = "REMOVE_DISTRICT_STUDENT_RECORD_VALIDATION_AJAX_SUCCESS";
export const VALIDATION_COMPLETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "VALIDATION_COMPLETE_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const UPDATE_VALIDATION_NOTES_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "UPDATE_VALIDATION_NOTES_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const UNSUBMIT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "UNSUBMIT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const UNSUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORD_AJAX_SUCCESS = "UNSUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORD_AJAX_SUCCESS";
export const UNSUBMIT_VALIDATION_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "UNSUBMIT_VALIDATION_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const UNSUBMIT_REASSESSMENT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS = "UNSUBMIT_REASSESSMENT_COMPLIANCE_ASSESSMENT_AJAX_SUCCESS";
export const SUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORDS_AJAX_SUCCESS = "SUBMIT_COMPLIANCE_ASSESSMENT_STUDENT_RECORDS_AJAX_SUCCESS";
export const LOAD_DISTRICT_COMPLIANCE_STATUSES_AJAX_SUCCESS = "LOAD_DISTRICT_COMPLIANCE_STATUSES_AJAX_SUCCESS";

// ---------------------- STUDENT CORRECTIVE ACTIONS ---------------------//
export const LOAD_COMPLIANCE_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS = "LOAD_COMPLIANCE_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS";
export const SAVE_DISTRICT_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS = "SAVE_DISTRICT_STUDENT_CORRECTIVE_RESULTS_AJAX_SUCCESS";
export const CHANGE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT = "CHANGE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT";
export const SUBMIT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS = "SUBMIT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const REJECT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS = "REJECT_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const APPROVE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS = "APPROVE_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const RESET_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS = "RESET_COMPLIANCE_STUDENT_CORRECTIVE_RESULT_AJAX_SUCCESS";

// ---------------------- MATRIX CORRECTIVE ACTIONS ---------------------//
export const LOAD_COMPLIANCE_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS = "LOAD_COMPLIANCE_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS";
export const SAVE_DISTRICT_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS = "SAVE_DISTRICT_MATRIX_CORRECTIVE_RESULTS_AJAX_SUCCESS";
export const CHANGE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT = "CHANGE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT";
export const SUBMIT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS = "SUBMIT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const REJECT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS = "REJECT_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const APPROVE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS = "APPROVE_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS";
export const RESET_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS = "RESET_COMPLIANCE_MATRIX_CORRECTIVE_RESULT_AJAX_SUCCESS";

// ----------------- COMPLIANCE CASE NOTE -----------------------//
export const DELETE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS = "DELETE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS";
export const SAVE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS = "SAVE_COMPLIANCE_CASE_NOTE_AJAX_SUCCESS";
export const EXPORT_COMPLIANCE_CASE_NOTES_AJAX_SUCCESS = "EXPORT_COMPLIANCE_CASE_NOTES_AJAX_SUCCESS";

// ------------ BPIE DATA ACTIONS ------------ //
export const LOAD_FIN_FACILITATOR_DISTRICTS_AJAX_SUCCESS = "LOAD_FIN_FACILITATOR_DISTRICTS_AJAX_SUCCESS";