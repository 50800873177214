import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {cloneDeep} from "lodash/lang";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import {removeDomainForInternalRoute} from "../../components/common/routingUtilities";
import LocalStorage from "../../components/shared/LocalStorage";
import {DisputeResolutionType} from "../drConstants";
import * as drLocations from "../drLocations";
import * as searchCriteriaActions from '../../actions/searchCriteriaActions';
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import DrApi from "../drApi";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import {DrFilter} from "../DrComponents/DrFilter";
import {DrNavigation} from "../DrComponents/DrNavigation";
import DrSummaryView from "./DrSummaryView";

const DrDistrictSummaryContainer = ({
                                        actions,
                                        currentLocation,
                                        dateWindowsAnnualList: dateWindows,
                                        defaultDateWindowId,
                                        history,
                                        searchCriteria
                                    }) => {
    const [summary, setSummary] = useState(null);
    const [caseload, setCaseload] = useState(null);

    const handleSearchFiltersChange = async (criteria, reloadsData) => {
        if (reloadsData) {
            criteria.clearOptionalSearchItems();
            await initializeData(criteria);
        } else
            actions.saveSearchCriteria(criteria);
    };

    const setDateRangeSearchCriteria = async (localSearchCriteria) => {
        let criteria = {...searchCriteria};
        criteria.dateRange = localSearchCriteria.dateRange;
        criteria.endDate = localSearchCriteria.endDate;
        criteria.startDate = localSearchCriteria.startDate;

        await handleSearchFiltersChange(criteria, false);
    };

    const setSearchCriteria = async (localSearchCriteria, summarySearch) => {
        const teamMembers = await actions.executeApi(DrApi.getSummaryTeamMembers, [searchCriteria.dateWindowId, summarySearch.type]);
        const currentUserId = LocalStorage.getLoggedInUser().Id;
        const teamMemberAccount = teamMembers.find(f => f.userId === currentUserId);

        let criteria = {...searchCriteria};
        criteria.dateRange = localSearchCriteria.dateRange;
        criteria.disputeResolutionType = summarySearch.type;
        criteria.district = summarySearch.districtName;
        criteria.endDate = localSearchCriteria.endDate;
        criteria.startDate = localSearchCriteria.startDate;
        criteria.status = summarySearch.status;
        criteria.teamMember = !teamMemberAccount ? "" : currentUserId;
        criteria.teamMemberRole = summarySearch.role;

        await handleSearchFiltersChange(criteria, false);
    };

    const handleClickGeneral = (dateWindowId, summarySearch) => {
        if(!summarySearch.url) {
            switch (summarySearch.type) {
                case DisputeResolutionType.StateComplaints:
                    summarySearch.url = drLocations.STATE_COMPLAINT_OVERVIEW_DOE.getUrl(dateWindowId, summarySearch.districtId, summarySearch.id);
                    break;
                case DisputeResolutionType.Mediation:
                    summarySearch.url = drLocations.MEDIATION_OVERVIEW.getUrl(dateWindowId, summarySearch.districtId, summarySearch.id);
                    break;
                case DisputeResolutionType.DueProcess:
                    summarySearch.url = drLocations.DUE_PROCESS_OVERVIEW.getUrl(dateWindowId, summarySearch.districtId, summarySearch.id);
                    break;
            }
        }

        if (summarySearch.url)
            history.push(removeDomainForInternalRoute(summarySearch.url));
    };

    const initializeData = async (criteria) => {
        const {dateWindowId} = criteria;
        if (!dateWindowId)
            return;

        let result = await actions.executeApi(DrApi.getDrSummaryData, [dateWindowId]);
        setSummary(result);

        result = await actions.executeApi(DrApi.getCaseloadData, [dateWindowId, LocalStorage.getLoggedInUser().Id]);
        setCaseload(result);

        actions.saveSearchCriteria(criteria);
    };

    const updatePinnedItems = async (id, type) => {
        let summaryData = cloneDeep(summary);
        let record = summaryData.find(f => f.id === id);
        if(record) {
            record.isPinned = !record.isPinned;

            setSummary(summaryData);
            await DrApi.saveDrSummaryPins(id, type);
        }
    };

    useEffect(() => {
        if (!defaultDateWindowId) return;

        let criteria = {...searchCriteria};

        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrSummary);

        initializeData(criteria);

    }, [defaultDateWindowId]);

    useEffect(() => {
        actions.updatePageTitle(`My Summary`);
    }, []);

    if (!searchCriteria || !searchCriteria.areRulesCurrent(DrFilterVisibilityRules.DrSummary) || !caseload)
        return null;

    return (
        <>
            <DrFilter
                dateWindows={dateWindows}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <DrSummaryView
                caseload={caseload}
                drSummarySearchCriteria={searchCriteria}
                handleClickGeneral={handleClickGeneral}
                history={history}
                setDateRangeSearchCriteria={setDateRangeSearchCriteria}
                setDrSummarySearchCriteria={setSearchCriteria}
                summary={summary}
                updatePinnedItems={updatePinnedItems}
            />

            <DrNavigation currentLocation={currentLocation} history={history}/>
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
        ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    const currentLocation = props.location.pathname;

    return {
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrDistrictSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    currentLocation: PropTypes.string,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrDistrictSummaryContainer);

