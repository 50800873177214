import PropTypes from "prop-types";
import React from "react";
import {isNullOrUndefined} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import {CheckBoxField} from "../../../../components/common/inputs";
import {IndicatorUpdatedAnnotations, IndicatorUpdatedMessage} from "../../bpieConstants";

export const BpieDistrictPieDetailsPriorityIndicators = ({
                                                             dbpiePriorityIndicators,
                                                             indicatorsByGoal,
                                                             isEditable,
                                                             sbpieIndicators,
                                                             sbpieTopIndicatorsEndYear,
                                                             sbpieTopIndicatorsStartYear,
                                                             selectedSbpieTopPriorityIndicators,
                                                             setSelectedSbpieTopPriorityIndicators
                                                         }) => {
    const handleSbpieIndicatorChange = (event) => {
        const indicatorId = event.target.value;
        const isChecked = event.target.checked;
        let updatedIndicators = [...selectedSbpieTopPriorityIndicators];

        if (isChecked && !selectedSbpieTopPriorityIndicators.some(i => i.bpieIndicatorId === indicatorId)) {
            const indicator = sbpieIndicators.find(i => i.bpieIndicatorId === indicatorId);
            if(!isNullOrUndefined(indicator))
                updatedIndicators.push(indicator);
        }
        else if(!isChecked)
            updatedIndicators = updatedIndicators.filter(i => i.bpieIndicatorId !== indicatorId);

        setSelectedSbpieTopPriorityIndicators(updatedIndicators);
    }

    const buildAssociatedGoalsString = (indicator) => {
        const associatedGoals = indicatorsByGoal.filter(i => i.priorityIndicatorIds.some(s => s === indicator.bpieIndicatorId));
        const goalNumbers = associatedGoals.map(i => i.goalNumber);
        const goalList = goalNumbers.length > 2
            ? goalNumbers.slice(0, -1).join(", ") + ", and " + goalNumbers[goalNumbers.length - 1]
            : goalNumbers.length === 2 ? `${goalNumbers[0]} and ${goalNumbers[1]}` : goalNumbers[0];
        const goalSuffix = associatedGoals.length > 1 ? "s" : "";
        return associatedGoals.length > 0 ? `<br/><strong>Currently associated with Goal${goalSuffix} ${goalList}</strong>` : "";
    }

    const anyInactiveIndicators = sbpieIndicators.some(i => !i.isIndicatorActive);

    return <>
        <GridTable>
            <thead>
            <tr>
                <th>District BPIE Priority Indicators</th>
            </tr>
            </thead>
            <tbody>
            {
                dbpiePriorityIndicators.map((indicator, key) => {
                    return <tr key={`dbpie_${key}`}>
                        <td>
                            <strong>Indicator {indicator.indicatorNumber}:</strong> {indicator.indicatorSummary}
                        </td>
                    </tr>;
                })
            }
            {
                dbpiePriorityIndicators.length === 0 &&
                <tr>
                    <td>
                        <strong>No District BPIE Priority Indicators have been set.</strong>
                    </td>
                </tr>
            }
            </tbody>
        </GridTable>

        <GridTable>
            <thead>
            <tr>
                <th>Top School BPIE Priority Indicators ({sbpieTopIndicatorsStartYear} - {sbpieTopIndicatorsEndYear})</th>
            </tr>
            </thead>
            <tbody>
            <>
                {
                    anyInactiveIndicators &&
                    <tr className={`highlight-row`}>
                        <td>{IndicatorUpdatedMessage}</td>
                    </tr>
                }
                {
                    sbpieIndicators.map((indicator, key) => {
                        const isChecked = selectedSbpieTopPriorityIndicators.some(i => i.bpieIndicatorId === indicator.bpieIndicatorId);
                        const inactiveMessage = indicator.isIndicatorActive ? "" : ` ${IndicatorUpdatedAnnotations}`;

                        return <tr key={`sbpie_${key}`}>
                            <td>
                                <CheckBoxField
                                    showLabel
                                    name={`indicator_${indicator.bpieIndicatorId}`}
                                    label={`<strong>Indicator ${indicator.indicatorNumber}:</strong> ${indicator.indicatorSummary} &nbsp; <strong>${indicator.percentageDescription}</strong>${inactiveMessage}${buildAssociatedGoalsString(indicator)}`}
                                    disabled={!isEditable}
                                    onChange={handleSbpieIndicatorChange}
                                    value={indicator.bpieIndicatorId}
                                    checked={isChecked}
                                />

                            </td>
                        </tr>;
                    })
                }
            </>
            </tbody>
        </GridTable>
    </>
        ;
};

BpieDistrictPieDetailsPriorityIndicators.propTypes = {
    dbpiePriorityIndicators: PropTypes.array.isRequired,
    indicatorsByGoal: PropTypes.array.isRequired,
    isEditable: PropTypes.bool.isRequired,
    sbpieIndicators: PropTypes.array.isRequired,
    sbpieTopIndicatorsEndYear: PropTypes.number.isRequired,
    sbpieTopIndicatorsStartYear: PropTypes.number.isRequired,
    selectedSbpieTopPriorityIndicators: PropTypes.array.isRequired,
    setSelectedSbpieTopPriorityIndicators: PropTypes.func.isRequired
};