import {mockResolveApiCall} from "../../../../api/ApiUtility";
import * as ApiUtility from "../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../api/requests/RequestTypes";
import * as config from "../../../../constants/config";
import {API_MESSAGES} from "../../gmsConstants";
import {API_ROOT} from "../../gmsLocations";
import * as mockData from "../../gmsMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsManageApplicationsApi {
    static getMainSections() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/mainSections`,
                RequestTypes.GET)
        );
    }

    static getGmsSettings(dateWindowId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/dates/${dateWindowId}/settings`,
                RequestTypes.GET)
        );
    }

    static saveMainSections(updatedSubsections) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/mainSections`,
                RequestTypes.PUT,
                JSON.stringify(updatedSubsections),
                API_MESSAGES.ADMIN.GMS_APPLICATION_SECTIONS.saved)
        );
    }

    static saveGmsSettings(dateWindowId, updatedGmsSettings) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/dates/${dateWindowId}/settings`,
                RequestTypes.PUT,
                JSON.stringify(updatedGmsSettings),
                API_MESSAGES.ADMIN.GMS_SETTINGS.saved)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsManageApplicationsApi {
    static getMainSections() {
        return mockResolveApiCall(mockData.getMainSections());
    }

    static getGmsSettings() {
        return mockResolveApiCall(mockData.getGmsSettings());
    }

    static saveMainSections() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.GMS_APPLICATION_SECTIONS.saved);
    }

    static saveGmsSettings() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.GMS_SETTINGS.saved);
    }
}

const GmsManageApplicationsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsManageApplicationsApi : MockGmsManageApplicationsApi;
export default GmsManageApplicationsApi;