import React from "react";
import PropTypes from "prop-types";
import ActionBar from "../../../components/common/buttons/ActionBar";
import DisplayField from "../../../components/common/inputs/DisplayField";
import DrStateComplaintActionDeadlines from "./DrStateComplaintActionDeadlines";
import DrStateComplaintStudent from "../Students/DrStateComplaintStudent";
import FormTable from "../../../components/common/FormTable";
import * as drStateComplaintConstants from "../drStateComplaintConstants";
import {isArrayNullOrEmpty} from "../../../components/common/commonUtilities";

const DrStateComplaintIssueComponent = ({
	                                        action,
	                                        isEditMode,
	                                        onClickDelete: handleClickDelete,
	                                        onClickEdit: handleClickEdit,
	                                        readOnly
                                        }) => {

	return (
		<div>
			{
				!readOnly &&
				<ActionBar
					elementId={`issue-${action.id}`}
					onEditClick={() => handleClickEdit(action.id)}
					onDeleteClick={(e) => handleClickDelete(e, action.id)}
					disabled={isEditMode}
				/>
			}
			<FormTable>
				<DisplayField
					columnsMedium={6}
					name="actionType"
					label="Type"
					showLabel
				>
					{
						action.actionType ? action.actionType : drStateComplaintConstants.ComplaintActionType.ToBeCompleted
					}
				</DisplayField>
				{
					action.description &&
					<DisplayField
						name="description"
						label="Description"
						showLabel
					>
						{action.description}
					</DisplayField>
				}
				<DrStateComplaintActionDeadlines
					deadlines={action.deadlines}
				/>

				{
					!isArrayNullOrEmpty(action.students) &&
					<DrStateComplaintStudent
						students={action.students}
					/>
				}
			</FormTable>
		</div>
	);
};

DrStateComplaintIssueComponent.propTypes = {
	action: PropTypes.object.isRequired,
	isEditMode: PropTypes.bool.isRequired,
	onClickDelete: PropTypes.func.isRequired,
	onClickEdit: PropTypes.func.isRequired,
	readOnly: PropTypes.bool.isRequired,
};

export default DrStateComplaintIssueComponent;