import React from "react";
import PropTypes from "prop-types";
import Button from "../../../components/common/buttons/Button";
import ButtonBar from "../../../components/common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import FormTable from "../../../components/common/FormTable";
import DisplayField from "../../../components/common/inputs/DisplayField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import SelectField from "../../../components/common/inputs/SelectField";
import TextAreaEditor from "../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as ButtonBarPositions from "../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {
    IntakeRequirementType,
    IntakeRequirementTypeDetailsList,
    IntakeRequirementTypeList
} from "../../StateComplaints/drStateComplaintConstants";

const DrAdminIntakeRequirementEdit = ({
                                          conditionalRequirements,
                                          handleSaveIntakeRequirement,
                                          intakeRequirement,
                                          setEditRequirementId
                                      }) => {
    const intakeRequirementForm = useFormInputObject({...intakeRequirement});

    const onSaveClick = (event) => {
        event.preventDefault();
        saveIntakeRequirement();
    };

    const onCancelClick = (event) => {
        event.preventDefault();
        setEditRequirementId(null);
    };

    const validateForm = () => {
        clearInputFormErrorFields(intakeRequirementForm);

        if (isTrimmedStringEmpty(intakeRequirementForm.requirementDescription.value))
            intakeRequirementForm.requirementDescription.setError("Required.")

        if (isTrimmedStringEmpty(intakeRequirementForm.requirementType.value.toString()))
            intakeRequirementForm.requirementType.setError("Required.")

        if (isConditionalOptional() && isTrimmedStringEmpty(intakeRequirementForm.conditionalParentId.value.toString()))
            intakeRequirementForm.conditionalParentId.setError("Required.")

        let isValid = inputFormIsValid(intakeRequirementForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const saveIntakeRequirement = () => {
        const isValid = validateForm();
        if (isValid) {
            let result = convertFormInputObjectToObject(intakeRequirementForm);
            result.conditionalParentId = isConditionalOptional() ? result.conditionalParentId : "";
            handleSaveIntakeRequirement(result);
        }
    };

    const getTypeDetails = () => {
        const typeDetails = IntakeRequirementTypeDetailsList.find(f => f.value.toString() === intakeRequirementForm.requirementType.value.toString());
        return typeDetails ? typeDetails.text : "";
    }

    const isConditionalOptional = () => {
        return intakeRequirementForm.requirementType.value.toString() === IntakeRequirementType.ConditionalOptional.toString();
    }

    return (
        <>
            <FormTable>
                <DisplayField
                    label={`Display Order`}
                    name={`lblOrder`}
                    showLabel
                >
                    {intakeRequirement.displayOrder.toString()}
                </DisplayField>
                <TextAreaEditor
                    label={`Description`}
                    showLabel
                    {...intakeRequirementForm.requirementDescription}
                />
                <SelectField
                    label={`Type`}
                    options={IntakeRequirementTypeList}
                    showLabel
                    {...intakeRequirementForm.requirementType}
                />
                <DisplayField
                    label={`Type Details`}
                    name={`lblTypeDetails`}
                    showLabel
                >
                    {getTypeDetails()}
                </DisplayField>
                {
                    isConditionalOptional() &&
                    <>
                        {
                            conditionalRequirements.length === 0 &&
                            <DisplayField
                                label={`Missing Conditional Requirement`}
                                name={`lblMissingConditionalRequirement`}
                                showLabel={false}
                            >
                                <strong className={`color--alert`}>At least one conditional requirement is required before adding a conditional optional requirement.</strong>
                            </DisplayField>
                        }
                        <SelectField
                            label={`Conditional requirement that this optional requirement depends upon?`}
                            options={conditionalRequirements}
                            showLabel
                            {...intakeRequirementForm.conditionalParentId}
                        />
                    </>
                }
            </FormTable>
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={onSaveClick}/>
                <Button name="btnCancel"
                        label="Cancel"
                        buttonType={ButtonTypes.CANCEL}
                        onClick={onCancelClick}/>
            </ButtonBar>
        </>
    );
};

DrAdminIntakeRequirementEdit.propTypes = {
    conditionalRequirements: PropTypes.array,
    handleSaveIntakeRequirement: PropTypes.func.isRequired,
    intakeRequirement: PropTypes.object.isRequired,
    setEditRequirementId: PropTypes.func.isRequired
};

export default DrAdminIntakeRequirementEdit;