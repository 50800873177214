import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {
    isArrayNullOrEmpty,
    isNullOrUndefined,
    isTrimmedStringEmpty
} from "../../../../components/common/commonUtilities";
import GridTable from "../../../../components/common/GridTable";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid, reinitializeInputFormFields,
    useFormInputObject,
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import Tile from "../../../../components/common/Tiles/Tile";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import {emptyGuid} from "../../../../constants/config";
import {createDiscretionaryProjectObject} from "./gmsManageDiscretionaryProjectsObjectFactory";
import {GmsManageDiscretionaryProjectsTableRowDisplay} from "./GmsManageDiscretionaryProjectsTableRowDisplay";
import {GmsManageDiscretionaryProjectsTableRowEdit} from "./GmsManageDiscretionaryProjectsTableRowEdit";
import * as gmsLocations from "../../gmsLocations";

export const GmsManageDiscretionaryProjectsView = ({
                                                       actions,
                                                       dataChanged,
                                                       discretionaryProjects,
                                                       gotoLocation,
                                                       handleDataChanged,
                                                       handleDeleteGmsDiscretionaryProject,
                                                       handleSaveGmsDiscretionaryProject,
                                                       searchCriteria
                                                   }) => {
    const projectForm = useFormInputObject(createDiscretionaryProjectObject());
    const [discretionaryProjectIdEditing, setDiscretionaryProjectIdEditing] = useState(null);

    const handleClickOrder = (event) => {
        let criteria = {...searchCriteria};
        criteria.UpdateSorting(event.target.dataset.id, event.target.dataset.sorttype || "");

        actions.saveSearchCriteria(criteria);
    };

    const isValidForm = () => {
        clearInputFormErrorFields(projectForm);

        if (isTrimmedStringEmpty(projectForm.projectName.value.toString()))
            projectForm.projectName.setError("Required");

        let isValid = inputFormIsValid(projectForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const handleSaveProjectClick = async (event) => {
        event.preventDefault();
        if (!isValidForm())
            return;

        const project = convertFormInputObjectToObject(projectForm);

        await handleSaveGmsDiscretionaryProject(discretionaryProjectIdEditing, project);
        setDiscretionaryProjectIdEditing(null);
    };

    const handleCancelProjectClick = (event) => {
        event.preventDefault();
        handleDataChanged(false);
        setDiscretionaryProjectIdEditing(null);
    };

    const handleAddProjectClick = (event) => {
        event.preventDefault();
        reinitializeInputFormFields(projectForm, createDiscretionaryProjectObject());
        setDiscretionaryProjectIdEditing(emptyGuid);
        handleDataChanged(true);
    };

    const handleModifyProjectClick = (event, project) => {
        event.preventDefault();

        reinitializeInputFormFields(projectForm, project);
        setDiscretionaryProjectIdEditing(project.discretionaryProjectId);
        handleDataChanged(true);
    };

    const handleDeleteProjectClick = (event, projectId) => {
        event.preventDefault();
        handleDeleteGmsDiscretionaryProject(projectId);
    };

    const handleViewProjectClick = async (event, projectId) => {
        event.preventDefault();
        gotoLocation(gmsLocations.GMS_ADMIN_MANAGE_FISCAL_AGENTS_USERS.getUrl(projectId));
    };

    useEffect(() => {
        if(!dataChanged && !isNullOrUndefined(discretionaryProjectIdEditing))
            setDiscretionaryProjectIdEditing(null);
    }, [dataChanged]);

    const rowsDisabled = !isNullOrUndefined(discretionaryProjectIdEditing);
    const isNewDiscretionaryProject = discretionaryProjectIdEditing === emptyGuid;
    const filteredProjects = searchCriteria.applyFiltersAndOrder(discretionaryProjects);
    const totalColums = 2;

    return (
        <>
            <GridTable>
                <thead>
                <tr>
                    <th onClick={handleClickOrder} data-id={`projectName`} className={`is-clickable`}>
                        Project
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(filteredProjects) && !isNewDiscretionaryProject &&
                    <tr>
                        <td colSpan={totalColums} className={"center"}>
                            No discretionary projects found.
                        </td>
                    </tr>
                }
                {
                    filteredProjects.map((project, key) => {
                        if (project.discretionaryProjectId === discretionaryProjectIdEditing)
                            return <GmsManageDiscretionaryProjectsTableRowEdit
                                discretionaryProject={projectForm}
                                handleCancelProjectClick={handleCancelProjectClick}
                                handleSaveProjectClick={handleSaveProjectClick}
                                key={key}
                            />;

                        return <GmsManageDiscretionaryProjectsTableRowDisplay
                            discretionaryProject={project}
                            handleDeleteProjectClick={handleDeleteProjectClick}
                            handleModifyProjectClick={handleModifyProjectClick}
                            handleViewProjectClick={handleViewProjectClick}
                            key={key}
                            rowDisabled={rowsDisabled}
                        />;
                    })
                }
                {
                    isNewDiscretionaryProject &&
                    <GmsManageDiscretionaryProjectsTableRowEdit
                        discretionaryProject={projectForm}
                        handleCancelProjectClick={handleCancelProjectClick}
                        handleSaveProjectClick={handleSaveProjectClick}
                        isNewDiscretionaryProject
                    />
                }
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan={totalColums}>
                        <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                            <Tile body={"Add Discretionary Project"}
                                  onClick={handleAddProjectClick}
                                  isNew={true}
                                  isMedium={true}
                            />
                        </TileContainer>
                    </td>
                </tr>
                </tfoot>
            </GridTable>
        </>
    );
};

GmsManageDiscretionaryProjectsView.propTypes = {
    actions: PropTypes.object.isRequired,
    dataChanged: PropTypes.bool.isRequired,
    discretionaryProjects: PropTypes.array,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDeleteGmsDiscretionaryProject: PropTypes.func.isRequired,
    handleSaveGmsDiscretionaryProject: PropTypes.func.isRequired,
    searchCriteria: PropTypes.object.isRequired
};