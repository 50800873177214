import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import PrintTable from "../../../components/common/PrintTable";
import api from "../drStateComplaintsApi";
import { CaseResearchVariableStatus } from "../../drConstants";
import TrackingSheetRow from "./TrackingSheetRow";
import {outputCurrentDate} from "../../../components/shared/sharedDataUtilities";

const TrackingSheetPrintView = ({
	                                actions,
	                                dateWindowId,
	                                districts
                                }) => {
	const [complaints, setComplaints] = useState(null);
	const [timelineColumns, setTimelineColumns] = useState([]);

	const handleUpdateComplaint = (complaints) => {
		setComplaints(complaints);
		buildTimelineColumns(complaints);
	};

	const buildTimelineColumns = (complaints) => {
		let columns = [];
		for(let complaint of complaints) {
			for(let timelineResponse of complaint.timelineModel.timelineResponseModels) {
				if(!columns.find(f => f.timelineRequirementId === timelineResponse.timelineRequirementId))
					columns.push({
						activity: timelineResponse.activity,
						timelineRequirementId: timelineResponse.timelineRequirementId,
						displayOrder: timelineResponse.displayOrder
					});
			}
		}

		setTimelineColumns(columns);
	};

	const getComplaints = async () => {
		await actions.executeApi(api.getComplaints, [dateWindowId, CaseResearchVariableStatus.StateComplaint.Open])
			.then((results) => handleUpdateComplaint(results));
	};

	useEffect(() => {
		getComplaints();
	}, []);

	if (!complaints)
		return null;

	return (
		<>
			<div className={"report-header"}>
				<h2>Open Complaints Only — {outputCurrentDate()} State Complaints Tracking Sheet</h2>
				<h2>Statewide Report</h2>
			</div>
			<PrintTable>
				<thead>
				<tr>
					<th>Complainant</th>
					<th>District</th>
					<th>Lead Investigator</th>
					<th>Backup Investigator</th>
					<th>Director</th>
					<th>BEESS Received</th>
					<th>Date Filed/Day 1</th>
					<th>Extension Date</th>
					{
						timelineColumns.map((column, index) => {
							return (
								<th key={index}>{column.activity}</th>
							);
						})
					}
					<th>Day 60/90</th>
				</tr>
				</thead>
				<tbody>
				{
					complaints.map((complaint, key) => {
						const districtName = districts.find(f => f.id === complaint.districtId).name;
						return (
							<TrackingSheetRow complaint={complaint} districtName={districtName} key={key}
							                  rowIndex={key} timelineColumns={timelineColumns}/>
						);
					})
				}
				</tbody>
			</PrintTable>
		</>
	);
};

TrackingSheetPrintView.propTypes = {
	actions: PropTypes.object,
	dateWindowId: PropTypes.string.isRequired,
	districts: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TrackingSheetPrintView;