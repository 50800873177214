import SppApi from "../api/SppApi";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";

//LOAD SPP DISTRICT TILES
export function loadSppDistrictTilesSuccess(tiles){
	return { type: actionTypes.LOAD_SPP_DISTRICT_TILES_AJAX_SUCCESS, data: tiles };
}

export function loadSppDistrictTiles(institutionId, dateWindowGroupingId, routeUrl){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.getSppDistrictTiles(institutionId, dateWindowGroupingId, routeUrl)
			.then(tiles => {
				dispatch(loadSppDistrictTilesSuccess(tiles));
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
			});
	};
}

//CLEAR SPP DOCUMENTS
export function clearSppData(){
	return { type: actionTypes.CLEAR_SPP_DATA };
}

//LOAD SPP INSTANCES
export function loadSppInstancesSucess(instances){
	return { type: actionTypes.LOAD_SPP_INSTANCES_AJAX_SUCCESS, instances};
}

export function loadSppInstances(institutionId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetSppInstances(institutionId)
			.then(instances => {
				dispatch(loadSppInstancesSucess(instances));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

//GET THE DOCUMENT
export function loadSppDocumentSuccess(document){
	return { type: actionTypes.LOAD_SPP_DOCUMENT_VIEW_MODEL_AJAX_SUCCESS, document};
}

export function loadSppDocument(documentId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetSppDocument(documentId)
			.then(elements => {
				dispatch(loadSppDocumentSuccess(elements));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

//GET THE DOCUMENT ELEMENTS
export function loadSppDocumentElementsSuccess(elements){
	return { type: actionTypes.LOAD_SPP_DOCUMENT_ELEMENTS_AJAX_SUCCESS, elements};
}

export function loadSppDocumentElements(documentId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetSppDocumentElements(documentId)
			.then(elements => {
				dispatch(loadSppDocumentElementsSuccess(elements));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

//SAVE RESPONSES
export function saveSppResponsesSuccess(){
	return { type: actionTypes.SAVE_SPP_RESPONSES_AJAX_SUCCESS };
}

export function saveSppResponses(documentId, responses, markReadyToSubmit=false){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.SaveSppResponses(documentId, responses, markReadyToSubmit)
			.then(() => {
				dispatch(saveSppResponsesSuccess());
				return true;
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					return false;
				}
			);
	};
}

//GET THE INSTITUTION RESPONSES
export function loadInstitutionResponsesSuccess(responses){
	return { type: actionTypes.LOAD_SPP_RESPONSES_AJAX_SUCCESS, responses };
}

export function loadInstitutionResponses(institutionId, documentId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetInstitutionResponses(institutionId, documentId)
			.then((responses) => {
				dispatch(loadInstitutionResponsesSuccess(responses));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

//SUBMIT SPP RESPONSES
//GET THE INSTITUTION RESPONSES
export function submitResponsesSuccess(){
	return { type: actionTypes.SUBMIT_SPP_RESPONSES_AJAX_SUCCESS };
}

export function submitSppResponses(documentId, responses){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.SubmitResponses(documentId, responses)
			.then(() => {
				dispatch(submitResponsesSuccess());
				return true;
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					return false;
				}
			);
	};
}

export function loadDateWindowGroupingElements(dateWindowGroupingId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetDateWindowGroupingElements(dateWindowGroupingId)
			.then((elements) => {
				dispatch(loadSppDocumentElementsSuccess(elements));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function loadPolicyComplianceSuccess(policyCompliance){
	return { type: actionTypes.LOAD_SPP_POLICY_COMPLIANCE_AJAX_SUCCESS, data: policyCompliance};
}

export function loadPolicyCompliance(documentId, institutionId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.GetPolicyCompliance(documentId, institutionId)
			.then((policyCompliance) => {
				dispatch(loadPolicyComplianceSuccess(policyCompliance));
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
				}
			);
	};
}

export function changePolicyResponse(response){
	return dispatch => {
		dispatch({type: actionTypes.CHANGE_POLICY_RESPONSE, data: response});
		return Promise.resolve();
	};
}

export function createPolicyResponse(response){
	return dispatch => {
		dispatch({type: actionTypes.CREATE_POLICY_RESPONSE, data: response});
		return Promise.resolve();
	}
}

export function loadDistrictStatusesSuccess(districtStatuses){
	return {type: actionTypes.LOAD_DISTRICT_STATUSES_AJAX_SUCCESS, data: districtStatuses };
}

export function loadDistrictStatuses(dateWindowId){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.LoadSppDistrictStatuses(dateWindowId)
			.then((data) => {
				dispatch(loadDistrictStatusesSuccess(data));
				return data;
			})
			.catch(error => {
				dispatch(ajaxCallError(error));
				return null;
			});
	};
}

export function saveCopyStrategySuccess(){
	return { type: actionTypes.SAVE_SPP_SECTION_COPY_STRATEGY_AJAX_SUCCESS };
}

export function saveCopyStrategy(documentId, sectionCopyConfigs){
	return dispatch => {
		dispatch(beginAjaxCall());

		return SppApi.saveCopyStrategy(documentId, sectionCopyConfigs)
			.then(() => {
				dispatch(saveCopyStrategySuccess());
				return true;
			})
			.catch(error => {
					dispatch(ajaxCallError(error));
					return false;
				}
			);
	};
}
