import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import * as MonitoringComplianceUtilities
    from "../../../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import Button from "../../../../components/common/buttons/Button";
import {SMALL_STICKY_HEIGHT} from "../../../../constants/buttonStickyHeights";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import StudentResultsGrid from "../../../../components/MonitoringCompliance/StudentCorrections/StudentResultsGrid";

export const StudentCorrectionsStateView = ({
                                                actions,
                                                dateWindowId,
                                                districtId,
                                                editCommentItemId,
                                                handleCommentChange,
                                                institutions,
                                                isCommentInEditMode,
                                                isLoading,
                                                onCommentCancel,
                                                onCommentCreate,
                                                onCommentEdit,
                                                onCommentSave,
                                                onStudentProtocolCorrectiveResultOutcomeChange,
                                                returnToMainPage,
                                                studentProtocolCorrectiveResults,
                                                studentRecordId
                                            }) => {
    const [titleIsSet, setTitleIsSet] = useState(false);
    const [loadResults, setLoadResults] = useState(false);

    useEffect(() => {
        if (MonitoringComplianceUtilities.isValidId(dateWindowId) && MonitoringComplianceUtilities.isValidId(districtId) && !loadResults) {
            actions.loadDistrictComplianceWithStudentCorrectiveResults(districtId, dateWindowId);
            setLoadResults(true);
        }
    }, [districtId, dateWindowId]);

    useEffect(() => {
        if (titleIsSet || institutions.length === 0) return;

        const pageTitle = MonitoringComplianceUtilities.createPageTitle(institutions, districtId, "Student Specific Corrections");
        actions.updatePageTitle(pageTitle);

        setTitleIsSet(true);
    }, [institutions])

    if (!studentProtocolCorrectiveResults || studentProtocolCorrectiveResults.length === 0) return null;

    const studentCorrectionResults = studentProtocolCorrectiveResults.filter(f => f.studentRecordId === studentRecordId);

    return (
        <section className={"compliance__studentCorrectionResults"}>
            {
                studentProtocolCorrectiveResults.length === 0 &&
                <p>No student specific corrections are needed at this time.</p>
            }
            {
                studentProtocolCorrectiveResults.length > 0 &&
                <StudentResultsGrid
                    editCommentItemId={editCommentItemId}
                    handleCommentChange={handleCommentChange}
                    isCommentInEditMode={isCommentInEditMode}
                    isLoading={isLoading}
                    onCommentCancel={(event) => onCommentCancel(event, "loadDistrictComplianceWithStudentCorrectiveResults")}
                    onCommentCreate={onCommentCreate}
                    onCommentEdit={onCommentEdit}
                    onCommentSave={onCommentSave}
                    onStudentProtocolCorrectiveResultOutcomeChange={(event, outcomeDto) => onStudentProtocolCorrectiveResultOutcomeChange(event, outcomeDto, "Student", "loadDistrictComplianceWithStudentCorrectiveResults")}
                    studentCorrectionResults={studentCorrectionResults}
                />
            }
            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM} stickyHeight={SMALL_STICKY_HEIGHT}>
                <Button buttonType={ButtonTypes.BACK}
                        label={"Main Page"}
                        name={"btnBack"}
                        onClick={returnToMainPage}
                />
            </ButtonBar>
        </section>
    );
}

StudentCorrectionsStateView.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    editCommentItemId: PropTypes.string.isRequired,
    handleCommentChange: PropTypes.func.isRequired,
    institutions: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCommentInEditMode: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCommentCancel: PropTypes.func.isRequired,
    onCommentCreate: PropTypes.func.isRequired,
    onCommentEdit: PropTypes.func.isRequired,
    onCommentSave: PropTypes.func.isRequired,
    onStudentProtocolCorrectiveResultOutcomeChange: PropTypes.func.isRequired,
    returnToMainPage: PropTypes.func.isRequired,
    studentProtocolCorrectiveResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    studentRecordId: PropTypes.string.isRequired
};
