import React from 'react';
import PropTypes from 'prop-types';
import DisplayField from "./DisplayField";

const TextReadOnly = ({
	                      label,
	                      name,
	                      value,
                      }) => {

	return (
		<DisplayField
			name={name}
			label={label}
			showLabel={!!label}
			displayClass={`textReadOnly`}
		>
			{value}
		</DisplayField>
	);
};

TextReadOnly.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
};

export default TextReadOnly;