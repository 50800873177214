import React from "react";
import PropTypes from 'prop-types';
import {
    isArrayNullOrEmpty,
} from "../common/commonUtilities";
import GridTable from "../common/GridTable";
import CheckBoxField from "../common/inputs/CheckBoxField";
import {SortType} from "../../constants/sharedData/dynamicSorting";
import {TaskItemForListItem} from "../tasks/TaskItem";
import ResultsPager from "../common/pagers/ResultsPager";
import {ConvertStateComplaintToSingular} from "../../DisputeResolution/drConstants";
import {createSummarySearchObject} from "./summaryObjectFactory";

export const DrSummaryView = ({
                                  filteredSummary,
                                  handleClickOrder,
                                  handleClickRecord,
                                  handlePinItemClick,
                                  isDueNow,
                                  pagerMethods,
                                  pageSearchCriteria,
                                  usePinItems
                              }) => {

    const handleClickRecordEvent = (event, districtId, id, type, districtName, isPinned, associatedRoleEnum, url) => {
        event.preventDefault();
        event.stopPropagation();

        handleClickRecord(createSummarySearchObject({
            districtId,
            id,
            type,
            districtName,
            isPinned,
            associatedRoleEnum,
            url
        }));
    };

    const buildTableData = (district, tdContent) => {
        return usePinItems ?
            <td className={"is-clickable"}
                onClick={(event) => handleClickRecordEvent(event, district.districtId, district.id, district.type, district.districtName, district.isPinned)}>
                {tdContent}
            </td>
            : <td>{tdContent}</td>;
    };

    const totalColumns = usePinItems ? 7 : 6;
    const tableClassName = isDueNow ? "dr-summary-due-now-table" : "dr-summary-upcoming-table";

    return (
        <GridTable className={tableClassName}>
            <thead>
            <tr>
                {
                    usePinItems &&
                    <th>Pin</th>
                }
                <th onClick={handleClickOrder} data-id="typeDescription" className="is-clickable">
                    Type
                </th>
                <th onClick={handleClickOrder} data-id="title" className="is-clickable">
                    Case Number
                </th>
                <th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
                    District Name
                </th>
                <th onClick={handleClickOrder} data-id={`dateFiled`} data-sorttype={SortType.Date}
                    className={`is-clickable`}>
                    Date Filed/ Received
                </th>
                <th>
                    Tasks
                </th>
                <th>
                    Roles
                </th>
            </tr>
            </thead>
            <tbody>
            {
                isArrayNullOrEmpty(filteredSummary) &&
                <tr>
                    <td colSpan={totalColumns} className={"center"}>
                        <p><strong>No results match the search criteria specified.</strong></p>
                    </td>
                </tr>
            }
            {
                !isArrayNullOrEmpty(filteredSummary) &&
                pageSearchCriteria.GetCurrentPageRecords(filteredSummary).map((district, key) =>
                    (<tr
                        key={key}
                        className={usePinItems ? "" : "is-clickable"}
                        onClick={usePinItems ? null : (event) => handleClickRecordEvent(event, district.districtId, district.id, district.type, district.districtName)}
                    >
                        {
                            usePinItems &&
                            <td>
                                <CheckBoxField
                                    checked={district.isPinned}
                                    label={`Pin ${ConvertStateComplaintToSingular(district.typeDescription)} ${district.title}`}
                                    name={`cbPinUpcoming_${district.id}`}
                                    onChange={() => handlePinItemClick(district.id, district.type)}
                                    showLabel={false}
                                />
                            </td>
                        }
                        {buildTableData(district, ConvertStateComplaintToSingular(district.typeDescription))}
                        {buildTableData(district, district.title)}
                        {buildTableData(district, district.districtName)}
                        {buildTableData(district, district.dateFiled)}
                        <td>
                            <ul>
                                {
                                    district.tasks.map((task, index) => {
                                        return <TaskItemForListItem
                                            key={index}
                                            task={task}
                                            viewRecord={(event) => handleClickRecordEvent(event, district.districtId, district.id, district.type, district.districtName, district.isPinned, task.associatedRoleEnum, task.url)}
                                        />;
                                    })
                                }
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {
                                    district.myRoles.map((role, index) => {
                                        return <li key={index}>{role}</li>;
                                    })
                                }
                            </ul>
                        </td>
                    </tr>)
                )
            }
            </tbody>
            <>
                {
                    filteredSummary.length > 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={totalColumns}>
                            <ResultsPager
                                currentPage={pageSearchCriteria.Page}
                                disabled={false}
                                first={pagerMethods.first}
                                last={pagerMethods.last}
                                next={pagerMethods.next}
                                previous={pagerMethods.previous}
                                recordsPerPage={pageSearchCriteria.RecordsPerPage}
                                totalRecords={filteredSummary.length}
                            />
                        </td>
                    </tr>
                    </tfoot>
                }
            </>
        </GridTable>
    );
};

DrSummaryView.propTypes = {
    filteredSummary: PropTypes.array,
    handleClickOrder: PropTypes.func.isRequired,
    handleClickRecord: PropTypes.func.isRequired,
    handlePinItemClick: PropTypes.func.isRequired,
    isDueNow: PropTypes.bool.isRequired,
    pagerMethods: PropTypes.object.isRequired,
    pageSearchCriteria: PropTypes.object.isRequired,
    usePinItems: PropTypes.bool.isRequired
};

export default DrSummaryView;