import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {Editor, EditorTools} from '@progress/kendo-react-editor';
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import HtmlDisplayBlock from "../../../htmlDisplay/HtmlDisplayBlock";
import Button from "../../buttons/Button";
import InputLayout from "../InputLayout";
import {createFakeEvent} from "../inputUtility";
import {validateTelerikHtmlConversion, convertToTelerikHtml, createContentWrap} from "./TextAreaEditorUtility";

const AbstractTextAreaEditor = ({
                                    align,
                                    disabled,
                                    elementEditBar,
                                    error,
                                    helpText,
                                    getValue,
                                    height = 160,
                                    includeAdvancedTools = false,
                                    inputClass,
                                    isClickable,
                                    label,
                                    labelClass,
                                    name,
                                    onChange,
                                    showLabel,
                                    value,
                                    wrapperClass
                                }) => {

    const [rteValue, setRteValue] = useState(convertToTelerikHtml(value, includeAdvancedTools));
    const [html, setHtml] = useState(validateTelerikHtmlConversion(value, includeAdvancedTools));
    const [isEditing, setIsEditing] = useState(false);

    const handleChange = (event) => {
        const newValue = event.html;
        const html = validateTelerikHtmlConversion(newValue, includeAdvancedTools);
        setRteValue(newValue);
        setHtml(html);
        onChange(createFakeEvent(html, name));
    };

    const getHtml = () => {
        return html;
    }

    useEffect(() => {
        if (!value || !isClickable)
            setIsEditing(true);
    }, []);

    useEffect(() => {
        if(getValue)
            getValue(getHtml);
    }, [html]);

    useEffect(() => {
        if(value !== html)
        {
            setHtml(validateTelerikHtmlConversion(value, includeAdvancedTools));
            setRteValue(convertToTelerikHtml(value, includeAdvancedTools));
        }
    }, [value]);

    // not included - FormatBlock, ForeColor, BackColor, FontSize, FontName, InsertImage, InsertFile, Print, Pdf,
    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        CleanFormatting,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, Outdent, OrderedList, UnorderedList,
        Undo, Redo,
        Link, Unlink, ViewHtml,
        InsertTable,
        SelectAll,
        AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
        DeleteRow, DeleteColumn, DeleteTable,
        MergeCells, SplitCell
    } = EditorTools;

    const contentWrapTool = createContentWrap({
        nodeType: 'div',
        props: {
            data: [
                {text: "PP Legislative Changes", class: "spp-legislative-changes"}
            ],
            textField: "text",
            dataItemKey: "class"
        }
    });

    const customFormatBlock = EditorTools.createFormatBlockDropDownList({
        commandName: "Custom",
        items: [
            {text: "None", value: "p"},
            {text: "Paragraph (Via H6)", value: "h6"},
            {text: "H2", value: "h2"},
            {text: "H3", value: "h3"},
            {text: "H4", value: "h4"},
        ],
        defaultItem: 0
    });

    const tools = [
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        [CleanFormatting],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        [SelectAll],
        [Undo, Redo],
        [Link, Unlink],
        [InsertTable, AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
            DeleteRow, DeleteColumn, DeleteTable, MergeCells, SplitCell]
    ];

    if(includeAdvancedTools) {
        tools.push(ViewHtml);
        tools.push(customFormatBlock);
        tools.push(contentWrapTool);
    }

    return (
        <InputLayout
            align={align}
            disabled={disabled}
            elementEditBar={elementEditBar}
            error={error}
            helpText={helpText}
            inputClass={inputClass}
            label={label}
            labelClass={labelClass}
            name={name}
            showLabel={showLabel}
            useInputTheme={false}
            wrapperClass={`${wrapperClass} RichTextEditor`}
        >
            {!disabled && isEditing &&
            <div className={isEditing && isClickable && `RichTextEditor__hover`}>
                {
                    !!html && isClickable &&
                    <Button
                        buttonType={ButtonTypes.EXIT}
                        label={`Exit`}
                        showLabel={false}
                        name={`rteBtnExit`}
                        onClick={() => setIsEditing(false)}
                    />
                }
                <Editor
                    contentStyle={{height: height}}
                    defaultEditMode={"div"}
                    onChange={handleChange}
                    tools={tools}
                    value={rteValue}
                    className={includeAdvancedTools ? `RichTextEditor__advanced` : ``}
                />
            </div>
            }
            {
                (!!disabled || !isEditing) &&
                <HtmlDisplayBlock
                    html={value}
                    onClick={!disabled ? (() => setIsEditing(true)) : null}
                />
            }
        </InputLayout>
    );
};

AbstractTextAreaEditor.propTypes = {
    align: PropTypes.string,
    disabled: PropTypes.bool,
    elementEditBar: PropTypes.object,
    error: PropTypes.string,
    helpText: PropTypes.string,
    getValue: PropTypes.func,
    height: PropTypes.number,
    includeAdvancedTools: PropTypes.bool,
    inputClass: PropTypes.string,
    isClickable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    showLabel: PropTypes.bool,
    value: PropTypes.string.isRequired,
    wrapperClass: PropTypes.string
};

AbstractTextAreaEditor.defaultProps = {
    value: "",
    wrapperClass: ""
};

export default AbstractTextAreaEditor;