import React from "react";
import PropTypes from "prop-types";
import LocalStorage from "../shared/LocalStorage";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Button from "../common/buttons/Button";
import {ReadMore} from "../common/ReadMore";
import * as caseNoteUtilities from "./caseNoteUtilities";
import {Other} from "../../constants/caseNotes/genericCaseNoteConstants";

const CaseNoteComponent = ({
                               caseNoteType,
                               readOnlyCaseNoteTypes,
                               otherAreaCaseNoteTypes,
                               caseNoteTypeList,
                               caseNote,
                               handleDelete,
                               handleEditCaseNote,
                               isMinimumCaseNote,
                               readOnly
                           }) => {

    const handleDeleteClick = (event, caseNoteId) => {
        event.preventDefault();

        if (caseNoteId && confirm("Are you sure you want to delete this case note?")) {
            handleDelete(caseNoteId);
        }
    };

    const handleEditClick = (event, caseNoteId) => {
        event.preventDefault();
        handleEditCaseNote(caseNoteId);
    };

    const noteType = caseNoteTypeList.find(f => f.value === caseNote.noteType);
    const noteTypeText = caseNoteType[Other] && noteType.value === caseNoteType[Other].id ? `${noteType.text} - ${caseNote.otherNoteType}` : noteType.text;
    const canEdit = LocalStorage.getLoggedInUser().Id === caseNote.authorId;
    const duration = caseNote.startTime && caseNote.endTime
        ? `${caseNote.startTime} - ${caseNote.endTime} for ${caseNote.activityDuration} hour(s)`
        : "";

    return (
        <>
            <td>{caseNote.dateAdded}</td>
            <td>{caseNote.author}</td>
            <td>
                {
                    !isMinimumCaseNote &&
                    <p>{noteTypeText}</p>
                }
                <ReadMore text={caseNote.content} />
                {
                    duration &&
                    <p><strong>Duration</strong>: {duration}</p>
                }
            </td>
            {
                !readOnly &&
                <td className={`action-no-wrap`}>
                    {
                        canEdit &&
                        !caseNoteUtilities.isNoteTypeReadOnly(readOnlyCaseNoteTypes, caseNote.noteType)
                        && !caseNoteUtilities.isNoteTypeOtherAssociatedArea(otherAreaCaseNoteTypes, caseNote.noteType) &&
                        <>
                            <Button
                                name={`btnEdit_${caseNote.caseNoteId}`}
                                onClick={(e) => handleEditClick(e, caseNote.caseNoteId)}
                                label={`Edit Case Note for ${caseNote.author} on ${caseNote.dateAdded}`}
                                buttonType={ButtonTypes.EDIT}
                                showLabel={false}
                            />

                            <Button
                                name={`btnDelete_${caseNote.caseNoteId}`}
                                onClick={(e) => handleDeleteClick(e, caseNote.caseNoteId)}
                                label={`Delete Case Note for ${caseNote.author} on ${caseNote.dateAdded}`}
                                buttonType={ButtonTypes.DELETE}
                                showLabel={false}
                            />
                        </>
                    }
                </td>
            }
        </>
    );
};

CaseNoteComponent.propTypes = {
    caseNote: PropTypes.object.isRequired,
    caseNoteType: PropTypes.object.isRequired,
    caseNoteTypeList: PropTypes.array.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEditCaseNote: PropTypes.func.isRequired,
    isMinimumCaseNote: PropTypes.bool.isRequired,
    otherAreaCaseNoteTypes: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    readOnlyCaseNoteTypes: PropTypes.object,
};

export default CaseNoteComponent;