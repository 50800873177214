import React from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import {ReadMore} from "../../../../components/common/ReadMore";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {BUDGET_COLUMNS} from "./gmsApplicationBudgetConstants";
import {containsBudgetColumn, getTotalCharactersForReadMore} from "./gmsApplicationBudgetUtilities";

export const GmsApplicationBudgetTableLineItemDisplay = ({
                                                             budgetColumnsSelected,
                                                             budgetLineItem,
                                                             canEditFeedback,
                                                             canUserEditBudget,
                                                             canViewFeedback,
                                                             handleDelete,
                                                             handleEdit,
                                                             handleEditOnlyFeedback,
                                                             isAmendmentBudget,
                                                             isEditing,
                                                             scrollId,
                                                         }) => {
    const handleClickEdit = isEditing ? undefined : () => handleEdit(budgetLineItem.budgetLineItemId);
    const handleClickEditOnlyFeedback = isEditing ? undefined : () => handleEditOnlyFeedback(budgetLineItem.budgetLineItemId);
    const handleDeleteForAmendment = budgetLineItem.isAmendmentLineItem || !isAmendmentBudget
        ? () => handleDelete(budgetLineItem.budgetLineItemId)
        : () => handleEdit(budgetLineItem.budgetLineItemId, true);
    const handleClickDelete = isEditing ? undefined : handleDeleteForAmendment;
    const readMoreCharacters = getTotalCharactersForReadMore(budgetColumnsSelected);
    const btnAriaDescription = `Entry ${budgetLineItem.entryNumber}`;

    return (
        <tr id={scrollId}>
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.entry) &&
                <td className={`text-center`}>{budgetLineItem.entryNumber}</td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.fundingCategory) &&
                <td className={`text-center`}>{budgetLineItem.fundingCategoryCode}</td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.function) &&
                <td className={`text-center`}>{budgetLineItem.functionCode}</td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.objectCodeDescription) &&
                <td className={`text-center`}>
                    <div>{budgetLineItem.objectCode}</div>
                    {
                        budgetLineItem.exceptionToFteRule &&
                        <div>(FTE&nbsp;Rule&nbsp;Exception)</div>
                    }
                    <div>{budgetLineItem.alternateDescription}</div>
                </td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.accountTitleAndNarrative) &&
                <td>
                    <ReadMore characters={readMoreCharacters} text={budgetLineItem.accountDescription} />
                </td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.fte) &&
                <td className={`text-center`}>{budgetLineItem.fullTimeEquivalent}</td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.amount) &&
                <td className={`text-center`}>{budgetLineItem.amountForDisplay}</td>
            }
            {
                containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.agreement) &&
                <td className={`text-center`}>{budgetLineItem.percentage}</td>
            }
            {
                canViewFeedback && containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.feedback) &&
                <td>
                    <ReadMore characters={readMoreCharacters} text={budgetLineItem.feedback} />
                </td>
            }
            {
                isAmendmentBudget && containsBudgetColumn(budgetColumnsSelected, BUDGET_COLUMNS.amendmentComments) &&
                <td>
                    <ReadMore characters={readMoreCharacters} text={budgetLineItem.amendmentComment} />
                </td>
            }
            {
                canUserEditBudget &&
                <td className={`text-center`}>
                    <Button buttonType={ButtonTypes.EDIT} btnClass={`button--vertical-margin`}
                            label={`Edit ${btnAriaDescription}`}
                            name={`btnEdit${budgetLineItem.budgetLineItemId}`} onClick={handleClickEdit}
                            showLabel={false}/>
                    {
                        !budgetLineItem.isDeletedForAmendment &&
                        <>
                            <br/>
                            <Button buttonType={ButtonTypes.DELETE} label={`Delete ${btnAriaDescription}`}
                                    name={`btnDelete${budgetLineItem.budgetLineItemId}`} onClick={handleClickDelete}
                                    showLabel={false}/>
                        </>
                    }
                    {
                        canEditFeedback &&
                        <>
                            <br/>
                            <Button buttonType={ButtonTypes.CONTENT} btnClass={`button--vertical-margin`}
                                    label={`Edit only feedback ${btnAriaDescription}`}
                                    name={`btnEditOnlyFeedback${budgetLineItem.budgetLineItemId}`}
                                    onClick={handleClickEditOnlyFeedback} showLabel={false}/>
                        </>
                    }
                </td>
            }
        </tr>
    );
};


GmsApplicationBudgetTableLineItemDisplay.propTypes = {
    budgetColumnsSelected: PropTypes.array.isRequired,
    budgetLineItem: PropTypes.object,
    canEditFeedback: PropTypes.bool.isRequired,
    canUserEditBudget: PropTypes.bool.isRequired,
    canViewFeedback: PropTypes.bool.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired,
    handleEditOnlyFeedback: PropTypes.func.isRequired,
    isAmendmentBudget: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool,
    scrollId: PropTypes.string.isRequired,
};