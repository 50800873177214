import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {
    createListIfExistsInIdArray,
} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import GridColumn from "../../../../components/common/GridColumn";
import GridRow from "../../../../components/common/GridRow";
import ListManagerField from "../../../../components/common/inputs/ListManagerField";
import {
    convertFormInputObjectToObject,
    useFormInputObject,
} from "../../../../components/common/inputs/inputUtility";
import SelectField from "../../../../components/common/inputs/SelectField";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import {GMS_ROLES_LIST} from "../../gmsConstants";
import {createGmsTeamMembersUpdateObject} from "../../gmsObjectFactory";
import {gridConstants} from "../../../../constants/inputConstants";
import {GmsAdminNavigation} from "../GmsAdminNavigation";

const GmsAdminTeamMembersEdit = ({
                                     dataChanged,
                                     fullListOfUsersSorted,
                                     handleChangeGmsRole,
                                     handleDataChanged,
                                     handleSaveTeamMembers,
                                     selectedGmsRole,
                                     selectedTeamMemberUserIds,
                                     stateUsers,
                                 }) => {
    const teamMembersForm = useFormInputObject(createGmsTeamMembersUpdateObject(selectedTeamMemberUserIds, () => handleDataChanged()));

    const validateForm = (form) => {
        let errors = "";
        if (form.selectedTeamMemberUserIds.length === 0)
            errors += "At least one team member needs to be selected.";

        if (errors) {
            NotifyUser.Warning(errors);
            return false;
        }

        return true;
    };

    const onSaveClick = (event) => {
        event.preventDefault();
        saveTeamMembers();
    };

    const saveTeamMembers = () => {
        let result = convertFormInputObjectToObject(teamMembersForm);

        const isValid = validateForm(result);
        if (isValid) {
            handleSaveTeamMembers(result.selectedTeamMemberUserIds);
        }

        return isValid;
    };

    const handleChangeRole = (event) => {
        if (saveTeamMembers())
            handleChangeGmsRole(event.target.value);
    };

    const handleChangeTeam = (event) => {
        teamMembersForm.selectedTeamMemberUserIds.onChange(event);
        handleDataChanged();
    };

    return (
        <>
            <GridRow rowClass="filterBar" medium={gridConstants.column.TWO}>
                <GridColumn>
                    <SelectField
                        name={"gmsRole"}
                        label={"Role"}
                        showLabel
                        options={GMS_ROLES_LIST}
                        includeDefaultOption={false}
                        value={selectedGmsRole}
                        onChange={handleChangeRole}
                    />
                </GridColumn>
            </GridRow>
            <FormTable>
                <ListManagerField
                    {...teamMembersForm.selectedTeamMemberUserIds}
                    archivedLabel={"Deleted/No Access"}
                    listItems={stateUsers}
                    managerLabel={`Team Members`}
                    name={`selectedTeamMemberUserIds`}
                    onChange={handleChangeTeam}
                    listValues={createListIfExistsInIdArray(teamMembersForm.selectedTeamMemberUserIds.value, fullListOfUsersSorted)}
                />
            </FormTable>
            <GmsAdminNavigation
                handleSave={onSaveClick}
                isDirty={dataChanged}
            />
        </>
    );
};

GmsAdminTeamMembersEdit.propTypes = {
    dataChanged: PropTypes.bool,
    fullListOfUsersSorted: PropTypes.array.isRequired,
    handleChangeGmsRole: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveTeamMembers: PropTypes.func.isRequired,
    selectedGmsRole: PropTypes.number.isRequired,
    selectedTeamMemberUserIds: PropTypes.array.isRequired,
    stateUsers: PropTypes.array.isRequired,
};

export default GmsAdminTeamMembersEdit;