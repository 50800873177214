import React, {useState} from "react";
import PropTypes from 'prop-types';
import Button from "../../../../../components/common/buttons/Button";
import ButtonBar from "../../../../../components/common/buttons/ButtonBar";
import Collapsible from "../../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../../components/common/CollapsibleTrigger";
import {createListIfExistsInIdArray, isArrayNullOrEmpty} from "../../../../../components/common/commonUtilities";
import FormTable from "../../../../../components/common/FormTable";
import ListManagerField from "../../../../../components/common/inputs/ListManagerField";
import * as ButtonBarPositions from "../../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../../constants/ButtonTypes";
import * as gmsLocations from "../../../gmsLocations";
import {
    createProjectUserIdsArray,
    removeProjectUsersAfterFiscalAgentsUpdated
} from "./gmsManageFiscalAgentUsersUtilities";

export const GmsManageFiscalAgentsUsersView = ({
                                                   discretionaryProject,
                                                   fiscalAgents,
                                                   gotoLocation,
                                                   handleDataChanged,
                                                   handleSaveGmsDiscretionaryProjectFiscalAgentsUsers,
                                                   isLoading,
                                                   users
                                               }) => {
    const [fiscalAgentIds, setFiscalAgentIds] = useState(discretionaryProject.discretionaryProjectFiscalAgents.map(fiscalAgent => fiscalAgent.fiscalAgentId));
    const [projectUserIds, setProjectUserIds] = useState(createProjectUserIdsArray(discretionaryProject.discretionaryProjectFiscalAgents));

    const handleSaveFiscalAgentsClick = async (event) => {
        event.preventDefault();

        await handleSaveGmsDiscretionaryProjectFiscalAgentsUsers(fiscalAgentIds, projectUserIds);
    };

    const handleReturnToProjectListClick = (event) => {
        event.preventDefault();
        handleDataChanged(false);
        gotoLocation(gmsLocations.GMS_ADMIN_MANAGE_DISCRETIONARY_PROJECTS.path);
    };

    const handleModifyFiscalAgents = (event) => {
        event.preventDefault();
        const updatedFiscalAgentIds = event.target.value;
        const updateProjectUserIds = removeProjectUsersAfterFiscalAgentsUpdated(updatedFiscalAgentIds, projectUserIds);

        if (updateProjectUserIds.length !== projectUserIds.length
            && !confirm("By removing the fiscal agent, this will also remove some project users. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
            return;

        setFiscalAgentIds(updatedFiscalAgentIds);
        setProjectUserIds(updateProjectUserIds);
        handleDataChanged(true);
    };

    const handleModifyUsers = (event) => {
        event.preventDefault();
        setProjectUserIds(event.target.value);
        handleDataChanged(true);
    };

    const availableUsers = users.filter(f => fiscalAgentIds.includes(f.fiscalAgentId));

    const fiscalAgentsTrigger = <CollapsibleTrigger text={`Associate Fiscal Agents`}/>;
    const projectUsersTrigger = <CollapsibleTrigger text={`Associate Users`}/>;

    return (
        <>
            <p>Collapse the fiscal agents container to easily get to the list of associated project users.</p>
            <Collapsible id={`fiscalAgents-container`} open trigger={fiscalAgentsTrigger}>
                <FormTable>
                    <ListManagerField
                        archivedLabel={"Deleted"}
                        listItems={fiscalAgents}
                        managerLabel={`Fiscal Agents`}
                        name={`fiscalAgentIds`}
                        onChange={handleModifyFiscalAgents}
                        listValues={createListIfExistsInIdArray(fiscalAgentIds, fiscalAgents)}
                    />
                </FormTable>
            </Collapsible>

            <Collapsible id={`projectUsers-container`} open trigger={projectUsersTrigger}>
                <FormTable>
                    {
                        (!isArrayNullOrEmpty(availableUsers) || !isArrayNullOrEmpty(projectUserIds)) &&
                        <ListManagerField
                            archivedLabel={"Deleted"}
                            listItems={availableUsers}
                            managerLabel={`Users`}
                            name={`projectUserIds`}
                            onChange={handleModifyUsers}
                            listValues={createListIfExistsInIdArray(projectUserIds, availableUsers)}
                        />
                    }
                    {
                        isArrayNullOrEmpty(availableUsers) && isArrayNullOrEmpty(projectUserIds) &&
                        <p>No users available to associate.</p>
                    }
                </FormTable>
            </Collapsible>

            <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                <Button
                    name={"save"}
                    label={"Save Fiscal Agents"}
                    onClick={handleSaveFiscalAgentsClick}
                    buttonType={ButtonTypes.SAVE}
                    diabled={isLoading}
                />
                <Button
                    name={"btnReturn"}
                    label={"Return to Discretionary Projects"}
                    onClick={handleReturnToProjectListClick}
                    buttonType={ButtonTypes.BACK}
                    diabled={isLoading}
                />
            </ButtonBar>
        </>
    );
};

GmsManageFiscalAgentsUsersView.propTypes = {
    discretionaryProject: PropTypes.object,
    fiscalAgents: PropTypes.array,
    gotoLocation: PropTypes.func.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSaveGmsDiscretionaryProjectFiscalAgentsUsers: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    users: PropTypes.array
};