import React from "react";
import PropTypes from "prop-types";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import SelectField from "../common/inputs/SelectField";
import NumberField from "../common/inputs/NumberField";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Allow from "../../components/authorization/Allow";
import { MonitoringCompliancePolicy, policyEvents } from "../../components/authorization/policies/MonitoringCompliancePolicy";

const AssessmentIndicatorRequirementForm = ({
	                                            indicatorRequirement,
	                                            onChange,
	                                            onSaveClick,
	                                            onCancelClick,
	                                            indicatorList,
												availableIndicators,
	                                            isLoading
                                            }) => {

	let indicatorIdsAvailable = [];
	if(availableIndicators) {
		indicatorIdsAvailable = availableIndicators.map(f => f.id);
	}

	indicatorIdsAvailable.push(indicatorRequirement.indicatorId);

	let availableIndicatorList = indicatorList.filter(f => indicatorIdsAvailable.indexOf(f.value) > -1);

	return (
		<section className="compliance__requiredProtocol__indicator compliance__requiredProtocol__indicator--editing">
			<Allow policy={MonitoringCompliancePolicy}
				   policyEvent={policyEvents.monitoring_compliance.assessment.MODIFY}>
				<GridRow>
					<GridColumn medium={"8"}>
						<SelectField
							name={"indicatorId"}
							label={"Indicator:"}
							showLabel
							options={availableIndicatorList}
							onChange={event => onChange(event, indicatorRequirement.indicatorRequirementId)}
							disabled={isLoading}
							value={indicatorRequirement.indicatorId}
						/>
					</GridColumn>
					<GridColumn medium={"4"}>
						<NumberField
							name={"studentRecordsRequirementCount"}
							label={"# Required Records:"}
							showLabel
							onChange={event => onChange(event, indicatorRequirement.indicatorRequirementId)}
							disabled={isLoading}
							value={indicatorRequirement.studentRecordsRequirementCount.toString()}
						/>
					</GridColumn>
				</GridRow>

				<ButtonBar position={ButtonBarPositions.BOTTOM}>
					<Button name={"save"}
							label={"Save"}
							onClick={onSaveClick}
							buttonType={ButtonTypes.SAVE}
							diabled={isLoading}/>
					<Button name={"cancel"}
							label={"Cancel"}
							onClick={onCancelClick}
							buttonType={ButtonTypes.CANCEL}
							diabled={isLoading}/>
				</ButtonBar>
			</Allow>
		</section>
	);
};

AssessmentIndicatorRequirementForm.propTypes = {
	indicatorRequirement: PropTypes.object,
	onChange: PropTypes.func.isRequired,
	onSaveClick: PropTypes.func.isRequired,
	onCancelClick: PropTypes.func.isRequired,
	indicatorList: PropTypes.arrayOf(PropTypes.object).isRequired,
	availableIndicators: PropTypes.arrayOf(PropTypes.object).isRequired,
	isLoading: PropTypes.bool.isRequired
};

export default AssessmentIndicatorRequirementForm;