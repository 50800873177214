import * as systemConfig from "../../constants/config";
import moment from "moment";
import {Small} from "../../constants/sharedData/enrollmentSizes";
import {INSTITUTION_TYPES} from "../../constants/sharedData/institutionTypes";
import LocalStorage from "./LocalStorage";
export {monitoring_performance} from "../../constants/sharedData/dynamicSorting";

export function generateDateWindowSelectListItems(dateWindows){
	return dateWindows.map(
		win => {
			return {
				value: win.id,
				text: new Date(win.openDate).getFullYear() + ' - ' + new Date(win.closeDate).getFullYear()
			};
		}
	);
}

export function generateDateWindowSelectListItemsAfterYear(dateWindows, startYear){
	const filteredDateWindows = dateWindows.filter(d => d.openDate > startYear);

	return generateDateWindowSelectListItems(filteredDateWindows);
}

export function generateInstitutionsSelectListItems(institutions){
	return institutions.map(ins => {
		return {value: ins.id, text: ins.name};
	});
}

export function generateIndicatorSelectListItems(indicators, includeType=false, includeTitle = false){
	if(!indicators)
		return [];

	return indicators.map(
		ind => {
			const prefix = includeType ? `${ind.type.toUpperCase()} ` : "";
			const title = includeTitle && ind.title ? ` - ${ind.title}` : "";
			return {
				value: ind.id,
				text: `${prefix}${ind.publicIdentifier}${title}`
			};
		}
	);
}

export function isValueInList(list, value) {
	return list
		.filter(li => li.value === value)
		.length > 0;
}

export function filterCurrentAnnualDateWindows(dateWindows, includeNextYearDateWindow = false){
	const currentDate = new Date();

	let lastWindowStartYear = currentDate.getMonth() >= systemConfig.dateWindowStartMonth ? currentDate.getFullYear() : currentDate.getFullYear() - 1;

	if (includeNextYearDateWindow)
		lastWindowStartYear++;

	let annualDateWindows = dateWindows
		.filter(dw => isAnnualDateWindow(dw.openDate, dw.closeDate))
		.filter(dw => new Date(dw.openDate).getFullYear() <= lastWindowStartYear);

	return annualDateWindows;
}

export function isAnnualDateWindow(startDateString, endDateString){
	const startUTC = new Date(startDateString);
	const endUTC = new Date(endDateString);

	return ((endUTC.getFullYear() - startUTC.getFullYear()) === 1);
}


export function createDateWindowRange(startDateUtc, endDateUtc){
	const startDate= new Date(startDateUtc).getFullYear();
	const endDate = new Date(endDateUtc).getFullYear();

	return `${startDate} - ${endDate}`;
}

export function outputStandardDateFormat(dateString, includeTime = true, displayAsTwoDigitYear = false) {
	if(includeTime) {
		const d = new Date(dateString);
		return d.toLocaleDateString("en-US") + " " + d.toLocaleTimeString("en-US");
	}

	if(!dateString || dateString === "")
		return "";

	return moment(dateString, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false).format(displayAsTwoDigitYear ? "M/D/YY" : "M/D/YYYY");
}

export function outputStandardTimeOnlyFormat(timeString, useSaveFormat = false) {
	if(!timeString || timeString === "")
		return "";

	const timeFormat = useSaveFormat ? "HH:mm" : "h:mm A";

	const outputStr = moment(timeString, ["HH:mm", "HH:mm:ss", "h:mm A"], false).format(timeFormat);

	return outputStr !== "Invalid date" ? outputStr : "";
}

export function outputShortDateFormat(dateString) {
	return moment(dateString, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false).format("MMM DD");
}

export function outputLongDateFormat(dateString) {
	if(!dateString || dateString === "")
		return "";

	return moment(dateString, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false).format("MMMM D, YYYY");
}

export function getDifferentBetweenDatesInDays(endDate, beginningDate = null) {
	const firstDate = !beginningDate ? moment() : moment(beginningDate, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false);
	const secondDate = moment(endDate, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false);
	return secondDate.diff(firstDate, 'days');
}

export function getDifferentBetweenTimesInMinutes(startTime, endTime) {
	const firstTime = moment(startTime, ["HH:mm", "h:mm A"], false);
	const secondTime = moment(endTime, ["HH:mm", "h:mm A"], false);
	return secondTime.diff(firstTime, 'minutes');
}
export function outputCurrentDate(includeTime = false) {
	const currentDate = new Date();
	return outputStandardDateFormat(currentDate, includeTime);
}

export function isDateValid(dateString="") {
	if (!dateString) return false;
	return moment(dateString, ["MM/DD/YYYY", "YYYY/MM/DD", "MM-DD-YYYY", "YYYY-MM-DD"], false).isValid();
}

export function fetchDistrict(institutions, institutionId) {
	const result = institutions.filter(ins => ins.id === institutionId)[0];
	if (result)
		return result.name;

	return "";
}

export function fetchDate(dateWindows, dateWindowId) {
	const result = dateWindows.filter(ins => ins.id === dateWindowId)[0];
	if (result)
		return createDateWindowRange(result.openDate, result.closeDate);

	return "";
}

export function getDateNdaysFromGivenDate(theGivenDate, nDays) {
	if(!isDateValid(theGivenDate) || !Number.isInteger(nDays)) return "";

		let theDate = new Date(theGivenDate.substring(0,10).replace(/-/g, '\/'));

		theDate.setDate(theDate.getDate() + nDays);

		let dd = theDate.getDate();
		let m = theDate.getMonth() + 1;
		let y = theDate.getFullYear();

	return (m <= 9 ? '0' + m : m) + '/' + (dd <= 9 ? '0' + dd : dd) + '/' + y;
}

export function generateInstitutionCollections(institutions) {
	return {
		institutions: institutions.filter(f => f.institutionType !== INSTITUTION_TYPES.stateAgency.id && f.institutionType !== INSTITUTION_TYPES.discretionaryAgency.id),
		institutionsWithStateAgencies: institutions.filter(f => f.institutionType !== INSTITUTION_TYPES.discretionaryAgency.id),
		institutionsWithDiscretionaryAgencies: institutions.filter(f => f.institutionType !== INSTITUTION_TYPES.stateAgency.id),
		allInstitutions: [...institutions],
		districtsWithSchools: institutions.filter(f => f.hasAssociatedSchools)
	};
}


export function newInstitution(){
	return {
		id: "",
		name: "",
		institutionType: 0,
		enrollmentSize: Small
	};
}

export function newIndicator(){
	return {
		id: "",
		publicIdentifier: "",
		text: "",
		title: ""
	};
}

export function convertBooleanToYesNoText(value) {
	return convertTextToBoolean(value) ? "Yes" : "No";
}

export function convertTextToBoolean(value) {
	return `${value}`.toLowerCase() === "true";
}

export function newComment(auditId) {
	return {
		auditCommentId: "",
		auditId: auditId,
		commentOwnerEmail: LocalStorage.getLoggedInUser().Email,
		isCommentOwner: true,
		text: "",
		dateCreated: new Date(),
		dateModified: null,
		displayDate: outputStandardDateFormat(new Date())
	};
}