import {deepCopyArray} from "../components/common/commonUtilities";
import {SiteSectionsForTeamMember} from "../constants/config";
import {INSTITUTION_TYPES} from "../constants/sharedData/institutionTypes";
import {createDistrictSiteSectionTeamMemberObject} from "../containers/users/userObjectFactory";
import {isDifferenceInDatesGreaterThanOneYear} from "../components/common/dateUtilities";

let dateWindows = null;
let institutions = null;
let sppIndicators = null;
let ptsIndicators = null;
let beessIndicators = null;
let fakeSystemMessage = null;

export function getAnnualDateWindows() {
    const annualDates = getDateWindows().filter(d => !isDifferenceInDatesGreaterThanOneYear(d.openDate, d.closeDate));
    return annualDates;
}
export function getDateWindows(){
    if (dateWindows === null){
        dateWindows = [
            {
                id: "a34a2861-375f-4207-b2ce-89ee17333393",
                openDate: "07/01/2022",
                closeDate: "06/30/2023"
            },
            {
                id: "224a5ca3-367c-4d1b-9730-7e9b2e155bd1",
                openDate: "07/01/2021",
                closeDate: "06/30/2022"
            },
            {
                id: "4f703473-6eae-4e05-a5a8-3827d559313f",
                openDate: "07/01/2020",
                closeDate: "06/30/2021"
            },
            {
                id: "4283c568-0652-4dca-9d83-3253fcbe3b61",
                openDate: "07/01/2019",
                closeDate: "06/30/2020"
            },
            {
                id: "dffa3ab7-7f95-40f4-89a2-3a2167ad41d9",
                openDate: "07/01/2018",
                closeDate: "06/30/2019"
            },
            {
                id: "5fe7b088-e9e4-457f-41b6-08d4fec59579",
                openDate: "07/01/2017",
                closeDate: "06/30/2018"
            },
            {
                id: "9c644736-eea5-43ca-1088-08d466512670",
                openDate: "07/01/2016",
                closeDate: "06/30/2017"
            },
            {
                id: "b6364825-d523-4797-af2b-6a56735cfe44",
                openDate: "07/01/2015",
                closeDate: "06/30/2016"
            },
            {
                id: "2299574e-6ca2-4997-83b8-46c3019a18a7",
                openDate: "07/01/2014",
                closeDate: "06/30/2015"
            },
            {
                id: "3",
                openDate: "07/01/2013",
                closeDate: "06/30/2014"
            },
            {
                id: "2",
                openDate: "07/01/2016",
                closeDate: "06/30/2019"
            },
            {
                id: "1",
                openDate: "07/01/2013",
                closeDate: "06/30/2016"
            }
        ];
    }

    return dateWindows;
}

export function getInstitutions(){
    if (institutions === null){
        institutions = [
            {
                id: "1",
                name: "Alachua",
                edwId: "01",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.county.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: true
            },
            {
                id: "2",
                name: "Baker",
                edwId: "02",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.county.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: true
            },
            {
                id: "3",
                name: "Bay",
                edwId: "03",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.county.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: true
            },
            {
                id: "4",
                name: "Bradford",
                edwId: "04",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.county.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: true
            },
            {
                id: "5",
                name: "Brevard",
                edwId: "05",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.charter.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "6",
                name: "Broward",
                edwId: "06",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "7",
                name: "Calhoun",
                edwId: "07",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "8",
                name: "Charlotte",
                edwId: "08",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "9",
                name: "Citrus",
                edwId: "09",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "10",
                name: "Clay",
                edwId: "10",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "11",
                name: "Collier",
                edwId: "11",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            },
            {
                id: "12",
                name: "Columbia",
                edwId: "12",
                enrollmentSize: 1,
                type: INSTITUTION_TYPES.labSchool.id,
                districtSiteSectionTeamMemberDtos: [],
                hasAssociatedSchools: false
            }
        ];
    }

    return institutions;
}

export function createDistrictSiteSectionTeamMemberDto(userId, districtId, siteSection) {
    return {
        ...createDistrictSiteSectionTeamMemberObject(userId, districtId, siteSection),
        firstName: `F${userId}`,
        lastName: `L${userId}`
    };
}

export function getDistricts(sampleUserId = "1") {
    let districts = getInstitutions();

    districts[0].districtSiteSectionTeamMemberDtos = [];
    districts[0].districtSiteSectionTeamMemberDtos.push(createDistrictSiteSectionTeamMemberDto(sampleUserId, districts[0].id, SiteSectionsForTeamMember.PoliciesAndProcedures.id));
    districts[0].districtSiteSectionTeamMemberDtos.push(createDistrictSiteSectionTeamMemberDto(sampleUserId, districts[0].id, SiteSectionsForTeamMember.DesktopMonitoring.id));

    return deepCopyArray(districts);
}

export function getSppIndicators() {
    if(sppIndicators === null) {
        sppIndicators = [
            {
                id: "spp1",
                publicIdentifier: "1",
                text: "You have to do things with the stuff so it all comes together, otherwise the stuff might not come together the way the things need them to.",
                title: ""
            },
            {
                id: "spp2",
                publicIdentifier: "CMD D4t4",
                text: "Our neural pathways have become accustomed to your sensory input patterns.",
                title: ""
            },
            {
                id: "spp3",
                publicIdentifier: "K1TTY",
                text: "Persons within 7,926 miles of the World Cat Organization headquarters must acknowledge and act as though kitty is the queen of the domicile.",
                title: ""
            },
            {
                id: "spp4",
                publicIdentifier: "1337",
                text: "Even though there is definitely nothing in the real world to which the notion of a 5-year plan correlates, Florida schools must attempt to teach its children the myth that is the 5-Year Plan.",
                title: ""
            }
        ];
    }

    return sppIndicators;
}

export function getPtsIndicators() {
    if(ptsIndicators === null) {
        ptsIndicators = [
            {
                id: "Pts1",
                publicIdentifier: "Pts1",
                text: "You have to do things with the stuff so it all comes together, otherwise the stuff might not come together the way the things need them to.",
                title: ""
            },
            {
                id: "Pts2",
                publicIdentifier: "Pts2",
                text: "Our neural pathways have become accustomed to your sensory input patterns.",
                title: ""
            },
            {
                id: "Pts3",
                publicIdentifier: "Pts3",
                text: "Persons within 7,926 miles of the World Cat Organization headquarters must acknowledge and act as though kitty is the queen of the domicile.",
                title: ""
            },
            {
                id: "Pts4",
                publicIdentifier: "Pts4",
                text: "Even though there is definitely nothing in the real world to which the notion of a 5-year plan correlates, Florida schools must attempt to teach its children the myth that is the 5-Year Plan.",
                title: ""
            }
        ];
    }

    return ptsIndicators;
}

export function getBeessIndicators() {
    if(beessIndicators === null) {
        beessIndicators = [
            {
                id: "1",
                publicIdentifier: "CEIS - Discipline",
                text: "Incidents of removal of students with disabilities through in-school suspension (ISS), out-of-school suspension(OSS), or expulsion for students with disabilities of any given race are at least 3.5 times more likely to occur when compared to all other races combined.",
                title: "",
                hasProtocols: false
            },
            {
                id: "2",
                publicIdentifier: "CEIS - Over-identification",
                text: "3.5 times more likely to be identified as disabled",
                title: "",
                hasProtocols: false
            },
            {
                id: "3",
                publicIdentifier: "CEIS - Placement",
                text: "3.5 times more likely to be placed in a separate class or environment",
                title: "",
                hasProtocols: false
            },
            {
                id: "4",
                publicIdentifier: "T16",
                text: "",
                title: "SPP 13 – Secondary Transition Age 16",
                hasProtocols: true
            }
        ];
    }

    return beessIndicators;
}

export function getFakeSystemMessage() {
    if(fakeSystemMessage === null) {
        fakeSystemMessage = {
            header: "",
            text: "",
            startDisplayingDate: "2017-10-07T00:00:00",
            endDisplayingDate: "2017-11-08T00:00:00"
        };
    }

    return fakeSystemMessage;
}