import React from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import CollapsibleTrigger from "../../../../components/common/CollapsibleTrigger";
import {
    convertToString,
    isObjectNullOrEmpty,
    isTrimmedStringEmpty
} from "../../../../components/common/commonUtilities";
import ColumnRadioField from "../../../../components/common/inputs/ColumnRadioField";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject, inputFormIsValid,
    useFormInputObject
} from "../../../../components/common/inputs/inputUtility";
import {NotifyUser} from "../../../../components/common/NotifyUser";
import HtmlDisplayBlock from "../../../../components/htmlDisplay/HtmlDisplayBlock";
import {isSupportingEvidenceEnabled, isSupportingEvidenceRequired} from "../../bpieUtilities";
import {getIndicatorAndResponse} from "../bpieAssessmentFactory";
import FormTable from "../../../../components/common/FormTable";
import TextArea from "../../../../components/common/inputs/TextArea";
import BackNextPager from "../../../../components/common/pagers/BackNextPager";
import {ResourceGlossaryView} from "../ResourceGlossaryView";

const BpieComponentView = ({
                               canEdit,
                               glossary,
                               handleDataChanged,
                               handleRedirect,
                               handleSaveResponseAndRedirect,
                               indicatorNumber,
                               indicators,
                               isBpieActive,
                               lastIndicatorNumber,
                               ratingsDefinition,
                               resources,
                               responses
                           }) => {
    const {indicator, response} = getIndicatorAndResponse(indicators, responses, indicatorNumber);
    const responseForm = useFormInputObject(response, () => handleDataChanged());

    const handleImplementationStatusChange = (event) => {
        event.preventDefault();

        responseForm.implementationStatus.setValue(event.target.value);

        if (!isSupportingEvidenceEnabled(indicator, responseForm.implementationStatus.value))
            responseForm.supportingEvidence.setValue("");

        handleDataChanged();
    }

    const validateResponse = () => {
        clearInputFormErrorFields(responseForm);

        if (isTrimmedStringEmpty(convertToString(responseForm.implementationStatus.value)))
            responseForm.implementationStatus.setError("Implementation status not selected.");

        if (isSupportingEvidenceRequired(indicator, responseForm.implementationStatus.value)) {
            if (isTrimmedStringEmpty(responseForm.supportingEvidence.value))
                responseForm.supportingEvidence.setError("Missing supporting evidence.");
        }

        let isValid = inputFormIsValid(responseForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    }

    const saveResponseAndRedirect = (event, desiredIndicator, isNext = false) => {
        event.preventDefault();

        const isValid = isNext ? validateResponse() : true;
        if (!isValid) return;

        if (canEdit && isBpieActive) {
            const result = convertFormInputObjectToObject(responseForm);
            handleSaveResponseAndRedirect(result, indicator.bpieIndicatorId, desiredIndicator);
        } else
            handleRedirect(desiredIndicator);
    };

    const notificationTrigger = <CollapsibleTrigger text={`Rating Definitions`}/>;

    if (!indicator || !responseForm)
        return null;

    return (
        <>
            <ResourceGlossaryView glossary={glossary} resources={resources} />

            <h2>Domain: {indicator.indicatorDomainType}</h2>

            <h3>Indicator {indicator.indicatorNumber}</h3>
            <HtmlDisplayBlock html={indicator.description}/>

            <h3>Examples of Evidence for Practice {indicator.indicatorNumber}</h3>
            <HtmlDisplayBlock html={indicator.evidence}/>

            <FormTable>
                <div>
                    <ColumnRadioField
                        {...responseForm.implementationStatus}
                        disabled={!canEdit || !isBpieActive}
                        legend={`Select the Implementation Status`}
                        onChange={handleImplementationStatusChange}
                        options={indicator.implementationStatusOptions}
                        showLegend={false}
                    />
                    <CollapsibleContainer
                        trigger={notificationTrigger}
                        id={`notification-container`}
                    >
                        {ratingsDefinition}
                    </CollapsibleContainer>
                </div>
            </FormTable>

            <h3>Data Sources/Supporting Evidence</h3>
            {
                !isObjectNullOrEmpty(indicator.suggestedMeasures) &&
                <p><strong>Suggested Measures: </strong>{indicator.suggestedMeasures}</p>
            }
            <FormTable>
                <TextArea
                    {...responseForm.supportingEvidence}
                    disabled={!canEdit || !isBpieActive || !isSupportingEvidenceEnabled(indicator, responseForm.implementationStatus.value)}
                    label={`Enter data sources and supporting evidence`}
                    showLabel={true}
                />
            </FormTable>
            <BackNextPager
                back={(event) => saveResponseAndRedirect(event, indicator.indicatorNumber - 1)}
                backText={"Previous Indicator"}
                center={(event) => saveResponseAndRedirect(event)}
                centerText={isBpieActive ? "Save and Exit" : "Exit"}
                disableBack={indicator.indicatorNumber === 1}
                disableNext={indicator.indicatorNumber === lastIndicatorNumber}
                isBottom
                next={(event) => saveResponseAndRedirect(event, indicator.indicatorNumber + 1, true)}
                nextText={"Next Indicator"}
            />
        </>
    );
}

BpieComponentView.propTypes = {
    canEdit: PropTypes.bool.isRequired,
    glossary: PropTypes.string,
    indicators: PropTypes.array.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleRedirect: PropTypes.func.isRequired,
    handleSaveResponseAndRedirect: PropTypes.func.isRequired,
    indicatorNumber: PropTypes.number.isRequired,
    isBpieActive: PropTypes.bool.isRequired,
    lastIndicatorNumber: PropTypes.number.isRequired,
    ratingsDefinition: PropTypes.object.isRequired,
    resources: PropTypes.string,
    responses: PropTypes.array.isRequired,
};

export default BpieComponentView;