import React from 'react';
import PropTypes from 'prop-types';
import SelectFieldAdapter, {searchableSelectFieldProps} from "./SelectFieldAdapter";

const SearchableMultiSelectField = (props) => {
    return <SelectFieldAdapter
        {...props}
        isMulti
    />;
};

SearchableMultiSelectField.propTypes = {
    ...searchableSelectFieldProps,
    value: PropTypes.array.isRequired
};

export default SearchableMultiSelectField;