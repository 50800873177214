import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";

export const createSchoolAdmin = ({
                                      email,
                                      firstName,
                                      isReplacement = false,
                                      lastName
                                  } = {}) => (
    {
        displayName: buildDisplayName(firstName, lastName, email),
        email: email || "",
        firstName: firstName || "",
        isReplacement,
        lastName: lastName || ""
    }
);

export const createEditRowObject = (id = '', isEdit = false) => (
    {id, isEdit}
)

const buildDisplayName = (firstName, lastName, email) => {
    if (isTrimmedStringEmpty(firstName) && isTrimmedStringEmpty(lastName) && isTrimmedStringEmpty(email)) {
        return 'None';
    }
    return isTrimmedStringEmpty(lastName) ? `${firstName} (${email})` : `${lastName}, ${firstName} (${email})`;
};
