import PropTypes from "prop-types";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as LayoutActions from "../../../actions/layoutActions";
import * as monitoringComplianceStudentCorrectionActions
    from "../../../actions/monitoringComplianceStudentCorrectionActions";
import MatrixCorrectionsSummaryView from "./MatrixCorrectionsSummaryView";
import {StudentCorrectionsSummaryView} from "./StudentCorrectionsSummaryView";

export const CorrectionsSummaryContainer = ({
                                                actions,
                                                assessmentId,
                                                dateWindowId,
                                                history,
                                                isLoading,
                                                isMatrix = false,
                                                matrixProtocolCorrectiveResults,
                                                selectedAssessment,
                                                studentProtocolCorrectiveResults,
                                                studentRecordId
                                            }) => {
    return isMatrix
        ? <MatrixCorrectionsSummaryView
            actions={actions}
            assessmentId={assessmentId}
            dateWindowId={dateWindowId}
            history={history}
            isLoading={isLoading}
            matrixProtocolCorrectiveResults={matrixProtocolCorrectiveResults}
        />
        : <StudentCorrectionsSummaryView
            actions={actions}
            assessmentId={assessmentId}
            dateWindowId={dateWindowId}
            history={history}
            isLoading={isLoading}
            selectedAssessment={selectedAssessment}
            studentProtocolCorrectiveResults={studentProtocolCorrectiveResults}
            studentRecordId={studentRecordId}
        />;
};

CorrectionsSummaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    assessmentId: PropTypes.string.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMatrix: PropTypes.bool,
    matrixProtocolCorrectiveResults: PropTypes.array,
    selectedAssessment: PropTypes.object,
    studentProtocolCorrectiveResults: PropTypes.array,
    studentRecordId: PropTypes.string
};

const mapStateToProps = (state) => {
    return {
        matrixProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.matrixProtocolCorrectiveResults,
        studentProtocolCorrectiveResults: state.monitoringComplianceStudentCorrection.studentProtocolCorrectiveResults,
    };
};

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        LayoutActions,
        monitoringComplianceStudentCorrectionActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CorrectionsSummaryContainer);

