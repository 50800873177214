import React from "react";
import PropTypes from "prop-types";
import AccountUtility from "../../api/AccountUtility";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";
import {convertBooleanToYesNoText, outputStandardDateFormat} from "../shared/sharedDataUtilities";
import {styles as ActionButton} from "../../constants/ActionButton";

const AuditHistoryOutcomeCommentDisplay = ({comment, onCommentEdit, isInEditMode}) => {
    const isDistrictUser = AccountUtility.isDistrictUser();

    if (isDistrictUser && !comment.isVisibleToDistrict)
        return null;

    const ownerInfo = comment.auditorFirstName ? `${comment.auditorFirstName} ${comment.auditorLastName}` : comment.commentOwnerEmail;
    const commentOwnerEmail = `<a href='mailto:${comment.commentOwnerEmail}'>${ownerInfo}</a>`;

    const commentOwnerDetails = `${outputStandardDateFormat(comment.displayDate)}, ${commentOwnerEmail} wrote:`;

    return (
        <li>
            <HtmlDisplayInline html={commentOwnerDetails} />
            <div className={`htmlDisplay htmlDisplay--editable`}>
                {
                    comment.isCommentOwner && !isInEditMode && onCommentEdit &&
                    <span className={ActionButton.position.RIGHT}>
						<Button
                            buttonType={ButtonTypes.EDIT_ROUND}
                            label="Edit"
                            name={"btnEdit_" + comment.auditCommentId}
                            onClick={() => onCommentEdit(comment.auditCommentId)}
                            showLabel={false}
                        />
					</span>
                }
                <HtmlDisplayBlock html={comment.text}/>
            </div>
            {
                !isDistrictUser &&
                <p>Visible to the district: {convertBooleanToYesNoText(comment.isVisibleToDistrict)}</p>
            }
        </li>

    );
};

AuditHistoryOutcomeCommentDisplay.propTypes = {
    comment: PropTypes.object.isRequired,
    onCommentEdit: PropTypes.func,
    isInEditMode: PropTypes.bool
};

export default AuditHistoryOutcomeCommentDisplay;