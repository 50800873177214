import React from 'react';
import PropTypes from 'prop-types';
import MatrixOfService from "./MatrixOfService";

const MatrixOfServiceValidation = ({
                                     indicatorProtocols,
                                     protocolResponses,
                                     isEditing,
                                     onResponseChange,
                                     assessmentApproved
                                 }) => {
    return (
        <MatrixOfService
            indicatorProtocols={indicatorProtocols}
            protocolResponses={protocolResponses}
            showState={true}
            isEditing={isEditing}
            onResponseChange={onResponseChange}
            studentRecordId={""}
            isReassess={false}
            assessmentApproved={assessmentApproved}
        />
    );
};

MatrixOfServiceValidation.propTypes = {
    indicatorProtocols: PropTypes.array.isRequired,
    protocolResponses: PropTypes.array.isRequired,
    isEditing: PropTypes.bool.isRequired,
    onResponseChange: PropTypes.func.isRequired,
    assessmentApproved: PropTypes.bool.isRequired
};

export default MatrixOfServiceValidation;