import {mockResolveApiCall} from "../../../../../api/ApiUtility";
import * as ApiUtility from "../../../../../api/ApiUtility";
import AjaxRequestOptions from "../../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../../api/requests/RequestTypes";
import * as config from "../../../../../constants/config";
import {API_MESSAGES} from "../../../gmsConstants";
import {API_ROOT} from "../../../gmsLocations";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerGmsManageFiscalAgentsUsersApi {
    static saveDiscretionaryProjectFiscalAgents(discretionaryProjectId, fiscalAgentUsers) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/projects/${discretionaryProjectId}/agents`,
                RequestTypes.PUT,
                JSON.stringify(fiscalAgentUsers),
                API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.FISCAL_AGENTS_USERS.saved)
        );
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockGmsManageFiscalAgentsUsersApi {
    static saveDiscretionaryProjectFiscalAgents() {
        return mockResolveApiCall("", API_MESSAGES.ADMIN.DISCRETIONARY_PROJECTS.FISCAL_AGENTS_USERS.saved);
    }
}

const GmsManageFiscalAgentsUsersApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerGmsManageFiscalAgentsUsersApi : MockGmsManageFiscalAgentsUsersApi;
export default GmsManageFiscalAgentsUsersApi;