import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as searchCriteriaActions from "../../actions/searchCriteriaActions";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {DrFilterVisibilityRules, DrSearchCriteria} from "../../components/common/searchCriterias/DrSearchCriteria";
import LocalStorage from "../../components/shared/LocalStorage";
import {fetchDistrict,fetchDate} from "../../components/shared/sharedDataUtilities";
import {DrFilter} from "../DrComponents/DrFilter";
import DrDistrictView from "./DrDistrictView";

const DrDistrictContainer = ({
                                 actions,
                                 allInstitutions,
                                 dateWindows,
                                 dateWindowsAnnualList: dateWindowsList,
                                 defaultDateWindowId,
                                 history,
                                 searchCriteria,
                             }) => {

    const [dataLoaded, setDataLoaded] = useState(false);
    const [date, setDate] = useState("");
    const [district, setDistrict] = useState("");
    const currentUser = LocalStorage.getLoggedInUser();
    const institutionId = currentUser.InstitutionId;

    const handleSearchFiltersChange = (criteria) => {
        actions.saveSearchCriteria(criteria);
    };

    useEffect(() => {
        if (isArrayNullOrEmpty(allInstitutions) || !institutionId) return;

        setDistrict(fetchDistrict(allInstitutions, institutionId));
    }, [allInstitutions, institutionId]);

    useEffect(() => {
        if (!dataLoaded) {
            if (district && date)
                setDataLoaded(true);
            return;
        }

        actions.updatePageTitle(`Dispute Resolution Summary ${district} (${date})`);
    }, [dataLoaded, district, date]);

    useEffect(() => {
        if (!defaultDateWindowId) return;

        let criteria = {...searchCriteria};

        criteria.dateWindowId = !criteria.dateWindowId ? defaultDateWindowId : criteria.dateWindowId;
        criteria.setVisibilityRules(DrFilterVisibilityRules.DrDistrictTiles);
        handleSearchFiltersChange(criteria);

    }, [defaultDateWindowId]);

    useEffect(() => {
        setDate(fetchDate(dateWindows, searchCriteria.dateWindowId));
    }, [searchCriteria.dateWindowId]);

    if (!dataLoaded || !searchCriteria.areRulesCurrent(DrFilterVisibilityRules.DrDistrictTiles) || !institutionId) return;

    return (
        <>
            <DrFilter
                dateWindows={dateWindowsList}
                handleSearchFiltersChange={handleSearchFiltersChange}
                searchCriteria={searchCriteria}
            />
            <DrDistrictView
                actions={actions}
                districtId={institutionId}
                history={history}
                searchCriteria={searchCriteria}
            />
        </>
    );
};

const mapStateToProps = (state, props) => {
    const defaultDateWindowId = props && !isArrayNullOrEmpty(props.dateWindowsAnnualList)
        ? props.dateWindowsAnnualList[0].value : "";

    const searchCriteria = state.searchCriteria.DrSearchCriteria || new DrSearchCriteria(defaultDateWindowId);

    const currentLocation = props.location.pathname;

    return {
        currentLocation,
        defaultDateWindowId,
        searchCriteria,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        layoutActions,
        searchCriteriaActions,
        apiForLocalStateActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

DrDistrictContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    allInstitutions: PropTypes.array.isRequired,
    dateWindows: PropTypes.array.isRequired,
    dateWindowsAnnualList: PropTypes.array.isRequired,
    defaultDateWindowId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    searchCriteria: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DrDistrictContainer);

export const MemoizedDrDistrictContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(React.memo(DrDistrictContainer));