import {emptyGuid} from "../../constants/config";
import {isNullOrUndefined} from "../common/commonUtilities";

export const createReminderObject = ({
                                         id,
                                         remindDate,
                                         remindDateOutput,
                                         reminderId,
                                         reminderNotes
                                     } = {}) => ({
    id,
    remindDate,
    remindDateOutput,
    reminderId: reminderId || emptyGuid,
    reminderNotes: reminderNotes ?? ""
});

export const createNotificationSettingsArray = (notificationSettings = []) => {
    return !notificationSettings ? [] : notificationSettings.map(setting => createNotificationSettingObject(setting));
};

export const createNotificationSettingObject = ({
                                                    settingId,
                                                    notificationType,
                                                    notificationTypeDescription,
                                                    isSubscribed
                                                } = {}, updateIsSubscribed = null) => ({
    settingId,
    notificationType,
    notificationTypeDescription,
    isSubscribed: !isNullOrUndefined(updateIsSubscribed) ? updateIsSubscribed : isSubscribed
});

export const createNotificationSettingsFormObject = (notificationSettings = []) => ({
    notificationSettings: !notificationSettings ? [] : notificationSettings.filter(f => f.isSubscribed).map(setting => setting.notificationTypeDescription)
});