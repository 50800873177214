import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import { RequestTypes } from '../../../api/requests/RequestTypes';
import {monitoring_compliance_api as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";
import {getCorrectiveActivities, getProtocolCorrectiveActions} from "../../../mockData/monitoringComplianceMockData";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceCorrectionsApi {
    static loadProtocolCorrectiveActions(monitoringAssessmentId){
        let request = AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${monitoringAssessmentId}/protocols`,
            RequestTypes.GET);

        request.contentType = 'application/json';

        return ApiUtility.sendRequest(request);
    }

    static loadCapActivities(monitoringAssessmentId){
        let request = AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${monitoringAssessmentId}/activities`,
            RequestTypes.GET);

        request.contentType = 'application/json';

        return ApiUtility.sendRequest(request);
    }

    static saveCapActivity(monitoringAssessmentId, activity, isNew){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${monitoringAssessmentId}/activities`,
            isNew ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(activity),
            isNew ? ApiSuccessMessages.activity.CREATE : ApiSuccessMessages.activity.UPDATE,
            'application/json'));
    }

    static deleteCapActivity(monitoringAssessmentId, activityId){
        const dto = {monitoringAssessmentId, activityId};

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${monitoringAssessmentId}/activities`,
            RequestTypes.DELETE,
            JSON.stringify(dto),
            ApiSuccessMessages.activity.DELETE,
            'application/json'));
    }

    static saveCapActivityResult(monitoringAssessmentId, activityId, activityResults, isNew){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${monitoringAssessmentId}/activities/${activityId}/results`,
            isNew ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(activityResults),
            ApiSuccessMessages.activityResult.SAVED,
            'application/json'));
    }

    static saveCapActivityResultReview(review){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${review.monitoringAssessmentId}/activities/${review.activityId}/reviews/${review.reviewId}`,
            RequestTypes.PUT,
            JSON.stringify(review),
            ApiSuccessMessages.review.SAVED,
            'application/json'));
    }

    static submitDistrictCapActivities(assessmentId, indicatorId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/indicators/${indicatorId}/activities/submit`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.activity.SUBMIT,
            'application/json'));
    }

    static rejectDistrictCapActivity(assessmentId, activityId) {
        let request = AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/activities/${activityId}/revise`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.activity.REJECT);

        return ApiUtility.sendRequest(request);
    }

    static approveDistrictCapActivity(assessmentId, activityId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/activities/${activityId}/approve`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.activity.APPROVE));
    }

    static resetDistrictCapActivity(assessmentId, activityId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/activities/${activityId}/reset`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.activity.RESET));
    }

    static CapActivityAuditComment(auditCommentDto, isCreate, assessmentId, activityId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/activities/${activityId}/audits/${auditCommentDto.auditId}/comments`,
            isCreate ? RequestTypes.POST : RequestTypes.PUT,
            JSON.stringify(auditCommentDto),
            ApiSuccessMessages.auditHistoryComments.SAVE));
    }

    static submitDistrictCapActivityResult(assessmentId, activityId, activityResultId) {
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/activities/${activityId}/results/${activityResultId}/submit`,
            RequestTypes.POST,
            null,
            ApiSuccessMessages.activityResult.SUBMIT,
            'application/json'));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceCorrectionsApi {
    static loadProtocolCorrectiveActions(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([...getProtocolCorrectiveActions()]);
            }, config.environment.delay);
        });
    }

    static loadCapActivities(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve([...getCorrectiveActivities()]);
            }, config.environment.delay);
        });
    }

    static saveCapActivity(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteCapActivity(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveCapActivityResult(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveCapActivityResultReview(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static submitDistrictCapActivities(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static rejectDistrictCapActivity(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static approveDistrictCapActivity() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static resetDistrictCapActivity() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static CapActivityAuditComment() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static submitDistrictCapActivityResult(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceCorrectionsApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceCorrectionsApi : MockMonitoringComplianceCorrectionsApi;
export default MonitoringComplianceCorrectionsApi;
