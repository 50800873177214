import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as sppActions from "../../actions/sppActions";
import * as sppAdminActions from "../../actions/sppAdminActions";
import * as shardDataActions from "../../actions/sharedDataActions";
import * as sppUtilities from "../../components/spp/SppUtilities";
import GridColumn from "../../components/common/GridColumn";
import GridRow from "../../components/common/GridRow";
import SelectField from "../../components/common/inputs/SelectField";
import * as sppLocations from "../../constants/spp/sppLocations";
import {gridConstants} from "../../constants/inputConstants";

class SppAdminDocumentHierarchySelector extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onSelectChange = this.onSelectChange.bind(this);
		this.loadSppDocument = this.loadSppDocument.bind(this);
		this.loadSppDocumentElements = this.loadSppDocumentElements.bind(this);
	}

	componentDidMount() {
        if (this.props.dateWindows.length === 0)
            this.props.actions.loadAllDateWindows();

        if (this.props.instances.length === 0)
            this.props.actions.loadSppInstances();


        if (this.props.params && this.props.params.documentId) {
        	this.loadSppDocument(this.props.params.documentId);
        	this.loadSppDocumentElements(this.props.params.documentId);
    	}
	}

	loadSppDocumentElements(documentId) {
		if (documentId && (
			this.props.documentElements.length === 0 ||
			documentId !== this.props.documentElements[0].documentId)
		)
			this.props.actions.loadSppDocumentElements(documentId);
	}

	loadSppDocument(documentId) {
		if (documentId && (
			!this.props.selectedDocument.id ||
			this.props.selectedDocument.id !== documentId)
		) {
			this.props.actions.loadSppDocument(documentId);
		}
	}

	onSelectChange(event, location) {
		const id = event.target.value;

        let documentId = (this.props.params && this.props.params.documentId) ? this.props.params.documentId : "";
        let partId= (this.props.params && this.props.params.partId) ? this.props.params.partId : "";
		let sectionId = (this.props.params && this.props.params.sectionId && !this.props.isForCopy) ? this.props.params.sectionId : "";

		if(!this.props.isForCopy) {
			this.setState({currentStep: 1});

			if (this.props.isEditing)
				this.props.actions.cancelEditSppElement();

			if (location === sppLocations.ADMIN_EDITOR_DOCUMENT.path && !id)
				location = sppLocations.ADMIN_EDITOR.path;
			else if (location === sppLocations.ADMIN_EDITOR_DOCUMENT.path)
				documentId = id ? id : "-1";
			else if (location === sppLocations.ADMIN_EDITOR_PART.path) {
				location = sppLocations.ADMIN_EDITOR_SECTION.path;
				partId = id ? id : "-1";
				sectionId = "-1";
			}
			else if (location === sppLocations.ADMIN_EDITOR_SECTION.path)
				sectionId = id ? id : "-1";

		} else {
			if (location === sppLocations.ADMIN_COPY_DOCUMENT.path && !id)
				location = sppLocations.ADMIN_COPY.path;
			else if (location === sppLocations.ADMIN_COPY_DOCUMENT.path)
				documentId = id ? id : "-1";
			else if (location === sppLocations.ADMIN_COPY_PART.path) {
				partId = id ? id : "-1";
			}
		}

		 if(this.props.warnOnNavigate && !this.props.warnOnNavigate())
		 	return;

		if (id !== "-1") {
			this.loadSppDocument(documentId);
			this.loadSppDocumentElements(documentId);
		}

		location = !this.props.isForCopy ? location
			.replace(sppLocations.DOCUMENT_ID, documentId)
			.replace(sppLocations.PART_ID, partId)
			.replace(sppLocations.SECTION_ID, sectionId)
			: location
			.replace(sppLocations.DOCUMENT_ID, documentId)
			.replace(sppLocations.PART_ID, partId);

		this.props.history.push(location);
	}

	render() {
		if (this.props.instances.length === 0 ||
			this.props.dateWindows.length === 0)
			return null;

		const selectDocumentItems = sppUtilities.generateInstanceSelectList(this.props.instances, this.props.dateWindows);

		const documentId = (this.props.params && this.props.params.documentId) ? this.props.params.documentId : "";
		let partIdParams = (this.props.params && this.props.params.partId) ? this.props.params.partId : "";
		let sectionIdParams = (this.props.params && this.props.params.sectionId) ? this.props.params.sectionId : "";

		const {selectPartItems, selectSectionItems, partId, sectionId} = sppUtilities.getSectionMenuOptions(this.props.selectedDocument, this.props.documentElements, {}, partIdParams, sectionIdParams, false);

		return (
				<GridRow rowClass="filterBar" medium={!this.props.isForCopy ? gridConstants.column.THREE : gridConstants.column.TWO}>
				<GridColumn>
					<SelectField
						name="selectedDocument"
						showLabel={true}
						label="Document"
						onChange={(event) => this.onSelectChange(event, !this.props.isForCopy ? sppLocations.ADMIN_EDITOR_DOCUMENT.path : sppLocations.ADMIN_COPY_DOCUMENT.path)}
						options={selectDocumentItems}
						value={documentId}
						includeDefaultOption
						defaultOption={"Select a document year"}
					/>
				</GridColumn>
				<GridColumn>
					<SelectField
						name="selectedPart"
						showLabel={true}
						label="Part"
						onChange={(event) => this.onSelectChange(event, !this.props.isForCopy ? sppLocations.ADMIN_EDITOR_PART.path : sppLocations.ADMIN_COPY_PART.path)}
						options={selectPartItems}
						value={partId}
						includeDefaultOption
						defaultOption={selectPartItems.length > 0 ? "Select a part" : "No document selected"}
					/>
				</GridColumn>
				{
					!this.props.isForCopy &&
					<GridColumn>
						<SelectField
							name="selectedSection"
							showLabel={true}
							label="Section"
							onChange={(event) => this.onSelectChange(event, sppLocations.ADMIN_EDITOR_SECTION.path)}
							options={selectSectionItems}
							value={sectionId}
							includeDefaultOption
							defaultOption={selectSectionItems.length > 0 ? "Select a section" : "No part selected"}
						/>
					</GridColumn>
				}

			</GridRow>

		);
	}
}

SppAdminDocumentHierarchySelector.defaultProps = {
	isForCopy: false
};

SppAdminDocumentHierarchySelector.propTypes = {
	actions: PropTypes.object.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	documentElements: PropTypes.array,
	instances: PropTypes.arrayOf(PropTypes.object),
	isForCopy: PropTypes.bool,
	params: PropTypes.object,
	isEditing: PropTypes.bool,
	selectedDocument: PropTypes.object,
	history: PropTypes.object.isRequired,
	warnOnNavigate: PropTypes.func
};

function mapStateToProps(state) {
	return {
		dateWindows: state.sharedData.dateWindows,
		documentElements: state.spp.documentElements,
		instances: state.spp.sppInstances,
		isEditing: state.spp.isInEditMode,
		selectedDocument: state.spp.selectedDocument
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		shardDataActions,
		sppActions,
		sppAdminActions,
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SppAdminDocumentHierarchySelector));
