import React from 'react';
import PropTypes from 'prop-types';
import InputLayout from "./InputLayout";

class InputAdapter extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    onChange(event) {
        if (this.onChange)
            this.props.onChange(event);
    }

    onInput(event) {
        this.onChange = null;
        this.props.onChange(event);
    }

    render() {
        let {
            align = "middle",
            columnsSmall = 12,
            columnsMedium,
            columnsLarge,
            disabled,
            elementEditBar,
            error,
            helpText,
            inputClass = "",
            inputFieldClass = "",
            inputType,
            isInline,
            label,
            labelClass = "",
            maxNumber,
            minNumber,
            name,
            placeholder,
            readonly,
            showLabel,
            step,
            value = "",
            wrapperClass = "",
        } = this.props;

        if (value === null)
            value = "";

        if (inputType === `number`
            && !inputFieldClass.includes("text-right")
            && !inputFieldClass.includes("text-left")
            && !inputFieldClass.includes("text-center")) {
            inputFieldClass += " text-right";
        }

        return (
            <InputLayout name={name}
                         label={label}
                         showLabel={showLabel}
                         error={error}
                         helpText={helpText}
                         wrapperClass={wrapperClass}
                         labelClass={labelClass}
                         inputClass={inputClass}
                         disabled={disabled}
                         align={align}
                         small={columnsSmall}
                         medium={columnsMedium}
                         large={columnsLarge}
                         elementEditBar={elementEditBar}>
                <div className={isInline ? `l-inline-block` : ""}>
                    <input type={inputType ? inputType : "text"}
                           className={inputFieldClass}
                           name={name}
                           id={name}
                           placeholder={placeholder}
                           value={value}
                           max={maxNumber}
                           min={minNumber}
                           onChange={this.onChange}
                           onInput={this.onInput}
                           readOnly={disabled || readonly}
                           step={step}
                    />
                    <span className={`input-icon__icon`}></span>
                </div>
            </InputLayout>
        );
    }
}

export const sharedProps = {
    align: PropTypes.string,
    columnsSmall: PropTypes.number,
    columnsMedium: PropTypes.number,
    columnsLarge: PropTypes.number,
    disabled: PropTypes.bool,
    elementEditBar: PropTypes.object,
    error: PropTypes.string,
    helpText: PropTypes.string,
    inputClass: PropTypes.string,
    inputFieldClass: PropTypes.string,
    inputType: PropTypes.string,
    isInline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    readonly: PropTypes.bool,
    showLabel: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    wrapperClass: PropTypes.string
};

export const numberProps = {
    maxNumber: PropTypes.number,
    minNumber: PropTypes.number,
    step: PropTypes.string,
};

InputAdapter.propTypes = {
    ...sharedProps,
    ...numberProps,
};

export default InputAdapter;