import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import AuditHistory from "../../components/audit/AuditHistory";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import Button from "../../components/common/buttons/Button";
import {getProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import { getParams } from "../../components/layout/getParams";
import IndicatorProtocolValidation from "../../components/MonitoringCompliance/IndicatorProtocolValidation";
import IndicatorProtocolStateForm from "../../components/MonitoringCompliance/IndicatorProtocolStateForm";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import * as responseOptions from "../../constants/monitoringCompliance/responseOptions";
import {NotifyUser} from "../../components/common/NotifyUser";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class MonitoringComplianceValidateStudentRecordPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			studentRecord: null,
			loadProtocols: false,
			protocolResponses: [],
			showDelete: true,
			editSubmittedValidation: false
		};

		this.onClickReturn = this.onClickReturn.bind(this);
		this.loadIndicatorProtocols = this.loadIndicatorProtocols.bind(this);
		this.onChangeProtocolResponse = this.onChangeProtocolResponse.bind(this);
		this.onChangeSubQuestionResponse = this.onChangeSubQuestionResponse.bind(this);
		this.onClickSave = this.onClickSave.bind(this);
		this.onClickRemove = this.onClickRemove.bind(this);
		this.editSubmittedValidation = this.editSubmittedValidation.bind(this);
	}

	componentDidMount() {
		this.props.actions.updatePageTitle("Monitoring for Compliance - Validate Student Records");

		if(this.props.selectedAssessment && this.props.selectedAssessment.indicatorRequirements)
			this.loadIndicatorProtocols(this.props.indicatorRequirementId);
	}

	componentDidUpdate() {
		if (!this.state.studentRecord &&
			this.props.currentIndicatorRequirement &&
			this.props.selectedAssessment.studentRecords &&
			this.props.indicatorProtocols &&
			this.props.indicatorProtocols.length > 0) {

			let selectedAssessment = {...this.props.selectedAssessment};
			let studentRecord = selectedAssessment.studentRecords.find(rec => rec.studentRecordId === this.props.studentRecordId);

			if (studentRecord) {
				let protocolResponses = [...studentRecord.protocolResponses.map(rec => Object.assign({}, rec))];
				if(this.props.indicatorProtocols.some(s => s.protocolId === protocolResponses[0].protocolId)) {
					const showDelete = protocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length > 0;
					const isValidationEditable = studentRecord.isRecordSubmitted && !this.props.indicatorRequirementStatus.validationSubmitted || false;
					this.setState({
						studentRecord: studentRecord,
						protocolResponses: this.setDefaultResponse(protocolResponses, isValidationEditable),
						showDelete: showDelete
					});
					this.props.actions.setEditMode(isValidationEditable);
				}
			}
		}

		if(!this.state.loadProtocols)
			this.loadIndicatorProtocols(this.props.indicatorRequirementId);
	}

	loadIndicatorProtocols(indicatorRequirementId) {
		if (this.props.selectedAssessment && this.props.selectedAssessment.indicatorRequirements) {
			this.setState({loadProtocols: true});
			const indicatorRequirement = this.props.selectedAssessment.indicatorRequirements.find(r => r.indicatorRequirementId === indicatorRequirementId);
			this.props.actions.loadIndicatorProtocols(this.props.selectedAssessment.dateWindowId, indicatorRequirement.indicatorId);
		}
	}

	onChangeProtocolResponse(event, protocolId) {
		this.updateProtocolResponses(event.target.value, protocolId);
	}

	updateProtocolResponses(responseValue, protocolId) {
		this.setState({
			protocolResponses: this.state.protocolResponses.map(rec => {
				if (rec.protocolId === protocolId && rec.responseType === responseTypes.VALIDATION)
					rec.response = responseValue;

				return rec;
			})
		});
		this.props.handleDataChanged();
    }

	onChangeSubQuestionResponse(event, protocolId, index) {
		this.setState({
			protocolResponses: this.state.protocolResponses.map(rec => {
				if (rec.protocolId === protocolId && rec.responseType === responseTypes.VALIDATION) {
					let subQuestionResponses = MonitoringComplianceUtilities.updateSubQuestionArray([...rec.subQuestionResponses], index, event.target.value);
					rec.subQuestionResponses = [...subQuestionResponses];
				}

				return rec;
			})
		});
		this.props.handleDataChanged();
	}

	createNewProtocolResponses(protocolResponses) {
		this.props.indicatorProtocols.map(protocol => {
			let response = protocolResponses.find(r => r.protocolId === protocol.protocolId && r.responseType === responseTypes.VALIDATION);
			if (!response) {
				let protocolResponseId = emptyGuid;
				let newResponse = MonitoringComplianceUtilities.newProtocolResponse(protocolResponseId, protocol.protocolId, responseOptions.NV, responseTypes.VALIDATION, protocol.subQuestions.length);
				protocolResponses.push(newResponse);
			}
		});
	}

	setDefaultResponse(protocolResponses, isValidationEditable) {
		if(protocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length !== this.props.indicatorProtocols.length && isValidationEditable && this.canUserEdit()) {
			this.createNewProtocolResponses(protocolResponses);
		}

		return protocolResponses;
	}

	validate() {
		let isValid = true;

		let responsesWithErrors = this.props.indicatorProtocols.reduce((errors, protocol) => {

			if(protocol.subQuestions.length === 0) {
				let stateResponse = MonitoringComplianceUtilities.getProtocolResponse(
					this.state.protocolResponses,
					protocol.protocolId,
					responseTypes.VALIDATION);

				if (stateResponse === "")
					errors.push(protocol.publicIdentifier);
			}
			else {
				let missingSubQuestion = false;
				let stateResponses = MonitoringComplianceUtilities.getSubQuestionResponse(
					this.state.protocolResponses,
					protocol.protocolId,
					responseTypes.VALIDATION
				);
				for(let i = 0; i < protocol.subQuestions.length && missingSubQuestion; i++)
				{
					if(stateResponses.length < i + 1 || stateResponses[i] === "")
						missingSubQuestion = true;
				}

				if (missingSubQuestion)
					errors.push(protocol.publicIdentifier);
			}
			return errors;
		}, []);

		if(responsesWithErrors.length > 0) {
			NotifyUser.Error("Missing responses to the following protocols:" + responsesWithErrors);
			isValid = false;
		}

		return isValid;
	}

	onClickSave(event) {
		event.preventDefault();

		this.save(true);
	}

	save(reload) {
		if((!this.props.isEditing || !this.canUserEdit()) && !reload)
			this.returnToMainPage();

		if (this.props.isEditing && this.canUserEdit() && this.validate()) {
			this.props.actions.saveStudentRecord(this.props.selectedAssessment.districtId,
				this.props.selectedAssessment.monitoringAssessmentId,
				this.state.studentRecord.studentRecordId,
				MonitoringComplianceUtilities.prepareProtocolResponsesForSave(this.state.protocolResponses, responseTypes.VALIDATION),
				responseTypes.VALIDATION)
				.then(() => {
					this.props.handleDataChanged(false);
					this.props.loadComplianceAssessment(() => {
						if (reload) {
							this.setState({studentRecord: null});
							this.props.actions.setEditMode(this.state.studentRecord.isRecordSubmitted && (!this.props.indicatorRequirementStatus.validationSubmitted || this.state.editSubmittedValidation) || false);
						}
						else
							this.returnToMainPage();
					})
				});
		}
	}

	returnToMainPage() {
		this.props.history.push(ComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path
			.replace(ComplianceLocations.DATE_WINDOW_ID, this.props.selectedAssessment.dateWindowId)
			.replace(ComplianceLocations.DISTRICT_ID, this.props.selectedAssessment.districtId));
	}

	onClickReturn(event) {
		event.preventDefault();

		this.save(false);
	}

	onClickRemove(event) {
		event.preventDefault();

		if(confirm("Are you sure you want to remove the validation for this student record?")) {
			const dateWindowId = this.props.selectedAssessment.dateWindowId;
			const districtId = this.props.selectedAssessment.districtId;
			this.props.actions.removeStudentRecordValidation(this.props.selectedAssessment.districtId,
				this.props.selectedAssessment.monitoringAssessmentId,
				this.state.studentRecord.studentRecordId)
				.then(() => {
					this.props.handleDataChanged(false);
					this.props.loadComplianceAssessment();
					this.props.history.push(ComplianceLocations.VALIDATE_STUDENT_RECORDS_DOE_DISTRICT.path
						.replace(ComplianceLocations.DATE_WINDOW_ID, dateWindowId)
						.replace(ComplianceLocations.DISTRICT_ID, districtId));
				});
		}
	}

	editSubmittedValidation(event) {
		event.preventDefault();
		if(confirm("Are you sure you want to edit this already submitted validation?  By doing so, it will automatically remove reassessed responses if they are no longer needed.")) {
			this.props.actions.setEditMode(true);
			this.setState({editSubmittedValidation: true});
		}
	}

	canUserEdit() {
		return AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
	}

	render() {
		if (!this.props.selectedAssessment.studentRecords || !this.state.studentRecord) return null;
		const canEdit = this.canUserEdit();
		const isEditing = this.props.isEditing && canEdit;
		return (
			<div>
				{
					isEditing &&
					<ButtonBar position={ButtonBarPositions.STICKY}>
						{
							this.state.showDelete &&
							!this.state.editSubmittedValidation &&
							<Button name={"remove"}
									label={"Remove Validation"}
									onClick={this.onClickRemove}
									disabled={this.props.isLoading}
									buttonType={ButtonTypes.DELETE}/>
						}
						<Button name={"save"}
								label={"Save"}
								onClick={this.onClickSave}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.SAVE}/>
						<Button name={"cancel"}
								label={"Save and Return to Validate Records"}
								onClick={this.onClickReturn}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.BACK}/>
					</ButtonBar>
				}
				{
					!isEditing &&
					<ButtonBar position={ButtonBarPositions.STICKY}>
						<Button name={"cancel"}
								label={"Return to Validate Records"}
								onClick={this.onClickReturn}
								disabled={this.props.isLoading}
								buttonType={ButtonTypes.BACK}/>
						{
							this.props.indicatorRequirementStatus.validationSubmitted &&
							this.state.studentRecord.isRecordValidated &&
							canEdit &&
							!this.props.indicatorRequirementStatus.assessmentApproved &&
							<Button label={"Edit Submitted Validation"} name={"btnEditSubmittedValidation"}
									onClick={this.editSubmittedValidation}/>
						}
					</ButtonBar>
				}
				<section className={`compliance__studentRecord`}>
				{
					this.state.studentRecord &&
					<>
						<h3>Record: {this.state.studentRecord.name}</h3>
						<AuditHistory audits={this.state.studentRecord.audits} />
					</>
				}
				{
					!this.props.indicatorProtocols &&
					<p>No records.</p>
				}
				{
					!isEditing &&
					this.props.indicatorProtocols.length > 0 &&
						<IndicatorProtocolValidation
							indicatorProtocols={this.props.indicatorProtocols}
							protocolResponses={this.state.protocolResponses}
							assessmentApproved={this.props.indicatorRequirementStatus.assessmentApproved}
						/>
				}

				{
					isEditing &&
					this.props.indicatorProtocols.map(protocol => {

						let districtResponse = MonitoringComplianceUtilities.getProtocolResponse(
							this.state.protocolResponses,
							protocol.protocolId,
							responseTypes.ASSESS,
							true);

						let stateResponse = MonitoringComplianceUtilities.getProtocolResponse(
							this.state.protocolResponses,
							protocol.protocolId,
							responseTypes.VALIDATION);

						let stateResponseText = MonitoringComplianceUtilities.getProtocolResponse(
							this.state.protocolResponses,
							protocol.protocolId,
							responseTypes.VALIDATION,
							true);

						let districtSubQuestionResponses = MonitoringComplianceUtilities.getSubQuestionResponse(
							this.state.protocolResponses,
							protocol.protocolId,
							responseTypes.ASSESS);

						let stateSubQuestionResponses = MonitoringComplianceUtilities.getSubQuestionResponse(
							this.state.protocolResponses,
							protocol.protocolId,
							responseTypes.VALIDATION);

						return (
							<IndicatorProtocolStateForm
								key={protocol.protocolId}
								protocol={protocol}
								response={stateResponse}
								responseText={stateResponseText}
								subQuestionResponses={stateSubQuestionResponses}
								districtResponse={districtResponse}
								districtSubQuestionResponses={districtSubQuestionResponses}
								onResponseChange={(event) => this.onChangeProtocolResponse(event, protocol.protocolId)}
								onSubQuestionResponseChange={this.onChangeSubQuestionResponse}
							/>);
					})
				}

				</section>
			</div>
		);
	}


}

MonitoringComplianceValidateStudentRecordPage.propTypes = {
	actions: PropTypes.object.isRequired,
	assessmentId: PropTypes.string,
	selectedAssessment: PropTypes.object.isRequired,
	indicatorProtocols: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentIndicatorRequirement: PropTypes.object.isRequired,
	handleDataChanged: PropTypes.func.isRequired,
	indicatorRequirementId: PropTypes.string,
	indicatorRequirementStatus: PropTypes.object.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	loadComplianceAssessment: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	studentRecordId: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    const params = getParams(ownProps.location.pathname, ComplianceLocations.COMPLIANCE_RECORD_VALIDATE);

	const currentIndicatorRequirement =
		state.monitoringCompliance.selectedAssessment.indicatorRequirements ?
			state.monitoringCompliance.selectedAssessment.indicatorRequirements
				.find(ir => ir.indicatorRequirementId === params.indicatorRequirementId) :
			{};

	const indicatorRequirementStatus = getProtocolRequirementStatus(currentIndicatorRequirement);

	return {
		selectedAssessment: state.monitoringCompliance.selectedAssessment,
		indicatorProtocols: state.monitoringCompliance.indicatorProtocols,
		currentIndicatorRequirement: currentIndicatorRequirement,
		indicatorRequirementStatus: indicatorRequirementStatus,
		isEditing: state.monitoringCompliance.isEditing,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

export default connect(mapStateToProps)(MonitoringComplianceValidateStudentRecordPage);