import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from "../common/buttons/ActionBar";
import FileViewDelete from "../common/inputs/FileViewDelete";
import * as policyEvents from "../../constants/policyEvents";
import Allow from "../../components/authorization/Allow";
import {MonitoringPerformancePolicy} from "../authorization/policies/MonitoringPerformancePolicy";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";

const ActionResponse = ({
							response,
							onEditActionResponse,
							isLoading,
							districtView,
							onFileView,
							onSubmitActionResponse,
							canEdit
						}) => {

	return (
		<section className={"performance__response"} id={"actionResponse"}>
			<h4>Response</h4>
			{
				response &&
				<div>
					<div><strong>Date Completed:</strong> {outputStandardDateFormat(response.dateResolutionCompleted, false)}</div>
					<div><strong>Action Taken:</strong> <HtmlDisplayBlock html={response.resolutionDescription} /></div>
					{
						response &&
						response.fileItems &&
						!isArrayNullOrEmpty(response.fileItems) &&
						<div className={`performance__response__attachments`}>
							<div><strong>Attachments:</strong></div>
							{
								response.fileItems.map((fi) => {
									return (
										<FileViewDelete
											key={fi.fileItemId}
											name={fi.fileItemId}
											label={fi.name}
											onView={onFileView}
											disabled={isLoading}
										/>);
								})
							}

						</div>
					}
				</div>
			}

			{
				response &&
				districtView &&
				canEdit &&
				<Allow policy={MonitoringPerformancePolicy}
					   policyEvent={policyEvents.monitoring_performance.response.ACTION_BAR}>
					<ActionBar
						elementId={response.responseId}
						onEditClick={() => onEditActionResponse(response.responseId)}
						onSubmitClick={onSubmitActionResponse}
						policy={MonitoringPerformancePolicy}
						editPolicyEvent={policyEvents.monitoring_performance.response.MODIFY}
						submitPolicyEvent={policyEvents.monitoring_performance.response.SUBMIT}
						disabled={isLoading}
					/>
				</Allow>
			}
		</section>
	);
};

ActionResponse.propTypes = {
	response: PropTypes.object,
	onEditActionResponse: PropTypes.func,
	isLoading: PropTypes.bool,
	districtView: PropTypes.bool,
	onFileView: PropTypes.func,
	onSubmitActionResponse: PropTypes.func,
	canEdit: PropTypes.bool
};

export default ActionResponse;