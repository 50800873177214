import React from 'react';
import PropTypes from 'prop-types';
import {isTrimmedStringEmpty} from "../common/commonUtilities";
import RadioField from "../common/inputs/RadioField";
import GridTable from "../common/GridTable";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import * as AuthorizationUtilities from "../authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";
import {inputStyles} from '../../constants/inputConstants';
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";

const IndicatorProtocolFormWithSubQuestions = ({
                                                   protocol,
                                                   response,
                                                   subQuestionResponses,
                                                   onSubQuestionResponseChange,
                                                   showState,
                                                   districtResponse,
                                                   districtSubQuestionResponses,
                                                   showMissingResponses
                                               }) => {
    const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);
    return (
        <div className={`subQuestion__responseContainer`}>
            {
                showState &&
                <div>
                    <p>Generated District Response: <HtmlDisplayInline html={districtResponse} /></p>
                    <p>
                        <strong>Generated State Response: <HtmlDisplayInline html={response} /></strong>
                        <br/><span className={"clarifying-text"}>(Updated after saved)</span>
                    </p>
                    <GridTable>
                        <thead>
                        <tr>
                            <th><HtmlDisplayBlock
                                html={!protocol.subQuestionDirections ? "Prompt" : protocol.subQuestionDirections}/>
                            </th>
                            <th className={"text-center"}>District Responses</th>
                            <th>State Responses</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            protocol.subQuestions.map((subQuestion, index) => {
                                let districtResponse = "";
                                if (districtSubQuestionResponses.length >= index + 1) {
                                    districtResponse = districtSubQuestionResponses[index];
                                }

                                let stateResponse = "";
                                if (subQuestionResponses.length >= index + 1) {
                                    stateResponse = subQuestionResponses[index];
                                }

                                return (
                                    <tr key={protocol.protocolId + "_" + index}>
                                        <td>
                                            <ol type={"a"} start={index + 1}>
                                                <li><HtmlDisplayBlock html={subQuestion.text}/></li>
                                            </ol>
                                        </td>
                                        <td className={"text-center"}>
                                            <HtmlDisplayBlock html={districtResponse} />
                                        </td>
                                        <td className={"response-no-wrap"}>
                                            <RadioField
                                                name={protocol.protocolId + "_" + index}
                                                options={subQuestion.stateResponseOptions}
                                                value={stateResponse}
                                                legend={`Protocol Responses for &quot;${subQuestion.text}&quot;`}
                                                showLegend={false}
                                                onChange={(event) => onSubQuestionResponseChange(event, protocol.protocolId, index)}
                                                direction={inputStyles.radio.direction.HORIZONTAL}
                                                disabled={!canEdit}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                </div>
            }

            {
                !showState &&
                <div>
                    <p>
                        Generated response: <HtmlDisplayInline html={response} />
                        <br/><span className={"clarifying-text"}>(Updated after saved)</span>
                    </p>
                    <GridTable>
                        <thead>
                        <tr>
                            <th><HtmlDisplayBlock
                                html={!protocol.subQuestionDirections ? "Prompt" : protocol.subQuestionDirections}/>
                            </th>
                            <th>Responses</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            protocol.subQuestions.map((subQuestion, index) => {
                                let response = "";
                                if (subQuestionResponses.length >= index + 1) {
                                    response = subQuestionResponses[index];
                                }

                                const missingData = showMissingResponses && isTrimmedStringEmpty(response) ? "missing-data-highlight" : "";

                                return (
                                    <tr key={protocol.protocolId + "_" + index}>
                                        <td>
                                            <ol type={"a"} start={index + 1}>
                                                <li><HtmlDisplayBlock html={subQuestion.text}/></li>
                                            </ol>
                                        </td>
                                        <td className={`response-no-wrap ${missingData}`}>
                                            <RadioField
                                                name={protocol.protocolId + "_" + index}
                                                options={subQuestion.districtResponseOptions}
                                                legend={`Protocol Responses for &quot;${subQuestion.text}&quot;`}
                                                showLegend={false}
                                                value={response}
                                                onChange={(event) => onSubQuestionResponseChange(event, protocol.protocolId, index)}
                                                direction={inputStyles.radio.direction.HORIZONTAL}
                                                disabled={!canEdit}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </GridTable>
                </div>
            }
        </div>
    );
};

IndicatorProtocolFormWithSubQuestions.defaultProps = {
    response: "",
    districtResponse: "",
    districtSubQuestionResponses: []
};

IndicatorProtocolFormWithSubQuestions.propTypes = {
    protocol: PropTypes.object.isRequired,
    response: PropTypes.string.isRequired,
    subQuestionResponses: PropTypes.array.isRequired,
    onSubQuestionResponseChange: PropTypes.func.isRequired,
    showState: PropTypes.bool.isRequired,
    showMissingResponses: PropTypes.bool.isRequired,
    districtResponse: PropTypes.string.isRequired,
    districtSubQuestionResponses: PropTypes.array.isRequired
};

export default IndicatorProtocolFormWithSubQuestions;