import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../../components/common/GridTable";

const MatrixStateSummaryGrid = (
    {
        correctionResults,
        onClick
    }) => {
    return (
        <div>
            <GridTable>
                <thead>
                <tr>
                    <th>Matrix Record</th>
                    <th className={"text-center"}>Total Not Submitted</th>
                    <th className={"text-center"}>Records Needing to be Reviewed</th>
                    <th className={"text-center"}>Total Being Revised</th>
                    <th className={"text-center"}>Total Finished</th>
                </tr>
                </thead>
                <tbody>
                {
                    correctionResults.map((result, index) => {
                        return (
                            <tr key={`sr_${index}`} onClick={(event) => onClick(event, index)}
                                className={`is-clickable`}>
                                <td>{result.name}</td>
                                <td className={"text-center"}>{result.totalInProgress}</td>
                                <td>{result.recordsToBeReviewed}</td>
                                <td className={"text-center"}>{result.totalToBeRevised}</td>
                                <td className={"text-center"}>{result.totalAccepted} out of {result.totalRecords}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </GridTable>
        </div>
    );
};

MatrixStateSummaryGrid.propTypes = {
    correctionResults: PropTypes.arrayOf(PropTypes.object).isRequired,
    onClick: PropTypes.func.isRequired
};

export default MatrixStateSummaryGrid;