import React from 'react';
import PropTypes from 'prop-types';
import AuditHistory from "../../../../components/audit/AuditHistory";
import {convertToBoolean, isTrimmedStringEmpty} from "../../../../components/common/commonUtilities";
import FormTable from "../../../../components/common/FormTable";
import CheckBoxField from "../../../../components/common/inputs/CheckBoxField";
import CheckBoxList from "../../../../components/common/inputs/CheckBoxList";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import RadioField from "../../../../components/common/inputs/RadioField";
import TextAreaEditor from "../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import SectionHeader from "../../../../components/common/SectionHeader";
import HtmlDisplayBlock from "../../../../components/htmlDisplay/HtmlDisplayBlock";
import {audit_outcomes, reviewOutcomesOptions} from "../../../../constants/auditOutcomes";
import * as correctionResultConstants from "../../../../constants/monitoringCompliance/correctionResults";

const ActivityResultsReviewForm = ({
                                       activityResultsReviewForm,
                                       canAudit = false,
                                       canDistrictEdit = false,
                                       canEdit = false,
                                       disabledForm = true,
                                       isLoading
                                   }) => {
    const displayOnlyWhenApprovedNotEditable = (activityResultsReviewForm.capActivityResultsStatus.value === audit_outcomes.ACCEPTED.value && !canEdit) || canEdit;
    const needsRevision = activityResultsReviewForm.capActivityResultsStatus.value === audit_outcomes.NEEDS_REVISION.value;
    return (
        <>
            <SectionHeader>
                <h3>Activity Results Review</h3>
            </SectionHeader>
            <FormTable className={`activities-results-review-form`}>
                {
                    canEdit &&
                    displayOnlyWhenApprovedNotEditable &&
                    <CheckBoxList
                        {...activityResultsReviewForm.resultsFromReview}
                        disabled={disabledForm}
                        options={correctionResultConstants.reviewResultOptions}
                        showLabel
                        legend={`Results`}
                        listHasTextField={correctionResultConstants.correctionOtherTextArray}
                    />
                }
                {
                    !canEdit &&
                    displayOnlyWhenApprovedNotEditable &&
                    <div>
                        <strong>Results:</strong>
                        {
                            activityResultsReviewForm.resultsFromReview.value.length > 0 &&
                            <ul>
                                {
                                    activityResultsReviewForm.resultsFromReview.value.map((result, index) => {
                                        return (
                                            <li key={`review_result_${index}`}><HtmlDisplayBlock html={result}
                                                                                                 className={`textContainer`}/>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        }
                    </div>
                }
                <TextAreaEditor
                    {...activityResultsReviewForm.comments}
                    disabled={disabledForm}
                    height={400}
                    label={canEdit ? `Comments <em>(only thing required when the outcome is &quot;Needs Revision&quot;)</em>` : `Comments`}
                    showLabel
                    value={!canEdit && isTrimmedStringEmpty(activityResultsReviewForm.comments.value) ? "None" : activityResultsReviewForm.comments.value}
                />
                {
                    displayOnlyWhenApprovedNotEditable &&
                    <>
                        <DateTimePickerField
                            {...activityResultsReviewForm.dateDocumentationReceived}
                            medium={6}
                            disabled={disabledForm}
                            label={"Date Documentation Received"}
                            showLabel
                        />
                        <DateTimePickerField
                            {...activityResultsReviewForm.dateDocumentationReviewed}
                            medium={6}
                            disabled={disabledForm}
                            label={"Date Documentation Reviewed"}
                            showLabel
                        />
                        <DateTimePickerField
                            {...activityResultsReviewForm.dateCorrectionDemonstrated}
                            medium={6}
                            disabled={disabledForm}
                            label={"Date Correction Demonstrated"}
                            showLabel
                        />
                        <RadioField
                            {...activityResultsReviewForm.timelineStatus}
                            disabled={disabledForm}
                            legend={"Status"}
                            options={correctionResultConstants.reviewStatusOptions}
                            showClearResponseButton
                            showLegend
                        />
                    </>
                }
                <div>
                    <AuditHistory
                        audits={activityResultsReviewForm.resultAudits.value}
                        isLoading={isLoading}
                    />
                    {
                        canEdit &&
                        <RadioField
                            {...activityResultsReviewForm.capActivityResultsStatus}
                            disabled={!canAudit}
                            legend={"Outcome"}
                            options={reviewOutcomesOptions}
                            showLegend
                        />
                    }
                    {
                        !canEdit &&
                        <div className={needsRevision ? `highlight-needsRevision results-details` : `results-details`}>
                            <p><strong>Outcome: </strong> {activityResultsReviewForm.capActivityResultsStatus.value}</p>
                            {
                                needsRevision &&
                                !canEdit &&
                                <CheckBoxField
                                    {...activityResultsReviewForm.acknowledgeResultsNeedsRevisionComplete}
                                    checked={convertToBoolean(activityResultsReviewForm.acknowledgeResultsNeedsRevisionComplete.value)}
                                    disabled={!canDistrictEdit}
                                    label={`I acknowledge that the revisions are complete and ready to be submitted.`}
                                    showLabel
                                    value={(!convertToBoolean(activityResultsReviewForm.acknowledgeResultsNeedsRevisionComplete.value)).toString()}
                                    wrapperClass={`acknowledge-container`}
                                />
                            }
                        </div>
                    }
                </div>
            </FormTable>
        </>
    );
};

ActivityResultsReviewForm.propTypes = {
    activityResultsReviewForm: PropTypes.object.isRequired,
    canAudit: PropTypes.bool,
    canDistrictEdit: PropTypes.bool,
    canEdit: PropTypes.bool,
    disabledForm: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired
};

export default ActivityResultsReviewForm;