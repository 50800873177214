import {
    createListItem, dynamicSort,
    isArray, isArrayNullOrEmpty, isNullOrUndefined,
} from "../components/common/commonUtilities";
import {getRandomInt} from "../components/common/HtmlUtilities";
import {outputCurrentDate} from "../components/shared/sharedDataUtilities";
import {createTeamMemberObject} from "../components/users/teamMemberObjectFactory";
import {emptyGuid} from "../constants/config";
import {DrRolesAssociatedToComplaint, IntakeStatusEnum} from "./drConstants";
import {getRole} from "./drUtilities";

export const createTeamMemberList = (teamMembers = [], showInactiveLabel = false, includeAssociatedToComplaintRolesOnly = false, includeRoleInName = false) => {
    let list = [];
    if (isArray(teamMembers))
        teamMembers.map((i) => {
            let teamMember = createTeamMemberObject(i);

            if (includeAssociatedToComplaintRolesOnly && DrRolesAssociatedToComplaint.indexOf(teamMember.role) === -1) return;

            if (!teamMember.id) return;

            let fullName = `${teamMember.fullName}${teamMember.isDisabled && showInactiveLabel ? " (Inactive)" : ""}`;
            if (includeRoleInName) {
                const userRole = getRole(teamMember.role);
                if (userRole)
                    fullName += ` - ${userRole.description}`;
            }

            list.push({
                ...createListItem(teamMember.id, fullName),
                isDisabled: teamMember.isDisabled,
                role: teamMember.role,
                userId: teamMember.userId
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};

export const createTeamMembersUpdateObject = (selectedTeamMemberUserIds) => ({
    selectedTeamMemberUserIds: selectedTeamMemberUserIds ? selectedTeamMemberUserIds : [],
    selectedTeamMemberUserId: !isArrayNullOrEmpty(selectedTeamMemberUserIds) ? selectedTeamMemberUserIds[0] : 0
});

export const createIntakeVersionObject = ({
                                              dateCreated,
                                              description,
                                              hasResponses,
                                              intakeRequirements,
                                              status,
                                              statusOutput,
                                              versionId
                                          } = {}) => ({
    dateCreated: dateCreated || outputCurrentDate(),
    description: description || "",
    hasResponses: isNullOrUndefined(hasResponses) ? false : hasResponses,
    intakeRequirements: intakeRequirements ? intakeRequirements.map(requirement => {
        return createIntakeRequirementObject(requirement);
    }) : [],
    status: status || IntakeStatusEnum.Editable.id,
    statusOutput: statusOutput || IntakeStatusEnum.Editable.description,
    versionId: versionId || emptyGuid
});

export const createIntakeRequirementObject = ({
                                                  requirementId,
                                                  conditionalParentId,
                                                  requirementDescription,
                                                  requirementDescriptionNoHtml,
                                                  displayOrder,
                                                  requirementType,
                                                  requirementTypeOutput,
                                                  versionId
                                              } = {}) => ({
    requirementId: requirementId || emptyGuid,
    conditionalParentId: conditionalParentId || "",
    requirementDescription,
    requirementDescriptionNoHtml,
    displayOrder,
    requirementType: requirementType || "",
    requirementTypeOutput,
    versionId
});

export const createComplaintCaseNumberList = (caseNumbers = []) => {
    let list = [];
    if (isArray(caseNumbers))
        caseNumbers.map((caseNumber) => {
            list.push({
                ...createListItem(caseNumber.complaintId, caseNumber.complaintNumber)
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};

export const createDueProcessCaseNumberList = (caseNumbers = []) => {
    let list = [];
    if (isArray(caseNumbers))
        caseNumbers.map((caseNumber) => {
            list.push({
                ...createListItem(caseNumber.dueProcessId, caseNumber.dueProcessCaseNumber)
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};

export function createUserRoleObject(role, teamMemberId) {
    return {
        role,
        teamMemberId
    };
}

export const createSchoolInformationObject = ({
                                                  enrollmentLocation,
                                                  id,
                                                  schoolCode
                                              } = {}) => ({
    enrollmentLocation,
    enrollmentLocationError: "",
    id: !id ? `${getRandomInt()}` : id,
    schoolCode,
    schoolCodeError: ""
});