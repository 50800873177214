import {matchPath} from "react-router-dom";

function getParams(currentPath, routeToMatch){
    const matchedInfo = matchPath(currentPath, {
        path: routeToMatch.path,
        exact: true,
        strict: false
    });

    if(matchedInfo === null || !routeToMatch.params)
        return null;

    let routeParams = Object.keys(routeToMatch.params);

    let paramObj = {};
    for(let param in routeParams)
        paramObj[routeParams[param]] = matchedInfo.params[routeParams[param]];

    return paramObj;
}

//Used for pages/containers in which multiple routes (ie: different parameters) use the same component
function getParamsMultiple(currentPath, routesToMatch){
    for(let i = 0; i < routesToMatch.length; i++){
        let matchedInfo = null;

        if(!routesToMatch[i].getParams)
	        matchedInfo = getParams(currentPath, routesToMatch[i]);
        else
            matchedInfo = routesToMatch[i].getParams(currentPath);

        if (matchedInfo)
            return matchedInfo;
    }

    return null;
}

export {getParams, getParamsMultiple};