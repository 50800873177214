import React from "react";
import PropTypes from "prop-types";
import {USERS} from "../../constants/contexts";
import * as policyEvents from "../../constants/policyEvents";
import * as AuthorizationUtilities from "../authorization/AuthorizationUtilities";
import {UserPolicy} from "../authorization/policies/UserPolicy";
import UserSearchRow from "./UserSearchRow";
import ResultsPager from "../common/pagers/ResultsPager";
import * as config from "../../constants/config";
import GridTable from "../common/GridTable";

const UserSearchResults = ({
	                            users,
	                            first,
	                            previous,
	                            next,
	                            last,
	                            totalRecords,
	                            recordsPerPage,
	                            currentPage,
	                            details,
	                            order,
	                            isLoading,
								impersonating
                            }) => {
	if (users.length === 0)
		return (<p>The user search yielded no users. If you believe this message to be an error, {config.supportDetails(USERS)}.</p>);

	const canImpersonate = AuthorizationUtilities.allow(UserPolicy, policyEvents.IMPERSONATE);
	const footerColumnSpan = canImpersonate ? "5" : "4";

	return (
		<GridTable>
			<thead>
			<tr>
				<th onClick={order} className="is-clickable">Email</th>
				<th onClick={order} className="is-clickable">Username</th>
				<th onClick={order} className="is-clickable">District / Institution</th>
				<th onClick={order} className="is-clickable">Status</th>
				{
					canImpersonate &&
					<th scope={"col"} className={"text-center"}>
						Actions
					</th>
				}
			</tr>
			</thead>
			<tbody>
			{users.map(
				(user, index) => {
					return (<UserSearchRow
						key={index}
						user={user}
						onClick={details}
						impersonating={impersonating}
					/>);
				}
			)}
			</tbody>

			<tfoot>
			{totalRecords > 0 &&
				<tr>
					<td colSpan={footerColumnSpan}>
						<ResultsPager first={first} previous={previous} next={next} last={last} totalRecords={totalRecords}
						              recordsPerPage={recordsPerPage} currentPage={currentPage} disabled={isLoading}/>
					</td>
				</tr>
			}
			</tfoot>

		</GridTable>
	);
};

UserSearchResults.propTypes = {
	users: PropTypes.arrayOf(PropTypes.object).isRequired,
	order: PropTypes.func,
	first: PropTypes.func.isRequired,
	last: PropTypes.func.isRequired,
	next: PropTypes.func.isRequired,
	previous: PropTypes.func.isRequired,
	totalRecords: PropTypes.number.isRequired,
	currentPage: PropTypes.number.isRequired,
	recordsPerPage: PropTypes.number,
	details: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	impersonating: PropTypes.func.isRequired
};

export default UserSearchResults;