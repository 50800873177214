import React from "react";
import PropTypes from "prop-types";
import LocalStorage from "../../../components/shared/LocalStorage";
import api from "../drStateComplaintsApi";
import SectionHeader from "../../../components/common/SectionHeader";
import * as drStateComplaintConstants from "../../StateComplaints/drStateComplaintConstants";
import CaseNotesView from "../../../components/CaseNotes/CaseNotesView";

export const DrStateComplaintCaseNotesContainer = ({
                                                       actions,
                                                       complaint,
                                                       handleDataChanged,
                                                       handleScroll,
                                                       handleScrollToTop,
                                                       handleUpdateComplaint,
                                                       userDrTeamMemberRoles
                                                   }) => {
    const handleSaveCaseNote = async (caseNote) => {
        handleDataChanged(false);
        caseNote.complaintId = complaint.id;
        caseNote.authorId = LocalStorage.getLoggedInUser().Id;
        return actions.executeApi(api.saveCaseNote, [caseNote])
            .then((updatedComplaint) => {
                handleUpdateComplaint(updatedComplaint);
                return true;
            })
            .catch(() => {
                return false;
            });
    };

    const handleDeleteCaseNote = (caseNoteId) => {
        actions.executeApi(api.deleteCaseNote, [complaint.id, caseNoteId])
            .then((updatedComplaint) => {
                handleUpdateComplaint(updatedComplaint);
            });
    };

    const handleDownloadCaseInvestigationReport = (caseInvestigationFilter) => {
        actions.executeApi(api.downloadCaseInvestigationReport, [complaint.id, caseInvestigationFilter]);
    };

    if (!complaint) return null;

    const isComplaintTeamMember = !!userDrTeamMemberRoles && userDrTeamMemberRoles.length > 0;

    return (
        <>
            <SectionHeader>
                <h2>Case Notes</h2>
            </SectionHeader>

            <CaseNotesView
                caseNotes={complaint.caseNoteModels}
                caseNoteType={drStateComplaintConstants.CaseNoteType}
                caseNoteTypeList={drStateComplaintConstants.CaseNoteTypeList}
                handleDataChanged={handleDataChanged}
                handleDeleteCaseNote={handleDeleteCaseNote}
                handleDownloadCaseInvestigationReport={handleDownloadCaseInvestigationReport}
                handleSaveCaseNote={handleSaveCaseNote}
                handleScroll={handleScroll}
                handleScrollToTop={handleScrollToTop}
                otherAreaCaseNoteTypes={drStateComplaintConstants.OtherAreaCaseNoteTypes}
                readOnly={!isComplaintTeamMember}
                readOnlyCaseNoteTypes={drStateComplaintConstants.ReadOnlyCaseNoteTypes}
            />
        </>
    );
};

DrStateComplaintCaseNotesContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    complaint: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleScroll: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    userDrTeamMemberRoles: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default DrStateComplaintCaseNotesContainer;
