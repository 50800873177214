import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import ManageFinView from "./ManageFinView";
import regionAdminApi from "../Regions/regionAdminApi";
import {createRegionsList} from "../Regions/regionObjectFactory";
import finFacilitatorAdminApi from "./finFacilitatorAdminApi";

const ManageFinContainer = ({
                                actions,
                                handleDataChanged
                            }) => {

    const [regions, setRegions] = useState([]);
    const [bpieUsers, setBpieUsers] = useState([]);

    const loadRegions = async () => {
        await actions.executeApi(regionAdminApi.getRegions, [])
            .then((results) => {
                const regionsList = createRegionsList(results);
                setRegions(regionsList);
            });
    }

    const loadBpieUsers = async () => {
        await actions.executeApi(finFacilitatorAdminApi.getFinUsers)
            .then((stateUsersResults) => {
                setBpieUsers(stateUsersResults)
            });
    }

    const saveBpieUsers = async () => {
        await actions.executeApi(finFacilitatorAdminApi.SaveFinFaclitators, [bpieUsers])
            .then(() => {
                initializeData();
                handleDataChanged(false);
            });
    };

    const initializeData = () => {
        loadRegions();
        loadBpieUsers();
    };

    const cancelAndReload = () => {
        if (confirm("Are you sure you want to cancel?\nPress \"Okay\" to continue or \"Cancel\" to return to the page.")) {
            loadBpieUsers();
            handleDataChanged(false);
        }
    };

    const handleChangeRegion = (regionId, userId) => {
        let users = [...bpieUsers];
        const bpieUser = users.find(u => u.userId === userId);
        bpieUser.regionId = regionId;
        setBpieUsers(users);
        handleDataChanged(true);
    }

    useEffect(() => {
        actions.updatePageTitle(`Manage FIN Facilitators`);

        initializeData();
    }, []);

    return (
        <>
            <ManageFinView bpieUsers={bpieUsers}
                           cancelAndReload={cancelAndReload}
                           handleChangeRegion={handleChangeRegion}
                           regions={regions}
                           saveBpieUsers={saveBpieUsers}
            />

        </>
    );
}

ManageFinContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired
};

export default ManageFinContainer;