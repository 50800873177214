import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import {
    IsIntakeRequirementMetWithNotApplicable,
    IntakeRequirementType,
    IsIntakeRequirementMet
} from "../drStateComplaintConstants";
import * as drObjectFactory from "../drStateComplaintObjectFactory";
import DrStateComplaintIntakeView from "./DrStateComplaintIntakeView";
import api from "../drStateComplaintsApi";

const DrStateComplaintIntakeContainer = ({
                                             actions,
                                             complaint,
                                             handleDataChanged,
                                             handleSelectSection,
                                             handleUpdateComplaint,
                                             readOnly
                                         }) => {
    const [intakeResponses, setIntakeResponses] = useState([]);
    const [intakeRequirements, setIntakeRequirements] = useState([]);

    const handleSaveIntakeResponseForm = (intakeForm) => {
        const form = {
            complaintId: complaint.id,
            intakeRequirementResponseModels: intakeResponses.filter(f => !isTrimmedStringEmpty(f.isRequirementMet)),
            intakeComments: intakeForm.intakeComments,
            additionalIntakeResponses: drObjectFactory.createAdditionalResponsesFromIntake(intakeForm)
        };

        actions.executeApi(api.saveIntakeResponseForm, [form])
            .then((result) => {
                handleUpdateComplaint(result);
                handleDataChanged(false);
            });
    };

    const handleIntakeChange = (requirementId, responseId, elementId, event) => {
        event.preventDefault();

        let updatedState = [...intakeResponses];
        let updatedResponse = updatedState.find(d => d.responseId === responseId && d.requirementId === requirementId);

        if (updatedResponse) {
            const requirementInfo = intakeRequirements.find(d => d.id === requirementId);
            if(requirementInfo.requirementType === IntakeRequirementType.Conditional)
                updateOptionalChildren(updatedState, requirementId, event.target.value);

            updatedResponse[elementId] = event.target.value;
        }

        setIntakeResponses(updatedState);

        handleDataChanged(true);
    };

    const updateOptionalChildren = (allIntakeResponses, parentRequirementId, isParentMetRequirement) => {
        const optionalRequirements = intakeRequirements.filter(f => f.conditionalParentId === parentRequirementId);

        for(let requirement of optionalRequirements) {
            let updatedResponse = allIntakeResponses.find(d => d.requirementId === requirement.id);

            if(updatedResponse) {
                if(isParentMetRequirement === IsIntakeRequirementMet.Yes && updatedResponse.isRequirementMet === IsIntakeRequirementMetWithNotApplicable.NotApplicable.id)
                    updatedResponse.isRequirementMet = "";
                else if(isParentMetRequirement === IsIntakeRequirementMet.No && updatedResponse.isRequirementMet === "")
                    updatedResponse.isRequirementMet = IsIntakeRequirementMetWithNotApplicable.NotApplicable.id;
            }
        }
    }

    const updateAllResponses = (response) => {
        let updatedState = [...intakeResponses];
        for(let updatedIntakeResponse of updatedState)
            updatedIntakeResponse.isRequirementMet = response;

        setIntakeResponses(updatedState);

        handleDataChanged(true);
    }

    const getRequirementNumber = (requirementId) => {
        let requirementNumber = 0;
        for (let requirement of intakeRequirements) {
            if (requirement.requirementType !== IntakeRequirementType.Informational)
                requirementNumber++;

            if (requirement.id === requirementId)
                return requirementNumber;
        }

        return requirementNumber;
    }

    useEffect(() => {
        api.getIntakeRequirements(complaint.intakeRequirementVersionId)
            .then((result) => setIntakeRequirements(result));
    }, [complaint]);

    useEffect(() => {
        if (!complaint || !intakeRequirements || intakeRequirements.length === 0) return;

        let responseModel = complaint.intakeRequirementResponseModels.map((intakeResponse) => drObjectFactory.createIntakeResponse(intakeResponse, getRequirementNumber(intakeResponse.requirementId)));

        for (let intakeRequirement of intakeRequirements.filter(f => f.requirementType !== IntakeRequirementType.Informational)) {
            let responses = responseModel.filter(f => f.requirementId === intakeRequirement.id);

            if(responses.length > 0)
                continue;

            const newIntakeResponse = drObjectFactory.createIntakeResponse({requirementId: intakeRequirement.id}, getRequirementNumber(intakeRequirement.id));
            responseModel.push(newIntakeResponse);
        }

        setIntakeResponses(responseModel);
    }, [complaint, intakeRequirements]);

    if (!complaint || intakeResponses.length === 0) return null;

    return <DrStateComplaintIntakeView
        complaint={complaint}
        handleDataChanged={handleDataChanged}
        handleIntakeChange={handleIntakeChange}
        handleSaveIntakeResponseForm={handleSaveIntakeResponseForm}
        handleSelectSection={handleSelectSection}
        intakeRequirements={intakeRequirements}
        intakeResponses={intakeResponses}
        readOnly={readOnly}
        updateAllResponses={updateAllResponses}
    />;
};

DrStateComplaintIntakeContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    complaint: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired
};

export default DrStateComplaintIntakeContainer;
