import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from "../common/buttons/ActionBar";
import ActionResponse from "./ActionResponse";
import ActionResponseForm from "./ActionResponseForm";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import AuditBar from "../common/buttons/AuditBar";
import * as ActionBarPositions from "../../constants/ActionBarPositions";
import Allow from "../../components/authorization/Allow";
import {
	MonitoringPerformancePolicy,
	policyEvents
} from "../../components/authorization/policies/MonitoringPerformancePolicy";
import * as PerformanceUtilities from "./MonitoringPerformanceUtilities";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";
import AuditHistory from "../audit/AuditHistory";

const IndicatorAction = ({
							 action,
							 onDeleteAction,
							 onEditAction,
							 onAddActionResponse,
							 onEditActionResponse,
							 onChangeActionResponse,
							 onSaveActionResponse,
							 onCancelActionResponse,
							 onResponseDocumentUpload,
							 onSubmitActionResponse,
							 onResponseOutcomeChange,
							 isEditing,
							 isLoading,
							 districtView,
							 editItemId,
							 onFileView,
							 onFileDelete,
							 onCommentCreate,
							 isCommentInEditMode,
							 handleCommentChange,
							 onCommentEdit,
							 onCommentSave,
							 onCommentCancel,
							 editCommentItemId
						 }) => {

	const responseId = action.response ? action.response.responseId : "";

	return (
		<section className={`performance__action`} id={action.actionId}>
			<div className={`performance__action__details`}>
				<h4>Action</h4>
				<div><strong>Action Type:</strong> {PerformanceUtilities.getActionTypeText(action.districtActionType)}
				</div>
				<div><strong>Due Date:</strong> {outputStandardDateFormat(action.dueDate, false)}</div>
				<div><strong>Summary:</strong> <HtmlDisplayBlock html={action.actionSummary} /></div>
			</div>
			{
				action.response &&
				editItemId !== action.response.responseId &&
				<>
					<ActionResponse
						response={action.response}
						isLoading={isLoading}
						onEditActionResponse={onEditActionResponse}
						onSubmitActionResponse={(indicatorAssessmentId, actionId) => onSubmitActionResponse(indicatorAssessmentId, actionId)}
						districtView={districtView}
						onFileView={onFileView}
						canEdit={action.canDistrictEditData}
					/>
					<AuditHistory
						audits={action.audits}
						onCommentCancel={onCommentCancel}
						onCommentSave={onCommentSave}
						onCommentEdit={onCommentEdit}
						handleCommentChange={handleCommentChange}
						editItemId={editCommentItemId}
						isInEditMode={isCommentInEditMode}
						isLoading={isLoading}
						onCommentCreate={onCommentCreate}
						parentIdPropName={"actionId"}
					/>
				</>
			}

			{
				districtView &&
				action.response &&
				isEditing &&
				editItemId === action.response.responseId &&
				<ActionResponseForm
					response={action.response}
					onChange={onChangeActionResponse}
					onCancel={onCancelActionResponse}
					onSave={onSaveActionResponse}
					isLoading={isLoading}
					onDocumentUpload={onResponseDocumentUpload}
					onFileView={onFileView}
					onFileDelete={onFileDelete}
				/>
			}

			{
				!action.response &&
				!isEditing &&
				districtView &&
				<Allow policy={MonitoringPerformancePolicy}
					   policyEvent={policyEvents.monitoring_performance.response.MODIFY}>
					<TileContainer rowTilesLarge="1" rowTilesMedium="1">
						<Tile body={"Add a Response"} onClick={onAddActionResponse} isNew={true}/>
					</TileContainer>
				</Allow>
			}


			{
				!action.response &&
				!isEditing &&
				!districtView &&
				action.canDistrictEditData &&
				<Allow policy={MonitoringPerformancePolicy}
					   policyEvent={policyEvents.monitoring_performance.action.MODIFY}>
					<ActionBar
						elementId={action.actionId}
						onDeleteClick={onDeleteAction}
						onEditClick={onEditAction}
						disabled={isLoading}
					/>
				</Allow>
			}

			{
				action.response &&
				!isEditing &&
				<Allow policy={MonitoringPerformancePolicy}
					   policyEvent={policyEvents.monitoring_performance.response.AUDIT}>
					<AuditBar
						elementId={action.actionId + "_" + responseId}
						isExpanded={true}
						outcome={action.actionStatus}
						onOutcomeChange={!districtView ? onResponseOutcomeChange : null}
						position={ActionBarPositions.RIGHT}
						disabled={isLoading}
					/>
				</Allow>
			}
		</section>
	);
};

IndicatorAction.propTypes = {
	action: PropTypes.object.isRequired,
	onDeleteAction: PropTypes.func,
	onEditAction: PropTypes.func,
	onAddActionResponse: PropTypes.func,
	onEditActionResponse: PropTypes.func,
	onChangeActionResponse: PropTypes.func,
	onSaveActionResponse: PropTypes.func,
	onCancelActionResponse: PropTypes.func,
	onResponseDocumentUpload: PropTypes.func,
	onFileView: PropTypes.func,
	onFileDelete: PropTypes.func,
	onSubmitActionResponse: PropTypes.func,
	onResponseOutcomeChange: PropTypes.func,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	districtView: PropTypes.bool.isRequired,
	editItemId: PropTypes.string,

	onCommentCreate: PropTypes.func,
	isCommentInEditMode: PropTypes.bool,
	handleCommentChange: PropTypes.func,
	onCommentEdit: PropTypes.func,
	onCommentSave: PropTypes.func,
	onCommentCancel: PropTypes.func,
	editCommentItemId: PropTypes.string
};

export default IndicatorAction;