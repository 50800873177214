import React from 'react';
import PropTypes from "prop-types";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import GridTable from "../../../../components/common/GridTable";

const GmsApplicationOverviewSection = ({
                                   children,
                                   sectionTitle,
                                   openSection
                               }) => {

    return (
        <CollapsibleContainer trigger={sectionTitle} open={openSection}>
            <GridTable>
                <thead>
                <tr>
                    <th>Section</th>
                    <th className={`action-no-wrap`}>Status</th>
                </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </GridTable>
        </CollapsibleContainer>
    );
};


GmsApplicationOverviewSection.propTypes = {
    children: PropTypes.any,
    sectionTitle: PropTypes.string.isRequired,
    openSection: PropTypes.bool.isRequired
};
export default GmsApplicationOverviewSection;