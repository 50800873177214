import {isNullOrUndefined} from "../common/commonUtilities";

export const createSummarySearchObject = ({
                                              districtId,
                                              districtName,
                                              id,
                                              isPinned,
                                              role,
                                              status,
                                              type,
                                              url
                                          } = {}) => ({
    districtId: districtId || "",
    districtName: districtName || "",
    id: id || "",
    isPinned: !isNullOrUndefined(isPinned) && isPinned,
    role: role || "",
    status: status || "",
    type: type || "",
    url: url || ""
});