import * as config from "../constants/config";
import * as ApiUtility from "./ApiUtility";
import * as ComplianceUtilities from "../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {monitoring_compliance_api as ApiSuccessMessages} from "../constants/api/ApiSuccessMessages";
import AjaxRequestOptions from "./requests/AjaxRequestOptions";
import { RequestTypes } from './requests/RequestTypes';
import {
    getComplianceAssessments,
    getDistrictStatuses,
    getProtocols,
    saveComplianceAssessments,
    getComplianceReassessments,
    saveComplianceReassessment
} from "../mockData/monitoringComplianceMockData";
import * as responseTypes from "../constants/monitoringCompliance/responseTypes";

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerMonitoringComplianceApi {
    static createDistrictComplianceAssessment(newAssessment){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/' + newAssessment.dateWindowId + '/assessments',
            RequestTypes.POST,
            JSON.stringify(newAssessment),
            ApiSuccessMessages.assessment.CREATE,
            'application/json'));
    }

    static updateDistrictComplianceAssessment(assessment){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/' + assessment.dateWindowId + '/assessments',
            RequestTypes.PUT,
            JSON.stringify(assessment),
            ApiSuccessMessages.assessment.UPDATE,
            'application/json'));
    }

    static deleteDistrictComplianceAssessment(districtId, assessmentId){
        const dto = {
            monitoringAssessmentId: assessmentId,
            districtId: districtId
        };
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/districts/' + districtId + "/assessments/" + assessmentId,
            RequestTypes.DELETE,
            JSON.stringify(dto),
            ApiSuccessMessages.assessment.DELETE,
            'application/json'));
    }

    static loadComplianceAssessment(assessmentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/assessments/' + assessmentId,
            RequestTypes.GET));
    }

    static loadDistrictComplianceAssessments(districtId, dateWindowId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/' + dateWindowId + '/districts/' + districtId + "/assessments",
            RequestTypes.GET));
    }

    static saveDistrictRequiredProtocols(monitoringAssessmentId, dateWindowId, indicatorRequirements){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/' + dateWindowId + '/assessments/' + monitoringAssessmentId + "/requirements",
            RequestTypes.PUT,
            JSON.stringify(indicatorRequirements),
            ApiSuccessMessages.protocol.SAVE,
            'application/json'));
    }

    static loadIndicatorProtocols(dateWindowId, indicatorId){
        let request = AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/' + dateWindowId + "/protocols/indicators/" + indicatorId,
            RequestTypes.GET);

        request.contentType = 'application/json';

        return ApiUtility.sendRequest(request);
    }

    static saveStudentRecord(districtId, assessmentId, studentRecordId, protocolResponses, responseType){
        const dto = {
            monitoringAssessmentId: assessmentId,
            districtId: districtId,
            studentRecordId: studentRecordId,
            protocolResponses: protocolResponses,
            responseType: responseType
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/districts/' + districtId + "/assessments/" + assessmentId,
            RequestTypes.POST,
            JSON.stringify(dto),
            ApiSuccessMessages.studentRecord.SAVE,
            'application/json'));
    }

    static saveReassessment(assessmentId, protocolResponses){
        const dto = {
            monitoringAssessmentId: assessmentId,
            protocolResponses: protocolResponses
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/reassessments/' + assessmentId,
            RequestTypes.POST,
            JSON.stringify(dto),
            ApiSuccessMessages.assessment.REASSESSMENT_SAVE,
            'application/json'));
    }

    static deleteStudentRecord(districtId, assessmentId, studentRecordId){
        const dto = {
            monitoringAssessmentId: assessmentId,
            districtId: districtId,
            studentRecordId: studentRecordId
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/districts/' + districtId + "/assessments/" + assessmentId + "/students/" + studentRecordId,
            RequestTypes.DELETE,
            JSON.stringify(dto),
            ApiSuccessMessages.studentRecord.DELETE,
            'application/json'));
    }

    static removeStudentRecordValidation(districtId, assessmentId, studentRecordId){
        const dto = {
            monitoringAssessmentId: assessmentId,
            districtId: districtId,
            studentRecordId: studentRecordId
        };

        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + 'monitoring/compliances/districts/' + districtId + "/assessments/" + assessmentId + "/students/" + studentRecordId + "/validation",
            RequestTypes.DELETE,
            JSON.stringify(dto),
            ApiSuccessMessages.studentRecord.REMOVEVALIDATION,
            'application/json'));
    }

    static submitValidation(assessmentId, indicatorRequirementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/protocols/${indicatorRequirementId}/validated`,
            RequestTypes.PUT,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId}),
            ApiSuccessMessages.assessment.VALIDATED,
            'application/json'));
    }

    static updateValidationNotes(assessmentId, indicatorRequirementId, validationNotes){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/protocols/${indicatorRequirementId}/notes`,
            RequestTypes.PUT,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId, validationNotes: validationNotes}),
            ApiSuccessMessages.assessment.NOTES_UPDATED,
            'application/json'));
    }

    static unsubmitAssessment(assessmentId, indicatorRequirementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/protocols/${indicatorRequirementId}/unsubmit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId}),
            ApiSuccessMessages.assessment.UNSUBMIT,
            'application/json'));
    }

    static unsubmitStudentRecord(assessmentId, studentRecordId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/students/${studentRecordId}/unsubmit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId, studentRecordId: studentRecordId}),
            ApiSuccessMessages.studentRecord.UNSUBMIT,
            'application/json'));
    }

    static unsubmitValidation(assessmentId, indicatorRequirementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/protocols/${indicatorRequirementId}/validated/unsubmit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId}),
            ApiSuccessMessages.assessment.VALIDATION_UNSUBMIT,
            'application/json'));
    }

    static unsubmitReassessment(assessmentId, indicatorRequirementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/assessments/${assessmentId}/protocols/${indicatorRequirementId}/reassessment/unsubmit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId}),
            ApiSuccessMessages.assessment.REASSESSMENT_UNSUBMIT,
            'application/json'));
    }

    static submitStudentRecords(districtId, assessmentId, indicatorRequirementId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl +`monitoring/compliances/districts/${districtId}/assessments/${assessmentId}/requirements/${indicatorRequirementId}/studentRecords/submit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId, indicatorRequirementId: indicatorRequirementId}),
            ApiSuccessMessages.studentRecord.SUBMIT,
            'application/json'));
    }

    static resubmitStudentRecords(districtId, assessmentId){
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl +`monitoring/compliances/districts/${districtId}/assessments/${assessmentId}/studentRecords/resubmit`,
            RequestTypes.POST,
            JSON.stringify({monitoringAssessmentId: assessmentId}),
            ApiSuccessMessages.studentRecord.SUBMIT,
            'application/json'));
    }

    static LoadDistrictComplianceStatuses(dateWindowId, indicatorId) {
        const indicatorUrl = indicatorId ? `/indicators/${indicatorId}` : ``;
        return ApiUtility.sendRequest(AjaxRequestOptions(
            config.apiUrl + `monitoring/compliances/${dateWindowId}${indicatorUrl}/assessments`,
            RequestTypes.GET));
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************
class MockMonitoringComplianceApi {
    static createDistrictComplianceAssessment(newAssessment){
        const assessmentDTO = ComplianceUtilities.newComplianceAssessment(newAssessment.districtId, newAssessment.dateWindowId, newAssessment.monitoringAssessmentId, newAssessment.indicatorRequirements, newAssessment.classification);
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessmentDTO);
            }, config.environment.delay);
        });
    }

    static updateDistrictComplianceAssessment(assessment){
        return new Promise(resolve => {
            setTimeout(() => {
                const otherAssessments = getComplianceAssessments().filter(a => a.monitoringAssessmentId !== assessment.monitoringAssessmentId);

                saveComplianceAssessments([...otherAssessments, assessment]);
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteDistrictComplianceAssessment(districtId, assessmentId){
        return new Promise(resolve => {
            setTimeout(() => {
                const otherAssessments = getComplianceAssessments().filter(a => a.monitoringAssessmentId !== assessmentId);

                saveComplianceAssessments([...otherAssessments]);
                resolve();
            }, config.environment.delay);
        });
    }

    static loadComplianceAssessment(assessmentId){
        const assessment = getComplianceAssessments().filter(a => a.monitoringAssessmentId === assessmentId)[0];

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment);
            }, config.environment.delay);
        });
    }

    static loadDistrictComplianceAssessments(districtId, dateWindowId){
        const assessment = getComplianceAssessments().filter(a => a.districtId === districtId && a.dateWindowId == dateWindowId)[0];

        return new Promise(resolve => {
            setTimeout(() => {
                resolve(assessment);
            }, config.environment.delay);
        });
    }

    static saveDistrictRequiredProtocols(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static loadIndicatorProtocols(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve([...getProtocols()]);
            }, config.environment.delay);
        });
    }

    static saveStudentRecord(districtId, assessmentId, studentRecordId, protocolResponses){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = getComplianceAssessments();
                const otherAssessments = assessments.filter(a => a.monitoringAssessmentId !== assessmentId);
                let assessment = assessments.filter(a => a.monitoringAssessmentId === assessmentId)[0];
                let studentRecord = assessment.studentRecords.filter(a => a.studentRecordId === studentRecordId)[0];
                studentRecord.protocolResponses = [...protocolResponses.map(r => {
                    return ({
                        protocolResponseId: r.protocolResponseId,
                        protocolId: r.protocolId,
                        response: r.response,
                        responseType: r.responseType
                    })
                })];

                saveComplianceAssessments([...otherAssessments, assessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static saveReassessment(assessmentId, protocolResponses){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessment = Object.assign({}, getComplianceReassessments());
                assessment.protocolResponses = [...protocolResponses.map(r => {
                    return ({
                        studentRecordId: r.studentRecordId,
                        protocolResponseId: r.protocolResponseId,
                        protocolId: r.protocolId,
                        response: r.response,
                        responseType: r.responseType,
                        subQuestionResponses: r.subQuestionResponses
                    })
                })];

                saveComplianceReassessment(assessment);

                resolve();
            }, config.environment.delay);
        });
    }

    static deleteStudentRecord(districtId, assessmentId, studentRecordId){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = getComplianceAssessments();
                const otherAssessments = assessments.filter(a => a.monitoringAssessmentId !== assessmentId);
                let assessment = assessments.filter(a => a.monitoringAssessmentId === assessmentId)[0];
                assessment.studentRecords = assessment.studentRecords.filter(a => a.studentRecordId !== studentRecordId);

                saveComplianceAssessments([...otherAssessments, assessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static removeStudentRecordValidation(districtId, assessmentId, studentRecordId){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = getComplianceAssessments();
                const otherAssessments = assessments.filter(a => a.monitoringAssessmentId !== assessmentId);
                let assessment = assessments.filter(a => a.monitoringAssessmentId === assessmentId)[0];
                let studentRecord = assessment.studentRecords.filter(a => a.studentRecordId === studentRecordId)[0];
                if(studentRecord && studentRecord.protocolResponses)
                    studentRecord.protocolResponses = studentRecord.protocolResponses.filter(r => r.responseType === responseTypes.ASSESS);

                saveComplianceAssessments([...otherAssessments, assessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static submitValidation(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static updateValidationNotes(assessmentId, indicatorRequirementId, validationNotes){
        return new Promise((resolve) => {
            setTimeout(() => {
                let assessments = getComplianceAssessments();
                const otherAssessments = assessments.filter(a => a.monitoringAssessmentId !== assessmentId);
                let assessment = assessments.find(a => a.monitoringAssessmentId === assessmentId);
                const otherIndicatorRequirements = assessment.indicatorRequirements.filter(a => a.indicatorRequirementId !== indicatorRequirementId);
                let indicatorRequirement = assessment.indicatorRequirements.find(a => a.indicatorRequirementId === indicatorRequirementId);
                if(indicatorRequirement) {
                    indicatorRequirement.validationNotes = validationNotes;
                    assessment.indicatorRequirements = [...otherIndicatorRequirements, indicatorRequirement];
                }

                saveComplianceAssessments([...otherAssessments, assessment]);

                resolve();
            }, config.environment.delay);
        });
    }

    static unsubmitAssessment(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static unsubmitStudentRecord(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static unsubmitValidation(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static unsubmitReassessment(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static submitStudentRecords(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static resubmitStudentRecords(){
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static LoadDistrictComplianceStatuses(dateWindowId) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getDistrictStatuses().filter(f => f.dateWindowId.toLowerCase() === dateWindowId.toLowerCase()));
            }, config.environment.delay);
        });
    }
}

const MonitoringComplianceApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerMonitoringComplianceApi : MockMonitoringComplianceApi;
export default MonitoringComplianceApi;
