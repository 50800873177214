import React from "react";
import PropTypes from "prop-types";
import DrStateComplaintOutcomeView from "./DrStateComplaintOutcomeView";

export const DrStateComplaintOutcomeContainer = ({
                                                       actions,
                                                       api,
                                                       complaint,
                                                       handleDataChanged,
                                                       handleSelectSection,
                                                       handleUpdateComplaint,
                                                       readOnly,
                                                   }) => {

    const handleSaveComplaint = (form) => {
         actions.executeApi(api.saveComplaint, [form])
            .then((result) => {
                handleUpdateComplaint(result);
            });
    };

    if (!complaint) return;

    return <DrStateComplaintOutcomeView
        complaint={complaint}
        handleSaveComplaint={handleSaveComplaint}
        handleSelectSection={handleSelectSection}
        handleDataChanged={handleDataChanged}
        readOnly={readOnly}
    />;
};

DrStateComplaintOutcomeContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    api: PropTypes.func.isRequired,
    complaint: PropTypes.object.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleSelectSection: PropTypes.func.isRequired,
    handleUpdateComplaint: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
};

export default DrStateComplaintOutcomeContainer;
