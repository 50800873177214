import React from "react";
import PropTypes from 'prop-types';
import {
    isArrayNullOrEmpty,
} from "../common/commonUtilities";
import GridTable from "../common/GridTable";
import {TaskItemForListItem} from "../tasks/TaskItem";
import ResultsPager from "../common/pagers/ResultsPager";
import {createSummarySearchObject} from "./summaryObjectFactory";

export const McSummaryView = ({
                                  filteredSummary,
                                  handleClickOrder,
                                  handleClickRecord,
                                  isDueNow,
                                  pagerMethods,
                                  pageSearchCriteria
                              }) => {

    const handleClickRecordEvent = (event, districtId, districtName, url) => {
        event.preventDefault();
        event.stopPropagation();

        handleClickRecord(createSummarySearchObject({
            districtId,
            districtName,
            url
        }));
    };

    const tableClassName = isDueNow ? "mc-summary-due-now-table" : "mc-summary-upcoming-table";

    return (
        <GridTable className={tableClassName}>
            <thead>
            <tr>
                <th onClick={handleClickOrder} data-id="districtName" className="is-clickable">
                    District Name
                </th>
                <th onClick={handleClickOrder} data-id="status" className="is-clickable">
                    Status
                </th>
                <th>
                    Tasks
                </th>
            </tr>
            </thead>
            <tbody>
            {
                isArrayNullOrEmpty(filteredSummary) &&
                <tr>
                    <td colSpan={3} className={"center"}>
                        <p><strong>No results match the search criteria specified.</strong></p>
                    </td>
                </tr>
            }
            {
                !isArrayNullOrEmpty(filteredSummary) &&
                pageSearchCriteria.GetCurrentPageRecords(filteredSummary).map((district, key) =>
                    (<tr key={key}
                         className={"is-clickable"}
                         onClick={(event) => handleClickRecordEvent(event, district.districtId, district.districtName)}>
                        <td>{district.districtName}</td>
                        <td>{district.status}</td>
                        <td>
                            <ul>
                                {
                                    district.tasks.map((task, index) => {
                                        return <TaskItemForListItem
                                            key={index}
                                            task={task}
                                            viewRecord={(event) => handleClickRecordEvent(event, district.districtId, district.districtName, task.url)}
                                        />;
                                    })
                                }
                            </ul>
                        </td>
                    </tr>)
                )
            }
            </tbody>
            <>
                {
                    filteredSummary.length > 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={3}>
                            <ResultsPager
                                currentPage={pageSearchCriteria.Page}
                                disabled={false}
                                first={pagerMethods.first}
                                last={pagerMethods.last}
                                next={pagerMethods.next}
                                previous={pagerMethods.previous}
                                recordsPerPage={pageSearchCriteria.RecordsPerPage}
                                totalRecords={filteredSummary.length}
                            />
                        </td>
                    </tr>
                    </tfoot>
                }
            </>
        </GridTable>
    );
};

McSummaryView.propTypes = {
    filteredSummary: PropTypes.array,
    handleClickOrder: PropTypes.func.isRequired,
    handleClickRecord: PropTypes.func.isRequired,
    isDueNow: PropTypes.bool.isRequired,
    pagerMethods: PropTypes.object.isRequired,
    pageSearchCriteria: PropTypes.object.isRequired,
};

export default McSummaryView;