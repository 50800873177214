import React from "react";
import PropTypes from 'prop-types';
import GridTable from "../../../components/common/GridTable";

export const McReviewSummaryView = ({
                                        protocolsInNonCompliance,
                                        studentRecordsInNonCompliance,
                                        totalStudents,
                                    }) => {
    return (
        <GridTable className={`compliance__submission-review-table`}>
            <thead>
            <tr>
                <th>Summary</th>
                <th className={`text-center`}>Totals</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Total student records</td>
                <td>{totalStudents}</td>
            </tr>
            <tr>
                <td>
                    Total student records found non-compliant by the district
                    {
                        studentRecordsInNonCompliance.length > 0 &&
                        <ul>
                            {
                                studentRecordsInNonCompliance.map((studentRecord, index) => <li
                                    key={index}>{studentRecord.name}</li>)
                            }
                        </ul>
                    }
                </td>
                <td>{studentRecordsInNonCompliance.length}</td>
            </tr>
            <tr>
                <td>
                    Total protocols found non-compliant by the district
                    {
                        protocolsInNonCompliance.length > 0 &&
                        <ul>
                            {
                                protocolsInNonCompliance.map((protocol, index) => <li key={index}>{protocol}</li>)
                            }
                        </ul>
                    }
                </td>
                <td>{protocolsInNonCompliance.length}</td>
            </tr>
            </tbody>
        </GridTable>
    );
};

McReviewSummaryView.propTypes = {
    protocolsInNonCompliance: PropTypes.array.isRequired,
    studentRecordsInNonCompliance: PropTypes.array.isRequired,
    totalStudents: PropTypes.number.isRequired,
};

export default McReviewSummaryView;