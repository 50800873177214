import React from 'react';
import PropTypes from 'prop-types';
import GridTable from "../../components/common/GridTable";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import * as MonitoringComplianceUtilities from "./MonitoringComplianceUtilities";
import {isArrayNullOrEmpty} from "../common/commonUtilities";
import {Allow} from "../authorization/Allow";
import {
    MonitoringCompliancePolicy,
    policyEvents
} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import Button from "../common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";

const RecordRequirementsTable = ({
                                     canEdit = false,
                                     canUnsubmit = false,
                                     indicatorRequirement,
                                     isLoading,
                                     onClickAddStudentRecord,
                                     onClickDeleteStudentRecord,
                                     onClickUnsubmitStudentRecord,
                                     onEditClick,
                                     requirementRecords = []
                                 }) => {
    return (
        <>
            <h3>{MonitoringComplianceUtilities.createIndicatorTitle(indicatorRequirement.title, indicatorRequirement.publicIdentifier)}</h3>
            <p>Records Required: {indicatorRequirement.studentRecordsRequirementCount}, Records
                Added: {requirementRecords.length}</p>
            <GridTable>
                <thead>
                <tr>
                    <th>
                        GSW Student Number
                    </th>
                    <th className={"text-center"}>
                        Total Compliant
                    </th>
                    <th className={"text-center"}>
                        Total Non-Compliant
                    </th>
                    <th className={"text-center"}>
                        Status
                    </th>
                    <th className={"text-center"}>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    isArrayNullOrEmpty(requirementRecords) &&
                    <tr>
                        <td colSpan={5} className={`center`}> No records.</td>
                    </tr>
                }
                {
                    !isArrayNullOrEmpty(requirementRecords) &&
                    requirementRecords.map(record => {
                        const canEditStudentRecord = canEdit && !record.isRecordSubmitted;
                        const canUnsubmitStudentRecord = canUnsubmit && record.isRecordSubmitted;
                        return (
                            <tr key={record.studentRecordId}>
                                <td>{record.name}</td>
                                <td className={"text-center"}>{record.totalCompliance}</td>
                                <td className={"text-center"}>{record.totalNonCompliance}</td>
                                <td className={"text-center"}>{MonitoringComplianceUtilities.generateStudentRecordStatusText(record)}</td>
                                <td className={`action-no-wrap text-center`}>
                                    <Button
                                        name={`btnEdit_${record.studentRecordId}`}
                                        onClick={(e) => onEditClick(e, indicatorRequirement.indicatorRequirementId, record.studentRecordId)}
                                        label={`${canEditStudentRecord ? "Edit" : "View"} Student Record for - ${record.name}`}
                                        buttonType={canEditStudentRecord ? ButtonTypes.EDIT : ButtonTypes.VIEW}
                                        showLabel={false}
                                    />
                                    {
                                        canEditStudentRecord &&
                                        <Button
                                            name={`btnDelete_${record.studentRecordId}`}
                                            onClick={(e) => onClickDeleteStudentRecord(e, record.studentRecordId)}
                                            label={`Delete Student Record for - ${record.name}`}
                                            buttonType={ButtonTypes.DELETE}
                                            showLabel={false}
                                        />
                                    }
                                    {
                                        canUnsubmitStudentRecord &&
                                        <Button
                                            name={`btnUnsubmit_${record.studentRecordId}`}
                                            onClick={(e) => onClickUnsubmitStudentRecord(e, record.studentRecordId)}
                                            label={`Unsubmit Student Record for - ${record.name}`}
                                            buttonType={ButtonTypes.HISTORY}
                                            showLabel={false}
                                        />
                                    }
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
                <tfoot>
                {
                    canEdit &&
                    !MonitoringComplianceUtilities.isAssessmentRecordsSubmitted(requirementRecords) &&
                    <Allow policy={MonitoringCompliancePolicy}
                           policyEvent={policyEvents.monitoring_compliance.studentRecord.MODIFY}>
                        <tr>
                            <td colSpan={5}>
                                <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                                    <Tile
                                        body={"Add Student Record"}
                                        onClick={!isLoading ? (event) => onClickAddStudentRecord(event, indicatorRequirement.indicatorRequirementId) : null}
                                        isNew={true}
                                        isMedium={true}
                                    />
                                </TileContainer>
                            </td>
                        </tr>
                    </Allow>
                }
                </tfoot>
            </GridTable>
        </>
    );
};

RecordRequirementsTable.propTypes = {
    canEdit: PropTypes.bool,
    canUnsubmit: PropTypes.bool,
    indicatorRequirement: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onClickAddStudentRecord: PropTypes.func,
    onClickDeleteStudentRecord: PropTypes.func,
    onClickUnsubmitStudentRecord: PropTypes.func,
    onEditClick: PropTypes.func,
    requirementRecords: PropTypes.arrayOf(PropTypes.object)
};

export default RecordRequirementsTable;