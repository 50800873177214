import React from 'react';
import PropTypes from 'prop-types';
import FormTable from "../../../../components/common/FormTable";
import DateTimePickerField from "../../../../components/common/inputs/DateTimePickerField";
import TextAreaEditor from "../../../../components/common/inputs/textAreaEditor/TextAreaEditor";
import SectionHeader from "../../../../components/common/SectionHeader";

const ActivityResultsForm = ({
                                 activityResultsForm,
                                 disabledForm
                             }) => {

    return (
        <>
            <SectionHeader>
                <h3>Activity Results</h3>
            </SectionHeader>
            <FormTable>
                <DateTimePickerField
                    {...activityResultsForm.dateCorrected}
                    medium={6}
                    disabled={disabledForm}
                    label={"Date Corrected"}
                    showLabel
                />
                <TextAreaEditor
                    {...activityResultsForm.results}
                    disabled={disabledForm}
                    height={400}
                    label={`Results`}
                    showLabel
                />
            </FormTable>
        </>
    );
};

ActivityResultsForm.propTypes = {
    activityResultsForm: PropTypes.object.isRequired,
    disabledForm: PropTypes.bool.isRequired,
};

export default ActivityResultsForm;