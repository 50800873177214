import React from 'react';
import PropTypes from 'prop-types';
import CollapsibleContainer from "../common/CollapsibleContainer";
import ActionBar from "../common/buttons/ActionBar";
import IndicatorAction from "./IndicatorAction";
import IndicatorActionForm from "./IndicatorActionForm";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import {actionTypes} from "../../constants/monitoringPerformance/monitoringPerformanceActionTypes";
import Allow from "../../components/authorization/Allow";
import {
	MonitoringPerformancePolicy,
	policyEvents
} from "../../components/authorization/policies/MonitoringPerformancePolicy";
import HtmlDisplayBlock from "../htmlDisplay/HtmlDisplayBlock";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import * as ActionBarPositions from "../../constants/ActionBarPositions";

const AssessmentIndicator = ({
								 indicatorAssessment,
								 indicators,
								 onEditIndicator,
								 onDeleteIndicator,
								 isEditing,
								 editItemId,
								 isLoading,
								 onCancel,
								 onAddAction,
								 onSaveAction,
								 onDeleteAction,
								 onChangeAction,
								 onEditAction,
								 isNewAction,
								 onOpenIndicator,
								 districtView,
								 onAddActionResponse,
								 onEditActionResponse,
								 onChangeActionResponse,
								 onSaveActionResponse,
								 onCancelActionResponse,
								 onResponseDocumentUpload,
								 onFileView,
								 onFileDelete,
								 onSubmitActionResponse,
								 onResponseOutcomeChange,
								 assessmentId,
								 onCommentCreate,
								 isCommentInEditMode,
								 handleCommentChange,
								 onCommentEdit,
								 onCommentSave,
								 onCommentCancel,
								 editCommentItemId
							 }) => {

	const indicatorAssessmentId = indicatorAssessment.indicatorAssessmentId;
	const indicator = indicators.filter(i => i.value === indicatorAssessment.indicatorId)[0];

	if(!indicator)
		return null;

	const collapsibleContainerTrigger = (
		<GridRow>
			<GridColumn medium={"6"}>
				{indicator.text}
			</GridColumn>
			<GridColumn medium={"6"}>
				{
					districtView &&
					<span>
						Responses Needing to be Submitted: {indicatorAssessment.totalInProgressOrNeedsRevision}
					</span>
				}
				{
					!districtView &&
					<span>
						Needs Review: {indicatorAssessment.totalSubmittedAndReviewable}
					</span>
				}
			</GridColumn>
		</GridRow>);

	return (
		<CollapsibleContainer trigger={collapsibleContainerTrigger}
							  onOpening={() => onOpenIndicator(assessmentId, indicatorAssessmentId)} isFirstLoadOnlyOnOpen>
			<section>
				{
					indicatorAssessment.explanationForAssessment &&
					<HtmlDisplayBlock html={indicatorAssessment.explanationForAssessment} />
				}
				{
					!isEditing &&
					!districtView &&
					<ActionBar
						elementId={indicatorAssessmentId}
						editPolicyEvent={policyEvents.monitoring_performance.indicator.MODIFY}
						onEditClick={(event) => onEditIndicator(event, indicatorAssessmentId)}
						onDeleteClick={(event) => onDeleteIndicator(event, indicatorAssessmentId)}
						policy={MonitoringPerformancePolicy}
						deletePolicyEvent={policyEvents.monitoring_performance.indicator.MODIFY}
						disabled={isLoading}
						position={ActionBarPositions.TOP_RIGHT}
					/>
				}
				{
					indicatorAssessment.indicatorActions &&
					indicatorAssessment.indicatorActions.map((action) => {
						const actionId = action.actionId;

						if (!districtView && isEditing && actionId === editItemId) {
							return (
								<IndicatorActionForm
									key={actionId}
									action={action}
									onChange={(event) => onChangeAction(event, indicatorAssessmentId, actionId)}
									onSave={(event) => onSaveAction(event, indicatorAssessmentId)}
									onCancel={(event) => onCancel(event, assessmentId, indicatorAssessmentId)}
									actionTypes={actionTypes}
									isNewAction={isNewAction}
								/>);
						}
						else {
							return (
								<IndicatorAction
									key={actionId}
									action={action}
									onDeleteAction={(event) => onDeleteAction(event, indicatorAssessmentId, actionId)}
									onEditAction={() => onEditAction(indicatorAssessmentId, actionId)}
									isLoading={isLoading}
									isEditing={isEditing}
									districtView={districtView}
									onAddActionResponse={(event) => onAddActionResponse(event, assessmentId, indicatorAssessmentId, actionId)}
									onEditActionResponse={onEditActionResponse}
									onChangeActionResponse={onChangeActionResponse}
									onSaveActionResponse={(event) => onSaveActionResponse(event, indicatorAssessmentId, actionId)}
									onCancelActionResponse={(event) => onCancelActionResponse(event, assessmentId, indicatorAssessmentId)}
									onResponseDocumentUpload={onResponseDocumentUpload}
									onSubmitActionResponse={() => onSubmitActionResponse(indicatorAssessmentId, actionId)}
									onResponseOutcomeChange={(event) => onResponseOutcomeChange(event, {
										assessmentId,
										indicatorAssessmentId,
										actionId
									})}
									editItemId={editItemId}
									onFileView={onFileView}
									onFileDelete={onFileDelete}
									editCommentItemId={editCommentItemId}
									handleCommentChange={handleCommentChange}
									isCommentInEditMode={isCommentInEditMode}
									onCommentCancel={(event) => onCommentCancel(event, assessmentId, indicatorAssessmentId)}
									onCommentCreate={onCommentCreate}
									onCommentEdit={onCommentEdit}
									onCommentSave={(event, comment, actionId) => onCommentSave(event, comment, actionId, assessmentId, indicatorAssessmentId)}
								/>);
						}
					})
				}
				{
					(!indicatorAssessment.indicatorActions || indicatorAssessment.indicatorActions.length === 0) &&
					<p>No actions at this time</p>
				}
				{
					!isEditing &&
					!districtView &&
					<Allow policy={MonitoringPerformancePolicy}
						   policyEvent={policyEvents.monitoring_performance.action.MODIFY}>
						<TileContainer rowTilesLarge="1" rowTilesMedium="1">
							<Tile body={"Add Action"}
								  onClick={(event) => onAddAction(event, indicatorAssessment.indicatorAssessmentId)}
								  isNew={true}/>
						</TileContainer>
					</Allow>
				}
			</section>
		</CollapsibleContainer>
	);
};

AssessmentIndicator.propTypes = {
	indicatorAssessment: PropTypes.object.isRequired,
	indicators: PropTypes.arrayOf(PropTypes.object).isRequired,

	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	editItemId: PropTypes.string,
	isNewAction: PropTypes.bool,

	onEditIndicator: PropTypes.func,
	onDeleteIndicator: PropTypes.func,
	onCancel: PropTypes.func,
	onAddAction: PropTypes.func,
	onSaveAction: PropTypes.func,
	onDeleteAction: PropTypes.func,
	onChangeAction: PropTypes.func,
	onEditAction: PropTypes.func,
	onOpenIndicator: PropTypes.func,

	districtView: PropTypes.bool.isRequired,

	onAddActionResponse: PropTypes.func,
	onEditActionResponse: PropTypes.func,
	onChangeActionResponse: PropTypes.func,
	onSaveActionResponse: PropTypes.func,
	onCancelActionResponse: PropTypes.func,
	onResponseDocumentUpload: PropTypes.func,
	onFileView: PropTypes.func,
	onFileDelete: PropTypes.func,
	onSubmitActionResponse: PropTypes.func,
	onResponseOutcomeChange: PropTypes.func,
	assessmentId: PropTypes.string,

	onCommentCreate: PropTypes.func,
	isCommentInEditMode: PropTypes.bool,
	handleCommentChange: PropTypes.func,
	onCommentEdit: PropTypes.func,
	onCommentSave: PropTypes.func,
	onCommentCancel: PropTypes.func,
	editCommentItemId: PropTypes.string
};

export default AssessmentIndicator;