import PropTypes from "prop-types";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import {DisplayField, TextField} from "../../../components/common/inputs";
import React, {useState} from "react";
import {isNullOrUndefined, isTrimmedStringEmpty} from "../../../components/common/commonUtilities";
import SectionHeader from "../../../components/common/SectionHeader";
import Button from "../../../components/common/buttons/Button";
import * as ButtonTypes from "../../../constants/ButtonTypes";
import {
    clearInputFormErrorFields,
    inputFormIsValid, isValidPhone,
    useFormInputObject
} from "../../../components/common/inputs/inputUtility";
import {gridConstants} from "../../../constants/inputConstants";
import {createProgramUserObject} from "../ptsObjectFactory";
import {NotifyUser} from "../../../components/common/NotifyUser";
import * as AuthorizationUtilities from "../../../components/authorization/AuthorizationUtilities";
import {PtsPolicy} from "../../../components/authorization/policies/PtsPolicy";
import * as policyEvents from "../../../constants/policyEvents";

export const PtsProgramUserView = ({
                                       handleDataChanged,
                                       isCompleted = true,
                                       isLoading,
                                       programUser,
                                       sectionClass = "",
                                       sectionTitle,
                                       updateProgramUser,
                                   }) => {
    const canEdit = AuthorizationUtilities.allow(PtsPolicy, policyEvents.MANAGE) && !isCompleted;
    const [isEditing, setIsEditing] = useState(false);
    const programUserForm = useFormInputObject(createProgramUserObject(programUser), () => handleDataChanged());
    const handleEditProgramUser = (event) => {
        event.preventDefault();
        setIsEditing(true);
    };

    const isValid = () => {
        clearInputFormErrorFields(programUserForm);
        if (!isValidPhone(programUserForm.phoneNumber.value))
            programUserForm.phoneNumber.setError("Phone number is invalid.");

        let isValid = inputFormIsValid(programUserForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleSaveProgramUser = (event) => {
        event.preventDefault();
        if (isValid()) {
            updateProgramUser(programUser.programUserId, programUserForm.phoneNumber.value);
            handleDataChanged(false);
            setIsEditing(false);
        }
    };

    const handleCancelProgramUser = (event) => {
        event.preventDefault();

        programUserForm.phoneNumber.setValue(programUser.phoneNumber);
        handleDataChanged(false);
        setIsEditing(false);
    };

    if (isTrimmedStringEmpty(programUser.fullName)
        && isTrimmedStringEmpty(programUser.email)
        && isTrimmedStringEmpty(programUser.phoneNumber))
        return null;

    const hasPhoneNumber = !isNullOrUndefined(updateProgramUser);
    const phoneNumber = !isTrimmedStringEmpty(programUserForm.phoneNumber.value)
        ? programUserForm.phoneNumber.value
        : "None provided.";

    return <>
        {
            sectionTitle &&
            <SectionHeader>
                <h2>{sectionTitle}</h2>
            </SectionHeader>
        }
        <section className={sectionClass}>
            {
                !isTrimmedStringEmpty(programUser.fullName) &&
                <DisplayField name={`programUserName`} label={`Name`}
                              showLabel>{programUser.fullName}</DisplayField>
            }
            {
                !isTrimmedStringEmpty(programUser.email) &&
                <DisplayField name={`programUserEmail`} label={`Email`}
                              showLabel>{programUser.email}</DisplayField>
            }
            {
                hasPhoneNumber &&
                <GridRow rowClass={`programUser__phoneNumber`}>
                    {
                        !isEditing &&
                        <>
                            <GridColumn small={gridConstants.column.NINE}>
                                <DisplayField name={`programUserPhone`} label={`Phone`}
                                              showLabel>{phoneNumber}</DisplayField>
                            </GridColumn>
                            {
                                canEdit &&
                                <GridColumn small={gridConstants.column.THREE}>
                                    <Button name={`btnEditProgramUser`} label={`Edit`} showLabel={false}
                                            buttonType={ButtonTypes.EDIT}
                                            onClick={handleEditProgramUser} disabled={isLoading}/>
                                </GridColumn>
                            }
                        </>
                    }
                    {
                        canEdit && isEditing &&
                        <>
                            <GridColumn small={gridConstants.column.SEVEN}>
                                <TextField {...programUserForm.phoneNumber} label={`Phone Number`} showLabel/>
                            </GridColumn>
                            <GridColumn small={gridConstants.column.FIVE}>
                                <Button name={`btnSaveProgramUser`} label={`Save`} showLabel={false}
                                        buttonType={ButtonTypes.SAVE}
                                        onClick={handleSaveProgramUser} disabled={isLoading}/>

                                <Button name={`btnCancelProgramUser`} label={`Cancel`} showLabel={false}
                                        buttonType={ButtonTypes.CANCEL}
                                        onClick={handleCancelProgramUser} disabled={isLoading}/>
                            </GridColumn>
                        </>
                    }
                </GridRow>
            }
        </section>
    </>;
};

PtsProgramUserView.propTypes = {
    handleDataChanged: PropTypes.func.isRequired,
    isCompleted: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    programUser: PropTypes.object,
    sectionClass: PropTypes.string,
    sectionTitle: PropTypes.string,
    updateProgramUser: PropTypes.func,
};