import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as sppActions from "../../actions/sppActions";
import * as sppAdminActions from "../../actions/sppAdminActions";
import * as shardDataActions from "../../actions/sharedDataActions";
import * as sharedDataUtilities from "../../components/shared/sharedDataUtilities";
import GridTable from "../../components/common/GridTable";
import * as documentStatus from "../../constants/spp/documentStatus";
import Button from "../../components/common/buttons/Button";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";

class SppAdminDocumentsPage extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onCopySppDocument = this.onCopySppDocument.bind(this);
		this.onCopySppResponses = this.onCopySppResponses.bind(this);
		this.onToggleSppDocument = this.onToggleSppDocument.bind(this);
	}

	componentDidMount() {
		if (this.props.dateWindows.length === 0)
			this.props.actions.loadAllDateWindows();

		if (this.props.instances.length === 0)
			this.props.actions.loadSppInstances();
	}

	onCopySppDocument(event, instanceId) {
		event.preventDefault();

		this.props.actions.CopySppDocument(instanceId)
			.then(() => this.props.actions.loadSppInstances());
	}

	onCopySppResponses(event, instanceId) {
		event.preventDefault();

		this.props.actions.CopySppResponses(instanceId);
	}

	onToggleSppDocument(event, instanceId) {
		event.preventDefault();

		this.props.actions.ToggleSppDocumentStatus(instanceId);
	}

	render() {
		if (this.props.instances.length === 0 ||
			this.props.dateWindowSelectItems.length === 0)
			return null;

		return (
			<div>
				{
					!this.props.isLoading &&
					this.props.instances.length > 0 &&
					<GridTable>
						<thead>
						<tr>
							<th>Document Year</th>
							<th>Status</th>
							<th>Actions</th>
						</tr>
						</thead>
						<tbody>
						{
							this.props.instances.map((instance, index) => {
								const dateWindowText = this.props.dateWindowSelectItems.filter(dw => dw.value === instance.dateWindowId)[0].text;
								const toggleButtonLabel = instance.status === documentStatus.EDITING ? 'Publish Document' : 'Un-Publish Document';
									return (
										<tr key={index}>
											<td>{dateWindowText}</td>
											<td>{instance.status}</td>
											<td>
												<ButtonBar position={ButtonBarPositions.TABLE}>
													{
														index === 0 &&
														instance.status === documentStatus.PUBLISHED &&
														<Button name={"clone|" + instance.id}
														        label={"Copy Document"}
														        showLabel
														        onClick={(event) => this.onCopySppDocument(event, instance.id)}
														        buttonType={ButtonTypes.CLONE}
														        disabled={this.props.isLoading}/>
													}
													{
														instance.status === documentStatus.EDITING &&
														<Button name={"import|" + instance.id}
														        label={"Copy Responses"}
														        showLabel
														        onClick={(event) => this.onCopySppResponses(event, instance.id)}
														        buttonType={ButtonTypes.CLONE}
														        disabled={this.props.isLoading}/>
													}
													{
														index === 0 &&
														<Button name={"toggle|" + instance.id}
														        label={toggleButtonLabel}
														        showLabel
														        onClick={(event) => this.onToggleSppDocument(event, instance.id)}
														        buttonType={ButtonTypes.SYSTEM}
														        disabled={this.props.isLoading}/>
													}
												</ButtonBar>
											</td>
										</tr>
									);
								}
							)
						}
						</tbody>
					</GridTable>
				}
			</div>
		);
	}
}

SppAdminDocumentsPage.propTypes = {
	actions: PropTypes.object.isRequired,
	dateWindows: PropTypes.arrayOf(PropTypes.object).isRequired,
	dateWindowSelectItems: PropTypes.arrayOf(PropTypes.object).isRequired,
	instances: PropTypes.arrayOf(PropTypes.object),
	isLoading: PropTypes.bool.isRequired,
	institutions: PropTypes.array
};

function mapStateToProps(state) {
	let dateWindowSelectItems = sharedDataUtilities.generateDateWindowSelectListItems(state.sharedData.dateWindows);

	return {
		dateWindows: state.sharedData.dateWindows,
		dateWindowSelectItems,
		instances: state.spp.sppInstances,
		isLoading: state.ajaxCallsInProgress > 0
	};
}

function mapDispatchToProps(dispatch) {
	const combinedActions = Object.assign(
		{},
		shardDataActions,
		sppActions,
		sppAdminActions
	);

	return {
		actions: bindActionCreators(combinedActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SppAdminDocumentsPage);