import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {bindActionCreators} from 'redux';
import LocalStorage from "../../components/shared/LocalStorage";
import * as systemLocations from "../../constants/systemLocations";
import * as accountActions from "../../actions/accountActions";
import LoginForm from '../../components/account/LoginForm';
import {setPageTitle} from "../../components/common/commonUtilities";
import {NotifyUser} from "../../components/common/NotifyUser";

export class LoginPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loginViewModel: {
				username: "",
				password: "",
				token: ""
			},
			isLoading: false,
			errors: {}
		};

		setPageTitle();

		this.onClick = this.onClick.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.redirect = this.redirect.bind(this);
	}

	componentDidMount() {
		this.checkActiveToken();

		const usernameTb = document.getElementById("username");
		if (usernameTb)
			usernameTb.focus();
	}

	checkActiveToken() {
		if(LocalStorage.isLoggedIn())
			this.redirect(true);
	}

	onFormChange(e) {
		const loginViewModel = this.state.loginViewModel;
		const name = e.target.name;

		loginViewModel[name] = e.target.value;

		this.setState({loginViewModel: loginViewModel});
	}

	loginFormIsValid() {
		let formIsValid = true;
		let errors = {};

		const {username, password} = this.state.loginViewModel;
		if (username.trim().length === 0) {
			errors.username = 'Username is required.';
			formIsValid = false;
		}

		if (password.trim().length === 0) {
			errors.password = 'Password is required.';
			formIsValid = false;
		}

		this.setState({errors: errors});
		return formIsValid;
	}

	onClick(e) {
		e.preventDefault();

		if (!this.loginFormIsValid()) {
			NotifyUser.Warning("Please fix the issues as specified and try logging in again.");
			return;
		}

		this.setState({isLoading: true});
		this.props.actions.attemptLogin(this.state.loginViewModel)
			.then(() => {
				this.redirect(false);
			})
			.catch(() => {
				this.setState({isLoading: false});
			});
	}

	redirect(isUserLoggedIn) {
		const {isLoggedIn, redirectUrl} = this.props.account;
		if (isLoggedIn || isUserLoggedIn) {
			let redirectStr = (redirectUrl !== undefined && redirectUrl.toString() !== "")
				? redirectUrl.toString()
				: systemLocations.DASHBOARD.path;
			this.props.history.push(redirectStr);
		}
	}

	render() {
		return (
			<LoginForm
				loginViewModel={this.state.loginViewModel}
				onClick={this.onClick}
				onChange={this.onFormChange}
				isLoading={this.state.isLoading}
				errors={this.state.errors}
			/>
		);
	}
}

LoginPage.propTypes = {
	actions: PropTypes.object.isRequired,
	account: PropTypes.object,
	history: PropTypes.object.isRequired
};


function mapStateToProps(state) {
	return {
		account: state.account
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(accountActions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);