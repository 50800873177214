import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Allow} from "../../../../components/authorization/Allow";
import {allow} from "../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../components/authorization/policies/BpiePolicy";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import {createListItem} from "../../../../components/common/commonUtilities";
import RadioField from "../../../../components/common/inputs/RadioField";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import {
    SBPIE_ASSESSMENT_STATUS,
    sbpieActivateAssessmentStatusList, sbpieAssessmentStatusList
} from "../../bpieConstants";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import GridRow from "../../../../components/common/GridRow";
import GridColumn from "../../../../components/common/GridColumn";
import {gridConstants, inputStyles} from "../../../../constants/inputConstants";

export const BpieSchoolSummaryStatus = ({
                                            handleActivateSbpie,
                                            handleCreateSbpie,
                                            handleUpdateStatus,
                                            hasAdministrator,
                                            isDistrictUser,
                                            schoolDetails
                                        }) => {

    const [currentStatus, setCurrentStatus] = useState(schoolDetails.currentStatus);

    const handleChangeCurrentStatus = (event) => {
        event.preventDefault();
        setCurrentStatus(parseInt(event.target.value));
    }

    const handleClickStatusUpdate = (event) => {
        event.preventDefault();
        let confirmMessage = ``;
        switch (currentStatus) {
            case SBPIE_ASSESSMENT_STATUS.Pending.id:
                confirmMessage = `change the status to pending`;
                break;
            case SBPIE_ASSESSMENT_STATUS.Activated.id:
                confirmMessage = `activate the SBPIE Assessment`;
                break;
            case SBPIE_ASSESSMENT_STATUS.NotApplicable.id:
                confirmMessage = `no longer allow the SBPIE Assessment to be activated`;
                break;
        }
        if (confirm(`Are you sure you want to ${confirmMessage}?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page.`)) {
            handleUpdateStatus(schoolDetails.districtId, schoolDetails.msidNumber, currentStatus);
        }
    }

    const handleClickCreateSbpie = (event) => {
        event.preventDefault();
        if (confirm("Are you sure you want to create and activate a SBPIE Assessment?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            handleCreateSbpie(schoolDetails.districtId, schoolDetails.msidNumber);
    };

    const handleClickActivateSbpie = (event) => {
        event.preventDefault();
        if (confirm("Are you sure you want to activate the SBPIE Assessment?\n\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            handleActivateSbpie(schoolDetails.districtId, schoolDetails.msidNumber);
    };

    const getMissingAdministratorMessage = (section) => {
        return <p className={`page-directions-highlight`}>The ability to {section} is currently disabled as the current
            school administrator email address is missing.</p>;
    }

    const areAnyStatusOptionsAvailable = () => {
        if (schoolDetails.eligibleForNewSbpie && allow(BpiePolicy, policyEvents.schoolBpieAssessments.CREATE_ACTIVATE))
            return true;

        if (schoolDetails.canActivateSbpie && isDistrictUser && allow(BpiePolicy, policyEvents.schoolBpieAssessments.CREATE_ACTIVATE))
            return true;

        if (schoolDetails.canUpdateStatus && allow(BpiePolicy, policyEvents.schoolBpieAssessments.UPDATE_STATUS))
            return true;

        return false;
    }

    const getActivateOptions = () => {
        const initialStatus = sbpieAssessmentStatusList.find(f => f.id === schoolDetails.currentStatus);
        if(initialStatus.id === SBPIE_ASSESSMENT_STATUS.Pending.id || initialStatus.id === SBPIE_ASSESSMENT_STATUS.NotApplicable.id)
            return sbpieActivateAssessmentStatusList;

        return [initialStatus, createListItem(SBPIE_ASSESSMENT_STATUS.NotApplicable.id, SBPIE_ASSESSMENT_STATUS.NotApplicable.description)];
    };

    let activateOptions = getActivateOptions();
    let activateWarningMessage = null;
    if (!hasAdministrator) {
        if (schoolDetails.eligibleForNewSbpie)
            return getMissingAdministratorMessage(`create and activate a new SBPIE`);

        if (isDistrictUser) {
            return schoolDetails.canUpdateStatus
                ? getMissingAdministratorMessage(`activate the SBPIE Assessment`)
                : getMissingAdministratorMessage(`update the SBPIE status`);
        }

        activateOptions = sbpieActivateAssessmentStatusList.filter(f => f.id !== SBPIE_ASSESSMENT_STATUS.Activated.id);
        activateWarningMessage = getMissingAdministratorMessage(`activate the SBPIE Assessment`);
    }

    if (!areAnyStatusOptionsAvailable())
        return null;

    return (
        <>
            {activateWarningMessage}
            <ButtonBar position={ButtonBarPositions.TOP} className={`summary-status`}>
                {
                    schoolDetails.eligibleForNewSbpie &&
                    <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                        <Button
                            label={`Create and Activate SBPIE Assessment for ${schoolDetails.currentYearDatesCovered}`}
                            name={`btnCreateSbpieAssessment`}
                            onClick={handleClickCreateSbpie}
                            showLabel
                        />
                    </Allow>
                }
                {
                    schoolDetails.canActivateSbpie && !schoolDetails.eligibleForNewSbpie &&
                    isDistrictUser &&
                    <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.CREATE_ACTIVATE}>
                        <Button
                            label={`Activate SBPIE Assessment for ${schoolDetails.currentYearDatesCovered}`}
                            name={`btnActivateSbpieAssessment`}
                            onClick={handleClickActivateSbpie}
                            showLabel
                        />
                    </Allow>
                }
                {
                    schoolDetails.canUpdateStatus &&
                    <Allow policy={BpiePolicy} policyEvent={policyEvents.schoolBpieAssessments.UPDATE_STATUS}>
                        <GridRow medium={gridConstants.column.TWO} rowClass={"summary-school-admin"}>
                            <GridColumn>
                                <RadioField
                                    direction={inputStyles.radio.direction.HORIZONTAL}
                                    legend={`Status for ${schoolDetails.currentYearDatesCovered}`}
                                    name={`currentStatus`}
                                    options={activateOptions}
                                    onChange={handleChangeCurrentStatus}
                                    showLegend
                                    value={currentStatus}
                                />
                            </GridColumn>
                            <GridColumn>
                                <Button
                                    buttonType={ButtonTypes.SAVE}
                                    disabled={currentStatus === schoolDetails.currentStatus}
                                    label={`Save Status Update`}
                                    name={`btnSaveStatusUpdate`}
                                    onClick={handleClickStatusUpdate}
                                    showLabel
                                />
                            </GridColumn>
                        </GridRow>
                    </Allow>
                }
            </ButtonBar>
        </>
    );
};

BpieSchoolSummaryStatus.propTypes = {
    handleActivateSbpie: PropTypes.func.isRequired,
    handleCreateSbpie: PropTypes.func.isRequired,
    handleUpdateStatus: PropTypes.func.isRequired,
    hasAdministrator: PropTypes.bool.isRequired,
    isDistrictUser: PropTypes.bool.isRequired,
    schoolDetails: PropTypes.object.isRequired,
};