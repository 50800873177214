import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import Button from "../../../../components/common/buttons/Button";
import ButtonBar from "../../../../components/common/buttons/ButtonBar";
import CollapsibleContainer from "../../../../components/common/CollapsibleContainer";
import {isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";
import Tile from "../../../../components/common/Tiles/Tile";
import TileContainer from "../../../../components/common/Tiles/TileContainer";
import * as ButtonBarPositions from "../../../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../../../constants/ButtonTypes";
import {emptyGuid} from "../../../../constants/config";
import gmsApi from "../../gmsApi";
import * as gmsLocations from "../../gmsLocations";
import {GmsApplicationNavigation} from "../GmsApplicationNavigation";
import GmsApplicationSalaryForm from "./GmsApplicationSalaryForm";

const GmsApplicationSalaryContainer = ({
                                           actions,
                                           applicationId,
                                           history,
                                       }) => {
    const [summary, setSummary] = useState(null);

    const loadApplicationSalaries = () => {
        actions.executeApi(gmsApi.getApplicationSalaries, [applicationId])
            .then((result) => {
                setSummary(result);
            });
    };

    const handleDelete = (salaryId) => {
        if (confirm("Deleting this Salary Increase is permanent. Continue?"))
            actions.executeApi(gmsApi.deleteApplicationSalary, [applicationId, salaryId])
                .then((result) => {
                    setSummary(result);
                });
    };

    const handleEdit = (event, id = emptyGuid) => {
        event && event.preventDefault();
        const resolvedPath = gmsLocations.GMS_APPLICATION_SALARIES_EDIT.getUrl(applicationId, id);
        history.push(resolvedPath);
    };


    useEffect(() => {
        loadApplicationSalaries();
    }, []);

    useEffect(() => {
        if (summary?.applicationTitle)
            actions.updatePageTitle(summary.applicationTitle);

        actions.updatePageSubTitle('Salary Increase');

        return actions.clearSubTitle;
    }, [summary]);

    return <>
        {
            summary?.salaries &&
            summary.salaries.map((salary) => {
                return <CollapsibleContainer trigger={salary.positionTitle} key={salary.id}>
                    <GmsApplicationSalaryForm salary={salary}/>
                    <ButtonBar position={ButtonBarPositions.TABLE}>
                        <Button
                            buttonType={ButtonTypes.EDIT}
                            label={"Edit"}
                            name={`btnEdit${salary.id}`}
                            onClick={handleEdit}
                        />
                        <Button
                            buttonType={ButtonTypes.DELETE}
                            label={"Delete"}
                            name={`btnDelete${salary.id}`}
                            onClick={handleDelete}
                        />
                    </ButtonBar>
                </CollapsibleContainer>;
            })
        }
        {
            isArrayNullOrEmpty(summary?.salaries) &&
            <p>Currently, there are no salary increase forms completed. Use the &ldquo;Add Salary Increase&rdquo; button below.</p>
        }

        <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
            <Tile body={"Add Salary Increase"}
                  isNew={true}
                  isMedium={true}
                  onClick={handleEdit}
            />
        </TileContainer>
        <GmsApplicationNavigation
            applicationId={applicationId}
            history={history}
            sectionId={"Salaries"}
        />
    </>;
};


GmsApplicationSalaryContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

export default GmsApplicationSalaryContainer;