import * as config from "../../../constants/config";
import * as ApiUtility from "../../../api/ApiUtility";
import AjaxRequestOptions from "../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from '../../../api/requests/RequestTypes';
import {dr_api as ApiSuccessMessages} from "../../../constants/api/ApiSuccessMessages";

const disputeAdminApi = 'dispute-resolution/admin';

//************************************************** STANDARD API SERVER CALLS ****************************************
class ServerDrAdminIntakeApi {
    static getIntakeRequirements() {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/intake-requirements/`,
                RequestTypes.GET));
    }

    static saveIntakeRequirementVersion(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/intake-versions/${model.versionId}`,
                RequestTypes.PUT,
                JSON.stringify(model),
                ApiSuccessMessages.admin.intakeVersions.SAVED));
    }

    static saveIntakeRequirement(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/intake-versions/${model.versionId}/intake-requirements/${model.requirementId}`,
                RequestTypes.PUT,
                JSON.stringify(model),
                ApiSuccessMessages.admin.intakeRequirements.SAVED));
    }

    static deleteIntakeVersion(versionId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/intake-versions/${versionId}`,
                RequestTypes.DELETE,
                null,
                ApiSuccessMessages.admin.intakeVersions.DELETED));
    }

    static deleteIntakeRequirement(versionId, requirementId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${disputeAdminApi}/intake-versions/${versionId}/intake-requirements/${requirementId}`,
                RequestTypes.DELETE,
                null,
                ApiSuccessMessages.admin.intakeRequirements.DELETED));
    }

    static saveElementOrder(versionId, sortedElement) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
            `${config.apiUrl}${disputeAdminApi}/intake-versions/${versionId}/sort`,
                RequestTypes.POST,
                JSON.stringify(sortedElement),
                ApiSuccessMessages.admin.intakeVersions.SAVE_ORDER,
            'application/json'));
    }
}

//************************************************** MOCK API SERVER CALLS ****************************************
class MockDrAdminIntakeApi {
    static getIntakeRequirements() {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveIntakeRequirementVersion(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveIntakeRequirement(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteIntakeVersion(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static deleteIntakeRequirement(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }

    static saveElementOrder(){
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, config.environment.delay);
        });
    }
}

const DrAdminIntakeApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerDrAdminIntakeApi : MockDrAdminIntakeApi;
export default DrAdminIntakeApi;