import {actionTypes} from '../../constants/monitoringPerformance/monitoringPerformanceActionTypes';
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";

export function newAssessmentIndicator(monitoringAssessmentId, indicatorAssessmentId, indicatorId = "", isRequired = false, explanationForAssessment = "") {
	return {
		indicatorAssessmentId,
		indicatorId,
		monitoringAssessmentId,
		isRequired,
		explanationForAssessment
	};
}

export function newIndicatorAction(monitoringAssessmentId, indicatorAssessmentId, actionId, actionSummary = "", districtActionType = "", dueDate = "") {
	if(isNaN(parseInt(districtActionType)))
		districtActionType = getActionTypeValue(districtActionType);

	return {
		monitoringAssessmentId,
		indicatorAssessmentId,
		actionId,
		actionSummary,
		districtActionType,
		canDistrictEditData: true,
		dueDate
	};
}

export function newIndicatorActionResponse(monitoringAssessmentId, indicatorAssessmentId, actionId, response) {
	return {
		responseId: response.responseId,
		monitoringAssessmentId,
		indicatorAssessmentId,
		actionId,
		resolutionDescription: response.resolutionDescription ? response.resolutionDescription : "",
		dateResolutionCompleted: response.dateResolutionCompleted ? outputStandardDateFormat(response.dateResolutionCompleted, false) : "",
		fileItems: response.fileItems ? response.fileItems : []
	};
}


export function isArray(value) {
	return (typeof value === 'object' && value.constructor === Array);
}

export function getAction(assessment, actionId) {
	let theAction;

	if (!assessment.indicatorAssessments)
		return;

	for (let i = 0; i < assessment.indicatorAssessments.length; i++) {
		if (!assessment.indicatorAssessments[i].indicatorActions) continue;

		for (let j = 0; j < assessment.indicatorAssessments[i].indicatorActions.length; j++) {
			if (assessment.indicatorAssessments[i].indicatorActions[j].actionId === actionId)
				theAction = assessment.indicatorAssessments[i].indicatorActions[j];
		}
	}

	return Object.assign({}, theAction);
}

export function getActionResponse(assessment, responseId) {
	let actions = [];
	const monitoringAssessmentId = assessment.monitoringAssessmentId;

	for (let i = 0; i < assessment.indicatorAssessments.length; i++) {
		if (!assessment.indicatorAssessments[i].indicatorActions) continue;

		for (let j = 0; j < assessment.indicatorAssessments[i].indicatorActions.length; j++) {
			const action = Object.assign(
				{indicatorAssessmentId: assessment.indicatorAssessments[i].indicatorAssessmentId},
				assessment.indicatorAssessments[i].indicatorActions[j]
			);
			actions.push(action);
		}
	}

	const action = actions
		.filter(act => act.response)
		.filter(act => act.response.responseId === responseId)[0];

	return Object.assign(
		{
			monitoringAssessmentId,
			actionId: action.actionId,
			indicatorAssessmentId: action.indicatorAssessmentId
		},
		action.response
	);
}

export function getActionTypeText(actionTypeValue) {
	let actionType = actionTypes.find(f => f.value.toString() === actionTypeValue);

	if(!actionType)
		return actionTypeValue;

	return actionType.text;
}

export function getActionTypeValue(actionTypeText) {
	let actionType = actionTypes.find(f => f.text === actionTypeText);

	if(!actionType)
		return "";

	return actionType.value.toString();
}