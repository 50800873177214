import {createListFromObject} from "../../../../components/common/commonUtilities";

export const meetingLeaderTitles = {
    FINFacilitator: "FIN Facilitator",
    SchoolPrincipal: "School Principal",
    AssistantPrincipal: "Assistant Principal",
    GuidanceCounselor: "Guidance Counselor",
    SchoolPsychologist: "School Psychologist",
    DeanOfStudents: "Dean of Students",
    InstructionalCoach: "Instructional Coach",
    DistrictStaff: "District Staff",
    TeacherLeader: "Teacher Leader",
    ESELiaison: "ESE Liaison",
    SpecialEducationTeamLeader: "Special Education Team Leader",
    Other: "Other"
}

export const meetingLeaderTitlesList = createListFromObject(meetingLeaderTitles);

export const schoolBpieTrainingStatus = {
    NotSelected: 1,
    TakeAssessment: 2,
    TakeTraining: 3,
    PassedTraining: 4,
    FailedTraining: 5
};