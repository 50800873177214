import React from 'react';
import PropTypes from 'prop-types';
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import CollapsibleContainer from "../common/CollapsibleContainer";
import CollapsibleTrigger from "../common/CollapsibleTrigger";
import * as sppUtilities from "./SppUtilities";
import * as sppElementTypes from "../../constants/spp/sppElementTypes";
import audit_outcomes from "../../constants/auditOutcomes";
import LocalStorage from "../../components/shared/LocalStorage";
import GridTable from "../common/GridTable";

const DocumentPart = ({
                          partId,
                          documentHierarchy,
                          elements,
                          onPrintPartClick,
                          responses,
                          selectSection,
                          showOnlyNeeds = false,
                      }) => {
    const partHierarchy = documentHierarchy.filter(hier => hier.elementId === partId)[0];
    const partElement = elements.filter(element => element.id === partId.toLowerCase())[0];
    const partResponses = sppUtilities.getPartResponses(partElement.id, documentHierarchy, elements, responses);
    const currentUser = LocalStorage.getLoggedInUser();
    const notifyDistrict = "(Needs Revision)";
    const notifyState = "(Needs Review)";
    let status = null;

    const reviseResponses = partResponses.filter(
        response =>
            response.audits.length > 0 &&
            response.audits[0].outcome === audit_outcomes.NEEDS_REVISION.enum).length;

    const submittedResponses = partResponses.filter(
        response =>
            response.audits.length > 0 &&
            response.audits[0].outcome === audit_outcomes.SUBMITTED.enum).length;

    if (currentUser) {
        if (currentUser.InstitutionId === null && submittedResponses > 0)
            status = notifyState;
        else if (currentUser.InstitutionId !== null && reviseResponses > 0)
            status = notifyDistrict;
    }

    const trigger = status ? <CollapsibleTrigger text={partElement.text} status={status}/> : partElement.text;

    return <>
        <CollapsibleContainer trigger={trigger}>
            <ButtonBar position={ButtonBarPositions.TABLE}>
                <Button name="btnPrint"
                        label="Print Part"
                        buttonType={ButtonTypes.PRINT}
                        onClick={(event) => onPrintPartClick(event, partElement.id)}
                />
            </ButtonBar>
            <GridTable>
                <thead>
                <tr>
                    <th className="center">Section</th>
                    {
                        currentUser &&
                        <th className="center">Progress</th>
                    }
                    {
                        currentUser &&
                        currentUser.InstitutionId === null &&
                        <th className="center">Total Responses Needing Review</th>
                    }
                    {
                        currentUser &&
                        <th className="center">Total Responses Needing Revision</th>
                    }
                    <th className="center">Files</th>
                </tr>
                </thead>
                <tbody>
                {
                    partHierarchy.childElements.map(sectionHierarchyItem => {
                        const sectionElement = elements.filter(el => el.id === sectionHierarchyItem.elementId.toLowerCase())[0];

                        const sectionElements =
                            sppUtilities.getSectionElements(
                                sectionHierarchyItem.elementId,
                                documentHierarchy,
                                elements, false);

                        const sectionResponses =
                            sppUtilities.getSectionResponses(
                                sectionHierarchyItem.elementId,
                                documentHierarchy,
                                elements,
                                responses);

                        const sectionProgress =
                            sppUtilities.calculateProgress(sectionElements, sectionResponses);

                        const sectionFileElements = sectionElements.filter(el => el.layout === sppElementTypes.File);
                        const sectionFileElementIds = sectionFileElements.map(el => el.id);

                        const sectionFileResponses = sectionFileElementIds.filter(elementId => {
                            return sectionResponses.some(response => response.elementId === elementId && response.data !== "");
                        });

                        const totalNeedsReview = sectionResponses.filter(response => response.audits.length > 0 && response.audits[0].outcome === audit_outcomes.SUBMITTED.enum).length;
                        const totalNeedsRevision = sectionResponses.filter(response => response.audits.length > 0 && response.audits[0].outcome === audit_outcomes.NEEDS_REVISION.enum).length;

                        if (showOnlyNeeds) {
                            if (!status || status === notifyState && totalNeedsReview === 0 || status === notifyDistrict && totalNeedsRevision === 0)
                                return;
                        }

                        return (
                            <tr key={sectionElement.id} onClick={(event) => selectSection(event, sectionElement.id)}
                                id={sectionElement.id} className="is-clickable">
                                <td>{sectionElement.text}</td>
                                {
                                    currentUser &&
                                    <td className="center">{sectionProgress}</td>
                                }
                                {
                                    currentUser &&
                                    currentUser.InstitutionId === null &&
                                    <td className="center">{totalNeedsReview}</td>
                                }
                                {
                                    currentUser &&
                                    <td className="center">{totalNeedsRevision}</td>
                                }
                                <td className="center">{sectionFileResponses.length}</td>
                            </tr>);
                    })
                }
                </tbody>
            </GridTable>
        </CollapsibleContainer>
    </>;
};

DocumentPart.propTypes = {
    partId: PropTypes.string.isRequired,
    documentHierarchy: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)]).isRequired,
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
    onPrintPartClick: PropTypes.func.isRequired,
    selectSection: PropTypes.func.isRequired,
    responses: PropTypes.arrayOf(PropTypes.object).isRequired,
    showOnlyNeeds: PropTypes.bool,
};

export default DocumentPart;