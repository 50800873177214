import React from "react";
import {Switch} from "react-router-dom";
import * as contexts from "../constants/contexts";
import EnsureLoggedIn from "../containers/account/EnsureLoggedIn";
import PrintLayout from "../containers/layout/PrintLayout";
import StateComplaintTasksContainer from "./StateComplaints/PrintReports/StateComplaintTasksContainer";
import withTracker from "../withTracker";
import {MemoizedDrDistrictContainer} from "./District/DrDistrictContainer";
import * as drLocations from "./drLocations";
import DrDueProcessActionContainer from "./DueProcesses/Actions/DrDueProcessActionContainer";
import DrDueProcessWrapper from "./DueProcesses/DrDueProcessWrapper";
import DrDueProcessOverviewContainer from "./DueProcesses/Overview/DrDueProcessOverviewContainer";
import DrDueProcessResolutionProcessContainer from "./DueProcesses/ResolutionProcess/DrDueProcessResolutionProcessContainer";
import DrDueProcessFinalOrderContainer from "./DueProcesses/FinalOrder/DrDueProcessFinalOrderContainer";
import DrDueProcessCaseNotesContainer from "./DueProcesses/CaseNotes/DrDueProcessCaseNotesContainer";
import DrMediationOverviewContainer from "./Mediations/DrMediationOverviewContainer";
import DrStateComplaintActionContainer from "./StateComplaints/Actions/DrStateComplaintActionContainer";
import DrStateComplaintClosureProcessContainer from "./StateComplaints/Closure/DrStateComplaintClosureProcessContainer";
import DrStateComplaintClosureNoActionsContainer from "./StateComplaints/ClosureWithNoActions/DrStateComplaintClosureNoActionsContainer";
import DrStateComplaintDistrictWrapper from "./StateComplaints/DrStateComplaintDistrictWrapper";
import DrStateComplaintWrapper from "./StateComplaints/DrStateComplaintWrapper";
import DrStateComplaintTimelineContainer from "./StateComplaints/Timeline/DrStateComplaintTimelineContainer";
import DrStateComplaintRoutingContainer from "./StateComplaints/Routing/DrStateComplaintRoutingContainer";
import DrStateComplaintOutcomeContainer from "./StateComplaints/Outcome/DrStateComplaintOutcomeContainer";
import DrStateComplaintIssueContainer from "./StateComplaints/Issues/DrStateComplaintIssueContainer";
import DrStateComplaintOverviewContainer from "./StateComplaints/Overview/DrStateComplaintOverviewContainer";
import DrStateComplaintResponseContainer from "./StateComplaints/Responses/DrStateComplaintResponseContainer";
import DrSummaryContainer from "./DrSummary/DrSummaryContainer";
import DrDistrictSummaryContainer from "./DistrictSummary/DrDistrictSummaryContainer";
import DrCaseListContainer from "./CaseList/DrCaseListContainer";
import DrCaseloadContainer from "./Caseload/DrCaseloadContainer";
import DrCaseResearchContainer from "./CaseResearch/DrCaseResearchContainer";
import DrAdminWrapper from "./Admin/DrAdminWrapper";
import DrAdminTeamMembersContainer from "./Admin/TeamMembers/DrAdminTeamMembersContainer";
import DrAdminIntakeContainer from "./Admin/Intake/DrAdminIntakeContainer";
import DrStateComplaintIntakeContainer from "./StateComplaints/Intake/DrStateComplaintIntakeContainer";
import DrStateComplaintCaseNotesContainer from "./StateComplaints/CaseNotes/DrStateComplaintCaseNotesContainer";

const DrRoutes = () => {

	return (
		<Switch>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrSummaryContainer)}
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.DR_SUMMARY.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDistrictSummaryContainer)}
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.DR_DISTRICT_SUMMARY.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrCaseListContainer)}
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.LANDING_CASE_LIST.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrCaseloadContainer)} //
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.DR_CASELOAD_SUMMARY.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrCaseResearchContainer)}
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.DR_CASE_RESEARCH_SUMMARY.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				component={withTracker(MemoizedDrDistrictContainer)}
				context={contexts.DISPUTE_RESOLUTION_CONTEXTS}
				path={drLocations.LANDING_DISTRICT.path}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintOverviewContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_OVERVIEW_DOE.path}
				wrapperPage={DrStateComplaintWrapper}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintIntakeContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_INTAKE.path}
				wrapperPage={DrStateComplaintWrapper}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintCaseNotesContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_CASE_NOTES.path}
				wrapperPage={DrStateComplaintWrapper}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintTimelineContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_TIMELINE.path}
				wrapperPage={DrStateComplaintWrapper}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintRoutingContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_ROUTING.path}
				wrapperPage={DrStateComplaintWrapper}
			/>
			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintOutcomeContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_OUTCOME.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrMediationOverviewContainer)}
				context={[contexts.MEDIATIONS]}
				path={drLocations.MEDIATION_OVERVIEW.path}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintIssueContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_ISSUES.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDueProcessOverviewContainer)}
				context={[contexts.DUE_PROCESS]}
				path={drLocations.DUE_PROCESS_OVERVIEW.path}
				wrapperPage={DrDueProcessWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDueProcessResolutionProcessContainer)}
				context={[contexts.DUE_PROCESS]}
				path={drLocations.DUE_PROCESS_RESOLUTION_PROCESS.path}
				wrapperPage={DrDueProcessWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDueProcessFinalOrderContainer)}
				context={[contexts.DUE_PROCESS]}
				path={drLocations.DUE_PROCESS_FINAL_ORDER.path}
				wrapperPage={DrDueProcessWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDueProcessActionContainer)}
				context={[contexts.DUE_PROCESS]}
				path={drLocations.DUE_PROCESS_ACTIONS.path}
				wrapperPage={DrDueProcessWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrDueProcessCaseNotesContainer)}
				context={[contexts.DUE_PROCESS]}
				path={drLocations.DUE_PROCESS_CASE_NOTES.path}
				wrapperPage={DrDueProcessWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintActionContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_ACTIONS.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintResponseContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_RESPONSES.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintResponseContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_DISTRICT.path}
				wrapperPage={DrStateComplaintDistrictWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintClosureProcessContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_CLOSURE_PROCESS.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrStateComplaintClosureNoActionsContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.STATE_COMPLAINT_CLOSURE_WITH_NO_ACTIONS.path}
				wrapperPage={DrStateComplaintWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrAdminTeamMembersContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.DR_ADMIN_TEAM_MEMBERS.path}
				wrapperPage={DrAdminWrapper}
			/>

			<EnsureLoggedIn
				authorizationRequired
				exact
				component={withTracker(DrAdminIntakeContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				path={drLocations.DR_ADMIN_INTAKE.path}
				wrapperPage={DrAdminWrapper}
			/>

			<PrintLayout
				path={drLocations.STATE_COMPLAINT_TASK_PRINT.path}
				component={withTracker(StateComplaintTasksContainer)}
				context={[contexts.STATE_COMPLAINTS]}
				authorizationRequired
			/>
		</Switch>
	);
};

export default DrRoutes;