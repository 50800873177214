import React from 'react';
import PropTypes from 'prop-types';
import InputAdapter from "./InputAdapter";

const PasswordField = (props) => {
	return <InputAdapter
		{...props}
		inputType={`password`}
		inputClass="input-password"
	/>;
};

PasswordField.propTypes = {
	align: PropTypes.string,
	columnsSmall: PropTypes.number,
	columnsMedium: PropTypes.number,
	columnsLarge: PropTypes.number,
	disabled: PropTypes.bool,
	elementEditBar: PropTypes.object,
	error: PropTypes.string,
	label: PropTypes.string.isRequired,
	labelClass: PropTypes.string,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	showLabel: PropTypes.bool,
	value: PropTypes.string,
	wrapperClass: PropTypes.string,
};

export default PasswordField;