import * as elementTypes from "../../constants/surveys/elementTypes";
import {emptyGuid} from "../../constants/config";
import {answerChoicePrefix} from "../../constants/surveys/idPrefixes";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";

function newElement(componentId, parentId = emptyGuid, elementType = elementTypes.TEXTBOX, labelText = "", answerChoices = []){
    return {
        componentId: componentId,
        labelText: labelText,
        subtext: "",
        elementType: elementType,
        isRequired: false,
        parentId: parentId,
        answerChoices: answerChoices
    };
}

function newAnswerChoice(componentId, parentId){
    return {
        componentId: componentId,
        text: "",
        value: "",
        parentId: parentId
    };
}

function newSurveyDetails(dateWindowId) {
    return {
        closeDate: "",
        dateWindowId: dateWindowId,
        status: surveyStatuses.Editing,
        surveyId: "",
        title: "",
        version: ""
    };
}

function resetComponentIdBackToEmptyGuid(elements) {
    const fixedElements = [...elements].map((component) => {
        let newComponent = {...component};

        newComponent.answerChoices = [...newComponent.answerChoices].map((choice) => {
            let newChoice = {...choice};
            newChoice.componentId = (newChoice.componentId.startsWith(answerChoicePrefix) ? emptyGuid : newChoice.componentId);

            return newChoice;
        });

        return newComponent;
    });

    return fixedElements;
}

export { newElement, newAnswerChoice, newSurveyDetails, resetComponentIdBackToEmptyGuid };