import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/common/buttons/Button";
import DisplayField from "../../components/common/inputs/DisplayField";
import GridTable from "../../components/common/GridTable";
import SelectField from "../../components/common/inputs/SelectField";
import TextField from "../../components/common/inputs/TextField";
import TileContainer from "../../components/common/Tiles/TileContainer";
import Tile from "../../components/common/Tiles/Tile";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {createFakeEvent} from "../../components/common/inputs/inputUtility";
import {EnrollmentLocationList} from "../drConstants";
import {createSchoolInformationObject} from "../drObjectFactory";
import {getRandomInt} from "../../components/common/HtmlUtilities";
import {isArrayNullOrEmpty} from "../../components/common/commonUtilities";
import {styles as ActionButton} from "../../constants/ActionButton";

const DrSchoolInformationEdit = ({
                                                   onChange: setSchoolInformationRecords,
                                                   readOnly,
                                                   value: schoolInformationRecords
                                               }) => {
    const handleClickAddSchool = () => {
        let updatedSchoolInformationRecords = [...schoolInformationRecords];
        const id = `${getRandomInt()}`;
        updatedSchoolInformationRecords.push(createSchoolInformationObject({id}));
        handleSetSchools(updatedSchoolInformationRecords);
    };

    const handleClickRemoveSchool = (event) => {
        let updatedSchoolInformationRecords = [...schoolInformationRecords];
        const id = event.target.id;
        updatedSchoolInformationRecords = updatedSchoolInformationRecords.filter(s => s.id !== id);
        handleSetSchools(updatedSchoolInformationRecords);
    };

    const handleChangSchool = (event, id) => {
        let updatedSchoolInformationRecords = [...schoolInformationRecords];
        let school = updatedSchoolInformationRecords.filter(s => s.id === id)[0];
        const field = event.target.name;
        school[field] = event.target.value;
        handleSetSchools(updatedSchoolInformationRecords);
    };

    const handleSetSchools = (updatedSchoolInformationRecords) => {
        setSchoolInformationRecords(createFakeEvent(updatedSchoolInformationRecords, "schoolInformationRecords"));
    };

    return (
        <DisplayField label={"Associated Schools"} name="schoolInformationRecords" showLabel>
            {
                !isArrayNullOrEmpty(schoolInformationRecords) &&
                <GridTable>
                    <thead>
                    <tr className="text-center">
                        <th>School Code</th>
                        <th>Enrollment Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        schoolInformationRecords.map((school, key) => {
                            return <tr className="text-center" key={key}>
                                <td>
                                    <TextField
                                        disabled={readOnly}
                                        error={school.schoolCodeError}
                                        label={`School Code for row ${key}`}
                                        name="schoolCode"
                                        showLabel={false}
                                        onChange={(event) => handleChangSchool(event, school.id)}
                                        value={school.schoolCode}
                                    />
                                </td>
                                <td>
                                    <SelectField
                                        disabled={readOnly}
                                        error={school.enrollmentLocationError}
                                        includeDefaultOption
                                        label={`Enrollment Location for row ${key}`}
                                        name="enrollmentLocation"
                                        onChange={(event) => handleChangSchool(event, school.id)}
                                        options={EnrollmentLocationList}
                                        value={school.enrollmentLocation}
                                    />
                                    <span className={ActionButton.position.RIGHT}>
										<Button name={`${school.id}`}
                                                label={"Remove"}
                                                showLabel={false}
                                                onClick={handleClickRemoveSchool}
                                                buttonType={ButtonTypes.REMOVE}/>
									</span>
                                </td>
                            </tr>;
                        })
                    }
                    </tbody>
                </GridTable>
            }

            <TileContainer rowTilesLarge={"1"} rowTilesMedium={"1"}>
                <Tile body={"Add a School"}
                      onClick={handleClickAddSchool}
                      isNew={true}
                      isMedium={true}/>
            </TileContainer>
        </DisplayField>
    );
};

DrSchoolInformationEdit.propTypes = {
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.array.isRequired
};

export default DrSchoolInformationEdit;