import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HtmlDisplayInline from "../htmlDisplay/HtmlDisplayInline";

const OrderDragDrop = ({ onDragEnd, containerId, elementSets, elementIdName, elementContentName }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={containerId.toString()}>
                {(provided) => (
                    <div ref={provided.innerRef} className={"draggable-container"}>
                        {elementSets.map((elementSet, index) => {
                            return (
                                <Draggable key={elementSet[elementIdName]}
                                           draggableId={elementSet[elementIdName].toString()} index={index}>
                                    {(provided) => (
                                        <div ref={provided.innerRef}
                                             {...provided.draggableProps}
                                             {...provided.dragHandleProps}
                                             className={"draggable-area"}
                                        >
                                            <HtmlDisplayInline html={elementSet[elementContentName]} />
                                        </div>
                                    )}
                                </Draggable>
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>

    );
};

OrderDragDrop.propTypes = {
    onDragEnd: PropTypes.func.isRequired,
    containerId: PropTypes.string.isRequired,
    elementIdName: PropTypes.string.isRequired,
    elementSets: PropTypes.array.isRequired,
    elementContentName: PropTypes.string.isRequired
};

export default OrderDragDrop;