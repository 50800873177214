import React from 'react';
import PropTypes from 'prop-types';
import {getGeneralProtocolRequirementStatus} from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import * as MonitoringComplianceUtilities from "../../components/MonitoringCompliance/MonitoringComplianceUtilities";
import {emptyGuid} from "../../constants/config";
import * as ComplianceLocations from "../../constants/monitoringCompliance/monitoringComplianceLocations";
import * as responseTypes from "../../constants/monitoringCompliance/responseTypes";
import BackNextPager from "../../components/common/pagers/BackNextPager";
import {animateScroll as scroll} from "react-scroll/modules";
import ReassessGrid from "../../components/MonitoringCompliance/Reassess/ReassessGrid";
import MatrixOfService from "../../components/MonitoringCompliance/MatrixOfService";
import * as AuthorizationUtilities from "../../components/authorization/AuthorizationUtilities";
import {MonitoringCompliancePolicy} from "../../components/authorization/policies/MonitoringCompliancePolicy";
import {monitoring_compliance} from "../../constants/policyEvents";

export class ReassessDistrictPage extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            protocolResponses: [],
            params: {}
        };

        this.onChangeProtocolResponse = this.onChangeProtocolResponse.bind(this);
        this.onChangeSubQuestionResponse = this.onChangeSubQuestionResponse.bind(this);
        this.onChangeMatrixResponse = this.onChangeMatrixResponse.bind(this);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        if (this.state.protocolResponses.length === 0 &&
            this.props.selectedAssessment &&
            this.props.selectedAssessment.protocolResponsesToBeReassessed) {

            this.initializeData();
        }
    }

    componentDidUpdate() {
        if (this.state.protocolResponses.length === 0 &&
            this.props.selectedAssessment &&
            this.props.selectedAssessment.protocolResponsesToBeReassessed) {

            this.initializeData();
        }
    }

    initializeData() {
        const generalAssessmentStatus = getGeneralProtocolRequirementStatus(this.props.selectedAssessment);

        if(!generalAssessmentStatus.validationSubmitted ||
            !generalAssessmentStatus.studentRecordsWithDiscrepanciesRequiringReassessment ||
            generalAssessmentStatus.allAssessmentsApproved)
            return this.props.history.push(ComplianceLocations.LANDING_DISTRICT.path);

        let protocolResponses = [...this.props.selectedAssessment.protocolResponsesToBeReassessed.map(rec => Object.assign({}, rec))];
        this.setState({
            protocolResponses: this.setDefaultResponse(protocolResponses)
        });

        const currentProtocol = this.loadProtocol()[0];
        this.props.actions.updatePageTitle(`Reassessment of ${currentProtocol.publicIdentifier}`);
    }

    onChangeProtocolResponse(event, protocolResponseId, studentRecordId, protocolId) {
        this.setState({
            protocolResponses: this.state.protocolResponses.map(rec => {
                    if (rec.protocolResponseId === protocolResponseId && rec.responseType === responseTypes.REASSESS && rec.studentRecordId === studentRecordId && rec.protocolId === protocolId)
                        rec.response = event.target.value;

                    return rec;
            })
        });
        this.props.handleDataChanged();
    }

    onChangeSubQuestionResponse(event, protocolResponseId, studentRecordId, protocolId, index) {
        this.setState({
            protocolResponses: this.state.protocolResponses.map(rec => {
                if (rec.protocolResponseId === protocolResponseId && rec.responseType === responseTypes.REASSESS && rec.studentRecordId === studentRecordId && rec.protocolId === protocolId) {
                    let subQuestionResponses = MonitoringComplianceUtilities.updateSubQuestionArray([...rec.subQuestionResponses], index, event.target.value);
                    rec.subQuestionResponses = [...subQuestionResponses];
                }

                return rec;
            })
        });
        this.props.handleDataChanged();
    }

    onChangeMatrixResponse(event, protocolId, name, studentRecordId) {
        const districtResponse = MonitoringComplianceUtilities.getMatrixReportedReviewedResponses(
            this.state.protocolResponses.filter(f => f.studentRecordId === studentRecordId),
            protocolId,
            responseTypes.REASSESS);

        districtResponse[name] = event.target.name.startsWith("cb") && !event.target.checked ? "" : event.target.value;

        this.setState({
            protocolResponses: this.state.protocolResponses.map(rec => {
                if (rec.protocolId === protocolId && rec.studentRecordId === studentRecordId && rec.responseType === responseTypes.REASSESS)
                    rec.response = `${districtResponse.reportedDistrict},${districtResponse.reviewedDistrict}`;

                return rec;
            })
        });
        this.props.handleDataChanged();
    }

    createNewProtocolResponses(protocolResponses) {
        const protocolIds = [...new Set(protocolResponses.map(p => p.protocolId))];
        protocolIds.map(protocolId => {
            protocolResponses.filter(r => r.protocolId === protocolId && r.responseType === responseTypes.ASSESS).map(districtAssessProtocol => {
                const isAssessmentValidated = protocolResponses.filter(r => r.protocolId === protocolId && r.responseType === responseTypes.VALIDATION && r.studentRecordId === districtAssessProtocol.studentRecordId).length > 0;
                if( !isAssessmentValidated) {
                    let reassessedResponse = protocolResponses.find(r => r.protocolId === protocolId && r.responseType === responseTypes.REASSESS && r.studentRecordId === districtAssessProtocol.studentRecordId);
                    if (!reassessedResponse) {
                        let protocolResponseId = emptyGuid;
                        let newResponse = MonitoringComplianceUtilities.newReassessProtocolResponse(protocolResponseId, districtAssessProtocol);
                        protocolResponses.push(newResponse);
                    }
                }
            });
        });
    }

    setDefaultResponse(protocolResponses) {
        const totalStateReviewed = protocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length;
        const totalDistrictAssessed = protocolResponses.filter(f => f.responseType === responseTypes.ASSESS).length;
        const totalToBeReassessed = totalDistrictAssessed - totalStateReviewed;
        if(protocolResponses.filter(f => f.responseType === responseTypes.REASSESS).length !== totalToBeReassessed) {
            this.createNewProtocolResponses(protocolResponses);
        }

        return protocolResponses;
    }

    onSave(event) {
        event.preventDefault();

        this.save(this.props.currentStep);
    }

    save(newStep) {
        const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);

        if(canEdit) {
            this.props.actions.saveReassessment(this.props.selectedAssessment.monitoringAssessmentId,
                MonitoringComplianceUtilities.prepareProtocolResponsesForSave(this.state.protocolResponses, responseTypes.REASSESS))
                .then(() => {
                    this.props.handleDataChanged(false);
                    this.props.loadComplianceAssessment(() => {
                        this.redirect(newStep);
                    });
                });
        }
        else {

            this.redirect(newStep);
        }
    }

    redirect(newStep) {
        if(newStep > 0) {
            this.props.updateCurrentStep(newStep);
            scroll.scrollToTop();
        }
        else
            this.return();
    }

    return() {
        this.props.history.push(ComplianceLocations.COMPLIANCE_REASSESS_DISTRICT_REVIEW.path
            .replace(ComplianceLocations.ASSESSMENT_ID, this.props.selectedAssessment.monitoringAssessmentId));
    }

    next(event) {
        event.preventDefault();

        const totalSteps = this.calculateTotalPages();

        if (this.props.currentStep === totalSteps)
            this.save(0);
        else
            this.save(this.props.currentStep + 1);
    }

    back(event) {
        event.preventDefault();

        if (this.props.currentStep === 1)
            this.save(0);
        else
            this.save(this.props.currentStep - 1);
    }

    getBackButtonText(curStep) {
        return (curStep === 1) ? "Summary Page" : "Back";
    }

    getNextButtonText(curStep, totalSteps) {
        return (curStep === totalSteps) ? "Summary Page" : "Next";
    }

    calculateTotalPages() {
        let protocolsToBeReassessed = this.props.selectedAssessment.protocolsToBeReassessed.filter(f => !f.isMatrix);
        let totalPages = protocolsToBeReassessed.length;
        if(this.props.selectedAssessment.protocolsToBeReassessed.filter(f => f.isMatrix).length > 0)
            totalPages++;

        return totalPages;
    }

    loadProtocol() {
        let protocolsToBeReassessed = this.props.selectedAssessment.protocolsToBeReassessed.filter(f => !f.isMatrix);
        let currentProtocol = this.props.currentStep - 1;
        if(currentProtocol < protocolsToBeReassessed.length)
            return [protocolsToBeReassessed[currentProtocol]];

        return this.props.selectedAssessment.protocolsToBeReassessed.filter(f => f.isMatrix);
    }

    render() {
        if(!this.props.selectedAssessment || !this.props.selectedAssessment.protocolResponsesToBeReassessed || this.props.currentStep === 0) return null;

        const protocolsToBeReassessed = this.loadProtocol();
        const protocolToBeReassessed = protocolsToBeReassessed[0];
        const protocolResponses = this.state.protocolResponses.filter(f => f.protocolId === protocolToBeReassessed.protocolId);
        const totalSteps = this.calculateTotalPages();
        const currentStep = this.props.currentStep;
        const canEdit = AuthorizationUtilities.allow(MonitoringCompliancePolicy, monitoring_compliance.studentRecord.MODIFY);

        return (
            <section className={"compliance__reassessStandards"}>
                {
                    !protocolToBeReassessed.isMatrix &&
                    <ReassessGrid
                        isLastPage={totalSteps === currentStep}
                        protocolToBeReassessed={protocolToBeReassessed}
                        protocolResponses={protocolResponses}
                        onResponseChange={this.onChangeProtocolResponse}
                        onSubQuestionResponseChange={this.onChangeSubQuestionResponse}
                    />
                }
                {
                    protocolToBeReassessed.isMatrix &&
                    <>
                        {
                            protocolResponses.filter(f => f.responseType === responseTypes.ASSESS).map(studentRecord => {
                                const currentStudentProtocolResponses = this.state.protocolResponses.filter(f => f.studentRecordId === studentRecord.studentRecordId);
                                const isEditingMatrix = currentStudentProtocolResponses.filter(f => f.responseType === responseTypes.VALIDATION).length === 0;
                                return (
                                    <div key={studentRecord.studentRecordId} className={"matrix-container"}>
                                        <p>The record ID is <strong>{studentRecord.studentRecordName}</strong>.</p>
                                        <MatrixOfService
                                            isEditing={isEditingMatrix && canEdit}
                                            indicatorProtocols={protocolsToBeReassessed}
                                            onResponseChange={this.onChangeMatrixResponse}
                                            protocolResponses={currentStudentProtocolResponses}
                                            showState={!isEditingMatrix}
                                            studentRecordId={studentRecord.studentRecordId}
                                            isReassess={true}
                                            assessmentApproved={false}
                                        />
                                    </div>
                                );
                            })
                        }
                    </>
                }
                <BackNextPager
                    next={this.next}
                    back={totalSteps === 1 ? null : this.back}
                    backText={this.getBackButtonText(currentStep)}
                    nextText={this.getNextButtonText(currentStep, totalSteps)}
                    currentPage={currentStep}
                    totalPages={totalSteps}
                    isBottom
                    wrapperClass={`section-spacing-top`}
                />
            </section>
        );
    }
}

ReassessDistrictPage.propTypes = {
    actions: PropTypes.object.isRequired,
    currentStep: PropTypes.number.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    loadComplianceAssessment: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    selectedAssessment: PropTypes.object,
    updateCurrentStep: PropTypes.func.isRequired
};

export default ReassessDistrictPage;