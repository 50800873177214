import React from 'react';
import PropTypes from 'prop-types';

const SppElementSet = ({children}) => {
    return (
            <form>
                <div className="elementSet">
                    {children}
                </div>
            </form>
    );
};

SppElementSet.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
    ]).isRequired
};

export default SppElementSet;