import {createListItem, dynamicSort, isArray, isArrayNullOrEmpty} from "../../../../components/common/commonUtilities";

export const createRegionsList = (regions) => {
    let list = [];

    if(isArray(regions)) {
        regions.map((region) => {
            list.push({
                ...createListItem(region.regionId, region.regionName),
                districtIds: region.districtIds
            })
        });
    }

    list = list.sort(dynamicSort("text"));

    return list;
};

export const createDistrictsList = (districts = [], showInactiveLabel = false) => {
    let list = [];

    if (isArray(districts))
        districts.map((district) => {
            let districtName = `${district.text}${district.isDisabled && showInactiveLabel ? " (Inactive)" : ""}`;
            list.push({
                ...createListItem(district.id, districtName),
                isDisabled: district.isDisabled
            });
        });

    list = list.sort(dynamicSort("text"));

    return list;
};

export const createRegionUpdateObject = (selectedDistrictIds, availableDistricts) => ({
    selectedDistrictIds: selectedDistrictIds ? selectedDistrictIds : [],
    selectedDistrictId: !isArrayNullOrEmpty(selectedDistrictIds) ? selectedDistrictIds[0] : "",
    currentAvailableDistricts: availableDistricts
});

export const createRegionDetailsObject = (regionName) => ({
    regionName
});