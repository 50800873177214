import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {allow} from "../../../../../components/authorization/AuthorizationUtilities";
import {BpiePolicy, policyEvents} from "../../../../../components/authorization/policies/BpiePolicy";
import {isNullOrUndefined} from "../../../../../components/common/commonUtilities";
import SectionHeader from "../../../../../components/common/SectionHeader";
import {emptyGuid} from "../../../../../constants/config";
import {createDistrictContactsList} from "../../../bpieUtilities";
import bpieDistrictApi from "../../bpieDistrictApi";
import {createDpieProgressMonitoring} from "../../bpieDistrictObjectFactory";
import {DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../../../bpieConstants";
import {getDistrictPiePageSubTitle, isPageDependenciesLoaded} from "../../bpieDistrictPieUtilities";
import bpieDistrictPieProgressMonitoringsApi from "../bpieDistrictPieProgressMonitoringsApi";
import {BpieDistrictPieProgressMonitoringDetailsForm} from "./BpieDistrictPieProgressMonitoringDetailsForm";

export const BpieDistrictPieProgressMonitoringDetailsContainer = ({
                                                                      actions,
                                                                      dateWindowId,
                                                                      districtBpie,
                                                                      districtId,
                                                                      districtPie,
                                                                      districtPieId,
                                                                      dpieProgressMonitoringId,
                                                                      handleDataChanged,
                                                                      handleDistrictBpieSearchRedirect,
                                                                      handleDistrictPieSectionsRedirect,
                                                                      loadDistrictPie,
                                                                      updatePageTitleDetails
                                                                  }) => {
    const [districtContacts, setDistrictContacts] = useState(null);
    const isAdd = dpieProgressMonitoringId === emptyGuid;
    const isEditable = allow(BpiePolicy, policyEvents.districtPieAssessments.MODIFY);
    const addEditPrefixForSubTitle = !isEditable ? "" : isAdd ? "Add " : "Edit ";
    const pageSubTitle = `${addEditPrefixForSubTitle}District Progress Monitoring Details`;

    const handleSaveDistrictPieProgressMonitoringDetails = (dpieProgressMonitoringDetails) => {
        actions.executeApi(bpieDistrictPieProgressMonitoringsApi.saveDistrictPieProgressMonitoring, [dateWindowId, districtId, districtPieId, dpieProgressMonitoringId, dpieProgressMonitoringDetails])
            .then(() => {
                loadDistrictPie();

                redirectToProgressMonitoringSummary();
            });
    };

    const handleCancelDpieProgressMonitoringDetails = () => {
        redirectToProgressMonitoringSummary();
    };

    const redirectToProgressMonitoringSummary = () => {
        handleDataChanged(false);
        handleDistrictPieSectionsRedirect(districtPieId, DistrictPieTask.ProgressMonitoring.id);
    };

    useEffect(() => {
        if (isNullOrUndefined(districtBpie)) return;

        if (districtBpie.districtBpieStatus < DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id) {
            handleDistrictBpieSearchRedirect();
            return;
        }

        loadDistrictPie();
    }, [districtBpie]);

    useEffect(() => {
        if (isNullOrUndefined(districtPie)) return;

        updatePageTitleDetails(getDistrictPiePageSubTitle(pageSubTitle, districtPie));

        const progressMonitoringStatus = districtPie.districtPieTaskStatuses.find(taskStatus => taskStatus.dpieTask === DistrictPieTask.ProgressMonitoring.id);
        if (progressMonitoringStatus?.isDisabled ?? true) {
            handleDistrictBpieSearchRedirect();
        }
    }, [districtPie]);

    useEffect(() => {
        if (districtContacts)
            return;

        actions.executeApi(bpieDistrictApi.getDistrictContacts, [districtId])
            .then(contacts => setDistrictContacts(createDistrictContactsList(contacts)));
    }, []);

    if(!isPageDependenciesLoaded([districtBpie], districtPie, districtPieId)) return null;

    const dpieProgressMonitoring = districtPie.dpieProgressMonitorings.find(pm => pm.dpieProgressMonitoringId === dpieProgressMonitoringId) ?? createDpieProgressMonitoring();
    const fullPageSubTitle = getDistrictPiePageSubTitle(pageSubTitle, districtPie);
    return <>
        <SectionHeader>
            <h2>{fullPageSubTitle}</h2>
        </SectionHeader>

        <BpieDistrictPieProgressMonitoringDetailsForm
            districtContacts={districtContacts}
            dpieProgressMonitoring={dpieProgressMonitoring}
            handleCancelDpieProgressMonitoringDetails={handleCancelDpieProgressMonitoringDetails}
            handleDataChanged={handleDataChanged}
            handleSaveDistrictPieProgressMonitoringDetails={handleSaveDistrictPieProgressMonitoringDetails}
            isEditable={isEditable}
        />
    </>;
}

BpieDistrictPieProgressMonitoringDetailsContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    dateWindowId: PropTypes.string.isRequired,
    districtBpie: PropTypes.object.isRequired,
    districtId: PropTypes.string.isRequired,
    districtPie: PropTypes.object,
    districtPieId: PropTypes.string.isRequired,
    dpieProgressMonitoringId: PropTypes.string.isRequired,
    handleDataChanged: PropTypes.func.isRequired,
    handleDistrictBpieSearchRedirect: PropTypes.func.isRequired,
    handleDistrictPieSectionsRedirect: PropTypes.func.isRequired,
    loadDistrictPie: PropTypes.func.isRequired,
    updatePageTitleDetails: PropTypes.func.isRequired
};