import React from "react";
import PropTypes from "prop-types";
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import SelectField from "../common/inputs/SelectField";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";

const AssessmentIndicatorForm = ({
                                     indicatorList,
                                     value,
                                     onChangeIndicator,
                                     onSaveIndicator,
                                     onCancel,
                                     isLoading
                                 }) => {
    return (
        <section className="performance__indicator performance__indicator--editing"
                 id={"performance__indicator--editing"}>
            <GridRow>
                <GridColumn>
                    <SelectField name={"indicatorId"}
                                 label={"Indicator"}
                                 showLabel
                                 options={indicatorList}
                                 onChange={onChangeIndicator}
                                 disabled={isLoading}
                                 value={value.indicatorId}/>
                </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn>
                    <TextAreaEditor
                        name={"explanationForAssessment"}
                        label={"Area of Focus"}
                        showLabel={true}
                        value={!value.explanationForAssessment ? "" : value.explanationForAssessment}
                        onChange={onChangeIndicator}
                    />
                </GridColumn>
            </GridRow>
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name={"save"}
                        label={"Save"}
                        onClick={onSaveIndicator}
                        buttonType={ButtonTypes.SAVE}
                        diabled={isLoading}/>
                <Button name={"cancel"}
                        label={"Cancel"}
                        onClick={onCancel}
                        buttonType={ButtonTypes.CANCEL}
                        diabled={isLoading}/>
            </ButtonBar>
        </section>
    );
};

AssessmentIndicatorForm.propTypes = {
    indicatorList: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.object,
    onChangeIndicator: PropTypes.func,
    onSaveIndicator: PropTypes.func,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool
};

export default AssessmentIndicatorForm;
