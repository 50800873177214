import React from "react";
import PropTypes from "prop-types";
import Button from "../../components/common/buttons/Button";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as surveyStatuses from "../../constants/surveys/surveyStatuses";
import * as surveyRespondentProgresses from "../../constants/surveys/surveyRespondentProgesses";
import ButtonBar from "../../components/common/buttons/ButtonBar";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";

const SurveyRespondentRow = ({
	                             surveyRespondentId,
	                             email,
	                             institution,
	                             progress,
	                             surveyStatus,
	                             onDelete,
	                             onUnsubmit
                             }) => {
	return (
		<tr>
			<td>
				{email}
			</td>
			<td>
				{institution}
			</td>
			<td>
				{progress}
			</td>

			{
				surveyStatus !== surveyStatuses.Closed &&
				<td className="button-cell">
					<ButtonBar position={ButtonBarPositions.TABLE}>
						<Button name={"btnDelete|" + surveyRespondentId}
						            label={"Delete Respondent"}
						            onClick={onDelete}
						            showLabel={false}
						            buttonType={ButtonTypes.DELETE}/>
						{
							progress === surveyRespondentProgresses.Submitted &&
							<Button name={"btnUnsubmit|" + surveyRespondentId}
							            label={"Unsubmit Respondent"}
							            showLabel={false}
							            onClick={onUnsubmit}
							            buttonType={ButtonTypes.HISTORY}/>
						}
					</ButtonBar>
				</td>
			}
		</tr>
	);
};

SurveyRespondentRow.propTypes = {
	surveyRespondentId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	institution: PropTypes.string,
	progress: PropTypes.string.isRequired,
	surveyStatus: PropTypes.string.isRequired,
	onDelete: PropTypes.func,
	onUnsubmit: PropTypes.func

};

export default SurveyRespondentRow;
