export const INSTANCE_ID = ":surveyInstanceId";
export const RESPONDENT_ID = ":respondentId";
export const PARENT_ELEMENT_ID = ":parentElementId";
export const DATE_WINDOW_ID = ":dateWindowId";
export const ROOT = `/Surveys`;
//GENERAL
export const LANDING = {
    path: `${ROOT}`
};
export const TAKE = {
    path: `${ROOT}/TakeSurvey/${INSTANCE_ID}/Respondent/${RESPONDENT_ID}`,
    params: {
        surveyInstanceId: INSTANCE_ID,
        respondentId: RESPONDENT_ID
    }
};
export const CONGRATULATIONS = {
    path: `${ROOT}/Congratulations`,
    params: {}
};

//MANAGE
export const MANAGE = {
    path: `${ROOT}/Manage/${INSTANCE_ID}`,
    params: {
        surveyInstanceId: INSTANCE_ID
    }
};

export const MANAGE_GROUP_DETAILS = {
    path: `${ROOT}/Manage/${DATE_WINDOW_ID}/GroupDetails`,
    params: {
        dateWindowId: DATE_WINDOW_ID
    }
};

export const MANAGE_DETAILS = {
    path: `${MANAGE.path}/Details`,
    params: {
        surveyInstanceId: INSTANCE_ID
    }
};

export const MANAGE_ELEMENTS = {
    path: `${MANAGE.path}/Elements`,
    params: {
        surveyInstanceId: INSTANCE_ID
    }
};

export const ORDER_ELEMENTS = {
    path: `${MANAGE.path}/Order/${PARENT_ELEMENT_ID}`,
    params: {
        surveyInstanceId: INSTANCE_ID,
        parentElementId: PARENT_ELEMENT_ID
    }
};

export const MANAGE_RESPONDENTS = {
    path: `${MANAGE.path}/Respondents`,
    params: {
        surveyInstanceId: INSTANCE_ID
    }
};

export const MANAGE_REPORTS = {
    path: `${MANAGE.path}/Reports`,
    params: {
        surveyInstanceId: INSTANCE_ID
    }
};
