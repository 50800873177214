import React from 'react';
import PropTypes from 'prop-types';
import GridRow from "../common/GridRow";
import GridColumn from "../common/GridColumn";
import ActionBar from "../common/buttons/ActionBar";
import * as MonitoringPerformanceTypes
	from "../../constants/monitoringPerformance/monitoringPerformanceAssessmentTypes";
import {
	MonitoringPerformancePolicy,
	policyEvents
} from "../../components/authorization/policies/MonitoringPerformancePolicy";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";

const MonitoringPerformanceAssessment = ({
	                                         assessment,
	                                         isEditing,
	                                         isLoading,
	                                         onEditAssessment,
                                         }) => {
	const monitoringTypeText = MonitoringPerformanceTypes.Text(assessment.monitoringType);

	return (
		<section className="performance__monitoringType" id={"performance__monitoringType"}>
			<header>
				<h2>Assessment Details</h2>
			</header>
			<GridRow>
				{
					!isEditing &&
					<ActionBar
						elementId={"type"}
						onEditClick={onEditAssessment}
						policy={MonitoringPerformancePolicy}
						editPolicyEvent={policyEvents.monitoring_performance.assessment.MODIFY}
						disabled={isLoading}
					/>
				}
				<GridColumn small={"12"} large={"12"}>
					<p><strong>Type:</strong> {monitoringTypeText}</p>
					<p><strong>Date(s):</strong> {outputStandardDateFormat(assessment.visitStartDate, false)}
						{(assessment.visitStartDate !== assessment.visitEndDate) ?
							` - ${outputStandardDateFormat(assessment.visitEndDate, false)}` : ""}</p>
				</GridColumn>
			</GridRow>
		</section>
	);
};

MonitoringPerformanceAssessment.propTypes = {
	assessment: PropTypes.object,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onEditAssessment: PropTypes.func.isRequired,
};

export default MonitoringPerformanceAssessment;