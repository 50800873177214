import AjaxRequestOptions from "../../../../api/requests/AjaxRequestOptions";
import {RequestTypes} from "../../../../api/requests/RequestTypes";
import * as config from "../../../../constants/config";
import * as ApiUtility from "../../../../api/ApiUtility";
import {mockResolveApiCall} from "../../../../api/ApiUtility";
import {BpieApiMessages} from "../../bpieConstants";
import {API_ROOT} from "../../bpieLocations";

class ServerRegionAdminApi {
    static getRegions(){
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/regions`,
                RequestTypes.GET));
    }

    static SaveDistrictsForRegion(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/regions/${model.regionId}/districts`,
                RequestTypes.PUT,
                JSON.stringify(model),
                BpieApiMessages.regions.DISTRICT_SAVED));
    }

    static SaveRegion(model) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/regions/${model.regionId}`,
                RequestTypes.PUT,
                JSON.stringify(model),
                BpieApiMessages.regions.SAVED));
    }

    static DeleteRegion(regionId) {
        return ApiUtility.sendRequest(
            AjaxRequestOptions(
                `${config.apiUrl}${API_ROOT}/admin/regions/${regionId}`,
                RequestTypes.DELETE,
                null,
                BpieApiMessages.regions.DELETED));
    }
}


class MockRegionAdminApi {
    static getRegions() {
        return mockResolveApiCall({});
    }

    static SaveDistrictsForRegion(){
        return ApiUtility.mockResolveApiCall({}, BpieApiMessages.regions.DISTRICT_SAVED);
    }

    static SaveRegion(){
        return ApiUtility.mockResolveApiCall({}, BpieApiMessages.regions.SAVED);
    }

    static DeleteRegion(){
        return ApiUtility.mockResolveApiCall({}, BpieApiMessages.regions.DELETED);
    }
}

const RegionAdminApi = (config.environment.API_MODE === config.SERVER_API_MODE) ? ServerRegionAdminApi: MockRegionAdminApi;
export default RegionAdminApi;