import React from "react";
import {GMS} from "../../constants/contexts";
import {FilterView, FilterViewPropTypes} from "../../components/common/searchCriterias/FilterView";

export const GmsFilter = ({
                               dateWindows,
                               handleExport,
                               handleSearchFiltersChange,
                               numberOfFilterColumns = 0,
                               searchCriteria
                           }) => {
    return <FilterView
        context={GMS}
        dateWindows={dateWindows}
        handleExport={handleExport}
        handleSearchFiltersChange={handleSearchFiltersChange}
        numberOfFilterColumns={numberOfFilterColumns}
        searchCriteria={searchCriteria}
    />;
};

GmsFilter.propTypes = FilterViewPropTypes;