import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import AccountUtility from "../../../api/AccountUtility";
import {
    isNullOrUndefined,
    isTrimmedStringEmpty,
    setPageTitle
} from "../../../components/common/commonUtilities";
import Button from "../../../components/common/buttons/Button";
import GridColumn from "../../../components/common/GridColumn";
import GridRow from "../../../components/common/GridRow";
import * as LayoutActions from "../../../actions/layoutActions";
import * as apiForLocalStateActions from "../../../actions/apiForLocalStateActions";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getParamsMultiple} from "../../../components/layout/getParams";
import {emptyGuid} from "../../../constants/config";
import {DistrictBpieAssessmentSections, DBPIE_ASSESSMENT_STATUS, DistrictPieTask} from "../bpieConstants";
import * as bpieLocations from "../bpieLocations";
import {DASHBOARD} from "../../../constants/systemLocations";
import {CanEditDistrictBpie, CanSubmitDistrictBpie} from "../bpieUtilities";
import bpieDistrictApi from "./bpieDistrictApi";
import {createDbpie, createDistrictPie} from "./bpieDistrictObjectFactory";

const BpieDistrictWrapper = ({
                                 actions,
                                 Component,
                                 currentLocation,
                                 dateWindowId,
                                 districtId,
                                 districtBpieId,
                                 districtPieId,
                                 dpieGoalId,
                                 dpieProgressMonitoringId,
                                 finFacilitatorDistricts,
                                 finFacilitatorDistrictsLoaded,
                                 history,
                                 indicatorNumber,
                                 props
                             }) => {
    const [dataChanged, setDataChanged] = useState(false);
    const [districtBpie, setDistrictBpie] = useState(null);
    const [districtPie, setDistrictPie] = useState(null);

    const isDistrictUser = !AccountUtility.isBpieStateUser();

    const districtInfoUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_INFO.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_INFO.getUrl(dateWindowId, districtId, districtBpieId);

    const districtPieIntroUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DPIE_INTRO.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DPIE_INTRO.getUrl(dateWindowId, districtId, districtBpieId);

    const districtPieSummaryUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId);

    const assessmentUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, indicatorNumber).replace(bpieLocations.INDICATOR_NUMBER, 1)
        : bpieLocations.STATE_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, indicatorNumber).replace(bpieLocations.INDICATOR_NUMBER, 1);

    const summaryUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_SUMMARY.getUrl(dateWindowId, districtId, districtBpieId);

    const prioritiesUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_PRIORITIES.getUrl(dateWindowId, districtId, districtBpieId)
        : bpieLocations.STATE_DBPIE_PRIORITIES.getUrl(dateWindowId, districtId, districtBpieId);

    const returnDistrictBpieSearchUrl = isDistrictUser
        ? bpieLocations.DISTRICT_DBPIE_SEARCH.getUrl()
        : bpieLocations.STATE_DBPIE_SEARCH.getUrl();

    const getScrollConfig = () => {
        const scrollConfig = {
            duration: 300,
            delay: 0,
            smooth: true
        };

        return scrollConfig;
    };

    const handleScroll = (containerId) => {
        const scroller = Scroll.scroller;
        window.setTimeout(() => {
            scroller.scrollTo(containerId, getScrollConfig());
        }, 150);
    };

    const handleScrollToTop = () => {
        const animateScroll = Scroll.animateScroll;
        window.setTimeout(() => {
            animateScroll.scrollToTop(getScrollConfig());
        }, 150);
    }

    const handleSelectSection = (event, section, forceReplace = false) => {
        let url = "";
        switch (section) {
            case DistrictBpieAssessmentSections.DistrictInformation:
                url = districtInfoUrl;
                break;
            case DistrictBpieAssessmentSections.DistrictPieIntroduction:
                url = districtPieIntroUrl;
                break;
            case DistrictBpieAssessmentSections.DistrictPieSummary:
                url = districtPieSummaryUrl;
                break;
            case DistrictBpieAssessmentSections.Assessment:
                url = summaryUrl;
                break;
        }

        if (url)
            navButtonClick(event, url, forceReplace);
    };

    const getSectionFromCurrentLocation = () => {
        switch (currentLocation) {
            case districtInfoUrl:
                return DistrictBpieAssessmentSections.DistrictInformation;
            case districtPieIntroUrl:
                return DistrictBpieAssessmentSections.DistrictPieIntroduction;
            case assessmentUrl:
            case summaryUrl:
            case prioritiesUrl:
                return DistrictBpieAssessmentSections.Assessment;
        }

        return isUrlInDistrictSummarySection() ? DistrictBpieAssessmentSections.DistrictPieSummary : "";
    }

    const isUrlInDistrictSummarySection = () => {
        const districtPieDetailsUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId)
            : bpieLocations.STATE_DPIE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId);

        const districtPieGoalsSummaryUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_GOALS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId)
            : bpieLocations.STATE_DPIE_GOALS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId);

        const districtPieGoalsDetailsUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_GOAL_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieGoalId)
            : bpieLocations.STATE_DPIE_GOAL_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieGoalId);

        const districtPieProgressMonitoringsSummaryUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING.getUrl(dateWindowId, districtId, districtBpieId, districtPieId)
            : bpieLocations.STATE_DPIE_PROGRESS_MONITORING.getUrl(dateWindowId, districtId, districtBpieId, districtPieId);

        const districtPieProgressMonitoringDetailsUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId)
            : bpieLocations.STATE_DPIE_PROGRESS_MONITORING_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId);

        const districtPieActionStepUpdatesSummaryUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_ACTION_STEP_UPDATE.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId)
            : bpieLocations.STATE_DPIE_ACTION_STEP_UPDATE.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieProgressMonitoringId);

        const districtPieActionStepUpdateDetailsUrl = isDistrictUser
            ? bpieLocations.DISTRICT_DPIE_ACTION_STEP_UPDATE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieGoalId, dpieProgressMonitoringId)
            : bpieLocations.STATE_DPIE_ACTION_STEP_UPDATE_DETAILS.getUrl(dateWindowId, districtId, districtBpieId, districtPieId, dpieGoalId, dpieProgressMonitoringId);

        const pagesInDistrictSummarySection = [
            districtPieDetailsUrl,
            districtPieSummaryUrl,
            districtPieGoalsSummaryUrl,
            districtPieGoalsDetailsUrl,
            districtPieProgressMonitoringsSummaryUrl,
            districtPieProgressMonitoringDetailsUrl,
            districtPieActionStepUpdatesSummaryUrl,
            districtPieActionStepUpdateDetailsUrl
        ];

        return pagesInDistrictSummarySection.includes(currentLocation);
    }

    const navButtonClick = (event, location, forceReplace = false, reloadDistrictBpie = false) => {
        event.preventDefault();

        if (forceReplace) {
            normalizeUrlAndRedirect(location, true);
            setDataChanged(false);
            return;
        }

        if (location === currentLocation) return;

        if (dataChanged) {
            if (!confirm("You have made changes that have not been saved. Do you want to continue?\n\nPress \"OK\" to continue or \"Cancel\" to return to the page."))
                return;
        }

        setDataChanged(false);
        normalizeUrlAndRedirect(location);

        if (reloadDistrictBpie)
            loadDistrictBpie();

        handleScrollToTop();
    };

    const handleDataChanged = (dataUpdated = true) => {
        setDataChanged(dataUpdated);
    };

    const normalizeUrlAndRedirect = (location, isReplace = false, dbpieId = null, dpieId = null, goalId = null, progressMonitoringId = null) => {
        const path = location
            .replace(bpieLocations.BPIE_DATE_WINDOW_ID, dateWindowId)
            .replace(bpieLocations.BPIE_DISTRICT_ID, districtId)
            .replace(bpieLocations.DISTRICT_BPIE_ID, dbpieId || districtBpieId)
            .replace(bpieLocations.DISTRICT_PIE_ID, dpieId || districtPieId)
            .replace(bpieLocations.DISTRICT_PIE_GOAL_ID, goalId || dpieGoalId)
            .replace(bpieLocations.DISTRICT_PIE_PROGRESS_MONITORING_ID, progressMonitoringId || dpieProgressMonitoringId)
            .replace(bpieLocations.INDICATOR_NUMBER, 1);
        isReplace ? history.replace(path) : history.push(path);
    };

    const handleUpdateDistrictBpie = (result) => {
        const canEdit = CanEditDistrictBpie(result, finFacilitatorDistricts);
        const canSubmit = CanSubmitDistrictBpie(result, finFacilitatorDistricts);
        setDistrictBpie(createDbpie(result, canEdit, isDistrictUser, canSubmit));
        if (dataChanged)
            setDataChanged(false);
    };

    const handleUpdateDistrictPie = (result) => {
        setDistrictPie(createDistrictPie(result));
    };

    const handleAssessmentRedirect = (desiredIndicator) => {
        setDistrictBpie(null);
        loadDistrictBpie();

        if (!desiredIndicator)
            handleSummaryRedirect();
        else {
            const newAssessmentUrl = isDistrictUser
                ? bpieLocations.DISTRICT_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, desiredIndicator)
                : bpieLocations.STATE_DBPIE_ASSESSMENT.getUrl(dateWindowId, districtId, districtBpieId, desiredIndicator)
            normalizeUrlAndRedirect(newAssessmentUrl);
            handleScrollToTop();
        }
    };

    const handleSummaryRedirect = () => {
        normalizeUrlAndRedirect(summaryUrl);
    }

    const handlePrioritiesRedirect = () => {
        normalizeUrlAndRedirect(prioritiesUrl);
    }

    const handleDistrictPieSummaryRedirect = () => {
        loadDistrictBpie();
        normalizeUrlAndRedirect(districtPieSummaryUrl);
    }

    const handleDistrictBpieSearchRedirect = () => {
        normalizeUrlAndRedirect(returnDistrictBpieSearchUrl);
    }

    const handleDistrictPieSectionsRedirect = (districtPieId, sectionId) => {
        let selectedSection = null;

        switch (sectionId) {
            case DistrictPieTask.TeamMemberIndicatorInformation.id:
                selectedSection = isDistrictUser ? bpieLocations.DISTRICT_DPIE_DETAILS : bpieLocations.STATE_DPIE_DETAILS;
                break;
            case DistrictPieTask.Goals.id:
                selectedSection = isDistrictUser ? bpieLocations.DISTRICT_DPIE_GOALS : bpieLocations.STATE_DPIE_GOALS;
                break;
            case DistrictPieTask.ProgressMonitoring.id:
                selectedSection = isDistrictUser ? bpieLocations.DISTRICT_DPIE_PROGRESS_MONITORING : bpieLocations.STATE_DPIE_PROGRESS_MONITORING;
                break;
        }

        if (!isNullOrUndefined(selectedSection))
            normalizeUrlAndRedirect(selectedSection.getUrl(dateWindowId, districtId, districtBpieId, districtPieId));
    };

    const loadDistrictBpie = () => {
        return actions.executeApi(bpieDistrictApi.getDistrictBpie, [dateWindowId, districtId, districtBpieId])
            .then((result) => {
                handleUpdateDistrictBpie(result);
                if (districtBpieId === emptyGuid && result.districtBpieId && result.districtBpieId !== emptyGuid)
                    normalizeUrlAndRedirect(bpieLocations.STATE_DBPIE_INFO.path, true, result.districtBpieId);
            });
    }

    const loadDistrictPie = () => {
        return actions.executeApi(bpieDistrictApi.getDistrictPie, [dateWindowId, districtId, districtPieId])
            .then(handleUpdateDistrictPie);
    }

    const updatePageTitleDetails = (subTitle = "") => {
        const headerLeft = `DBPIE Assessment`;
        const headerCenter = getSectionFromCurrentLocation();
        const headerRight = districtBpie.districtName;

        actions.updatePageTitle(headerLeft, headerCenter, headerRight);
        const pageTitle = !isTrimmedStringEmpty(subTitle) ? subTitle : headerCenter;
        setPageTitle(`${headerLeft}${!isTrimmedStringEmpty(pageTitle) ? ` - ${pageTitle}` : ``}; ${districtBpie.districtName}`);
    }

    useEffect(() => {
        if (AccountUtility.isBpieSchoolAdmin()) {
            history.push(DASHBOARD.path);
            return;
        }

        if (!finFacilitatorDistrictsLoaded)
            return;

        const userDetails = AccountUtility.getUserDetails();
        if (isDistrictUser && districtId !== userDetails.InstitutionId) {
            history.push(bpieLocations.DISTRICT_BPIE_SUMMARY.getUrl());
            return;
        }

        loadDistrictBpie();
    }, [finFacilitatorDistrictsLoaded]);

    useEffect(() => {
        if (!districtBpie || isUrlInDistrictSummarySection())
            return;

        updatePageTitleDetails();
    }, [districtBpie, currentLocation]);

    if (!districtBpie)
        return null;

    return (
        <GridRow
            isPageNav
        >
            <GridColumn large="3">
                <GridRow>
                    <GridColumn
                        isPageNavLinks
                        isStickyLinks
                    >
                        <Button
                            btnClass={currentLocation === districtInfoUrl ? "is-active" : ""}
                            onClick={(event) => navButtonClick(event, districtInfoUrl)}
                            label={DistrictBpieAssessmentSections.DistrictInformation}
                            name={districtInfoUrl}
                        />
                        {
                            !!districtBpie.districtBpieId &&
                            <Button
                                btnClass={(currentLocation === assessmentUrl || currentLocation === summaryUrl || currentLocation === prioritiesUrl) ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, districtBpie.responses.length === 0 && districtBpie.isEditable ? assessmentUrl : summaryUrl)}
                                label={DistrictBpieAssessmentSections.Assessment}
                                name={summaryUrl}
                            />
                        }
                        {
                            districtBpie.districtBpieStatus >= DBPIE_ASSESSMENT_STATUS.Finalized.id &&
                            <Button
                                btnClass={currentLocation === districtPieIntroUrl ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, districtPieIntroUrl)}
                                label={DistrictBpieAssessmentSections.DistrictPieIntroduction}
                                name={districtPieIntroUrl}
                            />
                        }
                        {
                            districtBpie.districtBpieStatus >= DBPIE_ASSESSMENT_STATUS.DistrictPieInProgress.id &&
                            <Button
                                btnClass={isUrlInDistrictSummarySection() ? "is-active" : ""}
                                onClick={(event) => navButtonClick(event, districtPieSummaryUrl, false, true)}
                                label={DistrictBpieAssessmentSections.DistrictPieSummary}
                                name={districtPieSummaryUrl}
                            />
                        }
                        <Button
                            onClick={(event) => navButtonClick(event, returnDistrictBpieSearchUrl)}
                            label={"Return to DBPIE Search"}
                            name={returnDistrictBpieSearchUrl}
                        />
                    </GridColumn>
                </GridRow>
            </GridColumn>
            <GridColumn
                isPageNavContent
                large="9"
            >
                <Component
                    {...props}
                    actions={actions}
                    dataChanged={dataChanged}
                    dateWindowId={dateWindowId}
                    districtBpie={districtBpie}
                    districtBpieId={districtBpieId}
                    districtPie={districtPie}
                    districtPieId={districtPieId}
                    districtId={districtId}
                    dpieGoalId={dpieGoalId}
                    dpieProgressMonitoringId={dpieProgressMonitoringId}
                    handleAssessmentRedirect={handleAssessmentRedirect}
                    handleDataChanged={handleDataChanged}
                    handleDistrictBpieSearchRedirect={handleDistrictBpieSearchRedirect}
                    handleDistrictPieSectionsRedirect={handleDistrictPieSectionsRedirect}
                    handleDistrictPieSummaryRedirect={handleDistrictPieSummaryRedirect}
                    handlePrioritiesRedirect={handlePrioritiesRedirect}
                    handleScroll={handleScroll}
                    handleScrollToTop={handleScrollToTop}
                    handleSelectSection={handleSelectSection}
                    handleSummaryRedirect={handleSummaryRedirect}
                    history={history}
                    indicatorNumber={indicatorNumber}
                    isDistrictUser={isDistrictUser}
                    isEditable={districtBpie.isEditable}
                    loadDistrictBpie={loadDistrictBpie}
                    loadDistrictPie={loadDistrictPie}
                    normalizeUrlAndRedirect={normalizeUrlAndRedirect}
                    updatePageTitleDetails={updatePageTitleDetails}
                />
            </GridColumn>
        </GridRow>
    );
};

const mapStateToProps = (state, props) => {
    const {component: Component} = props;
    const currentLocation = props.location.pathname;

    const allRoutes = AccountUtility.isBpieStateUser()
        ? bpieLocations.AllStateBpieRoutes
        : bpieLocations.AllDistrictBpieRoutes;
    const multipleParams = getParamsMultiple(currentLocation, allRoutes);
    const params = !multipleParams ? {} : multipleParams;

    return {
        ...params,
        Component,
        currentLocation,
        finFacilitatorDistricts: state.bpie.finFacilitatorDistricts,
        finFacilitatorDistrictsLoaded: state.bpie.finFacilitatorDistrictsLoaded,
        props,
    };
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign({}, LayoutActions, apiForLocalStateActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

BpieDistrictWrapper.propTypes = {
    actions: PropTypes.object,
    Component: PropTypes.func,
    currentLocation: PropTypes.string,
    dateWindowId: PropTypes.string,
    districtId: PropTypes.string,
    districtBpieId: PropTypes.string,
    districtPieId: PropTypes.string,
    dpieGoalId: PropTypes.string,
    dpieProgressMonitoringId: PropTypes.string,
    finFacilitatorDistricts: PropTypes.array,
    finFacilitatorDistrictsLoaded: PropTypes.bool.isRequired,
    history: PropTypes.object,
    indicatorNumber: PropTypes.string,
    props: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BpieDistrictWrapper);