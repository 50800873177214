import {createListFromObject} from "../../components/common/commonUtilities";

export const RequestedBy =[
	"Student",
	"District",
	"Parent",
	"Parent's Advocate",
	"Other Parent Representative"
];

export const AgreementList = [
    {text:"Yes", value: "True"},
    {text:"No", value: "False"},
    {text:"Partial", value: "Partial"},
];

export const Status = {
	Pending: "Pending",
	Held: "Held",
	Closed: "Closed",
	Withdrawn: "Withdrawn"
};
export const StatusList = createListFromObject(Status);

export const DrFilterMediationStatus = {
	Open: "open",
	Closed: "closed",
	Withdrawn: "withdrawn"
};
export const DrFilterMediationStatusList = createListFromObject(DrFilterMediationStatus);