import React from "react";
import PropTypes from "prop-types";
import {isArrayNullOrEmpty} from "../common/commonUtilities";

const GeneralNotificationsView = ({
                                      notifications
                                  }) => {
    if (isArrayNullOrEmpty(notifications))
        return null;

    return (
        <>
            <h2>General Notifications</h2>
            <p>The following notifications are automatically sent when appropriate.</p>
            <ul>
                {
                    notifications.map((notification, index) => {
                        return (
                            <li key={index}>{notification.description}</li>
                        );
                    })
                }
            </ul>
        </>
    );
};

GeneralNotificationsView.propTypes = {
    notifications: PropTypes.array.isRequired
};

export default GeneralNotificationsView;