import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as apiForLocalStateActions from "../../actions/apiForLocalStateActions";
import * as layoutActions from "../../actions/layoutActions";
import * as sharedDataActions from "../../actions/sharedDataActions";
import AccountUtility from "../../api/AccountUtility";
import UserApi from "../../api/UserApi";
import {allow} from "../../components/authorization/AuthorizationUtilities";
import {isTrimmedStringEmpty} from "../../components/common/commonUtilities";
import {UserSearchCriteria} from "../../components/common/searchCriterias/userSearchCriteria";
import {SiteSectionsForTeamMemberList} from "../../constants/config";
import {DASHBOARD} from "../../constants/systemLocations";
import {UserPolicy} from "../../components/authorization/policies/UserPolicy";
import * as PolicyEvents from "../../constants/policyEvents";
import * as userConstants from "../../constants/users/userConstants";
import * as behaviors from "../../constants/behaviors";
import StateUserDistrictAssignmentView from "./StateUserDistrictAssignmentView";
import {createStateUserList} from "./userObjectFactory";

const StateUserDistrictAssignmentContainer = ({
                                                  actions,
                                                  history
                                              }) => {
    const [districts, setDistricts] = useState(null);
    const [stateUsers, setStateUsers] = useState(null);

    const getDistricts = () => {
        actions.executeApi(UserApi.getInstitutionsWithTeamMembers)
            .then((results) => setDistricts(results));
    };

    const initializeData = () => {
        getDistricts();

        let contexts = "";
        SiteSectionsForTeamMemberList.map((section) => {
            if(!isTrimmedStringEmpty(contexts)) contexts += ",";
            contexts += `${section.context}.${behaviors.EDIT}`;
        });

        let userSearchCriteria = new UserSearchCriteria("", contexts, userConstants.USER_TYPES.State, userConstants.STATUS.ACTIVE);
        userSearchCriteria.RecordsPerPage = 0;
        userSearchCriteria.SortByColumn = "LastName";
        actions.executeApi(UserApi.getUsers, [userSearchCriteria])
            .then((stateUsersResults) => setStateUsers(createStateUserList(stateUsersResults.data)));
    };

    const saveTeamMembers = (updatedTeamMembers) => {
        actions.executeApi(UserApi.saveInstitutionWithTeamMembers, [updatedTeamMembers])
            .then(() => {
                actions.loadInstitutions();
                getDistricts();
            });
    };

    const cancelAndReload = () => {
        if(confirm("Are you sure you want to cancel?\nPress \"Okay\" to continue or \"Cancel\" to return to the page."))
            getDistricts();
    };

    useEffect(() => {
        if (AccountUtility.isDistrictUser() || !allow(UserPolicy, PolicyEvents.MANAGE))
            history.push(DASHBOARD.path);

        actions.updatePageTitle(`Team Member District Assignments`);

        initializeData();
    }, []);

    if (!districts || !stateUsers)
        return null;

    return (
        <StateUserDistrictAssignmentView
            cancelAndReload={cancelAndReload}
            districts={districts}
            saveTeamMembers={saveTeamMembers}
            stateUsers={stateUsers}
        />
    );
};

const mapDispatchToProps = dispatch => {
    const combinedActions = Object.assign(
        {},
        apiForLocalStateActions,
        layoutActions,
        sharedDataActions,
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
};

StateUserDistrictAssignmentContainer.propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default connect(
    null,
    mapDispatchToProps
)(StateUserDistrictAssignmentContainer);