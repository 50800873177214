import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import GridColumn from "../common/GridColumn";
import GridRow from "../common/GridRow";
import GridTable from "../common/GridTable";
import DateSelectField from "../common/inputs/DateSelectField";
import SelectField from "../common/inputs/SelectField";
import TileContainer from "../common/Tiles/TileContainer";
import Tile from "../common/Tiles/Tile";
import {outputStandardDateFormat} from "../shared/sharedDataUtilities";
import * as ButtonTypes from "../../constants/ButtonTypes";
import * as caseNoteObjectFactory from "./caseNoteObjectFactory";
import {createDateSelectFiltersObject} from "../common/inputs/inputObjectFactory";
import {newCaseNote} from "../../constants/caseNotes/genericCaseNoteConstants";
import {isArrayNullOrEmpty, isTrimmedStringEmpty} from "../common/commonUtilities";
import CaseNoteEdit from "./CaseNoteEdit";
import CaseNoteComponent from "./CaseNoteComponent";
import * as scrollUtilities from "../common/scrollUtilities";

const CaseNotesView = ({
                           caseNotes = [],
                           caseNoteType,
                           caseNoteTypeList = [],
                           handleDataChanged,
                           handleDeleteCaseNote,
                           handleDownloadCaseInvestigationReport,
                           handleReturn,
                           handleSaveCaseNote,
                           handleScroll = scrollUtilities.handleScroll,
                           handleScrollToTop = scrollUtilities.handleScrollToTop,
                           isMinimumCaseNote = false,
                           otherAreaCaseNoteTypes,
                           readOnly,
                           readOnlyCaseNoteTypes
                       }) => {
    const [editCaseNoteId, setEditCaseNoteId] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [caseNoteFilters, setCaseNoteFilters] = useState(createDateSelectFiltersObject());
    const [caseNotesList, setCaseNotesList] = useState(caseNotes);

    const noCaseNotes = isArrayNullOrEmpty(caseNotes);

    const handleSave = async (form) => {
        const caseNote = caseNoteObjectFactory.createCaseNoteObject(form);
        const isSuccess = await handleSaveCaseNote(caseNote);
        if (isSuccess) {
            setEditCaseNoteId('');
            if (!caseNote.caseNoteId)
                handleScroll("case-notes-view");
            else
                handleScroll(`case-note-${caseNote.caseNoteId}`);
        }
        checkAndHandleDataChanged(false);
    };

    const handleDelete = async (caseNoteId) => {
        const isSuccess = await handleDeleteCaseNote(caseNoteId);
        if (isSuccess) {
            handleScroll("case-notes-view");
        }
    };

    const handleDownloadCaseInvestigationReportClick = (event) => {
        event.preventDefault();

        if (handleDownloadCaseInvestigationReport)
            handleDownloadCaseInvestigationReport(caseNoteFilters);
    };

    const handleCancel = () => {
        if (!editCaseNoteId || editCaseNoteId === newCaseNote)
            handleScroll("case-notes-view");
        else
            handleScroll(`case-note-${editCaseNoteId}`);

        setEditCaseNoteId('');
        checkAndHandleDataChanged(false);
    };

    const checkAndHandleDataChanged = (dataChanged) => {
        if (handleDataChanged)
            handleDataChanged(dataChanged);
    };

    const handleFilterCaseNotesClick = (event) => {
        event.preventDefault();

        filterCaseNotesList(caseNoteFilters);
    };

    const handleClearCaseNotesClick = (event) => {
        event.preventDefault();

        const newFilters = createDateSelectFiltersObject();
        setCaseNoteFilters(newFilters);

        filterCaseNotesList(newFilters);
    }

    const filterCaseNotesList = (filters) => {
        if (isArrayNullOrEmpty(caseNotes)) return;

        let updatedCaseNotesList = [...caseNotes];

        if (!isTrimmedStringEmpty(filters.startDate))
            updatedCaseNotesList = updatedCaseNotesList.filter(f => new Date(f.dateAdded) >= new Date(filters.startDate));

        if (!isTrimmedStringEmpty(filters.endDate)) {
            const endDate = outputStandardDateFormat(filters.endDate, false);
            updatedCaseNotesList = updatedCaseNotesList.filter(f => new Date(f.dateAdded) <= new Date(endDate));
        }

        if (!isTrimmedStringEmpty(filters.noteType.toString()))
            updatedCaseNotesList = updatedCaseNotesList.filter(f => f.noteType.toString() === filters.noteType.toString());

        setCaseNotesList(updatedCaseNotesList);
    }

    const handleSelectDate = (dateRangeType, startDate, endDate) => {
        let filters = {...caseNoteFilters};
        filters.dateRangeType = dateRangeType;
        filters.startDate = startDate;
        filters.endDate = endDate;
        setCaseNoteFilters(filters);
    };

    const handleFilterUpdateChange = (event) => {
        event.preventDefault();
        let filters = {...caseNoteFilters};
        filters[event.target.name] = event.target.value;
        setCaseNoteFilters(filters);
    }

    const getCaseNoteForEdit = (caseNoteId) => {
        const caseNote = caseNotesList.find(f => f.caseNoteId === caseNoteId);
        if (!caseNote) {
            setEditCaseNoteId("");
            return caseNoteObjectFactory.createCaseNoteObject();
        }

        return caseNoteObjectFactory.createCaseNoteObject(caseNote);
    };

    useEffect(() => {
        setIsEditMode(!!editCaseNoteId);
    }, [editCaseNoteId]);

    useEffect(() => {
        setCaseNotesList(caseNotes);
        filterCaseNotesList(caseNoteFilters);
    }, [caseNotes])

    return (
        <>
            <div id={`case-notes-view`}>
                {
                    !noCaseNotes &&
                    !editCaseNoteId &&
                    <GridRow rowClass="filterBar">
                        <GridColumn medium={`6`}>
                            <DateSelectField
                                dateRangeType={caseNoteFilters.dateRangeType}
                                endDate={caseNoteFilters.endDate}
                                label={`Date`}
                                name={`filterDate`}
                                onSelectDate={handleSelectDate}
                                startDate={caseNoteFilters.startDate}
                                showLabel
                            />
                        </GridColumn>
                        {
                            !isMinimumCaseNote &&
                            <GridColumn medium={`6`}>
                                <SelectField
                                    label="Activity"
                                    name="noteType"
                                    showLabel
                                    onChange={handleFilterUpdateChange}
                                    options={caseNoteTypeList}
                                    value={caseNoteFilters.noteType}
                                />
                            </GridColumn>
                        }
                        <ButtonBar position={""}>
                            <Button name={`btnDownload`}
                                    label={`Export`}
                                    buttonType={ButtonTypes.DOWNLOAD}
                                    onClick={handleDownloadCaseInvestigationReportClick}
                                    btnClass={`l-float-right`}
                            />
                            <Button name={`btnClearFilter`}
                                    label={`Clear`}
                                    buttonType={ButtonTypes.CANCEL}
                                    onClick={handleClearCaseNotesClick}
                                    btnClass={`l-float-right`}
                            />
                            <Button name="btnUpdateFilter"
                                    label="Update"
                                    buttonType={ButtonTypes.SEARCH}
                                    onClick={handleFilterCaseNotesClick}
                                    btnClass={"l-float-right"}
                            />
                        </ButtonBar>
                    </GridRow>
                }
                {
                    !isEditMode &&
                    !readOnly &&
                    !noCaseNotes &&
                    editCaseNoteId !== newCaseNote &&
                    <TileContainer rowTilesLarge="1" rowTilesMedium="1">
                        <Tile body={"Add Case Note"}
                              onClick={() => setEditCaseNoteId(newCaseNote)}
                              isNew={true}/>
                    </TileContainer>
                }
                {
                    (
                        noCaseNotes ||
                        editCaseNoteId === newCaseNote
                    ) &&
                    !readOnly &&
                    <CaseNoteEdit
                        caseNoteType={caseNoteType}
                        readOnlyCaseNoteTypes={readOnlyCaseNoteTypes}
                        otherAreaCaseNoteTypes={otherAreaCaseNoteTypes}
                        caseNoteTypeList={caseNoteTypeList}
                        caseNote={caseNoteObjectFactory.createCaseNoteObject()}
                        containerId={`case-note-${editCaseNoteId}`}
                        handleCancel={handleCancel}
                        handleReturn={handleReturn}
                        handleSave={handleSave}
                        handleDataChanged={checkAndHandleDataChanged}
                        handleScrollToTop={handleScrollToTop}
                        isMinimumCaseNote={isMinimumCaseNote}
                        noCaseNotes={noCaseNotes}
                    />
                }
                {
                    editCaseNoteId &&
                    editCaseNoteId !== newCaseNote &&
                    <CaseNoteEdit
                        caseNoteType={caseNoteType}
                        readOnlyCaseNoteTypes={readOnlyCaseNoteTypes}
                        otherAreaCaseNoteTypes={otherAreaCaseNoteTypes}
                        caseNoteTypeList={caseNoteTypeList}
                        caseNote={getCaseNoteForEdit(editCaseNoteId)}
                        containerId={`case-note-${editCaseNoteId}`}
                        handleCancel={handleCancel}
                        handleReturn={handleReturn}
                        handleSave={handleSave}
                        handleDataChanged={checkAndHandleDataChanged}
                        handleScrollToTop={handleScrollToTop}
                        isMinimumCaseNote={isMinimumCaseNote}
                    />
                }
                {
                    !editCaseNoteId &&
                    !isArrayNullOrEmpty(caseNotesList) &&
                    <GridTable>
                        <thead>
                        <tr className="text-center">
                            <th>Date</th>
                            <th>Author</th>
                            <th>Activity</th>
                            {
                                !readOnly &&
                                <th>Actions</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            caseNotesList.map((c, key) => {
                                const caseNote = caseNoteObjectFactory.createCaseNoteObject(c);
                                return <tr key={key} id={`case-note-${caseNote.caseNoteId}`} valign={`top`}>
                                    <CaseNoteComponent
                                        caseNoteType={caseNoteType}
                                        readOnlyCaseNoteTypes={readOnlyCaseNoteTypes}
                                        otherAreaCaseNoteTypes={otherAreaCaseNoteTypes}
                                        caseNoteTypeList={caseNoteTypeList}
                                        caseNote={caseNote}
                                        handleDelete={handleDelete}
                                        handleEditCaseNote={setEditCaseNoteId}
                                        isMinimumCaseNote={isMinimumCaseNote}
                                        readOnly={readOnly}
                                    />
                                </tr>;
                            })
                        }
                        </tbody>
                    </GridTable>
                }
                {
                    noCaseNotes &&
                    readOnly &&
                    <p><strong>No case notes have been created yet.</strong></p>
                }
                {
                    !noCaseNotes &&
                    isArrayNullOrEmpty(caseNotesList) &&
                    <p><strong>No case notes match the filters specified.</strong></p>
                }
            </div>
            {
                handleReturn &&
                !editCaseNoteId &&
                !noCaseNotes &&
                <ButtonBar position={ButtonBarPositions.STICKY_BOTTOM}>
                    <Button
                        buttonType={ButtonTypes.BACK}
                        label="Return"
                        name="btnReturn"
                        onClick={handleReturn}
                        showLabel
                    />
                </ButtonBar>
            }
        </>
    );
};

CaseNotesView.propTypes = {
    caseNotes: PropTypes.array,
    caseNoteType: PropTypes.object.isRequired,
    caseNoteTypeList: PropTypes.array.isRequired,
    handleDataChanged: PropTypes.func,
    handleDeleteCaseNote: PropTypes.func.isRequired,
    handleDownloadCaseInvestigationReport: PropTypes.func,
    handleReturn: PropTypes.func,
    handleSaveCaseNote: PropTypes.func.isRequired,
    handleScroll: PropTypes.func,
    handleScrollToTop: PropTypes.func,
    isMinimumCaseNote: PropTypes.bool,
    otherAreaCaseNoteTypes: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    readOnlyCaseNoteTypes: PropTypes.object
};

export default CaseNotesView;