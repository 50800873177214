export const DELIVERABLE_SUMMARY_SCROLL_CONFIG = {
    duration: 200,
    delay: 0,
    smooth: true
};

export const MODAL_ID = "deliverableSummaryEditModal";

export const DELIVERABLES_SUMMARY_EDIT_MODAL_SCROLL_CONFIG = {
    containerId: MODAL_ID,
    duration: 200,
    delay: 0,
    smooth: true
};