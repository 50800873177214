import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "../common/buttons/Button";
import ButtonBar from "../common/buttons/ButtonBar";
import {isTrimmedStringEmpty} from "../common/commonUtilities";
import DateTimePickerField from "../common/inputs/DateTimePickerField";
import DisplayField from "../common/inputs/DisplayField";
import TextField from "../common/inputs/TextField";
import FormTable from "../common/FormTable";
import SelectField from "../common/inputs/SelectField";
import TextAreaEditor from "../common/inputs/textAreaEditor/TextAreaEditor";
import {
    clearInputFormErrorFields,
    convertFormInputObjectToObject,
    inputFormIsValid,
    useFormInputObject
} from "../common/inputs/inputUtility";
import {NotifyUser} from "../common/NotifyUser";
import * as ButtonBarPositions from "../../constants/ButtonBarPositions";
import * as ButtonTypes from "../../constants/ButtonTypes";
import {DateTimePickerType} from "../../constants/inputConstants";
import * as caseNoteUtilities from "./caseNoteUtilities";
import * as caseNoteObjectFactory from "./caseNoteObjectFactory";
import {getDifferentBetweenTimesInMinutes} from "../shared/sharedDataUtilities";
import {newCaseNote, Other} from "../../constants/caseNotes/genericCaseNoteConstants";

const CaseNoteEdit = ({
                          caseNoteType,
                          readOnlyCaseNoteTypes,
                          otherAreaCaseNoteTypes,
                          caseNoteTypeList,
                          caseNote,
                          containerId,
                          handleCancel,
                          handleReturn,
                          handleSave,
                          handleDataChanged,
                          handleScrollToTop,
                          isMinimumCaseNote,
                          noCaseNotes = false
                      }) => {

    const caseNoteForm = useFormInputObject(caseNoteObjectFactory.createCaseNoteObject(caseNote), () => handleDataChanged());

    const validateForm = () => {
        clearInputFormErrorFields(caseNoteForm);

        if (!caseNoteForm.dateAdded.value)
            caseNoteForm.dateAdded.setError("Date added is required.");

        if(!isMinimumCaseNote) {
            if (!caseNoteForm.noteType.value)
                caseNoteForm.noteType.setError("Note type activity is required.");
            else if (caseNoteForm.noteType.value.toString() === caseNoteType[Other].id.toString() && isTrimmedStringEmpty(caseNoteForm.otherNoteType.value))
                caseNoteForm.otherNoteType.setError("Other activity requires description.")
            if (!caseNoteForm.startTime.value && caseNoteForm.endTime.value)
                caseNoteForm.startTime.setError("Start time is required when the end time is specified.")
            else if (caseNoteForm.startTime.value && !caseNoteForm.endTime.value)
                caseNoteForm.endTime.setError("End time is required when the start time is specified.")
            else if (caseNoteForm.startTime.value && caseNoteForm.endTime.value && getDifferentBetweenTimesInMinutes(caseNoteForm.startTime.value, caseNoteForm.endTime.value) <= 0)
                caseNoteForm.startTime.setError("Start time needs to come before the end time.")
        }

        if (!caseNoteForm.content.value)
            caseNoteForm.content.setError("Note content is required.");

        let isValid = inputFormIsValid(caseNoteForm);
        if (!isValid)
            NotifyUser.Warning("Missing required items.  Please review your responses and try submitting again.");

        return isValid;
    };

    const handleCancelClick = (event) => {
        event.preventDefault();
        handleCancel();
    }

    const handleSaveClick = (event) => {
        event.preventDefault();
        if (validateForm()) {
            const result = convertFormInputObjectToObject(caseNoteForm);
            handleSave(result);
        }
    };

    useEffect(() => {
        handleScrollToTop();
    }, []);

    const isCaseNoteTypeDisabled = caseNoteUtilities.isNoteTypeOtherAssociatedArea(otherAreaCaseNoteTypes, caseNoteForm.noteType.value);
    const filteredCaseNoteTypeList = isCaseNoteTypeDisabled
        ? caseNoteTypeList
        : caseNoteTypeList.filter(f => !caseNoteUtilities.isNoteTypeOtherAssociatedArea(otherAreaCaseNoteTypes, f.value))
            .filter(f => !caseNoteUtilities.isNoteTypeReadOnly(readOnlyCaseNoteTypes, f.value));


    return (
        <div id={containerId}>
            <FormTable>
                {
                    !caseNote.caseNoteId &&
                    <DisplayField label={`New Case Note`} name={`newCaseNote`} showLabel={false}>
                        <h2>New Case Note</h2>
                    </DisplayField>
                }
                <DisplayField label={`Author`} name={`author`} showLabel columnsMedium={6}>
                    {caseNote.author}
                </DisplayField>
                <DateTimePickerField
                    label="Date"
                    medium={6}
                    showLabel
                    {...caseNoteForm.dateAdded}
                    disabled={!!caseNote.caseNoteId}
                />
                {
                    !isMinimumCaseNote &&
                    <SelectField
                        medium={6}
                        label="Activity"
                        name="noteType"
                        showLabel
                        options={filteredCaseNoteTypeList}
                        {...caseNoteForm.noteType}
                        disabled={isCaseNoteTypeDisabled}
                    />
                }
                {
                    caseNoteForm.noteType.value &&
                    caseNoteType[Other] &&
                    caseNoteForm.noteType.value.toString() === caseNoteType[Other].id.toString() &&
                    <TextField
                        label={`Other Description`}
                        columnsMedium={6}
                        showLabel
                        {...caseNoteForm.otherNoteType}
                    />
                }
                {
                    !isMinimumCaseNote &&
                    <>
                        <DateTimePickerField
                            dateTimePickerType={DateTimePickerType.TimeOnly}
                            label="Start Time (Optional)"
                            medium={6}
                            noCalendar
                            showLabel
                            {...caseNoteForm.startTime}
                        />
                        <DateTimePickerField
                            dateTimePickerType={DateTimePickerType.TimeOnly}
                            label="End Time (Optional)"
                            medium={6}
                            noCalendar
                            showLabel
                            {...caseNoteForm.endTime}
                        />
                    </>
                }
                <TextAreaEditor
                    label="Content"
                    name="content"
                    showLabel={false}
                    {...caseNoteForm.content}
                />
            </FormTable>
            <ButtonBar position={ButtonBarPositions.BOTTOM}>
                <Button name="btnSave"
                        label="Save"
                        buttonType={ButtonTypes.SAVE}
                        onClick={handleSaveClick}/>
                {
                    !noCaseNotes &&
                    <Button name="btnCancel"
                            label="Cancel"
                            buttonType={ButtonTypes.CANCEL}
                            onClick={handleCancelClick}/>
                }
                {
                    noCaseNotes &&
                    handleReturn &&
                    <Button
                        buttonType={ButtonTypes.BACK}
                        label="Return"
                        name="btnReturn"
                        onClick={handleReturn}
                        showLabel
                    />
                }
            </ButtonBar>
        </div>
    );
};

CaseNoteEdit.propTypes = {
    caseNote: PropTypes.object.isRequired,
    caseNoteType: PropTypes.object.isRequired,
    caseNoteTypeList: PropTypes.array.isRequired,
    containerId: PropTypes.string.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleReturn: PropTypes.func,
    handleDataChanged: PropTypes.func,
    handleSave: PropTypes.func.isRequired,
    handleScrollToTop: PropTypes.func.isRequired,
    isMinimumCaseNote: PropTypes.bool.isRequired,
    noCaseNotes: PropTypes.bool,
    otherAreaCaseNoteTypes: PropTypes.object,
    readOnlyCaseNoteTypes: PropTypes.object
};

export default CaseNoteEdit;